<template>
  <el-container class="condition-container">
    <div class="sidebar-inner" style="width: 100%;">
      <MultiSelectField
          fieldIndex='0'
          title='Which Home Location'
          fieldStep='1'
          :showAnyOption='false'
          :anyLabel='false'
          :specificLabel='false'
          specificAnyLabel='Any of the following locations'
          specificExclusionLabel='Any Home Locations except the following'
          :selectedOptions.sync='selectedLocations'
          specificChoice='specific'
          :exclusionChoice.sync='exclusionOption'
          :options='locations'
          selectKey='id'
        />
    </div>
    <div v-if="selectedLocations.length" class="save-condition-container">
        <el-button type="primary" class="xpl-button xpl-button--primary save-condition-btn" @click="commitCondition">Save</el-button>
      </div>
  </el-container>
</template>
<script>
  import EventBus from '@/event-bus';
  import { getMTLinkedLocations } from '@/api';
  import MultiSelectField from '@/components/Contacts/ContactLists/Conditions/Fields/MultiSelectField';

  export default {
    props: ['condition', 'editedRule'],
    components: {
      MultiSelectField,
    },
    data() {
      return {
        toggleLocationSelection: true,
        selectedLocations: this.editedRule ? this.editedRule.locations : [],
        locations: [],
        exclusionOption: this.editedRule ? this.editedRule.exclusion : 'any',
      };
    },
    created() {
      this.fetchLocations();
    },
    methods: {
      async fetchLocations() {
        try {
          const response = await getMTLinkedLocations();
          this.locations = response.locations;
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          locations: this.selectedLocations,
          exclusion: this.exclusionOption,
        };
        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .condition-container {
     background-color: #F4F8FA;
    height: 100%;
    width: 100%;
  }
  .sidebar-inner {
    height: 100%;
    position: relative;
    padding-top: 0;
    background-color: #F4F8FA;
    padding-left: 2.625rem;
    padding-right: 2.25rem;
    padding-bottom: 0;
    width: 100%;
  }
  .card-condtion {
  width: 100%;
  background-color: #FFFFFF;
  border: solid 1px var(--xpl-color-gray-400);
  box-shadow: none;
  border-radius: 0;
  cursor: auto;
  :hover {
  background-color: #FFFFFF;
  cursor: auto;
  }
}
  .save-condition-btn {
    width: 729px;
    margin: 16px 12px 16px 12px;
  }
  .save-condition-container {
    // box-shadow: 0 -6px 7px 0 rgba(0, 0, 0, 0.05);
    padding: 0;
    margin: 0;
  }
</style>
