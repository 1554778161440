<template>
  <transition name="fade">
    <div class="container-fluid" v-cloak>
      <div class="container-header">
        <h2>Sign Up Forms</h2>
        <button class="xpl-button xpl-button--primary" @click="new_form = true">Create Sign Up Form</button>
        <CreateForm v-if="new_form" />
      </div>
      <div class="datatable" v-if="forms && forms.data && forms.data.length">
        <div class="filters">
          <form @submit.prevent>
            <div class="input-group">
              <span class="input-group-addon">
                <span class="icon icon-search"></span>
              </span>
              <input type="search" name="query" v-model.trim="query" class="form-control" placeholder="Search" />
            </div>
          </form>
        </div>

        <div v-if="filteredForms.length">
          <table class="table bb-datatable table-hover">
            <thead>
              <tr>
                <td></td>
                <td></td>
                <td>Last 30 Days</td>
                <td>All Time</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="form in filteredForms" @click="$router.push('/contacts/embed/' + form.uuid)">
                <td></td>
                <td>
                  <h6>{{ form.name }}</h6>
                  <div class="small text-muted">Last Updated on {{ form.updated_at | humanDateTime }}</div>
                </td>
                <td class="last_30">
                  <div>
                    <span class="material-icons">group</span>
                    <span class="text-muted" v-if="!form.last_30">&mdash;</span>
                    <span v-if="form.last_30">{{ form.last_30 | prettyNumber }}</span>
                  </div>
                </td>
                <td class="all_time">
                  <div>
                    <span class="material-icons">group_work</span>
                    <span class="text-muted" v-if="!form.all_time">&mdash;</span>
                    <span v-if="form.all_time">{{ form.all_time | prettyNumber }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <img src="https://assets.brandbot.com/public/empty-states/signup-forms.svg" alt="No Forms!" class="illustration" v-if="forms && forms.data && !forms.data.length" />
      <p class="text-center" v-if="forms && forms.data && !forms.data.length">You have no Sign Up Forms!</p>
    </div>
  </transition>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import CreateForm from './EmbeddableForms/CreateForm';

  export default {
    components: {
      CreateForm,
    },
    data() {
      return {
        query: '',
        new_form: false,
        forms: null,
      };
    },
    mounted() {
      EventBus.$on('create_form.close', () => {
        this.new_form = false;
      });
    },
    created() {
      this.fetchForms();
    },
    beforeDestroy() {
      EventBus.$off('create_form.close');
    },
    computed: {
      filteredForms() {
        if (!this.query) {
          return this.forms.data;
        }

        return this.forms.data.filter(f =>
          f.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1,
        );
      },
    },
    methods: {
      fetchForms() {
        api.getForms().then((response) => {
          this.forms = response.forms;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  table h6 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #1d364b;
    margin: 0;
  }

  .bb-datatable {
    border: none;
    border-collapse: separate;
    border-spacing: 0 .5rem;
  }

  .bb-datatable thead tr td {
    cursor: initial;
    border: none;
  }

  .bb-datatable tbody tr td {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    color: #1d364b;
    // box-shadow: 0 1px 0 0 rgba(100, 164, 210, 0.13);
  }

  .bb-datatable tbody tr td:first-child {
    border-left: 1px solid var(--xpl-color-gray-400);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .bb-datatable tbody tr td:last-child {
    border-right: 1px solid var(--xpl-color-gray-400);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    max-width: none;
    width: auto;
  }

  td.last_30 div,
  td.all_time div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  td.last_30 .material-icons {
    font-size: 1.25rem;
    line-height: 1rem;
    color: rgb(96, 131, 233);
    margin-right: .5rem;
  }

  td.all_time .material-icons {
    font-size: 1.25rem;
    line-height: 1rem;
    color: rgb(195, 224, 249);
    margin-right: .5rem;
  }

  .illustration {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 15rem;
  }
</style>
