<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Choose Children Accounts
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <label>Add Children Accounts</label>
                <Multiselect v-model="selected_children" placeholder="Children Accounts" :options="children" :searchable="true" label="name" track-by="id" :allow-empty="false" :show-labels="false" :multiple="true" />
              </div>

              <div class="form-group">
                <button type="button" class="xpl-button xpl-button--secondary" @click="selected_children = children">Add all chidren accounts</button>
              </div>

              <div class="form-group">
                <label @click="all_editable = !all_editable">
                  <span class="icon icon-checkbox-checked2" v-if="all_editable"></span>
                  <span class="icon icon-checkbox-unchecked2" v-if="!all_editable"></span>
                  Mark all as editable
                </label>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="button" class="xpl-button xpl-button--primary" @click="save" :disabled="!selected_children.length">Save</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['scheduledEmailId'],
    components: {
      Multiselect,
    },
    data() {
      return {
        all_editable: true,
        selected_children: [],
        children: [],
      };
    },
    created() {
      this.fetchChildren();
    },
    methods: {
      fetchChildren() {
        api.getChildrenAccounts().then((response) => {
          this.children = response.children;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      closeModal() {
        EventBus.$emit('subaccounts.modal_close');
      },
      save() {
        const payload = {
          scheduled_email_id: this.scheduledEmailId,
          subaccount_ids: this.selected_children.map(sc => sc.id),
          editable: this.all_editable,
        };

        api.scheduledEmailLinkChildren(payload).then((response) => {
          this.$toasted.success(response.message);
          this.closeModal();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  .multiselect__tags {
    max-height: 4rem;
    overflow-y: scroll;
  }
</style>
<style lang="scss" scoped>
  .icon {
    margin-right: .5rem;
  }

  .modal .modal-content .modal-body {
    overflow-y: visible;
  }
</style>
