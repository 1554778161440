<template>
  <div>
    <el-row>
      <el-col :span="24" class="right">
        <el-button type="primary" class="xpl-button xpl-button--primary" @click="newProgram">Link new SheerID Program</el-button>
      </el-col>
    </el-row>

    <div class="programs">
      <el-card v-for="program in programs" :key="program.id">
        <el-row>
          <el-col :span="3">
            <div v-if="program.enabled" class="sending-active">
              <span class="material-icons">play_circle_outline</span>
              Live
            </div>
            <div v-if="!program.enabled" class="sending-inactive">
              <span class="material-icons">block</span>
              Off
            </div>
          </el-col>
          <el-col :span="17">
            <div class="name">{{ program.name }}</div>
          </el-col>
          <el-col :span="4">
            <el-button type="secondary" class="xpl-button xpl-button--secondary pull-right" size="small" @click="viewProgram(program.uuid)">View</el-button>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>
<script>
  import { getSheerIDPrograms, createNewSheerIDProgram } from '@/api';

  export default {
    data() {
      return {
        programs: [],
      };
    },
    created() {
      this.fetchPrograms();
    },
    methods: {
      viewProgram(uuid) {
        this.$router.push(`/apps/sheer-id/${uuid}`);
      },
      fetchPrograms() {
        getSheerIDPrograms().then((response) => {
          this.programs = response.programs;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      newProgram() {
        this.$prompt('Name', 'New SheerID Program', {
          confirmButtonText: 'Create',
          cancelButtonText: 'Cancel',
          inputPattern: /^.{2,}$/,
          inputErrorMessage: 'At least 2 characters are required.',
        }).then(({ value }) => {
          this.createProgram(value);
        });
      },
      createProgram(name) {
        createNewSheerIDProgram({ name }).then((response) => {
          this.$router.push(`/apps/sheer-id/${response.program.uuid}`);
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-col.right {
    display: flex;
    justify-content: flex-end;
  }

  .programs {
    margin-top: 2rem;
  }

  .programs .name {
    font-size: 1.125rem;
    font-weight: bold;
    letter-spacing: 0.15px;
    color: #172333;
  }

  .programs .el-card + .el-card {
    margin-top: 1rem;
  }


  .programs .el-card .el-row {
    display: flex;
    align-items: center;
  }

  .programs .el-card .sending-active {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #6923f4;
    font-size: .875rem;
    font-weight: bold;
  }
  .el-card .sending-active .material-icons {
    font-size: 1.25rem;
  }

  .el-card .sending-inactive {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #d62929;
    font-size: .875rem;
    font-weight: bold;
  }
  .el-card .sending-inactive .material-icons {
    font-size: 1.25rem;
  }
</style>
