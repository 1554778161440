<template>
  <div>
    <el-row :span="24" style="margin-bottom: 2rem;">
      <el-col :span="12">
        <h2>Franchise Data</h2>
      </el-col>
      <el-col :span="12" class="text-right">
        <el-button type="warning" @click.stop="syncVendor" :loading="syncLoading" v-if="$store.state.user.current_account.is_child">Sync Vendor Data</el-button>
      </el-col>
    </el-row>

    <el-form @submit.native.prevent>
      <el-form-item>
        <el-input v-model="query" placeholder="Search..." />
      </el-form-item>
    </el-form>

    <el-tabs @tab-click="selectTab">
      <el-tab-pane :label="type | humanType" :name="type" v-for="type in available_types" :key="type">
      </el-tab-pane>
    </el-tabs>

    <FranchiseDataPoint v-if="activeType" :type="activeType" :query="query" />
  </div>
</template>
<script>
  import {
    getVendorDataTypes,
    syncVendorData,
  } from '@/api';
  import FranchiseDataPoint from './FranchiseDataPoint';

  export default {
    components: {
      FranchiseDataPoint,
    },
    data() {
      return {
        available_types: [],
        types: [],
        loading: false,
        activeType: null,
        syncLoading: false,
        query: '',
        data: null,
      };
    },
    created() {
      this.fetchDataTypes();
    },
    filters: {
      humanType(type) {
        let string = type.replace('_', ' ');
        string = string.toLowerCase()
              .split(' ')
              .map(s => s.charAt(0).toUpperCase() + s.substring(1))
              .join(' ');

        return string;
      },
    },
    methods: {
      selectTab(tab) {
        this.activeType = tab.name;
      },
      setEditable(type) {
        if (type.edit) {
          type.edit = false;
        } else {
          this.$set(type, 'edit', true);
        }
      },
      syncVendor() {
        this.syncLoading = true;
        syncVendorData().then(() => {
          this.$message.success('Sync is finished.');
          this.syncLoading = false;
        }).catch((err) => {
          this.syncLoading = false;
          this.$message.error(err.message);
        });
      },
      fetchDataTypes(type = '') {
        this.loading = true;
        this.syncLoading = true;

        getVendorDataTypes(type).then((response) => {
          this.available_types = response.types;
          this.activeType = this.available_types[0];
          this.loading = false;
          this.syncLoading = false;
        }).catch((err) => {
          this.loading = false;
          this.syncLoading = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-card + .el-card {
    margin-top: 2rem;
  }

  .el-form {
    margin-top: 1rem;
  }
</style>
