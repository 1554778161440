<template>
  <div>
  <el-card shadow="never" class="connect" v-if="!show_locations">
    <h1 class="text-center">Connect to your Zingfit Account</h1>
    <el-form @submit.native.prevent>
      <el-form-item>
        <label>Client ID</label>
        <el-input v-model="client_id" placeholder="Client ID">
        </el-input>
      </el-form-item>
      <el-form-item>
        <label>Client Secret</label>
        <el-input v-model.trim="client_secret" placeholder="Client Secret" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="loginZingfit" :loading="loading" :disabled="!client_id || !client_secret">Connect</el-button>
      </el-form-item>
      </el-form>
  </el-card>
  <el-card shadow="never" v-if="show_locations">
    <h1 class="text-center">Available Locations</h1>
    <el-form @submit.native.prevent>
      <el-form-item>
        <el-table :data="locations">
          <el-table-column prop="id" label="ID"></el-table-column>
          <el-table-column prop="name" label="Name"></el-table-column>
          <el-table-column align="right" label="Link to Account">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.connect" active-text="Link to Account" />
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="saveLocations" :loading="loading" :disabled="!selectedLocations.length">Link Locations</el-button>
      </el-form-item>
    </el-form>
  </el-card>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import { saveZFCredentials, linkZFLocations } from '@/api';

  export default {
    data() {
      return {
        loading: false,
        client_id: '',
        client_secret: '',
        region_id: null,
        access_token: null,
        locations: [],
        show_locations: false,
      };
    },
    computed: {
      selectedLocations() {
        return this.locations.filter(l => l.connect);
      },
    },
    methods: {
      saveLocations() {
        this.loading = true;
        const payload = {
          sites: this.selectedLocations.map(l => l.id),
        };

        linkZFLocations(payload).then((response) => {
          this.loading = false;
          this.$message.success(response.message);
          EventBus.$emit('signup.vendor.done', 'zingfit');
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      loginZingfit() {
        this.loading = true;
        const payload = {
          client_id: this.client_id,
          client_secret: this.client_secret,
        };
        saveZFCredentials(payload).then((response) => {
          this.loading = false;
          this.$message.success(response.message);
          this.show_locations = true;
          this.locations = response.sites;
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-card.connect {
    max-width: 500px;
    width: 500px;
    min-width: 500px;
  }

  .el-card {
    max-width: 750px;
    width: 750px;
    min-width: 750px;
    border: none;
  }

  .el-table {
    max-height: 500px;
    overflow-y: scroll;
  }

  .el-card h1 {
    font-size: 1.75rem;
    color: #00152a;
    margin-bottom: 2.5rem;
  }

  .el-form-item {
    margin-bottom: 1rem;
  }
</style>
