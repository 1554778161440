import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;
const PASSWORD_CLIENT_ID = process.env.VUE_APP_PASSWORD_CLIENT_ID || '2';
const PASSWORD_CLIENT_SECRET = process.env.VUE_APP_PASSWORD_CLIENT_SECRET || '0EREEHyBgvgems1O9zKdWv7jKpqC7zPmgIzkweml';

export const authAxios = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  },
});

authAxios.interceptors.response.use(response => response, (error) => {
  const originalRequest = error.config;
  if (error.response && error.response.status === 401 && !originalRequest.retry) {
    originalRequest.retry = true;
    originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
    return axios(originalRequest);
  }

  return Promise.reject(error);
});

export function loginUser(creds) {
  const formData = new FormData();
  formData.append('grant_type', 'password');
  formData.append('client_id', PASSWORD_CLIENT_ID);
  formData.append('client_secret', PASSWORD_CLIENT_SECRET);
  formData.append('username', creds.email);
  formData.append('password', creds.password);
  formData.append('scope', '');
  const URL = API_URL.replace('api/', '');
  return new Promise((resolve, reject) => {
    axios.post(`${URL}oauth/token`, formData).then((response) => {
      resolve(response.data);
    }).catch((err) => {
      reject(err.response.data);
    });
  });
}

export function getCurrentUser() {
  return new Promise((resolve, reject) => {
    authAxios.get('user').then((response) => {
      localStorage.setItem('user', JSON.stringify(response.data.data));
      resolve(response.data.data);
    }).catch((err) => {
      reject(err.response.data);
    });
  });
}

export function logoutUser() {
  return new Promise((resolve, reject) => {
    authAxios.post('auth/logout').then((response) => {
      resolve(response.data.data);
    }).catch((err) => {
      reject(err.response.data);
    });
  });
}

export function signup(user) {
  return new Promise((resolve, reject) => {
    axios.post(`${API_URL}auth/register`, user).then((response) => {
      resolve(response.data);
    }).catch((err) => {
      reject(err.response.data);
    });
  });
}

export function updateUser(payload, userId) {
  return new Promise((resolve, reject) => {
    authAxios.put(`users/${userId}`, payload).then((response) => {
      resolve(response.data);
    }).catch((err) => {
      reject(err.response.data);
    });
  });
}

export function confirmEmail(uuid) {
  return new Promise((resolve, reject) => {
    axios.post(`${API_URL}users/confirm/email/`, uuid).then((response) => {
      resolve(response.data.data);
    }).catch((err) => {
      reject(err.response.data);
    });
  });
}

export function confirmInvitation(uuid) {
  return new Promise((resolve, reject) => {
    axios.post(`${API_URL}users/confirm/invitation/`, uuid).then((response) => {
      resolve(response.data.data);
    }).catch((err) => {
      reject(err.response.data);
    });
  });
}

export function getResetLink(email) {
  return new Promise((resolve, reject) => {
    axios.post(`${API_URL}auth/reset/`, email).then((response) => {
      resolve(response.data);
    }).catch((err) => {
      reject(err.response.data);
    });
  });
}

export function resetPassword(payload) {
  return new Promise((resolve, reject) => {
    axios.post(`${API_URL}auth/password/reset/`, payload).then((response) => {
      resolve(response.data);
    }).catch((err) => {
      reject(err.response.data);
    });
  });
}

export function getAvailableCategories() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/available-categories')
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getCurrentAccount() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts')
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function createAccount(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts', payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getTeamMembers(confirmed = null) {
  return new Promise((resolve, reject) => {
    authAxios.get(`accounts/team?confirmed=${confirmed}`)
      .then((response) => {
        let data = response.data;
        let members = [];
        for(let key in data.members) {
          members.push(data.members[key]);
        }
        data.members = members;
        resolve(data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getMemberships() {
  return new Promise((resolve, reject) => {
    authAxios.get('users/memberships')
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function switchMembership(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('users/switch-membership', payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getAccountUser(accountUserId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`account-user/${accountUserId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function changeAccountUser(accountUser) {
  return new Promise((resolve, reject) => {
    authAxios.put(`account-user/${accountUser.id}`, accountUser)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function removeAccountUser(accountUserId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`account-user/${accountUserId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function addAccountUser(email) {
  return new Promise((resolve, reject) => {
    authAxios.post('account-user', email)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        if (err.response.status === 403) {
          reject({ message: 'You do not have enough permissions to perform this action.' });
        } else {
          reject(err.response.data);
        }
      });
  });
}

export function reinviteAccountUser(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('account-user/resend', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        if (err.response.status === 403) {
          reject({ message: 'You do not have enough permissions to perform this action.' });
        } else {
          reject(err.response.data);
        }
      });
  });
}

export function getContact(uuid, offset = 0) {
  return new Promise((resolve, reject) => {
    authAxios.get(`contacts/${uuid}?offset=${offset}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateAccount(accountId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`accounts/${accountId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function activateMindbodySite(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/mindbody/activate-site', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function checkMindbodyConnection() {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/mindbody/check')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getActiveIntegrations() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/integrations')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getAccountEmailMetadata() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/account-email-metadata')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateAccountEmailMetadata(accountEmailMetadataId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`accounts/account-email-metadata/${accountEmailMetadataId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function editContact(id, contact) {
  return new Promise((resolve, reject) => {
    authAxios.put(`contacts/${id}`, contact)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getVerifiedEmails() {
  return new Promise((resolve, reject) => {
    authAxios.get('verified-emails')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteVerifiedEmails(verifiedEmailId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`verified-emails/${verifiedEmailId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function createVerifiedEmails(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('verified-emails', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function confirmVerifiedEmail(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('verified-emails/confirm', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function sendOneOffEmail(contactId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.post(`contacts/send-email/${contactId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getCustomTags() {
  return new Promise((resolve, reject) => {
    authAxios.get('tags?type=custom')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function addCustomTag(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('tags', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateTag(tagId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`tags/${tagId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteTag(tagId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`tags/${tagId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function addContactCustomTag(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('contacts/tags', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function addContactCustomTagMass(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('contacts/tags-mass', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function removeContactCustomTag(contactTag) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`contacts/tags/${contactTag.uuid}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteContact(contactUuid) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`contacts/${contactUuid}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getContactSegments() {
  return new Promise((resolve, reject) => {
    authAxios.get('contacts/segments')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getContacts(filter, page, sorting = '', query = '') {
  return new Promise((resolve, reject) => {
    authAxios.get(`contacts/?page=${page}&filter=${filter}&sort=${sorting}&query=${query}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function searchContacts(filter, query) {
  return new Promise((resolve, reject) => {
    authAxios.get(`contacts/?query=${encodeURI(query)}&filter=${filter}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function searchContactsFast(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('contacts/search', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function addContact(contact) {
  contact.type = 'new';
  return new Promise((resolve, reject) => {
    authAxios.post('contacts', contact)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function addContactNote(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('contacts/note', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function editContactNote(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('contacts/note/edit', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteContactNote(noteId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`contacts/note/${noteId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function unsubContact(contact) {
  contact.type = 'unsubscribe';
  return new Promise((resolve, reject) => {
    authAxios.post('contacts', contact)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function uploadContacts(formData) {
  return new Promise((resolve, reject) => {
    authAxios.post('contacts/upload', formData)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function uploadUnsubscribes(formData) {
  return new Promise((resolve, reject) => {
    authAxios.post('contacts/upload-unsubscribes', formData)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getContactLists(page, sorting = '', query = '', all = '', editor = '') {
  return new Promise((resolve, reject) => {
    authAxios.get(`contacts/lists/?page=${page}&query=${query}&all=${all}&editor=${editor}&sortby=${sorting}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteContactList(uuid) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`contacts/lists/${uuid}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function searchContactLists(query) {
  return new Promise((resolve, reject) => {
    authAxios.get(`contacts/lists/?query=${encodeURI(query)}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function addContactList(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('contacts/lists/', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getContactList(uuid) {
  return new Promise((resolve, reject) => {
    authAxios.get(`contacts/lists/${uuid}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateContactList(contactListId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`contacts/lists/${contactListId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function copyContactList(contactListUuid) {
  return new Promise((resolve, reject) => {
    authAxios.post(`contacts/lists/copy/${contactListUuid}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getContactListContacts(uuid, page, query) {
  return new Promise((resolve, reject) => {
    authAxios.get(`contacts/lists/${uuid}/contacts?page=${page}&query=${query}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function unsubscribeContacts(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('contacts/unsubscribe/', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function subscribeContacts(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('contacts/subscribe/', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function removeContacts(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('contacts/delete-multiple', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function restoreInCampaign(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaigns/put-back', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function removeContactsFromList(contactListId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.post(`contacts/lists/${contactListId}/contacts/remove`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function addContactContactList(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('contacts/lists/add-contact', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function uploadContactsContactList(formData) {
  return new Promise((resolve, reject) => {
    authAxios.post('contacts/lists/upload', formData)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getContactListsAvailableConditionTypes(vendor = 'mindbody') {
  return new Promise((resolve, reject) => {
    authAxios.get(`contacts/lists/condition-types/${vendor}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getCalendarItems(start, end, filter, offset = 0, assignee = '', statuses = '', campaign = '', sort = 'desc') {
  return new Promise((resolve, reject) => {
    authAxios.get(`calendars?start_date=${start}&end_date=${end}&filter=${filter}&offset=${offset}&assignee=${assignee}&statuses=${statuses}&campaign=${campaign}&sort=${sort}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function saveSmartRule(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('contacts/lists/smart-rule', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getPricingOptions() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/mindbody/pricing-options')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getProducts() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/mindbody/products')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getContracts() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/mindbody/contracts')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getPricingOptionsPackages() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/mindbody/pricing-options-packages')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getServiceCategories() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/mindbody/service-categories')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getRevenueCategories() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/mindbody/revenue-categories')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getClasses() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/mindbody/classes')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getEnrollments() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/mindbody/enrollments')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getStaffNames() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/mindbody/staff-names')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getStaffes() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/mindbody/staffes')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getClassTimes() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/mindbody/class-times')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getMindbodyMemberships() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/mindbody/memberships')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getMindbodyIndexes() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/mindbody/indexes')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getPastSentEmails() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/past-sent-emails')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getAssetImages(path = '') {
  return new Promise((resolve, reject) => {
    authAxios.get(`accounts/assets?type=media&path=${path}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getSavedLinks() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/assets?type=links')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function saveSavedLinks(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/assets', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateAccountAsset(accountAssetId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`accounts/assets/${accountAssetId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteAccountAsset(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/assets/delete', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function uploadAssetImage(payload, path = '') {
  return new Promise((resolve, reject) => {
    authAxios.post(`accounts/assets?path=${path}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function saveImageFromAviary(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/assets/aviary-save', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteAssets(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/assets/delete', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function moveImages(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/assets/move', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function createNewFolder(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/assets', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function addScheduledEmail(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('scheduled-emails', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getScheduledEmail(uuid) {
  return new Promise((resolve, reject) => {
    authAxios.get(`scheduled-emails/${uuid}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getTemplateEmails(type) {
  return new Promise((resolve, reject) => {
    authAxios.get(`template-emails?type=${type}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function createTemplate(type, payload) {
  return new Promise((resolve, reject) => {
    authAxios.post(`template-emails?type=${type}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateTemplate(templateId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`template-emails/${templateId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteTemplate(templateId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`template-emails/${templateId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateScheduledEmail(scheduledEmailId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`scheduled-emails/${scheduledEmailId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getEmail(emailId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`emails/${emailId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function putEmail(emailId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`emails/${emailId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getEmailPreview(emailId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`emails/${emailId}/preview`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteScheduledEmail(scheduledEmailId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`scheduled-emails/${scheduledEmailId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function sendTestEmail(emailId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.post(`emails/${emailId}/test`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function saveComment(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('comments', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteComment(commentUuid) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`comments/${commentUuid}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getCommentsScheduledEmail(scheduledEmailId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`scheduled-emails/${scheduledEmailId}/comments`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getPreview(payload) {
  return new Promise((resolve, reject) => {
    axios.post('https://livepreview.brndbot.net/preview', payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getTemplatePreview(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('templates/preview', payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getEmailEditorSections() {
  return new Promise((resolve, reject) => {
    authAxios.get('emails/editor/sections')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getBlocksForSection(uuid) {
  return new Promise((resolve, reject) => {
    authAxios.get(`emails/editor/sections/${uuid}/blocks`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function saveFooter(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('emails/footer', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getBrandingPreview() {
  return new Promise((resolve, reject) => {
    authAxios.get('brand-builder')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function saveBrandingMetadata(payload) {
  return new Promise((resolve, reject) => {
    authAxios.put('brand-builder/1', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function saveBrandingLogo(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('brand-builder', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function emailPerformance(uuid) {
  return new Promise((resolve, reject) => {
    authAxios.get(`emails/${uuid}/performance`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function contactEmailPerformance(contactUuid, typeUuid) {
  return new Promise((resolve, reject) => {
    authAxios.get(`contacts/${contactUuid}/email/${typeUuid}/stats`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function emailPerformanceContacts(uuid, event, page = 1, query = '') {
  return new Promise((resolve, reject) => {
    authAxios.get(`emails/${uuid}/performance-contacts/${event}?page=${page}&query=${query}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getAllAutomations() {
  return new Promise((resolve, reject) => {
    authAxios.get('/apps/nps')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}
export function createAutomation(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('/apps/nps', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getAutomation(uuid) {
  return new Promise((resolve, reject) => {
    authAxios.get(`/apps/nps/${uuid}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateAutomation(automationId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`/apps/nps/${automationId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteAutomation(automationId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`/apps/nps/${automationId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function createAutomationGroup(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('automation-group', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function massAddToCampaign(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaigns/mass-add', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function createCampaign(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaigns', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getAllCampaigns() {
  return new Promise((resolve, reject) => {
    authAxios.get('campaigns')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getCampaign(uuid) {
  return new Promise((resolve, reject) => {
    authAxios.get(`campaigns/${uuid}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteCampaign(campaignId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`campaigns/${campaignId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function addContactCampaign(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaigns/manual-enroll', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function uploadContactsCampaign(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaigns/upload-contacts', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function addNewGoalCampaign(campaignId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`campaigns/${campaignId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteGoalCampaign(campaignId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`campaigns/${campaignId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateCampaign(campaignId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`campaigns/${campaignId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateCampaignOrder(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaigns/order', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function copyCampaign(campaignId) {
  return new Promise((resolve, reject) => {
    authAxios.post(`campaigns/${campaignId}/copy`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function replicateAccount(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaigns/account-replicate', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function createScheduledTask(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('scheduled-tasks', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function editScheduledTask(scheduledTaskId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`scheduled-tasks/${scheduledTaskId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getScheduledTask(scheduledTaskId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`scheduled-tasks/${scheduledTaskId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteScheduledTask(scheduledTaskId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`scheduled-tasks/${scheduledTaskId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function reportingScheduledTask(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('scheduled-tasks/reporting', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function createCampaignItem(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaigns/items', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getCampaignItem(uuid) {
  return new Promise((resolve, reject) => {
    authAxios.get(`campaigns/items/${uuid}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getCampaignItems(campaignId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`campaigns/${campaignId}/items/`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateCampaignItem(campaignItemId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`campaigns/items/${campaignItemId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteCampaignItem(campaignItemId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`campaigns/items/${campaignItemId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function enrolledContactsCampaign(campaignId, page, query) {
  return new Promise((resolve, reject) => {
    authAxios.get(`campaigns/${campaignId}/enrolled-contacts?page=${page}&query=${query}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function historyContactsCampaign(campaignId, page, query) {
  return new Promise((resolve, reject) => {
    authAxios.get(`campaigns/${campaignId}/history-contacts?page=${page}&query=${query}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function goalContactsCampaign(campaignId, page, query) {
  return new Promise((resolve, reject) => {
    authAxios.get(`campaigns/${campaignId}/goal-contacts?page=${page}&query=${query}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteContactsFromCampaign(campaignId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.post(`campaigns/${campaignId}/delete-contacts`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function contactCampaignEvents(contactId, campaignId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`contacts/${contactId}/campaigns/${campaignId}/events`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function contactsCreatedTask(campaignItemId, page) {
  return new Promise((resolve, reject) => {
    authAxios.get(`campaigns/items/${campaignItemId}/contacts-created-task?page=${page}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function contactsCreatedSms(campaignItemId, page) {
  return new Promise((resolve, reject) => {
    authAxios.get(`campaigns/items/${campaignItemId}/contacts-created-sms?page=${page}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function contactsSentSms(campaignItemId, page) {
  return new Promise((resolve, reject) => {
    authAxios.get(`campaigns/items/${campaignItemId}/contacts-sent-sms?page=${page}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function contactsScheduledEmail(campaignItemId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`campaigns/items/${campaignItemId}/contacts-scheduled-email`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function copyCampaignItem(campaignItemId) {
  return new Promise((resolve, reject) => {
    authAxios.post(`campaigns/items/${campaignItemId}/copy`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getCampaignTemplateGroups() {
  return new Promise((resolve, reject) => {
    authAxios.get('campaigns/templates/groups')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getCampaignTemplates() {
  return new Promise((resolve, reject) => {
    authAxios.get('campaigns/templates')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getCampaignTemplate(campaignTemplateId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`campaigns/templates/${campaignTemplateId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getScheduledPlans() {
  return new Promise((resolve, reject) => {
    authAxios.get('plans')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function createScheduledPlan(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('plans', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateScheduledPlan(planId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`plans/${planId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getScheduledPlan(scheduledPlanId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`plans/${scheduledPlanId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteScheduledPlan(scheduledPlanId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`plans/${scheduledPlanId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getScheduledPlanTemplateGroups() {
  return new Promise((resolve, reject) => {
    authAxios.get('plans/templates/groups')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getScheduledPlanTemplates() {
  return new Promise((resolve, reject) => {
    authAxios.get('plans/templates')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getScheduledPlanTemplate(scheduledPlanTemplateId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`plans/templates/${scheduledPlanTemplateId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getBilling() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/billing')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getAdminBilling() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/admin-billing')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function cancelSubscription(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/admin-billing/cancel', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateAccountCard(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/billing/card', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getInvoice(invoiceId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`accounts/billing/invoice/${invoiceId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function upgradeAccount(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/billing/upgrade', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getChildrenAccounts() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/children')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function scheduledEmailLinkChildren(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('scheduled-emails/link-children', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getScheduledEmailLinkedChildren(scheduledEmailId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`scheduled-emails/${scheduledEmailId}/linked-children`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateAccountScheduledEmail(payload, accountScheduledEmailId) {
  return new Promise((resolve, reject) => {
    authAxios.put(`scheduled-emails/update-account-scheduled-email/${accountScheduledEmailId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteAccountScheduledEmail(accountScheduledEmailId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`scheduled-emails/update-account-scheduled-email/${accountScheduledEmailId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function pushToChild(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('scheduled-emails/push-child', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateFeedback(npsUuid, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`apps/nps/${npsUuid}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}
export function feedbackUploadLogo(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('apps/nps/uploadLogo', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function createForm(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('forms', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getForms() {
  return new Promise((resolve, reject) => {
    authAxios.get('forms')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getForm(formUuid) {
  return new Promise((resolve, reject) => {
    authAxios.get(`forms/${formUuid}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getFormMBORequiredFields() {
  return new Promise((resolve, reject) => {
    authAxios.get('forms/mindbody/required-fields')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getMTWaivers(formUuid) {
  return new Promise((resolve, reject) => {
    authAxios.get(`forms/marianatek/waivers?form_uuid=${formUuid}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function createMTWaiver(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('forms/marianatek/waivers', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function linkMTWaiver(payload) {
  return new Promise((resolve, reject) => {
    authAxios.put('forms/marianatek/waivers', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function unlinkMTWaiver(payload) {
  return new Promise((resolve, reject) => {
    authAxios.put('forms/marianatek/waivers', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateForm(formId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`forms/${formId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteForm(formId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`forms/${formId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function copyForm(formId) {
  return new Promise((resolve, reject) => {
    authAxios.post(`forms/${formId}/copy`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getFormContacts(formId, stat) {
  return new Promise((resolve, reject) => {
    authAxios.get(`forms/${formId}/stats?stat=${stat}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function replicateCampaign(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaigns/replicate', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function newListGroup(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('contacts/lists/list-groups', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function newEmailTemplate(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('templates', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteListGroup(tagId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`contacts/lists/list-groups/${tagId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function templatePurposes() {
  return new Promise((resolve, reject) => {
    authAxios.get('templates/purposes')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getListGroups() {
  return new Promise((resolve, reject) => {
    authAxios.get('contacts/lists/list-groups')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function templateSuites() {
  return new Promise((resolve, reject) => {
    authAxios.get('templates/suites')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getEmailTemplates(type = 'full', mode = 'scheduled') {
  return new Promise((resolve, reject) => {
    authAxios.get(`templates?type=${type}&mode=${mode}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getEmailTemplatesSaved(type = 'full') {
  return new Promise((resolve, reject) => {
    authAxios.get(`templates/saved?type=${type}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateEmailTemplatesSaved(templateId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`templates/${templateId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteEmailTemplatesSaved(templateId, brandbot = null) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`templates/${templateId}?brandbot=${brandbot}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getListsForEmails() {
  return new Promise((resolve, reject) => {
    authAxios.get('contacts/lists/list-for-emails')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function linkListToGroup(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('contacts/lists/link-list-groups', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function exportContactList(payload) {
  return new Promise((resolve, reject) => {
    authAxios.get('contacts/lists/export', payload, { responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.csv');
        document.body.appendChild(link);
        link.click();
        resolve(response);
      }).catch((err) => {
        reject(err);
      });
  });
}

export function convertMJMLtoJSON(payload) {
  return new Promise((resolve, reject) => {
    axios.post('https://mooryn6f85.execute-api.us-east-1.amazonaws.com/production/mjml-json', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getContactMessages(contactId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`messages/${contactId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function sendContactMessage(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('messages', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function excludeContact(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('messages/exclude', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getThreads() {
  return new Promise((resolve, reject) => {
    authAxios.get('messages')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function quickSendScheduledEmail(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('scheduled-emails/quick-send', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getActivationCode() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/mindbody/activation-code')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getTwilioData() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/twilio-data')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function formEmailDeveloper(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('forms/email-developer', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function createCheckoutFlow(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('checkout-flows', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function checkoutFlows() {
  return new Promise((resolve, reject) => {
    authAxios.get('checkout-flows')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getCheckoutFlow(checkoutFlowId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`checkout-flows/${checkoutFlowId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function publishCheckoutFlow(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('checkout-flows/publish', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function unpublishCheckoutFlow(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('checkout-flows/unpublish', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateCheckoutFlow(payload, checkoutFlowId) {
  return new Promise((resolve, reject) => {
    authAxios.put(`checkout-flows/${checkoutFlowId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function uploadImageCheckoutFlow(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('checkout-flows/upload-image', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function uploadDefaultCheckoutFlow(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('checkout-flows/upload-default', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getCheckoutFlowPricingOptions() {
  return new Promise((resolve, reject) => {
    authAxios.get('checkout-flows/pricing-options')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteCheckoutFlow(checkoutFlowId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`checkout-flows/${checkoutFlowId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getLandingPageTemplates() {
  return new Promise((resolve, reject) => {
    authAxios.get('checkout-flows/templates')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export async function getLandingPageTemplatePreview(payload, subdomain) {
  try {
    const response = await authAxios.post('checkout-flows/preview', payload);
    const mode = process.env.NODE_ENV !== 'production' ? 'staging' : `prod`;   
    let htmlToBeInjected = response.data.html.replaceAll('/prod/', `/${mode}/`)
    .replaceAll('{{ subdomain }}', `${subdomain}`)
    return htmlToBeInjected;
  } catch (err) {
    throw new Error(err);
  }
  
}

export function getRequiredFields(refresh) {
  return new Promise((resolve, reject) => {
    authAxios.get(`checkout-flows/required-fields?refresh=${refresh}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function editSubdomain(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/subdomain', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function activateCheckoutFlow(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('checkout-flows/activate', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function checkoutFlowPurchasesAllTimeCount(checkoutFlowId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`checkout-flows/${checkoutFlowId}/all-time-count`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getCheckoutFlowStats(checkoutFlowId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`checkout-flows/${checkoutFlowId}/stats`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function checkoutFlowPurchasesAllTimeContacts(checkoutFlowId, page = 1) {
  return new Promise((resolve, reject) => {
    authAxios.get(`checkout-flows/${checkoutFlowId}/all-time-contacts?page=${page}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function checkoutFlowAbandonAllTimeContacts(checkoutFlowId, page = 1) {
  return new Promise((resolve, reject) => {
    authAxios.get(`checkout-flows/${checkoutFlowId}/all-time-abandon-contacts?page=${page}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function checkoutFlowRecoveredAllTimeContacts(checkoutFlowId, page = 1) {
  return new Promise((resolve, reject) => {
    authAxios.get(`checkout-flows/${checkoutFlowId}/all-time-recovered-contacts?page=${page}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getCampaignsSmall() {
  return new Promise((resolve, reject) => {
    authAxios.get('campaigns/small')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function createFunnel(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('funnels', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getFunnels() {
  return new Promise((resolve, reject) => {
    authAxios.get('funnels')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getFunnel(funnelId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`funnels/${funnelId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateFunnel(payload, funnelId) {
  return new Promise((resolve, reject) => {
    authAxios.put(`funnels/${funnelId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteFunnel(funnelId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`funnels/${funnelId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function funnelsWithStages() {
  return new Promise((resolve, reject) => {
    authAxios.get('funnels/stages')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function addContactToFunnel(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('funnels/contact', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function removeContactFromFunnel(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('funnels/contact/remove', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function stagesForContact(contactId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`contacts/funnels/${contactId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getContactsInStage(stageId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`funnels/stages/${stageId}/contacts`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function taskReporting(startDate, endDate) {
  return new Promise((resolve, reject) => {
    authAxios.get(`accounts/task-reporting?start=${startDate}&end=${endDate}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function newUserGroup(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('account-user/user-group', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getUserGroups() {
  return new Promise((resolve, reject) => {
    authAxios.get('account-user/user-group')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function editUserGroup(userGroupId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`account-user/user-group/${userGroupId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getUsersInGroup(userGroupId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`account-user/user-group/${userGroupId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}


export function addUserToGroup(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('account-user/user-group/add', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function removeFromGroup(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('account-user/user-group/remove', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}


export function createCampaignFolder(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaign-folders', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getCampaignFolders() {
  return new Promise((resolve, reject) => {
    authAxios.get('campaign-folders')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateCampaignFolder(folderId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`campaign-folders/${folderId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getCampaignFolder(folderId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`campaign-folders/${folderId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function linkCampaignToGroup(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaign-folders/link', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function unlinkCampaignToGroup(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaign-folders/unlink', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteCampaignGroup(folderId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`campaign-folders/${folderId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getStaffMembers() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/pike13/staff-members')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getPaymentOptions() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/pike13/payment-options')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getServiceNames() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/pike13/service-names')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getPikeEvents() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/pike13/events')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getPersonPlans() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/pike13/person-plans')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getPlanTypes() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/pike13/plan-types')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getPlanCategories() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/pike13/plan-categories')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getContactSourceNames() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/pike13/source-names')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getPikeProducts() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/pike13/products')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getPikePlanProducts() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/pike13/plan-products')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getPikeProductTypes() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/pike13/product-types')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getPikeProductCategories() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/pike13/product-categories')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function addTemplates() {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/add-templates')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function activateSMS() {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/activate-sms')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function campaignDebug(payload, page = 1) {
  return new Promise((resolve, reject) => {
    authAxios.post(`campaigns/debug?page=${page}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function campaignDebugClearBuffer(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaigns/debug/clear-buffer', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function campaignRunGoals(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaigns/debug/run-goals', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function campaignRunEntry(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaigns/debug/run-entry', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function markAsAffiliate(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/affiliate', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getCustomEventNames() {
  return new Promise((resolve, reject) => {
    authAxios.get('webhooks/event-names')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getAllEmailTemplates() {
  return new Promise((resolve, reject) => {
    authAxios.get('templates/all')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function pikeToken(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/pike-token', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function pikeTokenPublic(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/pike-public-token', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getPikeCustomFields() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/pike13/custom-fields')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getPikeServiceLocations() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/pike13/service-locations')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getFormsSmall(type = '') {
  return new Promise((resolve, reject) => {
    authAxios.get(`forms/small?type=${type}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getWaiversSmall() {
  return new Promise((resolve, reject) => {
    authAxios.get('forms/waivers-small')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getWaivers() {
  return new Promise((resolve, reject) => {
    authAxios.get('forms/waivers')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getFormInsights(formId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`forms/${formId}/insights`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function exportFormInsights(formId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`forms/${formId}/insights?export=true`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getTemplatesForm() {
  return new Promise((resolve, reject) => {
    authAxios.get('forms/templates')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function publishForm(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('forms/publish', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function unpublishForm(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('forms/unpublish', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getTemplateFormPreview(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('forms/preview', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function uploadImageForm(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('forms/upload-image', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getSavedPdfs() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/assets/pdfs')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getMindbodyReferralTypes() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/mindbody/referral-types')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getContactDocuments(contactId, page) {
  return new Promise((resolve, reject) => {
    authAxios.get(`accounts/assets/contact/${contactId}?page=${page}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function openContactDocument(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/assets/contact/document', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteContactDocument(contactId, documentId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`accounts/assets/contact/${contactId}/document/${documentId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function uploadContactDocument(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/assets/contact/upload', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getFormFields(formId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`forms/custom-fields/${formId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function fetchFormCustomAnswers(customFieldId) {
  return new Promise((resolve, reject) => {
    authAxios.get(`forms/custom-fields/answers/${customFieldId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function createTargetedSMS(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('apps/targeted-sms', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getTargetedSMS() {
  return new Promise((resolve, reject) => {
    authAxios.get('apps/targeted-sms')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getTargetedSMSSingle(uuid) {
  return new Promise((resolve, reject) => {
    authAxios.get(`apps/targeted-sms/${uuid}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function editTargetedSingle(id, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`apps/targeted-sms/${id}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteTargetedSingle(id) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`apps/targeted-sms/${id}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function pushTargetedSMS(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('apps/targeted-sms/push', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function setAsCorpPaid(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/set-corp-paid', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function activateWebhook(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/pike13/webhooks', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getVendorDataTypes(type = '', query = '', page = 1) {
  return new Promise((resolve, reject) => {
    authAxios.get(`accounts/franchise/data-types?type=${type}&query=${query}&page=${page}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function addVendorDataPoint(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/franchise/data-point', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateVendorDataPoint(id, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`accounts/franchise/data-point/${id}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function syncVendorData() {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/franchise/sync')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function saveMappingRelationship(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/franchise/map', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function pushToFranchisees(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaigns/franchise/push', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function syncedFranchiseCampaigns(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaigns/franchise/synced', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function syncChangesCampaigns(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaigns/franchise/sync-changes', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function newMergeTag(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/merge-tags', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateMergeTag(mergeTagId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`accounts/merge-tags/${mergeTagId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteMergeTag(mergeTagId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`accounts/merge-tags/${mergeTagId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function syncMergeTags() {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/merge-tags/sync')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function validatePhoneNumber(contactId) {
  return new Promise((resolve, reject) => {
    authAxios.put(`contacts/validate-phone/${contactId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function sendOptinEmail(contactId) {
  return new Promise((resolve, reject) => {
    authAxios.put(`contacts/send-optin/${contactId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function newFranchiseeAccount(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/new-franchisee', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getMTMemberships() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/marianatek/memberships')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getKeywordReplies() {
  return new Promise((resolve, reject) => {
    authAxios.get('keyword-replies')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getMTLinkedLocations() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/marianatek/linked-locations')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function saveKeywordReply(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('keyword-replies', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteKeywordReply(keywordReplyId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`keyword-replies/${keywordReplyId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateKeywordReply(keywordReplyId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`keyword-replies/${keywordReplyId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function saveMTToken(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/marianatek/save-credentials', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function saveZFCredentials(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/zingfit/save-credentials', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function linkZFLocations(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/zingfit/link-locations', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function markAsZingfitFree() {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/zingfit/mark-as-free')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function MTLinkLocations(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/marianatek/link-locations', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function MTCredits() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/marianatek/credits')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function MTCreditsWithoutClassPass() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/marianatek/credits')
      .then((response) => {
        resolve(response.data.credits.filter(credit => credit.name !== 'ClassPass'));
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function MTUserTags() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/marianatek/user-tags')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function MTInstructors() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/marianatek/instructors')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function MTClassTypes() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/marianatek/class-types')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function MTClassTimes() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/marianatek/class-times')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function MTProducts() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/marianatek/products')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function MTChildProducts(type = null) {
  return new Promise((resolve, reject) => {
    authAxios.get(`accounts/marianatek/child-products?type=${type}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function MTRefreshChildProducts() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/marianatek/refresh-child-products')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function MTProductClasses() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/marianatek/product-classes')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function MTVisitStatuses() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/marianatek/visit-statuses')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function MTMembershipStatuses() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/marianatek/membership-statuses')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function MTGateways() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/marianatek/gateways')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function MTDiscounts(type = null) {
  return new Promise((resolve, reject) => {
    authAxios.get(`accounts/marianatek/discounts?type=${type}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function syncBranding() {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/sync-brand')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function exportCampaignData(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaigns/export', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response);
      });
  });
}

export function applyEmailToItems() {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/apply-emails')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function applyFranchiseFooter(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/franchise/footer', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function campaignPauseAll(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('campaigns/pause-all', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function addVimeoIntegration(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('accounts/integrations/vimeo', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getVimeoProducts() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/vimeo/products')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getVimeoStatuses() {
  return new Promise((resolve, reject) => {
    authAxios.get('accounts/vimeo/statuses')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getClassSchedule() {
  return new Promise((resolve, reject) => {
    authAxios.get('/class-reminders')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getClassScheduleAdmin() {
  return new Promise((resolve, reject) => {
    authAxios.get('/class-reminders/admin')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getClassDetail(classUuid) {
  return new Promise((resolve, reject) => {
    authAxios.get(`/class-reminders/${classUuid}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateClassDetail(classUuid, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`/class-reminders/${classUuid}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getClassRoster(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('/class-reminders/roster', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getClassReminderSettings() {
  return new Promise((resolve, reject) => {
    authAxios.get('/class-reminders/settings')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateClassRemindersSettings(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('/class-reminders/settings', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function refreshSchedule() {
  return new Promise((resolve, reject) => {
    authAxios.post('/class-reminders/refresh')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getClassRemindersTemplates(filter = 'brandbot') {
  return new Promise((resolve, reject) => {
    authAxios.get(`/class-reminders/templates?filter=${filter}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function classRemindersSendTestSMS(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('/class-reminders/sms-test', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function classRemindersSendTestEmail(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('/class-reminders/email-test', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getCovidTemplate() {
  return new Promise((resolve, reject) => {
    authAxios.get('/class-reminders/covid')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function saveCovidTemplate(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('/class-reminders/covid', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getSheerIDPrograms() {
  return new Promise((resolve, reject) => {
    authAxios.get('/sheerid-programs')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getSheerIDProgram(programUuid) {
  return new Promise((resolve, reject) => {
    authAxios.get(`/sheerid-programs/${programUuid}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateSheerIDSettings(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('/sheerid-programs/settings', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getSheerIDSettings() {
  return new Promise((resolve, reject) => {
    authAxios.get('/sheerid-programs/settings')
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getSheerIDProgramStats(uuid) {
  return new Promise((resolve, reject) => {
    authAxios.get(`/sheerid-programs/stats/${uuid}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getSheerIDProgramStatsDetails(uuid, filter = '') {
  return new Promise((resolve, reject) => {
    authAxios.get(`/sheerid-programs/stats/${uuid}/details?filter=${filter}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getSheerIDTemplates(templateFilter) {
  return new Promise((resolve, reject) => {
    authAxios.get(`/sheerid-programs/templates?filter=${templateFilter}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function createNewSheerIDProgram(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('/sheerid-programs', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function updateSheerIDProgram(programId, payload) {
  return new Promise((resolve, reject) => {
    authAxios.put(`/sheerid-programs/${programId}`, payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function deleteSheerIDProgram(programId) {
  return new Promise((resolve, reject) => {
    authAxios.delete(`/sheerid-programs/${programId}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export function enterpriseHelpers(payload) {
  return new Promise((resolve, reject) => {
    authAxios.post('/accounts/enterprise-helpers', payload)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err.response.data);
      });
  });
}

export async function getIsTimelineTurfed() {
  try {
    const response = await authAxios.get('accounts/turf-timeline');
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
}

export async function updatetIsTimelineTurfed(payload) {
  try {
    const response = await authAxios.post('accounts/turf-timeline', payload);
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
}

export async function conditionTestRunner(payload) {
  try {
    const response = await authAxios.put('bridge/test-condition', payload);
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
}


export * from './uia';
