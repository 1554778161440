<template>
  <el-container class="campaign-items-list-item-complete-container">
    <div class="campaign-items-sidebar-item-top-row">
      <div class="campaign-items-sidebar-item-type flex-centered">
        <font-awesome-icon v-if="this.item.type === 'task'" icon="fa-regular fa-clipboard" size="xs" />
        <font-awesome-icon v-if="this.item.type === 'tag'" icon="fa-solid fa-tag" size="xs" />
        <font-awesome-icon v-if="this.item.type === 'phone_call'" icon="fa-solid fa-phone" size="xs" />
      </div>
      <div class="sidebar-item-name">
        {{ item.name }}
      </div>
    </div>
    <div class="campaign-items-sidebar-item-bottom-row">
      <div class="campaign-items-sidebar-item-status">
        <span class="active status-text"  v-if="item.turned_on_at">Active</span>
        <span class="paused status-text"  v-if="item.paused_at">Paused</span>
        <span class="draft status-text"  v-if="!item.turned_on_at && !item.paused_at">Draft</span>
        <div class="campaign-item-delay-cont" v-if="item.time_rule.choice ==='relative' && item.time_rule.relative === 'Immediately'">
          Delay: Immediately
        </div>
        <div class="campaign-item-delay-cont" v-if="item.time_rule.choice ==='both' && item.time_rule.relative === 'Immediately'">
          Send Immediately with rule
        </div>
        <div class="campaign-item-delay-cont" v-else-if="item.time_rule.choice ==='relative' && item.time_rule.relative !== 'Immediately'">
          Delay: <span class="delay-avg-cap">{{displayDelayAverage}}</span>
        </div>
        <div class="campaign-item-delay-cont" v-else-if="item.time_rule.choice ==='both' && item.time_rule.relative !== 'Immediately'">
          Delay: <span class="delay-avg-cap">{{displayDelayAverage}}</span> with rule
        </div>
        <div class="campaign-item-delay-cont" v-else-if="item.time_rule.choice ==='behavior'">
          Wait until rule is met
        </div>
      </div>
    </div>
  </el-container>
</template>
<script>
export default {
  props: ['item'],
  computed: {
    displayDelayAverage() {
     if(this.item.time_rule.relative_count === 1 && this.item.time_rule.relative !== 'Immediately'){
      return `${this.item.time_rule.relative_count} ${this.item.time_rule.relative.slice(0, -1)}`;
     } else if (this.item.time_rule.relative_count > 1 ) {
      return `${this.item.time_rule.relative_count} ${this.item.time_rule.relative}`;
     }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.campaign-items-sidebar {
  max-width: 100%;
  min-width: 23rem;
  background-color: #FFFFFF;
}
.campaign-items-sidebar-header{
  background-color: white;
}

.campaign-items-sidebar-item-top-row{
  display: flex;
  flex-direction: row;
  padding: 18px 0 0 12px;
  line-height: 1;
}
.campaign-items-sidebar-item-bottom-row{
  padding-left: 36px;
  padding-bottom: 18px;
}

.campaign-item-delay-cont{
  display: inline;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #000000;
}
.sidebar-item-name{
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-left: 12px;
  overflow: hidden;
  margin-right: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.sidebar-item-details-date{
  color: #161E27;
  font-family: Muli;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}

.material-icons {
  color: #272431;
  font-size: 12px;
}
.campaign-items-sidebar-item-status{
  line-height: 1;
  /deep/ .el-tag--dark {
    background-color: transparent;
  }

  .status-text {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    padding: 0;
  }
  .active{
    color: #37B88F;
    border: none;
    margin-right: 10px;
  }
  .paused{
    color: #6A6D7D;
    border: none;
    margin-right: 10px;
  }
  .draft{
    color: #5088D6;
    border: none;
    margin-right: 10px;
  }
}
 .svg-inline--fa {
    color: #272431;
  }

.item-active {
  .svg-inline--fa {
    color: #6923F4;
  }
  .campaign-items-sidebar-item-status {
    color: #6923F4;

    .active{
      color: #6923F4;
  }
  .paused{
    color: #6923F4;
  }
  .draft{
    color: #6923F4;
  }
  }
}
</style>
