<template>
  <el-container>
    <el-header>
      <div class="display-small">SMS Text Messaging</div>
    </el-header>
    <el-container style="padding-top: 3.75rem;">
      <el-aside class="sidebar" width="220px">
        <div class="links">
          <div :class="['right', 'link', tabs.inbox ? 'active' : '']" @click="toggleTab('inbox')">SMS Inbox</div>
          <div :class="['right', 'link', tabs.keyword_replies ? 'active' : '']" @click="toggleTab('keyword_replies')" v-if="$store.state.user.current_account.franchises && $store.state.user.current_account.franchises.indexOf('aqua-tots') > -1">SMS Keyword Replies</div>
        </div>
      </el-aside>
      <Conversations v-if="tabs.inbox" />
      <KeywordReplies v-if="tabs.keyword_replies" />
    </el-container>
  </el-container>
</template>
<script>
  import Conversations from './Conversations';
  import KeywordReplies from './KeywordReplies';

  export default {
    components: {
      Conversations,
      KeywordReplies,
    },
    data() {
      return {
        tabs: {
          inbox: true,
          keyword_replies: false,
        },
      };
    },
    mounted() {
      // eslint-disable-next-line
      document.title = `SMS Text Messaging | ${this.$store.getters.brandName}`;

      if (this.$options.sockets) {
        this.$options.sockets.onmessage = (data) => {
          if (data && data.data) {
            const message = JSON.parse(data.data);
            // eslint-disable-next-line
            console.log('incoming: ', message);
            if ([7].indexOf(this.$store.state.user.id) > -1) {
              this.$notify({
                title: 'New SMS',
                message: message.body,
              });
            }
          }
        };
      }
    },
    created() {
      if (this.$route.query.tab) {
        Object.keys(this.tabs).forEach((t) => {
          this.tabs[t] = false;
        });
        this.tabs[this.$route.query.tab] = true;
      }
    },
    beforeDestroy() {
      // eslint-disable-next-line
      document.title = this.$store.getters.brandName;
      // clearInterval(this.interval);

      if (this.$options.sockets) {
        delete this.$options.sockets.onmessage;
      }
    },
    methods: {
      toggleTab(tab) {
        Object.keys(this.tabs).forEach((t) => {
          this.tabs[t] = false;
        });
        this.tabs[tab] = true;
        this.$router.push({ query: { tab } });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-main {
    padding: 0;
  }

  .el-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--xpl-color-gray-400);
    position: fixed;
    width: 100%;
  }

  .sidebar {
    background-color: rgba(248, 249, 250, 0.8);
    padding-top: 2.625rem;
  }

  .sidebar .links .link {
    font-weight: 400;
    cursor: pointer;
    transition: all 150ms ease-in-out;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.33;
    letter-spacing: 0.15px;
    color: #617080;
    text-transform: uppercase;
    margin-bottom: 1rem;
    padding: 4px 16px;
    border-right: 3px solid transparent;
  }

  .sidebar .links .link.right {
    padding-left: 1.6rem;
  }

  .sidebar .links .link.disabled {
    cursor: initial;
  }

  .sidebar .links .link:hover:not(.disabled),
  .sidebar .links .link.active {
    background-color: var(--xpl-color-secondary-bg-lm);
  }
</style>
