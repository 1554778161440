<template>
  <div class="container-fluid">
    <h1>Upgrade Your Account</h1>
    <div class="row" v-if="tier">
      <div class="col-md-8">
        <div class="card">
          <div class="card-body">
            <h2>Upgrade and get free strategy and training</h2>
            <p>A once-per-month charge based on the highest number of subscribers and transactional recipients in your account during a 30-day billing period.</p>
          </div>
        </div>
        <div class="card">
          <div class="card-header card-header-merged card-header-secondary">Billing Plan</div>
          <div class="card-body">
            <h2>Monthly ({{ tier.english }})</h2>
            <p>A monthly subscription charge based on the highest number of <q>subscribed</q> contacts in your account during a 30-day billing period.</p>
            <div class="alert alert-secondary">
              {{$store.getters.brandName}} automatically sets your billing plan with your number of <q>subscribed</q> contacts. Your account currently has {{ billing_contacts | prettyNumber }} <q>subscribed</q> contacts. Your monthly subscription will be {{ tier.monthly / 100 | prettyCurrency }}.
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card checkout">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <strong>Due Today</strong>
              </div>
              <div class="col-sm-6 text-right">
                <strong>{{ tier.monthly / 100 | prettyCurrency }}</strong>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <span>Monthly Charge</span>
              </div>
              <div class="col-sm-6 text-right">
                <span>{{ tier.monthly / 100 | prettyCurrency }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <span>Next Charge</span>
              </div>
              <div class="col-sm-6 text-right">
                <span>{{ next_charge | humanDate }}</span>
              </div>
            </div>
          </div>
          <div class="card-body text-center">
            <button type="button" class="xpl-button xpl-button--primary" @click="upgrade" ref="upgradeBtn">Upgrade Account</button>
          </div>
        </div>
      </div>
    </div>
    <UpdateCardModal v-if="show_card_modal" />
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import moment from 'moment';
  import UpdateCardModal from '@/components/Settings/UpdateCardModal';

  export default {
    components: {
      UpdateCardModal,
    },
    data() {
      return {
        billing_metadata: null,
        billing_contacts: null,
        tier: null,
        next_charge: moment().add(1, 'months'),
        show_card_modal: false,
      };
    },
    mounted() {
      EventBus.$on('update_card_modal.close', (success) => {
        this.show_card_modal = false;
        if (success) {
          this.upgrade();
        }
      });
    },
    created() {
      this.fetchBilling();
    },
    beforeDestroy() {
      EventBus.$off('update_card_modal.close');
    },
    methods: {
      fetchBilling() {
        api.getBilling().then((response) => {
          this.billing_contacts = response.billing_contacts;
          this.tier = response.tier;
          this.billing_metadata = response.billing_metadata;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      upgrade() {
        const target = this.$refs.upgradeBtn;
        target.disabled = true;

        api.getBilling().then((response) => {
          this.billing_contacts = response.billing_contacts;
          this.tier = response.tier;
          this.billing_metadata = response.billing_metadata;

          if (this.billing_metadata && this.billing_metadata.card_last_four) {
            api.upgradeAccount({}).then((res) => {
              this.$toasted.success(res.message);
              this.$store.dispatch('refreshUser').then(() => {
                window.location.href = '/settings/billing';
              });
            }).catch((err) => {
              this.$toasted.error(err.message);
            });
          } else {
            this.show_card_modal = true;
          }
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  h1 {
    font-size: 1.25rem;
    line-height: 1.4;
    color: #2e4457;
    margin-bottom: 1.25rem;
  }

  .card + .card {
    margin-top: 1.5rem;
  }

  .card-body h2 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #1d364b;
    margin-bottom: .5rem;
  }

  .card-body p {
    font-size: .875rem;
    line-height: 1.43;
    color: #1d364b;
  }

  .card-body a {
    margin-top: 1.5rem;
  }

  .alert.alert-secondary {
    margin-top: .75rem;
    border: none;
    border-radius: 0;
    background-color: #f3f7f9;
    padding: .75rem;
    font-size: .75rem;
    line-height: 1.67;
    color: #1d364b;
  }

  .card.checkout .card-body {
    font-size: .875rem;
    font-style: italic;
    line-height: 1.43;
    color: #8b9bac;
  }

  .card.checkout .card-body .row {
    margin-bottom: 1rem;
  }

  .card.checkout .card-body .row:last-child {
    margin-bottom: 1.5rem;
  }

  .card.checkout .card-body .row:first-child {
    color: #000;
    font-style: normal;
  }

  .card.checkout .card-body:last-child {
    border-top: 2px solid var(--xpl-color-gray-400);
  }
</style>
