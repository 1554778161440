<template>
  <div class="documents">
    <el-card v-loading="loading">
      <div class="display-small" style="margin-bottom: 2rem;">Contact Documents</div>

      <el-upload :multiple="false" :limit="1" accept="application/pdf" action="" :http-request="uploadFile" :on-change="handleChange" :file-list="fileList">
          <el-button type="primary" class="xpl-button xpl-button--primary":loading="loading">Upload a PDF</el-button>
        </el-upload>

      <el-table :data="documents.data" style="margin-top: 1rem;">
        <el-table-column prop="type" label="Type" width="100">
          <template slot-scope="scope"><span class="text-capitalize">{{ scope.row.type }}</span></template>
        </el-table-column>
        <el-table-column prop="name" label="Document Name"></el-table-column>
        <el-table-column prop="created_at" label="Created At" width="200">
          <template slot-scope="scope">{{ scope.row.created_at | humanDateTime }}</template>
        </el-table-column>
        <el-table-column label="Actions" align="right" width="200">
          <template slot-scope="scope">
            <el-button size="small" @click.stop="openContactDoc(scope.row)">Open</el-button>
            <el-button size="small" type="danger" @click.stop="deleteContactDoc(scope.row)">Delete</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-center">
        <el-pagination @current-change="currentChange" @nextPage="nextPage" @prevPage="prevPage" layout="total, prev, pager, next" :total="documents.total"></el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
  import {
    getContactDocuments,
    openContactDocument,
    deleteContactDocument,
    uploadContactDocument } from '@/api';

  export default {
    props: ['contactId'],
    data() {
      return {
        loading: false,
        documents: [],
        fileList: [],
      };
    },
    created() {
      this.fetchContactDocuments();
    },
    methods: {
      handleChange() {
        this.fileList = [];
      },
      uploadFile(payload) {
        const formData = new FormData();
        formData.append('pdf_file', payload.file, payload.file.name);
        formData.append('type', 'pdf');
        formData.append('contact_id', this.contactId);

        this.loading = true;
        uploadContactDocument(formData).then((response) => {
          this.$message.success(response.message);
          this.loading = false;
          this.fetchContactDocuments();
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      currentChange(p) {
        this.fetchContactDocuments(p);
      },
      nextPage(p) {
        this.fetchContactDocuments(p);
      },
      prevPage(p) {
        this.fetchContactDocuments(p);
      },
      fetchContactDocuments(page = 0) {
        getContactDocuments(this.contactId, page).then((response) => {
          this.documents = response.documents;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      openContactDoc(row) {
        this.$confirm('We will be opening this file in a secure window. The link will expire in 15 minutes. Proceed?')
        .then(() => {
          this.loading = true;
          const payload = {
            contact_id: this.contactId,
            document_id: row.id,
          };
          openContactDocument(payload).then((response) => {
            this.loading = false;
            this.$message.success('Opening document in new tab...');
            window.open(response.url);
          }).catch((err) => {
            this.loading = false;
            this.$message.error(err.message);
          });
        });
      },
      deleteContactDoc(row) {
        this.$confirm('Are you sure you want to delete this document?').then(() => {
          this.loading = true;

          deleteContactDocument(this.contactId, row.id).then((response) => {
            this.loading = false;
            this.$message.success(response.message);
            this.fetchContactDocuments();
          }).catch((err) => {
            this.loading = false;
            this.$message.error(err.message);
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .documents {
    padding: 1.5rem;
    overflow-y: scroll;
  }
</style>
