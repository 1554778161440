<template>
  <transition name="fade">
    <div class="modal show" style="display: flex; align-items: center; justify-content: center;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="left"></div>
            <div class="right">
              <a href="/calendar" class="cancel" v-if="false">Cancel</a>
              <div>
                <div class="heading">Make your emails stick out!</div>
                <h1>Before you start designing, let’s quickly set up your brand.</h1>
                <div class="description">All of our templates and email sections will automatically style to your brand. Take 2 minutes and set up your brand!</div>
                <div class="helper">This should only take 1-2 minutes!</div>
                <button type="button" class="xpl-button xpl-button--primary" @click.stop="toggle">Lets get the brand back together!</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    methods: {
      toggle() {
        EventBus.$emit('quick_brand');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal-dialog {
    max-width: none;
    max-height: none;
    height: 32rem;
    width: 52rem;
  }

  .modal-body {
    padding: 0;
    display: flex;
    height: 32rem;
    width: 52rem;
  }

  .cancel {
    font-size: .75rem;
    color: #8b9bac;
  }

  .left {
    background-color: var(--xpl-color-secondary-bg-lm);
    height: 100%;
    flex: 35%;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-left: 2.5rem;
    padding-right: 3.75rem;
    height: 100%;
    flex: 65%;
  }

  .right .heading {
    font-size: .875rem;
    color: #617080;
    margin-bottom: 1.875rem;
  }

  .right h1 {
    font-size: 1.625rem;
    color: #00152a;
    margin-bottom: 1.25rem;
  }

  .right .description {
    font-size: .875rem;
    color: #00152a;
    margin-bottom: 1.875rem;
  }

  .right .helper {
    font-style: italic;
    font-size: .875rem;
    color: #617080;
    margin-bottom: 1.25rem;
  }
</style>
