<template>
  <div class="row">
    <span class="title">{{ step.name }}</span>
    <div v-if="selected_option === 'any'">Any</div>
    <div v-else>
      <div v-if="selected_option === 'exclude'">Any except: </div>
      <div v-for="location in selected_value" :key="location.id">
        <div class="disable-caps">{{ location.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiSelectSummary',
  props: ['step'],
  computed: {
    json() { return JSON.stringify(this.step.default); },
    selected_option() {
      return this.step.default ? this.step.default.option : this.step.selected_option;
    },
    selected_value() {
      return this.step.default ? this.step.default.value : this.step.selected_value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "summary_item.scss";
</style>
