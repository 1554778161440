<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Move to List Group
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <label>Select the List Group you would like to move this list to</label>
              <Multiselect v-model="list_group" :options="list_groups" placeholder="Search List Groups" track-by="id" label="name" :searchable="true" :show-labels="false" :multiple="false" />
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="submit" ref="submit" class="xpl-button xpl-button--primary" @click.stop="save" :disabled="!list_group">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['contactListId'],
    components: {
      Multiselect,
    },
    data() {
      return {
        list_group: null,
        list_groups: [],
      };
    },
    created() {
      this.fetchListGroups();
    },
    methods: {
      fetchListGroups() {
        api.getListGroups().then((response) => {
          this.list_groups = response.list_groups;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      closeModal() {
        EventBus.$emit('move_to_group.close');
      },
      save() {
        if (!this.list_group) {
          return;
        }

        const payload = {
          contact_list_id: this.contactListId,
          tag_id: this.list_group.id,
        };
        api.linkListToGroup(payload).then((response) => {
          this.$toasted.success(response.message);
          this.closeModal();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
  .modal .modal-content .modal-body {
    overflow-y: visible;
  }
</style>
