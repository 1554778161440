<template>
  <div v-if="email">
    <div :class="{ header: true, 'footer-editing': editingFooter }">
      <div v-if="!editingFooter">
        {{ scheduledEmail ? scheduledEmail.name : campaignItem.name }}
        <small class="updated" v-if="email">
          <span v-if="!saving">Last updated {{ email.updated_at | dateTime }}</span>
          <i class="fa fa-spin fa-spinner fa-fw" v-if="saving"></i>
        </small>
      </div>
      <div v-if="editingFooter">
        Email Footer Editor
      </div>
      <div v-if="!editingFooter" class="header-right">
        <a href="#brand-builder" @click.stop="openCompanyBrandModal"
           v-if="!$store.state.user.current_account.brand_restriction && ((scheduledEmail && scheduledEmail.type === 'full') || (campaignItem && campaignItem.type === 'email'))">Company
          Brand</a>

        <div class="dropdown" v-if="scheduledEmail && !campaignItem">
          <button type="button" class="xpl-button xpl-button--primary" @click="exit_menu = !exit_menu">Save and Exit
          </button>
          <div :class="{ 'dropdown-menu': true, 'dropdown-menu-right': true, show: exit_menu }">
            <a href="#" class="dropdown-item" @click.prevent="closeEdit">Save in progress</a>
            <el-tooltip :disabled="this.saveValidator" effect="light" content="Please add a subject line & recipients"
                        placement="bottom-start">
              <el-button type="button" class="dropdown-item second-drop-down-save"
                         :class="{ 'is-disabled': !this.saveValidator }" @click.prevent="closeEditAndQueue"><span>Save as ready to be sent</span>
              </el-button>
            </el-tooltip>
          </div>
        </div>
        <button type="button" class="xpl-button xpl-button--primary" v-if="campaignItem && !scheduledEmail"
                @click.prevent="closeEdit">Save and Exit
        </button>
      </div>
      <div v-if="editingFooter">
        <button type="button" class="xpl-button xpl-button--primary" @click.prevent="closeFooterEdit">Save and Return to
          Email
        </button>
      </div>
    </div>
    <div class="container" v-if="email">
      <div class="sidebar rich" v-if="email.type === 'rich'">
        <div class="small">Available Personalizations</div>
        <div class="personalizations" v-if="$store.state.user.current_account.active_vendors.indexOf('pike13') === -1">
          <div class="personalization">
            First Name
            <a href="#" @click.prevent="copyPersonalization('first_name', 'there')">Copy</a>
          </div>
          <div class="personalization">
            Last Name
            <a href="#" @click.prevent="copyPersonalization('last_name', 'there')">Copy</a>
          </div>
          <div class="personalization">
            Month to date visits
            <a href="#" @click.prevent="copyPersonalization('visits_mtd')">Copy</a>
          </div>
          <div class="personalization">
            Last Instructor
            <a href="#" @click.prevent="copyPersonalization('last_instructor')">Copy</a>
          </div>
          <div class="personalization">
            Last visit date (e.g. May 7th)
            <a href="#" @click.prevent="copyPersonalization('last_visit_at')">Copy</a>
          </div>
        </div>
        <div class="personalizations" v-if="$store.state.user.current_account.active_vendors.indexOf('pike13') > -1">
          <div class="personalization">
            First Name
            <a href="#" @click.prevent="copyPersonalization('first_name', 'there')">Copy</a>
          </div>
          <div class="personalization">
            Last Name
            <a href="#" @click.prevent="copyPersonalization('last_name', 'there')">Copy</a>
          </div>
        </div>
      </div>

      <div class="sidebar full-email" v-if="email.type === 'full'">
        <div class="building-blocks"
             v-if="(currentElement === null && currentSection === null && !confirmEditingFooter) || (editingFooter && currentElement === null && currentSection === null)">
          <a href="#" class="block" @click.prevent="currentSection = section; currentElement = null;"
             v-for="section in email_sections">
            <img :src="'https://assets.brandbot.com/public/icons/blocks/' + section.name.replace(/ /g, '+') + '.svg'"/>
            {{ section.name }}
            <span class="icon icon-arrow-right3"></span>
          </a>
        </div>

        <ConfirmEditingFooter v-if="confirmEditingFooter && !editingFooter"/>
        <SelectionBlock :currentSection="currentSection" :editingFooter="editingFooter" v-if="currentSection"/>
        <EditBlock :currentElement="currentElement" v-if="currentElement"/>
      </div>

      <div class="content">
        <div class="card" v-if="email && !edit_details">
          <div class="card-header card-header-secondary card-header-merged">
            <div>
              Email Details
            </div>
            <div>
              <button type="button" class="xpl-button xpl-button--primary" @click="toggleEditDetails">Edit Details
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-2 label">Subject Line</div>
              <div class="col-md-10">
                <div>{{ email.subject || '&nbsp;' }}</div>
                <div class="text-muted">{{ email.pre_header }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2 label">From Info</div>
              <div class="col-md-10">
                {{ email.from_name }}
                <span class="text-muted">({{ email.from_address }})</span>
              </div>
            </div>
            <div class="row" v-if="email.recipients_lists && email.exclusions_lists && !campaignItem">
              <div class="col-md-2 label">Recipients</div>
              <div class="col-md-10">
                <div>
                  <span v-for="list in email.recipients_lists" style="margin-right: .5rem;">
                    {{ list.name }} <span class="text-muted" v-if="list.total || list.subscribed">({{
                      list.total || list.subscribed | prettyNumber
                    }})</span>
                  </span>
                </div>
                <div class="text-muted" v-if="email.exclusions_lists.length">
                  Exclude:
                  <span v-for="list in email.exclusions_lists" style="margin-right: .5rem;">
                    {{ list.name }} <span
                    v-if="list.total || list.subscribed">({{ list.total || list.subscribed | prettyNumber }})</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="email && edit_details">
          <div class="card-header card-header-merged">
            Email Details
          </div>
          <div class="card-body">
            <form @submit.prevent>
              <div class="form-group">
                <label for="subject">Subject Line</label>
                <input type="text" id="subject" v-model.trim="email.subject" class="form-control"
                       ref="subjectLine" placeholder="Write a great header here!"/>
              </div>

              <div class="form-group">
                <label for="pre_header">Preview Text</label>
                <input type="text" id="pre_header" v-model.trim="email.pre_header"
                       placeholder="This is the gray preview text next to your subject line. It helps with open rate!"
                       class="form-control"/>
                <div class="small text-muted">This is the preview that shows in your contact’s inbox</div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Sending Address</label>
                    <Multiselect v-model="from_address" placeholder="Select a Verified Email" :options="verified_emails"
                                 label="email" track-by="uuid" :searchable="false" :allow-empty="false"
                                 :show-labels="false"/>
                    <div class="small text-muted">To add verified email addresses go to settings.</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="from_name">From Name</label>
                    <input type="text" id="from_name" v-model.trim="email.from_name" class="form-control"/>
                    <div class="small text-muted">Make this something your contacts will recognize.</div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="replyto_address">Reply-To</label>
                <input type="text" id="replyto_address" v-model.trim="email.replyto_address" class="form-control"/>
              </div>

              <div class="form-group" v-if="!campaignItem">
                <label>Recipients</label>
                <Multiselect v-model="email.recipients_lists" placeholder="Type to search" :options="contact_lists"
                             label="name" track-by="uuid" :allow-empty="true" :multiple="true" :show-labels="false"
                             group-values="data" group-label="group" :custom-label="customLabel">
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{ props.option.name || props.option.$groupLabel }}</span>
                      <span class="option__small pull-right"
                            v-if="props.option.subscribed">{{ props.option.subscribed | prettyNumber }}</span>
                    </div>
                  </template>
                </Multiselect>
                <div class="small text-muted">Contacts that are in multiple lists will only be sent one email</div>
              </div>

              <div class="form-group" v-if="!campaignItem">
                <label>Exclude these lists</label>
                <Multiselect v-model="email.exclusions_lists" placeholder="Type to search" :options="contact_lists"
                             label="name" track-by="uuid" :allow-empty="true" :multiple="true" :show-labels="false"
                             group-values="data" group-label="group" :custom-label="customLabel">
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{ props.option.name || props.option.$groupLabel }}</span>
                      <span class="option__small text-muted pull-right"
                            v-if="props.option.subscribed">{{ props.option.subscribed | prettyNumber }}</span>
                    </div>
                  </template>
                </Multiselect>
                <div class="small text-muted">Contacts in these lists will not receive this email</div>
              </div>

              <div class="form-group">
                <button type="button" class="xpl-button xpl-button--primary" @click="saveDetails">Save Details</button>
              </div>
            </form>
          </div>
        </div>

        <div class="card email-body rich" v-show="email" v-if="email.type === 'rich'">
          <div class="card-header card-header-secondary card-header-merged">
            Email Body
          </div>
          <div class="card-body">
            <form @submit.prevent>
              <textarea ref="body"></textarea>
            </form>
          </div>
        </div>

        <div v-if="!$store.state.user.current_account.brand_restriction">
          <div class="container-bg-edit" v-if="email.type === 'full'"
               @click.stop="show_container_bg_color_picker = !show_container_bg_color_picker" v-cloak>
            <span class="button"><i class="material-icons">format_color_fill</i></span> Change Background Color
          </div>
          <Sketch v-model="container_bg_color" :disableAlpha="true" @input="changeContainerBackgroundColor"
                  v-if="show_container_bg_color_picker"/>
        </div>
        <div class="card email-body" v-if="email.type === 'full'" style="margin-top: 4rem;">
          <div class="card-body">
            <div class="email-preview" v-if="email_preview" v-html="email_preview"></div>
          </div>
        </div>
      </div>
    </div>
    <BrandBuilder v-if="brand_builder"/>
    <LandingPageModal v-if="landing_page" :name="landingPageName"/>
    <AssetBrowserModal v-if="show_asset_browser_modal"/>
  </div>
</template>
<script>
import EventBus from '@/event-bus';
import * as api from '@/api';
import BrandBuilder from '@/components/FullEmailEditor/BrandBuilder/BrandBuilder';
import AssetBrowserModal from '@/components/FullEmailEditor/EditBlocks/AssetBrowserModal';
import LandingPageModal from '@/components/FullEmailEditor/EditBlocks/LandingPageModal';
import _ from 'lodash';
import {Sketch} from 'vue-color';
import {seedUuids, markAsFooterElement} from '@/mjml';
import Multiselect from 'vue-multiselect';
// eslint-disable-next-line
import tinymce from 'tinymce';
import 'tinymce/themes/modern';
import 'tinymce/plugins/link';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/lists';
import EditBlock from './EditBlock';
import SelectionBlock from './SelectionBlock';
import ConfirmEditingFooter from './ConfirmEditingFooter';

export default {
  props: ['scheduledEmail', 'campaignItem', 'emailId'],
  components: {
    Sketch,
    Multiselect,
    EditBlock,
    SelectionBlock,
    ConfirmEditingFooter,
    BrandBuilder,
    LandingPageModal,
    AssetBrowserModal,
  },
  data() {
    return {
      show_asset_browser_modal: false,
      landing_page: false,
      brand_builder: false,
      show_container_bg_color_picker: false,
      container_bg_color: {
        hex: null,
      },
      exit_menu: false,
      saving: false,
      verified_emails: [],
      from_address: null,
      contact_lists: [],
      recipients_list: [],
      exclusions_list: [],
      body: null,
      edit_details: false,
      email_preview: null,
      currentElement: null,
      currentSection: null,
      email_sections: [],
      save_interval: null,
      confirmEditingFooter: false,
      editingFooter: false,
      email: null,
    };
  },
  mounted() {
    api.getEmail(this.emailId).then((response) => {
      this.email = response.email;
      this.fetchVerifiedEmails();
      if (this.email.type === 'rich') {
        this.$nextTick(() => {
          this.initTinyMCE();
          if (this.email.subject === "")
          this.$refs.subjectLine.focus();
        });
      } else {
        this.refreshPreview();
      }
    }).catch((err) => {
      this.$toasted.error(err.message);
    });

    api.getListsForEmails().then((response) => {
      // filter null items
      this.contact_lists = response.lists?.map(item => {
        item.data = item.data.filter(x => x !== null);
        return item;
      });
    }).catch((err) => {
      this.$toasted.error(err.message);
    });

    if ((this.scheduledEmail && this.scheduledEmail.type === 'full') ||
      (this.campaignItem && this.campaignItem.email &&
        this.campaignItem.email.type === 'full')) {
      api.getEmailEditorSections().then((response) => {
        this.email_sections = response.sections;
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    }

    EventBus.$on('landing_page.open', () => {
      Array.from(document.getElementsByClassName('mjWrapper-toolbar'), el => el.remove());
      this.landing_page = true;
    });

    EventBus.$on('landing_page.close', () => {
      this.landing_page = false;
    });

    EventBus.$on('asset_browser.open', () => {
      Array.from(document.getElementsByClassName('mjWrapper-toolbar'), el => el.remove());
      this.show_asset_browser_modal = true;
    });

    EventBus.$on('asset_browser.close', () => {
      this.show_asset_browser_modal = false;
    });

    EventBus.$on('brand_builder.close', () => {
      this.brand_builder = false;
    });

    EventBus.$on('editor.click.element', (bbUuid) => {
      this.findElement(this.email.body, bbUuid);
    });

    EventBus.$on('preview_refresh', () => {
      this.refreshPreview();
    });

    EventBus.$on('email.edit.attributes', () => {
      this.refreshPreview();
    });

    // We need to add the fonts to the mj-head
    EventBus.$on('email.edit.fonts', (payload) => {
      this.editMjmlHead(payload);
    });

    EventBus.$on('editor.close', () => {
      this.currentElement = null;
      Array.from(document.getElementsByClassName('mjWrapper-toolbar'), el => el.remove());
      if (this.editingFooter) {
        this.saveFooter(false);
      } else {
        this.saveBody();
      }
    });

    EventBus.$on('selection_block.close', () => {
      this.currentSection = null;
    });

    EventBus.$on('editor.drop.element', (position, siblingUuid, payload) => {
      if (!this.editingFooter) {
        this.insertElement(position, siblingUuid, seedUuids(payload));
      } else {
        this.insertElement(position, siblingUuid, markAsFooterElement(seedUuids(payload)));
      }
    });

    EventBus.$on('editor.footer.edit.confirm', () => {
      Array.from(document.getElementsByClassName('bb-selected'), el => el.classList.remove('bb-selected'));

      this.confirmEditingFooter = true;
      this.editingFooter = true;
    });
  },
  created() {
    if ((this.scheduledEmail && this.scheduledEmail.type === 'full') ||
      (this.campaignItem && this.campaignItem.email &&
        this.campaignItem.email.type === 'full')) {
      document.addEventListener('click', this.handleClickEvents, false);
    }
    
  },
  updated() {
    if ((this.scheduledEmail && this.scheduledEmail.type === 'full') ||
      (this.campaignItem && this.campaignItem.email &&
        this.campaignItem.email.type === 'full')) {
      const editableElements = document.getElementsByClassName('bb-edit');
      if (editableElements.length) {
        Array.from(editableElements, el => el.addEventListener('click', this.onBBEditClick, false));
      }

      if (this.editingFooter) {
        Array.from(document.querySelectorAll('div.mj-wrapper:not(.bb-footer)'), (el) => {
          el.style.opacity = '.1';
          return true;
        });
      } else {
        Array.from(document.querySelectorAll('div.mj-wrapper:not(.bb-footer)'), (el) => {
          el.style.opacity = '1';
          return true;
        });
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.save_interval);
    EventBus.$off('brand_builder.close');
    EventBus.$off('landing_page.open');
    EventBus.$off('landing_page.close');
    EventBus.$off('editor.click.element');
    EventBus.$off('editor.close');
    EventBus.$off('selection_block.close');
    EventBus.$off('preview_refresh');
    EventBus.$off('email.edit.attributes');
    EventBus.$off('email.edit.fonts');
    EventBus.$off('editor.drop.element');
    EventBus.$off('editor.footer.edit.confirm');
    EventBus.$off('asset_browser.open');
    EventBus.$off('asset_browser.close');

    if ((this.scheduledEmail && this.scheduledEmail.type === 'full') ||
      (this.campaignItem && this.campaignItem.email &&
        this.campaignItem.email.type === 'full')) {
      Array.from(document.getElementsByClassName('mjWrapper-toolbar'), el => el.remove());
      document.removeEventListener('click', this.handleClickEvents, false);
      document.removeEventListener('click', this.onBBEditClick, false);
    }
  },
  computed: {
    saveValidator() {
      if (this.email.subject === '' || this.email.recipients_lists.length === 0) {
        return false;
      }
      return true;
    },
    landingPageName() {
      if (this.campaignItem) {
        return this.campaignItem.name;
      }

      return this.scheduledEmail.name;
    },
  },
  watch: {
    email(newEmail) {
      if (newEmail.subject === '' && newEmail.recipients_lists.length === 0) {
        this.edit_details = true;
      }
    },
  },
  methods: {
    toggleEditDetails() {
      this.edit_details = true;
      Array.from(document.getElementsByClassName('mjWrapper-toolbar'), el => el.remove());
    },
    openCompanyBrandModal() {
      this.brand_builder = !this.brand_builder;
    },
    onBBEditClick(event) {
      event.stopPropagation();
      event.preventDefault();
      const target = event.target || event.srcElement;
      const intendedTarget = target.closest('.bb-edit');
      const closestMjWrapper = intendedTarget.closest('.mj-wrapper');
      Array.from(document.getElementsByClassName('mjWrapper-toolbar'), el => el.remove());

      if (closestMjWrapper.classList.contains('bb-footer') !== this.editingFooter) {
        this.currentElement = null;
        this.currentSection = null;
        this.confirmEditingFooter = true;
        return;
      }

      const existingElements = Array.from(document.getElementsByClassName('bb-selected'));
      existingElements.forEach(el => el.classList.remove('bb-selected'));

      intendedTarget.classList.add('bb-selected');
      closestMjWrapper.classList.add('bb-selected');

      const classes = intendedTarget.classList;
      const bbUuid = Array.from(classes).filter(item => item.indexOf('bb-uuid-') > -1)[0];

      // Are we allowed to remove or duplicate?
      let locked = false;
      if (Array.from(classes).filter(c => c.indexOf('bb-do-not-find') > -1).length) {
        locked = true;
      }

      EventBus.$emit('editor.click.element', bbUuid);

      // Show the toolbar for the mj-wrapper
      // We clean first
      Array.from(document.getElementsByClassName('mjWrapper-toolbar'), el => el.remove());

      const bodyRect = document.body.getBoundingClientRect();
      const elemRect = closestMjWrapper.getBoundingClientRect();
      const offsetTop = elemRect.top - bodyRect.top;
      const offsetLeft = elemRect.left - bodyRect.left;
      const tooltip = document.createElement('div');
      tooltip.classList.add('mjWrapper-toolbar');
      if (!locked) {
        tooltip.innerHTML = '<i class="material-icons copy">content_copy</i><i class="material-icons move-up">keyboard_arrow_up</i><i class="material-icons move-down">keyboard_arrow_down</i><i class="material-icons bg-color">format_color_fill</i><i class="material-icons delete">delete_forever</i>';
      } else {
        tooltip.innerHTML = '<i class="material-icons move-up">keyboard_arrow_up</i><i class="material-icons move-down">keyboard_arrow_down</i><i class="material-icons bg-color">format_color_fill</i>';
      }
      tooltip.style.top = `calc(${offsetTop}px - 2rem)`;
      tooltip.style.left = `${offsetLeft}px`;
      document.body.append(tooltip);
    },
    toggleSidebarTab(tab) {
      this.sidebar_tabs.build = false;
      this.sidebar_tabs.style = false;

      this.sidebar_tabs[tab] = true;
    },
    editMjmlHead(payload) {
      const headChildren = this.email.body.children[0].children;

      if (payload.type === 'fonts') {
        const font = {
          tagName: 'mj-font',
          attributes: {
            name: payload.name,
            href: payload.url,
          },
        };

        // Let's make sure we don't add it twice...
        const exists = headChildren.filter(c => c.tagName === 'mj-font' && c.attributes.name === payload.name).length;
        if (!exists) {
          headChildren.push(font);
        }
      }

      this.refreshPreview();
    },
    findElement(tree, query) {
      if (!this.editingFooter) {
        this.confirmEditingFooter = false;
      }

      this.currentElement = null;
      this.currentSection = null;

      const bag = [];

      function findElementInner(t, q) {
        Object.keys(t).forEach((key) => {
          if (t[key] !== null && typeof t[key] === 'object') {
            if (Object.prototype.hasOwnProperty.call(t[key], 'attributes') &&
              Object.prototype.hasOwnProperty.call(t[key].attributes, 'css-class') &&
              t[key].attributes['css-class'].indexOf(q) > -1) {
              bag.push(t[key]);
            } else {
              findElementInner(t[key], q);
            }
          }
        });
      }

      findElementInner(tree, query);

      if (bag.length) {
        this.currentElement = bag[0];
      }
    },
    handleClickEvents(event) {
      if (event.target && event.target.classList.contains('delete')) {
        event.preventDefault();
        Array.from(document.getElementsByClassName('mjWrapper-toolbar'), el => el.remove());
        this.deleteElement();
      }

      if (event.target && event.target.classList.contains('move-up')) {
        event.preventDefault();
        Array.from(document.getElementsByClassName('mjWrapper-toolbar'), el => el.remove());
        this.moveElementUp();
      }

      if (event.target && event.target.classList.contains('move-down')) {
        event.preventDefault();
        Array.from(document.getElementsByClassName('mjWrapper-toolbar'), el => el.remove());
        this.moveElementDown();
      }

      if (event.target && event.target.classList.contains('copy')) {
        event.preventDefault();
        Array.from(document.getElementsByClassName('mjWrapper-toolbar'), el => el.remove());
        this.copyElement();
      }

      if (event.target && event.target.classList.contains('bg-color')) {
        event.preventDefault();
        Array.from(document.getElementsByClassName('mjWrapper-toolbar'), el => el.remove());
        this.changeBackgroundColor();
      }

      if (event.target && event.target.classList.contains('bb-footer')) {
        event.preventDefault();
        Array.from(document.getElementsByClassName('mjWrapper-toolbar'), el => el.remove());
        this.currentElement = null;
        this.currentSection = null;
        this.confirmEditingFooter = true;
      }

      // if (event.target && event.target.tagName === 'A') {
      //   event.preventDefault();
      // }

      return true;
    },
    deleteElement() {
      // eslint-disable-next-line
      if (!confirm('Are you sure you want to delete this section? This is not reversible.')) {
        return;
      }

      const activeMjWrapper = document.getElementsByClassName('mj-wrapper bb-selected')[0];
      const activeMjWrapperUuid = Array.from(activeMjWrapper.classList).filter(cl => cl.indexOf('bb-uuid') > -1)[0];

      function deleteElementInner(t, q) {
        Object.keys(t).forEach((key) => {
          if (t[key] !== null && typeof t[key] === 'object') {
            if (Object.prototype.hasOwnProperty.call(t[key], 'attributes') &&
              Object.prototype.hasOwnProperty.call(t[key].attributes, 'css-class') &&
              t[key].attributes['css-class'].indexOf(q) > -1) {
              t.splice(key, 1);
            } else {
              deleteElementInner(t[key], q);
            }
          }
        });

        return t;
      }

      this.currentElement = null;
      this.$set(this.email, 'body', deleteElementInner(this.email.body, activeMjWrapperUuid));

      // Clean toolbars
      Array.from(document.getElementsByClassName('mjWrapper-toolbar'), el => el.remove());

      this.refreshPreview();

      this.$toasted.success('Section deleted');
    },
    moveElementUp() {
      const mainWrapper = this.email.body.children[1].children[0];
      const activeMjWrapper = document.getElementsByClassName('mj-wrapper bb-selected')[0];
      const activeMjWrapperUuid = Array.from(activeMjWrapper.classList).filter(cl => cl.indexOf('bb-uuid') > -1)[0];

      // We only allow moving up in the footer if the element before has a bb-footer class
      // If not, we cancel the event
      // Works also if only element
      mainWrapper.children.forEach((child, index) => {
        if (child.attributes['css-class'].indexOf(activeMjWrapperUuid) > -1) {
          if (activeMjWrapper.classList.contains('bb-footer')) {
            // Footer element
            if (
              mainWrapper.children[index - 1] &&
              mainWrapper.children[index - 1]
                .attributes['css-class']
                .indexOf('bb-footer') > -1) {
              // Move element up
              mainWrapper.children.splice(index - 1 < 0 ? 0 : index - 1,
                0,
                mainWrapper.children.splice(index, 1)[0],
              );

              this.refreshPreview();
            }
          } else {
            // Not in footer
            // Move element up
            mainWrapper.children.splice(index - 1 < 0 ? 0 : index - 1,
              0,
              mainWrapper.children.splice(index, 1)[0],
            );

            this.refreshPreview();
          }
        }
      });
    },
    moveElementDown() {
      const mainWrapper = this.email.body.children[1].children[0];
      const activeMjWrapper = document.getElementsByClassName('mj-wrapper bb-selected')[0];
      const activeMjWrapperUuid = Array.from(activeMjWrapper.classList).filter(cl => cl.indexOf('bb-uuid') > -1)[0];

      let moved;
      mainWrapper.children.forEach((child, index) => {
        if (child.attributes['css-class'].indexOf(activeMjWrapperUuid) > -1) {
          if (activeMjWrapper.classList.contains('bb-footer')) {
            // Move element down
            // We need to keep track of moving
            // because the moving direction is the same as array walk :)
            if (!moved &&
              mainWrapper.children[index + 1] &&
              mainWrapper.children[index + 1]
                .attributes['css-class']
                .indexOf('bb-footer') > -1) {
              mainWrapper.children.splice(index + 1, 0, mainWrapper.children.splice(index, 1)[0]);

              this.refreshPreview();
            }
          } else {
            // eslint-disable-next-line
            if (!moved &&
              mainWrapper.children[index + 1] &&
              mainWrapper.children[index + 1]
                .attributes['css-class']
                .indexOf('bb-footer') === -1) {
              mainWrapper.children.splice(index + 1, 0, mainWrapper.children.splice(index, 1)[0]);

              this.refreshPreview();
            }
          }

          moved = true;
        }
      });
    },
    copyElement() {
      const mainWrapper = this.email.body.children[1].children[0];
      const activeMjWrapper = document.getElementsByClassName('mj-wrapper bb-selected')[0];
      const activeMjWrapperUuid = Array.from(activeMjWrapper.classList).filter(cl => cl.indexOf('bb-uuid') > -1)[0];

      // Find the json object of element to be copied
      let objectToCopy;
      mainWrapper.children.forEach((child) => {
        if (child.attributes['css-class'].indexOf(activeMjWrapperUuid) > -1) {
          if (!objectToCopy) {
            objectToCopy = child;
          }
        }
      });

      this.insertElement('after', activeMjWrapperUuid, seedUuids(JSON.stringify(objectToCopy)));

      this.$toasted.success('Section copied!');
    },
    changeBackgroundColor() {
      const mainWrapper = this.email.body.children[1].children[0];
      const activeMjWrapper = document.getElementsByClassName('mj-wrapper bb-selected')[0];
      const activeMjWrapperUuid = Array.from(activeMjWrapper.classList).filter(cl => cl.indexOf('bb-uuid') > -1)[0];

      let mjWrapperObject;
      mainWrapper.children.forEach((child) => {
        if (child.attributes['css-class'].indexOf(activeMjWrapperUuid) > -1) {
          if (!mjWrapperObject) {
            mjWrapperObject = child;
          }
        }
      });

      this.currentElement = mjWrapperObject;
    },
    changeContainerBackgroundColor: _.debounce(function customFunction(data) {
        const containerElement = this.email.body.children[1].children[0];
        containerElement.attributes['background-color'] = data.hex;

        this.refreshPreview();
        this.show_container_bg_color_picker = false;
      }, 300,
    ),
    insertElement(position, siblingUuid, element) {
      const mainWrapper = this.email.body.children[1].children[0];

      let inserted;
      mainWrapper.children.forEach((child, index) => {
        if (child.attributes['css-class'].indexOf(siblingUuid) > -1) {
          // Insert at found position of mj-wrapper, before or after depending on what user intends
          if (!inserted) {
            mainWrapper.children.splice(position === 'before' ? index : index + 1, 0, element);
          }
          inserted = true;
        }
      });

      this.refreshPreview();
    },
    refreshPreview() {
      if (this.email.body) {
        Object.keys(this.email.body).forEach((key) => {
          if (this.email.body[key] == null) {
            delete this.email.body[key];
          }
        });

        const payload = {
          payload: JSON.stringify(this.email.body),
        };

        api.getPreview(payload).then((response) => {
          this.$set(this, 'email_preview', response.html);
        });
      }
    },
    initTinyMCE() {
      window.tinymce.init({
        menubar: false,
        toolbar: 'fontsizeselect | bold italic underline removeformat | link | numlist bullist | forecolor',
        fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt',
        target: this.$refs.body,
        browser_spellcheck: true,
        branding: false,
        theme: 'modern',
        skin_url: '/static/skins/lightgray',
        plugins: ['link', 'autoresize', 'textcolor', 'paste', 'lists'],
        autoresize_bottom_margin: 8,
        paste_as_text: true,
        statusbar: false,
        min_height: 1000,
        init_instance_callback: (editor) => {
          editor.on('KeyUp', () => {
            this.body = editor.getContent();
          });
          editor.on('Change', () => {
            this.body = editor.getContent();
          });

          this.body = this.email.body;
          editor.setContent(this.body);
        },
      });
    },
    fetchVerifiedEmails() {
      api.getVerifiedEmails().then((response) => {
        this.verified_emails = response.emails;
        this.from_address = response.emails.filter(e => e.email === this.email.from_address)[0];
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    closeEdit() {
      this.saveDetails();
      this.saveBody(true);
    },
    closeEditAndQueue() {
      if (this.scheduledEmail && !this.saveValidator) {
        this.$toasted.error('You cannot schedule an email that has no recipients or subject line.');
        return;
      }

      this.saving = true;
      const payload = {
        pre_header: this.email.pre_header,
      };
      if (this.email.type === 'rich') {
        payload.body = this.body;
      } else {
        // Remove elements with 'bb-footer' from saving with body
        const body = this.email.body;
        body.children[1].children[0].children = body.children[1].children[0]
          .children
          .filter(el => el.attributes['css-class'].indexOf('bb-footer') === -1);

        payload.body = body;
      }

      api.putEmail(this.email.id, payload).then((response) => {
        this.saving = false;
        this.email.updated_at = response.updated_at;

        // Save Details and mark as ready to send
        // Closing happens in next function
        this.saveDetails(null, true);
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    closeFooterEdit() {
      this.saveFooter();
    },
    // eslint-disable-next-line no-unused-vars
    saveDetails(event = null, queue = false) {
      const payload = {
        scheduled: queue,
        subject: this.email.subject,
        pre_header: this.email.pre_header,
        from_name: this.email.from_name,
        from_address: this.from_address?.id,
        replyto_address: this.email.replyto_address,
        recipients_lists: this.email.recipients_lists.length ?
          this.email.recipients_lists : null,
        exclusions_lists: this.email.exclusions_lists.length ?
          this.email.exclusions_lists : null,
      };

      if (queue) {
        payload.scheduled = true;
      }

      api.putEmail(this.email.id, payload).then((response) => {
        this.edit_details = false;
        this.email.from_address = response.payload.from_address;
        this.email.from_name = response.payload.from_name;

        if (queue) {
          EventBus.$emit('scheduled_item.email.edit.close');
        }
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    saveBody(close = false) {
      this.saving = true;
      const payload = {};
      if (this.email.type === 'rich') {
        payload.body = this.body;
      } else {
        // Remove elements with 'bb-footer' from saving with body
        const body = JSON.parse(JSON.stringify(this.email.body));
        body.children[1].children[0].children = body.children[1].children[0].children
          .filter(el => el.attributes['css-class'].indexOf('bb-footer') === -1);

        payload.body = body;
      }

      api.putEmail(this.email.id, payload).then((response) => {
        this.saving = false;
        this.email.updated_at = response.updated_at;

        if (close) {
          this.$toasted.success(response.message);
          EventBus.$emit('scheduled_item.email.edit.close');
        }
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    saveFooter(closeEditFooter = true) {
      // We are actually sending a stringified JSON

      // Let's copy the bag of data to a new const
      const emailBodyChildren = JSON.parse(JSON.stringify(this.email.body));
      const payload = {
        footer: emailBodyChildren.children[1].children[0].children
          .filter(el => el.attributes['css-class'].indexOf('bb-footer') > -1),
      };

      api.saveFooter(payload).then((response) => {
        this.$toasted.success(response.message);
        if (closeEditFooter) {
          this.editingFooter = false;
          this.confirmEditingFooter = false;
          this.currentElement = null;
          this.currentSection = null;
        }
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    copyPersonalization(personalization, fallback = 'Fallback') {
      this.$copyText(`[%${personalization} | ${fallback}%]`).then(() => {
        this.$toasted.success('Copied!');
      }, () => {
        this.$toasted.error('Something wrong happened while trying to copy...');
      });
    },
    customLabel({name, subscribed}) {
      if (subscribed !== undefined) {
        return `${name} (${this.$options.filters.prettyNumber(subscribed)})`;
      }

      return `${name}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.vc-sketch {
  position: absolute;
  z-index: 100;
}

.email-preview {
  font-family: initial !important;
  font-size: initial !important;
  font-weight: initial !important;
  color: initial !important;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  color: white;
  background-color: #2E4457;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
}

.header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.header-right a {
  margin-right: 1.5rem;
  font-size: .75rem;
  line-height: 1rem;
  color: white;
}

.header-right .dropdown-menu {
  border-color: transparent;
}

.header-right .dropdown a {
  font-size: .875rem;
  line-height: 1rem;
  color: #1d364b;
  transition: none;
}

.second-drop-down-save {
  font-size: .875rem;
  line-height: 1rem;
  color: #1d364b;
  transition: none;
  border: none;
  margin: 0;
}

.second-drop-down-save span {
  position: relative;
  right: 25px;
  color: #1d364b;
}

.second-drop-down-save.is-disabled {
  opacity: .65;
  border-color: #a3b2bb;
  background: #a3b2bb;
  background: linear-gradient(#a3b2bb) !important;
  border-radius: 0;
  color: #1d364b;

  :hover {
    opacity: .65;
    border-color: #a3b2bb !important;
    color: #1d364b;
    background: #a3b2bb !important;
    background: linear-gradient(#a3b2bb) !important;
  }
}

.header-right .dropdown .dropdown-item:hover {
  background-color: white;
  color: white;
  background: linear-gradient(45deg, #3ab8ff 0%, #00cfff 100%);
}


.header.footer-editing {
  color: rgb(29, 54, 75);
  background-color: var(--xpl-color-secondary-bg-lm);
  border-bottom: none;
}

.header .icon {
  font-size: 2rem;
  color: #afb7c7;
  cursor: pointer;
  margin-right: 1.5rem;
}

.container {
  padding-right: 0;
  padding-left: 0;
  max-width: none;
  padding-top: 3rem;
}

.container .sidebar {
  min-width: 26rem;
  max-width: 26rem;
  width: 26rem;
  background-color: #fff;
  position: fixed;
  z-index: 1;
  right: 0;
  top: 0;
  height: calc(100vh - 3rem);
  overflow-y: scroll;
  margin-top: 3rem;
  border-left: 1px solid #d9e7f0;
}

.container .sidebar.rich {
  padding: 2rem;
}

.container .content {
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: calc(26rem + 2rem);
  /*overflow-y: scroll;*/
  height: calc(100vh - 3rem);
}

.personalizations {
  margin-top: .875rem;
}

.personalization {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: .875rem;
  color: rgb(29, 54, 75);
  line-height: 1.25rem;
  margin-top: 1rem;
}

.personalizations .personalization:first-child {
  margin-top: 0;
}

.personalization a {
  color: rgb(46, 68, 87);
  text-decoration: underline;
}

.personalization a:hover {
  text-decoration: none;
}

.email-body {
  border: none;
}

.email-body .card-body {
  margin-right: -3rem;
  margin-left: -3rem;
  padding: 0;
  min-height: 16rem;
}

.card.email-body.rich {
  margin-top: 2.5rem;
}

.card.email-body.rich .card-body {
  margin: 0;
}

.card-header-secondary a {
  font-size: .75rem;
  font-weight: 700;
  line-height: 1rem;
  color: rgb(29, 54, 75);
  text-decoration: none;
  margin-right: .75rem;
}

small.updated {
  color: #8B9BAC;
  margin-left: 1rem;
}

.card .row {
  line-height: 1.25rem;
  color: rgb(29, 54, 75);
  margin-top: 1rem;
  font-size: .875rem;
}

.card .row:first-child {
  margin-top: 0;
}

.card .label {
  font-weight: 700;
}

.building-blocks {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  height: calc(100vh - 4rem);
  overflow: hidden;
  overflow-y: scroll;
}

.building-blocks .block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  // box-shadow: 0 1px 1px 0 rgba(205, 215, 226, 0.35);
  border: solid 1px rgb(205, 215, 226);
  padding: 1rem;
  margin-bottom: .5rem;
}

.building-blocks .block .icon-arrow-right3 {
  font-size: .75rem;
  margin-left: auto;
  color: #617080;
}

.building-blocks .block:last-child {
  margin-bottom: 0;
}

.building-blocks a.block {
  font-size: .875rem;
  font-weight: 700;
  color: rgb(29, 54, 75);
  text-decoration: none;
  transition: all 150ms ease-in-out;
}

a.block:hover {
  text-decoration: none;
  background-color: var(--xpl-color-background-lm);
  // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px var(--xpl-color-secondary-lm);
}

.building-blocks .block img {
  margin-right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  flex-grow: 0;
}

.container-bg-edit {
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-size: .75rem;
  line-height: 1rem;
  color: rgb(46, 68, 87);
  margin-bottom: 1.5rem;
  margin-top: 2rem;
}

.container-bg-edit .button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 6px;
  background-color: rgb(205, 215, 226);
  margin-right: .5rem;
}

.container-bg-edit .button .material-icons {
  font-size: .875rem;
}

.card:first-child .card-body {
  padding-bottom: 2rem;
}

div.small.text-muted {
  margin-top: .25rem;
}
</style>
