<template>
  <div class="container-fluid" v-if="contact">
    <div class="sidebar">
      <router-link to="/contacts" class="go-back"><i class="fa fa-chevron-left"></i> All Contacts</router-link>
      <div class="card">
        <div class="card-body">
          <h6 v-if="!contact.hide_email">{{ contact.email }}</h6>
          <h6 v-if="contact.hide_email && contact.first_name">{{ contact.first_name }} {{ contact.last_name }}</h6>
          <h6 v-if="contact.hide_email && !contact.first_name">No Email Found</h6>
          <div class="dropdown">
            <button :class="[optionsDropdown ? 'active xpl-button xpl-button--secondary' : 'xpl-button xpl-button--secondary' ]" @click.stop="optionsDropdown = !optionsDropdown" v-on-clickaway.stop="away">Options</button>
            <div :class="['dropdown-menu', optionsDropdown ? 'show' : '']">
              <a class="dropdown-item" href="#" @click.stop="optinEmail" v-loading="loading_optin" v-if="showCommunicationEmailBtn">Send Communication Preferences Email</a>
              <a class="dropdown-item" href="#" v-if="!contact.unsubscribed_at" @click.stop="unsubscribeContact">Unsubscribe from Email</a>
              <a class="dropdown-item" href="#" v-if="contact.brandbot_phone && !contact.unsubscribed_sms_at" @click.stop="unsubscribeContactSMS">Unsubscribe from SMS</a>
              <a class="dropdown-item" v-if="contact.unsubscribed_at" href="#" @click.stop="subscribeContact">Subscribe to Email</a>
              <a class="dropdown-item" v-if="showSubscribeSMS" href="#" @click.stop="subscribeContactSMS">Subscribe to SMS</a>
              <a class="dropdown-item" href="#" @click.prevent="validatePhone" v-if="contact.mobile_phone">Validate Phone Number</a>
              <a class="dropdown-item" href="#" @click="switchTab('settings_tab')" v-if="$store.state.user.current_account.active_vendors.indexOf('pike13') > -1">Settings</a>
              <a href="#" class="dropdown-item text-danger" @click.stop="deleteContact">Delete Contact</a>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header card-header-merged card-header-white">
          Contact Details
          <a href="#" @click.prevent="toggleState" v-if="state === 'view'">Edit</a>
          <button type="button" class="xpl-button xpl-button--primary" @click.prevent="toggleState" v-if="state === 'edit'">Save</button>
        </div>
        <div class="card-body">
          <div class="contact-detail">
            <div class="label">First Name</div>
            <div v-if="state === 'view'">
              <div class="data" v-if="contact.first_name">{{ contact.first_name }}</div>
              <div class="data" v-if="!contact.first_name">&mdash;</div>
            </div>
            <div v-else>
              <input type="text" name="first_name" v-model="contact.first_name" class="form-control" />
            </div>
          </div>
          <div class="contact-detail">
            <div class="label">Last Name</div>
            <div v-if="state === 'view'">
              <div class="data" v-if="contact.last_name">{{ contact.last_name }}</div>
              <div class="data" v-if="!contact.last_name">&mdash;</div>
            </div>
            <div v-else>
              <input type="text" name="last_name" v-model="contact.last_name" class="form-control" />
            </div>
          </div>
          <div class="contact-detail">
            <div class="label">Is Contact Subscribed</div>
            <div class="data pills">
              <span class="pill pill-default" v-if="!contact.unsubscribed_at">Yes to Email</span>
              <span class="pill pill-danger" v-if="contact.unsubscribed_at">No to Email</span>
              <span class="pill pill-default" v-if="contact.brandbot_phone && !contact.unsubscribed_sms_at">Yes to SMS</span>
              <span class="pill pill-danger" v-if="contact.brandbot_phone && contact.unsubscribed_sms_at">No to SMS</span>
            </div>
          </div>
          <div v-if="expand_details">
            <div class="contact-detail">
              <div class="label">Associated Segments</div>
              <div class="data pills" v-if="contact.contact_lists.length">
                <span class="pill pill-default" v-for="list in contact.contact_lists">{{ list.name }}</span>
              </div>
              <div class="data" v-if="!contact.contact_lists.length">-</div>
            </div>
            <div class="contact-detail">
              <div class="label">Phone Number</div>
              <div v-if="state === 'view'" v-loading="loading_phone">
                <div class="data" v-if="contact.mobile_phone">
                  {{ contact.brandbot_phone || contact.mobile_phone }}
                  <span class="small text-success" v-if="contact.brandbot_phone">&nbsp;Valid</span>
                  <span class="small text-danger" v-if="!contact.brandbot_phone">&nbsp;Invalid</span>
                </div>
                <div class="data" v-if="!contact.mobile_phone">No valid phone number</div>
              </div>
              <div v-else>
                <input type="text" name="mobile_phone" v-model="contact.mobile_phone" class="form-control" />
              </div>
            </div>
            <div class="contact-detail">
              <div class="label">Contact Created On</div>
              <div class="data" v-if="contact.created_at">{{ contact.created_at | humanDate }}</div>
              <div class="data" v-if="!contact.created_at">-</div>
            </div>
            <div class="contact-detail">
              <div class="label">Birthdate</div>
              <div v-if="state === 'view'">
                <div class="data" v-if="contact.birthdate">{{ humanBirthdate(contact.birthdate) }}</div>
                <div class="data" v-if="!contact.birthdate">-</div>
              </div>
              <div v-else>
                <!-- put dropdown here please -->
              </div>
            </div>
            <div class="contact-detail">
              <div class="label">Gender</div>
              <div v-if="state === 'view'">
                <div class="data text-capitalize" v-if="contact.gender && contact.gender.value">{{ contact.gender.value === 'other' ? 'Non-Binary' : contact.gender.value }}</div>
                <div class="data" v-if="!contact.gender || !contact.gender.value">-</div>
              </div>
              <div v-else>
                <Multiselect v-model="contact.gender" :options="genders" track-by="value" label="label" :searchable="false" :allow-empty="false" :show-labels="false" />
              </div>
            </div>
            <div class="contact-detail" v-if="contact.address_1">
              <div class="label">Address</div>
              <div class="data">{{ contact.address_1 }}</div>
            </div>
            <div class="contact-detail" v-if="contact.city">
              <div class="label">City</div>
              <div class="data">{{ contact.city }}</div>
            </div>
            <div class="contact-detail" v-if="contact.zipcode">
              <div class="label">Zipcode / Postal Code</div>
              <div class="data">{{ contact.zipcode }}</div>
            </div>
            <div class="contact-detail" v-if="contact.country">
              <div class="label">Country</div>
              <div class="data">{{ contact.country }}</div>
            </div>
          </div>
          <a href="#" class="small" style="color: black;" v-if="expand_details" @click.prevent="expand_details = false">Hide contact details</a>
          <a href="#" class="small" style="color: black;" v-if="!expand_details" @click.prevent="expand_details = true">Show contact details</a>
        </div>
      </div>

      <div class="card">
        <div class="card-header card-header-merged card-header-white">
          Tags
          <a href="/account/tags" target="_blank">Manage tags</a>
        </div>
        <div class="card-body">
          <Multiselect v-model="new_tag" :options="tags" placeholder="Search tags" track-by="id" label="name" :searchable="true" :show-labels="false" :reset-after="true" @select="chooseTag" :taggable="true" tag-placeholder="Press enter to create this tag" @tag="createTag" />
          <div class="data pills" v-if="manualTags(contact.tags).length">
            <span class="pill pill-secondary" v-for="tag in manualTags(contact.tags)">
              {{ tag.name }} <span class="material-icons"  @click.stop="removeTag(tag)">close</span>
            </span>
          </div>
        </div>
      </div>

      <div class="card" v-if="contact.guardian">
        <div class="card-header card-header-merged card-header-white">Guardians</div>
        <div class="card-body" style="padding-top: 0;">
          <div class="data" style="margin-bottom: 0;" v-if="contact.guardian.first_name">{{ contact.guardian.first_name }} {{ contact.guardian.last_name }}</div>
          <a :href="'/contacts/' + contact.guardian.uuid" target="_blank">{{ contact.guardian.email }}</a>
        </div>
      </div>

      <div class="card" v-if="contact.custom_fields && contact.custom_fields.length">
        <div class="card-header card-header-merged card-header-white">Custom Fields</div>
        <div class="card-body">
          <div class="contact-detail" v-for="field in contact.custom_fields">
            <div class="label">{{ field.name }}</div>
            <div class="data">
              {{ field.value }}
            </div>
          </div>
        </div>
      </div>

      <div class="card" v-if="contact.mindbody_client_ids && contact.mindbody_client_ids.length">
        <div class="card-header card-header-merged card-header-white">MINDBODY Details</div>
        <div class="card-body">
          <div class="contact-detail">
            <div class="label">Joined at Date</div>
            <div class="data" v-if="contact.joined_at">{{ contact.joined_at | humanDate(false) }}</div>
            <div class="data" v-if="!contact.joined_at">-</div>
          </div>
          <div class="contact-detail">
            <div class="label">All Time Visits</div>
            <div class="data" v-if="contact.visits_all">{{ contact.visits_all }}</div>
            <div class="data" v-if="!contact.visits_all">-</div>
          </div>
          <div class="contact-detail">
            <div class="label">Last Visit Date</div>
            <div class="data" v-if="contact.last_visit_at && contact.last_visit_at !== '1969-01-01'">{{ contact.last_visit_at | humanDate(false) }}</div>
            <div class="data" v-if="!contact.last_visit_at || contact.last_visit_at === '1969-01-01'">-</div>
          </div>
          <div class="contact-detail">
            <div class="label">Associated Index Values</div>
            <div class="data pills" v-if="indexTags(contact.tags).length">
              <span class="pill pill-secondary" v-for="tag in indexTags(contact.tags)">
                {{ tag.name }} - {{ tag.value }}
              </span>
            </div>
            <div class="data" v-if="!indexTags(contact.tags).length">-</div>
          </div>
          <div class="powered">
            <img src="https://assets.brandbot.com/public/partners/MB-powered-by.svg" class="img-fluid" alt="Powered by MINDBODY" />
          </div>
        </div>
      </div>

      <div class="card mindbody" v-if="contact.mindbody_client_ids && contact.mindbody_client_ids.length">
        <div class="card-header card-header-merged card-header-white">
        Subscription Sync with MINDBODY
        </div>
        <div class="card-body">
          <div class="contact-detail" v-if="contact.source === 'mindbody' && contact.unsubscribed_reason !== 'brandbot'">
            <div>
              <span class="material-icons">autorenew</span>
            </div>
            <div>
              <div class="title">Email Subscription preferences actively synced with MINDBODY</div>
              <div class="label">Last updated {{ contact.updated_at | humanDateTime }}.</div>
            </div>
          </div>
          <div class="contact-detail" v-if="contact.source === 'brandbot' || contact.unsubscribed_reason === 'brandbot'">
            <div>
              <span class="material-icons">sync_disabled</span>
            </div>
            <div>
              <div class="title">Email Subscription preferences not synced with MINDBODY</div>
              <div class="label">Last updated {{ contact.updated_at | humanDateTime }}.</div>
              <div class="label">This contact's subscription preferences were changed through {{$store.getters.brandName}} and therefore we stopped looking at MINDBODY's preferences.</div>
            </div>
          </div>
          <div class="contact-detail" v-if="false && contact.source === 'mindbody' && contact.unsubscribed_reason !== 'brandbot'">
            <div>
              <span class="material-icons">autorenew</span>
            </div>
            <div>
              <div class="title">SMS Subscription preferences actively synced with MINDBODY</div>
              <div class="label">Last updated {{ contact.updated_at | humanDateTime }}.</div>
            </div>
          </div>
          <div class="contact-detail" v-if="false && contact.source === 'brandbot' || contact.unsubscribed_reason === 'brandbot'">
            <div>
              <span class="material-icons">sync_disabled</span>
            </div>
            <div>
              <div class="title">SMS Subscription preferences not synced with MINDBODY</div>
              <div class="label">Last updated {{ contact.updated_at | humanDateTime }}.</div>
              <div class="label">This contact's subscription preferences were changed through {{$store.getters.brandName}} and therefore we stopped looking at MINDBODY's preferences.</div>
            </div>
          </div>
          <div class="contact-detail" v-if="contact.mindbody_client_ids.length > 1">
            <div>
              <span class="material-icons">warning</span>
            </div>
            <div>
              <div class="title">Multiple contact IDs were found for this email address</div>
              <div class="label">The following contact IDs were found with this email address which may cause problems with profile data being synced properly:</div>
              <div class="label" v-for="client_id in contact.mindbody_client_ids">{{ client_id }}</div>
            </div>
          </div>
          <div class="contact-detail" v-if="false && contact.mindbody_client_ids.length">
            <a @click.stop="switchTab('sync_tab')" class="xpl-button xpl-button--secondary" target="_blank">View History</a>
          </div>
          <div class="contact-detail" v-if="contact.mindbody_client_ids.length">
            <a :href="'https://clients.mindbodyonline.com/asp/adm/adm_clt_ph.asp?fcltid=' + contact.mindbody_client_ids[0]" class="xpl-button xpl-button--secondary" target="_blank">Go to MINDBODY Profile</a>
          </div>
        </div>
      </div>
    </div>

    <div class="timeline">
      <div class="header">
        <div class="tabs">
          <a href="#" :class="['tab', timeline_tab ? 'active' : '']" @click.stop="switchTab('timeline_tab')">Timeline</a>
          <a href="#" :class="['tab', sms_tab ? 'active' : '']" v-if="contact.brandbot_phone && $store.state.user.current_account.sms_enabled" @click.stop="switchTab('sms_tab')" style="display: flex; align-items: center;">SMS Chat</a>
          <a href="#" :class="['tab', documents_tab ? 'active' : '']" @click.stop="switchTab('documents_tab')" style="display: flex; align-items: center;">Documents</a>
          <a href="#" :class="['tab', funnel_tab ? 'active' : '']" v-if="$store.state.user.current_account.funnel_enabled" @click.stop="switchTab('funnel_tab')" style="display: flex; align-items: center;">Funnel <span class="pill pill-default text-uppercase pill-sm" style="margin-left: .5rem;">beta</span></a>
        </div>
        <div class="actions">
          <button :class="[send_email ? 'active xpl-button xpl-button--secondary' : 'xpl-button xpl-button--secondary']" @click="send_email = !send_email">Email</button>
          <button :class="[add_note ? 'active xpl-button xpl-button--secondary' : 'xpl-button xpl-button--secondary']" @click="add_note = !add_note">Note</button>
          <button :class="[show_tasks_sidebar ? 'active xpl-button xpl-button--secondary' : 'xpl-button xpl-button--secondary']" @click="associatedContact = contact; show_tasks_sidebar = !show_tasks_sidebar">Task</button>
          <button :class="[show_calls_sidebar ? 'active xpl-button xpl-button--secondary' : 'xpl-button xpl-button--secondary']" @click="associatedContact = contact; show_calls_sidebar = !show_calls_sidebar">Call Reminder</button>
          <button class="xpl-button xpl-button--secondary" @click="show_debug = !show_debug" v-if="$store.getters.isBrandBotAdmin">{{ show_debug ? 'Hide' : 'Show' }} Data</button>
        </div>
      </div>
      <SendEmail :to="contact.email" :to_id="contact.id" v-if="send_email" />
      <div style="padding: 1.5rem;" v-if="sync_tab">
        <SyncHistory/>
      </div>
      <div style="padding: 1.5rem;" v-if="settings_tab">
        <ContactSettings :contactId="contact.id" :childBehavior="contact.child_behavior" />
      </div>
      <div style="padding: 1.5rem;" v-if="funnel_tab">
        <AddToFunnel :contactId="contact.id" />
      </div>
      <div class="timeline-days" v-if="timeline_tab">
        <div class="timeline-day" style="margin-top: 2rem;">
          <div class="card" v-for="item in contact.scheduled" v-if="item.metadata.status === 'scheduled'">
            <div class="card-body">
              <div class="item-header">
                <img src="https://assets.brandbot.com/public/icons/timeline/waiting-on.svg" />
                <div>
                  Scheduled <strong>{{ item.campaign_item_name }}</strong>
                  <div class="small text-muted">
                    {{ item.metadata.scheduled_at | humanDate(false) }} &middot; {{ item.campaign_name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="no-timeline" v-if="!Object.keys(contact.events).length">
          <img src="https://assets.brandbot.com/public/empty-states/contact-timeline.svg" />
          <div>This contact doesn't have any logged activity.</div>
        </div>

        <div class="timeline-day" v-for="(events, day) in timelineEvents">
          <div class="day">{{ day | humanDate(false) }}</div>
          <div :class="['card', item.type === 'campaign' && item.metadata.status === 'created' ? 'hoverable' : '']" v-for="item in events">
            <div class="card-body" v-if="item.vendor === 'marianatek'">
              <div class="item-header" v-if="item.type === 'reservation'">
                <img src="https://assets.brandbot.com/public/icons/timeline/signed-in.svg" v-if="item.status === 'check in'" />
                <img src="https://assets.brandbot.com/public/icons/timeline/reserved.svg" v-if="item.status === 'pending'" />
                <img src="https://assets.brandbot.com/public/icons/timeline/late-cancel-absent.svg" v-if="item.status === 'class cancelled' || item.status === 'graced cancel' || item.status === 'graced no show' || item.status === 'penalty cancel' || item.status === 'penalty no show' || item.status === 'removed' || item.status === 'standard cancel'" />
                <div>
                  Marked {{ item.status }} for <strong>{{ item.name }}</strong>.
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'order'">
                <img src="https://assets.brandbot.com/public/icons/timeline/purchase.svg" />
                <div>
                  Purchased {{ item.title }}.
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'credit_transaction'">
                <img src="https://assets.brandbot.com/public/icons/timeline/purchase.svg" />
                <div>
                  Used a Credit <strong>{{ item.name }}</strong>
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'membership_instance'">
                <img src="https://assets.brandbot.com/public/icons/timeline/joined-membership.svg" />
                <div>
                  Started the Membership <strong>{{ item.name }}</strong>
                </div>
              </div>
            </div>
            <div class="card-body" v-if="item.vendor === 'pike13'">
              <div class="item-header" v-if="item.type === 'visit'">
                <img src="https://assets.brandbot.com/public/icons/timeline/signed-in.svg" v-if="item.state === 'completed'" />
                <img src="https://assets.brandbot.com/public/icons/timeline/reserved.svg" v-if="item.state === 'registered' || item.state === 'waiting' || item.state === 'reserved'" />
                <img src="https://assets.brandbot.com/public/icons/timeline/late-cancel-absent.svg" v-if="item.state === 'late_canceled' || item.state === 'expired' || item.state === 'removed' || item.state === 'noshowed'" />
                <div>
                  Marked {{ item.state | pikeState }}
                  for <strong>{{ item.service_name }}</strong><span v-if="item.instructor_names">&nbsp;with {{ item.instructor_names }}.</span><span v-if="!item.instructor_names">.</span>
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'purchase'">
                <img src="https://assets.brandbot.com/public/icons/timeline/purchase.svg" />
                <div>
                  Purchased product <strong>{{ item.product_name }}</strong>.
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'plan'">
                <img src="https://assets.brandbot.com/public/icons/timeline/joined-membership.svg" />
                <div>
                  Started plan <strong>{{ item.plan_name }}</strong>.
                </div>
              </div>
              <div class="item-arrow" @click="toggleDrawer(item)">
                <span class="material-icons" v-if="!item.drawer">keyboard_arrow_down</span>
                <span class="material-icons" v-if="item.drawer">keyboard_arrow_up</span>
              </div>
            </div>
            <div class="card-body" v-if="!item.hasOwnProperty('vendor') || (item.vendor !== 'pike13' && item.vendor !== 'marianatek')">
              <div class="item-header" v-if="item.type === 'form'">
                <img src="https://assets.brandbot.com/public/icons/timeline/entered-form.svg" />
                <div>
                  Joined through the Sign Up Form <strong>{{ item.form_name }}</strong>.
                  <div class="small text-muted">{{ item.created_at | humanDateTime }}</div>
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'note'">
                <div>
                  <span class="material-icons">assignment</span>
                </div>
                <div style="width: 100%;">
                  <div style="width: 100%; display: flex; justify-content: space-between; align-items: flex-start;">
                    <div>
                      {{ item.note_creator_first_name ? item.note_creator_first_name : item.note_creator_email }} created a note
                      <div class="small text-muted">Created on {{ item.created_at | humanDateTime }}</div>
                    </div>
                    <a href="#" class="text-muted" v-if="!item.edit" @click.stop="toggleEditNote(item)">Edit</a>
                  </div>
                  <div style="margin-top: 1rem;" v-if="!item.edit">
                    {{ item.note }}
                  </div>
                  <form style="margin-top: 1rem;" @submit.prevent v-if="item.edit">
                    <div class="form-group">
                      <textarea class="form-control" v-model="item.note"></textarea>
                    </div>
                    <div class="form-group">
                      <button type="button" class="xpl-button xpl-button--primary" @click.stop="editNote(item)">Save</button>
                      <a href="#" class="text-danger" @click.stop="deleteNote(item)" style="margin-left: .5rem;">Delete</a>
                    </div>
                  </form>
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'email'">
                <span class="material-icons">email</span>
                <div>
                  <span v-if="item.sent_auto_emails_name">{{ item.sent_auto_emails_name }}</span>
                  <span v-if="item.scheduled_email_name">{{ item.scheduled_email_name }}</span>
                  <div class="small text-muted" v-if="item.scheduled_email_name">Scheduled Email</div>
                  <div class="small text-muted" v-if="item.sent_auto_emails_name">Auto-Campaign Email &middot; {{ item.sent_auto_emails_campaigns_name }}</div>
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'one-off'">
                <img src="https://assets.brandbot.com/public/icons/timeline/personal-email.svg" />
                <div>
                  Personal email from {{ item.metadata.sender_email }}
                  <div class="small text-muted">{{ item.created_at | humanDateTime(false) }}</div>
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'visit' && item.metadata && item.metadata.hasOwnProperty('status')">
                <img src="https://assets.brandbot.com/public/icons/timeline/signed-in.svg" v-if="item.metadata.status === 'Signed-In' || item.metadata.status === 'Completed' || item.metadata.status === 'Made-Up'" />
                <img src="https://assets.brandbot.com/public/icons/timeline/reserved.svg" v-if="item.metadata.status === 'Reserved' || item.metadata.status === 'Booked' || item.metadata.status === 'Confirmed'" />
                <img src="https://assets.brandbot.com/public/icons/timeline/late-cancel-absent.svg" v-if="item.metadata.status === 'Absent' || item.metadata.status === 'Late Cancel' || item.metadata.status === 'No Show'" />
                <div>
                  Marked {{ item.metadata.status }}
                  for {{ item.metadata.description }}<span v-if="item.metadata.staff_first_name && item.metadata.staff_last_name">&nbsp;with {{ item.metadata.staff_first_name }} {{ item.metadata.staff_last_name }}.</span><span v-if="!item.metadata.staff_first_name && !item.metadata.staff_last_name">.</span>
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'visit' && item.metadata && item.metadata.hasOwnProperty('state')">
                <img src="https://assets.brandbot.com/public/icons/timeline/signed-in.svg" v-if="item.metadata.state === 'completed'" />
                <img src="https://assets.brandbot.com/public/icons/timeline/reserved.svg" v-if="item.metadata.state === 'registered' || item.metadata.state === 'reserved'" />
                <img src="https://assets.brandbot.com/public/icons/timeline/late-cancel-absent.svg" v-if="item.metadata.state === 'expired' || item.metadata.state === 'late_canceled' || item.metadata.state === 'noshowed'" />
                <div>
                  Marked {{ item.metadata.state }}
                  for {{ item.metadata.serviceName }}<span v-if="item.metadata.instructorNames">&nbsp;with {{ item.metadata.instructorNames }}</span>.
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'purchase' && item.metadata.hasOwnProperty('pricing_option_name')">
                <img src="https://assets.brandbot.com/public/icons/timeline/purchase.svg" />
                Purchased pricing option&nbsp;<strong>{{ item.metadata.pricing_option_name }}</strong>&nbsp;in MINDBODY.
              </div>
              <div class="item-header" v-if="item.type === 'purchase' && item.metadata.hasOwnProperty('productName')">
                <img src="https://assets.brandbot.com/public/icons/timeline/purchase.svg" />
                Purchased product&nbsp;<strong>{{ item.metadata.productName }}</strong>&nbsp;in Pike13.
              </div>
              <div class="item-header" v-if="item.type === 'unsubscribe' && item.metadata.action === 'subscribe'">
                <img src="https://assets.brandbot.com/public/icons/timeline/subscribe-email.svg" />
                <div v-if="item.metadata.reason === 'mindbody'">
                Marked as subscribed to emails in MINDBODY.
                </div>
                <div v-else-if="item.metadata.reason === 'pike13'">
                Marked as subscribed to emails in Pike13.
                </div>
                <div v-else>
                Marked as subscribed to emails in {{$store.getters.brandName}}.
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'unsubscribe' && item.metadata.action === 'unsubscribe'">
                <img src="https://assets.brandbot.com/public/icons/timeline/unsubscribe-email.svg" />
                <div v-if="item.metadata.reason === 'mindbody'">
                Marked as unsubscribed from emails in MINDBODY.
                </div>
                <div v-else-if="item.metadata.reason === 'pike13'">
                Marked as unsubscribed from emails in Pike13.
                </div>
                <div v-else>
                Marked as unsubscribed from emails in {{$store.getters.brandName}}.
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'unsubscribe' && item.metadata.action === 'subscribe-sms'">
                <img src="https://assets.brandbot.com/public/icons/timeline/subscribe-sms.svg" />
                <div v-if="item.metadata.reason === 'mindbody'">
                Marked as subscribed to SMS in MINDBODY.
                </div>
                <div v-else-if="item.metadata.reason === 'pike13'">
                Marked as subscribed to SMS in Pike13.
                </div>
                <div v-else>
                Marked as subscribed to SMS in {{$store.getters.brandName}}.
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'unsubscribe' && item.metadata.action === 'unsubscribe-sms'">
                <img src="https://assets.brandbot.com/public/icons/timeline/unsubscribe-sms.svg" />
                <div v-if="item.metadata.reason === 'mindbody'">
                Marked as unsubscribed from SMS in MINDBODY.
                </div>
                <div v-else-if="item.metadata.reason === 'pike13'">
                Marked as unsubscribed from SMS in Pike13.
                </div>
                <div v-else>
                Marked as unsubscribed from SMS in {{$store.getters.brandName}}.
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'unsubscribe' && typeof(item.metadata.action) === 'undefined'">
                <img src="https://assets.brandbot.com/public/icons/timeline/unsubscribe-email.svg" />
                <div v-if="item.metadata.reason === 'mindbody'">
                Marked as unsubscribed from Email in MINDBODY.
                </div>
                <div v-else-if="item.metadata.reason === 'pike13'">
                Marked as unsubscribed from Email in Pike13.
                </div>
                <div v-else>
                Marked as unsubscribed from Email in {{$store.getters.brandName}}.
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'activation' && item.md_activations_purchase_metadata">
                <img src="https://assets.brandbot.com/public/icons/timeline/purchase-visit.svg" />
                Visited for the first time with a pricing option&nbsp;<strong>{{ item.md_activations_purchase_metadata.pricing_option_name }}</strong>
              </div>
              <div class="item-header" v-if="item.type === 'checkout' && item.metadata.status">
                <img src="https://assets.brandbot.com/public/icons/timeline/landed.svg" v-if="item.metadata.status === 'landed'" />
                <img src="https://assets.brandbot.com/public/icons/timeline/abandon.svg" v-if="item.metadata.status === 'abandon'" />
                <img src="https://assets.brandbot.com/public/icons/timeline/finished.svg" v-if="item.metadata.status === 'finished'" />
                <div v-if="item.metadata.status === 'landed'">
                  Started the Landing Page <a :href="'https://clients.brandbot.com/apps/landing-pages/' + item.checkoutflow_uuid" target="_blank" style="text-decoration: underline;">{{ item.checkoutflow_name }}</a>
                </div>
                <div v-if="item.metadata.status === 'abandon'">
                  Abandoned the Landing Page <a :href="'https://clients.brandbot.com/apps/landing-pages/' + item.checkoutflow_uuid" target="_blank" style="text-decoration: underline;">{{ item.checkoutflow_name }}</a>
                </div>
                <div v-if="item.metadata.status === 'finished'">
                  Purchased from the Landing Page <a :href="'https://clients.brandbot.com/apps/landing-pages/' + item.checkoutflow_uuid" target="_blank" style="text-decoration: underline;">{{ item.checkoutflow_name }}</a>
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'membership' && item.metadata.hasOwnProperty('membership_type')">
                <img src="https://assets.brandbot.com/public/icons/timeline/joined-membership.svg" />
                <div>
                  Assigned a membership
                  <div class="small text-muted">{{ item.metadata.membership_type }}</div>
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'membership' && item.metadata.hasOwnProperty('productName')">
                <img src="https://assets.brandbot.com/public/icons/timeline/joined-membership.svg" />
                <div>
                  Started a plan
                  <div class="small text-muted">{{ item.metadata.productName }}</div>
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'index'">
                <img src="https://assets.brandbot.com/public/icons/timeline/client-index.svg" />
                <div>
                  Assigned a MINDBODY Index
                  <div class="small text-muted">{{ item.metadata.assigned_at | humanDateTime }} &middot; {{ item.index_value }}</div>
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'campaign' && item.metadata.status === 'not-sent-no-phone'">
                <div>
                  Was not sent the scheduled SMS because there is no valid phone number on file.
                  <div class="small text-muted">{{ item.created_at | humanDate }} &middot; {{ item.campaign_name }}</div>
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'sms'&& item.metadata.type === 'auto'">
                <div>
                  <span class="material-icons">sms</span>
                </div>
                <div>
                  Sent an Automated SMS from an Auto-Campaign
                  <div class="small text-muted">{{ item.created_at | humanDate }} &middot; {{ item.sent_auto_emails_campaigns_name }}</div>
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'remail'">
                <img src="https://assets.brandbot.com/public/icons/timeline/guardian-email.svg" />
                <div v-if="item.metadata.guardian_uuid">
                  <span v-if="item.campaign_item_name && item.metadata.copy">
                    A copy of the Auto-Email <strong>{{ item.campaign_item_name }}</strong> was sent to the guardian <a :href="'/contacts/' + item.metadata.guardian_uuid">{{ item.metadata.guardian_first_name }}</a>
                  </span>
                  <span v-if="item.campaign_item_name && !item.metadata.copy">
                    The Auto-Email <strong>{{ item.campaign_item_name }}</strong> was sent to the guardian <a :href="'/contacts/' + item.metadata.guardian_uuid">{{ item.metadata.guardian_first_name }}</a> instead
                  </span>
                  <span v-if="item.scheduled_email_name && item.metadata.copy">
                    A copy of the Scheduled Email <strong>{{ item.scheduled_email_name }}</strong> was sent to the guardian <a :href="'/contacts/' + item.metadata.guardian_uuid">{{ item.metadata.guardian_first_name }}</a>
                  </span>
                  <span v-if="item.scheduled_email_name && !item.metadata.copy">
                    The Scheduled Email <strong>{{ item.scheduled_email_name }}</strong> was sent to the guardian <a :href="'/contacts/' + item.metadata.guardian_uuid">{{ item.metadata.guardian_first_name }}</a> instead
                  </span>
                  <div class="small text-muted" v-if="item.campaign_item_name">Auto-Campaign Email &middot; {{ item.campaign_item_name }}</div>
                  <div class="small text-muted" v-if="item.scheduled_email_name">Scheduled Email &middot; {{ item.scheduled_email_name }}</div>
                </div>
                <div v-if="item.metadata.child_email">
                  <span v-if="item.campaign_item_name && item.metadata.copy">
                    A copy of the Auto-Email <strong>{{ item.campaign_item_name }}</strong> was sent to this account from dependent <a :href="'/contacts/' + item.metadata.child_uuid">{{ item.metadata.child_first_name }}</a>
                  </span>
                  <span v-if="item.campaign_item_name && !item.metadata.copy">
                    The Auto-Email <strong>{{ item.campaign_item_name }}</strong> was sent to this account instead of sending it to <a :href="'/contacts/' + item.metadata.child_uuid">{{ item.metadata.child_first_name }}</a>
                  </span>
                  <span v-if="item.scheduled_email_name && item.metadata.copy">
                    A copy of the Scheduled Email <strong>{{ item.scheduled_email_name }}</strong> was sent to this account from dependent <a :href="'/contacts/' + item.metadata.child_uuid">{{ item.metadata.child_first_name }}</a>
                  </span>
                  <span v-if="item.scheduled_email_name && !item.metadata.copy">
                    The Scheduled Email <strong>{{ item.scheduled_email_name }}</strong> was sent to this account instead of sending it to <a :href="'/contacts/' + item.metadata.child_uuid">{{ item.metadata.child_first_name }}</a>
                  </span>
                  <div class="small text-muted" v-if="item.campaign_item_name">Auto-Campaign Email &middot; {{ item.campaign_item_name }}</div>
                  <div class="small text-muted" v-if="item.scheduled_email_name">Scheduled Email &middot; {{ item.scheduled_email_name }}</div>
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'campaign' && item.metadata.status === 'hit-goal'">
                <img src="https://assets.brandbot.com/public/icons/timeline/goal.svg" />
                <div>
                  Hit Goal <strong>{{ item.metadata.goal_name }}</strong>
                  <div class="small text-muted">{{ item.campaign_name }}</div>
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'webhook'">
                <img src="https://assets.brandbot.com/public/icons/timeline/entered-form.svg" />
                <div>
                  Event Name <strong>{{ item.metadata.event_name }}</strong>
                </div>
              </div>
              <div class="item-header" v-if="item.type === 'campaign' && (item.metadata.status === 'enrollment' || item.metadata.status === 'exit')">
                <img src="https://assets.brandbot.com/public/icons/timeline/enter-campaign.svg" v-if="item.metadata.status === 'enrollment'" />
                <img src="https://assets.brandbot.com/public/icons/timeline/exit-campaign.svg" v-if="item.metadata.status === 'exit'" />
                <div v-if="item.metadata.status === 'enrollment'">
                  <span>Enrolled in campaign</span>
                  <div class="small text-muted">{{ item.metadata.enrolled_at | humanDate }} &middot; {{ item.campaign_name }}</div>
                </div>
                <span v-if="item.metadata.status === 'exit'">Exited the campaign "{{ item.campaign_name }}" on {{ item.metadata.exited_at | humanDateTime }} through the exit settings.</span>
              </div>
              <div class="item-header task" v-if="item.type === 'scheduled_task'" @click="toggleSidebar(null,item.scheduled_task_id,item.task_phone_call === 1 ? 'phone_call' : 'task')">
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/empty.svg" v-if="item.scheduled_task_complete === 0" />
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/complete.svg" v-if="item.scheduled_task_complete === 1" />
                <span class="material-icons" v-if="item.task_phone_call === 0">assignment</span>
                <span class="material-icons" v-if="item.task_phone_call === 1">phone</span>
                <div>
                  <div>{{ item.task_name }}</div>
                  <div class="small text-muted">{{ item.created_at | humanDate(false) }}</div>
                </div>
                <div class="assignee" v-if="item.task_assigned_user_first_name">
                  <avatar :username="item.task_assigned_user_first_name ? item.task_assigned_user_first_name + ' ' + item.task_assigned_user_last_name : item.task_assigned_user_email" color="#fff" :rounded="true" :size="24"></avatar>
                </div>
              </div>
              <div class="item-header task" v-if="item.type === 'campaign' && item.metadata.status === 'created'" @click="toggleSidebar(null,item.scheduled_task_id,item.task_phone_call === 1 ? 'phone_call' : 'task')">
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/empty.svg" v-if="item.scheduled_task_complete === 0" />
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/complete.svg" v-if="item.scheduled_task_complete === 1" />
                <span class="material-icons" v-if="item.task_phone_call === 0">assignment</span>
                <span class="material-icons" v-if="item.task_phone_call === 1">phone</span>
                <div>
                  <div>{{ item.task_name }}</div>
                  <div class="small text-muted">{{ item.created_at | humanDate(false) }} &middot; {{ item.campaign_name }}</div>
                </div>
                <div class="assignee">
                  <avatar :username="item.task_assigned_user_first_name ? item.task_assigned_user_first_name + ' ' + item.task_assigned_user_last_name : item.task_assigned_user_email" color="#fff" :rounded="true" :size="24"></avatar>
                </div>
              </div>
              <div class="item-arrow" @click="toggleDrawer(item)"
              v-if="item.type !== 'campaign' &&
              item.type !== 'unsubscribe' &&
              item.type !== 'purchase' &&
              item.type !== 'form' &&
              item.type !== 'note' &&
              item.type !== 'sms' &&
              item.type !== 'scheduled_task' &&
              item.type !== 'remail' &&
              item.type !== 'reservation' &&
              item.type !== 'order' &&
              item.type !== 'credit_transaction' &&
              item.type !== 'membership_instance'">
                <span class="material-icons" v-if="!item.drawer">keyboard_arrow_down</span>
                <span class="material-icons" v-if="item.drawer">keyboard_arrow_up</span>
              </div>
            </div>
            <div class="card-footer" v-if="item.drawer && item.vendor === 'pike13' && item.type === 'visit'">
              <div class="row">
                <div class="title">Time:</div>
                <div>{{ item.start_at | humanDateTime(false) }}</div>
              </div>
              <div class="row">
                <div class="title">Paid With:</div>
                <div>{{ item.paid_with }}</div>
              </div>
              <div class="row">
                <div class="title">First Visit:</div>
                <div>{{ item.first_visit ? 'Yes' : 'No' }}</div>
              </div>
            </div>
            <div class="card-footer" v-if="item.drawer && item.vendor === 'pike13' && item.type === 'plan'">
              <div class="row">
                <div class="title">Rev. Category:</div>
                <div>{{ item.revenue_category }}</div>
              </div>
              <div class="row">
                <div class="title">Plan Type:</div>
                <div>{{ item.plan_type }}</div>
              </div>
              <div class="row">
                <div class="title">Grants Membership:</div>
                <div>{{ item.grants_membership ? 'Yes' : 'No' }}</div>
              </div>
            </div>
            <div class="card-footer" v-if="item.drawer && item.vendor === 'pike13' && item.type === 'purchase'">
              <div class="row">
                <div class="title">Rev. Category:</div>
                <div>{{ item.revenue_category }}</div>
              </div>
              <div class="row">
                <div class="title">Product Type:</div>
                <div>{{ item.revenue_category }}</div>
              </div>
            </div>
            <div class="card-footer" v-if="item.drawer && item.type === 'checkout'">
              <div class="row">
                <div class="title">Time:</div>
                <div>{{ item.created_at | humanDateTime(false) }}</div>
              </div>
              <div class="row">
                <div class="title">URL:</div>
                <div><a :href="'https://' + subdomain + '.brandbot-checkout.com/' + item.vanity + '.html'" target="_blank">https://{{ subdomain }}.brandbot-checkout.com/{{ item.vanity }}.html</a></div>
              </div>
            </div>
            <div class="card-footer" v-if="item.drawer && item.vendor !== 'pike13' && item.type === 'visit'">
              <div class="row">
                <div class="title">Status:</div>
                <div>{{ item.metadata.status || item.metadata.state }}</div>
              </div>
              <div class="row">
                <div class="title">Class Name:</div>
                <div>{{ item.metadata.description || item.metadata.serviceName }}</div>
              </div>
              <div class="row">
                <div class="title">Start Time:</div>
                <div>{{ item.metadata.start_time || item.metadata.serviceTime }}</div>
              </div>
              <div class="row" v-if="item.metadata.end_time">
                <div class="title">End Time:</div>
                <div>{{ item.metadata.end_time }}</div>
              </div>
              <div class="row" v-if="$store.state.user.current_account.active_vendors.indexOf('pike13') === -1">
                <div class="title">Pricing Option:</div>
                <div>{{ item.metadata.pricing_option_desc || item.metadata.paidWith }}</div>
              </div>
              <div class="row" v-if="item.metadata.staff_first_name && item.metadata.staff_last_name">
                <div class="title">Instructor:</div>
                <div>{{ item.metadata.staff_first_name }} {{ item.metadata.staff_last_name }}</div>
              </div>
              <div class="row" v-if="item.metadata.instructorNames">
                <div class="title">Instructor(s):</div>
                <div>{{ item.metadata.instructorNames }}</div>
              </div>
            </div>
            <div class="card-footer" v-if="item.drawer && item.type === 'activation'">
              <div class="row">
                <div class="title">Status:</div>
                <div>{{ item.md_activations_visit_metadata.status }}</div>
              </div>
              <div class="row">
                <div class="title">Class Name:</div>
                <div>{{ item.md_activations_visit_metadata.description }}</div>
              </div>
              <div class="row">
                <div class="title">Start Time:</div>
                <div>{{ item.md_activations_visit_metadata.start_time }}</div>
              </div>
              <div class="row">
                <div class="title">End Time:</div>
                <div>{{ item.md_activations_visit_metadata.end_time }}</div>
              </div>
              <div class="row">
                <div class="title">Pricing Option:</div>
                <div>{{ item.md_activations_visit_metadata.pricing_option_desc }}</div>
              </div>
              <div class="row" v-if="item.md_activations_visit_metadata.staff_first_name && item.md_activations_visit_metadata.staff_last_name">
                <div class="title">Instructor:</div>
                <div>{{ item.md_activations_visit_metadata.staff_first_name }} {{ item.md_activations_visit_metadata.staff_last_name }}</div>
              </div>
            </div>
            <div class="card-footer" v-if="item.drawer && item.type === 'membership'">
              <div class="row">
                <div class="title">Payment Date:</div>
                <div>{{ item.metadata.payment_date || item.metadata.latestInvoiceDueDate | humanDate }}</div>
              </div>
              <div class="row">
                <div class="title">Start Date:</div>
                <div>{{ item.metadata.membership_start_date || item.metadata.startDate | humanDate }}</div>
              </div>
              <div class="row">
                <div class="title">End Date:</div>
                <div>{{ item.metadata.membership_end_date || item.metadata.endDate | humanDate }}</div>
              </div>
            </div>
            <div class="card-footer one-off" v-if="item.drawer && (item.type === 'one-off' || item.type === 'email')">
              <div class="metric" v-if="item.drawer_bag.stats.delivered_at">
                <img src="https://assets.brandbot.com/public/icons/timeline/email-sent.svg" /> Delivered on {{ item.drawer_bag.stats.delivered_at | humanDateTime }}
              </div>
              <div class="metric" v-if="!item.drawer_bag.stats.delivered_at">
                <img src="https://assets.brandbot.com/public/icons/timeline/email-sent.svg" />
                Sent on {{ item.created_at | humanDateTime(false) }}
              </div>
              <div class="metric" v-if="item.drawer_bag.stats.first_open">
                <img src="https://assets.brandbot.com/public/icons/timeline/email-open.svg" />
                First Opened {{ item.drawer_bag.stats.first_open | humanDateTime }}
              </div>
              <div class="metric" v-if="item.drawer_bag.stats.unsubscribed">
                <img src="https://assets.brandbot.com/public/icons/timeline/email-unsub.svg" />
                Unsubscribed on {{ item.drawer_bag.stats.unsubscribed | humanDateTime }}
              </div>
              <div class="metric" v-if="item.drawer_bag.stats.bounced">
                <img src="https://assets.brandbot.com/public/icons/timeline/email-bounce.svg" />
                Bounced on {{ item.drawer_bag.stats.bounced | humanDateTime }}
              </div>
              <div class="metric" v-if="item.drawer_bag.stats.clicks_unique_link && item.drawer_bag.stats.clicks_unique_link.length">
                <div v-for="click in item.drawer_bag.stats.clicks_unique_link">
                  <img src="https://assets.brandbot.com/public/icons/timeline/email-click.svg" />
                  Clicked {{ click.url }} on {{ click.timestamp | humanDateTime }}
                </div>
              </div>
            </div>
            <div class="card-footer" style="background-color: white;" v-if="item.drawer && item.type === 'one-off'">
              <div class="one-off">
                <h6>{{ item.metadata.subject }}</h6>
                <div v-html="item.metadata.body"></div>
              </div>
            </div>
            <div class="card-footer" v-if="item.drawer && item.type === 'index'">
              <div class="row">
                <div class="title">Name:</div>
                <div>{{ item.index_name }}</div>
              </div>
              <div class="row">
                <div class="title">Value:</div>
                <div>{{ item.index_value }}</div>
              </div>
            </div>
            <div class="card-footer" v-if="[2, 6, 7, 445, 7763, 9304, 8235, 6082, 7145, 9748,10131, 10132, 10587].indexOf($store.state.user.id) > -1 && show_debug">
              <pre class="small">{{ item }}</pre>
            </div>
            </div>
          </div>

          <div class="text-center" style="margin-top: 2rem;" v-if="Object.keys(contact.events).length">
            <button type="button" class="xpl-button xpl-button--secondary" @click="loadMore">Load more</button>
          </div>
        </div>

        <SMSChat :contactId="contact.id" v-if="sms_tab" />
        <Documents :contactId="contact.id" v-if="documents_tab" />
      </div>
      <TasksSidebar v-if="show_tasks_sidebar" :scheduledTaskId="scheduled_task_id" :associatedContact="associatedContact" />
      <PhoneCallsSidebar v-if="show_calls_sidebar" :scheduledTaskId="scheduled_task_id" :associatedContact="associatedContact" />
      <AddNote v-if="add_note" :contactId="contact.id" />
    </div>
  </div>
</template>
<script>
  import * as api from '@/api';
  import moment from 'moment';
  import Multiselect from 'vue-multiselect';
  import EventBus from '@/event-bus';
  import { directive as onClickaway } from 'vue-clickaway';
  import TasksSidebar from '@/components/Tasks/TasksSidebar';
  import { accountIds } from '@/values/accountIdValues';
  import PhoneCallsSidebar from '@/components/Tasks/PhoneCallsSidebar';
  import Avatar from 'vue-avatar';
  import SendEmail from './ContactDetail/SendEmail';
  import AddNote from './AddNote';
  import SMSChat from './SMSChat';
  import Documents from './Documents';
  import Tags from './Tags/Tags';
  import AddToFunnel from './AddToFunnel';
  import ContactSettings from './ContactSettings';
  import SyncHistory from './SyncHistory';

  export default {
    props: ['uuid'],
    directives: {
      onClickaway,
    },
    components: {
      ContactSettings,
      AddToFunnel,
      Multiselect,
      SendEmail,
      Tags,
      Avatar,
      TasksSidebar,
      PhoneCallsSidebar,
      AddNote,
      SMSChat,
      Documents,
      SyncHistory,
    },
    data() {
      return {
        loading_optin: false,
        loading_phone: false,
        show_settings: false,
        show_debug: false,
        tags: [],
        new_tag: null,
        show_tasks_sidebar: false,
        show_calls_sidebar: false,
        scheduled_task_id: null,
        offset: 50,
        query: '',
        contact: null,
        state: 'view',
        send_email: false,
        add_note: false,
        tags_modal: false,
        optionsDropdown: false,
        expand_details: false,
        associatedContact: null,
        genders: [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }, { label: 'Non-Binary', value: 'other' }],
        sync_tab: false,
        timeline_tab: true,
        sms_tab: false,
        documents_tab: false,
        funnel_tab: false,
        settings_tab: false,
      };
    },
    created() {
      this.fetchContact();
      this.fetchTags();
    },
    mounted() {
      EventBus.$on('calendar.task_sidebar.close', () => {
        this.scheduled_task_id = null;
        this.associatedContact = null;
        this.show_tasks_sidebar = false;
        this.show_calls_sidebar = false;
      });
      EventBus.$on('contact_detail.send_email.close', this.closeSendEmail);
      EventBus.$on('contact_details.tags.close', this.closeTags);
      EventBus.$on('add_note.close', () => {
        this.add_note = false;
        this.fetchContact();
      });
    },
    beforeDestroy() {
      EventBus.$off('calendar.task_sidebar.close');
      EventBus.$off('cotact_detail.send_email.close');
      EventBus.$off('contact_details.tags.close');
      EventBus.$off('add_note.close');
      // eslint-disable-next-line
      document.title = this.$store.getters.brandName;
    },
    computed: {
      showCommunicationEmailBtn() {
        this.isTots = accountIds.aquaTots.includes(this.contact.account_id);
        this.isBaseCamp = accountIds.baseCamp.includes(this.contact.account_id);

        if (this.isTots || this.isBaseCamp) {
          return true;
        }
        return false;
      },
      showSubscribeSMS() {
        // BBE-2168 
        // if (!this.$store.getters.isStaff &&
        //     accountIds.aquaTots.indexOf(this.contact.account_id) > -1) {
        //   return false;
        // }

        if (this.contact.brandbot_phone && this.contact.unsubscribed_sms_at) {
          return true;
        }

        return false;
      },
      subdomain() {
        const metadata = JSON.parse(this.$store.state.user.current_account.metadata);

        return metadata.subdomain;
      },
      scheduledEvents() {
        if (!this.contact.events) {
          return [];
        }

        const bag = [];

        // eslint-disable-next-line
        for (const day in this.contact.events) {
          // eslint-disable-next-line
          for (const i in this.contact.events[day]) {
            const event = this.contact.events[day][i];
            if (event.type === 'campaign' && ['enrollment', 'exit', 'sent'].indexOf(event.metadata.status) === -1) {
              bag.push(event);
            }
          }
        }

        // eslint-disable-next-line
        return bag;
      },
      timelineEvents() {
        if (!this.contact.events) {
          return {};
        }

        const bag = {};

        // eslint-disable-next-line
        for (const day in this.contact.events) {
          // eslint-disable-next-line
          for (const i in this.contact.events[day]) {
            const event = this.contact.events[day][i];
            if ((event.metadata && ['waiting-behavior', 'waiting-both', 'not-scheduled-unsub', 'not-sent-unsub', 'not-waiting-behavior-unsub', 'too-late', 'sent'].indexOf(event.metadata.status) === -1) ||
              event.note || event.type === 'scheduled_task' || event.vendor === 'pike13' || event.vendor === 'marianatek') {
              if (Object.prototype.hasOwnProperty.call(bag, day)) {
                bag[day].push(event);
              } else {
                bag[day] = [];
                bag[day].push(event);
              }
            }
          }
        }

        // eslint-disable-next-line
        return bag;
      },
    },
    filters: {
      pikeState(state) {
        if (!state) {
          return '';
        }

        if (state === 'late_canceled') {
          return 'Late Canceled';
        }

        if (state === 'noshowed') {
          return 'No Showed';
        }

        return state.charAt(0).toUpperCase() + state.slice(1);
      },
    },
    methods: {
      optinEmail() {
        this.loading_optin = true;
        api.sendOptinEmail(this.contact.id).then((response) => {
          this.$message.success(response.message);
          this.loading_optin = false;
        }).catch((err) => {
          this.loading_optin = false;
          this.$message.error(err.message);
        });
      },
      validatePhone() {
        this.expand_details = true;
        this.loading_phone = true;
        api.validatePhoneNumber(this.contact.id).then((response) => {
          this.loading_phone = false;
          this.$message.success(response.message);
          this.fetchContact();
        }).catch((err) => {
          this.loading_phone = false;
          this.$message.error(err.message);
        });
      },
      toggleSidebar(associatedContact, scheduledTaskId, type) {
        this.associatedContact = associatedContact;
        this.scheduled_task_id = scheduledTaskId;
        if (type === 'phone_call') {
          this.show_calls_sidebar = true;
        } else {
          this.show_tasks_sidebar = true;
        }
      },
      toggleEditNote(item) {
        if (!Object.prototype.hasOwnProperty.call(item, 'edit')) {
          this.$set(item, 'edit', true);
        } else {
          item.edit = !item.edit;
        }
      },
      editNote(item) {
        const payload = {
          note_id: item.metadata.note_id,
          contact_id: this.contact.id,
          note: item.note,
        };

        api.editContactNote(payload).then((response) => {
          this.$toasted.success(response.message);
          item.edit = false;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      deleteNote(item) {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to delete this note?')) {
          return;
        }

        api.deleteContactNote(item.metadata.note_id).then((response) => {
          this.$toasted.success(response.message);
          item.edit = false;
          this.fetchContact();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      away() {
        this.optionsDropdown = false;
      },
      switchTab(tab) {
        this.timeline_tab = false;
        this.sms_tab = false;
        this.funnel_tab = false;
        this.settings_tab = false;
        this.sync_tab = false;
        this.documents_tab = false;
        this[tab] = true;
      },
      createTag(tag) {
        const payload = {
          type: 'brandbot',
          name: tag,
          id: Math.floor((Math.random() * 10000000)),
        };

        api.addCustomTag(payload).then((response) => {
          this.chooseTag(response.tag);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      chooseTag(tag) {
        this.contact.tags.push(tag);
        const payload = {
          contact_id: this.contact.id,
          tag_id: tag.id,
        };
        api.addContactCustomTag(payload).then((response) => {
          this.$toasted.success(response.message);
          this.contact.tags = response.tags;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchTags() {
        api.getCustomTags().then((response) => {
          this.tags = response.tags;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchContact() {
        api.getContact(this.uuid).then((response) => {
          response.contact.gender = {
            label: response.contact.gender,
            value: response.contact.gender,
          };
          this.contact = response.contact;
          if (this.contact.first_name && this.contact.last_name) {
            document.title = `${this.contact.first_name} ${this.contact.last_name} | ${this.$store.getters.brandName}`;
          }
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      loadMore() {
        api.getContact(this.contact.uuid, this.offset + 50).then((response) => {
          this.$set(this.contact, 'events', response.contact.events);
          this.$set(this, 'offset', this.offset + 50);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      toggleDrawer(item) {
        if ((item.type === 'one-off' || item.type === 'email') && !Object.prototype.hasOwnProperty.call(item, 'drawer')) {
          api.contactEmailPerformance(this.contact.uuid,
            item.metadata.uuid || item.scheduled_email_uuid || item.metadata.id)
          .then((response) => {
            this.$set(item, 'drawer', !item.drawer);
            this.$set(item, 'drawer_bag', {});
            this.$set(item.drawer_bag, 'stats', response.stats);
          }).catch((err) => {
            this.$toasted.error(err.message);
          });
        } else {
          this.$set(item, 'drawer', !item.drawer);
        }
      },
      closeSendEmail() {
        this.send_email = false;
      },
      unsubscribeContactSMS() {
        // eslint-disable-next-line
        if (!confirm(`Are you sure you want to unsubscribe this contact?`)) {
          return;
        }

        const payload = {
          contact_ids: this.contact.id,
          type: 'sms',
        };

        api.unsubscribeContacts(payload).then((response) => {
          this.$toasted.success(response.message);
          this.contact.unsubscribed_sms_at = new Date();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      unsubscribeContact() {
        // eslint-disable-next-line
        if (!confirm(`Are you sure you want to unsubscribe this contact?`)) {
          return;
        }

        const payload = {
          contact_ids: this.contact.id,
        };

        api.unsubscribeContacts(payload).then((response) => {
          this.$toasted.success(response.message);
          this.contact.unsubscribed_at = new Date();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      subscribeContactSMS() {
        // eslint-disable-next-line
        if (!confirm(`Are you sure you want to subscribe this contact?`)) {
          return;
        }

        const payload = {
          contact_ids: this.contact.id,
          type: 'sms',
        };

        api.subscribeContacts(payload).then((response) => {
          this.$toasted.success(response.message);
          this.contact.unsubscribed_sms_at = null;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      subscribeContact() {
        // eslint-disable-next-line
        if (!confirm(`Are you sure you want to subscribe this contact?`)) {
          return;
        }

        const payload = {
          contact_ids: this.contact.id,
        };

        api.subscribeContacts(payload).then((response) => {
          this.$toasted.success(response.message);
          this.contact.unsubscribed_at = null;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      removeTag(contactTag) {
        // eslint-disable-next-line
        if (confirm('Are you sure you want to delete this Tag?')) {
          api.removeContactCustomTag(contactTag).then((response) => {
            this.$toasted.success(response.message);
            this.contact.tags = this.contact.tags.filter(t => t.uuid !== contactTag.uuid);
          }).catch((err) => {
            this.$toasted.error(err.message);
          });
        }
      },
      deleteContact() {
        // eslint-disable-next-line
        if (confirm('Are you sure you want to delete this Contact?')) {
          api.deleteContact(this.contact.uuid).then((response) => {
            this.$toasted.success(response.message);
            this.$router.push('/contacts');
          }).catch((err) => {
            this.$toasted.error(err.message);
          });
        }
      },
      humanBirthdate(date) {
        return moment(date).format('MMM Do YYYY');
      },
      indexTags(tags) {
        return tags.filter(tag => tag.type === 'index');
      },
      manualTags(tags) {
        return tags.filter(tag => tag.type !== 'index');
      },
      toggleState() {
        if (this.contact.source === 'mindbody') {
          this.$toasted.error('This Contact is synced from MINDBODY. You cannot edit it in BrandBot.');
          return;
        }

        if (this.state === 'edit') {
          this.save();
          this.state = 'view';
        } else {
          this.state = 'edit';
        }
      },
      save() {
        const contact = this.contact;
        if (contact.gender && contact.gender.value) {
          contact.gender = contact.gender.value;
        } else {
          delete contact.gender;
        }
        api.editContact(this.contact.id, contact).then((response) => {
          this.$toasted.success(response.message);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
  .container-fluid {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    padding-top: 0;
    padding-left: 2rem;
    padding-right: 0;
    padding-bottom: 0;
  }

  .sidebar {
    padding-top: 1.25rem;
    position: fixed;
    height: calc(100vh - 4rem);
    overflow-y: scroll;
    max-width: 19.625rem;
    min-width: 19.625rem;
    width: 19.625rem;
    padding-bottom: 2rem;
  }

  .sidebar::-webkit-scrollbar {
    display: none;
  }

  .sidebar .go-back {
    display: block;
    margin-bottom: 1.5rem;
  }

  .contact-detail .data.pills {
    padding-top: .5rem;
  }

  .contact-detail .data.pills .pill-default {
    font-size: .75rem;
    line-height: 1.43;
    color: #1d364b;
    background-color: #f3f7f9;
  }

  .pill.pill-secondary {
    padding-left: .75rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
    border-radius: 6px;
    background-color: rgb(217, 231, 240);
    font-size: .875rem;
    line-height: 1.43;
    color: rgb(28, 44, 56);
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: .5rem;
  }

  .pill.pill-secondary .material-icons {
    margin-left: .75rem;
    font-size: .75rem;
    font-weight: normal;
    line-height: .875rem;
    cursor: pointer;
  }

  .contact-detail .datepicker {
    display: block;
  }

  .contact-detail .datepicker:before {
    content: none;
  }

  .contact-detail .datepicker > input.focus {
    border: 2px solid #6923f4;
  }

  .powered img {
    width: 40%;
  }

  .timeline {
    max-height: calc(100vh - 3rem);
    height: calc(100vh - 3rem);
    min-height: calc(100vh - 3rem);
    margin-left: 22.125rem;
    width: 100%;
    min-width: 544px;
    background-color: var(--xpl-color-background-lm);
  }

  .timeline-days {
    min-height: calc(100% - 3.625rem);
    height: calc(100% - 3.625rem);
    max-height: calc(100% - 3.625rem);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    overflow-y: scroll;
  }

  .timeline .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    padding-left: 1.5rem;
    padding-right: 1rem;
  }

  .timeline .header .tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .timeline .header .tabs .tab {
    font-weight: 700;
    color: rgb(139, 155, 172);
    border-bottom: 2px solid transparent;
    transition: all 300ms ease-in-out;
    margin-left: 1.75rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .timeline .header .tabs .tab:first-child {
    margin-left: 0;
  }

  .timeline .header .tabs .tab.active,
  .timeline .header .tabs .tab:hover {
    text-decoration: none;
    color: rgb(46, 68, 87);
  }

  .timeline .header .tabs .tab.active {
    border-color: var(--xpl-color-secondary-lm);
  }

  .timeline h4 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #2e4457;
    font-weight: 300;
    margin-bottom: 1rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid #cdd7e2;
  }

  .timeline .timeline-day .day {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1rem;
    color: #697c86;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }

  .timeline .timeline-day .card {
    margin-bottom: .75rem;
    // box-shadow: 0 2px 5px 0 rgba(217, 231, 240, 0.5);
  }

  .timeline .timeline-day .card.hoverable {
    cursor: pointer;
  }

  .timeline .timeline-day .card .card-body {
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .timeline .timeline-day .card .card-footer .one-off {
    font-size: .75rem;
    line-height: 1.67;
    color: rgb(29, 54, 75);
  }

  .timeline .timeline-day .card .card-footer.one-off {
    padding: 0;
  }

  .timeline .timeline-day .card .card-footer .one-off h6 {
    font-size: .875rem;
    font-weight: bold;
    color: rgb(29, 54, 75);
    margin-bottom: .75rem;
  }

  .timeline .timeline-day .card .card-footer .metric {
    padding-left: 3rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    color: rgb(61, 71, 82);
    border-bottom: 1px solid white;
  }

  .timeline .timeline-day .card .card-footer .metric:last-child {
    border-bottom: none;
  }

  .timeline .timeline-day .card .card-footer .metric img {
    width: 1.125rem;
    height: 1.125rem;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: .5rem;
  }

  .timeline .timeline-day .card .card-body .item-header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: .875rem;
    line-height: 1rem;
    color: rgb(29, 54, 75);
  }

  .timeline .timeline-day .card .card-body .item-header.task {
    cursor: pointer;
  }

  .timeline .timeline-day .card .card-body .item-header .small.text-muted {
    margin-top: .25rem;
  }

  .timeline .timeline-day .card .card-body .item-header .assignee {
    margin-left: auto;
  }

  .timeline .timeline-day .card .card-body .item-header .material-icons,
  .timeline .timeline-day .card .card-body .item-header .fa {
    font-size: 1rem;
    line-height: 1rem;
    margin-right: 1rem;
    color: rgb(217, 231, 240);
  }

  .timeline .timeline-day .card .card-body .item-header img {
    width: 1rem;
    height: 1rem;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 1rem;
    display: block;
  }

  .timeline .timeline-day .card .card-body .item-arrow {
    margin-left: auto;
  }

  .timeline .timeline-day .card .card-body .item-arrow .material-icons {
    display: block;
    font-size: 1.5rem;
    line-height: 1rem;
    color: rgb(205, 215, 226);
    cursor: pointer;
  }

  .timeline .timeline-day .card .card-body + .card-footer {
    border-top: none;
  }

  .timeline .timeline-day .card .card-footer {
    padding: 0;
    padding-top: .875rem;
    padding-left: 3rem;
    background-color: var(--xpl-color-background-lm);
    font-size: .75rem;
    color: rgb(97, 112, 128);
  }

  .timeline .timeline-day .card .card-footer .row {
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 1.25rem;
    margin-bottom: .25rem;
  }

  .timeline .timeline-day .card .card-footer .row .title {
    font-weight: 700;
    color: #617080;
    min-width: 6rem;
    width: 6rem;
    margin-right: .5rem;
  }

  .search-bar {
    position: relative;
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .search-bar input {
    width: 100%;
    padding: .75rem;
    padding-left: 2.25rem;
    border: 1px solid #E9F1F6;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .search-bar .material-icons {
    font-size: 1.25rem;
    position: absolute;
    color: #8B9BAC;
    top: .8125rem;
    left: .75rem;
  }

  .search-bar input::-webkit-input-placeholder {
    color: #617080;
  }
  .search-bar input::-moz-placeholder {
    color: #617080;
  }
  .search-bar input:-ms-input-placeholder {
    color: #617080;
  }
  .search-bar input:-moz-placeholder {
    color: #617080;
  }

  .no-timeline {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: .875rem;
    color: #617080;
    text-align: center;
  }

  .no-timeline img {
    width: 15rem;
    display: block;
    margin-bottom: .5rem;
  }

  .card {
    // box-shadow: 0 1px 0 0 rgba(217, 231, 240, 0.5);
    background-color: rgb(255, 255, 255);
    border: solid 1px rgb(217, 231, 240);
    margin-bottom: 1.5rem;
  }

  .card .card-header a {
    font-size: .875rem;
    color: rgb(46, 68, 87);
  }

  .card .card-body h6 {
    font-size: 1.125rem;
    color: rgb(46, 68, 87);
  }

  .card .card-body .dropdown {
    margin-top: 6px;
  }

  .card .card-body .label {
    font-size: .75rem;
    color: rgb(97, 112, 128);
    margin-bottom: .25rem;
  }

  .card .card-body .data {
    font-size: .875rem;
    color: rgb(0, 21, 42);
    margin-bottom: .875rem;
  }

  .card .card-body .title {
    font-size: .75rem;
    font-weight: bold;
    color: rgb(61, 71, 82);
    margin-bottom: .625rem;
    margin-top: 1.5rem;
  }

  .card.mindbody .title {
    margin-top: 0;
  }

  .card.mindbody .card-body .contact-detail {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 1.5rem;
  }

  .card.mindbody .card-body .contact-detail:first-child {
    margin-top: 0;
  }

  .card.mindbody .contact-detail .material-icons {
    margin-right: .5rem;
  }

  .sidebar .card .card-body {
    padding-left: 1rem;
    padding-right: 1rem;
  }
</style>
