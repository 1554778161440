<template>
  <div>
    <div class="card">
      <el-collapse v-model="email" @change="handleChange">
      <el-collapse-item class="history-title" title="Email Subscription Preferences" name="1">
        <div class="history-event">Last updated Mar 12th 2019 at 8:09pm</div>
        <div class="history-event">Last updated Mar 12th 2019 at 8:09pm</div>
      </el-collapse-item>
      </el-collapse>
    </div>
    <div class="card">
      <el-collapse v-model="sms" @change="handleChange">
      <el-collapse-item class="history-title" title="SMS Subscription Preferences" name="1">
        <div class="history-event">Last updated Mar 12th 2019 at 8:09pm</div>
        <div class="history-event">Last updated Mar 12th 2019 at 8:09pm</div>
      </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        email: [''],
        sms: [''],
      };
    },
    methods: {
      handleChange() {
        // console.log(val);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .text {
    color: #1d364b !important;
    font-size: 1rem !important;
  }
  .history-title  {
    padding: 0.5rem 1rem 1rem 1rem;
  }
  .history-event {
    padding: .3rem;
  }

</style>
