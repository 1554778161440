<template>
  <transition name="slidy">
    <div class="conditions-sidebar" v-if="!add_condition">
      <GlobalRuleHeader ruleName="" />
      <div class="conditions-sidebar-body">
        <div class="conditions-rule" v-if="contact_list.rule">
          <div v-for="(rule, index) in contact_list.rule">
            <div :class="[rule.confirm ? 'rule confirm' : 'rule']">
              <div class="confirm-header">
                Are you sure you want to remove this condition?
                <div class="actions">
                  <button type="button" class="btn-sm xpl-button xpl-button--secondary xpl-button--warning" @click="deleteCondition(index, true)">Remove</button>
                  <button type="button" class="btn-sm xpl-button xpl-button--secondary" @click="rule.confirm = false">Cancel</button>
                </div>
              </div>
              <div class="header" @click="editCondition(rule, index)">
                <div>
                  <img :src="'https://assets.brandbot.com/public/icons/conditions/' + rule.condition.uuid + '.svg'" />
                  {{ enrollment && rule.condition.uuid === 'd323c020-0569-4f1e-ab1d-f56672d66610' ? 'Joins a Membership for the first time' : rule.condition.name }}
                </div>
                <div class="remove" @click.stop="deleteCondition(index)">Remove <span class="icon icon-bin"></span></div>
              </div>
              <div class="body-container" @click="editCondition(rule, index)">
                <div class="body">
                  <!-- Has A Membership MT -->
                  <div v-if="rule.condition.uuid === '0567f82c-bd39-4850-b4f0-715fe8c8009d'">
                    <div class="row">
                      <span class="title">Customers Who Have / Do Not Have?</span>
                      <div class="disable-caps" v-if="rule.has_memberships">Have the following</div>
                      <div class="disable-caps" v-if="!rule.has_memberships">Does not have the following</div>
                    </div>
                    <div class="row">
                      <span class="title">Which Memberships?</span>
                      <div class="disable-caps" v-if="rule.all_memberships">All Membership(s)</div>
                      <div class="disable-caps" v-if="!rule.all_memberships">Specific Membership(s)</div>
                    </div>
                    <div v-if="!rule.all_memberships" class="row">
                      <span class="title">Which Membership Types?</span>
                      <div class="disable-caps" v-if="rule.membership_type_option === 'any'">All membership types</div>
                      <div v-if="rule.membership_type_option === 'specific'">
                        <div v-if="rule.membership_type_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.membership_type_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="membership in rule.membership_types" :key="membership.id">
                          <div class="disable-caps">{{ membership.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_memberships" class="row">
                      <span class="title disable-caps">Which Membership Status(es)?</span>
                      <div class="disable-caps" v-if="rule.membership_status_option === 'any'">All membership statuses</div>
                      <div v-if="rule.membership_status_option === 'specific'">
                        <div v-if="rule.membership_status_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.membership_status_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="status in rule.membership_statuses" :key="status.id">
                          <div class="disable-caps">{{ status.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_memberships" class="row">
                      <span class="title">When Did They Start Their Membership?</span>
                      <div class="disable-caps" >{{ rule.membership_start_filter | timeComponent(rule.membership_start_value) }}</div>
                    </div>
                    <div v-if="!rule.all_memberships" class="row">
                      <span class="title disable-caps">Membership Purchase Location</span>
                      <div class="disable-caps" v-if="rule.selected_purchase_location_option === 'any' || !rule.selected_purchase_location_option">Any location in my account</div>
                      <div v-if="rule.selected_purchase_location_option === 'specific'">
                        <div v-if="rule.selected_purchase_location_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.selected_purchase_location_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="location in rule.selected_purchase_locations" :key="location.id">
                          <div class="disable-caps">{{ location.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Home Location MT -->
                  <div v-if="rule.condition.uuid === 'e3d9c44d-a03c-4979-8a35-89c8003b2fb8'">
                    <div class="row">
                      <span class="title">Which Home Location?</span>
                      <div>
                        <div v-if="rule.exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="location in rule.locations" :key="location.id">
                          <div class="disable-caps">{{ location.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Milestone Visits MT -->
                  <div v-if="rule.condition.uuid === '5005b33e-6ea6-45fc-affd-4b2f184efebf'">
                    <div class="row">
                      <span class="title">How Many Visits?</span>
                      <div class="disable-caps" >{{ rule.comparison | countComponent(rule.first_bound, rule.second_bound, 'visits') }}</div>
                    </div>
                    <div class="row">
                      <span class="title disable-caps">At Which Location(s)?</span>
                      <div class="disable-caps" v-if="rule.option === 'any'">Any location in my account</div>
                      <div v-if="rule.option === 'specific'">
                        <div v-if="rule.exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="location in rule.locations" :key="location.id">
                          <div class="disable-caps">{{ location.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <span class="title">What Is Their Home Location?</span>
                      <div class="disable-caps" v-if="rule.home_option === 'any'">Any location in my account</div>
                      <div v-if="rule.home_option === 'specific'">
                        <div v-if="rule.home_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.home_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="location in rule.home_locations" :key="location.id">
                          <div class="disable-caps">{{ location.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Number Of Visits MT -->
                  <div v-if="rule.condition.uuid === '6696ed9d-bacc-445e-a8fa-0866201ad53f'">
                    <div class="row">
                      <span class="title">How Many Visits?</span>
                      <div class="disable-caps" >{{ rule.count_comparison | countComponent(rule.first_bound, rule.second_bound, 'visits') }}</div>
                    </div>
                    <div class="row">
                      <span class="title">When Did These Visits Occur?</span>
                      <div class="disable-caps" >{{ rule.first_visit_comparison | timeComponent(rule.first_visit_value, hasSinceEntering = true) }}</div>
                    </div>
                    <div class="row">
                      <span class="title">What Types Of Visits Does This Count?</span>
                      <div class="disable-caps" v-if="rule.all_first_visits">All visits</div>
                      <div class="disable-caps" v-if="!rule.all_first_visits">Specific visits</div>
                    </div>
                    <div v-if="!rule.all_first_visits" class="row">
                      <span class="title disable-caps">Which Class Name(s)?</span>
                      <div class="disable-caps" v-if="rule.class_name_option === 'any'">All class names</div>
                      <div v-if="rule.class_name_option === 'specific'">
                        <div v-if="rule.class_name_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.class_name_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="class_name in rule.class_names" :key="class_name.id">
                          <div class="disable-caps">{{ class_name.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_first_visits" class="row">
                      <span class="title disable-caps">Which Payment Option(s)?</span>
                      <div class="disable-caps" v-if="rule.payment_option_option === 'any'">All payment options</div>
                      <div v-if="rule.payment_option_option === 'specific'">
                        <div v-if="rule.membership_option_exclusion === 'any' && rule.membership_types_checked" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.membership_option_exclusion === 'except' && rule.membership_types_checked" class="disable-caps">Any except the following:</div>
                        <div v-if="rule.membership_types_checked" v-for="membership in rule.membership_options" :key="membership.id">
                          <div class="disable-caps">{{ membership.name }}</div>
                        </div>
                        <div v-if="rule.credit_option_exclusion === 'any' && rule.credit_types_checked" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.credit_option_exclusion === 'except' && rule.credit_types_checked" class="disable-caps">Any except the following:</div>
                        <div v-if="rule.credit_types_checked" v-for="credit in rule.credit_options" :key="credit.id">
                          <div class="disable-caps">{{ credit.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_first_visits" class="row">
                      <span class="title disable-caps">Which Class Instructor(s)?</span>
                      <div class="disable-caps" v-if="rule.class_instructor_option === 'any'">All class instructors</div>
                      <div v-if="rule.class_instructor_option === 'specific'">
                        <div v-if="rule.class_instructor_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.class_instructor_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="instructor in rule.class_instructors" :key="instructor.id">
                          <div class="disable-caps">{{ instructor.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_first_visits" class="row">
                      <span class="title disable-caps">Which Class Time(s)?</span>
                      <div class="disable-caps" v-if="rule.class_time_option === 'any'">All class times</div>
                      <div v-if="rule.class_time_option === 'specific'">
                        <div v-if="rule.class_time_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.class_time_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="class_time in rule.class_times" :key="class_time.id">
                          <div class="disable-caps">{{ class_time.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_first_visits" class="row">
                      <span class="title disable-caps">Which Visit Status(es)?</span>
                      <div class="disable-caps" v-if="rule.visit_status_option === 'any'">All visit statuses</div>
                      <div v-if="rule.visit_status_option === 'specific'">
                        <div v-if="rule.visit_status_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.visit_status_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="status in rule.visit_statuses" :key="status.id">
                          <div class="disable-caps">{{ status.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_first_visits" class="row">
                      <span class="title disable-caps">Which Visit Location(s)?</span>
                      <div class="disable-caps" v-if="rule.visit_location_option === 'any'">Any location in my account</div>
                      <div v-if="rule.visit_location_option === 'specific'">
                        <div v-if="rule.visit_location_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.visit_location_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="location in rule.visit_locations" :key="location.id">
                          <div class="disable-caps">{{ location.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Last Visit MT -->
                  <div v-if="rule.condition.uuid === 'a037768c-a9cd-4250-abbe-062d27e1b78c'">
                    <div class="row">
                      <span class="title">What Are You Looking For?</span>
                      <div class="disable-caps" v-if="rule.visit_only">Last Visit Only</div>
                      <div class="disable-caps" v-if="!rule.visit_only">Last Interaction (Visit or Purchase)</div>
                    </div>
                    <div class="row">
                      <span class="title">When Was Their Last Visit Or Interaction?</span>
                      <div class="disable-caps" >{{ rule.timeframe_comparison | timeComponent(rule.timeframe_value) }}</div>
                    </div>
                    <div class="row">
                      <span class="title">Was Their Last Visit Any Type Or A Specific Type?</span>
                      <div class="disable-caps" v-if="rule.include_all_visits">All visit types</div>
                      <div class="disable-caps" v-if="!rule.include_all_visits">Specific type of visits</div>
                    </div>
                    <div v-if="!rule.include_all_visits" class="row">
                      <span class="title disable-caps">Which Payment Option(s)?</span>
                      <div class="disable-caps" v-if="rule.payment_option_option === 'any'">All payment options</div>
                      <div v-if="rule.payment_option_option === 'specific'">
                        <div v-if="rule.membership_option_exclusion === 'any' && rule.membership_types_checked" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.membership_option_exclusion === 'except' && rule.membership_types_checked" class="disable-caps">Any except the following:</div>
                        <div v-if="rule.membership_types_checked" v-for="membership in rule.membership_options" :key="membership.id">
                          <div class="disable-caps">{{ membership.name }}</div>
                        </div>
                        <div v-if="rule.credit_option_exclusion === 'any' && rule.credit_types_checked" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.credit_option_exclusion === 'except' && rule.credit_types_checked" class="disable-caps">Any except the following:</div>
                        <div v-if="rule.credit_types_checked" v-for="credit in rule.credit_options" :key="credit.id">
                          <div class="disable-caps">{{ credit.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.include_all_visits" class="row">
                      <span class="title">Where Was Their Last Visit Or Interaction?</span>
                      <div class="disable-caps" v-if="rule.selected_home_option === 'any'">Any location in my account</div>
                      <div v-if="rule.selected_home_option === 'specific'">
                        <div v-if="rule.selected_home_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.selected_home_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="location in rule.selected_home_locations" :key="location.id">
                          <div class="disable-caps">{{ location.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Visits Remaining MT -->
                  <div v-if="rule.condition.uuid === '744156da-5aed-409d-a2cb-6436ae3a6e04'">
                    <div class="row">
                      <span class="title">Customers Who Have / Do Not Have?</span>
                      <div v-if="rule.hasOwnProperty('has_visits_remaining')">
                        <div class="disable-caps" v-if="rule.has_visits_remaining">Has visits remaining</div>
                        <div class="disable-caps" v-if="!rule.has_visits_remaining">Does not have visits remaining</div>
                      </div>
                      <div v-else>
                        <div class="disable-caps">Has visits remaining</div>
                      </div>
                    </div>
                    <div class="row">
                      <span class="title">How Many Visits Remaining?</span>
                      <div class="disable-caps" >{{ rule.comparison | countComponent(rule.first_bound, rule.second_bound, 'visits') }}</div>
                    </div>
                    <div class="row">
                      <span class="title">Which Credit Packages?</span>
                      <div class="disable-caps" v-if="rule.all_credit_packages">All credit packages</div>
                      <div class="disable-caps" v-if="!rule.all_credit_packages">Specific credit package</div>
                    </div>
                    <div v-if="!rule.all_credit_packages" class="row">
                      <span class="title disable-caps">Which Credit Package Type(s)?</span>
                      <div class="disable-caps" v-if="rule.credit_package_option === 'any'">All credit packages</div>
                      <div v-if="rule.credit_package_option === 'specific'">
                        <div v-if="rule.credit_package_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.credit_package_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="credit_package in rule.credit_packages" :key="credit_package.id">
                          <div class="disable-caps">{{ credit_package.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Finished A Credit Package MT -->
                  <div v-if="rule.condition.uuid === '78b51a87-ca35-4132-8342-ae145b067fc3'">
                    <div class="row">
                      <span class="title">When Did They Finish?</span>
                      <div class="disable-caps" >{{ rule.finished_time_comparison | timeComponent(rule.finished_time_value) }}</div>
                    </div>
                    <div class="row">
                      <span class="title">Which Type Of Credit Package?</span>
                      <div class="disable-caps" v-if="rule.all_credit_packages">All credit packages</div>
                      <div class="disable-caps" v-if="!rule.all_credit_packages">Specific credit package</div>
                    </div>
                    <div v-if="!rule.all_credit_packages" class="row">
                      <span class="title disable-caps">Which Credit Package?</span>
                      <div class="disable-caps" v-if="rule.credit_package_option === 'any'">All credit packages</div>
                      <div v-if="rule.credit_package_option === 'specific'">
                        <div v-if="rule.credit_package_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.credit_package_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="credit_package in rule.credit_packages" :key="credit_package.id">
                          <div class="disable-caps">{{ credit_package.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_credit_packages" class="row">
                      <span class="title">How Long Did It Take Them To Finish?</span>
                      <div class="disable-caps" >{{ rule.finish_comparison | countComponent(rule.finish_first_bound, rule.finish_second_bound, 'days') }}</div>
                    </div>
                    <div v-if="!rule.all_credit_packages" class="row">
                      <span class="title disable-caps">Credit Package Purchase Location</span>
                      <div class="disable-caps" v-if="rule.selected_purchase_location_option === 'any' || !rule.selected_purchase_location_option">Any location in my account</div>
                      <div v-if="rule.selected_purchase_location_option === 'specific'">
                        <div v-if="rule.selected_purchase_location_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.selected_purchase_location_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="location in rule.selected_purchase_locations" :key="location.id">
                          <div class="disable-caps">{{ location.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Starts A Credit Package MT -->
                  <div v-if="rule.condition.uuid === '928debf9-8856-41bf-8251-fbcb2776dd3c'">
                    <div class="row">
                      <span class="title">When Did The Credit Package Start?</span>
                      <div class="disable-caps" >{{ rule.credit_package_comparison | timeComponent(rule.credit_package_value) }}</div>
                    </div>
                    <div class="row">
                      <span class="title">Which Type Of Credit Package(s)?</span>
                      <div class="disable-caps" v-if="rule.all_credit_packages">All credit packages</div>
                      <div class="disable-caps" v-if="!rule.all_credit_packages">Specific credit package</div>
                    </div>
                    <div v-if="!rule.all_credit_packages" class="row">
                      <span class="title disable-caps">Which Credit Package?</span>
                      <div class="disable-caps" v-if="rule.credit_package_option === 'any'">All credit packages</div>
                      <div v-if="rule.credit_package_option === 'specific'">
                        <div v-if="rule.credit_package_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.credit_package_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="credit_package in rule.credit_packages" :key="credit_package.id">
                          <div class="disable-caps">{{ credit_package.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Has A Tag MT -->
                  <div v-if="rule.condition.uuid === '4d0702b6-53b4-4e51-ade3-dc9983c635ab'">
                    <div class="row">
                      <span class="title">Customers Who Have / Do Not Have?</span>
                      <div class="disable-caps" v-if="rule.have_tags">Have the following</div>
                      <div class="disable-caps" v-if="!rule.have_tags">Does not have the following</div>
                    </div>
                    <div class="row">
                      <span class="title disable-caps">Which Tag?</span>
                      <div v-if="rule.tag_option === 'specific'">
                        <div v-if="rule.tag_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.tag_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="tag in rule.tags" :key="tag.id">
                          <div class="disable-caps">{{ tag.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Membership Cancelled MT -->
                  <div v-if="rule.condition.uuid === 'af56f4ee-3104-4e12-9ef4-00b0cda467cd'">
                    <div class="row">
                      <span class="title">When Was The Membership Cancelled?</span>
                      <div class="disable-caps" >{{ rule.cancellation_comparison | timeComponent(rule.cancellation_value, hasSinceEntering = true) }}</div>
                    </div>
                    <div class="row">
                      <span class="title">Which Memberships?</span>
                      <div class="disable-caps" v-if="rule.all_memberships">All Membership(s)</div>
                      <div class="disable-caps" v-if="!rule.all_memberships">Specific Membership(s)</div>
                    </div>
                    <div v-if="!rule.all_memberships" class="row">
                      <span class="title">Which Membership Types?</span>
                      <div class="disable-caps" v-if="rule.membership_type_option === 'any'">All membership types</div>
                      <div v-if="rule.membership_type_option === 'specific'">
                        <div v-if="rule.membership_type_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.membership_type_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="membership in rule.membership_types" :key="membership.id">
                          <div class="disable-caps">{{ membership.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_memberships" class="row">
                      <span class="title disable-caps">Which Membership Status(es)?</span>
                      <div class="disable-caps" v-if="rule.membership_status_option === 'any'">All membership statuses</div>
                      <div v-if="rule.membership_status_option === 'specific'">
                        <div v-if="rule.membership_status_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.membership_status_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="status in rule.membership_statuses" :key="status.id">
                          <div class="disable-caps">{{ status.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_memberships" class="row">
                      <span class="title">When Did They Start Their Membership?</span>
                      <div class="disable-caps" >{{ rule.membership_start_comparison | timeComponent(rule.membership_start_value, hasSinceEntering = true) }}</div>
                    </div>
                  </div>
                  <!-- First Visit MT -->
                  <div v-if="rule.condition.uuid === '5898fb00-771c-44b8-b8f0-0a0f7da1fbc6'">
                    <div class="row">
                      <span class="title">When Did The Visit Occur?</span>
                      <div class="disable-caps" >{{ rule.first_visit_comparison | timeComponent(rule.first_visit_value) }}</div>
                    </div>
                    <div class="row">
                      <span class="title">Which Visits?</span>
                      <div class="disable-caps" v-if="rule.all_first_visits">All visits</div>
                      <div class="disable-caps" v-if="!rule.all_first_visits">Specific visits</div>
                    </div>
                    <div v-if="!rule.all_first_visits" class="row">
                      <span class="title disable-caps">Which Class Name(s)?</span>
                      <div class="disable-caps" v-if="rule.class_name_option === 'any'">All class names</div>
                      <div v-if="rule.class_name_option === 'specific'">
                        <div v-if="rule.class_name_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.class_name_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="class_name in rule.class_names" :key="class_name.id">
                          <div class="disable-caps">{{ class_name.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_first_visits" class="row">
                      <span class="title disable-caps">Which Payment Option(s)?</span>
                      <div class="disable-caps" v-if="rule.payment_option_option === 'any'">All payment options</div>
                      <div v-if="rule.payment_option_option === 'specific'">
                        <div v-if="rule.membership_option_exclusion === 'any' && rule.membership_types_checked" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.membership_option_exclusion === 'except' && rule.membership_types_checked" class="disable-caps">Any except the following:</div>
                        <div v-if="rule.membership_types_checked" v-for="membership in rule.membership_options" :key="membership.id">
                          <div class="disable-caps">{{ membership.name }}</div>
                        </div>
                        <div v-if="rule.credit_option_exclusion === 'any' && rule.credit_types_checked" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.credit_option_exclusion === 'except' && rule.credit_types_checked" class="disable-caps">Any except the following:</div>
                        <div v-if="rule.credit_types_checked" v-for="credit in rule.credit_options" :key="credit.id">
                          <div class="disable-caps">{{ credit.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_first_visits" class="row">
                      <span class="title disable-caps">Which Class Instructor(s)?</span>
                      <div class="disable-caps" v-if="rule.class_instructor_option === 'any'">All class instructors</div>
                      <div v-if="rule.class_instructor_option === 'specific'">
                        <div v-if="rule.class_instructor_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.class_instructor_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="instructor in rule.class_instructors" :key="instructor.id">
                          <div class="disable-caps">{{ instructor.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_first_visits" class="row">
                      <span class="title disable-caps">Which Class Time(s)?</span>
                      <div class="disable-caps" v-if="rule.class_time_option === 'any'">All class times</div>
                      <div v-if="rule.class_time_option === 'specific'">
                        <div v-if="rule.class_time_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.class_time_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="class_time in rule.class_times" :key="class_time.id">
                          <div class="disable-caps">{{ class_time.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- New Profile in MarianaTek MT -->
                  <div v-if="rule.condition.uuid === 'a59c23dc-9207-4690-887e-563af43188e8'">
                    <div class="row">
                      <span class="title">When Was The Profile Created?</span>
                      <div class="disable-caps" >{{ rule.profile_creation_comparison | timeComponent(rule.profile_creation_value) }}</div>
                    </div>
                    <div class="row">
                      <span class="title disable-caps">What Is Their Home Location?</span>
                      <div class="disable-caps" v-if="rule.home_option === 'any'">Any location in my account</div>
                      <div v-if="rule.home_option === 'specific'">
                        <div v-if="rule.home_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.home_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="location in rule.home_locations" :key="location.id">
                          <div class="disable-caps">{{ location.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Credit Package Expiration MT -->
                  <div v-if="rule.condition.uuid === 'ce919016-7dd9-4c50-97d4-64fe6d03f9d2'">
                    <div class="row">
                      <span class="title">When Was The Expiration Date?</span>
                      <div class="disable-caps" >{{ rule.expiration_comparison | timeComponent(rule.expiration_value) }}</div>
                    </div>
                    <div class="row">
                      <span class="title disable-caps">Which Credit Package(s)?</span>
                      <div class="disable-caps" v-if="rule.all_credit_packages">All credit packages</div>
                      <div class="disable-caps" v-if="!rule.all_credit_packages">Specific credit package</div>
                    </div>
                    <div v-if="!rule.all_credit_packages" class="row">
                      <span class="title disable-caps">Which Credit Package?</span>
                      <div class="disable-caps" v-if="rule.credit_package_option === 'any'">All credit packages</div>
                      <div v-if="rule.credit_package_option === 'specific'">
                        <div v-if="rule.credit_package_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.credit_package_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="credit_package in rule.credit_packages" :key="credit_package.id">
                          <div class="disable-caps">{{ credit_package.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_credit_packages" class="row">
                      <span class="title">How Many Classes Were Left When It Expired?</span>
                      <div class="disable-caps" >{{ rule.finish_comparison | countComponent(rule.finish_first_bound, rule.finish_second_bound, 'classes') }}</div>
                    </div>
                  </div>
                   <!-- Membership Expiration MT -->
                  <div v-if="rule.condition.uuid === '253360f9-ec87-4ee4-8067-1b3f75007acf'">
                    <div class="row">
                      <span class="title">When Was The Expiration Date?</span>
                      <div class="disable-caps" >{{ rule.expiration_comparison | timeComponent(rule.expiration_value, hasSinceEntering = true) }}</div>
                    </div>
                    <div class="row">
                      <span class="title disable-caps">Which Membership(s)?</span>
                      <div class="disable-caps" v-if="rule.all_memberships">All memberships</div>
                      <div class="disable-caps" v-if="!rule.all_memberships">Specific membership(s)</div>
                    </div>
                    <div v-if="!rule.all_memberships" class="row">
                      <span class="title disable-caps">Which Type Of Membership?</span>
                      <div class="disable-caps" v-if="rule.membership_type_option === 'any'">All memberships</div>
                      <div v-if="rule.membership_type_option === 'specific'">
                        <div v-if="rule.membership_type_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.membership_type_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="membership in rule.membership_types" :key="membership.id">
                          <div class="disable-caps">{{ membership.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Future Reservation MT -->
                  <div v-if="rule.condition.uuid === '88d36c7a-5151-4e3d-9528-13007b0c5d4e'">
                    <div class="row">
                      <span class="title">Customers Who Have / Do Not Have?</span>
                      <div v-if="rule.hasOwnProperty('has_future_reservation')">
                        <div class="disable-caps" v-if="rule.has_future_reservation">Has a future visit</div>
                        <div class="disable-caps" v-if="!rule.has_future_reservation">Does not have a future visit</div>
                      </div>
                      <div v-else>
                        <div class="disable-caps">Has a future visit</div>
                      </div>
                    </div>
                    <div class="row">
                      <span class="title">What Are You Looking For?</span>
                      <div class="disable-caps" >{{ rule.first_visit_comparison | timeComponent(rule.first_visit_value, hasSinceEntering = true) }}</div>
                    </div>
                    <div class="row">
                      <span class="title disable-caps">Which Visit(s)?</span>
                      <div class="disable-caps" v-if="rule.all_first_visits">All visits</div>
                      <div class="disable-caps" v-if="!rule.all_first_visits">Specific visits</div>
                    </div>
                    <div v-if="!rule.all_first_visits" class="row">
                      <span class="title disable-caps">Which Class Name(s)?</span>
                      <div class="disable-caps" v-if="rule.class_name_option === 'any'">All class names</div>
                      <div v-if="rule.class_name_option === 'specific'">
                        <div v-if="rule.class_name_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.class_name_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="class_name in rule.class_names" :key="class_name.id">
                          <div class="disable-caps">{{ class_name.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_first_visits" class="row">
                      <span class="title disable-caps">Which Payment Option(s)?</span>
                      <div class="disable-caps" v-if="rule.payment_option_option === 'any'">All payment options</div>
                      <div v-if="rule.payment_option_option === 'specific'">
                        <div v-if="rule.membership_option_exclusion === 'any' && rule.membership_types_checked" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.membership_option_exclusion === 'except' && rule.membership_types_checked" class="disable-caps">Any except the following:</div>
                        <div v-if="rule.membership_types_checked" v-for="membership in rule.membership_options" :key="membership.id">
                          <div class="disable-caps">{{ membership.name }}</div>
                        </div>
                        <div v-if="rule.credit_option_exclusion === 'any' && rule.credit_types_checked" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.credit_option_exclusion === 'except' && rule.credit_types_checked" class="disable-caps">Any except the following:</div>
                        <div v-if="rule.credit_types_checked" v-for="credit in rule.credit_options" :key="credit.id">
                          <div class="disable-caps">{{ credit.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_first_visits" class="row">
                      <span class="title disable-caps">Which Class Instructor(s)?</span>
                      <div class="disable-caps" v-if="rule.class_instructor_option === 'any'">All class instructors</div>
                      <div v-if="rule.class_instructor_option === 'specific'">
                        <div v-if="rule.class_instructor_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.class_instructor_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="instructor in rule.class_instructors" :key="instructor.id">
                          <div class="disable-caps">{{ instructor.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_first_visits" class="row">
                      <span class="title disable-caps">Which Class Time(s)?</span>
                      <div class="disable-caps" v-if="rule.class_time_option === 'any'">All class times</div>
                      <div v-if="rule.class_time_option === 'specific'">
                        <div v-if="rule.class_time_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.class_time_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="class_time in rule.class_times" :key="class_time.id">
                          <div class="disable-caps">{{ class_time.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_first_visits" class="row">
                      <span class="title disable-caps">Which Visit Location(s)?</span>
                      <div class="disable-caps" v-if="rule.visit_location_option === 'any'">Any location in my account</div>
                      <div v-if="rule.visit_location_option === 'specific'">
                        <div v-if="rule.visit_location_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.visit_location_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="location in rule.visit_locations" :key="location.id">
                          <div class="disable-caps">{{ location.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_first_visits" class="row">
                      <span class="title disable-caps">Only Include First Reservation Ever?</span>
                      <div class="disable-caps" v-if="rule.first_visit_option">Yes</div>
                      <div class="disable-caps" v-if="!rule.first_visit_option">No</div>
                    </div>
                  </div>
                  <!-- Made A Purchase MT -->
                  <div v-if="rule.condition.uuid === '37a361cc-ded7-48b6-9d70-1c4b7aa08284'">
                    <div class="row">
                      <span class="title">Customers Who Purchased / Did Not Purchase?</span>
                      <div class="disable-caps" v-if="rule.all_made_purchase">Purchased the following</div>
                      <div class="disable-caps" v-if="!rule.all_made_purchase">Did not purchase the following</div>
                    </div>
                    <div class="row">
                      <span class="title">When Was The Purchase Made?</span>
                      <div class="disable-caps" >{{ rule.purchase_comparison | timeComponent(rule.purchase_value, hasSinceEntering = true) }}</div>
                    </div>
                    <div class="row">
                      <span class="title disable-caps">Which Purchase(s)?</span>
                      <div class="disable-caps" v-if="rule.all_purchase_types">All purchases</div>
                      <div class="disable-caps" v-if="!rule.all_purchase_types">Specific purchases</div>
                    </div>
                    <div v-if="!rule.all_purchase_types" class="row">
                      <span class="title disable-caps">Which Type Of Purchase?</span>
                      <div class="disable-caps" v-if="rule.purchase_option === 'any'">All purchases</div>
                      <div v-if="rule.purchase_option === 'specific'">
                        <div v-if="rule.purchase_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.purchase_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="purchase in rule.purchases" :key="purchase.id">
                          <div class="disable-caps">{{ purchase.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_purchase_types" class="row">
                      <span class="title disable-caps">Which Purchase Category?</span>
                      <div class="disable-caps" v-if="rule.purchase_category_option === 'any'">All categories</div>
                      <div v-if="rule.purchase_category_option === 'specific'">
                        <div v-if="rule.purchase_category_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.purchase_category_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="category in rule.purchase_categories" :key="category.id">
                          <div class="disable-caps">{{ category.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!rule.all_purchase_types" class="row">
                      <span class="title disable-caps">Which Purchase Location(s)?</span>
                      <div class="disable-caps" v-if="rule.purchase_location_option === 'any'">Any location in my account</div>
                      <div v-if="rule.purchase_location_option === 'specific'">
                        <div v-if="rule.purchase_location_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.purchase_location_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="location in rule.purchase_locations" :key="location.id">
                          <div class="disable-caps">{{ location.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Is Vimeo Customer -->
                  <div v-if="rule.condition.uuid === '71f9ae9e-b852-40c1-b6c6-f79322f7f4bf'">
                    <div class="row">
                      <span class="title">Is a Customer / Is Not a Customer?</span>
                      <div class="disable-caps" v-if="rule.is_customer">Is a Vimeo Customer</div>
                      <div class="disable-caps" v-if="!rule.is_customer">Is not a Vimeo Customer</div>
                    </div>
                    <div class="row">
                      <span class="title">When did the Contact become a Customer?</span>
                      <div class="disable-caps" >{{ rule.customer_creation_comparison | timeComponent(rule.customer_creation_value) }}</div>
                    </div>
                  </div>
                  <!-- Has Vimeo Subscription  -->
                  <div v-if="rule.condition.uuid === 'feb848d2-d242-44b4-8754-80ef489aa963'">
                    <div class="row">
                      <span class="title">Has / Does not Have a Subscription?</span>
                      <div class="disable-caps" v-if="rule.has_subscription">Has a Subscription</div>
                      <div class="disable-caps" v-if="!rule.has_subscription">Does not Have a Subscription</div>
                    </div>
                    <div class="row">
                      <span class="title">When did the Subscription Start?</span>
                      <div class="disable-caps" >{{ rule.subscription_creation_comparison | timeComponent(rule.subscription_creation_value) }}</div>
                    </div>
                    <div class="row">
                      <span class="title">Which Products is this Subscription for?</span>
                      <div class="disable-caps" v-if="rule.specific_products === 'any'">All products</div>
                      <div class="disable-caps" v-if="rule.specific_products === 'specific'">
                        <div v-if="rule.product_exclusion_option === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.product_exclusion_option === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="product in rule.selected_products" :key="product.id">
                          <div class="disable-caps">{{ product.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <span class="title">Which Statuse(s)</span>
                      <div class="disable-caps" v-if="rule.specific_statuses === 'any'">All statuses</div>
                      <div class="disable-caps" v-if="rule.specific_statuses === 'specific'">
                        <div v-if="rule.status_exclusion_option === 'any'" class="disable-caps">Any of the following:</div>
                        <div v-if="rule.status_exclusion_option === 'except'" class="disable-caps">Any except the following:</div>
                        <div v-for="status in rule.selected_statuses" :key="status.id">
                          <div class="disable-caps">{{ status.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="row" v-if="rule.timeframe">
                    <span class="title" v-if="rule.timeframe">Time Frame:</span>
                    <span v-if="rule.timeframe === 'all'">{{ rule.timeframe | timeFrame }}</span>
                    <span v-if="rule.timeframe === 'relative_future'">
                      {{ rule.comparison === 'more' ? 'At least' : rule.comparison }} {{ rule.relative_days }} Days {{ rule.comparison === 'more' ? 'from now' : rule.timeframe | timeFrame }}
                    </span>
                    <span v-if="rule.timeframe === 'more'">
                      Within the next {{ rule.relative_days }} day{{ rule.relative_days > 1 ? 's' : '' }}
                    </span>
                    <span v-if="rule.timeframe === 'exactly'">
                      Exactly {{ rule.relative_days }} day{{ rule.relative_days > 1 ? 's' : '' }} from now
                    </span>
                    <span v-if="rule.timeframe === 'absolute'">
                      {{ rule.comparison || rule.comparison_date }}
                      <span v-if="rule.start_date">{{ rule.start_date | humanDate(false) }}</span>
                      <span v-if="rule.end_date"> and {{ rule.end_date | humanDate(false) }}</span>
                      <span v-if="rule.start_date_day && rule.start_date_month">{{ rule.start_date_month }} {{ rule.start_date_day }}</span>
                      <span v-if="rule.end_date_day && rule.end_date_month"> and {{ rule.end_date_month }} {{ rule.end_date_day }}</span>
                    </span>
                    <span v-if="rule.timeframe === 'relative'">
                      {{ rule.comparison }}
                      <span v-if="rule.comparison === 'more'">than</span>
                      <span v-if="rule.comparison === 'within'">the last</span>
                      {{ rule.relative_days }} days
                      <span v-if="rule.comparison !== 'within'">ago</span>
                    </span>
                    <span v-if="rule.timeframe === 'entering_campaign'">{{ rule.timeframe | timeFrame }}</span>
                    <span v-if="rule.timeframe === 'future'">In The Future</span>
                  </div>
                  <div class="row" v-if="rule.purchase === true || rule.purchase === false">
                    <span class="title">Purchase:</span>
                    <span>{{ rule.purchase ? 'Made' : 'Has Not Made'}}</span><span v-if="rule.purchase_type === 'specific'">&nbsp;a specific</span><span v-if="rule.purchase_type === 'any'">&nbsp;any</span>&nbsp;purchase
                  </div>
                  <div class="row" v-if="rule.hasOwnProperty('purchased')">
                    <span class="title">Purchase:</span>
                    <span>{{ rule.purchased ? 'Made' : 'Has Not Made'}}</span><span v-if="rule.filter_purchase">&nbsp;a specific</span><span v-if="!rule.filter_purchase">&nbsp;any</span>&nbsp;purchase
                  </div>
                  <div class="row" v-if="rule.pricing_options && rule.pricing_options.length">
                    <span class="title">Pricing Options:</span>
                    <div>
                      <div v-for="pricing_option in rule.pricing_options">{{ pricing_option.name }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.revenue_categories && rule.revenue_categories.length">
                    <span class="title">Revenue Categories:</span>
                    <div>
                      <div v-for="revenue_category in rule.revenue_categories">{{ revenue_category.name }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.hasOwnProperty('finished') || rule.hasOwnProperty('landed') || rule.hasOwnProperty('abandonned') || rule.hasOwnProperty('abandoned')">
                    <span class="title" v-if="rule.hasOwnProperty('finished')">Finished:</span>
                    <span class="title" v-if="rule.hasOwnProperty('landed')">Landed:</span>
                    <span class="title" v-if="rule.hasOwnProperty('abandonned')">Abandonned:</span>
                    <span class="title" v-if="rule.hasOwnProperty('abandoned')">Abandoned:</span>
                    <div v-if="rule.hasOwnProperty('finished')">{{ rule.finished ? 'Have' : 'Have Not' }}</div>
                    <div v-if="rule.hasOwnProperty('landed')">{{ rule.landed ? 'Have' : 'Have Not' }}</div>
                    <div v-if="rule.hasOwnProperty('abandonned')">{{ rule.abandonned ? 'Have' : 'Have Not' }}</div>
                    <div v-if="rule.hasOwnProperty('abandoned')">{{ rule.abandoned ? 'Has' : 'Has Not' }}</div>
                  </div>
                  <div class="row" v-if="rule.landing_pages && rule.landing_pages.length">
                    <span class="title">Landing Pages:</span>
                    <div>
                      <div v-for="l in rule.landing_pages">{{ l.name }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.service_categories && rule.service_categories.length">
                    <span class="title">Service Categories:</span>
                    <div>
                      <div v-for="service_category in rule.service_categories">{{ service_category.name }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.contracts && rule.contracts.length">
                    <span class="title">Contracts:</span>
                    <div>
                      <div v-for="contract in rule.contracts">{{ contract.name }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.hasOwnProperty('auto_pay')">
                    <span class="title">Auto-Pay Only:</span>
                    <div>
                      {{ rule.auto_pay ? 'Yes' : 'No' }}
                    </div>
                  </div>
                  <div class="row" v-if="rule.hasOwnProperty('has_package')">
                    <span class="title">Has Package:</span>
                    <div>{{ rule.has_package === false ? 'No' : 'Yes' }}</div>
                  </div>
                  <div class="row" v-if="rule.selected_packages && rule.selected_packages.length">
                    <span class="title">Packages:</span>
                    <div>
                      <div v-for="pricing_option in rule.selected_packages">{{ pricing_option.name }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.hasOwnProperty('any_timeframe') && rule.any_timeframe === false && rule.timeframe_type && rule.days">
                    <span class="title">Finished in:</span>
                    <div>
                      {{ rule.timeframe_type === 'exactly' ? rule.timeframe_type : rule.timeframe_type + ' than' }} {{ rule.days }} days.
                    </div>
                  </div>
                  <div class="row" v-if="rule.products && rule.products.length">
                    <span class="title">Products:</span>
                    <div>
                      <div v-for="product in rule.products">{{ product.name }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.classes && rule.classes.length">
                    <span class="title">Classes:</span>
                    <div>
                      <div v-for="c in rule.classes">{{ c.name }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.staffes && rule.staffes.length">
                    <span class="title">Staff:</span>
                    <div>
                      <div v-for="c in rule.staffes">{{ c.staff_name || c }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.unsubscribed">
                    <span class="title">Unsubscribed</span>
                    <div>
                      Yes
                    </div>
                  </div>
                  <div class="row" v-if="rule.hasOwnProperty('has_payment')">
                    <span class="title">Payment on File</span>
                    <div v-if="rule.has_payment">Yes</div>
                    <div v-if="rule.has_payment === false">No</div>
                  </div>
                  <div class="row" v-if="rule.hasOwnProperty('future_reservation') && !rule.future_reservation">
                    <span class="title">Has Reservation</span>
                    <div>
                      No
                    </div>
                  </div>
                  <div class="row" v-if="rule.service_names && rule.service_names.length">
                    <span class="title">Service Names:</span>
                    <div>
                      <div v-for="service_name in rule.service_names">{{ service_name }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.services && rule.services.length">
                    <span class="title">Service Names:</span>
                    <div>
                      <div v-for="service_name in rule.services">{{ service_name }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.in_funnel_stage">
                    <span class="title">In Funnel Stage</span>
                    <div>
                      {{ rule.in_funnel_stage ? 'Yes' : 'No' }}
                    </div>
                  </div>
                  <div class="row" v-if="rule.selected_funnels">
                    <span class="title">Funnel Stage</span>
                    <div>
                      <div v-for="stage in rule.selected_funnels">{{ stage.stage }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.comparison_visit || rule.condition.uuid === 'd2b29794-384f-4e75-9ac8-5fe1b4d9c92f'">
                    <span class="title">Visit Details:</span>
                    <span v-if="rule.comparison_visit && rule.comparison_visit !== 'more'">
                      <span>Visited {{ rule.comparison_visit || rule.comparison }}</span>
                      <span v-if="!rule.comparison && rule.comparison_visit && rule.comparison_visit !== 'exactly'">&nbsp;than</span>
                      <span v-if="!rule.comparison_visit && rule.comparison && rule.comparison === 'more'">&nbsp;than</span>
                    </span>
                    <span v-if="rule.comparison_visit && rule.comparison_visit === 'more'">
                      Visited at least
                    </span>
                    <span v-if="(rule.hasOwnProperty('visit_number_start') && rule.visit_number_start !== null) || (rule.visit_number && rule.visit_number !== null)">{{ rule.visit_number_start !== null ? '&nbsp;' + rule.visit_number_start + ' ' : '' }}{{ rule.visit_number }} times</span>
                    <span v-if="rule.comparison === 'within'">Within the last</span>
                    <span v-if="rule.relative_days">&nbsp;{{ rule.relative_days }} days {{ rule.comparison !== 'within' ? 'ago' : '' }}</span>
                    .
                  </div>
                  <div class="row" v-if="rule.visit_comparison || rule.condition.uuid === 'd2b29794-384f-4e75-9ac8-5fe1b4d9c92f'">
                    <span class="title">Visit Details:</span>
                    <span v-if="rule.visit_comparison && rule.visit_comparison !== 'more'">
                      <span>Visited {{ rule.visit_comparison || rule.comparison }}</span>
                      <span v-if="!rule.comparison && rule.visit_comparison && rule.visit_comparison !== 'exactly'">&nbsp;than</span>
                      <span v-if="!rule.visit_comparison && rule.comparison && rule.comparison === 'more'">&nbsp;than</span>
                    </span>
                    <span v-if="rule.visit_comparison && rule.visit_comparison === 'more'">
                      Visited at least
                    </span>
                    <span v-if="(rule.hasOwnProperty('visit_number_start') && rule.visit_number_start !== null) || (rule.visit_number && rule.visit_number !== null)">{{ rule.visit_number_start !== null ? '&nbsp;' + rule.visit_number_start + ' ' : '' }}{{ rule.visit_number }} times</span>
                    <span v-if="rule.comparison === 'within'">Within the last</span>
                    <span v-if="rule.relative_days">&nbsp;{{ rule.relative_days }} days {{ rule.comparison !== 'within' ? 'ago' : '' }}</span>
                    .
                  </div>
                  <div class="row" v-if="rule.hasOwnProperty('is_member')">
                    <span class="title">Is A Member:</span>
                    <div>
                      {{ rule.is_member ? 'Yes' : 'No' }}
                    </div>
                  </div>
                  <div class="row" v-if="rule.hasOwnProperty('is_guardian')">
                    <span class="title">Is A Guardian:</span>
                    <div>
                      {{ rule.is_guardian ? 'Yes' : 'No' }}
                    </div>
                  </div>
                  <div class="row" v-if="rule.hasOwnProperty('location')">
                    <span class="title">Location:</span>
                    <div v-if="rule.location">
                      {{ rule.location.location_name }}
                    </div>
                    <div v-if="!rule.location">
                      No Preferred Location
                    </div>
                  </div>
                  <div class="row" v-if="rule.misc && rule.misc.filtering">
                    <span class="title">Location(s)</span>
                    <div v-if="rule.misc.location">
                      <span>ID: {{ rule.misc.location }}</span>
                    </div>
                  </div>
                  <div class="row" v-if="rule.keyword">
                    <span class="title">Keyword</span>
                    <div>{{ rule.keyword }}</div>
                  </div>
                  <div class="row" v-if="rule.membership_type && rule.membership_type.length">
                    <span class="title">Membership Types:</span>
                    <div>
                      <div v-for="mt in rule.membership_type">{{ mt.type }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.plans && rule.plans.length">
                    <span class="title">Plans:</span>
                    <div>
                      <div v-for="p in rule.plans">{{ p }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.products && rule.products.length">
                    <span class="title">Products:</span>
                    <div>
                      <div v-for="p in rule.products">{{ p.name }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.membership_status && rule.membership_status.length">
                    <span class="title">Membership Statuses:</span>
                    <div>
                      <div v-for="ms in rule.membership_status">{{ ms.status }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.selected_indexes && rule.selected_indexes.length">
                    <span class="title">Client Indexes:</span>
                    <div>
                      <div v-for="si in rule.selected_indexes">{{ si.name }} - {{ si.value }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.hasOwnProperty('has_tag')">
                    <span class="title">Has Tags:</span>
                    <div>
                      {{ rule.has_tag ? 'Yes' : 'No' }}
                    </div>
                  </div>
                  <div class="row" v-if="rule.selected_tags">
                    <span class="title">Tags:</span>
                    <div>
                      <div v-for="tag in rule.selected_tags">{{ tag.name }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.selected_genders && rule.selected_genders.length">
                    <span class="title">Genders:</span>
                    <div>
                      <div v-for="g in rule.selected_genders">{{ g.gender }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.hasOwnProperty('is_prospect')">
                    <span class="title" v-if="rule.is_prospect === true">Is a prospect:</span>
                    <span class="title" v-if="rule.is_prospect === false">Is not a prospect:</span>
                  </div>
                  <div class="row" v-if="rule.hasOwnProperty('visited') && Array.isArray(rule.status)" v-for="st in rule.status">
                    <span class="title">{{ st }}:</span>
                    <div>{{ rule.visited ? 'Has ' + st : 'Has Not ' + st }}</div>
                  </div>
                  <div class="row" v-if="rule.hasOwnProperty('visited') && !Array.isArray(rule.status)">
                    <span class="title">{{ rule.status }}:</span>
                    <div>{{ rule.visited ? 'Has ' + rule.status : 'Has Not ' + rule.status }}</div>
                  </div>
                  <div class="row" v-if="rule.hasOwnProperty('is_unsubscribed')">
                    <span class="title">Unsubscribed</span>
                    <div>{{ rule.is_unsubscribed ? 'Yes' : 'No' }}</div>
                  </div>
                  <div class="row" v-if="rule.hasOwnProperty('has_account_credit')">
                    <span class="title">Has Account Credit</span>
                    <div>{{ rule.has_account_credit ? 'Yes' : 'No' }}</div>
                  </div>
                  <div class="row" v-if="rule.hasOwnProperty('in_segment')">
                    <span class="title">In Segments:</span>
                    <div>{{ rule.in_segment ? 'Yes' : 'No' }}</div>
                  </div>
                  <div class="row" v-if="rule.selected_segments && rule.selected_segments.length">
                    <span class="title">Segments:</span>
                    <div>
                      <div v-for="s in rule.selected_segments">{{ s.name }}</div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.condition.uuid === '9ebf3698-2407-4d70-97f1-afa09adddac9'">
                    <span class="title">Interaction</span>
                    <div>{{ rule.interacted ? 'Has Interacted' : 'Has Not Interacted'}}</div>
                  </div>
                  <div class="row" v-if="rule.condition.uuid === '9ebf3698-2407-4d70-97f1-afa09adddac9' && rule.action">
                    <span class="title">Action</span>
                    <div>{{ rule.action }}</div>
                  </div>
                  <div class="row" v-if="rule.condition.uuid === '9ebf3698-2407-4d70-97f1-afa09adddac9' && rule.filtering === 'all'">
                    <span class="title">Filtering</span>
                    <div>All Emails</div>
                  </div>
                  <div class="row" v-if="rule.condition.uuid === '9ebf3698-2407-4d70-97f1-afa09adddac9' && rule.filtering === 'specific'">
                    <span class="title">Filtered</span>
                    <div>
                      <div v-for="email in rule.emails">
                        {{ email.name }}
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.condition.uuid === 'ac08df3e-b14a-4760-8068-54585cb19d9e' && rule.forms">
                    <span class="title">Forms</span>
                    <div>
                      <div v-for="form in rule.forms">
                        {{ form.name }}
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.condition.uuid === '7302c598-ab99-4091-9090-69850d4dc50e' && rule.forms">
                    <span class="title">Forms:</span>
                    <div>
                      <div v-for="form in rule.forms">
                        {{ form.name }}
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="rule.condition.uuid === 'b4d57f9f-2515-42d3-8e17-4e48d106586e'">
                    <span class="title">Field</span>
                    <div>
                      {{ rule.field.label }}
                    </div>
                  </div>
                  <div class="row" v-if="rule.condition.uuid === 'b4d57f9f-2515-42d3-8e17-4e48d106586e'">
                    <span class="title">Filtering</span>
                    <div>
                      {{ rule.option ? rule.option.replace('-', ' ') : '' }}
                    </div>
                  </div>
                  <div class="row" v-if="rule.form">
                    <span class="title">Form:</span>
                    <div>{{ rule.form.name }}</div>
                  </div>
                  <div class="row" v-if="rule.field">
                    <span class="title">Field:</span>
                    <div>{{ rule.field.label }}</div>
                  </div>
                  <div class="row" v-if="rule.condition.uuid === 'b4d57f9f-2515-42d3-8e17-4e48d106586e'">
                    <span class="title">Value</span>
                    <div>
                      <span v-if="rule.field.type === 'string'">{{ rule.value }}</span>
                      <span v-if="rule.field.type === 'boolean'">{{ rule.value }}</span>
                      <span v-if="rule.field.type === 'date'">{{ rule.value_date }}</span>
                    </div>
                  </div>
                  <!-- <div class="row" v-if="rule.condition.uuid === 'e3d9c44d-a03c-4979-8a35-89c8003b2fb8'">
                    <div class="title">Filtering</div>
                    <span v-if="rule.exclusion === 'except'">Any except the following</span>
                    <span v-if="rule.exclusion === 'any'">Any of the following</span>
                  </div>
                  <div class="row" v-if="rule.condition.uuid === 'e3d9c44d-a03c-4979-8a35-89c8003b2fb8'">
                    <span class="title">Locations</span>
                    <span>
                      <div v-for="location in rule.locations">
                        {{ location.name }}
                      </div>
                    </span>
                  </div> -->
                  <!-- <div class="row" v-if="rule.condition.uuid === '5005b33e-6ea6-45fc-affd-4b2f184efebf' && rule.locations && rule.locations.length">
                    <span class="title">Locations</span>
                    <span>
                      <div v-for="location in rule.locations">
                        {{ location.name }}
                      </div>
                    </span>
                  </div>
                  <div class="row" v-if="rule.condition.uuid === '5005b33e-6ea6-45fc-affd-4b2f184efebf'">
                    <span class="title">Visit Details</span>
                    <span>
                      <span v-if="rule.comparison === 'exactly'">Exactly {{ rule.first_bound }} visits</span>
                      <span v-if="rule.comparison === 'moreThan'">More than {{ rule.first_bound }} visits</span>
                      <span v-if="rule.comparison === 'lessThan'">Less than {{ rule.first_bound }} visits</span>
                      <span v-if="rule.comparison === 'between'">Between {{ rule.first_bound }} and {{ rule.second_bound }} visits</span>
                    </span>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="and-separator">AND</div>
          </div>
        </div>
        <img src="https://assets.brandbot.com/public/empty-states/empty-rule.svg" class="empty" v-if="!contact_list.rule || !contact_list.rule.length" />
        <button type="button" class="xpl-button xpl-button--secondary" v-if="!contact_list.rule || !contact_list.rule.length" @click="add_condition = true">Add a condition</button>
        <button type="button" class="xpl-button xpl-button--secondary" v-if="contact_list.rule && contact_list.rule.length" @click="add_condition = true">Add another condition</button>
        <div class="spacer"><hr/></div>
      </div>
      <div class="conditions-sidebar-footer">
        <button type="button" class="xpl-button xpl-button--primary" @click="saveSmartRule">Save</button>
      </div>
    </div>
    <ConditionsSelection v-if="add_condition" :selectedCondition.sync="selectedCondition" :editedRule.sync="editedRule" :enrollment="enrollment" :goalList="goalList" :campaignItem="campaignItem" :locations="locations" />
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import moment from 'moment';
  import * as api from '@/api';
  import GlobalRuleHeader from '@/components/Global/GlobalRuleHeader';
  import ConditionsSelection from './ConditionsSelection';


  export default {
    props: ['contact_list', 'enrollment', 'goalList', 'campaignItem'],
    data() {
      return {
        add_condition: false,
        rules: [],
        selectedCondition: null,
        editedRule: null,
        editedRuleIndex: null,
        locations: [],
        marianaTest: false,
      };
    },
    components: {
      ConditionsSelection,
      GlobalRuleHeader,
    },
    mounted() {
      this.fetchLocations();
    },
    created() {
      // Stop scrolling
      document.body.classList.add('stop-scroll');

      EventBus.$on('conditions_selection.pick', (condition) => {
        this.selectedCondition = condition;
      });

      EventBus.$on('selected_condition.close', () => {
        this.add_condition = false;
        this.selectedCondition = null;
        this.editedRule = null;
      });

      EventBus.$on('condition_selection.back', () => {
        this.selectedCondition = null;
        this.editedRule = null;
      });

      EventBus.$on('conditions_selection.commit', (payload) => {
        // If we're editing, let's simply replace the values we're editing
        if (this.editedRule) {
          this.contact_list.rule[this.editedRuleIndex] = payload;
        }

        if (this.contact_list.rule && !this.editedRule) {
          // We replace similar one if it exists AND if it is supposed to be unique
          if (this.contact_list.rule.filter(r =>
            r.condition.uuid === payload.condition.uuid,
            ).length &&
            payload.condition.unique) {
            this.contact_list.rule = this.contact_list.rule.filter(r =>
              r.condition.uuid !== payload.condition.uuid,
            );

            this.contact_list.rule.push(payload);
          } else {
            // Or we simply push if new
            this.contact_list.rule.push(payload);
          }
        } else if (!this.contact_list.rule && !this.editedRule) {
          // If current rule is empty, we initialize and push to.
          this.contact_list.rule = [];
          this.contact_list.rule.push(payload);
        }

        // Reset data holders to initial state.
        this.add_condition = false;
        this.selectedCondition = null;
        this.editedRule = null;
        this.editedRuleIndex = null;
      });
    },
    beforeDestroy() {
      document.body.classList.remove('stop-scroll');
      EventBus.$off('conditions_selection.commit');
      EventBus.$off('selected_condition.close');
      EventBus.$off('conditions_selection.pick');
      EventBus.$off('condition_selection.back');
    },
    methods: {
      fetchLocations() {
        api.getContactListsAvailableConditionTypes().then((response) => {
          this.locations = response.locations;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      closeSidebar() {
        EventBus.$emit('contact_list.conditions_sidebar.close');
      },
      deleteCondition(index, confirm = false) {
        if (!confirm) {
          this.$set(this.contact_list.rule[index], 'confirm', true);
          return;
        }

        if (confirm) {
          this.contact_list.rule.splice(index, 1);
        }
      },
      editCondition(rule, index) {
        this.add_condition = true;
        this.selectedCondition = rule.condition;
        this.editedRule = rule;
        this.editedRuleIndex = index;
        EventBus.$emit('selected_condition.edit', rule);
      },
      saveSmartRule(event) {
        const target = event.srcElement || event.target;
        const originalInner = target.innerHTML;

        target.disabled = true;
        target.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';

        const payload = {
          contact_list_id: this.contact_list.id,
          rule: JSON.stringify(this.contact_list.rule),
        };

        api.saveSmartRule(payload).then((response) => {
          this.$toasted.success(response.message);
          EventBus.$emit('contact_list.refresh');
          this.closeSidebar();
          target.disabled = false;
          target.innerHTML = originalInner;
        }).catch((err) => {
          this.$toasted.error(err.message);
          target.innerHTML = originalInner;
          target.disabled = false;
        });
      },
    },
    filters: {
      timeFrame(value) {
        if (value === 'all') {
          return 'All Time';
        }

        if (value === 'entering_campaign') {
          return 'Since entering this campaign';
        }

        if (value === 'relative_future') {
          return 'From now';
        }

        return value;
      },
      countComponent(filter, boundOne, boundTwo, label) {
        if (filter === 'atLeast') {
          return `At least ${boundOne} ${label}`;
        }

        if (filter === 'lessThan') {
          return `Less than ${boundOne} ${label}`;
        }

        if (filter === 'exactly') {
          return `Exactly ${boundOne} ${label}`;
        }

        if (filter === 'between') {
          return `Between ${boundOne} and ${boundTwo} ${label}`;
        }

        return boundTwo;
      },
      timeComponent(option, value, hasSinceEntering) {
        if (option === 'withinTheNext') {
          return `Within the next ${value} days`;
        }

        if (option === 'atLeastFuture') {
          return `At least ${value} days in the future`;
        }

        if (option === 'exactlyFuture') {
          return `Exactly ${value} days in the future`;
        }

        if (option === 'sinceEntering' && hasSinceEntering) {
          return 'Since entering campaign';
        }

        if (option === 'withinTheLast') {
          return `Within the last ${value} days`;
        }

        if (option === 'atLeastPast') {
          return `At least ${value} days in the past`;
        }

        if (option === 'exactlyPast') {
          return `Exactly ${value} days in the past`;
        }

        if (option === 'before') {
          return `Before ${moment(value).format('MMM Do YYYY')}`;
        }

        if (option === 'after') {
          return `After ${moment(value).format('MMM Do YYYY')}`;
        }

        if (option === 'specificDate') {
          return `On ${moment(value).format('MMM Do YYYY')}`;
        }

        if (option === 'allTime' || (option === 'sinceEntering' && !hasSinceEntering)) {
          return 'All Time';
        }

        if (option === 'between') {
          return `From ${moment(value[0]).format('MMM Do YYYY')} to ${moment(value[1]).format('MMM Do YYYY')}`;
        }
        return option;
      },
    },
  };
</script>
<style scoped lang="scss">
  .conditions-sidebar {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2000;
    width: 48rem;
    height: 100vh;
    background-color: #F4F8FA;
    border-left: 1px solid lightgray;
    border-radius: 6px;
    overflow-x: hidden;
    overflow-y: hidden;
    .conditions-sidebar-body {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow-y: scroll;
      height: calc(100vh - 135px);
      .conditions-rule {
        padding-bottom: 1rem;
      }
      .spacer {
        margin-top: 48px;
      }
    }
  }

  .conditions-sidebar-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: white;
    padding: 1rem;
    padding-right: 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .conditions-rule {
    width: 100%;
    margin-bottom: 1rem;
  }

  .conditions-rule .rule {
    width: 100%;
    background-color: #ffffff;
    // box-shadow: 0 1px 0 0 rgba(217, 231, 240, 0.5);
    border: solid 1px var(--xpl-color-gray-400);
    transition: all 150ms ease-in-out;
    margin-bottom: 1rem;
  }

  .conditions-rule .rule .confirm-header {
    display: none;
  }

  .conditions-rule .rule.confirm,
  .conditions-rule .rule.confirm:hover {
    border-color: #ec6b6b;
  }

  .conditions-rule .rule.confirm .confirm-header {
    display: block;
    background-color: #ec6b6b;
    color: white;
    padding: 1rem;
    padding-top: .75rem;
  }

  .confirm-header .actions {
    margin-top: .625rem;
  }

  .conditions-rule .rule:last-child {
    margin-bottom: 0;
  }

  .conditions-rule .rule:hover {
    border: 1px solid #579dd5;
    // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  }

  .conditions-rule .rule .header {
    padding: 1rem;
    padding-bottom: 0;
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.25rem;
    color: #1d364b;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .conditions-rule .rule .header .remove {
    cursor: pointer;
    font-size: .5rem;
    color: #acc4d1;
    text-transform: uppercase;
    min-width: 4rem;
  }

  .conditions-rule .rule .header .icon {
    margin-left: .5rem;
  }

  .header img {
    width: 1rem;
    height: 1rem;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: .25rem;
  }

  .conditions-rule .rule .body-container {
    padding: 1rem;
    padding-top: 0;
  }

  .conditions-rule .rule .body-container .body {
    padding-top: 1rem;
    text-transform: capitalize;
    color: #8b9bac;
    font-size: .75rem;
    line-height: 1rem;
    padding-bottom: 1rem;
    border-radius: 6px;
  }

  .conditions-rule .rule .body-container .body .title {
    font-weight: 700;
    color: #617080;
    min-width: 8rem;
    width: 18rem;
    margin-right: .5rem;
  }

  .conditions-rule .rule .body-container .body .disable-caps {
    text-transform: none;
  }

  .conditions-rule .rule .body-container .body .row {
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 1.25rem;
    margin-bottom: .25rem;
  }

  .conditions-rule .rule .body-container .body .row:last-child {
    margin-bottom: 0;
  }

  .conditions-rule .rule .body {
    border: solid 1px var(--xpl-color-gray-400);
    padding: .75rem;
    padding-top: .25rem;
    font-size: .75rem;
    font-weight: 700;
    line-height: 1rem;
    color: #1d364b;
    text-transform: capitalize;
    margin-top: .5rem;
    cursor: pointer;
  }

  .icon-cancel-square {
    cursor: pointer;
    color: #afb7c7;
  }

  .and-separator {
    color: #617080;
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1rem;
    margin-top: 1rem;
    text-align: center;
  }

  .conditions-rule > div:last-child .and-separator {
    display: none;
  }

  img.empty {
    width: 15rem;
    margin-bottom: 2rem;
  }
</style>
