<template>
  <div>
    <div data-test-main-header class="display-medium title" style="margin-bottom: 2rem;">
      Form Insights
      <el-button data-test-export-button type="secondary" @click="fetchExport" :loading="exportLoading">Export to CSV</el-button>
    </div>
    <el-row :span="24">
      <el-col :span="24">
        <el-card>
          <div data-test-stat-number class="text-center display-medium">{{ answers.length | prettyNumber }}</div>
          <div data-test-all-time class="text-center display-caption subdued">All Time</div>
        </el-card>
      </el-col>
    </el-row>
    <el-card style="margin-top: 2rem;">
      <el-table  data-test-table v-if="answers.length" :data="answers">
        <el-table-column data-test-created-at-col fixed prop="created_at" :key="created_at" :label="'Date'" :width="200" sortable :formatter="humanDate"></el-table-column>
        <el-table-column data-test-contact-col fixed prop="contact" :key="contact" :label="'Contact'" :width="200">
          <template data-test-contact-info slot-scope="scope">
            <a :href="'/contacts/' + scope.row.contact.uuid" target="_blank">{{ scope.row.contact.fullname || scope.row.contact.email }}</a>
          </template>
        </el-table-column>
        <el-table-column data-test-custom-coll v-for="col in custom_fields" :prop="col.label" :key="col.label" :label="col.label" :width="200">
          <template slot-scope="scope">
            {{ scope.row[col.label] }}
          </template>
        </el-table-column>
      </el-table>
      <div data-test-no-answer  v-if="!answers.length" class="text-center">
        No data has been collected yet
      </div>
    </el-card>
  </div>
</template>
<script>
  import { getFormInsights, exportFormInsights } from '@/api';

  export default {
    props: ['form'],
    data() {
      return {
        exportLoading: false,
        loading: false,
        all_time: {},
        answers: [],
        custom_fields: [],
      };
    },
    created() {
      this.fetchInsights();
    },
    methods: {
      humanDate(row) {
        return this.$options.filters.humanDate(row.created_at);
      },
      fetchExport() {
        this.exportLoading = true;
        exportFormInsights(this.form.id).then((response) => {
          this.exportLoading = false;
          this.$message.success('Downloading...');
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'export.csv');
          link.click();
          window.URL.revokeObjectURL(url);
        }).catch(() => {
          this.exportLoading = false;
          this.$message.error('We are having issues exporting your data. Please try again later.');
        });
      },
      fetchInsights() {
        getFormInsights(this.form.id).then((response) => {
          this.all_time = response.all_time;
          this.answers = response.answers;
          this.custom_fields = response.custom_fields;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss">
  .el-table .cell {
    word-break: break-word !important;
  }
</style>
<style lang="scss" scoped>
  .display-medium {
    margin-bottom: 1rem;
  }

  .display-medium.title {
    display: flex;
    justify-content: space-between;
  }
</style>
