import Vuex from 'vuex';
import Vue from 'vue';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

Vue.use(Vuex);

const state = {
  isLoading: false,
  isDev: process.env.NODE_ENV === 'development',
  isLoggedIn: !!localStorage.getItem('access_token'),
  access_token: localStorage.getItem('access_token'),
  refresh_token: localStorage.getItem('refresh_token'),
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
  socket: {
    isConnected: false,
    message: '',
    reconnectError: false,
  },
  cache: {},
  anonymous: false,
};

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
});
