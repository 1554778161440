<template>
  <div>
    <div class="card">
      <div class="card-header card-header-merged card-header-secondary">Email Settings</div>
      <div class="card-body email">
        <div class="title">Verified Emails</div>
        <div class="small">You are only able to send emails through verified email addresses.</div>
        <form @submit.prevent>
          <div class="emails">
            <div class="email" v-for="email in emails">
              {{ email.email }}
              <span class="material-icons" @click.prevent="removeVerifiedEmail(email)">delete</span>
            </div>
          </div>
          <el-button type="secondary" class="xpl-button xpl-button--secondary"  @click="show_verified_email_modal = true">Add another verified email address</el-button>
          <VerifiedEmailModal v-if="show_verified_email_modal" />
        </form>

        <div class="title" style="margin-top: 2.5rem;">Default Sending Profile</div>
        <div class="small">This will populate on all new emails</div>
        <form @submit.prevent v-if="account_emails_metadata">
          <div class="form-group">
            <label>Default From Name</label>
            <el-input type="text" v-model="account_emails_metadata.from_name" placeholder="From Name" />
          </div>

          <div class="form-group">
            <label>Default From Address</label>
              <el-select v-model="account_emails_metadata.from_address" placeholder="Select an email" style="width: 100%;" value-key="email">
                <el-option v-for="email in emails" :key="email.email" :label="email.email" :value="email"></el-option>
              </el-select>
          </div>

          <div class="form-group">
            <label>Default Reply-To</label>
            <el-input type="text" v-model="account_emails_metadata.replyto_address" placeholder="Reply-To" />
          </div>

          <div class="form-group">
            <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="saveSettings">Save</el-button>
            <el-button type="secondary" class="xpl-button xpl-button--secondary"  @click.stop="applyToItems" :loading="loading">Apply to Existing Items</el-button>
          </div>
        </form>
      </div>
    </div>
    <div class="card" v-if="account_emails_metadata">
      <div class="card-header card-header-merged card-header-secondary">Footer Settings</div>
      <div class="card-body">
        <form @submit.prevent>
          <div class="form-group">
            <label>Company Name</label>
            <el-input type="text" v-model="account_emails_metadata.name_footer" />
          </div>
        </form>
        <div class="title" style="margin-top: 1.5rem;">Physical Address</div>
        <div class="small">CAN-SPAM laws require adding a physical address to every email</div>
        <form @submit.prevent>
          <div class="form-group">
            <label>Address 1</label>
            <el-input type="text" v-model="account.address_1" placeholder="Address 1" />
          </div>
          <div class="form-group">
            <label>Address 2</label>
            <el-input type="text" v-model="account.address_2" placeholder="Address 2" />
          </div>
          <div class="form-group">
            <label>State</label>
            <el-input type="text" v-model="account.state" placeholder="State" />
          </div>
          <div class="form-group">
            <label>City</label>
            <el-input type="text" v-model="account.city" placeholder="City" />
          </div>
          <div class="form-group">
            <label>Zip Code / Postal Code</label>
            <el-input type="text" v-model="account.zip_code" placeholder="Zipcode" />
          </div>
          <div class="form-group">
            <label>Country</label>
            <el-select v-model="account.country" style="width: 100%;">
              <el-option v-for="country in countries" :label="country.label" :value="country.value" :key="country.value"></el-option>
            </el-select>
          </div>
          <div class="form-group">
            <el-button type="primary" class="xpl-button xpl-button--primary" @click="saveFooterSettings">Save</el-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import countries from '@/components/Auth/countries.json';
  import VerifiedEmailModal from './VerifiedEmailModal';

  export default {
    components: {
      VerifiedEmailModal,
    },
    data() {
      return {
        default_from_email: null,
        emails: [],
        account_emails_metadata: null,
        countries,
        country: '',
        account: this.$store.state.user.current_account,
        show_verified_email_modal: false,
        loading: false,
      };
    },
    mounted() {
      EventBus.$on('verified_emails_modal.close', () => {
        this.show_verified_email_modal = false;
        this.fetchVerifiedEmails();
      });
    },
    created() {
      this.country = this.countries.filter(c => c.value === this.account.country)[0];
      api.getAccountEmailMetadata().then((response) => {
        this.account_emails_metadata = response.account_emails_metadata;
        this.fetchVerifiedEmails();
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    beforeDestroy() {
      EventBus.$off('verified_emails_modal.close');
    },
    methods: {
      applyToItems() {
        this
        .$confirm('Are you sure you want to apply your new Default Sending Profile to all existing Campaign Items?')
        .then(() => {
          this.loading = true;
          const payload = {
            from_address_id: this.account_emails_metadata.from_address.id,
            from_name: this.account_emails_metadata.from_name,
            replyto_address: this.account_emails_metadata.replyto_address,
          };

          api.updateAccountEmailMetadata(this.account_emails_metadata.id, payload).then(() => {
            api.applyEmailToItems().then((response) => {
              this.loading = false;
              this.$message.success(response.message);
            }).catch((err) => {
              this.loading = false;
              this.$message.error(err.message);
            });
          }).catch((err) => {
            this.$message.error(err.message);
          });
        });
      },
      fetchVerifiedEmails() {
        api.getVerifiedEmails().then((response) => {
          this.emails = response.emails;
          const email = this.account_emails_metadata.from_address;
          this.account_emails_metadata.from_address = this.emails.filter(e => e.email === email)[0];
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      removeVerifiedEmail(email) {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to remove this verified email?')) {
          return;
        }

        api.deleteVerifiedEmails(email.id).then((response) => {
          this.$toasted.success(response.message);
          this.fetchVerifiedEmails();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      saveSettings() {
        const payload = {
          from_address_id: this.account_emails_metadata.from_address.id,
          from_name: this.account_emails_metadata.from_name,
          replyto_address: this.account_emails_metadata.replyto_address,
        };

        api.updateAccountEmailMetadata(this.account_emails_metadata.id, payload).then((response) => {
          this.$toasted.success(response.message);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      saveFooterSettings() {
        const payload = {
          address_1: this.account.address_1,
          address_2: this.account.address_2,
          state: this.account.state,
          city: this.account.city,
          zip_code: this.account.zip_code,
          country: this.account.country,
          name_footer: this.account_emails_metadata.name_footer,
        };

        api.updateAccount(this.account.id, payload).then((response) => {
          this.$toasted.success(response.message);
          this.$store.dispatch('refreshUser');
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .card-body .title {
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.43;
    color: rgb(29, 54, 75);
    margin-bottom: .25rem;
  }

  .card-body .title:last-child {
    margin-top: 2.5rem;
  }

  form {
    max-width: 50%;
  }

  .card-body.email form {
    margin-top: 1.5rem;
  }

  .emails {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .emails .email {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-right: 1rem;
    border: solid 1px rgb(231, 238, 245);
    font-size: .875rem;
    line-height: 1.43;
    color: rgb(51, 51, 51);
    margin-bottom: .5rem;
  }

  .emails .email .material-icons {
    cursor: pointer;
    color:rgb(139, 155, 172);
    font-size: .875rem;
  }

  .card + .card {
    margin-top: 2.5rem;
  }
</style>
