<template>
  <div>
    <el-container>
      <el-header>
        <div class="display-medium">Auto-Campaigns</div>
      </el-header>
      <el-container class="inner">
        <el-aside width="17rem">
          <div :class="['item', currentFolder ? '' : 'active']" @click.stop="fetchCampaigns">
            All Auto-Campaigns
          </div>

          <div class="heading">Auto-Campaigns Groups</div>

          <div :class="['item', currentFolder === folder.id ? 'active' : '']" v-for="folder in folders" :key="folder.id" @click.stop="fetchCampaignsInGroup(folder.id)">
            {{ folder.name }}
            <span class="count" v-if="folder.count">{{ folder.count }}</span>
          </div>

          <a href="#" @click.stop="newGroup">Add a new Auto-Campaigns Group</a>
        </el-aside>
        <el-main v-loading="!loaded">
          <div v-if="loaded">
            <el-row :gutter="24" class="heading">
              <el-col :span="12">
                <div class="display-medium">
                  {{ currentFolderName }}
                </div>
              </el-col>
              <el-col :span="8" :offset="4" style="display: flex; justify-content: flex-end;" v-if="currentFolder">
                <div class="dropdown" style="margin-right: .5rem;">
                  <button type="button" class="xpl-button xpl-button--secondary" @click.stop="show_options = !show_options">Options</button>
                  <div :class="['dropdown-menu', show_options ? 'show' : '']">
                    <a href="#" class="dropdown-item" @click.stop="renameGroup">Rename</a>
                    <a href="#" class="dropdown-item text-danger" @click.stop="archiveGroup">Archive</a>
                  </div>
                </div>
                <button type="button" class="xpl-button xpl-button--primary" @click="show_create_modal = true">Create an Auto-Campaign</button>
              </el-col>
              <el-col :span="8" :offset="4" style="text-align: right;" v-if="!currentFolder">
                <button type="button" class="xpl-button xpl-button--primary" @click="show_create_modal = true">Create an Auto-Campaign</button>
              </el-col>
            </el-row>

            <EmptyState v-if="!campaigns.length && !currentFolder" />

            <el-row :gutter="24" class="search">
              <el-col :span="24">
               <el-input prefix-icon="el-icon-search" placeholder="Search" v-model="query" v-if="campaigns.length" ref="campaignSearch" ></el-input>
              </el-col>
            </el-row>

            <!-- <el-row :gutter="24">
              <el-col :span="24">
                <div class="alert-warning">
                  <div class="alert-title">Pause All Live Auto-Campaigns for COVID-19</div>
                  Many customers are electing to temporarily pause their live automations during this difficult time. By clicking below, all live automations will be paused but marked as paused temporarily. This will allow the {{$store.getters.brandName}} team to unpause all of your current live automations when this all passes.
                  <div style="margin-top: 1rem">
                    <el-button style="margin-bottom: 1rem;" type="warning" size="small" @click.stop="pauseAllCampaigns" :loading="loadingPause">Pause all live automations</el-button>
                  </div>
                </div>
              </el-col>
            </el-row> -->

            <el-dropdown trigger="click" class="move-to" v-if="selectedCampaigns.length">
              <el-button type="secondary" class="xpl-button xpl-button--secondary"  size="small">
                Move To <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="folder in folders" :key="folder.id">
                  <a href="#" @click.prevent="moveToGroup(folder.id)">{{ folder.name }}</a>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <el-button type="danger" size="small" v-if="selectedCampaigns.length && currentFolder" @click="removeFromGroup">
              Remove
            </el-button>

            <draggable v-model="campaigns" @end="updatedOrder">
              <div class="v2__card" v-for="campaign in filteredCampaigns" :key="campaign.id" :data-uuid="campaign.uuid">
                <div class="card-body">
                  <el-row :gutter="24">
                    <el-col :span="1">
                      <el-checkbox v-model="campaign.selected"></el-checkbox>
                    </el-col>
                    <el-col :span="1">
                      <span class="material-icons">drag_indicator</span>
                    </el-col>
                    <el-col :span="1">
                      <img src="https://assets.brandbot.com/public/icons/scheduled-items/draft.svg" v-if="!campaign.turned_on_at && !campaign.paused_at" />
                      <img src="https://assets.brandbot.com/public/icons/scheduled-items/play.svg" v-if="campaign.turned_on_at && !campaign.paused_at" />
                      <img src="https://assets.brandbot.com/public/icons/scheduled-items/paused.svg" v-if="!campaign.turned_on_at && campaign.paused_at" />
                    </el-col>
                    <el-col :span="9">
                      <div class="name" @click.stop="$router.push('/campaigns/' + campaign.uuid)">{{ campaign.name }}</div>
                      <div class="state draft" v-if="!campaign.turned_on_at && !campaign.paused_at">Draft</div>
                      <div class="state active" v-if="campaign.turned_on_at && !campaign.paused_at">Active</div>
                      <div class="state paused" v-if="!campaign.turned_on_at && campaign.paused_at">Paused</div>
                      <div class="display-caption subdued" v-if="!campaign.turned_on_at && !campaign.paused_at">Created on {{ campaign.created_at | humanDateTime }}</div>
                      <div class="display-caption subdued" v-if="campaign.turned_on_at && !campaign.paused_at">Last set live on {{ campaign.turned_on_at | humanDateTime }}</div>
                      <div class="display-caption subdued" v-if="!campaign.turned_on_at && campaign.paused_at">Last paused on {{ campaign.paused_at | humanDateTime }}</div>
                    </el-col>
                    <el-col :span="3" :offset="2" class="stats">
                      <div class="in" v-if="campaign.current">{{ campaign.current | prettyNumber }}</div>
                      <div class="in" v-else>&mdash;</div>
                      <div class="display-caption">Current</div>
                    </el-col>
                    <el-col :span="3" class="stats">
                      <div class="all" v-if="campaign.total">{{ campaign.total | prettyNumber }}</div>
                      <div class="all" v-else>&mdash;</div>
                      <div class="display-caption">All Time</div>
                    </el-col>
                    <el-col :span="3" class="stats">
                      <div class="conversion" v-if="campaign.goal && !campaign.no_goals">
                        {{ campaign.goal / campaign.total | prettyPercentage }}
                      </div>
                      <div class="conversion" v-else>&mdash;</div>
                      <div class="display-caption">Conversion</div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </draggable>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <CreateCampaignsModal v-if="show_create_modal" />
    <NameCampaignModal v-if="show_scratch_modal" />
    <CreateCampaignsTemplate v-if="show_template_modal" />
  </div>
</template>
<script>
  import draggable from 'vuedraggable';
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import CreateCampaignsModal from './CreateCampaignsModal';
  import NameCampaignModal from './NameCampaignModal';
  import CreateCampaignsTemplate from './CreateCampaignsTemplate';
  import EmptyState from './EmptyState';

  export default {
    components: {
      CreateCampaignsModal,
      NameCampaignModal,
      CreateCampaignsTemplate,
      EmptyState,
      draggable,
    },
    data() {
      return {
        loadingPause: false,
        show_create_modal: false,
        show_scratch_modal: false,
        show_template_modal: false,
        campaigns: [],
        folders: [],
        query: '',
        loaded: false,
        currentFolder: null,
        show_options: false,
      };
    },
    mounted() {
      // eslint-disable-next-line
      document.title = `Auto-Campaigns | ${this.$store.getters.brandName}`;
    },
    created() {
      this.fetchCampaigns();
      this.fetchCampaignGroups();

      EventBus.$on('auto_campaigns.create_modal.close', () => {
        this.show_create_modal = false;
        this.show_scratch_modal = false;
      });

      EventBus.$on('auto_campaigns.create_modal.choice', (mode) => {
        this.show_create_modal = false;

        if (mode === 'scratch') {
          this.show_scratch_modal = true;
        } else {
          this.show_template_modal = true;
        }
      });

      EventBus.$on('campaign_template.selection.close', () => {
        this.show_template_modal = false;
      });
    },
    beforeDestroy() {
      // eslint-disable-next-line
      document.title = this.$store.getters.brandName;

      EventBus.$off('auto_campaigns.create_modal.close');
      EventBus.$off('auto_campaigns.create_modal.choice');
      EventBus.$off('campaign_template.selection.close');
    },
    watch: {
      campaigns() {
        this.$nextTick(() => {
          this.$refs.campaignSearch.focus();
        });
      },
    },
    computed: {
      selectedCampaigns() {
        return this.campaigns.filter(c => c.selected);
      },
      filteredCampaigns() {
        if (this.query.length) {
          return this.campaigns.filter(c =>
            c.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1,
          );
        }

        return this.campaigns;
      },
      currentFolderName() {
        if (this.currentFolder) {
          return this.folders.filter(f => f.id === this.currentFolder)[0].name;
        }
        return 'All Auto-Campaigns';
      },
    },
    methods: {
      pauseAllCampaigns() {
        const payload = {
          account_id: this.$store.state.user.current_account.id,
        };
        this.$confirm('Are you sure you want to pause all of your automations? You are always able to set each automation live if you need to at any time.').then(() => {
          this.loadingPause = true;
          api.campaignPauseAll(payload).then((response) => {
            this.$message.success(response.message);
            this.loadingPause = false;
          }).catch((err) => {
            this.loadingPause = false;
            this.$message.error(err.message);
          });
        });
      },
      archiveGroup() {
        this.$confirm('This will archive this group. Continue?', 'Warning', {
          confirmButtonText: 'Archive',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }).then(() => {
          api.deleteCampaignGroup(this.currentFolder).then((response) => {
            this.$message({
              type: 'success',
              message: response.message,
            });
            this.fetchCampaignGroups();
            this.fetchCampaigns();
            this.currentFolder = null;
          }).catch((err) => {
            this.$message({
              type: 'error',
              message: err.message,
            });
          });
        });
      },
      renameGroup() {
        this.show_options = false;
        this.$prompt('Auto-Campaign Group Name', 'Rename', {
          confirmButtonText: 'Rename',
          cancelButtonText: 'Cancel',
          inputPattern: /^.{2,}$/,
          inputErrorMessage: 'Group name is too short.',
          inputValue: this.currentFolderName,
        }).then(({ value }) => {
          this.updateCampaignFolder({ name: value });
        });
      },
      updateCampaignFolder(payload) {
        api.updateCampaignFolder(this.currentFolder, payload).then((response) => {
          this.$message({
            type: 'success',
            message: response.message,
          });
          this.fetchCampaignGroups();
        }).catch((err) => {
          this.$message({
            type: 'error',
            message: err.message,
          });
        });
      },
      moveToGroup(folderId) {
        const payload = {
          campaign_ids: this.selectedCampaigns.map(s => s.id),
          campaign_folder_id: folderId,
        };

        api.linkCampaignToGroup(payload).then((response) => {
          this.$message({
            type: 'success',
            message: response.message,
          });

          this.fetchCampaignGroups();
          if (this.currentFolder) {
            this.fetchCampaignsInGroup(this.currentFolder);
          } else {
            this.fetchCampaigns();
          }

          // eslint-disable-next-line
          for (const campaign of this.campaigns) {
            campaign.selected = false;
          }
        }).catch((err) => {
          this.$message({
            type: 'error',
            message: err.message,
          });
        });
      },
      removeFromGroup() {
        this.$confirm('This will remove the campaign from this group. Continue?', 'Warning', {
          confirmButtonText: 'Remove',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }).then(() => {
          const payload = {
            campaign_folder_id: this.currentFolder,
            campaign_ids: this.selectedCampaigns.map(s => s.id),
          };
          api.unlinkCampaignToGroup(payload).then((response) => {
            this.$message({
              type: 'success',
              message: response.message,
            });

            this.fetchCampaignGroups();
            this.fetchCampaignsInGroup(this.currentFolder);
          }).catch((err) => {
            this.$message({
              type: 'error',
              message: err.message,
            });
          });
        });
      },
      updatedOrder(event) {
        const newOrder = {};
        const uuid = event.item.dataset.uuid;
        const newIndex = event.newIndex;

        // eslint-disable-next-line
        this.campaigns.forEach((campaign, i) => {
          if (campaign.uuid === uuid) {
            newOrder[campaign.uuid] = newIndex;
          } else {
            newOrder[campaign.uuid] = i;
          }
        });

        api.updateCampaignOrder({ order: newOrder }).then((response) => {
          this.$message({
            type: 'success',
            message: response.message,
          });
        }).catch((err) => {
          this.$message({
            type: 'error',
            message: err.message,
          });
        });
      },
      newGroup() {
        this.$prompt('Auto-Campaign Group Name', 'New', {
          confirmButtonText: 'Create',
          cancelButtonText: 'Cancel',
          inputPattern: /^.{2,}$/,
          inputErrorMessage: 'Group name is too short.',
        }).then(({ value }) => {
          this.createGroup(value);
        });
      },
      createGroup(name) {
        api.createCampaignFolder({ name }).then((response) => {
          this.$message({
            type: 'success',
            message: response.message,
          });
          this.fetchCampaignGroups();
        }).catch((err) => {
          this.$message({
            type: 'error',
            message: err.message,
          });
        });
      },
      fetchCampaignGroups() {
        api.getCampaignFolders().then((response) => {
          this.folders = response.folders;
        }).catch((err) => {
          this.$message({
            type: 'error',
            message: err.message,
          });
        });
      },
      fetchCampaigns() {
        this.currentFolder = null;
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: '#fff',
        });

        api.getAllCampaigns().then((response) => {
          this.campaigns = response.campaigns.sort((one, two) => one.order - two.order);
          this.loaded = true;
          loading.close();
          
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchCampaignsInGroup(folderId) {
        this.currentFolder = folderId;
        this.loaded = false;

        api.getCampaignFolder(folderId).then((response) => {
          this.campaigns = response.campaigns.sort((one, two) => one.order - two.order);
          this.loaded = true;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .v2__card {
    margin-bottom: .75rem;
  }

  .el-header {
    display: block;
    width: 100%;
    position: fixed;
    z-index: 100;
    border-bottom: 1px solid var(--xpl-color-gray-400);
    padding-left: 1.25rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .el-main {
    margin-top: 3.75rem;
    height: calc(100vh - 6.75rem);
  }

  .el-aside {
    margin-top: 3.75rem;
    height: calc(100vh - 6.75rem);
    padding-top: 2.5rem;
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .el-aside .item {
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: .875rem;
    color: #2e4457;
    transition: all 150ms ease-in-out;
    border-radius: 6px;
    margin-bottom: .375rem;
  }

  .el-aside .item:hover,
  .el-aside .item.active {
    cursor: pointer;
    background-color: var(--xpl-color-secondary-bg-lm);
  }

  .el-aside .item.active {
    font-weight: 700;
  }

  .el-aside .item .count {
    margin-left: auto;
    color: #617080;
  }

  .el-aside .heading {
    padding-left: 1rem;
    margin-top: 1.875rem;
    font-size: 1rem;
    font-weight: 700;
    color: #00152a;
    margin-bottom: 1.25rem;
  }

  .el-aside a {
    display: block;
    margin-top: 1rem;
    padding-left: 1rem;
    font-size: .875rem;
    color: #6923f4;
  }

  .container .container-inner {
    height: calc(100vh - 4rem);
    display: block;
    width: 100%;
    margin-top: 4rem;
    padding-top: 1.875rem;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .el-main .heading {
    margin-bottom: 1.875rem;
  }

  .el-main .heading .display-medium {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .el-row.search {
    margin-bottom: 2.5rem;
  }

  .el-col img {
    display: block;
    max-width: 1.25rem;
    width: 1.25rem;
    min-width: 1.25rem;
  }

  .el-col .name {
    line-height: 1.5;
    font-weight: 700;
    color: #00152a;
    margin-bottom: 2px;
    transition: all 150ms ease-in-out;
    transition: text-decoration 150ms ease-in-out;
  }

  .el-col .name:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #6923f4;
  }

  .material-icons {
    cursor: move;
    color: #afbcca;
  }

  .el-col .in,
  .el-col .all,
  .el-col .conversion {
    font-weight: 700;
    font-size: 1rem;
    text-align: right;
  }

  .el-col.stats .display-caption {
    line-height: 1.67;
    text-align: right;
  }

  .el-col .in {
    color: var(--xpl-color-secondary-lm);
  }

  .el-col .all {
    color: #8301f1;
  }

  .el-col .conversion {
    color: #6923f4;
  }

  .state {
    font-size: .875rem;
    font-weight: bold;
    line-height: 1.43;
    margin-bottom: .5rem;
  }

  .state.active {
    color: #49e295;
  }

  .state.paused {
    color: #8b9bac;
  }

  .state.draft {
    color: #8301f1;
  }

  .move-to {
    margin-bottom: 1.25rem;
  }

  .el-dropdown-menu__item a,
  .el-dropdown-menu__item a:hover {
    display: block;
    width: 100%;
    color: #606266;
    text-decoration: none;
  }

  .alert-warning {
    background-color: #fdf6ec;
    color: #e6a23c;
    width: 100%;
    padding: 8px 24px;
    margin: 0;
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;
    opacity: 1;
    font-size: 12px;
    line-height: 1.5em;
    margin-bottom: 1rem;
  }

  .alert-warning .alert-title {
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 700;
  }
</style>
