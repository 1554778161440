import Vue from 'vue';
import moment from 'moment';
import 'moment-timezone';
import numeral from 'numeral';
import store from '../store';

Vue.filter('dateTime', value => moment.utc(value).tz(store.getters.currentTimezone).format('MMM DD [at] h:mma'));
Vue.filter('prettyNumber', number => numeral(number).format('0,0[.]00'));
Vue.filter('prettyCurrency', (number, decimals = true) => {
  if (!decimals) {
    return numeral(number).format('$0,0');
  }

  return numeral(number).format('$0,0.00');
});
Vue.filter('prettyPercentage', number => numeral(number).format('0[.]00%'));
Vue.filter('humanDate', (date, convertTz = true) => {
  if (convertTz) {
    return moment.utc(date).tz(store.getters.currentTimezone).format('MMM Do Y');
  }

  return moment(date).format('MMM Do Y');
});

Vue.filter('humanDateMin', (date, convertTz = true) => {
  if (convertTz) {
    return moment.utc(date).tz(store.getters.currentTimezone).format('MMM Do');
  }

  return moment(date).format('MMM Do');
});
Vue.filter('humanDateTime', (date, convertTz = true) => {
  if (convertTz) {
    return moment.utc(date).tz(store.getters.currentTimezone).format('MMM Do Y [at] h:mma');
  }

  return moment(date).format('MMM Do Y [at] h:mma');
});
//
// const dumb = true;
// export default dumb;
