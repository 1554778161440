<template>
  <div class="condition">
    <el-form>
      <el-form-item>
        Any contact whose last interaction was exactly
        <el-input-number :min="0" v-model="relative_days"></el-input-number>
        days ago.
      </el-form-item>

      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="commitCondition" :disabled="!relative_days">Save</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    props: ['uuid', 'rule', 'campaignItem'],
    data() {
      return {
        relative_days: null,
      };
    },
    created() {
      if (this.rule && this.rule.length && this.rule[0].relative_days) {
        this.relative_days = this.rule[0].relative_days;
      }
    },
    methods: {
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.uuid,
            quick: true,
          },
          last_visit_type: 'visit',
          time_comparison: 'exactly',
          relative_days: this.relative_days,
          filter_plan: false,
          plans: [],
          types: [],
          categories: [],
        };
        EventBus.$emit('condition.commit', payload);
      },
    },
  };
</script>
