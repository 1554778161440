<template>
  <div>
    <div class="tabs">
      <a href="#" :class="{ active: true }">Style</a>
    </div>
    <div class="">
      <form @submit.prevent>
        <div class="form-group">
          <label>Background Color</label>
          <div class="input-group" @click="show_picker = !show_picker">
            <span class="input-group-addon">
              <span class="icon icon-square" :style="'color: ' + background_color.hex"></span>
            </span>
            <input type="text" ref="background_color" v-model="background_color.hex" class="form-control" readonly />
          </div>
          <Sketch v-model="background_color" :disableAlpha="true" @input="backgroundColorEdited" v-if="show_picker" />
        </div>
      </form>
    </div>

    <div class="footer">
      <button type="button" class="xpl-button xpl-button--primary" @click="saveAndCloseBlock">Save and Close</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import _ from 'lodash';
  import { Sketch } from 'vue-color';

  export default {
    props: ['currentElement'],
    components: {
      Sketch,
    },
    data() {
      return {
        show_picker: false,
        background_color: {
          hex: null,
        },
      };
    },
    mounted() {
      this.background_color.hex = this.currentElement.attributes['background-color'];
    },
    methods: {
      saveAndCloseBlock() {
        EventBus.$emit('editor.close');
      },
      attributeEdited: _.debounce(() => {
        EventBus.$emit('email.edit.attributes');
      }, 300,
      ),
      backgroundColorEdited: _.debounce(function customFunction(data) {
        this.currentElement.attributes['background-color'] = data.hex;
        this.attributeEdited();
      }, 300,
      ),
    },
  };
</script>
<style lang="scss" scoped>
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgb(205, 215, 226);
    padding-top: .75rem;
    padding-bottom: .75rem;
    padding-left: 1.5rem;
    background-color: white;
    z-index: 1001;
  }

  .tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .tabs a {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: rgb(29, 54, 75);
    font-size: .875rem;
    text-align: center;
    border-bottom: 2px solid rgb(217, 231, 240);
    transition: all 150ms ease-in-out;
  }

  .tabs a:hover,
  .tabs a.active {
    text-decoration: none;
    border-bottom: 2px solid var(--xpl-color-secondary-lm);
  }

  form {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  label {
    font-size: .875rem;
    line-height: 20px;
    color: rgb(29, 54, 75);
    margin-bottom: .25rem;
  }

  .input-group {
    cursor: pointer;
  }

  .input-group-addon {
    cursor: pointer;
    padding: .25rem .5rem;
    padding-right: 0;
  }

  .input-group input {
    cursor: pointer;
  }

  .icon-square {
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 1.5rem;
    cursor: pointer;
  }

  input[readonly] {
    background-color: white;
  }
</style>
