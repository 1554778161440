<template>
<el-container class="condition-container">
  <div class="sidebar-inner" style="width: 100%;">
      <TimeFrameField
        fieldIndex="0"
        title="When Was The Profile Created?"
        fieldStep="1"
        :selectedOption.sync="timeframeComparison"
        :valueOne.sync="timeframeValue"
        pastLabel="days ago"
        :hidePast="false"
        :hideFuture="true"
        :hideTimeType="false"
        :goalList="null"
        id="22"
      />
      <MultiSelectField
        fieldIndex="1"
        title="What Is Their Home Location?"
        fieldStep="2"
        anyLabel="Any location in my account"
        specificLabel="Specific location(s) in my account"
        specificAnyLabel="Any of the following"
        specificExclusionLabel="Any except the following"
        :selectedOptions.sync="selectedHomeLocations"
        :specificChoice.sync="selectedHomeOption"
        :exclusionChoice.sync="exclusionHomeOption"
        :options="options"
        selectKey="id"
        id="452"
      />
    </div>
    <div class="save-condition-container" v-if="showSaveButton">
      <el-button class="save-condition-btn" type="primary" @click="commitCondition">Save</el-button>
    </div>
</el-container>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import TimeFrameField from '@/components/Contacts/ContactLists/Conditions/Fields/TimeFrameField';
  import MultiSelectField from '@/components/Contacts/ContactLists/Conditions/Fields/MultiSelectField';

  export default {
    props: ['condition', 'editedRule', 'goalList'],
    components: {
      TimeFrameField,
      MultiSelectField,
    },
    data() {
      return {
        options: [],
        exclusionOption: 'any',
        selectedHomeOption: 'any',
        selectedHomeLocations: [],
        exclusionHomeOption: 'any',
        firstPass: true,
        timeframeComparison: null,
        timeframeValue: 1,
      };
    },
    created() {
      this.fetchLocations();
      if (this.editedRule) {
        this.firstPass = false;

        this.timeframeComparison = this.editedRule.profile_creation_comparison;
        if (this.editedRule.profile_creation_comparison === 'sinceEntering') {
          this.timeframeComparison = 'allTime';
        }
        this.timeframeValue = this.editedRule.profile_creation_value;

        this.selectedHomeOption = this.editedRule.home_option;
        this.selectedHomeLocations = this.editedRule.home_locations;
        this.exclusionHomeOption = this.editedRule.home_exclusion;
      }
    },
    computed: {
      showSaveButton() {
        if (!this.timeframeComparison) {
          return false;
        }

        if ((this.timeframeComparison === 'before' || this.timeframeComparison === 'after' || this.timeframeComparison === 'between' || this.timeframeComparison === 'specificDate') && Number.isInteger(this.timeframeValue)) {
          return false;
        }

        if (this.timeframeComparison === 'between' && !this.timeframeValue.length) {
          return false;
        }
        if (this.selectedHomeOption === 'specific' && (!this.selectedHomeLocations.length || !this.exclusionHomeOption)) {
          return false;
        }
        return true;
      },
    },
    methods: {
      async fetchLocations() {
        try {
          const response = await api.getMTLinkedLocations();
          this.options = response.locations;
        } catch (err) {
          this.$toasted.err(err.message);
        }
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          profile_creation_comparison: this.timeframeComparison,
          profile_creation_value: this.timeframeValue,
          home_locations: this.selectedHomeLocations,
          home_exclusion: this.exclusionHomeOption,
          home_option: this.selectedHomeOption,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
@import "src/scss/global/_spacing.scss";
@import "src/scss/global/_new_main.scss";
  .field-container {
  margin-top: 28px;
}
.option-body {
  margin-top: 20px;
}

.card-condition {
  background-color: #FFFFFF;
  border: solid 1px var(--xpl-color-gray-400);
  box-shadow: none;
  border-radius: 0;
  cursor: auto;
}

.card-condition:hover {
  background-color: #FFFFFF;
  cursor: auto;
}
.field-step {
  height: 1.5rem;
  width: 1.5rem;
  background-color: $bb-color-blue-v2;
  font-family: $primary-bb-font-family;
  font-size: $base-font-size;
  color: #FFFFFF;
  border-radius: 50%;
  text-align: center;
  margin: $space-base-inline;
}
.field-title {
  font-family: $primary-bb-font-family;
  font-weight: 700;
  font-size: $base-font-size;
  color: $base-color-black-v2;
  line-height: $loose-medium-line-height;
}

.radio-btn {
  margin-top: $space-extra-tight;
  margin-bottom: $space-extra-tight;
}

.field-label-text  {
  display: block;
}
.field-label-text /deep/ .el-radio__label {
  font-family: $primary-bb-font-family !important;
  font-size: $base-loose-font-size !important;
  color: $base-color-black-v2 !important;
  line-height: $loose-line-height !important;
  padding-left: 8px;
}
.field-label-text /deep/ .el-radio__inner {
  background-color: #F2F4F6 !important;
  border: 1px solid #A6B2BF !important;
  height: 16px;
  width: 16px;
}
.field-label-text /deep/  .el-radio__input.is-checked .el-radio__inner::after {
  background-color: var(--xpl-color-secondary-lm) !important;
  width: 12px;
  height: 12px;
}
.condition-container {
  background-color: #F4F8FA;
  height: 100%;
  width: 100%;
}

.field-container:last-of-type{
  padding-bottom: 100px;
}
.sidebar-inner {

  height: 100%;
  position: relative;
  padding-top: 0;
  background-color: #F4F8FA;
  padding-left: 2.625rem;
  padding-right: 2.25rem;
  padding-bottom: 0;
  width: 100%;
}
.save-condition-btn {
  width: 729px;
  margin: 16px 12px 16px 12px;
}
.save-condition-container {
  // box-shadow: 0 -6px 7px 0 rgba(0, 0, 0, 0.05);
  padding: 0;
  margin: 0;
  z-index: 1;
}
.condition-banner-style {
  background-color: #F4F8FA !important;
  margin-top: 13px !important;
  padding-bottom: 0;
}

</style>
