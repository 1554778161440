<template>
  <div>
    <el-card>
      <div class="display-small">Enterprise</div>
      <el-form>
        <el-form-item>
          <div>Transform this Account into a Franchise Account (HQ)</div>
          <el-button type="secondary" class="xpl-button xpl-button--secondary"  size="small" @click="transformHQ">Transform into HQ</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card style="margin-top: 2rem;">
      <div class="display-small">Real-time</div>
      <el-form>
        <el-form-item>
          <el-switch v-model="realTime" @change="toggleRealTime" active-text="Enabled">
          </el-switch>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card style="margin-top: 2rem;" v-loading="isLoadingReplication">
      <div class="display-small">Replicating campaigns</div>
      <el-form>
        <el-form-item>
          <el-select v-model="target_account_id" filterable placeholder="Select an account">
            <el-option v-for="account in accounts" :key="account.id" :label="account.name" :value="account.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="replicateAccount">Replicate Campaigns</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card style="margin-top: 2rem;">
      <div class="display-small">Pause All Campaigns</div>
      <el-form>
        <el-form-item>
          <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="pauseAllCampaigns" :loading="loadingPause">Pause All Campaigns in this Account</el-button>
        </el-form-item>
      </el-form>
    </el-card>

  </div>
</template>
<script>
  import * as api from '@/api';
  import VueDatepickerLocal from 'vue-datepicker-local';

  export default {
    components: {
      VueDatepickerLocal,
    },
    data() {
      return {
        target_account_id: null,
        isLoadingReplication: false,
        loadingPause: false,
        realTime: this.$store.state.user.current_account.real_time,
        accounts: [],
        account: this.$store.state.user.current_account,
        local: {
          dow: 1,
          hourTip: '',
          minuteTip: '',
          secondTip: '',
          yearSuffix: '',
          monthsHead: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
          months: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
          weeks: 'Mon_Tue_Wed_Thu_Fri_Sat_Sun'.split('_'),
        },
        kickOffCall: {
          date: '',
          person: '',
        },
        onboardingCall1: {
          date: '',
          person: '',
        },
        onboardingCall2: {
          date: '',
          person: '',
        },
        onboardingCall3: {
          date: '',
          person: '',
        },
      };
    },
    created() {
      this.fetchAccount();
      this.fetchMemberships();
    },
    methods: {
      transformHQ() {
        const payload = {
          make_franchise: true,
        };

        api.enterpriseHelpers(payload).then((response) => {
          this.$message.success(response.message);
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      pauseAllCampaigns() {
        const payload = {
          account_id: this.$store.state.user.current_account.id,
        };
        this.$confirm('Are you sure?').then(() => {
          this.loadingPause = true;
          api.campaignPauseAll(payload).then((response) => {
            this.$message.success(response.message);
            this.loadingPause = false;
          }).catch((err) => {
            this.loadingPause = false;
            this.$message.error(err.message);
          });
        });
      },
      fetchMemberships() {
        api.getMemberships().then((response) => {
          this.accounts = response.memberships;
        });
      },
      toggleRealTime(value) {
        const realTimeState = this.$store.state.user.current_account.real_time;
        this.$confirm(`Real-time is currently ${realTimeState ? 'enabled' : 'disabled'}. Are you sure you would like to ${realTimeState ? 'disable' : 'enable'} real-time?`)
        .then(() => {
          const currentAccountId = this.$store.state.user.current_account.id;
          const payload = {
            real_time: value,
          };
          api.updateAccount(currentAccountId, payload).then((response) => {
            this.$store.dispatch('refreshUser');
            this.$message.success(response.message);
          }).catch((err) => {
            // switch the state back if the API call fails
            this.realTime = !value;
            this.$message.error(err.message);
          });
        }).catch(() => {
          // switch the state back if user fails the confirmation dialog
          this.realTime = !value;
        });
      },
      replicateAccount() {
        this.isLoadingReplication = true;
        const payload = {
          target_account_id: this.target_account_id,
        };
        api.replicateAccount(payload).then((response) => {
          this.$message.success(response.message);
          this.isLoadingReplication = false;
        }).catch((err) => {
          this.isLoadingReplication = false;
          this.$message.error(err.message);
        });
      },
      fetchAccount() {
        api.getCurrentAccount().then((response) => {
          const account = response.account;
          const metadata = JSON.parse(account.metadata);

          // eslint-disable-next-line
          for (const step in metadata) {
            // eslint-disable-next-line
            if (metadata.hasOwnProperty(step) && step === 'onboarding') {
              if (Object.keys(metadata[step])[0] === 'kickoff') {
                this.kickOffCall = metadata[step].kickoff;
              }

              if (Object.keys(metadata[step])[0] === 'onboarding1') {
                this.onboardingCall1 = metadata[step].onboarding1;
              }

              if (Object.keys(metadata[step])[0] === 'onboarding2') {
                this.onboardingCall2 = metadata[step].onboarding2;
              }

              if (Object.keys(metadata[step])[0] === 'onboarding3') {
                this.onboardingCall3 = metadata[step].onboarding3;
              }
            }
          }
        });
      },
      saveStep(step, event) {
        const target = event.srcElement || event.target;
        target.disabled = true;
        const payload = {
          onboarding: true,
        };
        if (step === 'kickoff') {
          payload.onboarding_payload = {
            kickoff: this.kickOffCall,
          };
        }
        if (step === 'onboarding1') {
          payload.onboarding_payload = {
            onboarding1: this.onboardingCall1,
          };
        }
        if (step === 'onboarding2') {
          payload.onboarding_payload = {
            onboarding2: this.onboardingCall2,
          };
        }
        if (step === 'onboarding3') {
          payload.onboarding_payload = {
            onboarding3: this.onboardingCall3,
          };
        }

        api.updateAccount(this.$store.state.user.current_account.id, payload).then((response) => {
          this.$toasted.success(response.message);
          target.disabled = false;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .datepicker {
    display: block;
  }

  .card-body .title {
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.43;
    color: rgb(29, 54, 75);
    margin-bottom: .25rem;
  }
</style>
