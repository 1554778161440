<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Name this Scheduled Plan
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <label for="name">Name</label>
                <input ref="nameInput" type="text" id="name" v-model="name" class="form-control" placeholder="Name" />
              </div>
              <div class="form-group" v-if="template">
                <label for="name">{{ template.date_string }}</label>
                <input type="text"
                  name="date-picker"
                  id="date-picker"
                  v-model="date"
                  placeholder="Pick a date"
                  class="form-control" />
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="submit" class="xpl-button xpl-button--primary" ref="createButton" @click.stop="createPlan">Create</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import flatpickr from 'flatpickr';

  export default {
    props: ['template'],
    data() {
      return {
        name: this.template ? this.template.name : '',
        date: null,
      };
    },
    created() {
      this.$nextTick(() => {
        flatpickr('#date-picker', {
          dateFormat: 'Y-m-d',
          maxDate: '2027-01-01',
        });
        this.$refs.nameInput.focus();
      });
    },
    methods: {
      closeModal() {
        EventBus.$emit('scheduled_plan.create_modal.close');
      },
      createPlan() {
        if (this.name.length < 4) {
          return;
        }

        const originalInner = this.$refs.createButton.innerHTML;

        this.$refs.createButton.disabled = true;
        this.$refs.createButton.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';

        const payload = {
          name: this.name,
          template: JSON.stringify(this.template),
          date: this.date,
        };

        api.createScheduledPlan(payload).then((response) => {
          this.$toasted.success(response.message);
          this.$router.push(`/plans/${response.scheduled_plan.uuid}`);
          this.$refs.createButton.innerHTML = originalInner;
          this.$refs.createButton.disabled = false;
          this.closeModal();
        }).catch((err) => {
          this.$refs.createButton.innerHTML = originalInner;
          this.$refs.createButton.disabled = false;
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style src="flatpickr/dist/flatpickr.min.css"></style>
<style lang="scss">
</style>
<style lang="scss" scoped>
  .form-control:disabled, .form-control[readonly] {
    background-color: white;
  }
</style>
