<template>
  <div class="sidebar-inner" style="width: 100%;">
    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">1</span>
      </el-col>
      <el-col :span="22" class="v2__heading">Get all contacts who</el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2" :span="22">
          <el-radio-group v-model="is_unsubscribed">
            <el-radio :label="true" border>Are Unsubscribed to SMS</el-radio>
            <el-radio :label="false" border>Are not Unsubscribed to SMS</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
    </div>

    <el-form>
      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="commitCondition">Save this condition</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    props: ['condition', 'editedRule'],
    data() {
      return {
        is_unsubscribed: true,
      };
    },
    created() {
      if (this.editedRule) {
        this.is_unsubscribed = this.editedRule.is_unsubscribed;
      }
    },
    methods: {
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          is_unsubscribed: this.is_unsubscribed,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
