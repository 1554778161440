<template>
  <div class="card-condition-border" @click="sendCurrentOpen">
    <div :class="['card-condition', open ? 'disable-hover-bg' : '']">
      <el-row>
        <el-col :span="12">
          <div class="flex">
            <span>{{ title }}</span>
        </div>
        </el-col>
        <el-col v-if="!open" :span="12">
          <div class="text-align-left">
            {{ englishSentence }}
        </div>
        </el-col>
      </el-row>
      <transition name="fade">
        <div v-if="open" @click.stop>
          <div v-if="showFuture" class="option-subbody">
            <div class="subtitle">
              RELATIVE TIME FRAME IN THE FUTURE
            </div>
            <el-radio class="radio-btn" v-model="radio" @change="$emit('update:selectedOption', radio)" label="withinTheNext">Within the next</el-radio>
            <transition name="input">
              <div v-if="radio === 'withinTheNext'" class="other-form">
                <el-input-number size="mini" controls-position="right" v-model="withinTheNextCount" @change="$emit('update:valueOne', withinTheNextCount)" :min="1" :max="1000"></el-input-number>
                <span class="desc-label">{{ futureLabel }}</span>
              </div>
            </transition>
            <transition name="input">
              <div v-if="radio === 'withinTheNext'" class="desc-text">
                {{ 'Today would be within the next 0 days. (I.e. “Within the next 1 day” equals today and tomorrow)' }}
              </div>
            </transition>
            <el-radio class="radio-btn" v-model="radio" @change="$emit('update:selectedOption', radio)" label="atLeastFuture">At least</el-radio>
            <transition name="input">
              <div v-if="radio === 'atLeastFuture'" class="other-form">
                <el-input-number size="mini" controls-position="right" v-model="atLeastFutureCount" @change="$emit('update:valueOne', atLeastFutureCount)" :min="1" :max="1000"></el-input-number>
                <span class="desc-label">{{ futureLabel }}</span>
              </div>
            </transition>
            <transition name="input">
              <div v-if="radio === 'atLeastFuture'" class="desc-text">
                {{ 'Today would be at least the next 0 days. (I.e. “Within the next 1 day” equals today and tomorrow)' }}
              </div>
            </transition>
            <el-radio class="radio-btn" v-model="radio" @change="$emit('update:selectedOption', radio)" label="exactlyFuture">Exactly</el-radio>
            <transition name="input">
              <div v-if="radio === 'exactlyFuture'" class="other-form">
                <el-input-number size="mini" controls-position="right" v-model="exactlyFutureCount" @change="$emit('update:valueOne', exactlyFutureCount)" :min="1" :max="1000"></el-input-number>
                <span class="desc-label">{{ futureLabel }}</span>
              </div>
            </transition>
            <transition name="input">
              <div v-if="radio === 'exactlyFuture'" class="desc-text">
                {{ 'Today would be exactly 0 days.' }}
              </div>
            </transition>
          </div>
          <div v-if="showPast" class="option-subbody">
            <div class="subtitle">
              IN THE PAST
            </div>
            <el-radio v-if="goalList" class="radio-btn" v-model="radio" @change="$emit('update:selectedOption', radio)" label="sinceEntering">Since entering the campaign</el-radio>
            <el-radio class="radio-btn" v-model="radio" @change="$emit('update:selectedOption', radio)" label="withinTheLast">Within the last</el-radio>
            <transition name="input">
              <div v-if="radio === 'withinTheLast'" class="other-form">
                <el-input-number size="mini" controls-position="right" v-model="withinTheLastCount" @change="$emit('update:valueOne', withinTheLastCount)" :min="1" :max="1000"></el-input-number>
                <span class="desc-label">{{ futureLabel }}</span>
              </div>
            </transition>
            <transition name="input">
              <div v-if="radio === 'withinTheLast'" class="desc-text">
                {{ 'Today is within the last 0 days. (I.e. “Within the last 1 day” equals yesterday and today)' }}
              </div>
            </transition>
            <el-radio class="radio-btn" v-model="radio" @change="$emit('update:selectedOption', radio)" label="atLeastPast">At least</el-radio>
            <transition name="input">
              <div v-if="radio === 'atLeastPast'" class="other-form">
                <el-input-number size="mini" controls-position="right" v-model="atLeastPastCount" @change="$emit('update:valueOne', atLeastPastCount)" :min="1" :max="1000"></el-input-number>
                <span class="desc-label">{{ pastLabel }}</span>
              </div>
            </transition>
            <transition name="input">
              <div v-if="radio === 'atLeastPast'" class="desc-text">
                {{ 'Today is at least 0 days ago. (I.e. “At least 1 day ago” equals today and tomorrow)' }}
              </div>
            </transition>
            <el-radio class="radio-btn" v-model="radio" @change="$emit('update:selectedOption', radio)" label="exactlyPast">Exactly</el-radio>
            <transition name="input">
              <div v-if="radio === 'exactlyPast'" class="other-form">
                <el-input-number size="mini" controls-position="right" v-model="exactlyPastCount" @change="$emit('update:valueOne', exactlyPastCount)" :min="1" :max="1000"></el-input-number>
                <span class="desc-label">{{ pastLabel }}</span>
              </div>
            </transition>
            <transition name="input">
              <div v-if="radio === 'exactlyPast'" class="desc-text">
                {{ 'Today is exactly 0 days. (I.e. “Exactly 1 day ago” equals yesterday)' }}
              </div>
            </transition>
          </div>
          <div v-if="showAbs" class="option-subbody">
            <div class="subtitle">
              ABSOLUTE TIME FRAME
            </div>
            <el-radio class="radio-btn" v-model="radio" @change="$emit('update:selectedOption', radio)" label="before">Before</el-radio>
            <transition name="input">
              <div v-if="radio === 'before'" class="other-form">
                <el-date-picker
                  v-model="beforeDate"
                  @change="$emit('update:valueOne', beforeDate)"
                  type="date"
                  placeholder="Pick a date">
                </el-date-picker>
              </div>
            </transition>
            <transition name="input">
              <div v-if="radio === 'before'" class="desc-text">
                {{ 'The date selected is not included (i.e. “Before Oct 10th” equals Oct 9th and before)' }}
              </div>
            </transition>
            <el-radio class="radio-btn" v-model="radio" @change="$emit('update:selectedOption', radio)" label="after">After</el-radio>
            <transition name="input">
              <div v-if="radio === 'after'" class="other-form">
                <el-date-picker
                  v-model="afterDate"
                  @change="$emit('update:valueOne', afterDate)"
                  type="date"
                  placeholder="Pick a date">
                </el-date-picker>
              </div>
            </transition>
            <transition name="input">
              <div v-if="radio === 'after'" class="desc-text">
                {{ 'This does not include the selection (i.e. “After Oct 10th” equals Oct 11th and after)' }}
              </div>
            </transition>
            <el-radio class="radio-btn" v-model="radio" @change="$emit('update:selectedOption', radio)" label="between">Between</el-radio>
            <transition name="input">
              <div v-if="radio === 'between'" class="other-form">
                <el-date-picker
                  @change="$emit('update:valueOne', betweenDate)"
                  v-model="betweenDate"
                  type="daterange"
                  range-separator=""
                  start-placeholder="Start date"
                  end-placeholder="End date">
                </el-date-picker>
              </div>
            </transition>
            <transition name="input">
              <div v-if="radio === 'between'" class="desc-text">
                {{ 'This will include both selections (i.e. “Between Oct 1st and Oct 3rd” equals Oct 1st, 2nd and 3rd)' }}
              </div>
            </transition>
            <el-radio class="radio-btn" v-model="radio" @change="$emit('update:selectedOption', radio)" label="specificDate">On a specific date</el-radio>
            <transition name="input">
              <div v-if="radio === 'specificDate'" class="other-form">
              <el-date-picker
                @change="$emit('update:valueOne', specificDate)"
                v-model="specificDate"
                type="date"
                placeholder="Pick a date">
              </el-date-picker>
              </div>
            </transition>
            <transition name="input">
              <div v-if="radio === 'specificDate'" class="desc-text">
                {{ '' }}
              </div>
            </transition>
            <el-radio class="radio-btn" v-model="radio" @change="$emit('update:selectedOption', radio)" label="allTime">All time</el-radio>
          </div>
          <el-button v-if="!inDropDown" :disabled="disable" class="continue-btn" type="primary" @click="sendCurrentOpen">Continue</el-button>
      </div>
      </transition>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import moment from 'moment';

  export default {
    props: [
      'id',
      'currentlyOpen',
      'title',
      'futureLabel',
      'pastLabel',
      'absLabel',
      'withinTheNextCopy',
      'atLeastFutureCopy',
      'exactlyFutureCopy',
      'openDrawer',
      'showFuture',
      'showPast',
      'showAbs',
      'goalList',
      'inDropDown',
      'selectedOption',
      'notEditState',
      'valueOne',
    ],
    data() {
      return {
        picked: '',
        radio: this.selectedOption,
        withinTheNextCount: null,
        atLeastFutureCount: null,
        exactlyFutureCount: null,
        withinTheLastCount: null,
        atLeastPastCount: null,
        exactlyPastCount: null,
        betweenDate: null,
        beforeDate: null,
        afterDate: null,
        specificDate: null,
        result: '',
        sinceEnteringCount: null,
      };
    },
    created() {
      this.$nextTick(() => {
        if (this.selectedOption === 'withinTheNext') {
          this.withinTheNextCount = this.valueOne;
        } else if (this.selectedOption === 'atLeastFuture') {
          this.atLeastFutureCount = this.valueOne;
        } else if (this.selectedOption === 'exactlyFuture') {
          this.exactlyFutureCount = this.valueOne;
        } else if (this.selectedOption === 'sinceEntering') {
          this.sinceEnteringCount = this.valueOne;
        } else if (this.selectedOption === 'withinTheLast') {
          this.withinTheLastCount = this.valueOne;
        } else if (this.selectedOption === 'atLeastPast') {
          this.atLeastPastCount = this.valueOne;
        } else if (this.selectedOption === 'exactlyPast') {
          this.exactlyPastCount = this.valueOne;
        } else if (this.selectedOption === 'before') {
          this.beforeDate = this.valueOne;
        } else if (this.selectedOption === 'after') {
          this.afterDate = this.valueOne;
        } else if (this.selectedOption === 'between') {
          this.betweenDate = this.valueOne;
        } else if (this.selectedOption === 'specificDate') {
          this.specificDate = this.valueOne;
        }
      });
    },
    computed: {
      open() {
        return this.currentlyOpen === this.id;
      },
      disable() {
        if (this.radio) {
          return false;
        }

        return true;
      },
      englishSentence() {
        if (this.radio === 'withinTheNext') {
          return `Within the next ${this.withinTheNextCount} ${this.futureLabel}`;
        } else if (this.radio === 'atLeastFuture') {
          return `At least ${this.atLeastFutureCount} ${this.futureLabel} in the future`;
        } else if (this.radio === 'exactlyFuture') {
          return `Exactly ${this.exactlyFutureCount} ${this.futureLabel} in the future`;
        } else if (this.radio === 'withinTheLast') {
          return `Within the last ${this.withinTheLastCount} ${this.futureLabel}`;
        } else if (this.radio === 'sinceEntering') {
          return `${this.sinceEnteringCount} ${this.futureLabel} since entering campaign`;
        } else if (this.radio === 'atLeastPast') {
          return `At least ${this.atLeastPastCount} ${this.pastLabel} in the past`;
        } else if (this.radio === 'exactlyPast') {
          return `Exactly ${this.exactlyPastCount} ${this.pastLabel} in the past`;
        } else if (this.radio === 'before') {
          return `Before ${moment(this.beforeDate).format('MMM Do YYYY')}`;
        } else if (this.radio === 'after') {
          return `After ${moment(this.afterDate).format('MMM Do YYYY')}`;
        } else if (this.radio === 'specificDate') {
          return `On ${moment(this.specificDate).format('MMM Do YYYY')}`;
        } else if (this.radio === 'allTime') {
          return 'All Time';
        } else if (this.radio === 'between') {
          return `From ${moment(this.betweenDate[0]).format('MMM Do YYYY')} to ${moment(this.betweenDate[1]).format('MMM Do YYYY')}`;
        }

        return '';
      },
    },
    methods: {
      sendCurrentOpen() {
        EventBus.$emit('update:currentlyOpen', this.id);
      },
      updateResult(id) {
        EventBus.$emit('current_active_drawer', id);
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
