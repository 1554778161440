<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="left">
              <h1>Style your brand</h1>
              <div class="alert" v-if="!dismiss_alert">
                <div>
                  <span class="material-icons">info</span>
                </div>
                <div>
                  <p>
                    These are just your defaults. You are able to change these on any individual emails in the future.
                  </p>
                  <button type="button" class="xpl-button xpl-button--secondary" @click.stop="dismiss_alert = !dismiss_alert">Got it!</button>
                </div>
              </div>

              <div class="steps">
                <div class="step">
                  <div class="step-number" v-if="$store.state.user.current_account.account_emails_metadata.logo.indexOf('placeholder') > -1">1</div>
                  <span class="complete material-icons" v-else>check_circle</span>
                  Choose your logo settings
                </div>

                <form @submit.prevent>
                  <div class="form-group" v-if="$store.state.user.current_account.account_emails_metadata.logo.indexOf('placeholder') > -1">
                    <label>Upload your logo</label>
                    <input type="file" accept="image/*" id="logo_file" ref="logo_file" class="input-file" @change="fileUpload($event)" />
                    <label for="logo_file" ref="logo_file_label">
                      Upload
                    </label>
                    <div class="small text-muted">The perfect logo is a .PNG or .JPEG that is at least 600px wide</div>
                  </div>
                  <div class="form-group" v-else>
                    <label>Upload a new logo</label>
                    <input type="file" accept="image/*" id="logo_file" ref="logo_file" class="input-file" @change="fileUpload($event)" />
                    <label for="logo_file" ref="logo_file_label">
                      Upload
                    </label>
                    <div class="small text-muted">The perfect logo is a .PNG or .JPEG that is at least 600px wide</div>
                  </div>
                  <div class="form-group" style="margin-top: 1rem;">
                    <label>Choose how wide you want your logo</label>
                    <input type="text" v-model.number="logo_width" ref="logo_width" class="form-control" @input="attributeEdited" />
                  </div>
                </form>

                <div class="step" :style="$store.state.user.current_account.account_emails_metadata.logo.indexOf('placeholder') > -1 ? 'opacity: .5; pointer-events: none;' : ''">
                  <div class="step-number" v-if="primary_color.hex === '#3597DE' || (main_font && main_font.value === 'Ubuntu, Helvetica, Arial, sans-serif')">2</div>
                  <span class="complete material-icons" v-else>check_circle</span>
                  Choose your color and font
                </div>

                <form @submit.prevent :style="$store.state.user.current_account.account_emails_metadata.logo.indexOf('placeholder') > -1 ? 'opacity: .5; pointer-events: none;' : ''">
                  <div class="form-group">
                    <label>Choose your most used color</label>
                    <div class="input-group" @click="show_primary_color_picker = !show_primary_color_picker">
                      <span class="input-group-addon">
                        <span class="icon icon-square" :style="'color: ' + primary_color.hex"></span>
                      </span>
                      <input type="text" ref="primary_color" v-model="primary_color.hex" class="form-control" style="z-index: 1;" readonly />
                    </div>
                    <Sketch v-model="primary_color" :disableAlpha="true" @input="attributeEdited" v-if="show_primary_color_picker" />
                    <div class="small text-muted">This will be used for buttons and headers</div>
                  </div>
                </form>

                <form @submit.prevent :style="$store.state.user.current_account.account_emails_metadata.logo.indexOf('placeholder') > -1 ? 'opacity: .5; pointer-events: none;' : ''">
                  <div class="form-group">
                    <label>Choose your most used font</label>
                    <Multiselect v-model="main_font" placeholder="Change Font Family" :options="available_fonts" :searchable="true" label="label" track-by="value" :allow-empty="false" :show-labels="false" @input="attributeEdited">
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <div class="option__title">{{ props.option.label }}</div>
                          <span class="option__small">{{ props.option.safe ? 'Web Safe' : 'Custom' }}</span>
                        </div>
                      </template>
                    </Multiselect>
                    <div class="small text-muted">This will be used for headers</div>
                  </div>
                </form>

                <div class="buttons">
                  <button type="button" class="xpl-button xpl-button--primary" @click.stop="saveAndClose">Save my brand</button>
                  <a href="/calendar">Cancel</a>
                </div>
              </div>
            </div>
            <div class="right">
              <iframe id="iframe" src="" class="preview-rendering"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
  import { directive as onClickaway } from 'vue-clickaway';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';
  import { Sketch } from 'vue-color';
  import _ from 'lodash';
  import Fonts from '@/components/FullEmailEditor/EditBlocks/fonts.json';

  export default {
    directives: {
      onClickaway,
    },
    components: {
      Sketch,
      Multiselect,
    },
    data() {
      return {
        available_fonts: Fonts,
        dismiss_alert: false,
        show_primary_color_picker: false,
        primary_color: {
          hex: null,
        },
        main_font: {},
        preview_html: null,
        metadata: null,
        logo_width: null,
      };
    },
    created() {
      this.fetchBrandingPreview();
    },
    beforeDestroy() {
      document.getElementById('iframe').removeEventListener('load', this.resizeIframe);
    },
    methods: {
      saveAndClose() {
        this.$toasted.success('Your brand has been successfully updated');

        this.$router.go(-1);
      },
      attributeEdited: _.debounce(function customFunction() {
        const payload = {};

        if (this.primary_color.hex) {
          payload.primary_color = this.primary_color.hex;
        }

        if (this.main_font) {
          payload.header_font_family = this.main_font.value;
        }

        if (this.logo_width) {
          payload.logo_width = `${this.logo_width}px`;
        }

        api.saveBrandingMetadata(payload).then(() => {
          this.fetchBrandingPreview();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      }, 300,
      ),
      fetchBrandingPreview() {
        api.getBrandingPreview().then((response) => {
          this.preview_html = response.preview_html;
          this.primary_color.hex = response.metadata.primary_color;
          this.logo_width = response.metadata.logo_width.replace('px', '');
          this.main_font = this.available_fonts
          .filter(f => f.value === response.metadata.header_font_family)[0];

          this.$nextTick(() => {
            const iframe = document.getElementById('iframe');
            iframe.contentWindow.document.open();
            iframe.contentWindow.document.write(response.preview_html);
            iframe.contentWindow.document.close();

            iframe.addEventListener('load', this.resizeIframe);
          });
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      resizeIframe() {
        document.getElementById('iframe').style.height =
        `${document.getElementById('iframe').contentWindow.document.body.offsetHeight}px`;
      },
      fileUpload(event) {
        const target = event.srcElement || event.target;
        // const originalInner = this.$refs.image_file_label.innerHTML;

        if (!target.files.length) {
          return;
        }

        this.$refs.logo_file_label.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';
        target.disabled = true;
        const file = target.files[0];

        const formData = new FormData();
        formData.append('type', 'image');
        formData.append('logo_file', file);

        api.saveBrandingLogo(formData).then(() => {
          this.$store.dispatch('refreshUser').then(() => {
            this.fetchBrandingPreview();
            this.$refs.logo_file_label.innerHTML = 'Uploaded!';
          });
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .vc-sketch {
    position: absolute;
    z-index: 100;
  }

  .modal-dialog {
    margin: 0;
    max-width: none;
    width: 100%;
  }

  .modal .modal-content {
    height: 100vh;
    max-height: 100vh;
    border-radius: 0;
  }

  .modal-body {
    max-height: none !important;
    padding: 0;
    display: flex;
  }

  .modal .modal-body .left {
    padding-top: 3.25rem;
    padding-left: 5.625rem;
    padding-right: 5.625rem;
    flex: 50%;
    background-color: white;
  }

  .left {
    overflow-y: scroll;
  }

  .left h1 {
    font-size: 1.625rem;
    color: #00152a;
  }

  .left .alert {
    border: 0;
    border-radius: 0;
    margin: 0;
    margin-top: 1.875rem;
    display: flex;
    background-color: #f3f7f9;
    padding: 1rem;
    font-size: .875rem;
    color: #00152a;
  }

  .left .alert p {
    margin: 0;
  }

  .left .alert .btn {
    margin-top: .625rem;
  }

  .left .alert > div .material-icons {
    color: #afbcca;
    margin-right: .625rem;
  }

  .modal .modal-body .right {
    flex: 50%;
    background-color: #f3f7f9;
  }

  input[readonly] {
    background-color: white;
  }

  .input-group input {
    cursor: pointer;
  }

  .input-group-addon {
    padding: .25rem .5rem;
    padding-right: 0;
  }

  .icon-square {
    font-size: 1.5rem;
    cursor: pointer;
  }

  .btn + a {
    margin-left: 1rem;
    color: #617080;
    font-size: .75rem;
  }

  .steps form {
    padding-left: 2.5rem;
  }

  .steps .step {
    margin-top: 2rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: .875rem;
    color: #00152a;
  }

  .steps .step.active {
    color: rgb(29, 54, 75);
  }

  .steps .step .title {
    font-weight: 700;
  }

  .steps .step .step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 99rem;
    background-color: var(--xpl-color-secondary-lm);
    margin-right: 1rem;
    font-size: .75rem;
    color: white;
  }

  .steps .step span.complete {
    margin-right: 1rem;
    color: #00dd8c;
  }

  .buttons {
    padding-left: 2.5rem;
  }

  iframe.preview-rendering {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: none;
    width: 100%;
    height: auto;
    max-height: 100vh;
    overflow: hidden;
  }

  iframe.preview-rendering * {
    pointer-events: none;
  }

  .right {
    padding-bottom: 2rem;
  }
</style>
