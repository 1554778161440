<template>
  <el-form @submit.native.prevent>
    <div class="field" v-if="['birthday', 'referral_source'].indexOf(fieldName) === -1">
      <label v-if="!shadow">{{ placeholder }}</label>
      <el-input v-model="field" :placeholder="placeholder" readonly />
      <div>
        <el-checkbox size="mini" v-model="req" label="Required" @change="changeRequired" />
      </div>
    </div>
    <div class="field" v-if="fieldName === 'referral_source'">
      <label v-if="!shadow">{{ placeholder }}</label>
      <div>
        <el-select v-model="field" placeholder="Referral Source" readonly style="width: 100%;">
          <el-option v-for="item in referral_types" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </div>
      <div>
        <el-checkbox size="mini" v-model="req" label="Required" @change="changeRequired" />
      </div>
    </div>
    <div class="field" v-if="fieldName === 'birthday'">
      <label>{{ placeholder }}</label>
      <el-row :span="24">
        <el-col :span="7">
        <el-select v-model="day" placeholder="Day">
          <el-option v-for="day in days" :key="day" :label="day" :value="day"></el-option>
        </el-select>
        </el-col>
        <el-col :span="7" :offset="1">
        <el-select v-model="month" placeholder="Month">
          <el-option v-for="mo in months" :key="mo" :label="mo" :value="mo"></el-option>
        </el-select>
      </el-col>
      <el-col :span="8" :offset="1">
        <el-select v-model="year" placeholder="Year">
          <el-option v-for="y in years" :key="y" :label="y" :value="y"></el-option>
        </el-select>
      </el-col>
      </el-row>
      <div>
        <el-checkbox size="mini" v-model="req" label="Required" @change="changeRequired" />
      </div>
    </div>
    <div>
      <el-switch active-text="Include" v-model="model" :disabled="disabled" @change="emitValue"></el-switch>
    </div>
  </el-form>
</template>
<script>
  import { getMindbodyReferralTypes } from '@/api';
  import EventBus from '@/event-bus';

  export default {
    props: ['placeholder', 'disabled', 'include', 'fieldName', 'shadow', 'fieldIndex', 'required'],
    data() {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let i = 0; i < 100; i += 1) {
        years.push(currentYear - i);
      }
      return {
        field: null,
        referral_types: [],
        model: this.include,
        req: this.required,
        day: null,
        month: null,
        year: null,
        days: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        years,
      };
    },
    created() {
      if (this.fieldName === 'referral_source') {
        this.fetchReferralTypes();
      }
    },
    methods: {
      emitValue(value) {
        EventBus.$emit('forms.update.field', this.fieldIndex, value);
      },
      changeRequired(value) {
        EventBus.$emit('forms.update.required', this.fieldIndex, value);
      },
      fetchReferralTypes() {
        getMindbodyReferralTypes().then((response) => {
          this.referral_types = response.types;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .field {
    width: 70%;
  }
</style>
