<template>
  <div class="condition">
    <el-form>
      <el-form-item>Any contact when they create a Pike13 profile.</el-form-item>
      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="commitCondition">Save</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    props: ['uuid', 'rule', 'campaignItem'],
    methods: {
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.uuid,
            quick: true,
          },
          timeframe: 'relative',
          time_comparison: 'within',
          start_date: null,
          end_date: null,
          relative_days: 2,
          source_types: [],
          type_filtering: false,
        };
        EventBus.$emit('condition.commit', payload);
      },
    },
  };
</script>
