<template>
  <div class="field-container">
    <div class="card-condition">
      <el-row>
        <el-col :span="1.5" class="step-col">
          <div class="field-step">
            {{ this.fieldStep }}
          </div>
        </el-col>
        <el-col :span="13">
          <span class="field-title">{{ this.title }}</span>
          <div class="text-align-left field-body-text"></div>
          <el-radio
            v-if="!hideTimeType"
            class="radio-btn field-label-text field-label-text relative"
            v-model="showTimeType"
            name="relative-check"
            label="relative"
            >Relative to today</el-radio
          >
          <el-radio
            v-if="!hideTimeType"
            class="radio-btn field-label-text field-label-text static"
            v-model="showTimeType"
            name="static-check"
            label="static"
            >Static time frame</el-radio
          >
          <transition name="fade">
            <div class="date-form">
              <el-form :model="dateForm" :rules="rules" ref="dayForm">
                <el-form-item prop="selectedDateTimeframe">
                  <div v-if="this.showTimeTypeChecker === 'relative'" class="option-subbody">
                    <div v-if="!this.hideFuture" class="subtitle">RELATIVE TO TODAY</div>
                    <el-radio
                      v-if="!this.hideFuture"
                      class="radio-btn field-label-text field-label-text"
                      v-model="dateForm.selectedDateTimeframe"
                      name="within-next-option"
                      @change="
                        $emit(
                          'update:selectedOption',
                          dateForm.selectedDateTimeframe
                        )
                      "
                      label="withinTheNext"
                      >Within the next</el-radio
                    >
                    <transition v-if="!this.hideFuture" name="input">
                      <div
                        v-if="
                          dateForm.selectedDateTimeframe === 'withinTheNext'
                        "
                        class="other-form"
                      >
                        <el-input-number
                          size="mini"
                          controls-position="right"
                          name="within-next-input"
                          v-model="dateForm.withinTheNextCount"
                          @change="
                            $emit(
                              'update:valueOne',
                              dateForm.withinTheNextCount
                            )
                          "
                          :min="1"
                          :max="1000"
                        ></el-input-number>
                        <span class="desc-label">{{ futureLabel }}</span>
                      </div>
                    </transition>
                    <transition v-if="!this.hideFuture" name="input">
                      <div
                        v-if="
                          dateForm.selectedDateTimeframe === 'withinTheNext'
                        "
                        class="desc-text"
                      >
                        {{
                          "Today would be within the next 0 days. (I.e. “Within the next 1 day” equals today and tomorrow)"
                        }}
                      </div>
                    </transition>
                    <el-radio
                      v-if="!this.hideFuture"
                      class="radio-btn field-label-text"
                      v-model="dateForm.selectedDateTimeframe"
                      name="atleast-future-option"
                      @change="
                        $emit(
                          'update:selectedOption',
                          dateForm.selectedDateTimeframe
                        )
                      "
                      label="atLeastFuture"
                      >At least</el-radio
                    >
                    <transition v-if="!this.hideFuture" name="input">
                      <div
                        v-if="
                          dateForm.selectedDateTimeframe === 'atLeastFuture'
                        "
                        class="other-form"
                      >
                        <el-input-number
                          size="mini"
                          controls-position="right"
                          name="atleast-future-input"
                          v-model="dateForm.atLeastFutureCount"
                          @change="
                            $emit(
                              'update:valueOne',
                              dateForm.atLeastFutureCount
                            )
                          "
                          :min="1"
                          :max="1000"
                        ></el-input-number>
                        <span class="desc-label">{{ futureLabel }}</span>
                      </div>
                    </transition>
                    <transition v-if="!this.hideFuture" name="input">
                      <div
                        v-if="
                          dateForm.selectedDateTimeframe === 'atLeastFuture'
                        "
                        class="desc-text"
                      >
                        {{
                          "Today would be at least the next 0 days. (I.e. “Within the next 1 day” equals today and tomorrow)"
                        }}
                      </div>
                    </transition>
                    <el-radio
                      v-if="!this.hideFuture"
                      class="radio-btn field-label-text"
                      v-model="dateForm.selectedDateTimeframe"
                      name="exactly-future-option"
                      @change="
                        $emit(
                          'update:selectedOption',
                          dateForm.selectedDateTimeframe
                        )
                      "
                      label="exactlyFuture"
                      >Exactly</el-radio
                    >
                    <transition v-if="!this.hideFuture" name="input">
                      <div
                        v-if="
                          dateForm.selectedDateTimeframe === 'exactlyFuture'
                        "
                        class="other-form"
                      >
                        <el-input-number
                          size="mini"
                          controls-position="right"
                          name="exactly-future-input"
                          v-model="dateForm.exactlyFutureCount"
                          @change="
                            $emit(
                              'update:valueOne',
                              dateForm.exactlyFutureCount
                            )
                          "
                          :min="1"
                          :max="1000"
                        ></el-input-number>
                        <span class="desc-label">{{ futureLabel }}</span>
                      </div>
                    </transition>
                    <transition v-if="!this.hideFuture" name="input">
                      <div
                        v-if="
                          dateForm.selectedDateTimeframe === 'exactlyFuture'
                        "
                        class="desc-text"
                      >
                        {{ "Today would be exactly 0 days." }}
                      </div>
                    </transition>
                    <div v-if="!this.hidePast" class="subtitle">IN THE PAST</div>
                    <el-radio
                      v-if="goalList && !this.hidePast"
                      class="radio-btn field-label-text"
                      v-model="dateForm.selectedDateTimeframe"
                      name="goal-option"
                      @change="
                        $emit(
                          'update:selectedOption',
                          dateForm.selectedDateTimeframe
                        )
                      "
                      label="sinceEntering"
                      >Since entering the campaign</el-radio
                    >

                    <el-radio
                      v-if="!this.hidePast"
                      class="radio-btn field-label-text"
                      v-model="dateForm.selectedDateTimeframe"
                      name="within-last-option"
                      @change="
                        $emit(
                          'update:selectedOption',
                          dateForm.selectedDateTimeframe
                        )
                      "
                      label="withinTheLast"
                      >Within the last</el-radio
                    >
                    <transition v-if="!this.hidePast" name="input">
                      <div
                        v-if="
                          dateForm.selectedDateTimeframe === 'withinTheLast'
                        "
                        class="other-form"
                      >
                        <el-input-number
                          size="mini"
                          controls-position="right"
                          v-model="dateForm.withinTheLastCount"
                          name="within-last-input"
                          @change="
                            $emit(
                              'update:valueOne',
                              dateForm.withinTheLastCount
                            )
                          "
                          :min="1"
                          :max="1000"
                        ></el-input-number>
                        <span class="desc-label">{{ pastLabel }}</span>
                      </div>
                    </transition>
                    <transition v-if="!this.hidePast" name="input">
                      <div
                        v-if="
                          dateForm.selectedDateTimeframe === 'withinTheLast'
                        "
                        class="desc-text"
                      >
                        {{
                          "Today is within the last 0 days. (I.e. “Within the last 1 day” equals yesterday and today)"
                        }}
                      </div>
                    </transition>
                    <el-radio
                      v-if="!this.hidePast"
                      class="radio-btn field-label-text"
                      v-model="dateForm.selectedDateTimeframe"
                      name="atleast-past-option"
                      @change="
                        $emit(
                          'update:selectedOption',
                          dateForm.selectedDateTimeframe
                        )
                      "
                      label="atLeastPast"
                      >At least</el-radio
                    >
                    <transition v-if="!this.hidePast" name="input">
                      <div
                        v-if="dateForm.selectedDateTimeframe === 'atLeastPast'"
                        class="other-form"
                      >
                        <el-input-number
                          size="mini"
                          controls-position="right"
                          v-model="dateForm.atLeastPastCount"
                          name="atleast-past-input"
                          @change="
                            $emit('update:valueOne', dateForm.atLeastPastCount)
                          "
                          :min="1"
                          :max="1000"
                        ></el-input-number>
                        <span class="desc-label">{{ pastLabel }}</span>
                      </div>
                    </transition>
                    <transition v-if="!this.hidePast" name="input">
                      <div
                        v-if="dateForm.selectedDateTimeframe === 'atLeastPast'"
                        class="desc-text"
                      >
                        {{
                          "Today is at least 0 days ago. (I.e. “At least 1 day ago” equals today and tomorrow)"
                        }}
                      </div>
                    </transition>
                    <el-radio
                      v-if="!this.hidePast"
                      class="radio-btn field-label-text"
                      v-model="dateForm.selectedDateTimeframe"
                      name="exactly-past-option"
                      @change="
                        $emit(
                          'update:selectedOption',
                          dateForm.selectedDateTimeframe
                        )
                      "
                      label="exactlyPast"
                      >Exactly</el-radio
                    >
                    <transition v-if="!this.hidePast" name="input">
                      <div
                        v-if="dateForm.selectedDateTimeframe === 'exactlyPast'"
                        class="other-form"
                      >
                        <el-input-number
                          size="mini"
                          name="exactly-past-input"
                          controls-position="right"
                          v-model="dateForm.exactlyPastCount"
                          @change="
                            $emit('update:valueOne', dateForm.exactlyPastCount)
                          "
                          :min="1"
                          :max="1000"
                        ></el-input-number>
                        <span class="desc-label">{{ pastLabel }}</span>
                      </div>
                    </transition>
                    <transition v-if="!this.hidePast" name="input">
                      <div
                        v-if="dateForm.selectedDateTimeframe === 'exactlyPast'"
                        class="desc-text"
                      >
                        {{
                          "Today is exactly 0 days. (I.e. “Exactly 1 day ago” equals yesterday)"
                        }}
                      </div>
                    </transition>
                  </div>
                  <div v-if="this.showTimeTypeChecker === 'static'" class="option-subbody">
                    <div class="subtitle">STATIC TIME FRAME</div>
                    <el-radio
                      class="radio-btn field-label-text"
                      v-model="dateForm.selectedDateTimeframe"
                      name="before-option"
                      @change="
                        $emit(
                          'update:selectedOption',
                          dateForm.selectedDateTimeframe
                        )
                      "
                      label="before"
                      >Before</el-radio
                    >
                    <transition name="input">
                      <div
                        v-if="dateForm.selectedDateTimeframe === 'before'"
                        class="other-form"
                      >
                        <el-date-picker
                        name="before-input"
                          v-model="dateForm.beforeDate"
                          @change="
                            $emit('update:valueOne', dateForm.beforeDate)
                          "
                          type="date"
                          placeholder="Pick a date"
                        >
                        </el-date-picker>
                      </div>
                    </transition>
                    <transition name="input">
                      <div
                        v-if="dateForm.selectedDateTimeframe === 'before'"
                        class="desc-text"
                      >
                        {{
                          "The date selected is not included (i.e. “Before Oct 10th” equals Oct 9th and before)"
                        }}
                      </div>
                    </transition>
                    <el-radio
                      class="radio-btn field-label-text"
                      v-model="dateForm.selectedDateTimeframe"
                      name="after-option"
                      @change="
                        $emit(
                          'update:selectedOption',
                          dateForm.selectedDateTimeframe
                        )
                      "
                      label="after"
                      >After</el-radio
                    >
                    <transition name="input">
                      <div
                        v-if="dateForm.selectedDateTimeframe === 'after'"
                        class="other-form"
                      >
                        <el-date-picker
                          v-model="dateForm.afterDate"
                          name="after-input"
                          @change="$emit('update:valueOne', dateForm.afterDate)"
                          type="date"
                          placeholder="Pick a date"
                        >
                        </el-date-picker>
                      </div>
                    </transition>
                    <transition name="input">
                      <div
                        v-if="dateForm.selectedDateTimeframe === 'after'"
                        class="desc-text"
                      >
                        {{
                          "This does not include the selection (i.e. “After Oct 10th” equals Oct 11th and after)"
                        }}
                      </div>
                    </transition>
                    <el-radio
                      class="radio-btn field-label-text"
                      v-model="dateForm.selectedDateTimeframe"
                      name="between-option"
                      @change="
                        $emit(
                          'update:selectedOption',
                          dateForm.selectedDateTimeframe
                        )
                      "
                      label="between"
                      >Between</el-radio
                    >
                    <transition name="input">
                      <div
                        v-if="dateForm.selectedDateTimeframe === 'between'"
                        class="other-form"
                      >
                        <el-date-picker
                          @change="
                            $emit('update:valueOne', dateForm.betweenDate)
                          "
                          name="between-input"
                          v-model="dateForm.betweenDate"
                          type="daterange"
                          range-separator=""
                          start-placeholder="Start date"
                          end-placeholder="End date"
                        >
                        </el-date-picker>
                      </div>
                    </transition>
                    <transition name="input">
                      <div
                        v-if="dateForm.selectedDateTimeframe === 'between'"
                        class="desc-text"
                      >
                        {{
                          "This will include both selections (i.e. “Between Oct 1st and Oct 3rd” equals Oct 1st, 2nd and 3rd)"
                        }}
                      </div>
                    </transition>
                    <el-radio
                      class="radio-btn field-label-text"
                      v-model="dateForm.selectedDateTimeframe"
                      name="specific-date-option"
                      @change="
                        $emit(
                          'update:selectedOption',
                          dateForm.selectedDateTimeframe
                        )
                      "
                      label="specificDate"
                      >On a specific date</el-radio
                    >
                    <transition name="input">
                      <div
                        v-if="dateForm.selectedDateTimeframe === 'specificDate'"
                        class="other-form"
                      >
                        <el-date-picker
                        name="specific-date-input"
                          @change="
                            $emit('update:valueOne', dateForm.specificDate)
                          "
                          v-model="dateForm.specificDate"
                          type="date"
                          placeholder="Pick a date"
                        >
                        </el-date-picker>
                      </div>
                    </transition>
                    <transition name="input">
                      <div
                        v-if="dateForm.selectedDateTimeframe === 'specificDate'"
                        class="desc-text"
                      >
                        {{ "" }}
                      </div>
                    </transition>
                    <el-radio
                      name="alltime-option"
                      class="radio-btn field-label-text"
                      v-model="dateForm.selectedDateTimeframe"
                      @change="
                        $emit(
                          'update:selectedOption',
                          dateForm.selectedDateTimeframe
                        )
                      "
                      label="allTime"
                      >All time</el-radio
                    >
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </transition>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>

  export default {
    props: [
      'fieldIndex',
      'title',
      'fieldStep',
      'selectedOption',
      'valueOne',
      'futureLabel',
      'pastLabel',
      'hideTimeType',
      'hidePast',
      'hideFuture',
      'goalList',
      'id',
    ],
    data() {
      return {
        dateForm: {
          selectedDateTimeframe: this.selectedOption,
          withinTheNextCount: null,
          atLeastFutureCount: null,
          exactlyFutureCount: null,
          withinTheLastCount: null,
          atLeastPastCount: null,
          exactlyPastCount: null,
          betweenDate: null,
          beforeDate: null,
          afterDate: null,
          specificDate: null,
          sinceEnteringCount: null,
          allTime: null,
        },
        showTimeType: 'value',
        showRelative: false,
        showStatic: false,
        rules: {
          selectedDateTimeframe: [{ required: true, trigger: 'change', message: 'This field is required.' },
            { min: 1, message: 'Should be atleast 1', trigger: 'change' },
          ],
        },
      };
    },
    created() {
      const fieldValue = this.valueOne;
      switch (this.selectedOption) {
        case 'withinTheNext':
          this.dateForm.withinTheNextCount = fieldValue;
          break;
        case 'atLeastFuture':
          this.dateForm.atLeastFutureCount = fieldValue;
          break;
        case 'exactlyFuture':
          this.dateForm.exactlyFutureCount = fieldValue;
          break;
        case 'sinceEntering':
          this.dateForm.sinceEnteringCount = fieldValue;
          break;
        case 'withinTheLast':
          this.dateForm.withinTheLastCount = fieldValue;
          break;
        case 'atLeastPast':
          this.dateForm.atLeastPastCount = fieldValue;
          break;
        case 'exactlyPast':
          this.dateForm.exactlyPastCount = fieldValue;
          break;
        case 'before':
          this.dateForm.beforeDate = fieldValue;
          break;
        case 'after':
          this.dateForm.afterDate = fieldValue;
          break;
        case 'between':
          this.dateForm.betweenDate = fieldValue;
          break;
        case 'specificDate':
          this.dateForm.specificDate = fieldValue;
          break;
        case 'allTime':
          this.dateForm.allTime = fieldValue;
          break;
        default:
      }
      if (this.hideTimeType === 'static') {
        this.showTimeType = 'relative';
      } else if (this.hideTimeType === 'relative') {
        this.showTimeType = 'static';
      }

      const SHOW_RELATIVE_OPTIONS = ['withinTheNext', 'atLeastFuture', 'exactlyFuture', 'sinceEntering', 'withinTheLast', 'atLeastPast', 'exactlyPast'];
      const SHOW_STATIC_OPTIONS = ['before', 'after', 'between', 'specificDate', 'allTime'];

      if (SHOW_RELATIVE_OPTIONS.includes(this.selectedOption)) {
        this.showTimeType = 'relative';
      } else if (SHOW_STATIC_OPTIONS.includes(this.selectedOption)) {
        this.showTimeType = 'static';
      }
    },
    watch: {
      selectedOption() {
        this.$emit('update:valueOne', 1);
        this.dateForm.withinTheNextCount = null;
        this.dateForm.atLeastFutureCount = null;
        this.dateForm.exactlyFutureCount = null;
        this.dateForm.withinTheLastCount = null;
        this.dateForm.atLeastPastCount = null;
        this.dateForm.exactlyPastCount = null;
        this.dateForm.betweenDate = null;
        this.dateForm.beforeDate = null;
        this.dateForm.afterDate = null;
        this.dateForm.specificDate = null;
        this.dateForm.sinceEnteringCount = null;
      },
    },
    computed: {
      showTimeTypeChecker() {
        if (this.showTimeType === 'relative') {
          return 'relative';
        } else if (this.showTimeType === 'static') {
          return 'static';
        }
        return null;
      },
    },
  };
</script>
<style lang="scss" scoped>
@import "src/scss/global/_spacing.scss";
@import "src/scss/global/_new_main.scss";
.card-condition {
  background-color: #FFFFFF;
  border: solid 1px var(--xpl-color-gray-400);
  box-shadow: none;
  border-radius: 0;
  cursor: auto;
}
  .card-condition:hover {
  background-color: #FFFFFF;
  cursor: auto;
  }
.field-step {
  height: 1.5rem;
  width: 1.5rem;
  background-color: $bb-color-blue-v2;
  font-family: $primary-bb-font-family;
  font-size: $base-font-size;
  color: #FFFFFF;
  border-radius: 50%;
  text-align: center;
  margin: $space-base-inline;
}
.field-body-text {
  font-family: $primary-bb-font-family;
  font-weight: 400;
  font-size: $base-loose-font-size;
  color: #00152A;
  line-height: $loose-line-height;
}
.field-title {
  font-family: $primary-bb-font-family;
  font-weight: 700;
  font-size: $base-font-size;
  color: $base-color-black-v2;
  line-height: $loose-medium-line-height;
}
.option-body {
  margin-top: 0;
}
.field-label-text /deep/ .el-radio__label {
  font-family: $primary-bb-font-family !important;
  font-size: $base-loose-font-size !important;
  color: $base-color-black-v2 !important;
  line-height: $loose-line-height !important;
  padding-left: 8px;
}
.field-label-text /deep/ .el-radio__inner {
  background-color: #F2F4F6 !important;
  border: 1px solid #A6B2BF !important;
  height: 16px;
  width: 16px;
}
.field-label-text /deep/  .el-radio__input.is-checked .el-radio__inner::after {
   background-color: var(--xpl-color-secondary-lm) !important;
   width: 12px;
   height: 12px;
}
.radio-btn {
  margin-top: $space-extra-tight;
  margin-bottom: $space-extra-tight;
}
.other-form /deep/ .el-input__inner:focus {
  border-color: var(--xpl-color-secondary-lm) !important;
}
.static {
  display: block;
}
.relative {
  margin-top: 24px;
}
.checks{
  font-family: $primary-bb-font-family !important;
  font-size: 12px;
  line-height: 16px;
  color: #39414A !important;
  font-weight: 700;
}
.checks /deep/ .el-checkbox__inner {
  height: 16px;
  width: 16px;
  border: 1px solid #DCDFE6 !important;
  z-index: 0;
}
.checks /deep/ .el-checkbox__label {
  height: 16px;
  width: 16px;
  color: #39414A !important;
}
.checks /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #F3F7F9 !important;
}
.checks /deep/ .el-checkbox__input.is-checked .el-checkbox__inner::after {
    border: 2px solid #067DFF;
    border-left: 0;
    border-top: 0;
    height: 7px;
    left: 5px;
    position: absolute;
    top: 2px;
    width: 3px;
}
.option-subbody{
  padding-left: 26px;
}
.subtitle {
  margin-bottom: 8px;
}
.field-container {
  margin-top: 28px;
}

</style>
