<template>
  <div>
    <el-card v-loading="settings.optionsSaving">
      <div class="display-small" style="margin-bottom: 2rem;">Landing Page Options</div>
      <div class="section">
        <div class="v2__heading">Is this for new or existing customers?</div>
          <el-radio v-model="settings.customerType" label="new" border>New Customers</el-radio>
          <el-radio v-model="settings.customerType" label="existing" border>Existing Customers</el-radio>
      </div>

      <div class="section">
        <div class="v2__heading">What are you selling?</div>

        <div v-if="activeVendor === 'mindbody'">
          <el-radio v-model="settings.saleType" label="pricing_option" border>Pricing Option</el-radio>
          <el-radio v-model="settings.saleType" label="contract" border>Contract</el-radio>
          <div class="options" v-if="settings.saleType">
            <el-form v-if="settings.saleType === 'pricing_option'">
              <label>Select the Pricing Option</label>
              <el-form-item>
                <el-select v-model="settings.pricing_option" filterable placeholder="Pricing Option" value-key="id" style="width: 100%;">
                  <el-option v-for="item in settings.pricing_options" :key="item.id" :label="item.name" :value="item">
                    <span>{{ item.name }}</span>
                    <span class="display-caption subdued pull-right">
                      {{ item.price | prettyCurrency }} &middot; ID: {{ item.id }}
                    </span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>

            <el-form v-if="settings.saleType === 'contract'">
              <label>Select the Contract</label>
              <el-form-item>
                <el-select v-model="settings.contract" filterable placeholder="Contract" value-key="id" style="width: 100%;">
                  <el-option
                    v-for="item in settings.contracts" :key="item.id" :label="item.name" :value="item">
                    <span>{{ item.name }}</span>
                    <span class="display-caption subdued pull-right">
                      {{ item.price | prettyCurrency }} &middot; ID: {{ item.id }}
                    </span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <div v-if="activeVendor === 'marianatek'">
          <el-radio v-model="settings.saleType" label="credit" border>Credit</el-radio>
          <el-radio v-model="settings.saleType" label="membership" border>Membership</el-radio>
          <el-radio v-model="settings.saleType" label="product" border>Other Products</el-radio>

          <div class="options" v-if="settings.saleType">
            <el-form v-if="settings.saleType === 'credit'">
              <label>Select the Credit</label>
              <el-form-item>
                <el-select v-model="settings.product" filterable placeholder="Credit" value-key="id" style="width: 100%;">
                  <el-option v-for="item in settings.credits" :key="item.id" :label="item.name" :value="item">
                    <span>{{ item.name }}</span>
                    <span class="display-caption subdued pull-right">
                      {{ item.price | prettyCurrency }}
                    </span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>

            <el-form v-if="settings.saleType === 'membership'">
              <label>Select the Membership</label>
              <el-form-item>
                <el-select v-model="settings.product" filterable placeholder="Membership" value-key="id" style="width: 100%;">
                  <el-option v-for="item in settings.memberships" :key="item.id" :label="item.name" :value="item">
                    <span>{{ item.name }}</span>
                    <span class="display-caption subdued pull-right">
                      {{ item.price | prettyCurrency }}
                    </span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>

            <el-form v-if="settings.saleType === 'product'">
              <label>Select the Product</label>
              <el-form-item>
                <el-select v-model="settings.product" filterable placeholder="Product" value-key="id" style="width: 100%;">
                  <el-option v-for="item in settings.products" :key="item.id" :label="item.name" :value="item">
                    <span>{{ item.name }}</span>
                    <span class="display-caption subdued pull-right">
                      {{ item.price | prettyCurrency }}
                    </span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="display-caption">
            <span v-if="!refreshLoading"><a href="#" @click.prevent="triggerDataRefresh">Click here</a> to trigger a data refresh.</span>
            <span v-if="refreshLoading">Loading...</span>
          </div>
        </div>
      </div>

      <el-alert :closable="false" title="Different Prices" description="Your Pricing Option has a different Online Price than the default one. We take the Online Price into account when building your Landing Page. Please update it by going into your MINDBODY Portal." type="warning" v-if="showPriceDifferentAlert" style="margin-bottom: 1rem;"></el-alert>

      <div class="section" v-if="activeVendor === 'marianatek'">
        <div class="v2__heading">What Payment Gateway should be used?</div>
        <el-form>
          <el-form-item>
            <el-select v-model="settings.shared_gateway_id" placeholder="Payment Gateway" style="width: 100%;">
              <el-option v-for="item in settings.gateways" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>

      <div class="section" v-if="settings.saleType === 'pricing_option'">
        <div class="v2__heading">Do you want to add a discount to this package?</div>
        <div>
          <el-radio v-model="settings.isDiscounted" :label="true" border>Yes, add discount</el-radio>
          <el-radio v-model="settings.isDiscounted" :label="false" border>No, keep full price</el-radio>
        </div>
        <div class="options" v-if="settings.isDiscounted">
          <el-form>
            <el-form-item>
              <label>How much of a discount?</label>
              <el-input type="number" v-model.number="settings.discount" placeholder="10">
                <template slot="prepend">$</template>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="section" v-if="settings.product && settings.product.is_discountable">
        <div class="v2__heading">Do you want to add a discount to this package?</div>
        <div>
          <el-radio v-model="settings.isDiscounted" :label="true" border>Yes, add discount</el-radio>
          <el-radio v-model="settings.isDiscounted" :label="false" border>No, keep full price</el-radio>
        </div>
        <div class="options" v-if="settings.isDiscounted">
          <el-form>
            <el-form-item>
              <label>Do you want to use an existing discount or create one?</label>
              <div>
                <el-radio v-model="settings.discountType" label="existing" border>Use an existing discount</el-radio>
                <el-radio v-model="settings.discountType" label="new" v-if="false" border>Create a new discount</el-radio>
              </div>
            </el-form-item>
          </el-form>
          <el-form v-if="settings.discountType === 'existing'">
            <el-form-item>
              <label>Select Discount</label>
              <el-select v-model="settings.discount_code" filterable placeholder="Discount" style="width: 100%;">
                <el-option v-for="item in settings.discounts" :key="item.code" :label="item.name" :value="item.code">
                  <span>{{ item.name }}</span>
                  <span class="display-caption subdued pull-right">
                    {{ item.code }}
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-form v-if="settings.discountType === 'new'">
            <el-form-item>
              <label>How much of a discount?</label>
              <el-input type="number" v-model.number="settings.discount" placeholder="10">
                <template slot="prepend">$</template>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="section" v-if="settings.saleType === 'contract'">
        <div class="v2__heading">Do you want to allow customers to select a custom Start Date?</div>
        <div>
          <el-radio v-model="settings.isCustomStartDate" :label="true" border>Yes</el-radio>
          <el-radio v-model="settings.isCustomStartDate" :label="false" border>No</el-radio>
        </div>
      </div>
      <div class="section" v-if="settings.saleType === 'contract' && settings.isCustomStartDate">
        <div class="v2__heading">Should customers get charged immediately or on Contract Start Date?</div>
        <div>
          <el-radio v-model="settings.isChargedImmediately" :label="true" border>Immediately</el-radio>
          <el-radio v-model="settings.isChargedImmediately" :label="false" border>On Start Date</el-radio>
        </div>
      </div>

      <div class="section" v-if="settings.saleType === 'contract' && settings.contract">
        <div class="v2__heading">Do you want to display the price including tax or not?</div>
        <div>
          <el-radio v-model="settings.displayInclusiveTax" :label="true" border>Yes</el-radio>
          <el-radio v-model="settings.displayInclusiveTax" :label="false" border>No</el-radio>
        </div>
      </div>
      <div class="section" v-if="settings.saleType === 'contract' && settings.contract">
        <div class="v2__heading">Do you want to assign sales of this Contract to a specific Staff Member?</div>
        <div>
          <el-radio v-model="settings.assignStaff" :label="true" border>Yes</el-radio>
          <el-radio v-model="settings.assignStaff" :label="false" border>No</el-radio>
        </div>
        <el-form v-if="settings.assignStaff">
          <el-form-item>
            <label>Select Staff Member</label>
            <el-select v-model="settings.salesRep" filterable placeholder="Staff Member" style="width: 100%;">
              <el-option v-for="item in settings.staffes" :key="item.id" :label="item.name" :value="item.id">
                <span>{{ item.name }}</span>
                <span class="display-caption subdued pull-right">
                  {{ item.id }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>

      <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="saveOptions" :disabled="!settings.customerType || !settings.saleType">Save</el-button>
    </el-card>
    <el-card v-loading="settings.appearanceSaving">
      <el-row>
        <el-col :span="11">
          <div class="display-small" style="margin-bottom: 2rem;">Appearance</div>

          <el-form ref="appearanceForm" :model="settings" :rules="appearanceRules" status-icon>
            <el-form-item prop="name">
              <label>Custom Name</label>
              <el-input type="text" v-model="settings.name" placeholder="Custom Name" />
            </el-form-item>
            <el-form-item prop="description">
              <label>Custom Description</label>
              <el-input type="text" v-model="settings.description" placeholder="Custom Description" />
              <div class="display-caption subdued">Optional</div>
            </el-form-item>
            <el-form-item v-if="activeVendor === 'marianatek'">
              <label>Add a Display Discount</label>
              <el-input type="number" v-model="settings.displayPrice" placeholder="Display Discount" />
              <div class="small text-muted">This is for display purposes only.</div>
            </el-form-item>
            <el-form-item prop="button_text">
              <label>Button Text</label>
              <el-input type="text" v-model="settings.button_text" placeholder="Button Text" />
            </el-form-item>
            <el-form-item prop="button_color">
              <label>Button Color</label>
              <div>
                <el-color-picker size="medium" v-model="settings.button_color"></el-color-picker>
              </div>
            </el-form-item>
            <el-form-item prop="phone">
              <label>Help Phone Number</label>
              <el-input type="text" v-model="settings.phone" placeholder="Phone Number" />
            </el-form-item>
            <el-form-item prop="email">
              <label>Help Email Address</label>
              <el-input type="email" v-model="settings.email" placeholder="Email Address" />
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :offset="1" :span="12">
          <div class="preview" v-if="settings.pricing_option || settings.contract || settings.product">
            <el-card :body-style="{'padding-bottom': 0}">
              <div class="name">{{ settings.name }}</div>
              <div class="description">{{ settings.description }}</div>
              <div class="pricing" v-if="!settings.discount && !settings.displayPrice">{{ price }}</div>
              <div class="pricing" v-if="settings.discount || settings.displayPrice"><span class="old">{{ price }}</span>{{ priceWithDiscount }}</div>
              <el-button native-type="button" type="primary" :style="'width: 100%; background-color: ' + settings.button_color + '; border-color: ' + settings.button_color">{{ settings.button_text }}</el-button>
              <div class="footer" v-if="!settings.phone && settings.email">
                Need help? Email us at <a :href="'mailto:'+settings.email">{{ settings.email }}</a>
              </div>
              <div class="footer" v-if="settings.phone && !settings.email">
                Need help? Call us at <a :href="'tel:'+settings.phone" target="_blank">{{ settings.phone }}</a>
              </div>
              <div class="footer" v-if="settings.phone && settings.email">
                Need help? Email us at <a :href="'mailto:'+settings.email" target="_blank">{{ settings.email }}</a>
                or call us at
                <a :href="'tel:'+settings.phone" target="_blank">{{ settings.phone }}</a>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>

      <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="saveAppearance">Save</el-button>
    </el-card>

    <el-card v-loading="settings.vanitySaving">
      <div class="display-small" style="margin-bottom: 2rem;">Landing Page Settings</div>
      <div class="section">
        <div class="v2__heading">Choose the url for your landing page</div>
        <el-form ref="vanityForm" :model="settings" :rules="vanityRules" status-icon>
          <el-form-item prop="vanity">
            <el-input placeholder="example.html" v-model="settings.vanity">
              <template slot="prepend">https://{{ checkoutflow.subdomain }}.brandbot-checkout.com/</template>
              <template slot="append">.html</template>
            </el-input>
          </el-form-item>
          <el-alert title="This has to be unique and cannot be changed once published" type="info" show-icon></el-alert>
        </el-form>
      </div>

      <div class="section">
        <div class="v2__heading">Do you want to send a customer to another page after a sucessful purchase?</div>
        <div>
          <el-radio v-model="settings.redirect" :label="true" border>Yes, redirect to another webpage</el-radio>
          <el-radio v-model="settings.redirect" :label="false" border>No, just show a success message</el-radio>
        </div>
        <div class="options" v-if="settings.redirect !== null">
          <el-form v-if="settings.redirect">
            <el-form-item prop="link">
              <label>What is the URL of the webpage?</label>
              <el-input type="text" v-model="settings.link" placeholder="https://brandbot.com" />
            </el-form-item>
          </el-form>
          <el-form v-if="!settings.redirect">
            <el-form-item>
              <label>What should the success message say?</label>
              <el-input type="text" v-model="settings.success" placeholder="Thank you for your purchase!" />
            </el-form-item>
          </el-form>
        </div>
      </div>

      <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="saveVanity">Save</el-button>
    </el-card>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['checkoutflow'],
    data() {
      const checkVanity = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('A valid url is required for your Landing Page.'));
        }

        if (!value.match(/^[0-9a-zA-Z-_]+$/)) {
          return callback(new Error('Only alphanumeric characters, "-" and "_" are allowed.'));
        }

        return callback();
      };
      const checkUrl = (rule, value, callback) => {
        if (value &&
          !value.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm)) {
          return callback(new Error('Your redirection url is not valid.'));
        }

        return callback();
      };
      return {
        activeVendor: this.$store.state.user.current_account.active_vendors[0],
        showPriceDifferentAlert: false,
        refreshLoading: false,
        settings: {
          displayInclusiveTax: false,
          vanitySaving: false,
          optionsSaving: false,
          appearanceSaving: false,
          customerType: 'existing',
          saleType: null,
          pricing_option: null,
          pricing_options: [],
          contract: null,
          contracts: [],
          product: null,
          shared_gateway_id: null,
          credits: [],
          memberships: [],
          products: [],
          gateways: [],
          discounts: [],
          discount_code: null,
          isDiscounted: false,
          discountType: 'existing',
          discount: null,
          name: '',
          description: '',
          displayPrice: null,
          button_text: 'Buy now',
          button_color: 'var(--xpl-color-secondary-lm)',
          email: this.$store.state.user.email,
          phone: '',
          vanity: '',
          redirect: false,
          link: null,
          success: 'Thank you for your purchase!',
          isCustomStartDate: false,
          isChargedImmediately: true,
          assignStaff: false,
          salesRep: null,
          staffes: [],
        },
        appearanceRules: {
          name: [{ required: true, trigger: 'change', message: 'Please select a customer type.' }],
          button_text: [{ required: true, trigger: 'change', message: 'Please fill out the button text.' }],
          button_color: [{ required: true, trigger: 'change', message: 'Please pick a button color.' }],
          email: [{ required: true, trigger: 'change', message: 'Please fill out a help email address.' }],
        },
        vanityRules: {
          vanity: [{
            validator: checkVanity,
            trigger: 'change',
          }],
          link: [{
            validator: checkUrl,
            trigger: 'blur',
          }],
        },
      };
    },
    created() {
      if (this.activeVendor === 'mindbody') {
        this.fetchPricingOptions();
        this.fetchContracts();
        this.fetchStaffes();
      }

      if (this.activeVendor === 'marianatek') {
        this.fetchProducts('credits');
        this.fetchProducts('memberships');
        this.fetchProducts('products');
        this.fetchGateways();
        this.fetchDiscounts();
      }

      if (this.checkoutflow.settings) {
        const keys = Object.keys(this.checkoutflow.settings);
        // eslint-disable-next-line
        for (let i = keys.length - 1; i >= 0; i--) {
          this.$set(this.settings, keys[i], this.checkoutflow.settings[keys[i]]);
        }
      }

      if (this.settings.salesRep) {
        this.settings.assignStaff = true;
      }
    },
    watch: {
      // eslint-disable-next-line
      'settings.displayInclusiveTax': function (newVal) {
        if (newVal === true) {
          this.settings.contract.price = this.settings.contract.full_price;
        } else {
          this.settings.contract.price = this.settings.contracts
            .filter(c => c.id === this.settings.contract.id)[0].price;
        }
      },
      // eslint-disable-next-line
      'settings.pricing_option': function (newVal) {
        if (newVal) {
          if (this.settings.name === '') {
            this.settings.name = this.settings.pricing_option.name;
          }

          // eslint-disable-next-line
          if (newVal.hasOwnProperty('default_price') && newVal.price !== newVal.default_price) {
            this.showPriceDifferentAlert = true;
          } else {
            this.showPriceDifferentAlert = false;
          }
        }
      },
      // eslint-disable-next-line
      'settings.contract': function (newVal, oldVal) {
        this.showPriceDifferentAlert = false;
        if (newVal) {
          if (this.settings.name === '') {
            this.settings.name = this.settings.contract.name;
          }
        }
      },
      // eslint-disable-next-line
      'settings.product': function (newVal, oldVal) {
        this.showPriceDifferentAlert = false;
        if (newVal) {
          if (this.settings.name === '') {
            this.settings.name = this.settings.product.name;
            this.settings.description = this.settings.product.description;
          }
        }
      },
      // eslint-disable-next-line
      'settings.saleType': function (newVal) {
        if (newVal === 'contract') {
          this.settings.pricing_option = null;
          this.settings.isDiscounted = false;
          this.settings.discount = null;
        }

        if (newVal === 'pricing_option') {
          this.settings.contract = null;
        }
      },
      // eslint-disable-next-line
      'settings.isDiscounted': function (newVal) {
        if (newVal === false) {
          this.settings.discount = null;
        }
      },
    },
    computed: {
      price() {
        if (!this.settings.pricing_option && !this.settings.contract && !this.settings.product) {
          return null;
        }

        if (this.settings.product) {
          return Number(this.settings.product.price)
          .toLocaleString(undefined, {
            style: 'currency',
            currency: this.$store.state.user.current_account.currency,
          });
        }

        if (this.settings.contract) {
          return Number(this.settings.contract.price)
          .toLocaleString(undefined, {
            style: 'currency',
            currency: this.$store.state.user.current_account.currency,
          });
        }

        return Number(this.settings.pricing_option.price)
        .toLocaleString(undefined, {
          style: 'currency',
          currency: this.$store.state.user.current_account.currency,
        });
      },
      priceWithDiscount() {
        if (this.settings.saleType === 'contract') {
          return this.price;
        }

        if (this.settings.product && !this.settings.displayPrice) {
          return Number(this.settings.product.price - this.settings.discount)
          .toLocaleString(undefined, {
            style: 'currency',
            currency: this.$store.state.user.current_account.currency,
          });
        }

        if (this.settings.product && this.settings.displayPrice) {
          return Number(this.settings.product.price - this.settings.displayPrice)
          .toLocaleString(undefined, {
            style: 'currency',
            currency: this.$store.state.user.current_account.currency,
          });
        }

        if (!this.settings.pricing_option) {
          return null;
        }

        return Number(this.settings.pricing_option.price - this.settings.discount)
        .toLocaleString(undefined, {
          style: 'currency',
          currency: this.$store.state.user.current_account.currency,
        });
      },
    },
    methods: {
      triggerDataRefresh() {
        this.refreshLoading = true;
        api.MTRefreshChildProducts().then((response) => {
          this.refreshLoading = false;
          this.$message.success(response.message);
          this.fetchProducts('credits');
          this.fetchProducts('memberships');
          this.fetchProducts('products');
        }).catch((err) => {
          this.refreshLoading = false;
          this.$message.error(err.message);
        });
      },
      saveOptions() {
        if (!this.settings.customerType) {
          this.$message.error('Please choose whether this landing page is targeted towards new or existing clients');
          return;
        }

        if (!this.settings.saleType) {
          this.$message.error('Please choose what you are trying to sell');
          return;
        }

        if (this.settings.saleType === 'pricing_option' &&
          !this.settings.pricing_option) {
          this.$message.error('Please pick a Pricing Option');
          return;
        }

        if (this.settings.saleType === 'contract' &&
          !this.settings.contract) {
          this.$message.error('Please pick a Contract');
          return;
        }

        this.settings.optionsSaving = true;
        const payload = {
          settings: {
            displayInclusiveTax: this.settings.displayInclusiveTax,
            customerType: this.settings.customerType,
            saleType: this.settings.saleType,
            pricing_option: this.settings.pricing_option,
            contract: this.settings.contract,
            product: this.settings.product,
            shared_gateway_id: this.settings.shared_gateway_id,
            isDiscounted: this.settings.isDiscounted,
            discount: this.settings.discount,
            displayPrice: this.settings.displayPrice,
            discount_code: this.settings.discount_code,
            isCustomStartDate: this.settings.isCustomStartDate,
            isChargedImmediately: this.settings.isChargedImmediately,
            name: this.settings.name,
            description: this.settings.description,
            button_text: this.settings.button_text,
            button_color: this.settings.button_color,
            email: this.settings.email,
            phone: this.settings.phone,
          },
        };

        if (this.settings.assignStaff) {
          payload.settings.salesRep = this.settings.salesRep;
        }

        api.updateCheckoutFlow(payload, this.checkoutflow.id).then((response) => {
          this.$message.success(response.message);
          this.settings.optionsSaving = false;
          EventBus.$emit('checkoutflow.refresh');
        }).catch((err) => {
          this.$message.error(err.message);
          this.settings.optionsSaving = false;
        });
      },
      saveAppearance() {
        this.$refs.appearanceForm.validate((valid) => {
          if (!valid) {
            return;
          }

          this.settings.appearanceSaving = true;
          const payload = {
            settings: {
              name: this.settings.name,
              description: this.settings.description,
              button_text: this.settings.button_text,
              button_color: this.settings.button_color,
              displayPrice: this.settings.displayPrice,
              email: this.settings.email,
              phone: this.settings.phone,
            },
          };

          api.updateCheckoutFlow(payload, this.checkoutflow.id).then((response) => {
            this.$message.success(response.message);
            this.settings.appearanceSaving = false;
            EventBus.$emit('checkoutflow.refresh');
          }).catch((err) => {
            this.$message.error(err.message);
            this.settings.appearanceSaving = false;
          });
        });
      },
      saveVanity() {
        this.$refs.vanityForm.validate((valid) => {
          if (!valid) {
            return;
          }

          this.settings.vanitySaving = true;
          const payload = {
            settings: {
              vanity: this.settings.vanity,
              redirect: this.settings.redirect,
              link: this.settings.link,
              success: this.settings.success,
            },
          };

          api.updateCheckoutFlow(payload, this.checkoutflow.id).then((response) => {
            this.$message.success(response.message);
            this.settings.vanitySaving = false;
            EventBus.$emit('checkoutflow.refresh');
          }).catch((err) => {
            this.$message.error(err.message);
            this.settings.vanitySaving = false;
          });
        });
      },
      fetchPricingOptions() {
        api.getCheckoutFlowPricingOptions().then((response) => {
          this.settings.pricing_options = response.pricing_options;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchContracts() {
        api.getContracts().then((response) => {
          this.settings.contracts = response.contracts;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchProducts(type) {
        api.MTChildProducts(type).then((response) => {
          this.settings[type] = response[type];
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchGateways() {
        api.MTGateways().then((response) => {
          this.settings.gateways = response.gateways;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchDiscounts() {
        api.MTDiscounts('code').then((response) => {
          this.settings.discounts = response.discounts;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchStaffes() {
        api.getStaffes().then((response) => {
          this.settings.staffes = response.staffes;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss">
  .input-with-color .el-input-group__prepend {
    background-color: #fff;
  }
</style>
<style lang="scss" scoped>
  .el-card + .el-card {
    margin-top: 1.875rem;
  }

  .v2__heading {
    margin-bottom: 1rem;
  }

  .section {
    margin-bottom: 2rem;
  }

  .options {
    padding: 1.25rem;
    background-color: #f5f8fb;
    margin-top: .75rem;
  }

  .options .el-form-item {
    margin-bottom: .625rem;
  }

  .preview {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    padding-left: 3.25rem;
    padding-right: 3.25rem;
    background-color: #f3f7f9;
    margin-right: -20px;
    margin-top: -20px;
    margin-bottom: -20px;
    height: 100vh;
  }

  .preview .el-card {
    width: 100%;
  }

  .preview .el-card .el-card__body {
    padding-bottom: 0;
  }

  .preview .name {
    font-size: 1.125rem;
    font-weight: 700;
    color: rgb(0, 21, 42);
    margin-bottom: .5rem;
  }

  .preview .description {
    font-size: 1rem;
    line-height: 1.5;
    color: rgb(0, 21, 42);
    margin-bottom: 1.5rem;
  }

  .preview .pricing {
    margin-bottom: 2.5rem;
    font-size: 1.25rem;
    font-weight: 700;
    color: rgb(0, 21, 42);
  }

  .preview .pricing .old {
    text-decoration: line-through;
    color: rgb(97, 112, 128);
    margin-right: .5rem;
  }

  .preview .footer {
    margin-top: 1rem;
    padding: 1rem;
    background-color: var(--xpl-color-background-lm);
    font-size: .625rem;
    font-weight: 700;
    color: rgb(97, 112, 128);
    text-align: center;
    margin-right: -20px;
    margin-left: -20px;
  }

  .preview .footer a {
    color: #2E4457;
    text-decoration: underline;
  }
</style>
