<template>
  <div class="v2__card">
    <div class="card-body">
      <div class="v2__heading" style="margin-bottom: 2rem;">Guardians Relationship Settings</div>

      <el-form ref="relationship" @submit.native.prevent>
        <el-form-item>
          <div>
            <el-radio v-model="action" label="replace">Send an email to the guardians of this account instead</el-radio>
          </div>
          <div>
            <el-radio v-model="action" label="copy">Send a copy of the email to the guardians</el-radio>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="xpl-button xpl-button--primary":loading="loading" @click.stop="saveSettings">Save</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
  import * as api from '@/api';

  export default {
    props: ['contactId', 'childBehavior'],
    data() {
      return {
        loading: false,
        action: this.childBehavior,
      };
    },
    methods: {
      saveSettings() {
        const payload = {
          child_behavior: this.action,
        };

        this.loading = true;

        api.editContact(this.contactId, payload).then((response) => {
          this.loading = false;
          this.$message.success(response.message);
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
