import Vue from 'vue';
import * as types from './mutation-types';

export default {
  [types.LOGIN](state) {
    state.pending = true;
    state.loading = true;
  },
  [types.LOGIN_SUCCESS](state) {
    state.access_token = localStorage.getItem('access_token');
    state.refresh_token = localStorage.getItem('refresh_token');
    state.isLoggedIn = true;
    state.pending = false;
    state.loading = false;
  },
  [types.LOGIN_FAILURE](state) {
    state.isLoggedIn = false;
    state.pending = false;
    state.access_token = null;
    state.refresh_token = null;
    state.user = {};
  },
  [types.FETCH_USER](state) {
    state.user = JSON.parse(localStorage.getItem('user'));
  },
  [types.LOGOUT](state) {
    state.isLoggedIn = false;
    state.access_token = null;
    state.refresh_token = null;
    state.user = {};
  },
  [types.LOADING](state) {
    state.loading = true;
  },
  [types.LOADED](state) {
    state.loading = false;
  },
  [types.SOCKET_ONOPEN](state, event) {
    Vue.prototype.$socket = event.currentTarget;
    state.socket.isConnected = true;
    // Send auth data
    if (state.user && state.user.id) {
      Vue.prototype.$socket.sendObj({
        channel: 'auth',
        body: {
          account: {
            id: state.user.current_account.id,
            twilio_sid: state.user.current_account.twilio_sid,
          },
          user: {
            id: state.user.id,
          },
        },
      });
    }
  },
  [types.SOCKET_ONCLOSE](state) {
    state.socket.isConnected = false;
  },
  [types.SOCKET_ONERROR](state) {
    state.socket.isConnected = false;
  },
  [types.SOCKET_ONMESSAGE](state, message) {
    // default handler called for all methods
    state.socket.message = message;
  },
  [types.SOCKET_RECONNECT]() {
    // mutations for reconnect methods
  },
  [types.SOCKET_RECONNECT_ERROR](state) {
    state.socket.reconnectError = true;
  },
};
