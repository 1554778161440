<template>
  <div>
    <el-card>
      <div class="v2__heading">Affiliates</div>
      <el-button type="primary" class="xpl-button xpl-button--primary" @click="addTemplates">Add Studio Grow Templates</el-button>
      <hr>
      <el-form>
        <el-form-item>
          <el-select v-model="affiliate" placeholder="Pick an Affiliate">
            <el-option :key="'Studio Grow'" :label="'Studio Grow'" :value="'Studio Grow'">
            </el-option>
          </el-select>
          <el-button type="primary" class="xpl-button xpl-button--primary" @click="markAffiliate">Save</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card>
      <div class="v2__heading">New Template</div>
        <form @submit.prevent>
          <div class="form-group">
            <label>Email Type</label>
            <Multiselect v-model="type" :options="['full', 'rich']" placeholder="Pick a type" :show-labels="false" :allow-empty="false" />
          </div>

          <div class="form-group">
            <label>Scheduled or Auto</label>
            <Multiselect v-model="mode" :options="['scheduled', 'auto']" placeholder="Pick a type" :show-labels="false" :allow-empty="false" />
          </div>

          <div class="form-group">
            <label>Purpose</label>
            <Multiselect v-model="purpose" :options="purposes" placeholder="Pick a purpose" :show-labels="false" :allow-empty="false" :searchable="true" label="name" track-by="id" :taggable="true" tag-placeholder="Add this as a new purpose" @tag="addPurpose" />
          </div>

          <div class="form-group">
            <label>Suite</label>
            <Multiselect v-model="suite" :options="suites" placeholder="Pick a suite" :show-labels="false" :allow-empty="false" :searchable="true" label="name" track-by="id" :taggable="true" tag-placeholder="Add this as a new suite" @tag="addSuite" />
          </div>

          <div class="form-group">
            <label>Name</label>
            <input type="text" v-model.trim="name" placeholder="Name" class="form-control" required="required" />
          </div>

          <div class="form-group">
            <label>Subject</label>
            <input type="text" v-model.trim="subject" placeholder="Subject" class="form-control" />
          </div>

          <div class="form-group">
            <label>Pre Header</label>
            <input type="text" v-model.trim="pre_header" placeholder="Pre Header Text" class="form-control" />
          </div>

          <div class="form-group">
            <label>MJML</label>
            <textarea v-model="mjml" class="form-control" placeholder="MJML" required="required"></textarea>
            <pre class="small" v-if="response && response.message">{{ response.message }}</pre>
          </div>

          <button type="button" class="xpl-button xpl-button--primary" :disabled="!purpose || !suite || !name || !mjml_clean" @click="save">Save</button>
        </form>
    </el-card>

    <el-card>
      <div class="v2__heading">Templates</div>
      <el-table :data="email_templates" style="width: 100%">
        <el-table-column prop="id" label="ID" width="60"></el-table-column>
        <el-table-column prop="type" label="Type" width="100"></el-table-column>
        <el-table-column prop="mode" label="Mode" width="100"></el-table-column>
        <el-table-column prop="name" label="Name" width="300"></el-table-column>
        <el-table-column prop="pre_header_text" label="Pre-Header Text" width="300"></el-table-column>
        <el-table-column prop="subject" label="Subject" width="300"></el-table-column>
        <el-table-column fixed="right" label="Operations" width="150">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editTemplate(scope.row)">Edit</el-button>
            <el-button type="text" style="color: red;" size="small" @click="deleteTemplate(scope.row.id)">Delete</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <EmailTemplateModal v-if="email_template" :template="email_template" />
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
  import EventBus from '@/event-bus';
  import _ from 'lodash';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';
  import EmailTemplateModal from './EmailTemplateModal';

  export default {
    components: {
      Multiselect,
      EmailTemplateModal,
    },
    data() {
      return {
        mode: null,
        type: null,
        purpose: null,
        purposes: [],
        suite: null,
        suites: [],
        name: '',
        mjml: '',
        mjml_clean: null,
        subject: '',
        pre_header: '',
        response: '',
        affiliate: '',
        email_templates: [],
        email_template: null,
      };
    },
    mounted() {
      EventBus.$on('edit_template.close', () => {
        this.email_template = null;
      });
    },
    created() {
      this.fetchPurposes();
      this.fetchSuites();
      this.fetchEmailTemplates();
    },
    watch: {
      mjml: _.debounce(function mjmlEdited() {
        this.mjmlEdited();
      }, 500),
    },
    methods: {
      editTemplate(row) {
        this.email_template = row;
      },
      deleteTemplate() {
        this.$message.error('Not available.');
      },
      markAffiliate() {
        api.markAsAffiliate({
          affiliate: this.affiliate,
        }).then((response) => {
          this.$message.success(response.message);
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchEmailTemplates() {
        api.getAllEmailTemplates().then((response) => {
          this.email_templates = response.templates;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      mjmlEdited() {
        if (this.type === 'rich') {
          this.mjml_clean = true;
          return;
        }

        if (this.mjml && this.mjml.length) {
          api.convertMJMLtoJSON({ payload: `${this.mjml}` }).then((response) => {
            this.response = response;
            this.mjml_clean = response;
          }).catch((err) => {
            this.response = err;
          });
        } else {
          this.response = '';
          this.mjml_clean = null;
        }
      },
      addTemplates() {
        api.addTemplates().then((response) => {
          this.$toasted.success(response.message);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchPurposes() {
        api.templatePurposes().then((response) => {
          this.purposes = response.purposes;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchSuites() {
        api.templateSuites().then((response) => {
          this.suites = response.suites;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      addPurpose(tag) {
        const payload = {
          action: 'purpose',
          name: tag,
        };

        api.newEmailTemplate(payload).then((response) => {
          this.purpose = response.model;
          this.purposes.push(response.model);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      addSuite(tag) {
        const payload = {
          action: 'suite',
          name: tag,
        };

        api.newEmailTemplate(payload).then((response) => {
          this.suite = response.model;
          this.suites.push(response.model);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      save(event) {
        const target = event.srcElement;

        target.disabled = true;

        if (!this.mjml_clean && !this.mjml_clean.mjml) {
          return;
        }

        if (!this.purpose || !this.purpose.id) {
          return;
        }

        const payload = {
          action: 'template',
          type: this.type,
          mode: this.mode,
          email_purpose_id: this.purpose.id,
          email_suite_id: this.suite.id,
          name: this.name,
          subject: this.subject,
          pre_header: this.pre_header,
        };

        if (this.type === 'full') {
          payload.mjml = JSON.stringify(this.mjml_clean.mjml);
        } else {
          payload.mjml = JSON.stringify(this.mjml);
        }

        api.newEmailTemplate(payload).then(() => {
          this.$toasted.success('Saved');
          target.disabled = false;
          this.name = '';
          this.subject = '';
          this.pre_header = '';
          this.mjml = '';
        }).catch((err) => {
          target.disabled = false;
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-card + .el-card {
    margin-top: 2rem;
  }

  .v2__heading {
    margin-bottom: 2rem;
  }
</style>
