<template>
  <el-form @submit.native.prevent>
    <div class="field" v-if="fieldName === 'country'">
      <label  data-test-field-placeholder-country v-if="!shadow">{{ placeholder }}</label>
      <div>
        <el-select  data-test-country-dropdown v-model="field" placeholder="Country" readonly style="width: 100%;">
          <el-option  data-test-country-option v-for="c in countries" :key="c.value" :label="c.label" :value="c.value"></el-option>
        </el-select>
      </div>
    </div>
    <div class="field" v-if="['country'].indexOf(fieldName) === -1">
      <label v-if="!showTooltip && !shadow" data-test-placeholder >{{ placeholder }}</label>
      <el-tooltip v-if="showTooltip" class="item" effect="light" content="This a required field for MarianaTek account creation." placement="right" popper-class="mt-auto-account-creation-tooltip 2">
      <label data-test-placeholder v-if="!shadow">{{ placeholder }}</label>
       </el-tooltip>
      <el-input data-test-field-input v-model="field" :placeholder="placeholder" readonly />
    </div>
    <div v-if="showIncludeSwitch">
      <el-switch data-test-include-switch active-text="Include" v-model="model" :disabled="disabled" @change="emitValue"></el-switch>
    </div>
  </el-form>
</template>
<script>
import countries from '@/components/Auth/countries.json';
import EventBus from '@/event-bus';

export default {
  props: [
    'placeholder',
    'disabled',
    'include',
    'fieldName',
    'fieldIndex',
    'shadow',
    'vendorSyncSettings',
    'homeLocations',
  ],
  data() {
    return {
      field: null,
      countries,
      model: this.include,
    };
  },
  methods: {
    emitValue(value) {
      EventBus.$emit('forms.update.field', this.fieldIndex, value);
    },
  },
  computed: {
    showIncludeSwitch() {
      // Check if MT account and sync is on to hide include switch for required fields
      if (this.vendorSyncSettings.active && ['first_name', 'last_name', 'phone'].includes(this.fieldName)) {
        if (this.$store.getters.currentUserActiveVendor === 'marianatek') {
          return false;
        }
      }
      // Email fields are always included
      if (this.fieldName === 'email') {
        return false;
      }
      return true;
    },
    showTooltip() {
      // Check if account is MT
      if (this.$store.getters.currentUserActiveVendor === 'marianatek') {
        // Check is sync is on to show tooltip for required standard fields
        if (['first_name', 'last_name', 'phone', 'email'].includes(this.fieldName) && this.vendorSyncSettings.active) {
          return true;
        }
      } return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.field {
  width: 70%;
}
#waiver-checkbox {
  margin-bottom: 0;
  display: block;
}
</style>
