<template>
  <div>
    <a href="#" class="go-back" @click.prevent="closeEmailStat"><i class="fa fa-caret-left"></i> Back to email preview</a>
    <Scheduled :campaignItemId="campaignItemId" v-if="event === 'scheduled'" />
    <Sent :typeUuid="typeUuid" v-if="event === 'sent'" />
    <Delivered :typeUuid="typeUuid" v-if="event === 'delivered'" />
    <Opened :typeUuid="typeUuid" v-if="event === 'opened'" />
    <Clicked :typeUuid="typeUuid" v-if="event === 'clicked'" />
    <Bounced :typeUuid="typeUuid" v-if="event === 'bounced'" />
    <Unsubscribed :typeUuid="typeUuid" v-if="event === 'unsubscribed'" />
    <Revenue :typeUuid="typeUuid" v-if="event === 'revenue'" />
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import Scheduled from './Scheduled';
  import Sent from './Sent';
  import Delivered from './Delivered';
  import Opened from './Opened';
  import Clicked from './Clicked';
  import Bounced from './Bounced';
  import Unsubscribed from './Unsubscribed';
  import Revenue from './Revenue';

  export default {
    props: ['event', 'typeUuid', 'campaignItemId'],
    components: {
      Scheduled,
      Sent,
      Delivered,
      Opened,
      Clicked,
      Bounced,
      Unsubscribed,
      Revenue,
    },
    data() {
      return {};
    },
    methods: {
      closeEmailStat() {
        EventBus.$emit('scheduled_email.stats.close');
      },
    },
  };
</script>
