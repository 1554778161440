<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner">
      Options
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <label>Select Package(s)</label>
        <div class="multiselect-block">
          <el-select v-model="selected_packages" multiple filterable collapse-tags placeholder="Packages" value-key="name" style="width: 100%;">
            <el-option v-for="item in packages"
              :key="item.name"
              :label="item.name"
              :value="item">
            </el-option>
          </el-select>
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Timeframe
    </div>

    <form @submit.prevent>
      <div class="form-group" v-if="goalList">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'entering_campaign'" @click="timeframe = 'entering_campaign'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'entering_campaign'" @click="timeframe = 'entering_campaign'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label @click="timeframe = 'entering_campaign'; comparison = null;">
              Since entering this campaign
            </label>
            <small class="form-text text-muted">See if the contact has met this condition since entering this auto-campaign</small>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'absolute'" @click="timeframe = 'absolute'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'absolute'" @click="timeframe = 'absolute'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_absolute" @click="timeframe = 'absolute'; comparison = null;">
              Finished packages around specific dates
            </label>
            <small class="form-text text-muted">Select before, after, or between static dates. e.g. After July 27th 2018</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="timeframe === 'absolute'">
        <div class="form-inline">
          <label>Packages were finished</label>
          &nbsp;
          <Multiselect v-model="comparison" placeholder="Select a comparison" :options="comparisons_date" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" style="min-width: 13rem; width: 13rem; max-width: 13rem;" />
        </div>

        <div class="dates" v-if="comparison && timeframe === 'absolute'">
          <div class="date">
            <vue-datepicker-local v-model="dates.start" :local="local" format="MM-DD-YYYY" />
          </div>
          <span v-if="comparison.value === 'between'">&nbsp;and&nbsp;</span>
          <div class="date" v-if="comparison.value === 'between'">
            <vue-datepicker-local v-model="dates.end" :local="local" format="MM-DD-YYYY" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'relative'" @click="timeframe = 'relative'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'relative'" @click="timeframe = 'relative'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_relative" @click="timeframe = 'relative'; comparison = null;">
              Finished packages relative to the current date
            </label>
            <small class="form-text text-muted">Select at least, less than, or exactly a number of days ago. This is a moving window of time. e.g. Within the last 10 days</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="timeframe === 'relative'">
        <div class="form-inline">
          Every contact who finished a package
          <Multiselect v-model="comparison" placeholder="Select a comparison" :options="comparisons_number" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" style="min-width: 13rem; width: 13rem; max-width: 13rem;" />&nbsp;
          <input type="number" name="relative_days" v-model.number="relative_days" class="form-control" min="0" style="display: inline-block; width: 5rem;" />&nbsp;
          days<span v-if="comparison && comparison.value !== 'within'">&nbsp;ago</span>.
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'all'" @click="timeframe = 'all'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'all'" @click="timeframe = 'all'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_all" @click="timeframe = 'all'; comparison = null;">
              All time
            </label>
            <small class="form-text text-muted">See if the contact has met this condition in their entire lifetime</small>
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Options
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="!any_timeframe" @click="any_timeframe = true"></span>
            <span class="icon icon-radio-checked" v-if="any_timeframe" @click="any_timeframe = true"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_absolute" @click="any_timeframe = true">
              Finished classes in any amount of time
            </label>
            <div class="small text-muted">Get contacts who used all of their visits in the selected Class Package(s) in any amount of time.</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="any_timeframe" @click="any_timeframe = false"></span>
            <span class="icon icon-radio-checked" v-if="!any_timeframe" @click="any_timeframe = false"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_relative" @click="any_timeframe = false">
              Finished classes in a specific amount of time
            </label>
            <div class="small text-muted">Get contacts who used all of their visits in the selected Class Package(s) in a specific amount of time.</div>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="!any_timeframe">
        Contacts who finished their Class Package(s) in&nbsp;
        <el-radio-group v-model="timeframe_type">
          <el-radio-button :label="'less'">Less than</el-radio-button>
          <el-radio-button :label="'exactly'">Exactly</el-radio-button>
          <el-radio-button :label="'more'">More than</el-radio-button>
        </el-radio-group>
        <div>
          <el-input type="number" v-model.number="days" placeholder="1" style="display: inline-block;" min="0" />&nbsp;days.
        </div>
      </div>
    </form>

    <div class="form-group">
      <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition" :disabled="!any_timeframe && (days === null || days < 0 || days === '')">Save this condition</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';
  import VueDatepickerLocal from 'vue-datepicker-local';

  export default {
    props: ['condition', 'editedRule', 'enrollment', 'goalList'],
    components: {
      Multiselect,
      VueDatepickerLocal,
    },
    data() {
      return {
        timeframe_type: 'exactly',
        selected_packages: [],
        packages: [],
        any_package: true,
        any_timeframe: true,
        days: null,
        timeframe: 'all',
        comparison: null,
        relative_days: 0,
        comparisons_date: [{
          label: 'After',
          value: 'after',
        }, {
          label: 'Before',
          value: 'before',
        }, {
          label: 'Between',
          value: 'between',
        }],
        comparisons_number: [{
          label: 'At least',
          value: 'more',
        }, {
          label: 'Within the last',
          value: 'within',
        }, {
          label: 'Exactly',
          value: 'exactly',
        }],
        dates: {
          start: '',
          end: '',
        },
        local: {
          dow: 1,
          hourTip: '',
          minuteTip: '',
          secondTip: '',
          yearSuffix: '',
          monthsHead: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
          months: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
          weeks: 'Mon_Tue_Wed_Thu_Fri_Sat_Sun'.split('_'),
        },
      };
    },
    created() {
      this.fetchPricingOptionPackages();

      if (this.editedRule) {
        this.any_package = this.editedRule.any_package;
        this.any_timeframe = this.editedRule.any_timeframe;
        this.selected_packages = this.editedRule.selected_packages;
        this.days = this.editedRule.days;
        this.timeframe_type = this.editedRule.timeframe_type;

        this.timeframe = this.editedRule.timeframe;

        if (this.timeframe === 'relative') {
          this.comparison = this.comparisons_number.filter(c =>
            c.value === this.editedRule.comparison,
          )[0];
        }
        if (this.timeframe === 'absolute') {
          this.comparison = this.comparisons_date.filter(c =>
            c.value === this.editedRule.comparison,
          )[0];
        }

        this.relative_days = this.editedRule.relative_days;
        this.dates.start = this.editedRule.start_date;
        this.dates.end = this.editedRule.end_date;
      }
    },
    methods: {
      fetchPricingOptionPackages() {
        api.getPricingOptionsPackages().then((response) => {
          this.packages = response.pricing_options;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          any_package: this.any_package,
          selected_packages: this.selected_packages,
          any_timeframe: this.any_timeframe,
          timeframe_type: this.timeframe_type,
          days: this.days,
          timeframe: this.timeframe,
          relative_days: this.relative_days,
          comparison: this.comparison ? this.comparison.value : null,
          start_date: this.dates.start,
          end_date: this.dates.end,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }
</style>
