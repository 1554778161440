<template>
  <div class="loading-wrapper">
    <div class="text">
      <i class="fa fa-spin fa-spinner fa-fw"></i>
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingBox',
  props: ['message'],
};
</script>

<style lang="scss" scoped>
.loading-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .text {
    flex-shrink: 0;
    color: rgba(1,1,1,0.5);
    text-align: center;
  }
}
</style>
