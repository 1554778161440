//import router from '../router';
import * as types from './mutation-types';
import * as api from '../api';

export default {
  // eslint-disable-next-line no-unused-vars
  login({state, commit, rootState}, creds) {
    commit(types.LOGIN);
    commit(types.LOADING);

    return new Promise((resolve, reject) => {
      api.loginUser(creds).then((response) => {
        if (localStorage.getItem('access_token')) {
          resolve(response);
          commit(types.LOGIN_SUCCESS);
        }
      })
        .catch((err) => {
          reject(err);
        });
    });
  },
  logout({commit}) {
    commit(types.LOGOUT);
    //router.push('/login');
    window.location.href = '/login';
  },
  refreshUser({commit}) {
    return new Promise((resolve, reject) => {
      if (!localStorage.getItem('access_token') ||
        localStorage.getItem('access_token') === null) {
        reject();
      }

      api.getCurrentUser()
        .then(() => {
          commit(types.FETCH_USER);
          resolve();
        }).catch((err) => {
        reject(err);
      });
    });
  },
  signup({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.signup(payload).then((response) => {
        resolve(response);
        commit(types.LOGIN_SUCCESS);
      })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
