<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">1</span>
        </el-col>
        <el-col :span="22" class="v2__heading">What custom field?</el-col>
      </el-row>

      <el-row>
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-form>
              <el-form-item>
                <label>Field Name</label>
                <el-select v-model="field" filterable placeholder="Choose a field" style="width: 100%;" value-key="label">
                  <el-option v-for="field in fields" :key="field.label" :label="field.label" :value="field"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>

          <el-card :shadow="'none'" v-if="field">
            <div class="" style="margin-bottom: 1.5rem;">Custom Field Options</div>

            <el-form v-if="field && field.type === 'string'" @submit.native.prevent>
              <el-row>
                <el-radio v-model="option" label="is" size="small">Is</el-radio>
                <el-col :offset="1" :span="23">
                  <el-input v-model="value" size="small" v-if="option === 'is'" />
                </el-col>
              </el-row>
              <el-row>
                <el-radio v-model="option" label="is-not" size="small">Is not</el-radio>
                <el-col :offset="1" :span="23">
                  <el-input v-model="value" size="small" v-if="option === 'is-not'" />
                </el-col>
              </el-row>
              <el-row>
                <el-radio v-model="option" label="contains" size="small">Contains</el-radio>
                <el-col :offset="1" :span="23">
                  <el-input v-model="value" size="small" v-if="option === 'contains'" />
                </el-col>
              </el-row>
              <el-row>
                <el-radio v-model="option" label="contains-not" size="small">Does not contain</el-radio>
                <el-col :offset="1" :span="23">
                  <el-input v-model="value" size="small" v-if="option === 'contains-not'" />
                </el-col>
              </el-row>
            </el-form>

            <el-form v-if="field && field.type === 'boolean'" @submit.native.prevent>
              <el-row>
                <el-radio v-model="option" label="true" size="small">Is true</el-radio>
              </el-row>
              <el-row>
                <el-radio v-model="option" label="false" size="small">Is false</el-radio>
              </el-row>
            </el-form>

            <el-form v-if="field && field.type === 'date'" @submit.native.prevent>
              <div class="display-caption">Relative</div>
              <el-row>
                <el-radio v-model="option" label="more" size="small">More than</el-radio>
                <el-col :offset="1" :span="23" v-if="option === 'more'">
                  <el-input-number v-model="value" size="small" :min="1" :step="1" />
                  <span class="small">days ago</span>
                </el-col>
              </el-row>
              <el-row>
                <el-radio v-model="option" label="exactly" size="small">Exactly</el-radio>
                <el-col :offset="1" :span="23" v-if="option === 'exactly'">
                  <el-input-number v-model="value" size="small" :min="1" :step="1" />
                  <span class="small">days ago</span>
                </el-col>
              </el-row>
              <el-row>
                <el-radio v-model="option" label="less" size="small">Less than</el-radio>
                <el-col :offset="1" :span="23" v-if="option === 'less'">
                  <el-input-number v-model="value" size="small" :min="1" :step="1" />
                  <span class="small">days ago</span>
                </el-col>
              </el-row>

              <div class="display-caption" style="margin-top: 1rem;">Absolute</div>
              <el-row>
                <el-radio v-model="option" label="after" size="small">After</el-radio>
                <el-col :offset="1" :span="23" v-if="option === 'after'">
                  <el-date-picker v-model="value_date" type="date" value-format="yyyy-MM-dd" placeholder="Select a date" size="small"></el-date-picker>
                </el-col>
              </el-row>
              <el-row>
                <el-radio v-model="option" label="on" size="small">On</el-radio>
                <el-col :offset="1" :span="23" v-if="option === 'on'">
                  <el-date-picker v-model="value_date" type="date" value-format="yyyy-MM-dd" placeholder="Select a date" size="small"></el-date-picker>
                </el-col>
              </el-row>
              <el-row>
                <el-radio v-model="option" label="before" size="small">Before</el-radio>
                <el-col :offset="1" :span="23" v-if="option === 'before'">
                  <el-date-picker v-model="value_date" type="date" value-format="yyyy-MM-dd" placeholder="Select a date" size="small"></el-date-picker>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <el-form>
      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="commitCondition">Save this condition</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['condition', 'editedRule'],
    data() {
      return {
        field: null,
        fields: [],
        option: null,
        value: null,
        value_date: null,
      };
    },
    created() {
      this.fetchFields();

      if (this.editedRule) {
        this.field = this.editedRule.field;
        this.option = this.editedRule.option;
        this.value = this.editedRule.value;
        this.value_date = this.editedRule.value_date;
      }
    },
    methods: {
      fetchFields() {
        api.getPikeCustomFields().then((response) => {
          this.fields = response.fields;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          field: this.field,
          option: this.option,
          value: this.value,
          value_date: this.value_date,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
 .options {
    padding: 1.25rem;
    background-color: #f5f8fb;
    margin-bottom: 1.5rem;
  }
</style>
