<template>
  <div class="sidebar-inner" style="width: 100%;">
    <el-form>
      <el-form-item>
        <label>Contact has the following Preferred Location</label>
        <el-select v-model="location" clearable placeholder="No Preferred Location" style="width: 100%;" value-key="location_id">
          <el-option v-for="item in filteredLocations" :key="item.location_id" :label="item.location_name" :value="item"></el-option>
        </el-select>
        <div class="small text-muted">To get contacts who do not have a Preferred Location, leave this field empty.</div>
      </el-form-item>

      <el-form-item>
        <div class="alert">
          <div><span class="material-icons">info_outline</span></div>
          <div>
            <div class="body">We give every contact a "Preferred Location" when they visit that location more than 50% of their total visits. When we don’t have enough data, we default to your first location.</div>
          </div>
        </div>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="commitCondition">Save this condition</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    props: ['condition', 'editedRule', 'locations'],
    data() {
      return {
        location: null,
      };
    },
    created() {
      if (this.editedRule) {
        this.location = this.editedRule.location;
      }
    },
    computed: {
      filteredLocations() {
        return this.locations.filter(l => l.location_name !== 'Online Store');
      },
    },
    methods: {
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          location: this.location,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar-inner {
    padding-bottom: 5rem;
    min-height: 70vh;
  }

  .alert {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: .75rem;
    background-color: #f3f7f9;
    border-radius: 6px;
  }

  .alert .material-icons {
    font-size: 1rem;
    color: #afbcca;
    margin-right: .625rem;
  }

  .alert .body {
    font-weight: 700;
    font-size: .75rem;
    line-height: 1rem;
    color: #1d364b;
  }
</style>
