<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            {{ editableGroup ? 'Edit User Group' : 'New User Group' }}
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <input type="text" v-model.trim="name" class="form-control" placeholder="Name" />
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="button" class="xpl-button xpl-button--primary" :disabled="!name" @click.stop="doGroup">{{ editableGroup ? 'Edit' : 'Create' }}</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['editableGroup'],
    data() {
      return {
        name: '',
      };
    },
    created() {
      if (this.editableGroup) {
        this.name = this.editableGroup.name;
      }
    },
    methods: {
      doGroup() {
        const payload = {
          name: this.name,
        };

        if (this.editableGroup) {
          api.editUserGroup(this.editableGroup.id, payload).then((response) => {
            this.$toasted.success(response.message);
            this.closeModal();
          }).catch((err) => {
            this.$toasted.error(err.message);
          });
        } else {
          api.newUserGroup(payload).then((response) => {
            this.$toasted.success(response.message);
            this.closeModal();
          }).catch((err) => {
            this.$toasted.error(err.message);
          });
        }
      },
      closeModal() {
        EventBus.$emit('user_group.close');
      },
    },
  };
</script>
