<template>
  <div class="fonts">
    <div class="sidebar">
      <h2>Header</h2>
      <form @submit.prevent v-if="metadata">
        <div class="form-group">
          <label>Header Font</label>
          <Multiselect v-model="header_font_family" placeholder="Change Font Family" :options="available_fonts" :searchable="true" label="label" track-by="value" :allow-empty="false" :show-labels="false" @input="attributeEdited">
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <div class="option__title">{{ props.option.label }}</div>
                <span class="option__small">{{ props.option.safe ? 'Web Safe' : 'Custom' }}</span>
              </div>
            </template>
          </Multiselect>
        </div>

        <div class="form-group">
          <label>Header Color</label>
          <div class="input-group" @click="show_header_color_picker = !show_header_color_picker">
            <span class="input-group-addon">
              <span class="icon icon-square" :style="'color: ' + header_color.hex"></span>
            </span>
            <input type="text" ref="header_color" v-model="header_color.hex" class="form-control" readonly />
          </div>
          <Sketch v-model="header_color" :disableAlpha="true" @input="attributeEdited" v-if="show_header_color_picker" />
        </div>

        <a class="text-muted" href="#" @click="see_more = !see_more">{{ see_more ? 'Less' : 'More' }} Options <i class="fa fa-caret-down"></i></a>

        <div v-if="see_more" style="width: 100%;">
          <div class="form-group">
            <label>Transform</label>
            <Multiselect v-model="metadata.header_text_transform" placeholder="Text Transform" :options="['None', 'Capitalize', 'Uppercase', 'Lowercase']" :allow-empty="false" :show-labels="false" @input="attributeEdited" />
          </div>

          <div class="form-group">
            <label>Letter Spacing</label>
            <Multiselect v-model="metadata.header_letter_spacing" placeholder="Letter Spacing" :options="['0px', '0.5px', '1px', '2px', '3px']" :allow-empty="false" :show-labels="false" @input="attributeEdited" />
          </div>
        </div>

        <button class="xpl-button xpl-button--primary" @click.stop="nextStep">Next</button>
      </form>
    </div>
    <div class="preview">
      <div class="alert">
        <div class="illustration">
          <span class="material-icons">check_circle</span>
        </div>
        <div>
          <h1>This is a preview of your selections.</h1>
          <p>You are setting the defaults for this account. These defaults can be changed at any time. Also every element in every email can be styled specifically for that email.</p>
        </div>
      </div>

      <div class="branding_preview" v-html="preview_html">
      </div>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';
  import _ from 'lodash';
  import { Sketch } from 'vue-color';
  import Fonts from '../../EditBlocks/fonts.json';

  export default {
    components: {
      Sketch,
      Multiselect,
    },
    data() {
      return {
        see_more: false,
        preview_html: null,
        show_header_color_picker: false,
        header_color: {
          hex: null,
        },
        header_font_family: null,
        metadata: null,
        available_fonts: Fonts,
      };
    },
    created() {
      this.fetchBrandingPreview();
    },
    methods: {
      nextStep() {
        EventBus.$emit('brand_builder.move_to', 'fonts_subheader');
      },
      attributeEdited: _.debounce(function customFunction() {
        const payload = {
          header_color: this.header_color.hex,
          header_text_transform: this.metadata.header_text_transform,
          header_letter_spacing: this.metadata.header_letter_spacing,
          header_font_family: this.header_font_family.value,
        };

        api.saveBrandingMetadata(payload).then(() => {
          this.fetchBrandingPreview();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      }, 300,
      ),
      fetchBrandingPreview() {
        api.getBrandingPreview().then((response) => {
          this.preview_html = response.preview_html;
          this.metadata = response.metadata;

          this.$nextTick(() => {
            this.header_color.hex = this.metadata.header_color;
            this.header_font_family = this.available_fonts.filter(f =>
              f.value === response.metadata.header_font_family,
            )[0];
          });
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .vc-sketch {
    position: absolute;
    z-index: 100;
  }

  .fonts {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100vw;
    height: calc(100vh - 3.5rem);
    padding-left: 20rem;
    background-color: rgb(243, 247, 249);
  }

  .fonts h2 {
    font-size: 1rem;
    font-weight: 700;
    color: #1d364b;
    margin-bottom: .5rem;
  }

  .sidebar {
    position: fixed;
    left: 0;
    min-width: 20rem;
    width: 20rem;
    background-color: white;
    padding: 1rem;
    height: calc(100% - 3.5rem);
  }

  .sidebar form {
    width: 100%;
    height: calc(100% - 3.5rem);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .sidebar form .form-group {
    width: 100%;
  }

  .sidebar form button {
    margin-top: auto;
  }

  .preview {
    flex: 75%;
    padding: 1rem;
    background-color: rgb(243, 247, 249);
    height: 100%;
  }

  .preview .alert {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 0;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: rgb(255, 255, 255);
  }

  .preview .alert h1 {
    font-size: .875rem;
    line-height: 1.25rem;
    color: rgb(97, 112, 128);
    margin-bottom: 0;
  }

  .preview .alert p {
    font-size: .75rem;
    line-height: 1rem;
    color: rgb(29, 54, 75);
    margin: 0;
  }

  .preview .alert .illustration {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(217, 231, 240);
    width: 2rem;
    max-width: 2rem;
    height: 2rem;
    max-height: 2rem;
    border-radius: 99rem;
    color: white;
    margin-right: 1rem;
  }

  input[readonly] {
    background-color: white;
  }

  .input-group {
    z-index: 0;
  }

  .input-group input {
    cursor: pointer;
  }

  .input-group-addon {
    padding: .25rem .5rem;
    padding-right: 0;
  }

  .icon-square {
    font-size: 1.5rem;
    cursor: pointer;
  }

  a.text-muted {
    font-size: .875rem;
    color: #848f99;
    margin-bottom: 1rem;
  }

  .branding_preview {
    pointer-events: none;
    margin-left: -1rem;
    margin-right: -1rem;
  }
</style>
