<template>
  <div>
    <div class="card">
      <div class="card-header card-header-merged card-header-secondary">Activation</div>
      <div class="card-body">
        <p class="text-success" v-if="$store.state.user.current_account.website_url">
          This Account has a Website URL.
        </p>
        <p class="text-danger" v-if="!$store.state.user.current_account.website_url">
          This Account does not have a Website URL.
        </p>

        <p class="text-success" v-if="subdomain">
          This Account has a subdomain.
        </p>
        <p class="text-danger" v-if="!subdomain">
          This Account does not have a subdomain.
        </p>

        <p class="text-success" v-if="$store.state.user.current_account.liability_waiver">
          This Account has a Liability Waiver.
        </p>
        <p class="text-danger" v-if="!$store.state.user.current_account.liability_waiver">
          This Account does not have a Liability Waiver.
        </p>

        <button type="button" class="xpl-button xpl-button--primary" @click.stop="activate" v-if="!$store.state.user.current_account.checkout_enabled">Activate for this account</button>
      </div>
    </div>
    <div class="card">
      <div class="card-header card-header-merged card-header-secondary">Subdomain</div>
      <div class="card-body">
        <div class="title">Current Subdomain</div>
        <form @submit.prevent>
          <div class="form-group">
            <div class="input-group">
              <input type="text" v-model.trim="subdomain" class="form-control" placeholder="" />
              <div class="input-group-append">
                <span class="input-group-text">.brandbot-checkout.com</span>
              </div>
            </div>
          </div>

          <div class="form-group">
            <button type="submit" class="xpl-button xpl-button--secondary xpl-button--warning" @click.stop="editSubdomain">Change</button>
          </div>
        </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import * as api from '@/api';

  export default {
    data() {
      return {
        subdomain: null,
      };
    },
    created() {
      this.subdomain = this.$store.getters.currentAccountSubdomain;
    },
    methods: {
      activate(event) {
        event.target.disabled = true;

        const payload = {
          account_id: this.$store.state.user.current_account.id,
        };

        api.activateCheckoutFlow(payload).then((response) => {
          this.$store.dispatch('refreshUser').then(() => {
            this.$toasted.success(response.message);
          });
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      editSubdomain() {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to edit this subdomain? It will invalidate all the existing pages.')) {
          return;
        }

        const payload = {
          subdomain: this.subdomain,
        };

        api.editSubdomain(payload).then((response) => {
          this.$toasted.success(response.message);
          this.$store.dispatch('refreshUser');
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .card + .card {
    margin-top: 2rem;
  }

  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }

  .input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group > .form-control {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    border-left: 2px solid #CDD7E2;
  }

  .input-group-append {
    margin-left: -1px;
    display: flex;
  }

  .input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem;
  }

  .input-group > .input-group-append > .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
</style>
