<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="spacing">
      <YesNoComponent
        :id="1"
        :selection.sync="isCustomer"
        :currentlyOpen="currentlyOpen"
        :notEditState="!editedRule"
        :title="'Is a Customer / Is Not a Customer?'"
        :yesCopy="'Is'"
        :noCopy="'Is Not'"
        :descLabel="''"
        :yesSummary="'Is a Vimeo Customer'"
        :noSummary="'Is not a Vimeo Customer'"
        :showLabel="false"
        :initiatesDropDown="false"
      />
      <TimeComponent
        :id="2"
        :currentlyOpen="currentlyOpen"
        :notEditState="!editedRule"
        :title="'When Did This Person Become a Customer?'"
        :futureLabel="'days'"
        :pastLabel="'days ago'"
        :absLabel="'days'"
        :showAbs="true"
        :showPast="true"
        :inDropDown="false"
        :selectedOption.sync="timeframeComparison"
        :valueOne.sync="timeframeValue"
        :goalList="goalList"
      />
    </div>
    <div class="save-condition-container" v-if="showSaveButton">
      <el-button class="save-condition-btn" type="primary" @click="commitCondition">Save</el-button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import YesNoComponent from '../MarianaTek/Components/YesNoComponent';
  import TimeComponent from '../MarianaTek/Components/TimeComponent';

  export default {
    props: ['condition', 'editedRule', 'goalList'],
    components: {
      YesNoComponent,
      TimeComponent,
    },
    data() {
      return {
        currentlyOpen: this.editedRule ? null : 1,
        options: [],
        isCustomer: true,
        firstPass: true,
        timeframeComparison: 'allTime',
        timeframeValue: 1,
        completedSteps: this.editedRule ?
        { 1: true, 2: true } : { 1: true, 2: false },
      };
    },
    mounted() {
      EventBus.$on('update:currentlyOpen', (id) => {
        this.handleCurrentlyOpen(id);
      });
    },
    created() {
      if (this.editedRule) {
        this.firstPass = false;
        this.currentlyOpen = null;

        this.timeframeComparison = this.editedRule.customer_creation_comparison;
        this.timeframeValue = this.editedRule.customer_creation_value;
        this.isCustomer = this.editedRule.is_customer;
      }
    },
    beforeDestroy() {
      EventBus.$off('update:currentlyOpen');
    },
    computed: {
      showSaveButton() {
        if (!this.timeframeComparison) {
          return false;
        }

        return true;
      },
    },
    methods: {
      handleCurrentlyOpen(id) {
        this.currentlyOpen = this.currentlyOpen === id ? id + 1 : id;
        if (!this.completedSteps[this.currentlyOpen]) {
          this.completedSteps[this.currentlyOpen] = true;
        }
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          customer_creation_comparison: this.timeframeComparison,
          customer_creation_value: this.timeframeValue,
          is_customer: this.isCustomer,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar-inner {
    height: 100%;
    position: relative;
    padding-top: 0;
    background-color: #F2F4F6;
  }
</style>
