<template>
  <transition name="slidy">
    <div class="conditions-sidebar" v-if="!add_condition">
      <GlobalRuleHeader ruleName=""/>
      <div class="conditions-sidebar-body">
        <div class="conditions-rule" v-if="contact_list.rule">
          <div v-for="(rule, index) in contact_list.rule">
            <div :class="[rule.confirm ? 'rule confirm' : 'rule']">
              <div class="confirm-header">
                Are you sure you want to remove this condition?
                <div class="actions">
                  <button type="button" class="btn-sm xpl-button xpl-button--secondary xpl-button--warning"
                          @click="deleteCondition(index, true)">Remove
                  </button>
                  <button type="button" class="btn-sm xpl-button xpl-button--secondary" @click="rule.confirm = false">Cancel</button>
                </div>
              </div>
              <div class="header" @click="editCondition(rule, index)">
                <div><img :src="rule.condition.icon_url"/> {{ rule.condition.name }}</div>
                <div class="remove" @click.stop="deleteCondition(index)">Remove <span class="icon icon-bin"></span>
                </div>
              </div>
              <div v-if="rule && rule.condition" class="body-container" @click="editCondition(rule, index)">
                <div class="body">
                  <BrandBotSummary v-if="rule.condition.vendor === 'brandbot'" :condition="rule"/>
                  <div v-for="step in rule.condition.steps">
                    <RuleSummaryRow :step="step"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="and-separator">AND</div>
          </div>
        </div>
        <img src="https://assets.brandbot.com/public/empty-states/empty-rule.svg" class="empty"
             v-if="!contact_list.rule || !contact_list.rule.length"/>
        <button type="button" class="xpl-button xpl-button--secondary" v-if="!contact_list.rule || !contact_list.rule.length"
                @click="add_condition = true">Add a condition
        </button>
        <button type="button" class="xpl-button xpl-button--secondary" v-if="contact_list.rule && contact_list.rule.length"
                @click="add_condition = true">Add another condition
        </button>
        <div class="spacer"><hr/></div>
      </div>
      <div class="conditions-sidebar-footer">
        <button type="button" class="xpl-button xpl-button--primary" @click="saveSmartRule">Save Rule</button>
      </div>
    </div>
    <ConditionsSelection v-if="add_condition"
                         :selectedCondition.sync="selectedCondition"
                         :editedRule.sync="editedRule"/>
  </transition>
</template>
<script>
import EventBus from '@/event-bus';
import * as api from '@/api';
import GlobalRuleHeader from '@/components/Global/GlobalRuleHeader';
import ConditionsSelection from './ConditionsSelection';
import RuleSummaryRow from './Summaries/RuleSummaryRow';
import BrandBotSummary from './Summaries/BrandBotSummary';
import RuleEnglish from './Summaries/RuleEnglish';
import RuleEnglishHybrid from '../RuleEnglishHybrid';

export default {
  props: ['contact_list_ref'],
  data() {
    return {
      add_condition: false,
      contact_list: {},
      rules: [],
      selectedCondition: null,
      editedRule: null,
      editedRuleIndex: null,
    };
  },
  components: {
    RuleEnglishHybrid,
    RuleEnglish,
    BrandBotSummary,
    RuleSummaryRow,
    ConditionsSelection,
    GlobalRuleHeader,
  },
  async mounted() {
    this.contact_list = JSON.parse(JSON.stringify(this.contact_list_ref));
    // this.fetchLocations();
    this.contact_list.rule = await api.applyTemplateProperties(this.contact_list.rule);
  },
  created() {
    // Stop scrolling
    document.body.classList.add('stop-scroll');

    EventBus.$on('conditions_selection.pick', (condition) => {
      this.selectedCondition = condition;
    });

    EventBus.$on('selected_condition.close', () => {
      this.add_condition = false;
      this.selectedCondition = null;
      this.editedRule = null;
    });

    EventBus.$on('condition_selection.back', () => {
      this.selectedCondition = null;
      this.editedRule = null;
    });

    EventBus.$on('conditions_selection.commit', (payload) => {
      //
      if (!payload.condition.steps) {
        payload.condition.vendor = 'brandbot';
        payload.condition.icon_url = `https://assets.brandbot.com/public/icons/conditions/${payload.condition.uuid}.svg`;
      }
      // If we're editing, let's simply replace the values we're editing
      if (this.editedRule) {
        this.contact_list.rule[this.editedRuleIndex] = payload;
      }

      if (this.contact_list.rule && !this.editedRule) {
        // We replace similar one if it exists AND if it is supposed to be unique
        if (this.contact_list.rule.filter(r =>
          r.condition.uuid === payload.condition.uuid,
        ).length &&
          payload.condition.unique) {
          this.contact_list.rule = this.contact_list.rule.filter(r =>
            r.condition.uuid !== payload.condition.uuid,
          );

          this.contact_list.rule.push(payload);
        } else {
          // Or we simply push if new
          this.contact_list.rule.push(payload);
        }
      } else if (!this.contact_list.rule && !this.editedRule) {
        // If current rule is empty, we initialize and push to.
        this.contact_list.rule = [];
        this.contact_list.rule.push(payload);
      }

      // Reset data holders to initial state.
      this.add_condition = false;
      this.selectedCondition = null;
      this.editedRule = null;
      this.editedRuleIndex = null;
    });
  },
  beforeDestroy() {
    document.body.classList.remove('stop-scroll');
    EventBus.$off('conditions_selection.commit');
    EventBus.$off('selected_condition.close');
    EventBus.$off('conditions_selection.pick');
    EventBus.$off('condition_selection.back');
  },
  methods: {
    closeSidebar() {
      EventBus.$emit('contact_list.conditions_sidebar.close');
    },
    deleteCondition(index, confirm = false) {
      if (!confirm) {
        this.$set(this.contact_list.rule[index], 'confirm', true);
        return;
      }

      if (confirm) {
        this.contact_list.rule.splice(index, 1);
      }
    },
    editCondition(rule, index) {
      this.editedRule = rule;
      this.editedRuleIndex = index;
      this.add_condition = true;
      EventBus.$emit('selected_condition.edit', rule);
      this.selectedCondition = rule;
    },
    saveSmartRule(event) {

      if(!this.contact_list?.rule?.length) return;

      const target = event.srcElement || event.target;
      const originalInner = target.innerHTML;

      target.disabled = true;
      target.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';

      const rules = [];
      this.contact_list.rule.forEach((condition) => {
        //
        if (condition.condition.vendor === 'brandbot') {
          rules.push(condition);
        } else {
          const rule = api.UIASmartRule(condition);
          rules.push({ condition: rule });
        }
      });

      const payload = {
        contact_list_id: this.contact_list.id,
        rule: JSON.stringify(rules),
      };

      api.saveSmartRule(payload)
        .then((response) => {
          this.$toasted.success(response.message);
          EventBus.$emit('contact_list.refresh');
          this.closeSidebar();
          target.disabled = false;
          target.innerHTML = originalInner;
        })
        .catch((err) => {
          this.$toasted.error(err.message);
          target.innerHTML = originalInner;
          target.disabled = false;
        });
    },
  },
};
</script>
<style lang="scss">
.conditions-sidebar {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2000;
  width: 48rem;
  height: 100vh;
  background-color: #F4F8FA;
  box-shadow: -1px 0 10px 0 rgba(139, 155, 172, 0.36);
  border-radius: 3px;
  overflow-x: hidden;
  overflow-y: hidden;

  .conditions-sidebar-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    height: calc(100vh - 70px);
    margin-bottom: 0!important;
    .conditions-rule {
      padding-bottom: 1rem;
    }
    .spacer {
      margin-top: 48px;
    }
  }

  .conditions-sidebar-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: #0f7fd3;
    padding: 1rem 1.5rem 1rem 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.conditions-rule {
  padding: 0 1rem;
  width: 100%;
  margin-bottom: 1rem;
  &:first-child {
    margin-top: 1rem;
  }

  .rule {
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 1px 0 0 rgba(217, 231, 240, 0.5);
    border: solid 1px #d9e7f0;
    border-radius: 6px;
    transition: all 150ms ease-in-out;
    margin-bottom: 1rem;

    .confirm-header {
      display: none;
    }

    &.confirm {
      border-color: #ec6b6b;

      &:hover {
        border-color: #ec6b6b;
      }

      .confirm-header {
        display: block;
        background-color: #ec6b6b;
        color: white;
        padding: 1rem;
        padding-top: .75rem;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      border: 1px solid #579dd5;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    }

    .header {
      padding: 1rem;
      padding-bottom: 0;
      font-size: .875rem;
      font-weight: 600;
      line-height: 1.25rem;
      color: #1d364b;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .remove {
        cursor: pointer;
        font-size: .5rem;
        color: #acc4d1;
        text-transform: uppercase;
        min-width: 4rem;
      }

      .icon {
        margin-left: .5rem;
      }
    }

    .body-container {
      padding: 1rem;
      padding-top: 0;

      .body {
        padding-top: 1rem;
        text-transform: capitalize;
        color: #8b9bac;
        font-size: .75rem;
        line-height: 1rem;
        padding-bottom: 1rem;

        .title {
          font-weight: 600;
          color: #617080;
          min-width: 8rem;
          width: 18rem;
          margin-right: .5rem;
        }

        .disable-caps {
          text-transform: none;
        }

        .row {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          line-height: 1.25rem;
          margin: 0 0 .25rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}


.confirm-header .actions {
  margin-top: .625rem;
}

.header img {
  width: 1rem;
  height: 1rem;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: .25rem;
}

.icon-cancel-square {
  cursor: pointer;
  color: #afb7c7;
}

.and-separator {
  color: #617080;
  font-size: .75rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;
}

.conditions-rule > div:last-child .and-separator {
  display: none;
}

img.empty {
  width: 15rem;
  margin-bottom: 2rem;
}
</style>
