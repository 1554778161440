<template>
  <div class="field-container">
    <div class="card-condition">
      <el-row>
        <el-col :span="1.5" class="step-col">
          <div class="field-step">{{ this.field.step }}</div>
        </el-col>
        <el-col :span="22">
          <span class="field-title">{{ this.field.name }}</span>
          <div class="text-align-left field-body-text"></div>
          <el-radio v-model="form.fieldType"
                    class="radio-btn field-label-text field-label-text relative"
                    name="relative-check"
                    @change="didChange()"
                    label="any">Any
          </el-radio>
          <el-radio v-model="form.fieldType"
                    class="radio-btn field-label-text field-label-text static"
                    name="static-check"
                    @change="didChange()"
                    label="specific">Specific
          </el-radio>
          <transition name="fade">
            <el-form :model="form" ref="multiSelectForm" v-if="form.fieldType === 'specific'">
              <div class="option-subbody">
                <div class="d-flex mt-10 mb-10">
                  <!-- Second Selection -->
                  <el-form-item class="specific-choice-item" prop="specificChoice">
                    <el-radio v-model="form.exclusionChoice"
                              class="desc-text"
                              @change="didChange()"
                              label="include"
                              border>Any of the following
                    </el-radio>
                    <el-radio v-model="form.exclusionChoice"
                              class="desc-text"
                              @change="didChange()"
                              label="exclude"
                              border>Any except the following
                    </el-radio>
                  </el-form-item>
                </div>
                <el-form-item prop="specificChoice">
                  <el-select @change="didChange()"
                             v-model="form.selectedOptions"
                             class="max-width"
                             :class="form.errorSelect"
                             multiple
                             filterable
                             clearable
                             size="large"
                             value-key="id"
                             placeholder="Select">
                    <el-option v-for="opt in this.options"
                               :key="opt.id"
                               :label="opt.name"
                               :value="opt">
                    </el-option>
                  </el-select>
                  <LoadingBox v-if="this.isLoading"/>
                </el-form-item>
              </div>
            </el-form>
          </transition>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>

import LoadingBox from '../../../../../Global/LoadingBox';
import * as api from '@/api';

export default {
  components: { LoadingBox },
  props: [
    'field',
  ],
  data() {
    return {
      isLoading: true,
      options: [],
      form: {
        selectedOptions: [],
        fieldType: 'any',
        exclusionChoice: 'include',
        errorSelect: 'error',
      },
    };
  },
  created() {
    this.fetchRemoteData();
  },
  methods: {
    async fetchRemoteData() {
      this.isLoading = true;
      try {
        this.options = await api.getMultiselectItems(this.field);
        let d = this.field.default;
        if (Array.isArray(d)) {
          d = { option: 'any', value: d };
        }
        this.form.fieldType = (!d.option || d.option === 'any') ? 'any' : 'specific';
        if (this.form.fieldType !== 'any') {
          this.form.exclusionChoice = d.option || 'include';
          this.form.selectedOptions = d.value || [];
        }
        this.didChange();
      } catch (err) {
        this.$toasted.error(err.message);
      }
      this.isLoading = false;
    },
    didChange() {
      this.field.default = {
        option: this.form.fieldType === 'any' ? 'any' : this.form.exclusionChoice,
        value: this.form.selectedOptions || [],
      };
      this.validate();
    },
    validate() {
      let isValid = true;
      if (this.form.fieldType !== 'any' && this.form.selectedOptions.length === 0) {
        isValid = false;
      }
      this.field.isValid = isValid;
      this.form.errorSelect = isValid ? '' : 'error';
      this.$log('multiSelectField isvalid=', this.field.isValid);
      this.$emit('didValidate');
    },
  },
};
</script>
<style lang="scss" scoped>
@import "uia_fields";

.mt-10 {
  margin-top: 16px;
}

.el-select.error /deep/ {
  input {
    border-color: red
  }
}
</style>
