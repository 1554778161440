<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner">
      Options
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="!any_package" @click="any_package = true"></span>
            <span class="icon icon-radio-checked" v-if="any_package" @click="any_package = true"></span>
          </div>
          <div class="col-xs-10">
            <label @click="any_package = true">
              Any Class Package
            </label>
            <div class="small text-muted">Get contacts who have visits remaining on any Class Package.</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="any_package" @click="any_package = false"></span>
            <span class="icon icon-radio-checked" v-if="!any_package" @click="any_package = false"></span>
          </div>
          <div class="col-xs-10">
            <label @click="any_package = false">
              A specific Class Package
            </label>
            <div class="small text-muted">Get contacts who have visits remaining on specific Class Package(s).</div>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="!any_package">
        <div class="form-group">
          <label>Select Pricing Option(s)</label>
          <div class="multiselect-block">
            <el-select v-model="selected_packages" collapse-tags multiple filterable placeholder="Select Pricing Option(s)" style="display: block;" value-key="name">
              <el-option v-for="item in packages" :key="item.name" :label="item.name" :value="item"></el-option>
            </el-select>
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Visits
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="!any_amount" @click="any_amount = true"></span>
            <span class="icon icon-radio-checked" v-if="any_amount" @click="any_amount = true"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_absolute" @click="any_amount = true">
              Any amount of visits remaining after expiration
            </label>
            <div class="small text-muted">Get contacts who have any amount of visits remaining after expiration on the selected Class Package(s).</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="any_amount" @click="any_amount = false"></span>
            <span class="icon icon-radio-checked" v-if="!any_amount" @click="any_amount = false"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_relative" @click="any_amount = false">
              Specific amount of visits remaining after expiration
            </label>
            <div class="small text-muted">Get contacts who have a specific amount of visits remaining after expiration on the selected Class Package(s).</div>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="!any_amount">
        Any contact who still has&nbsp;
        <el-select v-model="comparison_amount" placeholder="Pick">
          <el-option :key="'more'" :label="'At least'" :value="'more'"></el-option>
          <el-option :key="'less'" :label="'Less than'" :value="'less'"></el-option>
          <el-option :key="'exactly'" :label="'Exactly'" :value="'exactly'"></el-option>
          <el-option :key="'between'" :label="'Between'" :value="'between'"></el-option>
        </el-select>
        &nbsp;
        <input type="number" v-model.number="amount_start" class="form-control" :min="comparison_amount === 'between' ? 1 : 0" style="display: inline-block; width: 5rem;" :disabled="!comparison_amount" /><span v-if="comparison_amount === 'between'"> and <input type="number" v-model.number="amount_end" class="form-control" min="0" style="display: inline-block; width: 5rem;" /></span>&nbsp;visits remaining in the selected package(s).
      </div>
    </form>

    <div class="condition-banner" v-if="!enrollment">
      Timeframe
    </div>

    <form @submit.prevent>
      <div class="form-group" v-if="enrollment">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'entering_campaign'" @click="timeframe = 'entering_campaign'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'entering_campaign'" @click="timeframe = 'entering_campaign'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label @click="timeframe = 'entering_campaign'; comparison = null;">
              Since entering this campaign
            </label>
            <small class="form-text text-muted">See if the contact has met this condition since entering this auto-campaign</small>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'absolute'" @click="timeframe = 'absolute'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'absolute'" @click="timeframe = 'absolute'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_absolute" @click="timeframe = 'absolute'; comparison = null;">
              Around specific dates
            </label>
            <small class="form-text text-muted">Select before, after, or between static dates. e.g. After July 27th 2018</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="timeframe === 'absolute'">
        <div class="form-inline">
          <label>These visits occured</label>&nbsp;
          <el-select v-model="comparison" placeholder="Select a comparison">
            <el-option :key="'after'" :label="'After'" :value="'after'"></el-option>
            <el-option :key="'before'" :label="'Before'" :value="'before'"></el-option>
            <el-option :key="'between'" :label="'Between'" :value="'between'"></el-option>
          </el-select>
        </div>

        <div class="dates" v-if="comparison && timeframe === 'absolute'">
          <div class="date">
            <vue-datepicker-local v-model="dates.start" :local="local" format="MM-DD-YYYY" />
          </div>
          <span v-if="comparison === 'between'">and</span>
          <div class="date" v-if="comparison === 'between'">
            <vue-datepicker-local v-model="dates.end" :local="local" format="MM-DD-YYYY" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'relative'" @click="timeframe = 'relative'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'relative'" @click="timeframe = 'relative'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_relative" @click="timeframe = 'relative'; comparison = null;">
              Relative to Today
            </label>
            <small class="form-text text-muted">Select at least, less than, or exactly a number of days ago. This is a moving window of time. e.g. Within the last 10 days.</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="timeframe === 'relative'">
        <div class="form-inline">
          Every contact in the timeframe&nbsp;
          <el-select v-model="comparison" placeholder="Select a comparison" value-key="value">
            <el-option :key="'more'" :label="'At least'" :value="'more'"></el-option>
            <el-option :key="'within'" :label="'Within the last'" :value="'within'"></el-option>
            <el-option :key="'exactly'" :label="'Exactly'" :value="'exactly'"></el-option>
          </el-select>&nbsp;
          <input type="number" name="relative_days" v-model.number="relative_days" class="form-control" min="0" style="display: inline-block; width: 5rem;" :disabled="!comparison" />&nbsp;
          days<span v-if="comparison !== 'within'">&nbsp;ago</span>.
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'all'" @click="timeframe = 'all'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'all'" @click="timeframe = 'all'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label @click="timeframe = 'all'; comparison = null;">
              All Time
            </label>
            <div class="small text-muted">See if the contact has met this condition in their entire lifetime</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'future'" @click="timeframe = 'future'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'future'" @click="timeframe = 'future'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label @click="timeframe = 'future'; comparison = null;">
              Specific Days in the Future
            </label>
            <div class="small text-muted">See if the contact will meet this condition in the future.</div>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="timeframe === 'future'">
        <div class="form-inline">
          <input type="text" v-model.number="days_future" class="form-control" min="0" style="display: inline-block; width: 5rem;" />&nbsp;
          days in the future
        </div>
      </div>
    </form>

    <div class="form-group">
      <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition">Save this condition</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';
  import VueDatepickerLocal from 'vue-datepicker-local';

  export default {
    props: ['condition', 'editedRule', 'enrollment'],
    components: {
      Multiselect,
      VueDatepickerLocal,
    },
    data() {
      return {
        days_future: null,
        timeframe: 'all',
        relative_days: null,
        comparison: null,
        selected_packages: [],
        packages: [],
        any_package: true,
        any_amount: true,
        comparison_amount: null,
        dates: {
          start: '',
          end: '',
        },
        amount_start: null,
        amount_end: null,
        local: {
          dow: 1,
          hourTip: '',
          minuteTip: '',
          secondTip: '',
          yearSuffix: '',
          monthsHead: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
          months: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
          weeks: 'Mon_Tue_Wed_Thu_Fri_Sat_Sun'.split('_'),
        },
      };
    },
    created() {
      this.fetchPricingOptionPackages();

      if (this.editedRule) {
        this.any_package = this.editedRule.any_package;
        this.any_amount = this.editedRule.any_amount;
        this.selected_packages = this.editedRule.selected_packages;
        this.amount_start = this.editedRule.amount_start;
        this.amount_end = this.editedRule.amount_end;
        this.timeframe = this.editedRule.timeframe;
        this.days_future = this.editedRule.days_future;
        this.comparison = this.editedRule.comparison;
        this.relative_days = this.editedRule.relative_days;
        this.comparison_amount = this.editedRule.comparison_amount;
        this.dates.start = this.editedRule.start_date;
        this.dates.end = this.editedRule.end_date;
      }
    },
    methods: {
      fetchPricingOptionPackages() {
        api.getPricingOptionsPackages().then((response) => {
          this.packages = response.pricing_options;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          any_package: this.any_package,
          selected_packages: this.selected_packages,
          any_amount: this.any_amount,
          comparison_amount: this.comparison_amount,
          comparison: this.comparison,
          amount_start: this.amount_start,
          amount_end: this.amount_end,
          relative_days: this.relative_days,
          timeframe: this.timeframe,
          days_future: this.days_future,
          start_date: this.dates.start,
          end_date: this.dates.end,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }
</style>
