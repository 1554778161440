<template>
  <el-container>
    <el-main>
      <div class="display-small">SMS Keyword Replies</div>
      <el-card v-loading="loading">
        <div class="display-small">Active Keyword Replies</div>
        <el-table :data="keyword_replies">
          <el-table-column label="Keyword" prop="keyword"></el-table-column>
          <el-table-column label="Contact Reply" prop="reply_contact">
            <template slot-scope="scope">
              <el-input v-model="scope.row.reply_contact" v-if="scope.row.edit" />
              <span v-if="!scope.row.edit">{{ scope.row.reply_contact }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Inbound Reply" prop="reply_inbound">
            <template slot-scope="scope">
              <el-input v-model="scope.row.reply_inbound" v-if="scope.row.edit" />
              <span v-if="!scope.row.edit">{{ scope.row.reply_inbound }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Options" align="right" width="250">
            <template slot-scope="scope">
              <el-button type="secondary" class="xpl-button xpl-button--secondary" size="small" @click="$set(scope.row, 'edit', true)" v-if="!scope.row.edit">Edit</el-button>
              <el-button type="danger" size="small" @click="deleteKeyword(scope.row.id)" v-if="!scope.row.edit">Delete</el-button>
              <el-button type="primary" class="xpl-button xpl-button--primary" size="small" @click="editKeyword(scope.row)" v-if="scope.row.edit">Save</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <el-card>
        <div class="display-small">Add new Keyword Reply</div>
        <el-form @submit.native.prevent>
          <el-form-item>
            <label>Keyword</label>
            <el-input v-model.trim="keyword" placeholder="Keyword" />
            <div class="small text-muted">Your keyword has to be a single word and be unique across your account. No spaces or special characters are allowed.</div>
          </el-form-item>
          <el-form-item>
            <label>Auto-Reply for Existing Contact</label>
            <el-input v-model="reply_contact" placeholder="Auto Reply for existing contacts" />
            <div class="small text-muted">This Auto Reply will be sent when we receive an SMS from an existing contact</div>
          </el-form-item>
          <el-form-item>
            <label>Auto-Reply for New Contact</label>
            <el-input v-model="reply_inbound" placeholder="Auto Reply for new contacts" />
            <div class="small text-muted">This Auto Reply will be sent when we receive an SMS from a new contact</div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="addKeyword" :loading="loading" :disabled="!keyword || !reply_contact || !reply_inbound">Save</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-main>
  </el-container>
</template>
<script>
  import { saveKeywordReply, getKeywordReplies, deleteKeywordReply, updateKeywordReply } from '@/api';

  export default {
    data() {
      return {
        loading: false,
        keyword: null,
        reply_contact: null,
        reply_inbound: null,
        keyword_replies: [],
      };
    },
    created() {
      this.getReplies();
    },
    methods: {
      getReplies() {
        this.loading = true;
        getKeywordReplies().then((response) => {
          this.loading = false;
          this.keyword_replies = response.keyword_replies;
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      editKeyword(keyword) {
        const payload = {
          reply_inbound: keyword.reply_inbound,
          reply_contact: keyword.reply_contact,
        };

        updateKeywordReply(keyword.id, payload).then((response) => {
          this.$message.success(response.message);
          this.getReplies();
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      deleteKeyword(keywordReplyId) {
        this.$confirm('Are you sure you want to delete this Keyword Reply?')
        .then(() => {
          this.removeKeyword(keywordReplyId);
        });
      },
      removeKeyword(keywordReplyId) {
        deleteKeywordReply(keywordReplyId).then((response) => {
          this.$message.success(response.message);
          this.getReplies();
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      addKeyword() {
        if (!this.keyword.length ||
          !this.reply_contact.length ||
          !this.reply_inbound.length) {
          this.$message.error('All fields are required');
          return;
        }

        this.loading = true;

        const payload = {
          keyword: this.keyword,
          reply_contact: this.reply_contact,
          reply_inbound: this.reply_inbound,
        };

        saveKeywordReply(payload).then((response) => {
          this.loading = false;
          this.$message.success(response.message);
          this.getReplies();
        }).catch((err) => {
          this.loading = false;
          if (err.errors && Object.keys(err.errors).length) {
            const msg = err.errors[Object.keys(err.errors)[0]];
            this.$message.error(msg[0]);
          } else {
            this.$message.error(err.message);
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-container {
    padding: 0;
    background-color: white;
    height: calc(100vh - 9rem);
  }

  .el-card {
    margin-top: 1rem;
  }
</style>
