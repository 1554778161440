<template>
  <div>
    <h3>All Contacts <span>({{ count | prettyNumber }})</span></h3>
    <div class="sub-text">All contacts</div>
    <DataTable :filter="'all'" :count="count" />
  </div>
</template>
<script>
  import DataTable from './DataTable';

  export default {
    props: ['count'],
    components: {
      DataTable,
    },
    data() {
      return {};
    },
  };
</script>
