<template>
  <div>
    <el-container>
      <el-header>
        <div class="display-small">{{$store.getters.brandName}} LiveStream Manager
          <span class="pill pill-secondary" style="margin-left: .5rem;" v-if="$store.state.user.current_account.active_vendors.indexOf('marianatek') > -1">BETA</span>
        </div>
      </el-header>
      <el-container class="inner">
        <el-aside width="15rem">
          <div @click.stop="switchTab('schedule')" :class="['item', tabs.schedule ? 'active' : '']">
            Class Schedule
          </div>
          <div @click.stop="switchTab('settings')" :class="['item', tabs.settings ? 'active' : '']">
            Set Up
          </div>
          <div @click.stop="switchTab('waiver')" :class="['item', tabs.waiver ? 'active' : '']" v-if="$store.state.user.current_account.active_vendors && $store.state.user.current_account.active_vendors.indexOf('marianatek') > -1 && allowWaivers">
            Re-opening Waiver
          </div>
          <div @click.stop="switchTab('admin')" :class="['item', tabs.admin ? 'active' : '']" v-if="$store.state.user.is_super">
            Admin
          </div>
        </el-aside>
        <el-main>
          <ClassRemindersSchedule v-if="tabs.schedule" />
          <ClassRemindersSettings v-if="tabs.settings" />
          <LiveStreamPlus v-if="tabs.waiver" />
          <ClassRemindersAdmin v-if="tabs.admin" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
  import ClassRemindersSchedule from './ClassRemindersSchedule';
  import ClassRemindersSettings from './ClassRemindersSettings';
  import LiveStreamPlus from './LiveStreamPlus';
  import ClassRemindersAdmin from './ClassRemindersAdmin';

  export default {
    components: {
      ClassRemindersSchedule,
      ClassRemindersSettings,
      LiveStreamPlus,
      ClassRemindersAdmin,
    },
    data() {
      return {
        tabs: {
          schedule: true,
          settings: false,
          waiver: false,
          admin: false,
        },
      };
    },
    created() {
      if (this.$route.query.tab) {
        // eslint-disable-next-line
        for (const tab in this.tabs) {
          // eslint-disable-next-line
          if (this.tabs.hasOwnProperty(tab)) {
            this.tabs[tab] = false;
          }
        }
        this.tabs[this.$route.query.tab] = true;
      }
    },
    computed: {
      allowWaivers() {
        // if ([1459, 1460, 1461, 1462, 1463, 1464, 1465, 1466, 1470, 1471, 1479, 1481, 1483,
        //   1484, 1485, 1486, 1487, 1488, 1507, 1508, 1509, 1510, 1511, 1512, 1513, 1514, 1515,
        //   1533, 1534, 1535, 1536, 1537, 1538, 1539, 1540, 1541, 1565, 1632]
        //   .indexOf(this.$store.state.user.current_account.id) > -1) {
        //   return false;
        // }

        return true;
      },
    },
    methods: {
      switchTab(t) {
        // eslint-disable-next-line
        for (const tab in this.tabs) {
          // eslint-disable-next-line
          if (this.tabs.hasOwnProperty(tab)) {
            this.tabs[tab] = false;
          }
        }

        this.tabs[t] = true;
        this.$router.push({ query: { tab: t } });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-header {
    display: block;
    width: 100%;
    position: fixed;
    z-index: 100;
    border-bottom: 1px solid #d9e7f0;
    padding-left: 1.25rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .el-main {
    margin-top: 3.75rem;
    height: calc(100vh - 6.75rem);
    padding: 1.875rem 3.75rem;
  }

  .el-aside {
    margin-top: 3.75rem;
    height: calc(100vh - 6.75rem);
    padding-top: 2.5rem;
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .el-aside .item {
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: .875rem;
    color: #2e4457;
    transition: all 150ms ease-in-out;
    border-radius: 6px;
    margin-bottom: .375rem;
  }

  .el-aside .item:hover,
  .el-aside .item.active {
    cursor: pointer;
    background-color: var(--xpl-color-secondary-bg-lm);
  }

  .el-aside .item.active {
    font-weight: 700;
  }
</style>
