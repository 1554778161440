<template>
  <div v-if="scheduled_plan">
    <div class="nav-subheader">
      <div class="nav-inner">
        <router-link to="/plans/" class="go-back"><i class="fa fa-chevron-left"></i> All Scheduled Plans</router-link>
        <div class="name">
          <input type="text" autocomplete="off" v-model.trim="scheduled_plan.name" @keyup.enter="editName" />
        </div>
        <div class="actions">
          <div class="dropdown button-separator" v-on-clickaway="away">
            <button type="button" class="xpl-button xpl-button--secondary" @click.stop="options_dropdown = !options_dropdown">Options</button>
            <div :class="['dropdown-menu', 'dropdown-menu-right', options_dropdown ? 'show' : '']">
              <a class="dropdown-item text-danger" href="#" @click.stop="deletePlan">Delete</a>
            </div>
          </div>
          <div class="dropdown add" v-on-clickaway="away">
            <button type="button" class="xpl-button xpl-button--primary" @click.stop="add_dropdown = !add_dropdown">Add To</button>
            <div :class="['dropdown-menu', 'dropdown-menu-right', add_dropdown ? 'show' : '']">
              <a class="dropdown-item" href="#" @click.prevent="scheduled_email = !scheduled_email; scheduledTaskId = null;">
                <span class="material-icons">email</span> Scheduled Email
              </a>
              <a class="dropdown-item" href="#" v-if="false">
                <span class="material-icons">forum</span> Facebook Post
              </a>
              <a class="dropdown-item" href="#" @click.prevent="scheduled_task = !scheduled_task; scheduledTaskId = null;">
                <span class="material-icons">assignment</span> Task
              </a>
              <a class="dropdown-item" href="#" @click.prevent="scheduled_phone_call = !scheduled_phone_call; scheduledTaskId = null;">
                <span class="material-icons">call</span> Phone Call Reminder
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="plan-inner" v-if="!Object.keys(scheduled_plan_items).length">
      <img class="empty" src="https://assets.brandbot.com/public/empty-states/scheduled-plan.svg" alt="Scheduled Plan" />
      <div class="empty">You have no items in this scheduled plan</div>
    </div>

    <div class="plan-inner" v-if="Object.keys(scheduled_plan_items).length">
      <div class="plan-item" v-for="(items, date) in scheduled_plan_items">
        <div class="day">{{ date | humanDate(false) }}</div>
          <div class="card hoverable" v-if="items.length" v-for="item in items" @click="showPlanItem(item)">
            <div :class="['card-body', item.type === 'scheduled_email' ? 'email' : 'task']">
              <div class="status" v-if="item.type === 'scheduled_email'">
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/in-progress.svg" v-if="!item.scheduled" />
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/complete.svg" v-if="item.scheduled && item.sent" />
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/scheduled.svg" v-if="item.scheduled && !item.sent" />
              </div>
              <div class="status" v-if="item.type === 'scheduled_task'">
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/empty.svg" v-if="!item.complete" />
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/complete.svg" v-if="item.complete" />
              </div>
              <div class="details-container">
                <div class="name">
                  <span class="material-icons" v-if="item.type === 'scheduled_email'">email</span>
                  <span class="material-icons" v-if="item.type === 'scheduled_task' && item.phone_call === 0">assignment</span>
                  <span class="material-icons" v-if="item.type === 'scheduled_task' && item.phone_call === 1">phone</span>
                  <h6>{{ item.name }}</h6>
                </div>
                <div class="details" v-if="item.type === 'scheduled_email'">
                  <span class="text-muted" v-if="item.sent">Sent on {{ item.scheduled_at | humanDateTime }}.</span>
                  <span class="text-muted" v-if="item.scheduled && !item.sent">Scheduled for {{ item.scheduled_at | humanDateTime }}.</span>
                  <span class="text-muted" v-if="!item.scheduled && item.email_id">In Progress</span>
                  <span class="text-muted" v-if="!item.email_id">Not Started</span>
                </div>
              </div>
              <ScheduledEmailMetrics v-if="item.type === 'scheduled_email' && item.scheduled && item.sent && item.email_uuid" :emailUuid="item.email_uuid" />
              <div class="associated" v-if="item.type === 'scheduled_task'">
                <span v-if="item.associated_contact_first_name">{{ item.associated_contact_first_name }} {{ item.associated_contact_last_name }}</span>
                <span v-if="!item.associated_contact_first_name">{{ item.associated_contact_email }}</span>
              </div>
              <div class="assignee">
                <avatar :username="item.assigned_user_first_name ? ' ' + item.assigned_user_first_name + ' ' + item.assigned_user_last_name : item.assigned_user_email" color="#fff" :rounded="true" :size="24"  v-if="item.assigned_user_id"></avatar>
              </div>
            </div>
          </div>
      </div>
    </div>
    <ScheduledEmail v-if="scheduled_email" :scheduledPlanId="scheduled_plan.id" />
    <TasksSidebar v-if="scheduled_task" :scheduledPlanId="scheduled_plan.id" :scheduledTaskId="scheduledTaskId" />
    <PhoneCallsSidebar v-if="scheduled_phone_call" :scheduledPlanId="scheduled_plan.id" :scheduledTaskId="scheduledTaskId" />
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import { directive as onClickaway } from 'vue-clickaway';
  import Avatar from 'vue-avatar';
  import TasksSidebar from '@/components/Tasks/TasksSidebar';
  import PhoneCallsSidebar from '@/components/Tasks/PhoneCallsSidebar';
  import ScheduledEmail from './ScheduledEmail';

  export default {
    props: ['uuid'],
    components: {
      ScheduledEmail,
      TasksSidebar,
      PhoneCallsSidebar,
      Avatar,
    },
    directives: {
      onClickaway,
    },
    data() {
      return {
        options_dropdown: false,
        add_dropdown: false,
        scheduled_plan: null,
        scheduled_plan_items: [],
        scheduled_email: false,
        scheduled_task: false,
        scheduled_phone_call: false,
        scheduledTaskId: null,
      };
    },
    beforeRouteEnter(to, from, next) {
      api.getScheduledPlan(to.params.uuid).then((response) => {
        next((vm) => {
          vm.scheduled_plan = response.scheduled_plan;
          vm.scheduled_plan_items = response.scheduled_plan_items;
        });
      }).catch((err) => {
        next((vm) => {
          vm.$toasted.error(err.message);
        });
      });
    },
    mounted() {
      EventBus.$on('scheduled_modal.close', () => {
        this.scheduled_email = false;
      });

      EventBus.$on('calendar.task_sidebar.close', (refresh) => {
        this.scheduled_task = false;
        this.scheduled_phone_call = false;

        if (refresh) {
          this.fetchScheduledPlan();
        }
      });
    },
    beforeDestroy() {
      EventBus.$off('scheduled_modal.close');
      EventBus.$off('calendar.task_sidebar.close');
    },
    methods: {
      editName() {
        const payload = {
          name: this.scheduled_plan.name,
        };

        api.updateScheduledPlan(this.scheduled_plan.id, payload).then((response) => {
          this.$toasted.success(response.message);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      async deletePlan() {
        const options = {
          reverse: true,
          type: 'hard',
          okText: 'Delete',
          verification: 'delete',
          animation: 'bounce',
        };
        try {
          await this.$dialog.confirm('Are you sure you want to delete this Scheduled Plan and its associated items?', options);
        } catch (err) {
          return;
        }
        try {
          const response = await api.deleteScheduledPlan(this.scheduled_plan.id);
          this.$toasted.success(response.message);
          this.$router.push('/plans');
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      showPlanItem(item) {
        if (item.type === 'scheduled_email') {
          this.$router.push(`/scheduled-emails/${item.uuid}`);
        }

        if (item.type === 'scheduled_task') {
          if (item.phone_call) {
            this.scheduled_phone_call = true;
          } else {
            this.scheduled_task = true;
          }
          this.scheduledTaskId = item.id;
        }
      },
      away() {
        this.options_dropdown = false;
        this.add_dropdown = false;
      },
      fetchScheduledPlan() {
        api.getScheduledPlan(this.scheduled_plan.uuid).then((response) => {
          this.scheduled_plan = response.scheduled_plan;
          this.scheduled_plan_items = response.scheduled_plan_items;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .nav-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav-inner .name {
    display: flex;
    align-items: center;
    width: 50%;
  }

  .nav-inner .name input {
    outline: 0;
    border: none;
    width: 100%;
    text-align: center;
  }

  .nav-inner .actions {
    display: flex;
  }

  .nav-inner .actions .btn {
    margin-right: 1rem;
  }

  .nav-inner .actions .dropdown.add .dropdown-menu.show {
    min-width: 15rem;
    left: calc(-100% - 5rem);
  }

  .nav-inner .actions .dropdown.add .btn {
    margin-right: 0;
  }

  .nav-inner .dropdown.add .dropdown-item {
    display: flex;
    align-items: center;
    transition: none;
  }

  .nav-inner .dropdown.add .dropdown-item:hover {
    background-color: white;
    color: white;
    background: linear-gradient(45deg, #3ab8ff 0%, #00cfff 100%);
  }

  .nav-inner .dropdown.add .dropdown-item .material-icons {
    color: #cdd7e2;
    font-size: 1.5rem;
    margin-right: 1rem;
  }

  .nav-inner .dropdown.add .dropdown-item:hover .material-icons {
    color: white;
  }

  .plan-inner {
    max-width: 73.75rem;
    margin: auto;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .plan-inner img.empty {
    width: 15rem;
    margin: auto;
    display: block;
  }

  .plan-inner div.empty {
    text-align: center;
    margin-top: 1.5rem;
    font-size: 0.875rem;
    font-weight: 700;
    color: rgb(97, 112, 128);
  }

  .plan-inner .plan-item .day {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1rem;
    color: #697c86;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }

  .plan-inner .plan-item:first-child .day {
    margin-top: 0;
  }

  .plan-item .card {
    cursor: pointer;
    margin-bottom: .25rem;
  }

  .plan-item .card.empty {
    cursor: initial;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    background-color: #edf2f4;
    border: solid 1px var(--xpl-color-gray-400);
    box-shadow: none;
    font-size: .75rem;
    line-height: 1.25rem;
    color: #617080;
  }

  .plan-item .card .card-body {
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .plan-item .card .card-body.task {
    padding-top: .5rem;
    padding-bottom: .5rem;
    align-items: center;
  }

  .plan-item .card .card-body h6 {
    margin-bottom: 0;
  }

  .plan-item .card .card-body .status,
  .plan-item .card .card-body .status img {
    width: 1.5rem;
    height: 1.5rem;
    display: block;
  }

  .plan-item .card .card-body .associated {
    flex-shrink: 0;
    flex-grow: 0;
    min-width: 10rem;
    width: 10rem;
    text-align: left;
    margin-left: auto;
    font-size: .875rem;
    color: rgb(98, 98, 98);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .plan-item .card .card-body .assignee {
    width: 1.5rem;
    margin-left: auto;
  }

  .plan-item .card .card-body .associated + .assignee {
    margin-left: 5rem;
  }

  .plan-item .card .card-body .details-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .plan-item .card .card-body .details-container .name {
    display: flex;
  }

  .plan-item .card .card-body .details-container .name .material-icons,
  .plan-item .card .card-body .details-container .name h6 {
    line-height: 1rem;
  }

  .plan-item .card .card-body .details-container .name .material-icons {
    margin-left: .75rem;
    margin-right: .5rem;
    font-size: 1.25rem;
    color: var(--xpl-color-gray-400);
  }

  .plan-item .card .card-body .details-container .name h6 {
    color: rgb(76, 79, 82);
  }

  .plan-item .card .card-body .details-container .details {
    margin-top: .25rem;
    margin-left: .875rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: rgb(132, 143, 153);
    font-size: .75rem;
  }

  .plan-item .card .card-body .details .material-icons {
    margin-right: .5rem;
    font-size: .75rem;
  }

  .metrics {
    margin-left: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: .75rem;
    line-height: 1.25;
    color: #5c6063;
    text-align: right;
  }

  .metrics .number {
    text-align: right;
    font-size: 1rem;
    line-height: 1.5;
    color: rgb(76, 79, 82);
    display: block;
    margin-bottom: .25rem;
  }

  .metrics .sent {
    margin-right: 1.25rem;
  }

  .metrics .opens {
    margin-right: 1.25rem;
  }
</style>
