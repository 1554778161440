<template>
  <transition name="slidy">
    <div class="activity-sidebar">
      <div class="activity-sidebar-header">
        <a href="#" class="go-back" @click.prevent="closeSidebar"><i class="fa fa-chevron-left"></i> Close</a>
        Campaign Activity
        <div class="small text-muted">{{ campaign.name }}</div>
        <router-link :to="'/contacts/' + contact.uuid" class="contact" target="_blank">
          {{ contact.first_name ? contact.first_name + ' ' + contact.last_name : contact.email }}
        </router-link>
        <div class="pill pill-primary">{{ contact.unsubscribed_at ? 'Unsubscribed' : 'Subscribed' }} to Email</div>
      </div>
      <div class="activity-sidebar-body">
        <div class="events">
          <div :class="['event', event.type === 'task' || event.type === 'phone_call' ? 'hoverable' : '']" v-for="event in activityEvents" @click="showTask(event)">
            <div class="header">
              <img src="https://assets.brandbot.com/public/icons/timeline/enter-campaign.svg" v-if="event.event_metadata.status === 'enrollment'" />
              <span class="material-icons" style="color: black;" v-if="event.event_metadata.status === 'exit'">exit_to_app</span>
              <span class="material-icons" style="color: black;" v-if="event.event_metadata.status === 'hit-goal'">exit_to_app</span>
              <img src="https://assets.brandbot.com/public/icons/scheduled-items/empty.svg" v-if="(event.type === 'task' || event.type === 'phone_call') && event.task_complete === 0" />
              <img src="https://assets.brandbot.com/public/icons/scheduled-items/complete.svg" v-if="(event.type === 'task' || event.type === 'phone_call') && event.task_complete" />
              <div>
                <div class="name">
                  <span v-if="event.type === 'task'" class="material-icons">assignment</span>
                  <span v-if="event.type === 'phone_call'" class="material-icons">phone</span>
                  <span v-if="event.type === 'email'" class="material-icons">email</span>
                  <span v-if="event.event_metadata.status === 'enrollment'">{{ event.event_metadata.method === 'manual' ? 'Manually entered' : 'Entered' }} auto-campaign as {{ event.event_metadata.unsubscribed_at ? 'unsubscribed' : 'subscribed' }}.</span>
                  <span v-if="event.type">{{ event.campaign_item_name }}</span>
                  <span v-if="event.event_metadata.status === 'exit'">
                    Exited the campaign through the exit rule
                  </span>
                  <span v-if="event.event_metadata.status === 'hit-goal'">
                    Hit a goal ({{ event.event_metadata.goal_name }})
                  </span>
                </div>
                <div class="small text-muted">
                  <span v-if="event.event_metadata.status === 'sent'">
                    Sent on {{ event.event_created_at | humanDateTime }}
                  </span>
                  <span v-if="event.event_metadata.status === 'enrollment'">
                    {{ event.event_metadata.enrolled_at | humanDateTime(false) }}
                  </span>
                  <span v-if="event.type === 'email' && event.event_metadata.status === 'scheduled'">
                    Scheduled for {{ event.event_metadata.scheduled_at | humanDate }}
                  </span>
                  <span v-if="(event.type === 'task' || event.type === 'phone_call') && event.event_metadata.status === 'created'">
                    Task created for {{ event.task_scheduled_at | humanDate(false) }}
                  </span>
                  <span v-if="event.event_metadata.status === 'exit'">
                    {{ event.event_metadata.exited_at | humanDateTime }}
                  </span>
                  <span v-if="event.event_metadata.status === 'hit-goal'">
                    {{ event.event_created_at | humanDateTime }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="title" v-if="waitingOnEvents.length">Waiting to see if conditions are met</div>
          <div class="event" v-for="event in waitingOnEvents">
            <div class="header">
              <div>
                <div class="name">
                  <span v-if="event.type === 'task'" class="material-icons">assignment</span>
                  <span v-if="event.type === 'phone_call'" class="material-icons">phone</span>
                  <span v-if="event.type === 'email'" class="material-icons">email</span>
                  <span v-if="event.type">{{ event.campaign_item_name }}</span>
                </div>
                <div class="small text-muted">
                  <span v-if="event.event_metadata.status === 'waiting-both'">
                    Waiting to see if contact meets conditions after delay
                  </span>
                  <span v-if="event.event_metadata.status === 'waiting-behavior'">
                    Waiting to see if contact meets conditions
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="title" v-if="didNotSendEvents.length">Did not send</div>
          <div class="event" v-for="event in didNotSendEvents">
            <div class="header">
              <div>
                <div class="name">
                  <span v-if="event.type === 'task'" class="material-icons">assignment</span>
                  <span v-if="event.type === 'phone_call'" class="material-icons">phone</span>
                  <span v-if="event.type === 'email'" class="material-icons">email</span>
                  <span v-if="event.type">{{ event.campaign_item_name }}</span>
                </div>
                <div class="small text-muted">
                  <span v-if="event.event_metadata.status === 'not-sent-unsub'">
                    Did not sent because contact was unsubscribed at time of sending
                  </span>
                  <span v-if="event.event_metadata.status === 'not-sent-hit-goal'">
                    Did not sent because the contact exited the campaign after hitting a goal
                  </span>
                  <span v-if="event.event_metadata.status === 'too-late'">
                    Did not meet conditions after delay
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="title" v-if="notScheduledUnsub.length">Not elligible because Contact is unsubscribed</div>
          <div class="event" v-for="event in notScheduledUnsub">
            <div class="header">
              <div>
                <div class="name">
                  <span v-if="event.type === 'task'" class="material-icons">assignment</span>
                  <span v-if="event.type === 'phone_call'" class="material-icons">phone</span>
                  <span v-if="event.type === 'email'" class="material-icons">email</span>
                  <span v-if="event.type">{{ event.campaign_item_name }}</span>
                </div>
                <div class="small text-muted">
                  <span v-if="event.event_metadata.status === 'not-scheduled-unsub'">
                    Did not schedule because contact was unsubscribed
                  </span>
                  <span v-if="event.event_metadata.status === 'not-waiting-behavior-unsub'">
                    Did not schedule because contact was unsubscribed
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TasksSidebar v-if="show_tasks_sidebar" :scheduledTaskId="scheduledTaskId" />
      <PhoneCallsSidebar v-if="show_phone_calls_sidebar" :scheduledTaskId="scheduledTaskId" />
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import TasksSidebar from '@/components/Tasks/TasksSidebar';
  import PhoneCallsSidebar from '@/components/Tasks/PhoneCallsSidebar';

  export default {
    props: ['campaign', 'contact'],
    components: {
      TasksSidebar,
      PhoneCallsSidebar,
    },
    data() {
      return {
        show_tasks_sidebar: false,
        show_phone_calls_sidebar: false,
        scheduledTaskId: null,
        events: [],
        waiting: [],
        exited: [],
      };
    },
    mounted() {
      EventBus.$on('calendar.task_sidebar.close', (refresh) => {
        this.show_tasks_sidebar = false;
        this.show_phone_calls_sidebar = false;

        if (refresh) {
          this.fetchEvents();
        }
      });
    },
    created() {
      document.body.classList.add('stop-scroll');
      this.fetchEvents();
    },
    beforeDestroy() {
      document.body.classList.remove('stop-scroll');
      EventBus.$off('calendar.task_sidebar.close');
    },
    computed: {
      activityEvents() {
        if (!this.events.length) {
          return [];
        }

        return this.events.filter(e =>
          e.event_metadata.status !== 'hit-goal' &&
          e.event_metadata.status !== 'waiting-behavior' &&
          e.event_metadata.status !== 'waiting-both' &&
          e.event_metadata.status !== 'not-sent-unsub' &&
          e.event_metadata.status !== 'not-scheduled-unsub' &&
          e.event_metadata.status !== 'not-waiting-behavior-unsub' &&
          e.event_metadata.status !== 'too-late',
        );
      },
      waitingOnEvents() {
        if (!this.events.length) {
          return [];
        }

        return this.events.filter(e =>
          e.event_metadata.status === 'waiting-both' ||
          e.event_metadata.status === 'waiting-behavior',
        );
      },
      didNotSendEvents() {
        if (!this.events.length) {
          return [];
        }

        return this.events.filter(e =>
          e.event_metadata.status === 'not-sent-unsub' ||
          e.event_metadata.status === 'not-sent-hit-goal' ||
          e.event_metadata.status === 'too-late',
        );
      },
      notScheduledUnsub() {
        if (!this.events.length) {
          return [];
        }

        return this.events.filter(e =>
          e.event_metadata.status === 'not-scheduled-unsub' ||
          e.event_metadata.status === 'not-waiting-behavior-unsub',
        );
      },
    },
    methods: {
      closeSidebar() {
        EventBus.$emit('contact_activity.sidebar.close');
      },
      fetchEvents() {
        api.contactCampaignEvents(this.contact.id, this.campaign.id).then((response) => {
          this.events = response.events;
          // this.waiting = response.waiting;
          // this.exited = response.exited;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      showTask(eventData) {
        if (eventData.type === 'task' && eventData.event_metadata.status === 'created') {
          this.show_tasks_sidebar = true;
        }

        if (eventData.type === 'phone_call' && eventData.event_metadata.status === 'created') {
          this.show_phone_calls_sidebar = true;
        }

        this.scheduledTaskId = eventData.scheduled_task_id;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .activity-sidebar {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;
    width: 33.625rem;
    height: 100vh;
    background-color: var(--xpl-color-gray-400);
    // box-shadow: -1px 0 10px 0 rgba(139, 155, 172, 0.36);
    border-radius: 6px;
    overflow-x: scroll;
  }

  .activity-sidebar-header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background-color: #fff;
    padding-top: 1rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    padding-bottom: 1.5rem;
    color: rgb(29, 54, 75);
    line-height: 1.5;
  }

  .activity-sidebar-header .go-back {
    margin-bottom: .75rem;
  }

  .activity-sidebar-header .contact {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .activity-sidebar-body {
    padding: 1rem;
    padding-top: 0;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .events {
    margin-top: 1rem;
    width: 100%;
  }

  .events .title {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-size: .875rem;
    font-weight: 700;
    color: rgb(29, 54, 75);
  }

  .events .event {
    width: 100%;
    padding: 1rem;
    padding-left: .875rem;
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    // box-shadow: 0 1px 0 0 rgba(217, 231, 240, 0.5);
    border: solid 1px rgb(217, 231, 240);
    margin-bottom: .75rem;
  }

  .events .event.hoverable {
    cursor: pointer;
  }

  .events .event:last-child {
    margin-bottom: 0;
  }

  .events .event .header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .events .event .header .material-icons,
  .events .event .header img {
    color: black;
    font-size: 1.5rem;
    margin-right: .5rem;
    width: 1.5rem;
    height: 1.5rem;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .events .event .header .name {
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.5;
    color: rgb(29, 54, 75);
    margin-bottom: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .events .event .header .name .material-icons {
    color: rgb(217, 231, 240);
    font-size: 1rem;
    margin-right: .5rem;
    line-height: 1.25;
  }
</style>
