<template>
  <div class="sidebar-inner" style="width: 100%;">
    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">1</span>
      </el-col>
      <el-col :span="22" class="v2__heading">Do you want to find contacts who have or do not have an invoice with the following filters?</el-col>
    </el-row>

    <el-row style="margin-bottom: 2rem;">
      <el-col :offset="2">
        <el-radio-group v-model="has_invoice">
          <el-radio :label="true" border>Contacts who have</el-radio>
          <el-radio :label="false" border>Contacts who have <b>not</b></el-radio>
        </el-radio-group>
      </el-col>
    </el-row>

    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">2</span>
      </el-col>
      <el-col :span="22" class="v2__heading">What invoice status would you like to filter on?</el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row>
        <el-col :offset="2" :span="22">
            <el-form>
              <el-form-item>
                <el-select v-model="selected_statuses" filterable multiple placeholder="Statuses" style="width: 100%;">
                  <el-option v-for="status in statuses" :value="status.value" :label="status.label" :key="status.value"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
        </el-col>
      </el-row>
    </div>

    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">3</span>
      </el-col>
      <el-col :span="22" class="v2__heading">What is the invoice due date?</el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2" :span="22">
          <el-radio-group v-model="timeframe">
            <el-radio label="all" border>All Time</el-radio>
            <el-radio label="absolute" border>Around static date(s)</el-radio>
            <el-radio label="relative" border>A relative time frame</el-radio>
            <el-radio label="entering_campaign" border v-if="goalList">Since entering campaign</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row v-if="timeframe === 'absolute'">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-radio-group v-model="time_comparison">
              <el-radio-button label="before">Before</el-radio-button>
              <el-radio-button label="between">Between</el-radio-button>
              <el-radio-button label="after">After</el-radio-button>
            </el-radio-group>

            <div class="block" v-if="time_comparison && time_comparison !== 'between'">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="dates"
                type="date"
                placeholder="Pick a date">
              </el-date-picker>
            </div>

            <div class="block" v-if="time_comparison && time_comparison === 'between'">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="dates"
                type="daterange"
                range-separator="-"
                start-placeholder="Start"
                end-placeholder="End">
              </el-date-picker>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row v-if="timeframe === 'relative'">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-radio-group v-model="time_comparison">
              <el-radio-button label="more">At least</el-radio-button>
              <el-radio-button label="within">Within the last</el-radio-button>
              <el-radio-button label="exactly">Exactly</el-radio-button>
            </el-radio-group>
            <div class="block" style="display: flex; align-items: center;">
              <el-input-number :min="0" v-model="relative_days"></el-input-number>
              &nbsp;
              days<span v-if="time_comparison && time_comparison !== 'within'">&nbsp;ago</span>.
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">4</span>
      </el-col>
      <el-col :span="22" class="v2__heading">Filter on invoices with discounts?</el-col>
    </el-row>

    <el-row style="margin-bottom: 2rem;">
      <el-col :offset="2">
        <el-radio-group v-model="has_discounts">
          <el-radio :label="true" border>Yes</el-radio>
          <el-radio :label="false" border>No</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>

    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">5</span>
      </el-col>
      <el-col :span="22" class="v2__heading">Filter on invoices with adjustments?</el-col>
    </el-row>

    <el-row style="margin-bottom: 2rem;">
      <el-col :offset="2">
        <el-radio-group v-model="has_adjustments">
          <el-radio :label="true" border>Yes</el-radio>
          <el-radio :label="false" border>No</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>

    <el-form>
      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="commitCondition">Save this condition</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    props: ['condition', 'editedRule'],
    data() {
      return {
        selected_statuses: [],
        statuses: [{
          label: 'Closed',
          value: 'closed',
        }, {
          label: 'Open',
          value: 'open',
        }, {
          label: 'Canceled',
          value: 'canceled',
        }, {
          label: 'On Hold',
          value: 'on_hold',
        }, {
          label: 'Purchase Request',
          value: 'purchase_request',
        }],
        timeframe: 'all',
        time_comparison: null,
        dates: null,
        relative_days: null,
        has_discounts: false,
        has_adjustments: false,
        has_invoice: false,
      };
    },
    created() {
      if (this.editedRule) {
        this.timeframe = this.editedRule.timeframe;
        this.time_comparison = this.editedRule.time_comparison;
        this.relative_days = this.editedRule.relative_days;

        if (this.editedRule.end_date) {
          this.dates = [this.editedRule.start_date, this.editedRule.end_date];
        } else {
          this.dates = this.editedRule.start_date;
        }

        this.selected_statuses = this.editedRule.statuses;
        this.has_adjustments = this.editedRule.has_adjustments;
        this.has_discounts = this.editedRule.has_discounts;
        this.has_invoice = this.editedRule.has_invoice;
      }
    },
    methods: {
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          timeframe: this.timeframe,
          time_comparison: this.time_comparison,
          start_date: Array.isArray(this.dates) ? this.dates[0] : this.dates,
          end_date: Array.isArray(this.dates) ? this.dates[1] : null,
          relative_days: this.relative_days,
          statuses: this.selected_statuses,
          has_discounts: this.has_discounts,
          has_adjustments: this.has_adjustments,
          has_invoice: this.has_invoice,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
 .options {
    padding: 1.25rem;
    background-color: #f5f8fb;
  }
</style>
