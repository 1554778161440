<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner">
      Details
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="last_visit_type === null || last_visit_type !== 'visit'" @click="last_visit_type = 'visit'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="last_visit_type === 'visit'" @click="last_visit_type = 'visit'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label @click="last_visit_type = 'visit'; comparison = null;">Last Visit</label>
            <div class="small text-muted">Get contacts based on their last visit</div>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="last_visit_type === 'visit'">
        <div class="form-inline">
          Any contact whose last visit was&nbsp;
          <Multiselect v-model="comparison" placeholder="Select a comparison" :options="comparisons_number" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" style="min-width: 13rem; width: 13rem; max-width: 13rem;" />&nbsp;
          <input type="number" name="relative_days" v-model.number="relative_days" class="form-control" min="0" style="display: inline-block; width: 5rem;" :disabled="!comparison" />&nbsp;days<span v-if="comparison && comparison.value !== 'within'">&nbsp;ago</span>.
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="last_visit_type === null || last_visit_type !== 'both'" @click="last_visit_type = 'both'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="last_visit_type === 'both'" @click="last_visit_type = 'both'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label @click="last_visit_type = 'both'; comparison = null;">Last Visit and Last Purchase</label>
            <div class="small text-muted">Get contacts based on their last visit or last purchase</div>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="last_visit_type === 'both'">
        <div class="form-inline">
          Any contact whose last visit and last purchase was&nbsp;
          <Multiselect v-model="comparison" placeholder="Select a comparison" :options="comparisons_number" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" style="min-width: 13rem; width: 13rem; max-width: 13rem;" />&nbsp;
          <input type="number" name="relative_days" v-model.number="relative_days" class="form-control" min="0" style="display: inline-block; width: 5rem;" :disabled="!comparison" />&nbsp;days<span v-if="comparison && comparison.value !== 'within'">&nbsp;ago</span>.
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Filters
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-checkbox-unchecked2" v-if="!filter_pricing_options" @click="filter_pricing_options = !filter_pricing_options"></span>
            <span class="icon icon-checkbox-checked2" v-if="filter_pricing_options" @click="filter_pricing_options = !filter_pricing_options"></span>
          </div>
          <div class="col-xs-10">
            <label @click="filter_pricing_options = !filter_pricing_options">
              Last Visit with specific Pricing Options
            </label>
            <small class="form-text text-muted">Isolates the last time a client visited with this specific type of purchase.</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="filter_pricing_options">
        <div class="form-group">
          <label for="pricing_options">Only include last visits with these pricing option(s)</label>
          <div class="multiselect-block">
            <Multiselect v-model="selected_pricing_options" placeholder="Select Pricing Option(s)" :options="pricing_options" label="name" track-by="id" :searchable="true" :allow-empty="true" :show-labels="false" :multiple="true" :close-on-select="false" />
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Location and Site ID (Optional)
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="misc_filtering === null || misc_filtering === true" @click="misc_filtering = false"></span>
            <span class="icon icon-radio-checked" v-if="misc_filtering === false" @click="misc_filtering = false"></span>
          </div>
          <div class="col-xs-10">
            <label for="misc_filtering_no" @click="misc_filtering = false">
              Select all locations and Site IDs
            </label>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="misc_filtering === null || misc_filtering === false" @click="misc_filtering = true"></span>
            <span class="icon icon-radio-checked" v-if="misc_filtering === true" @click="misc_filtering = true"></span>
          </div>
          <div class="col-xs-10">
            <label for="misc_filtering_yes" @click="misc_filtering = true">
               Select specific locations or Site IDs
            </label>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="misc_filtering">
        <div class="form-group">
          <label class="form-control-label">Location</label>
          <div class="multiselect-block">
            <Multiselect v-model="location" placeholder="All Locations" label="location_name" track-by="location_id" :options="locations" :searchable="false" :allow-empty="true" :show-labels="false" />
          </div>
        </div>
      </div>
    </form>

    <div class="form-group">
      <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition" :disabled="!last_visit_type">Save this condition</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['locations', 'condition', 'editedRule', 'enrollment'],
    components: {
      Multiselect,
    },
    data() {
      return {
        last_visit_type: null,
        misc_filtering: false,
        filter_pricing_options: false,
        selected_pricing_options: [],
        pricing_options: [],
        location: null,
        relative_days: 0,
        comparison: null,
        comparisons_number: [{
          label: 'At least',
          value: 'more',
        }, {
          label: 'Within the last',
          value: 'within',
        }, {
          label: 'Exactly',
          value: 'exactly',
        }],
      };
    },
    created() {
      this.fetchPricingOptions();

      if (this.editedRule) {
        this.comparison = this.comparisons_number.filter(c =>
          c.value === this.editedRule.comparison,
        )[0];
        this.relative_days = this.editedRule.relative_days;
        this.last_visit_type = this.editedRule.last_visit_type;
        if (this.editedRule.filter_pricing_options) {
          this.filter_pricing_options = this.editedRule.filter_pricing_options;
          this.selected_pricing_options = this.editedRule.pricing_options;
        }

        this.misc_filtering = this.editedRule.misc.filtering;
        if (this.misc_filtering) {
          this.location = this.locations.filter(l =>
            l.location_id === this.editedRule.misc.location,
          )[0];
        }
      }
    },
    methods: {
      fetchPricingOptions() {
        api.getPricingOptions().then((response) => {
          this.pricing_options = response.pricing_options;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          filter_pricing_options: this.filter_pricing_options,
          pricing_options: this.selected_pricing_options,
          last_visit_type: this.last_visit_type,
          relative_days: this.relative_days,
          comparison: this.comparison ? this.comparison.value : null,
          misc: {
            filtering: this.misc_filtering,
            location: this.location ? this.location.location_id : null,
            site_id: null,
          },
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked,
  .icon-checkbox-unchecked2,
  .icon-checkbox-checked2 {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }
</style>

