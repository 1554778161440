<template>
  <div class="logo">
    <div class="sidebar">
      <form @submit.prevent v-if="metadata">
        <div class="form-group">
          <label>Primary Color</label>
          <div class="input-group" @click="show_primary_color_picker = !show_primary_color_picker">
            <span class="input-group-addon">
              <span class="icon icon-square" :style="'color: ' + primary_color.hex"></span>
            </span>
            <input type="text" ref="primary_color" v-model="primary_color.hex" class="form-control" readonly />
          </div>
          <Sketch v-model="primary_color" :disableAlpha="true" @input="attributeEdited" v-if="show_primary_color_picker" />
        </div>

        <div class="form-group">
          <label>Secondary Color</label>
          <div class="input-group" @click="show_secondary_color_picker = !show_secondary_color_picker">
            <span class="input-group-addon">
              <span class="icon icon-square" :style="'color: ' + secondary_color.hex"></span>
            </span>
            <input type="text" ref="secondary_color" v-model="secondary_color.hex" class="form-control" readonly />
          </div>
          <Sketch v-model="secondary_color" :disableAlpha="true" @input="attributeEdited" v-if="show_secondary_color_picker" />
        </div>

        <div class="form-group">
          <label>Email Background</label>
          <div class="input-group" @click="show_email_background_color_picker = !show_email_background_color_picker">
            <span class="input-group-addon">
              <span class="icon icon-square" :style="'color: ' + email_background_color.hex"></span>
            </span>
            <input type="text" ref="email_background_color" v-model="email_background_color.hex" class="form-control" readonly />
          </div>
          <Sketch v-model="email_background_color" :disableAlpha="true" @input="attributeEdited" v-if="show_email_background_color_picker" />
        </div>

        <div class="form-group">
          <label>Email Page Color</label>
          <div class="input-group" @click="show_page_color_picker = !show_page_color_picker">
            <span class="input-group-addon">
              <span class="icon icon-square" :style="'color: ' + page_color.hex"></span>
            </span>
            <input type="text" ref="page_color" v-model="page_color.hex" class="form-control" readonly />
          </div>
          <Sketch v-model="page_color" :disableAlpha="true" @input="attributeEdited" v-if="show_page_color_picker" />
        </div>

        <button class="xpl-button xpl-button--primary" @click.stop="nextStep">Next</button>
      </form>
    </div>
    <div class="preview">
      <div class="alert">
        <div class="illustration">
          <span class="material-icons">check_circle</span>
        </div>
        <div>
          <h1>This is a preview of your selections.</h1>
          <p>You are setting the defaults for this account. These defaults can be changed at any time. Also every element in every email can be styled specifically for that email.</p>
        </div>
      </div>

      <div class="branding_preview" v-html="preview_html">
      </div>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import _ from 'lodash';
  import { Sketch } from 'vue-color';

  export default {
    components: {
      Sketch,
    },
    data() {
      return {
        preview_html: null,
        show_primary_color_picker: false,
        show_secondary_color_picker: false,
        show_email_background_color_picker: false,
        show_page_color_picker: false,
        primary_color: {
          hex: null,
        },
        secondary_color: {
          hex: null,
        },
        email_background_color: {
          hex: null,
        },
        page_color: {
          hex: null,
        },
        metadata: null,
      };
    },
    created() {
      this.fetchBrandingPreview();
    },
    methods: {
      nextStep() {
        EventBus.$emit('brand_builder.move_to', 'fonts_header');
      },
      attributeEdited: _.debounce(function customFunction() {
        const payload = {
          primary_color: this.primary_color.hex,
          secondary_color: this.secondary_color.hex,
          email_background_color: this.email_background_color.hex,
          page_color: this.page_color.hex,
        };

        api.saveBrandingMetadata(payload).then(() => {
          this.fetchBrandingPreview();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      }, 300,
      ),
      fetchBrandingPreview() {
        api.getBrandingPreview().then((response) => {
          this.preview_html = response.preview_html;
          this.metadata = response.metadata;

          this.$nextTick(() => {
            this.primary_color.hex = this.metadata.primary_color;
            this.secondary_color.hex = this.metadata.secondary_color;
            this.email_background_color.hex = this.metadata.email_background_color;
            this.page_color.hex = this.metadata.page_color;
          });
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .vc-sketch {
    position: absolute;
    z-index: 100;
  }

  .logo {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100vw;
    height: calc(100vh - 3.5rem);
    padding-left: 20rem;
    background-color: rgb(243, 247, 249);
  }

  .sidebar {
    position: fixed;
    left: 0;
    min-width: 20rem;
    width: 20rem;
    background-color: white;
    padding: 1rem;
    height: calc(100% - 3.5rem);
  }

  .sidebar form {
    width: 100%;
    height: calc(100% - 3.5rem);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .sidebar form .form-group {
    width: 100%;
  }

  .sidebar form button {
    margin-top: auto;
  }

  .preview {
    flex: 75%;
    padding: 1rem;
    background-color: rgb(243, 247, 249);
    height: 100%;
  }

  .preview .alert {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 0;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: rgb(255, 255, 255);
  }

  .preview .alert h1 {
    font-size: .875rem;
    line-height: 1.25rem;
    color: rgb(97, 112, 128);
    margin-bottom: 0;
  }

  .preview .alert p {
    font-size: .75rem;
    line-height: 1rem;
    color: rgb(29, 54, 75);
    margin: 0;
  }

  .preview .alert .illustration {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(217, 231, 240);
    width: 2rem;
    max-width: 2rem;
    height: 2rem;
    max-height: 2rem;
    border-radius: 99rem;
    color: white;
    margin-right: 1rem;
  }

  input[readonly] {
    background-color: white;
  }

  .input-group input {
    cursor: pointer;
  }

  .input-group-addon {
    padding: .25rem .5rem;
    padding-right: 0;
  }

  .icon-square {
    font-size: 1.5rem;
    cursor: pointer;
  }

  .branding_preview {
    pointer-events: none;
    margin-left: -1rem;
    margin-right: -1rem;
  }
</style>
