<template>
  <div>
    <SubdomainModal data-test-subdomain-modal v-if="ask_subdomain" :account="$store.state.user.current_account" />
    <el-container>
      <el-header>
        <div data-test-main-header class="display-small">Forms</div>
      </el-header>
      <el-container class="inner">
        <el-aside width="17rem">
          <div data-test-all-forms-header-1 :class="['item', 'active']">
            All Forms
          </div>
        </el-aside>
        <el-main v-loading="loading">
          <el-row :gutter="24" class="heading">
            <el-col :span="12">
              <div data-test-all-forms-header-2 class="display-medium">
                All Forms
              </div>
            </el-col>
            <el-col :span="8" :offset="4" style="display: flex; justify-content: flex-end;">
              <el-button data-test-create-form-button type="primary" class="xpl-button xpl-button--primary" @click.prevent="newForm">Create a new Form</el-button>
            </el-col>
          </el-row>

          <el-row :gutter="24" class="search">
            <el-col :span="24">
              <el-input data-test-search-for-form prefix-icon="el-icon-search" placeholder="Search" v-model.trim="query"></el-input>
            </el-col>
          </el-row>

          <el-card
          data-test-form-card
            shadow="none"
            v-for="form in filteredForms"
            @click.native="$router.push('/apps/forms/' + form.uuid)"
            :key="form.id">
            <div class="name">{{ form.name }}</div>
            <div class="small text-muted">Last updated on {{ form.updated_at | humanDateTime }}</div>
          </el-card>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
  import {
    getFormsSmall,
    createForm,
  } from '@/api';
  import SubdomainModal from './SubdomainModal';

  export default {
    components: {
      SubdomainModal,
    },
    data() {
      return {
        loading: false,
        ask_subdomain: false,
        query: '',
        forms: {},
      };
    },
    created() {
      this.getForms();
    },
    computed: {
      filteredForms() {
        if (!this.forms.data) {
          return [];
        }

        return this.forms.data;
      },
    },
    methods: {
      getForms() {
        this.loading = true;
        getFormsSmall().then((response) => {
          this.forms = response.forms;
          this.ask_subdomain = response.ask_subdomain;
          this.loading = false;
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      newForm() {
        this.$prompt('Name', 'New Form', {
          confirmButtonText: 'Create',
          cancelButtonText: 'Cancel',
          inputPattern: /^.{3,}$/,
          inputErrorMessage: 'A Form name of at least 3 characters is required.',
        }).then(({ value }) => {
          this.postForm(value);
        });
      },
      postForm(name) {
        createForm({ name }).then((response) => {
          this.$message.success(response.message);
          this.getForms();
          if (this.$intercom) {
            this.$intercom.trackEvent('Created a Form');
          }

          // eslint-disable-next-line no-undef
          analytics.track('Created a Form');

          this.$router.push(`/apps/forms/${response.form.uuid}`);
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-header {
    display: block;
    width: 100%;
    position: fixed;
    z-index: 100;
    border-bottom: 1px solid var(--xpl-color-gray-400);
    padding-left: 1.25rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .el-main {
    margin-top: 3.75rem;
    height: calc(100vh - 6.75rem);
  }

  .el-aside {
    margin-top: 3.75rem;
    height: calc(100vh - 6.75rem);
    padding-top: 2.5rem;
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .el-aside .item {
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: .875rem;
    color: #2e4457;
    transition: all 150ms ease-in-out;
    border-radius: 6px;
    margin-bottom: .375rem;
  }

  .el-aside .item:hover,
  .el-aside .item.active {
    cursor: pointer;
    background-color: var(--xpl-color-secondary-bg-lm);
  }

  .el-aside .item.active {
    font-weight: 700;
  }

  .el-aside .item .count {
    margin-left: auto;
    color: #617080;
  }

  .el-aside .heading {
    padding-left: 1rem;
    margin-top: 1.875rem;
    font-size: 1rem;
    font-weight: 700;
    color: #00152a;
    margin-bottom: 1.25rem;
  }

  .el-aside a {
    display: block;
    margin-top: 1rem;
    padding-left: 1rem;
    font-size: .875rem;
    color: #6923f4;
  }

  .container .container-inner {
    height: calc(100vh - 4rem);
    display: block;
    width: 100%;
    margin-top: 4rem;
    padding-top: 1.875rem;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .el-main .heading {
    margin-bottom: 1.875rem;
  }

  .el-main .heading .display-medium {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .el-row.search {
    margin-bottom: 2.5rem;
  }

  .el-card + .el-card {
    margin-top: 1rem;
  }

  .el-card:hover,
  .el-card:focus {
    border-color: var(--xpl-color-secondary-lm);
    cursor: pointer;
  }

  .el-card .name {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #1d364b;
  }
</style>
