<template>
  <div>
    <div v-if="rule && rule.length" :class="['conditions-container', expanded || rule.length === 1 ? 'expanded' : '']">
      <div class="conditions" v-for="condition in rule">
        <div class="name">
          <img :src="'https://assets.brandbot.com/public/icons/conditions/' + condition.condition.uuid + '.svg'" />
          {{ enrollment && condition.condition.uuid === 'd323c020-0569-4f1e-ab1d-f56672d66610' ? 'Joins a Membership for the first time' : condition.condition.name }}
        </div>
        <div class="details">
          <!-- Has A Membership MT -->
          <div v-if="condition.condition.uuid === '0567f82c-bd39-4850-b4f0-715fe8c8009d'">
            <div class="row">
              <span class="title">Customers Who Have / Do Not Have?</span>
              <div class="disable-caps" v-if="condition.has_memberships">Have the following</div>
              <div class="disable-caps" v-if="!condition.has_memberships">Does not have the following</div>
            </div>
            <div class="row">
              <span class="title">Which Memberships?</span>
              <div class="disable-caps" v-if="condition.all_memberships">All Membership(s)</div>
              <div class="disable-caps" v-if="!condition.all_memberships">Specific Membership(s)</div>
            </div>
            <div v-if="!condition.all_memberships" class="row">
              <span class="title">Which Membership Types?</span>
              <div class="disable-caps" v-if="condition.membership_type_option === 'any'">All membership types</div>
              <div v-if="condition.membership_type_option === 'specific'">
                <div v-if="condition.membership_type_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.membership_type_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="membership in condition.membership_types" :key="membership.id">
                  <div class="disable-caps">{{ membership.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_memberships" class="row">
              <span class="title disable-caps">Which Membership Status(es)?</span>
              <div class="disable-caps" v-if="condition.membership_status_option === 'any'">All membership statuses</div>
              <div v-if="condition.membership_status_option === 'specific'">
                <div v-if="condition.membership_status_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.membership_status_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="status in condition.membership_statuses" :key="status.id">
                  <div class="disable-caps">{{ status.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_memberships" class="row">
              <span class="title">When Did They Start Their Membership?</span>
              <div class="disable-caps" >{{ condition.membership_start_filter | timeComponent(condition.membership_start_value) }}</div>
            </div>
            <div v-if="!condition.all_memberships" class="row">
              <span class="title disable-caps">Membership Purchase Location</span>
              <div class="disable-caps" v-if="condition.selected_purchase_location_option === 'any' || !condition.selected_purchase_location_option">Any location in my account</div>
              <div v-if="condition.selected_purchase_location_option === 'specific'">
                <div v-if="condition.selected_purchase_location_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.selected_purchase_location_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="location in condition.selected_purchase_locations" :key="location.id">
                  <div class="disable-caps">{{ location.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Home Location MT -->
          <div v-if="condition.condition.uuid === 'e3d9c44d-a03c-4979-8a35-89c8003b2fb8'">
            <div class="row">
              <span class="title">Which Home Location?</span>
              <div>
                <div v-if="condition.exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="location in condition.locations" :key="location.id">
                  <div class="disable-caps">{{ location.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Milestone Visits MT -->
          <div v-if="condition.condition.uuid === '5005b33e-6ea6-45fc-affd-4b2f184efebf'">
            <div class="row">
              <span class="title">How Many Visits?</span>
              <div class="disable-caps" >{{ condition.comparison | countComponent(condition.first_bound, condition.second_bound, 'visits') }}</div>
            </div>
            <div class="row">
              <span class="title disable-caps">At Which Location(s)?</span>
              <div class="disable-caps" v-if="condition.option === 'any'">Any location in my account</div>
              <div v-if="condition.option === 'specific'">
                <div v-if="condition.exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="location in condition.locations" :key="location.id">
                  <div class="disable-caps">{{ location.name }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <span class="title">What Is Their Home Location?</span>
              <div class="disable-caps" v-if="condition.home_option === 'any'">Any location in my account</div>
              <div v-if="condition.home_option === 'specific'">
                <div v-if="condition.home_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.home_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="location in condition.home_locations" :key="location.id">
                  <div class="disable-caps">{{ location.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Number Of Visits MT -->
          <div v-if="condition.condition.uuid === '6696ed9d-bacc-445e-a8fa-0866201ad53f'">
            <div class="row">
              <span class="title">How Many Visits?</span>
              <div class="disable-caps" >{{ condition.count_comparison | countComponent(condition.first_bound, condition.second_bound, 'visits') }}</div>
            </div>
            <div class="row">
              <span class="title">When Did These Visits Occur?</span>
              <div class="disable-caps" >{{ condition.first_visit_comparison | timeComponent(condition.first_visit_value, hasSinceEntering = true) }}</div>
            </div>
            <div class="row">
              <span class="title">What Types Of Visits Does This Count?</span>
              <div class="disable-caps" v-if="condition.all_first_visits">All visits</div>
              <div class="disable-caps" v-if="!condition.all_first_visits">Specific visits</div>
            </div>
            <div v-if="!condition.all_first_visits" class="row">
              <span class="title disable-caps">Which Class Name(s)?</span>
              <div class="disable-caps" v-if="condition.class_name_option === 'any'">All class names</div>
              <div v-if="condition.class_name_option === 'specific'">
                <div v-if="condition.class_name_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.class_name_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="class_name in condition.class_names" :key="class_name.id">
                  <div class="disable-caps">{{ class_name.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_first_visits" class="row">
              <span class="title disable-caps">Which Payment Option(s)?</span>
              <div class="disable-caps" v-if="condition.payment_option_option === 'any'">All payment options</div>
              <div v-if="condition.payment_option_option === 'specific'">
                <div v-if="condition.membership_option_exclusion === 'any' && condition.membership_types_checked" class="disable-caps">Any of the following:</div>
                <div v-if="condition.membership_option_exclusion === 'except' && condition.membership_types_checked" class="disable-caps">Any except the following:</div>
                <div v-if="condition.membership_types_checked" v-for="membership in condition.membership_options" :key="membership.id">
                  <div class="disable-caps">{{ membership.name }}</div>
                </div>
                <div v-if="condition.credit_option_exclusion === 'any' && condition.credit_types_checked" class="disable-caps">Any of the following:</div>
                <div v-if="condition.credit_option_exclusion === 'except' && condition.credit_types_checked" class="disable-caps">Any except the following:</div>
                <div v-if="condition.credit_types_checked" v-for="credit in condition.credit_options" :key="credit.id">
                  <div class="disable-caps">{{ credit.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_first_visits" class="row">
              <span class="title disable-caps">Which Class Instructor(s)?</span>
              <div class="disable-caps" v-if="condition.class_instructor_option === 'any'">All class instructors</div>
              <div v-if="condition.class_instructor_option === 'specific'">
                <div v-if="condition.class_instructor_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.class_instructor_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="instructor in condition.class_instructors" :key="instructor.id">
                  <div class="disable-caps">{{ instructor.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_first_visits" class="row">
              <span class="title disable-caps">Which Class Time(s)?</span>
              <div class="disable-caps" v-if="condition.class_time_option === 'any'">All class times</div>
              <div v-if="condition.class_time_option === 'specific'">
                <div v-if="condition.class_time_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.class_time_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="class_time in condition.class_times" :key="class_time.id">
                  <div class="disable-caps">{{ class_time.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_first_visits" class="row">
              <span class="title disable-caps">Which Visit Status(es)?</span>
              <div class="disable-caps" v-if="condition.visit_status_option === 'any'">All visit statuses</div>
              <div v-if="condition.visit_status_option === 'specific'">
                <div v-if="condition.visit_status_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.visit_status_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="status in condition.visit_statuses" :key="status.id">
                  <div class="disable-caps">{{ status.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_first_visits" class="row">
              <span class="title disable-caps">Which Visit Location(s)?</span>
              <div class="disable-caps" v-if="condition.visit_location_option === 'any'">Any location in my account</div>
              <div v-if="condition.visit_location_option === 'specific'">
                <div v-if="condition.visit_location_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.visit_location_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="location in condition.visit_locations" :key="location.id">
                  <div class="disable-caps">{{ location.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Last Visit MT -->
          <div v-if="condition.condition.uuid === 'a037768c-a9cd-4250-abbe-062d27e1b78c'">
            <div class="row">
              <span class="title">What Are You Looking For?</span>
              <div class="disable-caps" v-if="condition.visit_only">Last Visit Only</div>
              <div class="disable-caps" v-if="!condition.visit_only">Last Interaction (Visit or Purchase)</div>
            </div>
            <div class="row">
              <span class="title">When Was Their Last Visit Or Interaction?</span>
              <div class="disable-caps" >{{ condition.timeframe_comparison | timeComponent(condition.timeframe_value) }}</div>
            </div>
            <div class="row">
              <span class="title">Was Their Last Visit Any Type Or A Specific Type?</span>
              <div class="disable-caps" v-if="condition.include_all_visits">All visit types</div>
              <div class="disable-caps" v-if="!condition.include_all_visits">Specific type of visits</div>
            </div>
            <div v-if="!condition.include_all_visits" class="row">
              <span class="title disable-caps">Which Payment Option(s)?</span>
              <div class="disable-caps" v-if="condition.payment_option_option === 'any'">All payment options</div>
              <div v-if="condition.payment_option_option === 'specific'">
                <div v-if="condition.membership_option_exclusion === 'any' && condition.membership_types_checked" class="disable-caps">Any of the following:</div>
                <div v-if="condition.membership_option_exclusion === 'except' && condition.membership_types_checked" class="disable-caps">Any except the following:</div>
                <div v-if="condition.membership_types_checked" v-for="membership in condition.membership_options" :key="membership.id">
                  <div class="disable-caps">{{ membership.name }}</div>
                </div>
                <div v-if="condition.credit_option_exclusion === 'any' && condition.credit_types_checked" class="disable-caps">Any of the following:</div>
                <div v-if="condition.credit_option_exclusion === 'except' && condition.credit_types_checked" class="disable-caps">Any except the following:</div>
                <div v-if="condition.credit_types_checked" v-for="credit in condition.credit_options" :key="credit.id">
                  <div class="disable-caps">{{ credit.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.include_all_visits" class="row">
              <span class="title">Where Was Their Last Visit Or Interaction?</span>
              <div class="disable-caps" v-if="condition.selected_home_option === 'any'">Any location in my account</div>
              <div v-if="condition.selected_home_option === 'specific'">
                <div v-if="condition.selected_home_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.selected_home_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="location in condition.selected_home_locations" :key="location.id">
                  <div class="disable-caps">{{ location.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Visits Remaining MT -->
          <div v-if="condition.condition.uuid === '744156da-5aed-409d-a2cb-6436ae3a6e04'">
            <div class="row">
              <span class="title">Customers Who Have / Do Not Have?</span>
              <div v-if="condition.hasOwnProperty('has_visits_remaining')">
                <div class="disable-caps" v-if="condition.has_visits_remaining">Has visits remaining</div>
                <div class="disable-caps" v-if="!condition.has_visits_remaining">Does not have visits remaining</div>
              </div>
              <div v-else>
                <div class="disable-caps">Has visits remaining</div>
              </div>
            </div>
            <div class="row">
              <span class="title">How Many Visits Remaining?</span>
              <div class="disable-caps" >{{ condition.comparison | countComponent(condition.first_bound, condition.second_bound, 'visits') }}</div>
            </div>
            <div class="row">
              <span class="title">Which Credit Packages?</span>
              <div class="disable-caps" v-if="condition.all_credit_packages">All credit packages</div>
              <div class="disable-caps" v-if="!condition.all_credit_packages">Specific credit package</div>
            </div>
            <div v-if="!condition.all_credit_packages" class="row">
              <span class="title disable-caps">Which Credit Package Type(s)?</span>
              <div class="disable-caps" v-if="condition.credit_package_option === 'any'">All credit packages</div>
              <div v-if="condition.credit_package_option === 'specific'">
                <div v-if="condition.credit_package_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.credit_package_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="credit_package in condition.credit_packages" :key="credit_package.id">
                  <div class="disable-caps">{{ credit_package.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Finished A Credit Package MT -->
          <div v-if="condition.condition.uuid === '78b51a87-ca35-4132-8342-ae145b067fc3'">
            <div class="row">
              <span class="title">When Did They Finish?</span>
              <div class="disable-caps" >{{ condition.finished_time_comparison | timeComponent(condition.finished_time_value) }}</div>
            </div>
            <div class="row">
              <span class="title">Which Type Of Credit Package?</span>
              <div class="disable-caps" v-if="condition.all_credit_packages">All credit packages</div>
              <div class="disable-caps" v-if="!condition.all_credit_packages">Specific credit package</div>
            </div>
            <div v-if="!condition.all_credit_packages" class="row">
              <span class="title disable-caps">Which Credit Package?</span>
              <div class="disable-caps" v-if="condition.credit_package_option === 'any'">All credit packages</div>
              <div v-if="condition.credit_package_option === 'specific'">
                <div v-if="condition.credit_package_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.credit_package_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="credit_package in condition.credit_packages" :key="credit_package.id">
                  <div class="disable-caps">{{ credit_package.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_credit_packages" class="row">
              <span class="title">How Long Did It Take Them To Finish?</span>
              <div class="disable-caps" >{{ condition.finish_comparison | countComponent(condition.finish_first_bound, condition.finish_second_bound, 'days') }}</div>
            </div>
            <div v-if="!condition.all_credit_packages" class="row">
            <span class="title disable-caps">Credit Package Purchase Location</span>
            <div class="disable-caps" v-if="condition.selected_purchase_location_option === 'any' || !condition.selected_purchase_location_option">Any location in my account</div>
            <div v-if="condition.selected_purchase_location_option === 'specific'">
              <div v-if="condition.selected_purchase_location_exclusion === 'any'" class="disable-caps">Any of the following:</div>
              <div v-if="condition.selected_purchase_location_exclusion === 'except'" class="disable-caps">Any except the following:</div>
              <div v-for="location in condition.selected_purchase_locations" :key="location.id">
                <div class="disable-caps">{{ location.name }}</div>
              </div>
            </div>
          </div>
          </div>
          <!-- Starts A Credit Package MT -->
          <div v-if="condition.condition.uuid === '928debf9-8856-41bf-8251-fbcb2776dd3c'">
            <div class="row">
              <span class="title">When Did The Credit Package Start?</span>
              <div class="disable-caps" >{{ condition.credit_package_comparison | timeComponent(condition.credit_package_value) }}</div>
            </div>
            <div class="row">
              <span class="title">Which Type Of Credit Package(s)?</span>
              <div class="disable-caps" v-if="condition.all_credit_packages">All credit packages</div>
              <div class="disable-caps" v-if="!condition.all_credit_packages">Specific credit package</div>
            </div>
            <div v-if="!condition.all_credit_packages" class="row">
              <span class="title disable-caps">Which Credit Package?</span>
              <div class="disable-caps" v-if="condition.credit_package_option === 'any'">All credit packages</div>
              <div v-if="condition.credit_package_option === 'specific'">
                <div v-if="condition.credit_package_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.credit_package_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="credit_package in condition.credit_packages" :key="credit_package.id">
                  <div class="disable-caps">{{ credit_package.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Has A Tag MT -->
          <div v-if="condition.condition.uuid === '4d0702b6-53b4-4e51-ade3-dc9983c635ab'">
            <div class="row">
              <span class="title">Customers Who Have / Do Not Have?</span>
              <div class="disable-caps" v-if="condition.have_tags">Have the following</div>
              <div class="disable-caps" v-if="!condition.have_tags">Does not have the following</div>
            </div>
            <div class="row">
              <span class="title disable-caps">Which Tag?</span>
              <div v-if="condition.tag_option === 'specific'">
                <div v-if="condition.tag_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.tag_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="tag in condition.tags" :key="tag.id">
                  <div class="disable-caps">{{ tag.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Membership Cancelled MT -->
          <div v-if="condition.condition.uuid === 'af56f4ee-3104-4e12-9ef4-00b0cda467cd'">
            <div class="row">
              <span class="title">When Was The Membership Cancelled?</span>
              <div class="disable-caps" >{{ condition.cancellation_comparison | timeComponent(condition.cancellation_value, hasSinceEntering = true) }}</div>
            </div>
            <div class="row">
              <span class="title">Which Memberships?</span>
              <div class="disable-caps" v-if="condition.all_memberships">All Membership(s)</div>
              <div class="disable-caps" v-if="!condition.all_memberships">Specific Membership(s)</div>
            </div>
            <div v-if="!condition.all_memberships" class="row">
              <span class="title">Which Membership Types?</span>
              <div class="disable-caps" v-if="condition.membership_type_option === 'any'">All membership types</div>
              <div v-if="condition.membership_type_option === 'specific'">
                <div v-if="condition.membership_type_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.membership_type_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="membership in condition.membership_types" :key="membership.id">
                  <div class="disable-caps">{{ membership.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_memberships" class="row">
              <span class="title disable-caps">Which Membership Status(es)?</span>
              <div class="disable-caps" v-if="condition.membership_status_option === 'any'">All membership statuses</div>
              <div v-if="condition.membership_status_option === 'specific'">
                <div v-if="condition.membership_status_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.membership_status_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="status in condition.membership_statuses" :key="status.id">
                  <div class="disable-caps">{{ status.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_memberships" class="row">
              <span class="title">When Did They Start Their Membership?</span>
              <div class="disable-caps" >{{ condition.membership_start_comparison | timeComponent(condition.membership_start_value, hasSinceEntering = true) }}</div>
            </div>
          </div>
          <!-- First Visit MT -->
          <div v-if="condition.condition.uuid === '5898fb00-771c-44b8-b8f0-0a0f7da1fbc6'">
            <div class="row">
              <span class="title">When Did The Visit Occur?</span>
              <div class="disable-caps" >{{ condition.first_visit_comparison | timeComponent(condition.first_visit_value) }}</div>
            </div>
            <div class="row">
              <span class="title">Which Visits?</span>
              <div class="disable-caps" v-if="condition.all_first_visits">All visits</div>
              <div class="disable-caps" v-if="!condition.all_first_visits">Specific visits</div>
            </div>
            <div v-if="!condition.all_first_visits" class="row">
              <span class="title disable-caps">Which Class Name(s)?</span>
              <div class="disable-caps" v-if="condition.class_name_option === 'any'">All class names</div>
              <div v-if="condition.class_name_option === 'specific'">
                <div v-if="condition.class_name_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.class_name_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="class_name in condition.class_names" :key="class_name.id">
                  <div class="disable-caps">{{ class_name.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_first_visits" class="row">
              <span class="title disable-caps">Which Payment Option(s)?</span>
              <div class="disable-caps" v-if="condition.payment_option_option === 'any'">All payment options</div>
              <div v-if="condition.payment_option_option === 'specific'">
                <div v-if="condition.membership_option_exclusion === 'any' && condition.membership_types_checked" class="disable-caps">Any of the following:</div>
                <div v-if="condition.membership_option_exclusion === 'except' && condition.membership_types_checked" class="disable-caps">Any except the following:</div>
                <div v-if="condition.membership_types_checked" v-for="membership in condition.membership_options" :key="membership.id">
                  <div class="disable-caps">{{ membership.name }}</div>
                </div>
                <div v-if="condition.credit_option_exclusion === 'any' && condition.credit_types_checked" class="disable-caps">Any of the following:</div>
                <div v-if="condition.credit_option_exclusion === 'except' && condition.credit_types_checked" class="disable-caps">Any except the following:</div>
                <div v-if="condition.credit_types_checked" v-for="credit in condition.credit_options" :key="credit.id">
                  <div class="disable-caps">{{ credit.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_first_visits" class="row">
              <span class="title disable-caps">Which Class Instructor(s)?</span>
              <div class="disable-caps" v-if="condition.class_instructor_option === 'any'">All class instructors</div>
              <div v-if="condition.class_instructor_option === 'specific'">
                <div v-if="condition.class_instructor_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.class_instructor_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="instructor in condition.class_instructors" :key="instructor.id">
                  <div class="disable-caps">{{ instructor.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_first_visits" class="row">
              <span class="title disable-caps">Which Class Time(s)?</span>
              <div class="disable-caps" v-if="condition.class_time_option === 'any'">All class times</div>
              <div v-if="condition.class_time_option === 'specific'">
                <div v-if="condition.class_time_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.class_time_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="class_time in condition.class_times" :key="class_time.id">
                  <div class="disable-caps">{{ class_time.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- New Profile in MarianaTek MT -->
          <div v-if="condition.condition.uuid === 'a59c23dc-9207-4690-887e-563af43188e8'">
            <div class="row">
              <span class="title">When Was The Profile Created?</span>
              <div class="disable-caps" >{{ condition.profile_creation_comparison | timeComponent(condition.profile_creation_value) }}</div>
            </div>
            <div class="row">
              <span class="title disable-caps">What Is Their Home Location?</span>
              <div class="disable-caps" v-if="condition.home_option === 'any'">Any location in my account</div>
              <div v-if="condition.home_option === 'specific'">
                <div v-if="condition.home_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.home_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="location in condition.home_locations" :key="location.id">
                  <div class="disable-caps">{{ location.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Credit Package Expiration MT -->
          <div v-if="condition.condition.uuid === 'ce919016-7dd9-4c50-97d4-64fe6d03f9d2'">
            <div class="row">
              <span class="title">When Was The Expiration Date?</span>
              <div class="disable-caps" >{{ condition.expiration_comparison | timeComponent(condition.expiration_value) }}</div>
            </div>
            <div class="row">
              <span class="title disable-caps">Which Credit Package(s)?</span>
              <div class="disable-caps" v-if="condition.all_credit_packages">All credit packages</div>
              <div class="disable-caps" v-if="!condition.all_credit_packages">Specific credit package</div>
            </div>
            <div v-if="!condition.all_credit_packages" class="row">
              <span class="title disable-caps">Which Credit Package?</span>
              <div class="disable-caps" v-if="condition.credit_package_option === 'any'">All credit packages</div>
              <div v-if="condition.credit_package_option === 'specific'">
                <div v-if="condition.credit_package_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.credit_package_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="credit_package in condition.credit_packages" :key="credit_package.id">
                  <div class="disable-caps">{{ credit_package.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_credit_packages" class="row">
              <span class="title">How Many Classes Were Left When It Expired?</span>
              <div class="disable-caps" >{{ condition.finish_comparison | countComponent(condition.finish_first_bound, condition.finish_second_bound, 'classes') }}</div>
            </div>
          </div>
            <!-- Membership Expiration MT -->
          <div v-if="condition.condition.uuid === '253360f9-ec87-4ee4-8067-1b3f75007acf'">
            <div class="row">
              <span class="title">When Was The Expiration Date?</span>
              <div class="disable-caps" >{{ condition.expiration_comparison | timeComponent(condition.expiration_value, hasSinceEntering = true) }}</div>
            </div>
            <div class="row">
              <span class="title disable-caps">Which Membership(s)?</span>
              <div class="disable-caps" v-if="condition.all_memberships">All memberships</div>
              <div class="disable-caps" v-if="!condition.all_memberships">Specific membership(s)</div>
            </div>
            <div v-if="!condition.all_memberships" class="row">
              <span class="title disable-caps">Which Type Of Membership?</span>
              <div class="disable-caps" v-if="condition.membership_type_option === 'any'">All memberships</div>
              <div v-if="condition.membership_type_option === 'specific'">
                <div v-if="condition.membership_type_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.membership_type_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="membership in condition.membership_types" :key="membership.id">
                  <div class="disable-caps">{{ membership.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Future Reservation MT -->
          <div v-if="condition.condition.uuid === '88d36c7a-5151-4e3d-9528-13007b0c5d4e'">
            <div class="row">
              <span class="title">Customers Who Have / Do Not Have?</span>
              <div v-if="condition.hasOwnProperty('has_future_reservation')">
                <div class="disable-caps" v-if="condition.has_future_reservation">Has a future visit</div>
                <div class="disable-caps" v-if="!condition.has_future_reservation">Does not have a future visit</div>
              </div>
              <div v-else>
                <div class="disable-caps">Has a future visit</div>
              </div>
            </div>
            <div class="row">
              <span class="title">What Are You Looking For?</span>
              <div class="disable-caps" >{{ condition.first_visit_comparison | timeComponent(condition.first_visit_value, hasSinceEntering = true) }}</div>
            </div>
            <div class="row">
              <span class="title disable-caps">Which Visit(s)?</span>
              <div class="disable-caps" v-if="condition.all_first_visits">All visits</div>
              <div class="disable-caps" v-if="!condition.all_first_visits">Specific visits</div>
            </div>
            <div v-if="!condition.all_first_visits" class="row">
              <span class="title disable-caps">Which Class Name(s)?</span>
              <div class="disable-caps" v-if="condition.class_name_option === 'any'">All class names</div>
              <div v-if="condition.class_name_option === 'specific'">
                <div v-if="condition.class_name_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.class_name_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="class_name in condition.class_names" :key="class_name.id">
                  <div class="disable-caps">{{ class_name.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_first_visits" class="row">
              <span class="title disable-caps">Which Payment Option(s)?</span>
              <div class="disable-caps" v-if="condition.payment_option_option === 'any'">All payment options</div>
              <div v-if="condition.payment_option_option === 'specific'">
                <div v-if="condition.membership_option_exclusion === 'any' && condition.membership_types_checked" class="disable-caps">Any of the following:</div>
                <div v-if="condition.membership_option_exclusion === 'except' && condition.membership_types_checked" class="disable-caps">Any except the following:</div>
                <div v-if="condition.membership_types_checked" v-for="membership in condition.membership_options" :key="membership.id">
                  <div class="disable-caps">{{ membership.name }}</div>
                </div>
                <div v-if="condition.credit_option_exclusion === 'any' && condition.credit_types_checked" class="disable-caps">Any of the following:</div>
                <div v-if="condition.credit_option_exclusion === 'except' && condition.credit_types_checked" class="disable-caps">Any except the following:</div>
                <div v-if="condition.credit_types_checked" v-for="credit in condition.credit_options" :key="credit.id">
                  <div class="disable-caps">{{ credit.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_first_visits" class="row">
              <span class="title disable-caps">Which Class Instructor(s)?</span>
              <div class="disable-caps" v-if="condition.class_instructor_option === 'any'">All class instructors</div>
              <div v-if="condition.class_instructor_option === 'specific'">
                <div v-if="condition.class_instructor_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.class_instructor_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="instructor in condition.class_instructors" :key="instructor.id">
                  <div class="disable-caps">{{ instructor.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_first_visits" class="row">
              <span class="title disable-caps">Which Class Time(s)?</span>
              <div class="disable-caps" v-if="condition.class_time_option === 'any'">All class times</div>
              <div v-if="condition.class_time_option === 'specific'">
                <div v-if="condition.class_time_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.class_time_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="class_time in condition.class_times" :key="class_time.id">
                  <div class="disable-caps">{{ class_time.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_first_visits" class="row">
              <span class="title disable-caps">Which Visit Location(s)?</span>
              <div class="disable-caps" v-if="condition.visit_location_option === 'any'">Any location in my account</div>
              <div v-if="condition.visit_location_option === 'specific'">
                <div v-if="condition.visit_location_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.visit_location_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="location in condition.visit_locations" :key="location.id">
                  <div class="disable-caps">{{ location.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_first_visits" class="row">
              <span class="title disable-caps">Only Include First Reservation Ever?</span>
              <div class="disable-caps" v-if="condition.first_visit_option">Yes</div>
              <div class="disable-caps" v-if="!condition.first_visit_option">No</div>
            </div>
          </div>
          <!-- Made A Purchase MT -->
          <div v-if="condition.condition.uuid === '37a361cc-ded7-48b6-9d70-1c4b7aa08284'">
            <div class="row">
              <span class="title">Customers Who Purchased / Did Not Purchase?</span>
              <div class="disable-caps" v-if="condition.all_made_purchase">Purchased the following</div>
              <div class="disable-caps" v-if="!condition.all_made_purchase">Did not purchase the following</div>
            </div>
            <div class="row">
              <span class="title">When Was The Purchase Made?</span>
              <div class="disable-caps" >{{ condition.purchase_comparison | timeComponent(condition.purchase_value, hasSinceEntering = true) }}</div>
            </div>
            <div class="row">
              <span class="title disable-caps">Which Purchase(s)?</span>
              <div class="disable-caps" v-if="condition.all_purchase_types">All purchases</div>
              <div class="disable-caps" v-if="!condition.all_purchase_types">Specific purchases</div>
            </div>
            <div v-if="!condition.all_purchase_types" class="row">
              <span class="title disable-caps">Which Type Of Purchase?</span>
              <div class="disable-caps" v-if="condition.purchase_option === 'any'">All purchases</div>
              <div v-if="condition.purchase_option === 'specific'">
                <div v-if="condition.purchase_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.purchase_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="purchase in condition.purchases" :key="purchase.id">
                  <div class="disable-caps">{{ purchase.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_purchase_types" class="row">
              <span class="title disable-caps">Which Purchase Category?</span>
              <div class="disable-caps" v-if="condition.purchase_category_option === 'any'">All categories</div>
              <div v-if="condition.purchase_category_option === 'specific'">
                <div v-if="condition.purchase_category_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.purchase_category_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="category in condition.purchase_categories" :key="category.id">
                  <div class="disable-caps">{{ category.name }}</div>
                </div>
              </div>
            </div>
            <div v-if="!condition.all_purchase_types" class="row">
              <span class="title disable-caps">Which Purchase Location(s)?</span>
              <div class="disable-caps" v-if="condition.purchase_location_option === 'any'">Any location in my account</div>
              <div v-if="condition.purchase_location_option === 'specific'">
                <div v-if="condition.purchase_location_exclusion === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.purchase_location_exclusion === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="location in condition.purchase_locations" :key="location.id">
                  <div class="disable-caps">{{ location.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Is Vimeo Customer -->
          <div v-if="condition.condition.uuid === '71f9ae9e-b852-40c1-b6c6-f79322f7f4bf'">
            <div class="row">
              <span class="title">Is a Customer / Is Not a Customer?</span>
              <div class="disable-caps" v-if="condition.is_customer">Is a Vimeo Customer</div>
              <div class="disable-caps" v-if="!condition.is_customer">Is not a Vimeo Customer</div>
            </div>
            <div class="row">
              <span class="title">When did the Contact become a Customer?</span>
              <div class="disable-caps" >{{ condition.customer_creation_comparison | timeComponent(condition.customer_creation_value) }}</div>
            </div>
          </div>
          <!-- Has Vimeo Subscription  -->
          <div v-if="condition.condition.uuid === 'feb848d2-d242-44b4-8754-80ef489aa963'">
            <div class="row">
              <span class="title">Has / Does not Have a Subscription?</span>
              <div class="disable-caps" v-if="condition.has_subscription">Has a Subscription</div>
              <div class="disable-caps" v-if="!condition.has_subscription">Does not Have a Subscription</div>
            </div>
            <div class="row">
              <span class="title">When did the Subscription Start?</span>
              <div class="disable-caps" >{{ condition.subscription_creation_comparison | timeComponent(condition.subscription_creation_value) }}</div>
            </div>
            <div class="row">
              <span class="title">Which Products is this Subscription for?</span>
              <div class="disable-caps" v-if="condition.specific_products === 'any'">All products</div>
              <div class="disable-caps" v-if="condition.specific_products === 'specific'">
                <div v-if="condition.product_exclusion_option === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.product_exclusion_option === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="product in condition.selected_products" :key="product.id">
                  <div class="disable-caps">{{ product.name }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <span class="title">Which Statuse(s)</span>
              <div class="disable-caps" v-if="condition.specific_statuses === 'any'">All statuses</div>
              <div class="disable-caps" v-if="condition.specific_statuses === 'specific'">
                <div v-if="condition.status_exclusion_option === 'any'" class="disable-caps">Any of the following:</div>
                <div v-if="condition.status_exclusion_option === 'except'" class="disable-caps">Any except the following:</div>
                <div v-for="status in condition.selected_statuses" :key="status.id">
                  <div class="disable-caps">{{ status.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="condition.condition.uuid === 'e3d9c44d-a03c-4979-8a35-89c8003b2fb8'">
            <div class="title">Filtering</div>
            <span v-if="condition.exclusion === 'except'">Any except the following</span>
            <span v-if="condition.exclusion === 'any'">Any of the following</span>
          </div>
          <div class="row" v-if="condition.condition.uuid === 'e3d9c44d-a03c-4979-8a35-89c8003b2fb8'">
            <span class="title">Locations</span>
            <span>
              <div v-for="location in condition.locations">
                {{ location.name }}
              </div>
            </span>
          </div>
          <!-- <div class="row" v-if="condition.condition.uuid === '5005b33e-6ea6-45fc-affd-4b2f184efebf' && condition.locations && condition.locations.length">
            <span class="title">Locations</span>
            <span>
              <div v-for="location in condition.locations">
                {{ location.name }}
              </div>
            </span>
          </div>
          <div class="row" v-if="condition.condition.uuid === '5005b33e-6ea6-45fc-affd-4b2f184efebf'">
            <span class="title">Visit Details</span>
            <span>
              <span v-if="condition.comparison === 'exactly'">Exactly {{ condition.first_bound }} visits</span>
              <span v-if="condition.comparison === 'moreThan'">More than {{ condition.first_bound }} visits</span>
              <span v-if="condition.comparison === 'lessThan'">Less than {{ condition.first_bound }} visits</span>
              <span v-if="condition.comparison === 'between'">Between {{ condition.first_bound }} and {{ condition.second_bound }} visits</span>
            </span>
          </div> -->
          <div class="row" v-if="condition.condition.uuid === '10f161c2-3998-4922-a720-31bfba7744ff'">
            <span class="title">Time Frame:</span>
            <span v-if="condition.time_comparison === 'exactly'">
              Exactly {{ condition.relative_days }} day{{ condition.relative_days > 1 ? 's' : '' }} ago
            </span>
            <span v-if="condition.time_comparison === 'within'">
              Within the last {{ condition.relative_days }} day{{ condition.relative_days > 1 ? 's' : '' }}
            </span>
            <span v-if="condition.time_comparison === 'more'">
              At least {{ condition.relative_days }} day{{ condition.relative_days > 1 ? 's' : '' }} ago
            </span>
          </div>
          <div class="row" v-if="condition.timeframe">
            <span class="title" v-if="condition.timeframe">Time Frame:</span>
            <span v-if="condition.timeframe === 'all'">{{ condition.timeframe | timeFrame }}</span>
            <span v-if="condition.timeframe === 'relative_future'">
              {{ condition.comparison === 'more' ? 'At least' : condition.comparison }} {{ condition.relative_days }} Days {{ condition.comparison === 'more' ? 'from now' : condition.timeframe | timeFrame }}
            </span>
            <span v-if="condition.timeframe === 'more'">
              Within the next {{ condition.relative_days }} day{{ condition.relative_days > 1 ? 's' : '' }}
            </span>
            <span v-if="condition.timeframe === 'exactly'">
              Exactly {{ condition.relative_days }} day{{ condition.relative_days > 1 ? 's' : '' }} from now
            </span>
            <span v-if="condition.timeframe === 'absolute'">
              {{ condition.comparison || condition.comparison_date }}
              <span v-if="condition.start_date">{{ condition.start_date | humanDate(false) }}</span>
              <span v-if="condition.end_date"> and {{ condition.end_date | humanDate(false) }}</span>
              <span v-if="condition.start_date_day && condition.start_date_month">{{ condition.start_date_month }} {{ condition.start_date_day }}</span>
              <span v-if="condition.end_date_day && condition.end_date_month"> and {{ condition.end_date_month }} {{ condition.end_date_day }}</span>
            </span>
            <span v-if="condition.timeframe === 'relative'">
              {{ condition.comparison }}
              <span v-if="condition.comparison === 'more'">than</span>
              <span v-if="condition.comparison === 'within'">the last</span>
              {{ condition.relative_days }} days
              <span v-if="condition.comparison !== 'within'">ago</span>
            </span>
            <span v-if="condition.timeframe === 'entering_campaign'">{{ condition.timeframe | timeFrame }}</span>
            <span v-if="condition.timeFrame === 'future'">In The Future</span>
          </div>
          <div class="row" v-if="condition.purchase === true || condition.purchase === false">
            <span class="title">Purchase:</span>
            <span>{{ condition.purchase ? 'Made' : 'Has Not Made'}}</span><span v-if="condition.purchase_type === 'specific'">&nbsp;a specific</span><span v-if="condition.purchase_type === 'any'">&nbsp;any</span>&nbsp;purchase
          </div>
          <div class="row" v-if="condition.pricing_options && condition.pricing_options.length">
            <span class="title">Pricing Options:</span>
            <div>
              <div v-for="pricing_option in condition.pricing_options">{{ pricing_option.name }}</div>
            </div>
          </div>
          <div class="row" v-if="condition.hasOwnProperty('has_package')">
            <span class="title">Has Package:</span>
            <div>{{ condition.has_package === false ? 'No' : 'Yes' }}</div>
          </div>
          <div class="row" v-if="condition.selected_packages && condition.selected_packages.length">
            <span class="title">Packages:</span>
            <div>
              <div v-for="pricing_option in condition.selected_packages">{{ pricing_option.name }}</div>
            </div>
          </div>
          <div class="row" v-if="condition.hasOwnProperty('any_timeframe') && condition.any_timeframe === false && condition.timeframe_type && condition.days">
            <span class="title">Finished in:</span>
            <div>
              {{ condition.timeframe_type === 'exactly' ? condition.timeframe_type : condition.timeframe_type + ' than' }} {{ condition.days }} days.
            </div>
          </div>
          <div class="row" v-if="condition.classes && condition.classes.length">
            <span class="title">Classes:</span>
            <div>
              <div v-for="c in condition.classes">{{ c.name }}</div>
            </div>
          </div>
          <div class="row" v-if="condition.revenue_categories && condition.revenue_categories.length">
            <span class="title">Revenue Categories:</span>
            <div>
              <div v-for="revenue_category in condition.revenue_categories">{{ revenue_category.name }}</div>
            </div>
          </div>
          <div class="row" v-if="condition.service_categories && condition.service_categories.length">
            <span class="title">Service Categories:</span>
            <div>
              <div v-for="service_category in condition.service_categories">{{ service_category.name }}</div>
            </div>
          </div>
          <div class="row" v-if="condition.service_names && condition.service_names.length">
            <span class="title">Service Names:</span>
            <div>
              <div v-for="service_name in condition.service_names">{{ service_name }}</div>
            </div>
          </div>
          <div class="row" v-if="condition.hasOwnProperty('is_unsubscribed')">
            <span class="title">Unsubscribed</span>
            <div>{{ condition.is_unsubscribed ? 'Yes' : 'No' }}</div>
          </div>
          <div class="row" v-if="condition.hasOwnProperty('has_account_credit')">
            <span class="title">Account Credit</span>
            <div>{{ condition.has_account_credit ? 'Yes' : 'No' }}</div>
          </div>
          <div class="row" v-if="condition.services && condition.services.length">
            <span class="title">Service Names:</span>
            <div>
              <div v-for="service_name in condition.services">{{ service_name }}</div>
            </div>
          </div>
          <div class="row" v-if="condition.contracts && condition.contracts.length">
            <span class="title">Contracts:</span>
            <div>
              <div v-for="contract in condition.contracts">{{ contract.name }}</div>
            </div>
          </div>
          <div class="row" v-if="condition.products && condition.products.length">
            <span class="title">Products:</span>
            <div>
              <div v-for="product in condition.products">{{ product.name }}</div>
            </div>
          </div>
          <div class="row" v-if="condition.hasOwnProperty('auto_pay')">
            <span class="title">Auto-Pay Only:</span>
            <div>
              {{ condition.auto_pay ? 'Yes' : 'No' }}
            </div>
          </div>
          <div class="row" v-if="condition.unsubscribed">
            <span class="title">Unsubscribed</span>
            <div>
              Yes
            </div>
          </div>
          <div class="row" v-if="condition.hasOwnProperty('has_payment')">
            <span class="title">Payment on File</span>
            <div v-if="condition.has_payment">Yes</div>
            <div v-if="condition.has_payment === false">No</div>
          </div>
          <div class="row" v-if="condition.hasOwnProperty('future_reservation') && !condition.future_reservation">
            <span class="title">Has Reservation</span>
            <div>
              No
            </div>
          </div>
          <div class="row" v-if="condition.hasOwnProperty('finished') || condition.hasOwnProperty('landed') || condition.hasOwnProperty('abandonned') || condition.hasOwnProperty('abandoned')">
            <span class="title" v-if="condition.hasOwnProperty('finished')">Finished:</span>
            <span class="title" v-if="condition.hasOwnProperty('landed')">Landed:</span>
            <span class="title" v-if="condition.hasOwnProperty('abandonned')">Abandonned:</span>
            <span class="title" v-if="condition.hasOwnProperty('abandoned')">Abandoned:</span>
            <div v-if="condition.hasOwnProperty('finished')">{{ condition.finished ? 'Have' : 'Have Not' }}</div>
            <div v-if="condition.hasOwnProperty('landed')">{{ condition.landed ? 'Have' : 'Have Not' }}</div>
            <div v-if="condition.hasOwnProperty('abandonned')">{{ condition.abandonned ? 'Hae' : 'Have Not' }}</div>
            <div v-if="condition.hasOwnProperty('abandoned')">{{ condition.abandoned ? 'Has' : 'Has Not' }}</div>
          </div>
          <div class="row" v-if="condition.landing_pages && condition.landing_pages.length">
            <span class="title">Landing Pages:</span>
            <div>
              <div v-for="l in condition.landing_pages">{{ l.name }}</div>
            </div>
          </div>
          <div class="row" v-if="condition.misc && condition.misc.filtering">
            <span class="title">Location(s)</span>
            <div v-if="condition.misc.location">
              <span>ID: {{ condition.misc.location }}</span>
            </div>
          </div>
          <div class="row" v-if="condition.staffes && condition.staffes.length">
            <span class="title">Staff:</span>
            <div>
              <div v-for="c in condition.staffes">{{ c.staff_name || c }}</div>
            </div>
          </div>
          <div class="row" v-if="condition.form">
            <span class="title">Form:</span>
            <div>{{ condition.form.name }}</div>
          </div>
          <div class="row" v-if="condition.field">
            <span class="title">Field:</span>
            <div>{{ condition.field.label }}</div>
          </div>
          <div class="row" v-if="condition.comparison_visit || condition.condition.uuid === 'd2b29794-384f-4e75-9ac8-5fe1b4d9c92f'">
          <span class="title">Visit Details:</span>
          <span v-if="condition.comparison_visit && condition.comparison_visit !== 'more'">
            <span>Visited {{ condition.comparison_visit || condition.comparison }}</span>
            <span v-if="!condition.comparison && condition.comparison_visit && condition.comparison_visit !== 'exactly' && condition.comparison_visit !== 'between'">than</span>
            <span v-if="!condition.comparison_visit && condition.comparison && condition.comparison === 'more'">than</span>
          </span>
          <span v-if="condition.comparison_visit && condition.comparison_visit === 'more'">
            Visited at least
          </span>
          <span v-if="(condition.hasOwnProperty('visit_number_start') && condition.visit_number_start !== null) || (condition.visit_number !== null && condition.visit_number)">{{ condition.hasOwnProperty('visit_number_start') && condition.visit_number_start !== null ? '&nbsp;' + condition.visit_number_start + ' ' : '' }}
          {{ condition.visit_number }}
          {{ (condition.comparison_visit === 'between' || condition.comparison === 'between') && condition.hasOwnProperty('visit_number_end') && condition.visit_number_end !== null ? ' and ' + condition.visit_number_end : '' }}
          times</span>
          <span v-if="condition.comparison === 'within'">Within the last</span>
          <span v-if="condition.relative_days">&nbsp;{{ condition.relative_days }} days {{ condition.comparison !== 'within' ? 'ago' : '' }}</span>
          .
          </div>
          <div class="row" v-if="condition.visit_compaison">
          <span class="title">Visit Details:</span>
          <span v-if="condition.visit_compaison && condition.visit_compaison !== 'more'">
            <span>Visited {{ condition.visit_compaison || condition.comparison }}</span>
            <span v-if="!condition.comparison && condition.visit_compaison && condition.visit_compaison !== 'exactly' && condition.visit_compaison !== 'between'">than</span>
            <span v-if="!condition.visit_compaison && condition.comparison && condition.comparison === 'more'">than</span>
          </span>
          <span v-if="condition.visit_compaison && condition.visit_compaison === 'more'">
            Visited at least
          </span>
          <span v-if="(condition.hasOwnProperty('visit_number_start') && condition.visit_number_start !== null) || (condition.visit_number !== null && condition.visit_number)">{{ condition.hasOwnProperty('visit_number_start') && condition.visit_number_start !== null ? '&nbsp;' + condition.visit_number_start + ' ' : '' }}
          {{ condition.visit_number }}
          {{ (condition.visit_compaison === 'between' || condition.comparison === 'between') && condition.hasOwnProperty('visit_number_end') && condition.visit_number_end !== null ? ' and ' + condition.visit_number_end : '' }}
          times</span>
          <span v-if="condition.comparison === 'within'">Within the last</span>
          <span v-if="condition.relative_days">&nbsp;{{ condition.relative_days }} days {{ condition.comparison !== 'within' ? 'ago' : '' }}</span>
          .
          </div>
          <div class="row" v-if="condition.hasOwnProperty('is_member')">
            <span class="title">Is A Member:</span>
            <div>
              {{ condition.is_member ? 'Yes' : 'No' }}
            </div>
          </div>
          <div class="row" v-if="condition.hasOwnProperty('is_guardian')">
            <span class="title">Is A Guardian:</span>
            <div>
              {{ condition.is_guardian ? 'Yes' : 'No' }}
            </div>
          </div>
          <div class="row" v-if="condition.membership_type && condition.membership_type.length">
            <span class="title">Membership Types:</span>
            <div>
              <div v-for="mt in condition.membership_type">{{ mt.type }}</div>
            </div>
          </div>
          <div class="row" v-if="condition.plans && condition.plans.length">
            <span class="title">Plans:</span>
            <div>
              <div v-for="p in condition.plans">{{ p }}</div>
            </div>
          </div>
          <div class="row" v-if="condition.invert_plans">
            <span class="title">Invert:</span>
            <div>Yes</div>
          </div>
          <div class="row" v-if="condition.membership_status && condition.membership_status.length">
            <span class="title">Membership Statuses:</span>
            <div>
              <div v-for="ms in condition.membership_status">{{ ms.status }}</div>
            </div>
          </div>
          <div class="row" v-if="condition.selected_indexes && condition.selected_indexes.length">
            <span class="title">Client Indexes:</span>
            <div>
              <div v-for="si in condition.selected_indexes">{{ si.name }} - {{ si.value }}</div>
            </div>
          </div>
          <div class="row" v-if="condition.hasOwnProperty('in_segment')">
            <span class="title">In Segments:</span>
            <div>{{ condition.in_segment ? 'Yes' : 'No' }}</div>
          </div>
          <div class="row" v-if="condition.selected_segments && condition.selected_segments.length">
            <span class="title">Segments:</span>
            <div>
              <div v-for="s in condition.selected_segments">{{ s.name }}</div>
            </div>
          </div>
          <div class="row" v-if="condition.hasOwnProperty('has_tag')">
            <span class="title">Has Tags:</span>
            <div>
              {{ condition.has_tag ? 'Yes' : 'No' }}
            </div>
          </div>
          <div class="row" v-if="condition.in_funnel_stage">
            <span class="title">In Funnel Stage</span>
            <div>
              {{ condition.in_funnel_stage ? 'Yes' : 'No' }}
            </div>
          </div>
          <div class="row" v-if="condition.selected_funnels">
            <span class="title">Funnel Stage</span>
            <div>
              <div v-for="stage in condition.selected_funnels">{{ stage.stage }}</div>
            </div>
          </div>
          <div class="row" v-if="condition.selected_tags">
            <span class="title">Tags:</span>
            <div>
              <div v-for="tag in condition.selected_tags">{{ tag.name }}</div>
            </div>
          </div>
          <div class="row" v-if="condition.keyword">
            <span class="title">Keyword</span>
            <div>{{ condition.keyword }}</div>
          </div>
          <div class="row" v-if="condition.selected_genders && condition.selected_genders.length">
            <span class="title">Genders:</span>
            <div>
              <div v-for="g in condition.selected_genders">{{ g.gender }}</div>
            </div>
          </div>
          <div class="row" v-if="condition.hasOwnProperty('is_prospect')">
            <span class="title" v-if="condition.is_prospect === true">Is a prospect:</span>
            <span class="title" v-if="condition.is_prospect === false">Is not a prospect:</span>
          </div>
          <div class="row" v-if="condition.hasOwnProperty('visited') && Array.isArray(condition.status)" v-for="st in condition.status">
            <span class="title">{{ st }}:</span>
            <div>{{ condition.visited ? 'Has ' + st : 'Has Not ' + st }}</div>
          </div>
          <div class="row" v-if="condition.hasOwnProperty('visited') && !Array.isArray(condition.status)">
            <span class="title">{{ condition.status }}:</span>
            <div>{{ condition.visited ? 'Has ' + condition.status : 'Has Not ' + condition.status }}</div>
          </div>
          <div class="row" v-if="condition.hasOwnProperty('location')">
            <span class="title">Location:</span>
            <div v-if="condition.location">{{ condition.location.location_name }}</div>
            <div v-if="!condition.location">No Preferred Location</div>
          </div>
          <div class="row" v-if="condition.condition.uuid === '9ebf3698-2407-4d70-97f1-afa09adddac9'">
            <span class="title">Interaction</span>
            <div>{{ condition.interacted ? 'Has Interacted' : 'Has Not Interacted'}}</div>
          </div>
          <div class="row" v-if="condition.condition.uuid === '9ebf3698-2407-4d70-97f1-afa09adddac9' && condition.action">
            <span class="title">Action</span>
            <div>{{ condition.action }}</div>
          </div>
          <div class="row" v-if="condition.condition.uuid === '9ebf3698-2407-4d70-97f1-afa09adddac9' && condition.filtering === 'all'">
            <span class="title">Filtering</span>
            <div>All Emails</div>
          </div>
          <div class="row" v-if="condition.condition.uuid === '9ebf3698-2407-4d70-97f1-afa09adddac9' && condition.filtering === 'specific'">
            <span class="title">Filtered</span>
            <div>
              <div v-for="email in condition.emails">
                {{ email.name }}
              </div>
            </div>
          </div>
          <div class="row" v-if="condition.condition.uuid === 'ac08df3e-b14a-4760-8068-54585cb19d9e' && condition.forms">
            <span class="title">Forms</span>
            <div>
              <div v-for="form in condition.forms">
                {{ form.name }}
              </div>
            </div>
          </div>
          <div class="row" v-if="condition.condition.uuid === '7302c598-ab99-4091-9090-69850d4dc50e' && condition.forms">
            <span class="title">Forms</span>
            <div>
              <div v-for="form in condition.forms">
                {{ form.name }}
              </div>
            </div>
          </div>
          <div class="row" v-if="condition.condition.uuid === 'b4d57f9f-2515-42d3-8e17-4e48d106586e'">
            <span class="title">Field</span>
            <div>
              {{ condition.field.label }}
            </div>
          </div>
          <div class="row" v-if="condition.condition.uuid === 'b4d57f9f-2515-42d3-8e17-4e48d106586e'">
            <span class="title">Filtering</span>
            <div>
              {{ condition.option ? condition.option.replace('-', ' ') : '' }}
            </div>
          </div>
          <div class="row" v-if="condition.condition.uuid === 'b4d57f9f-2515-42d3-8e17-4e48d106586e'">
            <span class="title">Value</span>
            <div>
              <span v-if="condition.field.type === 'string'">{{ condition.value }}</span>
              <span v-if="condition.field.type === 'boolean'">{{ condition.value }}</span>
              <span v-if="condition.field.type === 'date'">{{ condition.value_date }}</span>
            </div>
          </div>
          <div class="row" v-if="condition.hasOwnProperty('payment_options') && condition.payment_options.length">
            <span class="title">Payment Options</span>
            <div>
              <div v-for="po in condition.payment_options">{{ po }}</div>
            </div>
          </div>
        </div>
        <div class="and-link" v-if="rule.length > 1">AND</div>
      </div>
      <div class="see-more" v-if="rule.length > 1" @click="expanded = !expanded">
        <div class="gradient" v-if="!expanded"></div>
        <div class="see-more-inner">
          <span v-if="!expanded">Show more (total: {{ rule.length }})</span>
          <span v-if="expanded">Hide</span>
          <span class="material-icons" v-if="!expanded">keyboard_arrow_down</span>
          <span class="material-icons" v-if="expanded">keyboard_arrow_up</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import moment from 'moment';

  export default {
    props: ['rule', 'enrollment'],
    data() {
      return {
        expanded: false,
      };
    },
    filters: {
      timeFrame(value) {
        if (value === 'all') {
          return 'All Time';
        }

        if (value === 'entering_campaign') {
          return 'Since entering this campaign';
        }

        if (value === 'relative_future') {
          return 'From now';
        }

        return value;
      },
      countComponent(filter, boundOne, boundTwo, label) {
        if (filter === 'atLeast') {
          return `At least ${boundOne} ${label}`;
        }

        if (filter === 'lessThan') {
          return `Less than ${boundOne} ${label}`;
        }

        if (filter === 'exactly') {
          return `Exactly ${boundOne} ${label}`;
        }

        if (filter === 'between') {
          return `Between ${boundOne} and ${boundTwo} ${label}`;
        }

        return boundTwo;
      },
      timeComponent(option, value, hasSinceEntering) {
        if (option === 'withinTheNext') {
          return `Within the next ${value} days`;
        }

        if (option === 'atLeastFuture') {
          return `At least ${value} days in the future`;
        }

        if (option === 'exactlyFuture') {
          return `Exactly ${value} days in the future`;
        }

        if (option === 'sinceEntering' && hasSinceEntering) {
          return 'Since entering campaign';
        }

        if (option === 'withinTheLast') {
          return `Within the last ${value} days`;
        }

        if (option === 'atLeastPast') {
          return `At least ${value} days in the past`;
        }

        if (option === 'exactlyPast') {
          return `Exactly ${value} days in the past`;
        }

        if (option === 'before') {
          return `Before ${moment(value).format('MMM Do YYYY')}`;
        }

        if (option === 'after') {
          return `After ${moment(value).format('MMM Do YYYY')}`;
        }

        if (option === 'specificDate') {
          return `On ${moment(value).format('MMM Do YYYY')}`;
        }

        if (option === 'allTime' || (option === 'sinceEntering' && !hasSinceEntering)) {
          return 'All Time';
        }

        if (option === 'between') {
          return `From ${moment(value[0]).format('MMM Do YYYY')} to ${moment(value[1]).format('MMM Do YYYY')}`;
        }
        return option;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .conditions-container {
    height: 8rem;
    overflow: hidden;
  }

  .conditions-container.expanded {
    height: auto;
    overflow: auto;
  }

  .conditions .name img {
    filter: grayscale(100%);
    opacity: .6;
    width: 1rem;
    height: 1rem;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: .5rem;
  }

  .conditions .name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: .875rem;
    color: #1d364b;
    line-height: 1rem;
    margin-bottom: .5rem;
  }

  .conditions .details {
    padding-left: 1.5rem;
  }

  .conditions .details {
    text-transform: capitalize;
    color: #8b9bac;
    font-size: .75rem;
    line-height: 1rem;
  }

  .conditions .details .title {
    font-weight: 700;
    color: #617080;
    min-width: 6rem;
    width: 18rem;
    margin-right: .5rem;
  }

  .conditions .details .disable-caps {
    text-transform: none;
  }

  .conditions .details .row {
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 1.25rem;
    margin-bottom: .25rem;
  }

  .conditions .details .row:last-child {
    margin-bottom: 0;
  }

  .conditions-container .conditions .and-link {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: .625rem;
    color: #8B9BAC;
  }

  .conditions-container .conditions:nth-last-child(2) .and-link {
    display: none;
  }

  .conditions-container .see-more {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    font-size: .75rem;
    padding-left: 1.5rem;
    font-weight: 500;
    color: var(--xpl-color-secondary-lm);
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90%;
  }

  .conditions-container .see-more .material-icons {
    font-size: .75rem;
  }

  .conditions-container.expanded .see-more {
    padding-left: 0;
    position: relative;
  }

  .conditions-container .see-more-inner {
    display: flex;
    align-items: center;
    padding-top: .5rem;
    padding-bottom: 1rem;
    width: 90%;
    background-color: white;
  }

  .conditions-container.expanded .see-more-inner {
    padding-left: 0;
    padding-bottom: 0;
  }

  .conditions-container .gradient {
    height: 2rem;
    width: 90%;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
</style>
