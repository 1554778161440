<template>
  <div class="overlay">
    <div class="header" v-if="!show_preview">
      <div>
        <a href="#" class="go-back" @click.stop="closePreview">
          <span class="icon icon-arrow-left3"></span>
          Go Back
        </a>
      </div>
      <button type="button" class="xpl-button xpl-button--primary" @click.stop="selectTemplate">Use This Template</button>
    </div>
    <div class="preview-container">
      <div class="preview" v-if="template">
        <h1>{{ template.name }}</h1>
        <div class="description" v-html="template.description"></div>

        <hr>

        <div class="plan-items">
          <div class="plan-item" v-for="item in template.items">
            <div :class="['card', item.type === 'email' ? 'hoverable' : '']">
              <div :class="['card-body', item.type === 'email' ? 'email' : 'task']">
                <div>
                  <h6>{{ item.name }}</h6>
                  <div class="small text-muted">{{ Math.abs(item.relative_days)  }} days {{ item.relative_days < 0 ? 'before' : 'after' }} <span class="text-lowercase">{{ template.date_string }}</span></div>
                </div>
                <div v-if="item.type === 'email'">
                  <button type="button" class="xpl-button xpl-button--secondary" @click="show_preview = true; templateEmail = item.email;">Preview</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ScheduledPlanTemplateEmailPreview v-if="show_preview && templateEmail" :template="template" :templateEmail="templateEmail" />
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import ScheduledPlanTemplateEmailPreview from './ScheduledPlanTemplateEmailPreview';

  export default {
    props: ['campaignTemplateId'],
    components: {
      ScheduledPlanTemplateEmailPreview,
    },
    data() {
      return {
        show_preview: false,
        template: null,
        templateEmail: null,
      };
    },
    mounted() {
      EventBus.$on('campaign_template.email.preview.close', () => {
        this.show_preview = false;
        this.templateEmail = null;
      });
    },
    created() {
      api.getScheduledPlanTemplate(this.campaignTemplateId).then((response) => {
        this.template = response.scheduled_plan_template;
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    beforeDestroy() {
      EventBus.$off('campaign_template.email.preview.close');
    },
    methods: {
      selectTemplate() {
        EventBus.$emit('campaign_template.email.preview.select', this.template);
      },
      closePreview() {
        EventBus.$emit('campaign_template.preview.close');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #f3f7f9;
    z-index: 10000;
    overflow: hidden;
  }

  .header {
    height: 3rem;
    background-color: #ffffff;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #cdd7e2;
  }

  .preview-container {
    overflow-y: scroll;
    height: 100vh;
  }

  .preview {
    padding-top: 2rem;
    width: 100%;
    height: 100vh;
    max-width: 56rem;
    margin: auto;
  }

  .preview h1 {
    font-size: 1.25rem;
    line-height: 1.4;
    color: rgb(46, 68, 87);
    margin-bottom: .875rem;
  }

  .preview .description {
    padding-top: .875rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: .875rem;
    background-color: rgb(255, 255, 255);
    // box-shadow: 0 1px 0 0 rgba(217, 231, 240, 0.5);
    border: solid 1px rgb(217, 231, 240);
    font-size: .875rem;
    line-height: 1.43;
    color: rgb(97, 112, 128);
  }

  .preview hr {
    border-color: rgb(217, 231, 240);
    margin-top: 2.5rem;
    margin-bottom: 2rem;
  }

  .plan-items {
    width: 100%;
    margin: auto;
    max-width: 56rem;
    padding-bottom: 5rem;
  }

  .plan-item .card {
    margin-bottom: 1rem;
  }

  .plan-item .card.hoverable {
    cursor: pointer;
  }

  .plan-item .card .card-body {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .plan-item .card .card-body h6 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: rgb(76, 79, 82);
    margin-bottom: 0;
  }
</style>
