<template>
  <div>
    <div class="tabs">
      <a href="#" :class="{ active: sidebar_tabs.build }" @click.prevent="toggleSidebarTab('build')">Build</a>
      <a href="#" :class="{ active: sidebar_tabs.style }" @click.prevent="toggleSidebarTab('style')">Style</a>
    </div>
    <div class="build_tab" v-show="sidebar_tabs.build">
      <textarea ref="content"></textarea>
      <div class="banner">Available Personalizations</div>
      <div class="personalizations-container">
        <div class="personalizations" v-if="$store.state.user.current_account.active_vendors.indexOf('pike13') > -1">
          <div class="personalization">
            First Name
            <a href="#" @click.prevent="copyPersonalization('first_name', 'there')">Copy</a>
          </div>
          <div class="personalization">
            Last Name
            <a href="#" @click.prevent="copyPersonalization('last_name', 'there')">Copy</a>
          </div>
          <div class="personalization" v-if="$store.state.user.current_account.franchise || $store.state.user.current_account.family" v-for="merge_tag in $store.state.user.current_account.merge_tags">
            {{ merge_tag.label }}
            <a href="#" @click.prevent="copyPersonalization(merge_tag.identifier, null)">Copy</a>
          </div>
        </div>
        <div class="personalizations" v-if="$store.state.user.current_account.active_vendors.indexOf('pike13') === -1">
          <div class="personalization">
            First Name
            <a href="#" @click.prevent="copyPersonalization('first_name', 'there')">Copy</a>
          </div>
          <div class="personalization">
            Last Name
            <a href="#" @click.prevent="copyPersonalization('last_name', 'there')">Copy</a>
          </div>
          <div class="personalization">
            Last Instructor
            <a href="#" @click.prevent="copyPersonalization('last_instructor')">Copy</a>
          </div>
          <div class="personalization">
            Last visit date (e.g. May 7th)
            <a href="#" @click.prevent="copyPersonalization('last_visit_at')">Copy</a>
          </div>
          <div class="personalization" v-if="$store.state.user.current_account.active_vendors && $store.state.user.current_account.active_vendors.indexOf('pike13') > -1">
            Next Future Visit (e.g. Monday, June 8th)
            <a href="#" @click.prevent="copyPersonalization('direct_future_reservation', 'soon')">Copy</a>
          </div>
          <div class="personalization" v-if="$store.state.user.current_account.franchise || $store.state.user.current_account.family" v-for="merge_tag in $store.state.user.current_account.merge_tags">
            {{ merge_tag.label }}
            <a href="#" @click.prevent="copyPersonalization(merge_tag.identifier, null)">Copy</a>
          </div>
        </div>
      </div>
    </div>
    <div class="style_tab" v-show="sidebar_tabs.style">
      <div class="banner">Text Styling</div>
      <form @submit.prevent>
        <div class="form-group" v-if="!$store.state.user.current_account.brand_restriction">
          <label>Font Family</label>
          <Multiselect v-model="font_family" placeholder="Change Font Family" :options="available_fonts" :searchable="true" label="label" track-by="value" :allow-empty="false" :show-labels="false" @input="toggleFontFamily">
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <div class="option__title">{{ props.option.label }}</div>
                <span class="option__small small">{{ props.option.safe ? 'Web Safe' : 'Custom' }}</span>
              </div>
            </template>
          </Multiselect>
        </div>

        <div class="group" v-if="!$store.state.user.current_account.brand_restriction">
          <div class="form-group">
            <label>Font Size</label>
            <Multiselect v-model="currentElement.attributes['font-size']" placeholder="Font Size" :options="['9px', '10px', '11px', '12px', '13px', '14px', '16px', '18px', '24px', '30px', '36px', '48px', '60px', '72px']" :allow-empty="false" :show-labels="false" @input="attributeEdited" @blur="$v.currentElement.attributes['font-size'].$touch()" :taggable="true" @tag="addFontSize" tag-placeholder="" />
          </div>
          <div class="form-group">
            <label>Line Height</label>
            <input type="number" v-model.number="currentElement.attributes['line-height']" name="line_height" :class="{ 'form-control': true, 'is-invalid': $v.currentElement.attributes['line-height'].$invalid }" min="1" @input="attributeEdited" @blur="$v.currentElement.attributes['line-height'].$touch()" />
          </div>
          <div class="form-group">
            <label>Letter Spacing</label>
            <Multiselect v-model="currentElement.attributes['letter-spacing']" placeholder="Letter Spacing" :options="['0px', '0.5px', '1px', '2px', '3px']" :allow-empty="false" :show-labels="false" @input="attributeEdited" @blur="$v.currentElement.attributes['letter-spacing'].$touch()" />
          </div>
        </div>

        <div class="form-group">
          <label>Alignment</label>
          <div class="alignments">
            <div :class="{ alignment: true, selected: currentElement.attributes.align === 'left' }" @click="currentElement.attributes.align = 'left'; attributeEdited();">
              <span class="material-icons">format_align_left</span>
            </div>
            <div :class="{ alignment: true, selected: currentElement.attributes.align === 'center' }" @click="currentElement.attributes.align = 'center'; attributeEdited();">
              <span class="material-icons">format_align_center</span>
            </div>
            <div :class="{ alignment: true, selected: currentElement.attributes.align === 'right' }" @click="currentElement.attributes.align = 'right'; attributeEdited();">
              <span class="material-icons">format_align_right</span>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>Font Style</label>
          <div class="styles">
            <div :class="{ style: true, selected: currentElement.attributes['font-weight'] === 'bold' }" @click="toggleFontWeight">
              <span class="material-icons">format_bold</span>
            </div>
            <div :class="{ style: true, selected: currentElement.attributes['font-style'] === 'italic' }" @click="toggleFontStyle">
              <span class="material-icons">format_italic</span>
            </div>
          </div>
        </div>

        <div class="form-group" v-if="!$store.state.user.current_account.brand_restriction">
          <label>Font Color</label>
          <div class="input-group">
            <span class="input-group-addon">
              <span class="icon icon-square" :style="'color: ' + font_color.hex" @click="show_picker = !show_picker"></span>
            </span>
            <input type="text" ref="font_color" v-model="font_color.hex" class="form-control" readonly />
          </div>
          <Sketch v-model="font_color" :disableAlpha="true" @input="fontColorEdited" v-if="show_picker" />
        </div>
      </form>

      <div class="banner">Container Styling</div>

      <form @submit.prevent>
        <div class="group">
          <div class="form-group">
            <label>Spacing Top</label>
            <input type="number" ref="spacing_top" :class="{ 'form-control': true, 'is-invalid': $refs.spacing_top && !$refs.spacing_top.value }" min="0" @input="spacingTopEdited($event.target.value)" @blur="$v.currentElement.attributes['padding-top'].$touch()" />
          </div>

          <div class="form-group">
            <label>Spacing Bottom</label>
            <input type="number" ref="spacing_bottom" :class="{ 'form-control': true, 'is-invalid': $refs.spacing_bottom && !$refs.spacing_bottom.value }" min="0" @input="spacingBottomEdited($event.target.value)" @blur="$v.currentElement.attributes['padding-bottom'].$touch()" />
          </div>
        </div>

        <div class="form-group">
          <label>Horizontal Spacing</label>
          <input type="number" ref="horizontal_spacing" :class="{ 'form-control': true, 'is-invalid': $refs.horizontal_spacing && !$refs.horizontal_spacing.value }" min="0" @input="horizontalSpacingEdited($event.target.value)" @blur="$v.currentElement.attributes['padding-left'].$touch()" />
        </div>

        <div class="form-group" v-if="!$store.state.user.current_account.brand_restriction">
          <label>Background Color</label>
          <div class="input-group">
            <span class="input-group-addon">
              <span class="icon icon-square" :style="'color: ' + background_color.hex" @click="show_picker_bg = !show_picker_bg"></span>
            </span>
            <input type="text" ref="background-color" v-model="background_color.hex" class="form-control" readonly />
          </div>
          <Sketch v-model="background_color" :disableAlpha="true" @input="backgroundColorEdited" v-if="show_picker_bg" />
        </div>
      </form>
    </div>

    <div class="footer">
      <button type="button" class="xpl-button xpl-button--primary" @click="saveAndCloseBlock">Save and close</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  // eslint-disable-next-line
  import tinymce from 'tinymce/tinymce';
  import Multiselect from 'vue-multiselect';
  import _ from 'lodash';
  import { Sketch } from 'vue-color';
  import { required } from 'vuelidate/lib/validators';
  import 'tinymce/themes/modern';
  import 'tinymce/plugins/link';
  import 'tinymce/plugins/autoresize';
  import 'tinymce/plugins/textcolor';
  import 'tinymce/plugins/colorpicker';
  import 'tinymce/plugins/paste';
  import 'tinymce/plugins/advlist';
  import 'tinymce/plugins/lists';
  import Fonts from './fonts.json';

  export default {
    props: ['currentElement'],
    components: {
      Multiselect,
      Sketch,
    },
    data() {
      return {
        show_picker: false,
        show_picker_bg: false,
        font_color: {
          hex: this.currentElement.attributes.color,
        },
        background_color: {
          hex: this.currentElement.attributes['background-color'],
        },
        font_family: this.currentElement.attributes['font-family'],
        sidebar_tabs: {
          build: true,
          style: false,
        },
        available_fonts: Fonts,
      };
    },
    validations: {
      font_family: {
        required,
      },
      currentElement: {
        attributes: {
          'font-size': {
            required,
          },
          'line-height': {
            required,
          },
          'letter-spacing': {
            required,
          },
          'padding-top': {
            required,
          },
          'padding-bottom': {
            required,
          },
          'padding-left': {
            required,
          },
        },
      },
      font_color: {
        hex: {
          required,
        },
      },
    },
    mounted() {
      this.initTinyMCE();

      this.font_family = this.available_fonts.filter(f => f.value === this.currentElement.attributes['font-family'])[0];
      this.font_color.hex = this.currentElement.attributes.color;
      this.background_color.hex = this.currentElement.attributes['container-background-color'];
      this.$refs.spacing_top.value = this.currentElement.attributes['padding-top'] ? this.currentElement.attributes['padding-top'].replace('px', '') : null;
      this.$refs.spacing_bottom.value = this.currentElement.attributes['padding-bottom'] ? this.currentElement.attributes['padding-bottom'].replace('px', '') : null;
      this.$refs.horizontal_spacing.value = this.currentElement.attributes['padding-left'] ? this.currentElement.attributes['padding-left'].replace('px', '') : null;
    },
    beforeDestroy() {
      window.tinymce.EditorManager.execCommand('mceRemoveEditor', true, this.$refs.content);
    },
    watch: {
      currentElement(newVal) {
        // Refresh content of TinyMCE if element has changed entirely.
        window.tinymce.activeEditor.setContent(this.currentElement.content);

        // Some values need to be updates manually because they are computed
        this.font_family = this.available_fonts.filter(f => f.value === newVal.attributes['font-family'])[0];
        this.currentElement.attributes['font-weight'] = newVal.attributes['font-weight'];
        this.currentElement.attributes['font-style'] = newVal.attributes['font-style'];
        this.font_color.hex = newVal.attributes.color;
        this.background_color.hex = newVal.attributes['container-background-color'];

        if (this.$refs.spacing_top && this.$refs.spacing_bottom && this.$refs.horizontal_spacing) {
          this.$refs.spacing_top.value = newVal.attributes['padding-top'] ? newVal.attributes['padding-top'].replace('px', '') : null;
          this.$refs.spacing_bottom.value = newVal.attributes['padding-bottom'] ? newVal.attributes['padding-bottom'].replace('px', '') : null;
          this.$refs.horizontal_spacing.value = newVal.attributes['padding-left'] ? newVal.attributes['padding-left'].replace('px', '') : null;
        }
      },
    },
    methods: {
      copyPersonalization(personalization, fallback = 'Fallback') {
        let personalizationString = `[%${personalization} | ${fallback}%]`;
        if (!fallback || !fallback.length) {
          personalizationString = `[%${personalization}%]`;
        }
        this.$copyText(`${personalizationString}`).then(() => {
          this.$toasted.success('Copied!');
        }, () => {
          this.$toasted.error('Something wrong happened while trying to copy...');
        });
      },
      saveAndCloseBlock() {
        EventBus.$emit('editor.close');
      },
      attributeEdited: _.debounce(() => {
        EventBus.$emit('email.edit.attributes');
      }, 300,
      ),
      toggleFontStyle() {
        if (this.currentElement.attributes['font-style'] === 'italic') {
          this.currentElement.attributes['font-style'] = null;
        } else {
          this.currentElement.attributes['font-style'] = 'italic';
        }

        this.attributeEdited();
      },
      toggleFontWeight() {
        if (this.currentElement.attributes['font-weight'] === 'bold') {
          this.currentElement.attributes['font-weight'] = null;
        } else {
          this.currentElement.attributes['font-weight'] = 'bold';
        }

        this.attributeEdited();
      },
      toggleFontFamily(selectedOption) {
        if (selectedOption.safe) {
          this.currentElement.attributes['font-family'] = selectedOption.value;
        } else {
          this.currentElement.attributes['font-family'] = selectedOption.value;
          const payload = {
            type: 'fonts',
            name: selectedOption.label,
            url: `https://fonts.googleapis.com/css?family=${selectedOption.label.replace(' ', '+')}`,
          };
          EventBus.$emit('email.edit.fonts', payload);
        }

        this.attributeEdited();
      },
      spacingTopEdited: _.debounce(function customFunction(value) {
        this.currentElement.attributes['padding-top'] = `${value}px`;
        this.attributeEdited();
      }, 300,
      ),
      spacingBottomEdited: _.debounce(function customFunction(value) {
        this.currentElement.attributes['padding-bottom'] = `${value}px`;
        this.attributeEdited();
      }, 300,
      ),
      horizontalSpacingEdited: _.debounce(function customFunction(value) {
        this.currentElement.attributes['padding-right'] = `${value}px`;
        this.currentElement.attributes['padding-left'] = `${value}px`;

        this.attributeEdited();
      }, 300,
      ),
      addFontSize: _.debounce(function customFunction(size) {
        if (size.replace(' ', '').indexOf('px') > -1) {
          this.currentElement.attributes['font-size'] = size;
        } else {
          if (isNaN(Number(size))) {
            return;
          }

          this.currentElement.attributes['font-size'] = `${Number(size)}px`;
        }

        this.attributeEdited();
      }, 300,
      ),
      fontColorEdited: _.debounce(function customFunction(data) {
        this.currentElement.attributes.color = data.hex;
        this.attributeEdited();
      }, 300,
      ),
      backgroundColorEdited: _.debounce(function customFunction(data) {
        this.currentElement.attributes['container-background-color'] = data.hex;
        this.attributeEdited();
      }, 300,
      ),
      toggleSidebarTab(tab) {
        this.sidebar_tabs.build = false;
        this.sidebar_tabs.style = false;

        this.sidebar_tabs[tab] = true;
      },
      initTinyMCE() {
        let toolbar = 'bold italic underline removeformat | numlist bullist | link | forecolor';
        if (this.$store.state.user.current_account.brand_restriction) {
          toolbar = 'bold italic underline removeformat | numlist bullist | link';
        }
        window.tinymce.init({
          menubar: false,
          toolbar,
          target: this.$refs.content,
          branding: false,
          browser_spellcheck: true,
          theme: 'modern',
          skin_url: '/static/skins/lightgray',
          plugins: ['link', 'autoresize', 'textcolor', 'colorpicker', 'paste', 'advlist', 'lists'],

          // Link plugin options
          default_link_target: '_blank',
          link_title: false,
          target_list: false,

          autoresize_bottom_margin: 8,
          paste_as_text: true,
          statusbar: false,
          min_height: 1000,
          // Remove <p> tags
          forced_root_block: false,
          init_instance_callback: (editor) => {
            editor.on('KeyUp', () => {
              this.currentElement.content = editor.getContent();

              this.attributeEdited();
            });
            editor.on('Change', () => {
              this.currentElement.content = editor.getContent();

              this.attributeEdited();
            });

            editor.setContent(this.currentElement.content);
          },
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .vc-sketch {
    position: absolute;
    z-index: 100;
  }

  .footer {
    position: fixed;
    display: flex;
    align-items: center;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgb(205, 215, 226);
    padding-top: .75rem;
    padding-bottom: .75rem;
    padding-left: 1.5rem;
    background-color: white;
    z-index: 1001;
  }

  .footer button {
    margin-right: .5rem;
  }

  .tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tabs a {
    width: 50%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: rgb(29, 54, 75);
    font-size: .875rem;
    text-align: center;
    border-bottom: 2px solid rgb(217, 231, 240);
    transition: all 150ms ease-in-out;
  }

  .tabs a:hover,
  .tabs a.active {
    text-decoration: none;
    border-bottom: 2px solid var(--xpl-color-secondary-lm);
  }

  .banner {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1.5rem;
    background-color: var(--xpl-color-background-lm);
    border-top: 1px solid #d9e7f0;
    border-bottom: 1px solid #d9e7f0;
    font-size: .75rem;
    line-height: 1rem;
    color: rgb(139, 155, 172);
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  form {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  label {
    font-size: .875rem;
    line-height: 20px;
    color: rgb(29, 54, 75);
    margin-bottom: .25rem;
  }

  .alignments,
  .styles {
    display: flex;
    align-items: center;
  }

  .alignments .alignment,
  .styles .style {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.25rem;
    height: 2.5rem;
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    border: 1px solid  rgb(175, 188, 202);
    margin-right: .25rem;
    transition: all 150ms ease-in-out;
    color: #617080;
  }

  .alignments .alignment:last-child,
  .styles .style:last-child {
    margin-right: 0;
  }

  .alignments .alignment:hover,
  .alignments .alignment.selected,
  .styles .style:hover,
  .styles .style.selected {
    border: 2px solid var(--xpl-color-secondary-lm);
    color: var(--xpl-color-secondary-lm);
  }

  .styles .style {
    font-size: .75rem;
  }

  .group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .group .form-group {
    margin-right: .5rem;
  }

  .group .form-group:last-child {
    margin-right: 0;
  }

  .input-group {
    z-index: 0;
  }

  .input-group-addon {
    padding: .25rem .5rem;
    padding-right: 0;
  }

  .input-group .form-control {
    z-index: 1 !important;
  }

  .icon-square {
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 1.5rem;
    cursor: pointer;
  }

  input[readonly] {
    background-color: white;
  }

  .build_tab,
  .style_tab {
    padding-bottom: 4rem;
  }

  .build_tab textarea {
    height: 15rem;
    min-height: 15rem;
  }

  .personalizations-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .personalizations {
    margin-top: .875rem;
  }

  .personalization {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: .875rem;
    color: rgb(29, 54, 75);
    line-height: 1.25rem;
    margin-top: 1rem;
  }

  .personalizations .personalization:first-child {
    margin-top: 0;
  }

  .personalization a {
    color: rgb(46, 68, 87);
    text-decoration: underline;
  }

  .personalization a:hover {
    text-decoration: none;
  }
</style>
