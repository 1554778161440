<template>
  <div class="landing">
    <el-card :shadow="'never'">
      <el-row>
        <el-col :span="12">
          <img :src="$store.getters.brandLogo" :alt="$store.getters.brandName" class="logo" />
        </el-col>
        <el-col :span="12">
          <img src="https://assets.brandbot.com/public/partners/pike13.svg" alt="Pike13" />
        </el-col>
      </el-row>
      <h1>All-in-one marketing and sales for Pike13 customers</h1>
      <p>Get started with {{$store.getters.brandName}}’s tools like email marketing, two-way texting, automations, and sales management. The best part? Everything is powered by your Pike13 data.</p>
      <el-button @click.stop="pikeAuth" :loading="loading">Get Started for Free Today</el-button>
      <div class="link">
        Already have an account? <a href="/login">Login</a>.
      </div>
    </el-card>
  </div>
</template>
<script>
  import * as api from '@/api';

  export default {
    data() {
      return {
        loading: false,
        redirect_url: 'https://clients.brandbot.com/signup/pike13',
        client_id: '3Sl5D0eqK29Gf7URsU2qTGNaO3ccOuBiGP3w31Fg',
      };
    },
    created() {
      localStorage.setItem('brandbotSelectedVendor', 'pike');

      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        this.loading = true;
        this.saveToken(code);
      }
    },
    methods: {
      saveToken(code) {
        const payload = {
          code,
        };

        api.pikeTokenPublic(payload).then((response) => {
          this.loading = false;
          this.$message.success(response.message);
          localStorage.setItem('access_token', response.tokens.access_token);
          localStorage.setItem('refresh_token', response.tokens.refresh_token);
          if (response.pike_token) {
            localStorage.setItem('pikeToken', true);
          }
          this.$store.dispatch('refreshUser').then(() => {
            this.$nextTick(() => {
              window.location.href = '/signup';
            });
          });
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      pikeAuth() {
        this.loading = true;
        location.href = `https://pike13.com/oauth/authorize?response_type=code&redirect_uri=${this.redirect_url}&client_id=${this.client_id}`;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .landing {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: white;

    .logo {
      width: 42px;
      height: auto;
      max-height: 42px;
    }
  }

  .el-card {
    max-width: 500px;
    width: 500px;
    min-width: 500px;
    border: none;
  }

  .el-card .el-row {
    margin-bottom: 2.5rem;
  }

  .el-card .el-row img {
    max-width: 175px;
    display: block;
  }

  .el-card h1 {
    font-size: 1.75rem;
    color: #00152a;
    margin-bottom: 1rem;
  }

  .el-card .el-button {
    background-color: #81bc00;
    border-color: #81bc00;
    color: white;
  }

  .el-card p {
    font-size: .875rem;
    line-height: 1.71;
    color: #00152a;
  }

  .link {
    font-size: .75rem;
    margin-top: .875rem;
  }

  .link a {
    color: var(--xpl-color-secondary-lm);
  }
</style>
