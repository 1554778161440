<template>
  <el-container class="condition-container">
    <div class="sidebar-inner" style="width: 100%">
      <TimeFrameField
        fieldIndex="0"
        title="When Was The Membership Cancelled?"
        fieldStep="1"
        :selectedOption.sync="cancellationTimeFrameComparison"
        :valueOne.sync="cancellationTimeFrameValue"
        pastLabel="days ago"
        :hidePast="false"
        :hideFuture="true"
        :hideTimeType="false"
        :goalList="goalList"
        id="22"
      />
      <BooleanField
        fieldIndex="1"
        title="Which Memberships?"
        fieldStep="2"
        yesText="All memberships"
        noText="Specific memberships"
        :selection.sync="selectionMembershipType"
        :showLabel="true"
        descLabel="Options include type, status and start date"
        id="23"
      />
      <div v-if="!selectionMembershipType" class="condition-banner-style">
        Drill Down Options
      </div>
      <MultiSelectField
        v-if="!selectionMembershipType"
        fieldIndex="2"
        title="Which Membership Type?"
        fieldStep="3"
        anyLabel="Any membership type"
        specificLabel="Specific membership type(s)"
        specificAnyLabel="Any of the following membership type"
        specificExclusionLabel="Any except the following"
        :selectedOptions.sync="selectedMembershipTypes"
        :specificChoice.sync="membershipType"
        :exclusionChoice.sync="membershipTypeExclusionOption"
        :options="membershipTypes"
        selectKey="id"
        id="452"
      />
      <MultiSelectField
        v-if="!selectionMembershipType"
        fieldIndex="3"
        title="Which Membership Status?"
        fieldStep="4"
        anyLabel="Any membership status"
        specificLabel="Specific membership status(es)"
        specificAnyLabel="Any of the following membership statuses"
        specificExclusionLabel="Any except the following"
        :selectedOptions.sync="selectedMembershipStatuses"
        :specificChoice.sync="membershipStatusType"
        :exclusionChoice.sync="membershipStatusExclusionOption"
        :options="membershipStatuses"
        selectKey="id"
        id="455"
      />
      <TimeFrameField
        v-if="!selectionMembershipType"
        fieldIndex="4"
        title="When Did They Start Their Membership?"
        fieldStep="5"
        :selectedOption.sync="membershipStartTimeFrameComparison"
        :valueOne.sync="membershipStartTimeFrameValue"
        pastLabel="days ago"
        :hidePast="false"
        :hideFuture="true"
        :hideTimeType="false"
        :goalList="goalList"
        id="27"
      />
    </div>
    <div class="save-condition-container" v-if="showSaveButton">
      <el-button
        class="save-condition-btn"
        type="primary"
        @click="commitCondition"
        >Save</el-button
      >
    </div>
  </el-container>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import BooleanField from '@/components/Contacts/ContactLists/Conditions/Fields/BooleanField';
  import TimeFrameField from '@/components/Contacts/ContactLists/Conditions/Fields/TimeFrameField';
  import MultiSelectField from '@/components/Contacts/ContactLists/Conditions/Fields/MultiSelectField';

  export default {
    props: ['condition', 'editedRule', 'goalList'],
    components: {
      BooleanField,
      TimeFrameField,
      MultiSelectField,
    },
    data() {
      return {
        cancellationTimeFrameComparison: null,
        cancellationTimeFrameValue: 1,
        selectionMembershipType: true,
        membershipType: 'any',
        membershipTypeExclusionOption: 'any',
        selectedMembershipTypes: [],
        membershipTypes: [],
        expandedMembershipStatuses: false,
        membershipStatusType: 'any',
        membershipStatusExclusionOption: 'any',
        selectedMembershipStatuses: [],
        membershipStatuses: [],
        membershipStartTimeFrameComparison: null,
        membershipStartTimeFrameValue: 1,
      };
    },
    created() {
      this.fetchMemberships();
      this.fetchMembershipStatuses();

      if (this.editedRule) {
        this.cancellationTimeFrameComparison = this.editedRule.cancellation_comparison;
        this.cancellationTimeFrameValue = this.editedRule.cancellation_value;
        this.selectionMembershipType = this.editedRule.all_memberships;
        this.selectedMembershipTypes = this.editedRule.membership_types;
        this.membershipTypeExclusionOption = this.editedRule.membership_type_exclusion;
        this.membershipType = this.editedRule.membership_type_option;
        this.selectedMembershipStatuses = this.editedRule.membership_statuses;
        this.membershipStatusExclusionOption = this.editedRule.membership_status_exclusion;
        this.membershipStatusType = this.editedRule.membership_status_option;
        this.membershipStartTimeFrameComparison = this.editedRule.membership_start_comparison;
        this.membershipStartTimeFrameValue = this.editedRule.membership_start_value;
      }
    },
    computed: {
      showSaveButton() {
        if (!this.cancellationTimeFrameComparison) {
          return false;
        }
        if ((this.cancellationTimeFrameComparison === 'before' || this.cancellationTimeFrameComparison === 'after' || this.cancellationTimeFrameComparison === 'between' || this.cancellationTimeFrameComparison === 'specificDate') && Number.isInteger(this.cancellationTimeFrameValue)) {
          return false;
        }

        if (this.cancellationTimeFrameComparison === 'between' && !this.cancellationTimeFrameValue.length) {
          return false;
        }
        if (!this.selectionMembershipType) {
          if (this.membershipType === 'specific' && !this.selectedMembershipTypes.length) {
            return false;
          }

          if (this.membershipStatusType === 'specific' && !this.selectedMembershipStatuses.length) {
            return false;
          }

          if (!this.membershipStartTimeFrameComparison) {
            return false;
          }
          if ((this.membershipStartTimeFrameComparison === 'before' || this.membershipStartTimeFrameComparison === 'after' || this.timeframeComparison === 'between' || this.membershipStartTimeFrameComparison === 'specificDate') && Number.isInteger(this.membershipStartTimeFrameValue)) {
            return false;
          }

          if (this.membershipStartTimeFrameComparison === 'between' && !this.membershipStartTimeFrameValue.length) {
            return false;
          }
          return true;
        }
        return true;
      },
    },

    methods: {
      async fetchMemberships() {
        try {
          const response = await api.getMTMemberships();
          this.membershipTypes = response.memberships;
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      async fetchMembershipStatuses() {
        try {
          const response = await api.MTMembershipStatuses();
          this.membershipStatuses = response.membership_statuses;
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          cancellation_comparison: this.cancellationTimeFrameComparison,
          cancellation_value: this.cancellationTimeFrameValue,
          all_memberships: this.selectionMembershipType,
          membership_types: this.selectedMembershipTypes,
          membership_type_exclusion: this.membershipTypeExclusionOption,
          membership_type_option: this.membershipType,
          membership_statuses: this.selectedMembershipStatuses,
          membership_status_exclusion: this.membershipStatusExclusionOption,
          membership_status_option: this.membershipStatusType,
          membership_start_comparison: this.membershipStartTimeFrameComparison,
          membership_start_value: this.membershipStartTimeFrameValue,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
@import "src/scss/global/_spacing.scss";
@import "src/scss/global/_new_main.scss";
  .field-container {
  margin-top: 28px;
}
.option-body {
  margin-top: 20px;
}

.card-condition {
  background-color: #FFFFFF;
  border: solid 1px var(--xpl-color-gray-400);
  box-shadow: none;
  border-radius: 0;
  cursor: auto;
}

.card-condition:hover {
  background-color: #FFFFFF;
  cursor: auto;
}
.field-step {
  height: 1.5rem;
  width: 1.5rem;
  background-color: $bb-color-blue-v2;
  font-family: $primary-bb-font-family;
  font-size: $base-font-size;
  color: #FFFFFF;
  border-radius: 50%;
  text-align: center;
  margin: $space-base-inline;
}
.field-title {
  font-family: $primary-bb-font-family;
  font-weight: 700;
  font-size: $base-font-size;
  color: $base-color-black-v2;
  line-height: $loose-medium-line-height;
}

.radio-btn {
  margin-top: $space-extra-tight;
  margin-bottom: $space-extra-tight;
}

.field-label-text  {
  display: block;
}
.field-label-text /deep/ .el-radio__label {
  font-family: $primary-bb-font-family !important;
  font-size: $base-loose-font-size !important;
  color: $base-color-black-v2 !important;
  line-height: $loose-line-height !important;
  padding-left: 8px;
}
.field-label-text /deep/ .el-radio__inner {
  background-color: #F2F4F6 !important;
  border: 1px solid #A6B2BF !important;
  height: 16px;
  width: 16px;
}
.field-label-text /deep/  .el-radio__input.is-checked .el-radio__inner::after {
  background-color: var(--xpl-color-secondary-lm) !important;
  width: 12px;
  height: 12px;
}
.condition-container {
  background-color: #F4F8FA;
  height: 100%;
  width: 100%;
}

.field-container:last-of-type{
  padding-bottom: 100px;
}
.sidebar-inner {

  height: 100%;
  position: relative;
  padding-top: 0;
  background-color: #F4F8FA;
  padding-left: 2.625rem;
  padding-right: 2.25rem;
  padding-bottom: 0;
  width: 100%;
}
.save-condition-btn {
  width: 729px;
  margin: 16px 12px 16px 12px;
}
.save-condition-container {
  // box-shadow: 0 -6px 7px 0 rgba(0, 0, 0, 0.05);
  padding: 0;
  margin: 0;
  z-index: 1;
}
.condition-banner-style {
  background-color: #F4F8FA !important;
  margin-top: 13px !important;
  padding-bottom: 0;
}

</style>

