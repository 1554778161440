<template>
  <el-container>
    <el-aside class="threads-container" width="300px">
      <div class="filter">All Conversations</div>
      <div class="threads">
        <div :class="['thread', thread.contact_id === selectedThread.contact_id ? 'active' : '']" v-for="thread in threads" @click="selectThread(thread)" v-if="Object.keys(threads).length">
          <div class="unread" v-if="thread.unread">
            <span class="material-icons">fiber_manual_record</span>
          </div>
          <div class="thread-inner">
            <div class="header">
              <div class="contact" v-if="!thread.first_name">{{ thread.email }}</div>
              <div class="contact" v-else>{{ thread.first_name }} {{ thread.last_name }}</div>
              <div class="text-muted">{{ thread.created_at | humanDateMin }}</div>
            </div>
            <div class="body text-muted">{{ thread.body }}</div>
          </div>
        </div>
        <div v-if="!Object.keys(threads).length && !globalLoading">
          <img src="https://assets.brandbot.com/public/empty-states/no-sms.svg" class="img-fluid" />
          <div class="empty small">You have no open conversations!</div>
        </div>
      </div>
    </el-aside>
    <el-main v-loading="globalLoading || threadLoading">
      <div class="content">
        <div class="header" v-if="selectedThread">
          <a :href="'/contacts/' + selectedThread.uuid" v-if="!selectedThread.first_name">{{ selectedThread.email }}</a>
          <a :href="'/contacts/' + selectedThread.uuid" v-else>{{ selectedThread.first_name }} {{ selectedThread.last_name }}</a>
        </div>
        <div class="conversation" id="conversation" v-if="selectedThread">
          <div :class="['message', msg.hasOwnProperty('from') ? 'own' : 'account', specialKeywords.indexOf(msg.body.toUpperCase()) > -1 ? 'subscription' : '']" v-for="msg in conversation">
            <div class="message-inner" v-if="specialKeywords.indexOf(msg.body.toUpperCase()) === -1">
              {{ msg.body }}
            </div>
            <div class="message-footer" v-if="specialKeywords.indexOf(msg.body.toUpperCase()) === -1">
              <span v-if="msg.sent_user">
                Sent By {{ msg.sent_user.first_name ? msg.sent_user.first_name : msg.sent_user.email }}
                &vert;
              </span>
              <span v-if="msg.campaign">
                Sent By Auto-Campaign ({{ msg.campaign }})
                &vert;
              </span>
              <span v-if="msg.hasOwnProperty('from')">Received on {{ msg.created_at | humanDateTime }}</span>
              <span v-if="!msg.delivered_at && !msg.hasOwnProperty('from')">Sent on {{ msg.created_at | humanDateTime }}</span>
              <span v-if="msg.delivered_at">Delivered on {{ msg.delivered_at | humanDateTime }}</span>
            </div>
            <div class="message-subscription" v-if="stopKeywords.indexOf(msg.body.toUpperCase()) > -1">
              This contact has <strong>unsubscribed</strong> from your SMS on {{ msg.created_at | humanDateTime }}
            </div>
            <div class="message-subscription" v-if="startKeywords.indexOf(msg.body.toUpperCase()) > -1">
              This contact has <strong>subscribed</strong> back to your SMS on {{ msg.created_at | humanDateTime }}
            </div>
          </div>
        </div>
        <el-form class="composer" @submit.native.prevent v-if="selectedThread">
          <el-form-item>
            <textarea v-model="body" autocomplete="off" placeholder="SMS Message" rows="4"></textarea>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="sendMessage" :disabled="!body || body.length < 1" :loading="sendLoading">Send Message</el-button>
            <span class="counter" v-if="body">{{ body.length }} / 160</span>
          </el-form-item>
        </el-form>
      </div>
    </el-main>
  </el-container>
</template>
<script>
  import * as api from '@/api';

  export default {
    data() {
      return {
        threadLoading: false,
        globalLoading: false,
        sendLoading: false,
        body: null,
        threads: [],
        selectedThread: null,
        conversation: [],
        interval: null,
        loading: false,
        scrolled: false,
        stopKeywords: ['STOP', 'END', 'QUIT', 'CANCEL', 'UNSUBSCRIBE'],
        startKeywords: ['START'],
        specialKeywords: ['STOP', 'END', 'QUIT', 'CANCEL', 'UNSUBSCRIBE', 'START'],
      };
    },
    created() {
      this.fetchThreads();
    },
    methods: {
      sendMessage() {
        if (this.body.length < 1) {
          return;
        }

        const payload = {
          contact_id: this.selectedThread.contact_id,
          body: this.body,
        };

        this.sendLoading = true;
        api.sendContactMessage(payload).then((response) => {
          this.sendLoading = false;
          this.body = null;
          this.$message.success(response.message);
          this.scrolled = false;
          this.selectThread();
        }).catch((err) => {
          this.sendLoading = false;
          this.$message.error(err.message);
        });
      },
      fetchThreads() {
        this.globalLoading = true;
        this.body = null;
        api.getThreads().then((response) => {
          this.threads = response.threads;
          if (Object.keys(response.threads).length) {
            this.selectThread(response.threads[Object.keys(response.threads)[0]]);
          }
          this.globalLoading = false;
        }).catch((err) => {
          this.globalLoading = false;
          this.$toasted.error(err.message);
        });
      },
      selectThread(thread = null) {
        if (thread) {
          this.selectedThread = thread;
        }

        if (!this.selectedThread) {
          return true;
        }


        this.threadLoading = true;
        this.body = null;
        api.getContactMessages(this.selectedThread.contact_id).then((response) => {
          this.threadLoading = false;
          this.conversation = response.conversation;
          this.selectedThread.unread = false;
          this.$nextTick(() => {
            if (!this.scrolled) {
              const objDiv = document.getElementById('conversation');
              objDiv.scrollTop = objDiv.scrollHeight;
            }
          });
        }).catch((err) => {
          this.threadLoading = false;
          this.$message.error(err.message);
        });

        return true;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-main {
    padding: 0;
  }

  .threads {
    background-color: white;
    height: calc(100vh - 9.5rem);
    overflow-y: scroll;
  }

  .threads .thread {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    font-size: .75rem;
    border-bottom: 1px solid rgb(217, 231, 240);
    cursor: pointer;
    transition: all 150ms ease-in-out;
  }

  .threads .thread .thread-inner {
    width: 100%;
    overflow: hidden;
  }

  .threads .thread:hover,
  .threads .thread.active {
    background-color: rgba(180, 224, 250, 0.36);
  }

  .threads .thread .thread-inner > .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .25rem;
  }

  .threads .thread .contact {
    font-weight: bold;
    color: rgb(0, 21, 42);
  }

  .threads .thread:hover .contact,
  .threads .thread.active .contact {
    color: var(--xpl-color-secondary-lm);
  }

  .threads .thread .thread-inner .body {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .content {
    width: 100%;
    height: 100%;
    color: black;
  }

  .content > .header {
    background-color: white;
    border-bottom: 1px solid transparent;
    padding: 0;
    margin: 0;
  }

  .content > .header a {
    display: block;
    font-weight: 700;
    padding-left: 1rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: .875rem;
    color: var(--xpl-color-secondary-lm);
    text-decoration: underline;
    line-height: 1.4;
  }

  .conversation {
    height: calc(100vh - 9.5rem - 11.3125rem);
    max-height: calc(100vh - 9.5rem - 11.3125rem);
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    overflow-y: scroll;
  }

  .message {
    min-width: 29rem;
    max-width: 29rem;
    width: 29rem;
    margin-bottom: 1.5rem;
  }

  .message.subscription {
    max-width: none;
    min-width: none;
    width: 100%;
  }

  .message-inner {
    border-radius: .5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    font-size: .875rem;
  }

  .message.own .message-inner {
    background-color: rgb(255, 255, 255);
    color: rgb(46, 68, 87);
    border-bottom-left-radius: 0;
  }

  .message.account {
    margin-left: auto;
  }

  .message.account .message-footer {
    text-align: right;
  }

  .message.account .message-inner {
    background-color: #6923f4;
    color: rgb(255, 255, 255);
    border-bottom-right-radius: 0;
  }

  .message-footer {
    font-size: .75rem;
    color: rgb(139, 155, 172);
  }

  .composer {
    position: fixed;
    width: calc(100% - 32.5rem - 1rem - 1rem);
    bottom: 0;
    padding: 2rem;
    margin-left: 1rem;
    background-color: rgb(255, 255, 255);
    border: 1px solid var(--xpl-color-gray-400);
    margin-bottom: 0;
  }

  .composer .el-form-item {
    margin-bottom: 0;
  }

  textarea {
    outline: none;
    text-align: left;
    border: none;
    outline: none;
    line-height: 1.4;
    width: 100%;
    color: #00152a;
    overflow-wrap: break-word;
    resize: none;
    height: 60px;
  }

  .threads img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .threads .empty {
    text-align: center;
  }

  .thread .unread .material-icons {
    color: var(--xpl-color-secondary-lm);
    font-size: .5rem;
    margin-right: 1rem;
  }

  .message .message-subscription {
    font-size: .75rem;
    width: 100%;
    text-align: center;
    color: rgb(139, 155, 172);
  }

  .el-aside.threads-container {
    background-color: white;
  }

  .el-aside .filter {
    font-size: .875rem;
    line-height: 1.43;
    color: #00152a;
    padding-left: 1rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .counter {
    margin-left: .5rem;
    font-size: .75rem;
    color: #00152a;
  }
</style>
