<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Quick Send to one or more contacts
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <label>Choose your contacts</label>
                <Multiselect v-model="selected_contacts" :options="contacts" placeholder="Start typing to search" :searchable="true" :show-labels="false" :allow-empty="false" :internal-search="false" @search-change="searchContacts" label="email" id="id" :multiple="true" :loading="isLoading">
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <div>{{ props.option.email }}</div>
                      <div class="small">{{ props.option.fullname }}</div>
                    </div>
                  </template>
                </Multiselect>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="button" class="xpl-button xpl-button--primary" @click.stop="quickSend" :disabled="!selected_contacts.length">Send</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';
  import _ from 'lodash';

  export default {
    components: {
      Multiselect,
    },
    props: ['scheduledEmailId', 'campaignItemId'],
    data() {
      return {
        selected_contacts: [],
        contacts: [],
        isLoading: false,
      };
    },
    created() {
      this.fetchContacts();
    },
    methods: {
      closeModal() {
        EventBus.$emit('quick_send.close');
      },
      searchContacts: _.debounce(function debounce(query) {
        this.fetchContacts(query);
      }, 500),
      fetchContacts(query) {
        this.isLoading = true;
        api.getContacts('all', 0, '', query).then((response) => {
          this.contacts = response.contacts.data;
          this.isLoading = false;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      quickSend(event) {
        if (!this.selected_contacts.length) {
          return;
        }

        const target = event.srcElement || event.target;
        const originalInner = target.innerHTML;

        target.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';
        target.disabled = true;


        const payload = {
          contacts: this.selected_contacts.map(c => c.id),
        };

        if (this.scheduledEmailId) {
          payload.scheduled_email_id = this.scheduledEmailId;
        }

        if (this.campaignItemId) {
          payload.campaign_item_id = this.campaignItemId;
        }

        api.quickSendScheduledEmail(payload).then((response) => {
          this.$toasted.success(response.message);
          target.innerHTML = originalInner;
          this.closeModal();
        }).catch((err) => {
          target.innerHTML = originalInner;
          target.disabled = false;
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
  .modal .modal-content .modal-body {
    overflow-y: visible;
  }
</style>
