<template>
  <div class="intro">
    <h1>Let's setup your look</h1>
    <p>
      All of {{$store.getters.brandName}}’s emails are built with sections that automatically style themselves based on your brand.
      <br>
      This should only take 3 minutes.
    </p>
    <button class="xpl-button xpl-button--primary" @click="startBuilder">Let's get the brand back together</button>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    data() {
      return {};
    },
    methods: {
      startBuilder() {
        EventBus.$emit('brand_builder.move_to', 'logo');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .intro {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  h1 {
    margin-top: 2.25rem;
    margin-bottom: 0;
    font-size: 1.25rem;
    line-height: 1.75rem;
    text-align: center;
    color: var(--xpl-color-secondary-lm);
  }

  p {
    margin-top: 1.25rem;
    margin-bottom: 1.5rem;
    max-width: 23.4375rem;
    font-size: .875rem;
    font-weight: 700;
    text-align: center;
    color: rgb(139, 155, 172);
  }
</style>
