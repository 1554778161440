<template>
  <div class="condition">
    <div class="alert">
      <img src="https://assets.brandbot.com/public/campaigns/time-behavior.svg" />
      {{ campaignItem.type === 'email' || campaignItem.type === 'sms' ? 'Send' : 'Create' }} after a delay, but only if the contact meets an additional rule
    </div>
    <form @submit.prevent>
      <div class="form-group heading">
          <span v-if="campaignItem.name !== 'MT Action' && campaignItem.name !== 'Webhook'">
        {{ campaignItem.type === 'email' || campaignItem.type === 'sms' ? 'Send' : 'Create' }} this {{ campaignItem.type === 'phone_call' ? 'phone call reminder' : campaignItem.type }}
          </span>
          <span v-if="campaignItem.name === 'MT Action'">Create this {{ campaignItem.name === 'MT Action' ? 'Action' : 'Webhook' }}</span>
           <span v-if="campaignItem.name == 'Webhook'">Send this {{ campaignItem.name === 'MT Action' ? 'Action' : 'Webhook' }}</span>

        <input type="number" v-model="campaignItem.time_rule.relative_count" class="form-control" min="1" v-if="campaignItem.time_rule.relative !== 'Immediately'" />
        <Multiselect v-model="campaignItem.time_rule.relative" :options="['Hours', 'Days', 'Weeks', 'Immediately']" :searchable="false" :allow-empty="false" :show-labels="false" v-if="campaignItem.type === 'email' || campaignItem.type === 'sms'" style="min-width: 10rem; width: 10rem; max-width: 10rem;" />
        <Multiselect v-model="campaignItem.time_rule.relative" :options="['Days', 'Weeks', 'Immediately']" :searchable="false" :allow-empty="false" :show-labels="false" v-if="campaignItem.type === 'task' || campaignItem.type === 'phone_call' || campaignItem.type === 'tag'" style="min-width: 10rem; width: 10rem; max-width: 10rem;" />
        after entering this auto-campaign&nbsp;<u><mark>but only if</mark></u>:
      </div>

      <div class="options-container" v-if="!selectedOption">
        <div class="quick-options">
          <div class="title" v-if="$store.getters.isPike13">Quick Options</div>
          <div class="options" v-if="$store.getters.isPike13">
            <div class="option" @click.prevent="selectQuickOption('1e6a8fb2-8205-41fa-87a8-a46a0c2327dd')">
              <div class="icon">
                <img src="https://assets.brandbot.com/public/icons/conditions/82584fc8-ba77-4f57-982a-68f8a52828cb.svg" />
              </div>
              <div class="name">
                <div class="header">Number of Visits</div>
                <span>The contact has visited a certain amount of times since entering the automation</span>
              </div>
            </div>
            <div class="option" @click.prevent="selectQuickOption('2d5f2b5c-1620-451a-96fb-0f96bab671e0')">
              <div class="icon">
                <img src="https://assets.brandbot.com/public/icons/conditions/d323c020-0569-4f1e-ab1d-f56672d66610.svg" />
              </div>
              <div class="name">
                <div class="header">Has a Plan</div>
                <span>The contact has an active plan</span>
              </div>
            </div>
          </div>
          <div class="title" v-if="!($store.getters.isPike13 || $store.getters.isMT || $store.getters.isUIAVendor)">Quick Options</div>
          <div class="options" v-if="!($store.getters.isPike13 || $store.getters.isMT || $store.getters.isUIAVendor)">
            <div class="option" @click.prevent="selectQuickOption('82584fc8-ba77-4f57-982a-68f8a52828cb')">
              <div class="icon">
                <img src="https://assets.brandbot.com/public/icons/conditions/82584fc8-ba77-4f57-982a-68f8a52828cb.svg" />
              </div>
              <div class="name">
                <div class="header">Number of Visits</div>
                <span>The contact has visited a certain amount of times since entering the automation</span>
              </div>
            </div>
            <div class="option" @click.prevent="selectQuickOption('d323c020-0569-4f1e-ab1d-f56672d66610')">
              <div class="icon">
                <img src="https://assets.brandbot.com/public/icons/conditions/d323c020-0569-4f1e-ab1d-f56672d66610.svg" />
              </div>
              <div class="name">
                <div class="header">Is a Member</div>
                <span>The contact has an active membership type</span>
              </div>
            </div>
            <div class="option" @click.prevent="selectQuickOption('929b071b-bae1-47c9-92a4-ad80c9669e76')">
              <div class="icon">
                <img src="https://assets.brandbot.com/public/icons/conditions/929b071b-bae1-47c9-92a4-ad80c9669e76.svg" />
              </div>
              <div class="name">
                <div class="header">Not Tried a Service</div>
                <span>The contact has not visited a certain class/appointment type</span>
              </div>
            </div>
            <div class="option" @click.prevent="selectQuickOption('d2b29794-384f-4e75-9ac8-5fe1b4d9c92f')">
              <div class="icon">
                <img src="https://assets.brandbot.com/public/icons/conditions/d2b29794-384f-4e75-9ac8-5fe1b4d9c92f.svg" />
              </div>
              <div class="name">
                <div class="header">No Interaction</div>
                <span>The contact has not interacted with your business in a given amount of time</span>
              </div>
            </div>
            <div class="option" @click.prevent="selectQuickOption('9ebf3698-2407-4d70-97f1-afa09adddac9')">
              <div class="icon">
                <img src="https://assets.brandbot.com/public/icons/conditions/9ebf3698-2407-4d70-97f1-afa09adddac9.svg" />
              </div>
              <div class="name">
                <div class="header">Not engaged with a previous email</div>
                <span>The contact has not opened or clicked on a previous email sent</span>
              </div>
            </div>
          </div>
        </div>

        <div class="quick-options">
          <div class="title">Advanced Options</div>
          <div class="options">
            <div class="option" @click.prevent="campaignItem.behavior_rule.rule = null; conditions_sidebar = true">
              <div class="icon">
                <img src="https://assets.brandbot.com/public/icons/conditions/advanced.svg" />
              </div>
              <div class="name">
                <div class="header">Custom Rule</div>
                <span>Use our advanced rule builder to combine any of our 20+ conditions</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="selected-options" v-if="selectedOption">
        <div class="btn-back" @click.prevent="selectedOption = null; campaignItem.behavior_rule.rule = null; advanced_rule = false;">
          Select a different option
        </div>

        <div v-if="!advanced_rule">
          <LastVisit v-if="selectedOption === 'd2b29794-384f-4e75-9ac8-5fe1b4d9c92f'" :uuid="selectedOption" :rule="campaignItem.behavior_rule.rule" :campaignItem="campaignItem" />
          <IsAMember v-if="selectedOption === 'd323c020-0569-4f1e-ab1d-f56672d66610'" :uuid="selectedOption" :rule="campaignItem.behavior_rule.rule" :campaignItem="campaignItem" />
          <EmailInteraction v-if="selectedOption === '9ebf3698-2407-4d70-97f1-afa09adddac9'" :uuid="selectedOption" :rule="campaignItem.behavior_rule.rule" :campaignItem="campaignItem" />
          <NumberOfVisits v-if="selectedOption === '82584fc8-ba77-4f57-982a-68f8a52828cb'" :uuid="selectedOption" :rule="campaignItem.behavior_rule.rule" :campaignItem="campaignItem" />
          <ClassVisitBehavior v-if="selectedOption === '929b071b-bae1-47c9-92a4-ad80c9669e76'" :uuid="selectedOption" :rule="campaignItem.behavior_rule.rule" :campaignItem="campaignItem" />

          <PikeHasAPlan v-if="selectedOption === '2d5f2b5c-1620-451a-96fb-0f96bab671e0'" :uuid="selectedOption" :rule="campaignItem.behavior_rule.rule" :campaignItem="campaignItem" />
          <PikeAllTimeVisits v-if="selectedOption === '1e6a8fb2-8205-41fa-87a8-a46a0c2327dd'" :uuid="selectedOption" :rule="campaignItem.behavior_rule.rule" :campaignItem="campaignItem" />
        </div>

        <div v-if="advanced_rule">
          <p style="display: flex; justify-content: space-between;">
            The contact meets the following rule
            <button class="xpl-button xpl-button--secondary" type="button" @click="conditions_sidebar = true">Edit Custom Rule</button>
          </p>
          <div style="position: relative;">
            <RuleEnglishHybrid :rule="campaignItem.behavior_rule.rule" :enrollment="false" />
          </div>

          <div style="margin-top: 1rem;">
            <button type="button" class="xpl-button xpl-button--primary" @click="saveItem">Save</button>
          </div>
        </div>
      </div>
    </form>
    <ConditionsSidebarHybrid v-if="conditions_sidebar" :contact_list="campaignItem.behavior_rule" :goalList="true" :campaignItem="true" />
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import Multiselect from 'vue-multiselect';
  import ConditionsSidebar from '@/components/Contacts/ContactLists/Conditions/ConditionsSidebar';
  import RuleEnglish from '@/components/Contacts/ContactLists/Conditions/RuleEnglish';
  import LastVisit from './Quick/LastVisit';
  import IsAMember from './Quick/IsAMember';
  import EmailInteraction from './Quick/EmailInteraction';
  import NumberOfVisits from './Quick/NumberOfVisits';
  import ClassVisitBehavior from './Quick/ClassVisitBehavior';
  import PikeHasAPlan from './Quick/PikeHasAPlan';
  import PikeAllTimeVisits from './Quick/PikeAllTimeVisits';
  import RuleEnglishHybrid from '../../Contacts/ContactLists/Conditions/RuleEnglishHybrid';
  import ConditionsSidebarHybrid from '../../Contacts/ContactLists/Conditions/ConditionsSidebarHybrid';

  export default {
    props: ['campaignItem'],
    components: {
      ConditionsSidebarHybrid,
      RuleEnglishHybrid,
      PikeAllTimeVisits,
      PikeHasAPlan,
      Multiselect,
      ConditionsSidebar,
      RuleEnglish,
      LastVisit,
      IsAMember,
      EmailInteraction,
      NumberOfVisits,
      ClassVisitBehavior,
    },
    data() {
      return {
        conditions_sidebar: false,
        choice: this.campaignItem.time_rule.choice,
        relative: this.campaignItem.time_rule.relative,
        relative_count: this.campaignItem.time_rule.relative_count,
        selectedOption: null,
        advanced_rule: false,
      };
    },
    mounted() {
      EventBus.$on('contact_list.conditions_sidebar.close', () => {
        this.conditions_sidebar = false;
      });

      EventBus.$on('contact_list.refresh', () => {
        this.advanced_rule = true;

        if (this.campaignItem.behavior_rule.rule &&
          this.campaignItem.behavior_rule.rule.length) {
          this.selectedOption = this.campaignItem.behavior_rule.rule[0].condition.uuid;
        }
      });
    },
    created() {
      if (this.campaignItem.behavior_rule.rule &&
        this.campaignItem.behavior_rule.rule.length &&
        this.campaignItem.behavior_rule.rule[0].condition.quick) {
        this.advanced_rule = false;
        this.selectedOption = this.campaignItem.behavior_rule.rule[0].condition.uuid;
      }

      if (this.campaignItem.behavior_rule.rule &&
        this.campaignItem.behavior_rule.rule.length &&
        !this.campaignItem.behavior_rule.rule[0].condition.quick) {
        this.advanced_rule = true;
        this.selectedOption = this.campaignItem.behavior_rule.rule[0].condition.uuid;
      }
    },
    beforeDestroy() {
      EventBus.$off('contact_list.conditions_sidebar.close');
      EventBus.$off('contact_list.refresh');
    },
    methods: {
      commitCondition() {
        this.campaignItem.time_rule.choice = this.choice;
        this.campaignItem.time_rule.relative = this.relative;
        this.campaignItem.time_rule.relative_count = this.relative_count;
        EventBus.$emit('campaign_item.commit');
      },
      selectQuickOption(option) {
        this.selectedOption = option;
      },
      saveItem() {
        this.selectedOption = null;
        this.advanced_rule = false;
        EventBus.$emit('campaign_item.commit');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .alert {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 6px;
    background-color: rgba(180, 224, 250, 0.57);
    font-size: .75rem;
    font-weight: 700;
    color: rgb(46, 68, 87);
    padding-left: .75rem;
    padding-right: .75rem;
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .alert img {
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: .75rem;
  }

  form .multiselect {
    display: inline-block;
    width: auto;
    margin-right: .5rem;
    margin-left: .5rem;
  }

  form input + .multiselect {
    margin-left: 0;
  }

  .form-group {
    display: flex;
    align-items: center;
    font-weight: 700;
    color: #1d364b;
  }

  .form-group input {
    width: auto;
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .btn-bb-primary {
    margin-top: 1.5rem;
  }

  .quick-options .title {
    text-transform: uppercase;
    color: #AFBCCA;
    font-size: .625rem;
    font-weight: 700;
    line-height: 1.5rem;
  }

  .quick-options .options .option {
    width: 100%;
    display: flex;
    border: 1px solid var(--xpl-color-gray-400);
    border-radius: 6px;
    background-color: #FFFFFF;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: .675rem;
    padding-right: .675rem;
  }

  .quick-options .options .option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .875rem;
    color: #1D364B;
    transition: all 300ms ease-in-out;
    margin-bottom: .5rem;
  }

  .quick-options .options .option:hover {
    cursor: pointer;
    border: 1px solid var(--xpl-color-secondary-lm);
    // box-shadow: 0 2px 4px 0 var(--xpl-color-gray-400);
  }

  .quick-options .options .option .icon {
    width: 2.25rem;
    height: 2.25rem;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: .5rem;
    border-radius: 6px;
    background-color: #EFF6FD;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 300ms ease-in-out;
  }

  .quick-options .options .option:hover .icon {
    background-color: var(--xpl-color-secondary-bg-lm);
  }

  .quick-options .options .option .icon img {
    width: 1rem;
    max-width: 1rem;
  }

  .quick-options .options .option .name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: .875rem;
    color: rgba(29, 54, 75, .55);
  }

  .quick-options .options .option .name .header {
    margin-right: 1rem;
    color: rgb(29, 54, 75);
    font-weight: bold;
  }

  .selected-options {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-radius: 6px;
    background-color: rgba(235, 244, 250, 0.51);
    // box-shadow: inset 0 3px 15px 0 rgba(139, 155, 172, 0.13);
  }

  .selected-options .btn-back {
    text-decoration: underline;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: #698B9C;
    font-size: .75rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  .options-container {
    margin-top: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 6px;
    background-color: rgba(235, 244, 250, 0.51);
    // box-shadow: inset 0 3px 15px 0 rgba(139, 155, 172, 0.13);
  }

  mark {
    background-color: #FFCEAC;
  }

  .form-group.heading {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
</style>
