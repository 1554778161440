<template>
  <el-card shadow="never">
    <div class="text-center" style="margin-bottom: 2.5rem;">
      <img src="https://assets.brandbot.com/public/partners/pike13.svg" alt="Pike13" />
    </div>
    <h1 class="text-center">Connect to your Pike13 Account</h1>
    <el-form v-if="newAuth">
      <el-form-item style="text-align: center; margin-top: 1.5rem;">
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="openPike">Login to Pike13</el-button>
      </el-form-item>
    </el-form>
    <div v-if="!newAuth" style="text-align: center; margin-top: 1.5rem;">
      <el-button type="primary" class="xpl-button xpl-button--primary":loading="loading">{{ loading ? 'Connecting...' : 'Connected!' }}</el-button>
    </div>
  </el-card>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';

  export default {
    props: ['accountId'],
    data() {
      return {
        newAuth: true,
        loading: false,
        redirect_url: 'https://clients.brandbot.com/signup',
        client_id: '3Sl5D0eqK29Gf7URsU2qTGNaO3ccOuBiGP3w31Fg',
      };
    },
    created() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        this.newAuth = false;
        this.loading = true;
        this.saveToken(code);
      }
    },
    methods: {
      saveToken(code) {
        const payload = {
          code,
        };

        api.pikeToken(payload).then((response) => {
          this.loading = false;
          this.$message.success(response.message);
          EventBus.$emit('signup.vendor.done', 'pike');
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      openPike() {
        if (!this.accountId) {
          this.$message.error('Unable to connect you.');
          return;
        }

        this.loading = true;
        location.href = `https://pike13.com/oauth/authorize?response_type=code&redirect_uri=${this.redirect_url}&client_id=${this.client_id}`;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-card {
    max-width: 500px;
    width: 500px;
    min-width: 500px;
    border: none;
  }

  .el-card h1 {
    font-size: 1.75rem;
    color: #00152a;
    margin-bottom: 2.5rem;
  }

  .el-form-item {
    margin-bottom: 1rem;
  }
</style>
