<template>
  <div class="row">
    <span class="title">{{ step.name }}</span>
    <div>
      <div class="disable-caps">
        {{ selected_option | timeComponent(selected_value) }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'TimeFrameSummary',
  props: ['step'],
  computed: {
    json() { return JSON.stringify(this.step.default); },
    selected_option() {
      return this.step.default ? this.step.default.option : this.step.selected_timeframe;
    },
    selected_value() {
      return this.step.default ? this.step.default.value : this.step.selected_value;
    },
  },
  filters: {
    timeComponent(option, value, valueTwo) {
      value = value || 'n/a';
      if (Array.isArray(value)) {
        valueTwo = value[1];
        value = value[0];
      }
      switch (option) {
        case 'within_the_next':
          return `Within the next ${value} days`;
        case 'at_least_in_future':
          return `At least ${value} days in the future`;
        case 'exactly_in_future':
          return `Exactly ${value} days in the future`;
        case 'within_the_last':
          return `Within the last ${value} days`;
        case 'at_least_in_past':
          return `At least ${value} days in the past`;
        case 'exactly_in_past':
          return `Exactly ${value} days in the past`;
        case 'all_time':
          return 'All time';
        case 'none':
          return 'None';
        case 'before':
          return `Before ${moment(value)
            .format('MMM Do YYYY')}`;
        case 'after':
          return `After ${moment(value)
            .format('MMM Do YYYY')}`;
        case 'specific':
          return `On ${moment(value)
            .format('MMM Do YYYY')}`;
        case 'between':
          return `From ${moment(value)
            .format('MMM Do YYYY')} to ${moment(valueTwo)
            .format('MMM Do YYYY')}`;
        default:
          return option;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "summary_item.scss";
</style>
