<template>
  <div>Confirming...</div>
</template>
<script>
  import * as api from '@/api';

  export default {
    props: ['uuid'],
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        const payload = {
          confirmation_code: to.params.uuid,
        };

        api.confirmVerifiedEmail(payload).then((response) => {
          vm.$toasted.success(response.message);
          vm.$router.push('/settings/emails');
        }).catch((err) => {
          vm.$toasted.error(err.message);
        });
      });
    },
  };
</script>
