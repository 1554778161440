<template>
  <div>
    <el-row :span="24" class="heading">
      <el-col :span="12">
        <div class="display-medium">
          Admin
        </div>
      </el-col>
    </el-row>

    <el-row :span="24">
      <el-col :span="24">
        <el-table :data="classes" style="width: 100%;" v-loading="loading">
          <el-table-column label="Class">
            <template slot-scope="scope">
              <a :href="'/apps/class-reminders/' + scope.row.uuid" target="_blank">{{ scope.row.class_name }}</a>
            </template>
          </el-table-column>
          <el-table-column prop="start_time" label="Time"></el-table-column>
          <el-table-column prop="instructor_first_name" label="Instructor First Name"></el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>
<script>
  import { getClassScheduleAdmin } from '@/api';

  export default {
    data() {
      return {
        loading: false,
        classes: [],
      };
    },
    created() {
      this.fetchClasses();
    },
    methods: {
      fetchClasses() {
        this.loading = true;
        getClassScheduleAdmin().then((response) => {
          this.loading = false;
          this.classes = response.classes;
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>