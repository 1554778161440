<template>
  <transition name="fade">
    <div class="form" v-if="form" v-cloak>
      <div class="list-nav">
        <router-link to="/contacts/embed" class="go-back"><i class="fa fa-chevron-left"></i> All Sign Up Forms</router-link>
        <input type="text" autocomplete="off" v-model="form.name" @keyup.enter="editName" />
        <div class="actions">
          <div class="dropdown">
            <button type="button" class="xpl-button xpl-button--secondary" @click="options_dropdown = !options_dropdown" v-on-clickaway="away">Options</button>
            <div :class="{ 'dropdown-menu': true, 'dropdown-menu-right': true, 'show': options_dropdown }">
              <a href="#" class="dropdown-item" @click.prevent="copyForm">Copy Sign Up Form</a>
              <a href="#" class="dropdown-item text-danger" @click.prevent="deleteForm">Delete Sign Up Form</a>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="card">
          <div class="card-body">
            <div class="stats">
              <div :class="['stat', stat === 'last_30' ? 'show' : '']" @click.prevent="stat = 'last_30'">
                <h6>Sign Ups in Last 30 Days</h6>
                <div class="number text-muted" v-if="!form.last_30">&mdash;</div>
                <div class="number" v-if="form.last_30">{{ form.last_30 | prettyNumber }}</div>
              </div>
              <div :class="['stat', stat === 'all' ? 'show' : '']" @click.prevent="stat = 'all'">
                <h6>All Time Sign Ups</h6>
                <div class="number text-muted" v-if="!form.all_time">&mdash;</div>
                <div class="number" v-if="form.all_time">{{ form.all_time | prettyNumber }}</div>
              </div>
            </div>
            <FormStats v-if="stat" :stat="stat" :formId="form.id" />
          </div>
        </div>

        <div class="card builder">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-5">
                <h6>Form Settings</h6>
                <p class="small text-muted">Pick which fields you would like to show on your form. The Email Address field is required.</p>
                <div class="fields">
                  <div class="title">Field Selection</div>

                  <div class="field" @click="toggleField('first_name')">
                    <span class="icon icon-checkbox-checked2" v-if="fields.first_name"></span>
                    <span class="icon icon-checkbox-unchecked2" v-if="!fields.first_name"></span>
                    First Name
                  </div>

                  <div class="field" @click="toggleField('last_name')">
                    <span class="icon icon-checkbox-checked2" v-if="fields.last_name"></span>
                    <span class="icon icon-checkbox-unchecked2" v-if="!fields.last_name"></span>
                    Last Name
                  </div>

                  <div class="field">
                    <span class="icon icon-checkbox-checked2 text-muted"></span>
                    Email Address <i class="small">Required</i>
                  </div>

                  <div class="field" @click="toggleField('phone')">
                    <span class="icon icon-checkbox-checked2" v-if="fields.phone"></span>
                    <span class="icon icon-checkbox-unchecked2" v-if="!fields.phone"></span>
                    Phone Number
                  </div>
                </div>

                <div class="fields">
                  <div class="title">Additional Options</div>
                  <div class="custom-option" v-for="(field, index) in customFields" v-if="Object.keys(customFields).length && !add_field">
                    <div class="label">
                      {{ field.label }}
                      <span class="edit" @click.stop="editCustom(index, field)">Edit</span>
                    </div>
                    <div class="small text-muted text-capitalize">{{ field.type }}</div>
                  </div>
                  <button type="button" class="xpl-button xpl-button--primary" @click="add_field = true" v-if="!add_field">Add a New Field</button>
                  <div v-if="add_field">
                    <div class="small">What kind of input would you like to add?</div>
                    <div class="field" v-if="!field_type">
                      <button type="button" class="xpl-button xpl-button--secondary" @click="field_type = 'dropdown'">Dropdown</button>
                      <button type="button" class="xpl-button xpl-button--secondary" @click="field_type = 'multi'">Multi-Select Checkboxes</button>
                    </div>
                    <AddDropdown v-if="field_type === 'dropdown'" :field="field" :fields="fields" />
                    <AddMultiCheckboxes v-if="field_type === 'multi'" :field="field" :fields="fields" />
                  </div>
                </div>
              </div>
              <div class="col-sm-7">
                <h6>Preview</h6>
                <div class="preview">
                  <form @submit.prevent>
                    <div class="form-group" v-if="fields.first_name">
                      <label>First Name</label>
                      <input type="text" name="first_name" placeholder="First Name" class="form-control" readonly="readonly" autofill="off" required="required" />
                    </div>

                    <div class="form-group" v-if="fields.last_name">
                      <label>Last Name</label>
                      <input type="text" name="last_name" placeholder="Last Name" class="form-control" readonly="readonly" autofill="off" required="required" />
                    </div>

                    <div class="form-group" v-if="fields.email">
                      <label>Email Address</label>
                      <input type="email" name="email" placeholder="Email Address" class="form-control" readonly="readonly" autofill="off" required="required" />
                    </div>

                    <div class="form-group" v-if="fields.phone">
                      <label>Phone Number</label>
                      <input type="text" name="phone" placeholder="Phone Number" class="form-control" readonly="readonly" autofill="off" required="required" />
                    </div>

                    <div class="form-group" v-for="field in customFields" v-if="Object.keys(customFields).length">
                      <label>{{ field.label }}</label>
                      <select v-if="field.type === 'dropdown'" class="form-control">
                        <option v-for="option in field.options" :value="option.label">{{ option.label }}</option>
                      </select>
                      <div class="form-check" v-if="field.type === 'multi'">
                        <div v-for="option in field.options">
                          <input type="checkbox" :id="option.label" :value="option.label" class="form-check-input" style="margin-left: 0;" />
                          <label class="form-check-label" :for="option.label">{{ option.label }}</label>
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <button type="submit" class="xpl-button xpl-button--primary" @click="$toasted.info('This form is just a preview.')">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card code">
          <div class="card-header card-header-merged card-header-secondary">Embed Code</div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-7">
                <textarea v-model="generated_code" class="preview"></textarea>
              </div>
              <div class="col-sm-5">
                <p>Now you have to paste this code onto your webpage</p>
                <button type="button" class="xpl-button xpl-button--secondary" @click.stop="copyGeneratedCode">Copy Code</button>
                <p>Not technical? No problem, send this to your developer:</p>
                <button type="button" class="xpl-button xpl-button--secondary" @click.stop="email_dev_modal = !email_dev_modal">Email my Developer</button>
                <EmailDeveloperModal v-if="email_dev_modal" :code="generated_code" />
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header card-header-merged card-header-secondary">Settings</div>
          <div class="card-body">
            <p>Send a notification to the email below every time a contact fills out the form</p>
            <form @submit.prevent>
              <div class="form-group">
                <label>Email</label>
                <input type="email" v-model="form.email" class="form-control" placeholder="Email" />
              </div>

              <button type="submit" class="xpl-button xpl-button--primary" @click.stop="editEmail">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import { directive as onClickaway } from 'vue-clickaway';
  import _ from 'lodash';
  import FormStats from './FormStats';
  import AddDropdown from './AddDropdown';
  import AddMultiCheckboxes from './AddMultiCheckboxes';
  import EmailDeveloperModal from './EmailDeveloperModal';

  export default {
    directives: {
      onClickaway,
    },
    components: {
      FormStats,
      AddDropdown,
      AddMultiCheckboxes,
      EmailDeveloperModal,
    },
    props: ['uuid'],
    data() {
      return {
        email_dev_modal: false,
        field: null,
        add_field: false,
        field_type: null,
        stat: null,
        options_dropdown: false,
        form: {
          email: null,
        },
        generated_code: null,
        fields: {
          first_name: true,
          last_name: true,
          email: true,
          phone: true,
        },
      };
    },
    mounted() {
      EventBus.$on('close_stat', () => {
        this.stat = null;
      });

      EventBus.$on('dropdown.remove', (field) => {
        if (field) {
          this.$delete(this.fields, field);
          this.updateFields();
        }

        this.add_field = false;
        this.field_type = null;
        this.field = null;
      });

      EventBus.$on('multi.remove', (field) => {
        if (field) {
          this.$delete(this.fields, field);
          this.updateFields();
        }

        this.add_field = false;
        this.field_type = null;
        this.field = null;
      });

      EventBus.$on('dropdown.commit', (dropdown) => {
        this.$set(this.fields, `custom_${this.slugify(dropdown.label)}`, dropdown);
        this.updateFields();
        this.add_field = false;
        this.field_type = null;
        this.field = null;
      });

      EventBus.$on('multi.commit', (multi) => {
        this.$set(this.fields, `custom_${this.slugify(multi.label)}`, multi);
        this.updateFields();
        this.add_field = false;
        this.field_type = null;
        this.field = null;
      });

      EventBus.$on('email_dev.close', () => {
        this.email_dev_modal = false;
      });
    },
    created() {
      this.fetchForm();
      this.generateCode();
    },
    beforeDestroy() {
      EventBus.$off('email_dev.close');
      EventBus.$off('dropdown.commit');
      EventBus.$off('dropdown.remove');
      EventBus.$off('close_stat');
    },
    computed: {
      customFields() {
        if (Object.keys(this.fields).length) {
          return _.pickBy(this.fields, (val, key) => key.indexOf('custom') > -1);
        }

        return {};
      },
    },
    methods: {
      toggleField(field) {
        this.fields[field] = !this.fields[field];
        this.updateFields();
        this.generateCode();
      },
      slugify(str) {
        str = str.replace(/^\s+|\s+$/g, '');
        str = str.toLowerCase();

        const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
        const to = 'aaaaaaeeeeiiiioooouuuunc------';

        // eslint-disable-next-line
        for (let i = 0, l = from.length; i < l; i++) {
          str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str
          .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
          .replace(/\s+/g, '-') // collapse whitespace and replace by -
          .replace(/-+/g, '-'); // collapse dashes

        return str;
      },
      editCustom(index, field) {
        this.add_field = true;
        this.field_type = field.type;
        this.field = index;
      },
      updateFields() {
        const payload = {
          fields: JSON.stringify(this.fields),
        };

        api.updateForm(this.form.id, payload).then((response) => {
          this.$toasted.success(response.message);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      copyForm() {
        api.copyForm(this.form.id).then((response) => {
          this.$toasted.success(response.message);
          this.$router.push('/contacts/embed');
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      away() {
        this.options_dropdown = false;
      },
      editName() {
        const payload = {
          name: this.form.name,
        };

        api.updateForm(this.form.id, payload).then((response) => {
          this.$toasted.success(response.message);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      editEmail() {
        const payload = {
          email: this.form.email,
        };

        api.updateForm(this.form.id, payload).then((response) => {
          this.$toasted.success(response.message);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      deleteForm() {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to delete this form? This is irreversible.')) {
          return;
        }

        api.deleteForm(this.form.id).then((response) => {
          this.$toasted.success(response.message);
          this.$router.push('/contacts/embed');
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchForm() {
        api.getForm(this.uuid).then((response) => {
          this.form = response.form;
          const fields = JSON.parse(response.form.fields);
          this.$set(this, 'fields', fields);
          this.generateCode();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      generateEnglishName(field) {
        if (field === 'first_name') return 'First Name';
        if (field === 'last_name') return 'Last Name';
        if (field === 'email') return 'Email Address';
        if (field === 'phone') return 'Phone Number';

        return 'Field';
      },
      copyGeneratedCode() {
        this.$copyText(this.generated_code).then(() => {
          this.$toasted.success('Copied!');
        }, () => {
          this.$toasted.error('Something wrong happened while trying to copy...');
        });
      },
      generateCode() {
        // eslint-disable-next-line
        const lol = '</scr' + 'ipt>';
        const head = `<!-- Begin Connect Signup Form -->
<link href="https://assets.brandbot.com/public/embed/form-1.4.css" rel="stylesheet" type="text/css">
<style type="text/css">
/* Add your own style overrides in your site stylesheet or in this style block.
We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>
<div id="bb_form">
<form method="post" action="https://embed.brndbot.net/create" id="bb_form_inner">
<div id="alert" class="alert" style="display: none;"></div>
`;
        const footer = `
<div class="form-group">
<input type="hidden" name="bb_uuid" id="bb_uuid" value="${this.uuid}" />
<button type="submit" class="btn" id="bb_submit">Submit</button>
</div>
</form>
</div>
<script>window.jQuery || document.write('<scr'+'ipt src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js"></scr'+'ipt>')${lol}
<script src="https://assets.brandbot.com/public/embed/form-1.2.js">${lol}
<!-- Ending Connect Signup Form -->`;

        let fields = '';
        Object.keys(this.fields).forEach((k) => {
          if (this.fields[k] && k.indexOf('custom') === -1) {
            const field = `
<div class="form-group">
  <label for="bb_${k}">${this.generateEnglishName(k)}</label>
  <input type="${k === 'email' ? 'email' : 'text'}" name="bb_${k}" id="bb_${k}" class="form-control" required="required" />
</div>`;
            fields = `${fields} ${field}`;
          }
        });

        this.generated_code = `${head}${fields}${footer}`;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .card {
    margin-bottom: 1.5rem;
  }

  .stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .stats .stat {
    flex: calc(100% / 2 - 1rem);
    flex-shrink: 0;
    flex-grow: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    min-width: 6.25rem;
    padding: .75rem;
    padding-top: 1rem;
    border-radius: 6px;
    background-color: #ffffff;
    border: solid 1px #d6e0e8;
    margin-right: .5rem;
    transition: all 150ms ease-in-out;
  }

  .stats .stat:hover,
  .stats .stat.show {
    border-color: var(--xpl-color-secondary-lm);
    background-color: var(--xpl-color-secondary-bg-lm);
  }

  .stats .stat.show h6 {
    color: var(--xpl-color-secondary-lm);
  }

  .stats .stat:last-child {
    margin-right: 0;
  }

  .stats .stat h6 {
    text-align: center;
    font-size: .625rem;
    font-weight: 800;
    letter-spacing: 0.8px;
    color: #8b9bac;
    margin-bottom: .375rem;
    text-transform: uppercase;
  }

  .stats .stat .number {
    text-align: center;
    font-size: 1.25rem;
  }

  .card.builder h6 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #1d364b;
    margin-bottom: 1rem;
  }

  .card.builder .fields {
    margin-top: .625rem;
  }

  .card.builder .fields .title {
    font-size: .625rem;
    font-weight: 800;
    line-height: 2;
    color: #617080;
    text-transform: uppercase;
    margin-bottom: .75rem;
  }

  .card.builder .fields .field {
    cursor: pointer;
    margin-bottom: 1rem;
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.43;
    color: #1d364b;
  }

  .card.builder .fields .field .icon {
    margin-right: .5rem;
  }

  .card.builder .preview {
    width: 100%;
    border: solid 1px var(--xpl-color-gray-400);
    border-radius: 6px;
    padding: 1.5rem;
  }

  .card.builder .preview form {
    width: 50%
  }

  .card.code p {
    font-size: .875rem;
    font-weight: 700;
    color: #2e4457;
    margin-bottom: .75rem;
  }

  .card.code button {
    margin-bottom: 1rem;
  }

  .card.code .preview {
    width: 100%;
    border: solid 1px var(--xpl-color-gray-400);
    outline: none;
    height: 100%;
    font-size: .75rem;
  }

  .form-control[readonly] {
    background-color: white;
  }

  .custom-option {
    width: 20rem;
    max-width: 20rem;
    border-radius: 6px;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: .75rem;
    padding-right: .75rem;
    background-color: rgb(255, 255, 255);
    border: solid 1px rgb(214, 224, 232);
    margin-bottom: .875rem;
  }

  .custom-option .label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: .875rem;
    font-weight: 700;
    color: rgb(29, 54, 75);
  }

  .custom-option .label .edit {
    cursor: pointer;
    font-weight: normal;
  }

  .custom-option .label .edit:hover {
    text-decoration: underline;
  }
</style>
