<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Change Scheduled Date
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <div class="row">
                  <div class="col-md-4">
                    <label for="date-picker">Date</label>
                    <input type="text"
                          name="date-picker"
                          id="date-picker"
                          v-model="date"
                          placeholder="Pick a date"
                          :class="{ 'form-control': true  }" />
                  </div>
                  <div class="col-md-8">
                    <label for="time-picker">Time</label>
                    <div class="times">
                      <Multiselect v-model="time_hour" :options="[1,2,3,4,5,6,7,8,9,10,11,12]" placeholder="Hour" :searchable="true" :show-labels="false" :allow-empty="false" />
                      <Multiselect v-model="time_min" :options="['00','05','10','15','20','25','30','35','40','45','50','55']" placeholder="Minutes" :searchable="true" :show-labels="false" :allow-empty="false" />
                      <Multiselect v-model="time_ampm" :options="['AM','PM']" :searchable="true" :show-labels="false" :allow-empty="false" />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="button" class="xpl-button xpl-button--primary" @click.stop="saveDate($event)">Save</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import flatpickr from 'flatpickr';
  import Multiselect from 'vue-multiselect';

  export default {
    components: {
      Multiselect,
    },
    props: ['scheduledAt', 'scheduledEmailId'],
    data() {
      return {
        date: this.scheduledAt.split(' ')[0],
        time_hour: null,
        time_min: null,
        time_ampm: null,
      };
    },
    created() {
      const hour = Number(this.scheduledAt.split(' ')[1].split(':')[0]);
      this.time_min = this.scheduledAt.split(' ')[1].split(':')[1];

      if (hour > 12) {
        this.time_ampm = 'PM';
      } else {
        this.time_ampm = 'AM';
      }

      this.time_hour = hour > 12 ? hour - 12 : hour;

      this.$nextTick(() => {
        flatpickr('#date-picker', {
          dateFormat: 'Y-m-d',
          maxDate: '2027-01-01',
          minDate: new Date(),
        });
      });
    },
    methods: {
      closeModal() {
        EventBus.$emit('scheduled_email.change_date.close');
      },
      saveDate(event) {
        const target = event.srcElement || event.target;
        const originalInner = target.innerHTML;

        target.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';
        target.disabled = true;


        const payload = {
          date: this.date,
          time: `${this.time_hour}:${this.time_min} ${this.time_ampm}`,
        };

        api.updateScheduledEmail(this.scheduledEmailId, payload).then((response) => {
          this.$toasted.success(response.message);
          target.innerHTML = originalInner;
          this.closeModal();
        }).catch((err) => {
          target.innerHTML = originalInner;
          target.disabled = false;
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style src="flatpickr/dist/flatpickr.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
</style>
<style lang="scss" scoped>
  .modal .modal-content .modal-body {
    overflow-y: visible;
  }

  .form-control:disabled, .form-control[readonly] {
    background-color: white;
  }

  .times {
    display: flex;
  }

  .times .multiselect {
    margin-right: .5rem;
  }
</style>
