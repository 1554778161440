<template>
  <div>
    <el-card>
      <div class="display-small" style="margin-bottom: 2rem;">Subscription Settings</div>
      <div v-if="subscription && (subscription.subscription_name === 'Trial' || subscription.none)">
        <div>This account is currently on trial. Trial will end on {{ subscription.subscription_ends_at | humanDate(false) }}.</div>

        <el-form @submit.native.prevent>
          <el-form-item>
            <label>Change Trial End Date</label>
            <el-date-picker v-model="trial_ends_at" type="date" value-format="yyyy-MM-dd" placeholder="Pick a date"></el-date-picker>
          </el-form-item>
          <button type="button" class="xpl-button xpl-button--primary" @click="changeTrial">Change</button>
        </el-form>
      </div>
      <div v-else>
        This account has an active subscription.
      </div>
    </el-card>
    <el-card>
      <div class="display-small">Promotions</div>
      <div class="small text-muted">Find below the promotions applicable to this account.</div>
      <el-form style="margin-top: 2rem;" @submit.native.prevent>
        <el-form-item>
          <label>30 for $30</label>
          <div>
            <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="activate30for30" :loading="isLoadingPromo">Activate 30 for $30 for this Account</el-button>
            <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="activate30for30(false)" :loading="isLoadingPromo">First 30 days Free</el-button>
            <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="zingfitFree" :loading="zingfitLoading">Mark as Zingfit Free</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <div class="display-small">Active Subscriptions</div>
      <el-form>
        <el-form-item>
          <div>
            <a :href="customer_url" target="_blank">Go to Stripe Customer Profile</a> or <el-button size="small" type="primary" @click="createStripeCustomer">Create Stripe Customer</el-button>
          </div>
        </el-form-item>
      </el-form>
      <el-table :data="subscriptions" style="width: 100%">
        <el-table-column prop="subscription_id" label="Subscription ID"></el-table-column>
        <el-table-column prop="name" label="Plan"></el-table-column>
        <el-table-column label="Amount">
          <template slot-scope="scope">
            {{ scope.row.amount | prettyCurrency }}
          </template>
        </el-table-column>
        <el-table-column prop="created" label="Created At"></el-table-column>
        <el-table-column align="right" width="200px">
          <template slot-scope="scope">
            <el-button size="small" type="secondary" @click="linkToBrandBot(scope.row.subscription_id)">Link Custom to {{$store.getters.brandName}}</el-button>
            <el-button size="small" type="danger" @click="cancelSubscription(scope.row.subscription_id)">Cancel</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
  import * as api from '@/api';
  import VueDatepickerLocal from 'vue-datepicker-local';

  export default {
    components: {
      VueDatepickerLocal,
    },
    data() {
      return {
        local: {
          dow: 1,
          hourTip: '',
          minuteTip: '',
          secondTip: '',
          yearSuffix: '',
          monthsHead: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
          months: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
          weeks: 'Mon_Tue_Wed_Thu_Fri_Sat_Sun'.split('_'),
        },
        subscription: null,
        tier: null,
        billing_metadata: null,
        billing_contacts: null,
        loading: true,
        trial_ends_at: null,
        isLoadingPromo: false,
        zingfitLoading: false,
        subscriptions: [],
        customer_url: '',
      };
    },
    created() {
      this.fetchBilling();
      this.fetchAdminBilling();
    },
    methods: {
      zingfitFree() {
        this.zingfitLoading = true;
        api.markAsZingfitFree().then((response) => {
          this.zingfitLoading = false;
          this.$message.success(response.message);
        }).catch((err) => {
          this.zingfitLoading = false;
          this.$message.error(err.message);
        });
      },
      createStripeCustomer() {
        this.$confirm('Are you sure you want to create a Stripe customer?').then(() => {
          const payload = {
            create_stripe: true,
          };
          api.enterpriseHelpers(payload).then((response) => {
            this.$message.success(response.message);
          }).catch((err) => {
            this.$message.error(err.message);
          });
        }).catch(() => {});
      },
      linkToBrandBot(subscriptionId) {
        this.$confirm(`The subscription with ID ${subscriptionId} will be linked to this account and will *mark it as a custom subscription*. Continue?`)
        .then(() => {
          const payload = {
            link_stripe: true,
            subscription_id: subscriptionId,
          };
          api.enterpriseHelpers(payload).then((response) => {
            this.$message.success(response.message);
          }).catch((err) => {
            this.$message.error(err.message);
          });
        }).catch(() => {});
      },
      cancelSubscription(subscriptionId) {
        this.$confirm('This subscription will be set to be cancelled *at the end of the current billing cycle*.', 'Are you sure?').then(() => {
          const payload = {
            subscription_id: subscriptionId,
          };
          api.cancelSubscription(payload).then((response) => {
            this.$message.success(response.message);
          }).catch((err) => {
            this.$message.error(err.message);
          });
        }).catch(() => {});
      },
      activate30for30(chargeNow = true) {
        this.isLoadingPromo = true;

        const payload = {
          billing_promo: '30for30',
          charge_now: chargeNow,
        };

        api.updateAccount(this.$store.state.user.current_account.id, payload).then((response) => {
          this.$message.success(response.message);
          this.isLoadingPromo = false;
        }).catch((err) => {
          this.isLoadingPromo = false;
          this.$message.error(err.message);
        });
      },
      fetchAdminBilling() {
        api.getAdminBilling().then((response) => {
          this.subscriptions = response.subscriptions;
          this.customer_url = response.customer_url;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchBilling() {
        this.loading = true;
        api.getBilling().then((response) => {
          this.subscription = response.subscription;
          this.billing_metadata = response.billing_metadata;
          this.tier = response.tier;
          this.billing_contacts = response.billing_contacts;
          this.loading = false;
          this.trial_ends_at = this.subscription.subscription_ends_at;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      changeTrial(event) {
        const target = event.target || event.srcElement;
        target.disabled = true;

        const payload = {
          trial_ends_at: this.trial_ends_at,
        };

        api.updateAccount(this.$store.state.user.current_account.id, payload).then((response) => {
          this.$toasted.success(response.message);
          target.disabled = false;
          this.$store.dispatch('refreshUser');
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-card + .el-card {
    margin-top: 2rem;
  }
</style>
