<template>
  <div class="logo">
    <div class="sidebar">
      <form @submit.prevent v-if="metadata">
        <div class="form-group">
          <label>{{ metadata.logo ? 'Change Logo' : 'Upload Logo' }}</label>
          <input type="file" accept="image/*" id="logo_file" ref="logo_file" class="input-file" @change="fileUpload($event)" />
          <label for="logo_file" ref="logo_file_label">
            Upload
          </label>
        </div>

        <div class="form-group">
          <label>Logo Background Color</label>
          <div class="input-group" @click="show_background_color_picker = !show_background_color_picker">
            <span class="input-group-addon">
              <span class="icon icon-square" :style="'color: ' + background_color.hex"></span>
            </span>
            <input type="text" ref="background_color" v-model="background_color.hex" class="form-control" readonly />
          </div>
          <Sketch v-model="background_color" :disableAlpha="true" @input="backgroundColorEdited" v-if="show_background_color_picker" />
        </div>

        <div class="form-group">
          <label>Logo Width</label>
          <input type="number" name="logo" class="form-control" min="1" ref="logo_width" @input="attributeEdited" />
        </div>

        <button class="xpl-button xpl-button--primary" @click.stop="nextStep">Next</button>
      </form>
    </div>
    <div class="preview">
      <div class="alert">
        <div class="illustration">
          <span class="material-icons">check_circle</span>
        </div>
        <div>
          <h1>This is a preview of your selections.</h1>
          <p>You are setting the defaults for this account. These defaults can be changed at any time. Also every element in every email can be styled specifically for that email.</p>
        </div>
      </div>

      <div class="branding_preview" v-html="preview_html">
      </div>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import _ from 'lodash';
  import { Sketch } from 'vue-color';

  export default {
    components: {
      Sketch,
    },
    data() {
      return {
        preview_html: null,
        show_background_color_picker: false,
        background_color: {
          hex: null,
        },
        metadata: null,
      };
    },
    created() {
      this.fetchBrandingPreview();
    },
    methods: {
      nextStep() {
        EventBus.$emit('brand_builder.move_to', 'colors');
      },
      fileUpload(event) {
        const target = event.srcElement || event.target;
        // const originalInner = this.$refs.image_file_label.innerHTML;

        if (!target.files.length) {
          return;
        }

        this.$refs.logo_file_label.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';
        target.disabled = true;
        const file = target.files[0];

        const formData = new FormData();
        formData.append('type', 'image');
        formData.append('logo_file', file);

        api.saveBrandingLogo(formData).then(() => {
          this.fetchBrandingPreview();
          this.$refs.logo_file_label.innerHTML = 'Uploaded!';
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      attributeEdited: _.debounce(function customFunction() {
        const payload = {
          logo_width: `${this.$refs.logo_width.value}px`,
          logo_background: this.background_color.hex,
        };

        api.saveBrandingMetadata(payload).then(() => {
          this.fetchBrandingPreview();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      }, 300,
      ),
      fetchBrandingPreview() {
        api.getBrandingPreview().then((response) => {
          this.preview_html = response.preview_html;
          this.metadata = response.metadata;

          this.$nextTick(() => {
            this.$refs.logo_width.value = this.metadata.logo_width.replace('px', '');
            this.background_color.hex = this.metadata.logo_background;
          });
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      backgroundColorEdited() {
        this.attributeEdited();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .vc-sketch {
    position: absolute;
    z-index: 100;
  }

  .logo {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100vw;
    height: calc(100vh - 3.5rem);
    padding-left: 20rem;
    background-color: rgb(243, 247, 249);
  }

  .sidebar {
    position: fixed;
    left: 0;
    min-width: 20rem;
    width: 20rem;
    background-color: white;
    padding: 1rem;
    height: calc(100% - 3.5rem);
  }

  .sidebar form {
    width: 100%;
    height: calc(100% - 3.5rem);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .sidebar form .form-group {
    width: 100%;
  }

  .sidebar form button {
    margin-top: auto;
  }

  .preview {
    flex: 75%;
    padding: 1rem;
    background-color: rgb(243, 247, 249);
    height: 100%;
  }

  .preview .alert {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 0;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: rgb(255, 255, 255);
  }

  .preview .alert h1 {
    font-size: .875rem;
    line-height: 1.25rem;
    color: rgb(97, 112, 128);
    margin-bottom: 0;
  }

  .preview .alert p {
    font-size: .75rem;
    line-height: 1rem;
    color: rgb(29, 54, 75);
    margin: 0;
  }

  .preview .alert .illustration {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(217, 231, 240);
    width: 2rem;
    max-width: 2rem;
    height: 2rem;
    max-height: 2rem;
    border-radius: 99rem;
    color: white;
    margin-right: 1rem;
  }

  input[readonly] {
    background-color: white;
  }

  .input-group input {
    cursor: pointer;
  }

  .input-group-addon {
    padding: .25rem .5rem;
    padding-right: 0;
  }

  .icon-square {
    font-size: 1.5rem;
    cursor: pointer;
  }

  .branding_preview {
    pointer-events: none;
    margin-left: -1rem;
    margin-right: -1rem;
  }
</style>
