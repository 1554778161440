<template>
  <div>
    <div data-test-main-header class="display-medium" style="margin-bottom: 2rem;">Form Embed Code</div>
    <el-card>
      <el-row :span="24">
        <el-col :span="11">
          <el-form>
            <el-form-item>
              <el-input data-test-text-preview type="textarea" :rows="5" v-model="generateEmbeddedCode" readonly />
            </el-form-item>
            <el-form-item>
              <el-button data-test-copy-button type="secondary" size="small" @click.stop="copyGeneratedCode">Copy Code</el-button>
            </el-form-item>
          </el-form>
          <div class="section">
            <div data-test-developer-header style="margin-bottom: .875rem;">Not technical? No problem, send this to your developer:</div>
            <el-button data-test-email-button type="secondary" size="small" @click.stop="emailDeveloper">Email my Developer</el-button>
          </div>
        </el-col>
        <el-col :span="11" :offset="1">
          <div data-test-preview-header class="small text-muted">Preview</div>
          <div v-if="!termsLoading" data-test-genertated-preview id="embed_preview" v-html="generatePreviewCode"></div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
  import countries from '@/components/Auth/countries.json';
  import { getMindbodyReferralTypes } from '@/api';
  import axios from 'axios';
  import { enviromentVariables } from '@/values/enviromentValues';

  export default {
    props: ['form'],
    data() {
      return {
        generatedId: Math.random().toString(36).substring(7),
        referral_types: [],
        termsAndServiceUrlsMT: {
          terms_of_service_url: '',
          privacy_policy_url: '',
        },
        termsLoading: false,
      };
    },
    created() {
      this.fetchReferralTypes();
      if (this.$store.getters.currentUserActiveVendor === 'marianatek' && this.form.settings.sync.active) {
        this.fetchTermsandServiceUrlsMT();
        this.termsLoading = true;
      }
    },
    computed: {
      isStagingAndVendorSyncOn() {
        if (this.$store.getters.currentUserActiveVendor === 'marianatek') {
          return enviromentVariables.apiUrl === 'staging' && this.form.settings.sync.active;
        } return false;
      },
      injectEmbeddedScript() {
        const scriptTag = `</scr${''}ipt>`;
        if (this.isStagingAndVendorSyncOn) {
          return `<script src="https://assets.brandbot.com/forms-staging/embed/embed.js?id=${this.generatedId}">${scriptTag}`;
        }
        return `<script src="https://assets.brandbot.com/forms/embed/embed.js?id=${this.generatedId}">${scriptTag}`;
      },
      injectEmbeddedCss() {
        if (this.isStagingAndVendorSyncOn) {
          return '<link href="https://assets.brandbot.com/forms-staging/embed/embed.css" rel="stylesheet" type="text/css">';
        }
        return '<link href="https://assets.brandbot.com/forms/embed/embed.css" rel="stylesheet" type="text/css">';
      },
      injectFormPostMethod() {
        if (this.isStagingAndVendorSyncOn) {
          return `<form method="post" action="https://microservices.brndbot.net/forms-staging/answers" name="${this.generatedId}" id="bb_form_inner">`;
        }
        return `<form method="post" action="https://microservices.brndbot.net/forms/answers" name="${this.generatedId}" id="bb_form_inner">`;
      },
      generateEmbeddedCode () {
        return this.generateFormHtmlCode(false);
      },
      generatePreviewCode() {
        return this.generateFormHtmlCode(true);
      },
    },
    methods: {
       generateFormHtmlCode(asPreview) {
        let fields = this.form.fields ? JSON.parse(this.form.fields) : null;
        const lol = `</scr${''}ipt>`;

        if (!fields) {
          return null;
        }
        const devModeHeader = this.isStagingAndVendorSyncOn ? '<div><h1 class="dev-header">You are in Developer mode</h1> <style>.dev-header{color:red}</style>' :
        '';

        fields = fields.filter(f => f.include === true).sort((one, two) => one.order - two.order);
        const header = `<!-- Begin Connect Signup Form -->
          ${this.injectEmbeddedCss}
          <style type="text/css">
            /* Add your own style overrides in your site stylesheet or in this style block.
            We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
          </style>
          ${devModeHeader}
          <div id="bb_form">
            ${this.injectFormPostMethod}
              <div id="alert"></div>`;
        const footer = `<div class="form-group">
                <input type="hidden" name="bb_uuid" id="bb_uuid" value="${this.form.uuid}" />
                <button type="submit" class="btn" id="bb_submit" style="background-color: ${this.form.settings.template.btn_color}; border-color: ${this.form.settings.template.btn_color}; color: white;">${this.form.settings.template.btn_text}</button>
              </div>
            </form>
          </div>
          <script>window.jQuery || document.write('<scr'+'ipt src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js"></scr'+'ipt>') ${lol}
          ${this.injectEmbeddedScript}
          <!-- Ending Connect Signup Form -->`;
        let generatedFields = '';
        Object.keys(fields).forEach((idx) => {
          const field = fields[idx];
          generatedFields = `${generatedFields}${this.generateField(field, asPreview)}`;
        });

        return `${header}${generatedFields}${footer}`;
      },
      generateField(field, asPreview) {
        let html = '<div class="form-group">';

        if (['text', 'email', 'tel', 'password'].indexOf(field.type) > -1) {
          if (field.id !== 'referral_source') {
            html = `${html}<label for="bb_${field.id}">${field.label}</label><input type="${field.type}" name="bb_${field.id}" id="bb_${field.id}" class="form-control" ${field.required ? 'required="required" ' : ''}placeholder="${field.label}" />`;
          } else {
            html = `${html}<label for="bb_${field.id}">${field.label}</label><div><select id="bb_${field.id}">${this.generateReferral()}</select></div>`;
          }
        }

        if (field.type === 'dropdown' && field.options) {
          html = `${html}<label for="bb_${field.id}">${field.label}</label><div><select id="bb_${field.id}" placeholder="bb_${field.id}" name="bb_${field.id}">`;
          field.options.forEach((opt) => {
            html = `${html}<option value="${opt.label}">${opt.label}</option>`;
          });
          html = `${html}</select></div>`;
        }

        const termsAndServiceField = field.options && field.id === 'mt_terms_and_service';
        const isMultiField = field.type === 'multi' && field.options;
        if (isMultiField && !termsAndServiceField) {
          html = `${html}<label for="bb_${field.id}">${field.label}</label>`;
          field.options.forEach((opt) => {
            html = `${html}<div><input id="bb_${field.id}" type="checkbox" value="${opt.label}" /> ${opt.label}</div>`;
          });
        }

        if (isMultiField && termsAndServiceField) {
          let tosHref = "#";
          let privacyHref = "#";
          let hide ="display: none"

          if (asPreview){
            tosHref = this.termsAndServiceUrlsMT.terms_of_service_url;
            privacyHref = this.termsAndServiceUrlsMT.privacy_policy_url;
            hide = "display: block"
          }

          html = `<div class="form-group" id="bb_mt_terms_and_service_container" style="${hide}"><label for="bb_${field.id}">${field.label}</label>`;
          field.options.forEach((opt) => {
            html = `${html}<div><input id="bb_${field.id}" type="checkbox" value=${opt.value} oninvalid="this.setCustomValidity('You must agree to the Terms and Conditions.')" oninput="this.setCustomValidity('')"/> I agree to electronically signing the <a id="terms-link" href="${tosHref}" target="_blank" >Terms of Service</a> and <a id="privacy-link" href="${privacyHref}" target="_blank"  >Privacy Policy</a>.</div>`;
          });
        }

        if (field.id === 'country') {
          html = `${html}<label for="bb_${field.id}">${field.label}</label><div><select id="bb_${field.id}" placeholder="bb_${field.id}" name="bb_${field.id}">`;
          countries.forEach((country) => {
            html = `${html}<option value="${country.value}">${country.label}</option>`;
          });
          html = `${html}</select></div>`;
        }

        if (field.type === 'birthday') {
          html = `${html}<label for="bb_${field.id}">${field.label}</label><table><tr>
              <td><label for="bb_bday_day">Day</label><div><select id="bb_bday_day" placeholder="Day" style="focus { border-color: #3597DE; }" ${field.required ? 'required="required" ' : ''}>${this.generateDays()}</select></div></td>
              <td><label for="bb_bday_month">Month</label><div><select id="bb_bday_month" placeholder="Month" style="focus { border-color: #3597DE; }" ${field.required ? 'required="required" ' : ''}>${this.generateMonths()}</select></div></td>
              <td><label for="bb_bday_year">Year</label><div><select id="bb_bday_year" placeholder="Year style="focus { border-color: #3597DE; }" ${field.required ? 'required="required" ' : ''}"><option value=""></option>${this.generateYears()}</select></div></td>
            </tr></table>`;
        }

        if (field.type === 'rating') {
          html = `${html}<label for="bb_${field.id}">${field.label}</label><table><tr><td>${field.low}</td><td><input type="radio" name="bb_${field.id}" value="1" />1</td><td><input type="radio" name="bb_${field.id}" value="2" />2</td><td><input type="radio" name="bb_${field.id}" value="3" />3</td><td><input type="radio" name="bb_${field.id}" value="4" />4</td><td><input type="radio" name="bb_${field.id}" value="5" />5</td><td>${field.high}</td></tr></table>`;
        }

        html = `${html}</div>`;
        return html;
      },
      generateReferral() {
        let ref = '';
        this.referral_types.forEach((rt) => {
          ref = `${ref}<option value="${rt}">${rt}</option>`;
        });

        return ref;
      },
      generateDays() {
        let days = '';
        ['', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'].forEach((day) => {
          days = `${days}<option value="${day}">${day}</option>`;
        });

        return days;
      },
      generateMonths() {
        let months = '';
        [{ label: '', value: '' }, { label: 'January', value: '01' }, { label: 'February', value: '02' }, { label: 'March', value: '03' }, { label: 'April', value: '04' }, { label: 'May', value: '05' }, { label: 'June', value: '06' }, { label: 'July', value: '07' }, { label: 'August', value: '08' }, { label: 'September', value: '09' }, { label: 'October', value: '10' }, { label: 'November', value: '11' }, { label: 'December', value: '12' }].forEach((month) => {
          months = `${months}<option value="${month.value}">${month.label}</option>`;
        });

        return months;
      },
      generateYears() {
        const currentYear = new Date().getFullYear();
        let years = '';
        for (let i = 0; i < 100; i += 1) {
          // years.push(currentYear)
          years = `${years}<option value="${currentYear - i}">${currentYear - i}</option>`;
        }
        return years;
      },
      copyGeneratedCode() {
        this.$copyText(this.generateEmbeddedCode).then(() => {
          this.$message.success('Copied!');
        });
      },
      emailDeveloper() {
        this.$prompt('Your developer\'s email', 'Email', {
          confirmButtonText: 'Send',
          cancelButtonText: 'Cancel',
          inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
          inputErrorMessage: 'Invalid Email',
        }).then(({ value }) => {
          this.sendEmail(value);
        });
      },
      sendEmail(email) {
        this.$message.warning(`In construction... ${email}`);
      },
      async fetchReferralTypes() {
        try {
          const response = await getMindbodyReferralTypes();
          this.referral_types = response.types;
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      async fetchTermsandServiceUrlsMT() {
        const enviroment = enviromentVariables.apiUrl === 'staging' ? 'staging-api' : 'api';
        try {
          const accountId = this.$store.state.user.current_account.id;
          const response = await axios.get(`https://${enviroment}.brandbot.cloud/vendor/marianatek/accounts/${accountId}/terms_and_service`); //eslint-disable-line
          this.termsAndServiceUrlsMT = response.data;
          this.termsLoading = false;
        } catch (err) {
          this.$toasted.error('Terms of Service and Privacy Policy failed to load');
          this.termsLoading = false;
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-card + .el-card {
    margin-top: 2rem;
  }

  .el-card {
    font-size: 1rem;
  }

  .section {
    margin-bottom: 2rem;
  }
</style>
