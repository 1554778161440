<template>
  <div>
    <div
      :class="['navbar', 'navbar-expand-lg', 'fixed-top', 'banner', daysLeft < 15 ? 'start' : '', daysLeft < 8 ? 'middle' : '', daysLeft < 5 ? 'final' : '']"
      v-if="$store.state.user.current_account && $store.state.user.current_account.on_trial">
      Your free trial is ending in {{ daysLeft }} {{ daysLeft === 1 ? 'day' : 'days' }}
      <a href="/upgrade" class="xpl-button xpl-button--secondary">Upgrade</a>
    </div>
    <div v-if="!isProd && $store.getters.isUIAVendor" class="corner-mark">UIA<span v-if="$store.getters.isDevAccount"><br>DEV</span></div>
    <div class="bb-header fixed-top" :class="onTrial ? 'onTrial':''">
      <div class="xpl-secondary-nav">
        <nav>
          <ul>
            <li class="brand">
              <router-link to="/calendar" class="navbar-brand" :title="$store.getters.brandName">
                <img :src="$store.getters.brandLogo" :alt="$store.getters.brandName"
                     class="d-inline-block align-top logo">
              </router-link>
            </li>
            <li class="xpl-nav-item"
                :class="{'nav-item': true, 'active': isActive('calendar')}">
              <router-link class="nav-link"
                           :class="{'nav-item': true, 'active': isActive('calendar')}"
                           to="/calendar">Calendar
              </router-link>
            </li>
            <li class="xpl-nav-item"
                :class="{'nav-item': true, 'active': isActive('campaigns')}">
              <router-link class="nav-link"
                           :class="{'nav-item': true, 'active': isActive('campaigns')}"
                           to="/campaigns">Auto-Campaigns
              </router-link>
            </li>
            <li class="xpl-nav-item"
                :class="{'nav-item': true, 'active': isActive('plans')}">
              <router-link class="nav-link"
                           :class="{'nav-item': true, 'active': isActive('plans')}"
                           to="/plans">Scheduled Plans
              </router-link>
            </li>
            <li class="xpl-nav-item"
                :class="{'nav-item': true, 'active': isActive('contacts')}">
              <router-link class="nav-link"
                           :class="{'nav-item': true, 'active': isActive('contacts')}"
                           to="/contacts">Contacts
              </router-link>
            </li>
            <li class="xpl-nav-item"
                :class="{'nav-item': true, 'active': isActive('apps')}">
              <router-link class="nav-link"
                           :class="{'nav-item': true, 'active': isActive('apps')}"
                           to="/apps">Apps
              </router-link>
            </li>
            <li class="xpl-nav-item"
                :class="{'nav-item': true, 'active': isActive('inbox')}"
                v-if="$store.state.user.current_account && $store.state.user.current_account.sms_enabled">
            <span style="position: relative;">
              <span class="counter-sms" v-if="$store.state.user.current_account.sms_unread">{{
                  $store.state.user.current_account.sms_unread
                }}</span>
              <router-link class="nav-link"
                           :class="{'nav-item': true, 'active': isActive('inbox')}"
                           to="/inbox">SMS</router-link>
            </span>
            </li>
          </ul>
        </nav>
      </div>
      <ul class="navbar-nav ml-auto flex-row nav-right">
        <li class="nav-item dropdown mr-8" v-if="$store.state.user.current_account">
          <a class="nav-link dropdown-toggle f-075" href="#" @click.stop="toggleDropdown('account')"
             v-on-clickaway.stop="away">
            {{ $store.state.user.current_account ? $store.state.user.current_account.name : '' }}
            <span v-if="$store.getters.isBrandBotAdmin">({{ $store.state.user.current_account.id }})</span>
            <div :class="{ 'dropdown-menu': true, 'show': dropdown.account }">
              <router-link to="/settings/team" class="dropdown-item">Team</router-link>
              <router-link to="/settings/profile" class="dropdown-item">Settings</router-link>
              <router-link to="/account/assets" class="dropdown-item">Assets</router-link>
              <a target="_blank" href="https://docs.brandbot.com/en/" class="dropdown-item">Help Suite</a>
            </div>
          </a>
        </li>
        <li class="nav-item dropdown li-avatar">
          <div @click.stop="toggleDropdown('user')" v-on-clickaway.stop="away">
            <avatar
              :username="$store.state.user.first_name ? $store.state.user.first_name + ' ' + $store.state.user.last_name : $store.state.user.email"
              color="#ffffff" :rounded="true" :size="32" :customStyle="{ cursor: 'pointer' }"></avatar>
          </div>
          <div :class="{ 'dropdown-menu': true, 'dropdown-menu-right': true, 'show': dropdown.user }">
            <router-link class="dropdown-item" to="/settings/user_profile" @click.stop="toggleDropdown('user')">
              Settings
            </router-link>
            <a class="dropdown-item" href="#" @click.stop="switch_account = !switch_account">Switch Account</a>
            <a class="dropdown-item" href="/logout" @click.prevent="logout()">Sign Out</a>
          </div>
        </li>
      </ul>
      <AccountSwitch v-if="switch_account"/>
    </div>
  </div>
</template>
<script>
import * as api from '@/api';
import EventBus from '@/event-bus';
import Avatar from 'vue-avatar';
import moment from 'moment';
import {directive as onClickaway} from 'vue-clickaway';
import AccountSwitch from './AccountSwitch';

export default {
  directives: {
    onClickaway,
  },
  components: {
    Avatar,
    AccountSwitch,
  },
  data() {
    return {
      isProd: process.env.NODE_ENV === 'production',
      gitBranch: process.env.VUE_GIT_BRANCH || '?',
      navbar_open: false,
      switch_account: false,
      onTrial: this.$store.state.user.current_account?.on_trial === true,
      dropdown: {
        user: false,
        account: false,
      },
    };
  },
  created() {
    EventBus.$on('account_switch.close', () => {
      this.switch_account = false;
    });
  },
  methods: {
    away() {
      // eslint-disable-next-line
      for (const d in this.dropdown) {
        this.dropdown[d] = false;
      }
    },
    logout() {
      api.logoutUser()
        .then(() => {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('user');
          this.$store.dispatch('logout');
        });
    },
    toggleMenu() {
      this.navbar_open = !this.navbar_open;
    },
    toggleDropdown(type) {
      if (type === 'account') {
        this.dropdown.user = false;
      }

      if (type === 'user') {
        this.dropdown.account = false;
      }
      this.dropdown[type] = !this.dropdown[type];
    },
    closeDropdown(type) {
      this.dropdown[type] = false;
    },
    isActive(tab) {
      if (this.$route.path.indexOf(tab) > -1) {
        return true;
      }
      return false;
    },
  },
  computed: {
    daysLeft() {
      const a = moment(this.$store.state.user.current_account.trial_ends_at);
      const b = moment()
        .utc();
      return Math.abs(a.diff(b, 'days')) + 1;
    }
    },
  };
</script>
<style lang="scss" scoped>
.navbar {
  height: 3rem;
  padding: 0.5rem 1rem;
  font-size: 0.85rem;
  font-weight: 400;
  background-color: #ffffff;
  /* box-shadow: 0 0 4px 0 rgba(97, 112, 128, 0.11); */
}

li.brand {
  display: flex;
  align-items: center;
  justify-content: center;

  .navbar-brand {
    margin: 0 4px !important;
    text-align: center;

    img.logo {
      max-width: 30px;
    }
  }
}

.banner + .navbar {
  top: 2.5rem;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .875rem;
  color: #ffffff;
  background-color: var(--xpl-color-action-warning-dm);
  padding: 0;
}

.banner.start {
  background-color: #7DADC8;
}

.banner.middle {
  background-color: #FFA75F;
}

.banner.final {
  background-color: #EC6B6B;
}

.banner a {
  margin-left: 1rem;
  max-height: 38px;
}

.navbar-collapse.show {
  background: #fff;
}

.navbar-collapse.show {
  padding-left: 1rem;
}

.navbar img {
  height: 1.5625rem;
}

//.nav-item {
//  margin-right: 1rem;
//}

.nav-item.icon {
  margin-right: .5rem;
}

.nav-item.icon .material-icons {
  color: rgb(205, 215, 226);
  transition: all 300ms ease-in-out;
}

.nav-item.icon:hover .material-icons,
.nav-item.icon.active .material-icons {
  color: #2E4457;
}

.nav-link {
  font-weight: 400;
  color: #8B9BAC;
  transition: all 300ms ease-in-out;
}

.nav-link:hover,
.nav-item.active .nav-link {
  color: #2E4457;
}

.nav-item:last-child {
  margin-right: 0;
}

.dropdown-toggle {
  position: relative;

  .dropdown-menu.show {
    position: absolute;
    top: calc(100% + .3rem);
  }
}


.dropdown-avatar {
  padding: 0 !important;
  color: #fff !important;
  text-align: center;
  width: 2rem;
  height: 2rem;
  border-radius: 6px;
  background-color: rgb(56, 186, 231);
  line-height: 2rem;
  margin-left: 1rem;
  margin-right: .5rem;
}

.navbar-collapse.show .dropdown-avatar {
  margin: 0;
}

.counter-sms {
//  font-family: 'IcoMoon-Ultimate' !important;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: .75rem;
  font-size: .5rem;
  font-weight: 700;
  border-radius: 100%;
  background-color: var(--xpl-color-secondary-lm);
  color: #fff;
  position: absolute;
  top: 7px;
  right: 15px;
  padding: 1px 4px;
}


.flex-row {
  display: flex;
  flex-direction: row;
}

.nav-right {
  margin-right: 8px;
  margin-top: 4px;
  font-size: 0.875rem;
}

//navbar
.bb-header {
  display: flex;
  flex-direction: row;
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-left-inline-start: 0 !important;
  background: white;

  border-bottom: 1px solid rgb(229, 231, 235);

  &.onTrial {
    margin-top: 3rem;
  }
}

// logo
.navbar-brand {
  width: 42px;
  margin-right: 4px;
  margin-left: 2px;
}

// border(liner)
.xpl-secondary-nav nav {
  border: none !important;
}

.navbar-nav .dropdown-menu {
  position: absolute;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

.li-avatar {
  margin-top: 4px;
}

.f-075 {
  font-size: 0.75rem;
}

  .corner-mark {
    position: fixed;
    z-index: 5000;
    background: red;
    color: white;
    font-size: 11px;
    line-height: 12px;
    transform: rotate(-45deg);
    width: 68px;
    top: 4px;
    left: -18px;
    text-align: center;
  }
</style>
