<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner" v-if="!enrollment">
      Event Qualifier
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="!future_reservation || future_reservation === null" @click="future_reservation = true"></span>
            <span class="icon icon-radio-checked" v-if="future_reservation" @click="future_reservation = true"></span>
          </div>
          <div class="col-xs-10">
            <label @click="future_reservation = true">Has a Future Reservation</label>
            <div class="small text-muted">Isolates contacts who have a reservation in the future.</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="future_reservation || future_reservation === null" @click="future_reservation = false"></span>
            <span class="icon icon-radio-checked" v-if="future_reservation === false" @click="future_reservation = false"></span>
          </div>
          <div class="col-xs-10">
            <label @click="future_reservation = false">Does not have a Future Reservation</label>
            <div class="small text-muted">Isolates contacts who have not made a reservation in the future.</div>
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner" v-if="!enrollment">
      Time Frame
    </div>
    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'relative_future'" @click="timeframe = 'relative_future'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'relative_future'" @click="timeframe = 'relative_future'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_relative" @click="timeframe = 'relative_future'; comparison = null;">
              How far in the future
            </label>
            <small class="form-text text-muted">Select how many days ahead should we look at. We can only look up to 30 days in the future.</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="timeframe === 'relative_future'">
        <div class="form-inline">
          Every contact who made a reservation
          <Multiselect v-model="comparison" placeholder="Select a comparison" :options="comparisons_number_future" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" style="max-width: 13rem; min-width: 13rem; width: 13rem;" />&nbsp;
          <input type="number" name="relative_days" v-model.number="relative_days" class="form-control" min="1" max="30" style="display: inline-block; width: 5rem;" :disabled="!comparison" />&nbsp;
          days<span v-if="comparison && comparison.value !== 'more'">&nbsp;from now</span>.
        </div>
        <div class="small text-danger" v-if="relative_days > 30">We can only look up to 30 days in the future.</div>
      </div>
    </form>

    <div class="condition-banner">
      Qualifiers
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-checkbox-unchecked2" v-if="!filter_pricing_options" @click="filter_pricing_options = !filter_pricing_options"></span>
            <span class="icon icon-checkbox-checked2" v-if="filter_pricing_options" @click="filter_pricing_options = !filter_pricing_options"></span>
          </div>
          <div class="col-xs-10">
            <label @click="filter_pricing_options = !filter_pricing_options">
              Future Reservation with specific Pricing Options
            </label>
            <small class="form-text text-muted">Isolates future reservations with this specific type of pricing option(s).</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="filter_pricing_options">
        <div class="form-group">
          <label for="pricing_options">Only include future reservations with these pricing option(s)</label>
          <div class="multiselect-block">
            <Multiselect v-model="selected_pricing_options" placeholder="Select Pricing Option(s)" :options="pricing_options" label="name" track-by="id" :searchable="true" :allow-empty="true" :show-labels="false" :multiple="true" :close-on-select="false" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-checkbox-unchecked2" v-if="!filter_classes" @click="filter_classes = !filter_classes"></span>
            <span class="icon icon-checkbox-checked2" v-if="filter_classes" @click="filter_classes = !filter_classes"></span>
          </div>
          <div class="col-xs-10">
            <label @click="filter_classes = !filter_classes">
              Future Reservation with specific type of class
            </label>
            <small class="form-text text-muted">Isolates future reservations with this specific type of class.</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="filter_classes">
        <div class="form-group">
          <label for="pricing_options">Only include future reservations with these classes</label>
          <div class="multiselect-block">
            <Multiselect v-model="selected_classes" placeholder="Select Classes" :options="classes" label="name" track-by="name" :searchable="true" :allow-empty="true" :show-labels="false" :multiple="true" :close-on-select="false" />
          </div>
        </div>
      </div>
    </form>

      <div class="condition-banner">
        Location and Site ID (Optional)
      </div>

      <form @submit.prevent>
        <div class="form-group">
          <div class="row">
            <div class="col-xs-2">
              <span class="icon icon-radio-unchecked" v-if="misc_filtering === null || misc_filtering === true" @click="misc_filtering = false"></span>
              <span class="icon icon-radio-checked" v-if="misc_filtering === false" @click="misc_filtering = false"></span>
            </div>
            <div class="col-xs-10">
              <label for="misc_filtering_no" @click="misc_filtering = false">
                Select all locations and Site IDs
              </label>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-xs-2">
              <span class="icon icon-radio-unchecked" v-if="misc_filtering === null || misc_filtering === false" @click="misc_filtering = true"></span>
              <span class="icon icon-radio-checked" v-if="misc_filtering === true" @click="misc_filtering = true"></span>
            </div>
            <div class="col-xs-10">
              <label for="misc_filtering_yes" @click="misc_filtering = true">
                 Select specific locations or Site IDs
              </label>
            </div>
          </div>
        </div>

        <div class="options-inner" v-if="misc_filtering">
          <div class="form-group">
            <label class="form-control-label">Location</label>
            <div class="multiselect-block">
              <Multiselect v-model="location" placeholder="All Locations" label="location_name" track-by="location_id" :options="locations" :searchable="false" :allow-empty="true" :show-labels="false" />
            </div>
          </div>
        </div>
      </form>

      <div class="form-group">
        <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition" :disabled="relative_days > 30">Save this condition</button>
      </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';
  import VueDatepickerLocal from 'vue-datepicker-local';

  export default {
    props: ['locations', 'condition', 'editedRule', 'enrollment'],
    components: {
      Multiselect,
      VueDatepickerLocal,
    },
    data() {
      return {
        future_reservation: true,
        misc_filtering: false,
        location: null,
        relative_days: 0,
        timeframe: 'relative_future',
        comparison: null,
        comparisons_number_future: [{
          label: 'Within the next',
          value: 'more',
        }, {
          label: 'Exactly',
          value: 'exactly',
        }],
        filter_pricing_options: false,
        filter_classes: false,
        first_visit_type: null,
        selected_pricing_options: [],
        pricing_options: [],
        selected_classes: [],
        classes: [],
      };
    },
    created() {
      this.fetchPricingOptions();
      this.fetchClasses();

      if (this.editedRule) {
        this.timeframe = this.editedRule.timeframe;
        this.relative_days = this.editedRule.relative_days;

        if (this.timeframe === 'relative_future') {
          this.comparison = this.comparisons_number_future.filter(c =>
            c.value === this.editedRule.comparison,
          )[0];
        }

        this.filter_pricing_options = this.editedRule.filter_pricing_options;
        this.filter_classes = this.editedRule.filter_classes;
        this.selected_pricing_options = this.editedRule.pricing_options;
        this.selected_classes = this.editedRule.classes;

        this.future_reservation = this.editedRule.future_reservation;

        this.misc_filtering = this.editedRule.misc.filtering;
        if (this.misc_filtering) {
          this.location = this.locations.filter(l =>
            l.location_id === this.editedRule.misc.location,
          )[0];
        }
      }
    },
    methods: {
      fetchPricingOptions() {
        api.getPricingOptions().then((response) => {
          this.pricing_options = response.pricing_options;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchClasses() {
        api.getClasses().then((response) => {
          this.classes = response.classes;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          future_reservation: this.future_reservation,
          timeframe: this.timeframe,
          relative_days: this.relative_days,
          comparison: this.comparison ? this.comparison.value : null,
          filter_pricing_options: this.filter_pricing_options,
          filter_classes: this.filter_classes,
          pricing_options: this.selected_pricing_options,
          classes: this.selected_classes,
          misc: {
            filtering: this.misc_filtering,
            location: this.location ? this.location.location_id : null,
            site_id: null,
          },
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked,
  .icon-checkbox-checked2,
  .icon-checkbox-unchecked2 {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }
</style>

