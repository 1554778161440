<template>
  <div>
    <div data-test-header class="display-medium" style="margin-bottom: 2rem;">Form Builder</div>
    <div style="display: flex; justify-content: center; margin-bottom: 2rem;" v-if="form.settings.sync.active">
      <el-alert
       data-test-sync-alert
        style="max-width: 60%; width: 100%;"
        title="Vendor sync is enabled"
        description="Please go to Advanced Options to choose which fields to sync."
        type="warning"
        :closable="false"></el-alert>
    </div>
    <div class="container">
      <div data-test-standard-question-header class="display-small" style="margin-bottom: .875rem;">Standard Questions</div>

        <el-card
        data-test-standard-question-field-container
          class="field"

          :body-style="{ 'opacity': field.include ? 1 : 0.3 }"
          :key="field.index"
          v-for="field in standardFields"
          v-if="['address_1', 'address_2', 'state', 'city', 'zipcode', 'country'].indexOf(field.id) === -1">
          <StandardField
           :data-test-standard-question-field="field.id"
            :key="field.index"
            :fieldIndex="field.index"
            :placeholder="field.label"
            :include="field.id === 'email' ? null : field.include"
            :fieldName="field.id"
             :vendorSyncSettings="form.settings.sync"
            :homeLocations="autoAccountCreationLocations"
            />
        </el-card>
        <el-card class="field">
          <label  data-test-address-label>Address</label>
          <el-card
            data-test-standard-question-address-container
            :style="'border: none;'"
            :body-style="{ 'opacity': field.include ? 1 : 0.3 }"
            :shadow="'none'"
            :key="field.index"
            v-for="field in standardFields"
            v-if="['address_1', 'address_2', 'state', 'city', 'zipcode', 'country'].indexOf(field.id) > -1">
            <StandardField
            :data-test-standard-question-field-address="field.id"
              :shadow="'none'"
              :key="field.index"
              :fieldIndex="field.index"
              :placeholder="field.label"
              :include="field.include"
              :fieldName="field.id"
              :vendorSyncSettings="form.settings.sync"
              />
          </el-card>
        </el-card>

      <div style="margin-top: 1rem;"
         v-if="syncActive"
      >
       <el-card
        data-test-vendor-question-container
          class="field"
          :body-style="{ 'opacity': field.include ? 1 : 0.3 }"
          v-for="field in vendorFields"
          :key="field.index"
         v-if="field.id === 'birthday' && marianaVendorSyncField">
          <VendorField
           :data-test-vendor-field="field.id"
            :key="field.index"
            :fieldIndex="field.index"
            :include="field.include"
            :placeholder="field.label"
            :fieldName="field.id"
            :required="field.required"
             />
        </el-card>
       <el-card
        data-test-vendor-question-container
          class="field"
          :body-style="{ 'opacity': field.include ? 1 : 0.3 }"
          v-for="field in vendorFields"
          :key="field.index"
          v-if="field.id === 'home_location' && showHomeLocationField && marianaVendorSyncField">
          <VendorField
           :data-test-emergency-contact-question-vendor-field="field.id"
            :key="field.index"
            :fieldIndex="field.index"
            :include="field.include"
            :placeholder="field.label"
            :fieldName="field.id"
            :required="field.required"
            :vendorSyncSettings="form.settings.sync"
            :homeLocations="autoAccountCreationLocations"
             />
        </el-card>
        <el-card
        data-test-vendor-question-container
          class="field"
          :body-style="{ 'opacity': field.include ? 1 : 0.3 }"
          v-for="field in vendorFields"
          :key="field.index"
           v-if="field.id === 'waiver_signed' && form.settings.sync">
          <VendorField
           :data-test-emergency-contact-question-vendor-field="field.id"
            :key="field.index"
            :fieldIndex="field.index"
            :include="field.include"
            :placeholder="field.label"
            :fieldName="field.id"
            :required="field.required"
            :vendorSyncSettings="form.settings.sync"
            :homeLocations="autoAccountCreationLocations"
             />
        </el-card>
         <el-card
        data-test-vendor-question-container
          class="field"
          :body-style="{ 'opacity': field.include ? 1 : 0.3 }"
          v-for="field in vendorFields"
          :key="field.index"
           v-if="field.id === 'mt_opt_in_sms' && marianaVendorSyncField">
          <VendorField
           :data-test-emergency-contact-question-vendor-field="field.id"
            :key="field.index"
            :fieldIndex="field.index"
            :include="field.include"
            :placeholder="field.label"
            :fieldName="field.id"
            :required="field.required"
            :vendorSyncSettings="form.settings.sync"
            :homeLocations="autoAccountCreationLocations"
             />
        </el-card>
        <el-card
          data-test-vendor-question-container
          class="field"
          :body-style="{ 'opacity': field.include ? 1 : 0.3 }"
          v-for="field in vendorFields"
          :key="field.index"
           v-if="field.id === 'mt_opt_in_email' && marianaVendorSyncField">
          <VendorField
           :data-test-emergency-contact-question-vendor-field="field.id"
            :key="field.index"
            :fieldIndex="field.index"
            :include="field.include"
            :placeholder="field.label"
            :fieldName="field.id"
            :required="field.required"
            :vendorSyncSettings="form.settings.sync"
            :homeLocations="autoAccountCreationLocations"
             />
        </el-card>
         <el-card
          data-test-vendor-question-container
          class="field"
          :body-style="{ 'opacity': 1 }"
          v-for="field in vendorFields"
          :key="field.index"
           v-if="field.id === 'mt_terms_and_service' && marianaVendorSyncField">
          <VendorField
           :data-test-emergency-contact-question-vendor-field="field.id"
            :key="field.index"
            :fieldIndex="field.index"
            :include="field.include"
            :placeholder="field.label"
            :fieldName="field.id"
            :required="field.required"
            :vendorSyncSettings="form.settings.sync"
             />
        </el-card>
        <el-card
        data-test-vendor-question-container
          class="field"
          :body-style="{ 'opacity': field.include ? 1 : 0.3 }"
          v-for="field in vendorFields"
          :key="field.index"
          v-if="['emg_name', 'emg_email', 'emg_phone', 'emg_relationship', 'home_location', 'waiver_signed', 'mt_opt_in_email', 'mt_opt_in_sms', 'mt_terms_and_service'].indexOf(field.id) === -1 && $store.getters.currentUserActiveVendor === 'mindbody'">
          <VendorField
           :data-test-vendor-field="field.id"
            :key="field.index"
            :fieldIndex="field.index"
            :include="field.include"
            :placeholder="field.label"
            :fieldName="field.id"
            :required="field.required"
             />
        </el-card>

        <el-card class="field" v-if="$store.getters.currentUserActiveVendor === 'mindbody'">
          <label data-test-mindbody-emergency-contact-label >Emergency Contact</label>
          <el-card data-test-mindbody-emergency-contact-question-container
            :style="'border: none;'"
            :body-style="{ 'opacity': field.include ? 1 : 0.3 }"
            :shadow="'none'"
            :key="field.index"
            v-for="field in vendorFields"
            v-if="['emg_name', 'emg_email', 'emg_phone', 'emg_relationship'].indexOf(field.id) > -1">
              <VendorField
              :data-test-emergency-contact-question-mindbody-vendor-field="field.id"
                :shadow="'none'"
                :key="field.index"
                :fieldIndex="field.index"
                :include="field.include"
                :placeholder="field.label"
                :fieldName="field.id"
                :required="field.required" />
            </el-card>
          </el-card>
      </div>

      <div data-test-custom-question-header class="display-small" style="margin-bottom: .875rem; margin-top: 2rem;">Custom Questions</div>
        <div data-test-custom-question-container class="field" v-for="field in customFields" v-if="!addCustom && !editingField">
          <CustomField
            :key="field.index"
            :fieldIndex="field.index"
            :type="field.type"
            :placeholder="field.label"
            :options="field.options"
            :low="field.low"
            :high="field.high"
            :required="field.required" />
          </div>
      <el-form style="margin-top: 2rem;" @submit.native.prevent>
        <el-form-item v-if="!addCustom">
          <el-button data-test-add-custom-question-button type="secondary" @click.stop="addCustom = true">
            Add a Custom Question to this Form
          </el-button>
        </el-form-item>
        <el-form-item v-if="addCustom && !fieldType">
          <label data-test-add-custom-question-option-header >What kind of question would you like to add?</label>
          <div>
            <el-button data-test-add-custom-question-option-dropdown-button size="small" @click.stop="pickFieldType('dropdown')">Dropdown</el-button>
            <el-button data-test-add-custom-question-option-multiselect-button size="small" @click.stop="pickFieldType('multi')">Multi Select Checkboxes</el-button>
            <el-button size="small" data-test-add-custom-question-option-textinput-button @click.stop="pickFieldType('input')">Text Input</el-button>
            <el-button data-test-add-custom-question-option-rating-button size="small" @click.stop="pickFieldType('rating')">Rating</el-button>
          </div>
        </el-form-item>
        <InputText v-if="fieldType === 'input'" :field="editingField" />
        <Dropdown v-if="fieldType === 'dropdown'" :field="editingField" />
        <Multiselect v-if="fieldType === 'multi'" :field="editingField" />
        <Rating v-if="fieldType === 'rating'" :field="editingField" />
      </el-form>

      <el-card style="margin-top: 1rem;">
        <div data-test-call-to-action-header class="display-small" style="margin-bottom: 1rem;">Call to Action Design</div>
        <el-form style="display: block;">
          <el-form-item>
            <label data-test-call-to-action-submit-button-label>Button Text</label>
            <el-input data-test-call-to-action-submit-button-text v-model="template.btn_text" placeholder="Submit" />
          </el-form-item>
          <el-form-item>
            <label data-test-call-to-action-button-color-label >Button Color</label>
            <div>
              <el-color-picker data-test-call-to-action-color-picker v-model="template.btn_color"></el-color-picker>
            </div>
          </el-form-item>
          <el-form-item>
            <label>Use reCAPTCHA (anti-robot, anti-spam tool)</label><br/>
            <el-radio-group v-model="template.use_recaptcha">
              <el-radio :label="true" border>Yes, protect this form</el-radio>
              <el-radio :label="false" border>No</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button data-test-call-to-action-save button-color-label type="primary" @click.stop="saveCallToAction">Save</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>
<script>
import * as api from '@/api';
import EventBus from '@/event-bus';
import StandardField from './Fields/StandardField';
import VendorField from './Fields/VendorField';
import CustomField from './Fields/CustomField';
import InputText from './Fields/CustomFields/InputText';
import Dropdown from './Fields/CustomFields/Dropdown';
import Multiselect from './Fields/CustomFields/Multiselect';
import Rating from './Fields/CustomFields/Rating';

export default {
  props: ['form'],
  components: {
    StandardField,
    VendorField,
    CustomField,
    InputText,
    Dropdown,
    Multiselect,
    Rating,
  },
  data() {
    const recaptcha_key = process.env.VUE_APP_RECAPTCHA_EMBED_SITE_KEY;
    const recaptcha_hosted_key = process.env.VUE_APP_RECAPTCHA_HOSTED_SITE_KEY;
    return {
      syncActive: false,
      addCustom: false,
      fieldType: null,
      editingField: null,
      fields: [],
      template: {
        btn_color: '',
        btn_text: 'Submit',
        use_recaptcha: false,
        recaptcha_key: recaptcha_key,
        recaptcha_hosted_key: recaptcha_hosted_key,
      },
      autoAccountCreationLocations: [],
    };
  },
  mounted() {
    EventBus.$on('forms.update.field', (fieldIndex, value) => {
      this.fields[fieldIndex].include = value;
      this.saveFields();
    });

    EventBus.$on('forms.update.required', (fieldIndex, value) => {
      this.fields[fieldIndex].required = value;
      this.saveFields();
    });

    EventBus.$on('field.commit', (payload) => {
      this.addCustom = false;
      this.fieldType = null;
      this.editingField = null;
      const field = {
        order: this.fields.length + 1,
        id: `custom_${this.slugify(payload.label)}`,
        type: payload.type,
        label: payload.label,
        required: payload.required,
        include: true,
      };

      if (payload.options) {
        field.options = payload.options;
      }
      // high  is the upper limit for the rating field
      // low  is the lower limit for the rating field
      if (payload.low && payload.high) {
        field.low = payload.low;
        field.high = payload.high;
      }

      if (Object.prototype.hasOwnProperty.call(payload, 'fieldIndex')) {
        this.$set(this.fields, this.fields.splice(payload.fieldIndex, 1, field));
      } else {
        this.fields.push(field);
      }
      this.saveFields();
    });

    EventBus.$on('field.edit', (fieldIndex) => {
      this.addCustom = true;
      this.fieldType = this.fields[fieldIndex].type;
      this.editingField = this.fields[fieldIndex];
      this.editingField.fieldIndex = fieldIndex;
    });

    EventBus.$on('field.remove', (fieldIndex) => {
      this.addCustom = false;
      this.fieldType = null;
      this.editingField = null;
      this.$delete(this.fields, fieldIndex);
      this.saveFields();
    });

    EventBus.$on('field.cancel', () => {
      this.addCustom = false;
      this.fieldType = null;
      this.editingField = null;
    });
  },
  created() {
    const fields = JSON.parse(this.form.fields);
    this.fields = fields.sort((one, two) => one.order - two.order);

    this.syncActive = this.form.settings.sync.active;
    this.template.btn_text = this.form.settings.template.btn_text;
    this.template.btn_color = this.form.settings.template.btn_color;
    this.template.use_recaptcha = this.form.settings.template.use_recaptcha || false;
    if (this.$store.getters.currentUserActiveVendor === 'marianatek') {
      this.fetchLinkedLocations();
    }
  },
  beforeDestroy() {
    EventBus.$off('forms.update.field');
    EventBus.$off('forms.update.required');
    EventBus.$off('field.commit');
    EventBus.$off('field.edit');
    EventBus.$off('field.remove');
    EventBus.$off('field.cancel');
  },
  computed: {
    marianaVendorSyncField() {
      return this.$store.getters.currentUserActiveVendor === 'marianatek' && this.form.settings.sync;
    },
    showHomeLocationField() {
      // Show homelocation field if the vendor is MT
      // and no default location is selected
      if (
        this.form.settings.sync.home_location &&
        !this.form.settings.sync.home_location.default
      ) {
        return true;
      }
      return false;
    },
    standardFields() {
      const standardFields = this.fields;

      // We do this to maintain true index in fields array
      standardFields.forEach((f, k) => {
        standardFields[k].index = k;
      });

      return standardFields
        .filter(
          f =>
            [
              'email',
              'first_name',
              'last_name',
              'phone',
              'address_1',
              'address_2',
              'city',
              'state',
              'zipcode',
              'country',
            ].indexOf(f.id) > -1,
        )
        .sort((one, two) => one.order - two.order);
    },
    customFields() {
      const customFields = this.fields;

      // We do this to maintain true index in fields array
      customFields.forEach((f, k) => {
        customFields[k].index = k;
      });

      return customFields
        .filter(f => f.id.indexOf('custom_') > -1)
        .sort((one, two) => one.order - two.order);
    },
    vendorFields() {
      const vFields = [
        'password',
        'birthday',
        'emg_email',
        'emg_name',
        'emg_phone',
        'emg_relationship',
        'referral_source',
        'home_location',
        'waiver_signed',
        'mt_opt_in_email',
        'mt_opt_in_sms',
        'mt_terms_and_service',
      ];

      const vendorFields = this.fields;

      // We do this to maintain true index in fields array
      vendorFields.forEach((f, k) => {
        vendorFields[k].index = k;
      });

      return vendorFields
        .filter(f => vFields.indexOf(f.id) > -1)
        .sort((one, two) => one.order - two.order);
    },
  },
  methods: {
    async fetchLinkedLocations() {
      try {
        const response = await api.getMTLinkedLocations();
        this.autoAccountCreationLocations = response.locations;
      } catch (err) {
        this.$toasted.error(err.message);
      }
    },
    changeOrder(field, direction) {
      if (direction === 'up' && field.order === 1) {
        return;
      }

      if (field.id === 'address') {
        return;
      }

      const currentField = this.fields[field.index];

      if (direction === 'down') {
        this.$set(currentField, 'order', currentField.order + 1);
      }

      if (direction === 'up') {
        this.$set(currentField, 'order', currentField.order - 1);
      }
    },
    async saveCallToAction() {
      const payload = {
        settings: {
          template: this.form.settings.template,
        },
      };

      payload.settings.template.btn_color = this.template.btn_color;
      payload.settings.template.btn_text = this.template.btn_text;
      payload.settings.template.use_recaptcha = this.template.use_recaptcha;
      payload.settings.template.recaptcha_key = this.template.recaptcha_key;
      payload.settings.template.recaptcha_hosted_key = this.template.recaptcha_hosted_key;

      try {
        const response = await api.updateForm(this.form.id, payload);
        this.$message.success(response.message);
      } catch (err) {
        this.$message.error(err.message);
      }
    },
    async saveFields(hideNotification) {
      const payload = {
        fields: JSON.stringify(this.fields),
      };
      try {
        const response = await api.updateForm(this.form.id, payload);
        if (!hideNotification) {
          this.$message.success(response.message);
        }
        EventBus.$emit('form:refresh');
      } catch (err) {
        this.$message.error(err.message);
      }
    },
    pickFieldType(type) {
      this.fieldType = type;
      this.addCustom = true;
    },
    slugify(str) {
      str = str.replace(/^\s+|\s+$/g, '');
      str = str.toLowerCase();

      const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
      const to = 'aaaaaaeeeeiiiioooouuuunc------';

      // eslint-disable-next-line
      for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

      return str;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.container .field + .field {
  margin-top: 1rem;
}

.container .el-card .el-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container .el-card .el-form .field {
  width: 70%;
}
</style>
