<template>
  <transition name="input">
    <div>
      <el-form>
        <div class="d-flex mb-10">
          <el-form-item class="speciic-choice-item">
             <el-radio
              name="any-option"
              class="desc-text any"
              v-model="selectedOption"
              @change="$emit('update:filterOption', selectedOption)"
              label="all"
              border
              v-if="showAny"
            >
              Any {{ anyLabel}}
            </el-radio>
            <el-radio
              class="desc-text"
              name="any-specific-option"
              v-model="selectedOption"
              @change="$emit('update:filterOption', selectedOption)"
              label="any"
              border
            >
              {{ descLabel }}
            </el-radio>
            <el-radio
              class="desc-text"
              name="exclude-option"
              v-model="selectedOption"
              @change="$emit('update:filterOption', selectedOption)"
              label="except"
              border
            >
              Any except the following
            </el-radio>
          </el-form-item>
        </div>
      </el-form>
      <el-select
        @change="$emit('update:choice', selectedChoice)"
        v-if="selectedOption != 'all'"
        v-model="selectedChoice"
        class="max-width"
        multiple
        filterable
        collapse-tags
        clearable
        size="large"
        :value-key="selectKey"
        placeholder="Select"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.name"
          :value="item"
        >
        </el-option>
      </el-select>
    </div>
  </transition>
</template>
<script>
  export default {
    props: [
      'descLabel',
      'filterOption',
      'choice',
      'options',
      'selectKey',
      'showAny',
      'anyLabel',
    ],
    data() {
      return {
        selectedOption: this.filterOption,
        selectedChoice: this.choice,
      };
    },
  };
</script>
<style lang="scss" scoped>
@import "src/scss/global/_spacing.scss";
@import "src/scss/global/_new_main.scss";
.max-width {
  margin: 8px 0 8px 0;
  width: 100% !important;
}
.mb-10 {
  margin-bottom: 10px;
}
.inner-field-padding {
  padding-left: 24px;
}
 .d-flex /deep/ .el-radio.is-bordered.is-checked {
  border-color: var(--xpl-color-secondary-lm);
}
.d-flex /deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #606266;
}
.d-flex /deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #606266;
}
.d-flex /deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: var(--xpl-color-secondary-lm);
  background: var(--xpl-color-secondary-lm);
}
 .desc-text {
  margin: 4px 0 4px 0;
  margin-left: 0;
  display: block;
}
.el-form-item /deep/ .el-radio.is-bordered {
  margin-left: 0 !important;
}

.el-form-item {
  margin-bottom: 0;
}


.max-width {
  margin: 8px 0 8px 0;
  width: 100% !important;
}
.max-width /deep/ .el-input.is-focus .el-input__inner {
  border-color: var(--xpl-color-secondary-lm);
}

</style>
