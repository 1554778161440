<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Name this Auto-Campaign
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <label for="name">Name</label>
                <input type="text" id="name" v-model="name" class="form-control" placeholder="Name" @keyup.enter="createCampaign" />
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="submit" class="xpl-button xpl-button--primary" ref="createButton" @click.stop="createCampaign">Create</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['template'],
    data() {
      return {
        name: this.template ? this.template.name : '',
      };
    },
    methods: {
      closeModal() {
        EventBus.$emit('auto_campaigns.create_modal.close');
      },
      createCampaign() {
        if (this.name.length < 2) {
          return;
        }

        const originalInner = this.$refs.createButton.innerHTML;

        this.$refs.createButton.disabled = true;
        this.$refs.createButton.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';
        if (!this.template) {
          const payload = {
            name: this.name,
            type: 'auto',
            template: JSON.stringify({
              payload: {},
            }),
          };
          // Sending an empty payload template automatically sets no_goals = true
          api.createCampaign(payload).then((response) => {
            this.$toasted.success(response.message);
            if (this.$intercom) {
              this.$intercom.trackEvent('Created a Campaign', {
                'Automation Name': response.campaign.name,
                'Created By': this.$store.state.user.email,
              });
            }
            this.$router.push(`/campaigns/${response.campaign.uuid}`);
            this.$refs.createButton.innerHTML = originalInner;
            this.$refs.createButton.disabled = false;
          }).catch((err) => {
            this.$refs.createButton.innerHTML = originalInner;
            this.$refs.createButton.disabled = false;
            this.$toasted.error(err.message);
          });
        } else {
          const payload = {
            name: this.name,
            type: 'auto',
            template: JSON.stringify(this.template),
          };
          api.createCampaign(payload).then((response) => {
            this.$toasted.success(response.message);
            if (this.$intercom) {
              this.$intercom.trackEvent('Created a Campaign', {
                'Automation Name': response.campaign.name,
                'Created By': this.$store.state.user.email,
              });
            }
            this.$router.push(`/campaigns/${response.campaign.uuid}`);
            this.$refs.createButton.innerHTML = originalInner;
            this.$refs.createButton.disabled = false;
          }).catch((err) => {
            this.$refs.createButton.innerHTML = originalInner;
            this.$refs.createButton.disabled = false;
            this.$toasted.error(err.message);
          });
        }
      },
    },
  };
</script>
