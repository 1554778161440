<template>
  <el-container class="condition-container">
    <div class="sidebar-inner" style="width: 100%">
      <BooleanField
        fieldIndex="0"
        title="Customers Who Have / Do Not Have?"
        fieldStep="1"
        yesText="Has"
        noText="Does not have"
        :selection.sync="hasSelection"
        :showLabel="false"
        descLabel=""
        id="23"
      />
      <CountField
        fieldIndex="1"
        fieldStep="2"
        :countOne.sync="countOne"
        :countTwo.sync="countTwo"
        :selection.sync="selection"
        title="How Many Visits Remaining?"
        countType="Visits"
        moreThanText="This includes your selection above. (I.e. “At least 3 visits” equals 3 or more visits)"
        lessThanText="This is strictly lower than your selection (i.e. “Less than 5” equals 0 through 4)"
        betweenText="This includes both the lower and upper selection (I.e “Between 2 and 4 visits” equals 2, 3, or 4 visits)"
        id="14"
      />
      <BooleanField
        fieldIndex="2"
        title="Which Credit Packages?"
        fieldStep="3"
        yesText="All credit packages"
        noText="Specific types of credit packages"
        :selection.sync="selectionCredit"
        :showLabel="true"
        descLabel="Options include credit package and home location"
        id="23"
      />
      <div v-if="!selectionCredit" class="condition-banner-style">
        Drill Down Options
      </div>
      <MultiSelectField
        v-if="!selectionCredit"
        fieldIndex="3"
        title="Which Credit Package Types?"
        fieldStep="4"
        anyLabel="Any credit package"
        specificLabel="Specific credit package(s)"
        specificAnyLabel="Any of the following credit packages"
        specificExclusionLabel="Any except the following"
        :selectedOptions.sync="selectedPackages"
        :specificChoice.sync="specificCreditPackages"
        :exclusionChoice.sync="packageExclusionOption"
        :options="creditPackages"
        selectKey="id"
        id="455"
      />
    </div>
    <div v-if="showSaveBtn" class="save-condition-container">
      <el-button
        class="save-condition-btn"
        type="primary"
        @click="commitCondition"
        >Save</el-button
      >
    </div>
  </el-container>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import BooleanField from '@/components/Contacts/ContactLists/Conditions/Fields/BooleanField';
  import CountField from '@/components/Contacts/ContactLists/Conditions/Fields/CountField';
  import MultiSelectField from '@/components/Contacts/ContactLists/Conditions/Fields/MultiSelectField';

  export default {
    props: ['condition', 'editedRule', 'goalList'],
    components: {
      BooleanField,
      CountField,
      MultiSelectField,
    },
    data() {
      return {
        hasSelection: true,
        selection: null,
        countOne: 1,
        countTwo: 2,
        selectionCredit: true,
        specificCreditPackages: 'any',
        packageExclusionOption: 'any',
        selectedPackages: [],
        creditPackages: [],
      };
    },
    computed: {
      showSaveBtn() {
        // Check the type in the count field is selected
        if (!this.selection) {
          return false;
        }
        const creditPackagesAreSelected = this.selectedPackages.length;
        // Check if specific credit packs are being filtered and credit pack(s) are selected
        if (!this.selectionCredit && this.specificCreditPackages === 'specific') {
          return creditPackagesAreSelected;
        }
        return true;
      },
    },
    created() {
      this.fetchCreditPackages();
      if (this.editedRule) {
        this.countOne = this.editedRule.first_bound;
        this.countTwo = this.editedRule.second_bound;
        this.selection = this.editedRule.comparison;

        this.selectionCredit = this.editedRule.all_credit_packages;

        this.selectedPackages = this.editedRule.credit_packages;
        this.packageExclusionOption = this.editedRule.credit_package_exclusion;
        this.specificCreditPackages = this.editedRule.credit_package_option;
        if (Object.prototype.hasOwnProperty.call(this.editedRule, 'has_visits_remaining')) {
          this.hasSelection = this.editedRule.has_visits_remaining;
        } else {
          this.hasSelection = true;
        }
      }
    },
    methods: {
      async fetchCreditPackages() {
        try {
          this.creditPackages = await api.MTCreditsWithoutClassPass();
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          has_visits_remaining: this.hasSelection,
          comparison: this.selection,
          first_bound: this.countOne,
          second_bound: this.countTwo,
          all_credit_packages: this.selectionCredit,
          credit_packages: this.selectedPackages,
          credit_package_exclusion: this.packageExclusionOption,
          credit_package_option: this.specificCreditPackages,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
@import "src/scss/global/_spacing.scss";
@import "src/scss/global/_new_main.scss";
  .field-container {
  margin-top: 28px;
}
.option-body {
  margin-top: 20px;
}

.card-condition {
  background-color: #FFFFFF;
  border: solid 1px var(--xpl-color-gray-400);
  box-shadow: none;
  border-radius: 0;
  cursor: auto;
}

.card-condition:hover {
  background-color: #FFFFFF;
  cursor: auto;
}
.field-step {
  height: 1.5rem;
  width: 1.5rem;
  background-color: $bb-color-blue-v2;
  font-family: $primary-bb-font-family;
  font-size: $base-font-size;
  color: #FFFFFF;
  border-radius: 50%;
  text-align: center;
  margin: $space-base-inline;
}
.field-title {
  font-family: $primary-bb-font-family;
  font-weight: 700;
  font-size: $base-font-size;
  color: $base-color-black-v2;
  line-height: $loose-medium-line-height;
}

.radio-btn {
  margin-top: $space-extra-tight;
  margin-bottom: $space-extra-tight;
}

.field-label-text  {
  display: block;
}
.field-label-text /deep/ .el-radio__label {
  font-family: $primary-bb-font-family !important;
  font-size: $base-loose-font-size !important;
  color: $base-color-black-v2 !important;
  line-height: $loose-line-height !important;
  padding-left: 8px;
}
.field-label-text /deep/ .el-radio__inner {
  background-color: #F2F4F6 !important;
  border: 1px solid #A6B2BF !important;
  height: 16px;
  width: 16px;
}
.field-label-text /deep/  .el-radio__input.is-checked .el-radio__inner::after {
  background-color: var(--xpl-color-secondary-lm) !important;
  width: 12px;
  height: 12px;
}
.condition-container {
  background-color: #F4F8FA;
  height: 100%;
  width: 100%;
}

.field-container:last-of-type{
  padding-bottom: 100px;
}
.sidebar-inner {

  height: 100%;
  position: relative;
  padding-top: 0;
  background-color: #F4F8FA;
  padding-left: 2.625rem;
  padding-right: 2.25rem;
  padding-bottom: 0;
  width: 100%;
}
.save-condition-btn {
  width: 729px;
  margin: 16px 12px 16px 12px;
}
.save-condition-container {
  // box-shadow: 0 -6px 7px 0 rgba(0, 0, 0, 0.05);
  padding: 0;
  margin: 0;
  z-index: 1;
}
.condition-banner-style {
  background-color: #F4F8FA !important;
  margin-top: 13px !important;
  padding-bottom: 0;
}

</style>
