<template>
  <div>
    <div v-if="this.webHook !== 'Webhook' && this.webHook !== 'MT Action'" class="title">
      When should this {{ type === 'phone_call' ? 'phone call reminder' : type }} {{ type === 'email' || type === 'sms' ? 'send' : 'be created' }}
    </div>

     <div v-if="this.webHook === 'Webhook'" class="title">
      When should this Webhook send
    </div>

      <div v-if="this.webHook === 'MT Action'" class="title">
      When should this Action send
    </div>

    <div class="time" v-if="timeRule.choice !== 'behavior'">
      <div v-if="type === 'task' || type === 'phone_call' || type === 'tag'">
        <span v-if="timeRule.relative !== 'Immediately'"><strong>{{ timeRule.relative_count }} {{ timeRule.relative.toLowerCase() }}</strong></span>
        <span v-if="timeRule.relative === 'Immediately'"><strong>{{ timeRule.relative }}</strong></span>
        after a contact enters this auto-campaign
      </div>
      <div v-if="type === 'email' || type === 'sms'">
        <span v-if="timeRule.relative !== 'Immediately'"><strong>{{ timeRule.relative_count }} {{ timeRule.relative.toLowerCase() }}</strong></span>
        <span v-if="timeRule.relative === 'Immediately'"><strong>{{ timeRule.relative }}</strong></span>
        after a contact enters this auto-campaign
      </div>
    </div>
    <div class="time" v-if="timeRule.choice === 'behavior'">
      Whenever a contact matches the rule
    </div>


    <div v-if="this.webHook !== 'Webhook' && this.webHook !== 'MT Action'" class="title">
      Who should get this {{ type === 'phone_call' ? 'phone call reminder' : type }}
    </div>
    <div v-if="this.webHook == 'Webhook'" class="title">
      Who should get this Webhook
    </div>
    <div v-if="this.webHook == 'MT Action'" class="title">
      Who should get this Action
    </div>
    <div class="time" v-if="timeRule.choice === 'relative'">
      Every contact who has not met any goal yet
    </div>
    <div v-if="timeRule.choice === 'both' && behaviorRule.rule && behaviorRule.rule.length && behaviorRule.rule[0].condition.quick">
      <span class="behavior" v-if="behaviorRule.rule[0].condition.uuid === '929b071b-bae1-47c9-92a4-ad80c9669e76'">Only the contacts who have not visited <strong>any of the following class/appointment types</strong>:</span>
      <div class="tags" v-if="behaviorRule.rule[0].condition.uuid === '929b071b-bae1-47c9-92a4-ad80c9669e76' && behaviorRule.rule[0].classes && behaviorRule.rule[0].classes.length">
        <span class="tag" v-for="c in behaviorRule.rule[0].classes">{{ c.name }}</span>
      </div>

      <span class="behavior" v-if="behaviorRule.rule[0].condition.uuid === '82584fc8-ba77-4f57-982a-68f8a52828cb'">
        <span v-if="behaviorRule.rule[0].comparison_visit !== 'between'">
          Only the contacts who have visited
          <strong v-if="behaviorRule.rule[0].comparison_visit !== 'exactly' && behaviorRule.rule[0].comparison_visit !== 'more'">{{ behaviorRule.rule[0].comparison_visit }} than {{ behaviorRule.rule[0].visit_number_start }} times</strong>
          <strong v-if="behaviorRule.rule[0].comparison_visit === 'more'">at least {{ behaviorRule.rule[0].visit_number_start }} times</strong>
          <strong v-if="behaviorRule.rule[0].comparison_visit === 'exactly'">{{ behaviorRule.rule[0].comparison_visit }} {{ behaviorRule.rule[0].visit_number_start }} times</strong> since entering this auto-campaign with
        </span>
        <span v-if="behaviorRule.rule[0].comparison_visit === 'between'">
          Only the contacts who have visited <strong>{{ behaviorRule.rule[0].comparison_visit }} {{ behaviorRule.rule[0].visit_number_start }} and {{ behaviorRule.rule[0].visit_number_end }} times</strong> since entering this auto-campaign with
        </span>
        <span v-if="behaviorRule.rule[0].visit_type === 'any'">any pricing options.</span>
        <span v-if="behaviorRule.rule[0].visit_type === 'pricing_option'">the following pricing options:</span>
        <div class="tags">
          <span class="tag" v-for="p in behaviorRule.rule[0].pricing_options">{{ p.name }}</span>
        </div>
      </span>

      <span class="behavior" v-if="behaviorRule.rule[0].condition.uuid === 'd323c020-0569-4f1e-ab1d-f56672d66610'">Only the contacts who are assigned <strong>any of the following memberships</strong>:
        <div class="tags">
          <span class="tag" v-for="m in behaviorRule.rule[0].membership_type">{{ m.type }}</span>
        </div>
      </span>

      <span class="behavior" v-if="behaviorRule.rule[0].condition.uuid === '2d5f2b5c-1620-451a-96fb-0f96bab671e0'">Only the contacts who have <strong>any of the following plans</strong>:
        <div class="tags">
          <span class="tag" v-for="m in behaviorRule.rule[0].plans">{{ m }}</span>
        </div>
      </span>

      <span class="behavior" v-if="behaviorRule.rule[0].condition.uuid === 'd2b29794-384f-4e75-9ac8-5fe1b4d9c92f'">Only the contacts who have not visited or made a purchase <strong>in the last {{ behaviorRule.rule[0].relative_days }} days</strong>
      </span>

      <span class="behavior" v-if="behaviorRule.rule[0].condition.uuid === '9ebf3698-2407-4d70-97f1-afa09adddac9'">Only the contacts who have not <strong>{{ behaviorRule.rule[0].action.toLowerCase() }}ed any of the following emails:</strong>
        <div class="tags">
          <span class="tag" v-for="email in behaviorRule.rule[0].emails">{{ email.name }}</span>
        </div>
      </span>

       <span class="behavior" v-if="behaviorRule.rule[0].condition.uuid === '1e6a8fb2-8205-41fa-87a8-a46a0c2327dd'">
        Contacts who have visited your business <strong>{{ behaviorRule.rule[0].visit_number_start }} times ever</strong>
      </span>
    </div>

    <div v-if="timeRule.choice === 'behavior' && behaviorRule.rule && behaviorRule.rule.length && behaviorRule.rule[0].condition.quick">
      <span class="behavior" v-if="behaviorRule.rule[0].condition.uuid === '82584fc8-ba77-4f57-982a-68f8a52828cb'">
        Contacts who have visited
        <strong v-if="behaviorRule.rule[0].comparison_visit !== 'exactly' && behaviorRule.rule[0].comparison_visit !== 'more'">{{ behaviorRule.rule[0].comparison_visit }} than {{ behaviorRule.rule[0].visit_number_start }} times&nbsp;</strong>
        <strong v-if="behaviorRule.rule[0].comparison_visit === 'more'">at least {{ behaviorRule.rule[0].visit_number_start }} times&nbsp;</strong>
        <strong v-if="behaviorRule.rule[0].comparison_visit === 'exactly'">{{ behaviorRule.rule[0].comparison_visit }} {{ behaviorRule.rule[0].visit_number_start }} times</strong> since entering the auto-campaign with
        <strong>
          <span v-if="behaviorRule.rule[0].visit_type === 'any'">any pricing options</span>
          <span v-if="behaviorRule.rule[0].visit_type === 'pricing_option'">the following pricing options:</span>
        </strong>
        <div class="tags">
          <span class="tag" v-for="p in behaviorRule.rule[0].pricing_options">{{ p.name }}</span>
        </div>
      </span>

      <span class="behavior" v-if="behaviorRule.rule[0].condition.uuid === '2d5f2b5c-1620-451a-96fb-0f96bab671e0'">Only the contacts who have <strong>any of the following plans</strong>:
        <div class="tags">
          <span class="tag" v-for="m in behaviorRule.rule[0].plans">{{ m }}</span>
        </div>
      </span>

      <span class="behavior" v-if="behaviorRule.rule[0].condition.uuid === 'd2b29794-384f-4e75-9ac8-5fe1b4d9c92f'">Contacts who have not interacted with your business in <strong>{{ behaviorRule.rule[0].relative_days }} days</strong>
      </span>

      <span class="behavior" v-if="behaviorRule.rule[0].condition.uuid === '8cfb3bd2-1742-4be3-9ff6-51a9a42241d8'">
        Contacts who have visited your business <strong>{{ behaviorRule.rule[0].visit_number_start }} times ever</strong>
      </span>

      <span class="behavior" v-if="behaviorRule.rule[0].condition.uuid === '1e6a8fb2-8205-41fa-87a8-a46a0c2327dd'">
        Contacts who have visited your business <strong>{{ behaviorRule.rule[0].visit_number_start }} times ever</strong>
      </span>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['type', 'timeRule', 'behaviorRule', 'webHook'],
  };
</script>
<style lang="scss" scoped>
  .tags {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .tags .tag {
    flex-grow: 0;
    flex-shrink: 0;
    background-color: var(--xpl-color-gray-400);
    color: #2E4457;
    border-radius: 6px;
    position: relative;
    display: inline-block;
    padding: .5rem .75rem;
    margin-right: .5rem;
    line-height: 1;
    margin-bottom: .5rem;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    font-size: .75rem;
    font-weight: 700;
    text-align: center;
    opacity: .8;
  }

  .tags .tag:last-child {
    margin-right: 0;
  }

  .title {
    font-size: .625rem;
    font-weight: bold;
    line-height: 2;
    color: rgb(97, 112, 128);
    text-transform: uppercase;
    margin-bottom: .25rem;
  }

  .time,
  .behavior {
    font-size: 1rem;
    line-height: 1.85;
    color: rgb(0, 21, 42);
    padding-bottom: 1rem;
  }
</style>
