<template>
  <div class="checkoutflow" v-if="checkoutflow">
    <el-container>
      <div class="header-container">
         <div id="back-container">
        <router-link :to="'/apps/landing-pages'" class="go-back"> <span class="icon icon-arrow-left3"></span>
        Go Back
        </router-link>
      </div>
      <el-header>
        <input type="text" autocomplete="off" v-model.trim="checkoutflow.name" @keyup.enter="editName" />
        <div class="actions">
          <div style="margin-right: 1rem;" v-if="checkoutflow.published">
            <a class="display-caption" :href="'https://'+subdomain+'.brandbot-checkout.com/' + checkoutflow.settings.vanity + '.html'" target="_blank">View my Landing Page</a>
          </div>
          <el-dropdown trigger="click" @command="handleCommand" style="margin-right: 1rem;">
            <el-button type="secondary" class="xpl-button xpl-button--secondary"  size="medium">
              Options <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="unpublish">Unpublish Page</el-dropdown-item>
              <el-dropdown-item class="text-danger" command="delete">Delete</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button type="primary" class="xpl-button xpl-button--primary"size="medium" @click.stop="publish" v-if="!checkoutflow.published">Publish Page</el-button>
          <el-button type="primary" class="xpl-button xpl-button--primary"size="medium" @click.stop="publish" v-if="checkoutflow.published">Republish Page</el-button>
        </div>
      </el-header>
      </div>

      <el-container class="main-container">
        <el-aside width="20%">
          <div class="sidebar">
            <div class="links">
              <div :class="['link', tabs.settings ? 'active' : '']" @click="toggleTab('settings')">Landing Page Settings</div>
              <div :class="['link', tabs.permissions ? 'active' : '']" @click="toggleTab('permissions')">Permissions</div>
              <div :class="['link', tabs.design ? 'active' : '']" @click="toggleTab('design')">Page Design</div>
              <div :class="['link', tabs.insights ? 'active' : '']" @click="toggleTab('insights')">Insights</div>
              <div :class="['link', tabs.embed ? 'active' : '']" @click="toggleTab('embed')">Marketing Tracking</div>
              <div :class="['link', tabs.fields ? 'active' : '']" @click="toggleTab('fields')">Registration Fields Settings</div>
            </div>
          </div>
        </el-aside>
        <el-main v-loading="loading">
          <CheckoutSettings :checkoutflow="checkoutflow" v-if="tabs.settings" />
          <CheckoutPermissions :checkoutflow="checkoutflow" v-if="tabs.permissions" />
          <CheckoutTemplate :checkoutflow="checkoutflow" v-if="tabs.design" />
          <CheckoutInsights :checkoutflow="checkoutflow" v-if="tabs.insights" />
          <CheckoutEmbed :checkoutflow="checkoutflow" v-if="tabs.embed" />
          <CheckoutFields :checkoutflow="checkoutflow" v-if="tabs.fields" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import CheckoutSettings from './CheckoutSettings';
  import CheckoutPermissions from './CheckoutPermissions';
  import CheckoutTemplate from './CheckoutTemplate';
  import CheckoutInsights from './CheckoutInsights';
  import CheckoutEmbed from './CheckoutEmbed';
  import CheckoutFields from './CheckoutFields';

  export default {
    props: ['uuid'],
    components: {
      CheckoutSettings,
      CheckoutPermissions,
      CheckoutTemplate,
      CheckoutInsights,
      CheckoutEmbed,
      CheckoutFields,
    },
    data() {
      return {
        subdomain: this.$store.getters.currentAccountSubdomain,
        loading: false,
        tabs: {
          settings: true,
          permissions: false,
          design: false,
          insights: false,
          embed: false,
          fields: false,
        },
        options_dropdown: false,
        checkoutflow: null,
      };
    },
    mounted() {
      EventBus.$on('checkoutflow.refresh', () => {
        this.fetchCheckoutFlow();
      });
    },
    created() {
      this.fetchCheckoutFlow();

      if (this.$route.query.tab) {
        // eslint-disable-next-line
        for (const tab in this.tabs) {
          // eslint-disable-next-line
          if (this.tabs.hasOwnProperty(tab)) {
            this.tabs[tab] = false;
          }
        }
        this.tabs[this.$route.query.tab] = true;
      }
    },
    beforeDestroy() {
      EventBus.$off('checkoutflow.refresh');
    },
    methods: {
      toggleTab(t) {
        if (t === 'design' &&
          !this.checkoutflow.settings.saleType &&
            !(this.checkoutflow.settings.pricing_option ||
              this.checkoutflow.settings.contract)) {
          this.$message.error('Please complete the Settings section of your Landing Page.');
          return;
        }
        // eslint-disable-next-line
        for (const tab in this.tabs) {
          // eslint-disable-next-line
          if (this.tabs.hasOwnProperty(tab)) {
            this.tabs[tab] = false;
          }
        }

        this.tabs[t] = true;
        this.$router.push({ query: { tab: t } });
      },
      fetchCheckoutFlow() {
        if (this.uuid) {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: '#fff',
          });

          api.getCheckoutFlow(this.uuid).then((response) => {
            this.checkoutflow = response.checkoutflow;
            loading.close();
          }).catch((err) => {
            this.$toasted.error(err.message);
          });
        }
      },
      editName() {
        const payload = {
          name: this.checkoutflow.name,
        };

        api.updateCheckoutFlow(payload, this.checkoutflow.id).then((response) => {
          this.$toasted.success(response.message);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      handleCommand(command) {
        if (command === 'delete') {
          this.$confirm('This will permanently delete this Landing Page. Continue?', 'Warning', {
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }).then(() => {
            this.deleteFlow();
          });
        }

        if (command === 'unpublish') {
          this.unpublish();
        }
      },
      unpublish() {
        this.$confirm('Are you sure you want to unpublish this Landing Page?', 'Warning', {
          confirmButtonText: 'Unpublish',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }).then(() => {
          this.unpublishLandingPage();
        });
      },
      publish() {
        this.$confirm('Are you sure you want to publish this Landing Page?', 'Warning', {
          confirmButtonText: 'Publish',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }).then(() => {
          this.publishLandingPage();
        });
      },
      publishLandingPage() {
        this.loading = true;
        const payload = {
          checkoutflow_id: this.checkoutflow.id,
        };
        api.publishCheckoutFlow(payload).then((response) => {
          this.loading = false;
          this.$message.success(response.message);
          this.checkoutflow.published = true;
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      unpublishLandingPage() {
        this.loading = true;
        const payload = {
          checkoutflow_id: this.checkoutflow.id,
        };
        api.unpublishCheckoutFlow(payload).then((response) => {
          this.loading = false;
          this.$message.success(response.message);
          this.checkoutflow.published = false;
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      deleteFlow() {
        api.deleteCheckoutFlow(this.checkoutflow.id).then((response) => {
          this.$toasted.success(response.message);
          this.$router.push('/apps/landing-pages');
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang='scss' scoped>
@import "src/scss/global/_spacing.scss";
 #back-container {
   padding: $space-tight $space-loose 0 $space-loose;
   background-color: white;
 }
  .el-container {
    height: calc(100vh - 90px);
    background-color: white;
  }

  .el-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d9e7f0;
  }

  .el-aside {
    padding: 1.25rem;
  }

  .el-header input {
    text-align: left;
    border: none;
    outline: none;
    width: calc(100% / 3);
    font-size: 1.25rem;
    line-height: 1.4;
    color: #00152a;
  }

  .el-header .actions {
    width: calc(100% / 3);
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .el-header .actions .btn:first-child {
    margin-right: 1rem;
  }

  .sidebar .links .link {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #00152a;
    margin-bottom: 1rem;
    padding: 4px 16px;
    cursor: pointer;
    transition: all 150ms ease-in-out;
    border-right: 3px solid transparent;
  }
  .sidebar .links .link:hover,
  .sidebar .links .link.active {
    background-color: var(--xpl-color-secondary-bg-lm);
  }

  .sidebar .links .link.disabled {
    color: #617080;
    cursor: not-allowed;
  }
  .header-container{
    position: fixed;
    z-index: 1029;
    width: 100%;
  }
  .main-container {
    padding-top: 90px;
  }
</style>
