<template>
  <el-container class="condition-container">
    <div class="sidebar-inner" style="width: 100%;">
        <BooleanField
          fieldIndex="0"
          title="What Are You Looking For?"
          fieldStep="1"
          yesText="Customer's last visit only"
          noText="Customer's last interaction (visit or purchase)"
          :selection.sync="visitOnly"
          :showLabel="false"
          descLabel=""
          id="33"
        />
        <TimeFrameField
            fieldIndex="1"
            title="When Was Their Last Visit Or Interaction?"
            fieldStep="2"
            :selectedOption.sync="timeframeComparison"
            :valueOne.sync="timeframeValue"
            pastLabel="days ago"
            :hidePast="false"
            :hideFuture="true"
            :hideTimeType="false"
            :goalList="null"
            id="22"
        />
        <BooleanField
          fieldIndex="2"
          title="Was Their Last Visit Any Type Or A Specific Type?"
          fieldStep="3"
          yesText="All visit types"
          noText="Specific type of visits"
          :selection.sync="includeAllVisits"
          :showLabel="true"
          descLabel="Options include payment option and location"
          id="42"
        />
        <div v-if="!includeAllVisits" class="condition-banner-style">
          Drill Down Options
        </div>
        <div class="field-container" v-if="!includeAllVisits">
          <div class="card-condition">
            <el-row>
              <el-col :span="1.5" class="step-col">
                <div class="field-step">4</div>
              </el-col>
              <el-col :span="12" class="body-col">
                <span class="field-title">Which Payment Option(s)?</span>
                <div class="option-body">
                  <el-radio
                    class="radio-btn field-label-text"
                    v-model="paymentOptionType"
                    label="any"
                    >With any payment option</el-radio
                  >
                  <el-radio
                    class="radio-btn field-label-text"
                    v-model="paymentOptionType"
                    label="specific"
                    >Only specific payment options</el-radio
                  >
                  <el-checkbox
                    class="membership-check checks"
                    v-if="paymentOptionType === 'specific'"
                    v-model="membershipTypeChecked"
                    >Membership Types</el-checkbox
                  >
                  <div
                    class="desc-container"
                    v-if="
                      membershipTypeChecked && paymentOptionType === 'specific'
                    "
                  >
                    <SelectionField
                      :descLabel="'Any of the following membership types'"
                      :filterOption.sync="membershipOptionExclusionOption"
                      :choice.sync="selectedMembershipOptions"
                      :options="membershipTypes"
                      :selectKey="'id'"
                      style="margin-bottom: 16px"
                      :showAny="false"
                    />
                  </div>
                  <el-checkbox
                    class="credit-check checks"
                    v-if="paymentOptionType === 'specific'"
                    v-model="creditTypeChecked"
                    >Credit Types</el-checkbox
                  >
                  <div
                    class="desc-container"
                    v-if="creditTypeChecked && paymentOptionType === 'specific'"
                  >
                    <SelectionField
                      :descLabel="'Any of the following credit types'"
                      :filterOption.sync="creditOptionExclusionOption"
                      :choice.sync="selectedCreditOptions"
                      :options="creditTypes"
                      :selectKey="'id'"
                      :showAny="false"
                    />
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <MultiSelectField
          v-if="!includeAllVisits"
          fieldIndex="4"
          title="Where Was Their Last Visit Or Interaction?"
          fieldStep="5"
          anyLabel="Any location in my account"
          specificLabel="Specific location(s) in my account"
          specificAnyLabel="Any of the following locations"
          specificExclusionLabel="Any except the following"
          :selectedOptions.sync="selectedHomeLocations"
          :specificChoice.sync="selectedHomeOption"
          :exclusionChoice.sync="exclusionHomeOption"
          :options="options"
          selectKey="id"
          id="99"
        />
    </div>
    <div class="save-condition-container" v-if="showSaveButton">
      <el-button class="save-condition-btn" type="primary" @click="commitCondition">Save</el-button>
    </div>
  </el-container>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import BooleanField from '@/components/Contacts/ContactLists/Conditions/Fields/BooleanField';
  import TimeFrameField from '@/components/Contacts/ContactLists/Conditions/Fields/TimeFrameField';
  import MultiSelectField from '@/components/Contacts/ContactLists/Conditions/Fields/MultiSelectField';
  import SelectionField from '@/components/Contacts/ContactLists/Conditions/Fields/SelectionField';


  export default {
    props: ['condition', 'editedRule', 'goalList'],
    components: {
      BooleanField,
      TimeFrameField,
      MultiSelectField,
      SelectionField,
    },
    data() {
      return {
        options: [],
        visitOnly: true,
        includeAllVisits: true,
        exclusionOption: 'any',
        selectedHomeOption: 'any',
        selectedHomeLocations: [],
        exclusionHomeOption: 'any',
        timeframeComparison: null,
        timeframeValue: 1,
        specificPaymentOptions: 'any',
        creditTypeChecked: false,
        membershipTypeChecked: false,
        creditTypes: [],
        membershipTypes: [],
        paymentOptionType: 'any',
        creditOptionExclusionOption: 'any',
        membershipOptionExclusionOption: 'any',
        selectedCreditOptions: [],
        selectedMembershipOptions: [],
      };
    },
    created() {
      this.fetchLocations();
      this.fetchMemberships();
      this.fetchCredits();
      if (this.editedRule) {
        this.currentlyOpen = null;

        this.visitOnly = this.editedRule.visit_only;
        this.includeAllVisits = this.editedRule.include_all_visits;
        this.exclusionOption = this.editedRule.exclusion_option;
        this.selectedHomeOption = this.editedRule.selected_home_option;
        this.selectedHomeLocations = this.editedRule.selected_home_locations;
        this.exclusionHomeOption = this.editedRule.selected_home_exclusion;
        this.timeframeComparison = this.editedRule.timeframe_comparison;
        if (this.editedRule.timeframe_comparison === 'sinceEntering') {
          this.timeframeComparison = 'allTime';
        }
        this.timeframeValue = this.editedRule.timeframe_value;
        this.paymentOptionType = this.editedRule.payment_option_option;
        this.creditTypeChecked = this.editedRule.credit_types_checked;
        this.membershipTypeChecked = this.editedRule.membership_types_checked;
        this.selectedMembershipOptions = this.editedRule.membership_options;
        this.selectedCreditOptions = this.editedRule.credit_options;
        this.creditOptionExclusionOption = this.editedRule.credit_option_exclusion;
        this.membershipOptionExclusionOption = this.editedRule.membership_option_exclusion;
      }
    },
    computed: {
      showSaveButton() {
        if (!this.timeframeComparison) {
          return false;
        }
        if ((this.timeframeComparison === 'before' || this.timeframeComparison === 'after' || this.timeframeComparison === 'between' || this.timeframeComparison === 'specificDate') && Number.isInteger(this.timeframeValue)) {
          return false;
        }

        if (this.timeframeComparison === 'between' && !this.timeframeValue.length) {
          return false;
        }

        if (!this.includeAllVisits) {
          if (this.paymentOptionType === 'specific' && !this.creditTypeChecked && !this.membershipTypeChecked) {
            return false;
          }

          if (this.paymentOptionType === 'specific' && this.creditTypeChecked && this.creditOptionExclusionOption !== 'all') {
            if (!this.selectedCreditOptions.length) {
              return false;
            }
          }

          if (
            this.paymentOptionType === 'specific' &&
            this.membershipTypeChecked && this.membershipOptionExclusionOption !== 'all'
          ) {
            if (!this.selectedMembershipOptions.length) {
              return false;
            }
          }

          if (this.selectedHomeOption === 'specific' && (!this.selectedHomeLocations.length || !this.exclusionHomeOption)) {
            return false;
          }
        }
        return true;
      },
    },
    methods: {
      async fetchLocations() {
        try {
          const response = await api.getMTLinkedLocations();
          this.options = response.locations;
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      async fetchMemberships() {
        try {
          const response = await api.getMTMemberships();
          this.membershipTypes = response.memberships;
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      async fetchCredits() {
        try {
          const response = await api.MTCredits();
          this.creditTypes = response.credits;
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          visit_only: this.visitOnly,
          include_all_visits: this.includeAllVisits,
          exclusion_option: this.exclusionOption,
          selected_home_option: this.selectedHomeOption,
          selected_home_locations: this.selectedHomeLocations,
          selected_home_exclusion: this.exclusionHomeOption,
          timeframe_comparison: this.timeframeComparison,
          timeframe_value: this.timeframeValue,
          payment_option_option: this.paymentOptionType,
          credit_types_checked: this.creditTypeChecked,
          membership_types_checked: this.membershipTypeChecked,
          credit_options: this.selectedCreditOptions,
          membership_options: this.selectedMembershipOptions,
          membership_option_exclusion: this.membershipOptionExclusionOption,
          credit_option_exclusion: this.creditOptionExclusionOption,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
@import "src/scss/global/_spacing.scss";
@import "src/scss/global/_new_main.scss";
.field-container {
  margin-top: 28px;
}
.option-body {
  margin-top: 20px;
}

.card-condition {
  background-color: #FFFFFF;
  border: solid 1px var(--xpl-color-gray-400);
  box-shadow: none;
  border-radius: 0;
  cursor: auto;
}

.card-condition:hover {
  background-color: #FFFFFF;
  cursor: auto;
}
.field-step {
  height: 1.5rem;
  width: 1.5rem;
  background-color: $bb-color-blue-v2;
  font-family: $primary-bb-font-family;
  font-size: $base-font-size;
  color: #FFFFFF;
  border-radius: 50%;
  text-align: center;
  margin: $space-base-inline;
}
.field-title {
  font-family: $primary-bb-font-family;
  font-weight: 700;
  font-size: $base-font-size;
  color: $base-color-black-v2;
  line-height: $loose-medium-line-height;
}

.radio-btn {
  margin-top: $space-extra-tight;
  margin-bottom: $space-extra-tight;
}

.field-label-text  {
  display: block;
}
.field-label-text /deep/ .el-radio__label {
  font-family: $primary-bb-font-family !important;
  font-size: $base-loose-font-size !important;
  color: $base-color-black-v2 !important;
  line-height: $loose-line-height !important;
  padding-left: 8px;
}
.field-label-text /deep/ .el-radio__inner {
  background-color: #F2F4F6 !important;
  border: 1px solid #A6B2BF !important;
  height: 16px;
  width: 16px;
}
.field-label-text /deep/  .el-radio__input.is-checked .el-radio__inner::after {
  background-color: var(--xpl-color-secondary-lm) !important;
  width: 12px;
  height: 12px;
}
.condition-container {
  background-color: #F4F8FA;
  height: 100%;
  width: 100%;
}

.field-container:last-of-type{
  padding-bottom: 100px;
}
.sidebar-inner {

  height: 100%;
  position: relative;
  padding-top: 0;
  background-color: #F4F8FA;
  padding-left: 2.625rem;
  padding-right: 2.25rem;
  padding-bottom: 0;
  width: 100%;
}
.save-condition-btn {
  width: 729px;
  margin: 16px 12px 16px 12px;
}
.save-condition-container {
  // box-shadow: 0 -6px 7px 0 rgba(0, 0, 0, 0.05);
  padding: 0;
  margin: 0;
  z-index: 1;
}
.condition-banner-style {
  background-color: #F4F8FA !important;
  margin-top: 13px !important;
  padding-bottom: 0;
}

.membership-check {
  margin-top: 24px;
}
.credit-check {
  display: block;
}

.checks{
  font-family: $primary-bb-font-family;
  font-size: 12px;
  line-height: 16px;
  color: #39414A;
  font-weight: 700;
}
.checks /deep/ .el-checkbox__inner {
  height: 16px;
  width: 16px;
  border: 1px solid #DCDFE6;
  z-index: 0;
}
.checks /deep/ .el-checkbox__label {
  height: 16px;
  width: 16px;
  color: #39414A;
}
.checks /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #F3F7F9;
}
.checks /deep/ .el-checkbox__input.is-checked .el-checkbox__inner::after {
  border: 2px solid #067DFF;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 2px;
  width: 3px;
}
</style>
