<template>
  <div>
    <div class="card corporate">
      <div class="card-body">
        <div>
          <span class="material-icons">check_circle</span>
        </div>
        <div>
          <h1>Push this email to your children accounts</h1>
          <p>You can push this scheduled email to the children of your choice by selecting them below. Once you push an email, we make an exact copy of all of your settings.</p>
        </div>
      </div>
    </div>
    <div class="card children">
      <div class="card-header card-header-merged">Children Accounts</div>
      <div class="card-body">
        <div class="text-muted" v-if="!linked_children.length">You currently have no children accounts selected.</div>
        <div class="datatable" v-if="linked_children.length">
          <table class="table bb-datatable">
            <thead>
              <tr>
                <td>Account Name</td>
                <td>List</td>
                <td class="text-right">Editable?</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="child in linked_children" v-if="!child.sent">
                <td style="max-width: auto; width: auto;">{{ child.name }}</td>
                <td colspan="2">
                  <Multiselect v-model="child.selected_contact_lists" placeholder="Pick a contact list" :options="child.contact_lists" label="name" track-by="id" :searchable="true" :allow-empty="false" :show-labels="false" :multiple="true" @input="changeLists(child)" />
                </td>
                <td class="text-right">
                  <span class="icon icon-checkbox-unchecked2" v-if="!child.editable" @click="toggleEditable(child)"></span>
                  <span class="icon icon-checkbox-checked2" v-if="child.editable" @click="toggleEditable(child)"></span>
                </td>
                <td class="text-right">
                  <button type="button" class="xpl-button xpl-button--secondary" :disabled="!scheduled_email.email_id || !scheduled_email.email.subject || !child.selected_contact_lists.length" @click="pushToChild(child)">Push</button>
                </td>
                <td class="text-right">
                  <span class="material-icons" @click="removeChild(child)">delete_forever</span>
                </td>
              </tr>
              <tr v-for="child in linked_children" v-if="child.sent">
                <td style="max-width: auto; width: auto;">{{ child.name }}</td>
                <td>
                  <span v-for="list in child.selected_contact_lists" class="pill pill-default">{{ list.name }}</span>
                </td>
                <td>
                  <span class="text-muted">Sent</span>&nbsp;
                  <span v-if="child.stats">{{ child.stats.sent | prettyNumber }}</span>
                </td>
                <td>
                  <span class="text-muted">Opened</span>&nbsp;
                  <span v-if="child.stats">{{ child.stats.opened_unique | prettyNumber }}</span>
                </td>
                <td>
                  <span class="text-muted">Clicked</span>&nbsp;
                  <span v-if="child.stats && child.stats.opened_unique">{{ (child.stats.clicked_unique / child.stats.opened_unique) | prettyPercentage }}</span>
                  <span v-if="child.stats && !child.stats.opened_unique">{{ 0 | prettyPercentage }}</span>
                </td>
                <td class="text-right">
                  <span class="text-success">Sent</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <button type="button" class="xpl-button xpl-button--secondary" @click="show_subaccounts_modal = !show_subaccounts_modal" v-if="!linked_children.length">Add an account</button>
        <button type="button" class="xpl-button xpl-button--secondary" @click="show_subaccounts_modal = !show_subaccounts_modal" v-if="linked_children.length">Add another account</button>
      </div>
    </div>
    <SubAccountsModal v-if="show_subaccounts_modal" :scheduledEmailId="scheduled_email.id" />
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';
  import SubAccountsModal from './SubAccountsModal';

  export default {
    props: ['scheduled_email'],
    components: {
      Multiselect,
      SubAccountsModal,
    },
    data() {
      return {
        linked_children: [],
        show_subaccounts_modal: false,
      };
    },
    mounted() {
      EventBus.$on('subaccounts.modal_close', () => {
        this.show_subaccounts_modal = false;
        this.fetchLinkedChildren();
      });
    },
    created() {
      this.fetchLinkedChildren();
    },
    beforeDestroy() {
      EventBus.$off('subaccounts.modal_close');
    },
    methods: {
      fetchNeededStats() {
        const scheduledEmails = this.linked_children.filter(lc => lc.sent);
        scheduledEmails.forEach((se) => {
          this.fetchNeededStat(se, se.scheduled_email_uuid);
        });
      },
      fetchNeededStat(scheduledEmail, scheduledEmailUuid) {
        api.emailPerformance(scheduledEmailUuid).then((response) => {
          this.$set(scheduledEmail, 'stats', response.stats);
        });
      },
      pushToChild(child) {
        // eslint-disable-next-line
        if (!confirm('Pushing this email to this subaccount will override any previous version of the same email. Are you sure?')) {
          return;
        }

        const payload = {
          scheduled_email_id: this.scheduled_email.id,
          account_scheduled_email_id: child.account_scheduled_email_id,
        };

        api.pushToChild(payload).then((response) => {
          this.$toasted.success(response.message);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      removeChild(child) {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to delete this subaccount from this Scheduled Email?')) {
          return;
        }

        api.deleteAccountScheduledEmail(child.account_scheduled_email_id).then((response) => {
          this.$toasted.success(response.message);
          this.fetchLinkedChildren();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      changeLists(child) {
        const payload = {
          contact_list_ids: child.selected_contact_lists.map(cl => cl.id),
        };

        api.updateAccountScheduledEmail(payload, child.account_scheduled_email_id)
        .catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      toggleEditable(child) {
        const payload = {
          editable: !child.editable,
        };

        api.updateAccountScheduledEmail(payload, child.account_scheduled_email_id)
        .then((response) => {
          this.$toasted.success(response.message);
          this.$set(child, 'editable', !child.editable);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchLinkedChildren() {
        api.getScheduledEmailLinkedChildren(this.scheduled_email.id).then((response) => {
          this.linked_children = response.children;
          this.fetchNeededStats();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .card.corporate {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }

  .card.corporate .card-body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
  }

  .card.corporate .card-body .material-icons {
    margin-right: 1.5rem;
    font-size: 2rem;
    line-height: 2rem;
    color: #108043;
  }

  .card.corporate .card-body h1 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #1d364b;
    margin-bottom: 0;
  }

  .card.corporate .card-body p {
    margin: 0;
    font-size: .875rem;
    line-height: 1.43;
    color: #617080;
  }

  .card.children {
    margin-bottom: 2.5rem;
  }

  .card.children .card-body button {
    margin-top: .5rem;
  }

  .table .material-icons {
    cursor: pointer;
    font-size: 1.125rem;
  }

  .card.children .card-body .table button {
    margin: 0;
  }

  .datatable, .table {
    margin-bottom: 0;
  }
</style>
