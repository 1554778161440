<template>
  <div class="condition">
    <form @submit.prevent>
      <div class="form-group" v-if="campaignItem">
        <span>The contact has visited&nbsp;</span>
        <div v-if="campaignItem.time_rule.choice === 'both'">
          <Multiselect v-model="comparison_visit" placeholder="Pick" :options="comparisons_visit" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" style="min-width: 9rem; width: 9rem; max-width: 9rem;" />
        </div>
        <div v-if="campaignItem.time_rule.choice === 'behavior'">
          exactly
        </div>
        &nbsp;
        <input type="number" name="visit_number_start" v-model.number="visit_number_start" class="form-control" min="0" style="display: inline-block; width: 5rem; max-width: 5rem; min-width: 5rem;" :disabled="!comparison_visit && campaignItem.time_rule.choice !== 'behavior'" />&nbsp;
        <span v-if="comparison_visit && comparison_visit.value === 'between'">
          and&nbsp;<input type="number" name="visit_number_end" v-model.number="visit_number_end" class="form-control" min="0" style="display: inline-block;" />&nbsp;
        </span>
        <span>times since entering the auto-campaign. Only include visits using</span>
        <span style="margin-top: .5rem; margin-bottom: .5rem;">
          <Multiselect v-model="filtering" :options="filters" label="label" track-by="value" :allow-empty="false" :show-labels="false" style="min-width: 23rem; width: 23rem; max-width: 23rem;" />
        </span>
        <div class="multiselect-block" v-if="filtering && filtering.value">
          <Multiselect v-model="selected_pricing_options" placeholder="Choose pricing option(s)" :options="pricing_options" label="name" track-by="id" :searchable="true" :allow-empty="true" :show-labels="false" :multiple="true" :close-on-select="false" />
        </div>
      </div>
      <div class="form-group" v-else>
        <span>Any contacts who visits</span>
        <Multiselect v-model="comparison_visit" placeholder="Pick" :options="comparisons_visit" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" />&nbsp;
        <input type="number" name="visit_number_start" v-model.number="visit_number_start" class="form-control" min="0" style="display: inline-block; width: 5rem;" :disabled="!comparison_visit" />&nbsp;
        <span v-if="comparison_visit && comparison_visit.value === 'between'">
          and&nbsp;<input type="number" name="visit_number_end" v-model.number="visit_number_end" class="form-control" min="0" style="display: inline-block;" />&nbsp;
        </span>
        <span>times since entering the auto-campaign with</span>
        <span>
          <Multiselect v-model="filtering" :options="filters" label="label" track-by="value" :show-labels="false" style="min-width: 15rem; width: 15rem; max-width: 15rem;" :allow-empty="false" />
        </span>
        <div class="multiselect-block" v-if="filtering && filtering.value">
          <Multiselect v-model="selected_pricing_options" placeholder="Choose pricing option(s)" :options="pricing_options" label="name" track-by="id" :searchable="true" :allow-empty="true" :show-labels="false" :multiple="true" :close-on-select="false" />
        </div>
      </div>

      <div>
        <button class="xpl-button xpl-button--primary" @click.stop="commitCondition" :disabled="visit_number_start === null">Save</button>
      </div>
    </form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['uuid', 'rule', 'campaignItem'],
    components: {
      Multiselect,
    },
    data() {
      return {
        filtering: {
          label: 'Any pricing option',
          value: false,
        },
        filters: [{
          label: 'Any pricing option',
          value: false,
        }, {
          label: 'Any of the following pricing option(s)',
          value: true,
        }],
        pricing_options: [],
        selected_pricing_options: [],
        visit_number_start: null,
        visit_number_end: null,
        comparison_visit: null,
        comparisons_visit: [{
          label: 'At least',
          value: 'more',
        }, {
          label: 'Less than',
          value: 'less',
        }, {
          label: 'Exactly',
          value: 'exactly',
        }, {
          label: 'Between',
          value: 'between',
        }],
      };
    },
    created() {
      this.fetchPricingOptions();

      if (this.rule && this.rule.length) {
        this.selected_pricing_options = this.rule[0].pricing_options;
        this.visit_number_start = this.rule[0].visit_number_start;
        this.visit_number_end = this.rule[0].visit_number_end;
        this.comparison_visit = this.comparisons_visit.filter(c =>
          c.value === this.rule[0].comparison_visit,
        )[0];
        if (this.rule[0].visit_type === 'any') {
          this.filtering = { label: 'Any pricing option', value: false };
        } else {
          this.filtering = { label: 'Specific pricing option(s)', value: true };
        }
      }

      if (this.campaignItem.time_rule.choice === 'behavior') {
        this.comparison_visit = {
          label: 'Exactly',
          value: 'exactly',
        };
      }
    },
    methods: {
      fetchPricingOptions() {
        api.getPricingOptions().then((response) => {
          this.pricing_options = response.pricing_options;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition(event) {
        const target = event.target || event.srcElement;
        target.disabled = true;
        target.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';

        const payload = {
          condition: {
            uuid: this.uuid,
            quick: true,
          },
          timeframe: 'entering_campaign',
          relative_days: null,
          comparison_date: null,
          comparison_visit: this.comparison_visit ? this.comparison_visit.value : null,
          start_date: null,
          end_date: null,
          visit_type: this.filtering && this.filtering.value ? 'pricing_option' : 'any',
          pricing_options: this.filtering && this.filtering.value ?
            this.selected_pricing_options : [],
          exclude: null,
          visit_number_start: this.visit_number_start,
          visit_number_end: this.visit_number_end,
          misc: {
            filtering: false,
          },
        };
        EventBus.$emit('condition.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .form-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  span + .multiselect {
    margin-left: .5rem;
  }

  .multiselect + span {
    margin-left: .5rem;
  }

  form .multiselect {
    display: inline-block;
    width: auto;
  }

  .btn-bb-primary {
    margin-top: 1.5rem;
  }

  .multiselect-block {
    width: 100%;
    display: block;
  }

  .multiselect-block .multiselect {
    width: 100%;
  }
</style>
