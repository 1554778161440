<template>
  <div>
    <div class="heading">
      <h2>User Groups</h2>
      <button type="button" class="xpl-button xpl-button--primary" @click.stop="new_group = !new_group">Create a New User Group</button>
      <NewUserGroupModal v-if="new_group || editableGroup" :editableGroup="editableGroup" />
    </div>

    <div class="card" v-for="group in groups">
      <div class="card-body">
        <div class="team">
          {{ group.name }}
          <div class="options">
            <div class="dropdown">
              <button class="xpl-button xpl-button--secondary" @click.stop="toggleOptions(group)">Options</button>
              <div :class="['dropdown-menu', group.options ? 'show' : '']">
                <a href="#" class="dropdown-item" @click.stop="editableGroup = group">Rename</a>
                <a href="#" class="dropdown-item text-danger">Archive</a>
              </div>
            </div>
            <button class="xpl-button xpl-button--secondary" @click.stop="addToGroup = group">Add to Group</button>
            <AddUserGroupModal v-if="addToGroup" :addToGroup="addToGroup" />
          </div>
        </div>

        <div class="datatable" v-if="group.users.length">
          <table class="table bb-datatable">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Permissions</th>
                <th>Groups</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in group.users">
                <td>{{ user.first_name }} {{ user.last_name }}</td>
                <td>{{ user.email }}</td>
                <td class="text-capitalize">{{ user.permission }}</td>
                <td><div v-for="group in user.groups">{{ group }}</div></td>
                <td>
                  <a href="#" class="text-danger" @click.stop="removeFromGroup(user.id, group.id)">Remove from Group</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import NewUserGroupModal from './NewUserGroupModal';
  import AddUserGroupModal from './AddUserGroupModal';

  export default {
    components: {
      NewUserGroupModal,
      AddUserGroupModal,
    },
    data() {
      return {
        new_group: false,
        groups: [],
        editableGroup: null,
        addToGroup: null,
      };
    },
    mounted() {
      EventBus.$on('user_group.close', () => {
        this.new_group = false;
        this.editableGroup = null;
        this.fetchUserGroups();
      });

      EventBus.$on('add_user_group.close', () => {
        this.addToGroup = null;
        this.fetchUserGroups();
      });
    },
    created() {
      this.fetchUserGroups();
    },
    beforeDestroy() {
      EventBus.$off('user_group.close');
      EventBus.$off('add_user_group.close');
    },
    methods: {
      removeFromGroup(userId, groupId) {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to remove this user from this group?')) {
          return;
        }

        const payload = {
          user_id: userId,
          user_group_id: groupId,
        };

        api.removeFromGroup(payload).then((response) => {
          this.$toasted.success(response.message);
          this.fetchUserGroups();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchUserGroups() {
        api.getUserGroups().then((response) => {
          this.groups = response.user_groups;
        });
      },
      toggleOptions(group) {
        if (group.options) {
          group.options = false;
        } else {
          this.$set(group, 'options', true);
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .table thead th {
    border: none;
  }

  .datatable .bb-datatable tbody tr td:first-child {
    width: auto;
    max-width: none;
  }

  .datatable .bb-datatable tbody tr td:last-child {
    width: auto;
    max-width: none;
    text-align: right;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.875rem;
  }

  .card + .card {
    margin-top: .75rem;
  }

  .card .team {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    color: #00152a;
    margin-bottom: 1.5rem;
  }

  .card .team .options {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .card .team .options .btn:first-child {
    margin-right: .5rem;
  }

  .datatable {
    margin-bottom: 0;
  }
</style>
