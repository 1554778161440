<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">1</span>
        </el-col>
        <el-col :span="22" class="v2__heading">When did the contacts {{ abandoned ? '' : 'not ' }}abandon their cart?</el-col>
      </el-row>

      <el-row style="margin-bottom: 1.5rem;">
        <el-col :offset="2" :span="22">
          <el-radio-group v-model="timeframe">
            <el-radio label="all" border>All Time</el-radio>
            <el-radio label="absolute" border>Around static date(s)</el-radio>
            <el-radio label="relative" border>A relative time frame</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row v-if="timeframe === 'absolute'">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-radio-group v-model="comparison">
              <el-radio-button label="before">Before</el-radio-button>
              <el-radio-button label="between">Between</el-radio-button>
              <el-radio-button label="after">After</el-radio-button>
            </el-radio-group>

            <div class="block before" v-if="comparison === 'before'">
              <el-date-picker
                v-if="comparison === 'before'"
                value-format="yyyy-MM-dd"
                v-model="dateBefore"
                type="date"
                placeholder="Pick a date"
                popper-class= "before"
                :picker-options="beforePickerOptions"
                >

              </el-date-picker>
            </div>

            <div class="block" v-if=" comparison === 'after'">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="dateAfter"
                type="date"
                :picker-options="afterPickerOptions"
                placeholder="Pick a date"
                popper-class= "after">
              </el-date-picker>
            </div>

            <div class="block" v-if="comparison && comparison === 'between'">
             <el-date-picker
                v-model="datesBetween"
                type="daterange"
                range-separator="-"
                start-placeholder="Start"
                end-placeholder="End">
              </el-date-picker>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row v-if="timeframe === 'relative'">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-radio-group v-model="comparison">
              <el-radio-button label="more">At least</el-radio-button>
              <el-radio-button label="within">Within the last</el-radio-button>
              <el-radio-button label="exactly">Exactly</el-radio-button>
            </el-radio-group>
            <div class="block" style="display: flex; align-items: center;">
              <el-input-number :min="0" v-model="relative_days"></el-input-number>
              &nbsp;days<span v-if="comparison && comparison !== 'within'">&nbsp;ago</span>.
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-form>
      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click="commitCondition">Save this condition</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    props: ['condition', 'editedRule', 'enrollment'],
    data() {
      return {
        abandoned: true,
        timeframe: 'all',
        comparison: null,
        relative_days: null,
        datesBetween: null,
        dateBefore: null,
        dateAfter: null,
        payloadTimeframeValue: null,
        afterPickerOptions: {
          disabledDate(date) {
            return date > new Date();
          },
        },
        beforePickerOptions: {
          disabledDate() {
            return false;
          },
        },
      };
    },
    created() {
      if (this.editedRule) {
        this.abandoned = this.editedRule.abandoned;
        this.timeframe = this.editedRule.timeframe;
        if (this.editedRule.comparison === 'between') {
          this.datesBetween = [this.editedRule.start_date, this.editedRule.end_date];
        } else if (this.editedRule.comparison === 'after') {
          this.dateAfter = this.editedRule.start_date;
        } else {
          this.dateBefore = this.editedRule.start_date;
        }
        this.comparison = this.editedRule.comparison;
        this.relative_days = this.editedRule.relative_days;
        this.selected_landing_pages = this.editedRule.landing_pages;
      }
    },
    methods: {
      commitCondition() {
        // This cleans up the payload so it doesn't send back key's to the backend we dont need use
        if (this.timeframe === 'relative') {
          this.datesBetween = null;
          this.dateBefore = null;
          this.dateAfter = null;
          this.comparison = null;
        } else if (this.timeframe === 'absolute') {
          this.relative_days = null;
        } else if (this.timeframe === 'all') {
          this.datesBetween = null;
          this.dateBefore = null;
          this.dateAfter = null;
          this.relative_days = null;
          this.comparison = null;
          this.payloadTimeframeValue = null;
        }
        if (this.comparison === 'between') {
          this.payloadTimeframeValue = this.datesBetween;
        } else if (this.comparison === 'after') {
          this.payloadTimeframeValue = this.dateAfter;
        } else {
          this.payloadTimeframeValue = this.dateBefore;
        }
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          abandoned: this.abandoned,
          timeframe: this.timeframe,
          comparison: this.comparison,
          relative_days: this.relative_days,
          start_date: Array.isArray(this.payloadTimeframeValue)
          ? this.payloadTimeframeValue[0] : this.payloadTimeframeValue,
          end_date: Array.isArray(this.payloadTimeframeValue) ? this.payloadTimeframeValue[1] : null,
        };
        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .options {
    padding: 1.25rem;
    background-color: #f5f8fb;
  }
</style>
