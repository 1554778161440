<template>
  <div>
    <h3>Contacts in the Last 30 Days <span>({{ count | prettyNumber }})</span></h3>
    <div class="sub-text">Contacts added in the last 30 days</div>
    <DataTable :filter="'last_30'" :count="count" />
  </div>
</template>
<script>
  import DataTable from './DataTable';

  export default {
    props: ['count'],
    components: {
      DataTable,
    },
    data() {
      return {};
    },
  };
</script>
