<template>
  <el-card shadow="never">
    <h1>Company Details</h1>
    <el-form ref="accountSignup" :model="account" :rules="accountRules" status-icon @submit.native.prevent>
      <el-form-item prop="name">
        <label for="name">Company Name</label>
        <el-input id="name" v-model="account.name" placeholder="Company Name" />
      </el-form-item>
      <el-form-item prop="website">
        <label for="website">Business URL</label>
        <el-input id="website" v-model.trim="account.website" placeholder="Business URL" />
      </el-form-item>
      <el-form-item prop="timezone">
        <label for="type">Timezone</label>
        <div>
          <el-select v-model="account.timezone" filterable placeholder="Timezone" style="width: 100%;">
            <el-option-group v-for="group in timezones" :key="group.group" :label="group.group">
              <el-option v-for="timezone in group.zones" :key="timezone.value" :label="timezone.name" :value="timezone.value"></el-option>
            </el-option-group>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item prop="type">
        <label for="type">Company Type</label>
        <div>
          <el-select v-model="account.type" filterable placeholder="Company Type" style="width: 100%;">
              <el-option v-for="type in types" :key="type" :label="type" :value="type"></el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item style="text-align: center; margin-top: 1.5rem;">
        <el-button type="primary" class="xpl-button xpl-button--primary":loading="loading" @click.stop="createAccount">Continue</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import timezones from '../timezones.json';

  export default {
    props: ['pikeToken'],
    data() {
      const checkUrl = (rule, value, callback) => {
        if (value && value.length &&
          !value.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm)) {
          return callback(new Error('We need a valid url for your website.'));
        }

        return callback();
      };
      return {
        timezones,
        loading: false,
        types: ['CrossFit', 'Dance', 'Fitness', 'Health Club', 'Massage', 'Martial Arts', 'Personal Training', 'Physical Therapy', 'Pilates', 'Barre', 'Salon', 'Spa', 'Sports Training', 'Wellness/Health Center', 'Yoga', 'Other'],
        account: {
          name: '',
          website: '',
          type: '',
          timezone: null,
        },
        accountRules: {
          name: [{ required: true, trigger: 'change', message: 'A company name is required' }],
          website: [{ required: true, validator: checkUrl, trigger: 'change' }],
          type: [{ required: true, trigger: 'change', message: 'Please select a company type' }],
          timezone: [{ required: true, trigger: 'change', message: 'Please pick a timezone' }],
        },
      };
    },
    created() {
      const account = this.$store.state.user.current_account;
      if (account) {
        // eslint-disable-next-line
        for (const key in this.account) {
          if (account[key]) {
            this.$set(this.account, key, account[key]);
          }
        }
      }
    },
    methods: {
      createAccount() {
        this.$refs.accountSignup.validate((valid) => {
          if (!valid) {
            return;
          }

          this.loading = true;
          if (this.pikeToken) {
            api.updateAccount(this.$store.state.user.current_account.id, this.account)
            .then(() => {
              this.$store.dispatch('refreshUser').then(() => {
                EventBus.$emit('signup.account.done');
                this.loading = false;
              });
            }).catch((err) => {
              this.loading = false;
              if (err.errors) {
                this.$message.error(err.errors[Object.keys(err.errors)[0]][0]);
              } else {
                this.$message
                 .error('We were unable to update your account. Please try again later.');
              }
            });
          } else {
            api.createAccount(this.account).then((response) => {
              this.$store.dispatch('refreshUser').then(() => {
                EventBus.$emit('signup.account.done', response.account);
                this.loading = false;
              });
            }).catch((err) => {
              this.loading = false;
              if (err.errors) {
                this.$message.error(err.errors[Object.keys(err.errors)[0]][0]);
              } else {
                this.$message
                 .error('We were unable to create your account. Please try again later.');
              }
            });
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-card {
    max-width: 500px;
    width: 500px;
    min-width: 500px;
    border: none;
  }

  .el-card h1 {
    font-size: 1.75rem;
    color: #00152a;
    margin-bottom: 2.5rem;
  }

  .el-form-item {
    margin-bottom: 1rem;
  }
</style>
