<template>
  <transition name="fade">
    <div class="container-fluid" v-cloak>
      <div class="container-header">
        <h2>Funnels</h2>
        <button class="xpl-button xpl-button--primary" @click.stop="new_funnel = true">Create New Funnel</button>
        <CreateFunnelModal v-if="new_funnel" />
      </div>
      <div class="datatable" v-if="funnels && funnels.length">
        <div class="filters">
          <form @submit.prevent>
            <div class="input-group">
              <span class="input-group-addon">
                <span class="icon icon-search"></span>
              </span>
              <input type="search" name="query" v-model.trim="query" class="form-control" placeholder="Search" />
            </div>
          </form>
        </div>

        <div v-if="filteredFunnels.length">
          <table class="table bb-datatable table-hover">
            <thead>
              <tr>
                <td></td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="funnel in filteredFunnels" @click="$router.push('/contacts/funnels/' + funnel.uuid)">
                <td></td>
                <td>
                  <h6>{{ funnel.name }}</h6>
                  <div class="small text-muted">Last Updated on {{ funnel.updated_at | humanDateTime }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import CreateFunnelModal from './Funnels/CreateFunnelModal';

  export default {
    components: {
      CreateFunnelModal,
    },
    data() {
      return {
        new_funnel: false,
        query: '',
        funnels: [],
      };
    },
    mounted() {
      EventBus.$on('create_funnel.close', () => {
        this.new_funnel = false;
      });
    },
    created() {
      this.fetchFunnels();
    },
    beforeDestroy() {
      EventBus.$off('create_funnel.close');
    },
    computed: {
      filteredFunnels() {
        if (this.query.length) {
          return this.funnels.filter(f =>
            f.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1,
          );
        }

        return this.funnels;
      },
    },
    methods: {
      fetchFunnels() {
        api.getFunnels().then((response) => {
          this.funnels = response.funnels;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  table h6 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #1d364b;
    margin: 0;
  }

  .bb-datatable {
    border: none;
    border-collapse: separate;
    border-spacing: 0 .5rem;
  }

  .bb-datatable thead tr td {
    cursor: initial;
    border: none;
  }

  .bb-datatable tbody tr td {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    color: #1d364b;
    // box-shadow: 0 1px 0 0 rgba(100, 164, 210, 0.13);
  }

  .bb-datatable tbody tr td:first-child {
    border-left: 1px solid var(--xpl-color-gray-400);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .bb-datatable tbody tr td:last-child {
    border-right: 1px solid var(--xpl-color-gray-400);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    max-width: none;
    width: auto;
  }
</style>
