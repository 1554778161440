<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner">
      Event Qualifier
    </div>

    <form @submit.prevent>
      <div class="form-group" v-if="!enrollment">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'absolute'" @click="timeframe = 'absolute'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'absolute'" @click="timeframe = 'absolute'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_absolute" @click="timeframe = 'absolute'; comparison = null;">
              Static dates
            </label>
            <small class="form-text text-muted">Select before, after, or between static dates. e.g. After July 27th 2018</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="timeframe === 'absolute'">
        <div class="form-inline">
          <label>Contacts who have their birthdays</label>&nbsp;
          <Multiselect v-model="comparison" placeholder="Select a comparison" :options="comparisons_date" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" style="max-width: 13rem; min-width: 13rem; width: 13rem;" />
        </div>

        <div class="dates" v-if="comparison && timeframe === 'absolute'">
          <div class="date">
            <Multiselect v-model="dates.start_month" placeholder="Month" :options="['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']" :searchable="false" :allow-empty="false" :show-labels="false" />
            <Multiselect v-model="dates.start_day" placeholder="Day" :options="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]" :searchable="false" :allow-empty="false" :show-labels="false" />
          </div>
          <span v-if="comparison.value === 'between'">&nbsp;and&nbsp;</span>
          <div class="date" v-if="comparison.value === 'between'">
            <Multiselect v-model="dates.end_month" placeholder="Month" :options="['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']" :searchable="false" :allow-empty="false" :show-labels="false" />
            <Multiselect v-model="dates.end_day" placeholder="Day" :options="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]" :searchable="false" :allow-empty="false" :show-labels="false" />
          </div>
        </div>
      </div>

      <div class="form-group" v-if="!enrollment">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'relative'" @click="timeframe = 'relative'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'relative'" @click="timeframe = 'relative'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_relative" @click="timeframe = 'relative'; comparison = null;">
              Relative to Today
            </label>
            <small class="form-text text-muted">Select at least, less than, or exactly a number of days ago. This is a moving window of time. e.g. Within the last 10 days.</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="timeframe === 'relative'">
        <div class="form-inline">
          Every contact who had their birthday&nbsp;
          <Multiselect v-model="comparison" placeholder="Select a comparison" :options="comparisons_number" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" style="max-width: 13rem; min-width: 13rem; width: 13rem;" />&nbsp;
          <input type="number" name="relative_days" v-model.number="relative_days" class="form-control" min="0" style="display: inline-block; width: 5rem;" :disabled="!comparison" />&nbsp;
          days<span v-if="comparison && comparison.value !== 'within'">&nbsp;ago</span>.
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'relative_future'" @click="timeframe = 'relative_future'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'relative_future'" @click="timeframe = 'relative_future'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label @click="timeframe = 'relative_future'; comparison = null;">
              In the future
            </label>
            <small class="form-text text-muted">Select at least, less than, or exactly a number of days ago. This is a moving window of time. e.g. Within the last 10 days.</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="timeframe === 'relative_future'">
        <div class="form-inline">
          Every contact who has their birthday&nbsp;
          <Multiselect v-model="comparison" placeholder="Select a comparison" :options="comparisons_number_future" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" style="max-width: 13rem; min-width: 13rem; width: 13rem;" />&nbsp;
          <input type="number" name="relative_days" v-model.number="relative_days" class="form-control" min="0" style="display: inline-block; width: 5rem;" :disabled="!comparison" />&nbsp;
          days from now.
        </div>
      </div>
    </form>

    <div class="form-group">
      <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition" :disabled="!comparison">Save this condition</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import Multiselect from 'vue-multiselect';
  import VueDatepickerLocal from 'vue-datepicker-local';

  export default {
    props: ['condition', 'editedRule', 'enrollment'],
    components: {
      Multiselect,
      VueDatepickerLocal,
    },
    data() {
      return {
        timeframe: 'relative_future',
        relative_days: 0,
        comparison: null,
        comparisons_date: [{
          label: 'After',
          value: 'after',
        }, {
          label: 'Before',
          value: 'before',
        }, {
          label: 'Between',
          value: 'between',
        }],
        comparisons_number: [{
          label: 'At least',
          value: 'more',
        }, {
          label: 'Within the last',
          value: 'within',
        }, {
          label: 'Exactly',
          value: 'exactly',
        }],
        comparisons_number_future: [{
          label: 'At least',
          value: 'more',
        }, {
          label: 'Exactly',
          value: 'exactly',
        }],
        dates: {
          start_day: '',
          start_month: '',
          end_day: '',
          end_month: '',
        },
        local: {
          dow: 1,
          hourTip: '',
          minuteTip: '',
          secondTip: '',
          yearSuffix: '',
          monthsHead: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
          months: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
          weeks: 'Mon_Tue_Wed_Thu_Fri_Sat_Sun'.split('_'),
        },
      };
    },
    created() {
      if (this.editedRule) {
        this.timeframe = this.editedRule.timeframe;
        this.relative_days = this.editedRule.relative_days;

        if (this.timeframe === 'relative') {
          this.comparison = this.comparisons_number.filter(c =>
            c.value === this.editedRule.comparison,
          )[0];
        }
        if (this.timeframe === 'absolute') {
          this.comparison = this.comparisons_date.filter(c =>
            c.value === this.editedRule.comparison,
          )[0];
        }
        if (this.timeframe === 'relative_future') {
          this.comparison = this.comparisons_number_future.filter(c =>
            c.value === this.editedRule.comparison,
          )[0];
        }

        this.dates.start_day = this.editedRule.start_date_day;
        this.dates.start_month = this.editedRule.start_date_month;
        this.dates.end_day = this.editedRule.end_date_day;
        this.dates.end_month = this.editedRule.end_date_month;
      }
    },
    methods: {
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          timeframe: this.timeframe,
          relative_days: this.relative_days,
          comparison: this.comparison ? this.comparison.value : null,
          start_date_day: this.dates.start_day,
          start_date_month: this.dates.start_month,
          end_date_day: this.dates.end_day,
          end_date_month: this.dates.end_month,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar-inner {
    padding-bottom: 5rem;
    min-height: 70vh;
  }

  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates .date {
    display: flex;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }
</style>
