/* eslint no-unused-vars: "warn" */
/* eslint-disable */

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const FETCH_USER = 'FETCH_USER';
export const LOGOUT = 'LOGOUT';
export const LOADING = 'LOADING';
export const LOADED = 'LOADED';
export const SOCKET_ONOPEN = 'SOCKET_ONOPEN';
export const SOCKET_ONCLOSE = 'SOCKET_ONCLOSE';
export const SOCKET_ONERROR = 'SOCKET_ONERROR';
export const SOCKET_ONMESSAGE = 'SOCKET_ONMESSAGE';
export const SOCKET_RECONNECT = 'SOCKET_RECONNECT';
export const SOCKET_RECONNECT_ERROR = 'SOCKET_RECONNECT_ERROR';
