<template>
  <div>
    <div class="nav-subheader">
      <div class="nav-inner">
        <div>Settings</div>
      </div>
    </div>
    <div class="settings-container">
      <div class="settings-sidebar">
        <div class="title">Company Settings</div>
        <div class="tabs">
          <div :class="['tab', tabs.profile ? 'selected' : '']" @click="toggleTab('profile')">Company Profile</div>
          <div :class="['tab', tabs.franchisees ? 'selected' : '']" @click="toggleTab('franchisees')" v-if="$store.state.user.current_account.franchise">Franchisees</div>
          <div :class="['tab', tabs.franchise_mapping ? 'selected' : '']" @click="toggleTab('franchise_mapping')" v-if="false">Franchise Mapping</div>
          <div :class="['tab', tabs.team ? 'selected' : '']" @click="toggleTab('team')">Team</div>
          <div :class="['tab', tabs.groups ? 'selected' : '']" @click="toggleTab('groups')">User Groups</div>
          <div :class="['tab', tabs.billing ? 'selected' : '']" @click="toggleTab('billing')" v-if="$store.getters.showBilling">Billing</div>
          <div :class="['tab', tabs.integrations ? 'selected' : '']" @click="toggleTab('integrations')">Integrations</div>
          <div class="tab" @click="openQuickBrand()">Company Brand</div>
          <div :class="['tab', tabs.emails ? 'selected' : '']" @click="toggleTab('emails')">Emails</div>
          <div v-if="!$store.getters.isUIAVendor" :class="['tab', tabs.landing_pages ? 'selected' : '']" @click="toggleTab('landing_pages')">Landing Pages</div>
          <div :class="['tab', tabs.reporting ? 'selected' : '']" @click="toggleTab('reporting')">Reporting</div>
        </div>

        <div class="title">User Settings</div>

        <div class="tabs">
          <div :class="['tab', tabs.user_profile ? 'selected' : '']" @click="toggleTab('user_profile')">User Profile</div>
          <div :class="['tab', tabs.notifications ? 'selected' : '']" @click="toggleTab('notifications')">Notifications</div>
        </div>

        <div v-if="$store.getters.isStaff">
          <div class="title">Admin</div>
          <div class="tabs">
            <div :class="['tab', tabs.admin_onboarding ? 'selected' : '']" @click="toggleTab('admin_onboarding')">Onboarding</div>
            <div :class="['tab', tabs.admin_subscription ? 'selected' : '']" @click="toggleTab('admin_subscription')">Subscription</div>
            <div :class="['tab', tabs.admin_templates ? 'selected' : '']" @click="toggleTab('admin_templates')">Templates</div>
            <div :class="['tab', tabs.admin_mindbody ? 'selected' : '']" @click="toggleTab('admin_mindbody')">MINDBODY</div>
            <div :class="['tab', tabs.admin_twilio ? 'selected' : '']" @click="toggleTab('admin_twilio')">Twilio</div>
            <div :class="['tab', tabs.admin_checkoutflows ? 'selected' : '']" @click="toggleTab('admin_checkoutflows')">Checkout Flows</div>
            <div :class="['tab', tabs.debug ? 'selected' : '']" @click="toggleTab('debug')">Debug</div>
          </div>
        </div>
      </div>
      <div class="settings-inner">
        <CompanyProfileSettings v-if="tabs.profile" :account="$store.state.user.current_account" />
        <Franchisees v-if="tabs.franchisees" />
        <FranchiseData v-if="tabs.franchise_mapping" />
        <TeamSettings v-if="tabs.team" />
        <UserGroups v-if="tabs.groups" />
        <Billing v-if="tabs.billing" />
        <Integrations v-if="tabs.integrations" />
        <EmailSettings v-if="tabs.emails" />
        <CheckoutFlowSettings v-if="tabs.landing_pages && !$store.getters.isUIAVendor" />
        <UserProfile v-if="tabs.user_profile" />
        <Notifications v-if="tabs.notifications" />
        <Reporting v-if="tabs.reporting" />
        <AdminOnboarding v-if="tabs.admin_onboarding" />
        <AdminSubscription v-if="tabs.admin_subscription" />
        <AdminTemplates v-if="tabs.admin_templates" />
        <AdminMindbody v-if="tabs.admin_mindbody" />
        <AdminTwilio v-if="tabs.admin_twilio" />
        <AdminCheckoutFlows v-if="tabs.admin_checkoutflows" />
        <AdminDebug v-if="tabs.debug" />
      </div>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import CompanyProfileSettings from './CompanyProfileSettings';
  import Franchisees from './Franchisees';
  import FranchiseData from './FranchiseData';
  import TeamSettings from './TeamSettings';
  import UserGroups from './UserGroups';
  import Billing from './Billing';
  import Integrations from './Integrations';
  import EmailSettings from './EmailSettings';
  import CheckoutFlowSettings from './CheckoutFlowSettings';
  import UserProfile from './UserProfile';
  import Notifications from './Notifications';
  import Reporting from './Reporting';
  import AdminOnboarding from './AdminOnboarding';
  import AdminSubscription from './AdminSubscription';
  import AdminTemplates from './AdminTemplates';
  import AdminMindbody from './AdminMindbody';
  import AdminTwilio from './AdminTwilio';
  import AdminCheckoutFlows from './AdminCheckoutFlows';
  import AdminDebug from './AdminDebug';

  export default {
    props: ['tab'],
    components: {
      CompanyProfileSettings,
      Franchisees,
      FranchiseData,
      TeamSettings,
      UserGroups,
      Billing,
      Integrations,
      EmailSettings,
      CheckoutFlowSettings,
      UserProfile,
      Notifications,
      Reporting,
      AdminOnboarding,
      AdminSubscription,
      AdminTemplates,
      AdminMindbody,
      AdminTwilio,
      AdminCheckoutFlows,
      AdminDebug,
    },
    data() {
      return {
        switch_account: false,
        tabs: {
          profile: false,
          franchisees: false,
          franchise_mapping: false,
          team: false,
          groups: false,
          billing: false,
          integrations: false,
          emails: false,
          checkoutflow: false,
          reporting: false,
          user_profile: false,
          notifications: false,
          admin_onboarding: false,
          admin_subscription: false,
          admin_templates: false,
          admin_mindbody: false,
          admin_twilio: false,
          admin_checkoutflows: false,
          debug: false,
        },
      };
    },
    mounted() {
      EventBus.$on('account_switch.close', () => {
        this.account_switch = false;
      });
    },
    created() {
      this.tabs[this.tab] = true;
    },
    beforeDestroy() {
      EventBus.$off('account_switch.close');
    },
    watch: {
      $route: 'activateTab',
    },
    methods: {
      activateTab() {
        // eslint-disable-next-line
        for (const tab in this.tabs) {
          this.tabs[tab] = false;
        }
        this.tabs[this.tab] = true;
      },
      toggleTab(tab) {
        this.$router.push(`/settings/${tab}`);
      },
      openQuickBrand() {
        this.$router.push({ name: 'quick brand' });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .nav-subheader {
    position: fixed;
    z-index: 1000;
  }

  .settings-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    padding-top: 4rem;
  }

  .settings-sidebar {
    margin-top: 1px;
    height: calc(100% - 116px);
    background-color: #fff;
    width: 16rem;
    min-width: 16rem;
    position: fixed;
    padding: 1.5rem 1.5rem 7rem;
    z-index: 3;
    overflow-y: scroll;
  }

  .settings-sidebar .title {
    font-size: .75rem;
    font-weight: 700;
    line-height: 1.33;
    color: rgb(29, 54, 75);
  }

  .settings-sidebar .tabs {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }

  .settings-sidebar .tabs .tab {
    padding: .5rem .375rem;
    font-size: .875rem;
    line-height: 1.25rem;
    color: rgb(175, 188, 202);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 150ms ease-in-out;
  }

  .settings-sidebar .tabs .tab:hover,
  .settings-sidebar .tabs .tab.selected {
    background-color: var(--xpl-color-secondary-bg-lm);
    color: rgb(29, 54, 75);
  }

  .settings-sidebar .tabs .tab:first-child {
    margin-top: 1rem;
  }

  .settings-sidebar .tabs .tab:last-child {
    margin-bottom: 1rem;
  }

  .settings-inner {
    width: 100%;
    padding-top: 1.5rem;
    padding-left: calc(17rem + 1.5rem);
    padding-right: 1.5rem;
    padding-bottom: 2rem;
  }
</style>
