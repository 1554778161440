<template>
  <el-container>
    <el-main width="100%">
      <el-card>
        <div class="display-small">Continue setting up your Team Member account</div>
        <el-form @submit.native.prevent>
          <el-form-item>
            <label>Password</label>
            <el-input type="password" v-model="password" placeholder="Password" />
            <div class="small muted">Your password needs to be at least 6 characters.</div>
          </el-form-item>
          <el-form-item>
            <label>Confirm Password</label>
            <el-input type="password" v-model="confirm_password" placeholder="Confirm Password" />
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="agree_terms">
              I agree to the <a href="https://www.brandbot.com/legal/terms-of-service" target="_blank">Terms of Services</a> and <a href="https://www.brandbot.com/legal/acceptable-use-policy" target="_blank">Acceptable Use Policy</a>
            </el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="xpl-button xpl-button--primary":disabled="!password || !agree_terms || !confirm_password" @click.stop="confirmAccount" :loading="loading">Confirm</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-main>
  </el-container>
</template>
<script>
  import * as api from '@/api';

  export default {
    props: ['uuid'],
    data() {
      return {
        loading: false,
        password: '',
        confirm_password: '',
        agree_terms: false,
      };
    },
    methods: {
      confirmAccount() {
        const payload = {
          uuid: this.uuid,
          password: this.password,
        };

        if (this.password !== this.confirm_password ||
          !this.password.length) {
          this.$message.error('Your passwords do not match.');
          return;
        }

        if (this.password.length < 6) {
          this.$message.error('Your password needs to be at least 6 characters.');
          return;
        }

        if (this.agree_terms === false) {
          this.$message.error('You have to agree to the Terms of Services');
          return;
        }

        this.loading = true;

        api.confirmInvitation(payload).then(() => {
          this.loading = false;
          this.$message.success('You are now confirmed!');
          this.$router.push('/login');
        }).catch((err) => {
          this.loading = false;
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-main {
    padding-top: 5rem;
  }

  .el-card {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
</style>
