<template>
  <div class="sidebar-inner" style="width: 100%;">
    <span class="pill pill-default text-uppercase">Beta</span>
    <div class="condition-banner">
      Event Qualifier
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="!in_funnel_stage" @click="in_funnel_stage = true"></span>
            <span class="icon icon-radio-checked" v-if="in_funnel_stage" @click="in_funnel_stage = true"></span>
          </div>
          <div class="col-xs-10">
            <label @click="in_funnel_stage = true">Contact is in a Funnel Stage</label>
            <div class="small text-muted">Get Contacts who are currently in the selected Funnel Stage</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="in_funnel_stage" @click="in_funnel_stage = false"></span>
            <span class="icon icon-radio-checked" v-if="in_funnel_stage === false" @click="in_funnel_stage = false"></span>
          </div>
          <div class="col-xs-10">
            <label @click="in_funnel_stage = false">Contact is not in a Funnel Stage</label>
            <div class="small text-muted">Get Contacts who are currently not in the selected Funnel Stage</div>
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Funnel Details
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <label>Get contacts who are{{ in_funnel_stage ? '' : ' not'}} in the following Funnel Stage(s)</label>
        <div class="multiselect-block">
          <Multiselect v-model="selected_funnels" placeholder="Select Funnel Stage(s)" :options="funnels" track-by="id" label="stage" :searchable="true" :multiple="true" :allow-empty="false" :show-labels="false" :close-on-select="false">
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <div class="option__title">{{ props.option.stage }}</div>
                <div class="small" style="margin-top: .25rem;">Funnel: {{ props.option.funnel }}</div>
              </div>
            </template>
          </Multiselect>
        </div>
      </div>
    </form>

    <div class="form-group">
      <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition" :disabled="!selected_funnels.length">Save this condition</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['condition', 'editedRule'],
    components: {
      Multiselect,
    },
    data() {
      return {
        in_funnel_stage: true,
        selected_funnels: [],
        funnels: [],
      };
    },
    created() {
      this.fetchFunnels();

      if (this.editedRule) {
        this.in_funnel_stage = this.editedRule.in_funnel_stage;
        this.selected_funnels = this.editedRule.selected_funnels;
      }
    },
    methods: {
      fetchFunnels() {
        api.funnelsWithStages().then((response) => {
          this.funnels = response.funnels;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          in_funnel_stage: this.in_funnel_stage,
          selected_funnels: this.selected_funnels,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar-inner {
    padding-bottom: 5rem;
    min-height: 70vh;
  }

  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }
</style>
