<template>
  <transition name="fade">
    <div class="form" v-if="funnel" v-cloak>
      <div class="list-nav">
        <router-link to="/contacts/funnels" class="go-back"><i class="fa fa-chevron-left"></i> All Funnels</router-link>
        <input type="text" autocomplete="off" v-model.trim="funnel.name" @keyup.enter="editName" />
        <div class="actions">
          <div class="dropdown">
            <button type="button" class="xpl-button xpl-button--secondary" @click="options_dropdown = !options_dropdown">Options</button>
            <div :class="{ 'dropdown-menu': true, 'dropdown-menu-right': true, 'show': options_dropdown }">
              <a href="#" class="dropdown-item" @click.prevent="exportToCSV">Export to CSV</a>
              <a href="#" class="dropdown-item text-danger" @click.prevent="deleteFunnel">Delete Funnel</a>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="funnel-sidebar">
          <div class="card">
            <div class="card-body">
              <div class="title">Funnel Stages</div>
              <div class="stages" v-if="stages.length">
                <div :class="['stage', selected_stage === stage ? 'active' : '']" v-for="stage in stages" @click.stop="selectStage(stage)">
                  {{ stage.name }}
                  <span>{{ stage.count | prettyNumber }}</span>
                </div>
              </div>
              <a href="#" @click.stop="adding = true" v-if="!adding">Add a new funnel stage</a>
              <div v-if="adding">
                <input type="text" v-model="stage" class="form-control" placeholder="Stage name" @keyup.enter="addStage" />
                <div class="small text-muted">Press enter to save</div>
              </div>
            </div>
          </div>
        </div>
        <div class="funnel-content" v-if="selected_stage">
          <div class="small" v-if="!contacts.length">
            This Funnel Stage currently has no contacts.
          </div>
          <div class="datatable" v-if="contacts.length">
            <table class="table bb-datatable">
              <thead>
                <tr>
                  <td></td>
                  <td>Email Address</td>
                  <td>Name</td>
                  <td class="text-right">Entry Date</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="contact in contacts">
                  <td></td>
                  <td><router-link :to="'/contacts/' + contact.uuid" target="_blank" @click.prevent>{{ contact.email }}</router-link></td>
                  <td>{{ contact.first_name }} {{ contact.last_name }}</td>
                  <td class="text-right">{{ contact.created_at | humanDateTime }}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import * as api from '@/api';

  export default {
    props: ['uuid'],
    data() {
      return {
        options_dropdown: false,
        funnel: null,
        stages: [],
        adding: false,
        stage: '',
        selected_stage: null,
        contacts: [],
      };
    },
    created() {
      this.fetchFunnel();
    },
    methods: {
      addStage(event) {
        const payload = {
          stage: this.stage,
        };

        event.target.disabled = true;

        api.updateFunnel(payload, this.funnel.id).then(() => {
          this.stage = '';
          this.adding = false;
          event.target.disabled = false;
          this.fetchFunnel(true);
        }).catch((err) => {
          event.target.disabled = false;
          this.$toasted.error(err.message);
        });
      },
      fetchFunnel(stageOnly = false) {
        if (!this.uuid) {
          return;
        }

        api.getFunnel(this.uuid).then((response) => {
          if (!stageOnly) {
            this.funnel = response.funnel;
            this.stages = response.stages;
            if (this.stages.length) {
              this.selectStage(this.stages[0]);
            }
          } else {
            this.stages = response.stages;
          }
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      editName() {
        const payload = {
          name: this.funnel.name,
        };

        api.updateFunnel(payload, this.funnel.id).then((response) => {
          this.$toasted.success(response.message);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      exportToCSV() {
        window.open(`${process.env.VUE_APP_API_URL}funnels/export/${this.funnel.id}?uuid=${this.$store.state.user.current_account.uuid}`);
      },
      deleteFunnel() {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to delete this Funnel?')) {
          return;
        }

        api.deleteFunnel(this.funnel.id).then((response) => {
          this.$toasted.success(response.message);
          this.$router.push('/contacts/funnels');
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      selectStage(stage) {
        this.selected_stage = stage;
        api.getContactsInStage(stage.id).then((response) => {
          this.contacts = response.contacts;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .container-fluid {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 0;
  }

  .funnel-sidebar {
    max-width: 20rem;
  }

  .funnel-content {
    margin-top: -1rem;
    margin-left: .875rem;
    display: block;
    padding: 1rem;
    width: 100%;
    background: white;
    height: calc(100vh - 5.5rem);
  }

  .funnel-sidebar .card {
    width: 20rem;
    min-width: 20rem;
    max-width: 20rem;
  }

  .funnel-sidebar .card .card-body .title {
    font-size: .875rem;
    color: rgb(97, 112, 128);
  }

  .funnel-sidebar .card .card-body a {
    font-size: .6875rem;
    font-weight: 700;
    color: rgb(53, 151, 222);
  }

  .stages {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .stages .stage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: .75rem;
    padding-right: .75rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: .875rem;
    color: rgb(97, 112, 128);
    cursor: pointer;
    transition: all 150ms ease-in-out;
  }

  .stages .stage:hover,
  .stages .stage.active {
    background-color: var(--xpl-color-background-lm);
  }
</style>
