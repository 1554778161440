<template>
  <div>
    <div class="card">
      <div class="card-header card-header-merged card-header-secondary">User Profile</div>
      <div class="card-body">
        <div class="title">Change Your Name</div>
        <div class="small">This is going to be changed in all of your associated accounts.</div>
        <form @submit.prevent>
          <div class="form-group">
            <label>First Name</label>
            <input type="text" v-model="first_name" placeholder="First Name" class="form-control" />
          </div>
          <div class="form-group">
            <label>Last Name</label>
            <input type="text" v-model="last_name" placeholder="Last Name" class="form-control" />
          </div>
          <div class="form-group">
            <button type="button" class="xpl-button xpl-button--primary" @click="editName">Save</button>
          </div>
        </form>

        <div class="title">Change Your Password</div>
        <div class="small">This is going to be changed in all of your associated accounts.</div>
        <form @submit.prevent>
          <div class="form-group">
            <label>New Password</label>
            <input type="password" v-model="new_password" placeholder="New Password" class="form-control" />
          </div>
          <div class="form-group">
            <label>Confirm New Password</label>
            <input type="password" v-model="confirm_new_password" placeholder="Confirm New Password" class="form-control" />
          </div>
          <div class="form-group">
            <button type="button" class="xpl-button xpl-button--primary" @click="editPassword">Save</button>
          </div>
        </form>
      </div>
    </div>
    <div class="card" v-if="false">
      <div class="card-header card-header-merged card-header-secondary">Notifications</div>
      <div class="card-body">
        <div class="title">Edit your notifications settings for <strong><u>{{ $store.state.user.current_account.name }}</u></strong> below:</div>
        <form class="notifications" @submit.prevent>
          <div class="form-group">
            <span class="icon icon-checkbox-unchecked2"></span>
            <label>Get notified by email when an item is assigned to me</label>
          </div>

          <div class="form-group" @click.stop="subscribe_activity = !subscribe_activity">
            <span class="icon icon-checkbox-unchecked2" v-if="!subscribe_activity"></span>
            <span class="icon icon-checkbox-checked2" v-if="subscribe_activity"></span>
            <label>Get notified by email when someone comments on an item</label>
          </div>

          <div class="form-group" @click.stop="weekly_update = !weekly_update">
            <span class="icon icon-checkbox-unchecked2" v-if="!weekly_update"></span>
            <span class="icon icon-checkbox-checked2" v-if="weekly_update"></span>
            <label>Get weekly digest emails about my account activity</label>
          </div>

          <div class="form-group">
            <button type="button" class="xpl-button xpl-button--primary" @click.stop="">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import * as api from '@/api';

  export default {
    data() {
      return {
        first_name: this.$store.state.user.first_name,
        last_name: this.$store.state.user.last_name,
        current_password: '',
        new_password: '',
        confirm_new_password: '',
        weekly_update: false,
        subscribe_activity: false,
      };
    },
    methods: {
      editName(event) {
        if (!this.first_name || !this.last_name) {
          this.$toasted.error('A first and last name is required.');
          return;
        }

        const target = event.target || event.srcElement;
        target.disabled = true;

        const payload = {
          first_name: this.first_name,
          last_name: this.last_name,
        };

        api.updateUser(payload, this.$store.state.user.id).then((response) => {
          this.$store.dispatch('refreshUser');
          this.$toasted.success(response.message);
          target.disabled = false;
        }).catch((err) => {
          this.$toasted.error(err.message);
          target.disabled = false;
        });
      },
      editPassword(event) {
        if (!this.new_password ||
          !this.confirm_new_password) {
          this.$toasted.error('Please make sure all fields are filled out correctly.');
          return;
        }

        if (this.new_password !== this.confirm_new_password) {
          this.$toasted.error('Please make sure your passwords match.');
          return;
        }

        if (this.new_password.length < 6) {
          this.$toasted.error('Your new password has to be at least 6 characters long.');
          return;
        }

        const target = event.target || event.srcElement;
        target.disabled = true;

        const payload = {
          new_password: this.new_password,
          new_password_confirmation: this.confirm_new_password,
        };

        api.updateUser(payload, this.$store.state.user.id).then((response) => {
          this.$toasted.success(response.message);
          target.disabled = false;
        }).catch((err) => {
          this.$toasted.error(err.message);
          target.disabled = false;
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .card + .card {
    margin-top: 2rem;
  }

  .card-body .title {
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.43;
    color: rgb(29, 54, 75);
    margin-bottom: .25rem;
  }

  .card-body .small {
    margin-bottom: 1rem;
  }

  form {
    max-width: 50%;
  }

  form.notifications label {
    margin-bottom: 0;
  }

  form.notifications .form-group {
    display: flex;
    align-items: center;
  }

  .notifications .form-group .icon {
    margin-right: .5rem;
  }

  .card-body form:last-child {
    margin-top: 1.5rem;
  }
</style>
