<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Assign Scheduled Task
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <label>Select a Team Member or User Group</label>
                <Multiselect v-model="assignee" :options="team_members" placeholder="Select" track-by="uuid" label="email"  group-values="group" group-label="name" :searchable="true" :show-labels="false" @input="assigneeSelected" />
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="button" class="xpl-button xpl-button--primary" @click.stop="assign($event)">Assign</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['assignedUser', 'scheduledTaskId'],
    components: {
      Multiselect,
    },
    data() {
      return {
        assignee: null,
        team_members: [],
        assigned_user: this.assignedUser,
      };
    },
    created() {
      const confirmedMembers = true;

      api.getTeamMembers(confirmedMembers).then((response) => {
        this.team_members = response.multi;
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    methods: {
      assigneeSelected(selectedOption) {
        // Means this is a user
        if (Object.prototype.hasOwnProperty.call(selectedOption, 'id')) {
          this.assignee = {
            type: 'user',
            id: selectedOption.id,
            first_name: selectedOption.first_name,
            last_name: selectedOption.last_name,
            email: selectedOption.email,
          };
        } else {
          this.assignee = {
            type: 'group',
            uuid: selectedOption.uuid,
            name: selectedOption.email,
            email: selectedOption.email,
          };
        }

        return false;
      },
      closeModal() {
        EventBus.$emit('scheduled_task.assign.close');
      },
      assign() {
        EventBus.$emit('scheduled_task.assign_modal.choose', this.assignee);
        this.closeModal();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal .modal-content .modal-body {
    overflow-y: visible;
  }
</style>
