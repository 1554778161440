<template>
  <el-container class="condition-container">
    <div class="sidebar-inner" style="width: 100%">
      <TimeFrameField
        fieldIndex="0"
        title="When Did The Credit Package Start?"
        fieldStep="1"
        :selectedOption.sync="timeframeComparison"
        :valueOne.sync="timeframeValue"
        futureLabel="days"
        pastLabel="days ago"
        :hideTimeType="false"
        :goalList="null"
        :hidePast="false"
        :hideFuture="true"
        id="31"
      />
      <BooleanField
        fieldIndex="1"
        title="Which Type Of Credit Package(s)?"
        fieldStep="2"
        yesText="All credit packages"
        noText="Specific types"
        :selection.sync="selectionCredit"
        :showLabel="true"
        descLabel="Options include credit type"
        id="33"
      />
      <div v-if="!selectionCredit" class="condition-banner-style">
        Drill Down Options
      </div>
      <MultiSelectField
        v-if="!selectionCredit"
        fieldIndex="2"
        title="Which Credit Package?"
        fieldStep="3"
        :anyLabel="false"
        :specificLabel="false"
        specificAnyLabel="Any of the following credit packages"
        specificExclusionLabel="Any except the following"
        :selectedOptions.sync="selectedPackages"
        specificChoice="specific"
        :exclusionChoice.sync="packageExclusionOption"
        :options="creditPackages"
        selectKey="id"
        id="44"

      />
    </div>
    <div class="save-condition-container" v-if="showSaveButton">
      <el-button
        class="save-condition-btn"
        type="primary"
        @click="commitCondition"
        >Save</el-button
      >
    </div>
  </el-container>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import TimeFrameField from '@/components/Contacts/ContactLists/Conditions/Fields/TimeFrameField';
  import MultiSelectField from '@/components/Contacts/ContactLists/Conditions/Fields/MultiSelectField';
  import BooleanField from '@/components/Contacts/ContactLists/Conditions/Fields/BooleanField';

  export default {
    props: ['condition', 'editedRule', 'goalList'],
    components: {
      TimeFrameField,
      MultiSelectField,
      BooleanField,
    },
    data() {
      return {
        options: [],
        exclusionOption: 'any',
        selectedHomeOption: 'any',
        selectedHomeLocations: [],
        exclusionHomeOption: 'any',
        timeframeComparison: null,
        timeframeValue: 1,
        selectionCredit: true,
        specificCreditPackages: 'any',
        packageExclusionOption: 'any',
        selectedPackages: [],
        creditPackages: [],
      };
    },
    created() {
      this.fetchCreditPackages();
      if (this.editedRule) {
        this.timeframeComparison = this.editedRule.credit_package_comparison;
        if (this.editedRule.credit_package_comparison === 'sinceEntering') {
          this.timeframeComparison = 'allTime';
        }
        this.timeframeValue = this.editedRule.credit_package_value;
        this.selectionCredit = this.editedRule.all_credit_packages;
        this.selectedPackages = this.editedRule.credit_packages;
        this.packageExclusionOption = this.editedRule.credit_package_exclusion;
        this.specificCreditPackages = this.editedRule.credit_package_option;
      }
    },
    computed: {
      showSaveButton() {
        if (!this.timeframeComparison) {
          return false;
        }
        if ((this.timeframeComparison === 'before' || this.timeframeComparison === 'after' || this.timeframeComparison === 'between' || this.timeframeComparison === 'specificDate') && Number.isInteger(this.timeframeValue)) {
          return false;
        }

        if (this.timeframeComparison === 'between' && !this.timeframeValue.length) {
          return false;
        }
        if (!this.selectionCredit && !this.selectedPackages.length) {
          return false;
        }
        return true;
      },
    },
    methods: {
      async fetchCreditPackages() {
        try {
          this.creditPackages = await api.MTCreditsWithoutClassPass();
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          credit_package_comparison: this.timeframeComparison,
          credit_package_value: this.timeframeValue,
          all_credit_packages: this.selectionCredit,
          credit_packages: this.selectedPackages,
          credit_package_exclusion: this.packageExclusionOption,
          credit_package_option: this.specificCreditPackages,
        };
        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
@import "src/scss/global/_spacing.scss";
@import "src/scss/global/_new_main.scss";

.sidebar-inner {
  height: 100%;
  position: relative;
  padding-top: 0;
  background-color: #F4F8FA;
  padding-left: 2.625rem;
  padding-right: 2.25rem;
  padding-bottom: 0;
  width: 100%;
}

.condition-container {
  background-color: #F4F8FA;
  height: 100%;
  width: 100%;
}

.card-condtion {
  width: 100%;
  background-color: #FFFFFF;
  border: solid 1px var(--xpl-color-gray-400);
  box-shadow: none;
  border-radius: 0;
  cursor: auto;
}
.card-condtion:hover {
  background-color: #FFFFFF;
  cursor: auto;
}

.field-container:last-of-type{
  padding-bottom: 100px;
}
.save-condition-btn {
  width: 729px;
  margin: 16px 12px 16px 12px;
}
.save-condition-container {
  // box-shadow: 0 -6px 7px 0 rgba(0, 0, 0, 0.05);
  padding: 0;
  margin: 0;
}
.condition-banner-style {
  background-color: #F4F8FA !important;
  margin-top: 13px !important;
  padding-bottom: 0;
}
</style>
