<template>
  <transition name="fade">
    <div class="add-contacts">
      <a href="#" class="go-back" @click="AllContacts"><i class="fa fa-chevron-left"></i> All Contacts</a>
      <h2>Manually add and import contacts to your account</h2>
      <div class="inner">
        <div class="section">
          <h3>Upload from .CSV</h3>
          <div class="card">
            <div class="card-body">
              <form @submit.prevent>
                <label>Select CSV</label>
                <div class="form-group">
                  <input type="file" accept="text/csv,text/plain" id="csv_file" name="csv_file" class="input-file" @change="fileUpload($event)" />
                  <label for="csv_file">
                    <span ref="csv_file_name"></span>
                    Browse Files
                  </label>
                </div>
                <div class="form-check">
                  <label class="form-check-label" for="check">
                    <input type="checkbox" name="check" id="check" v-model="check" class="form-check-input" />
                    I understand that importing contacts may affect my billing plan
                  </label>
                </div>
              </form>
              <p class="tiny-text">Duplicate addresses will be removed. If you upload a contact that already exists in your account, {{$store.getters.brandName}} will update that account; if that contact is unsubscribed, they will be subscribed. By uploading you are agreeing that you have consent to email all of the imported contacts.</p>
              <button type="button" class="xpl-button xpl-button--primary" :disabled="!check || !file" @click="upload">Upload CSV</button>
            </div>
          </div>
        </div>
        <div class="section">
          <h3>Add a single contact</h3>
          <div class="card">
            <div class="card-body flex">
              <form @submit.prevent>
                <div class="form-group">
                  <label for="email">Email Address</label>
                  <input type="text"
                    id="email"
                    name="email"
                    v-model="one_off.email"
                    :class="{ 'form-control': true, 'is-invalid': $v.one_off.email.$error }"
                    placeholder="Email"
                    @input="$v.one_off.email.$touch()" />
                    <div class="invalid-feedback" v-if="!$v.one_off.email.email">A valid email is required.</div>
                </div>
                <div class="form-group">
                  <label for="first_name">First Name</label>
                  <input type="text"
                    id="first_name"
                    name="first_name"
                    v-model="one_off.first_name"
                    class="form-control"
                    placeholder="First Name" />
                </div>
                <div class="form-group">
                  <label for="last_name">Last Name</label>
                  <input type="text"
                    id="last_name"
                    name="last_name"
                    v-model="one_off.last_name"
                    class="form-control"
                    placeholder="Last Name" />
                </div>
              </form>
              <button type="button" class="xpl-button xpl-button--primary" @click="addContact" :disabled="$v.$error || $v.$invalid">Add Contact</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import { required, email } from 'vuelidate/lib/validators';

  export default {
    data() {
      return {
        check: false,
        file: null,
        one_off: {
          email: '',
          first_name: '',
          last_name: '',
        },
      };
    },
    validations: {
      one_off: {
        email: {
          required,
          email,
        },
      },
    },
    methods: {
      AllContacts() {
        EventBus.$emit('contacts.all_contacts');
      },
      addContact() {
        if (!this.one_off.email) {
          this.$toasted.error('We need an email to be able to add this contact.');
          return;
        }

        const payload = {
          email: this.one_off.email,
          first_name: this.one_off.first_name,
          last_name: this.one_off.last_name,
        };

        api.addContact(payload).then((response) => {
          this.$toasted.success(response.message);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fileUpload(event) {
        const target = event.target || event.srcElement;
        if (!target.files.length) {
          return;
        }

        const file = target.files[0];

        this.$refs.csv_file_name.innerHTML = file.name;
        this.file = file;
      },
      upload(event) {
        const target = event.target || event.srcElement;
        const formData = new FormData();
        formData.append('csv_file', this.file, this.file.name);

        target.disabled = true;

        api.uploadContacts(formData).then((response) => {
          this.$toasted.success(response.message);
          this.file = null;
          this.check = false;
          target.disabled = false;
        }).catch((err) => {
          target.disabled = false;
          if (err.errors && err.errors.csv_file && err.errors.csv_file.length) {
            this.$toasted.error(err.errors.csv_file[0]);
          } else {
            this.$toasted.error(err.message);
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .add-contacts {
    margin-top: 2rem;
  }

  h2, p {
    margin-top: .5rem;
  }

  p {
    max-width: 34rem;
  }

  p.tiny-text {
    font-size: .625rem;
    margin-bottom: 2rem;
  }

  .inner {
    margin-top: 2rem;
  }

  .section {
    margin-bottom: 2rem;
  }

  .card {
    // box-shadow: 0 1px 1px 0 rgba(205, 215, 226, 0.35);
  }

  .card-body.flex form {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .form-group {
    flex: 1;
    margin-right: 1rem;
  }

  .form-group:last-child {
    margin-right: 0;
  }
</style>
