<template>
 <transition name="fade">
    <div class="modal show" style="display: block;" @click="closeModal">
      <div class="modal-dialog" @click.stop>
        <div class="modal-content">
          <div class="modal-body">
            <el-form>
              <el-form-item>
                <label>Email</label>
                <el-input type="email" v-model="email" placeholder="Email" />
              </el-form-item>
              <el-form-item>
                <label>First Name</label>
                <el-input v-model="first_name" placeholder="First" />
              </el-form-item>
              <el-form-item>
                <label>Last Name</label>
                <el-input v-model="last_name" placeholder="Last" />
              </el-form-item>
              <el-form-item>
                <label>Permissions</label>
                <el-select v-model="permission" placeholder="Permission" style="width: 100%;">
                  <el-option :label="'Creator'" :value="'creator'"></el-option>
                  <el-option :label="'Manager'" :value="'manager'"></el-option>
                  <el-option :label="'Admin'" :value="'admin'"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="xpl-button xpl-button--primary" v-loading="loading" @click="inviteMember" :disabled="!email || !first_name || !last_name || !permission">Invite</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import { addAccountUser } from '@/api';

  export default {
    data() {
      return {
        loading: false,
        email: null,
        first_name: null,
        last_name: null,
        permission: null,
      };
    },
    methods: {
      closeModal() {
        EventBus.$emit('newUserModal.close');
      },
      inviteMember() {
        const payload = {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          permission: this.permission.toLowerCase(),
        };

        this.loading = true;

        addAccountUser(payload).then(() => {
          this.loading = false;
          this.$message.success('Team member invited!');
          this.closeModal();
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal .modal-content .modal-body {
    max-height: none;
  }
</style>
