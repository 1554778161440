<template>
  <el-card>
    <div class="v2__heading">{{ type | humanType }}</div>

    <el-form @submit.native.prevent="addNew">
      <el-form-item v-if="!$store.state.user.current_account.is_child">
        <label>Data Point Name</label>
        <el-input v-model="name" placeholder="Name" />
      </el-form-item>

      <el-form-item v-if="!$store.state.user.current_account.is_child">
        <el-button type="primary" class="xpl-button xpl-button--primary" @click="addNew" :loading="loading">Add New</el-button>
      </el-form-item>

      <el-form-item>
        <el-table :data="tableData" v-loading="loading">
          <el-table-column label="ID" prop="id"></el-table-column>
          <el-table-column label="Name" prop="name"></el-table-column>
          <el-table-column label="Required" prop="required" align="right">
            <template slot-scope="scope">
              <div v-if="!$store.state.user.current_account.is_child">
                <el-checkbox v-model="scope.row.required" @change="updateType(scope.row)" />
              </div>
              <div v-if="$store.state.user.current_account.is_child">
                {{ scope.row.required ? 'Required' : 'Not Required' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Equivalent" prop="equivalent" v-if="$store.state.user.current_account.is_child">
            <template slot-scope="scope">
              <div v-if="!scope.row.edit">{{ scope.row.equivalent ? scope.row.equivalent.name : '' }}</div>
              <el-select v-model="scope.row.equivalent"
                filterable
                placeholder="Equivalent"
                v-if="scope.row.edit"
                value-key="id">
                <el-option v-for="item in equivalents" :key="item.id" :label="item.name" :value="item"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="Actions" width="120" v-if="$store.state.user.current_account.is_child">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click.stop="setEditable(scope.row)">{{ scope.row.edit ? 'Done' : 'Edit' }}</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination v-if="false" layout="prev, next" @next-click="nextPage" @prev-click="prevPage" style="text-align: center;"></el-pagination>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
  import {
    getVendorDataTypes,
    addVendorDataPoint,
    updateVendorDataPoint,
    saveMappingRelationship,
  } from '@/api';

  export default {
    props: ['type', 'query'],
    data() {
      return {
        name: null,
        loading: false,
        activeType: null,
        tableData: [],
        equivalents: [],
        page: 1,
      };
    },
    created() {
      this.fetchDataTypes(this.type, this.query, this.page);
    },
    filters: {
      humanType(type) {
        let string = type.replace('_', ' ');
        string = string.toLowerCase()
              .split(' ')
              .map(s => s.charAt(0).toUpperCase() + s.substring(1))
              .join(' ');

        return string;
      },
    },
    watch: {
      type() {
        this.tableData = [];
        this.fetchDataTypes(this.type, this.query);
      },
    },
    methods: {
      prevPage() {
        if (this.page > 1) {
          this.page = this.page - 1;
          this.fetchDataTypes(this.type, this.query, this.page);
        }
      },
      nextPage() {
        this.page = this.page + 1;
        this.fetchDataTypes(this.type, this.query, this.page);
      },
      setEditable(type) {
        if (type.edit) {
          this.updateRelationship(type);
        } else {
          this.$set(type, 'edit', true);
        }
      },
      updateRelationship(type) {
        const payload = {
          franchise_id: type.id,
          child_id: type.equivalent.child_id,
        };
        saveMappingRelationship(payload).then((response) => {
          this.$set(type, 'edit', false);
          this.$message.success(response.message);
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchDataTypes(type = '', query = '', page = 1) {
        this.data = null;
        this.loading = true;

        getVendorDataTypes(type, query, page).then((response) => {
          this.equivalents = response.equivalents;
          this.$set(this, 'tableData', response.parent_data);
          this.loading = false;
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      updateType(type) {
        const payload = {
          required: type.required,
        };

        updateVendorDataPoint(type.id, payload).then((response) => {
          this.$message.success(response.message);
          this.fetchDataTypes(this.type, this.query, this.page);
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      addNew() {
        const payload = {
          vendor: this.$store.state.user.current_account.active_vendors[0],
          name: this.name,
          type: this.type,
        };

        this.loading = true;

        addVendorDataPoint(payload).then((response) => {
          this.name = null;
          this.$message.success(response.message);
          this.fetchDataTypes(this.type, this.query, this.page);
          this.loading = false;
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
