// import { applyPolyfills, defineCustomElements } from '@xplortech/apollo-core/loader';
import Vue from 'vue';
//import VueIntercom from 'vue-intercom';
import Vuex from 'vuex';
import Intercom from './libs/intercom/src/lib/index';
import Vuelidate from 'vuelidate';
import Toasted from 'vue-toasted';
import VueClipboard from 'vue-clipboard2';
import VuejsDialog from 'vuejs-dialog';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';
import VueNativeSock from 'vue-native-websocket';
import App from './App';
import router from './router';
import store from './store';
import filters from './filters';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faMessage } from '@fortawesome/free-regular-svg-icons'
import { faClipboard } from '@fortawesome/free-regular-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faTag } from '@fortawesome/free-solid-svg-icons'





/* add icons to the library */
library.add(faEnvelope, faMessage, faClipboard, faPhone, faTag)


/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
// applyPolyfills()
//    .then(() => defineCustomElements());

//Vue.use(VueIntercom, {appId: process.env.VUE_APP_INTERCOM_APP_ID});

if (process.env.NODE_ENV === 'production') {
  Raven
    .config('https://fd5812a3151a4d34b215e8acf178c1b6@sentry.io/254153')
    .addPlugin(RavenVue, Vue)
    .install();
  Raven.setUserContext({
    email: store.state.user ? store.state.user.email : 'anonymous',
  });

  Vue.use(VueNativeSock, 'wss://realtime.brndbot.net/', {
    format: 'json',
    reconnection: true,
    reconnectionAttempts: 10,
    reconnectionDelay: 5000,
    store,
  });
}

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(VueClipboard);
Vue.use(Toasted, {
  position: 'top-center',
  duration: 5000,
});
Vue.use(VuejsDialog);
Vue.use(ElementUI, {locale});

//
if (process.env.NODE_ENV === 'production') {
  Vue.prototype.$log = function $log() {
  };
} else {
// eslint-disable-next-line no-console
  Vue.prototype.$log = console.log;
}


window.toCanonicalHost = function (host) {
  const iframe = document.createElement('iframe');
  document.body.appendChild(iframe);
  iframe.id = "canonicaliframe";
  iframe.width = "1";
  iframe.height = "1";
  iframe.style.width = "1px";
  iframe.style.height = "1px";
  iframe.src = document.location.protocol + '//' + host + '/static/local-storage-sync.html';
}

window.addEventListener("message", function (e) {
  if (e.data.replaceByCanonicalUrl) {
    // localStorage.clear();
    window.location.replace($vm.toCanonicalUrl);
  }
  if (e.data.iframeLoaded) {
    const iframe = document.getElementById("canonicaliframe");
    iframe.contentWindow.postMessage({localStorage: JSON.stringify(localStorage)}, '*');
  }
}, false);

//new intercom stuff
let intercom; // = new Intercom({ appId });
window.rebootIntercom = function (options) {
  intercom.shutdown();
  if (intercom.isBooted) return;
  intercom.boot(options);
}
window.startIntercomMessenger = function (appId, options) {
  if (!intercom) {
    intercom = new Intercom({appId});
  }
  if (!intercom.ready) {
    intercom.once('ready', () => window.rebootIntercom(options));
  } else {
    window.rebootIntercom(options);
  }
}

window.sleep = function (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

/* eslint-disable no-new */
let $vm = new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {App},
  computed: {
    ...Vuex.mapGetters(['isLoggedIn', 'toCanonicalUrl']),
  },
  filters,
});
window.$vm = $vm;
