<template>
  <transition>
    <div class="conditions-sidebar" v-if="!selectedCondition">
      <div class="conditions-sidebar-header">
        <div class="back-button" @click="goBack">
          <span class="material-icons">arrow_back</span>
        </div>
        Select a condition to add
      </div>
      <div class="conditions-sidebar-body">
        <el-form @submit.native.prevent>
          <el-form-item>
            <label>Conditions Filtering</label>
            <el-select v-model="condition_category" filterable placeholder="Select a Condition Type" style="width: 100%;">
              <el-option v-for="item in condition_categories" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <el-input v-model="query" placeholder="Search..." />
          </el-form-item>
        </el-form>
        <div class="condition-types">
          <div class="condition-type" v-for="condition in conditionTypesFiltered" @click="pickCondition(condition)" :key="condition.uuid">
            <img :src="'https://assets.brandbot.com/public/icons/conditions/' + condition.uuid + '.svg'" />
            <div>
              <div class="header">
                {{ enrollment && condition.uuid === 'd323c020-0569-4f1e-ab1d-f56672d66610' ? 'Joins a Membership for the first time' : condition.name }}
                <span class="pill pill-info" v-if="condition.real_time && $store.getters.isStaff && $store.state.user.current_account.real_time">
                  Real-time
                </span>
              </div>
              <div class="description">{{ condition.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BaseCondition :selectedCondition.sync="selectedCondition" :editedRule="editedRule" :locations="locations" :enrollment="enrollment" :goalList="goalList" v-if="selectedCondition" />
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import { accountIds } from '@/values/accountIdValues';
  import BaseCondition from './BaseCondition';

  export default {
    props: ['selectedCondition', 'editedRule', 'enrollment', 'goalList', 'campaignItem', 'locations'],
    data() {
      return {
        condition_category: 'all',
        condition_categories: [],
        conditions: [],
        query: '',
      };
    },
    components: {
      BaseCondition,
    },
    mounted() {
      api.getContactListsAvailableConditionTypes().then((response) => {
        if (accountIds.aquaTotsCartAbandonmentBeta.includes(
          this.$store.state.user.current_account.id)
        ) {
          this.conditions = response.conditions;
        } else if (!accountIds.aquaTotsCartAbandonmentBeta.includes(
          this.$store.state.user.current_account.id)
        ) {
          const filterATConditions = response.conditions.filter(condition => condition.uuid !== '37a361cc-ded7-48b6-9d70-1c4b7aa08285');
          this.conditions = filterATConditions;
        }
        const categories = response.categories.map(c => ({
          label: c.charAt(0).toUpperCase() + c.slice(1).replace('_', ' '),
          value: c,
        }));
        this.condition_categories = categories;
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    computed: {
      conditionTypesFiltered() {
        let conditions = this.conditions;
        if (this.enrollment) {
          // Joined A Membership
          conditions = conditions.filter(ct => ct.uuid !== '27fb545f-fc3f-4cd0-9b4c-6de1983a3a8b');
        }

        if (this.condition_category !== 'all' && this.condition_category !== 'most_common') {
          conditions = conditions.filter(ct => ct.category === this.condition_category);
        }

        if (this.query) {
          conditions = conditions
            .filter(c => c.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1);
        }

        return conditions;
      },
    },
    methods: {
      goBack() {
        EventBus.$emit('selected_condition.close');
      },
      pickCondition(condition) {
        EventBus.$emit('conditions_selection.pick', condition);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .back-button {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f6fa;
    margin-right: 1.25rem;
    cursor: pointer;
  }

  .back-button .material-icons {
    color: #afbcca;
    font-size: 1rem;
    transition: color 150ms ease-in-out;
  }

  .back-button:hover .material-icons {
    color: #1D364B;
  }

  form {
    width: 100%;
    max-height: 80px;
  }

  .conditions-sidebar {
    background-color: #fff;
    border-left: 1px solid lightgray;
  }

.conditions-sidebar-header {
  justify-content: flex-start;
  padding-bottom: 1rem;
  background-color: #fff;
  padding: 2rem 1.5rem;
  display: flex;
}

  .conditions-sidebar-body {
    padding-top: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%
  }

  .condition-types {
    width: 100%;
    display: block;
    margin: 3.5rem 0;
    height: calc(100vh - 10% - 80px);
    overflow-y: scroll;
  }

  .condition-type {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border-radius: 6px;
    background-color: #ffffff;
    // box-shadow: 0 1px 0 0 rgba(217, 231, 240, 0.5);
    border: solid 1px var(--xpl-color-gray-400);
    margin-bottom: 1rem;
    padding: .75rem;
    transition: all 150ms ease-in-out;
  }

  .condition-type:hover {
    cursor: pointer;
    background-color: var(--xpl-color-gray-400);
    // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    border-color: #6923f4;
  }

  .condition-type .header {
    font-weight: 700;
    line-height: 1;
  }

  .condition-type img {
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 1rem;
  }

  .condition-type .description {
    margin-top: .5rem;
    font-size: .75rem;
    line-height: 1rem;
    color: #617080;
  }
</style>
