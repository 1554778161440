<template>
  <div :class="['card', enrollmentRuleComplete ? '' : 'inactive']">
    <div class="card-body">
      <div class="title">
        <div>
          <span :class="['step-number', goalComplete ? 'complete' : '']">
            <span v-if="goalPending">2</span>
            <span class="icon icon-checkmark" v-if="goalComplete"></span>
          </span>
          Do you want to automatically remove contacts if they meet one or more goals?
          <span style="margin-left: auto;" v-if="!hideButtons">
            <button type="button" class="xpl-button xpl-button--secondary" @click.prevent="edit_goal = true" v-if="campaign.goals && campaign.goals.length && !edit_goal">Edit</button>
            <button type="button" class="xpl-button xpl-button--secondary" @click.prevent="use_goals = null" v-if="use_goals === false || (!campaign.goals.length && use_goals !== null)">Edit</button>
          </span>
        </div>
      </div>
      <div class="goal" v-if="use_goals === null && !campaign.goals.length">
        Using goals can help you make sure you are not sending emails encouraging your customers to do something they may have already done.
        <div class="buttons">
          <button class="xpl-button xpl-button--secondary" @click.stop="toggleWithGoal(true, $event)">Yes</button>
          <button class="xpl-button xpl-button--secondary" @click.stop="toggleWithGoal(false, $event)">No</button>
        </div>
      </div>

      <div class="goal" v-if="use_goals === false || (!campaign.goals.length && use_goals !== null)">
        You have chosen to not define any goals for this campaign.
      </div>

      <div class="goal-setup goal-container" v-for="goal in campaign.goals">
        <div v-if="goal !== currentGoal && !currentGoal">
          <div class="goal-name">
            <div>{{ goal.name }}</div>
            <div>
              <button class="xpl-button xpl-button--secondary" @click.stop="editGoal(goal.id)" v-if="edit_goal">Edit Goal</button>
              <button class="btn btn-remove" @click.stop="removeGoal(goal.id)" v-if="edit_goal">
                <span class="material-icons" style="font-size: .875rem; line-height: 1.43; color: #EC6B6B; cursor: pointer;">delete</span>
              </button>
            </div>
          </div>
          <EnrollmentEnglish v-if="goal.rule && goal.rule.length && goal.rule[0].condition.quick" :rule="goal.rule[0]" />
          <div style="position: relative;" v-if="goal.rule && goal.rule.length && !goal.rule[0].condition.quick">
            <div style="margin-bottom: 1rem;">Any contacts when they meet the following rule:</div>
            <RuleEnglishHybrid :rule="goal.rule" :enrollment="false" />
          </div>
        </div>
        <div v-if="goal === currentGoal">
          <div class="goal-settings">
            <div class="goal-name">
              <div>
                <input type="text" v-model="goal.name" id="goal_name" placeholder="Goal Name" class="form-control" @keyup.enter="editGoalName" @focus="toggleHelp" @blur="toggleHelp" />
                <div class="small text-muted" v-if="name_help">Hit enter to save</div>
              </div>
            </div>
            <div>
              <button class="xpl-button xpl-button--secondary" v-if="goal.rule && goal.rule.length && false" @click="edit = false; currentGoal = null; edit_goal = false; selectedOption = null;">Done</button>
              <button class="btn" @click.stop="removeGoal(goal.id)" v-if="edit_goal">
                <span class="material-icons" style="font-size: .875rem; line-height: 1.43; color: #EC6B6B; cursor: pointer;">delete</span>
              </button>
            </div>
          </div>
          <label>When does a contact meet this goal?</label>
          <div class="options-container" v-if="selectedOption === null && !advanced_rule">
            <div class="quick-options" v-if="!($store.getters.isPike13 || $store.getters.isMT || $store.getters.isUIAVendor)">
              <div class="title">Quick Options</div>
              <div class="options">
                <div class="option" @click.prevent="selectQuickOption('5c48a13c-ac7f-4873-9d23-cba7b05f206e')">
                  <div class="icon">
                    <img src="https://assets.brandbot.com/public/icons/conditions/5c48a13c-ac7f-4873-9d23-cba7b05f206e.svg" />
                  </div>
                  <div class="name">
                    <div class="header">Purchase</div>
                    <span>The contact has made a purchase after entering this auto-campaign</span>
                  </div>
                </div>
                <div class="option" @click.prevent="selectQuickOption('d323c020-0569-4f1e-ab1d-f56672d66610')">
                  <div class="icon">
                    <img src="https://assets.brandbot.com/public/icons/conditions/d323c020-0569-4f1e-ab1d-f56672d66610.svg" />
                  </div>
                  <div class="name">
                    <div class="header">Membership</div>
                    <span>The contact has been assigned a membership type after entering this auto-campaign</span>
                  </div>
                </div>
                <div class="option" @click.prevent="selectQuickOption('af62480f-6da6-4519-9d24-de4996a30d98')">
                  <div class="icon">
                    <img src="https://assets.brandbot.com/public/icons/conditions/af62480f-6da6-4519-9d24-de4996a30d98.svg" />
                  </div>
                  <div class="name">
                    <div class="header">First Visit</div>
                    <span>The contact has a first visit (common for focusing on leads)</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="quick-options">
              <div class="title">Advanced Options</div>
              <div class="options">
                <div class="option" @click.prevent="conditions_sidebar = true">
                  <div class="icon">
                    <img src="https://assets.brandbot.com/public/icons/conditions/advanced.svg" />
                  </div>
                  <div class="name">
                    <div class="header">Custom Rule</div>
                    <span>Use our advanced rule builder to combine any of our 20+ conditions</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="selected-options" style="margin-left: 0;" v-if="advanced_rule && selectedOption === null">
            <div class="btn-back" @click.prevent="selectedOption = null; advanced_rule = false; currentGoal.rule = null;">
              <span class="material-icons">keyboard_arrow_left</span>
              Back to options
            </div>
            <p style="display: flex; justify-content: space-between;">
              The contact meets the following rule
              <button class="xpl-button xpl-button--secondary" type="button" @click="conditions_sidebar = true;">Edit Custom Rule</button>
            </p>
            <div style="position: relative;">
              <RuleEnglishHybrid :rule="goal.rule" :enrollment="false" />
            </div>
            <div style="margin-top: 1rem;">
              <button class="xpl-button xpl-button--primary" @click="selectedOption = null; currentGoal = null; advanced_rule = false;">Save</button>
            </div>
          </div>
        </div>
      </div>

      <div class="selected-options" v-if="(selectedOption && !currentGoal) || (edit && currentGoal && selectedOption)">
        <div class="btn-back" @click.prevent="selectedOption = null; currentGoal.rule = null;">
          <span class="material-icons">keyboard_arrow_left</span>
          Back to options
        </div>
        <Purchase v-if="selectedOption === '5c48a13c-ac7f-4873-9d23-cba7b05f206e'" :uuid="selectedOption" :rule="currentGoal.rule" :goal="true" />
        <Membership v-if="selectedOption === 'd323c020-0569-4f1e-ab1d-f56672d66610'" :uuid="selectedOption" :rule="currentGoal.rule" :goal="true" />
        <FirstVisit v-if="selectedOption === 'af62480f-6da6-4519-9d24-de4996a30d98'" :uuid="selectedOption" :rule="currentGoal.rule" :goal="true" />
      </div>

      <div class="goal-setup">
        <div class="goal-add" v-if="edit_goal && !selectedOption && !currentGoal && campaign.goals.length && campaign.goals[0].rule && campaign.goals[0].rule.length">
          <button class="xpl-button xpl-button--primary" @click="edit = false; currentGoal = null; edit_goal = false; selectedOption = null;">Done</button>
          <button class="xpl-button xpl-button--secondary" @click="newGoal">Add another goal</button>
        </div>
      </div>

      <div class="help">
        <a href="https://docs.brandbot.com/en/articles/3854893-auto-campaigns-goals" class="btn btn-bb-learn-more" target="_blank">
          <span class="material-icons">info_outline</span>
          Learn more about auto-campaign goals
        </a>
      </div>
    </div>
    <ConditionsSidebarHybrid v-if="conditions_sidebar" :contact_list.sync="currentGoal" :enrollment="false" :goalList="true" />
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import ConditionsSidebar from '@/components/Contacts/ContactLists/Conditions/ConditionsSidebar';
  import RuleEnglish from '@/components/Contacts/ContactLists/Conditions/RuleEnglish';
  import Purchase from './Quick/Purchase';
  import Membership from './Quick/Membership';
  import FirstVisit from './Quick/FirstVisit';
  import EnrollmentEnglish from './EnrollmentEnglish';
  import RuleEnglishHybrid from '../../Contacts/ContactLists/Conditions/RuleEnglishHybrid';
  import ConditionsSidebarHybrid from '../../Contacts/ContactLists/Conditions/ConditionsSidebarHybrid';

  export default {
    props: ['campaign', 'enrollmentRuleComplete', 'editing_goals'],
    components: {
      ConditionsSidebarHybrid,
      RuleEnglishHybrid,
      ConditionsSidebar,
      Purchase,
      Membership,
      FirstVisit,
      EnrollmentEnglish,
      RuleEnglish,
    },
    data() {
      return {
        name_help: false,
        edit: false,
        edit_goal: false,
        conditions_sidebar: false,
        use_goals: null,
        selectedOption: null,
        currentGoal: null,
        advanced_rule: false,
      };
    },
    mounted() {
      EventBus.$on('contact_list.conditions_sidebar.close', () => {
        this.conditions_sidebar = false;
        this.selectedOption = null;
        this.edit = true;
        this.advanced_rule = true;
      });

      EventBus.$on('condition.commit', (payload) => {
        if (payload.type && payload.type === 'goal') {
          this.currentGoal.rule = [payload];
          this.saveGoalRule();
        }
      });
    },
    created() {
      this.use_goals = !this.campaign.no_goals;
      this.edit_goal = this.editing_goals;
    },
    beforeDestroy() {
      EventBus.$off('contact_list.conditions_sidebar.close');
      EventBus.$off('condition.commit');
    },
    watch: {
      campaign(newVal) {
        this.campaign.goals = newVal.goals;
      },
    },
    methods: {
      toggleHelp() {
        this.name_help = !this.name_help;
      },
      selectQuickOption(option) {
        this.selectedOption = option;
      },
      saveGoalRule() {
        const payload = {
          contact_list_id: this.currentGoal.id,
          rule: JSON.stringify(this.currentGoal.rule),
        };

        api.saveSmartRule(payload).then((response) => {
          this.$toasted.success(response.message);
          this.selectedOption = null;
          this.currentGoal = null;
          EventBus.$emit('campaign.goal.setup');
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      editGoal(contactListId) {
        if (this.campaign.goals.length) {
          this.edit = true;
          this.currentGoal = this.campaign.goals.filter(g => g.id === contactListId)[0];
          if (this.currentGoal.rule &&
              this.currentGoal.rule.length &&
              this.currentGoal.rule[0].condition.quick) {
            this.selectedOption = this.currentGoal.rule[0].condition.uuid;
          }

          if (!this.currentGoal.rule[0].condition.quick) {
            this.advanced_rule = true;
          }
        }
      },
      removeGoal(contactListId) {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to remove this goal? This is irreversible.')) {
          return;
        }

        const payload = {
          action: 'delete_goal',
          contact_list_id: contactListId,
        };

        api.deleteGoalCampaign(this.campaign.id, payload).then((response) => {
          this.$toasted.success(response.message);
          this.campaign = response.campaign;
          this.edit = true;
          this.selectedOption = null;
          this.currentGoal = null;
          this.advanced_rule = false;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      editGoalName() {
        const payload = {
          name: this.currentGoal.name,
        };
        api.updateContactList(this.currentGoal.id, payload).then(() => {
          this.$toasted.success('Goal Name updated');
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      toggleWithGoal(toggle, event) {
        const target = event.target || event.srcElement;
        target.disabled = true;

        const payload = {
          no_goals: !toggle,
        };

        api.updateCampaign(this.campaign.id, payload).then(() => {
          target.disabled = false;
          this.campaign.no_goals = !this.campaign.no_goals;


          if (toggle) {
            this.newGoal();
          } else {
            this.use_goals = toggle;
          }
        }).catch((err) => {
          this.$toasted.error(err.message);
          target.disabled = false;
        });
      },
      newGoal() {
        api.addNewGoalCampaign(this.campaign.id, { action: 'new_goal' }).then((response) => {
          this.edit = true;
          this.edit_goal = true;
          this.campaign = response.campaign;
          this.currentGoal = this.campaign.goals[this.campaign.goals.length - 1];
          this.selectedOption = null;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
    computed: {
      hideButtons() {
        if (this.$store.state.user.is_hq_admin || this.$store.getters.isStaff) {
          return false;
        }

        if (this.campaign.sync_type && this.campaign.sync_type === 'sync_lock') {
          return true;
        }

        if (this.campaign.sync_type && this.campaign.sync_type === 'sync_unlock') {
          return true;
        }

        return false;
      },
      goalComplete() {
        if (this.use_goals === false) {
          return true;
        }

        if (this.campaign.goals.length && !this.edit) {
          return true;
        }

        return false;
      },
      goalPending() {
        if (this.goalComplete) {
          return false;
        }

        return true;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .conditions-container .see-more {
    padding-left: 0 !important;
  }
  .conditions-container .see-more-inner {
    background-color: #f6fafc !important;
  }

  .conditions-container .gradient {
    background: linear-gradient(rgba(246, 250, 252, 0), rgba(246, 250, 252, 1)) !important;
  }
  .card {
    margin-top: 1.5rem;
  }

  .inactive,
  .inactive .title,
  .inactive .goal-name {
    pointer-events: none;
    background-color: var(--xpl-color-gray-400) !important;
    color: #8b9bac !important;
  }

  .goal-name input {
    outline: none;
    border: none;
    border-radius: 0;
    padding: 0;
    padding-bottom: .25rem;
    font-size: 1rem;
    font-weight: 700;
    color: var(--xpl-color-secondary-lm);
    min-width: 20rem;
    border-bottom: 1px dashed var(--xpl-color-secondary-lm);
  }

  .inactive .step-number,
  .inactive .icon * {
    background-color: #cdd7e2 !important;
    border-color: #cdd7e2 !important;
    color: white !important;
  }

  .inactive .goal,
  .inactive .btn-bb-learn-more {
    opacity: .46;
  }

  .inactive button {
    display: none;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    color: #1D364B;
    line-height: 1.5rem;
    margin-bottom: .25rem;
  }

  .title div {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 99rem;
    background-color: var(--xpl-color-secondary-lm);
    margin-right: 1rem;
    color: white;
    font-size: .75rem;
  }

  .step-number.complete {
    background-color: white;
    border: 2px solid var(--xpl-color-secondary-lm);
    color: var(--xpl-color-secondary-lm);
  }

  .goal {
    padding-left: 2.5rem;
    margin-top: .75rem;
    font-size: .875rem;
    color: #617080;
    max-width: 44.25rem;
    line-height: 1.43;
    margin-bottom: 1.75rem;
  }

  .goal-setup {
    padding-left: 2.5rem;
    margin-top: 1.5rem;
    font-size: .875rem;
    color: #617080;
    line-height: 1.43;
  }

  .goal .buttons {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .goal .buttons button {
    min-width: 5rem;
  }

  .goal .buttons button:first-child {
    margin-right: .75rem;
  }

  .help  {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .quick-options:last-child {
    margin-top: 1.5rem;
  }

  .quick-options .title {
    text-transform: uppercase;
    color: #AFBCCA;
    font-size: .625rem;
  }

  .quick-options .options .option {
    width: 100%;
    display: flex;
    border: 1px solid var(--xpl-color-gray-400);
    border-radius: 6px;
    background-color: #FFFFFF;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: .675rem;
    padding-right: .675rem;
  }

  .quick-options .options .option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .875rem;
    color: #1D364B;
    transition: all 300ms ease-in-out;
    margin-bottom: .5rem;
  }

  .quick-options .options .option:hover {
    cursor: pointer;
    border: 1px solid var(--xpl-color-secondary-lm);
    // box-shadow: 0 2px 4px 0 var(--xpl-color-gray-400);
  }

  .quick-options .options .option .icon {
    width: 2.25rem;
    height: 2.25rem;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: .5rem;
    border-radius: 6px;
    background-color: #EFF6FD;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 300ms ease-in-out;
  }

  .quick-options .options .option:hover .icon {
    background-color: var(--xpl-color-secondary-bg-lm);
  }

  .quick-options .options .option .icon img {
    width: 1rem;
    max-width: 1rem;
  }

  .quick-options .options .option .name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: .875rem;
    color: rgba(29, 54, 75, .55);
  }

  .quick-options .options .option .name .header {
    color: rgb(29, 54, 75);
    font-weight: bold;
    margin-right: 1rem;
  }

  .goal-settings {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .goal-setup label {
    font-size: .875rem;
    font-weight: 700;
    color: #1d364b;
    margin-top: 1rem;
  }

  .selected-options .btn-back {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: rgb(105, 139, 156);
    font-size: .75rem;
    margin-bottom: 1.5rem;
  }

  .selected-options .btn-back .material-icons {
    font-size: .75rem;
    margin-right: .5rem;
    line-height: 1;
  }

  .goal-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    color: #6923f4;
    margin-bottom: .75rem;
  }

  .goal-name label {
    margin: 0;
    margin-bottom: .25rem;
    cursor: pointer;
  }

  .goal-add {
    margin-top: 2rem;
  }

  .goal-add .btn:first-child {
    margin-right: 1rem;
  }

  .options-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 6px;
    background-color: rgba(235, 244, 250, 0.51);
    box-shadow: inset 0 3px 15px 0 rgba(139, 155, 172, 0.13);
  }

  .selected-options {
    margin-left: 2.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 6px;
    background-color: rgba(235, 244, 250, 0.51);
    box-shadow: inset 0 3px 15px 0 rgba(139, 155, 172, 0.13);
  }

  .btn-remove {
    border-radius: 64px;
    margin-left: 8px;
  }
</style>
