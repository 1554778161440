<template>
  <el-card shadow="never">
    <div class="display-small">Available Locations</div>
    <el-table :data="locations.data" width="500px">
      <el-table-column prop="id" label="ID" width="100px"></el-table-column>
      <el-table-column prop="attributes.name" label="Name" width="300px"></el-table-column>
      <el-table-column align="right" label="Link to Account" width="200px">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.connect" active-text="Link to Account" />
        </template>
      </el-table-column>
    </el-table>
    <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="connectLocations" :loading="loading" :disabled="!connectedLocations.length">Continue</el-button>
  </el-card>
</template>
<script>
  import { MTLinkLocations } from '@/api';
  import EventBus from '@/event-bus';

  export default {
    props: ['locations'],
    data() {
      return {
        loading: false,
      };
    },
    methods: {
      connectLocations() {
        if (!this.connectedLocations.length) {
          this.$message.error('At least one location must be linked.');
          return;
        }

        this.loading = true;
        const payload = {
          location_ids: this.connectedLocations.map(l => l.id),
        };

        MTLinkLocations(payload).then((response) => {
          this.loading = false;
          this.$message.success(response.message);
          EventBus.$emit('signup.vendor.done', 'marianatek');
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
    },
    computed: {
      isPaginated() {
        if (this.locations.meta.pagination.count > this.locations.meta.pagination.per_page) {
          return true;
        }

        return false;
      },
      connectedLocations() {
        return this.locations.data.filter(l => l.connect);
      },
    },
  };
</script>
<style lang="scss" scoped>
.el-card {
  max-width: none;
  width: auto;
}

.el-table {
  margin-bottom: 1rem;
  max-height: 500px;
  overflow-y: scroll;
}
</style>
