<template>
  <div class="sidebar-inner" style="width: 100%;">
    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">1</span>
      </el-col>
      <el-col :span="22" class="v2__heading">What type of Last Interaction would you like to filter on?</el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2" :span="22">
          <el-radio-group v-model="last_visit_type">
            <el-radio label="visit" border>Last Visit</el-radio>
            <el-radio label="both" border>Last Visit and Purchase</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row>
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-radio-group v-model="time_comparison">
              <el-radio-button label="more">At least</el-radio-button>
              <el-radio-button label="within">Within the last</el-radio-button>
              <el-radio-button label="exactly">Exactly</el-radio-button>
            </el-radio-group>
            <div class="block" style="display: flex; align-items: center;">
              <el-input-number :min="0" v-model="relative_days"></el-input-number>
              &nbsp;
              days<span v-if="time_comparison && time_comparison !== 'within'">&nbsp;ago</span>.
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">2</span>
      </el-col>
      <el-col :span="22" class="v2__heading">Should the Last Interaction be of a specific Plan?</el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2" :span="22">
          <el-radio-group v-model="filter_plan">
            <el-radio :label="false" border>Any Plan</el-radio>
            <el-radio :label="true" border>Specific Plan(s)</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row style="margin-bottom: 2rem;" v-if="filter_plan">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-form>
              <label>Select your Plan(s)</label>
              <el-form-item>
                <el-select v-model="selected_plans" multiple filterable placeholder="Plans" style="width: 100%;">
                  <el-option v-for="plan in plans" :key="plan.name" :value="plan.name" :id="plan.name"></el-option>
                </el-select>
              </el-form-item>

              <label>Plan Type(s)</label>
              <el-form-item>
                <el-select v-model="selected_types" multiple filterable placeholder="Plan Types" style="width: 100%;">
                  <el-option v-for="type in types" :key="type.name" :value="type.name" :id="type.name" style="text-transform: uppercase;"></el-option>
                </el-select>
              </el-form-item>

              <label>Revenue Categorie(s)</label>
              <el-form-item>
                <el-select v-model="selected_revenue_categories" multiple filterable placeholder="Revenue Categories" style="width: 100%;">
                  <el-option v-for="category in categories" :key="category.name" :value="category.name" :id="category.name"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">3</span>
      </el-col>
      <el-col :span="22" class="v2__heading">Should the Last Interaction be of a specific Service?</el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2" :span="22">
          <el-radio-group v-model="filter_service">
            <el-radio :label="false" border>Any Service</el-radio>
            <el-radio :label="true" border>Specific Service(s)</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row style="margin-bottom: 2rem;" v-if="filter_service">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-form>
              <label>Select your Service(s)</label>
              <el-form-item>
                <el-select v-model="selected_services" multiple filterable placeholder="Services" style="width: 100%;">
                  <el-option v-for="service in services" :key="service.name" :value="service.name" :id="service.name"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-form>
      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="commitCondition">Save this condition</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['condition', 'editedRule', 'enrollment', 'goalList'],
    data() {
      return {
        last_visit_type: 'visit',
        time_comparison: null,
        relative_days: null,
        filter_plan: false,
        selected_plans: [],
        plans: [],
        selected_types: [],
        types: [],
        selected_revenue_categories: [],
        categories: [],
        filter_service: false,
        services: [],
        selected_services: [],
      };
    },
    created() {
      this.fetchPlans();
      this.fetchTypes();
      this.fetchCategories();
      this.fetchServiceNames();

      if (this.editedRule) {
        this.last_visit_type = this.editedRule.last_visit_type;
        this.time_comparison = this.editedRule.time_comparison;
        this.relative_days = this.editedRule.relative_days;

        this.selected_plans = this.editedRule.plans;
        this.selected_types = this.editedRule.types;
        this.selected_revenue_categories = this.editedRule.categories;
        this.selected_services = this.editedRule.services;

        if (this.selected_plans.length ||
          this.selected_types.length ||
          this.selected_revenue_categories.length) {
          this.filter_plan = true;
        }

        if (this.selected_services &&
          this.selected_services.length) {
          this.filter_service = true;
        }
      }
    },
    methods: {
      fetchServiceNames() {
        api.getServiceNames().then((response) => {
          this.services = response.service_names;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchPlans() {
        api.getPersonPlans().then((response) => {
          this.plans = response.plans;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchTypes() {
        api.getPlanTypes().then((response) => {
          this.types = response.types;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchCategories() {
        api.getPlanCategories().then((response) => {
          this.categories = response.categories;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          last_visit_type: this.last_visit_type,
          time_comparison: this.time_comparison,
          relative_days: this.relative_days,
          filter_plan: this.filter_plan,
          plans: this.selected_plans,
          types: this.selected_types,
          categories: this.selected_revenue_categories,
          services: this.selected_services,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
 .options {
    padding: 1.25rem;
    background-color: #f5f8fb;
  }
</style>
