<template>
  <el-container class="condition-container">
    <div class="sidebar-inner" style="width: 100%">
      <TimeFrameField
        fieldIndex="0"
        title="When Did They Finish?"
        fieldStep="1"
        :selectedOption.sync="finishedTimeFrameComparison"
        :valueOne.sync="finishedTimeFrameValue"
        :futureLabel="false"
        pastLabel="days ago"
        :hideTimeType="false"
        :goalList="null"
        :hidePast="false"
        :hideFuture="true"
        id="31"
      />
      <BooleanField
        fieldIndex="1"
        title="Which Type Of Credit Package(s)?"
        fieldStep="2"
        yesText="All credit packages"
        noText="Specific types of credit packages"
        :selection.sync="selectionCredit"
        :showLabel="true"
        descLabel="Options include credit package and duration"
        id="33"
      />
      <div v-if="!selectionCredit" class="condition-banner-style">
        Drill Down Options
      </div>
      <MultiSelectField
        v-if="!selectionCredit"
        fieldIndex="2"
        title="Which Credit Package?"
        fieldStep="3"
        anyLabel="Any credit package"
        specificLabel="Specific credit package(s)"
        specificAnyLabel="Any of the following credit packages"
        specificExclusionLabel="Any except the following"
        :selectedOptions.sync="selectedPackages"
        :specificChoice.sync="specificCreditPackages"
        :exclusionChoice.sync="packageExclusionOption"
        :options="creditPackages"
        selectKey="id"
        id="44"
      />
      <CountField
        v-if="!selectionCredit"
        fieldIndex="3"
        title="How Long Did It Take Them To Finish?"
        fieldStep="4"
        :countOne.sync="finishCountOne"
        :countTwo.sync="finishCountTwo"
        :selection.sync="finishSelection"
        countType="Days"
        moreThanText="This includes your selection above. (I.e. “At least 3 visits” equals 3 or more visits)"
        lessThanText="This will be lower than your selection (i.e. “Less than 5” equals 0 through 4)"
        betweenText="This includes both the lower and upper selection (I.e “Between 2 and 4 visits” equals 2, 3, or 4 visits)"
      />
      <MultiSelectField
        v-if="!selectionCredit"
        fieldIndex="4"
        title="Credit Package Purchase Location"
        fieldStep="5"
        anyLabel="Any location in my account"
        specificLabel="Specific location(s) in my account"
        specificAnyLabel="Any of the following locations"
        specificExclusionLabel="Any except the following"
        :selectedOptions.sync="selectedPurchaseLocations"
        :specificChoice.sync="purchaseLocationOption"
        :exclusionChoice.sync="excludedPurchaseLocationOption"
        :options="creditPackagePurchaseLocationOptions"
        selectKey="id"
        id="99"
      />
    </div>
    <div class="save-condition-container" v-if="showSaveBtn">
      <el-button
        class="save-condition-btn"
        type="primary"
        @click="commitCondition"
        >Save</el-button
      >
    </div>
  </el-container>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import TimeFrameField from '@/components/Contacts/ContactLists/Conditions/Fields/TimeFrameField';
  import MultiSelectField from '@/components/Contacts/ContactLists/Conditions/Fields/MultiSelectField';
  import BooleanField from '@/components/Contacts/ContactLists/Conditions/Fields/BooleanField';
  import CountField from '@/components/Contacts/ContactLists/Conditions/Fields/CountField';

  export default {
    props: ['condition', 'editedRule', 'goalList'],
    components: {
      TimeFrameField,
      MultiSelectField,
      BooleanField,
      CountField,
    },
    data() {
      return {
        finishedTimeFrameComparison: null,
        finishedTimeFrameValue: 1,
        finishSelection: '',
        finishCountOne: 1,
        finishCountTwo: 2,
        selectionCredit: true,
        specificCreditPackages: 'any',
        packageExclusionOption: 'any',
        selectedPackages: [],
        creditPackages: [],
        creditPackagePurchaseLocationOptions: [],
        selectedPurchaseLocations: [],
        purchaseLocationOption: 'any',
        excludedPurchaseLocationOption: 'any',
      };
    },
    computed: {
      showSaveBtn() {
        if (!this.finishedTimeFrameComparison) {
          return false;
        }
        if ((this.finishedTimeFrameComparison === 'before' || this.finishedTimeFrameComparison === 'after' || this.finishedTimeFrameComparison === 'between' || this.finishedTimeFrameComparison === 'specificDate') && Number.isInteger(this.finishedTimeFrameValue)) {
          return false;
        }

        if (this.finishedTimeFrameComparison === 'between' && !this.finishedTimeFrameValue.length) {
          return false;
        }
        if (!this.selectionCredit) {
          if (this.specificCreditPackages === 'specific' && !this.selectedPackages.length) {
            return false;
          }

          if (!this.finishSelection) {
            return false;
          }
        }
        if (this.purchaseLocationOption === 'specific' && (!this.selectedPurchaseLocations.length || !this.excludedPurchaseLocationOption)) {
          return false;
        }
        return true;
      },
    },
    created() {
      this.fetchCreditPackages();
      this.fetchLocations();
      if (this.editedRule) {
        this.currentlyOpen = null;
        this.finishedTimeFrameComparison = this.editedRule.finished_time_comparison;
        if (this.editedRule.finished_time_comparison === 'sinceEntering') {
          this.finishedTimeFrameComparison = 'allTime';
        }

        this.finishedTimeFrameValue = this.editedRule.finished_time_value;

        this.selectionCredit = this.editedRule.all_credit_packages;

        this.selectedPackages = this.editedRule.credit_packages;
        this.packageExclusionOption = this.editedRule.credit_package_exclusion;
        this.specificCreditPackages = this.editedRule.credit_package_option;

        this.finishSelection = this.editedRule.finish_comparison;
        this.finishCountOne = this.editedRule.finish_first_bound;
        this.finishCountTwo = this.editedRule.finish_second_bound;

        this.selectedPurchaseLocations = this.editedRule.selected_purchase_locations;
        this.purchaseLocationOption = this.editedRule.selected_purchase_location_option;
        this.excludedPurchaseLocationOption = this.editedRule.selected_purchase_location_exclusion;
        if (!this.editedRule.selected_purchase_location_option) {
          this.purchaseLocationOption = 'any';
        }
        if (!this.editedRule.selected_purchase_locations) {
          this.selectedPurchaseLocations = [];
        }
      }
    },
    methods: {
      async fetchLocations() {
        try {
          const response = await api.getMTLinkedLocations();
          this.creditPackagePurchaseLocationOptions = response.locations;
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      async fetchCreditPackages() {
        try {
          this.creditPackages = await api.MTCreditsWithoutClassPass();
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          finished_time_comparison: this.finishedTimeFrameComparison,
          finished_time_value: this.finishedTimeFrameValue,
          all_credit_packages: this.selectionCredit,
          credit_packages: this.selectedPackages,
          credit_package_exclusion: this.packageExclusionOption,
          credit_package_option: this.specificCreditPackages,
          finish_comparison: this.finishSelection,
          finish_first_bound: this.finishCountOne,
          finish_second_bound: this.finishCountTwo,
          selected_purchase_locations: this.selectedPurchaseLocations,
          selected_purchase_location_option: this.purchaseLocationOption,
          selected_purchase_location_exclusion: this.excludedPurchaseLocationOption,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
@import "src/scss/global/_spacing.scss";
@import "src/scss/global/_new_main.scss";
.sidebar-inner {
  height: 100%;
  position: relative;
  padding-top: 0;
  background-color: #F4F8FA;
  padding-left: 2.625rem;
  padding-right: 2.25rem;
  padding-bottom: 0;
  width: 100%;
}
.condition-container {
  background-color: #F4F8FA;
  height: 100%;
  width: 100%;
}
.card-condtion {
  width: 100%;
  background-color: #FFFFFF;
  border: solid 1px var(--xpl-color-gray-400);
  box-shadow: none;
  border-radius: 0;
  cursor: auto;
}
.card-condtion:hover {
  background-color: #FFFFFF;
  cursor: auto;
}
.field-container:last-of-type{
  padding-bottom: 100px;
}
.save-condition-btn {
  width: 729px;
  margin: 16px 12px 16px 12px;
}
.save-condition-container {
  // box-shadow: 0 -6px 7px 0 rgba(0, 0, 0, 0.05);
  padding: 0;
  margin: 0;
  z-index: 1;
}
.condition-banner-style {
  background-color: #F4F8FA !important;
  margin-top: 13px !important;
  padding-bottom: 0;
}
</style>
