<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Tag Management
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <label v-if="action === 'tag'">Choose the tags you wish to <strong>add</strong> to the selected contacts</label>
                <label v-if="action === 'untag'">Choose the tags you wish to <strong>remove from</strong> the selected contacts</label>
                <Multiselect v-model="selected_tags" :options="tags" placeholder="Search tags" track-by="id" label="name" :searchable="true" :show-labels="false" :taggable="true" tag-placeholder="Press enter to create this tag" @tag="createTag" :multiple="true" />
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="button" class="xpl-button xpl-button--primary" @click.stop="save" :disabled="!selected_tags.length">Save</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['action', 'contacts', 'allContacts', 'contactListId'],
    components: {
      Multiselect,
    },
    data() {
      return {
        selected_tags: [],
        tags: [],
      };
    },
    created() {
      api.getCustomTags().then((response) => {
        this.tags = response.tags;
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    methods: {
      save(event) {
        const target = event.target || event.srcElement;
        target.disabled = true;
        target.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';

        const payload = {
          action: this.action,
          contact_list_id: this.contactListId,
          all_contacts: this.allContacts,
          contact_ids: this.contacts.map(c => c.id),
          tag_ids: this.selected_tags.map(t => t.id),
        };

        api.addContactCustomTagMass(payload).then((response) => {
          this.$toasted.success(response.message);
          target.disabled = false;
          this.closeModal();
        }).catch((err) => {
          target.disabled = false;
          target.innerHTML = 'Save';
          this.$toasted.error(err.message);
        });
      },
      closeModal() {
        EventBus.$emit('mass_tag_modal.close');
      },
      createTag(tag) {
        if (this.action === 'untag') {
          this.$toasted.error('A newly created tag cannot be removed from contacts.');
          return;
        }

        const payload = {
          type: 'brandbot',
          name: tag,
          id: Math.floor((Math.random() * 10000000)),
        };

        api.addCustomTag(payload).then((response) => {
          this.selected_tags.push(response.tag);
          this.tags.push(response.tag);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal .modal-content .modal-body {
    overflow-y: visible;
  }
</style>
