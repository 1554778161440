<template>
  <el-card>
    <div slot="header" class="header">
      <span>Build your multi-selection based question</span>
      <el-button class="trash" type="text" @click.stop="cancel">
        <span class="material-icons">delete</span>
      </el-button>
    </div>
    <el-form @submit.native.prevent>
      <el-form-item>
        <label>What is the question you want to ask?</label>
        <el-input placeholder="Question" v-model="label" />
      </el-form-item>

      <div>
        <label>What options are available?</label>
      </div>

      <el-form-item v-for="(opt, index) in options" :key="index">
        <div class="option">
          <el-input placeholder="Option" v-model="opt.label" />
          <div class="button" @click.stop="removeField(index)">
            <span class="material-icons">remove_circle_outline</span>Remove
          </div>
        </div>
      </el-form-item>

      <div>
        <el-button type="secondary" class="xpl-button xpl-button--secondary"  size="small" @click.stop="addMore">Add another option</el-button>
      </div>
    </el-form>
    <div class="footer">
      <el-switch v-model="required" active-text="Required" />
      <el-button type="primary" class="xpl-button xpl-button--primary"size="small" @click.stop="commit" :disabled="!label || !options.length">Save</el-button>
    </div>
  </el-card>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    props: ['field'],
    data() {
      return {
        required: this.field ? this.field.required : false,
        label: this.field ? this.field.label : '',
        options: this.field ? this.field.options : [{
          label: 'Option 1',
        }],
      };
    },
    methods: {
      cancel() {
        EventBus.$emit('field.cancel');
      },
      commit() {
        if (!this.label || !this.options.length) {
          return;
        }

        const payload = {
          type: 'multi',
          label: this.label,
          options: this.options,
          required: this.required,
        };

        if (this.field) {
          payload.fieldIndex = this.field.fieldIndex;
        }

        EventBus.$emit('field.commit', payload);
      },
      addMore() {
        const option = {
          label: `Option ${this.options.length + 1}`,
        };
        this.options.push(option);
      },
      removeField(idx) {
        this.$delete(this.options, idx);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .header {
    font-weight: 700;
    color: #00152a;
  }

  .material-icons {
    color: #afbcca;
  }

  .trash {
    float: right;
    padding: .25rem 0;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--xpl-color-gray-400);
    padding-top: 1rem;
    margin-top: 1rem;
  }

  .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .option .el-input {
    width: 70%;
  }

  .option .button {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: .875rem;
    color: #617080;
    transition: all 150ms ease-in-out;
  }

  .option .button:hover,
  .option .button:hover .material-icons {
    color: #55bef2;
  }

  .option .material-icons {
    font-size: 1.25rem;
    margin-right: .5rem;
    transition: all 150ms ease-in-out;
  }
</style>
