<template>
  <div class="sidebar-inner" style="width: 100%;">
    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">1</span>
      </el-col>
      <el-col :span="22" class="v2__heading">When did the contacts create their profile?</el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2" :span="22">
          <el-radio-group v-model="timeframe">
            <el-radio label="all" border>All Time</el-radio>
            <el-radio label="absolute" border>Around static date(s)</el-radio>
            <el-radio label="relative" border>A relative time frame</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row v-if="timeframe === 'absolute'">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-radio-group v-model="time_comparison">
              <el-radio-button label="before">Before</el-radio-button>
              <el-radio-button label="between">Between</el-radio-button>
              <el-radio-button label="after">After</el-radio-button>
            </el-radio-group>

            <div class="block" v-if="time_comparison && time_comparison !== 'between'">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="dates"
                type="date"
                placeholder="Pick a date">
              </el-date-picker>
            </div>

            <div class="block" v-if="time_comparison && time_comparison === 'between'">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="dates"
                type="daterange"
                range-separator="-"
                start-placeholder="Start"
                end-placeholder="End">
              </el-date-picker>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row v-if="timeframe === 'relative'">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-radio-group v-model="time_comparison">
              <el-radio-button label="more">At least</el-radio-button>
              <el-radio-button label="within">Within the last</el-radio-button>
              <el-radio-button label="exactly">Exactly</el-radio-button>
            </el-radio-group>
            <div class="block" style="display: flex; align-items: center;">
              <el-input-number :min="0" v-model="relative_days"></el-input-number>
              &nbsp;
              days<span v-if="time_comparison && time_comparison !== 'within'">&nbsp;ago</span>.
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">2</span>
      </el-col>
      <el-col :span="22" class="v2__heading">What source type should the contacts have?</el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2" :span="22">
          <el-radio-group v-model="type_filtering">
            <el-radio label="any" border>Any</el-radio>
            <el-radio label="specific" border>Specific source type(s)</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row v-if="type_filtering === 'specific'">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-form>
              <label>Source Type(s)</label>
              <el-form-item>
                <el-select v-model="source_types" multiple filterable placeholder="Source Type(s)" style="width: 100%;">
                    <el-option v-for="source in source_names"
                        :key="source.name"
                        :label="source.name"
                        :value="source.name">
                    </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-form>
      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="commitCondition">Save this condition</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['condition', 'editedRule', 'enrollment', 'goalList'],
    data() {
      return {
        timeframe: 'all',
        time_comparison: null,
        dates: null,
        relative_days: null,
        source_types: null,
        type_filtering: false,
        source_names: [],
      };
    },
    created() {
      this.fetchSourceTypes();
      if (this.editedRule) {
        this.timeframe = this.editedRule.timeframe;
        this.time_comparison = this.editedRule.time_comparison;
        this.relative_days = this.editedRule.relative_days;

        this.type_filtering = this.editedRule.type_filtering;
        this.source_types = this.editedRule.source_types;

        if (this.editedRule.end_date) {
          this.dates = [this.editedRule.start_date, this.editedRule.end_date];
        } else {
          this.dates = this.editedRule.start_date;
        }
      }
    },
    methods: {
      fetchSourceTypes() {
        api.getContactSourceNames().then((response) => {
          this.source_names = response.source_names;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          timeframe: this.timeframe,
          time_comparison: this.time_comparison,
          start_date: Array.isArray(this.dates) ? this.dates[0] : this.dates,
          end_date: Array.isArray(this.dates) ? this.dates[1] : null,
          relative_days: this.relative_days,
          source_types: this.source_types,
          type_filtering: this.type_filtering,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
 .options {
    padding: 1.25rem;
    background-color: #f5f8fb;
  }
</style>
