<template>
  <div>
    <span v-if="rule.condition.uuid === '033c6574-fc3d-4ee8-9970-889790c432d6'">Any contact who uses (“activates”) <strong>any of the following pricing options for the first time:</strong></span>
    <span v-if="rule.condition.uuid === '5c48a13c-ac7f-4873-9d23-cba7b05f206e'">Any contact when they purchase <strong>any of the following pricing options:</strong></span>
    <span v-if="rule.condition.uuid === 'ff56be42-b88b-4abe-82b3-c47e5d485660'">Any contact when they purchase <strong>any of the following products:</strong></span>
    <span v-if="rule.condition.uuid === 'af62480f-6da6-4519-9d24-de4996a30d98'">Any contact after their first visit at your business with
      <span v-if="!rule.pricing_options.length"><strong>any pricing options.</strong></span>
      <span v-if="rule.pricing_options.length"><strong>the following pricing options:</strong></span>
    </span>
    <span v-if="rule.condition.uuid === '919d821d-4f0c-4993-b916-f02607fd901f'">Any contact after their first visit at your business with
      <span v-if="!rule.plans.length"><strong>any plans.</strong></span>
      <span v-if="rule.plans.length"><strong>the following plans:</strong></span>
    </span>
    <div class="tags" v-if="rule.hasOwnProperty('pricing_options') && rule.pricing_options.length">
      <div class="tag" v-for="option in rule.pricing_options">
        {{ option.name }}
      </div>
    </div>
    <div class="tags" v-if="rule.hasOwnProperty('products') && rule.products.length">
      <div class="tag" v-for="option in rule.products">
        {{ option.name }}
      </div>
    </div>

    <span v-if="rule.condition.uuid === 'd323c020-0569-4f1e-ab1d-f56672d66610'">Any contact when they are assigned <strong>any of the following membership types</strong>:</span>
    <span v-if="rule.condition.uuid === '36fd8c92-65ea-4395-8429-c443afad1eae'">Any contact when they start <strong>any of the following plans</strong>:</span>
    <span v-if="rule.condition.uuid === '27fb545f-fc3f-4cd0-9b4c-6de1983a3a8b'">Any contact when they joined <strong>any of the following membership types</strong>:</span>
    <span v-if="rule.condition.uuid === '2d5f2b5c-1620-451a-96fb-0f96bab671e0'">Any contact when they joined <strong>any of the following plans</strong>:</span>
    <div class="tags" v-if="rule.hasOwnProperty('membership_type')">
      <div class="tag" v-for="membership in rule.membership_type">
        {{ membership.type }}
      </div>
    </div>
    <div class="tags" v-if="rule.hasOwnProperty('plans')">
      <div class="tag" v-for="plan in rule.plans">
        {{ plan }}
      </div>
    </div>

    <span v-if="rule.condition.uuid === 'd2b29794-384f-4e75-9ac8-5fe1b4d9c92f'">Any contact if they have not interacted with your business in {{ '' + rule.relative_days + '' }} days.</span>
    <span v-if="rule.condition.uuid === '10f161c2-3998-4922-a720-31bfba7744ff'">Any contact if they have not interacted with your business in {{ '' + rule.relative_days + '' }} days.</span>

    <span v-if="rule.condition.uuid === '8cfb3bd2-1742-4be3-9ff6-51a9a42241d8'">Any contact when they visit {{ '' +  rule.visit_number_start + '' }} times ever.</span>
    <span v-if="rule.condition.uuid === '1e6a8fb2-8205-41fa-87a8-a46a0c2327dd'">Any contact when they visit {{ '' +  rule.visit_number_start + '' }} times ever.</span>

    <span v-if="rule.condition.uuid === '202eac6b-5e86-491a-89f6-b306bd101800'">Any contact who creates a new profile in MINDBODY.</span>
    <span v-if="rule.condition.uuid === '737adbd7-24b9-417f-a079-d245cd96a33c'">Any contact who creates a new profile in Pike13.</span>
  </div>
</template>
<script>
  export default {
    props: ['rule'],
  };
</script>
<style lang="scss" scoped>
  .tags {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .tags .tag {
    flex-grow: 0;
    flex-shrink: 0;
    background-color: var(--xpl-color-gray-400);
    color: #2E4457;
    border-radius: 6px;
    position: relative;
    display: inline-block;
    padding: .5rem .75rem;
    margin-right: .5rem;
    line-height: 1;
    margin-bottom: .5rem;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    font-size: .75rem;
    font-weight: 700;
    text-align: center;
    opacity: .8;
  }

  .tags .tag:last-child {
    margin-right: 0;
  }
</style>
