<template>
  <div class="git-branch-display" v-if="!isProd">
    <div><i>{{ env }} </i><b>{{ gitBranch }}</b><i> {{ gitDate }}</i><i> {{ apiUrl }}</i></div>
  </div>
</template>

<script>
export default {
  name: 'GitBranch',
  data() {
    return {
      isProd: process.env.NODE_ENV === 'production',
      env: process.env.NODE_ENV,
      gitBranch: process.env.VUE_APP_GIT_BRANCH,
      gitDate: process.env.VUE_APP_GIT_COMMIT_DATE,
      apiUrl: process.env.VUE_APP_API_URL
    };
  }
};
</script>

<style lang="scss" scoped>
.git-branch-display {
  position: fixed;
  top: 0;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  z-index: 100000;

  > div {
    width: 20px;
    overflow: hidden;
    font-size: 9px;
    padding: 2px 3px;
    border-radius: 0 0 4px 4px;
    background: rgba(250, 0, 0, 0.15);
    color: transparent;
    transition: 0.2s width ease;

    b {
      font-size: 9px;
    }

    i {
      text-decoration: none;
      font-style: normal;
      display: block;
      height: 0;
      overflow: hidden;
      transition: 0.2s height ease;
      padding: 0px 4px;
    }

    &:hover {
      width: 200px;
      color: #666;

      i {
        height: 12px
      }

      background: rgb(254, 217, 217);
    }
  }
}
</style>
