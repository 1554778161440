<template>
  <div>
    <div class="nav-subheader">
      <div class="nav-inner">
        <div>Your Calendar</div>
        <div class="nav-pills">
          <div :class="['pill', filters.emails ? 'active' : '']" @click="toggleFilter('emails')">Emails</div>
          <div :class="['pill', filters.tasks ? 'active' : '']" @click="toggleFilter('tasks')">To-Do</div>
        </div>
      </div>
      <div class="dropdown">
        <button type="button" class="xpl-button xpl-button--primary" @click="create_dropdown = !create_dropdown"
                v-on-clickaway="away">Create New
        </button>
        <div :class="{'dropdown-menu': true, 'dropdown-menu-right': true, 'show': create_dropdown}">
          <a class="dropdown-item" href="#" @click.stop="show_scheduled_email = true; create_dropdown = false;">
            <span class="material-icons">email</span> Scheduled Email
          </a>
          <a class="dropdown-item" href="#"
             @click.stop="show_tasks_sidebar = true; scheduledTaskId = null; create_dropdown = false;">
            <span class="material-icons">assignment</span> Task
          </a>
          <a class="dropdown-item" href="#"
             @click.stop="show_phone_sidebar = true; scheduledTaskId = null; create_dropdown = false;">
            <span class="material-icons">call</span> Phone Call Reminder
          </a>
          <a v-if="$store.state.user.current_account && $store.state.user.current_account.franchise"
             class="dropdown-item" href="#"
             @click.stop="show_scheduled_email = true; corporate = true; create_dropdown = false;">
            <span class="material-icons">exit_to_app</span> Pushed Corporate Email
          </a>
        </div>
      </div>
    </div>
    <div class="calendar-container">
      <div class="calendar-picker">
        <div class="filters">
          <div class="filters-inner">
            <h6 style="display: flex;">
              Filter by Assignee <a href="#" class="text-muted" style="margin-left: auto;"
                                    @click.stop="filterAssigned(null)" v-if="filter_assigned">Clear</a>
            </h6>
            <div class="assignee">
              <Multiselect v-model="filter_assigned" :options="members" placeholder="Select" track-by="uuid"
                           label="email" group-values="group" group-label="name" :searchable="true" :show-labels="false"
                           @input="assigneeSelected"/>
              <div class="text-muted small">

              </div>

              <div class="dropdown" v-if="false">
                <div class="none" v-if="filter_assigned === null"
                     @click.stop="assigned_dropdown = !assigned_dropdown; status_dropdown = false;">
                  <div class="square"></div>
                  No Assignee
                </div>
                <div class="member" v-if="filter_assigned !== null"
                     @click.stop="assigned_dropdown = !assigned_dropdown; status_dropdown = false;">
                  <avatar
                    :username="filter_assigned.first_name ? filter_assigned.first_name + ' ' + filter_assigned.last_name : filter_assigned.email"
                    color="#fff" :rounded="true" :size="24"
                    :customStyle="{ 'flex-shrink': '0', 'flex-grow': '0', 'margin-right': '.5rem' }"></avatar>
                  {{
                    filter_assigned.first_name ? filter_assigned.first_name + ' ' + filter_assigned.last_name : filter_assigned.email
                  }}
                  <span class="material-icons" @click.stop="filterAssigned(null)">remove_circle</span>
                </div>
                <div :class="['dropdown-menu', assigned_dropdown ? 'show' : '']">
                  <div class="dropdown-item search">
                    <input type="text" v-model="assignee_query" class="form-control" placeholder="Search..."/>
                  </div>
                  <a href="#" @click.prevent="filterAssigned($store.state.user)" class="dropdown-item">Myself</a>
                  <a href="#" @click.prevent="filterAssigned(member)" class="dropdown-item"
                     v-for="member in filteredAssignee" v-if="member.id !== $store.state.user.id">{{
                      member.first_name ? member.first_name + ' ' + member.last_name : member.email
                    }}</a>
                </div>
              </div>
            </div>

            <h6>Filter by Status</h6>
            <div class="statuses" v-if="filters.emails">
              <div class="status" @click.prevent="filterStatuses('not_started')">
                <span class="icon icon-checkbox-unchecked2" v-if="filter_statuses.indexOf('not_started') === -1"></span>
                <span class="icon icon-checkbox-checked2" v-if="filter_statuses.indexOf('not_started') > -1"></span>
                Not Started
              </div>
              <div class="status" @click.prevent="filterStatuses('draft')">
                <span class="icon icon-checkbox-unchecked2" v-if="filter_statuses.indexOf('draft') === -1"></span>
                <span class="icon icon-checkbox-checked2" v-if="filter_statuses.indexOf('draft') > -1"></span>
                In Progress
              </div>
              <div class="status" @click.prevent="filterStatuses('scheduled')">
                <span class="icon icon-checkbox-unchecked2" v-if="filter_statuses.indexOf('scheduled') === -1"></span>
                <span class="icon icon-checkbox-checked2" v-if="filter_statuses.indexOf('scheduled') > -1"></span>
                Scheduled
              </div>
              <div class="status" @click.prevent="filterStatuses('sent')">
                <span class="icon icon-checkbox-unchecked2" v-if="filter_statuses.indexOf('sent') === -1"></span>
                <span class="icon icon-checkbox-checked2" v-if="filter_statuses.indexOf('sent') > -1"></span>
                Sent
              </div>
              <div class="status" @click.prevent="filterStatuses('overdue')">
                <span class="icon icon-checkbox-unchecked2" v-if="filter_statuses.indexOf('overdue') === -1"></span>
                <span class="icon icon-checkbox-checked2" v-if="filter_statuses.indexOf('overdue') > -1"></span>
                Overdue
              </div>
            </div>

            <div class="statuses" v-if="filters.tasks">
              <div class="status" @click.prevent="filterStatuses('incomplete')">
                <span class="icon icon-checkbox-unchecked2" v-if="filter_statuses.indexOf('incomplete') === -1"></span>
                <span class="icon icon-checkbox-checked2" v-if="filter_statuses.indexOf('incomplete') > -1"></span>
                Incomplete
              </div>
              <div class="status" @click.prevent="filterStatuses('complete')">
                <span class="icon icon-checkbox-unchecked2" v-if="filter_statuses.indexOf('complete') === -1"></span>
                <span class="icon icon-checkbox-checked2" v-if="filter_statuses.indexOf('complete') > -1"></span>
                Complete
              </div>
              <div class="status" @click.prevent="filterStatuses('overdue')">
                <span class="icon icon-checkbox-unchecked2" v-if="filter_statuses.indexOf('overdue') === -1"></span>
                <span class="icon icon-checkbox-checked2" v-if="filter_statuses.indexOf('overdue') > -1"></span>
                Overdue
              </div>
            </div>

            <h6 v-if="filters.tasks">Filter by Campaign</h6>
            <div class="assignee" v-if="filters.tasks">
              <div class="dropdown">
                <div class="none" v-if="filter_campaign === null" @click.stop="campaign_dropdown = !campaign_dropdown">
                  <div class="square"></div>
                  No Campaign
                </div>
                <div class="member" v-if="filter_campaign !== null"
                     @click.stop="campaign_dropdown = !campaign_dropdown">
                  {{ filter_campaign.name }}
                  <span class="material-icons" @click.stop="filterCampaign(null)">remove_circle</span>
                </div>
                <div :class="['dropdown-menu', campaign_dropdown ? 'show' : '']">
                  <div class="dropdown-item search">
                    <input type="text" v-model="campaign_query" class="form-control" placeholder="Search..."/>
                  </div>
                  <a href="#" @click.prevent="filterCampaign(campaign)" class="dropdown-item campaign"
                     v-for="campaign in filteredCampaigns">{{ campaign.name }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="calendar-inner">
        <div class="filters">
          <div class="search-bar">
            <span class="material-icons">search</span>
            <input type="search" name="query" v-model="query" class="form-control"
                   placeholder="Start typing to search..." autocomplete="off"/>
            <button type="button" class="xpl-button xpl-button--secondary right-radius-only" @click.stop="toggleOrder">
              {{ oldest_first ? 'Most recent' : 'Oldest' }} first
            </button>
          </div>
        </div>

        <div class="calendar-item" v-for="item in filteredItems" :key="item.id">
          <div class="card hoverable" @click.stop="showCalendarItem(item)">
            <div :class="['card-body', item.type === 'scheduled_email' ? 'email' : 'task']">
              <div class="status" v-if="item.type === 'scheduled_email'">
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/in-progress.svg"
                     v-if="!item.scheduled && !item.overdue"/>
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/complete.svg"
                     v-if="item.scheduled && item.sent"/>
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/scheduled.svg"
                     v-if="item.scheduled && !item.sent"/>
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/alert.svg"
                     v-if="item.incomplete && !item.scheduled && !item.sent && item.overdue"/>
              </div>
              <div class="status" v-if="item.type === 'scheduled_task'">
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/empty.svg"
                     v-if="!item.complete && !item.overdue"/>
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/complete.svg" v-if="item.complete"/>
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/alert.svg"
                     v-if="!item.complete && item.overdue"/>
              </div>
              <div class="details-container">
                <div class="name">
                  <span class="material-icons" v-if="item.type === 'scheduled_email'">email</span>
                  <span class="material-icons"
                        v-if="item.type === 'scheduled_task' && item.phone_call === 0">assignment</span>
                  <span class="material-icons"
                        v-if="item.type === 'scheduled_task' && item.phone_call === 1">phone</span>
                  <h6>{{ item.name }}</h6>
                </div>
                <div class="details" v-if="item.type === 'scheduled_email'">
                  <span class="text-muted" v-if="item.sent">Sent &middot; {{ item.scheduled_at | humanDateTime }}</span>
                  <span class="text-muted" v-if="item.scheduled && !item.sent">Scheduled &middot; {{
                      item.scheduled_at | humanDateTime
                    }}</span>
                  <span class="text-muted" v-if="!item.scheduled && item.email_id && !item.overdue">In Progress &middot; {{
                      item.scheduled_at | humanDateTime
                    }}</span>
                  <span class="text-muted" v-if="!item.scheduled && item.email_id && item.overdue">Overdue &middot; {{
                      item.scheduled_at | humanDateTime
                    }}</span>
                  <span class="text-muted"
                        v-if="!item.email_id">Not Started &middot; {{ item.scheduled_at | humanDateTime }}</span>
                </div>
              </div>
              <ScheduledEmailMetrics
                v-if="item.type === 'scheduled_email' && item.scheduled && item.sent && item.email_uuid"
                :typeUuid="item.uuid"/>
              <div class="associated" v-if="item.type === 'scheduled_task'">
                <span v-if="item.associated_contact_first_name">{{
                    item.associated_contact_first_name
                  }} {{ item.associated_contact_last_name }}</span>
                <span v-if="!item.associated_contact_first_name">{{ item.associated_contact_email }}</span>
              </div>
              <div class="due-date" v-if="item.type === 'scheduled_task'">
                {{ item.scheduled_at_day | humanDate(false) }}
              </div>
              <div class="assignee">
                <avatar
                  :username="item.assigned_user_first_name ? ' ' + item.assigned_user_first_name + ' ' + item.assigned_user_last_name : item.assigned_user_email"
                  color="#fff" :rounded="true" :size="24" v-if="item.assigned_user_id"></avatar>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center load-more" v-if="filteredItems.length">
          <a href="#" class="xpl-button xpl-button--secondary" @click.prevent="loadMore">Load more</a>
        </div>
      </div>
    </div>
    <ScheduledEmail v-if="show_scheduled_email" :corporate="corporate"/>
    <TasksSidebar v-if="show_tasks_sidebar" :scheduledTaskId="scheduledTaskId"/>
    <PhoneCallsSidebar v-if="show_phone_sidebar" :scheduledTaskId="scheduledTaskId"/>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import * as api from '@/api';
import EventBus from '@/event-bus';
import { directive as onClickaway } from 'vue-clickaway';
import Avatar from 'vue-avatar';
import _ from 'lodash';
import Multiselect from 'vue-multiselect';
import ScheduledEmail from './ScheduledEmail';
import TasksSidebar from '../Tasks/TasksSidebar';
import PhoneCallsSidebar from '../Tasks/PhoneCallsSidebar';
import ScheduledEmailMetrics from './ScheduledEmailMetrics';

export default {
  directives: {
    onClickaway,
  },
  components: {
    Multiselect,
    TasksSidebar,
    PhoneCallsSidebar,
    Avatar,
    ScheduledEmail,
    ScheduledEmailMetrics,
  },
  data() {
    return {
      assignee: null,
      corporate: false,
      show_tasks_sidebar: false,
      show_phone_sidebar: false,
      scheduledTaskId: null,
      create_dropdown: false,
      status_dropdown: false,
      assigned_dropdown: false,
      show_scheduled_email: false,
      show_filters: false,
      filters: {
        emails: this.$route.query && this.$route.query.type && this.$route.query.type === 'emails',
        tasks: this.$route.query && this.$route.query.type && this.$route.query.type === 'tasks',
      },
      query: '',
      campaign_query: '',
      assignee_query: '',
      items: [],
      offset: 0,
      members: [],
      filter_assigned: null,
      filter_statuses: [],
      campaigns: [],
      filter_campaign: null,
      campaign_dropdown: false,
      oldest_first: true,
    };
  },
  mounted() {
    // eslint-disable-next-line
    document.title = `Calendar List View | ${this.$store.getters.brandName}`;

    EventBus.$on('calendar.task_sidebar.close', (refresh) => {
      this.show_tasks_sidebar = false;
      this.show_phone_sidebar = false;
      if (refresh) {
        this.fetchItemsFiltered(this.filters.emails ? 'emails' : 'tasks', true);
      }
    });

    EventBus.$on('scheduled_modal.close', () => {
      this.show_scheduled_email = false;
    });
  },
  created() {
    const type = this.$route.query.type || 'emails';
    this.filters[type] = true;
    this.fetchItemsFiltered(type);
    this.fetchTeamMembers();
    this.fetchCampaigns();
  },
  computed: {
    filteredCampaigns() {
      if (this.campaign_query.length) {
        const query = this.campaign_query.toLowerCase();
        return this.campaigns.filter(c => c.name.toLowerCase()
          .indexOf(query) > -1);
      }

      return this.campaigns;
    },
    filteredAssignee() {
      if (this.assignee_query.length) {
        const query = this.assignee_query.toLowerCase();
        return _.pickBy(this.members, (val) => {
          const fullname = `${val.first_name} ${val.last_name}`.toLowerCase();
          return fullname.indexOf(query) > -1;
        });
      }

      return this.members;
    },
    filteredItems() {
      if (this.query.length) {
        const query = this.query.toLowerCase();
        return this.items.filter((i) => {
          const contactFName = i.associated_contact_first_name ? i.associated_contact_first_name : '';
          const contactLName = i.associated_contact_last_name ? i.associated_contact_last_name : '';
          const contactEmail = i.associated_contact_email ? i.associated_contact_email : '';
          return i.name.toLowerCase()
              .indexOf(query) > -1 ||
            contactFName.toLowerCase()
              .indexOf(query) > -1 ||
            contactLName.toLowerCase()
              .indexOf(query) > -1 ||
            contactEmail.toLowerCase()
              .indexOf(query) > -1;
        });
      }

      return this.items;
    },
  },
  beforeDestroy() {
    // eslint-disable-next-line
    document.title = this.$store.getters.brandName;

    EventBus.$off('calendar.task_sidebar.close');
    EventBus.$off('scheduled_modal.close');
  },
  methods: {
    away() {

    },
    toggleOrder() {
      this.oldest_first = !this.oldest_first;
      this.fetchItemsFiltered(null, false, this.oldest_first ? 'desc' : 'asc');
    },
    assigneeSelected(selectedOption) {
      // Means this is a user
      if (Object.prototype.hasOwnProperty.call(selectedOption, 'id')) {
        this.filter_assigned = {
          type: 'user',
          id: selectedOption.id,
          first_name: selectedOption.first_name,
          last_name: selectedOption.last_name,
          email: selectedOption.email,
        };
      } else {
        this.filter_assigned = {
          type: 'group',
          uuid: selectedOption.uuid,
          name: selectedOption.email,
          email: selectedOption.email,
        };
      }

      const activeTab = this.filters.emails ? 'emails' : 'tasks';
      this.fetchItemsFiltered(activeTab);

      return false;
    },
    filterAssigned(member) {
      this.filter_assigned = member;
      this.assigned_dropdown = false;

      const activeTab = this.filters.emails ? 'emails' : 'tasks';
      this.fetchItemsFiltered(activeTab);
    },
    filterStatuses(status) {
      if (this.filters.tasks) {
        if (this.filter_statuses.indexOf(status) === -1) {
          this.filter_statuses.push(status);
        } else {
          this.filter_statuses.splice(this.filter_statuses.indexOf(status), 1);
        }
      } else {
        // eslint-disable-next-line
        if (this.filter_statuses.indexOf(status) === -1) {
          this.filter_statuses.push(status);
        } else {
          this.filter_statuses.splice(this.filter_statuses.indexOf(status), 1);
        }
      }

      const activeTab = this.filters.emails ? 'emails' : 'tasks';
      this.fetchItemsFiltered(activeTab);
    },
    filterCampaign(campaign) {
      this.filter_campaign = campaign;
      this.campaign_dropdown = false;

      const activeTab = this.filters.emails ? 'emails' : 'tasks';
      this.fetchItemsFiltered(activeTab);
    },
    fetchTeamMembers() {
      api.getTeamMembers()
        .then((response) => {
          this.members = response.multi;
        })
        .catch((err) => {
          this.$toasted.error(err.message);
        });
    },
    fetchCampaigns() {
      api.getCampaignsSmall()
        .then((response) => {
          this.campaigns = response.campaigns;
        })
        .catch((err) => {
          this.$toasted.error(err.message);
        });
    },
    showCalendarItem(item) {
      if (item.type === 'scheduled_email') {
        this.$router.push(`/scheduled-emails/${item.uuid}`);
      }

      if (item.type === 'scheduled_task') {
        if (item.phone_call) {
          this.show_phone_sidebar = true;
        } else {
          this.show_tasks_sidebar = true;
        }
        this.scheduledTaskId = item.id;
      }
    },
    toggleFilter(filter) {
      this.filters.emails = false;
      this.filters.tasks = false;
      this.filters[filter] = true;
      this.filter_assigned = null;
      this.filter_statuses = [];
      this.filter_campaign = null;
      this.fetchItemsFiltered(filter);
      this.$router.push({ name: 'calendar list', query: { type: filter } });
    },
    fetchItemsFiltered(filter, closed = false) {
      // Let's see if filtered
      let assignee = '';
      if (this.filter_assigned) {
        assignee = JSON.stringify(this.filter_assigned);
      }

      let statuses = '';
      if (this.filter_statuses.length) {
        statuses = this.filter_statuses;
      }

      let campaign = '';
      if (this.filter_campaign) {
        campaign = this.filter_campaign.id;
      }

      if (!filter) {
        filter = this.filters.emails ? 'emails' : 'tasks';
      }

      api.getCalendarItems('', '', filter, this.offset, assignee, statuses, campaign, this.oldest_first ? 'desc' : 'asc')
        .then((response) => {
          if (this.offset && !closed) {
            this.items = this.items.concat(response.items);
          } else {
            this.items = response.items;
          }
        })
        .catch((err) => {
          this.$toasted.error(err.message);
        });
    },
    loadMore() {
      this.offset += 25;
      this.fetchItemsFiltered();
    },
  },
};
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>
.nav-subheader {
  justify-content: space-between;
  position: fixed;
  z-index: 100;
}

.nav-inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dropdown-item {
  display: flex;
  align-items: center;
  transition: none;
}

/*XPLOR*/
/*.dropdown-item:hover {*/
/*  background-color: white;*/
/*  color: white;*/
/*  background: linear-gradient(45deg, #3ab8ff 0%, #00cfff 100%);*/
/*}*/

.dropdown-item.search:hover {
  background: white;
  cursor: initial;
}

.dropdown-item .material-icons {
  color: #cdd7e2;
  font-size: 1.5rem;
  margin-right: 1rem;
}

.dropdown-item:hover .material-icons {
  color: white;
}

.calendar-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  padding-top: 4rem;
}

.calendar-picker {
  height: 100vh;
  background-color: #fff;
  border-right: 1px solid rgb(229, 231, 235);
  width: 17rem;
  min-width: 17rem;
  max-width: 17rem;
  position: fixed;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 25rem;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 3;
}

.calendar-inner {
  width: 100%;
  padding-top: 1.5rem;
  padding-left: 18.5rem;
  padding-right: 1.5rem;
}

.calendar-picker .title {
  margin-bottom: .5rem;
  font-size: .625rem;
  font-weight: 800;
  line-height: 2.0;
  color: rgb(97, 112, 128);
  text-transform: uppercase;
}

.types {
  margin-top: 2rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.types a {
  display: block;
  border-radius: 6px;
  font-weight: 700;
  color: #afbcca;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  transition: all 150ms ease-in-out;
}

.types a:hover,
.types a.active {
  text-decoration: none;
  background-color: var(--xpl-color-gray-400);
  color: #1d364b;
}

.calendar-item .card {
  cursor: pointer;
  margin-bottom: .35rem;
}

.calendar-item .card.empty {
  cursor: initial;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #edf2f4;
  border: solid 1px var(--xpl-color-gray-400);
  box-shadow: none;
  font-size: .75rem;
  line-height: 1.25rem;
  color: #617080;
}

.calendar-item .card .card-body {
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.calendar-item .card .card-body.task {
  padding-top: .5rem;
  padding-bottom: .5rem;
  align-items: center;
}

.calendar-item .card .card-body .material-icons {
  color: rgb(217, 231, 240);
  font-size: 1rem;
  margin-right: .75rem;
  margin-left: .875rem;
}

.calendar-item .card .card-body h6 {
  margin-bottom: 0;
}

.calendar-item .card .card-body .material-icons {
  font-size: 1.25rem;
  margin-right: 1rem;
}

.calendar-item .card .card-body .status,
.calendar-item .card .card-body .status img {
  width: 1.5rem;
  height: 1.5rem;
  display: block;
}

.calendar-item .card .card-body .associated {
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 10rem;
  width: 10rem;
  text-align: left;
  margin-left: auto;
  font-size: .875rem;
  color: rgb(98, 98, 98);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.calendar-item .card .card-body .due-date {
  flex-shrink: 0;
  flex-grow: 0;
  font-size: .875rem;
  color: rgb(98, 98, 98);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.calendar-item .card .card-body .assignee {
  width: 1.5rem;
  margin-left: auto;
}

.calendar-item .card .card-body .associated + .assignee,
.calendar-item .card .card-body .due-date + .assignee {
  margin-left: 5rem;
}

.calendar-item .card .card-body .details-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex-basis: 25rem;
}

.calendar-item .card .card-body .details-container .name {
  display: flex;
}

.calendar-item .card .card-body .details-container .name .material-icons,
.calendar-item .card .card-body .details-container .name h6 {
  line-height: 1rem;
}

.calendar-item .card .card-body .details-container .name h6 {
  font-weight: 700;
  color: rgb(76, 79, 82);
}

.calendar-item .card .card-body .details-container .details {
  margin-top: .25rem;
  margin-left: .875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgb(132, 143, 153);
  font-size: .75rem;
}

.calendar-item .card .card-body .details .material-icons {
  margin-right: .5rem;
  font-size: .75rem;
}

.load-more {
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}

.search-bar {
  display: flex;
  margin: 0;
  margin-bottom: 1.5rem;
}

.search-bar input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  max-height: 40px;
  background: white;
}

.search-bar .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #E9F1F6;
  border-left: none;
  background-color: rgba(255, 255, 255, 0.5);
  color: #495057;
}

.right-radius-only {
  border-radius: 0 50px 50px 0;
  white-space: nowrap;
}

.calendar-picker hr {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  border-top: 1px solid var(--xpl-color-gray-400);
}

.filters {
  margin-top: 1rem;
}

.filters .dropdown-menu {
  max-height: 15rem;
  overflow-y: scroll;
  max-width: 14rem;
}

.filters .drawer {
  margin-top: 1rem;
  font-size: .625rem;
  font-weight: 700;
  line-height: 1.6;
  color: #617080;
  cursor: pointer;
}

.filters .drawer .material-icons {
  font-size: .625rem;
  font-weight: 700;
  line-height: 1.6;
  color: #617080;
  cursor: pointer;
}

.filters h6 {
  text-transform: uppercase;
  font-size: .625rem;
  font-weight: 800;
  line-height: 2;
  color: #617080;
  margin-top: 1.5rem;
}

.filters .statuses .status {
  display: flex;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.43;
  color: #1d364b;
  align-items: center;
  justify-content: flex-start;
  margin-top: .75rem;
  cursor: pointer;
}

.filters .statuses .status .icon {
  font-size: 1rem;
  margin-right: .5rem;
}

.filters .assignee .none {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: .875rem;
  line-height: 1;
  letter-spacing: 0.3px;
  color: #8b9bac;
  cursor: pointer;
}

.filters .assignee .none .square {
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: .5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 6px;
  border: dashed 2px #afbcca;
}

.filters .assignee .member {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: .875rem;
  line-height: 1;
  cursor: pointer;
  color: #2e4457;
}

.filters .assignee .member span {
  margin-left: auto;
  font-size: .875rem;
  color: rgb(214, 41, 41);
  opacity: .75;
  transition: all 150ms ease-in-out;
}

.filters .assignee .member span:hover {
  opacity: 1;
}

.reporting > div {
  font-size: .875rem;
  line-height: 1;
  letter-spacing: 0.3px;
  color: #8b9bac;
  cursor: pointer;
}
</style>
