<template>
  <div>
    <div class="text-center" style="padding: 5rem;" v-if="!campaign">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="campaign" v-if="campaign" v-loading="globalLoading">
      <div class="nav-subheader">
        <div class="nav-inner">
          <div class="campaign-name-container">
            <div class="name">
              <span autocomplete="off" :class="{ 'edit-border': show_campaign_name_save_btn }" style="cursor: default;" :contenteditable="show_campaign_name_save_btn" ref="campaignName">{{ campaign.name }}</span>
              <i class="fa fa-pencil edit-pencil-icon" v-if="!show_campaign_name_save_btn" @click.prevent="show_campaign_name_save_btn = true"></i>
              <span class="pill pill-success" v-if="campaign.turned_on_at && !campaign.paused_at">Live</span>
              <span class="pill pill-secondary" v-if="!campaign.turned_on_at && campaign.paused_at">Paused</span>
            </div>
            <div v-if="show_campaign_name_save_btn" class="campaign-name-save-btn-container">
              <el-button class="xpl-button xpl-button--primary small-save" @click.prevent="editName">Save</el-button>
            </div>
          </div>
          <div class="actions" style="position: relative">
            <el-popover
            placement="bottom"
            trigger="manual"
            v-model="pop_visible"
            visible-arrow=false
            popper-class="campaign-start-pop"
            >
              <div  class="campaign-pop-header">
                Heads up
              </div>
              <span class="padding-pop">
                <p  class="popper-text">You have automated items that are not set live.</p>
                <p  class="popper-text">These will not send until they are set live individually.</p>
                <p class="popper-text">Do you want to set the full automation live?</p>
              </span>
              <div class="pop-footer">
                <el-button class="cancel" @click="pop_visible = false">Cancel</el-button>
                
                <button class="confirm-pop" type="primary" size="mini" @click="startCampaign(), pop_visible = false">Start Automation</button>
              </div>
              <el-button slot="reference" class="xpl-button xpl-button--success pop draft" v-if="!campaign.turned_on_at && !hideButtons && !this.allLiveCheck" @click=" pop_visible = true">{{ loading ? 'Starting...' : 'Start' }}</el-button>
            </el-popover>
          <div style="margin-right: 6px">
                <button class="xpl-button xpl-button--secondary" v-if="campaign.turned_on_at && !campaign.paused_at && !hideButtons" @click="pauseCampaign">Pause</button>

            <button class="xpl-button xpl-button--success live" v-if="!campaign.turned_on_at && !campaign.paused_at && !hideButtons && this.allLiveCheck" @click="startCampaign" :disabled="!setUpComplete || loading">{{ loading ? 'Starting...' : 'Start' }}</button>

            <button class="xpl-button xpl-button--success live" v-if="!campaign.turned_on_at && campaign.paused_at && !hideButtons && this.allLiveCheck" @click="startCampaign" :disabled="loading">{{ loading ? 'Starting...' : 'Start' }}</button>

          </div>
        
            <div class="dropdown">
              <button type="button" class="xpl-button xpl-button--secondary" @click.stop="show_options = !show_options" v-on-clickaway="away">Options</button>
              <div :class="{ 'dropdown-menu': true, 'dropdown-menu-right': true, 'show': show_options }">
                <a :href="'/campaigns/' + uuid + '/settings'" class="dropdown-item" v-if="!hideButtons">Settings</a>
                <a :href="'/campaigns/' + uuid + '/synced'" class="dropdown-item" v-if="false && $store.state.user.current_account.franchise && !hideButtons">Synced Campaigns</a>
                <a href="#" class="dropdown-item" @click.prevent="copyCampaign" v-if="!hideButtons">Copy</a>
                <a href="#" class="dropdown-item text-danger" @click.prevent="deleteCampaign" v-if="!campaign.turned_on_at && !hideButtons">Delete</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="campaign-container">
        <div class="campaign-setup">
          <CampaignPerformance :campaign="campaign" v-if="campaign.turned_on_at || campaign.paused_at" />

          <div  v-if="!show_campaign_stats">
            
          
            <EnrollmentSetup v-if="(!campaign.turned_on_at && !campaign.paused_at) || (!campaign.turned_on_at && campaign.paused_at)" :campaign="campaign" :allLiveCheck="allLiveCheck" />
            <GoalSetup :campaign="campaign" :enrollmentRuleComplete="enrollmentRuleComplete" v-if="(!campaign.turned_on_at && !campaign.paused_at) || (!campaign.turned_on_at && campaign.paused_at)" :editing_goals="edit_goals"/>

            <div class="card settings" v-if="campaign.turned_on_at">
              <div class="card-body">
                <div class="title">
                  <span>Campaign Overview</span>
                  <button v-if="!hideButtons" type="button" class="xpl-button xpl-button--secondary" @click="$toasted.error('Please pause your campaign to edit it.')">
                    Edit
                  </button>
                </div>
                <div class="heading">
                  <img src="https://assets.brandbot.com/public/icons/timeline/enter-campaign.svg" />
                  Who is entering this campaign?
                </div>
                <div class="inner">
                  <div v-if="campaign.enrollment_rule && campaign.enrollment_rule.rule && campaign.enrollment_rule.rule.length">
                    <EnrollmentEnglish v-if="campaign.enrollment_rule.rule[0].condition.quick" :rule="campaign.enrollment_rule.rule[0]"  />
                    <RuleEnglishHybrid v-else :rule="campaign.enrollment_rule.rule" :enrollment="true" />
                  </div>
                  <div v-else>
                    You do not have an enrollment rule for this campaign.
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="add-contacts">
                  <div class="contact-header">Add segments or contacts manually</div>
                  <span class="contact-subdued-text">Add a segment that you've already created or add individuals that will be missed by the rule above.</span>
                  <el-button  v-if="!this.campaign.turned_on_at || !this.allLiveCheck" class="xpl-button xpl-button--secondary" @click="contacts_warning_show = true">Add Contacts</el-button>
                  <el-button v-else class="xpl-button xpl-button--secondary" @click="$router.push('/campaigns/' + campaign.uuid + '/enroll')">Add Contacts</el-button>
                  <div style="position: relative">
                    <el-popover
                    placement="bottom"
                    trigger="manual"
                    v-model="contacts_warning_show"
                    visible-arrow=false
                    popper-class="contacts-add-pop"
                    >
                      <div  class="campaign-pop-header">
                        Set your campaign live before adding contacts
                      </div>
                      <span class="padding-pop">
                        <p class="popper-text">Set your campaign and campaign items live before manually enrolling contacts in order for them to receive messages in the campaign!</p>
                        <p class="popper-text">Do you still want to add contacts to the campaign?</p>
                      </span>
                      <div class="pop-footer">
                        <el-button class="cancel" @click="contacts_warning_show = false">Cancel</el-button>
                        <button  class="confirm-pop" type="primary" size="mini" @click="$router.push('/campaigns/' + campaign.uuid + '/enroll'), contacts_warning_show = false" >Add Contacts</button>
                      </div>
                    </el-popover>
                  </div>
                 </div>
              </div>
             
              <div class="card-body">
                <div class="heading">
                  <img src="https://assets.brandbot.com/public/icons/timeline/exit-campaign.svg" />
                  Contacts leave when they reach any of the following goals
                </div>
                <div class="inner goals">
                  <div v-if="campaign.no_goals">You have chosen to not define any goals for this campaign.</div>
                  <div class="conditions" v-if="!campaign.no_goals">
                    <div class="conditions-goals" style="margin-top: 1rem;" v-for="goal in campaign.goals">
                      <div>
                      <h1>{{ goal.name }}</h1>
                        <div class="rule-bg" v-if="goal.drawer">
                          <div class="empty" v-if="!goal.rule || !goal.rule.length">
                            This goal is empty
                          </div>
                          <div v-else>
                            <EnrollmentEnglish v-if="goal.rule[0].condition.quick" :rule="goal.rule[0]" />
                            <RuleEnglishHybrid v-else :rule="goal.rule" :enrollment="true" />
                          </div>
                        </div>
                      </div>
                      <div class="left-side">
                        <div class="metrics" v-if="goal.rule && goal.rule.length">
                          <div class="contacts">
                            <span v-if="goal.count">{{ goal.count | prettyNumber }}</span>
                            <span v-if="!goal.count" class="text-muted">&mdash;</span>
                          </div>
                          <div class="drawer">
                            <span class="material-icons" @click.prevent="toggleDrawer(goal)" v-if="!goal.drawer">keyboard_arrow_down</span>
                            <span class="material-icons" @click.prevent="toggleDrawer(goal)" v-if="goal.drawer">keyboard_arrow_up</span>
                          </div>
                        </div>
                        <div class="actions" v-if="edit_goals">
                          <button type="button" class="xpl-button xpl-button--secondary xpl-button--warning" @click.prevent="deleteGoal(goal)">Delete</button>
                          <button type="button" class="xpl-button xpl-button--secondary" @click.prevent="editGoal(goal)">Edit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="campaign-container transparent">
        <div class="campaign-items-container">
          <div class="section-title">
            <div>
              <h1>Auto-Messages to Contacts in this Campaign</h1>
            </div>
            <div class="dropdown" v-if="!hideButtons">
              <button type="button" class="xpl-button xpl-button--primary" @click="show_add_message_dropdown = !show_add_message_dropdown">Add an auto-message</button>
              <div :class="['dropdown-menu', 'dropdown-menu-right', show_add_message_dropdown ? 'show' : '']">
                <a href="" class="dropdown-item" @click.prevent="campaign_item_name = true; campaign_item_type = 'email';">
                  <span class="material-icons">email</span> Auto-Email
                </a>
                <a href="" class="dropdown-item" @click.prevent="campaign_item_name = true; campaign_item_type = 'sms';" v-if="$store.state.user.current_account.sms_enabled" style="align-items: center;">
                  <span class="material-icons">sms</span> Auto-SMS
                </a>
              </div>
            </div>
          </div>

          <div class="card first-state" v-if="!campaignItemsEmails.length && !loading_items">
            <div class="card-body">
              <div>
                <h1>Encourage action with targeted auto-messages</h1>
                <div class="actions">
                  <a href="http://docs.brandbot.com/auto-campaigns/auto-messages-and-auto-actions/auto-emails" class="btn btn-bb-learn-more" target="_blank" style="margin: 0;">Learn more</a>
                </div>
              </div>
              <div>
                <img src="https://assets.brandbot.com/public/empty-states/auto-message.svg" style="height: 90%; min-width: 320px;" alt="Messages" />
              </div>
            </div>
          </div>
          <div class="campaign-items" v-if="loading_items">
            <div class="campaign-item text-center">
              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
          </div>
          <div class="campaign-items" v-if="campaignItemsEmails.length && !loading_items">
            
            <div class="campaign-item" v-for="campaign_item in campaignItemsEmails" v-bind:key="campaign_item.uuid" @click="$router.push({name: 'campaigns items', params:{uuid: campaign_item.uuid, campaignItems: campaign_items, campaign_id: campaign.id}})">
              <div>
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/in-progress.svg" v-if="!campaign_item.turned_on_at && !campaign_item.paused_at" />
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/paused.svg" v-if="campaign_item.paused_at" />
                <img src="https://assets.brandbot.com/public/icons/campaigns/active.svg" v-if="campaign_item.turned_on_at" />
              </div>
              <div>
                <div class="header">
                  <span class="material-icons" v-if="campaign_item.type === 'email'">email</span>
                  <span class="material-icons" v-if="campaign_item.type === 'sms'">sms</span>
                  <h1>{{ campaign_item.name }}</h1>
                  <span class="status active" v-if="campaign_item.turned_on_at">Active</span>
                  <span class="status paused" v-if="campaign_item.paused_at">Paused</span>
                  <span class="status draft" v-if="!campaign_item.turned_on_at && !campaign_item.paused_at">Draft</span>
                </div>
                <div class="small text-muted">
                  <div class="rule-bg" v-if="campaign_item.time_rule.choice">
                    <CampaignItemEnglish :type="campaign_item.type" :timeRule="campaign_item.time_rule" :behaviorRule="campaign_item.behavior_rule" :webHook="campaign_item.name" />

                    <div v-if="campaign_item.behavior_rule && campaign_item.behavior_rule.rule && campaign_item.behavior_rule.rule.length && !campaign_item.behavior_rule.rule[0].condition.quick &&
                    campaign_item.time_rule.choice !== 'relative'">
                      <span class="behavior">Every contact who matches the following conditions</span>
                      <div style="margin-top: .5rem;">
                        <RuleEnglishHybrid :rule="campaign_item.behavior_rule.rule" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="metrics" v-if="campaign_item.turned_on_at || campaign_item.paused_at">
                <div class="metric" v-if="campaign_item.type === 'email'">
                  <span class="number text-muted" v-if="!campaign_item.scheduled_emails">&mdash;</span>
                  <span class="number" v-if="campaign_item.scheduled_emails">{{ campaign_item.scheduled_emails | prettyNumber }}</span>
                  Scheduled
                </div>
                <div class="metric" v-if="campaign_item.type === 'sms'">
                  <span class="number text-muted" v-if="!campaign_item.scheduled_sms">&mdash;</span>
                  <span class="number" v-if="campaign_item.scheduled_sms">{{ campaign_item.scheduled_sms | prettyNumber }}</span>
                  Scheduled
                </div>
                <div class="metric" v-if="campaign_item.type === 'sms'">
                  <span class="number text-muted" v-if="!campaign_item.sent_sms">&mdash;</span>
                  <span class="number" v-if="campaign_item.sent_sms">{{ campaign_item.sent_sms | prettyNumber }}</span>
                  Sent
                </div>
                <CampaignItemMetrics :uuid="campaign_item.uuid" :campaignItemType="campaign_item.type" v-if="campaign_item.type === 'email'" />
              </div>
            </div>
          </div>

          <div class="section-title">
            <div>
              <h1>Auto-Actions to Contacts in this Campaign</h1>
            </div>
            <div class="dropdown" v-if="!hideButtons">
              <button type="button" class="xpl-button xpl-button--primary" @click.stop="show_add_task_dropdown = !show_add_task_dropdown"  v-on-clickaway="away">Add an auto-action</button>
              <div :class="['dropdown-menu', 'dropdown-menu-right', show_add_task_dropdown ? 'show' : '']">
                <a href="#" class="dropdown-item" @click.prevent="campaign_item_name = true; campaign_item_type = 'task'; show_add_task_dropdown = false;">
                  <span class="material-icons">assignment</span> Task
                </a>
                <a href="#" class="dropdown-item" @click.prevent="campaign_item_name = true; campaign_item_type = 'phone_call'; show_add_task_dropdown = false;">
                  <span class="material-icons">call</span> Phone Call Reminder
                </a>
                <a href="#" class="dropdown-item" @click.prevent="campaign_item_name = true; campaign_item_type = 'tag'; show_add_task_dropdown = false;">
                  <span class="material-icons">local_offer</span> Tag
                </a>
                <a v-if="$store.state.user.current_account.id === 1458" href="#" class="dropdown-item" @click.prevent="campaign_item_name = true; campaign_item_type = 'mtAction'; show_add_task_dropdown = false;">
                  <img class="grey-filter" src="https://assets.brandbot.com/public/icons/campaign-items/bolt-24px.svg"> Mariana Tek Action
                </a>
                <a v-if="$store.state.user.current_account.id === 1458" href="#" class="dropdown-item" @click.prevent="campaign_item_name = true; campaign_item_type = 'webHook'; show_add_task_dropdown = false;">
                 <img class="grey-filter" src="https://assets.brandbot.com/public/icons/campaign-items/device_hub-24px.svg"> Webhook
                </a>
              </div>
            </div>
          </div>

          <div class="card first-state" v-if="!campaignItemsTasks.length && !loading_items">
            <div class="card-body">
              <div>
                <h1>Manage the madness with tasks and phone call reminders</h1>
                <div class="actions">
                  <a href="http://docs.brandbot.com/auto-campaigns/auto-messages-and-auto-actions/auto-tasks-reminders-and-tags" class="btn btn-bb-learn-more" style="margin: 0;" target="_blank">Learn more</a>
                </div>
              </div>
              <div>
                <img src="https://assets.brandbot.com/public/empty-states/tasks-reminders.svg" style="height: 90%; min-width: 320px;" alt="Tasks & Phone Calls" />
              </div>
            </div>
          </div>
          <div class="campaign-items" v-if="loading_items">
            <div class="campaign-item text-center">
              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
          </div>
          <div class="campaign-items" v-if="campaignItemsTasks.length && !loading_items">
            <div class="campaign-item" v-for="campaign_item in campaignItemsTasks"  v-bind:key="campaign_item.uuid" @click="$router.push({name: 'campaigns items', params:{uuid: campaign_item.uuid, campaignItems: campaign_items, campaign_id: campaign.id}}), console.log(campaign_items)">
              <div>
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/in-progress.svg" v-if="!campaign_item.turned_on_at && !campaign_item.paused_at" />
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/paused.svg" v-if="campaign_item.paused_at" />
                <img src="https://assets.brandbot.com/public/icons/campaigns/active.svg" v-if="campaign_item.turned_on_at" />
              </div>
              <div>
                <div class="header">
                  <span class="material-icons" v-if="campaign_item.type === 'task'">assignment</span>
                  <span class="material-icons" v-if="campaign_item.type === 'phone_call'">phone</span>
                  <span class="material-icons" v-if="campaign_item.type === 'tag'">local_offer</span>
                  <h1>{{ campaign_item.name }}</h1>
                  <span class="status active" v-if="campaign_item.turned_on_at">Active</span>
                  <span class="status paused" v-if="campaign_item.paused_at">Paused</span>
                  <span class="status draft" v-if="!campaign_item.turned_on_at && !campaign_item.paused_at">Draft</span>
                </div>
                <div class="small text-muted">
                  <div class="rule-bg" v-if="campaign_item.time_rule.choice">
                    <CampaignItemEnglish :type="campaign_item.type" :timeRule="campaign_item.time_rule" :behaviorRule="campaign_item.behavior_rule" :webHook="campaign_item.name"/>

                    <div v-if="campaign_item.behavior_rule && campaign_item.behavior_rule.rule && campaign_item.behavior_rule.rule.length && !campaign_item.behavior_rule.rule[0].condition.quick &&
                    campaign_item.time_rule.choice !== 'relative'">
                      <span class="behavior">Every contact who matches the following conditions</span>
                      <div style="margin-top: .5rem;">
                        <RuleEnglishHybrid :rule="campaign_item.behavior_rule.rule" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="metrics" v-if="campaign_item.turned_on_at || campaign_item.paused_at">
                <div class="metric">
                  <span class="number text-muted" v-if="!campaign_item.created_tasks && !campaign_item.created_tags">&mdash;</span>
                  <span class="number" v-if="campaign_item.created_tasks || campaign_item.created_tags">{{ campaign_item.created_tasks || campaign_item.created_tags | prettyNumber }}</span>
                  Created
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
      <ConditionsSidebarHybrid v-if="conditions_sidebar" :contact_list="campaign.enrollment_rule" :enrollment="true" />
      <ConditionsSidebarHybrid v-if="selectedGoal" :contact_list="selectedGoal" :enrollment="false" :goalList="true" />
      <CampaignItemName v-if="campaign_item_name" :type="campaign_item_type" :campaignId="campaign.id" :campaignItems="campaign_items"/>
      <CampaignGoalName v-if="show_campaign_goal_name" :campaignId="campaign.id" />
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import { directive as onClickaway } from 'vue-clickaway';
  import ConditionsSidebar from '@/components/Contacts/ContactLists/Conditions/ConditionsSidebar';
  import RuleEnglish from '@/components/Contacts/ContactLists/Conditions/RuleEnglish';
  import EnrollmentEnglish from './EnrollmentEnglish';
  import CampaignItemName from './CampaignItemName';
  import CampaignGoalName from './CampaignGoalName';
  import CampaignItemMetrics from './CampaignItemMetrics';
  import CampaignIsPaused from './CampaignIsPaused';
  import CampaignIsLive from './CampaignIsLive';
  import CampaignPerformance from './CampaignPerformance';
  import EnrollmentSetup from './EnrollmentSetup';
  import GoalSetup from './GoalSetup';
  import CampaignItemEnglish from './CampaignItemEnglish';
  import RuleEnglishHybrid from '../../Contacts/ContactLists/Conditions/RuleEnglishHybrid';
  import ConditionsSidebarHybrid from '../../Contacts/ContactLists/Conditions/ConditionsSidebarHybrid';

  export default {
    props: ['uuid'],
    components: {
      ConditionsSidebarHybrid,
      RuleEnglishHybrid,
      CampaignItemName,
      ConditionsSidebar,
      RuleEnglish,
      CampaignItemEnglish,
      CampaignGoalName,
      CampaignItemMetrics,
      CampaignIsPaused,
      CampaignIsLive,
      CampaignPerformance,
      EnrollmentSetup,
      GoalSetup,
      EnrollmentEnglish,
    },
    directives: {
      onClickaway,
    },
    data() {
      return {
        globalLoading: false,
        loading: false,
        show_contact_activity: false,
        show_campaign_stats: false,
        campaign_item_name: false,
        campaign_item_type: null,
        show_add_task_dropdown: false,
        show_add_message_dropdown: false,
        show_campaign_goal_name: false,
        selectedGoal: null,
        conditions_sidebar: false,
        goals_sidebar: false,
        edit_goals: false,
        edit_exit: false,
        show_options: false,
        campaign: null,
        campaign_items: [],
        contacts_expire: false,
        contacts_exit_complete: false,
        expiration_days: 1,
        loading_items: true,
        pop_visible: false,
        show_campaign_name_save_btn: false,
        contacts_warning_show: false,
      };
    },
    created() {
      this.fetchCampaign();
    },
    mounted() {
      EventBus.$on('show_campaign_stats', (stat) => {
        this.show_campaign_stats = stat;
      });

      EventBus.$on('campaign.start', () => {
        this.startCampaign();
      });

      EventBus.$on('campaign.pause', () => {
        this.pauseCampaign();
      });

      EventBus.$on('contact_list.conditions_sidebar.close', () => {
        this.conditions_sidebar = false;
        // this.selectedGoal = null;
        // this.fetchCampaign();
      });

      EventBus.$on('campaign_item_name.modal.close', () => {
        this.campaign_item_name = false;
        this.campaign_item_type = null;
      });

      EventBus.$on('campaign.stats.close', () => {
        this.show_campaign_stats = false;
      });

      EventBus.$on('campaign_goal_name.modal.close', (payload) => {
        if (payload) {
          this.campaign = payload.campaign;
          this.selectedGoal = this.campaign.goals[this.campaign.goals.length - 1];
        }
        this.show_campaign_goal_name = false;
      });
      EventBus.$on('campaign.goal.setup', async () => {
        this.edit_goals = true;
        this.loading_items = true;
        this.campaign_items = [];
        this.campaign = null;
        await window.sleep(500);
        this.fetchCampaign();
      });
    },
    beforeDestroy() {
      EventBus.$off('campaign.goal.setup');
      EventBus.$off('show_campaign_stats');
      EventBus.$off('campaign.start');
      EventBus.$off('campaign.pause');
      EventBus.$off('contact_list.conditions_sidebar.close');
      EventBus.$off('campaign_item_name.modal.close');
      EventBus.$off('campaign.stats.close');
      EventBus.$off('campaign_goal_name.modal.close');
    },
    computed: {
      allActionsAreLive() {
        return this.campaign_items.map((item) => {
          if (item.turned_on_at === null && item.paused_at === null) {
              // draft mode
            return false;
          } else if (item.paused_at != null && item.turned_on_at === null) {
            // paused mode
            return false;
          } return true;
        });
      },
      allLiveCheck() {
        return Object.values(this.allActionsAreLive).every(v => v === true);
      },
      hideButtons() {
        if (this.$store.state.user.is_hq_admin) {
          return false;
        }

        // this magical array contains some non-BB users who can sync campaigns
        if (this.$store.getters.isBrandBotAdmin || this.$store.getters.isCampaignSuperUser) {
          return false;
        }

        if (this.campaign.sync_type && this.campaign.sync_type === 'sync_lock') {
          return true;
        }

        if (this.campaign.sync_type && this.campaign.sync_type === 'sync_unlock') {
          return false;
        }

        return false;
      },
      campaignItemsEmails() {
        if (!this.campaign_items) {
          return [];
        }

        return this.campaign_items.filter(c => c.type === 'email' || c.type === 'sms');
      },
      campaignItemsTasks() {
        if (!this.campaign_items) {
          return [];
        }

        return this.campaign_items.filter(c => c.type === 'task' ||
            c.type === 'phone_call' ||
            c.type === 'tag');
      },
      enrollmentRuleComplete() {
        return this.campaign.enrollment_rule.rule !== null &&
          this.campaign.enrollment_rule.rule.length;
      },
      goalComplete() {
        return this.campaign.no_goals ||
          (this.campaign.goals.length && this.campaign.goals[0].rule !== null);
      },
      exitRuleComplete() {
        return (this.campaign.no_exit_rule ||
          (this.campaign.metadata.contacts_expire &&
            this.campaign.metadata.contacts_expire_days > 0));
      },
      setUpComplete() {
        return this.enrollmentRuleComplete && this.goalComplete;
      },
    },
    methods: {
      toggleDrawer(goal) {
        if (goal.drawer) {
          goal.drawer = !goal.drawer;
        } else {
          this.$set(goal, 'drawer', true);
        }
      },
      editName() {
        this.$refs.campaignName.innerHTML = this.$refs.campaignName.textContent.trim();
        this.campaign.name = this.$refs.campaignName.textContent;
        const payload = {
          name: this.campaign.name,
        };

        api.updateCampaign(this.campaign.id, payload).then((response) => {
          this.$toasted.success(response.message);
          this.$refs.campaignName.blur();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
        this.show_campaign_name_save_btn = false;
        return false;
      },
      copyCampaign() {
        this.globalLoading = true;
        api.copyCampaign(this.campaign.id).then((response) => {
          this.globalLoading = false;
          this.$message.success(response.message);
          this.$router.push('/campaigns');
        }).catch((err) => {
          this.globalLoading = false;
          this.$message.error(err.message);
        });
      },
      away() {
        this.show_options = false;
        this.show_add_task_dropdown = false;
      },
      fetchCampaign() {
        api.getCampaign(this.uuid).then((response) => {
          this.campaign = response.campaign;
          this.fetchCampaignItems();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchCampaignItems() {
        if (this.campaign) {
          api.getCampaignItems(this.campaign.id).then((response) => {
            this.campaign_items = response.campaign_items;
            this.loading_items = false;
          }).catch((err) => {
            this.$toasted.error(err.message);
          });
        }
      },
      async deleteCampaign() {
        const options = {
          reverse: true,
          type: 'hard',
          okText: 'Delete',
          verification: 'delete',
          animation: 'bounce',
        };
        try {
          await this.$dialog.confirm('Are you sure you want to delete this campaign? This is irreversible.', options);
        } catch (err) {
          return;
        }
        try {
          const response = await api.deleteCampaign(this.campaign.id);
          this.$router.push('/campaigns/');
          this.$toasted.success(response.message);
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      showStat(stat) {
        if (this.show_campaign_stats !== stat) {
          this.show_campaign_stats = stat;
        } else {
          this.show_campaign_stats = false;
        }
      },
      editGoal(goal) {
        if (!this.edit_goals) {
          return;
        }

        this.selectedGoal = goal;
      },
      deleteGoal(goal) {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to delete this goal?')) {
          return;
        }

        const payload = {
          action: 'delete_goal',
          contact_list_id: goal.id,
        };

        api.deleteGoalCampaign(this.campaign.id, payload).then((response) => {
          this.$toasted.success(response.message);
          this.campaign = response.campaign;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      toggleWithGoal(event) {
        const target = event.target || event.srcElement;

        target.disabled = true;

        const payload = {
          no_goals: !this.campaign.no_goals,
        };

        api.updateCampaign(this.campaign.id, payload).then((response) => {
          this.$toasted.success(response.message);
          target.disabled = false;
          this.campaign.no_goals = !this.campaign.no_goals;
          this.edit_goals = false;
        }).catch((err) => {
          this.$toasted.error(err.message);
          target.disabled = false;
        });
      },
      addNewGoal() {
        if (this.campaign.goals.filter(g => !g.rule || !g.rule.length).length) {
          this.$toasted.error('Please make sure all your goals are set before creating a new one.');
        } else {
          this.show_campaign_goal_name = true;
        }
      },
      updateExitRule(event) {
        const target = event.target || event.srcElement;

        target.disabled = true;

        const payload = {
          no_exit_rule: !this.campaign.no_exit_rule,
        };

        api.updateCampaign(this.campaign.id, payload).then((response) => {
          this.$toasted.success(response.message);
          target.disabled = false;
          this.campaign.no_exit_rule = !this.campaign.no_exit_rule;
          this.edit_exit = !this.campaign.no_exit_rule;
        }).catch((err) => {
          this.$toasted.error(err.message);
          target.disabled = false;
        });
      },
      saveExitRule(event) {
        const target = event.target || event.srcElement;

        target.disabled = true;

        const payload = {
          contacts_expire: this.campaign.metadata.contacts_expire,
          expiration_days: this.campaign.metadata.contacts_expire_days,
        };

        api.updateCampaign(this.campaign.id, payload).then((response) => {
          this.$toasted.success(response.message);
          target.disabled = false;
          this.edit_exit = false;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      startCampaign() {
        const payload = {
          turn_on: true,
        };
        this.loading = true;

        api.updateCampaign(this.campaign.id, payload).then((response) => {
          this.loading = false;
          this.$toasted.success(response.message);
          this.campaign.turned_on_at = response.campaign.turned_on_at;
          this.campaign.paused_at = response.campaign.paused_at;
          this.fetchCampaign();
          this.edit_exit = false;
          this.edit_goals = false;
        }).catch((err) => {
          this.loading = false;
          this.$toasted.error(err.message);
        });
      },
      pauseCampaign() {
        const payload = {
          pause: true,
        };

        api.updateCampaign(this.campaign.id, payload).then((response) => {
          this.$toasted.success(response.message);
          this.campaign.turned_on_at = response.campaign.turned_on_at;
          this.campaign.paused_at = response.campaign.paused_at;
          this.fetchCampaign();
          this.edit_exit = false;
          this.edit_goals = false;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
    filters: {
      timeFrame(value) {
        if (value === 'all') {
          return 'All Time';
        }

        return value;
      },
    },
  };
</script>
<style lang="scss">
  .rule-bg .conditions-container {
    padding-top: .5rem !important;
    padding-bottom: 1rem !important;
  }

  .rule-bg .conditions-container .see-more {
    padding-left: 0 !important;
  }

  .rule-bg .see-more .see-more-inner,
  .rule-bg .see-more .gradient {
    padding-left: 1.5rem !important;
  }

  .rule-bg .conditions-container.expanded .see-more-inner {
    padding-left: 1.5rem !important;
    padding-bottom: 1rem !important;
  }

  .rule-bg .conditions-container .see-more .see-more-inner {
    background-color: #f2fafe !important;
  }

  .rule-bg .see-more .gradient {
    background: linear-gradient(rgba(242, 250, 254, 0), rgba(242, 250, 254, 1)) !important;
  }

  .rule-bg .time,
  .rule-bg .behavior {
    color: rgb(0, 21, 42);
    font-size: .875rem !important;
  }
</style>
<style lang="scss" scoped>
  .nav-subheader {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    height: auto;
    max-width: 100%;
  }

  .nav-inner {
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav-inner .name {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    max-width: 85%;
    width: 80%;
  }

  .nav-inner .name span[contenteditable] {
    outline: 0;
    border: none;
    width: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    height: 2rem;
  }

  .nav-inner .pill {
    padding-right: .75rem;
    padding-left: .75rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
    border-radius: 5px;
    border: none;
    height: auto;
    min-width: 3.25rem;
    font-size: .75rem;
    letter-spacing: 0.2px;
    color: #ffffff;
    text-transform: uppercase;
    position: relative;
    bottom: 5px;
  }

  .nav-inner .pill.pill-success {
    background-image: linear-gradient(to right, #49e295, #5ef6bc);
  }

  .nav-inner .pill.pill-secondary {
    color: black;
    background-color: #d8d8d8;
    text-overflow: normal;
    white-space: normal;
  }

  .nav-inner .name .pill {
    margin-left: .875rem;
  }

  .nav-inner .actions {
    display: flex;
  }

  .nav-inner .actions .btn {
    margin-right: 1rem;
  }

  .nav-inner .actions .dropdown .btn {
    margin-right: 0;
  }

  .campaign .campaign-container {
    width: 100%;
    background-color: var(--xpl-color-background-lm);
  }

  .campaign .campaign-container.transparent {
    background-color: transparent;
  }

  .campaign .campaign-container .campaign-setup,
  .campaign .campaign-container .campaign-items-container {
    width: 100%;
    max-width: 73.75rem;
    margin: auto;
    padding-top: 2.5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 2.5rem;
  }

  .campaign-container .step {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1rem;
    color: #60676e;
    margin-top: 2rem;
  }

  .campaign-container .step:first-child {
    margin-top: 0;
  }

  .campaign-container .step .step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 99rem;
    background-color: #afbcca;
    margin-right: 1rem;
    color: white;
    font-size: .75rem;
  }

  .campaign-container .step.active .step-number {
    background-color: #43a2e0;
  }

  .campaign-container .step.complete .step-number {
    background-color: #00dd8c;
  }

  hr {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    border-color: rgb(217, 231, 240);
  }

  .step + .card {
    margin-top: 2rem;
  }

  .step + .card .card-body {
    display: flex;
    flex-direction: column;
  }

  .alert.alert-secondary {
    margin-top: .75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1.5rem;
    border-radius: 5px;
    border: none;
    background-color: rgba(243, 247, 249, 0.57);
    font-size: .75rem;
    color: rgb(111, 131, 147);
  }

  .alert.alert-secondary .btn {
    margin-top: 1rem;
  }

  .btn-bb-learn-more {
    margin-left: auto;
    margin-right: auto;
  }

  .campaign-container h1 {
    font-size: 1rem;
    font-weight: 700;
    color: rgb(75, 76, 80);
  }

  .card.first-state {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .card.first-state .card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .card.first-state h1 {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.75rem;
    color: #6923f4;
    margin-bottom: 1rem;
  }

  .card.first-state p {
    max-width: 18.75rem;
    font-size: .875rem;
    font-weight: 700;
    color: #8b9bac;
    margin-bottom: 1.5rem;
  }

  .card.first-state .actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .card.first-state .actions .btn:first-child {
    margin-right: 1rem;
  }

  .card.first-state .actions .btn-link {
    font-size: .75rem;
    line-height: 1rem;
    color: #1d364b;
  }

  .conditions .icon {
    font-size: 1rem;
    color: var(--xpl-color-secondary-lm);
    margin-right: .5rem;
  }

  .conditions .name {
    margin-top: 1rem;
    font-size: .875rem;
    line-height: 1.25rem;
    color: #1d364b;
  }

  .conditions .details {
    padding-left: 1.75rem;
  }

  .conditions .details {
    text-transform: capitalize;
    color: #8b9bac;
    font-size: .75rem;
    line-height: 1rem;
  }

  .conditions .details .title {
    font-weight: 700;
    line-height: 1.25rem;
    display: block;
    margin-top: .25rem;
    display: block;
    color: #617080;
  }

  .card.goals .card-body {
    font-size: .875rem;
    line-height: 1.25rem;
    color: #617080;
  }

  .card.goals .actions {
    margin-top: 1rem;
    margin-bottom: 2.5rem;
  }

  .card.goals .actions span {
    font-size: .625rem;
    letter-spacing: 0.1px;
    color: #b3bac2;
    text-transform: uppercase;
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .card.goals .actions a {
    font-size: .875rem;
    line-height: .875rem;
    letter-spacing: 0.1px;
    color: #47525d;
    text-decoration: underline;
  }

  .conditions .conditions-goals {
    display: flex;
    align-items: flex-start;
    border-bottom: solid 1px var(--xpl-color-background-lm);
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .conditions .conditions-goals > div:first-child {
    width: 100%;
  }

  .conditions .conditions-goals h1 {
    font-size: .875rem;
    font-weight: normal;
    margin-bottom: 0;
    margin-top: .5rem;
    font-weight: 700;
    color: rgb(65, 67, 68);
  }

  .conditions .conditions-goals:first-child {
    padding-top: 0;
  }

  .conditions .conditions-goals:last-child {
    border-bottom: none;
  }

  .conditions-goals .rule-bg,
  .campaign-item .rule-bg,
  .inner .rule-bg {
    border-radius: 6px;
    position: relative;
    background-color: #f2fafe;
    padding: .75rem;
    padding-bottom: 0;
    padding-top: .5rem;
    border-radius: 6px;
    width: 45rem;
    margin-top: .75rem;
  }

  .inner .rule-bg {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .conditions-goals .empty {
    font-size: .875rem;
    padding-bottom: .75rem;
  }

  .btn-link.btn-new-goal {
    margin-right: auto;
    font-size: .875rem;
    line-height: 1.25rem;
    color: rgb(46, 68, 87);
    text-decoration: underline;
    cursor: pointer;
  }

  .conditions-goals .metrics {
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card.goals .conditions-goals .actions {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
  }

  .card.goals .conditions-goals .actions .btn {
    margin-right: .5rem;
  }

  .conditions-goals .metrics .icon,
  .conditions-goals .metrics .material-icons {
    font-size: 1rem;
    margin-right: .5rem;
  }

  .conditions-goals .metrics .contacts {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: 1rem;
    font-weight: 700;
    color: rgb(0, 21, 42);
  }

  .conditions-goals .metrics img {
    width: 1rem;
    height: 1rem;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 1rem;
  }

  .conditions-goals .metrics .goal-percentage {
    color: #617080;
    margin-right: 1.5rem;
  }

  .conditions-goals .left-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
  }

  .left-side .drawer {
    margin-left: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .left-side .drawer .material-icons {
    color: #afbcca;
    font-size: 1.25rem;
  }

  .card.exit-rules .card-body > div {
    font-size: .875rem;
    line-height: 1.25rem;
    color: rgb(96, 103, 110);
    margin-bottom: 1rem;
    cursor: pointer;
  }

  .card.exit-rules .card-body > div .icon {
    margin-right: .5rem;
  }

  .card.exit-rules .card-body > div a {
    font-size: .75rem;
    font-style: italic;
    text-decoration: underline;
    color: rgb(139, 155, 172);
  }

  .card.exit-rules .card-body .options-inner {
    margin-bottom: 1.5rem;
    font-size: .875rem;
    line-height: 1.25rem;
    color: rgb(29, 54, 75);
  }

  .card.exit-rules .card-body .options-inner input {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .card.exit-rules.inactive {
    opacity: .4;
  }

  .card .card-body .heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: rgb(29, 54, 75);
    margin-bottom: .5rem;
  }

  .card .card-body .heading img {
    width: 1rem;
    height: 1rem;
    display: block;
    margin-right: 1rem;
  }

  .card .card-body .inner {
    padding-left: 2rem;
    font-size: .75rem;
    line-height: 1.85;
    color: #00152a;
  }

  .section-title {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: rgb(255, 255, 255);
    border: solid 1px rgb(217, 231, 240);
    border-bottom: none;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .section-title h1 {
    font-size: 1rem;
    line-height: 1.5;
    color: rgb(75, 76, 80);
  }

  .campaign-items {
    margin-bottom: 2rem;
  }

  .campaign-items .campaign-item {
    cursor: pointer;
    padding: 1rem;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border: solid 1px #d9e7f0;
    border-bottom-color: transparent;
    transition: all 150ms ease-in-out;
  }

  .campaign-items .campaign-item:last-child {
    border-bottom-color: #d9e7f0;
  }

  .campaign-items .campaign-item:hover {
    border: 1px solid var(--xpl-color-secondary-lm);
    // box-shadow: 0 0 5px 0 rgba(0,0,0,0.10);
  }

  .campaign-items .campaign-item .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .campaign-items .campaign-item .header .material-icons {
    font-size: 1rem;
    color: rgb(217, 231, 240);
    margin-right: .625rem;
  }

  .campaign-items .campaign-item .header .status {
    margin-left: .75rem;
    font-size: .75rem;
    font-weight: bold;
  }

  .campaign-items .campaign-item .header .status.active {
    color: rgb(116, 221, 144);
  }

  .campaign-items .campaign-item .header .status.paused {
    color: rgb(139, 155, 172);
  }

  .campaign-items .campaign-item .header .status.draft {
    color: rgb(50, 162, 237);
  }

/*  .campaign-items .campaign-item .rule-bg {
    margin-top: .5rem;
  }
*/
  .campaign-items .campaign-item .metrics {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .campaign-items .campaign-item .metrics .metric {
    font-size: .75rem;
    line-height: 1.5;
    letter-spacing: 0.2px;
    color: rgb(118, 130, 141);
    margin-right: .5rem;
  }

  .campaign-items .campaign-item .metrics .metric .number {
    display: block;
    margin-bottom: .25rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.2px;
    color: rgb(46, 68, 87);
  }

  .campaign-items .campaign-item img {
    flex-shrink: 0;
    flex-grow: 0;
    width: 2rem;
    height: 2rem;
    margin-right: 1.5rem;
  }

  .campaign-items .campaign-item h1 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: rgb(76, 79, 82);
    margin-bottom: .25rem;
  }

  .card.paused,
  .card.started {
    // box-shadow: 0 2px 4px 0 rgba(217, 231, 240, 0.5);
    border: none;
  }

  .card.paused .card-header {
    padding: 0;
    border: none;
    background-image: linear-gradient(to right, rgb(187, 211, 242), rgb(169, 203, 224));
    height: 0.6875rem;
  }

  .card.started .card-header {
    padding: 0;
    border: none;
    background-image: linear-gradient(to right, rgb(70, 232, 136), rgb(94, 246, 188));
    height: 0.6875rem;
  }

  .card.paused .card-body,
  .card.started .card-body {
    border: solid 1px rgb(217, 231, 240);
    border-top: none;
    padding-top: 1rem;
    padding-left: 1.25rem;
    padding-bottom: 1rem;
    padding-right: 1.25rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .card.paused .card-body h1,
  .card.started .card-body h1 {
    font-size: 1rem;
    line-height: 1.5;
    color: rgb(29, 54, 75);
  }

  .card.paused .card-body .btn,
  .card.started .card-body .btn {
    margin-left: auto;
  }

  .card.paused .card-body img,
  .card.started .card-body img {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 1.25rem;
  }

  .stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .stats .stat {
    flex: calc(100% / 6 - 1rem);
    flex-shrink: 0;
    flex-grow: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    min-width: 6.25rem;
    padding: .75rem;
    padding-top: 1rem;
    border-radius: 6px;
    background-color: #ffffff;
    border: solid 1px #d6e0e8;
    margin-right: .5rem;
    transition: all 150ms ease-in-out;
  }

  .stats .stat:hover,
  .stats .stat.show {
    border-color: var(--xpl-color-secondary-lm);
    background-color: var(--xpl-color-secondary-bg-lm);
  }

  .stats .stat.show h6 {
    color: var(--xpl-color-secondary-lm);
  }

  .stats .stat:last-child {
    margin-right: 0;
  }

  .stats .stat h6 {
    text-align: center;
    font-size: .625rem;
    font-weight: 800;
    letter-spacing: 0.8px;
    color: #8b9bac;
    margin-bottom: .375rem;
    text-transform: uppercase;
  }

  .stats .stat .number {
    text-align: center;
    font-size: 1.25rem;
  }

  .stats .stat .number.in-campaign {
    color: var(--xpl-color-secondary-lm);
  }
  .stats .stat .number.history {
    color: rgb(92, 106, 196);
  }
  .stats .stat .number.goal {
    color: rgb(92, 106, 196);
  }

  .stats .stat .sub-number {
    margin-top: .375rem;
    font-size: .75rem;
    color: #212b36;
    text-align: center;
  }

  .section-title .dropdown-menu-right {
    left: -10rem;
  }

  .section-title .dropdown-item {
    display: flex;
    align-items: center;
    transition: none;
  }

  .section-title .dropdown-item:hover {
    background-color: white;
    color: white;
    background: linear-gradient(45deg, #3ab8ff 0%, #00cfff 100%);
  }

  .section-title .dropdown-item .material-icons {
    color: #cdd7e2;
    font-size: 1.5rem;
    margin-right: 1rem;
  }

  .section-title .dropdown-item:hover .material-icons {
    color: white;
  }

  .card.settings .card-body {
    border-top: solid 1px rgb(217, 231, 240);
    font-size: 1rem;
    color: rgb(97, 112, 128);
  }

  .card.settings .card-body .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.3px;
    color: #212b36;
  }

  .card.settings .card-body .title button {
    flex-shrink: 0;
    flex-grow: 0;
    opacity: .75;
  }

  .card.settings .inner {
    font-size: .875rem;
  }

  .exit-rule {
    font-size: .875rem;
    color: rgb(65, 67, 68);
    font-weight: 700;
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .campaign-items-container .card {
    margin-bottom: 1.5rem;
  }

  .inner.goals .rule-bg {
    padding: 1rem;
    width: 100% !important;
    background-color: rgb(243, 247, 249);
    font-size: .825rem;
    line-height: 1.85;
    color: rgb(46, 68, 87);
  }
    .add-contacts {
      margin: 0 0 0 2rem;
    }
    .contact-header {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    color: rgb(29, 54, 75);
  }
  .contact-subdued-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #617080;
    margin: 4px 0 16px 0;
    display: block;
  }
  .grey-filter {
    filter: invert(93%) sepia(26%) saturate(547%) hue-rotate(175deg) brightness(93%) contrast(89%);
    margin-right: 1rem;
  }

</style>

<style lang="scss" scoped>
@import "src/scss/global/_variables.scss";

.el-button.btn.btn-bb-success.pop.el-button--default {
  min-width: 5rem !important;
  background: #00dd8c !important;
  border: 1px solid #00dd8c !important;
  border-radius: 3px !important;
  cursor: pointer !important;
  color: $white-color;
  font-family: 'Muli', sans-serif;
  font-size: .875rem !important;
  font-weight: 500 !important;
  line-height: 1.43 !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  outline: none !important;
  text-align: center !important;
  text-decoration: none !important;
  transition: 0s !important;

  &:hover {
    background: transparent !important;
    background-image: linear-gradient(to right, rgb(70, 232, 136), rgb(94, 246, 188)) !important;
    border-color: transparent !important;
    color: $white-color !important;
    text-decoration: none !important;
  }
}

.campaign-pop-text {
  font-weight: 700;
  font-size: .875rem;
  line-height: 1rem;
  font-family: 'Muli', sans-serif;
  height: 100vh;
  padding: 15px;
}

.confirm-pop {
  min-width: 5rem;
  background: var(--xpl-color-secondary-lm);
  border: 1px solid var(--xpl-color-secondary-lm);
  border-radius: 5rem;
  cursor: pointer;
  color: #fff;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.43;
  padding: 6px 1rem;
  outline: none;
  text-align: center;
  text-decoration: none;
  margin-left: 10px;

  &:hover {
    background: linear-gradient(45deg, #3ab8ff 0%, #00cfff 100%);
    background: -webkit-linear-gradient(45deg, #3ab8ff 0%, #00cfff 100%);
    border-color: transparent;
    color: $white-color;
    text-decoration: none;
  }
}

.cancel {
margin: left ;

}

.pop-footer {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  background-color: #f3f7f9;
  font-size: .875rem;
  border-radius: 0 0 3px 3px;
  padding: 15px;
  border-top: 1px solid #e9ecef;
}

.padding-pop  {
  display: block;
  padding: 15px;
}

.popper-text {
  margin-bottom: .5rem;
  word-break: normal;
  text-align: left;
}

.campaign-name-container {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.small-save {
  padding: 4px 10px 4px 10px;
  height: 24px;
}

.edit-pencil-icon {
  cursor: pointer;
    margin-left: 6px;
    position: relative;
    bottom: 5px;
}
.edit-border {
  outline: none !important;
  border-radius: 6px;
  border: solid 1px #6923f4 !important;
  padding: 4px;
  margin-bottom: 5px;
}


</style>
