<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner">
      Event Qualifier
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="interacted == null || !interacted" @click="interacted = true"></span>
            <span class="icon icon-radio-checked" v-if="interacted" @click="interacted = true"></span>
          </div>
          <div class="col-xs-10">
            <label @click="interacted = true">Has interacted with an email</label>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="interacted === null || interacted" @click="interacted = false"></span>
            <span class="icon icon-radio-checked" v-if="!interacted && interacted !== null" @click="interacted = false"></span>
          </div>
          <div class="col-xs-10">
            <label @click="interacted = false">Has not interacted with an email</label>
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Event Qualifier
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="action !== 'sent'" @click="action = 'sent'"></span>
            <span class="icon icon-radio-checked" v-if="action === 'sent'" @click="action = 'sent'"></span>
          </div>
          <div class="col-xs-10">
            <label @click="action = 'sent'">Sent</label>
            <div class="small text-muted">Isolates contacts who have been sent specific emails</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="action !== 'open'" @click="action = 'open'"></span>
            <span class="icon icon-radio-checked" v-if="action === 'open'" @click="action = 'open'"></span>
          </div>
          <div class="col-xs-10">
            <label @click="action = 'open'">Opened</label>
            <div class="small text-muted">Isolates contacts who have opened emails</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="action !== 'click'" @click="action = 'click'"></span>
            <span class="icon icon-radio-checked" v-if="action === 'click'" @click="action = 'click'"></span>
          </div>
          <div class="col-xs-10">
            <label @click="action = 'click'">Clicked on a link</label>
            <div class="small text-muted">Isolates contacts who have clicked at least once on emails</div>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="action === 'click'">
        <label for="link">Exact URL</label>
        <input type="text" id="link" v-model.trim="link" placeholder="Link" class="form-control" />
        <div class="small text-muted">Please make sure the URL is correct for the condition to function properly</div>
      </div>
    </form>

    <div class="condition-banner">
      Email Details
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="filtering !== 'all'" @click="filtering = 'all'"></span>
            <span class="icon icon-radio-checked" v-if="filtering === 'all'" @click="filtering = 'all'"></span>
          </div>
          <div class="col-xs-10">
            <label @click="filtering = 'all'">Any email</label>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="filtering !== 'specific'" @click="filtering = 'specific'"></span>
            <span class="icon icon-radio-checked" v-if="filtering === 'specific'" @click="filtering = 'specific'"></span>
          </div>
          <div class="col-xs-10">
            <label @click="filtering = 'specific'">Specific email(s) sent in the past</label>
            <div class="small text-muted">Filters this condition to specific Scheduled Emails or Campaign Items emails.</div>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="filtering === 'specific'">
        <div class="form-group">
          <label>Email Name</label>
          <div class="multiselect-block">
            <Multiselect v-model="selected_emails" placeholder="Select emails" :options="past_emails" label="name" track-by="uuid" :searchable="true" :allow-empty="false" :show-labels="false" :multiple="true" :close-on-select="false">
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <div class="option__title">{{ props.option.name }}</div>
                  <span class="option__small text-muted small" v-if="props.option.type === 'scheduled_email'">Scheduled Email &middot; {{ props.option.scheduled_at | humanDateTime }}</span>
                  <span class="option__small text-muted small" v-if="props.option.type === 'campaign_item'">Campaign &middot; {{ props.option.campaign_name }}</span>
                </div>
              </template>
            </Multiselect>
          </div>
        </div>
      </div>
    </form>


    <div class="form-group">
      <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition" :disabled="!action || filtering === null">Save this condition</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['condition', 'editedRule', 'enrollment'],
    components: {
      Multiselect,
    },
    data() {
      return {
        link: '',
        interacted: true,
        action: null,
        filtering: null,
        selected_emails: [],
        past_emails: [],
      };
    },
    created() {
      this.fetchPastEmails();

      if (this.editedRule) {
        this.interacted = this.editedRule.interacted;
        this.action = this.editedRule.action;
        this.filtering = this.editedRule.filtering;
        this.selected_emails = this.editedRule.emails;
        this.link = this.editedRule.link;
      }
    },
    methods: {
      fetchPastEmails() {
        api.getPastSentEmails().then((response) => {
          this.past_emails = response.past_emails;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          interacted: this.interacted,
          action: this.action,
          filtering: this.filtering,
          emails: this.selected_emails,
          link: this.link,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar-inner {
    padding-bottom: 5rem;
    min-height: 70vh;
  }

  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }
</style>
