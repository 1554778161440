<template>
  <el-container class="main-cont">
    <el-row :gutter="24" class="heading">
      <el-col :span="12">
        <div class="display-medium">Your Class Schedule</div>
      </el-col>
      <el-col
        :span="8"
        :offset="4"
        style="display: flex; justify-content: flex-end"
      >
        <el-button
          type="primary"
          @click="refreshClassSchedule"
          :loading="loadingSchedule"
          :disabled="notificationToggleStatusLoading"
          >Refresh Schedule</el-button
        >
      </el-col>
    </el-row>
    <el-row :span="24" class="top-banner">
      <el-col :span="24">
        <el-card :class="[settings.enable ? '' : 'inactive']">
          <div class="inner">
            <span v-if="settings.enable"
              >This account will be sending automated reminders</span
            >
            <span v-if="!settings.enable"
              >This account WILL NOT be sending automated reminders</span
            >
            <el-button
              type="secondary"
              size="small"
              @click="changeEnable"
              :loading="loadingEnable"
              :disabled="notificationToggleStatusLoading"
              v-if="settings.enable"
              >Turn Off for My Account</el-button
            >
            <el-button
              type="secondary"
              size="small"
              @click="changeEnable"
              :loading="loadingEnable"
              v-if="!settings.enable"
              :disabled="notificationToggleStatusLoading"
              >Turn On for My Account</el-button
            >
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div class="schedule">
      <div>
        <a
          href="#"
          :class="[next_week ? '' : 'active']"
          @click.stop="next_week = false"
          >This Week</a
        >
        <a
          href="#"
          :class="[next_week ? 'active' : '']"
          @click.stop="next_week = true"
          >Next Week</a
        >
      </div>
      <el-switch
        v-model="notificationToggleStatus"
        @change="changeAllClassesStatus"
        active-text="Exclude All Classes"
        class="notifications-switch"
        :disabled="notificationToggleStatusLoading"
      >
      </el-switch>
    </div>
    <el-main v-loading="notificationToggleStatusLoading">
      <div class="classes">
      <div v-for="day in filterWeek">
        <div class="day">{{ day.day | humanDateMin(false) }}</div>
        <el-card
          v-for="classe in day.classes"
          :key="classe.id"
          v-loading="classe.loading"
        >
          <el-row>
            <el-col :span="3">
              <div v-if="classe.active" class="sending-active">
                <span class="material-icons">play_circle_outline</span>
                Virtual
              </div>
              <div v-if="!classe.active" class="sending-inactive">
                <span class="material-icons">block</span>
                Exclude
              </div>
              <div class="text-center" style="margin-top: 1rem">
                <el-switch
                  @change="changeStatusClass(classe)"
                  v-model="classe.active"
                  :active-value="1"
                  :inactive-value="0"
                  :disabled="notificationToggleStatusLoading"
                />
              </div>
            </el-col>
            <el-col :span="17">
              <el-row :span="24">
                <div class="class-name">{{ classe.class_name }}</div>
              </el-row>
              <el-row :span="24">
                <el-col :span="8">
                  <div class="instructor">
                    <span class="material-icons">account_box</span>
                    {{ classe.instructor_first_name }}
                    {{ classe.instructor_last_name }}
                  </div>
                  <div class="start-time">
                    <span class="material-icons">query_builder</span>
                    {{ classe.start_time | humanTime }}
                  </div>
                  <div class="location" v-if="classe.location_name">
                    <span class="material-icons">place</span>
                    {{ classe.location_name }}
                  </div>
                </el-col>
                <el-col :span="10">
                  <div
                    :class="[
                      'zoom-link',
                      classe.virtual_link ? 'active' : 'inactive',
                    ]"
                  >
                    <span class="material-icons">videocam</span>
                    <span v-if="classe.virtual_link">{{
                      classe.virtual_link
                    }}</span>
                    <span v-if="!classe.virtual_link">No Virtual Link</span>
                  </div>
                  <div
                    :class="[
                      'playlist-link',
                      classe.playlist_link ? 'active' : 'inactive',
                    ]"
                  >
                    <span class="material-icons">queue_music</span>
                    <span v-if="classe.playlist_link">{{
                      classe.playlist_link
                    }}</span>
                    <span v-if="!classe.playlist_link"
                      >No Playlist Link (Optional)</span
                    >
                  </div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="4">
              <el-button
                type="secondary"
                size="small"
                class="pull-right"
                @click="viewClass(classe.uuid)"
                >View</el-button
              >
            </el-col>
          </el-row>
        </el-card>
      </div>
    </div>
    </el-main>

  </el-container>
</template>
<script>
  import moment from 'moment';
  import {
    getClassSchedule,
    refreshSchedule,
    updateClassRemindersSettings,
    getClassReminderSettings,
    updateClassDetail } from '@/api';

  export default {
    data() {
      return {
        loadingEnable: false,
        loadingSchedule: false,
        next_week: false,
        allNotificationsOff: null,
        notificationToggleStatus: null,
        notificationToggleStatusLoading: false,
        schedule: [],
        settings: {
          enable: false,
        },
      };
    },
    created() {
      this.fetchClassSchedule();
      this.fetchClassReminderSettings();
      const classList = this.schedule.map(dayOfClasses => dayOfClasses.classes).flat();
      const onCount = classList.filter(c => c.active).length;
      const offCount = classList.filter(c => !c.active).length;
      if (onCount === 0) {
        this.notificationToggleStatus = true;
      } else if (offCount === 0) {
        this.notificationToggleStatus = false;
      } else if (offCount !== 0 && onCount !== 0) {
        this.notificationToggleStatus = true;
      }
    },
    watch: {
      schedule(newVal) {
        const classList = newVal.map(dayOfClasses => dayOfClasses.classes).flat();
        const onCount = classList.filter(c => c.active).length;
        const offCount = classList.filter(c => !c.active).length;
        if (onCount === 0) {
          this.notificationToggleStatus = true;
        } else if (offCount === 0) {
          this.notificationToggleStatus = false;
        } else if (offCount !== 0 && onCount !== 0) {
          this.notificationToggleStatus = true;
        }
      },
    },
    filters: {
      humanTime(datetime) {
        return moment(datetime).format('h:mma');
      },
    },
    computed: {
      filterWeek() {
        if (this.next_week) {
          return this.nextWeek;
        } return this.thisWeek;
      },
      thisWeek() {
        const now = moment.tz(this.$store.state.user.current_account.timezone);
        const schedule = this.schedule
        .filter(s => moment.tz(s.day, this.$store.getters.currentTimezone).isSame(now, 'week'));
        return schedule;
      },
      nextWeek() {
        const nextWeek = moment.tz(this.$store.getters.currentTimezone).add(1, 'weeks');
        return this.schedule.filter(s => moment.tz(s.day, this.$store.getters.currentTimezone)
        .isSame(nextWeek, 'week'));
      },
    },
    methods: {
      async changeAllClassesStatus() {
        const activeStatus = this.notificationToggleStatus ? 0 : 1;
        const payload = { active: activeStatus };
        const classList = this.schedule.map(dayOfClasses => dayOfClasses.classes).flat();
        this.notificationToggleStatusLoading = true;
        try {
          for (const clazz of classList) { //eslint-disable-line
            await updateClassDetail(clazz.uuid, payload); //eslint-disable-line
          }
          await this.fetchClassSchedule();
          if (activeStatus === 1) {
            this.$message.success('Success: Include All Classes');
          } else {
            this.$message.success('Success: Exclude All Classes');
          } this.notificationToggleStatusLoading = false;
        } catch (error) {
          this.$message.error(error.message);
        }
      },
      async changeStatusClass(classe) {
        this.$set(classe, 'loading', true);
        const payload = {
          active: classe.active,
        };
        try {
          const response = await updateClassDetail(classe.uuid, payload);
          this.$set(classe, 'loading', false);
          this.$message.success(response);
        } catch (err) {
          this.$set(classe, 'loading', false);
          this.$message.error(err.message);
        }
      },
      async fetchClassReminderSettings() {
        try {
          const response = await getClassReminderSettings();
          this.settings = response.settings;
        } catch (err) {
          this.$message.error(err.message);
        }
      },
      changeEnable() {
        this.loadingEnable = true;
        const payload = {
          enable: !this.settings.enable,
        };
        updateClassRemindersSettings(payload).then((response) => {
          this.loadingEnable = false;
          this.$message.success(response.message);
          this.fetchClassReminderSettings();
        }).catch((err) => {
          this.loadingEnable = false;
          this.$message.error(err.message);
        });
      },
      async fetchClassSchedule() {
        this.loadingSchedule = true;
        try {
          const response = await getClassSchedule();
          this.loadingSchedule = false;
          this.schedule = response.schedule;
        } catch (err) {
          this.loadingSchedule = false;
          this.$message.error(err.message);
        }
      },
      refreshClassSchedule() {
        this.loadingSchedule = true;
        refreshSchedule().then((response) => {
          this.$message.success(response.message);
          this.fetchClassSchedule();
        }).catch((err) => {
          this.loadingSchedule = false;
          this.$message.error(err.message);
        });
      },
      viewClass(classUuid) {
        this.$router.push(`/apps/class-reminders/${classUuid}`);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .top-banner {
    margin-top: 2rem;
    margin-bottom: .625rem;
  }

  .top-banner .inactive {
    background-color: #f2bebe;
  }

  .top-banner .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    color: #00152a;
  }

  .schedule {
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;
  }

  .schedule a {
    font-size: 0.875rem;
    color: #5e7082;
    transition: all 150ms ease-in-out;
  }

  .schedule a:first-child {
    margin-right: 1rem;
  }

  .schedule a.active,
  .schedule a:hover {
    font-weight: bold;
    color: #6923f4;
    padding-bottom: 1rem;
    border-bottom: 4px solid #6923f4;
    text-decoration: none;
  }

  .classes {
    margin-top: 3rem;
  }

  .classes .el-card {
    margin-top: .75rem;
  }

  .day {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: 700;
  }

  .class-name {
    font-size: 1.25rem;
    line-height: 1.4;
    letter-spacing: 0.15px;
    color: #172333;
    margin-bottom: 10px;
  }

  .instructor {
    font-size: .875rem;
    font-weight: 700;
    color: #17202c;
    display: flex;
    align-items: center;
  }

  .instructor .material-icons {
    font-size: .875rem;
    margin-right: .625rem;
  }

  .start-time {
    font-size: .875rem;
    font-weight: 700;
    color: #17202c;
    display: flex;
    align-items: center;
  }

  .start-time .material-icons {
    font-size: .875rem;
    margin-right: .625rem;
  }

  .location {
    font-size: .875rem;
    font-weight: 700;
    color: #17202c;
    display: flex;
    align-items: center;
  }

  .location .material-icons {
    font-size: .875rem;
    margin-right: .625rem;
  }

  .el-card .sending-active {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #6923f4;
    font-size: .875rem;
    font-weight: bold;
  }
  .el-card .sending-active .material-icons {
    font-size: 1.25rem;
  }

  .el-card .sending-inactive {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #d62929;
    font-size: .875rem;
    font-weight: bold;
  }
  .el-card .sending-inactive .material-icons {
    font-size: 1.25rem;
  }

  .el-card .zoom-link {
    font-size: .875rem;
    display: flex;
    align-items: center;
  }
  .el-card .zoom-link.active {
    color: #17202c;
  }
  .el-card .zoom-link.inactive {
    color: #ff931f;
  }
  .el-card .zoom-link .material-icons {
    font-size: .875rem;
    margin-right: .625rem;
  }

  .el-card .playlist-link {
    font-size: .875rem;
    display: flex;
    align-items: center
  }
  .el-card .playlist-link.active {
    color: #17202c;
  }
  .el-card .playlist-link.inactive {
    color: #a6b2bf;
  }
  .el-card .playlist-link .material-icons {
    font-size: .875rem;
    margin-right: .625rem;
  }
  .main-cont {
    display: block;
  }
  .notifications-switch {
    /deep/ span {
      color: #5e7082;
    }
  }
  /deep/ .el-loading-spinner {
    top: 25%;
  }

</style>
