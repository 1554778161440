<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div data-test-main-header class="v2__heading">Hosted Page Setup</div>
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span data-test-close-modal class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <div data-test-info-header style="margin-bottom: 1rem;">We need some information about your account before you can proceed</div>
            <el-form ref="details" :rules="rules" :model="setup" status-icon>
              <el-form-item prop="url">
                <label data-test-url-label for="url">Website URL</label>
                <el-input data-test-url-input type="text" id="url" v-model.trim="setup.url" placeholder="URL" />
              </el-form-item>
              <el-form-item prop="subdomain">
                <label data-test-subdomain-label for="subdomain">Subdomain</label>
                <input class="domain-input" data-test-subdomain-input type="text" id="subdomain" v-model.trim="setup.subdomain" placeholder="Subdomain" @input="setup.subdomain=$event.target.value.toLowerCase()"/>
                <div data-test-subdomain-explain-text class="display-caption subdued">It is common to use your own website domain for this. <br>Your subdomain will be used to create your Hosted Pages URLs. Your URLs will be in the format of <b>https://{{ setup.subdomain ? setup.subdomain : 'subdomain' }}.brandbot.io</b>. This is unique, lowercased and not editable once set.</div>
              </el-form-item>
              <el-form-item>
                <el-button data-test-save-button type="primary" class="xpl-button xpl-button--primary" @click.stop="saveDetails" :loading="loading">Save</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import * as api from '@/api';

  export default {
    props: ['account'],
    data() {
      const checkVanity = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('A valid subdomain is required for your Landing Pages.'));
        }

        if (!value.match(/^[0-9a-zA-Z-_]+$/)) {
          return callback(new Error('Only alphanumeric characters, "-" and "_" are allowed.'));
        }

        return callback();
      };
      const checkUrl = (rule, value, callback) => {
        if (value &&
          !value.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm)) {
          return callback(new Error('We need a valid url for your website.'));
        }

        return callback();
      };
      return {
        loading: false,
        setup: {
          subdomain: JSON.parse(this.account.metadata).subdomain,
          url: this.account.website_url,
        },
        rules: {
          subdomain: [{ validator: checkVanity, trigger: 'change' }],
          url: [{ validator: checkUrl, trigger: 'change' }],
        },
      };
    },
    methods: {
      closeModal() {
        this.$router.push('/apps');
      },
      saveDetails() {
        this.$refs.details.validate((valid) => {
          if (!valid) {
            return;
          }

          this.loading = true;

          const payload = {
            website_url: this.setup.url,
            subdomain: this.setup.subdomain,
          };

          api.updateAccount(this.account.id, payload).then((response) => {
            this.loading = false;
            this.$message.success(response.message);
            location.reload();
          }).catch((err) => {
            this.loading = false;
            this.$message.error(err.message);
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal .modal-body {
    max-height: 90vh;
    overflow-y: scroll;
  }

  .display-caption {
    line-height: 1.25rem;
  }

  .domain-input {
    border-radius: 6px;
    -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;

    &:hover {
      border-color: #C0C4CC;
    }

    &:focus {
      outline: none;
      border-color: #6923f4;
    }
  }

</style>
