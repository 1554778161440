<template>
  <div class="datatable">
    <form class="filters" @submit.prevent>
      <div class="input-group">
        <span class="input-group-addon">
          <span class="icon icon-search"></span>
        </span>
        <input type="search" name="query" v-model="query" class="form-control" placeholder="Search" />
      </div>
      <div v-if="selectedContacts.length">
        <div class="dropdown" @click="funnel_management_dropdown = !funnel_management_dropdown; subscription_dropdown = false; tag_management_dropdown = false;" v-if="$store.state.user.current_account.funnel_enabled">
          <button type="button" class="xpl-button xpl-button--primary">Funnel Management</button>
          <div :class="['dropdown-menu', funnel_management_dropdown ? 'show' : '']">
            <a href="#" @click="funnel_management_modal = 'add'" class="dropdown-item">Add to Funnel Stage ({{ !select_all_real ? selectedContacts.length : count | prettyNumber }})</a>
            <a href="#" @click="funnel_management_modal = 'remove'" class="dropdown-item">Remove from Funnel Stage ({{ !select_all_real ? selectedContacts.length : count | prettyNumber }})</a>
          </div>
        </div>
        <div class="dropdown" @click="tag_management_dropdown = !tag_management_dropdown; subscription_dropdown = false; funnel_management_dropdown = false;">
          <button type="button" class="xpl-button xpl-button--primary">Tag Management</button>
          <div :class="['dropdown-menu', tag_management_dropdown ? 'show' : '']">
            <a href="#" @click="tag_management_modal = 'tag'" class="dropdown-item">Tag Selected ({{ !select_all_real ? selectedContacts.length : count | prettyNumber }})</a>
            <a href="#" @click="tag_management_modal = 'untag'" class="dropdown-item">Untag Selected ({{ !select_all_real ? selectedContacts.length : count | prettyNumber }})</a>
          </div>
        </div>
        <div class="dropdown" @click="subscription_dropdown = !subscription_dropdown; tag_management_dropdown = false; funnel_management_dropdown = false;" v-if="!select_all_real">
          <button type="button" class="xpl-button xpl-button--secondary">Subscription Management</button>
          <div :class="['dropdown-menu', subscription_dropdown ? 'show' : '']">
            <a href="#" class="dropdown-item" @click.stop="unsubscribeContacts">Unsubscribe Selected ({{ selectedContacts.length }})</a>
            <a href="#" class="dropdown-item" @click.stop="subscribeContacts">Subscribe Selected ({{ selectedContacts.length }})</a>
          </div>
        </div>
        <a href="#" class="text-danger" @click.stop="removeContacts" v-if="!select_all_real">Delete Selected ({{ selectedContacts.length }})</a>
      </div>
      <div class="xpl-buttons" v-else>
        <button type="button" class="xpl-button xpl-button--secondary" @click="bulkUnsubscribe">Unsubscribe Contacts</button>
        <button type="button" class="xpl-button xpl-button--primary" @click="addNewContact">Add Contacts</button>
      </div>
    </form>

    <div v-if="contacts.data">
      <table class="table bb-datatable contacts">
        <thead>
          <tr>
            <td>
              <i class="icon icon-checkbox-unchecked2" v-if="!select_all" @click="selectAll"></i>
              <i class="icon icon-checkbox-checked2" v-if="select_all" @click="selectAll"></i>
            </td>
            <td @click="sort('email')">
              Email
              <i class="fa fa-caret-up" v-if="sorting.email === 'asc'"></i>
              <i class="fa fa-caret-down" v-if="sorting.email === 'desc'"></i>
            </td>
            <td @click="sort('fullname')">
              Name
              <i class="fa fa-caret-up" v-if="sorting.fullname === 'asc'"></i>
              <i class="fa fa-caret-down" v-if="sorting.fullname === 'desc'"></i>
            </td>
            <td @click="sort('source')">
              Source
              <i class="fa fa-caret-up" v-if="sorting.source === 'asc'"></i>
              <i class="fa fa-caret-down" v-if="sorting.source === 'desc'"></i>
            </td>
            <td @click="sort('created_at')">
              Date Added
              <i class="fa fa-caret-up" v-if="sorting.created_at === 'asc'"></i>
              <i class="fa fa-caret-down" v-if="sorting.created_at === 'desc'"></i>
            </td>
            <td @click="sort('contact_method')">
              SMS Subscription
              <i class="fa fa-caret-up" v-if="sorting.contact_method === 'asc'"></i>
              <i class="fa fa-caret-down" v-if="sorting.contact_method === 'desc'"></i>
            </td>
            <td @click="sort('contact_method')">
              Email Subscription
              <i class="fa fa-caret-up" v-if="sorting.contact_method === 'asc'"></i>
              <i class="fa fa-caret-down" v-if="sorting.contact_method === 'desc'"></i>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="select-all" v-if="select_all">
            <td colspan="7">
              <span v-if="!select_all_real"><a href="#" @click.prevent="select_all_real = true">Click here to select all contacts</a></span>
              <span v-if="select_all_real">You have selected {{ count | prettyNumber }} contacts. <a href="#" @click.prevent="select_all_real = false; select_all = false;">Unselect</a></span>
            </td>
          </tr>
          <tr v-for="contact in contacts.data" :class="[ contact.selected ? 'selected' : '' ]" :key="contact.id">
            <td @click.stop="toggleContact(contact)">
              <i class="icon icon-checkbox-unchecked2" v-if="!contact.selected"></i>
              <i class="icon icon-checkbox-checked2" v-if="contact.selected"></i>
            </td>
            <td>
              <router-link :to="'/contacts/' + contact.uuid">{{ contact.email | maskEmail(contact.fullname) }}</router-link>
            </td>
            <td>{{ contact.fullname }}</td>
            <td>{{ contact.source | contactSource }}</td>
            <td>{{ contact.created_at | humanDate }}</td>
            <td class="text-capitalize text-muted">{{ contact | smsMethod }}</td>
            <td class="text-right text-capitalize text-muted">
              {{ contact | contactMethod }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pages">
        <div class="pages-flip">
          <div class="page-left" @click="previousPage()">
            <i class="fa fa-arrow-left"></i>
          </div>
          <div class="page-right" @click="nextPage()">
            <i class="fa fa-arrow-right"></i>
          </div>
        </div>
        Page {{ contacts.current_page }} of {{ contacts.last_page }}
      </div>
      <MassTagModal v-if="tag_management_modal" :action="tag_management_modal" :contacts="selectedContacts" :allContacts="select_all_real" />
      <MassFunnelModal v-if="funnel_management_modal" :action="funnel_management_modal" :contacts="selectedContacts" :allContacts="select_all_real" />
    </div>
    <div v-else class="text-center">
      <i class="fa fa-spin fa-spinner fa-fw"></i>
    </div>
  </div>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import _ from 'lodash';
  import MassTagModal from './MassTagModal';
  import MassFunnelModal from './MassFunnelModal';

  export default {
    props: ['filter', 'count'],
    components: {
      MassTagModal,
      MassFunnelModal,
    },
    data() {
      return {
        tag_management_modal: false,
        tag_management_dropdown: false,
        subscription_dropdown: false,
        funnel_management_dropdown: false,
        funnel_management_modal: false,
        query: '',
        select_all: false,
        select_all_real: false,
        sorting: {
          email: 'asc',
          fullname: 'asc',
          source: 'asc',
          created_at: 'desc',
          contact_method: 'asc',
        },
        sortPageFilterVal: '',
        contacts: {},
      };
    },
    mounted() {
      this.fetchContacts(0, '');

      EventBus.$on('mass_tag_modal.close', () => {
        this.tag_management_modal = false;
        this.select_all_real = false;
        this.select_all = false;
      });

      EventBus.$on('mass_funnel_modal.close', () => {
        this.funnel_management_modal = false;
        this.tag_management_dropdown = false;
        this.select_all_real = false;
        this.select_all = false;
      });
    },
    beforeDestroy() {
      EventBus.$off('mass_tag_modal.close');
      EventBus.$off('mass_funnel_modal.close');
    },
    watch: {
      query() {
        this.searchContacts();
      },
    },
    computed: {
      selectedContacts() {
        if (this.contacts.data) {
          return this.contacts.data.filter(c => c.selected === true);
        }

        return [];
      },
    },
    methods: {
      addNewContact() {
        EventBus.$emit('contacts.add_contacts');
      },
      bulkUnsubscribe() {
        EventBus.$emit('contacts.unsubscribe_contacts');
      },
      removeContacts() {
        // eslint-disable-next-line
        if (!confirm(`Are you sure you want to remove ${this.selectedContacts.length} contact(s)?`)) {
          return;
        }

        const payload = {
          contact_ids: this.selectedContacts.map(c => c.id).join(','),
        };

        api.removeContacts(payload).then((response) => {
          this.$toasted.success(response.message);
          this.fetchContacts();
          this.select_all = false;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      subscribeContacts() {
        // eslint-disable-next-line
        if (!confirm(`Are you sure you want to subscribe ${this.selectedContacts.length} contact(s)?`)) {
          return;
        }

        const payload = {
          contact_ids: this.selectedContacts.map(c => c.id).join(','),
        };

        api.subscribeContacts(payload).then((response) => {
          this.$toasted.success(response.message);
          this.fetchContacts();
          this.select_all = false;
          this.subscription_dropdown = false;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      unsubscribeContacts() {
        // eslint-disable-next-line
        if (!confirm(`Are you sure you want to unsubscribe ${this.selectedContacts.length} contact(s)?`)) {
          return;
        }

        const payload = {
          contact_ids: this.selectedContacts.map(c => c.id).join(','),
        };

        api.unsubscribeContacts(payload).then((response) => {
          this.$toasted.success(response.message);
          this.fetchContacts();
          this.select_all = false;
          this.subscription_dropdown = false;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      selectAll() {
        this.contacts.data.forEach((c) => {
          if (!Object.prototype.hasOwnProperty.call(c, 'selected')) {
            this.$set(c, 'selected', !this.select_all);
          } else {
            c.selected = !this.select_all;
          }
        });

        this.select_all = !this.select_all;
      },
      toggleContact(contact) {
        if (!Object.prototype.hasOwnProperty.call(contact, 'selected')) {
          this.$set(contact, 'selected', true);
          return;
        }
        contact.selected = !contact.selected;
        this.select_all_real = false;
        this.select_all = false;
      },
      fetchContacts(page, sorting = '', query = '') {
        api.getContacts(this.filter, page, sorting, query).then((response) => {
          this.$set(this, 'contacts', response.contacts);
        }).catch(() => {
          this.$toasted.error('Something wrong happened while fetching your contacts...');
        });
      },
      previousPage() {
        if (this.contacts.current_page === 1) {
          return;
        }

        if (!this.contacts.prev_page_url) {
          return;
        }
        this.sorting[this.sortPageFilterVal] = this.sorting[this.sortPageFilterVal] === 'asc' ? 'asc' : 'desc';
        const sorting = this.sortPageFilterVal === '' ? '' : `${this.sortPageFilterVal}-${this.sorting[this.sortPageFilterVal]}`;
        const page = this.contacts.prev_page_url.split('=')[1];
        this.fetchContacts(page, sorting, this.query);
      },
      nextPage() {
        if (this.contacts.current_page === this.contacts.last_page) {
          return;
        }
        if (!this.contacts.next_page_url) {
          return;
        }
        this.sorting[this.sortPageFilterVal] = this.sorting[this.sortPageFilterVal] === 'asc' ? 'asc' : 'desc';
        const sorting = this.sortPageFilterVal === '' ? '' : `${this.sortPageFilterVal}-${this.sorting[this.sortPageFilterVal]}`;
        const page = this.contacts.next_page_url.split('=')[1];
        this.fetchContacts(page, sorting, this.query);
      },
      sort(sortFilter) {
        this.sortPageFilterVal = sortFilter;
        if (this.sorting[sortFilter] === 'asc') {
          this.sorting[sortFilter] = 'desc';
        } else {
          this.sorting[sortFilter] = 'asc';
        }
        const sorting = `${sortFilter}-${this.sorting[sortFilter]}`;
        this.fetchContacts(0, sorting, this.query);
      },

      searchContacts: _.debounce(async function debounce() {
        try {
          const response = await api.searchContacts(this.filter, this.query);
          this.contacts = response.contacts;
        } catch (error) {
          this.$message.error(error.message);
        }
      }, 1000),
    },
    filters: {
      maskEmail(email, fullname) {
        if (email.endsWith('@pike13')) {
          return fullname;
        }

        return email;
      },
      contactSource(source) {
        if (source === 'mindbody') {
          return 'MINDBODY';
        }

        if (source === 'pike13') {
          return 'Pike13';
        }

        if (source === 'brandbot') {
          return 'Manual Upload';
        }

        if (source === 'sms') {
          return 'SMS';
        }

        if (source === 'marianatek') {
          return 'MarianaTek';
        }

        return source;
      },
      smsMethod(contact) {
        if (contact.unsubscribed_sms_at) {
          return 'Unsubscribed';
        }

        return 'Subscribed';
      },
      contactMethod(contact) {
        if (contact.unsubscribed_at) {
          return 'Unsubscribed';
        }

        return 'Subscribed';
      },
    },
  };
</script>
<style lang="scss" scoped>
  .icon {
    font-size: .875rem;
    width: .875rem;
  }

  a.text-danger {
    margin-right: .5rem;
  }

  tr.select-all {
    border: none !important;
  }

  tr.select-all td {
    font-size: .75rem !important;
    background-color: #fef6f4 !important;
    text-align: center;
    border: none !important;
  }

  tr.select-all td a {
    color: var(--xpl-color-secondary-lm) !important;
    text-decoration: underline;
  }

  .dropdown {
    display: inline-block;
  }
</style>
