<template>
  <div class="condition">
    <el-form>
      <el-form-item>
        Any contact after their first visit at your business with
        <el-select v-model="filter_plan" placeholder="">
            <el-option :key="false" :label="'Any plan'" :value="false"></el-option>
            <el-option :key="true" :label="'Specific plans'" :value="true"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="filter_plan">
        <el-select v-model="selected_plans" multiple filterable placeholder="Plan(s)" style="width: 100%;">
          <el-option v-for="plan in plans" :key="plan.name" :value="plan.name" :id="plan.name"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="commitCondition">Save</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['uuid', 'rule', 'campaignItem'],
    data() {
      return {
        filter_plan: false,
        plans: [],
        selected_plans: [],
      };
    },
    created() {
      this.fetchPlans();

      if (this.rule && this.rule.length && this.rule[0].plans) {
        this.selected_plans = this.rule[0].plans;

        if (this.selected_plans.length) {
          this.filter_plan = true;
        }
      }
    },
    methods: {
      fetchPlans() {
        api.getPersonPlans().then((response) => {
          this.plans = response.plans;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.uuid,
            quick: true,
          },
          time_comparison: 'all',
          relative_days: null,
          filter_plan: this.filter_plan,
          plans: this.selected_plans,
          types: [],
          categories: [],
        };
        EventBus.$emit('condition.commit', payload);
      },
    },
  };
</script>
