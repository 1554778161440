<template>
  <div>
    <div class="nav-subheader">
      <div class="nav-inner">
        <div>Account Saved PDFs</div>
        <div class="nav-pills">
          <div :class="[tabs.media ? 'active' : '', 'pill']" @click="$router.push('/account/assets')">Media</div>
          <div :class="[tabs.saved_links ? 'active' : '', 'pill']" @click="$router.push('/account/saved-links')">Saved Links</div>
          <div :class="[tabs.pdf ? 'active' : '', 'pill']" @click="$router.push('/account/saved-pdfs')">Saved PDFs</div>
          <div :class="[tabs.templates ? 'active' : '', 'pill']" @click="$router.push('/account/templates')">Templates</div>
          <div :class="[tabs.tags ? 'active' : '', 'pill']" @click="$router.push('/account/tags')">Tags</div>
        </div>
      </div>
    </div>

    <div class="saved-pdfs">
      <div class="heading">
        <h1>Your account Saved PDFs</h1>
        <el-upload :multiple="false" :limit="1" accept="application/pdf" action="" :http-request="uploadFile" :on-change="handleChange" :file-list="fileList">
          <el-button type="primary" class="xpl-button xpl-button--primary":loading="loading">Upload a PDF</el-button>
        </el-upload>
      </div>

      <el-card>
        <el-table :data="savedPdfsFiltered">
          <el-table-column label="Link">
            <template slot="header" slot-scope="scope">
              <el-input v-model="search" size="mini" placeholder="Search" />
            </template>
            <template slot-scope="scope">
              <div v-if="!scope.row.toggled">
                {{ scope.row.name }}
                <div class="small text-muted">
                  <a :href="scope.row.url" target="_blank">{{ scope.row.url }}</a>
                </div>
                <div class="small text-muted">{{ scope.row.created_at | humanDateTime }}</div>
              </div>
              <div v-if="scope.row.toggled">
                <el-input type="text" v-model="scope.row.name" placeholder="Name" />
              </div>
            </template>
          </el-table-column>
          <el-table-column align="right" label="Actions">
            <template slot-scope="scope">
              <div v-if="!scope.row.toggled">
                <el-button type="text" size="small" @click.stop="toggleRow(scope.row)">Edit</el-button>
                <el-button size="small" @click.stop="archiveAccountAsset(scope.row)">Archive</el-button>
              </div>
              <div v-if="scope.row.toggled">
                <el-button type="primary" class="xpl-button xpl-button--primary"size="small" @click.stop="updateAccountAsset(scope.row)">Save</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>
<script>
  import * as api from '@/api';

  export default {
    data() {
      return {
        loading: false,
        saved_pdfs: [],
        search: '',
        fileList: [],
        tabs: {
          media: false,
          saved_links: false,
          pdf: true,
          templates: false,
          tags: false,
        },
      };
    },
    created() {
      this.fetchPDFs();
    },
    computed: {
      savedPdfsFiltered() {
        if (this.search.length) {
          return this.saved_pdfs.filter(sp =>
            sp.name.toLowerCase().includes(this.search.toLowerCase()),
          );
        }

        return this.saved_pdfs;
      },
    },
    methods: {
      handleChange() {
        this.fileList = [];
      },
      uploadFile(payload) {
        const formData = new FormData();
        formData.append('pdf_file', payload.file, payload.file.name);
        formData.append('type', 'pdf');

        this.loading = true;
        api.uploadAssetImage(formData).then((response) => {
          this.$message.success(response.message);
          this.loading = false;
          this.fetchPDFs();
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      archiveAccountAsset(pdf) {
        this.$confirm('Are you sure you want to archive this Saved PDF?').then(() => {
          const payload = {
            type: 'pdf',
            pdf_id: pdf.id,
          };

          this.loading = true;
          api.deleteAccountAsset(payload).then((response) => {
            this.loading = false;
            this.$message.success(response.message);
            this.fetchPDFs();
          }).catch((err) => {
            this.loading = false;
            this.$message.error(err.message);
          });
        });
      },
      updateAccountAsset(pdf) {
        const payload = {
          name: pdf.name,
        };

        this.loading = true;
        api.updateAccountAsset(pdf.id, payload).then((response) => {
          this.loading = false;
          this.$message.success(response.message);
          this.toggleRow(pdf);
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      toggleRow(pdf) {
        if (!pdf.toggled) {
          this.$set(pdf, 'toggled', true);
        } else {
          pdf.toggled = !pdf.toggled;
        }
      },
      fetchPDFs() {
        api.getSavedPdfs().then((response) => {
          this.saved_pdfs = response.pdfs;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .nav-inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .saved-pdfs {
    padding-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 44.375rem;
  }

  .saved-pdfs .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.75rem;
  }

  .saved-pdfs .heading h1 {
    font-size: 1.25rem;
    color: rgb(46, 68, 87);
  }

  .el-upload-list {
    display: none !important;
  }
</style>
