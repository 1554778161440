<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Associate a Contact
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="input-group">
                <span class="input-group-addon">
                  <span class="icon icon-search"></span>
                </span>
                <input type="search" name="query" v-model.trim="query" class="form-control" placeholder="Start typing to search your contacts" />
              </div>
            </form>
            <div class="contacts" v-if="contacts">
              <div :class="{ 'contact': true, 'selected': associated_contact && associated_contact.id === contact.id }" v-for="contact in contacts.data" @click="associated_contact = contact">
                <div v-if="contact.fullname">
                  <div>{{ contact.fullname }}</div>
                  <div class="small text-muted">{{ contact.email }}</div>
                </div>
                <div v-if="!contact.fullname">
                  <div>{{ contact.email }}</div>
                </div>
              </div>
            </div>
            <div class="text-center m-t" v-if="contacts && contacts.data && !contacts.data.length">
              No contacts found
            </div>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="button" class="xpl-button xpl-button--primary" @click.stop="assign($event)" :disabled="!associated_contact">Associate</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import _ from 'lodash';

  export default {
    props: ['associatedContact', 'scheduledTaskId'],
    data() {
      return {
        query: '',
        contacts: null,
        associated_contact: this.associatedContact,
      };
    },
    watch: {
      query() {
        this.searchContacts();
      },
    },
    methods: {
      closeModal() {
        EventBus.$emit('scheduled_task.associate.close');
      },
      assign() {
        EventBus.$emit('scheduled_task.associate.choose', this.associated_contact);
        this.closeModal();
      },
      fetchContacts() {
        api.getContacts('all', 0, '', this.query).then((response) => {
          this.contacts = response.contacts;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      searchContacts: _.debounce(function debounce() {
        this.fetchContacts();
      }, 750),
    },
  };
</script>
<style lang="scss" scoped>
  .contacts {
    margin-top: 1rem;
  }

  .contacts .contact {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
    font-size: .875rem;
    line-height: .875rem;
    letter-spacing: 0.2px;
    transition: all 150ms ease-in-out;
  }

  .contacts .contact:last-child {
    border-bottom: none;
  }

  .contacts .contact:hover,
  .contacts .contact.selected {
    cursor: pointer;
    background-color: var(--xpl-color-background-lm);
  }
</style>
