<template>
  <div class="container">
    <img :src="$store.getters.brandLogo" :alt="$store.getters.brandName" class="img-fluid" />
    <h1>Let's confirm your email!</h1>
    <p>We have sent you a validation email to {{ $store.state.user.email }}. Please click the confirmation link to activate your account.</p>
  </div>
</template>
<script>
  import store from '@/store';

  export default {
    data() {
      return {};
    },
    beforeRouteEnter(to, from, next) {
      if (Object.keys(store.state.user).length === 0) {
        store.dispatch('refreshUser')
          .then(() => {
            next();
          });
      } else {
        next();
      }
    },
  };
</script>
<style lang="scss" scoped>
  .container {
    width: 50em;
    height: calc(100vh - 3rem);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .container img {
    width: 42rem;
    height: auto;
    max-height: 42px;
  }

  .container h1 {
    font-size: 2.8rem;
    font-weight: 300;
  }

  .container p {
    margin-top: 1rem;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.5rem;
  }
</style>
