<template>
  <div>
    <Splash v-if="splash" />
    <QuickBrand v-if="brand" />
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import QuickBrand from './QuickBrand';
  import Splash from './Splash';

  export default {
    components: {
      QuickBrand,
      Splash,
    },
    data() {
      return {
        splash: true,
        brand: false,
      };
    },
    mounted() {
      EventBus.$on('quick_brand', () => {
        this.splash = false;
        this.brand = true;
      });
    },
    beforeDestroy() {
      EventBus.$off('quick_brand');
    },
  };
</script>
