<template>
  <div class="overlay">
    <TemplatePreview v-if="show_preview && campaignTemplateId" :campaignTemplateId="campaignTemplateId" />
    <div class="header" v-if="!show_preview && !campaignTemplateId">
      Select a scheduled plan template
      <span class="icon icon-cancel-square" @click="closeTemplateSelection"></span>
    </div>
    <div class="container" v-if="!show_preview && !campaignTemplateId">
      <div class="sidebar">
        <div class="title">Template Purpose</div>
        <div class="types">
          <a href="#" :class="[group === g.name ? 'active' : '']" v-for="g in groups" @click="group = g.name">{{ g.name }}</a>
        </div>
      </div>
      <div class="content">
        <div class="alert">
          <span class="material-icons">info_outline</span>
          All templates are just blueprints, feel free to customize them all to suit your business!
          <span class="material-icons" style="margin-left: auto;">close</span>
        </div>
        <div class="templates">
          <h1>Select a template</h1>
          <form @submit.prevent>
            <div class="input-group">
              <span class="input-group-addon">
                <span class="icon icon-search"></span>
              </span>
              <input type="search" name="query" v-model.trim="query" class="form-control" placeholder="Search" />
            </div>
          </form>
          <div class="template" v-for="template in filteredTemplates" v-if="filteredTemplates.length">
            <img src="#" />
            <div class="">
              <div class="name">
                {{ template.name }}
              </div>
              <div class="description">
                {{ template.description }}
              </div>
              <div class="actions">
                <button type="button" class="xpl-button xpl-button--primary" @click.prevent="selectTemplate(template)">Use Template</button>
                <a href="#" @click.prevent="campaignTemplateId = template.id; show_preview = true;">Learn More</a>
              </div>
            </div>
          </div>
          <div class="template empty" v-if="!filteredTemplates.length">
            No templates found!
          </div>
        </div>
      </div>
    </div>
    <NameScheduledPlanModal v-if="selectedTemplate" :template="selectedTemplate" />
  </div>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import TemplatePreview from './TemplatePreview';
  import NameScheduledPlanModal from './NameScheduledPlanModal';

  export default {
    components: {
      TemplatePreview,
      NameScheduledPlanModal,
    },
    data() {
      return {
        campaignTemplateId: null,
        show_preview: false,
        selectedTemplate: false,
        group: null,
        groups: [],
        templates: [],
        query: '',
      };
    },
    mounted() {
      EventBus.$on('campaign_template.preview.close', () => {
        this.show_preview = false;
        this.campaignTemplateId = null;
      });

      EventBus.$on('auto_campaigns.create_modal.close', () => {
        this.selectedTemplate = null;
      });

      EventBus.$on('campaign_template.email.preview.select', (template) => {
        this.show_preview = false;
        this.campaignTemplateId = null;
        this.selectTemplate(template);
      });
    },
    created() {
      api.getScheduledPlanTemplateGroups().then((response) => {
        this.groups = response.groups;
        this.group = this.groups[0].name;

        api.getScheduledPlanTemplates().then((res) => {
          this.templates = res.templates;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    beforeDestroy() {
      EventBus.$off('campaign_template.preview.close');
      EventBus.$off('auto_campaigns.create_modal.close');
      EventBus.$off('campaign_template.email.preview.select');
    },
    computed: {
      filteredTemplates() {
        if (this.group) {
          return this.templates.filter(t => t.scheduled_plan_template_group_name === this.group);
        }

        return this.templates;
      },
    },
    methods: {
      closeTemplateSelection() {
        EventBus.$emit('campaign_template.selection.close');
      },
      selectTemplate(template) {
        this.selectedTemplate = template;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10000;
  }

  .header {
    height: 3rem;
    background-color: #ffffff;
    padding-left: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #cdd7e2;
  }

  .header .icon {
    font-size: 2rem;
    color: #afb7c7;
    cursor: pointer;
    margin-right: 1.5rem;
  }

  .container {
    padding: 0;
    height: 100vh;
    width: 100vw;
    max-width: none;
  }

  .container .sidebar {
    min-width: 15rem;
    max-width: 15rem;
    width: 15rem;
    background-color: #fff;
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: fixed;
    left: 0;
    height: calc(100vh - 3rem);
    border-left: 1px solid var(--xpl-color-gray-400);
  }

  .container .content {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    background-color: #f3f7f9;
    padding-top: 1.5rem;
    padding-left: calc(15rem + 2.5rem);
    padding-right: 2.5rem;
  }

  .sidebar .title {
    font-size: .875rem;
    color: #1d364b;
    margin-bottom: 1.5rem;
  }

  .sidebar .types,
  .sidebar .tabs {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sidebar .types a,
  .sidebar .tabs a {
    display: block;
    border-radius: 6px;
    font-weight: 700;
    color: #afbcca;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 6px;
    padding-bottom: 6px;
    transition: all 150ms ease-in-out;
  }

  .sidebar .types a:hover,
  .sidebar .types a.active,
  .sidebar .tabs a:hover,
  .sidebar .tabs a.active {
    text-decoration: none;
    background-color: var(--xpl-color-gray-400);
    color: #1d364b;
  }

  .alert {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    // box-shadow: 0 1px 0 0 rgba(217, 231, 240, 0.5);
    border: solid 1px rgb(217, 231, 240);
    padding: .75rem;
    font-size: 14px;
    line-height: 1.25rem;
    color: rgb(29, 54, 75);
  }

  .alert .material-icons {
    font-size: 1.75rem;
    color: rgb(180, 224, 250);
    margin-right: .625rem;
  }

  .alert .material-icons:last-child {
    color: rgb(175, 183, 199);
    cursor: pointer;
  }

  .templates {
    margin-top: 2rem;
    padding-bottom: 2rem;
  }

  .templates h1 {
    font-size: 1.25rem;
    line-height: 1.4;
    color: rgb(46, 68, 87);
    margin-bottom: 1rem;
  }

  .templates form {
    display: flex;
    margin-bottom: 1.5rem;
  }

  .templates .template {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
    background-color: rgb(255, 255, 255);
    // box-shadow: 0 1px 0 0 rgba(217, 231, 240, 0.5);
    border: solid 1px rgb(217, 231, 240);
    margin-bottom: 1.5rem;
  }

  .templates .template.empty {
    font-size: .75rem;
    color: rgb(97, 112, 128);
  }

  .templates .template:last-child {
    margin-bottom: 0;
  }

  .templates .template .name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: rgb(29, 54, 75);
  }

  .templates .template img {
    margin-right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .templates .template .description {
    margin-top: 1rem;
    font-size: .875rem;
    line-height: 1.43;
    color: rgb(97, 112, 128);
  }

  .templates .template .actions {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .templates .template .actions .btn {
    margin-right: 1rem;
  }

  .templates .template .actions a {
    font-size: .75rem;
    color: rgb(29, 54, 75);
  }
</style>
