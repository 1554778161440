<template>
  <div>
    <el-card>
      <div class="display-small" style="margin-bottom: 2rem">MINDBODY Settings</div>

      <div class="title">Activation Code</div>
      <el-button type="primary" class="xpl-button xpl-button--primary" @click="getActivationCode" :loading="loading">Generate</el-button>
      <div class="small" v-if="!activation">Click Generate to obtain an Activation Code</div>
      <div style="margin-top: 1rem;" class="alert alert-secondary small" v-if="activation">
        <div>URL: {{ activation.url }}</div>
        <br>
        <div>Code: {{ activation.code }}</div>
      </div>
    </el-card>

    <el-card style="margin-top: 2rem;">
      <div class="display-small" style="margin-bottom: 2rem;">Deactivate MINDBODY Integration</div>
      <el-button type="danger" :loading="dloading" @click="deactivateMBO">Click here to deactivate the MINDBODY Integration for this Account</el-button>
    </el-card>
  </div>
</template>
<script>
  import * as api from '@/api';

  export default {
    data() {
      return {
        activation: null,
        loading: false,
        dloading: false,
      };
    },
    methods: {
      deactivateMBO() {
        this.$confirm('Are you sure?').then(() => {
          this.dloading = true;
          const payload = {
            deactivate_mbo: true,
          };
          api.enterpriseHelpers(payload).then((response) => {
            this.$message.success(response.message);
            this.dloading = false;
          }).catch((err) => {
            this.$message.error(err.message);
            this.dloading = false;
          });
        }).catch(() => {});
      },
      getActivationCode() {
        this.loading = true;
        api.getActivationCode().then((response) => {
          this.activation = response.activation;
          this.loading = false;
        }).catch((err) => {
          this.loading = false;
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .card-body .title {
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.43;
    color: rgb(29, 54, 75);
    margin-bottom: .25rem;
  }
</style>
