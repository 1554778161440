<template>
  <el-main v-loading="!program">
    <router-link to="/apps/sheer-id" class="go-back"><i class="fa fa-chevron-left"></i> Go Back</router-link>
    <el-row :span="24" class="program-name">
      <el-col :span="20" v-if="program">
        <span @click="toggleEditName" v-if="!editName">{{ program.name }}</span>
        <el-input v-if="editName" focus="true" ref="editName" v-model="program.name" @keyup.enter.native="toggleEditName" />
        <div class="input-helper" v-if="editName">Press Enter to save</div>
      </el-col>
      <el-col :span="4" class="text-right">
        <el-dropdown @command="handleCommand">
          <el-button type="secondary" class="xpl-button xpl-button--secondary" >Options</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="delete" class="text-danger">Delete</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>

    <el-row :span="24" class="top-banner" v-if="program">
      <el-col :span="24">
        <el-card :class="[program.enabled ? '' : 'inactive']">
          <div class="inner">
            <span v-if="program.enabled">This Program is currently active</span>
            <span v-if="!program.enabled">This Program is currently NOT ACTIVE</span>
            <el-button type="secondary" class="xpl-button xpl-button--secondary"  size="small" @click="changeProgramStatus" :loading="loadingProgram" v-if="program.enabled">Turn Off This Program</el-button>
            <el-button type="secondary" class="xpl-button xpl-button--secondary"  size="small" @click="changeProgramStatus" :loading="loadingProgram" v-if="!program.enabled">Turn On This Program</el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <div class="analytics">
      <el-row class="name">
        <el-col :span="24">Program Analytics</el-col>
      </el-row>
      <el-row class="cards">
        <el-col :span="7">
          <el-card @click.native="toggleStats('all')" :class="[tables.all ? 'active' : '']">
            <div class="card-inner">
              <span>SheerID Verifications</span>
              <span class="number">{{ this.stats.all | prettyNumber }}</span>
            </div>
          </el-card>
        </el-col>
        <el-col :span="7" :offset="1">
          <el-card @click.native="toggleStats('found')" :class="[tables.found ? 'active' : '']">
            <div class="card-inner">
              <span>Found Match</span>
              <span class="number">{{ this.stats.found | prettyNumber }}</span>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8" :offset="1">
          <el-card @click.native="toggleStats('notFound')" :class="[tables.notFound ? 'active' : '']">
            <div class="card-inner">
              <span>Did Not Find Match</span>
              <span class="number">{{ this.stats.notFound | prettyNumber }}</span>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row style="margin-top: 1rem;" v-if="tables.all || tables.found || tables.notFound">
        <el-col :span="24">
          <el-card>
            <el-table :data="stats.details" v-loading="loadingDetails">
              <el-table-column prop="email" label="Email"></el-table-column>
              <el-table-column prop="created_at" label="Date">
                <template slot-scope="scope">
                  {{ scope.row.created_at | humanDateTime }}
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <div class="settings" v-if="program && program.settings">
      <el-row class="name">
        <el-col :span="24">Settings</el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-card v-loading="loadingSettings">
            <el-form>
              <el-form-item>
                <label>SheerID Program ID</label>
                <el-input v-model="program.settings.program_id" placeholder="SheerID Program ID"></el-input>
              </el-form-item>
              <el-form-item>
                <label>If successfully verified, apply this Mariana Tek User Tag</label>
                <el-select v-model="program.settings.mt_user_tag_id" style="width: 100%" placeholder="User Tag">
                  <el-option v-for="item in mt_user_tags" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="xpl-button xpl-button--primary" @click="saveSettings">Save Changes</el-button>
              </el-form-item>
            </el-form>
          </el-card>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-card v-loading="loadingSettings">
            <div class="option">
              Successful Verification
            </div>
            <div class="inner-option">
              <el-checkbox v-model="program.settings.success.email.active" /> Send an Email
              <el-form v-if="program.settings.success.email.active">
                <el-form-item>
                  <div style="margin-top: 1rem;">
                    <el-radio v-model="program.settings.success.email.filter" label="saved" @change="program.settings.success.email.template_id = null" border>Saved Templates</el-radio>
                    <el-radio v-model="program.settings.success.email.filter" label="brandbot" @change="program.settings.success.email.template_id = null" border>{{$store.getters.brandName}} Templates</el-radio>
                  </div>
                  <label>Use the following template</label>
                  <el-select v-model="program.settings.success.email.template_id" style="width: 100%;" value-key="id">
                    <el-option v-for="template in success_templates" :key="template.id" :label="template.name" :value="template.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <div class="inner-option">
              <el-checkbox v-model="program.settings.success.sms.active" /> Send a text message
              <el-form v-if="program.settings.success.sms.active">
                <el-form-item>
                  <label>Use the following message</label>
                  <el-input type="textarea" v-model="program.settings.success.sms.body" />
                </el-form-item>
                <el-form-item>
                  <ProgramPersonalizations />
                </el-form-item>
              </el-form>
            </div>

            <div class="inner-option">
              <el-button type="primary" class="xpl-button xpl-button--primary" @click="saveSettings">Save Changes</el-button>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-card v-loading="loadingSettings">
            <div class="option">
              Match Not Found
            </div>
            <div class="inner-option">
              <el-checkbox v-model="program.settings.failure.email.active" /> Send an Email
              <el-form v-if="program.settings.failure.email.active">
                <el-form-item>
                  <div style="margin-top: 1rem;">
                    <el-radio v-model="program.settings.failure.email.filter" label="saved" @change="program.settings.failure.email.template_id = null" border>Saved Templates</el-radio>
                    <el-radio v-model="program.settings.failure.email.filter" label="brandbot" @change="program.settings.failure.email.template_id = null" border>{{$store.getters.brandName}} Templates</el-radio>
                  </div>
                  <label>Use the following template</label>
                  <el-select v-model="program.settings.failure.email.template_id" style="width: 100%;" value-key="id">
                    <el-option v-for="template in failure_templates" :key="template.id" :label="template.name" :value="template.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <div class="inner-option">
              <el-checkbox v-model="program.settings.failure.sms.active" /> Send a text message
              <el-form v-if="program.settings.failure.sms.active">
                <el-form-item>
                  <label>Use the following message</label>
                  <el-input type="textarea" v-model="program.settings.failure.sms.body" />
                </el-form-item>
                <el-form-item>
                  <ProgramPersonalizations />
                </el-form-item>
              </el-form>
            </div>

            <div class="inner-option">
              <el-button type="primary" class="xpl-button xpl-button--primary" @click="saveSettings">Save Changes</el-button>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </el-main>
</template>
<script>
  import {
    getSheerIDProgram,
    updateSheerIDProgram,
    getSheerIDTemplates,
    MTUserTags,
    getSheerIDProgramStats,
    getSheerIDProgramStatsDetails,
    deleteSheerIDProgram,
    } from '@/api';
  import ProgramPersonalizations from './ProgramPersonalizations';

  export default {
    components: {
      ProgramPersonalizations,
    },
    props: ['uuid'],
    data() {
      return {
        showDetails: false,
        loadingDetails: false,
        loadingSettings: false,
        loadingProgram: false,
        program: null,
        editName: false,
        stats: {
          all: 0,
          found: 0,
          notFound: 0,
          details: [],
        },
        tables: {
          all: false,
          found: false,
          notFound: false,
        },
        mt_user_tags: [],
        success_templates: [],
        failure_templates: [],
      };
    },
    created() {
      this.fetchProgram();
      this.fetchMTUserTags();
      this.fetchStats();
    },
    watch: {
      // eslint-disable-next-line
      'program.settings.success.email.filter': function (newVal, oldVal) {
        if (!oldVal) {
          return;
        }
        this.fetchTemplates('success', newVal);
      },
      // eslint-disable-next-line
      'program.settings.failure.email.filter': function (newVal, oldVal) {
        if (!oldVal) {
          return;
        }
        this.fetchTemplates('failure', newVal);
      },
    },
    methods: {
      handleCommand(command) {
        if (command === 'delete') {
          this.$confirm('Are you sure you want to delete this Program? Doing so will prevent any verifications from receiving {{$store.getters.brandName}} powered messaging.')
          .then(() => {
            this.deleteProgram();
          }).catch(() => {});
        }
      },
      deleteProgram() {
        deleteSheerIDProgram(this.program.id).then((response) => {
          this.$message.success(response.message);
          this.$router.push('/apps/sheer-id');
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      toggleEditName() {
        this.editName = !this.editName;

        if (!this.editName) {
          this.editProgram();
        }
      },
      editProgram() {
        const payload = {
          name: this.program.name,
        };
        updateSheerIDProgram(this.program.id, payload).then((response) => {
          this.$message.success(response.message);
        }).catch(err => this.$message.error(err.message));
      },
      toggleStats(stat) {
        this.stats.details = [];
        this.tables[stat] = !this.tables[stat];
        Object.keys(this.tables).forEach((k) => {
          if (k !== stat) {
            this.tables[k] = false;
          }
        });

        if (!this.tables[stat]) {
          return;
        }

        this.loadingDetails = true;
        getSheerIDProgramStatsDetails(this.uuid, stat).then((response) => {
          this.loadingDetails = false;
          this.stats.details = response.details;
        }).catch((err) => {
          this.loadingDetails = false;
          this.$message.error(err.message);
        });
      },
      fetchStats() {
        getSheerIDProgramStats(this.uuid).then((response) => {
          this.stats = response.stats;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchMTUserTags() {
        MTUserTags().then((response) => {
          this.mt_user_tags = response.user_tags;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchTemplates(model, templateFilter) {
        getSheerIDTemplates(templateFilter).then((response) => {
          this.$set(this, `${model}_templates`, response.templates);
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchProgram() {
        getSheerIDProgram(this.uuid).then((response) => {
          this.$set(this, 'program', response.program);
          this.fetchTemplates('success', this.program.settings.success.email.filter);
          this.fetchTemplates('failure', this.program.settings.failure.email.filter);
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      saveSettings() {
        const payload = {
          settings: this.program.settings,
        };

        this.loadingSettings = true;

        updateSheerIDProgram(this.program.id, payload).then((response) => {
          this.loadingSettings = false;
          this.program = response.program;
          this.$message.success(response.message);
        }).catch((err) => {
          this.loadingSettings = false;
          this.$message.error(err.message);
        });
      },
      changeProgramStatus() {
        this.loadingProgram = true;
        const payload = {
          enabled: !this.program.enabled,
        };
        updateSheerIDProgram(this.program.id, payload).then((response) => {
          this.program = response.program;
          this.$message.success(response.message);
          this.loadingProgram = false;
        }).catch((err) => {
          this.loadingProgram = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss">
  .program-name .el-input__inner {
    padding: 0;
    border: none !important;
    background-color: transparent !important;
    color: #00152a;
  }
</style>
<style lang="scss" scoped>
  .el-main {
    margin-top: 3rem;
    width: 63.25rem;
    margin-left: auto;
    margin-right: auto;
  }

  .program-name {
    margin-top: 1.875rem;
    font-size: 1.625rem;
    letter-spacing: 0.15px;
    color: #00152a;
    display: flex;
    justify-content: space-between;
  }

  .program-name .el-input {
    display: inline-block;
    font-size: 1.625rem;
    letter-spacing: 0.15px;
  }

  .program-name .el-input + .input-helper {
    font-size: .5rem;
  }

  .top-banner {
    margin-top: 1rem;
    margin-bottom: .625rem;
  }

  .top-banner .inactive {
    background-color: #f2bebe;
  }

  .top-banner .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    color: #00152a;
  }

  .analytics {
    margin-top: 4rem;
  }

  .analytics .name,
  .settings .name {
    margin-bottom: 1.25rem;
    margin-top: 2rem;
    font-size: 1.25rem;
    letter-spacing: 0.15px;
    color: #161e27;
  }

  .analytics .cards .el-card {
    font-size: .875rem;
    font-weight: 700;
    color: #161e27;
  }

  .analytics .cards .el-card:hover,
  .analytics .cards .el-card.active {
    cursor: pointer;
    border-color: var(--xpl-color-secondary-lm);
  }

  .analytics .cards .card-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .analytics .cards .card-inner .number {
    font-size: 1.25rem;
  }

  .settings .el-row {
    margin-bottom: 2rem;
  }

  .settings .el-row:first-child {
    margin-bottom: 1rem;
  }

  .option {
    font-weight: 700;
    line-height: 1.5;
    color: #00152a;
    margin-bottom: 1.875rem;
  }

  .option .el-checkbox,
  .inner-option .el-checkbox {
    margin-right: .625rem;
  }

  .inner-option {
    margin-left: 2.675rem;
    margin-top: 2.25rem;
  }
</style>
