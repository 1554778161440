<template>
  <transition name="fade">
    <div class="unsub-contacts">
      <a href="#" class="go-back" @click="AllContacts"><i class="fa fa-chevron-left"></i> All Contacts</a>
      <h2>Manually unsubscribe contacts</h2>
      <p>Unsubscribing a contact will make sure that the contact does not receive any emails from {{$store.getters.brandName}}.</p>

      <div class="inner">
        <div class="section">
          <h3>Unsubscribe in bulk from .CSV</h3>
          <div class="card">
            <div class="card-body">
              <form @submit.prevent>
                <label>Select CSV</label>
                <div class="form-group">
                  <input type="file" accept="text/csv,text/plain" id="csv_file" name="csv_file" class="input-file" @change="fileUpload($event)" />
                  <label for="csv_file">
                    <span ref="csv_file_name"></span>
                    Browse Files
                  </label>
                </div>
              </form>
              <button type="button" class="xpl-button xpl-button--primary" :disabled="!file" @click="upload">Upload CSV</button>
            </div>
          </div>
        </div>
        <div class="section">
          <h3>Unsubscribe a single contact</h3>
          <div class="card">
            <div class="card-body flex">
              <form @submit.prevent>
                <div class="form-group">
                  <label for="email">Email Address</label>
                  <input type="text"
                    id="email"
                    name="email"
                    v-model="one_off.email"
                    :class="{ 'form-control': true, 'is-invalid': $v.one_off.email.$error }"
                    placeholder="Email"
                    @input="$v.one_off.email.$touch()" />
                    <div class="invalid-feedback" v-if="!$v.one_off.email.email">A valid email is required.</div>
                </div>
              </form>
              <button type="button" class="xpl-button xpl-button--primary" @click="unsubContact" :disabled="$v.$error || $v.$invalid">Unsubscribe Contact</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import { required, email } from 'vuelidate/lib/validators';

  export default {
    data() {
      return {
        file: null,
        one_off: {
          email: '',
        },
      };
    },
    validations: {
      one_off: {
        email: {
          required,
          email,
        },
      },
    },
    methods: {
      AllContacts() {
        EventBus.$emit('contacts.all_contacts');
      },
      unsubContact() {
        if (!this.one_off.email) {
          this.$toasted.error('Please make sure the email field is filled out properly.');
          return;
        }

        const payload = {
          email: this.one_off.email,
        };

        api.unsubContact(payload).then((response) => {
          this.$toasted.success(response.message);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fileUpload(event) {
        const target = event.target || event.srcElement;
        if (!target.files.length) {
          return;
        }

        const file = target.files[0];

        this.$refs.csv_file_name.innerHTML = file.name;
        this.file = file;
      },
      upload(event) {
        const target = event.target || event.srcElement;
        target.disabled = true;
        target.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';
        const formData = new FormData();
        formData.append('csv_file', this.file, this.file.name);

        api.uploadUnsubscribes(formData).then((response) => {
          this.$toasted.success(response.message);
          this.$refs.csv_file_name.innerHTML = '';
          target.disabled = false;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .unsub-contacts {
    margin-top: 2rem;
  }

  h2, p {
    margin-top: .5rem;
  }

  p {
    max-width: 34rem;
  }

  p.tiny-text {
    font-size: .625rem;
    margin-bottom: 2rem;
  }

  .inner {
    margin-top: 2rem;
  }

  .section {
    margin-bottom: 2rem;
  }

  .card {
    // box-shadow: 0 1px 1px 0 rgba(205, 215, 226, 0.35);
  }

  .card-body.flex form {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .form-group {
    flex: 1;
    margin-right: 1rem;
  }

  .form-group:last-child {
    margin-right: 0;
  }
</style>
