<template>
  <div class="sidebar-inner" style="width: 100%;">
    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">1</span>
      </el-col>
      <el-col :span="22" class="v2__heading">Do you want to find the contacts who have landed or have not landed on a Landing Page?</el-col>
    </el-row>

    <el-row style="margin-bottom: 2rem;">
      <el-col :offset="2">
        <el-radio-group v-model="landed">
          <el-radio :label="true" border>Contacts who have</el-radio>
          <el-radio :label="false" border>Contacts who have <b>not</b></el-radio>
        </el-radio-group>
      </el-col>
    </el-row>

    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">2</span>
      </el-col>
      <el-col :span="22" class="v2__heading">What Landing Page(s) did the contacts {{ landed ? '' : 'not ' }}land on?</el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1.5rem;">
        <el-col :offset="2" :span="22">
          <el-radio-group v-model="specific">
            <el-radio :label="false" border>Any</el-radio>
            <el-radio :label="true" border>Specific one(s)</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row v-if="specific">
        <el-col :offset="2" :span="22">
          <div class="options">
            <label>Landing Pages</label>
            <el-select v-model="selected_landing_pages" multiple collapse-tags filterable placeholder="Landing Page(s)" style="width: 100%;" value-key="id">
                <el-option v-for="item in landing_pages"
                    :key="item.id"
                    :label="item.name"
                    :value="item">
                </el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
    </div>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">3</span>
        </el-col>
        <el-col :span="22" class="v2__heading">When did the contacts {{ landed ? '' : 'not ' }}land on these pages?</el-col>
      </el-row>

      <el-row style="margin-bottom: 1.5rem;">
        <el-col :offset="2" :span="22">
          <el-radio-group v-model="timeframe">
            <el-radio label="all" border>All Time</el-radio>
            <el-radio label="absolute" border>Around static date(s)</el-radio>
            <el-radio label="relative" border>A relative time frame</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row v-if="timeframe === 'absolute'">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-radio-group v-model="comparison">
              <el-radio-button label="before">Before</el-radio-button>
              <el-radio-button label="between">Between</el-radio-button>
              <el-radio-button label="after">After</el-radio-button>
            </el-radio-group>

            <div class="block" v-if="comparison && comparison !== 'between'">
              <el-date-picker
                v-model="dates"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="Pick a date">
              </el-date-picker>
            </div>

            <div class="block" v-if="comparison && comparison === 'between'">
              <el-date-picker
                v-model="dates"
                type="daterange"
                range-separator="-"
                value-format="yyyy-MM-dd"
                start-placeholder="Start"
                end-placeholder="End">
              </el-date-picker>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row v-if="timeframe === 'relative'">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-radio-group v-model="comparison">
              <el-radio-button label="more">At least</el-radio-button>
              <el-radio-button label="within">Within the last</el-radio-button>
              <el-radio-button label="exactly">Exactly</el-radio-button>
            </el-radio-group>
            <div class="block" style="display: flex; align-items: center;">
              <el-input-number min="0" v-model="relative_days"></el-input-number>
              &nbsp;days<span v-if="comparison && comparison !== 'within'">&nbsp;ago</span>.
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-form>
      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click="commitCondition">Save this condition</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['condition', 'editedRule', 'enrollment', 'goalList'],
    data() {
      return {
        landed: true,
        specific: false,
        selected_landing_pages: [],
        landing_pages: [],
        timeframe: 'all',
        comparison: null,
        relative_days: 1,
        dates: null,
      };
    },
    created() {
      this.fetchLandingPages();

      if (this.editedRule) {
        this.landed = this.editedRule.landed;
        this.specific = this.editedRule.specific;
        this.timeframe = this.editedRule.timeframe;
        this.comparison = this.editedRule.comparison;
        this.relative_days = this.editedRule.relative_days;
        this.selected_landing_pages = this.editedRule.landing_pages;

        if (this.editedRule.end_date) {
          this.dates = [this.editedRule.start_date, this.editedRule.end_date];
        } else {
          this.dates = this.editedRule.start_date;
        }
      }
    },
    watch: {
      specific(newVal) {
        if (newVal === false) {
          this.selected_landing_pages = [];
        }
      },
    },
    methods: {
      fetchLandingPages() {
        api.checkoutFlows().then((response) => {
          this.landing_pages = response.checkoutflows;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          landed: this.landed,
          specific: this.specific,
          timeframe: this.timeframe,
          comparison: this.comparison,
          relative_days: this.relative_days,
          start_date: Array.isArray(this.dates) ? this.dates[0] : this.dates,
          end_date: Array.isArray(this.dates) ? this.dates[1] : null,
          landing_pages: this.selected_landing_pages,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .options {
    padding: 1.25rem;
    background-color: #f5f8fb;
  }
</style>
