<template>
  <div class="condition">
    <form @submit.prevent>
      <div class="form-group" v-if="campaignItem">
        <label>The contact has never visited any of the following class/appointment types:</label>
        <div class="multiselect-block">
          <Multiselect v-model="selected_classes" placeholder="Choose" :options="classes" label="name" track-by="name" :searchable="true" :allow-empty="false" :show-labels="false" :multiple="true" :close-on-select="false" />
        </div>
      </div>
      <div class="form-group" v-else>
        <label>Any contact if they have not visited any of the following class types ever:</label>
        <div class="multiselect-block">
          <Multiselect v-model="selected_classes" placeholder="Choose" :options="classes" label="name" track-by="name" :searchable="true" :allow-empty="false" :show-labels="false" :multiple="true" :close-on-select="false" />
        </div>
      </div>

      <button class="xpl-button xpl-button--primary" @click.stop="commitCondition" :disabled="!selected_classes.length">Save</button>
    </form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['uuid', 'rule', 'campaignItem'],
    components: {
      Multiselect,
    },
    data() {
      return {
        classes: [],
        selected_classes: [],
      };
    },
    created() {
      this.fetchClasses();

      if (this.rule && this.rule.length && this.rule[0].classes) {
        this.selected_classes = this.rule[0].classes;
      }
    },
    methods: {
      fetchClasses() {
        api.getClasses().then((response) => {
          this.classes = response.classes;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition(event) {
        const target = event.target || event.srcElement;

        target.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';
        target.disabled = true;

        const payload = {
          condition: {
            uuid: this.uuid,
            quick: true,
          },
          status: 'Signed-In',
          visited: false,
          timeframe: 'all',
          relative_days: 0,
          comparison: null,
          start_date: null,
          end_date: null,
          filtering: 'specific',
          classes: this.selected_classes,
          staffes: [],
          start_time: null,
          end_time: null,
        };
        EventBus.$emit('condition.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .form-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .multiselect-block {
    width: 100%;
    display: block;
  }

  .condition label {
    color: #1D364B;
    margin-bottom: .75rem;
    font-weight: 400;
    font-size: 1rem;
  }

  .btn-bb-primary {
    margin-top: 1.5rem;
  }
</style>
