<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div>
              <a href="#" class="go-back" @click.stop="closePreview">
                <span class="icon icon-arrow-left3"></span>
                  Go Back
                </a>
                {{ template.name }}
            </div>
            <div class="dropdown">
              <button type="button" class="xpl-button xpl-button--secondary" @click.stop="toggle_dropdown = !toggle_dropdown">Options</button>
              <div :class="{ 'dropdown-menu': true, 'dropdown-menu-right': true, show: toggle_dropdown }">
                <a href="#" class="dropdown-item text-danger" @click.stop="deleteTemplate">Delete</a>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div class="preview">
              <div v-html="preview_html"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['template'],
    data() {
      return {
        toggle_dropdown: false,
        preview_html: null,
      };
    },
    created() {
      const payload = {
        type: this.template.type,
      };

      if (payload.type === 'full') {
        payload.payload = JSON.stringify(JSON.parse(this.template.body));
      } else {
        payload.payload = JSON.stringify(this.template.body);
      }

      api.getTemplatePreview(payload).then((response) => {
        this.preview_html = response.html;
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    methods: {
      closePreview() {
        EventBus.$emit('templates.preview.close');
      },
      deleteTemplate() {
        this.toggle_dropdown = false;

        api.deleteTemplate(this.template.id).then((response) => {
          this.$toasted.success(response.message);
          this.closePreview();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal .modal-content .modal-header {
    height: 3rem;
    background-color: #ffffff;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #cdd7e2;
    font-weight: 700;
    line-height: 1.5rem;
    color: #1d364b;
  }

  .modal .modal-content .modal-header .icon {
    font-size: .75rem;
    margin-right: .25rem;
  }

  .modal .modal-content .modal-header .go-back {
    margin-right: 1rem;
    color: #586777;
  }

  .modal-dialog {
    max-width: 100%;
    margin: 0;
    height: 100%;
  }

  .modal .modal-content {
    height: 100%;
  }

  .modal .modal-content .modal-body {
    max-height: 100%;
    background-color: #f3f7f9;
  }

  .preview {

  }
</style>
