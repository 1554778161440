<template>
  <div>
    <div data-test-header class="display-medium" style="margin-bottom: 2rem;">Form Design</div>
    <el-card v-loading="loading">
      <el-form ref="vanityForm" :model="settings" :rules="vanityRules" status-icon @submit.native.prevent>
        <el-form-item prop="vanity" v-if="editVanity">
          <label data-test-header-url>Choose the URL for your Hosted Form</label>
          <el-input data-test-url-input placeholder="example" v-model="settings.vanity">
            <template data-test-subdomain-input slot="prepend">https://{{ form.subdomain }}.brandbot.io/forms/</template>
            <template slot="append">.html</template>
          </el-input>
        </el-form-item>
        <el-form-item v-if="!editVanity">
          <label data-test-header-hosted-url>URL for your Hosted Form</label>
          <el-row>
            <el-input data-test-current-url-input v-model="currentUrl" readonly>
              <template slot="append">
                <el-button data-test-url-copy-button data-test- type="primary" @click="copyVanity">Copy</el-button>
              </template>
            </el-input>
          </el-row>
        </el-form-item>
        <el-form-item v-if="editVanity">
          <el-button  data-test-save-button type="primary" @click.stop="saveVanity">Save</el-button>
        </el-form-item>
        <el-form-item v-if="!editVanity">
          <el-button data-test-url-edit-button type="primary" @click.stop="confirmEditVanity">Edit Form URL</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card v-loading="loading">
      <div data-test-templates-header class="display-small" style="margin-bottom: 2rem;">Templates</div>
      <div class="templates">
        <el-card
          v-for="(t, index) in templates"
          :data-test-template-card="t.name"
          :key="index"
          :class="['template', settings.selectedTemplate === t.id ? 'active' : '']"
          :body-style="{padding: '0px'}">
          <div data-test-template-image class="image" :style="'background-image: url('+t.image+')'"></div>
          <div data-test-template-name class="bottom">
            {{ t.name }}
            <el-button :data-test-template-pick-selected-button="settings.template.id === t.id ? 'Selected' : 'Pick' " size="small" @click="selectTemplate(t)" :disabled="settings.template.id === t.id">
              {{ settings.template.id === t.id ? 'Selected' : 'Pick' }}
            </el-button>
          </div>
        </el-card>
      </div>
    </el-card>
    <el-card v-if="settings.template.id" class="main" :body-style="{padding: '0px'}">
      <div class="header v2__heading">
        <div data-test-template-preview-header>Preview</div>
        <el-button data-test-template-edit-button size="small" @click.stop="editDesign">Edit Design</el-button>
      </div>
      <div class="preview">
        <iframe data-test-template-preview id="iframe-small" src="" class="preview-rendering" height="100vh"></iframe>
      </div>
    </el-card>
    <FormDesignEdit data-test-form-design v-if="edit" :form="form" :template="currentTemplate" />
  </div>
</template>
<script>
  import {
    updateForm,
    getTemplatesForm,
    getTemplateFormPreview,
  } from '@/api';
  import EventBus from '@/event-bus';
  import FormDesignEdit from './FormDesignEdit';

  export default {
    props: ['form'],
    components: {
      FormDesignEdit,
    },
    data() {
      const checkVanity = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('A valid url is required for your Landing Page.'));
        }

        if (!value.match(/^[0-9a-zA-Z-_]+$/)) {
          return callback(new Error('Only alphanumeric characters, "-" and "_" are allowed.'));
        }

        return callback();
      };
      return {
        templates: [],
        loading: false,
        edit: false,
        editVanity: false,
        currentUrl: `https://${this.form.subdomain}.brandbot.io/forms/${this.form.settings.vanity}.html`,
        settings: {
          vanity: null,
          template: {
            id: 1,
            btn_color: 'var(--xpl-color-secondary-lm)',
            btn_text: 'Submit',
          },
        },
        vanityRules: {
          vanity: [{
            validator: checkVanity,
            trigger: 'change',
          }],
        },
      };
    },
    created() {
      if (this.form.settings) {
        const settings = this.form.settings;
        if (settings.vanity) {
          this.settings.vanity = settings.vanity;
          this.settings.template = settings.template;
        }
      }

      this.fetchTemplates();
      this.fetchPreview();
    },
    mounted() {
      EventBus.$on('close_edit', () => {
        this.edit = false;
        this.fetchPreview();
      });
    },
    beforeDestroy() {
      EventBus.$off('close_edit');
    },
    computed: {
      currentTemplate() {
        if (this.settings.template.id) {
          return this.templates.filter(t => t.id === this.settings.template.id)[0];
        }

        return null;
      },
    },
    methods: {
      confirmEditVanity() {
        this.$confirm('Are you sure you want to change the URL of this Form? The previous one will no longer be valid.').then(() => {
          this.editVanity = true;
        });
      },
      copyVanity() {
        this.$copyText(this.currentUrl).then(() => {
          this.$message.success('Copied!');
        }).catch(() => {
          this.$message.error('We were unable to copy the URL.');
        });
      },
      editDesign() {
        this.edit = !this.edit;
      },
      saveVanity() {
        this.$refs.vanityForm.validate((valid) => {
          if (!valid) {
            return;
          }

          this.loading = true;
          updateForm(this.form.id, { vanity: this.settings.vanity }).then((response) => {
            this.loading = false;
            this.$message.success(response.message);
            this.editVanity = false;
          }).catch((err) => {
            this.loading = false;
            this.$message.error(err.message);
          });
        });
      },
      fetchTemplates() {
        getTemplatesForm().then((response) => {
          this.templates = response.templates;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchPreview() {
        const payload = {
          form_uuid: this.form.uuid,
          template_id: this.settings.template.id,
        };
        getTemplateFormPreview(payload).then((response) => {
          this.$nextTick(() => {
            const iframe = document.getElementById('iframe-small');
            iframe.contentWindow.document.open();
            iframe.contentWindow.document.write(response.html);
            iframe.contentWindow.document.close();
          });
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      selectTemplate(t) {
        this.loading = true;
        this.settings.template.id = t.id;

        updateForm(this.form.id, { settings: this.settings }).then(() => {
          this.fetchPreview();
          this.loading = false;
        }).catch((err) => {
          this.loading = false;
          this.$error.message(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-card + .el-card {
    margin-top: 2rem;
  }

  .templates {
    margin-top: 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
  }

  .templates .template {
    flex: 0 0 25%;
    // box-shadow: 0 2px 4px 0 rgba(217, 231, 240, 0.31);
    border: solid 1px var(--xpl-color-gray-400);
    background-color: #ffffff;
    height: 15rem;
    transition: all 150ms ease-in-out;
    margin-right: .5rem;
    margin-bottom: .5rem;
  }

  .templates .template {
    margin-top: 0;
  }

  .templates .template:last-child {
    margin-right: 0;
  }

  .templates .template:hover,
  .templates .template.active {
    cursor: pointer;
    border-color: var(--xpl-color-secondary-lm);
  }

  .el-card.main + .el-card.main {
    margin-top: 1rem;
  }

  .el-card .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid rgba(217, 231, 240, 1);
  }

  .el-card .preview {
    padding: 1rem;
  }

  .el-card .bottom {
    font-size: .75rem;
    border-top: 1px solid rgba(217, 231, 240, 1);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }

  .el-card .image {
    width: 100%;
    height: 11rem;
    display: block;
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .preview-rendering * {
    font-family: initial !important;
    font-size: initial !important;
    font-weight: initial !important;
    color: initial !important;
  }

  .preview-rendering {
    width: 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    transition: all 300ms ease-in-out;
    /*pointer-events: none !important;*/
  }

  iframe.preview-rendering {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: none;
    width: 100%;
    height: auto;
    overflow: hidden;
    height: 40rem;
  }

  iframe.preview-rendering.mobile {
    max-width: 320px;
  }

  iframe.preview-rendering * {
    /*pointer-events: none;*/
  }
</style>
