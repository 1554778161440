<template>
  <el-card shadow="never">
    <h1>Create your personal account</h1>
    <el-form ref="userSignup" :model="user" :rules="userRules" status-icon @submit.native.prevent>
      <el-row>
        <el-col :span="11">
          <el-form-item prop="first_name">
            <label for="first_name">First Name</label>
            <el-input id="first_name" placeholder="First Name" v-model="user.first_name" />
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="1">
          <el-form-item prop="last_name">
            <label for="last_name">Last Name</label>
            <el-input id="last_name" placeholder="Last Name" v-model="user.last_name" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item prop="email">
        <label for="email">Email Address</label>
        <el-input id="email" placeholder="Email Address" v-model.trim="user.email" :disabled="pikeToken ? true : false" />
      </el-form-item>
      <el-form-item prop="password">
        <label for="password">Password</label>
        <el-input id="password" type="password" placeholder="Password" v-model="user.password" />
      </el-form-item>
      <el-form-item prop="password_confirmation" v-if="!pikeToken">
        <label for="password_confirmation">Confirm Password</label>
        <el-input id="password_confirmation" type="password" placeholder="Password Confirmation" v-model="user.password_confirmation" />
      </el-form-item>
      <p class="small">
        By clicking Continue I agree to the <a href="https://www.brandbot.com/legal/terms-of-service" target="_blank">Terms of Services</a> and <a href="https://www.brandbot.com/legal/acceptable-use-policy" target="_blank">Acceptable Use Policy</a>.
      </p>
      <el-form-item style="text-align: center; margin-top: 1.5rem;">
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="signup" :loading="loading">Continue</el-button>
      </el-form-item>
      <div class="link" v-if="!pikeToken">
        Already have an account? <a href="/login">Login</a>.
      </div>
    </el-form>
  </el-card>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['pikeToken'],
    data() {
      return {
        loading: false,
        userRules: {
          first_name: [{ min: 2, required: true, trigger: 'change', message: 'First name must be at least 2 characters' }],
          last_name: [{ min: 2, required: true, trigger: 'change', message: 'Last name must be at least 2 characters' }],
          email: [{ type: 'email', required: true, trigger: 'change', message: 'A valid email is required' }],
          password: [{ min: 6, required: true, trigger: 'change', message: 'A password of at least 6 characters is required' }],
          password_confirmation: [{ min: 6, required: true, trigger: 'change', message: 'Your passwords must match' }],
        },
        user: {
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          password_confirmation: '',
        },
      };
    },
    created() {
      const user = this.$store.state.user;
      // eslint-disable-next-line
      for (const key in this.user) {
        if (user[key]) {
          this.$set(this.user, key, user[key]);
        }
      }
    },
    methods: {
      signup() {
        this.$refs.userSignup.validate((valid) => {
          if (!valid) {
            return;
          }

          this.loading = true;
          if (this.pikeToken) {
            const payload = {
              first_name: this.user.first_name,
              last_name: this.user.last_name,
              new_password: this.user.password,
              new_password_confirmation: this.user.password,
            };
            api.updateUser(payload, this.$store.state.user.id).then(() => {
              this.loading = false;
              EventBus.$emit('signup.user.done');
            }).catch((err) => {
              this.loading = false;
              if (err.errors) {
                this.$message.error(err.errors[Object.keys(err.errors)[0]][0]);
              } else {
                this.$message
                 .error('We were unable to edit your account. Please try again later.');
              }
            });
          } else {
            api.signup(this.user).then((response) => {
              this.loading = false;
              EventBus.$emit('signup.user.done', response.user, response.tokens);
            }).catch((err) => {
              this.loading = false;
              if (err.errors) {
                this.$message.error(err.errors[Object.keys(err.errors)[0]][0]);
              } else {
                this.$message
                 .error('We were unable to create your account. Please try again later.');
              }
            });
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-card {
    max-width: 500px;
    width: 500px;
    min-width: 500px;
    border: none;
  }

  .el-card h1 {
    font-size: 1.75rem;
    color: #00152a;
    margin-bottom: 2.5rem;
  }

  .el-form-item {
    margin-bottom: 1rem;
  }

  .link {
    text-align: center;
    font-size: .75rem;
    margin-top: .875rem;
  }

  .link a {
    color: var(--xpl-color-secondary-lm);
  }
</style>
