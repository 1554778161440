<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Funnel Management
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <label v-if="action === 'add'">Choose the Funnel Stage you wish to <strong>add</strong> the selected contacts to</label>
                <label v-if="action === 'remove'">Choose the Funnel Stage you wish to <strong>remove</strong> the selected contacts from</label>
                <Multiselect v-model="selected_funnel" placeholder="Select Funnel Stage" :options="funnels" track-by="id" label="stage" :searchable="true" :multiple="false" :allow-empty="false" :show-labels="false">
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <div class="option__title">{{ props.option.stage }}</div>
                      <div class="small" style="margin-top: .25rem;">Funnel: {{ props.option.funnel }}</div>
                    </div>
                  </template>
                </Multiselect>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="button" class="xpl-button xpl-button--primary" @click.stop="save" :disabled="!selected_funnel">Save</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['action', 'contacts', 'allContacts', 'contactListId'],
    components: {
      Multiselect,
    },
    data() {
      return {
        selected_funnel: null,
        funnels: [],
      };
    },
    created() {
      this.fetchFunnels();
    },
    methods: {
      fetchFunnels() {
        api.funnelsWithStages().then((response) => {
          this.funnels = response.funnels;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      save(event) {
        const target = event.target || event.srcElement;
        target.disabled = true;
        target.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';

        const payload = {
          contact_list_id: this.contactListId,
          all_contacts: this.allContacts,
          contact_id: this.contacts.map(c => c.id),
          stage_id: this.selected_funnel.id,
        };

        if (this.action === 'add') {
          api.addContactToFunnel(payload).then((response) => {
            this.$toasted.success(response.message);
            target.disabled = false;
            this.closeModal();
          }).catch((err) => {
            target.disabled = false;
            target.innerHTML = 'Save';
            this.$toasted.error(err.message);
          });
        } else {
          api.removeContactFromFunnel(payload).then((response) => {
            this.$toasted.success(response.message);
            target.disabled = false;
            this.closeModal();
          }).catch((err) => {
            target.disabled = false;
            target.innerHTML = 'Save';
            this.$toasted.error(err.message);
          });
        }
      },
      closeModal() {
        EventBus.$emit('mass_funnel_modal.close');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal .modal-content .modal-body {
    overflow-y: visible;
  }
</style>
