<template>
  <div class="text-center">Your invoice has been downloaded.</div>
</template>
<script>
  import * as api from '@/api';
  import FileSaver from 'file-saver';

  export default {
    props: ['invoiceId'],
    beforeRouteEnter(from, to, next) {
      next((vm) => {
        api.getInvoice(from.params.invoiceId).then((response) => {
          FileSaver.saveAs(new File([response.invoice], 'Invoice', { type: 'application/pdf' }));
        }).catch((err) => {
          vm.$toasted.error(err.message);
        });
      });
    },
  };
</script>
