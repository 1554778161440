<template>
  <el-card v-loading="loading">
    <div class="v2__heading">Notifications Settings</div>
    <div class="display-caption" style="margin-bottom: 1.5rem;">These settings will apply to your account in {{ $store.state.user.current_account.name }}.</div>

    <el-form>
      <el-form-item>
        <el-checkbox v-model="subscribe_activity" label="Be notified when an item is assigned to me"></el-checkbox>
        <div class="small">This controls the emails you receive when a Task, Phone Call Reminder or Scheduled Email is assigned to you.</div>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="subscribe_alert" label="Be notified when I have an unread SMS in my Inbox"></el-checkbox>
        <div class="small">This controls the emails you receive when an SMS is left unread in your Inbox.</div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary":loading="loading" @click.stop="saveNotifications">Save</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
  import { getAccountUser, changeAccountUser } from '@/api';

  export default {
    data() {
      return {
        loading: false,
        accountUserId: this.$store.state.user.current_account.pivot.id,
        subscribe_activity: false,
        subscribe_alert: false,
      };
    },
    created() {
      this.getCurrentMembership();
    },
    methods: {
      getCurrentMembership() {
        this.loading = true;
        getAccountUser(this.accountUserId).then((response) => {
          this.loading = false;
          this.subscribe_activity = response.account_user.subscribe_activity;
          this.subscribe_alert = response.account_user.subscribe_alert;
        }).catch(() => {
          this.loading = false;
          this.$toasted.error('Something wrong happened while fetching the details of this membership...');
        });
      },
      saveNotifications() {
        const accountUser = {
          id: this.accountUserId,
          subscribe_alert: this.subscribe_alert,
          subscribe_activity: this.subscribe_activity,
        };

        this.loading = true;

        changeAccountUser(accountUser).then(() => {
          this.loading = false;
          this.$message.success('Preferences updated!');
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-form-item .small {
    padding-left: 1rem;
  }
</style>
