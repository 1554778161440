<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            Brand Builder
            <div class="steps">
              <span :class="steps.logo ? 'current' : ''" @click.stop="moveTo('logo')">Logo</span>
              <span :class="steps.colors ? 'current' : ''" @click.stop="moveTo('colors')">Colors</span>
              <span :class="steps.fonts_header || steps.fonts_subheader || steps.fonts_body ? 'current' : ''" @click.stop="moveTo('fonts_header')">Fonts</span>
              <span :class="steps.buttons ? 'current' : ''" @click.stop="moveTo('buttons')">Buttons</span>
            </div>
            <div class="btn-close-wrapper" @click.stop="closeBrandBuilder">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <Intro v-if="steps.intro" />
            <Logo v-if="steps.logo" />
            <Colors v-if="steps.colors" />
            <FontsHeader v-if="steps.fonts_header" />
            <FontsSubheader v-if="steps.fonts_subheader" />
            <FontsBody v-if="steps.fonts_body" />
            <Buttons v-if="steps.buttons" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import Intro from './Steps/Intro';
  import Logo from './Steps/Logo';
  import Colors from './Steps/Colors';
  import FontsHeader from './Steps/FontsHeader';
  import FontsSubheader from './Steps/FontsSubheader';
  import FontsBody from './Steps/FontsBody';
  import Buttons from './Steps/Buttons';

  export default {
    components: {
      Intro,
      Logo,
      Colors,
      FontsHeader,
      FontsSubheader,
      FontsBody,
      Buttons,
    },
    data() {
      return {
        steps: {
          intro: true,
          colors: false,
          fonts_header: false,
          fonts_subheader: false,
          fonts_body: false,
          buttons: false,
        },
      };
    },
    created() {
      EventBus.$on('brand_builder.move_to', (step) => {
        this.moveTo(step);
      });
    },
    beforeDestroy() {
      EventBus.$off('brand_builder.move_to');
    },
    methods: {
      moveTo(step) {
        // eslint-disable-next-line
        for (const s in this.steps) {
          this.steps[s] = false;
        }

        this.steps[step] = true;
      },
      closeBrandBuilder() {
        EventBus.$emit('brand_builder.close');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal-dialog {
    max-width: 100vw;
    margin: 0;
  }

  .modal-content {
    height: 100vh;
    border-radius: 0;
  }

  .modal .modal-content .modal-header {
    font-size: .875rem;
    color: rgb(29, 54, 75);
  }

  .modal .modal-content .modal-header .steps {
    font-size: .75rem;
  }

  .modal .modal-content .modal-header .steps span {
    cursor: pointer;
    color: rgb(175, 188, 202);
    margin-right: 1.5rem;
    transition: all 150ms ease-in-out;
  }

  .modal .modal-content .modal-header .steps span:hover {
    color: rgb(53, 151, 222);
  }

  .modal .modal-content .modal-header .steps span.current {
    color: rgb(53, 151, 222);
  }

  .modal .modal-content .modal-header .steps span:last-child {
    margin-right: 0;
  }

  .modal .modal-content .modal-body {
    padding: 0;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
</style>
