<template>
  <div class="conditions-sidebar">
    <GlobalRuleHeader ruleName="" />
    <div class="condition-header-padding">
      <div class="go-back-button" @click="goBack">
          <span class="material-icons">keyboard_arrow_left</span>
          <span class="go-back-text">Go back</span>
        </div>
      <div class="condition-header-style">
        {{ enrollment && selectedCondition.uuid === 'd323c020-0569-4f1e-ab1d-f56672d66610' ? 'Joins a Membership for the first time' : selectedCondition.name }}
      </div>
      <div class="condition-description-style" v-html="selectedCondition.long_description"></div>
    </div>
    <div class="conditions-sidebar-body">
      <div style="width: 100%">
      <CreditPackageExpirationMT :editedRule.sync="editedRule" :condition="selectedCondition" :goalList="goalList" v-if="selectedCondition.uuid === 'ce919016-7dd9-4c50-97d4-64fe6d03f9d2'"/>
      <FutureReservationMT :editedRule.sync="editedRule" :condition="selectedCondition" :goalList="goalList" v-if="selectedCondition.uuid === '88d36c7a-5151-4e3d-9528-13007b0c5d4e'"/>
      <MadeAPurchaseMT :editedRule.sync="editedRule" :condition="selectedCondition" :goalList="goalList" v-if="selectedCondition.uuid === '37a361cc-ded7-48b6-9d70-1c4b7aa08284'"/>
      <AllTimeVisitsMT :editedRule.sync="editedRule" :condition="selectedCondition" :goalList="goalList" v-if="selectedCondition.uuid === '5005b33e-6ea6-45fc-affd-4b2f184efebf'"/>
      <HomeLocationMT :editedRule.sync="editedRule" :condition="selectedCondition" :goalList="goalList" v-if="selectedCondition.uuid === 'e3d9c44d-a03c-4979-8a35-89c8003b2fb8'"/>
      <HasAMembershipMT :editedRule.sync="editedRule" :condition="selectedCondition" :goalList="goalList" v-if="selectedCondition.uuid === '0567f82c-bd39-4850-b4f0-715fe8c8009d'"/>
      <HasATagMT :editedRule.sync="editedRule" :condition="selectedCondition" :goalList="goalList" v-if="selectedCondition.uuid === '4d0702b6-53b4-4e51-ade3-dc9983c635ab'"/>
      <NewProfileMT :editedRule.sync="editedRule" :condition="selectedCondition" :goalList="goalList" v-if="selectedCondition.uuid === 'a59c23dc-9207-4690-887e-563af43188e8'"/>
      <FirstVisitMT :editedRule.sync="editedRule" :condition="selectedCondition" :goalList="goalList" v-if="selectedCondition.uuid === '5898fb00-771c-44b8-b8f0-0a0f7da1fbc6'"/>
      <NumberOfVisitsMT :editedRule.sync="editedRule" :condition="selectedCondition" :goalList="goalList" v-if="selectedCondition.uuid === '6696ed9d-bacc-445e-a8fa-0866201ad53f'"/>
      <FinishedACreditPackageMT :editedRule.sync="editedRule" :condition="selectedCondition" :goalList="goalList" v-if="selectedCondition.uuid === '78b51a87-ca35-4132-8342-ae145b067fc3'"/>
      <StartsCreditPackageMT :editedRule.sync="editedRule" :condition="selectedCondition" :goalList="goalList" v-if="selectedCondition.uuid === '928debf9-8856-41bf-8251-fbcb2776dd3c'"/>
      <MembershipCancelledMT :editedRule.sync="editedRule" :condition="selectedCondition" :goalList="goalList" v-if="selectedCondition.uuid === 'af56f4ee-3104-4e12-9ef4-00b0cda467cd'"/>
      <VisitsRemainingMT :editedRule.sync="editedRule" :condition="selectedCondition" :goalList="goalList" v-if="selectedCondition.uuid === '744156da-5aed-409d-a2cb-6436ae3a6e04'"/>
      <MTLastVisit :editedRule.sync="editedRule" :condition="selectedCondition" :goalList="goalList" v-if="selectedCondition.uuid === 'a037768c-a9cd-4250-abbe-062d27e1b78c'"/>
      <MembershipExpirationMT :editedRule.sync="editedRule" :condition="selectedCondition" :goalList="goalList" v-if="selectedCondition.uuid === '253360f9-ec87-4ee4-8067-1b3f75007acf'"/>
      <MadeAPurchase :editedRule.sync="editedRule" :locations="locations" :condition="selectedCondition" :enrollment="enrollment" :goalList="goalList" v-if="selectedCondition.uuid === '5c48a13c-ac7f-4873-9d23-cba7b05f206e'" />
      <PurchasedAService :editedRule.sync="editedRule" :locations="locations" :condition="selectedCondition" :enrollment="enrollment" :goalList="goalList" v-if="selectedCondition.uuid === 'c9c2da63-8389-4b71-9c7b-5a140966435d'" />
      <PurchasedRetail :editedRule.sync="editedRule" :locations="locations" :condition="selectedCondition" :enrollment="enrollment" :goalList="goalList" v-if="selectedCondition.uuid === 'fb30f318-f078-4263-87d9-c990337a26bd'" />
      <ActivatedPurchase :editedRule.sync="editedRule" :locations="locations" :condition="selectedCondition" :enrollment="enrollment" :goalList="goalList" v-if="selectedCondition.uuid === '033c6574-fc3d-4ee8-9970-889790c432d6'" />
      <FirstPurchase :editedRule.sync="editedRule" :locations="locations" :condition="selectedCondition" v-if="selectedCondition.uuid === '033c6574-fc3d-4ee8-9970-889790c432c5'" />
      <CreatedMindbodyProfile :editedRule.sync="editedRule" :locations="locations" :condition="selectedCondition" :enrollment="enrollment" v-if="selectedCondition.uuid === '202eac6b-5e86-491a-89f6-b306bd101800'" />
      <FirstVisit :editedRule.sync="editedRule" :locations="locations" :condition="selectedCondition" :enrollment="enrollment" v-if="selectedCondition.uuid === 'af62480f-6da6-4519-9d24-de4996a30d98'" />
      <ClassVisitBehavior :editedRule.sync="editedRule" :locations="locations" :condition="selectedCondition" :enrollment="enrollment" v-if="selectedCondition.uuid === '929b071b-bae1-47c9-92a4-ad80c9669e76'" />
      <!-- <EnrollmentBehavior :editedRule.sync="editedRule" :locations="locations" :enrollment="enrollment" :condition="selectedCondition" v-if="selectedCondition.uuid === '8bd62185-5edd-4dab-a03d-5fd64111c385'" /> -->
      <AppointmentBehavior :editedRule.sync="editedRule" :locations="locations" :enrollment="enrollment" :goalList="goalList" :condition="selectedCondition" v-if="selectedCondition.uuid === 'e3d63f59-26d8-4b59-b8db-c7517c1d9b15'" />
      <LastVisit :editedRule.sync="editedRule" :locations="locations" :condition="selectedCondition" :enrollment="enrollment" v-if="selectedCondition.uuid === 'd2b29794-384f-4e75-9ac8-5fe1b4d9c92f'" />
      <NumberVisits :editedRule.sync="editedRule" :locations="locations" :condition="selectedCondition" :goalList="goalList" v-if="selectedCondition.uuid === '82584fc8-ba77-4f57-982a-68f8a52828cb'" />
      <MilestoneVisits :editedRule.sync="editedRule" :locations="locations" :condition="selectedCondition" :enrollment="enrollment" v-if="selectedCondition.uuid === '8cfb3bd2-1742-4be3-9ff6-51a9a42241d8'" />
      <IsAMember :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === 'd323c020-0569-4f1e-ab1d-f56672d66610'" :locations="locations" />
      <HasMindbodyIndex :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '247b2e14-1b8f-4886-9300-2002453a045b'" />
      <HasBrandbotTag :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === 'f511f8f8-cd8f-40af-bc86-99b1faef9a02'" :enrollment="enrollment" :goalList="goalList" />
      <AssignedBrandBotTag :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '18da4f54-1615-4766-b5ee-c2e7b7334a67'" :enrollment="enrollment" :goalList="goalList" />
      <AssignedMindbodyIndex :editedRule.sync="editedRule" :locations="locations" :condition="selectedCondition" :enrollment="enrollment" v-if="selectedCondition.uuid === '61cd69a1-5a1e-4b40-bcc1-1deab882100c'" />
      <Birthday :editedRule.sync="editedRule" :condition="selectedCondition" :enrollment="enrollment" v-if="selectedCondition.uuid === 'e5c8a7af-e573-4d53-82a1-0c8f99bacfec'" />
      <Gender :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === 'b48aa38b-6476-485e-b4f8-f2b1bce1c679'" />
      <IsAProspect :editedRule.sync="editedRule" :locations="locations" :condition="selectedCondition" v-if="selectedCondition.uuid === 'd2916b8f-c632-4bc3-a827-ed92306ba5be'" />
      <BecameAProspect :editedRule.sync="editedRule" :locations="locations" :condition="selectedCondition" :enrollment="enrollment" v-if="selectedCondition.uuid === 'bcaae287-30a7-4d31-a591-13d55f17b463'" />
      <BecameAMember :editedRule.sync="editedRule" :locations="locations" :condition="selectedCondition" :enrollment="enrollment" v-if="selectedCondition.uuid === '27fb545f-fc3f-4cd0-9b4c-6de1983a3a8b'" />
      <TerminatedMembership :editedRule.sync="editedRule" :locations="locations" :condition="selectedCondition" :enrollment="enrollment" v-if="selectedCondition.uuid === '8c9b97d5-9656-47f7-a9de-86871d9402b0'" />
      <OutOfTowners :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '78dafa01-5b3a-428d-91f1-c96b77440661'" />
      <InteractedWithEmail :editedRule.sync="editedRule" :condition="selectedCondition" :enrollment="enrollment" v-if="selectedCondition.uuid === '9ebf3698-2407-4d70-97f1-afa09adddac9'" />
      <JoinedSignupForm :editedRule.sync="editedRule" :condition="selectedCondition" :enrollment="enrollment" :goalList="goalList" v-if="selectedCondition.uuid === 'ac08df3e-b14a-4760-8068-54585cb19d9e'" />
      <Unsubscribed :editedRule.sync="editedRule" :condition="selectedCondition" :enrollment="enrollment" :goalList="goalList" v-if="selectedCondition.uuid === '2fad64a8-f2e4-47e7-9429-36b18877850a'" />
      <UnsubscribedSMS :editedRule.sync="editedRule" :condition="selectedCondition" :enrollment="enrollment" :goalList="goalList" v-if="selectedCondition.uuid === '15929b17-3492-4bc6-91c2-253f7022b3b5'" />
      <PreferredLocation :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '5a03d4d7-648c-47a3-99a0-2b9b51b38886'" :locations="locations" />
      <FutureReservation :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '60e30ad7-0fdf-46f8-a691-b3d2705cd192'" :locations="locations" />
      <InSegment :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === 'a848f0f6-29c9-4f85-a4d0-bc188f09670b'" :locations="locations" />
      <RepliedToSMSWithKeyword :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '3992bfd3-9d39-4811-86d2-0e85675001d6'" />
      <FunnelStage :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '72a8d969-dc52-4131-a1a6-0feb2123a92f'" />
      <ActiveClassPackage :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '14eae5d2-4de0-4128-bba6-93b3f604d93f'" />
      <ClassPackageExpired :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '68d7c04d-049a-4554-9925-913a07b86e9f'" />
      <FinishedClassPackage :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === 'a80f3899-6b98-49ed-9a3b-e87f1b11be17'" :enrollment="enrollment" :goalList="goalList" />
      <GeneralEvent :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '989c7172-3159-4846-98a9-298393002583'" :enrollment="enrollment" :goalList="goalList" />
      <LandedLandingPage :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '65f2cd79-db45-4005-bea3-eeca722d4bea'" :enrollment="enrollment" :goalList="goalList" />
      <FinishedLandingPage :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '79256903-1854-41c5-8b40-f256ed2c5fb8'" :enrollment="enrollment" :goalList="goalList" />
      <AbandonnedLandingPage :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === 'c9187025-81e2-4475-b469-cdc054a156a6'" :enrollment="enrollment" :goalList="goalList" />
      <AnsweredAForm :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '7302c598-ab99-4091-9090-69850d4dc50e'" :enrollment="enrollment" :goalList="goalList" />
      <AnsweredWaiver :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '956e61a7-73be-4c41-9a7c-9b7958352b93'" :enrollment="enrollment" :goalList="goalList" />

      <Purchase :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === 'ff56be42-b88b-4abe-82b3-c47e5d485660'" :goalList="goalList" />
      <PikeNumberOfVisits :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '1e6a8fb2-8205-41fa-87a8-a46a0c2327dd'" :goalList="goalList" />
      <StartsAPlan :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '36fd8c92-65ea-4395-8429-c443afad1eae'" :goalList="goalList" />
      <CancelledAPlan :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '3c4060ad-f432-4655-9932-15ddc09908c1'" :goalList="goalList" />
      <HasAPlan :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '2d5f2b5c-1620-451a-96fb-0f96bab671e0'" />
      <Pike13CreatedProfile :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '737adbd7-24b9-417f-a079-d245cd96a33c'" :goalList="goalList" />
      <PikeLastVisit :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '10f161c2-3998-4922-a720-31bfba7744ff'" />
      <PikeFirstVisit :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '919d821d-4f0c-4993-b916-f02607fd901f'" :goalList="goalList" />
      <PikeIsAMember :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === 'b0e97a9c-6cb4-4881-bacb-34e41e2cfeda'" />
      <PikeHasPaymentOnFile :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '383a2307-b002-467e-b22a-217ee9660a80'" />
      <PikeVisitBehavior :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === 'ce7443b8-75f9-4149-8a13-7db1577bf2d2'" :goalList="goalList" />
      <PikeCustomFields :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === 'b4d57f9f-2515-42d3-8e17-4e48d106586e'" />
      <PikeFutureReservation  :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '8cdf7ca6-5f7d-416d-b1ba-23386c7ebff0'" />
      <PlanEnded :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === 'ff738193-e67f-4c0c-9529-c791740a9f45'" :goalList="goalList" />
      <IsStaffMember :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === 'fd44a734-bbd7-48d7-ae0b-d8399a8e37aa'" :goalList="goalList" />
      <PikeIsAGuardian :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '39009e2f-a4ec-4dc4-8670-0bfb69c5ca9d'" />
      <PikeHasAccountCredit :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === 'ebcd7844-1439-4952-a178-ee8aaf1f045a'" />
      <NewInvoice :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '4a8fa253-b3ea-462d-a160-5447375af8f1'" />
      <ATCartAbandonment :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '37a361cc-ded7-48b6-9d70-1c4b7aa08285'" :goalList="goalList" />

      <IsVimeoCustomer :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === '71f9ae9e-b852-40c1-b6c6-f79322f7f4bf'" />
      <HasVimeoSubscription :editedRule.sync="editedRule" :condition="selectedCondition" v-if="selectedCondition.uuid === 'feb848d2-d242-44b4-8754-80ef489aa963'" />
        <hr style="border-color: transparent; height: 25vh"/>
    </div>
    </div>
  </div>
</template>
<script>
import EventBus from '@/event-bus';
import GlobalRuleHeader from '@/components/Global/GlobalRuleHeader';
import CreatedMindbodyProfile from './CreatedMindbodyProfile';
import MadeAPurchase from './MadeAPurchase';
import FirstPurchase from './FirstPurchase';
import ActivatedPurchase from './ActivatedPurchase';
import FirstVisit from './FirstVisit';
import ClassVisitBehavior from './ClassVisitBehavior';
import EnrollmentBehavior from './EnrollmentBehavior';
import AppointmentBehavior from './AppointmentBehavior';
import LastVisit from './LastVisit';
import NumberVisits from './NumberVisits';
import MilestoneVisits from './MilestoneVisits';
import IsAMember from './IsAMember';
import HasMindbodyIndex from './HasMindbodyIndex';
import Birthday from './Birthday';
import Gender from './Gender';
import AssignedMindbodyIndex from './AssignedMindbodyIndex';
import IsAProspect from './IsAProspect';
import BecameAProspect from './BecameAProspect';
import BecameAMember from './BecameAMember';
import TerminatedMembership from './TerminatedMembership';
import OutOfTowners from './OutOfTowners';
import InteractedWithEmail from './InteractedWithEmail';
import JoinedSignupForm from './JoinedSignupForm';
import AnsweredAForm from './AnsweredAForm';
import AnsweredWaiver from './AnsweredWaiver';
import Unsubscribed from './Unsubscribed';
import HasBrandbotTag from './HasBrandbotTag';
import AssignedBrandBotTag from './AssignedBrandBotTag';
import PreferredLocation from './PreferredLocation';
import FutureReservation from './FutureReservation';
import InSegment from './InSegment';
import RepliedToSMSWithKeyword from './RepliedToSMSWithKeyword';
import FunnelStage from './FunnelStage';
import ActiveClassPackage from './ActiveClassPackage';
import ClassPackageExpired from './ClassPackageExpired';
import FinishedClassPackage from './FinishedClassPackage';
import PikeNumberOfVisits from './Pike13/NumberOfVisits';
import PikeFutureReservation from './Pike13/PikeFutureReservation';
import PlanEnded from './Pike13/PlanEnded';
import HasAPlan from './Pike13/HasAPlan';
import PikeLastVisit from './Pike13/PikeLastVisit';
import PikeFirstVisit from './Pike13/PikeFirstVisit';
import StartsAPlan from './Pike13/StartsAPlan';
import Pike13CreatedProfile from './Pike13/Pike13CreatedProfile';
import CancelledAPlan from './Pike13/CancelledAPlan';
import Purchase from './Pike13/Purchase';
import PikeIsAMember from './Pike13/PikeIsAMember';
import ATCartAbandonment from './Pike13/ATCartAbandonment';
import PikeHasPaymentOnFile from './Pike13/PikeHasPaymentOnFile';
import PikeVisitBehavior from './Pike13/PikeVisitBehavior';
import PikeCustomFields from './Pike13/PikeCustomFields';
import IsStaffMember from './Pike13/IsStaffMember';
import PikeIsAGuardian from './Pike13/PikeIsAGuardian';
import PikeHasAccountCredit from './Pike13/PikeHasAccountCredit';
import NewInvoice from './Pike13/NewInvoice';
import PurchasedAService from './PurchasedAService';
import PurchasedRetail from './PurchasedRetail';
import GeneralEvent from './GeneralEvent';
import LandedLandingPage from './LandedLandingPage';
import FinishedLandingPage from './FinishedLandingPage';
import AbandonnedLandingPage from './AbandonnedLandingPage';
import UnsubscribedSMS from './UnsubscribedSMS';
import HomeLocationMT from './MarianaTek/HomeLocationMT';
import AllTimeVisitsMT from './MarianaTek/AllTimeVisitsMT';
import HasAMembershipMT from './MarianaTek/HasAMembershipMT';
import VisitsRemainingMT from './MarianaTek/VisitsRemainingMT';
import HasATagMT from './MarianaTek/HasATagMT';
import NewProfileMT from './MarianaTek/NewProfileMT';
import MTLastVisit from './MarianaTek/MTLastVisit';
import StartsCreditPackageMT from './MarianaTek/StartsCreditPackageMT';
import FirstVisitMT from './MarianaTek/FirstVisitMT';
import NumberOfVisitsMT from './MarianaTek/NumberOfVisitsMT';
import MembershipCancelledMT from './MarianaTek/MembershipCancelledMT';
import MembershipExpirationMT from './MarianaTek/MembershipExpirationMT';
import FinishedACreditPackageMT from './MarianaTek/FinishedACreditPackageMT';
import MadeAPurchaseMT from './MarianaTek/MadeAPurchaseMT';
import FutureReservationMT from './MarianaTek/FutureReservationMT';
import CreditPackageExpirationMT from './MarianaTek/CreditPackageExpirationMT';
import IsVimeoCustomer from './Vimeo/IsVimeoCustomer';
import HasVimeoSubscription from './Vimeo/HasVimeoSubscription';


export default {
    props: ['selectedCondition', 'editedRule', 'locations', 'enrollment', 'goalList'],
    components: {
      GlobalRuleHeader,
      GeneralEvent,
      Purchase,
      ATCartAbandonment,
      Pike13CreatedProfile,
      PikeIsAMember,
      PikeVisitBehavior,
      PikeCustomFields,
      CancelledAPlan,
      HasAPlan,
      PikeLastVisit,
      PikeFirstVisit,
      StartsAPlan,
      PlanEnded,
      PikeNumberOfVisits,
      PikeFutureReservation,
      PurchasedAService,
      PurchasedRetail,
      CreatedMindbodyProfile,
      MadeAPurchase,
      ActivatedPurchase,
      FirstVisit,
      ClassVisitBehavior,
      EnrollmentBehavior,
      AppointmentBehavior,
      LastVisit,
      NumberVisits,
      FirstPurchase,
      MilestoneVisits,
      IsAMember,
      HasMindbodyIndex,
      Birthday,
      Gender,
      AssignedMindbodyIndex,
      IsAProspect,
      BecameAProspect,
      BecameAMember,
      TerminatedMembership,
      OutOfTowners,
      InteractedWithEmail,
      JoinedSignupForm,
      Unsubscribed,
      HasBrandbotTag,
      AssignedBrandBotTag,
      PreferredLocation,
      FutureReservation,
      InSegment,
      RepliedToSMSWithKeyword,
      FunnelStage,
      ActiveClassPackage,
      ClassPackageExpired,
      FinishedClassPackage,
      LandedLandingPage,
      FinishedLandingPage,
      AbandonnedLandingPage,
      IsStaffMember,
      AnsweredAForm,
      AnsweredWaiver,
      PikeHasPaymentOnFile,
      PikeIsAGuardian,
      PikeHasAccountCredit,
      NewInvoice,
      HomeLocationMT,
      AllTimeVisitsMT,
      HasAMembershipMT,
      VisitsRemainingMT,
      HasATagMT,
      NewProfileMT,
      StartsCreditPackageMT,
      FirstVisitMT,
      UnsubscribedSMS,
      MTLastVisit,
      NumberOfVisitsMT,
      MembershipCancelledMT,
      MembershipExpirationMT,
      FinishedACreditPackageMT,
      MadeAPurchaseMT,
      FutureReservationMT,
      CreditPackageExpirationMT,
      IsVimeoCustomer,
      HasVimeoSubscription,
    },
    methods: {
      goBack() {
        if (this.editedRule) {
          EventBus.$emit('selected_condition.close');
        } else if (!this.editedRule) {
          EventBus.$emit('condition_selection.back');
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .condition-header-padding {
    padding: 1.5rem 1.875rem 1.875rem 1.5rem;
    border-bottom: solid 1px #ccd6e0;
    background-color: white;
  }
  .condition-header-style {
    margin-top: 0;
    padding-top: 0;
    font-size: 26px;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.15px;
    color: #161e27;
  }

  .back-button {
    height: 1.5rem;
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    cursor: pointer;
  }

  .back-button .material-icons {
    flex-shrink: 0;
    flex-grow: 0;
    color: #afbcca;
    font-size: 1rem;
    transition: color 150ms ease-in-out;
  }

  .back-button .text {
    margin-left: 6px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #161e27;
  }

  .back-button:hover .material-icons {
    color: #1D364B;
  }

  .conditions-sidebar {
    background-color: #F4F8FA;
    border-left: 1px solid lightgray;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .conditions-sidebar-body {
    padding: 0;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: calc(100% - 134px) !important;
  }
  .go-back-button {
    position: relative;
    right: 6px;
    bottom: 9px;
    cursor: pointer;
  }
  .go-back-text {
    position: relative;
    bottom: 7px;
    right: 5px;
    color: #617080;
    font-size: 14px;
    cursor: pointer;
  }
</style>
