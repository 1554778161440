<template>
  <div>
    <div class="card">
      <div class="card-header card-header-merged card-header-white">
        <div class="title">
          <span :class="['step-number', setupComplete ? 'complete' : '']">
            <span v-if="!setupComplete">1</span>
            <span class="icon icon-checkmark" v-if="setupComplete"></span>
          </span>
          <span v-if="campaignItem.type === 'email'">Choose when to send this Email</span>
          <span v-if="campaignItem.type === 'task'">Choose when to create this Task</span>
          <span v-if="campaignItem.type === 'phone_call'">Choose when to create this Phone Call Reminder</span>
          <span v-if="campaignItem.type === 'tag' && campaignItem.name !=='MT Action'">Choose when to create this Tag</span>
          <span v-if="campaignItem.type === 'sms' && campaignItem.name !=='Webhook'">Choose when to send this SMS</span>

          <span v-if="campaignItem.type === 'tag' && campaignItem.name ==='MT Action'">Choose when to create this Mariana Tek Action</span>
          <span v-if="campaignItem.type === 'sms'&& campaignItem.name ==='Webhook'">Choose when to send this Webhook</span>

        </div>
        <div>
          <button class="xpl-button xpl-button--secondary" v-if="campaignItem.time_rule.choice && !edit && !hideButtons" @click.prevent="editCampaignItem">Edit</button>
        </div>
      </div>
      <div class="card-body">
        <div class="quick-options" v-if="(!option && !setupComplete)">
          <div class="options">
            <div class="option" @click="selectOption('relative')">
              <div class="icon">
                <img src="https://assets.brandbot.com/public/campaigns/time-only.svg" />
              </div>
              <div class="name">
                <div class="header">Time delay</div>
                <div v-if="campaignItem.type === 'email'">Send this email a certain amount of time after a contact enters this auto-campaign</div>
                <div v-if="campaignItem.type === 'task'">Create this task a certain amount of time after a contact enters this auto-campaign</div>
                <div v-if="campaignItem.type === 'phone_call'">Create this phone call reminder a certain amount of time after a contact enters this auto-campaign</div>
                <div v-if="campaignItem.type === 'tag' && campaignItem.name !=='MT Action'">Create this tag a certain amount of time after a contact enters this auto-campaign</div>
                <div v-if="campaignItem.type === 'sms' && campaignItem.name !=='Webhook'">Send this SMS a certain amount of time after a contact enters this auto-campaign</div>

                <div v-if="campaignItem.type === 'tag' && campaignItem.name ==='MT Action'">Create this Action a certain amount of time after a contact enters this auto-campaign</div>
                <div v-if="campaignItem.type === 'sms' && campaignItem.name ==='Webhook'">Create this Webhook a certain amount of time after a contact enters this auto-campaign</div>
              </div>
              <div class="examples">
                <a href="http://docs.brandbot.com/auto-campaigns/auto-messages-and-auto-actions/time-delay" target="_blank" class="xpl-button xpl-button--secondary">Learn More</a>
              </div>
            </div>
            <div class="option" @click="selectOption('both')">
              <div class="icon">
                <img src="https://assets.brandbot.com/public/campaigns/time-behavior.svg" />
              </div>
              <div class="name">
                <div class="header">Time delay with an additional rule</div>
                <div v-if="campaignItem.type === 'email'">Send this email after a delay, but only if the contact meets an additional rule</div>
                <div v-if="campaignItem.type === 'task'">Create this task after a delay, but only if the contact meets an additional rule</div>
                <div v-if="campaignItem.type === 'phone_call'">Create this phone call reminder after a delay, but only if the contact meets an additional rule</div>
                <div v-if="campaignItem.type === 'tag' && campaignItem.name !=='MT Action'">Create this tag after a delay, but only if the contact meets an additional rule</div>
                <div v-if="campaignItem.type === 'sms' && campaignItem.name !=='Webhook'">Send this SMS after a delay, but only if the contact meets an additional rule</div>

                <div v-if="campaignItem.type === 'tag' && campaignItem.name ==='MT Action'">Create this Action after a delay, but only if the contact meets an additional rule</div>
                <div v-if="campaignItem.type === 'sms' && campaignItem.name ==='Webhook'">Create this Webhook after a delay, but only if the contact meets an additional rule</div>
              </div>
              <div class="examples">
                <a href="http://docs.brandbot.com/auto-campaigns/auto-messages-and-auto-actions/time-delay-with-an-additional-rule" target="_blank" class="xpl-button xpl-button--secondary">Learn More</a>
              </div>
            </div>
            <div class="option" @click="selectOption('behavior')">
              <div class="icon">
                <img src="https://assets.brandbot.com/public/campaigns/behavior-only.svg" />
              </div>
              <div class="name">
                <div class="header">Right when a rule is met</div>
                <div>When a contact in this campaign meets a specific behavior rule</div>
              </div>
              <div class="examples">
                <a href="http://docs.brandbot.com/auto-campaigns/auto-messages-and-auto-actions/right-when-a-rule-is-met" target="_blank" class="xpl-button xpl-button--secondary">Learn More</a>
              </div>
            </div>
          </div>
        </div>

        <div class="selected-options" v-if="(option && !setupComplete && !edit) || edit">
          <div class="btn-back" @click.prevent="clearSetup">
            <span class="material-icons">keyboard_arrow_left</span>
            Back to options
          </div>
          <CampaignItemSetupTime v-if="option === 'relative'" :campaignItem="campaignItem" :campaign="campaign" />
          <CampaignItemSetupBoth v-if="option === 'both'" :campaignItem="campaignItem" :campaign="campaign" />
          <CampaignItemSetupBehavior v-if="option === 'behavior'" :campaignItem="campaignItem" :campaign="campaign" />
        </div>

        <div class="selected-options" v-if="setupComplete && !edit">
          <CampaignItemEnglish :type="campaignItem.type" :timeRule="campaignItem.time_rule" :behaviorRule="campaignItem.behavior_rule" :webHook="this.campaignItem.name"/>

          <div v-if="campaignItem.behavior_rule && campaignItem.behavior_rule.rule && campaignItem.behavior_rule.rule.length && !campaignItem.behavior_rule.rule[0].condition.quick && campaignItem.time_rule.choice !== 'relative'">
            <div>
              <RuleEnglishHybrid :rule="campaignItem.behavior_rule.rule" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import RuleEnglish from '@/components/Contacts/ContactLists/Conditions/RuleEnglish';
  import CampaignItemSetupTime from './CampaignItemSetupTime';
  import CampaignItemSetupBoth from './CampaignItemSetupBoth';
  import CampaignItemSetupBehavior from './CampaignItemSetupBehavior';
  import CampaignItemEnglish from './CampaignItemEnglish';
  import RuleEnglishHybrid from '../../Contacts/ContactLists/Conditions/RuleEnglishHybrid';

  export default {
    props: ['campaignItem', 'campaign'],
    data() {
      return {
        edit: false,
        option: null,
      };
    },
    components: {
      RuleEnglishHybrid,
      RuleEnglish,
      CampaignItemEnglish,
      CampaignItemSetupTime,
      CampaignItemSetupBoth,
      CampaignItemSetupBehavior,
    },
    mounted() {
      EventBus.$on('campaign_item.commit', () => {
        this.option = null;
        this.edit = false;
        this.updateCampaignItem();
      });

      EventBus.$on('condition.commit', (payload) => {
        this.campaignItem.behavior_rule.rule = [payload];
        this.updateCampaignItem();
        this.edit = false;
        this.option = null;
      });
      EventBus.$on('campaign_item.toggle', () => {
        this.edit = false;
      });
    },
    beforeDestroy() {
      EventBus.$off('campaign_item.commit');
      EventBus.$off('condition.commit');
      EventBus.$off('campaign_item.toggl');
    },
    computed: {
      hideButtons() {
        // this magical array contains some non-BB users who can sync campaigns
        if (this.$store.getters.isStaff ||
          [2, 6, 7, 445, 1391, 2213, 2867, 3332, 3370, 2560, 3463, 3468, 3369, 4190, 4758, 7763, 11440]
          .indexOf(this.$store.state.user.id) > -1) {
          return false;
        }
        
         if (this.$store.getters.hqAdminPermission) {
          return false;
        }

        if (this.campaign.sync_type && this.campaign.sync_type === 'sync_lock') {
          return true;
        }

        if (this.campaign.sync_type && this.campaign.sync_type === 'sync_unlock') {
          return true;
        }

        return false;
      },
      setupComplete() {
        if (this.campaignItem.time_rule.choice && this.campaignItem.time_rule.choice === 'relative') {
          return (
            this.campaignItem.time_rule.choice &&
            this.campaignItem.time_rule.relative &&
            this.campaignItem.time_rule.relative_count
            ) || (
            this.campaignItem.time_rule.choice &&
            this.campaignItem.time_rule.relative === 'Immediately');
        }

        if (this.campaignItem.time_rule.choice && this.campaignItem.time_rule.choice === 'both') {
          return (
            this.campaignItem.time_rule.relative &&
            this.campaignItem.time_rule.relative_count &&
            this.campaignItem.behavior_rule.rule &&
            this.campaignItem.behavior_rule.rule.length
            ) || (
            this.campaignItem.time_rule.relative === 'Immediately' &&
            this.campaignItem.behavior_rule.rule &&
            this.campaignItem.behavior_rule.rule.length);
        }

        if (this.campaignItem.time_rule.choice && this.campaignItem.time_rule.choice === 'behavior') {
          return this.campaignItem.behavior_rule.rule && this.campaignItem.behavior_rule.rule.length;
        }

        return false;
      },
    },
    methods: {
      editCampaignItem() {
        if (this.campaignItem.turned_on_at) {
          this.$toasted.error('Please pause your item to make any edits.');
          return;
        }

        this.edit = true;
        this.option = this.campaignItem.time_rule.choice;
      },
      clearSetup() {
        const payload = {
          choice: null,
          relative: null,
          relative_count: null,
          rule: null,
        };
        EventBus.$emit('campaign_item.refresh', payload);

        this.option = null;
        this.edit = false;
      },
      selectOption(option) {
        this.edit = true;
        this.campaignItem.time_rule.choice = option;
        this.option = option;
      },
      updateCampaignItem() {
        const payload = {
          choice: this.campaignItem.time_rule.choice,
          relative: this.campaignItem.time_rule.relative,
          relative_count: this.campaignItem.time_rule.relative_count * 1,
        };

        api.updateCampaignItem(this.campaignItem.id, payload).then(() => {
          const payload2 = {
            contact_list_id: this.campaignItem.behavior_rule.id,
            rule: JSON.stringify(this.campaignItem.behavior_rule.rule),
          };
          api.saveSmartRule(payload2).then((response) => {
            this.$toasted.success(response.message);
            EventBus.$emit('campaign_item.refresh');
          }).catch((err) => {
            this.$toasted.error(err.message);
          });
          EventBus.$emit('campaign_items_list.refresh');
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .card {
    border: none;
    background-color: rgb(255, 255, 255);
    // box-shadow: 0 4px 4px 0 rgba(97, 112, 128, 0.05);
  }

  .card-header.card-header-merged {
    height: auto;
    padding-left: 1.5rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-right: 1.5rem;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    color: #1D364B;
    line-height: 1.5rem;
    font-size: 1rem;
    margin-bottom: .25rem;
  }

  .step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 99rem;
    background-color: var(--xpl-color-secondary-lm);
    margin-right: 1rem;
    color: white;
    font-size: .75rem;
  }

  .step-number.complete {
    background-color: white;
    border: 2px solid var(--xpl-color-secondary-lm);
    color: var(--xpl-color-secondary-lm);
  }

  .quick-options {
    padding-left: 2.5rem;
  }

  .quick-options .title {
    text-transform: uppercase;
    color: rgb(175, 188, 202);
    font-weight: 700;
    font-size: .625rem;
    line-height: 2;
    margin-bottom: .5rem;
  }

  .quick-options .options .option {
    width: 100%;
    display: flex;
    border: 1px solid var(--xpl-color-gray-400);
    border-radius: 6px;
    background-color: #FFFFFF;
    padding-top: .75rem;
    padding-bottom: .75rem;
    padding-left: .675rem;
    padding-right: .675rem;
  }

  .quick-options .options .option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .875rem;
    color: #1D364B;
    transition: all 300ms ease-in-out;
    margin-bottom: .5rem;
  }

  .quick-options .options .option:hover {
    cursor: pointer;
    border: 1px solid var(--xpl-color-secondary-lm);
    // box-shadow: 0 2px 4px 0 var(--xpl-color-gray-400);
  }

  .quick-options .options .option .icon {
    width: 2.25rem;
    height: 2.25rem;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 1rem;
    border-radius: 6px;
    background-color: #EFF6FD;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 300ms ease-in-out;
  }

  .quick-options .options .option:hover .icon {
    background-color: var(--xpl-color-secondary-bg-lm);
  }

  .quick-options .options .option .icon img {
    width: 1rem;
    max-width: 1rem;
  }

  .quick-options .options .option .name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: .875rem;
    color: rgba(29, 54, 75, .55);
  }

  .quick-options .options .option .name .header {
    margin-right: 1rem;
    color: rgb(29, 54, 75);
    font-weight: bold;
  }

  .selected-options {
    padding-left: 2.5rem;
  }

  .selected-options .btn-back {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: rgb(105, 139, 156);
    font-size: .75rem;
    margin-bottom: 2rem;
  }

  .selected-options .btn-back .material-icons {
    font-size: 1rem;
    margin-right: .25rem;
    line-height: 1;
    font-weight: bold;
  }

  .delete {
    margin-left: .5rem;
    font-size: 1rem;
    cursor: pointer;
    color: #d62929;
  }

  .english {
    padding-left: 2.5rem;
    padding-top: .875rem;
  }

  .help {
    margin-top: 2.5rem;
    text-align: center;
  }

  button + a {
    margin-left: .5rem;
  }

  .behavior {
    font-size: 1rem;
    line-height: 1.85;
    color: rgb(0, 21, 42);
    margin-bottom: 1.5rem;
  }

  .option .examples {
    margin-left: auto;
    opacity: 0;
    transition: all 300ms ease-in-out;
  }

  .option:hover .examples {
    opacity: 1;
  }

  .option .examples a:hover {
    color: black;
  }
</style>
