<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Add a Note
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <textarea class="form-control" v-model="note"></textarea>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="submit" ref="submit" class="xpl-button xpl-button--primary" @click.stop="save" :disabled="!note.length">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['contactId'],
    data() {
      return {
        note: '',
      };
    },
    methods: {
      closeModal() {
        EventBus.$emit('add_note.close');
      },
      save() {
        if (!this.note.length) {
          return;
        }

        this.$refs.submit.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';
        this.$refs.submit.disabled = true;

        const payload = {
          contact_id: this.contactId,
          note: this.note,
        };

        api.addContactNote(payload).then((response) => {
          this.$toasted.success(response.message);
          this.closeModal();
        }).catch((err) => {
          this.$refs.submit.innerHTML = 'Save';
          this.$refs.submit.disabled = false;
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .selected-tags {
    margin-bottom: 1rem;
  }

  .selected-tags .fa-times {
    cursor: pointer;
  }

  .tags {
    margin-top: 1rem;
  }

  .tags .tag {
    padding: .5rem;
    font-size: .875rem;
    background-color: white;
    margin-top: .25rem;
    margin-bottom: .25rem;
    border: 1px solid #cdd7e2;
  }

  .tags .tag:hover,
  .tags .tag.selected {
    cursor: pointer;
    background-color: var(--xpl-color-gray-400);
  }
</style>
