<template>
  <div class="condition">
    <form @submit.prevent>
      <div class="form-group">
        Any contact after their first visit at your business with&nbsp;
        <Multiselect style="width: 20rem;" v-model="filtering" :options="filters" label="label" track-by="value" :show-labels="false" />
      </div>
      <div class="multiselect-block" v-if="filtering && filtering.value">
        <Multiselect v-model="selected_pricing_options" placeholder="Choose pricing option(s)" :options="pricing_options" label="name" track-by="id" :searchable="true" :allow-empty="true" :show-labels="false" :multiple="true" :close-on-select="false" />
      </div>
      <div>
        <button class="xpl-button xpl-button--primary" @click.stop="commitCondition" :disabled="filtering.value && !selected_pricing_options.length">Save</button>
      </div>
    </form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['uuid', 'rule', 'goal'],
    components: {
      Multiselect,
    },
    data() {
      return {
        filtering: {
          label: 'Any pricing option',
          value: false,
        },
        filters: [{
          label: 'Any pricing option',
          value: false,
        }, {
          label: 'Any of the following pricing options',
          value: true,
        }],
        pricing_options: [],
        selected_pricing_options: [],
      };
    },
    created() {
      this.fetchPricingOptions();

      if (this.rule && this.rule.length && this.rule[0].pricing_options) {
        this.selected_pricing_options = this.rule[0].pricing_options;
      }

      if (this.rule && this.rule.length && this.rule[0].pricing_options.length) {
        this.filtering = {
          label: 'Specific pricing option(s)',
          value: true,
        };
      }
    },
    methods: {
      fetchPricingOptions() {
        api.getPricingOptions().then((response) => {
          this.pricing_options = response.pricing_options;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition(event) {
        const target = event.target || event.srcElement;
        target.disabled = true;
        target.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';

        const payload = {
          condition: {
            uuid: this.uuid,
            quick: true,
          },
          timeframe: 'relative',
          filter_pricing_options: this.selected_pricing_options.length,
          pricing_options: this.selected_pricing_options,
          filter_classes: false,
          start_date: '',
          end_date: '',
          comparison: 'within',
          relative_days: 2,
          misc: {
            filtering: false,
          },
        };

        if (this.goal) {
          payload.type = 'goal';
        }

        EventBus.$emit('condition.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .form-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .multiselect-block {
    display: block;
    width: 100%;
  }

  .multiselect-block .multiselect {
    width: 100%;
  }

  form .multiselect {
    display: inline-block;
    width: auto;
  }

  .btn-bb-primary {
    margin-top: 1.5rem;
  }
</style>
