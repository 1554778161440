<template>
  <transition name="input">
    <div>
      <div class="d-flex mb-10">
        <el-radio class="desc-text margin-none" v-model="selectedOption" @change="$emit('update:filterOption', selectedOption)" label="any" border>
          {{ descLabel }}
        </el-radio>
        <el-radio class="desc-text margin-none" v-model="selectedOption" @change="$emit('update:filterOption', selectedOption)" label="except" border>
          Any except the following
        </el-radio>
        <el-radio class="desc-text margin-none" v-model="selectedOption" @change="$emit('update:filterOption', selectedOption)" label="all" border v-if="showAny">
          Any
        </el-radio>
      </div>
      <el-select
      @change="$emit('update:choice', selectedChoice)"
      v-model="selectedChoice"
      class="max-width"
      multiple
      filterable
      collapse-tags
      clearable
      size="large"
      :value-key="selectKey"
      placeholder="Select">
        <el-option v-for="item in options"
          :key="item.id"
          :label="item.name"
          :value="item">
        </el-option>
      </el-select>
    </div>
  </transition>
</template>
<script>
  export default {
    props: [
      'descLabel',
      'filterOption',
      'choice',
      'options',
      'selectKey',
      'showAny',
    ],
    data() {
      return {
        selectedOption: this.filterOption,
        selectedChoice: this.choice,
      };
    },
  };
</script>
<style lang="scss" scoped>
.max-width {
  width: 100% !important;
}
.mb-10 {
  margin-bottom: 10px;
}
</style>
