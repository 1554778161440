<template>
  <transition>
    <!--    -->
    <div v-if="!selectedCondition" class="conditions-sidebar selection">
      <div class="conditions-sidebar-header">
        <div class="back-button" @click="goBackFromConditionList">
          <span class="material-icons">arrow_back</span>
        </div>
        Select a condition to add
      </div>
      <div class="conditions-sidebar-body">
        <el-form @submit.native.prevent>
          <el-form-item>
            <label>Conditions Filtering</label>
            <el-input v-model="query" placeholder="Search..."/>
          </el-form-item>
        </el-form>
        <div class="condition-types">
          <LoadingBox v-if="isLoading"/>
          <div class="condition-type" v-for="condition in this.conditionTypesFiltered"
               @click="pickCondition(condition)"
               :key="condition.uuid">
            <span class="label-dev" v-if="condition.version_status === 'dev'">DEV</span>
            <img alt="icon" :src="condition.icon_url"/>
            <div>
              <div class="header">{{ condition.name }}</div>
              <div class="description" v-html="condition.description"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    -->
    <div v-else class="conditions-sidebar rules">
      <GlobalRuleHeader ruleName=""/>
      <div class="condition-header-padding">
        <div>
          <span class="label-dev" v-if="condition.version_status === 'dev'">DEV</span>
          <div class="go-back-button" @click="goBack">
            <span class="material-icons">keyboard_arrow_left</span>
            <span class="go-back-text">Go back</span>
          </div>
          <div class="condition-header-style">{{ condition.name }}</div>
          <div class="condition-description-style" v-html="condition.description"></div>
        </div>
        <div v-if="this.$store.getters.isDevAccount" class="test-runner-cont">
          <span class="label-dev">DEV</span>
          <div>External Condition Template ID: <b>{{ this.extConditionTemplateID }}</b></div>
          <div>External Condition Version ID: <b>{{ this.condition.external_condition_version_id }}</b></div>
          <el-button @click="testCondition()">Test Condition</el-button>
        </div>
        <el-dialog
          title="Condition Runner Test Data"
          :visible.sync="testDialogVisible"
          :modal=false
          :before-close="handleCloseDialog"
        >
          <div class="preview-box">
            <el-tabs v-model="previewTab" value="second">
              <el-tab-pane label="Request Payload" name="first">
                <div class="pre-wrapper">
                    <pre>{{testPayloadPreview}}</pre>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Contact List" name="second">
                <div class="pre-wrapper">
                  <pre>{{testContactListPreview}}</pre>
                </div>
              </el-tab-pane>

            </el-tabs>
            <div class="footer-cont">
              <span slot="footer" class="dialog-footer">
                <el-button @click="testDialogVisible = false">Close</el-button>
              </span>
              <span class="contact-count" v-if="previewTab === 'second' && this.testContactListCount !== ''">Contact Count: {{ testContactListCount }}</span>
            </div>

          </div>
        </el-dialog>
      </div>
      <div class="conditions-sidebar-body" :class="condition.vendor">
        <div class="conditions-sidebar-body-inner">
          <BrandBotField v-if="condition.vendor === 'brandbot'"
                         :selectedCondition.sync="condition"
                         class="brandbot"
                         @didValidate="validateSteps"/>
          <div v-else>
            <LoadingBox v-if="isLoadingSteps"/>
            <div v-for="item in condition.steps" style="width:100%;padding-left: 20px; padding-right: 20px;">
              <TimeFrameField v-if="item.type === 'timeframe'"
                              :field.sync="item"
                              @didValidate="validateSteps"/>
              <MultiSelectField v-if="item.type === 'multiselect' || item.type === 'include_exclude_multiselect'"
                                :field.sync="item"
                                @didValidate="validateSteps"/>
              <RadioField v-if="item.type === 'radio'"
                          :field.sync="item"
                          @didValidate="validateSteps"/>
              <CountField v-if="item.type === 'count'"
                          :field.sync="item"
                          @didValidate="validateSteps"/>
            </div>
          </div>
        </div>
      </div>
      <div class="save-condition-container" v-if="condition.vendor !== 'brandbot'">
        <el-button class="save-condition-btn" type="primary"
                   :disabled="!isSaveEnabled" @click="commitCondition">Save Condition
        </el-button>
      </div>
    </div>
  </transition>
</template>
<script>
import EventBus from '@/event-bus';
import * as api from '@/api';
import TimeFrameField from './Fields/TimeFrameField';
import MultiSelectField from './Fields/MultiSelectField';
import RadioField from './Fields/RadioField';
import CountField from './Fields/CountField';
import GlobalRuleHeader from '../../../../Global/GlobalRuleHeader';
import LoadingBox from '../../../../Global/LoadingBox';
import BrandBotField from './Fields/BrandBotField';

export default {
  props: ['selectedCondition', 'editedRule'],
  data() {
    return {
      isSaveEnabled: false,
      isLoading: false,
      isLoadingSteps: false,
      condition: {},
      conditions: [],
      query: '',
      testDialogVisible: false,
      testPayloadPreview: {},
      testContactListPreview: {},
      previewTab: 'first',
      extConditionTemplateID: '',
      testContactListCount: '',
    };
  },
  watch: {
    selectedCondition(newVal) {
      if (newVal == null) {
        return;
      }
      this.loadSelectedCondition(newVal);
    },
    condition() {
      this.validateSteps();
    },
  },
  components: {
    BrandBotField,
    LoadingBox,
    TimeFrameField,
    MultiSelectField,
    RadioField,
    CountField,
    GlobalRuleHeader,
  },
  mounted() {
    if (this.editedRule) {
      this.loadSelectedCondition(this.editedRule.condition);
      this.isLoading = false;
      return;
    }
    this.isLoading = true;
    api.getContactListsAvailableConditionTypesUIA(this.$store.getters.isDevAccount)
      .then((response) => {
        this.conditions = response;
        this.isLoading = false;
      })
      .catch((err) => {
        this.$toasted.error(err.message);
        this.isLoading = false;
      });
  },
  computed: {
    conditionTypesFiltered() {
      let conditions = this.conditions;
      if (this.query) {
        conditions = conditions
          .filter(c => c.name.toLowerCase()
            .indexOf(this.query.toLowerCase()) > -1);
      }
      return conditions;
    },
  },
  methods: {
    handleCloseDialog() {
      this.$confirm('Are you sure to close this dialog?')
        .then(() => {
          this.testDialogVisible = false;
        })
        .catch(() => {
        });
    },
    testCondition() {
      this.testDialogVisible = true;
      const payload = api.UIASmartRule({condition: this.condition});
      api.conditionTestRunner(payload).then((response) => {
        if (!response.success && response.error) {
          this.testPayloadPreview = response.error;
          this.testContactListPreview = response.error;
        }
        if (!response.error && response.success) {
          this.testPayloadPreview = response.payload;
          const contact_list = response.success.contact_list || [];
          this.testContactListPreview = contact_list.length > 0 ? contact_list.join("\n") : '--empty list--';
          this.testContactListCount = response.success.contact_count.toString();
        }
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    goBackFromConditionList() {
      EventBus.$emit('selected_condition.close');
    },
    goBack() {
      EventBus.$emit(this.editedRule ? 'selected_condition.close' : 'condition_selection.back');
    },
    pickCondition(condition) {
      EventBus.$emit('conditions_selection.pick', condition);
    },
    commitCondition() {
      EventBus.$emit('conditions_selection.commit', {condition: this.condition});
    },
    loadSelectedCondition(newVal) {
      if (newVal.version_uuid === 'N/A') { // brandbot's legacy conditions
        this.condition = {...newVal};
        this.condition.uuid = newVal.template_uuid;
        this.condition.vendor = 'brandbot';
        return;
      }
      this.isLoadingSteps = true;
      this.condition = {};
      this.$log(JSON.stringify(newVal));
      api.getConditionTypeUIA(newVal)
        .then((response) => {
          this.extConditionTemplateID = response.condition_template.external_condition_template_id;
          this.condition = response.condition_template_version;
          //
          if (newVal && newVal.steps) {
            newVal.steps.forEach((step) => {
              const c = this.condition.steps.find(x => x.type === step.type);
              this.$log(JSON.stringify(step));
              if (c.type) {
                c.default = step.default;
              }
            });
          }
          this.isLoadingSteps = false;
        })
        .catch((err) => {
          this.$toasted.error(err.message);
          this.isLoadingSteps = false;
        });
    },
    validateSteps() {
      let isValid = false;
      if (this.condition && this.condition.steps) {
        isValid = true;
        this.condition.steps.forEach((x) => {
          isValid = isValid && x.isValid;
        });
        this.$log(this.condition.steps.map(x => x.isValid));
      }
      this.isSaveEnabled = isValid;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.back-button {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f6fa;
  margin-right: 1.25rem;
  cursor: pointer;

  .material-icons {
    color: #afbcca;
    font-size: 1rem;
    transition: color 150ms ease-in-out;
  }

  &:hover .material-icons {
    color: #1D364B;
  }
}

form {
  width: 100%;
  max-height: 80px;
}

.conditions-sidebar {
  background-color: #fff;

  &.selection {
    .conditions-sidebar-header {
      justify-content: flex-start;
      background-color: #fff;
      padding: 2rem 1.5rem;
      display: flex;
    }

    .conditions-sidebar-body {
      padding-top: 1rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      overflow-y: auto;

      spacer {
        width: 100%;
        height: 20px;
      }

      form.el-form {
        padding: 0 1rem !important;
      }

      .condition-types {
        width: 100%;
        display: block;
        margin: 1.5rem 0 3.5rem;
        padding: 0 1rem;
        height: calc(100vh - 10% - 80px);
        overflow-y: scroll;

        .condition-type {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          border-radius: 6px;
          background-color: #ffffff;
          box-shadow: 0 1px 0 0 rgba(217, 231, 240, 0.5);
          border: solid 1px #d9e7f0;
          margin-bottom: 1rem;
          padding: .75rem;
          transition: all 150ms ease-in-out;

          &:hover {
            cursor: pointer;
            background-color: #ebf4fa;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
            border-color: #007ace;
          }

          .header {
            font-weight: 600;
            line-height: 1;
          }

          position: relative;
          overflow: hidden;

          .label-dev {
            background: red;
            color: white;
            padding: 0px 12px;
            font-size: 0.5rem;
            position: absolute;
            transform: rotate(-45deg);
            top: 4px;
            left: -10px;
          }

          img {
            width: 1.25rem;
            height: 1.25rem;
            flex-shrink: 0;
            flex-grow: 0;
            margin-right: 1rem;
            overflow: hidden;
            color: transparent;
          }

          &:last-child {
            margin-bottom: 48px;
          }

          .description {
            margin-top: .5rem;
            font-size: .75rem;
            line-height: 1rem;
            color: #617080;
          }
        }
      }
    }
  }

  &.rules {
    background-color: #F4F8FA;
    overflow-x: hidden;
    overflow-y: hidden;

    .condition-header-padding {
      display: flex;
      justify-content: space-between;
      padding: 1.5rem 1.875rem 1.875rem 1.5rem;
      border-bottom: solid 1px #ccd6e0;
      background-color: white;

      position: relative;
      overflow: hidden;

      .label-dev {
        background: red;
        color: white;
        padding: 0px 12px;
        font-size: 0.5rem;
        position: absolute;
        transform: rotate(-45deg);
        top: 4px;
        left: -10px;
      }
    }

    .condition-header-style {
      margin-top: 0;
      padding-top: 0;
      font-size: 26px;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: 0.15px;
      color: #161e27;
    }

    .back-button {
      height: 1.5rem;
      display: flex;
      flex-shrink: 0;
      flex-grow: 0;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
      cursor: pointer;
    }

    .back-button .material-icons {
      flex-shrink: 0;
      flex-grow: 0;
      color: #afbcca;
      font-size: 1rem;
      transition: color 150ms ease-in-out;
    }

    .back-button .text {
      margin-left: 6px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #161e27;
    }

    .back-button:hover .material-icons {
      color: #1D364B;
    }

    .conditions-sidebar-body {
      padding: 0;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: calc(100% - 276px);
      overflow-x: unset;
      overflow-y: auto;

      &.brandbot {
        height: calc(100% - 142px);
      }

      .conditions-sidebar-body-inner {
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 2rem;

        /deep/ .sidebar-inner {
          padding-top: 0 !important;
        }

        .brandbot {
          padding: 0 1rem;
        }
      }
    }

    .go-back-button {
      position: relative;
      right: 6px;
      bottom: 9px;
      cursor: pointer;
    }

    .go-back-text {
      position: relative;
      bottom: 7px;
      right: 5px;
      color: #617080;
      font-size: 14px;
      cursor: pointer;
    }

    .save-condition-container {
      position: fixed;
      bottom: 0px;
      padding: 15px;
      margin-left: 0;
      width: 100%;
      z-index: 1000;
    }
  }
}

.preview-box {
  position: relative;
  background: #fafafa;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  padding: 20px 15px 20px;
  font-weight: 500;
  margin: 10px 0;
  color: #333;
}

.preview-title {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  font-weight: 600;
  border: 1px solid #ddd;
  border-top: 0;
  border-left: 0;
  border-radius: 5px 0 5px 0;
  padding: 5px 12px;
  letter-spacing: 1px;
}

.footer-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contact-count {
  font-size: 14px;
  margin-right: 12px;
}

.test-runner-cont {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 4px 20px;
  position: relative;
  border-radius: 4px;
  border: 1px solid rgb(204, 214, 224);
  overflow: hidden;
  width: 14rem;

  > div {
    margin-bottom: 6px;
  }
}
.pre-wrapper {
  border-radius: 4px;
  border: 1px solid rgb(204, 214, 224);
  background: #f0f0f0;
  height: 200px;
  margin-bottom: 16px;
  overflow: auto;
  pre {
    font-size: 12px;
  }
}
</style>
