<template>
  <el-card>
    <el-form @submit.native.prevent>
      <label data-test-placeholder-label >{{ placeholder }}</label>
      <el-form-item v-if="type === 'text'">
        <el-input data-test-text-placeholder-input :placeholder="placeholder" readonly />
      </el-form-item>
      <el-form-item v-if="type === 'dropdown'">
        <el-select  data-test-dropdown-select v-model="model" :placeholder="placeholder" style="width: 100%;">
          <el-option data-test-dropdown-option v-for="item in options" :key="item.label" :label="item.label" :value="item.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="type === 'multi'">
        <div v-for="(opt, index) in options">
          <el-checkbox data-test-multiselect-option v-model="opt.model" :label="opt.label"></el-checkbox>
        </div>
      </el-form-item>
      <el-form-item v-if="type === 'rating'">
        <el-row class="rating" :span="24">
          <el-col data-test-rating-low-label :span="3">{{ low }}</el-col>
          <el-col :span="3" :offset="1">
            <el-radio data-test-rating-radio-1 v-model="model" :label="1" />
          </el-col>
          <el-col :span="3">
            <el-radio data-test-rating-radio-2 v-model="model" :label="2" />
          </el-col>
          <el-col :span="3">
            <el-radio data-test-rating-radio-3 v-model="model" :label="3" />
          </el-col>
          <el-col :span="3">
            <el-radio data-test-rating-radio-4 v-model="model" :label="4" />
          </el-col>
          <el-col :span="3">
            <el-radio data-test-rating-radio-5 v-model="model" :label="5" />
          </el-col>
          <el-col data-test-rating-high-label :span="3" :offset="1">{{ high }}</el-col>
        </el-row>
      </el-form-item>
      <div class="footer">
        <div>
          <span data-test-required-label class="text-muted" v-if="required">Required</span>
        </div>
        <div>
          <el-button data-test-edit-button type="secondary" size="small" @click.stop="editField">Edit</el-button>
          <el-button data-test-remove-button type="text" size="small" class="text-danger" @click.stop="removeField">Remove</el-button>
        </div>
      </div>
    </el-form>
  </el-card>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    props: ['fieldIndex', 'placeholder', 'options', 'type', 'low', 'high', 'required'],
    data() {
      return {
        model: null,
        texts: [this.low, '', '', '', this.high],
      };
    },
    methods: {
      removeField() {
        this.$confirm('Are you sure you want to remove this field? This is not recoverable.', 'Removing Field', {
          confirmButtonText: 'Remove',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }).then(() => {
          EventBus.$emit('field.remove', this.fieldIndex);
        }).catch(() => {});
      },
      editField() {
        EventBus.$emit('field.edit', this.fieldIndex);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-radio .el-radio__input {
    display: block !important;
    text-align: center;
    margin-bottom: .5rem;
  }

  .el-radio .el-radio__inner {
    width: 18px;
    height: 18px;
    border-width: 2px;
  }

  .el-radio .el-radio__label {
    display: block !important;
    padding-left: 0;
    text-align: center;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--xpl-color-gray-400);
    padding-top: 1rem;
    margin-top: 1rem;
  }

  .rating {
    margin-top: 1rem;
    text-align: center;
  }
</style>
