<template>
  <div class="sidebar-inner">
    <h6>Account Saved Footer</h6>
    <p>This is your shared footer. Would you like to edit this footer?</p>
    <button type="button" class="xpl-button xpl-button--primary" @click="editSavedFooter">Edit Saved Footer</button>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    data() {
      return {};
    },
    mounted() {
      Array.from(document.getElementsByClassName('bb-selected'), el => el.classList.remove('bb-selected'));
    },
    methods: {
      editSavedFooter() {
        EventBus.$emit('editor.footer.edit.confirm');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar-inner {
    padding-top: 2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  h6 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    color: rgb(29, 54, 75);
    margin-bottom: 1.5rem;
  }

  p {
    font-size: .875rem;
    line-height: 1.25rem;
    color: rgb(29, 54, 75);
    margin-bottom: 1rem;
  }
</style>
