<template>
  <div class="overlay">
    <div class="header" v-if="!show_preview">
      <div>
        <a href="#" class="go-back" @click.stop="closePreview">
          <span class="icon icon-arrow-left3"></span>
          Go Back
        </a>
      </div>
      <button type="button" class="xpl-button xpl-button--primary" @click.stop="selectTemplate(campaign_template)">Use This Template</button>
    </div>
    <div class="preview" v-if="campaign_template && !show_preview">
      <div class="overview">
        <h1>{{ campaign_template.name }}</h1>
        <div class="description">{{ campaign_template.description }}</div>

        <hr>

        <div class="actions">
          <div class="messages" v-if="campaign_items.emails.length">
            <div class="heading">
              <h1>Campaign Messages to Contacts</h1>
              <div class="small text-muted">
                Contacts will only get 1 message a day, even if they meet the requirements of more than one.
              </div>
            </div>

            <div class="campaign-items">
              <div class="campaign-item" v-for="email in campaign_items.emails">
                <div>
                  <div class="header">
                    <span class="material-icons">email</span>
                    <h1>{{ email.name }}</h1>
                  </div>
                  <div class="small text-muted">
                    <span v-if="email.time_rule.choice === 'relative'">
                      Send this email
                    <span class="text-lowercase" v-if="email.time_rule.relative === 'Immediately'">{{ email.time_rule.relative }}</span>
                    <span class="text-lowercase" v-if="email.time_rule.relative !== 'Immediately'">{{ email.time_rule.relative_count }} {{ email.time_rule.relative }}</span>
                    after a contact enters the auto-campaign</span>
                    <span v-if="email.time_rule.choice === 'both'">
                      Send this email
                      <span class="text-lowercase" v-if="email.time_rule.relative === 'Immediately'">{{ email.time_rule.relative }}</span>
                      <span class="text-lowercase" v-if="email.time_rule.relative !== 'Immediately'">{{ email.time_rule.relative_count }} {{ email.time_rule.relative }}</span>
                      after a contact enters the auto-campaign but only send if they meet the following rule:
                    </span>
                    <span v-if="email.time_rule.choice === 'behavior'">Send this email when a contact meets the following rule:</span>
                    <div class="rule-bg" v-if="email.time_rule.choice === 'behavior' && email.behavior_rule">
                      <RuleEnglishHybrid :rule="email.behavior_rule" />
                    </div>
                  </div>
                </div>
                <div>
                  <button type="button" class="xpl-button xpl-button--secondary" @click="templateEmail = email; show_preview = true;">Preview</button>
                </div>
              </div>
            </div>
          </div>

          <div class="tasks" v-if="campaign_items.tasks.length">
            <div class="heading">
              <h1>Campaign Actions to Contacts</h1>
              <div class="small text-muted">
                Contacts will only get 1 message a day, even if they meet the requirements of more than one.
              </div>
            </div>

            <div class="campaign-items">
              <div class="campaign-item" v-for="task in campaign_items.tasks">
                <div>
                  <div class="header">
                    <span class="material-icons" v-if="!task.task.phone_call">assignment</span>
                    <span class="material-icons" v-if="task.task.phone_call">phone</span>
                    <h1>{{ task.name }}</h1>
                  </div>
                  <div class="small text-muted">
                    <span v-if="task.time_rule.choice === 'relative'">
                      Create a task
                    <span class="text-lowercase" v-if="task.time_rule.relative === 'Immediately'">{{ task.time_rule.relative }}</span>
                    <span class="text-lowercase" v-if="task.time_rule.relative !== 'Immediately'">{{ task.time_rule.relative_count }} {{ task.time_rule.relative }}</span>
                    after a contact enters the auto-campaign</span>
                    <span v-if="task.time_rule.choice === 'both'">
                      Create a task
                      <span class="text-lowercase" v-if="task.time_rule.relative === 'Immediately'">{{ task.time_rule.relative }}</span>
                      <span class="text-lowercase" v-if="task.time_rule.relative !== 'Immediately'">{{ task.time_rule.relative_count }} {{ task.time_rule.relative }}</span>
                      after a contact enters the auto-campaign but only send if they meet the following rule:
                    </span>
                    <span v-if="task.time_rule.choice === 'behavior'">Send this task when a contact meets the following rule:</span>
                    <div class="rule-bg" v-if="task.time_rule.choice === 'behavior' && task.behavior_rule">
                      <RuleEnglishHybrid :rule="task.behavior_rule" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CampaignTemplateEmailPreview v-if="show_preview && templateEmail" :template="campaign_template" :templateEmail="templateEmail" />
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import CampaignTemplateEmailPreview from './CampaignTemplateEmailPreview';
  import RuleEnglishHybrid from '../Contacts/ContactLists/Conditions/RuleEnglishHybrid';

  export default {
    props: ['campaignTemplateId'],
    components: {
      RuleEnglishHybrid,
      CampaignTemplateEmailPreview,
    },
    data() {
      return {
        campaign_template: null,
        campaign_items: [],
        show_preview: false,
        templateEmail: null,
      };
    },
    mounted() {
      EventBus.$on('campaign_template.email.preview.close', () => {
        this.show_preview = false;
        this.templateEmail = null;
      });
    },
    created() {
      api.getCampaignTemplate(this.campaignTemplateId).then((response) => {
        this.campaign_template = response.campaign_template;
        this.campaign_items = response.campaign_items;
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    beforeDestroy() {
      EventBus.$off('campaign_template.email.preview.close');
    },
    methods: {
      selectTemplate(template) {
        EventBus.$emit('campaign_template.email.preview.select', template);
      },
      closePreview() {
        EventBus.$emit('campaign_template.preview.close');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #f3f7f9;
    z-index: 10000;
    overflow: hidden;
  }

  .header {
    height: 3rem;
    background-color: #ffffff;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #cdd7e2;
  }

  .preview {
    width: 100vw;
    height: 100vh;
    padding-top: 2rem;
    overflow-y: scroll;
  }

  .overview {
    max-width: 56rem;
    margin: auto;
    padding-bottom: 5rem;
  }

  .overview h1 {
    font-size: 1.25rem;
    line-height: 1.4;
    color: rgb(46, 68, 87);
    margin-bottom: .875rem;
  }

  .overview .description {
    padding-top: .875rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: .875rem;
    background-color: rgb(255, 255, 255);
    // box-shadow: 0 1px 0 0 rgba(217, 231, 240, 0.5);
    border: solid 1px rgb(217, 231, 240);
    font-size: .875rem;
    line-height: 1.43;
    color: rgb(97, 112, 128);
  }

  .preview hr {
    border-color: rgb(217, 231, 240);
    margin-top: 2.5rem;
    margin-bottom: 2rem;
  }

  .actions .messages {
    margin-bottom: 2.5rem;
  }

  .actions .messages .heading,
  .actions .tasks .heading {
    background-color: white;
    padding: 1.5rem;
    padding-bottom: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0;
    border-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: solid 1px rgb(217, 231, 240);
    border-bottom: none;
  }

  .actions .messages .heading h1,
  .actions .tasks .heading h1 {
    font-size: 1rem;
    font-weight: 700;
    color: rgb(75, 76, 80);
    margin-bottom: 0;
  }

  .campaign-items .campaign-item {
    padding: 1.5rem;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: solid 1px var(--xpl-color-gray-400);
    border-bottom-color: transparent;
    transition: all 150ms ease-in-out;
  }

  .campaign-items .campaign-item:last-child {
    border-bottom-color: var(--xpl-color-gray-400);
  }

  .campaign-items .campaign-item .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: none;
    height: auto;
    padding: 0;
    border: none;
  }

  .campaign-items .campaign-item .header .material-icons {
    font-size: 1rem;
    color: rgb(217, 231, 240);
    margin-right: .625rem;
  }

  .campaign-items .campaign-item h1 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: rgb(76, 79, 82);
    margin-bottom: .25rem;
  }

  .campaign-items .campaign-item .rule-bg {
    margin-top: .5rem;
  }
</style>
