<template>
  <transition name="fade">
    <div>
      <div class="allcontacts-header" v-if="!filters.add_contacts && !filters.unsubscribe_contacts">
        <div :class="[ filters.all ? 'header-item active' : 'header-item' ]" @click.stop="toggleTab('all')">
          All Contacts <span>({{ segments.all | prettyNumber }})</span>
        </div>
        <div :class="[ filters.subscribed ? 'header-item active' : 'header-item' ]" @click.stop="toggleTab('subscribed')">
          All Email Subscribed <span>({{ segments.subscribed | prettyNumber }})</span>
        </div>
        <div :class="[ filters.last_30 ? 'header-item active' : 'header-item' ]" @click.stop="toggleTab('last_30')">
          Last 30 Days <span>({{ segments.last_30 | prettyNumber }})</span>
        </div>
        <div :class="[ filters.unsubscribed ? 'header-item active' : 'header-item' ]" @click.stop="toggleTab('unsubscribed')">
          Unsubscribed Email <span>({{ segments.unsubscribed | prettyNumber }})</span>
        </div>
      </div>
      <div class="container-fluid contacts">
        <Subscribed :count="segments.subscribed" v-if="filters.subscribed" />
        <All :count="segments.all" v-if="filters.all" />
        <Last30 :count="segments.last_30" v-if="filters.last_30" />
        <Unsubscribed :count="segments.unsubscribed" v-if="filters.unsubscribed" />
        <AddContacts v-if="filters.add_contacts" />
        <UnsubscribeContacts v-if="filters.unsubscribe_contacts" />
      </div>
    </div>
  </transition>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import Subscribed from './AllContacts/Subscribed';
  import All from './AllContacts/All';
  import Last30 from './AllContacts/Last30';
  import Unsubscribed from './AllContacts/Unsubscribed';
  import AddContacts from './AddContacts/AddContacts';
  import UnsubscribeContacts from './UnsubscribeContacts/UnsubscribeContacts';

  export default {
    components: {
      Subscribed,
      All,
      Last30,
      Unsubscribed,
      AddContacts,
      UnsubscribeContacts,
    },
    data() {
      return {
        filters: {
          all: true,
          subscribed: false,
          last_30: false,
          unsubscribed: false,
          add_contacts: false,
          unsubscribe_contacts: false,
        },
        segments: {},
      };
    },
    mounted() {
      api.getContactSegments().then((response) => {
        this.segments = response.segments;
      }).catch(() => {
        this.$toasted.error('Something wrong happened while trying to fetch data for Contacts...');
      });

      EventBus.$on('contacts.add_contacts', () => {
        this.toggleTab('add_contacts');
      });

      EventBus.$on('contacts.unsubscribe_contacts', () => {
        this.toggleTab('unsubscribe_contacts');
      });

      EventBus.$on('contacts.all_contacts', () => {
        this.toggleTab('subscribed');
      });
    },
    methods: {
      toggleTab(tab) {
        Object.keys(this.filters).forEach((filter) => {
          this.filters[filter] = false;
        });

        this.filters[tab] = true;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .container-fluid {
  padding: 0;
  padding-left: 2rem;
  padding-right: 2rem;
  }

 .allcontacts-header {
  background-color: var(--xpl-color-gray-400);
  padding-left: 1.5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
 }

 .header-item {
  font-size: .875rem;
  line-height: 1.25rem;
  color: #8b9bac;
  margin-right: 1.5rem;
  cursor: pointer;
  font-weight: 400;
  transition: all 150ms ease-in-out;
   padding: 4px 8px;
 }

 .header-item span {
  color: #8b9bac;
 }

 .header-item.active,
 .header-item:hover {
   color: #1d364b;
   background: #d4d5de;
   border-radius: 6px;
 }

 .header-item:last-child {
  margin-right: 0;
 }
</style>
