<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner">
      Reply Details
    </div>

    <form @submit.prevent>
      Any contact who sent an SMS containing the following keyword
      <div class="form-group">
        <label>Reply Keyword</label>
        <input type="text" v-model="keyword" class="form-control" placeholder="Keyword" />
        <div class="small text-muted">The spelling has to be correct. Case insensitive.</div>
      </div>
    </form>

    <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition" :disabled="!keyword">Save this condition</button>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    props: ['condition', 'editedRule'],
    data() {
      return {
        keyword: '',
      };
    },
    created() {
      if (this.editedRule) {
        this.keyword = this.editedRule.keyword;
      }
    },
    methods: {
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          keyword: this.keyword,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar-inner {
    padding-bottom: 5rem;
    min-height: 70vh;
  }

  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates .date {
    display: flex;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }
</style>
