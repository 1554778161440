function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }

  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

function getDefaultAttributes(tagName) {
  const attributes = {};
  attributes['css-class'] = `bb-hover bb-edit bb-delete bb-uuid-${guid()}`;

  if (tagName === 'mj-button') {
    attributes.align = 'center';
    attributes['background-color'] = '#414141';
    attributes.border = 'none';
    attributes['border-radius'] = '3px';
    attributes.color = '#ffffff';
    attributes['font-family'] = 'Ubuntu, Helvetica, Arial, sans-serif';
    attributes['font-size'] = '13px';
    attributes['font-weight'] = 'normal';
    attributes['inner-padding'] = '10px 25px';
    attributes['line-height'] = '120%';
    attributes.padding = '10px 25px';
    attributes['text-decoration'] = 'none';
    attributes['text-transform'] = 'none';
    attributes['vertical-align'] = 'middle';
    attributes['border-bottom'] = null;
    attributes['border-left'] = null;
    attributes['border-right'] = null;
    attributes['border-top'] = null;
    attributes['container-background-color'] = null;
    attributes['font-weight'] = null;
    attributes.height = null;
    attributes.href = null;
    attributes['padding-bottom'] = null;
    attributes['padding-left'] = null;
    attributes['padding-right'] = null;
    attributes['padding-top'] = null;
    attributes.rel = null;
    attributes.width = null;
  }

  if (tagName === 'mj-divider') {
    attributes['border-color'] = '#000000';
    attributes['border-style'] = 'solid';
    attributes['border-width'] = '4px';
    attributes.padding = '10px 25px';
    attributes.width = '100%';
  }

  if (tagName === 'mj-spacer') {
    attributes.border = null;
    attributes['border-bottom'] = null;
    attributes['border-left'] = null;
    attributes['border-radius'] = null;
    attributes['border-right'] = null;
    attributes['border-top'] = null;
    attributes['container-background-color'] = null;
    attributes.direction = null;
    attributes.padding = null;
    attributes['padding-bottom'] = null;
    attributes['padding-left'] = null;
    attributes['padding-right'] = null;
    attributes['padding-top'] = null;
    attributes['vertical-align'] = null;
    attributes.width = null;
  }

  if (tagName === 'mj-text') {
    attributes.align = 'left';
    attributes.color = '#000000';
    attributes['font-family'] = 'Ubuntu, Helvetica, Arial, sans-serif';
    attributes['font-size'] = '13px';
    attributes['line-height'] = '1';
    attributes.padding = '10px 25px';
    attributes['background-color'] = null;
    attributes['font-style'] = null;
    attributes['font-weight'] = null;
    attributes.height = null;
    attributes['letter-spacing'] = null;
    attributes['padding-bottom'] = null;
    attributes['padding-left'] = null;
    attributes['padding-right'] = null;
    attributes['padding-top'] = null;
    attributes['text-decoration'] = null;
    attributes['text-transform'] = null;
    attributes['vertical-align'] = null;
  }

  if (tagName === 'mj-social') {
    // align: 'center',
    // 'border-radius': '3px',
    // color: '#333333',
    // 'font-family': 'Ubuntu, Helvetica, Arial, sans-serif',
    // 'font-size': '13px',
    // 'icon-size': '20px',
    // 'inner-padding': null,
    // 'line-height': '22px',
    // mode: 'horizontal',
    // padding: '10px 25px',
    // 'text-decoration': 'none',
  }

  if (tagName === 'mj-image') {
    // ok
  }


  // We clean all null values to not bother MJML's engine.
  // NVM - we need them to know what to edit...
  // Object.keys(attributes).forEach((key) => {
  //   if (attributes[key] == null) {
  //     delete attributes[key];
  //   }
  // });

  return attributes;
}

export function getMjmlDefaultObject(tagName) {
  const content = 'Hello World.';
  const attributes = getDefaultAttributes(tagName);

  const mjmlObject = {
    tagName,
    attributes,
    content,
  };

  return mjmlObject;
}

export function seedUuids(mjmlString) {
  function findElementInner(mjmlObject) {
    // Handling the case of mj-wrapper being the first key
    if (Object.prototype.hasOwnProperty.call(mjmlObject, 'attributes') &&
      Object.prototype.hasOwnProperty.call(mjmlObject.attributes, 'css-class')) {
      mjmlObject.attributes['css-class'] = mjmlObject.attributes['css-class'].replace('bb-uuid', `bb-uuid-${guid()}`);
    }

    Object.keys(mjmlObject).forEach((key) => {
      if (mjmlObject[key] !== null && typeof mjmlObject[key] === 'object') {
        if (Object.prototype.hasOwnProperty.call(mjmlObject[key], 'attributes') &&
          Object.prototype.hasOwnProperty.call(mjmlObject[key].attributes, 'css-class') &&
          mjmlObject[key].attributes['css-class'].indexOf('bb-uuid') > -1) {
          mjmlObject[key].attributes['css-class'] = mjmlObject[key].attributes['css-class'].replace('bb-uuid', `bb-uuid-${guid()}`);
        } else {
          findElementInner(mjmlObject[key]);
        }
      }
    });

    return mjmlObject;
  }

  return findElementInner(JSON.parse(mjmlString));
}

export function markAsFooterElement(mjml) {
  function findElementInner(mjmlObject) {
    // Handling the case of mj-wrapper being the first key
    if (Object.prototype.hasOwnProperty.call(mjmlObject, 'attributes') &&
      Object.prototype.hasOwnProperty.call(mjmlObject.attributes, 'css-class') &&
      mjmlObject.attributes['css-class'].indexOf('mj-wrapper') > -1) {
      mjmlObject.attributes['css-class'] = `${mjmlObject.attributes['css-class']} bb-footer`;
    }

    Object.keys(mjmlObject).forEach((key) => {
      if (mjmlObject[key] !== null && typeof mjmlObject[key] === 'object') {
        if (Object.prototype.hasOwnProperty.call(mjmlObject[key], 'attributes') &&
          Object.prototype.hasOwnProperty.call(mjmlObject[key].attributes, 'css-class') &&
          mjmlObject[key].attributes['css-class'].indexOf('bb-uuid') > -1 &&
          mjmlObject[key].attributes['css-class'].indexOf('mj-wrapper') > -1) {
          mjmlObject[key].attributes['css-class'] = `${mjmlObject[key].attributes['css-class']} bb-footer`;
        } else {
          findElementInner(mjmlObject[key]);
        }
      }
    });

    return mjmlObject;
  }

  return findElementInner(mjml);
}
