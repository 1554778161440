<template>
  <div>
    <div class="header">
      <h1>Opened</h1>
    </div>
    <div v-if="results.data && results.data.length">
      <el-table :data="results.data" style="width: 100%;" v-loading="loading">
        <el-table-column label="Email Address">
          <template slot="header" slot-scope="scope">
            <el-input v-model="query" size="mini" placeholder="Search" @change="searchData" />
          </template>
          <template slot-scope="scope">
            <a :href="'/contacts/' + scope.row.contact_uuid" target="_blank">{{ scope.row.email }}</a>
          </template>
        </el-table-column>
        <el-table-column label="Last Open At" fixed="right" align="right">
          <template slot-scope="scope">
            {{ scope.row.latest_timestamp | humanDateTime }}
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 2rem;">
        <el-pagination layout="total, prev, pager, next" :total="results.total" :page-size="results.per_page"
                       @next-click="nextPage" @prev-click="prevPage" @current-change="currentPage"
                       style="text-align: center;"></el-pagination>
      </div>
    </div>

    <div class="illustration" v-if="!loading && results.data && !results.data.length">
      <img src="https://assets.brandbot.com/public/empty-states/analytics-opened.svg" />
    </div>
  </div>
</template>
<script>
  import _ from 'lodash';
  import * as api from '@/api';

  export default {
    props: ['typeUuid'],
    data() {
      return {
        query: '',
        loading: true,
        results: {},
        page: 1,
      };
    },
    created() {
      this.fetchData();
    },
    methods: {
      searchData: _.debounce(function customFunc() {
        api.emailPerformanceContacts(this.typeUuid, 'open', this.page, this.query).then((response) => {
          this.$set(this, 'results', response.contacts);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      }, 200,
      ),
      fetchData() {
        this.loading = true;
        api.emailPerformanceContacts(this.typeUuid, 'open', this.page).then((response) => {
          this.$set(this, 'results', response.contacts);
          this.loading = false;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      nextPage() {
        this.page += 1;
        this.fetchData();
      },
      prevPage() {
        this.page = this.page - 1;
        this.fetchData();
      },
      currentPage(page) {
        this.page = page;
        this.fetchData();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .header {
    margin-top: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h1 {
    text-transform: capitalize;
    font-size: 1.25rem;
    font-weight: normal;
    color: #212B36;
    line-height: 1.5rem;
  }

  .illustration img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 10rem;
    margin-bottom: 2rem;
  }
</style>
