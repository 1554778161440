<template>
  <div>
    <div class="display-medium" style="margin-bottom: 2rem;">Insights</div>
    <el-row class="quick">
      <el-col :span="7">
        <el-card v-loading="loading" :class="[show === 'amount' ? 'active' : '']" @click.native="toggleStats('amount')">
          <div class="text-center display-medium">{{ amount | prettyCurrency }}</div>
          <div class="text-center display-caption subdued">Purchased Amount</div>
        </el-card>
      </el-col>
      <el-col :span="7" :offset="1">
        <el-card v-loading="loading" :class="[show === 'incomplete' ? 'active' : '']" @click.native="toggleStats('incomplete')">
          <div class="text-center display-medium">{{ incomplete | prettyNumber }}</div>
          <div class="text-center display-caption subdued">Started but Did not Finish</div>
        </el-card>
      </el-col>
      <el-col :span="7" :offset="1">
        <el-card v-loading="loading" :class="[show === 'recovered' ? 'active' : '']" @click.native="toggleStats('recovered')">
          <div class="text-center display-medium">{{ recovered | prettyCurrency }}</div>
          <div class="text-center display-caption subdued">Recovered Sales</div>
        </el-card>
      </el-col>
    </el-row>

    <el-row style="margin-top: 2rem;" v-if="show">
      <el-col :span="23">
        <el-card>
          <el-table :data="tableData.data">
            <el-table-column label="Contact" :width="show === 'recovered' ? '' : '300px'">
              <template slot-scope="scope">
                <a :href="'/contacts/' + scope.row.uuid" target="_blank" v-if="scope.row.first_name">{{ scope.row.first_name }} {{ scope.row.last_name }}</a>
                <a :href="'/contacts/' + scope.row.uuid" target="_blank" v-else>{{ scope.row.email }}</a>
              </template>
            </el-table-column>
            <el-table-column label="Purchased On" v-if="show === 'amount'">
              <template slot-scope="scope">
                {{ scope.row.created_at | humanDateTime }}
              </template>
            </el-table-column>
            <el-table-column label="Started On" v-if="show === 'incomplete'">
              <template slot-scope="scope">
                {{ scope.row.created_at | humanDateTime }}
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top: 2rem;">
            <el-pagination layout="total, prev, pager, next" :total="tableData.total" class="text-center" :pageSize="tableData.per_page" @next-click="nextPage" @prev-click="prevPage" @current-change="currentPage"></el-pagination>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
  import * as api from '@/api';

  export default {
    props: ['checkoutflow'],
    data() {
      return {
        loading: false,
        amount: null,
        incomplete: null,
        recovered: null,
        show: false,
        tableData: {
          data: [],
          total: 100,
          per_page: 25,
        },
      };
    },
    created() {
      this.fetchStatsNumbers();
      this.toggleStats('amount');
    },
    methods: {
      toggleStats(stat) {
        this.show = stat;
        if (stat === 'amount') {
          this.fetchPurchasesData();
        }

        if (stat === 'incomplete') {
          this.fetchIncompleteData();
        }

        if (stat === 'recovered') {
          this.fetchRecoveredData();
        }
      },
      fetchRecoveredData(page = 1) {
        api.checkoutFlowRecoveredAllTimeContacts(this.checkoutflow.id, page).then((response) => {
          this.tableData = response.contacts;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchIncompleteData(page = 1) {
        api.checkoutFlowAbandonAllTimeContacts(this.checkoutflow.id, page).then((response) => {
          this.tableData = response.contacts;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchPurchasesData(page = 1) {
        api.checkoutFlowPurchasesAllTimeContacts(this.checkoutflow.id, page).then((response) => {
          this.tableData = response.contacts;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchStatsNumbers() {
        this.loading = true;
        api.getCheckoutFlowStats(this.checkoutflow.id).then((response) => {
          this.amount = response.stats.amount;
          this.incomplete = response.stats.incomplete;
          this.recovered = response.stats.recovered;
          this.loading = false;
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      nextPage(page) {
        if (this.show === 'amount') {
          this.fetchPurchasesData(page);
        }
      },
      prevPage(page) {
        if (this.show === 'amount') {
          this.fetchPurchasesData(page);
        }
      },
      currentPage(page) {
        if (this.show === 'amount') {
          this.fetchPurchasesData(page);
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .quick .el-card {
    cursor: pointer;
  }

  .quick .el-card:hover,
  .quick .el-card.active {
    border-color: var(--xpl-color-secondary-lm);
  }

  .display-medium {
    margin-bottom: 1rem;
  }
</style>
