<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner">
      Has / Has Not
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="!has_package" @click="has_package = true"></span>
            <span class="icon icon-radio-checked" v-if="has_package" @click="has_package = true"></span>
          </div>
          <div class="col-xs-10">
            <label @click="has_package = true">Has an active package</label>
            <div class="small text-muted">Get contacts who have any Active Class Package.</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="has_package" @click="has_package = false"></span>
            <span class="icon icon-radio-checked" v-if="has_package === false" @click="has_package = false"></span>
          </div>
          <div class="col-xs-10">
            <label @click="has_package = false">Does not have an active package</label>
            <div class="small text-muted">Get contacts who do not have any Active Class Package.</div>
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Options
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="!any_package" @click="any_package = true"></span>
            <span class="icon icon-radio-checked" v-if="any_package" @click="any_package = true"></span>
          </div>
          <div class="col-xs-10">
            <label @click="any_package = true">
              Any Class Package
            </label>
            <div class="small text-muted">Get contacts who have visits remaining on any Class Package.</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="any_package" @click="any_package = false"></span>
            <span class="icon icon-radio-checked" v-if="!any_package" @click="any_package = false"></span>
          </div>
          <div class="col-xs-10">
            <label @click="any_package = false">
              A specific Class Package
            </label>
            <div class="small text-muted">Get contacts who have visits remaining on specific Class Package(s).</div>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="!any_package">
        <div class="form-group">
          <label>Select Pricing Option(s)</label>
          <div class="multiselect-block">
            <Multiselect v-model="selected_packages" :options="packages" placeholder="Select Pricing Option(s)" :allow-empty="false" :show-labels="false" :multiple="true" :close-on-select="false" label="name" track-by="name" :searchable="true" />
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner" v-if="!enrollment">
      Visits
    </div>

    <form @submit.prevent v-if="!enrollment">
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="!any_amount" @click="any_amount = true"></span>
            <span class="icon icon-radio-checked" v-if="any_amount" @click="any_amount = true"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_absolute" @click="any_amount = true">
              Any amount of visits remaining
            </label>
            <div class="small text-muted">Get contacts who have any amount of visits remaining on the selected Class Package(s).</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="any_amount" @click="any_amount = false"></span>
            <span class="icon icon-radio-checked" v-if="!any_amount" @click="any_amount = false"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_relative" @click="any_amount = false">
              Specific amount of visits remaining
            </label>
            <div class="small text-muted">Get contacts who have a specific amount of visits remaining on the selected Class Package(s).</div>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="!any_amount">
        Any contact who still has&nbsp;
        <Multiselect v-model="comparison_amount" placeholder="Pick" :options="comparisons" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" style="width: 10rem; min-width: 10rem; max-width: 10rem;" />
        &nbsp;
        <input type="number" v-model.number="amount_start" class="form-control" :min="comparison_amount && comparison_amount.value === 'between' ? 1 : 0" style="display: inline-block; width: 5rem;" :disabled="!comparison_amount" /><span v-if="comparison_amount && comparison_amount.value === 'between'"> and <input type="number" v-model.number="amount_end" class="form-control" min="0" style="display: inline-block; width: 5rem;" /></span>&nbsp;visits remaining in the selected package(s).
      </div>
    </form>

    <div class="form-group">
      <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition">Save this condition</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';
  import VueDatepickerLocal from 'vue-datepicker-local';

  export default {
    props: ['condition', 'editedRule', 'enrollment'],
    components: {
      Multiselect,
      VueDatepickerLocal,
    },
    data() {
      return {
        has_package: true,
        selected_packages: [],
        packages: [],
        any_package: true,
        any_amount: true,
        comparison_amount: null,
        comparisons: [{
          label: 'At least',
          value: 'more',
        }, {
          label: 'Less than',
          value: 'less',
        }, {
          label: 'Exactly',
          value: 'exactly',
        }, {
          label: 'Between',
          value: 'between',
        }],
        amount_start: null,
        amount_end: null,
      };
    },
    created() {
      this.fetchPricingOptionPackages();

      if (this.editedRule) {
        this.has_package = this.editedRule.has_package;
        this.any_package = this.editedRule.any_package;
        this.any_amount = this.editedRule.any_amount;
        this.selected_packages = this.editedRule.selected_packages;
        this.comparison_amount = this.comparisons
        .filter(c => c.value === this.editedRule.comparison_amount)[0];
        this.amount_start = this.editedRule.amount_start;
        this.amount_end = this.editedRule.amount_end;
      }
    },
    methods: {
      fetchPricingOptionPackages() {
        api.getPricingOptionsPackages().then((response) => {
          this.packages = response.pricing_options;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          has_package: this.has_package,
          any_package: this.any_package,
          selected_packages: this.selected_packages,
          any_amount: this.any_amount,
          comparison_amount: this.comparison_amount ? this.comparison_amount.value : null,
          amount_start: this.amount_start,
          amount_end: this.amount_end,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }
</style>
