<template>
  <div>
    <LandingPageSetup v-if="show_modal_setup" :account="account" />
    <el-container>
      <el-header>
        <div class="display-small">Landing Pages</div>
      </el-header>
      <el-container class="inner">
        <el-aside width="17rem">
          <div :class="['item', 'active']">
            All Landing Pages
          </div>
        </el-aside>
        <el-main>
          <el-row :gutter="24" class="heading">
            <el-col :span="12">
              <div class="display-medium">
                All Landing Pages
              </div>
            </el-col>
            <el-col :span="8" :offset="4" style="display: flex; justify-content: flex-end;">
              <el-button type="primary" class="xpl-button xpl-button--primary" @click.prevent="newFlow">Create a Landing Page</el-button>
            </el-col>
          </el-row>

          <el-row :gutter="24" class="search">
            <el-col :span="24">
              <el-input prefix-icon="el-icon-search" placeholder="Search" v-model.trim="query" v-if="checkoutflows.length"></el-input>
            </el-col>
          </el-row>

          <div class="v2__card" v-for="checkoutflow in filteredFlows" :key="checkoutflow.id">
            <div class="card-body">
              <el-row :gutter="24">
                <el-col :span="8">
                  <div class="name" @click.stop="$router.push('/apps/landing-pages/' + checkoutflow.uuid)">{{ checkoutflow.name }}</div>
                  <div class="state active" v-if="checkoutflow.published">Published</div>
                  <div class="state paused" v-if="!checkoutflow.published">Not Published</div>
                  <div class="display-caption subdued" v-if="!checkoutflow.published">Created on {{ checkoutflow.created_at | humanDateTime }}</div>
                  <div class="display-caption subdued" v-if="checkoutflow.published">Last published on {{ checkoutflow.updated_at | humanDateTime }}</div>
                </el-col>
                <el-col :span="6" :offset="6" class="stats">
                  <div class="in" v-if="checkoutflow.purchases">{{ checkoutflow.purchases | prettyNumber }}</div>
                  <div class="in" v-else>&mdash;</div>
                  <div class="display-caption">Purchases</div>
                </el-col>
                <el-col :span="6" class="stats" v-if="false">
                  <div class="all" v-if="checkoutflow.amount">{{ checkoutflow.amount | prettyCurrency }}</div>
                  <div class="all" v-else>&mdash;</div>
                  <div class="display-caption">Revenue</div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import LandingPageSetup from './LandingPageSetup';

  export default {
    components: {
      LandingPageSetup,
    },
    data() {
      return {
        account: null,
        show_modal_setup: false,
        query: '',
        checkoutflows: [],
      };
    },
    mounted() {
      // eslint-disable-next-line
      document.title = `Landing Pages | ${this.$store.getters.brandName}`;

      EventBus.$on('checkout_modal.close', () => {
        this.create_modal = false;
      });
    },
    created() {
      this.fetchCheckoutFlows();

      api.getCurrentAccount().then((response) => {
        if (!response.account.did_setup_landing_page) {
          this.account = response.account;
          this.show_modal_setup = true;
        }
      }).catch((err) => {
        // eslint-disable-next-line
        console.log(err);
      });
    },
    beforeDestroy() {
      // eslint-disable-next-line
      document.title = this.$store.getters.brandName;
      EventBus.$off('checkout_modal.close');
    },
    computed: {
      filteredFlows() {
        if (this.query.length) {
          return this.checkoutflows.filter(
            c => c.name.toLowerCase().includes(this.query.toLowerCase()),
          );
        }

        return this.checkoutflows;
      },
    },
    methods: {
      newFlow() {
        this.$prompt('Name', 'New Landing Page', {
          confirmButtonText: 'Create',
          cancelButtonText: 'Cancel',
          inputPattern: /^.{3,}$/,
          inputErrorMessage: 'A Landing Page name of at least 3 characters is required.',
        }).then(({ value }) => {
          this.createFlow(value);
        });
      },
      createFlow(name) {
        api.createCheckoutFlow({ name }).then((response) => {
          this.$router.push(`/apps/landing-pages/${response.checkoutflow.uuid}`);
        }).catch((err) => {
          this.$message({
            type: 'error',
            message: err.message,
          });
        });
      },
      fetchCheckoutFlows() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: '#fff',
        });
        api.checkoutFlows().then((response) => {
          this.checkoutflows = response.checkoutflows;
          loading.close();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-header {
    display: block;
    width: 100%;
    position: fixed;
    z-index: 100;
    border-bottom: 1px solid #d9e7f0;
    padding-left: 1.25rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .el-main {
    margin-top: 3.75rem;
    height: calc(100vh - 6.75rem);
  }

  .el-aside {
    margin-top: 3.75rem;
    height: calc(100vh - 6.75rem);
    padding-top: 2.5rem;
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .el-aside .item {
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: .875rem;
    color: #2e4457;
    transition: all 150ms ease-in-out;
    border-radius: 6px;
    margin-bottom: .375rem;
  }

  .el-aside .item:hover,
  .el-aside .item.active {
    cursor: pointer;
    background-color: var(--xpl-color-secondary-bg-lm);
  }

  .el-aside .item.active {
    font-weight: 700;
  }

  .el-aside .item .count {
    margin-left: auto;
    color: #617080;
  }

  .el-aside .heading {
    padding-left: 1rem;
    margin-top: 1.875rem;
    font-size: 1rem;
    font-weight: 700;
    color: #00152a;
    margin-bottom: 1.25rem;
  }

  .el-aside a {
    display: block;
    margin-top: 1rem;
    padding-left: 1rem;
    font-size: .875rem;
    color: #6923f4;
  }

  .container .container-inner {
    height: calc(100vh - 4rem);
    display: block;
    width: 100%;
    margin-top: 4rem;
    padding-top: 1.875rem;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .el-main .heading {
    margin-bottom: 1.875rem;
  }

  .el-main .heading .display-medium {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .el-row.search {
    margin-bottom: 2.5rem;
  }

  .v2__card {
    margin-bottom: .75rem;
  }

  .el-col .name {
    line-height: 1.5;
    font-weight: 700;
    color: #00152a;
    margin-bottom: 2px;
    transition: all 150ms ease-in-out;
    transition: text-decoration 150ms ease-in-out;
  }

  .el-col .name:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #6923f4;
  }

  .state {
    font-size: .875rem;
    font-weight: bold;
    line-height: 1.43;
    margin-bottom: .5rem;
  }

  .state.active {
    color: #49e295;
  }

  .state.paused {
    color: #8b9bac;
  }

  .el-col .in,
  .el-col .all {
    font-weight: 700;
    font-size: 1rem;
    text-align: right;
  }

  .el-col.stats .display-caption {
    line-height: 1.67;
    text-align: right;
  }

  .el-col .in {
    color: var(--xpl-color-secondary-lm);
  }

  .el-col .all {
    color: #8301f1;
  }
</style>
