<template>
  <ConditionsSidebarUIA v-if="$store.getters.isUIAVendor"
                        :contact_list_ref="contact_list"
                        :enrollment="enrollment"
                        :goalList="goalList"
                        :campaignItem="campaignItem"/>
  <ConditionsSidebar v-else
                     :contact_list="contact_list"
                     :enrollment="enrollment"
                     :goalList="goalList"
                     :campaignItem="campaignItem"/>
</template>
<script>
import ConditionsSidebar from './ConditionsSidebar';
import ConditionsSidebarUIA from './UIA/ConditionsSidebar';

export default {
  name: 'ConditionsSidebarHybrid',
  components: { ConditionsSidebar, ConditionsSidebarUIA },
  props: ['contact_list', 'enrollment', 'goalList', 'campaignItem'],
};
</script>

<style scoped>

</style>
