<template>
  <div class="assets-container">
    <div class="assets-sidebar">
      <h6>Asset Folders</h6>
      <div>
        <div :class="{ folder: true, selected: path === '' }" @click="selectFolder('')">Unfiled</div>
        <div :class="{ folder: true, selected: path === '*' }" @click="selectFolder('*')">All</div>
        <div :class="{ folder: true, selected: folder === path }" v-for="folder in folders" @click.prevent="selectFolder(folder)">
          {{ folder }}
          <span class="icon icon-cancel-circle" @click.stop="deleteFolder(folder)"></span>
        </div>
      </div>
      <a href="#" @click="new_folder_modal = !new_folder_modal">Create New Folder</a>
      <NewFolderModal v-if="new_folder_modal" />
    </div>
    <div class="assets-inner">
      <form @submit.prevent>
        <div class="input-group">
          <span class="input-group-addon">
            <span class="icon icon-search"></span>
          </span>
          <input type="search" name="query" v-model="query" class="form-control" placeholder="Search" />
        </div>
        <div class="buttons">
          <button type="button" class="xpl-button xpl-button--secondary xpl-button--warning" v-if="selectedFiles.length" @click="deleteImages">Delete</button>
          <div class="dropdown" v-if="selectedFiles.length && folders.length">
            <button type="button" class="xpl-button xpl-button--secondary dropdown-toggle" @click="moving_dropdown = !moving_dropdown">Move To</button>
            <div :class="{ 'dropdown-menu': true, show: moving_dropdown }">
              <a class="dropdown-item" href="#" v-for="folder in folders" @click.prevent="moveImagesTo(folder)">{{ folder }}</a>
            </div>
          </div>
          <div>
            <input type="file" accept="image/*" id="image_file" ref="image_file" class="input-file" @change="fileUpload($event)" />
            <label for="image_file" ref="image_file_label">
              Upload
            </label>
          </div>
        </div>
      </form>
      <div class="files">
        <div class="card" v-for="file in filteredCurrentFiles" :key="file.uuid">
          <div class="card-body">
            <div class="select">
              <span class="icon icon-checkbox-unchecked2" v-if="!file.selected" @click="toggleFile(file)"></span>
              <span class="icon icon-checkbox-checked2" v-if="file.selected" @click="toggleFile(file)"></span>
            </div>
            <a :href="'https://assets.brandbot.com/accounts/' + file.account_id + '/' + file.url" target="_blank">
              <div class="thumbnail" :style="'background-image: url(\'https://assets.brandbot.com/accounts/' + file.account_id + '/' + file.url + '\');'"></div>
            </a>
            <div class="file-info">
              <div class="name">{{ file.name }}</div>
              <div class="details">
                {{ file.created_at | humanDate }} |
                <abbr title="Image Width">{{ file.img_width || '?' }}</abbr> x <abbr title="Image Height">{{ file.img_height || '?' }}</abbr> px
                <div v-if="inAFolder(file.url)"><span class="icon icon-folder"></span> {{ inAFolder(file.url) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import NewFolderModal from './NewFolderModal';

  export default {
    components: {
      NewFolderModal,
    },
    data() {
      return {
        moving_dropdown: false,
        new_folder_modal: false,
        query: '',
        image: null,
        tabs: {
          media: true,
          saved_links: false,
        },
        folders: [],
        current_files: [],
        path: '',
      };
    },
    created() {
      this.fetchAssetImages();

      EventBus.$on('new_folder_modal.close', () => {
        this.new_folder_modal = false;
      });

      EventBus.$on('assets.refresh', () => {
        this.fetchAssetImages();
      });
    },
    computed: {
      filteredCurrentFiles() {
        if (this.query.length) {
          return this.current_files.filter(f =>
            f.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1 ||
            f.url.toLowerCase().indexOf(this.query.toLowerCase()) > -1,
          );
        }

        return this.current_files;
      },
      selectedFiles() {
        return this.current_files.filter(f => f.selected);
      },
    },
    methods: {
      inAFolder(url) {
        if (url.indexOf('/') > -1) {
          return url.split('/')[0];
        }

        return false;
      },
      fetchAssetImages() {
        api.getAssetImages(this.path).then((response) => {
          this.folders = response.folders;
          this.current_files = response.current_files;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      toggleFile(file) {
        if (!Object.prototype.hasOwnProperty.call(file, 'selected')) {
          this.$set(file, 'selected', true);
          return;
        }

        file.selected = !file.selected;
      },
      fileUpload(event) {
        const target = event.srcElement || event.target;
        const originalInner = this.$refs.image_file_label.innerHTML;

        if (!target.files.length) {
          return;
        }

        this.$refs.image_file_label.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';
        target.disabled = true;
        const file = target.files[0];

        // eslint-disable-next-line
        if (!confirm(`Are you sure you want to upload ${file.name}?`)) {
          this.$refs.image_file_label.innerHTML = originalInner;
          target.disabled = false;
          return;
        }

        this.image = file;

        const formData = new FormData();
        formData.append('type', 'image');
        formData.append('image_file', this.image, this.image.name);

        api.uploadAssetImage(formData, this.path).then((response) => {
          this.$toasted.success(response.message);

          this.image = null;
          this.$refs.image_file_label.innerHTML = originalInner;
          target.disabled = false;

          this.fetchAssetImages();
        }).catch((err) => {
          this.image = null;
          target.disabled = false;
          this.$refs.image_file_label.innerHTML = originalInner;
          this.$toasted.error(err.errors[Object.keys(err.errors)[0]]);
        });
      },
      selectFolder(path) {
        this.path = path;
        this.fetchAssetImages();
      },
      deleteImages() {
        // eslint-disable-next-line
        if (!confirm(`Are you sure you want to delete ${this.selectedFiles.length} images?`)) {
          return;
        }

        const payload = {
          type: 'image',
          images: this.selectedFiles.map(f => f.uuid),
        };

        api.deleteAssets(payload).then((response) => {
          this.$toasted.success(response.message);
          this.fetchAssetImages();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      deleteFolder(folder) {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to delete this folder? It will delete all images in it. This is not reversible.')) {
          return;
        }

        const payload = {
          type: 'folder',
          folder,
        };

        api.deleteAssets(payload).then((response) => {
          this.$toasted.success(response.message);
          this.fetchAssetImages();
          this.path = '';
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      moveImagesTo(folder) {
        // eslint-disable-next-line
        if (!confirm(`Are you sure you want to move ${this.selectedFiles.length} images to "${folder}?`)) {
          return;
        }

        const payload = {
          images: this.selectedFiles.map(f => f.uuid),
          target: folder,
        };

        api.moveImages(payload).then((response) => {
          this.$toasted.success(response.message);
          this.fetchAssetImages();
          this.path = '';
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .assets-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    padding-top: 4rem;
  }

  .assets-sidebar {
    margin-top: 1px;
    height: 100vh;
    background-color: #fff;
    width: 16rem;
    min-width: 16rem;
    position: fixed;
    padding-top: 1.5rem;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    padding-bottom: 10rem;
    overflow-y: scroll;
    z-index: 3;
  }

  .assets-sidebar h6,
  .assets-sidebar a {
    font-size: .75rem;
  }

  .folder {
    padding: .5rem .375rem;
    font-size: .875rem;
    line-height: 1.25rem;
    color: #1d364b;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 150ms ease-in-out;
  }

  .folder .icon {
    color: red;
    opacity: 0;
    pointer-events: none;
    transition: all 150ms ease-in-out;
  }

  .folder:hover,
  .folder.selected {
    background-color: var(--xpl-color-secondary-bg-lm);
  }

  .folder:hover .icon {
    pointer-events: all;
    opacity: 1;
  }

  .folder:first-child {
    margin-top: 1rem;
  }

  .folder:last-child {
    margin-bottom: 1rem;
  }

  .assets-inner {
    width: 100%;
    padding-top: 1.5rem;
    padding-left: calc(17rem + 1.5rem);
    padding-right: 1.5rem;
  }

  form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  form .buttons {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  form .buttons button {
    margin-right: .5rem;
  }

  form .buttons button:last-child {
    margin-right: 0;
  }

  .files {
    margin-top: 1rem;
  }

  .files .card {
    margin-bottom: 1rem;
  }

  .files .card-body {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: .875rem;
  }

  .thumbnail {
    width: 64px;
    height: 64px;
    /*border: 1px solid #979797;*/
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    /*background-color: #d8d8d8;*/
    margin-right: 1rem;
    margin-left: .625rem;
  }

  .icon-checkbox-unchecked2,
  .icon-checkbox-checked2 {
    cursor: pointer;
    color: #afbcca;
  }

  .file-info .name {
    font-size: .75rem;
    font-weight: 700;
    line-height: 1.25rem;
    color: #1d364b;
  }

  .file-info .details {
    font-size: .75rem;
    font-weight: 700;
    line-height: 1.25rem;
    color: #8b9bac;
  }

  .input-file + label {
    /*font-family: 'Muli';*/
    background-color: #55bef2;
    border: 1px solid #55bef2;
    color: white;
    border-radius: 6px;
    font-size: .875rem;
    font-weight: 500;
    line-height: 1.43;
    padding-top: .375rem;
    padding-bottom: .375rem;
    padding-left: 1rem;
    padding-right: 1rem;
    outline: none;
  }
</style>
