<template>
  <transition name="slidy">
    <div class="tasks-sidebar">
      <div class="actions" v-if="scheduledTaskId">
        <a href="#" class="go-back" @click.prevent="closeSidebar"><i class="fa fa-chevron-left"></i> Close</a>
        <div class="delete-button" @click.stop="deleteTask">
          <span class="material-icons">delete</span>
        </div>
      </div>
      <div class="tasks-sidebar-header">
        <span class="material-icons">assignment</span> Task
      </div>
      <div class="tasks-sidebar-body">
        <div class="section top">
          <form @submit.prevent>
              <img src="https://assets.brandbot.com/public/icons/scheduled-items/empty.svg" v-if="!scheduled_task.complete" @click.stop="toggleStatus" />
              <img src="https://assets.brandbot.com/public/icons/scheduled-items/complete.svg" v-if="scheduled_task.complete" @click.stop="toggleStatus" />
              <span v-if="scheduled_task.task.name && !edit_name" @click.stop="edit_name = true">{{ scheduled_task.task.name }}</span>
              <input type="text" v-model.trim="scheduled_task.task.name" class="form-control" placeholder="Write a task name" v-if="!scheduled_task.task.name || edit_name" v-on-clickaway.prevent="away" @click.stop ref="taskName"/>
          </form>
          <form class="note" @submit.prevent>
            <span v-if="!scheduled_task.task.note && !edit_note" @click.stop="edit_note = true">Add a note</span>
            <span v-if="scheduled_task.task.note && !edit_note" @click.stop="edit_note = true" v-html="scheduled_task.task.note"></span>
            <quill-editor style="width: 100%;" v-model="scheduled_task.task.note" v-if="edit_note" ref="myQuillEditor" :options="editorOption" v-on-clickaway="away" @click.stop></quill-editor>
          </form>
        </div>
        <div class="section">
          <span class="material-icons">event</span>
          <el-form v-if="!scheduled_task.scheduled_at || edit_date" @submit.native.prevent>
            <el-form-item>
              <label>Scheduled Date</label>
              <div>
                <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="scheduled_task.scheduled_at" placeholder="Pick a date" style="width: 100%;" @change="away"></el-date-picker>
              </div>
            </el-form-item>
          </el-form>
          <div class="assigned" v-if="scheduled_task.scheduled_at && !edit_date" @click.stop="edit_date = true">
            <span>Scheduled for {{ scheduled_task.scheduled_at | humanDate(false) }}</span>
          </div>
        </div>
        <div class="section" @click.stop="assign_modal = true">
          <div class="assigned" v-if="!scheduled_task.assigned_user">
            <span class="circle empty"></span>
            <span>No assignment</span>
          </div>
          <div class="assigned active" v-if="scheduled_task.assigned_user">
            <span class="user-badge" v-if="scheduled_task.assigned_user.first_name && scheduled_task.assigned_user.last_name">
              {{ scheduled_task.assigned_user.first_name.slice(0,1) }}{{ scheduled_task.assigned_user.last_name.slice(0,1) }}
            </span>
            <span class="user-badge" v-else>
              {{ scheduled_task.assigned_user.email.slice(0,2) }}
            </span>
            <span>{{ scheduled_task.assigned_user.first_name ? scheduled_task.assigned_user.first_name + ' ' + scheduled_task.assigned_user.last_name : scheduled_task.assigned_user.email }}</span>
            <span class="icon icon-cross3 remove-assigned" @click.stop="removeAssigned"></span>
          </div>
          <AssignModal :scheduledTaskId="scheduledTaskId" v-if="assign_modal" :assignedUser="scheduled_task.assigned_user" />
        </div>

        <div class="section" v-if="scheduled_task.scheduled_plan">
          <div class="plan active">
            <span class="material-icons">view_list</span>
            {{ scheduled_task.scheduled_plan.name }}
            <span class="icon icon-cross3 remove-assigned" @click.stop="removePlan"></span>
          </div>
        </div>

        <div class="section" style="border-bottom: solid 1px rgb(232, 232, 232);">
          <div class="assigned" v-if="!scheduled_task.associated_contact" @click.stop="associate_modal = true">
            <span class="material-icons">person</span>
            <span>No associated contact</span>
          </div>
          <div class="assigned associated active" v-if="scheduled_task.associated_contact">
            <span class="material-icons">person</span>
            <div>
              <h6>Associated Contact</h6>
              <a :href="'/contacts/' + scheduled_task.associated_contact.uuid" target="_blank">{{ scheduled_task.associated_contact.email }}</a>
              <div class="meta">Phone:
                <span v-if="scheduled_task.associated_contact.brandbot_phone">{{ scheduled_task.associated_contact.brandbot_phone }}</span>
                <span v-else>&mdash;</span>
              </div>
              <div class="meta">Name:
                <span v-if="scheduled_task.associated_contact.first_name">{{ scheduled_task.associated_contact.first_name }} {{ scheduled_task.associated_contact.last_name }}</span>
                <span v-else>&mdash;</span>
              </div>
            </div>
            <span class="icon icon-cross3 remove-assigned" @click.stop="removeAssociated"></span>
          </div>
          <AssociateContactModal :scheduledTaskId="scheduledTaskId" v-if="associate_modal" :associatedContact="scheduled_task.associated_contact" />
        </div>

        <div class="comments">
          <img class="no-comments" src="https://assets.brandbot.com/public/empty-states/comment-section.svg" v-if="!scheduled_task.comments || !scheduled_task.comments.length" />
          <div class="comment" v-for="comment in scheduled_task.comments">
            <div class="user-badge" :aria-label="comment.author.first_name ? comment.author.first_name + ' ' + comment.author.last_name : comment.author.email">{{ comment.author.first_name ? comment.author.first_name.slice(0,1) + comment.author.last_name.slice(0,1) : comment.author.email.slice(0,2) }}</div>
            <div>
              {{ comment.body }}
              <div class="small text-muted">
                {{ comment.created_at | humanDateTime }}
                <span v-if="$store.state.user.id === comment.user_id">&middot;</span>
                <a href="#" @click.stop="deleteComment(comment.uuid)" class="text-muted" v-if="$store.state.user.id === comment.user_id">Delete</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="comments-form"  v-if="scheduledTaskId">
        <div class="user-badge">{{ $store.state.user.initials }}</div>
        <form>
          <textarea placeholder="Add a comment" class="form-control" v-model="comment" @focus="expand($event)" @focusout="shrink($event)" rows="1" style="resize: none;"></textarea>
          <button type="button" ref="post_comment" class="xpl-button xpl-button--primary" @click="postComment" :disabled="!comment" hidden>Comment</button>
        </form>
      </div>
      <div class="tasks-sidebar-footer" v-if="!scheduledTaskId">
        <el-button type="primary" class="xpl-button xpl-button--primary":disabled="!scheduled_task.task.name || !scheduled_task.scheduled_at" @click="saveTask">Save Task</el-button>
        <el-button type="secondary" class="xpl-button xpl-button--secondary"  @click="closeSidebar">Cancel</el-button>
      </div>
    </div>
  </transition>
</template>
<script>
  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';
  import { quillEditor } from 'vue-quill-editor';
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import { directive as onClickaway } from 'vue-clickaway';
  import AssignModal from './AssignModal';
  import AssociateContactModal from './AssociateContactModal';

  export default {
    props: ['scheduledTaskId', 'scheduledPlanId', 'associatedContact'],
    directives: {
      onClickaway,
    },
    components: {
      quillEditor,
      AssignModal,
      AssociateContactModal,
    },
    data() {
      return {
        editorOption: {
          placeholder: 'Add a note',
          modules: {
            toolbar: ['bold', 'italic', 'underline', 'link'],
          },
        },
        comment: null,
        edit_note: false,
        edit_name: false,
        edit_date: false,
        assign_modal: false,
        associate_modal: false,
        scheduled_task: {
          task: {
            name: null,
            note: null,
          },
          scheduled_at: null,
          assigned_user: null,
          associated_contact: this.associatedContact ? this.associatedContact : null,
          comments: [],
        },
      };
    },
    mounted() {
      document.body.classList.add('stop-scroll');
      this.fetchTask();
       if(this.scheduled_task.task.name === null) {
        this.$refs.taskName.focus();
      }
    },
    created() {
     
      if (!this.scheduledTaskId) {
        this.edit_name = true;
      }

      EventBus.$on('scheduled_task.assign.close', () => {
        this.assign_modal = false;
      });

      EventBus.$on('scheduled_task.associate.close', () => {
        this.associate_modal = false;
      });

      EventBus.$on('scheduled_task.assign_modal.choose', (assignedUser) => {
        this.scheduled_task.assigned_user = assignedUser;
        if (this.scheduledTaskId) {
          this.saveTask();
        }
      });

      EventBus.$on('scheduled_task.associate.choose', (associatedContact) => {
        this.scheduled_task.associated_contact = associatedContact;
        if (this.scheduledTaskId) {
          this.saveTask();
        }
      });
    },
    beforeDestroy() {
      EventBus.$off('scheduled_task.assign.close');
      EventBus.$off('scheduled_task.associate.close');
      EventBus.$off('scheduled_task.assign_modal.choose');
      EventBus.$off('scheduled_task.associate.choose');
      document.body.classList.remove('stop-scroll');
    },
    methods: {
      away() {
        if (this.scheduledTaskId && (this.edit_note || this.edit_name || this.edit_date)) {
          this.saveTask();
          this.edit_note = false;
          this.edit_name = false;
          this.edit_date = false;
        }
      },
      toggleStatus() {
        if (!this.scheduledTaskId) {
          return;
        }

        const payload = {
          complete: !this.scheduled_task.complete,
        };

        api.editScheduledTask(this.scheduledTaskId, payload).then(() => {
          this.fetchTask();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchTask() {
        if (this.scheduledTaskId) {
          api.getScheduledTask(this.scheduledTaskId).then((response) => {
            this.scheduled_task = response.scheduled_task;
          }).catch((err) => {
            this.$toasted.error(err.message);
          });
        }
      },
      closeSidebar(refresh = true) {
        EventBus.$emit('calendar.task_sidebar.close', refresh);
      },
      deleteTask() {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to delete this task?')) {
          return;
        }

        api.deleteScheduledTask(this.scheduledTaskId).then((response) => {
          this.$toasted.success(response.message);
          this.closeSidebar();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      saveTask() {
        const payload = {
          name: this.scheduled_task.task.name,
          note: this.scheduled_task.task.note,
          scheduled_at: this.scheduled_task.scheduled_at,
          assigned_user_id: this.scheduled_task.assigned_user,
          associated_contact_id: this.scheduled_task.associated_contact ?
            this.scheduled_task.associated_contact.id : null,
        };

        if (payload.assigned_user_id && payload.assigned_user_id.uuid) {
          payload.assigned_user_id.type = 'group';
        }

        if (this.scheduledTaskId) {
          api.editScheduledTask(this.scheduledTaskId, payload).catch((err) => {
            this.$toasted.error(err.message);
          });
        } else {
          payload.plan_id = this.scheduledPlanId;
          api.createScheduledTask(payload).then((response) => {
            this.$toasted.success(response.message);
            this.closeSidebar();
          }).catch((err) => {
            this.$toasted.error(err.message);
          });
        }
      },
      expand(event) {
        const target = event.srcElement || event.target;
        target.rows = 3;

        this.$refs.post_comment.hidden = false;
      },
      shrink(event) {
        const target = event.srcElement || event.target;
        target.rows = 1;

        if (!this.comment) {
          this.$refs.post_comment.hidden = true;
        }
      },
      removeAssigned() {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to remove the assigned person?')) {
          return;
        }

        const payload = {
          assigned_user_id: null,
        };

        api.editScheduledTask(this.scheduledTaskId, payload).then(() => {
          this.fetchTask();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      removeAssociated() {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to remove the associated contact?')) {
          return;
        }

        const payload = {
          associated_contact_id: null,
        };

        api.editScheduledTask(this.scheduledTaskId, payload).then(() => {
          this.fetchTask();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      removePlan() {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to remove the associated Scheduled Plan?')) {
          return;
        }

        const payload = {
          plan_id: null,
        };

        api.editScheduledTask(this.scheduledTaskId, payload).then(() => {
          this.fetchTask();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      postComment(event) {
        const target = event.target || event.srcElement;
        target.disabled = true;
        const payload = {
          commentable_type: 'BrandBot\\Models\\ScheduledTask',
          commentable_id: this.scheduledTaskId,
          user_id: this.$store.state.user.id,
          body: this.comment,
        };

        api.saveComment(payload).then((response) => {
          this.scheduled_task.comments = response.comments;
          this.$toasted.success(response.message);
          this.comment = '';
          target.disabled = false;
        }).catch((err) => {
          target.disabled = false;
          this.$toasted.error(err.message);
        });
      },
      deleteComment(commentUuid) {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to delete this comment?')) {
          return;
        }

        api.deleteComment(commentUuid).then((response) => {
          this.$toasted.success(response.message);
          this.fetchTask();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchComments() {
        api.getCommentsScheduledEmail(this.scheduledTaskId).then((response) => {
          this.comments = response.comments;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .tasks-sidebar {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2000;
    width: 33.625rem;
    height: 100vh;
    background-color: #ffffff;
    // box-shadow: -1px 0 10px 0 rgba(139, 155, 172, 0.36);
    padding-top: 1rem;
    height: calc(100vh - 3rem);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 4rem;
  }

  .tasks-sidebar .actions {
    display: flex;
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 1.5rem;
  }

  .tasks-sidebar .actions .delete-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    border: 1px solid  rgb(214, 224, 232);
    color: rgb(175, 188, 202);
  }

  .tasks-sidebar .actions .delete-button .material-icons {
    font-size: .875rem;
  }

  .tasks-sidebar-header {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: .75rem;
    line-height: 1rem;
    color: rgb(139, 155, 172);
    margin-bottom: 1.5rem;
    padding-left: 2rem;
    padding-right: 1.5rem;
  }

  .tasks-sidebar-body {
    padding: 0;
    width: 100%;
  }

  .tasks-sidebar-header .material-icons {
    font-size: .875rem;
    margin-right: .5rem;
    cursor: pointer;
  }

  form {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .section.top img {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    margin-right: .75rem;
    padding-top: 0;
  }

  .section {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 1.5rem;
    border-top: solid 1px rgb(232, 232, 232);
  }

  .section:first-child {
    border-top: none;
  }

  .section:last-child {
    border-bottom: solid 1px rgb(232, 232, 232);
  }

  .section.top {
    padding-top: .25rem;
    flex-direction: column;
    justify-content: center;
    font-size: 1.25rem;
  }

  .section.top form {
    flex-direction: row;
    width: 100%;
    display: flex;
  }

  .section.top form span {
    line-height: 1.25;
    border: 2px solid transparent;
  }

  .section.top form input {
    margin-left: -.25rem;
    margin-top: -.25rem;
    margin-bottom: -.25rem;
    line-height: 1.25;
    font-size: 1.25rem;
    padding: 0;
    padding-left: .25rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .section .material-icons {
    margin-right: .75rem;
    color: rgb(150, 160, 171);
    font-size: 1.5rem;
  }

  .section form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .section form .datepicker {
    width: 100% !important;
  }

  .section form.note {
    flex-direction: column;
    font-size: .875rem;
    line-height: 1.25;
    color: rgb(139, 155, 172);
    padding-left: 2.75rem;
    padding-top: 1rem;
    margin-bottom: 2rem;
  }

  .section form.note span {
    border: 2px solid transparent;
    line-height: 1.25;
  }

  .section form.note textarea {
    margin-left: -.25rem;
    margin-top: -.25rem;
    padding: 0;
    padding-left: .25rem;
    padding-top: .25rem;
    line-height: 1.25;
    width: 100%;
    margin-bottom: 0;
  }

  .section .assigned,
  .section .plan {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .875rem;
    line-height: 1.25rem;
    color: rgb(97, 112, 128);
  }

  .section .associated {
    align-items: flex-start;
  }

  .section .associated h6 {
    font-size: .625rem;
    text-transform: uppercase;
    line-height: 1.25rem;
    color: rgb(97, 112, 128);
  }

  .section .associated .meta {
    margin-top: .5rem;
    font-size: .75rem;
    line-height: 1rem;
    color: rgb(139, 155, 172);
  }

  .section .associated .meta span {
    color: rgb(97, 112, 128);
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 99rem;
    margin-right: .75rem;
  }

  .assigned span,
  .plan span {
    margin-right: .75rem;
  }

  .assigned .remove-assigned,
  .plan .remove-assigned {
    cursor: pointer;
    opacity: 0;
    margin-left: auto;
    transition: all 300ms ease-in-out;
  }

  .assigned:hover .remove-assigned,
  .plan:hover .remove-assigned {
    opacity: 1;
  }

  .assigned.active {
    color: #2E4457;
  }

  .empty {
    border: 1px dashed #b7bfc6;
  }

  .full {
    border: 1px solid #b7bfc6;
  }

  .tasks-sidebar-footer,
  .comments-form {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    margin-top: auto;
    border-top: 2px solid rgb(205, 215, 226);
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .comments-form {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 33.625rem;
  }

  .comments-form form {
    text-align: right;
    flex-direction: column;
    align-items: flex-end;
  }

  .comments-form form button {
    margin-top: .5rem;
  }

  .comments-form .user-badge {
    margin-top: .5rem;
    margin-right: .875rem;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .tasks-sidebar-footer button {
    margin-right: .5rem;
  }

  .tasks-sidebar-footer button:last-child {
    margin-right: 0;
  }

  .comments {
    width: 100%;
    min-height: 5rem;
  }

  .comments .comment {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: .625rem;
    padding-bottom: .625rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: rgba(99, 114, 130, 1);
    border-bottom: 1px solid #e8e8e8;
    font-size: .875rem;
  }

  .comments .comment:last-child {
    border-bottom: none;
  }

  .comments .comment .user-badge {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: .875rem;
  }

  .comments .no-comments {
    margin-top: 2rem;
    display: flex;
    width: 5.25rem;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .el-form-item__content {
    line-height: 1 !important;
  }
</style>
