<template>
  <transition name="fade">
    <div class="contact-list" v-cloak>
      <div class="list-nav">
        <router-link :to="'/contacts/lists/' + contact_list.uuid" class="go-back"><i class="fa fa-chevron-left"></i> Go Back</router-link>
        {{ contact_list.name }}
        <span></span>
      </div>

      <div class="container-fluid">
        <h2>Manually add and import contacts to your Segment</h2>
        <p>Unsubscribing a contact will make sure that contact does not recieve any emails from {{$store.getters.brandName}}. Did you unsubscribe a contact by mistake? Here are the ways to resubscribe a contact.</p>

        <div class="inner">
          <div class="section">
            <h3>Upload from .CSV</h3>
            <div class="card">
              <div class="card-body">
                <form @submit.prevent>
                  <label>Select CSV</label>
                  <div class="form-group">
                    <input type="file" accept="text/csv,text/plain" id="csv_file" name="csv_file" class="input-file" @change="fileUpload($event)" />
                    <label for="csv_file">
                      <span ref="csv_file_name"></span>
                      Browse Files
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label" for="check">
                      <input type="checkbox" name="check" id="check" v-model="check" class="form-check-input" />
                      I understand that importing contacts may affect my billing plan
                    </label>
                  </div>
                </form>
                <p class="tiny-text">Duplicate addresses will be removed. If you upload a contact that already exists in your account, {{$store.getters.brandName}} will update that contact; if that contact is unsubscribed, they will be subscribed. By uploading you are agreeing that you have consent to email all of the imported contacts.</p>
                <button type="button" class="xpl-button xpl-button--primary" :disabled="!check || !file" @click="upload">Upload CSV</button>
              </div>
            </div>
          </div>
          <div class="section">
            <h3>Add a single contact</h3>
            <div class="card">
              <div class="card-body flex">
                <form @submit.prevent>
                  <div class="form-group">
                    <label for="email">Email Address</label>
                    <input type="text"
                      id="email"
                      name="email"
                      v-model="one_off.email"
                      :class="{ 'form-control': true, 'is-invalid': $v.one_off.email.$error }"
                      placeholder="Email"
                      @input="$v.one_off.email.$touch()" />
                      <div class="invalid-feedback" v-if="!$v.one_off.email.email">A valid email is required.</div>
                  </div>
                  <div class="form-group">
                    <label for="first_name">First Name</label>
                    <input type="text"
                      id="first_name"
                      name="first_name"
                      v-model="one_off.first_name"
                      :class="{ 'form-control': true, 'is-invalid': $v.one_off.first_name.$error }"
                      placeholder="First Name"
                      @input="$v.one_off.first_name.$touch()" />
                      <div class="invalid-feedback" v-if="!$v.one_off.first_name.required">A first name is required.</div>
                      <div class="invalid-feedback" v-if="!$v.one_off.first_name.minLength">We only accept first names of at least 2 characters.</div>
                  </div>
                  <div class="form-group">
                    <label for="last_name">Last Name</label>
                    <input type="text"
                      id="last_name"
                      name="last_name"
                      v-model="one_off.last_name"
                      :class="{ 'form-control': true, 'is-invalid': $v.one_off.last_name.$error }"
                      placeholder="Last Name"
                      @input="$v.one_off.last_name.$touch()" />
                      <div class="invalid-feedback" v-if="!$v.one_off.last_name.required">A last name is required.</div>
                      <div class="invalid-feedback" v-if="!$v.one_off.last_name.minLength">We only accept last names of at least 2 characters.</div>
                  </div>
                </form>
                <button type="button" class="xpl-button xpl-button--primary" @click="addContact" :disabled="$v.$error || $v.$invalid">Add Contact</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import * as api from '@/api';
  import { required, minLength, email } from 'vuelidate/lib/validators';

  export default {
    props: ['uuid'],
    data() {
      return {
        contact_list: {},
        check: false,
        file: null,
        one_off: {
          email: '',
          first_name: '',
          last_name: '',
        },
      };
    },
    validations: {
      one_off: {
        email: {
          required,
          email,
        },
        first_name: {
          required,
          minLength: minLength(2),
        },
        last_name: {
          required,
          minLength: minLength(2),
        },
      },
    },
    beforeRouteEnter(to, from, next) {
      api.getContactList(to.params.uuid).then((response) => {
        next((vm) => {
          vm.contact_list = response.contact_list;
        });
      }).catch(() => {
        this.$toasted.error('Something wrong happened while trying to fetch this contact list...');
      });
    },
    methods: {
      addContact() {
        if (!this.one_off.email || !this.one_off.first_name || !this.one_off.last_name) {
          this.$toasted.error('Please make sure all fields are filled out properly.');
          return;
        }

        const payload = {
          contact_list_id: this.contact_list.id,
          email: this.one_off.email,
          first_name: this.one_off.first_name,
          last_name: this.one_off.last_name,
        };

        api.addContactContactList(payload).then((response) => {
          this.$toasted.success(response.message);
          this.one_off.email = '';
          this.one_off.first_name = '';
          this.one_off.last_name = '';
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fileUpload(event) {
        const target = event.target || event.srcElement;
        if (!target.files.length) {
          return;
        }

        const file = target.files[0];

        this.$refs.csv_file_name.innerHTML = file.name;
        this.file = file;
      },
      upload(event) {
        const target = event.srcElement || event.target;
        const formData = new FormData();
        formData.append('csv_file', this.file, this.file.name);
        formData.append('contact_list_id', this.contact_list.id);

        target.disabled = true;

        api.uploadContactsContactList(formData).then((response) => {
          this.$toasted.success(response.message);
          this.file = null;
          this.check = false;
          target.disabled = false;
          this.$router.push(`/contacts/lists/${this.contact_list.uuid}`);
        }).catch((err) => {
          target.disabled = false;
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .list-nav span {
    width: calc(100% / 3);
  }

  h2, p {
    margin-top: .5rem;
  }

  p {
    max-width: 34rem;
  }

  p.tiny-text {
    font-size: .625rem;
    margin-bottom: 2rem;
  }

  .inner {
    margin-top: 2rem;
  }

  .section {
    margin-bottom: 2rem;
  }

  .card {
    // box-shadow: 0 1px 1px 0 rgba(205, 215, 226, 0.35);
  }

  .card-body.flex form {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .form-group {
    flex: 1;
    margin-right: 1rem;
  }

  .form-group:last-child {
    margin-right: 0;
  }
</style>
