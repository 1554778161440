<template>
  <!--  <div class="row">-->
  <!--    <span class="title">{{ step.name }}</span>-->
  <!--    <div>-->
  <!--      <div class="disable-caps">-->
  <!--        {{ step.default.option | countComponent(step.default.value) }}-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <div>
    <div class="row" v-if="condition.selected_genders && condition.selected_genders.length">
      <span class="title">Genders:</span>
      <div>
        <div v-for="g in condition.selected_genders">{{ g.gender }}</div>
      </div>
    </div>
    <div class="row" v-else-if="condition.timeframe">
      <span class="title">Time Frame:</span>
      <div>
        <div class="disable-caps">
          <span v-if="condition.timeframe === 'all'">{{ condition.timeframe | timeFrame }}</span>
          <span v-if="condition.timeframe === 'relative_future'">
              {{ condition.comparison === 'more' ? 'At least' : condition.comparison }} {{ condition.relative_days }} Days {{
              condition.comparison === 'more' ? 'from now' : condition.timeframe | timeFrame
            }}
            </span>
          <span v-if="condition.timeframe === 'more'">
              Within the next {{ condition.relative_days }} day{{ condition.relative_days > 1 ? 's' : '' }}
            </span>
          <span v-if="condition.timeframe === 'exactly'">
              Exactly {{ condition.relative_days }} day{{ condition.relative_days > 1 ? 's' : '' }} from now
            </span>
          <span v-if="condition.timeframe === 'absolute'">
              {{ condition.comparison || condition.comparison_date }}
              <span v-if="condition.start_date">{{ condition.start_date | humanDate(false) }}</span>
              <span v-if="condition.end_date"> and {{ condition.end_date | humanDate(false) }}</span>
              <span v-if="condition.start_date_day && condition.start_date_month">{{
                  condition.start_date_month
                }} {{ condition.start_date_day }}</span>
              <span v-if="condition.end_date_day && condition.end_date_month"> and {{
                  condition.end_date_month
                }} {{ condition.end_date_day }}</span>
            </span>
          <span v-if="condition.timeframe === 'relative'">
              {{ condition.comparison }}
              <span v-if="condition.comparison === 'more'">than</span>
              <span v-if="condition.comparison === 'within'">the last</span>
              {{ condition.relative_days }} days
              <span v-if="condition.comparison !== 'within'">ago</span>
            </span>
          <span v-if="condition.timeframe === 'entering_campaign'">{{ condition.timeframe | timeFrame }}</span>
          <span v-if="condition.timeFrame === 'future'">In The Future</span>
        </div>
      </div>
    </div>

    <div class="row" v-else-if="condition.hasOwnProperty('is_unsubscribed')">
      <span class="title">Unsubscribed</span>
      <div>{{ condition.is_unsubscribed ? 'Yes' : 'No' }}</div>
    </div>

    <div v-else-if="condition.form">
      <div class="row">
        <span class="title">Form:</span>
        <div>{{ condition.form.name }}</div>
      </div>

      <div class="row" v-if="condition.field">
        <span class="title">Field:</span>
        <div>{{ condition.field.label }}</div>
      </div>
    </div>

    <div v-else-if="condition.hasOwnProperty('in_segment')">
      <div class="row">
        <span class="title">In Segments:</span>
        <div>{{ condition.in_segment ? 'Yes' : 'No' }}</div>
      </div>
      <div class="row" v-if="condition.selected_segments && condition.selected_segments.length">
        <span class="title">Segments:</span>
        <div>
          <div v-for="s in condition.selected_segments">{{ s.name }}</div>
        </div>
      </div>
    </div>

    <div v-else-if="condition.condition.uuid === '9ebf3698-2407-4d70-97f1-afa09adddac9'">
      <div class="row" v-if="condition.condition.uuid === '9ebf3698-2407-4d70-97f1-afa09adddac9'">
        <span class="title">Interaction</span>
        <div>{{ condition.interacted ? 'Has Interacted' : 'Has Not Interacted' }}</div>
      </div>
      <div class="row" v-if="condition.condition.uuid === '9ebf3698-2407-4d70-97f1-afa09adddac9' && condition.action">
        <span class="title">Action</span>
        <div>{{ condition.action }}</div>
      </div>
      <div class="row"
           v-if="condition.condition.uuid === '9ebf3698-2407-4d70-97f1-afa09adddac9' && condition.filtering === 'all'">
        <span class="title">Filtering</span>
        <div>All Emails</div>
      </div>
      <div class="row"
           v-if="condition.condition.uuid === '9ebf3698-2407-4d70-97f1-afa09adddac9' && condition.filtering === 'specific'">
        <span class="title">Filtered</span>
        <div>
          <div v-for="email in condition.emails">
            {{ email.name }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>(no summary yet)</div>
  </div>
</template>

<script>
export default {
  name: 'BrandBotSummary',
  props: ['condition'],
  created() {
    this.$log('brandbotsummary', JSON.stringify(this.condition));
  },
  filters: {
    timeFrame(value) {
      if (value === 'all') {
        return 'All Time';
      }

      if (value === 'entering_campaign') {
        return 'Since entering this campaign';
      }

      if (value === 'relative_future') {
        return 'From now';
      }

      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "summary_item.scss";
</style>
