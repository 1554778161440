<template>
  <div class="field-container">
    <div class="card-condition">
      <el-row>
        <el-col :span="1.5" class="step-col">
          <div class="field-step">
            {{ this.fieldStep }}
          </div>
        </el-col>
        <el-col :span="12">
          <span class="field-title">{{ this.title }}</span>
          <el-form :model="multiForm" ref="multiSelectForm">
            <div class="option-body">
              <!-- First Selection -->
              <el-form-item prop="specificChoice">
                <el-radio v-if="this.anyLabel"
                          name="any-option"
                          class="radio-btn field-label-text any-option"
                          v-model="multiForm.specificChoice"
                          @change="$emit('update:specificChoice', multiForm.specificChoice)"
                          label="any">{{ this.anyLabel }}
                </el-radio>
                <el-radio v-if="this.specificLabel"
                          class="radio-btn field-label-text specific-option"
                          name="specific-option"
                          v-model="multiForm.specificChoice"
                          @change="$emit('update:specificChoice', multiForm.specificChoice)"
                          label="specific">{{ this.specificLabel }}
                </el-radio>
              </el-form-item>
              <div class="d-flex mb-10 inner-field-padding" v-if="multiForm.specificChoice === 'specific'">
                <!-- Second Selection -->
                <el-form-item class="speciic-choice-item" prop="specificChoice">
                  <el-radio class="desc-text"
                            name="specific-option-any"
                            v-model="multiForm.exclusionChoice"
                            @change="$emit('update:exclusionChoice', multiForm.exclusionChoice)"
                            label="any"
                            border>{{ this.specificAnyLabel }}
                  </el-radio>
                  <el-radio class="desc-text"
                            name="specific-option-exclude"
                            v-model="multiForm.exclusionChoice"
                            @change="$emit('update:exclusionChoice', multiForm.exclusionChoice)"
                            label="except"
                            border>{{ this.specificExclusionLabel }}
                  </el-radio>
                </el-form-item>
              </div>
              <el-form-item class="inner-field-padding" v-if="multiForm.specificChoice === 'specific'"
                            prop="specificChoice">
                <el-select v-if="!hugeList"
                           @change="$emit('update:selectedOptions', multiForm.selectedOptions)"
                           v-model="multiForm.selectedOptions"
                           class="max-width"
                           multiple
                           filterable
                           collapse-tags
                           clearable
                           size="large"
                           :value-key="selectKey"
                           placeholder="Select">
                  <el-option v-for="opt in this.options"
                             :key="opt.id"
                             :label="opt.name"
                             :value="opt">
                  </el-option>
                </el-select>
                <div v-else class="position-relative">
                  <el-select @change="$emit('update:selectedOptions', multiForm.selectedOptions)"
                             v-model="multiForm.selectedOptions"
                             icon="el-icon-search"
                             class="max-width"
                             :class="{ 'mag-glass': showMagGlass}"
                             multiple
                             filterable
                             collapse-tags
                             clearable
                             size="large"
                             remote
                             reserve-keyword
                             placeholder="Type to search"
                             :remote-method="remoteMethod"
                             @blur="selectOnBlur"
                             :loading="loading"
                             :value-key="selectKey">
                    <el-option v-for="opt in this.remoteOptions"
                               :key="opt.id"
                               :label="opt.name"
                               :value="opt">
                    </el-option>
                  </el-select>
                  <i v-if="showMagGlass" class="position-absolute el-icon-search"></i>
                </div>
              </el-form-item>
            </div>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>

export default {
  props: [
    'fieldIndex',
    'anyLabel',
    'specificLabel',
    'specificAnyLabel',
    'specificExclusionLabel',
    'title',
    'fieldStep',
    'selectedOptions',
    'specificChoice',
    'exclusionChoice',
    'options',
    'selectKey',
    'id',
    'typeToSearch',
  ],
  data() {
    return {
      loading: false,
      remoteOptions: [],
      MAX_ITEMS: 500,
      hugeList: this.typeToSearch || false,
      multiForm: {
        selectedOptions: this.selectedOptions,
        specificChoice: this.specificChoice,
        exclusionChoice: this.exclusionChoice,
      },
    };
  },
  created() {
    this.remoteOptions = [];
    if (this.hugeList) {
      this.remoteOptions.push(...this.selectedOptions);
    } else {
      this.remoteOptions = this.options;
    }
  },
  methods: {
    selectOnBlur() {
      console.log("selectOnBlur")
      this.remoteOptions = [...this.selectedOptions];
    },
    remoteMethod(query) {
      const q = query.toLowerCase().trim();
      if (q.length > 0) {
        const words = q.split(/\++/).map(x => x.trim());
        const selectedIds = this.selectedOptions.map(x => x.id) || [];
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          let filtered_options = [...this.options];
          for (const w of words) {
            filtered_options = filtered_options.filter(item => {
              return item.name.toLowerCase().indexOf(w) > -1;
            })
          }
          filtered_options = filtered_options.filter(item => {
            return !selectedIds.includes(item.id)
          })
          this.remoteOptions = [...this.selectedOptions];
          this.remoteOptions.push(...filtered_options.slice(0, this.MAX_ITEMS));
        }, 200);
      } else {
        this.remoteOptions = [...this.selectedOptions];
      }
    }
  },
  computed: {
    showMagGlass() {
      return !this.selectedOptions || !this.selectedOptions.length
    }
  }
};
</script>
<style lang="scss" scoped>
@import "src/scss/global/_spacing.scss";
@import "src/scss/global/_new_main.scss";

.mb-10 {
  margin-bottom: 10px;
}

.card-condition {
  border: solid 1px var(--xpl-color-gray-400);
  box-shadow: none;
  border-radius: 0;
  background-color: #FFFFFF;
  cursor: auto;
}

.card-condition:hover {
  background-color: #FFFFFF;
  cursor: auto;
}

.field-step {
  height: 1.5rem;
  width: 1.5rem;
  background-color: $bb-color-blue-v2;
  font-family: $primary-bb-font-family;
  font-size: $base-font-size;
  color: #FFFFFF;
  border-radius: 50%;
  text-align: center;
  margin: $space-base-inline;
}

.field-title {
  font-family: $primary-bb-font-family;
  font-weight: 700;
  font-size: $base-font-size;
  color: $base-color-black-v2;
  line-height: $loose-medium-line-height;
}

.field-label-text {
  display: block;
}

.field-label-text /deep/ .el-radio__label {
  font-family: $primary-bb-font-family !important;
  font-size: $base-loose-font-size !important;
  color: $base-color-black-v2 !important;
  line-height: $loose-line-height !important;
  padding-left: 8px;
}

.field-label-text /deep/ .el-radio__inner {
  background-color: #F2F4F6 !important;
  border: 1px solid #A6B2BF !important;
  height: 16px;
  width: 16px;
}

.field-label-text /deep/ .el-radio__input.is-checked .el-radio__inner::after {
  background-color: var(--xpl-color-secondary-lm) !important;
  width: 12px;
  height: 12px;

}

.radio-btn {
  margin-top: $space-extra-tight;
  margin-bottom: $space-extra-tight;
}

.option-body {
  margin-top: 20px;
}

.option-body /deep/ .el-form-item__content {
  line-height: 0;
}

.speciic-choice-item /deep/ .el-radio.is-bordered.is-checked {
  border-color: var(--xpl-color-secondary-lm);
}

.speciic-choice-item /deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #606266;
}

.speciic-choice-item /deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #606266;
}

.speciic-choice-item /deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: var(--xpl-color-secondary-lm) !important;
  background: var(--xpl-color-secondary-lm) !important;
}

.desc-text {
  margin: 4px 0 4px 0;
  margin-left: 0;
}

.el-radio.is-bordered + .el-radio.is-bordered {
  margin-left: 0;
}

.el-form-item /deep/ .el-radio.is-bordered {
  margin-left: 0;
}

.max-width {
  margin: 8px 0 8px 0;
  width: 100% !important;
}

.max-width /deep/ .el-input.is-focus .el-input__inner {
  border-color: var(--xpl-color-secondary-lm);
}

.field-body-text {
  font-family: $primary-bb-font-family;
  font-weight: 400;
  font-size: $base-loose-font-size;
  color: #00152A;
  line-height: $loose-line-height;
  margin-top: $space-loose
}

.more-summary {
  font-size: 14px;
  color: #617080;
}

.result {
  margin-right: 8px;
}

.field-container {
  margin-top: 28px;
}

.inner-field-padding {
  padding-left: 24px;
}

.mag-glass {
  /deep/ input {
    padding-left: 26px;
    margin-left: 0;
  }
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
  display: block;
  top: 22px;
  left: 8px;
}

</style>
