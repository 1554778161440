<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div data-test-header class="modal-header">
            Create a Quick Landing Page
            <div data-test-close-button class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <div style="width: 50rem;">
              <div style="margin-bottom: 1rem;" v-if="showGoBackButton">
                <a data-test-back-button class="go-back" href="#" @click.prevent="previousStep">
                  <i class="fa fa-caret-left"></i> Go Back
                </a>
              </div>
              <div  data-test-step-one style="margin-bottom: 2rem" v-if="steps.one">
                <el-row style="margin-bottom: 1.25rem;">
                  <el-col :span="1">
                    <span class="step-circle">1</span>
                  </el-col>
                  <el-col  data-test-sub-header :span="23" class="v2__heading">Do you want to use an existing Landing Page, or create a new one?</el-col>
                </el-row>
                <el-row>
                  <el-col :offset="1">
                    <el-radio-group v-model="type">
                      <el-radio data-test-new-landing-radio :label="'new'" border>New Landing Page</el-radio>
                      <el-radio data-test-existing-landing-radio :label="'existing'" border>Existing Landing Page</el-radio>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </div>

              <div data-test-existing-step-2 style="margin-bottom: 2rem" v-if="steps.two && type === 'existing'">
                <el-row style="margin-bottom: 1.25rem;">
                  <el-col :span="1">
                    <span class="step-circle">1</span>
                  </el-col>
                  <el-col data-test-existing-step-2-header :span="23" class="v2__heading">Select an existing Landing Page</el-col>
                </el-row>
                <el-row>
                  <el-col :offset="1">
                    <el-form>
                      <el-form-item>
                        <label data-test-existing-step-2-label>Landing Pages</label>
                        <el-select data-test-existing-step-2-landing-select v-model="landing_page" placeholder="Select a Landing Page" style="width: 100%;" value-key="id">
                            <el-option :data-test-existing-step-2-landing-option="item.id" v-for="item in landing_pages" :key="item.id" :label="item.name" :value="item">
                            </el-option>
                        </el-select>
                      </el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </div>

              <div data-test-step-two-new style="margin-bottom: 2rem" v-if="steps.two && type === 'new'">
                <el-row style="margin-bottom: 1.25rem;">
                  <el-col :span="1">
                    <span class="step-circle">2</span>
                  </el-col>
                  <el-col data-test-new-step-2-header :span="23" class="v2__heading">What are you selling?</el-col>
                </el-row>

                <el-row>
                  <el-col :offset="1">
                    <el-radio data-test-new-step-2-pricing-radio v-model="settings.saleType" label="pricing_option" border>Pricing Option</el-radio>
                    <el-radio data-test-new-step-2-contract-radio v-model="settings.saleType" label="contract" border>Contract</el-radio>
                    <div class="options" v-if="settings.saleType">
                      <el-form v-if="settings.saleType === 'pricing_option'">
                        <label>Select the Pricing Option</label>
                        <el-form-item data-test-new-step-2-pricing-form-item>
                          <el-select data-test-new-step-2-pricing-select v-model="settings.pricing_option" placeholder="Pricing Option" value-key="id" style="width: 100%;">
                            <el-option :data-test-new-step-2-pricing-option="item.id" v-for="item in settings.pricing_options" :key="item.id" :label="item.name" :value="item">
                              <span>{{ item.name }}</span>
                              <span class="display-caption subdued pull-right">
                                {{ item.price | prettyCurrency }} &middot; ID: {{ item.id }}
                              </span>
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-form>

                      <el-form v-if="settings.saleType === 'contract'">
                        <label>Select the Contract</label>
                        <el-form-item>
                          <el-select data-test-new-step-2-contract-select v-model="settings.contract" placeholder="Contract" value-key="id" style="width: 100%;">
                            <el-option :data-test-new-step-2-contract-option="item.id" v-for="item in settings.contracts" :key="item.id" :label="item.name" :value="item">
                              <span>{{ item.name }}</span>
                              <span class="display-caption subdued pull-right">
                                {{ item.price | prettyCurrency }} &middot; ID: {{ item.id }}
                              </span>
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-form>
                    </div>
                  </el-col>
                </el-row>

                <div v-if="settings.saleType === 'pricing_option'">
                  <el-row style="margin-top: 2rem; margin-bottom: 1.25rem;">
                    <el-col :span="1">
                      <span class="step-circle">3</span>
                    </el-col>
                    <el-col :span="23" class="v2__heading">Do you want to add a discount on this page?</el-col>
                  </el-row>
                  <el-row>
                    <el-col :offset="1">
                      <div>
                        <el-radio data-test-new-step-3-discount-radio-true v-model="settings.isDiscounted" :label="true" border>Yes, add discount</el-radio>
                        <el-radio data-test-new-step-3-discount-radio-false v-model="settings.isDiscounted" :label="false" border>No, keep full price</el-radio>
                      </div>
                      <div class="options" v-if="settings.isDiscounted">
                        <el-form>
                          <el-form-item>
                            <label>How much of a discount?</label>
                            <el-input type="number" data-test-new-step-3-discount-input v-model.number="settings.discount" placeholder="10">
                              <template slot="prepend">$</template>
                            </el-input>
                          </el-form-item>
                        </el-form>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>

              <div style="margin-bottom: 2rem;" v-if="steps.three">
                <el-row style="margin-bottom: 1.25rem;">
                  <el-col :span="1">
                    <span class="step-circle">{{ settings.saleType === 'pricing_option' ? '4' : '3' }}</span>
                  </el-col>
                  <el-col :span="23" class="v2__heading">Customize your Landing Page widget</el-col>
                </el-row>

                <el-row>
                  <el-col :offset="1">
                    <div class="options">
                      <el-row>
                        <el-col :span="11">
                        <el-form ref="appearanceForm" :rules="rules" :model="settings" status-icon>
                          <el-form-item prop="name">
                            <label>Custom Name</label>
                            <el-input type="text" data-test-new-step-4-name-input v-model="settings.name" placeholder="Custom Name" />
                          </el-form-item>
                          <el-form-item prop="description">
                            <label>Custom Description</label>
                            <el-input type="text" data-test-new-step-4-description-input v-model="settings.description" placeholder="Custom Description" />
                            <div class="display-caption subdued">Optional</div>
                          </el-form-item>
                          <el-form-item prop="button_text">
                            <label>Button Text</label>
                            <el-input type="text" data-test-new-step-4-button v-model="settings.button_text" placeholder="Button Text" />
                          </el-form-item>
                          <el-form-item prop="button_color">
                            <label>Button Color</label>
                            <div>
                              <el-color-picker data-test-new-step-4-button-color size="medium" v-model="settings.button_color"></el-color-picker>
                            </div>
                          </el-form-item>
                        </el-form>
                        </el-col>
                        <el-col :span="12">
                          <div class="preview" v-if="settings.pricing_option || settings.contract">
                            <el-card :body-style="{'padding-bottom': 0}">
                              <div data-test-new-step-4-preview-name class="name">{{ settings.name }}</div>
                              <div data-test-new-step-4-preview-description class="description">{{ settings.description }}</div>
                              <div data-test-new-step-4-preview-pricing class="pricing" v-if="!settings.discount">{{ price }}</div>
                              <div class="pricing" v-if="settings.discount"><span class="old">{{ price }}</span>{{ priceWithDiscount }}</div>
                              <el-button data-test-new-step-4-preview-button native-type="button" type="primary" :style="'width: 100%; background-color: ' + settings.button_color + '; border-color: ' + settings.button_color">{{ settings.button_text }}</el-button>
                              <div class="footer" v-if="!settings.phone && settings.email">
                                Need help? Email us at <a :href="'mailto:'+settings.email">{{ settings.email }}</a>
                              </div>
                              <div class="footer" v-if="settings.phone && !settings.email">
                                Need help? Call us at <a :href="'tel:'+settings.phone" target="_blank">{{ settings.phone }}</a>
                              </div>
                              <div class="footer" v-if="settings.phone && settings.email">
                                Need help? Email us at <a :href="'mailto:'+settings.email" target="_blank">{{ settings.email }}</a>
                                or call us at
                                <a :href="'tel:'+settings.phone" target="_blank">{{ settings.phone }}</a>
                              </div>
                            </el-card>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
              </div>

              <div style="margin-bottom: 2rem;" v-if="steps.four">
                <el-row style="margin-bottom: 1.25rem;">
                  <el-col :span="1">
                    <span class="step-circle">{{ settings.saleType === 'pricing_option' ? '5' : '4' }}</span>
                  </el-col>
                  <el-col :span="23" class="v2__heading">Finally, name this Landing Page</el-col>
                </el-row>
                <el-row>
                  <el-col :offset="1">
                    <el-form ref="vanityForm" :model="settings" :rules="vanityRules" status-icon>
                      <el-form-item prop="vanity">
                        <el-input data-test-new-step-5-subdomain placeholder="example.html" v-model="settings.vanity">
                          <template slot="prepend">https://{{ $store.state.user.current_account.account_emails_metadata.subdomain }}.brandbot-checkout.com/</template>
                        </el-input>
                      </el-form-item>
                      <el-alert title="This has to be unique and cannot be changed once published" type="info" show-icon></el-alert>
                    </el-form>
                  </el-col>
                </el-row>
              </div>

              <el-row>
                <el-col :offset="1">
                  <el-button data-test-existing-next-button type="primary" @click="nextStep" v-if="type === 'existing'">
                    {{ steps.two ? 'Pick' : 'Next' }}
                  </el-button>
                  <el-button data-test-new-next-button type="primary" @click="nextStep" v-if="type === 'new'">{{ steps.four ? 'Create' : 'Next' }}</el-button>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['name'],
    data() {
      const checkVanity = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('A valid url is required for your Landing Page.'));
        }

        if (!value.match(/^[0-9a-zA-Z-_]+$/)) {
          return callback(new Error('Only alphanumeric characters, "-" and "_" are allowed.'));
        }

        return callback();
      };
      return {
        landing_page: null,
        landing_pages: [],
        steps: {
          one: true,
          two: false,
          three: false,
          four: false,
        },
        type: 'new',
        settings: {
          customerType: 'existing',
          saleType: null,
          pricing_option: null,
          pricing_options: [],
          contract: null,
          contracts: [],
          isDiscounted: false,
          discount: null,
          name: '',
          description: '',
          button_text: 'Buy now',
          button_color: 'var(--xpl-color-secondary-lm)',
          email: this.$store.state.user.email,
          phone: '',
          vanity: this.slugify(this.name),
        },
        rules: {
          name: [{ required: true, trigger: 'blur', message: 'Name is required' }],
          button_text: [{ required: true, trigger: 'blur', message: 'Button text is required' }],
          button_color: [{ required: true, trigger: 'change', message: 'Button needs a color' }],
        },
        vanityRules: {
          vanity: [{
            validator: checkVanity,
            trigger: 'change',
          }],
        },
      };
    },
    created() {
      this.fetchLandingPages();
      this.fetchPricingOptions();
      this.fetchContracts();
      if (this.$store.state.user.current_account.active_vendors.indexOf('marianatek') > -1) {
        this.type = 'existing';
        this.steps.one = false;
        this.steps.two = true;
      }
    },
    watch: {
      // eslint-disable-next-line
      'settings.pricing_option': function () {
        this.settings.name = this.settings.pricing_option.name;
      },
      // eslint-disable-next-line
      'settings.contract': function () {
        this.settings.name = this.settings.contract.name;
      },
    },
    computed: {
      showGoBackButton() {
        if (this.steps.one) {
          return false;
        } else if (this.$store.state.user.current_account.active_vendors.indexOf('marianatek') > -1) {
          return false;
        }
        return true;
      },
      price() {
        if (!this.settings.pricing_option && !this.settings.contract) {
          return null;
        }

        if (this.settings.contract) {
          return Number(this.settings.contract.price)
          .toLocaleString(undefined, {
            style: 'currency',
            currency: this.$store.state.user.current_account.currency,
          });
        }

        return Number(this.settings.pricing_option.price)
        .toLocaleString(undefined, {
          style: 'currency',
          currency: this.$store.state.user.current_account.currency,
        });
      },
      priceWithDiscount() {
        if (!this.settings.pricing_option) {
          return null;
        }

        return Number(this.settings.pricing_option.price - this.settings.discount)
        .toLocaleString(undefined, {
          style: 'currency',
          currency: this.$store.state.user.current_account.currency,
        });
      },
    },
    methods: {
      slugify(str) {
        str = str.replace(/^\s+|\s+$/g, '');
        str = str.toLowerCase();

        const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
        const to = 'aaaaaaeeeeiiiioooouuuunc------';

        // eslint-disable-next-line
        for (let i = 0, l = from.length; i < l; i++) {
          str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str
          .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
          .replace(/\s+/g, '-') // collapse whitespace and replace by -
          .replace(/-+/g, '-'); // collapse dashes

        return str;
      },
      closeModal() {
        EventBus.$emit('landing_page.close');
      },
      previousStep() {
        if (this.steps.four) {
          this.steps.four = false;
          this.steps.three = true;
          return;
        }

        if (this.steps.three) {
          this.steps.three = false;
          this.steps.two = true;
          return;
        }

        if (this.steps.two) {
          this.steps.two = false;
          this.steps.one = true;
        }
      },
      nextStep() {
        if (this.steps.one) {
          this.steps.one = false;
          this.steps.two = true;
          return;
        }

        if (this.steps.two) {
          if (this.type === 'existing') {
            EventBus.$emit('landing_page.link', this.landing_page.url);
            this.$nextTick(() => {
              this.closeModal();
            });
            return;
          }

          if (!this.settings.saleType) {
            this.$message.error('Please make a selection');
            return;
          }

          if (this.settings.saleType === 'pricing_option' &&
            !this.settings.pricing_option) {
            this.$message.error('Please choose a Pricing Option');
            return;
          }

          if (this.settings.saleType === 'contract' &&
            !this.settings.contract) {
            this.$message.error('Please choose a Contract');
            return;
          }
          this.steps.two = false;
          this.steps.three = true;
          return;
        }

        if (this.steps.three) {
          this.$refs.appearanceForm.validate((valid) => {
            if (!valid) {
              return;
            }

            this.steps.three = false;
            this.steps.four = true;
          });

          return;
        }

        if (this.steps.four) {
          this.$refs.vanityForm.validate((valid) => {
            if (!valid) {
              return;
            }

            const payload = {
              name: this.settings.vanity,
              quick: true,
              settings: {
                selectedTemplate: 1,
                customerType: this.settings.customerType,
                saleType: this.settings.saleType,
                pricing_option: this.settings.pricing_option,
                contract: this.settings.contract,
                isDiscounted: this.settings.isDiscounted,
                discount: this.settings.discount,
                name: this.settings.name,
                description: this.settings.description,
                button_text: this.settings.button_text,
                button_color: this.settings.button_color,
                email: this.settings.email,
                phone: this.settings.phone,
                vanity: this.settings.vanity,
              },
            };
            api.createCheckoutFlow(payload).then((response) => {
              EventBus.$emit('landing_page.link', response.checkoutflow.url);
              this.$nextTick(() => {
                this.closeModal();
              });
            }).catch((err) => {
              this.$message.error(err.message);
            });
          });
        }
      },
      fetchLandingPages() {
        api.checkoutFlows().then((response) => {
          this.landing_pages = response.checkoutflows;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchPricingOptions() {
        api.getCheckoutFlowPricingOptions().then((response) => {
          this.settings.pricing_options = response.pricing_options;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchContracts() {
        api.getContracts().then((response) => {
          this.settings.contracts = response.contracts;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal-dialog {
    max-width: 100vw;
    margin: 0;
  }

  .modal-content {
    height: 100vh;
    border-radius: 0;
  }

  .modal .modal-content .modal-body {
    max-height: none;
  }

  .modal-body {
    padding-top: 2.5rem;
    display: flex;
    justify-content: center;
  }

  .options {
    padding: 1.25rem;
    background-color: #f5f8fb;
    margin-top: .75rem;
  }

  .options .el-form-item {
    margin-bottom: .625rem;
  }

.preview {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: #f3f7f9;
    margin-right: -20px;
    margin-top: -20px;
    margin-bottom: -20px;
    min-height: 60vh;
  }

  .preview .el-card {
    width: 100%;
  }

  .preview .el-card .el-card__body {
    padding-bottom: 0;
  }

  .preview .name {
    font-size: 1.125rem;
    font-weight: 700;
    color: rgb(0, 21, 42);
    margin-bottom: .5rem;
  }

  .preview .description {
    font-size: 1rem;
    line-height: 1.5;
    color: rgb(0, 21, 42);
    margin-bottom: 1.5rem;
  }

  .preview .pricing {
    margin-bottom: 2.5rem;
    font-size: 1.25rem;
    font-weight: 700;
    color: rgb(0, 21, 42);
  }

  .preview .pricing .old {
    text-decoration: line-through;
    color: rgb(97, 112, 128);
    margin-right: .5rem;
  }

  .preview .footer {
    margin-top: 1rem;
    padding: 1rem;
    background-color: var(--xpl-color-background-lm);
    font-size: .625rem;
    font-weight: 700;
    color: rgb(97, 112, 128);
    text-align: center;
    margin-right: -20px;
    margin-left: -20px;
  }

  .preview .footer a {
    color: #2E4457;
    text-decoration: underline;
  }
</style>
