<template>
  <div class="row">
    <span class="title">{{ step.name }}</span>
    <div>
      <div class="disable-caps">{{ displayName }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioSummary',
  props: ['step'],
  computed: {
    displayName() {
      let o;
      try {
        o = this.step.options.find(x => x.option_id === this.step.default.option_id);
        return (o && o.display_name) || '?';
      } catch (e) {
        return '?';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "summary_item.scss";
</style>
