<template>
  <div>
    <div class="stats-detail">
      <a href="#" class="go-back" @click.prevent="closeStat"><i class="fa fa-times"></i> Close</a>
    </div>
    <div style="display: flex; width: 100%;">
      <form @submit.prevent v-if="contacts && contacts.length">
        <div class="input-group">
          <span class="input-group-addon">
            <span class="icon icon-search"></span>
          </span>
          <input type="search" name="query" v-model.trim="query" class="form-control" placeholder="Search" />
        </div>
      </form>
    </div>
    <div class="datatable" v-if="contacts && contacts.length">
      <table class="table bb-datatable">
        <thead>
          <tr>
            <td>Name</td>
            <td class="text-right">Form Entry Date</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="contact in filteredContacts">
            <td>
              <router-link :to="'/contacts/' + contact.uuid" target="_blank">
                <span v-if="contact.first_name">{{ contact.first_name }} {{ contact.last_name }}</span>
                <span v-if="!contact.first_name">{{ contact.email }}</span>
              </router-link>
            </td>
            <td class="text-right">{{ contact.created_at | humanDateTime }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['formId', 'stat'],
    data() {
      return {
        query: '',
        contacts: null,
      };
    },
    created() {
      this.fetchContacts();
    },
    computed: {
      filteredContacts() {
        if (this.query.length) {
          return this.contacts.filter((c) => {
            c.first_name = !c.first_name ? '' : c.first_name;
            c.last_name = !c.last_name ? '' : c.last_name;

            return c.first_name.toLowerCase().indexOf(this.query.toLowerCase()) > -1 ||
              c.last_name.toLowerCase().indexOf(this.query.toLowerCase()) > -1 ||
              c.email.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
          });
        }

        return this.contacts;
      },
    },
    methods: {
      closeStat() {
        EventBus.$emit('close_stat');
      },
      fetchContacts() {
        api.getFormContacts(this.formId, this.stat).then((response) => {
          this.contacts = response.contacts;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .stats-detail {
    margin-top: 1.5rem;
    margin-bottom: 3rem;
  }
</style>
