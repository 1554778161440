import { render, staticRenderFns } from "./CampaignItemsList.vue?vue&type=template&id=5b5f4afc&scoped=true&"
import script from "./CampaignItemsList.vue?vue&type=script&lang=js&"
export * from "./CampaignItemsList.vue?vue&type=script&lang=js&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./CampaignItemsList.vue?vue&type=style&index=1&id=5b5f4afc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b5f4afc",
  null
  
)

export default component.exports