<template>
  <el-container class="campaign-items-list-container">
    <div class="campaign-items-sidebar" v-if="this.campaign_items !== null">
      <div v-if="campaignMessages.length" class="campaign-items-sidebar-header">
        Automated Messages
      </div>
      <CampaignItemsListMessage
        v-for="(item, index) in campaignMessages"
        :class="['campaign-item-sidebar-item', item.uuid === selected_item_uuid ? 'item-active' : '']"
        v-bind:index="index"
        v-bind:item="item"
        v-bind:key="item.uuid"
        v-on:click.native="toggleItem(item)"
      /> 
      <div v-if="campaignActions.length" class="campaign-items-sidebar-header">
        Automated Actions
      </div>      
      <CampaignItemsListAction
        v-for="(item, index) in campaignActions"
        :class="['campaign-item-sidebar-item', item.uuid === selected_item_uuid ? 'item-active' : '']"
        v-bind:index="index"
        v-bind:item="item"
        v-bind:key="item.uuid"
        v-on:click.native="toggleItem(item)"
      />       
      </div>
  </el-container>
</template>
<script>
import EventBus from '@/event-bus';
import CampaignItemsListMessage from './CampaignItemsListMessage';
import CampaignItemsListAction from './CampaignItemsListAction';

export default {
  props: ['campaign_items', 'selected_item_uuid'],
  components: {
    CampaignItemsListMessage,
    CampaignItemsListAction,
  },
  data() {
    return {
    };
  },
  computed: {
    campaignMessages() {
      const messageItems = this.campaign_items.filter(item => {
        if (item.type === 'email') {
          return item;
        }
        if (item.type === 'sms') {
          return item;
        }
      });
      return messageItems;
    },
    campaignActions() {
       const actionItems = this.campaign_items.filter(item => {
        if (item.type === 'tag' ) {
          return item;
        }
        if (item.type === 'phone_call' ) {
          return item;
        }
        if (item.type === 'task' ) {
          return item;
        }
      });
      return actionItems;
    },
  },
  methods: {
    toggleItem(item) {
      this.$router.push({name: 'campaigns items', params:{uuid: item.uuid, campaignItems: this.campaign_items, campaign_id: item.campaign_id}})
      EventBus.$emit('campaign_item.toggle', item);
    },
  },
};
</script>
<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>
<style lang="scss" scoped>
.campaign-items-sidebar {
  max-width: 100%;
  min-width: 264px;
  background-color: #ffffff;
}
.campaign-items-sidebar-header {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  background-color: white;
  border: 1px solid #E1E2E8;
  border-bottom: none;
  padding: 10px;
}
.campaign-item-sidebar-item {
  display: flex;
  flex-direction: column;
  background-color: #F3F4F6;
  border: 1px solid #E1E2E8;
  border-bottom-color: transparent;
  transition: all 150ms ease-in-out;
  &:hover {
    border: solid 1px var(--xpl-color-secondary-lm);
    cursor: pointer;
  }
}
  .campaign-items-sidebar .campaign-item-sidebar-item:last-child {
    border-bottom-color: #E1E2E8;
    &:hover {
    border: solid 1px var(--xpl-color-secondary-lm);
    cursor: pointer;
  }
  }
.campaign-items-sidebar-item-top-row {
  display: flex;
  flex-direction: row;
  padding: 20px 0 0 16px;
}

.sidebar-item-name {
  color: #161e27;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}
.item-active {
 background-color:  var(--xpl-color-purple-100);
}
</style>