<template>
  <CountSummary v-if="step.type === 'count'"
                :step="step"/>
  <RadioSummary v-else-if="step.type === 'radio'"
                :step="step"/>
  <MultiSelectSummary v-else-if="step.type === 'multiselect'  || step.type === 'include_exclude_multiselect'"
                      :step="step"/>
  <TimeFrameSummary v-else-if="step.type === 'timeframe'"
                    :step="step"/>
  <BrandBotSummary v-else-if="step.vendor === 'brandbot'"
                   :condition="step" />
</template>

<script>
import CountSummary from './CountSummary';
import RadioSummary from './RadioSummary';
import MultiSelectSummary from './MultiSelectSummary';
import TimeFrameSummary from './TimeFrameSummary';
import BrandBotSummary from './BrandBotSummary';

export default {
  name: 'RuleSummaryRow',
  components: { BrandBotSummary, TimeFrameSummary, MultiSelectSummary, RadioSummary, CountSummary },
  props: ['step'],
};
</script>

<style scoped>

</style>
