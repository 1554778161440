<template>
  <div class="condition">
    <el-form>
      <label>Any contact who starts any of the following plans for the first time ever</label>
      <el-form-item>
        <el-select v-model="selected_plans" multiple filterable placeholder="Plan(s)" style="width: 100%;">
            <el-option v-for="plan in plans" :key="plan.name" :value="plan.name" :id="plan.name">
            </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="commitCondition" :disabled="!selected_plans.length">Save</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['uuid', 'rule', 'campaignItem'],
    data() {
      return {
        plans: [],
        selected_plans: [],
      };
    },
    created() {
      this.fetchPlans();

      if (this.rule && this.rule.length && this.rule[0].plans) {
        this.selected_plans = this.rule[0].plans;
      }
    },
    methods: {
      fetchPlans() {
        api.getPersonPlans().then((response) => {
          this.plans = response.plans;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.uuid,
            quick: true,
          },
          started: true,
          timeframe: 'relative',
          time_comparison: 'within',
          start_date: null,
          end_date: null,
          relative_days: 2,
          filter_plan: true,
          plans: this.selected_plans,
          types: [],
          categories: [],
        };
        EventBus.$emit('condition.commit', payload);
      },
    },
  };
</script>
