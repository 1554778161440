export default {
  isHQ(state) {
    return state.user.current_account.franchise;
  },
  currentUserActiveVendor(state) {
    let vendor = null;
    if (state.user?.current_account?.active_vendors?.[0] === 'marianatek') {
      vendor = 'marianatek';
    } else if (state.user?.current_account?.active_vendors?.[0] === 'mindbody') {
      vendor = 'mindbody';
    } else if (state.user?.current_account?.active_vendors?.[0] === 'pike13') {
      vendor = 'pike13';
    }
    return vendor;
  },
  isMT(state) {
    return state.user.current_account.active_vendors[0] === 'marianatek';
  },
  isPike13(state) {
    return state.user.current_account.active_vendors[0] === 'pike13';
  },
  isMBO(state) {
    return state.user.current_account.active_vendors[0] === 'mindbody';
  },

  currentTimezone(state) {
    return state.user?.current_account?.timezone || null;
  },
  vendor(state) {
    return (state.user.current_account.active_vendors[0]) || 'brandbot';
  },
  isUIAVendor(state) {
    // eslint-disable-next-line max-len
    return state.user?.current_account_vendor_data?.is_uia_vendor || false;
  },
  isDevAccount(state) {
    // eslint-disable-next-line max-len
    return state.user?.current_account?.is_developer_account || false;
  },
  isLoggedIn(state) {
    return !state.anonymous && state.isLoggedIn;
  },
  isConfirmed(state) {
    return state.user.confirmed;
  },
  isLoading(state) {
    return state.loading;
  },
  isOnboarded(state) {
    return state.user.onboarded;
  },
  token(state) {
    return state.access_token;
  },
  isStaff(state) {
    // Deprecated. Please use more explicit isBrandBotAdmin
    return state.user.is_super;
  },
  isBrandBotAdmin(state) {
    return state.user.is_super;
  },
  isCampaignSuperUser(state) {
    // These users have access to extended campaign features such as...
    const CAMPAIGN_SUPER_USER_IDS = [
      2414, // jonm@aqua-tots.com
      2560, // julies@aqua-tots.com
      2867, // michelle@brandbot.com
      3369, // ariana@thebarrecode.com
      3463, // matt@shred415.com
      3468, // ashley@cityrow.com
      3992, // joseph@gocyclenow.com
      4190, // metrodetroit@thebarrecode.com
      4698, // jdellavalle@gocyclenow.com
      4758, // bostonpilatesplus@gmail.com
      5196, // sally@evolutionpoweryoga.com
      5266, // stefk@evolutionpoweryoga.com
      5294, // kyla@foxybox.ca
      5295, // nickstaples@zenergycycling.com
      5708, // theresa@powertrainsi.com
      6252, // carson@shred415.com
    ];
    return CAMPAIGN_SUPER_USER_IDS.includes(state.user.id);
  },

  //
  // rebrand stuff
  toCanonicalUrl(state, getters) {
    if (!getters.brandHost || getters.brandHost === window.location.host) {
      return null
    }
    return window.location.toString().replace(window.location.host, getters.brandHost);
  },
  brandHost(state) {
    if(state.isDev) return null;
    if(window.location.host.includes('uat.brandbot.cloud')) return null;
    if(window.location.host.includes('uat-staging.brandbot.cloud')) return null;
    return state.user?.current_account_vendor_data?.cannonical_uri_name || null;
    // || "connect-xplorapps-io.brandbot.cloud";
    // "connect.-xplorapps.io"
    // "192.168.1.85:8080";
  },
  brandLogo(state) {
    return state.user?.current_account_vendor_data?.logo_link || "/static/xplor-logo.svg";
  },
  brandName(state) {
    return state.user?.current_account_vendor_data?.brand_name || "BrandBot";
  },
  brandIntercomId(state) {
    return state.user?.current_account_vendor_data?.intercom_link || null;
  },
  activeVendor(state) {
    return state.user?.current_account_vendor_data?.vendor;
  },
  activeVendorDisplayName(state) {
    return state.user?.current_account_vendor_data?.vendor_display_name;
  },
  currentAccountSubdomain(state){
    return JSON.parse(state.user.current_account.metadata).subdomain;
  },
  showBilling(state, getters) {
    if (getters.isUIAVendor){
      if (state.user?.current_account?.active_vendors?.includes('zingfit') || 
        state.user?.current_account?.active_vendors?.includes('zingfit-uia')) {
        return true;
      }
      return false;
    } return true;
  },
  hqAdminPermission(state){
    const franchise = state.user?.current_account?.franchise;
    const hq_admin = state.user?.is_hq_admin;
    return franchise || hq_admin;
  }
};
