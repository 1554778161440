<template>
  <div class="forgot-password">
    <div class="card">
      <div class="card-body">
        <h1>Reset your password</h1>
        <form @submit.prevent>
          <div class="form-group">
            <input type="email" id="email" name="email" v-model="email" placeholder="Email Address" class="form-control" required />
          </div>

          <div class="form-group">
            <button type="button" ref="submit" class="xpl-button xpl-button--primary" @click="sendResetLink" :disabled="!email">Send Reset Email</button>
          </div>
          <div class="text-center">
            <router-link to="/login" class="small">Back to login</router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import * as api from '@/api';

  export default {
    data() {
      return {
        email: '',
      };
    },
    methods: {
      sendResetLink() {
        if (!this.email) return;

        const payload = {
          email: this.email,
        };

        this.$refs.submit.disabled = true;
        this.$refs.submit.innerHTML = '<i class="fa fa-fw fa-spin fa-spinner"></i>';

        api.getResetLink(payload).then((response) => {
          this.$toasted.success(response.message);
          this.email = '';
          this.$refs.submit.disabled = false;
          this.$refs.submit.innerHTML = 'Send Reset Email';
        }).catch((err) => {
          this.$refs.submit.disabled = false;
          this.$refs.submit.innerHTML = 'Send Reset Email';
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .forgot-password {
    background-color: var(--xpl-color-background-lm);
    //background-image: url('https://assets.brandbot.com/public/onboarding/login-bg.png');
    background-size: 100% 80%;
    background-repeat: no-repeat;
    background-position: bottom center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    padding-bottom: 4rem;
  }

  .forgot-password img {
    width: 4rem;
    margin-bottom: 2rem;
  }

  .forgot-password .card {
    max-width: 21.875rem;
    // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px rgb(217, 231, 240);
    flex-shrink: 0;
    flex-grow: 0;
  }

  .forgot-password .card .card-body {
    padding: 2rem;
  }

  h1 {
    font-size: 1.25rem;
    line-height: 1.4;
    color: rgb(46, 68, 87);
    margin-bottom: 1rem;
  }

  .small {
    color: rgb(46, 68, 87);
  }
</style>
