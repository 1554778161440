<template>
  <div class="condition">
    <form @submit.prevent>
      Any contact when they create a MINDBODY profile.
      <div>
        <button class="xpl-button xpl-button--primary" @click.stop="commitCondition">Save</button>
      </div>
    </form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    props: ['uuid', 'rule'],
    methods: {
      commitCondition(event) {
        const target = event.target || event.srcElement;
        target.disabled = true;
        target.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';

        const payload = {
          condition: {
            uuid: this.uuid,
            quick: true,
          },
          timeframe: 'relative',
          comparison: 'within',
          start_date: '',
          end_date: '',
          relative_days: 2,
          misc: {
            filtering: false,
          },
        };
        EventBus.$emit('condition.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .btn-bb-primary {
    margin-top: 1.5rem;
  }
</style>
