<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Create an Auto-Campaign
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <div class="choices">
              <div class="choice" @click="createCampaign('template')"  v-if="showCampaignTemplatesButton">

                <div class="illustration template"></div>
                Browse our Templates
              </div>
              <div class="choice" @click="createCampaign('scratch')">
                <div class="illustration scratch"></div>
                Create from scratch
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    data() {
      return {};
    },
    methods: {
      closeModal() {
        EventBus.$emit('auto_campaigns.create_modal.close');
      },
      createCampaign(mode) {
        EventBus.$emit('auto_campaigns.create_modal.choice', mode);
      },
    },
    computed: {
      showCampaignTemplatesButton() {
        const campaignTemplateVendors = ['mindbody', 'marianatek'];
        return campaignTemplateVendors.includes(this.$store.getters.currentUserActiveVendor);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal-body {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .choices {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .choices .choice {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 6px;
    background-color: #ffffff;
    border: 1px solid  #d6e0e8;
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-width: 12.8125rem;
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.25rem;
    color: #2e4457;
    transition: all 150ms ease-in-out;
  }

  .choices .choice .illustration {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 3rem;
    height: 3rem;
    margin-bottom: .75rem;
    transition: all 150ms ease-in-out;
  }

  .choices .choice .illustration.template {
    background-image: url('https://assets.brandbot.com/public/campaigns/template.svg');
  }

  .choices .choice:hover .illustration.template {
    background-image: url('https://assets.brandbot.com/public/campaigns/template-hover.svg');
  }

  .choices .choice .illustration.scratch {
    background-image: url('https://assets.brandbot.com/public/campaigns/scratch.svg');
  }

  .choices .choice:hover .illustration.scratch {
    background-image: url('https://assets.brandbot.com/public/campaigns/scratch-hover.svg');
  }

  .choices .choice:hover {
    border: 2px solid var(--xpl-color-secondary-lm);
    // box-shadow: 0 1px 0 0 rgba(100, 164, 210, 0.13);
  }
</style>
