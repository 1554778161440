import { render, staticRenderFns } from "./RuleEnglish.vue?vue&type=template&id=5e7c474f&scoped=true&"
import script from "./RuleEnglish.vue?vue&type=script&lang=js&"
export * from "./RuleEnglish.vue?vue&type=script&lang=js&"
import style0 from "./RuleEnglish.vue?vue&type=style&index=0&id=5e7c474f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e7c474f",
  null
  
)

export default component.exports