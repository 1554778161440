<template>
  <div>
    <div class="tabs">
      <a href="#" :class="{ active: sidebar_tabs.build }" @click.prevent="toggleSidebarTab('build')">Build</a>
      <a href="#" :class="{ active: sidebar_tabs.style }" @click.prevent="toggleSidebarTab('style')">Style</a>
    </div>
    <div class="build_tab" v-show="sidebar_tabs.build">
      <div class="sites">
        <div class="site" v-for="site in available_sites">
          <div class="name">
            <i class="icon icon-checkbox-unchecked2" v-if="activeSocialSites.indexOf(site.name) === -1" @click.stop="toggleSocialSite(site)"></i>
            <i class="icon icon-checkbox-checked2" v-if="activeSocialSites.indexOf(site.name) > -1" @click.stop="toggleSocialSite(site)"></i>
            <i :class="'icon icon-' + (site.name === 'pinterest' ? 'youtube' : site.name) + '2 icon-' + (site.name === 'pinterest' ? 'youtube' : site.name) + ' social'"></i>
            <div>{{ site.name === 'pinterest' ? 'youtube' : site.name }}</div>
          </div>

          <form @submit.prevent v-if="activeSocialSites.indexOf(site.name) > -1">
            <div class="form-group">
              <label>{{ site.name }} URL</label>
              <input type="text" v-model="currentElement.attributes[site.name + '-href']" class="form-control" @input="attributeEdited" />
            </div>

            <div class="form-group" v-if="!$store.state.user.current_account.brand_restriction">
              <label>Color</label>
              <div class="input-group" @click="site.show_picker = !site.show_picker">
                <span class="input-group-addon">
                  <span class="icon icon-square" :style="'color: ' + site.color.hex"></span>
                </span>
                <input type="text" v-model="site.color.hex" class="form-control" readonly />
              </div>
              <Sketch v-model="site.color" :disableAlpha="true" @input="colorEdited(site)" v-if="site.show_picker" />
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="style_tab" v-show="sidebar_tabs.style" >
      <div class="banner" v-if="!$store.state.user.current_account.brand_restriction">Icon Styling</div>

      <form @submit.prevent v-if="!$store.state.user.current_account.brand_restriction">
        <div class="form-group">
          <label>Icon Size</label>
          <input type="number" ref="icon_size" class="form-control" min="1" @input="iconSizeEdited($event.target.value)" />
        </div>

        <div class="form-group" v-if="!$store.state.user.current_account.brand_restriction">
          <label>Corner Roundness</label>
          <input type="number" ref="border_radius" class="form-control" min="1" @input="borderRadiusEdited($event.target.value)" />
        </div>

        <div class="form-group">
          <label>Spacing Around</label>
          <input type="number" ref="inner_padding" class="form-control" min="0" @input="innerPaddingEdited($event.target.value)" />
        </div>
      </form>

      <div class="banner">Container Styling</div>
      <form @submit.prevent>
          <div class="form-group">
            <label>Spacing Top</label>
            <input type="number" ref="spacing_top" class="form-control" min="0" @input="spacingTopEdited($event.target.value)" />
          </div>

          <div class="form-group">
            <label>Spacing Bottom</label>
            <input type="number" ref="spacing_bottom" class="form-control" min="0" @input="spacingBottomEdited($event.target.value)" />
          </div>

          <div class="form-group" v-if="!$store.state.user.current_account.brand_restriction">
            <label>Background Color</label>
            <div class="input-group" @click="show_bg_picker = !show_bg_picker">
              <span class="input-group-addon">
                <span class="icon icon-square" :style="'color: ' + background_color.hex"></span>
              </span>
              <input type="text" ref="background_color" v-model="background_color.hex" class="form-control" readonly />
            </div>
            <Sketch v-model="background_color" :disableAlpha="true" @input="backgroundColorEdited" v-if="show_bg_picker" />
          </div>
      </form>
    </div>

    <div class="footer">
      <button type="button" class="xpl-button xpl-button--primary" @click="saveAndCloseBlock">Save and close</button>    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import _ from 'lodash';
  import { Sketch } from 'vue-color';

  export default {
    props: ['currentElement'],
    components: {
      Sketch,
    },
    data() {
      return {
        sidebar_tabs: {
          build: true,
          style: false,
        },
        show_bg_picker: false,
        background_color: {
          hex: null,
        },
        show_color_picker: false,
        color: {
          hex: null,
        },
        available_sites: [
          {
            name: 'facebook',
            color: {
              hex: null,
            },
            show_picker: false,
          },
          {
            name: 'instagram',
            color: {
              hex: null,
            },
            show_picker: false,
          },
          {
            name: 'linkedin',
            color: {
              hex: null,
            },
            show_picker: false,
          },
          {
            name: 'google',
            color: {
              hex: null,
            },
            show_picker: false,
          },
          {
            name: 'twitter',
            color: {
              hex: null,
            },
            show_picker: false,
          },
          {
            name: 'pinterest',
            color: {
              hex: null,
            },
            show_picker: false,
          },
        ],
      };
    },
    mounted() {
      // Fill social icon colors
      this.available_sites.forEach((site, index) => {
        if (this.activeSocialSites.indexOf(site.name) > -1) {
          this.available_sites[index].color.hex = this.currentElement.attributes[`${site.name}-icon-color`];
        }
      });

      this.background_color.hex = this.currentElement.attributes['container-background-color'];
      this.$refs.icon_size.value = this.currentElement.attributes['icon-size'].replace('px', '');
      this.$refs.border_radius.value = this.currentElement.attributes['border-radius'].replace('px', '');
      this.$refs.spacing_top.value = this.currentElement.attributes['padding-top'].replace('px', '');
      this.$refs.spacing_bottom.value = this.currentElement.attributes['padding-bottom'].replace('px', '');
      this.$refs.inner_padding.value = this.currentElement.attributes['inner-padding'] ? this.currentElement.attributes['inner-padding'].replace('px', '') : null;
    },
    watch: {
      currentElement(newVal) {
        // Fill social icon colors
        this.available_sites.forEach((site, index) => {
          if (this.activeSocialSites.indexOf(site.name) > -1) {
            this.available_sites[index].color.hex = newVal.attributes[`${site.name}-icon-color`];
          }
        });

        this.background_color.hex = newVal.attributes['container-background-color'];
        this.$refs.icon_size.value = newVal.attributes['icon-size'].replace('px', '');
        this.$refs.border_radius.value = newVal.attributes['border-radius'].replace('px', '');
        this.$refs.spacing_top.value = newVal.attributes['padding-top'].replace('px', '');
        this.$refs.spacing_bottom.value = newVal.attributes['padding-bottom'].replace('px', '');
        this.$refs.inner_padding.value = newVal.attributes['inner-padding'] ? newVal.attributes['inner-padding'].replace('px', '') : null;
      },
    },
    computed: {
      activeSocialSites() {
        const displayed = this.currentElement.attributes.display.split(' ');
        const sites = displayed.map(d => d.split(':')[0]);
        return sites;
      },
    },
    methods: {
      toggleSidebarTab(tab) {
        this.sidebar_tabs.build = false;
        this.sidebar_tabs.style = false;

        this.sidebar_tabs[tab] = true;
      },
      saveAndCloseBlock() {
        EventBus.$emit('editor.close');
      },
      toggleSocialSite(site) {
        if (this.currentElement.attributes.display.indexOf(site.name) === -1) {
          this.currentElement.attributes.display = `${this.currentElement.attributes.display} ${site.name}:url`;
          this.currentElement.attributes[`${site.name}-href`] = 'https://brandbot.com';
        } else {
          this.currentElement.attributes.display = this.currentElement.attributes.display.replace(`${site.name}`, '').trim();
        }

        this.attributeEdited();
      },
      attributeEdited: _.debounce(() => {
        EventBus.$emit('email.edit.attributes');
      }, 300,
      ),
      backgroundColorEdited: _.debounce(function customFunction(data) {
        this.currentElement.attributes['container-background-color'] = data.hex;
        this.attributeEdited();
      }, 300,
      ),
      colorEdited: _.debounce(function customFunction(data) {
        this.currentElement.attributes[`${data.name}-icon-color`] = data.color.hex;
        this.attributeEdited();
      }, 300,
      ),
      iconSizeEdited: _.debounce(function customFunction(value) {
        this.currentElement.attributes['icon-size'] = `${value}px`;
        this.attributeEdited();
      }, 300,
      ),
      borderRadiusEdited: _.debounce(function customFunction(value) {
        this.currentElement.attributes['border-radius'] = `${value}px`;
        this.attributeEdited();
      }, 300,
      ),
      spacingTopEdited: _.debounce(function customFunction(value) {
        this.currentElement.attributes['padding-top'] = `${value}px`;
        this.attributeEdited();
      }, 300,
      ),
      spacingBottomEdited: _.debounce(function customFunction(value) {
        this.currentElement.attributes['padding-bottom'] = `${value}px`;
        this.attributeEdited();
      }, 300,
      ),
      innerPaddingEdited: _.debounce(function customFunction(value) {
        this.currentElement.attributes['inner-padding'] = `${value}px`;
        this.attributeEdited();
      }, 300,
      ),
    },
  };
</script>
<style lang="scss" scoped>
  .vc-sketch {
    position: absolute;
    z-index: 100;
  }

  .footer {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgb(205, 215, 226);
    padding-top: .75rem;
    padding-bottom: .75rem;
    padding-left: 1.5rem;
    background-color: white;
    z-index: 1001;
  }

  .footer button {
    margin-right: .5rem;
  }

  .tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tabs a {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: rgb(29, 54, 75);
    font-size: .875rem;
    text-align: center;
    border-bottom: 2px solid rgb(217, 231, 240);
    transition: all 150ms ease-in-out;
  }

  .tabs a:hover,
  .tabs a.active {
    text-decoration: none;
    border-bottom: 2px solid var(--xpl-color-secondary-lm);
  }

  .banner {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1.5rem;
    background-color: var(--xpl-color-background-lm);
    border-top: 1px solid #d9e7f0;
    border-bottom: 1px solid #d9e7f0;
    font-size: .75rem;
    line-height: 1rem;
    color: rgb(139, 155, 172);
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  form {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  label {
    font-size: .875rem;
    line-height: 20px;
    color: rgb(29, 54, 75);
    margin-bottom: .25rem;
  }

  .input-group {
    cursor: pointer;
  }

  .input-group-addon {
    cursor: pointer;
    padding: .25rem .5rem;
    padding-right: 0;
  }

  .input-group input {
    cursor: pointer;
  }

  .icon-square {
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 1.5rem;
    cursor: pointer;
  }

  input[readonly] {
    background-color: white;
  }

  .build_tab,
  .style_tab {
    padding-bottom: 5rem;
  }

  .sites {
    margin-top: 2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: block;
  }

  .sites .site {
    width: 100%;
    text-transform: capitalize;
    font-size: .875rem;
    color: rgb(29, 54, 75);
    margin-bottom: 2rem;
  }

  .sites .site .name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .sites .site:last-child {
    margin-bottom: 0;
  }

  .sites .site .icon {
    cursor: pointer;
    color: rgb(175, 188, 202);
    margin-right: 1rem;
  }

  .sites .site .icon-checkbox-checked2 {
    color: var(--xpl-color-secondary-lm);
  }

  .sites .site .icon.social {
    font-size: 1.5rem;
    color: rgb(68, 68, 68);
  }

  .sites .site form {
    margin-top: 1rem;
    width: 100%;
    display: block;
  }
</style>
