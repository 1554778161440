<template>
  <el-row :span="24" class="personalizations">
    <el-col :span="24">
      <div class="title">Available Personalizations</div>
      <div class="links">
        First Name (with fallback greeting)
        <a href="#" @click.prevent="copyText('[%first_name | there%]')">Copy</a>
      </div>
      <div class="links">
        Virtual Link URL
        <a href="#" @click.prevent="copyText('[%virtual_link%]')">Copy</a>
      </div>
      <div class="links">
        Playlist Link URL
        <a href="#" @click.prevent="copyText('[%playlist_link%]')">Copy</a>
      </div>
      <div class="links">
        Class Name
        <a href="#" @click.prevent="copyText('[%class_name%]')">Copy</a>
      </div>
      <div class="links">
        Class Time
        <a href="#" @click.prevent="copyText('[%class_time%]')">Copy</a>
      </div>
      <div class="links">
        Class Date
        <a href="#" @click.prevent="copyText('[%class_date%]')">Copy</a>
      </div>
      <div class="links">
        Class Instructor
        <a href="#" @click.prevent="copyText('[%class_instructor%]')">Copy</a>
      </div>
    </el-col>
  </el-row>
</template>
<script>
  export default {
    methods: {
      copyText(string) {
        this.$copyText(string).then(() => {
          this.$message.success('Copied!');
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .personalizations {
    width: 30%;
  }

  .title {
    font-weight: 700;
    font-size: .875rem;
  }

  .links {
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
    font-size: .75rem;
  }

  .links a {
    font-size: .75rem;
    color: #00152a;
    text-decoration: underline;
  }
</style>
