<template>
  <div class="condition">
    <el-form>
      <label>Any contact who purchases any of the following products for the first time ever</label>
      <el-form-item>
        <el-select v-model="selected_products" multiple filterable placeholder="Product(s)" style="width: 100%;" value-key="id">
            <el-option v-for="item in products" :value="item" :label="item.name" :key="item.id">
            </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="commitCondition" :disabled="!selected_products.length">Save</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['uuid', 'rule', 'campaignItem'],
    data() {
      return {
        products: [],
        selected_products: [],
      };
    },
    created() {
      this.fetchProducts();

      if (this.rule && this.rule.length && this.rule[0].products) {
        this.selected_products = this.rule[0].products;
      }
    },
    methods: {
      fetchProducts() {
        api.getPikeProducts().then((response) => {
          this.products = response.products;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.uuid,
            quick: true,
          },
          purchased: true,
          timeframe: 'all',
          filter_purchase: true,
          time_comparison: null,
          products: this.selected_products.filter(p => p.id),
          start_date: null,
          end_date: null,
          relative_days: null,
          types: [],
          categories: [],
        };
        EventBus.$emit('condition.commit', payload);
      },
    },
  };
</script>
