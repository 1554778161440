<template>
  <el-container class="condition-container">
    <div class='sidebar-inner' style='width: 100%;'>
        <CountField
          fieldIndex='0'
          title='How Many Visits?'
          fieldStep='1'
          :countOne.sync='countOne'
          :countTwo.sync='countTwo'
          :selection.sync='selection'
          countType='Visits'
          moreThanText='This includes your selection above. (I.e. “At least 3 visits” equals 3 or more visits)'
          lessThanText='This will be lower than your selection (i.e. “Less than 5” equals 0 through 4)'
          betweenText='This includes both the lower and upper selection (I.e “Between 2 and 4 visits” equals 2, 3, or 4 visits)'
        />
        <MultiSelectField
          fieldIndex='1'
          title='At Which Location(s)?'
          fieldStep='2'
          anyLabel='Any location in my account'
          specificLabel='Specific location(s) in my account'
          specificAnyLabel='Any of the following locations'
          specificExclusionLabel='Any except the following'
          :selectedOptions.sync='selectedLocations'
          :specificChoice.sync='selectedOption'
          :exclusionChoice.sync='exclusionOption'
          :options='options'
          selectKey='id'
        />
        <MultiSelectField
          title='What is Their Home Location?'
          fieldStep='3'
          fieldIndex='2'
          anyLabel='Any location in my account'
          specificLabel='Specific location(s) in my account'
          specificAnyLabel='Any of the following locations'
          specificExclusionLabel='Any except the following'
          :selectedOptions.sync='selectedHomeLocations'
          :specificChoice.sync='selectedHomeOption'
          :exclusionChoice.sync='exclusionHomeOption'
          :options='options'
          selectKey='id'
        />
    </div>
    <div v-if="this.showSavebutton" class='save-condition-container'>
      <el-button class='save-condition-btn' type='primary' @click='commitCondition'>Save</el-button>
    </div>
  </el-container>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import MultiSelectField from '@/components/Contacts/ContactLists/Conditions/Fields/MultiSelectField';
  import CountField from '@/components/Contacts/ContactLists/Conditions/Fields/CountField';

  export default {
    props: ['condition', 'editedRule', 'goalList'],
    components: {
      MultiSelectField,
      CountField,
    },
    data() {
      return {
        countOne: 1,
        countTwo: 2,
        selection: null,
        selectedOption: 'any',
        selectedLocations: [],
        options: [],
        locations: [],
        exclusionOption: 'any',
        selectedHomeOption: 'any',
        selectedHomeLocations: [],
        exclusionHomeOption: 'any',
      };
    },
    created() {
      this.fetchLocations();
      if (this.editedRule) {
        this.countOne = this.editedRule.first_bound;
        this.countTwo = this.editedRule.second_bound;
        this.selection = this.editedRule.comparison;
        this.selectedLocations = this.editedRule.locations;
        this.exclusionOption = this.editedRule.exclusion;
        this.selectedOption = this.editedRule.option;
        this.selectedHomeOption = this.editedRule.home_option;
        this.selectedHomeLocations = this.editedRule.home_locations;
        this.exclusionHomeOption = this.editedRule.home_exclusion;
      }
    },
    methods: {
      async fetchLocations() {
        try {
          const response = await api.getMTLinkedLocations();
          this.options = response.locations;
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          comparison: this.selection,
          first_bound: this.countOne,
          second_bound: this.countTwo,
          locations: this.selectedLocations,
          exclusion: this.exclusionOption,
          option: this.selectedOption,
          home_locations: this.selectedHomeLocations,
          home_exclusion: this.exclusionHomeOption,
          home_option: this.selectedHomeOption,
        };
        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
    computed: {
      showSavebutton() {
        if (!this.selection) {
          return false;
        }
        if (
          this.selectedOption === 'specific' &&
          !this.selectedLocations.length
        ) {
          return false;
        }
        if (
          this.selectedHomeOption === 'specific' &&
          !this.selectedHomeLocations.length
        ) {
          return false;
        }
        return true;
      },
    },
  };
</script>
<style lang="scss" scoped>
.condition-container {
  background-color: #F4F8FA;
  height: 100%;
  width: 100%;
}

.card-condtion {
  width: 100%;
  background-color: #FFFFFF;
  border: solid 1px var(--xpl-color-gray-400);
  box-shadow: none;
  border-radius: 0;
  cursor: auto;
  :hover {
  background-color: #FFFFFF;
  cursor: auto;
  }
}
.card-condtion :last-of-type{
  margin-bottom: 90px;
}
.field-container:last-of-type{
  padding-bottom: 100px;
}
.sidebar-inner {
  height: 100%;
  position: relative;
  padding-top: 0;
  background-color: #F4F8FA;
  padding-left: 2.625rem;
  padding-right: 2.25rem;
  padding-bottom: 0;
  width: 100%;
}
.save-condition-btn {
  width: 729px;
  margin: 16px 12px 16px 12px;
}
.save-condition-container {
  // box-shadow: 0 -6px 7px 0 rgba(0, 0, 0, 0.05);
  padding: 0;
  margin: 0;
  z-index: 1;
}
</style>
