<template>
  <transition name="fade">
    <div class="contact-list" v-cloak>
      <div class="list-nav">
        <router-link to="/contacts/lists" class="go-back"><i class="fa fa-chevron-left"></i> All Segments</router-link>
        <input type="text" autocomplete="off" v-model="contact_list.name" @keyup.enter="editName"/>
        <div class="actions">
          <div class="dropdown">
            <button type="button" class="xpl-button xpl-button--secondary" @click="options_dropdown = !options_dropdown" v-on-clickaway="away">Options</button>
            <div :class="{ 'dropdown-menu': true, 'dropdown-menu-right': true, 'show': options_dropdown }">
              <a href="#" class="dropdown-item" @click="move_group_modal = true">Move to Segment Group</a>
              <a href="#" class="dropdown-item" @click.stop="copyContactList">Copy Segment</a>
              <a href="#" class="dropdown-item" @click.stop="exportSegment"
                 v-if="$store.state.user.current_account.pivot !== 'creator'">Export Segment</a>
              <a href="#" class="dropdown-item text-danger" @click.stop="deleteContactList">Delete Segment</a>
            </div>
          </div>
          <button type="button" class="xpl-button xpl-button--secondary" @click.stop="$router.push('/contacts/lists/' + contact_list.uuid + '/import')">Add</button>
          <MoveToGroupModal v-if="move_group_modal" :contactListId="contact_list.id" />
        </div>
      </div>
      <div class="container-fluid">
        <el-alert
          v-if="contact_list.real_time && $store.getters.isStaff"
          center
          show-icon
          type="info"
          title="This is a real-time segment"
          description="Modifying the rule (e.g. adding the funnel stage condition) may change the real-time eligibility of this segment."
        />
        <div class="cards">
          <div class="card">
            <div class="card-header card-header-secondary card-header-merged">
              Smart Rule
              <span v-if="contact_list.rule && contact_list.rule.length">
              <a href="#" @click.prevent="conditions_sidebar = !conditions_sidebar">Edit</a>
              </span>
            </div>
            <div class="card-body">
              <LoadingBox v-if="isLoading"/>
              <div v-else>
                <p class="text-muted small">A smart rule will automatically add and update this segment based off of
                  conditions.<br>i.e. All active members.</p>
                <div v-if="contact_list.rule && contact_list.rule.length">
                  <RuleEnglishHybrid :rule="contact_list.rule" />
                </div>
                <div v-else>
                    <button type="button" class="xpl-button xpl-button--secondary"
                            v-if="!contact_list.rule || !contact_list.rule.length"
                            @click.prevent="conditions_sidebar = !conditions_sidebar">Add a Smart Rule
                    </button>
                </div>
              </div>
              <ConditionsSidebarHybrid v-if="conditions_sidebar" :contact_list="contact_list"/>
            </div>
          </div>
          <div class="card">
            <div class="card-header card-header-secondary card-header-merged">
              Segment Details
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  Subscribed Contacts <span class=""
                                            aria-label="The number of contacts that you have permission to email"><span
                  class="icon icon-info text-muted"></span></span>
                  <h2 style="line-height: 2.5rem;">{{ contact_list.valid | prettyNumber }}</h2>
                </div>
                <div class="col-md-6">
                  All Contacts <span class="" aria-label="The number of contacts regardless of their email permissions"><span
                  class="icon icon-info text-muted"></span></span>
                  <h2 style="line-height: 2.5rem;">{{ contact_list.total | prettyNumber }}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="contacts empty" v-if="!contact_list.total && (!contact_list.rule || !contact_list.rule.length)">
          <h3>You have no contacts in this segment.</h3>
          <img src="https://assets.brandbot.com/public/empty-states/contact-list.svg" class="illustration"/>
          <button type="button" class="btn btn-bb-primary" @click.prevent="conditions_sidebar = !conditions_sidebar">Add
            a smart rule
          </button>
          <router-link :to="'/contacts/lists/' + contact_list.uuid + '/import'">Or Import Contacts Manually
          </router-link>
        </div>
        <div class="contacts empty" v-if="!contact_list.total && contact_list.rule && contact_list.rule.length">
          <img src="https://assets.brandbot.com/public/empty-states/contact-list-rule.svg" class="illustration"/>
          <h3>No contacts were found for this rule.</h3>
        </div>
        <div class="contacts" v-if="contact_list.total">
          <h3>Contacts in Segment</h3>
          <div class="datatable" v-if="contact_list.contacts">
            <form class="filters" @submit.prevent>
              <input type="search" name="query" v-model="query" class="form-control" placeholder="Search..."/>
              <div class="actions" v-if="selectedContacts.length">
                <button type="button" class="btn btn-bb-primary" @click="campaign_dropdown = !campaign_dropdown">Add to
                  Campaign
                </button>
                <div class="dropdown"
                     @click="funnel_management_dropdown = !funnel_management_dropdown; subscription_dropdown = false; tag_management_dropdown = false;"
                     v-if="$store.state.user.current_account.funnel_enabled">
                  <button type="button" class="btn btn-bb-primary">Funnel Management</button>
                  <div :class="['dropdown-menu', funnel_management_dropdown ? 'show' : '']">
                    <a href="#" @click="funnel_management_modal = 'add'" class="dropdown-item">Add to Funnel Stage
                      ({{ !select_all_real ? selectedContacts.length : contact_list.total | prettyNumber }})</a>
                    <a href="#" @click="funnel_management_modal = 'remove'" class="dropdown-item"
                       v-if="!select_all_real">Remove from Funnel Stage
                      ({{ !select_all_real ? selectedContacts.length : contact_list.total | prettyNumber }})</a>
                  </div>
                </div>
                <div class="dropdown"
                     @click="tag_management_dropdown = !tag_management_dropdown; subscription_dropdown = false;">
                  <button type="button" class="btn btn-bb-primary">Tag Management</button>
                  <div :class="['dropdown-menu', tag_management_dropdown ? 'show' : '']">
                    <a href="#" @click="tag_management_modal = 'tag'" class="dropdown-item">Tag Selected
                      ({{ !select_all_real ? selectedContacts.length : contact_list.total | prettyNumber }})</a>
                    <a href="#" @click="tag_management_modal = 'untag'" class="dropdown-item">Untag Selected
                      ({{ !select_all_real ? selectedContacts.length : contact_list.total | prettyNumber }})</a>
                  </div>
                </div>
                <div class="dropdown"
                     @click="subscription_dropdown = !subscription_dropdown; tag_management_dropdown = false;"
                     v-if="!select_all_real">
                  <button type="button" class="xpl-button xpl-button--secondary">Subscription Management</button>
                  <div :class="['dropdown-menu', subscription_dropdown ? 'show' : '']">
                    <a href="#" class="dropdown-item" @click.stop="unsubscribeContacts">Unsubscribe Selected
                      ({{ selectedContacts.length | prettyNumber }})</a>
                    <a href="#" class="dropdown-item" @click.stop="subscribeContacts">Subscribe Selected
                      ({{ selectedContacts.length | prettyNumber }})</a>
                  </div>
                </div>
                <a href="#" class="text-danger" @click.stop="removeContacts" v-if="!select_all_real">Remove Selected
                  ({{ selectedContacts.length | prettyNumber }})</a>
              </div>
            </form>

            <table class="table bb-datatable">
              <thead>
              <tr>
                <td>
                  <span class="icon icon-checkbox-unchecked2" v-if="!select_all" @click="selectAll"></span>
                  <span class="icon icon-checkbox-checked2" v-if="select_all" @click="selectAll"></span>
                </td>
                <td>Email Address</td>
                <td>Name</td>
                <td>Source</td>
                <td>Date</td>
                <td class="text-right">Subscription</td>
                <td class="text-right">Added</td>
              </tr>
              </thead>
              <tbody>
              <tr class="select-all" v-if="select_all">
                <td colspan="6">
                  <span v-if="!select_all_real"><a href="#" @click.prevent="select_all_real = true">Click here to select all contacts</a></span>
                  <span v-if="select_all_real">You have selected {{ contact_list.total | prettyNumber }} contacts. <a
                    href="#" @click.prevent="select_all_real = false; select_all = false;">Unselect</a></span>
                </td>
              </tr>
              <tr v-for="contact in contact_list.contacts.data" :class="[ contact.selected ? 'selected' : '' ]"
                  @click="toggleContact(contact)">
                <td>
                  <span class="icon icon-checkbox-unchecked2" v-if="!contact.selected"></span>
                  <span class="icon icon-checkbox-checked2" v-if="contact.selected"></span>
                </td>
                <td>
                  <router-link :to="'/contacts/' + contact.uuid" target="_blank" @click.prevent>
                    {{ contact.email | maskEmail(contact.fullname) }}
                  </router-link>
                </td>
                <td>{{ contact.fullname }}</td>
                <td>{{ contact.source | contactSource }}</td>
                <td>{{ contact.created_at | humanDate }}</td>
                <td class="text-muted text-right text-capitalize">{{ contact | contactMethod }}</td>
                <td class="text-muted text-right">{{ contact.list_source | sourceOrigin }}</td>
              </tr>
              </tbody>
            </table>
            <div class="pages">
              <div class="pages-flip">
                <div class="page-left" @click="previousPage()">
                  <i class="fa fa-arrow-left"></i>
                </div>
                <div class="page-right" @click="nextPage()">
                  <i class="fa fa-arrow-right"></i>
                </div>
              </div>
              Page {{ contact_list.contacts.current_page }} of {{ contact_list.contacts.last_page }}
            </div>
            <MassTagModal v-if="tag_management_modal" :action="tag_management_modal" :contacts="selectedContacts"
                          :allContacts="select_all_real" :contactListId="contact_list.id"/>
            <MassFunnelModal v-if="funnel_management_modal" :action="funnel_management_modal"
                             :contacts="selectedContacts" :allContacts="select_all_real"
                             :contactListId="contact_list.id"/>
            <AddToCampaign v-if="campaign_dropdown" :contacts="selectedContacts" :allContacts="select_all_real"
                           :contactListId="contact_list.id"/>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import EventBus from '@/event-bus';
import * as api from '@/api';
import _ from 'lodash';
import { directive as onClickaway } from 'vue-clickaway';
import MassTagModal from '@/components/Contacts/AllContacts/MassTagModal';
import MassFunnelModal from '@/components/Contacts/AllContacts/MassFunnelModal';
import ConditionsSidebar from './Conditions/ConditionsSidebar';
import MoveToGroupModal from './MoveToGroupModal';
import AddToCampaign from './AddToCampaign';
import ConditionsSidebarUIA from './Conditions/UIA/ConditionsSidebar';
import LoadingBox from '../../Global/LoadingBox';
import ConditionsSidebarHybrid from './Conditions/ConditionsSidebarHybrid';
import RuleEnglishHybrid from './Conditions/RuleEnglishHybrid';

export default {
  props: ['uuid'],
  components: {
    RuleEnglishHybrid,
    ConditionsSidebarHybrid,
    LoadingBox,
    ConditionsSidebarUIA,
    ConditionsSidebar,
    MassTagModal,
    MoveToGroupModal,
    MassFunnelModal,
    AddToCampaign,
  },
  directives: {
    onClickaway,
  },
  data() {
    return {
      isLoading: false,
      query: '',
      select_all: false,
      select_all_real: false,
      conditions_sidebar: false,
      options_dropdown: false,
      subscription_dropdown: false,
      tag_management_dropdown: false,
      tag_management_modal: false,
      contact_list: {},
      move_group_modal: false,
      funnel_management_dropdown: false,
      funnel_management_modal: false,
      campaign_dropdown: false,
    };
  },
  mounted() {
    EventBus.$on('contact_list.conditions_sidebar.close', () => {
      this.conditions_sidebar = false;
    });

    EventBus.$on('contact_list.refresh', async () => {
      this.isLoading = true;
      await window.sleep(1000);
      this.fetchContactList();
    });

    EventBus.$on('mass_tag_modal.close', () => {
      this.tag_management_modal = false;
      this.select_all_real = false;
      this.select_all = false;
    });

    EventBus.$on('mass_funnel_modal.close', () => {
      this.funnel_management_modal = false;
      this.select_all_real = false;
      this.select_all = false;
    });

    EventBus.$on('move_to_group.close', () => {
      this.move_group_modal = false;
    });

    EventBus.$on('add_to_campaign.close', () => {
      this.campaign_dropdown = false;
    });
  },
  created() {
    this.fetchContactList();
  },
  beforeDestroy() {
    EventBus.$off('move_to_group.close');
    EventBus.$off('contact_list.conditions_sidebar.close');
    EventBus.$off('contact_list.refresh');
    EventBus.$off('mass_tag_modal.close');
    EventBus.$off('mass_funnel_modal.close');
    EventBus.$off('add_to_campaign.close');
  },
  watch: {
    query() {
      this.searchContacts();
    },
  },
  computed: {
    selectedContacts() {
      if (Object.prototype.hasOwnProperty.call(this.contact_list, 'contacts')) {
        return this.contact_list.contacts.data.filter(c => c.selected === true);
      }

      return [];
    },
  },
  methods: {
    exportSegment() {
      window.open(`${process.env.VUE_APP_API_URL}contacts/lists/export/${this.contact_list.id}?uuid=${this.$store.state.user.current_account.uuid}`);
      // api.exportContactList(payload).then(() => {
      //   this.$toasted.success('Downloading...');
      //   // const url = window.URL.createObjectURL(new Blob([response]));
      //   // const link = document.createElement('a');
      //   // link.href = url;
      //   // link.setAttribute('download', 'file.csv');
      //   // document.body.appendChild(link);
      //   // link.click();
      // }).catch((err) => {
      //   console.log(err);
      //   // this.$toasted.error(err.message);
      // });
    },
    away() {
      this.options_dropdown = false;
    },
    copyContactList() {
      // eslint-disable-next-line
      if (!confirm('Are you sure you want to copy this Contact List?')) {
        return;
      }

      api.copyContactList(this.contact_list.uuid)
        .then((response) => {
          this.$toasted.success(response.message);
          this.$router.push('/contacts/lists');
        })
        .catch((err) => {
          this.$toasted.error(err.message);
        });
    },
    deleteContactList() {
      // eslint-disable-next-line
      if (!confirm('Are you sure you want to delete this Contact List? This is irreversible.')) {
        return;
      }

      api.deleteContactList(this.contact_list.uuid)
        .then((response) => {
          this.$toasted.success(response.message);
          this.$router.push('/contacts/lists');
        })
        .catch((err) => {
          this.$toasted.error(err.response);
        });
    },
    fetchContactList() {
      //
      this.isLoading = true;
      const isUIA = this.$store.getters.isUIAVendor;
      if (isUIA) {
        // legacy stuff
        api.getContactListUIA(this.uuid)
          .then((response) => {
            this.contact_list = response.contact_list;
            this.fetchContactListContacts();
            this.isLoading = false;
          })
          .catch((e) => {
            this.$log(e);
            this.$toasted.error('Something wrong happened while trying to fetch this Contact List...');
            this.isLoading = false;
          });
        return;
      }
      // legacy stuff
      api.getContactList(this.uuid)
        .then((response) => {
          this.contact_list = response.contact_list;
          this.fetchContactListContacts();
          this.isLoading = false;
        })
        .catch(() => {
          this.$toasted.error('Something wrong happened while trying to fetch this Contact List...');
          this.isLoading = false;
        });
    },
    removeContacts() {
      // eslint-disable-next-line
      if (!confirm(`Are you sure you want to remove ${this.selectedContacts.length} contact(s) from this Contact List?`)) {
        return;
      }

      const payload = {
        contact_ids: this.selectedContacts.map(c => c.id)
          .join(','),
      };

      api.removeContactsFromList(this.contact_list.id, payload)
        .then((response) => {
          this.$toasted.success(response.message);
          this.fetchContactListContacts();
          this.select_all = false;
        })
        .catch((err) => {
          this.$toasted.error(err.message);
        });
    },
    subscribeContacts() {
      // eslint-disable-next-line
      if (!confirm(`Are you sure you want to subscribe ${this.selectedContacts.length} contact(s)?`)) {
        return;
      }

      const payload = {
        contact_ids: this.selectedContacts.map(c => c.id)
          .join(','),
      };

      api.subscribeContacts(payload)
        .then((response) => {
          this.$toasted.success(response.message);
          this.fetchContactListContacts();
          this.select_all = false;
        })
        .catch((err) => {
          this.$toasted.error(err.message);
        });
    },
    unsubscribeContacts() {
      // eslint-disable-next-line
      if (!confirm(`Are you sure you want to unsubscribe ${this.selectedContacts.length} contact(s)?`)) {
        return;
      }

      const payload = {
        contact_ids: this.selectedContacts.map(c => c.id)
          .join(','),
      };

      api.unsubscribeContacts(payload)
        .then((response) => {
          this.$toasted.success(response.message);
          this.fetchContactListContacts();
          this.select_all = false;
        })
        .catch((err) => {
          this.$toasted.error(err.message);
        });
    },
    fetchContactListContacts(page = 0) {
      api.getContactListContacts(this.contact_list.uuid, page, this.query)
        .then((response) => {
          this.$set(this.contact_list, 'contacts', response.contacts);
        })
        .catch((err) => {
          this.$toasted.error(err.message);
        });
    },
    previousPage() {
      if (this.contact_list.contacts.current_page === 1) {
        return;
      }

      if (!this.contact_list.contacts.prev_page_url) {
        return;
      }

      const page = this.contact_list.contacts.prev_page_url.split('=')[1];
      this.fetchContactListContacts(page);
    },
    nextPage() {
      if (this.contact_list.contacts.current_page === this.contact_list.contacts.last_page) {
        return;
      }

      if (!this.contact_list.contacts.next_page_url) {
        return;
      }

      const page = this.contact_list.contacts.next_page_url.split('=')[1];
      this.fetchContactListContacts(page);
    },
    selectAll() {
      this.contact_list.contacts.data.forEach((c) => {
        if (!Object.prototype.hasOwnProperty.call(c, 'selected')) {
          this.$set(c, 'selected', !this.select_all);
        } else {
          c.selected = !this.select_all;
        }
      });

      this.select_all = !this.select_all;
    },
    toggleContact(contact) {
      if (!Object.prototype.hasOwnProperty.call(contact, 'selected')) {
        this.$set(contact, 'selected', true);
        return;
      }
      contact.selected = !contact.selected;
      this.select_all_real = false;
      this.select_all = false;
    },
    editName() {
      const payload = {
        name: this.contact_list.name,
      };

      api.updateContactList(this.contact_list.id, payload)
        .then((response) => {
          this.$toasted.success(response.message);
        })
        .catch((err) => {
          this.$toasted.error(err.message);
        });
    },
    searchContacts: _.debounce(function debounce() {
      this.fetchContactListContacts();
    }, 750),
  },
  filters: {
    maskEmail(email, fullname) {
      if (email.endsWith('@pike13')) {
        return fullname;
      }

      return email;
    },
    contactSource(source) {
      if (source === 'mindbody') {
        return 'MINDBODY';
      }

      if (source === 'pike13') {
        return 'Pike13';
      }

      if (source === 'brandbot') {
        return 'Manual Upload';
      }

      return source;
    },
    contactMethod(contact) {
      if (contact.unsubscribed_at) {
        return 'Unsubscribed';
      }

      if (contact.contact_method === 'both') {
        return 'Email & SMS';
      }

      // if (!contact.contact_method) {
      //   return 'Unsubscribed';
      // }

      if (contact.contact_method === 'phone') {
        return 'SMS';
      }

      return 'Subscribed';
    },
    sourceOrigin(value) {
      if (value === 'automatic') {
        return 'Automatic';
      }

      if (value === 'manual') {
        return 'Manual';
      }

      return value;
    },
    timeFrame(value) {
      if (value === 'all') {
        return 'All Time';
      }

      return value;
    },
  },
};
</script>
<style lang="scss" scoped>
  .actions button {
    margin-right: 0;
  }

  .actions button:first-child {
    margin-right: 1rem;
  }

  .cards {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }

  .cards .card:first-child {
    margin-right: 2rem;
    min-width: 36.125rem;
    width: 60%;
  }

  .cards .card:last-child {
    min-width: 18rem;
    width: 40%;
  }

  .contacts {
    padding-top: 3.5rem;
  }

  .contacts.empty {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .contacts.empty button {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .illustration {
    display: block;
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 15rem;
  }

  .icon {
    font-size: .875rem;
    cursor: pointer;
  }

  a.text-danger {
    margin-right: .5rem;
  }

  .filters .actions button:first-child {
    margin-right: 0;
  }

  .bb-datatable thead tr td {
    cursor: default;
  }

  .conditions .fa-square {
    color: #d8d8d8;
  }

  .conditions .name {
    margin-top: 1rem;
    font-size: .875rem;
    line-height: 1.25rem;
    color: #1d364b;
  }

  .conditions .details {
    padding-left: 1rem;
  }

  .conditions .details {
    text-transform: capitalize;
    color: #8b9bac;
    font-size: .75rem;
    line-height: 1rem;
  }

  .conditions .details .title {
    font-weight: 700;
    line-height: 1.25rem;
    display: block;
    margin-top: .25rem;
    display: block;
    color: #617080;
  }

  tr.select-all {
    border: none !important;
  }

  tr.select-all td {
    color: rgb(29, 54, 75) !important;
    font-size: .75rem !important;
    background-color: rgb(180, 224, 250) !important;
    text-align: center;
    border: none !important;
  }

  tr.select-all td a {
    color: rgb(29, 54, 75) !important;
    text-decoration: underline;
  }

  .dropdown {
    display: inline-block;
  }
</style>
