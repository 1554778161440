<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Connect to Vimeo OTT
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <el-form>
              <el-form-item>
                <label>Your Vimeo OTT API Key</label>
                <el-input v-model="api_key" placeholder="API Key" />
                <div class="small"><a href="https://docs.brandbot.com/en/articles/3812173-how-to-integrate-your-vimeo-ott-account-with-brandbot" target="_blank">Learn more</a> about how to create an API Key in your Vimeo OTT account.</div>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="xpl-button xpl-button--primary":disabled="!api_key" @click.stop="connectVimeo" :loading="loading">Save</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';

  export default {
    props: ['accountId'],
    data() {
      return {
        loading: false,
        api_key: null,
      };
    },
    methods: {
      closeModal() {
        EventBus.$emit('connect_vimeo.close');
      },
      connectVimeo() {
        this.loading = true;
        const payload = {
          api_key: this.api_key,
        };
        api.addVimeoIntegration(payload).then((response) => {
          this.loading = false;
          this.$message.success(response.message);
          this.closeModal();
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
