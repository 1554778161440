<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="spacing">
      <YesNoComponent
        :id="1"
        :selection.sync="hasSubscription"
        :currentlyOpen="currentlyOpen"
        :notEditState="!editedRule"
        :title="'Has or Does Not Have a Vimeo Subscription?'"
        :yesCopy="'Has'"
        :noCopy="'Has Not'"
        :descLabel="''"
        :yesSummary="'Has a Vimeo Subscription'"
        :noSummary="'Does not have a Vimeo Subcription'"
        :showLabel="false"
        :initiatesDropDown="false"
      />
      <TimeComponent
        :id="2"
        :currentlyOpen="currentlyOpen"
        :notEditState="!editedRule"
        :title="'When Did This Subscription Start?'"
        :futureLabel="'days'"
        :pastLabel="'days ago'"
        :absLabel="'days'"
        :showAbs="true"
        :showPast="true"
        :inDropDown="false"
        :selectedOption.sync="timeframeComparison"
        :valueOne.sync="timeframeValue"
        :goalList="goalList"
      />
      <div class="card-condition-border" @click="handleCurrentlyOpen(3)">
        <div :class="['card-condition', currentlyOpen === 3 ? 'disable-hover-bg' : '']">
          <el-row :span="24">
            <el-col :span="12">Which Product(s) is this Subscription for?</el-col>
            <el-col v-if="currentlyOpen !== 3" :span="12">
              <div v-if="specificProducts === 'any'">All products</div>
              <div v-if="specificProducts === 'specific' && productExclusionOption === 'any' && selectedProducts.length">Any of the following</div>
              <div v-if="specificProducts === 'specific' && productExclusionOption === 'except' && selectedProducts.length">Any products except the following</div>
              <div v-if="specificProducts === 'specific'" v-for="result in productsFiltered" class="result">{{ result.name }}</div>
              <a v-if="selectedProducts.length > 2 && !expandedProducts" class="view-more-summary" @click.stop="expandedProducts = true">View more</a>
              <a class="view-more-summary" @click.stop="expandedProducts = false" v-if="expandedProducts">Collapse</a>
            </el-col>
          </el-row>
          <div class="option-body" v-if="currentlyOpen === 3" @click.stop>
            <el-radio class="radio-btn" v-model="specificProducts" label="any">Any products</el-radio>
            <el-radio class="radio-btn" v-model="specificProducts" label="specific">Specific product(s)</el-radio>
            <div class="desc-container" v-if="specificProducts === 'specific'">
              <SelectionOption
                :descLabel="'Any of the following products'"
                :filterOption.sync="productExclusionOption"
                :choice.sync="selectedProducts"
                :options="products"
                :selectKey="'id'"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card-condition-border" @click="handleCurrentlyOpen(4)">
        <div :class="['card-condition', currentlyOpen === 4 ? 'disable-hover-bg' : '']">
          <el-row :span="24">
            <el-col :span="12">Which Statuse(s)?</el-col>
            <el-col v-if="currentlyOpen !== 4" :span="12">
              <div v-if="specificStatuses === 'any'">All statuses</div>
              <div v-if="specificStatuses === 'specific' && statusExclusionOption === 'any' && selectedStatuses.length">Any of the following</div>
              <div v-if="specificStatuses === 'specific' && statusExclusionOption === 'except' && selectedStatuses.length">Any statuses except the following</div>
              <div v-if="specificStatuses === 'specific'" v-for="result in statusFiltered" class="result">{{ result.name }}</div>
              <a v-if="selectedStatuses.length > 2 && !expandedStatuses" class="view-more-summary" @click.stop="expandedStatuses = true">View more</a>
              <a class="view-more-summary" @click.stop="expandedStatuses = false" v-if="expandedStatuses">Collapse</a>
            </el-col>
          </el-row>
          <div class="option-body" v-if="currentlyOpen === 4" @click.stop>
            <el-radio class="radio-btn" v-model="specificStatuses" label="any">Any status</el-radio>
            <el-radio class="radio-btn" v-model="specificStatuses" label="specific">Specific statuse(s)</el-radio>
            <div class="desc-container" v-if="specificStatuses === 'specific'">
              <SelectionOption
                :descLabel="'Any of the following statuses'"
                :filterOption.sync="statusExclusionOption"
                :choice.sync="selectedStatuses"
                :options="statuses"
                :selectKey="'id'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="save-condition-container" v-if="showSaveButton">
      <el-button class="save-condition-btn" type="primary" @click="commitCondition">Save</el-button>
    </div>
  </div>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import YesNoComponent from '../MarianaTek/Components/YesNoComponent';
  import TimeComponent from '../MarianaTek/Components/TimeComponent';
  import SelectionOption from '../MarianaTek/Components/SelectionOption';

  export default {
    props: ['condition', 'editedRule', 'goalList'],
    components: {
      YesNoComponent,
      TimeComponent,
      SelectionOption,
    },
    data() {
      return {
        currentlyOpen: this.editedRule ? null : 1,
        options: [],
        hasSubscription: true,
        firstPass: true,
        timeframeComparison: 'allTime',
        timeframeValue: 1,
        productExclusionOption: 'any',
        specificProducts: 'any',
        products: [],
        statusExclusionOption: 'any',
        selectedProducts: [],
        expandedProducts: false,
        specificStatuses: 'any',
        statuses: [],
        selectedStatuses: [],
        expandedStatuses: false,
        completedSteps: this.editedRule ?
        { 1: true, 2: true, 3: true, 4: true } : { 1: true, 2: false, 3: false, 4: false },
      };
    },
    mounted() {
      EventBus.$on('update:currentlyOpen', (id) => {
        this.handleCurrentlyOpen(id);
      });
    },
    created() {
      this.fetchVimeoProducts();
      this.fetchVimeoStatuses();

      if (this.editedRule) {
        this.firstPass = false;
        this.currentlyOpen = null;

        this.timeframeComparison = this.editedRule.subscription_creation_comparison;
        this.timeframeValue = this.editedRule.subscription_creation_value;
        this.hasSubscription = this.editedRule.has_subscription;

        this.specificProducts = this.editedRule.specific_products;
        this.productExclusionOption = this.editedRule.product_exclusion_option;
        this.selectedProducts = this.editedRule.selected_products;

        this.specificStatuses = this.editedRule.specific_statuses;
        this.statusExclusionOption = this.editedRule.status_exclusion_option;
        this.selectedStatuses = this.editedRule.selected_statuses;
      }
    },
    beforeDestroy() {
      EventBus.$off('update:currentlyOpen');
    },
    computed: {
      productsFiltered() {
        if (!this.expandedProducts) {
          return this.selectedProducts.slice(0, 2);
        }

        return this.selectedProducts;
      },
      statusFiltered() {
        if (!this.expandedStatuses) {
          return this.selectedStatuses.slice(0, 2);
        }

        return this.selectedStatuses;
      },
      showSaveButton() {
        if (!this.timeframeComparison) {
          return false;
        }

        return true;
      },
    },
    methods: {
      fetchVimeoProducts() {
        api.getVimeoProducts().then((response) => {
          this.products = response.products;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchVimeoStatuses() {
        api.getVimeoStatuses().then((response) => {
          this.statuses = response.statuses;
        }).then((err) => {
          this.$message.error(err.message);
        });
      },
      handleCurrentlyOpen(id) {
        this.currentlyOpen = this.currentlyOpen === id ? id + 1 : id;
        if (!this.completedSteps[this.currentlyOpen]) {
          this.completedSteps[this.currentlyOpen] = true;
        }
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          subscription_creation_comparison: this.timeframeComparison,
          subscription_creation_value: this.timeframeValue,
          has_subscription: this.hasSubscription,

          specific_products: this.specificProducts,
          product_exclusion_option: this.productExclusionOption,
          selected_products: this.selectedProducts,

          specific_statuses: this.specificStatuses,
          status_exclusion_option: this.statusExclusionOption,
          selected_statuses: this.selectedStatuses,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar-inner {
    height: 100%;
    position: relative;
    padding-top: 0;
    background-color: #F2F4F6;
  }
</style>
