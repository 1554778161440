<template>
  <div>
    <div class="nav-subheader">
      <div class="nav-inner">
        <div>Scheduled Plans</div>
        <div class="actions">
          <button type="button" class="xpl-button xpl-button--primary" @click="show_create_modal = true">Create a Scheduled Plan</button>
        </div>
      </div>
    </div>
    <div class="plans-container">
      <div class="card first-state" v-if="!scheduled_plans.length">
        <div class="card-body">
          <div class="">
            <h1>Planning for success requires a plan</h1>
            <p>Organize your marketing by creating plans for everything on your calendar. See what needs to get done for your upcoming sale or how much work you have left on getting the word out about your next retreat.</p>
            <div class="learn-more">
              <a href="https://docs.brandbot.com/scheduled-marketing" target="_blank" class="btn btn-bb-learn-more">
                <span class="material-icons">description</span>
                Help Desk Articles
              </a>
            </div>
          </div>
          <div>
            <img src="https://assets.brandbot.com/public/empty-states/scheduled-plans.svg" alt="Scheduled Plans" />
          </div>
        </div>
      </div>

      <div class="plans" v-if="scheduled_plans.length">
        <div class="search-bar">
          <span class="material-icons">search</span>
          <input ref="searchPlans" type="search" name="query" v-model="query" class="form-control" placeholder="Start typing to search..." />
        </div>

        <div class="card hoverable plan" v-for="scheduled_plan in filteredPlans" @click="$router.push('/plans/' + scheduled_plan.uuid)">
          <div class="card-body">
            {{ scheduled_plan.name }}
            <div class="small text-muted">Created on {{ scheduled_plan.created_at | humanDateTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <NameScheduledPlanModal v-if="show_scratch_modal" />
    <CreatePlanModal v-if="show_create_modal" />
    <CreateScheduledPlanTemplate v-if="show_template_modal" />
  </div>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import CreatePlanModal from './CreatePlanModal';
  import NameScheduledPlanModal from './NameScheduledPlanModal';
  import CreateScheduledPlanTemplate from './CreateScheduledPlanTemplate';

  export default {
    components: {
      CreatePlanModal,
      NameScheduledPlanModal,
      CreateScheduledPlanTemplate,
    },
    data() {
      return {
        query: '',
        show_scratch_modal: false,
        show_template_modal: false,
        show_create_modal: false,
        scheduled_plans: [],
      };
    },
    beforeRouteEnter(to, from, next) {
      api.getScheduledPlans().then((response) => {
        next((vm) => {
          vm.scheduled_plans = response.scheduled_plans;
        });
      }).catch((err) => {
        next((vm) => {
          vm.$toasted.error(err.message);
        });
      });
    },
    mounted() {
      // eslint-disable-next-line
      document.title = `Scheduled Plans | ${this.$store.getters.brandName}`;

      EventBus.$on('scheduled_plan.create_modal.close', () => {
        this.show_create_modal = false;
        this.show_scratch_modal = false;
        this.show_template_modal = false;
      });

      EventBus.$on('scheduled_plan.create_modal.choice', (choice) => {
        this.show_create_modal = false;

        if (choice === 'scratch') {
          this.show_scratch_modal = true;
        } else {
          this.show_template_modal = true;
        }
      });

      this.$nextTick(() => {
        if (this.scheduled_plans?.length) {
          this.$refs.searchPlans.focus();
        }
      });
    },
    beforeDestroy() {
      // eslint-disable-next-line
      document.title = this.$store.getters.brandName;

      EventBus.$off('scheduled_plans.create_modal.close');
      EventBus.$off('scheduled_plan.create_modal.choice');
    },
    computed: {
      filteredPlans() {
        if (this.query) {
          return this.scheduled_plans.filter(s =>
            s.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1,
          );
        }

        return this.scheduled_plans;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .nav-subheader {
    width: 100%;
    justify-content: space-between;
    position: fixed;
    z-index: 100;
  }

  .nav-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .plans-container {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: calc(4rem + 2rem);
  }

  .card.first-state {
    min-height: 20rem;
  }

  .card.first-state .card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .card.first-state .card-body img {
    flex-shrink: 0;
    flex-grow: 0;
    width: 30rem;
  }

  .card h1 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #6923f4;
    margin-bottom: 1rem;
  }

  .card p {
    max-width: 18.75rem;
    font-size: .875rem;
    font-weight: 700;
    color: #8b9bac;
    margin-bottom: 1.5rem;
  }

  .card .actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .card .actions .btn:first-child {
    margin-right: 1rem;
  }

  .card .actions .btn-link {
    font-size: .75rem;
    line-height: 1rem;
    color: #1d364b;
  }

  .card .learn-more {
    margin-top: 1.5rem;
    font-size: .75rem;
    font-weight: 700;
    line-height: 1rem;
    color: #2e4457;
  }

  .card .learn-more .btn {
    margin-top: 1rem;
    margin-right: .5rem;
  }

  .card .learn-more .btn:last-child {
    margin-right: 0;
  }

  .plans {
    margin-top: 2rem;
  }

  .plans .search-bar {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .plans .plan {
    cursor: pointer;
    margin-bottom: 1rem;
  }

  .plans .plan:last-child {
    margin-bottom: 0;
  }

  .plans .plan .card-body {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    color: #1d364b;
  }
</style>
