<template>
  <div>
    <el-card>
      <div class="v2__heading">Landing Page Settings</div>
      <div class="display-caption" style="margin-bottom: 1.5rem;">These defaults will be used when building out your Landing Pages.</div>
      <el-form ref="landingForm" :rules="rules" :model="settings" @submit.native.prevent>
        <el-form-item prop="currency">
          <label for="currency">Currency</label>
          <div>
            <el-select v-model="settings.currency" filterable placeholder="Currency" style="width: 100%;">
              <el-option v-for="currency in currencies" :key="currency.code" :label="currency.currency" :value="currency.code">
                {{ currency.currency }} ({{ currency.code }})
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item prop="help_phone">
          <label for="help_phone">Help Phone Number</label>
          <el-input v-model.trim="settings.help_phone" id="help_phone" placeholder="Help Phone Number" />
        </el-form-item>
        <el-form-item prop="help_email">
          <label for="help_email">Help Email</label>
          <el-input type="email" v-model.trim="settings.help_email" id="help_email" placeholder="Help Phone Number" />
        </el-form-item>
        <el-form-item prop="liability_text">
          <label for="liability_text">Liability Waiver</label>
          <el-input type="textarea" :rows="5" v-model="settings.liability_text" id="liability_text" />
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item>
              <label>Default Navbar Logo</label>
              <el-upload :multiple="false"
                          :limit="1"
                          ref="navbarLogo"
                          accept="image/*"
                          action=""
                          :http-request="changeLogo">
                <el-button size="small" type="secondary">Change Logo</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <label for="logo_width">Logo Width</label>
              <el-input type="number" v-model.trim="settings.logo_width" id="logo_width" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="buy_button">
              <label>Buy Button Color</label>
              <div>
                <el-color-picker v-model="settings.buy_button"></el-color-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="background_color">
              <label>Page Background Color</label>
              <div>
                <el-color-picker v-model="settings.background_color"></el-color-picker>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="save">Save</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
  import * as api from '@/api';
  import currencies from '@/components/Auth/currencies.json';

  export default {
    data() {
      return {
        currencies,
        settings: {
          currency: this.$store.state.user.current_account.currency,
          buy_button: this.$store.state.user.current_account
            .account_emails_metadata.lp_button_color,
          background_color: this.$store.state.user.current_account
            .account_emails_metadata.lp_page_color,
          help_email: this.$store.state.user.current_account.account_emails_metadata.lp_email,
          help_phone: this.$store.state.user.current_account.account_emails_metadata.lp_phone,
          liability_text: this.$store.state.user.current_account.liability_waiver,
          logo_width: this.$store.state.user.current_account.lp_logo_width ? this.$store.state.user.current_account.lp_logo_width.replace('px', '') : null,
        },
        rules: {
          liability_text: [{ required: true, trigger: 'change', message: 'Please fill out your Liability Waiver' }],
          buy_button: [{ required: true, trigger: 'change', message: 'Please choose a default color' }],
          help_email: [{ required: true, type: 'email', trigger: 'change', message: 'Please choose a default email' }],
          background_color: [{ required: true, trigger: 'change', message: 'Please choose a default background color' }],
        },
      };
    },
    methods: {
      changeLogo(payload) {
        const formData = new FormData();
        formData.append('lp_logo', payload.file, payload.file.name);

        api.uploadDefaultCheckoutFlow(formData).then((response) => {
          this.$store.dispatch('refreshUser').then(() => {
            this.$message.success(response.message);
          });
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      save() {
        this.$refs.landingForm.validate((valid) => {
          if (!valid) {
            return;
          }

          const account = this.$store.state.user.current_account;

          const payload = {
            liability_waiver: this.settings.liability_text,
            lp_phone: this.settings.help_phone,
            lp_email: this.settings.help_email,
            lp_button_color: this.settings.buy_button,
            lp_page_color: this.settings.background_color,
            lp_logo_width: `${this.settings.logo_width}px`,
            currency: this.settings.currency,
          };

          api.updateAccount(account.id, payload).then((response) => {
            this.$store.dispatch('refreshUser').then(() => {
              this.$toasted.success(response.message);
            });
          }).catch((err) => {
            this.$toasted.error(err.message);
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .card-body .title {
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.43;
    color: rgb(29, 54, 75);
    margin-bottom: .25rem;
  }

  .card-body .title:last-child {
    margin-top: 2.5rem;
  }

  form {
    max-width: 50%;
  }

  .card-body.email form {
    margin-top: 1.5rem;
  }

  .emails {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .emails .email {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-right: 1rem;
    border: solid 1px rgb(231, 238, 245);
    font-size: .875rem;
    line-height: 1.43;
    color: rgb(51, 51, 51);
    margin-bottom: .5rem;
  }

  .emails .email .material-icons {
    cursor: pointer;
    color:rgb(139, 155, 172);
    font-size: .875rem;
  }

  .card + .card {
    margin-top: 2.5rem;
  }
</style>
