<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Rename Template
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <label>Template Name</label>
                <input type="text" v-model="template.name" class="form-control" placeholder="Template Name" />
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="button" class="xpl-button xpl-button--primary" :disabled="!template.name" @click="save">Save</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['template'],
    data() {
      return {};
    },
    methods: {
      closeModal() {
        EventBus.$emit('template.rename.close');
      },
      save(event) {
        const target = event.srcElement || event.target;
        target.disabled = true;
        const payload = {
          name: this.template.name,
        };

        api.updateEmailTemplatesSaved(this.template.id, payload).then((response) => {
          this.$toasted.success(response.message);
          this.closeModal();
        }).catch((err) => {
          target.disabled = false;
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
