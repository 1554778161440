<template>
  <div class="condition">
    <el-form>
      <el-form-item>Any contact when they visit
        <el-input-number :min="0" v-model="visit_number_start"></el-input-number>
      times ever.</el-form-item>
      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="commitCondition">Save</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    props: ['uuid', 'rule', 'campaignItem'],
    data() {
      return {
        visit_number_start: null,
      };
    },
    created() {
      //
    },
    methods: {
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.uuid,
            quick: true,
          },
          timeframe: '',
          visit_number_start: this.visit_number_start,
          visit_number_end: null,
          visit_comparison: 'exactly',
          time_comparison: null,
          start_date: null,
          end_date: null,
          relative_days: null,
          staffes: [],
          payment_options: [],
          service_names: [],
        };
        EventBus.$emit('condition.commit', payload);
      },
    },
  };
</script>
