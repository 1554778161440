<template>
  <el-container class="condition-container">
    <div class="sidebar-inner" style="width: 100%;">
        <BooleanField
          fieldIndex="0"
          title="Customers Who Have / Do Not Have?"
          fieldStep="1"
          yesText="Has"
          noText="Does not have"
          :selection.sync="hasMembership"
          :showLabel="false"
          descLabel="Options include location, credit type, membership type, status and more"
          id="33"
        />
        <BooleanField
          fieldIndex="1"
          title="Which Memberships?"
          fieldStep="2"
          yesText="Any membership"
          noText="Specific membership(s)"
          :selection.sync="allMembershipTypes"
          :showLabel="true"
          descLabel="Options include type, status, and membership start date"
          id="23"
        />
        <div v-if="!allMembershipTypes" class="condition-banner-style">
          Drill Down Options
        </div>
        <MultiSelectField
          v-if="!allMembershipTypes"
          fieldIndex="2"
          title="Which Membership Types?"
          fieldStep="3"
          anyLabel="Any membership type"
          specificLabel="Select membership type(s)"
          specificAnyLabel="Any of the following membership types"
          specificExclusionLabel="Any except the following"
          :selectedOptions.sync="selectedMemberships"
          :specificChoice.sync="specificMembershipTypes"
          :exclusionChoice.sync="membershipTypeExclusionOption"
          :options="memberships"
          selectKey="id"
          id="452"
        />
        <MultiSelectField
          v-if="!allMembershipTypes"
          fieldIndex="3"
          title="Which Membership Status(es)?"
          fieldStep="4"
          anyLabel="Any membership status"
          specificLabel="Select status(es)"
          specificAnyLabel="Any of the following membership statuses"
          specificExclusionLabel="Any except the following"
          :selectedOptions.sync="selectedStatuses"
          :specificChoice.sync="specificStatusTypes"
          :exclusionChoice.sync="statusExclusionOption"
          :options="statusOptions"
          selectKey="id"
          id="44"
        />
        <TimeFrameField
          v-if="!allMembershipTypes"
          fieldIndex="4"
          title="When Did They Start Their Membership?"
          fieldStep="5"
          :selectedOption.sync="timeframeComparison"
          :valueOne.sync="timeframeValue"
          pastLabel="days ago"
          :hidePast="false"
          :hideFuture="true"
          :hideTimeType="false"
          :goalList="null"
          id="22"
        />
         <MultiSelectField
           v-if="!allMembershipTypes"
          fieldIndex="5"
          title="Membership Purchase Location"
          fieldStep="6"
          anyLabel="Any location in my account"
          specificLabel="Specific location(s) in my account"
          specificAnyLabel="Any of the following locations"
          specificExclusionLabel="Any except the following"
          :selectedOptions.sync="selectedPurchaseLocations"
          :specificChoice.sync="purchaseLocationOption"
          :exclusionChoice.sync="excludedPurchaseLocationOption"
          :options="membershipPurchaseLocationOptions"
          selectKey="id"
          id="99"
        />
    </div>
    <div class="save-condition-container" v-if="showSaveButton">
      <el-button class="save-condition-btn" type="primary" @click="commitCondition">Save</el-button>
    </div>
  </el-container>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import TimeFrameField from '@/components/Contacts/ContactLists/Conditions/Fields/TimeFrameField';
  import MultiSelectField from '@/components/Contacts/ContactLists/Conditions/Fields/MultiSelectField';
  import BooleanField from '@/components/Contacts/ContactLists/Conditions/Fields/BooleanField';

  export default {
    props: ['condition', 'editedRule', 'goalList'],
    components: {
      TimeFrameField,
      MultiSelectField,
      BooleanField,
    },
    data() {
      return {
        hasMembership: true,
        allMembershipTypes: true,
        membershipTypeExclusionOption: 'any',
        statusExclusionOption: 'any',
        memberships: [],
        selectedMemberships: [],
        selectedStatuses: [],
        specificMembershipTypes: 'any',
        specificStatusTypes: 'any',
        statusOptions: [],
        timeframeComparison: null,
        timeframeValue: 1,
        membershipPurchaseLocationOptions: [],
        selectedPurchaseLocations: [],
        purchaseLocationOption: 'any',
        excludedPurchaseLocationOption: 'any',
      };
    },
    created() {
      this.fetchMemberships();
      this.fetchMembershipStatuses();
      this.fetchLocations();

      if (this.editedRule) {
        this.hasMembership = this.editedRule.has_memberships;
        this.allMembershipTypes = this.editedRule.all_memberships;
        this.specificMembershipTypes = this.editedRule.membership_type_option;
        this.membershipTypeExclusionOption = this.editedRule.membership_type_exclusion;
        this.statusExclusionOption = this.editedRule.membership_status_exclusion;
        this.specificStatusTypes = this.editedRule.membership_status_option;
        this.selectedMemberships = this.editedRule.membership_types;
        this.selectedStatuses = this.editedRule.membership_statuses;
        this.timeframeComparison = this.editedRule.membership_start_filter;
        this.selectedPurchaseLocations = this.editedRule.selected_purchase_locations;
        this.purchaseLocationOption = this.editedRule.selected_purchase_location_option;
        this.excludedPurchaseLocationOption = this.editedRule.selected_purchase_location_exclusion;
        if (this.editedRule.membership_start_filter === 'sinceEntering') {
          this.timeframeComparison = 'allTime';
        }
        this.timeframeValue = this.editedRule.membership_start_value;
        if (!this.editedRule.selected_purchase_location_option) {
          this.purchaseLocationOption = 'any';
        }
        if (!this.editedRule.selected_purchase_locations) {
          this.selectedPurchaseLocations = [];
        }
      }
    },
    computed: {
      showSaveButton() {
        if (!this.allMembershipTypes && !this.timeframeComparison) { return false; }

        if ((this.timeframeComparison === 'before' || this.timeframeComparison === 'after' || this.timeframeComparison === 'between' || this.timeframeComparison === 'specificDate') && Number.isInteger(this.timeframeValue)) {
          return false;
        }

        if (this.timeframeComparison === 'between' && !this.timeframeValue.length) {
          return false;
        }


        if (this.specificMembershipTypes === 'specific' && !this.selectedMemberships.length) {
          return false;
        }

        if (this.specificStatusTypes === 'specific' && (!this.selectedStatuses.length || !this.statusExclusionOption)) {
          return false;
        }
        if (this.purchaseLocationOption === 'specific' && (!this.selectedPurchaseLocations.length || !this.excludedPurchaseLocationOption)) {
          return false;
        }
        return true;
      },
    },
    methods: {
      async fetchLocations() {
        try {
          const response = await api.getMTLinkedLocations();
          this.membershipPurchaseLocationOptions = response.locations;
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      async fetchMemberships() {
        try {
          const response = await api.getMTMemberships();
          this.memberships = response.memberships;
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      async fetchMembershipStatuses() {
        try {
          const response = await api.MTMembershipStatuses();
          this.statusOptions = response.membership_statuses;
        } catch (err) {
          this.$message.error(err.message);
        }
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          has_memberships: this.hasMembership,
          all_memberships: this.allMembershipTypes,
          membership_type_option: this.specificMembershipTypes,
          membership_type_exclusion: this.membershipTypeExclusionOption,
          membership_status_exclusion: this.statusExclusionOption,
          membership_status_option: this.specificStatusTypes,
          membership_types: this.selectedMemberships,
          membership_statuses: this.selectedStatuses,
          membership_start_filter: this.timeframeComparison,
          membership_start_value: this.timeframeValue,
          selected_purchase_locations: this.selectedPurchaseLocations,
          selected_purchase_location_option: this.purchaseLocationOption,
          selected_purchase_location_exclusion: this.excludedPurchaseLocationOption,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
@import "src/scss/global/_spacing.scss";
@import "src/scss/global/_new_main.scss";
  .field-container {
  margin-top: 28px;
}
.option-body {
  margin-top: 20px;
}

.card-condition {
  background-color: #FFFFFF;
  border: solid 1px var(--xpl-color-gray-400);
  box-shadow: none;
  border-radius: 0;
  cursor: auto;
}

.card-condition:hover {
  background-color: #FFFFFF;
  cursor: auto;
}
.field-step {
  height: 1.5rem;
  width: 1.5rem;
  background-color: $bb-color-blue-v2;
  font-family: $primary-bb-font-family;
  font-size: $base-font-size;
  color: #FFFFFF;
  border-radius: 50%;
  text-align: center;
  margin: $space-base-inline;
}
.field-title {
  font-family: $primary-bb-font-family;
  font-weight: 700;
  font-size: $base-font-size;
  color: $base-color-black-v2;
  line-height: $loose-medium-line-height;
}

.radio-btn {
  margin-top: $space-extra-tight;
  margin-bottom: $space-extra-tight;
}

.field-label-text  {
  display: block;
}
.field-label-text /deep/ .el-radio__label {
  font-family: $primary-bb-font-family !important;
  font-size: $base-loose-font-size !important;
  color: $base-color-black-v2 !important;
  line-height: $loose-line-height !important;
  padding-left: 8px;
}
.field-label-text /deep/ .el-radio__inner {
  background-color: #F2F4F6 !important;
  border: 1px solid #A6B2BF !important;
  height: 16px;
  width: 16px;
}
.field-label-text /deep/  .el-radio__input.is-checked .el-radio__inner::after {
  background-color: var(--xpl-color-secondary-lm) !important;
  width: 12px;
  height: 12px;
}
.condition-container {
  background-color: #F4F8FA;
  height: 100%;
  width: 100%;
}

.field-container:last-of-type{
  padding-bottom: 100px;
}
.sidebar-inner {

  height: 100%;
  position: relative;
  padding-top: 0;
  background-color: #F4F8FA;
  padding-left: 2.625rem;
  padding-right: 2.25rem;
  padding-bottom: 0;
  width: 100%;
}
.save-condition-btn {
  width: 729px;
  margin: 16px 12px 16px 12px;
}
.save-condition-container {
  // box-shadow: 0 -6px 7px 0 rgba(0, 0, 0, 0.05);
  padding: 0;
  margin: 0;
  z-index: 1;
}
.condition-banner-style {
  background-color: #F4F8FA !important;
  margin-top: 13px !important;
  padding-bottom: 0;
}


</style>
