<template>
  <div class="condition">
    <form @submit.prevent>
      <div class="form-group" v-if="campaignItem">
        <span>The contact has not</span>
        <Multiselect v-model="action" placeholder="Action" :options="actions" :show-labels="false" :allow-empty="false" style="min-width: 7rem; width: 7rem; max-width: 7rem;" />
        <span>the following emails</span>
        <div style="margin-top: .5rem;" class="multiselect-block">
          <Multiselect v-model="selected_emails" placeholder="Email(s)" :options="emails" label="name" track-by="uuid" :searchable="true" :allow-empty="false" :show-labels="false" :multiple="true" :close-on-select="false" :disabled="!action">
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <div class="option__title">{{ props.option.name }}</div>
                <span class="option__small text-muted small" v-if="props.option.type === 'scheduled_email'">Scheduled Email &middot; {{ props.option.scheduled_at | humanDateTime }}</span>
                <span class="option__small text-muted small" v-if="props.option.type === 'campaign_item'">Campaign &middot; {{ props.option.campaign_name }}</span>
              </div>
            </template>
          </Multiselect>
        </div>
      </div>

      <div class="form-group" v-else>
        <span>Any contact who has not</span>
        <Multiselect v-model="action" placeholder="Action" :options="actions" :show-labels="false" :allow-empty="false" style="min-width: 7rem; width: 7rem; max-width: 7rem;" />
        <span>the following emails</span>
        <div style="margin-top: .5rem;" class="multiselect-block">
          <Multiselect v-model="selected_emails" placeholder="Email(s)" :options="emails" label="name" track-by="uuid" :searchable="true" :allow-empty="false" :show-labels="false" :multiple="true" :close-on-select="false" :disabled="!action">
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <div class="option__title">{{ props.option.name }}</div>
                <span class="option__small text-muted small" v-if="props.option.type === 'scheduled_email'">Scheduled Email &middot; {{ props.option.scheduled_at | humanDateTime }}</span>
                <span class="option__small text-muted small" v-if="props.option.type === 'campaign_item'">Campaign &middot; {{ props.option.campaign_name }}</span>
              </div>
            </template>
          </Multiselect>
        </div>
      </div>

      <div>
        <button class="xpl-button xpl-button--primary" @click.stop="commitCondition" :disabled="!actions">Save</button>
      </div>
    </form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['uuid', 'rule', 'campaignItem'],
    components: {
      Multiselect,
    },
    data() {
      return {
        action: null,
        actions: ['Open', 'Click'],
        selected_emails: [],
        emails: [],
      };
    },
    created() {
      this.fetchPastEmails();

      if (this.rule && this.rule.length) {
        this.action = this.rule[0].action;
        this.selected_emails = this.rule[0].emails;
      }
    },
    methods: {
      fetchPastEmails() {
        api.getPastSentEmails().then((response) => {
          this.emails = response.past_emails;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition(event) {
        const target = event.target || event.srcElement;
        target.disabled = true;
        target.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';

        const payload = {
          condition: {
            uuid: this.uuid,
            quick: true,
          },
          interacted: false,
          action: this.action,
          filtering: 'specific',
          emails: this.selected_emails,
        };
        EventBus.$emit('condition.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .form-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  span + .multiselect {
    margin-left: .5rem;
  }

  .multiselect + span {
    margin-left: .5rem;
  }

  form .multiselect {
    display: inline-block;
    width: auto;
  }

  .multiselect-block {
    width: 100%;
    display: block;
  }

  .multiselect-block .multiselect {
    width: 100%;
  }

  .btn-bb-primary {
    margin-top: 1.5rem;
  }
</style>
