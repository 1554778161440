<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Add a Scheduled Email
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <label>What kind of email</label>
              <div class="kinds">
                <div :class="{'kind': true, 'active': kind === 'full'}" @click="kind = 'full'">
                  <div class="illustration full-design"></div> Full Design
                </div>
                <div :class="{'kind': true, 'active': kind === 'rich'}" @click="kind = 'rich'">
                  <div class="illustration rich-text"></div> Text Only
                </div>
              </div>

              <div class="form-group">
                <label for="name">Email Name</label>
                <input type="text"
                      name="name"
                      v-model="name"
                      placeholder="Email Name"
                      :class="{ 'form-control': true, 'is-invalid': $v.name.$error }"
                      @input="$v.name.$touch()" />
                <div class="invalid-feedback" v-if="!$v.name.required">A Email Name is required.</div>
                <div class="small text-muted">This is for internal use only. This is not your subject line.</div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-md-4">
                    <label for="date-picker">Date</label>
                    <input type="text"
                          name="date-picker"
                          id="date-picker"
                          v-model="date"
                          placeholder="Pick a date"
                          :class="{ 'form-control': true, 'is-invalid': $v.date.$error }"
                          @input="$v.date.$touch()" />
                  </div>
                  <div class="col-md-8">
                    <label for="time-picker">Time</label>
                    <div class="times">
                      <Multiselect v-model="time_hour" :options="[1,2,3,4,5,6,7,8,9,10,11,12]" placeholder="Hour" :searchable="true" :show-labels="false" :allow-empty="false" />
                      <Multiselect v-model="time_min" :options="['00','05','10','15','20','25','30','35','40','45','50','55']" placeholder="Minutes" :searchable="true" :show-labels="false" :allow-empty="false" />
                      <Multiselect v-model="time_ampm" :options="['AM','PM']" :searchable="true" :show-labels="false" :allow-empty="false" />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="submit" class="xpl-button xpl-button--primary" @click.stop="save" :disabled="!this.kind || $v.$error || $v.$invalid">Create</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import flatpickr from 'flatpickr';
  import { required } from 'vuelidate/lib/validators';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['scheduledPlanId'],
    components: {
      Multiselect,
    },
    data() {
      return {
        kind: null,
        name: '',
        date: null,
        time_hour: 6,
        time_min: '30',
        time_ampm: 'AM',
      };
    },
    validations: {
      name: {
        required,
      },
      date: {
        required,
      },
    },
    created() {
      this.$nextTick(() => {
        flatpickr('#date-picker', {
          dateFormat: 'Y-m-d',
          maxDate: '2027-01-01',
          minDate: new Date(),
        });
      });
    },
    methods: {
      closeModal() {
        EventBus.$emit('scheduled_modal.close');
      },
      save(event) {
        if (!this.name || !this.date || !this.time_hour ||
          !this.time_min || !this.time_ampm || !this.kind) {
          return;
        }

        const target = event.target || event.srcElement;
        target.disabled = true;

        const payload = {
          kind: this.kind,
          name: this.name,
          date: this.date,
          time: `${this.time_hour}:${this.time_min} ${this.time_ampm}`,
          plan_id: this.scheduledPlanId,
        };

        api.addScheduledEmail(payload).then((response) => {
          this.$toasted.success(response.message);
          this.$router.push(`/scheduled-emails/${response.scheduled_email.uuid}`);
        }).catch((err) => {
          target.disabled = false;
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="flatpickr/dist/flatpickr.min.css"></style>
<style lang="scss">
</style>
<style lang="scss" scoped>
  .form-control:disabled, .form-control[readonly] {
    background-color: white;
  }

  .kinds {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: 1.5rem;
  }

  .kinds .kind {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: .75rem;
    width: 50%;
    border-radius: 6px;
    background-color: #ffffff;
    border: 1px solid  #CDD7E2;
    margin-right: 1.5rem;
    font-size: .875rem;
    line-height: 1.25rem;
    color: #1d364b;
    cursor: pointer;
    transition: all 150ms ease-in-out;
  }

  .kinds .kind:last-child {
    margin-right: 0;
  }

  .kinds .kind:hover,
  .kinds .kind.active {
    border: 2px solid var(--xpl-color-secondary-lm);
    // box-shadow: 0 1px 0 0 rgba(100, 164, 210, 0.13);
  }

  .kinds .kind .illustration {
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    transition: all 150ms ease-in-out;
  }

  .kinds .kind .illustration.full-design {
    background-image: url('https://assets.brandbot.com/public/scheduled-emails/full-design-default.svg');
  }

  .kinds .kind:hover .illustration.full-design,
  .kinds .kind.active .illustration.full-design {
    background-image: url('https://assets.brandbot.com/public/scheduled-emails/full-design-hover.svg');
  }

  .kinds .kind .illustration.rich-text {
    background-image: url('https://assets.brandbot.com/public/scheduled-emails/rich-text-default.svg');
  }

  .kinds .kind:hover .illustration.rich-text,
  .kinds .kind.active .illustration.rich-text {
    background-image: url('https://assets.brandbot.com/public/scheduled-emails/rich-text-hover.svg');
  }

  a.helper {
    font-size: .75rem;
    font-weight: 700;
    color: #1d364b;
    text-decoration: underline;
  }

  a.helper:hover {
    text-decoration: none;
  }

  form {
    margin-top: .75rem;
  }

  .times {
    display: flex;
  }

  .times .multiselect {
    margin-right: .5rem;
  }
</style>
