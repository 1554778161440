<template>
  <el-form @submit.native.prevent>
    <div class="field" v-if="fieldName === 'country'">
      <label v-if="!shadow">{{ placeholder }}</label>
      <div>
        <el-select v-model="field" placeholder="Country" readonly style="width: 100%;">
          <el-option v-for="c in countries" :key="c.value" :label="c.label" :value="c.value"></el-option>
        </el-select>
      </div>
    </div>
    <div class="field" v-if="['country'].indexOf(fieldName) === -1">
      <label v-if="!shadow">{{ placeholder }}</label>
      <el-input v-model="field" :placeholder="placeholder" readonly />
    </div>
    <div v-if="include !== null">
      <el-switch active-text="Include" v-model="model" :disabled="disabled" @change="emitValue"></el-switch>
    </div>
  </el-form>
</template>
<script>
  import countries from '@/components/Auth/countries.json';
  import EventBus from '@/event-bus';

  export default {
    props: ['placeholder', 'disabled', 'include', 'fieldName', 'fieldIndex', 'shadow'],
    data() {
      return {
        field: null,
        countries,
        model: this.include,
      };
    },
    methods: {
      emitValue(value) {
        EventBus.$emit('forms.update.field', this.fieldIndex, value);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .field {
    width: 70%;
  }
</style>
