<template>
  <div>
    <div class="stats-header">
      <h1>All Time</h1>

      <form @submit.prevent>
        <div class="input-group">
          <span class="input-group-addon">
            <span class="icon icon-search"></span>
          </span>
          <input type="search" name="query" v-model.trim="query" class="form-control" placeholder="Search" />
        </div>
      </form>
    </div>
    <div class="datatable" v-if="(!loading && contacts.data && contacts.data.length) || query.length">
      <div class="actions" v-if="selectedContacts.length">
        <button type="button" class="xpl-button xpl-button--secondary xpl-button--warning" @click.stop="deleteFromCampaign">Remove from Campaign</button>
      </div>
      <table class="table bb-datatable">
        <thead>
          <tr>
            <td style="font-size: .825rem;">
              <span class="icon icon-checkbox-unchecked2" v-if="!select_all" @click="selectAll"></span>
              <span class="icon icon-checkbox-checked2" v-if="select_all" @click="selectAll"></span>
            </td>
            <td>Name</td>
            <td>Campaign Entry Date</td>
            <td>SMS Subscription</td>
            <td>Email Subscription</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="contact in contacts.data">
            <td>
              <span class="icon icon-checkbox-unchecked2" v-if="!contact.selected" @click="toggleContact(contact)"></span>
              <span class="icon icon-checkbox-checked2" v-if="contact.selected" @click="toggleContact(contact)"></span>
            </td>
            <td>
              <router-link :to="'/contacts/' + contact.uuid" target="_blank">
                {{ contact.first_name ? contact.first_name + ' ' + contact.last_name : contact.email }}
              </router-link>
            </td>
            <td>{{ contact.enrolled_at | humanDate }}</td>
            <td>{{ contact.unsubscribed_sms_at ? 'Unsubscribed' : 'Subscribed' }}</td>
            <td>{{ contact.unsubscribed_at ? 'Unsubscribed' : 'Subscribed' }}</td>
            <td>
              <a href="#" @click.prevent="show_contact_activity = true; selectedContact = contact;">See Activity</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pages">
        <div class="pages-flip">
          <div class="page-left" @click="previousPage()">
            <i class="fa fa-arrow-left"></i>
          </div>
          <div class="page-right" @click="nextPage()">
            <i class="fa fa-arrow-right"></i>
          </div>
        </div>
        Page {{ contacts.current_page }} of {{ contacts.last_page }}
      </div>
    </div>
    <ContactActivity v-if="show_contact_activity" :campaign="campaign" :contact="selectedContact" />
    <div class="illustration" v-if="!loading && contacts.data && !contacts.data.length && !query.length">
      <img src="https://assets.brandbot.com/public/empty-states/analytics-history.svg" />
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import _ from 'lodash';
  import ContactActivity from '../ContactActivity';

  export default {
    props: ['campaign'],
    components: {
      ContactActivity,
    },
    data() {
      return {
        loading: true,
        show_contact_activity: false,
        selectedContact: null,
        query: '',
        contacts: null,
        select_all: false,
      };
    },
    mounted() {
      EventBus.$on('contact_activity.sidebar.close', () => {
        this.show_contact_activity = false;
      });
    },
    created() {
      this.fetchContacts();
    },
    beforeDestroy() {
      EventBus.$off('contact_activity.sidebar.close');
    },
    watch: {
      query() {
        this.searchContacts();
      },
    },
    computed: {
      selectedContacts() {
        return this.contacts.data.filter(c => c.selected);
      },
    },
    methods: {
      selectAll() {
        this.contacts.data.forEach((c) => {
          if (!Object.prototype.hasOwnProperty.call(c, 'selected')) {
            this.$set(c, 'selected', !this.select_all);
          } else {
            c.selected = !this.select_all;
          }
        });

        this.select_all = !this.select_all;
      },
      toggleContact(contact) {
        if (!Object.prototype.hasOwnProperty.call(contact, 'selected')) {
          this.$set(contact, 'selected', true);
          return;
        }
        contact.selected = !contact.selected;
      },
      fetchContacts(page = 0, query = '') {
        api.historyContactsCampaign(this.campaign.id, page, query).then((response) => {
          this.contacts = response.contacts;
          this.loading = false;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      searchContacts: _.debounce(function debounce() {
        this.fetchContacts(0, this.query);
      }, 1000),
      previousPage() {
        if (this.contacts.current_page === 1) {
          return;
        }

        if (!this.contacts.prev_page_url) {
          return;
        }

        const page = this.contacts.prev_page_url.split('=')[1];
        this.fetchContacts(page);
      },
      nextPage() {
        if (this.contacts.current_page === this.contacts.last_page) {
          return;
        }

        if (!this.contacts.next_page_url) {
          return;
        }

        const page = this.contacts.next_page_url.split('=')[1];
        this.fetchContacts(page);
      },
      deleteFromCampaign() {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to delete these contacts from the campaign? This is irreversible.')) {
          return;
        }

        const payload = {
          contact_ids: this.selectedContacts.map(c => c.id),
        };

        api.deleteContactsFromCampaign(this.campaign.id, payload).then((response) => {
          this.$toasted.success(response.message);
          this.fetchContacts();
          this.select_all = false;
          EventBus.$emit('campaign_item.refresh');
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .stats-header {
    display: flex;
    justify-content: space-between;
  }

  h1 {
    font-size: 1.25rem;
    color: rgb(33, 43, 54);
  }

  .datatable {
    margin-top: 2rem;
  }

  .illustration img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 10rem;
    margin-bottom: 2rem;
  }
</style>
