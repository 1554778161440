<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner" v-if="!enrollment">
      Time Frame
    </div>
    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'absolute'" @click="timeframe = 'absolute'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'absolute'" @click="timeframe = 'absolute'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_absolute" @click="timeframe = 'absolute'; comparison = null;">
              Visits around specific dates
            </label>
            <div class="small text-muted">Select before, after, or between static dates. e.g. After July 27th 2018</div>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="timeframe === 'absolute'">
        <div class="form-inline">
          <label>These visits occured</label>&nbsp;
          <Multiselect v-model="comparison" placeholder="Select a comparison" :options="comparisons_date" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" style="max-width: 13rem; min-width: 13rem; width: 13rem;" />
        </div>

        <div class="dates" v-if="comparison && timeframe === 'absolute'">
          <div class="date">
            <vue-datepicker-local v-model="dates.start" :local="local" format="MM-DD-YYYY" />
          </div>
          <span v-if="comparison.value === 'between'">&nbsp;and&nbsp;</span>
          <div class="date" v-if="comparison.value === 'between'">
            <vue-datepicker-local v-model="dates.end" :local="local" format="MM-DD-YYYY" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'relative'" @click="timeframe = 'relative'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'relative'" @click="timeframe = 'relative'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_relative" @click="timeframe = 'relative'; comparison = null;">
              Visits relative to Today
            </label>
            <small class="form-text text-muted">Select at least, less than, or exactly a number of days ago. This is a moving window of time. e.g. Within the last 10 days.</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="timeframe === 'relative'">
        <div class="form-inline">
          Every contact who visited for first time
          <Multiselect v-model="comparison" placeholder="Select a comparison" :options="comparisons_number" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" style="max-width: 13rem; min-width: 13rem; width: 13rem;" />&nbsp;
          <input type="number" name="relative_days" v-model.number="relative_days" class="form-control" min="0" style="display: inline-block; width: 5rem;" :disabled="!comparison" />&nbsp;
          days<span v-if="comparison && comparison.value !== 'within'">&nbsp;ago</span>.
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'all'" @click="timeframe = 'all'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'all'" @click="timeframe = 'all'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label @click="timeframe = 'all'; comparison = null;">
              All Time
            </label>
            <div class="small text-muted">See if the contact has met this condition in their entire lifetime</div>
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Qualifiers
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-checkbox-unchecked2" v-if="!filter_pricing_options" @click="filter_pricing_options = !filter_pricing_options"></span>
            <span class="icon icon-checkbox-checked2" v-if="filter_pricing_options" @click="filter_pricing_options = !filter_pricing_options"></span>
          </div>
          <div class="col-xs-10">
            <label @click="filter_pricing_options = !filter_pricing_options">
              First Visit with specific Pricing Options
            </label>
            <small class="form-text text-muted">Isolates the first time a client visited with this specific type of purchase.</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="filter_pricing_options">
        <div class="form-group">
          <label for="pricing_options">Only include first visits with these pricing option(s)</label>
          <div class="multiselect-block">
            <Multiselect v-model="selected_pricing_options" placeholder="Select Pricing Option(s)" :options="pricing_options" label="name" track-by="id" :searchable="true" :allow-empty="true" :show-labels="false" :multiple="true" :close-on-select="false" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-checkbox-unchecked2" v-if="!filter_classes" @click="filter_classes = !filter_classes"></span>
            <span class="icon icon-checkbox-checked2" v-if="filter_classes" @click="filter_classes = !filter_classes"></span>
          </div>
          <div class="col-xs-10">
            <label @click="filter_classes = !filter_classes">
              First Visit with specific type of class
            </label>
            <small class="form-text text-muted">Isolates the first time a client purchases this specific type of purchase.</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="filter_classes">
        <div class="form-group">
          <label for="pricing_options">Only include first visits with classes</label>
          <div class="multiselect-block">
            <Multiselect v-model="selected_classes" placeholder="Select Classes" :options="classes" label="name" track-by="name" :searchable="true" :allow-empty="true" :show-labels="false" :multiple="true" :close-on-select="false" />
          </div>
        </div>
      </div>
    </form>

      <div class="condition-banner">
        Location and Site ID (Optional)
      </div>

      <form @submit.prevent>
        <div class="form-group">
          <div class="row">
            <div class="col-xs-2">
              <span class="icon icon-radio-unchecked" v-if="misc_filtering === null || misc_filtering === true" @click="misc_filtering = false"></span>
              <span class="icon icon-radio-checked" v-if="misc_filtering === false" @click="misc_filtering = false"></span>
            </div>
            <div class="col-xs-10">
              <label for="misc_filtering_no" @click="misc_filtering = false">
                Select all locations and Site IDs
              </label>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-xs-2">
              <span class="icon icon-radio-unchecked" v-if="misc_filtering === null || misc_filtering === false" @click="misc_filtering = true"></span>
              <span class="icon icon-radio-checked" v-if="misc_filtering === true" @click="misc_filtering = true"></span>
            </div>
            <div class="col-xs-10">
              <label for="misc_filtering_yes" @click="misc_filtering = true">
                 Select specific locations or Site IDs
              </label>
            </div>
          </div>
        </div>

        <div class="options-inner" v-if="misc_filtering">
          <div class="form-group">
            <label class="form-control-label">Location</label>
            <div class="multiselect-block">
              <Multiselect v-model="location" placeholder="All Locations" label="location_name" track-by="location_id" :options="locations" :searchable="false" :allow-empty="true" :show-labels="false" />
            </div>
          </div>
        </div>
      </form>

      <div class="form-group">
        <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition">Save this condition</button>
      </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';
  import VueDatepickerLocal from 'vue-datepicker-local';

  export default {
    props: ['locations', 'condition', 'editedRule', 'enrollment'],
    components: {
      Multiselect,
      VueDatepickerLocal,
    },
    data() {
      return {
        misc_filtering: false,
        location: null,
        relative_days: 0,
        timeframe: 'all',
        comparison: null,
        comparisons_date: [{
          label: 'After',
          value: 'after',
        }, {
          label: 'Before',
          value: 'before',
        }, {
          label: 'Between',
          value: 'between',
        }],
        comparisons_number: [{
          label: 'At least',
          value: 'more',
        }, {
          label: 'Within the last',
          value: 'within',
        }, {
          label: 'Exactly',
          value: 'exactly',
        }],
        dates: {
          start: '',
          end: '',
        },
        local: {
          dow: 1,
          hourTip: '',
          minuteTip: '',
          secondTip: '',
          yearSuffix: '',
          monthsHead: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
          months: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
          weeks: 'Mon_Tue_Wed_Thu_Fri_Sat_Sun'.split('_'),
        },
        filter_pricing_options: false,
        filter_classes: false,
        first_visit_type: null,
        selected_pricing_options: [],
        pricing_options: [],
        selected_classes: [],
        classes: [],
      };
    },
    created() {
      this.fetchPricingOptions();
      this.fetchClasses();

      if (this.editedRule) {
        this.timeframe = this.editedRule.timeframe;
        this.relative_days = this.editedRule.relative_days;

        if (this.timeframe === 'relative') {
          this.comparison = this.comparisons_number.filter(c =>
            c.value === this.editedRule.comparison,
          )[0];
        }
        if (this.timeframe === 'absolute') {
          this.comparison = this.comparisons_date.filter(c =>
            c.value === this.editedRule.comparison,
          )[0];
        }

        this.dates.start = this.editedRule.start_date;
        this.dates.end = this.editedRule.end_date;
        this.filter_pricing_options = this.editedRule.filter_pricing_options;
        this.filter_classes = this.editedRule.filter_classes;
        this.selected_pricing_options = this.editedRule.pricing_options;
        this.selected_classes = this.editedRule.classes;

        this.misc_filtering = this.editedRule.misc.filtering;
        if (this.misc_filtering) {
          this.location = this.locations.filter(l =>
            l.location_id === this.editedRule.misc.location,
          )[0];
        }
      }
    },
    methods: {
      fetchPricingOptions() {
        api.getPricingOptions().then((response) => {
          this.pricing_options = response.pricing_options;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchClasses() {
        api.getClasses().then((response) => {
          this.classes = response.classes;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          timeframe: this.timeframe,
          relative_days: this.relative_days,
          comparison: this.comparison ? this.comparison.value : null,
          start_date: this.dates.start,
          end_date: this.dates.end,
          filter_pricing_options: this.filter_pricing_options,
          filter_classes: this.filter_classes,
          pricing_options: this.selected_pricing_options,
          classes: this.selected_classes,
          misc: {
            filtering: this.misc_filtering,
            location: this.location ? this.location.location_id : null,
            site_id: null,
          },
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked,
  .icon-checkbox-checked2,
  .icon-checkbox-unchecked2 {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }
</style>

