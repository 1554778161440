<template>
  <div class="metrics" v-if="stats">
    <div class="sent">
      <span class="number">{{ stats.sent | prettyNumber }}</span>
      Sent
    </div>
    <div class="opens">
      <span class="number">{{ stats.opened_unique | prettyNumber }}</span>
      Opens
    </div>
    <div class="clicks">
      <span class="number">{{ stats.clicked_unique | prettyNumber }}</span>
      Clicked
    </div>
  </div>
</template>
<script>
  import * as api from '@/api';

  export default {
    props: ['typeUuid'],
    data() {
      return {
        stats: null,
      };
    },
    created() {
      api.emailPerformance(this.typeUuid).then((response) => {
        this.stats = response.stats;
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
  };
</script>
<style lang="scss" scoped>
  .metrics {
    margin-left: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: .75rem;
    line-height: 1.25;
    color: #868e96;
    text-align: right;
    margin-right: 1rem;
  }

  .metrics .number {
    text-align: right;
    font-size: 1.125rem;
    line-height: 1;
    color: #4c4f52;
    display: block;
    margin-bottom: .5rem;
  }

  .metrics .sent {
    margin-right: .75rem;
    min-width: 4rem;
  }

  .metrics .opens {
    margin-right: .75rem;
    min-width: 4rem;
  }

  .metrics .clicks {
    min-width: 4rem;
  }
</style>
