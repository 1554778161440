<template>
  <div>
    <BuildTextBlock v-if="currentElement.tagName === 'mj-text'" :currentElement="currentElement" />
    <BuildButtonBlock v-if="currentElement.tagName === 'mj-button'" :currentElement="currentElement" />
    <BuildImageBlock v-if="currentElement.tagName === 'mj-image'" :currentElement="currentElement" />
    <BuildWrapperBlock v-if="currentElement.tagName === 'mj-wrapper'" :currentElement="currentElement" />
    <BuildDividerBlock v-if="currentElement.tagName === 'mj-divider' && currentElement.attributes['css-class'].indexOf('mj-spacer') === -1" :currentElement="currentElement" />
    <BuildSpacerBlock v-if="currentElement.tagName === 'mj-divider' && currentElement.attributes['css-class'].indexOf('mj-spacer') > -1" :currentElement="currentElement" />
    <BuildSocialBlock v-if="currentElement.tagName === 'mj-social'" :currentElement="currentElement" />
  </div>
</template>
<script>
  import BuildTextBlock from '@/components/FullEmailEditor/EditBlocks/BuildTextBlock';
  import BuildButtonBlock from '@/components/FullEmailEditor/EditBlocks/BuildButtonBlock';
  import BuildImageBlock from '@/components/FullEmailEditor/EditBlocks/BuildImageBlock';
  import BuildWrapperBlock from '@/components/FullEmailEditor/EditBlocks/BuildWrapperBlock';
  import BuildDividerBlock from '@/components/FullEmailEditor/EditBlocks/BuildDividerBlock';
  import BuildSpacerBlock from '@/components/FullEmailEditor/EditBlocks/BuildSpacerBlock';
  import BuildSocialBlock from '@/components/FullEmailEditor/EditBlocks/BuildSocialBlock';

  export default {
    props: ['currentElement'],
    components: {
      BuildTextBlock,
      BuildButtonBlock,
      BuildImageBlock,
      BuildWrapperBlock,
      BuildDividerBlock,
      BuildSpacerBlock,
      BuildSocialBlock,
    },
    data() {
      return {};
    },
    filters: {
      blockName(name) {
        if (name === 'mj-text') {
          return 'Text Block';
        }

        return name;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .block-name {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color: rgb(0, 21, 42);
  }
</style>
