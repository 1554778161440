<template>
  <div class="sidebar-inner" style="width: 100%;">
    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">1</span>
      </el-col>
      <el-col :span="22" class="v2__heading">What visit statuses would you like to filter on?</el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row>
        <el-col :offset="2" :span="22">
          <el-select v-model="visit_statuses" filterable multiple collapse-tags placeholder="Select Statuses" style="width: 100%;">
            <el-option v-for="status in statuses" :key="status.value" :label="status.label" :value="status.value"></el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>

    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">2</span>
      </el-col>
      <el-col :span="22" class="v2__heading">How many visits have the contacts completed?</el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-radio-group v-model="visit_comparison">
              <el-radio-button label="more">At least</el-radio-button>
              <el-radio-button label="less">Less than</el-radio-button>
              <el-radio-button label="exactly">Exactly</el-radio-button>
              <el-radio-button label="between">Between</el-radio-button>
            </el-radio-group>
            <div style="margin-top: 1rem;" v-if="visit_comparison">
              <div v-if="visit_comparison && visit_comparison !== 'between'">
                <el-input-number v-model="visit_number_start" :min="0" ></el-input-number>
              </div>
              <div v-if="visit_comparison && visit_comparison === 'between'">
                <el-input-number v-model="visit_number_start" :min="0" ></el-input-number>
                and
                <el-input-number v-model="visit_number_end" :min="0" ></el-input-number>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      </div>

    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">3</span>
      </el-col>
      <el-col :span="22" class="v2__heading">During what timeframe did the contacts complete their visits?</el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2" :span="22">
          <el-radio-group v-model="timeframe">
            <el-radio label="all" border>All Time</el-radio>
            <el-radio label="absolute" border>Around static date(s)</el-radio>
            <el-radio label="relative" border>A relative time frame</el-radio>
            <el-radio label="entering_campaign" border v-if="goalList">Since entering campaign</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row v-if="timeframe === 'absolute'">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-radio-group v-model="time_comparison">
              <el-radio-button label="before">Before</el-radio-button>
              <el-radio-button label="between">Between</el-radio-button>
              <el-radio-button label="after">After</el-radio-button>
            </el-radio-group>

            <div class="block" v-if="time_comparison && time_comparison !== 'between'">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="dates"
                type="date"
                placeholder="Pick a date">
              </el-date-picker>
            </div>

            <div class="block" v-if="time_comparison && time_comparison === 'between'">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="dates"
                type="daterange"
                range-separator="-"
                start-placeholder="Start"
                end-placeholder="End">
              </el-date-picker>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row v-if="timeframe === 'relative'">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-radio-group v-model="time_comparison">
              <el-radio-button label="more">At least</el-radio-button>
              <el-radio-button label="within">Within the last</el-radio-button>
              <el-radio-button label="exactly">Exactly</el-radio-button>
              <el-radio-button label="future">In the next</el-radio-button>
            </el-radio-group>
            <div class="block" style="display: flex; align-items: center;">
              <el-input-number :min="0" v-model="relative_days"></el-input-number>
              &nbsp;
              days<span v-if="time_comparison && time_comparison !== 'within' && time_comparison !== 'future'">&nbsp;ago</span>.
            </div>
          </div>
        </el-col>
      </el-row>
      </div>

    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">4</span>
      </el-col>
      <el-col :span="22" class="v2__heading">Do you want to filter on visits with specific Staff Members?</el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2">
          <el-radio-group v-model="filter_staff">
            <el-radio :label="true" border>Yes</el-radio>
            <el-radio :label="false" border @click.native="selected_staff = []">No</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row v-if="filter_staff">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-form>
              <label>Staff Members</label>
              <el-form-item>
                <el-select v-model="selected_staff" multiple filterable style="width: 100%;" multiple placeholder="Staff Members">
                  <el-option v-for="staff in staffes" :key="staff.id" :label="staff.name" :value="staff.name"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">5</span>
      </el-col>
      <el-col :span="22" class="v2__heading">Do you want to filter on visits with specific Payments Options?</el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2">
          <el-radio-group v-model="filter_payment_options">
            <el-radio :label="true" border>Yes</el-radio>
            <el-radio :label="false" border @click.native="selected_payments = []; invert_payment = false;">No</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row v-if="filter_payment_options">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-form>
              <label>Payment Options</label>
              <el-form-item>
                <el-select v-model="selected_payments" multiple filterable style="width: 100%;" placeholder="Payment Options">
                  <el-option v-for="payment in payment_options" :key="payment.name" :label="payment.name" :value="payment.name"></el-option>
                </el-select>
                <el-switch v-model="invert_payment" active-text="Filter on everything but the selected Payment Options" />
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">6</span>
      </el-col>
      <el-col :span="22" class="v2__heading">Do you want to filter on visits with specific Service Names?</el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2">
          <el-radio-group v-model="filter_service_names">
            <el-radio :label="true" border>Yes</el-radio>
            <el-radio :label="false" border @click.native="selected_service_names = []; invert_services = false;">No</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row v-if="filter_service_names">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-form>
              <label>Service Names</label>
              <el-form-item>
                <el-select v-model="selected_service_names" multiple filterable style="width: 100%;" placeholder="Service Names">
                  <el-option v-for="service in service_names" :key="service.name" :label="service.name" :value="service.name"></el-option>
                </el-select>
                <el-switch v-model="invert_services" active-text="Filter on everything but the selected Services Names" />
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">7</span>
      </el-col>
      <el-col :span="22" class="v2__heading">Do you want to filter on visits with specific Events?</el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2">
          <el-radio-group v-model="filter_events">
            <el-radio :label="true" border>Yes</el-radio>
            <el-radio :label="false" border @click.native="selected_events = []; invert_events = false;">No</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row v-if="filter_events">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-form>
              <label>Events</label>
              <el-form-item>
                <el-select v-model="selected_events" multiple filterable style="width: 100%;" placeholder="Events">
                  <el-option v-for="event in events" :key="event.name" :label="event.name" :value="event.id"></el-option>
                </el-select>
                <el-switch v-model="invert_events" active-text="Filter on everything but the selected Events" />
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">8</span>
      </el-col>
      <el-col :span="22" class="v2__heading">Do you want to filter on visits at specific Locations?</el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2">
          <el-radio-group v-model="filter_locations">
            <el-radio :label="true" border>Yes</el-radio>
            <el-radio :label="false" border @click.native="selected_locations = []">No</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row v-if="filter_locations">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-form>
              <label>Locations</label>
              <el-form-item>
                <el-select v-model="selected_locations" multiple filterable style="width: 100%;" placeholder="Locations">
                  <el-option v-for="location in locations" :key="location.id" :label="location.name" :value="location.id"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <button type="button" class="xpl-button xpl-button--primary" @click.stop="commitCondition">Save this condition</button>
      </div>
    </form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['condition', 'editedRule', 'enrollment', 'goalList'],
    data() {
      return {
        visit_statuses: ['completed'],
        statuses: [{
          label: 'Completed',
          value: 'completed',
        }, {
          label: 'Expired',
          value: 'expired',
        }, {
          label: 'Late Canceled',
          value: 'late_canceled',
        }, {
          label: 'No Showed',
          value: 'noshowed',
        }, {
          label: 'Registered',
          value: 'registered',
        }, {
          label: 'Removed',
          value: 'removed',
        }, {
          label: 'Reserved',
          value: 'reserved',
        }, {
          label: 'Waiting',
          value: 'waiting',
        }],
        timeframe: null,
        visit_number_start: null,
        visit_number_end: null,
        visit_comparison: 'more',
        time_comparison: 'more',
        relative_days: null,
        dates: null,
        filter_staff: false,
        filter_payment_options: false,
        filter_service_names: false,
        filter_events: false,
        filter_locations: false,
        staffes: [],
        selected_staff: [],
        payment_options: [],
        selected_payments: [],
        service_names: [],
        selected_service_names: [],
        events: [],
        selected_events: [],
        locations: [],
        selected_locations: [],
        invert_payment: false,
        invert_services: false,
        invert_events: false,
      };
    },
    created() {
      this.fetchStaffMembers();
      this.fetchPaymentOptions();
      this.fetchServiceNames();
      this.fetchEvents();
      this.fetchLocations();

      if (this.editedRule) {
        this.timeframe = this.editedRule.timeframe;
        this.visit_number_start = this.editedRule.visit_number_start;
        this.visit_number_end = this.editedRule.visit_number_end;
        this.visit_comparison = this.editedRule.visit_comparison;
        this.time_comparison = this.editedRule.time_comparison;
        this.relative_days = this.editedRule.relative_days;

        if (this.editedRule.end_date) {
          this.dates = [this.editedRule.start_date, this.editedRule.end_date];
        } else {
          this.dates = this.editedRule.start_date;
        }

        if (this.editedRule.staffes.length) {
          this.filter_staff = true;
        }

        if (this.editedRule.payment_options.length) {
          this.filter_payment_options = true;
        }

        if (this.editedRule.service_names.length) {
          this.filter_service_names = true;
        }

        if (this.editedRule.events.length) {
          this.filter_events = true;
        }

        if (this.editedRule.locations.length) {
          this.filter_locations = true;
        }

        this.selected_staff = this.editedRule.staffes;
        this.selected_payments = this.editedRule.payment_options;
        this.selected_service_names = this.editedRule.service_names;
        this.selected_events = this.editedRule.events;
        this.selected_locations = this.editedRule.locations;
        this.visit_statuses = this.editedRule.visit_statuses;

        this.invert_payment = this.editedRule.invert_payment;
        this.invert_services = this.editedRule.invert_services;
        this.invert_events = this.editedRule.invert_events;
      }
    },
    methods: {
      fetchStaffMembers() {
        api.getStaffMembers().then((response) => {
          this.staffes = response.staffes;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchPaymentOptions() {
        api.getPaymentOptions().then((response) => {
          this.payment_options = response.payment_options;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchServiceNames() {
        api.getServiceNames().then((response) => {
          this.service_names = response.service_names;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchEvents() {
        api.getPikeEvents().then((response) => {
          this.events = response.events;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchLocations() {
        api.getPikeServiceLocations().then((response) => {
          this.locations = response.locations;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          visit_statuses: this.visit_statuses,
          timeframe: this.timeframe,
          visit_number_start: this.visit_number_start,
          visit_number_end: this.visit_number_end,
          visit_comparison: this.visit_comparison,
          time_comparison: this.time_comparison,
          start_date: Array.isArray(this.dates) ? this.dates[0] : this.dates,
          end_date: Array.isArray(this.dates) ? this.dates[1] : null,
          relative_days: this.relative_days,
          staffes: this.selected_staff,
          payment_options: this.selected_payments,
          service_names: this.selected_service_names,
          events: this.selected_events,
          locations: this.selected_locations,
          invert_payment: this.invert_payment,
          invert_services: this.invert_services,
          invert_events: this.invert_events,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .options {
    padding: 1.25rem;
    background-color: #f5f8fb;
  }
</style>
