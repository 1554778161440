<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Email {{ to }}
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <el-form @submit.prevent>
              <el-form-item>
                <label>From</label>
                <el-select filterable v-model="from" placeholder="Select a Verified Email" style="width: 100%;" value-key="uuid">
                  <el-option v-for="email in verified_emails" :label="email.email" :value="email" :key="email.uuid"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <label>Subject</label>
                <el-input v-model="subject" placeholder="Subject" />
              </el-form-item>
              <el-form-item>
                <label>Use a Template</label>
                <el-select filterable v-model="template" placeholder="Use a Template" style="width: 100%;" @change="seedTemplate" value-key="id">
                  <el-option v-for="t in templates" :label="t.name" :value="t" :key="t.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <label>Email</label>
                <quill-editor style="width: 100%;" v-model="body" ref="myQuillEditor" :options="editorOption" @click.stop></quill-editor>
              </el-form-item>
            </el-form>
          </div>
          <div class="modal-footer">
            <span></span>
            <el-button type="primary" class="xpl-button xpl-button--primary" @click="sendEmail" :disabled="!subject || !body || !from">Send</el-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import { quillEditor } from 'vue-quill-editor';
  import * as api from '@/api';
  import EventBus from '@/event-bus';

  export default {
    props: ['to', 'to_id'],
    components: {
      quillEditor,
    },
    data() {
      return {
        from: '',
        subject: '',
        body: '',
        template: null,
        templates: [],
        verified_emails: [],
        editorOption: {
          placeholder: 'Add your Email Content here...',
          modules: {
            toolbar: ['bold', 'italic', 'underline', 'link'],
          },
        },
      };
    },
    mounted() {
      this.fillVerifiedEmails();
      this.fetchTemplates();
    },
    methods: {
      seedTemplate(t) {
        this.body = t.body;
      },
      fetchTemplates() {
        api.getEmailTemplatesSaved('rich').then((response) => {
          this.templates = response.templates;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      closeModal() {
        EventBus.$emit('contact_detail.send_email.close');
      },
      fillVerifiedEmails() {
        api.getVerifiedEmails().then((response) => {
          this.verified_emails = response.emails;
        }).catch((err) => {
          this.$message.error(err);
        });
      },
      sendEmail() {
        if (!this.from.uuid) {
          return;
        }

        const payload = {
          verified_email_id: this.from.uuid,
          subject: this.subject,
          body: this.body,
        };

        api.sendOneOffEmail(this.to_id, payload).then((response) => {
          this.$message.success(response.message);
          EventBus.$emit('contact_detail.send_email.close');
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal-dialog {
    max-width: 600px;
  }

  .modal .modal-content .modal-body {
    overflow-y: auto;
  }
</style>
