<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            New Tag
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <el-input type="text" v-model="name" placeholder="Name" @keyup.native.enter="createTag" />
            <el-checkbox v-model="franchise" label="Copy this tag to all Franchisees Accounts" v-if="$store.state.user.current_account.franchise" />
          </div>
          <div class="modal-footer">
            <span></span>
            <el-button type="primary" class="xpl-button xpl-button--primary"ref="create" :disabled="!name || name.length < 2" @click.prevent="createTag" :loading="loading">Create</el-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    data() {
      return {
        loading: false,
        name: '',
        franchise: false,
      };
    },
    methods: {
      closeModal() {
        EventBus.$emit('new_tag_modal.close');
      },
      createTag() {
        const payload = {
          name: this.name,
          franchise: this.franchise,
        };

        this.loading = true;
        api.addCustomTag(payload).then((response) => {
          this.loading = false;
          this.$toasted.success(response.message);
          this.closeModal();
        }).catch((err) => {
          this.loading = false;
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
