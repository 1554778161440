<template>
  <el-card shadow="never">
    <h1>Company Address</h1>
    <el-form ref="addressForm" :model="address" :rules="addressRules" status-icon @submit.native.prevent>
      <el-form-item prop="address_1">
        <label for="address_1">Address Line 1</label>
        <el-input id="address_1" v-model="address.address_1" placeholder="Address Line 1" />
      </el-form-item>
      <el-form-item prop="address_2">
        <label for="address_2">Address Line 2</label>
        <el-input id="address_2" v-model="address.address_2" placeholder="Address Line 2" />
      </el-form-item>
      <el-form-item prop="city">
        <label for="city">City</label>
        <el-input id="city" v-model="address.city" placeholder="City" />
      </el-form-item>
      <el-row>
        <el-col :span="11">
          <el-form-item prop="state">
            <label for="state">State / Province</label>
            <el-input id="state" v-model="address.state" placeholder="State" />
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="1">
          <el-form-item prop="zip_code">
            <label for="zip_code">Zip Code / Postal Code</label>
            <el-input id="zip_code" v-model="address.zip_code" placeholder="Zip Code / Postal Code" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item prop="country">
        <label for="country">Country</label>
        <div>
          <el-select v-model="address.country" filterable placeholder="Country" style="width: 100%;">
            <el-option v-for="item in countries" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item style="text-align: center; margin-top: 1.5rem;">
        <el-button type="primary" class="xpl-button xpl-button--primary":loading="loading" @click.stop="updateAddress">Continue</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import countries from '../countries.json';

  export default {
    data() {
      return {
        loading: false,
        countries,
        addressRules: {
          address_1: [{ required: true, trigger: 'change', message: 'Your address is required' }],
          city: [{ required: true, trigger: 'change', message: 'Your city is required' }],
          state: [{ required: true, trigger: 'change', message: 'Your state is required' }],
          zip_code: [{ required: true, trigger: 'change', message: 'Your zip code or province is required' }],
          country: [{ required: true, trigger: 'change', message: 'Your country is required' }],
        },
        address: {
          address_1: '',
          address_2: '',
          city: '',
          state: '',
          zip_code: '',
          country: '',
        },
      };
    },
    created() {
      const account = this.$store.state.user.current_account;
      // eslint-disable-next-line
      for (const key in this.address) {
        if (account[key]) {
          this.$set(this.address, key, account[key]);
        }
      }
    },
    methods: {
      updateAddress() {
        this.$refs.addressForm.validate((valid) => {
          if (!valid) {
            return;
          }

          const accountId = this.$store.state.user.current_account.id;

          this.loading = true;
          api.updateAccount(accountId, this.address).then(() => {
            this.loading = false;
            this.$store.dispatch('refreshUser').then(() => {
              EventBus.$emit('signup.address.done');
            });
          }).catch((err) => {
            this.loading = false;
            if (err.errors) {
              this.$message.error(err.errors[Object.keys(err.errors)[0]][0]);
            } else {
              this.$message
               .error('We were unable to update your account. Please try again later.');
            }
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-card {
    max-width: 500px;
    width: 500px;
    min-width: 500px;
    border: none;
  }

  .el-card h1 {
    font-size: 1.75rem;
    color: #00152a;
    margin-bottom: 2.5rem;
  }

  .el-form-item {
    margin-bottom: 1rem;
  }
</style>
