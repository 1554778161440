<template>
  <div :class="{ 'scheduled-email': true, 'editable': design.email }" v-if="scheduled_email">
    <TemplateSelection :scheduledEmail="scheduled_email" :type="scheduled_email.type" v-if="design.template" />
    <EditEmail :scheduledEmail="scheduled_email" :emailId="scheduled_email.email_id" v-if="design.email" />
    <div class="" v-if="!design.template && !design.email">
      <div class="header">
        <a href="#" class="go-back" @click.prevent="goBack"><span class="icon icon-arrow-left3"></span> Go Back</a>
        <input type="text" autocomplete="off" v-model.trim="scheduled_email.name" @keyup.enter="editName" />
        <div style="margin-left: auto;" v-if="!scheduled_email.locked">
          <button type="button" class="xpl-button xpl-button--secondary delete" @click="deleteScheduledEmail"><span class="material-icons">delete</span></button>
        </div>
      </div>
      <div class="container">
        <div class="sidebar">
          <div class="scheduled" v-if="!scheduled_email.scheduled" @click.stop="change_date_modal = true">
            <span>
              <span class="material-icons">event</span>
            </span>
            Scheduled for {{ scheduled_email.scheduled_at | dateTime }}
          </div>
          <div class="scheduled" style="cursor: initial;" @click.stop="$toasted.error('Please set back to in progress to change the scheduled date.')" v-if="scheduled_email.scheduled && !scheduled_email.sent">
            <span>
              <span class="material-icons">event</span>
            </span>
            Scheduled for {{ scheduled_email.scheduled_at | dateTime }}
          </div>
          <div class="scheduled" style="cursor: initial;" v-if="scheduled_email.scheduled && scheduled_email.sent">
            <span>
              <span class="material-icons">event</span>
            </span>
            Sent at {{ scheduled_email.scheduled_at | dateTime }}
          </div>

          <div class="assigned" v-if="!scheduled_email.assigned_user_id">
            <span class="circle empty" @click.stop="assign_modal = true"></span>
            <span @click.stop="assign_modal = true">No assignment</span>
          </div>
          <div :class="{ assigned: true, active: scheduled_email.assigned_user.first_name && scheduled_email.assigned_user.last_name }" v-if="scheduled_email.assigned_user_id">
            <span class="user-badge" v-if="scheduled_email.assigned_user.first_name && scheduled_email.assigned_user.last_name" @click.stop="assign_modal = true">
              {{ scheduled_email.assigned_user.first_name.slice(0,1) }}{{ scheduled_email.assigned_user.last_name.slice(0,1) }}
            </span>
            <span class="user-badge" @click.stop="assign_modal = true" v-else>
              {{ scheduled_email.assigned_user.email.slice(0,2) }}
            </span>
            <span @click.stop="assign_modal = true">{{ scheduled_email.assigned_user.first_name ? scheduled_email.assigned_user.first_name + ' ' + scheduled_email.assigned_user.last_name : scheduled_email.assigned_user.email }}</span>
            <span class="icon icon-cross3 remove-assigned" @click.stop="removeAssigned"></span>
          </div>
          <div class="plan" v-if="!scheduled_email.scheduled_plan">
            <span class="circle empty"></span>
            No Scheduled Plan
          </div>
          <div class="plan active" v-if="scheduled_email.scheduled_plan">
            <span class="material-icons">view_list</span>
            {{ scheduled_email.scheduled_plan.name }}
            <span class="icon icon-cross3 remove-assigned" @click.stop="removePlan"></span>
          </div>
          <div class="comments">
            <img class="no-comments" src="https://assets.brandbot.com/public/empty-states/comment-section.svg" v-if="!comments.length" />
            <div class="comment" v-for="comment in comments">
              <div class="user-badge" :aria-label="comment.author.first_name ? comment.author.first_name + ' ' + comment.author.last_name : comment.author.email">{{ comment.author.first_name && comment.author.last_name ? comment.author.first_name.slice(0,1) + comment.author.last_name.slice(0,1) : comment.author.email.slice(0,2) }}</div>
              <div>
                {{ comment.body }}
                <div class="small text-muted">
                  {{ comment.created_at | humanDateTime }}
                  <span v-if="$store.state.user.id === comment.user_id">&middot;</span>
                  <a href="#" @click.stop="deleteComment(comment.uuid)" class="text-muted" v-if="$store.state.user.id === comment.user_id">Delete</a>
                </div>
              </div>
            </div>
          </div>
          <div class="comments-form">
            <div class="user-badge">{{ $store.state.user.initials }}</div>
            <form>
              <textarea placeholder="Add a comment" class="form-control" v-model="comment" @focus="expand($event)" @focusout="shrink($event)" rows="1" style="resize: none;"></textarea>
              <button type="button" ref="post_comment" class="xpl-button xpl-button--primary" @click="postComment" :disabled="!comment" hidden>Comment</button>
            </form>
          </div>
        </div>
        <div class="content" v-if="!scheduled_email.email_id">
          <CorporateScheduledEmail v-if="scheduled_email.corporate_push" :scheduled_email="scheduled_email" />
          <img src="https://assets.brandbot.com/public/empty-states/email.svg" class="illustration" />
          <p class="empty">Your email is just a date and a time.<br>Give it some life by designing it!</p>
          <button type="button" class="xpl-button xpl-button--primary" @click="designEmail">Design this Email</button>
        </div>
        <div class="content email" v-if="scheduled_email.email">

          <div class="card overdue" v-if="scheduled_email.incomplete && !scheduled_email.scheduled && !scheduled_email.sent && scheduled_email.overdue">
            <div class="card-header card-header-merged"></div>
            <div class="card-body">
              <div>
                <span class="material-icons">error_outline</span>
              </div>
              <div>
                <h6>This email is overdue</h6>
                <p class="text-muted">This email was not saved as "Ready to be Sent" before its scheduled date and time. To send it, you first have to reschedule it.</p>
              </div>
              <div class="button" v-if="!scheduled_email.locked">
                <button class="xpl-button xpl-button--secondary" @click.stop="change_date_modal = true">Reschedule</button>
              </div>
            </div>
          </div>

          <div class="card ready" v-if="!scheduled_email.incomplete && scheduled_email.scheduled && !scheduled_email.sent">
            <div class="card-header card-header-merged"></div>
            <div class="card-body">
              <div>
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/scheduled.svg" />
              </div>
              <div>
                <h6>This email has been set as ready to be sent</h6>
                <p class="text-muted">If you need to edit it more, you will need to set it back to in progress.</p>
              </div>
              <div class="button" v-if="!scheduled_email.locked">
                <button class="xpl-button xpl-button--secondary" @click.stop="setScheduledEmailAsInProgress">Set back to in progress</button>
              </div>
            </div>
          </div>

          <div class="card progress" v-if="scheduled_email.incomplete && !scheduled_email.scheduled && !scheduled_email.sent && !scheduled_email.overdue">
            <div class="card-header card-header-merged"></div>
            <div class="card-body">
              <div>
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/in-progress.svg" />
              </div>
              <div>
                <h6>This email is in progress</h6>
                <p class="text-muted">This email will not send on its scheduled date and time until you save it as "Ready to be Sent".</p>
              </div>
              <div class="button" v-if="!scheduled_email.locked">
                <button class="xpl-button xpl-button--secondary" @click.stop="saveAsReady">Save as ready to be sent</button>
              </div>
            </div>
          </div>

          <CorporateScheduledEmail v-if="scheduled_email.corporate_push" :scheduled_email="scheduled_email" />

          <div class="card" v-if="scheduled_email.email">
            <div class="card-header card-header-secondary card-header-merged">
              <div>
              Email Details
              <small class="text-muted">Last updated {{ scheduled_email.email.updated_at | humanDateTime }}</small>
              </div>
              <div>
                <a href="#" @click.stop="show_template_modal = !show_template_modal">Save as Template</a>
                <button type="button" class="xpl-button xpl-button--secondary" @click.stop="show_quick_send_modal = !show_quick_send_modal" v-if="scheduled_email.sent">Quick Send</button>
                <button type="button" class="xpl-button xpl-button--primary" @click="designEmail" v-if="scheduled_email.incomplete && !scheduled_email.scheduled">Edit this Email</button>
              </div>
            </div>
            <div class="card-body">
              <div class="stats" v-if="scheduled_email.sent && stats">
                <div :class="{ 'stat': true, 'show': show_stat === 'sent' }" @click.stop="showStat('sent')">
                  <h6>Sent</h6>
                  <span class="number sent">{{ stats.sent | prettyNumber }}</span>
                </div>
                <div :class="{ 'stat': true, 'show': show_stat === 'opened' }" @click.stop="showStat('opened')">
                  <h6>Opened</h6>
                  <span class="number opened">{{ stats.sent ? stats.opened_unique / stats.sent : 0 | prettyPercentage }}</span>
                  <span class="sub-number">{{ stats.opened_unique | prettyNumber }}</span>
                </div>
                <div :class="{ 'stat': true, 'show': show_stat === 'clicked' }" @click.stop="showStat('clicked')">
                  <h6>Clicked</h6>
                  <span class="number clicked">{{ stats.sent ? stats.clicked_unique / stats.sent : 0 | prettyPercentage }}</span>
                  <span class="sub-number">{{ stats.clicked_unique | prettyNumber }}</span>
                </div>
                <div :class="{ 'stat': true, 'show': show_stat === 'bounced' }" @click.stop="showStat('bounced')">
                  <h6>Bounces</h6>
                  <span class="number bounced">{{ stats.sent ? stats.bounced / stats.sent : 0 | prettyPercentage }}</span>
                  <span class="sub-number">{{ stats.bounced | prettyNumber }}</span>
                </div>
                <div :class="{ 'stat': true, 'show': show_stat === 'unsubscribed' }" @click.stop="showStat('unsubscribed')">
                  <h6>Unsubscribed</h6>
                  <span class="number unsubscribed">{{ stats.sent ? stats.unsubscribed / stats.sent : 0 | prettyPercentage }}</span>
                  <span class="sub-number">{{ stats.unsubscribed | prettyNumber }}</span>
                </div>
                <div :class="{ 'stat': true, 'show': show_stat === 'revenue' }" @click.stop="showStat('revenue')">
                  <h6>Est. Revenue</h6>
                  <span class="number revenue" v-if="stats.revenue">{{ stats.revenue | prettyCurrency }}</span>
                  <span class="number text-muted" v-if="!stats.revenue">&mdash;</span>
                </div>
              </div>

              <EmailStat :event="show_stat" :typeUuid="scheduled_email.uuid" v-if="show_stat" />

              <div v-if="!show_stat">
                <div class="row">
                  <div class="col-md-2 label">Subject Line</div>
                  <div class="col-md-10">
                    {{ scheduled_email.email.subject }}
                    <div class="text-muted">{{ scheduled_email.email.pre_header }}</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 label">From Info</div>
                  <div class="col-md-10">
                    {{ scheduled_email.email.from_name }}
                    <span class="text-muted">({{ scheduled_email.email.from_address }})</span>
                  </div>
                </div>
                <div class="row" v-if="scheduled_email.email">
                  <div class="col-md-2 label">Recipients</div>
                  <div class="col-md-10">
                    <div>
                      <span v-for="list in scheduled_email.email.recipients_lists" style="margin-right: .5rem;">
                        {{ list.name }}
                      </span>
                    </div>
                    <div class="text-muted" v-if="scheduled_email.email.exclusions_lists.length">
                      Exclude:
                      <span v-for="list in scheduled_email.email.exclusions_lists" style="margin-right: .5rem;">
                        {{ list.name }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="scheduled_email.sent">
                  <div class="col-md-2"></div>
                  <div class="col-md-10">
                    <a :href="'https://archive.brandbot.email/' + scheduled_email.email_id + '/' + scheduled_email.email.uuid + '.html'" target="_blank">View Sent Email</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="preview" v-if="!show_stat && scheduled_email.email">
            <div class="preview-header">
              <span>Email Preview</span>
              <div class="preview-types">
                <span :class="{ 'icon': true, 'icon-display': true, 'active': preview_mode.desktop }" @click="togglePreviewMode('desktop')"></span>
                <span :class="{ 'icon': true, 'icon-mobile': true, 'active': preview_mode.mobile }" @click="togglePreviewMode('mobile')"></span>
              </div>
              <button type="button" class="xpl-button xpl-button--secondary" v-if="scheduled_email.email_id" @click="test_email = true">Test Email</button>
              <TestEmail v-if="test_email && scheduled_email.email_id" :emailId="scheduled_email.email_id" />
            </div>
            <div class="">
              <iframe id="iframe" src="" :class="{ 'preview-rendering': true, 'mobile': preview_mode.mobile }" height="0"></iframe>
              <div class="text-center" style="padding: 2rem;" v-if="loading_preview">
                <i class="fa fa-spin fa-spinner fa-fw"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChangeDateModal v-if="change_date_modal" :scheduledAt="scheduled_email.scheduled_at" :scheduledEmailId="scheduled_email.id" />
      <QuickSendModal v-if="show_quick_send_modal" :scheduledEmailId="scheduled_email.id" />
      <AssignModal v-if="assign_modal" :scheduledEmailId="scheduled_email.id" :assignedUser="scheduled_email.assigned_user" />
      <TemplateModal v-if="show_template_modal && scheduled_email.email" :emailId="scheduled_email.email.id" :email="scheduled_email.email" />
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import moment from 'moment';
  import Avatar from 'vue-avatar';
  import Multiselect from 'vue-multiselect';
  import TemplateSelection from './TemplateSelection';
  import EditEmail from './EditEmail';
  import TestEmail from './TestEmail';
  import ChangeDateModal from './ChangeDateModal';
  import AssignModal from './AssignModal';
  import EmailStat from './EmailStats/EmailStat';
  import TemplateModal from './TemplateModal';
  import CorporateScheduledEmail from './CorporateScheduledEmail';
  import QuickSendModal from './QuickSendModal';

  export default {
    props: ['uuid'],
    components: {
      Multiselect,
      TemplateSelection,
      EditEmail,
      TestEmail,
      ChangeDateModal,
      AssignModal,
      EmailStat,
      TemplateModal,
      Avatar,
      CorporateScheduledEmail,
      QuickSendModal,
    },
    data() {
      return {
        show_quick_send_modal: false,
        show_template_modal: false,
        show_stat: false,
        stats: null,
        scheduled_email: null,
        comments: [],
        comment: '',
        design: {
          template: false,
          email: false,
        },
        preview_mode: {
          desktop: true,
          mobile: false,
        },
        loading_preview: false,
        test_email: false,
        change_date_modal: false,
        assign_modal: false,
      };
    },
    beforeRouteEnter(to, from, next) {
      api.getScheduledEmail(to.params.uuid).then((response) => {
        next((vm) => {
          vm.from = from.path;
          vm.scheduled_email = response.scheduled_email;
        });
      }).catch((err) => {
        next((vm) => {
          vm.$toasted.error(err.message);
        });
      });
    },
    mounted() {
      EventBus.$on('quick_send.close', () => {
        this.show_quick_send_modal = false;
      });

      EventBus.$on('scheduled_email.template.selected', (payload) => {
        this.scheduled_email.email_id = payload.id;
        this.design.template = false;
        this.design.email = true;
      });

      EventBus.$on('scheduled_item.email.edit.close', () => {
        this.design.template = false;
        this.design.email = false;
        this.fetchScheduledEmail();
        this.fetchEmailPreview();
      });

      EventBus.$on('scheduled_email.template.close', () => {
        this.design.template = false;
        this.design.email = false;
      });

      EventBus.$on('scheduled_email.test_email.close', () => {
        this.test_email = false;
      });

      EventBus.$on('scheduled_email.change_date.close', () => {
        this.change_date_modal = false;
        this.fetchScheduledEmail();
      });

      EventBus.$on('scheduled_email.assign.close', () => {
        this.assign_modal = false;
        this.fetchScheduledEmail();
      });

      EventBus.$on('scheduled_email.stats.close', () => {
        this.show_stat = false;
      });

      EventBus.$on('scheduled_email.template.close', () => {
        this.show_template_modal = false;
      });
    },
    created() {
      if (!this.$store.state.user.current_account.did_setup_brand) {
        this.$router.push('/quick-brand');
      }

      this.$nextTick(() => {
        if (this.scheduled_email && this.scheduled_email.email_id) {
          this.fetchEmailPreview();

          if (this.scheduled_email.sent) {
            api.emailPerformance(this.scheduled_email.uuid).then((response) => {
              this.stats = response.stats;
            }).catch((err) => {
              this.$toasted.error(err.message);
            });
          }
        }

        this.fetchComments();
      });
    },
    beforeDestroy() {
      EventBus.$off('quick_send.close');
      EventBus.$off('scheduled_email.template.selected');
      EventBus.$off('scheduled_item.email.edit.close');
      EventBus.$off('scheduled_email.template.close');
      EventBus.$off('scheduled_email.test_email.close');
      EventBus.$off('scheduled_email.change_date.close');
      EventBus.$off('scheduled_email.assign.close');
      EventBus.$off('scheduled_email.stats.close');
      EventBus.$off('scheduled_email.template.close');
      EventBus.$off('subaccounts.modal_close');
      const i = document.getElementById('iframe');
      if (i) i.removeEventListener('load', this.resizeIframe);
    },
    computed: {
      hasTemplate() {
        return this.scheduled_email.email_id !== null;
      },
      isDraft() {
        return this.scheduled_email.incomplete || !this.scheduled_email.scheduled;
      },
    },
    filters: {
      dateTime(value) {
        return moment(value).format('ddd, MMM DD [at] h:mma');
      },
    },
    methods: {
      goBack() {
        if (this.from === '/calendar/list') {
          this.$router.push('/calendar/list');
        } else if (this.from.indexOf('/plans') > -1) {
          this.$router.go(-1);
        } else {
          this.$router.push('/calendar');
        }
      },
      editName() {
        const payload = {
          name: this.scheduled_email.name,
        };

        api.updateScheduledEmail(this.scheduled_email.id, payload).then((response) => {
          this.$toasted.success(response.message);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      showStat(stat) {
        if (this.show_stat !== stat) {
          this.show_stat = stat;
        } else {
          this.show_stat = false;
        }
      },
      saveAsReady() {
        const payload = {
          scheduled: true,
        };

        api.putEmail(this.scheduled_email.email_id, payload).then((response) => {
          this.$toasted.success(response.message);
          this.fetchScheduledEmail();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      setScheduledEmailAsInProgress(event) {
        const target = event.srcElement || event.target;
        const originalInnerHTML = target.innerHTML;

        target.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';

        const payload = {
          incomplete: true,
        };

        api.updateScheduledEmail(this.scheduled_email.id, payload).then((response) => {
          this.$toasted.success(response.message);
          this.scheduled_email.incomplete = response.scheduled_email.incomplete;
          this.scheduled_email.scheduled = response.scheduled_email.scheduled;
          target.innerHTML = originalInnerHTML;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      expand(event) {
        const target = event.srcElement || event.target;
        target.rows = 3;

        this.$refs.post_comment.hidden = false;
      },
      shrink(event) {
        const target = event.srcElement || event.target;
        target.rows = 1;

        if (!this.comment) {
          this.$refs.post_comment.hidden = true;
        }
      },
      postComment(event) {
        const target = event.target || event.srcElement;
        target.disabled = true;
        const payload = {
          commentable_type: 'BrandBot\\Models\\ScheduledEmail',
          commentable_id: this.scheduled_email.id,
          user_id: this.$store.state.user.id,
          body: this.comment,
        };

        api.saveComment(payload).then((response) => {
          this.comments = response.comments;
          this.$toasted.success(response.message);
          this.comment = '';
          this.$refs.post_comment.hidden = true;
          target.disabled = false;
        }).catch((err) => {
          target.disabled = false;
          this.$toasted.error(err.message);
        });
      },
      deleteComment(commentUuid) {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to delete this comment?')) {
          return;
        }

        api.deleteComment(commentUuid).then((response) => {
          this.$toasted.success(response.message);
          this.fetchComments();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchComments() {
        const scheduledEmailId = this.scheduled_email.id;

        api.getCommentsScheduledEmail(scheduledEmailId).then((response) => {
          this.comments = response.comments;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      removeAssigned() {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to remove the assigned person?')) {
          return;
        }

        const payload = {
          assigned_user_id: null,
        };

        api.updateScheduledEmail(this.scheduled_email.id, payload).then((response) => {
          this.$toasted.success(response.message);
          this.fetchScheduledEmail();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      removePlan() {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to dissassociate this Scheduled Plan?')) {
          return;
        }

        const payload = {
          plan_id: null,
        };

        api.updateScheduledEmail(this.scheduled_email.id, payload).then((response) => {
          this.$toasted.success(response.message);
          this.fetchScheduledEmail();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      async deleteScheduledEmail() {
        const options = {
          reverse: true,
          type: 'hard',
          okText: 'Delete',
          verification: 'delete',
          animation: 'bounce',
        };
        try {
          await this.$dialog.confirm('Are you sure you want to delete this Scheduled Email? This is irreversible.', options);
        } catch (err) {
          return;
        }
        try {
          const response = await api.deleteScheduledEmail(this.scheduled_email.id);
          this.$toasted.success(response.message);
          this.goBack();
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      togglePreviewMode(mode) {
        this.preview_mode.desktop = false;
        this.preview_mode.mobile = false;
        this.preview_mode[mode] = true;
      },
      fetchEmailPreview() {
        this.loading_preview = true;
        api.getEmailPreview(this.scheduled_email.email_id).then((response) => {
          this.loading_preview = false;

          this.$nextTick(() => {
            const iframe = document.getElementById('iframe');
            iframe.contentWindow.document.open();
            iframe.contentWindow.document.write(response.html);
            iframe.contentWindow.document.close();

            iframe.addEventListener('load', this.resizeIframe);
          });
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      resizeIframe() {
        document.getElementById('iframe').style.height =
        `${document.getElementById('iframe').contentWindow.document.body.offsetHeight}px`;
      },
      designEmail() {
        if (!this.hasTemplate) {
          this.design.template = true;
          this.design.email = false;
          return;
        }

        this.design.template = false;
        this.design.email = true;
      },
      fetchScheduledEmail() {
        api.getScheduledEmail(this.uuid).then((response) => {
          this.scheduled_email = response.scheduled_email;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 3rem;
    background-color: #ffffff;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid #cdd7e2;
    z-index: 10;
  }

  .header button.delete {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 300ms ease-in-out;
  }

  button.delete .material-icons {
    display: block;
    font-size: 1.25rem;
    line-height: 1rem;
    transition: all 300ms ease-in-out;
    color: #8b9bac;
  }

  button.delete:hover {
    border-color: #d62929;
    background-color: white;
  }

  button.delete:hover .material-icons {
    color: #d62929;
  }

  .header input {
    border: none;
    outline: none;
    width: 75%;
    margin-left: 1.5rem;
  }

  .container {
    height: 100vh;
    overflow-y: scroll;
    max-width: none;
    padding: 0;
    padding-top: 3rem;
  }

  .container .sidebar {
    display: flex;
    flex-direction: column;
    min-width: 22.5rem;
    max-width: 22.5rem;
    width: 22.5rem;
    background-color: #fff;
    position: fixed;
    z-index: 1;
    right: 0;
    top: 0;
    height: calc(100vh - 3rem);
    margin-top: 3rem;
  }

  .container .content {
    padding-left: 2rem;
    padding-right: calc(22.5rem + 2rem);
    min-height: calc(100vh - 3rem);
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
  }

  .container .content p.empty {
    border: none;
    text-align: center;
    max-width: 14.375rem;
    font-size: .875rem;
    line-height: 1.25rem;
    color: #8b9bac;
    margin-bottom: 1.5rem;
  }

  .container .content.email {
    padding-top: 1rem;
    margin-bottom: 3rem;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .illustration {
    width: 13rem;
    margin-bottom: 2rem;
  }

  .scheduled,
  .assigned,
  .plan {
    flex-shrink: 0;
    flex-grow: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
    font-size: .875rem;
    line-height: .875rem;
    letter-spacing: 0.2px;
    color: rgba(99, 114, 130, 0.67);
  }

  .plan {
    cursor: initial;
  }

  .assigned .remove-assigned,
  .plan .remove-assigned {
    cursor: pointer;
    opacity: 0;
    margin-left: auto;
    transition: all 300ms ease-in-out;
  }

  .assigned:hover .remove-assigned,
  .plan:hover .remove-assigned {
    opacity: 1;
  }

  .assigned.active,
  .plan.active {
    color: #2E4457;
  }

  .scheduled {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #2E4457;
  }

  .scheduled .material-icons,
  .plan .material-icons {
    color: #AFBCCA;
    margin-right: .75rem;
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 99rem;
    margin-right: .75rem;
  }

  .assigned span,
  .plan span {
    margin-right: .75rem;
  }

  .empty {
    border: 1px dashed #b7bfc6;
  }

  .full {
    border: 1px solid #b7bfc6;
  }

  .comments-form {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: auto;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding-top: .625rem;
    padding-bottom: .625rem;
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 50;
  }

  .comments-form form {
    width: 100%;
    text-align: right;
  }

  .comments-form form button {
    margin-top: .5rem;
  }

  .comments-form .user-badge {
    margin-top: .5rem;
    margin-right: .875rem;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .comments {
    width: 100%;
    min-height: 5rem;
  }

  .comments .no-comments {
    margin-top: 2rem;
    display: flex;
    width: 5.25rem;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .comments .comment {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: .625rem;
    padding-bottom: .625rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #2e4457;
    border-bottom: 1px solid #e8e8e8;
    font-size: .875rem;
  }

  .comments .comment:last-child {
    border-bottom: none;
  }

  .comments .comment .user-badge {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: .875rem;
  }

  .card-header-secondary a {
    font-size: .75rem;
    font-weight: 700;
    line-height: 1rem;
    color: rgb(29, 54, 75);
    text-decoration: none;
    margin-right: .75rem;
  }

  .card-header-secondary small {
    margin-left: 1rem;
  }

  .card .row {
    line-height: 1.25rem;
    color: rgb(29, 54, 75);
    margin-top: 1rem;
    font-size: .875rem;
  }

  .card .row:first-child {
    margin-top: 0;
  }

  .card .label {
    font-weight: 700;
  }

  .card {
    flex-shrink: 0;
    flex-grow: 0;
  }

  .card:first-child .card-body {
    padding-bottom: 2rem;
  }

  .card + .preview {
    border-bottom: none;
  }

  .preview {
    border: solid 1px rgb(217, 231, 240);
    border-top: none;
    padding: 1rem;
    background-color: rgb(247, 248, 249);
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .preview-header {
    margin-top: -1rem;
    margin-left: -1rem;
    width: calc(100% + 2rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: .875rem;
    font-weight: 700;
    color: rgb(29, 54, 75);
  }

  .preview-types .icon {
    cursor: pointer;
    margin-right: 1rem;
  }

  .preview-types .icon:last-child {
    margin-right: 0;
  }

  .preview-types .icon.active {
    color: rgb(0, 105, 160);
  }

  .preview-rendering * {
    font-family: initial !important;
    font-size: initial !important;
    font-weight: initial !important;
    color: initial !important;
  }

  .preview-rendering {
    width: 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    transition: all 300ms ease-in-out;
    pointer-events: none !important;
  }

  .preview-rendering.mobile {
    width: 320px;
  }

  .card.ready,
  .card.progress,
  .card.overdue {
    // box-shadow: 0 1px 0 0 rgba(217, 231, 240, 0.5);
    border: solid 1px #d9e7f0;
    margin-bottom: 2rem;
  }

  .card.ready .card-header {
    border-radius: 0;
    padding: 0;
    background-image: linear-gradient(to right, rgb(70, 232, 136), rgb(94, 246, 188));
    height: .5rem;
  }

  .card.progress .card-header {
    border-radius: 0;
    padding: 0;
    background-image: linear-gradient(to right, rgb(81, 217, 244), rgb(5, 177, 243));
    height: .5rem;
  }

  .card.overdue .card-header {
    border-radius: 0;
    padding: 0;
    background-image: linear-gradient(to right, rgb(250, 217, 97), rgb(255, 183, 105));
    height: .5rem;
  }

  .card.ready .card-body,
  .card.progress .card-body,
  .card.overdue .card-body {
    border: 0;
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }

  .card.ready .card-body h6,
  .card.progress .card-body h6,
  .card.overdue .card-body h6 {
    text-align: left;
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    color: #1d364b;
  }

  .card.ready .card-body p,
  .card.progress .card-body p,
  .card.overdue .card-body p {
    text-align: left;
    margin: 0;
    font-size: .75rem;
    line-height: 1.125rem;
    max-width: 26.5rem;
  }

  .card.ready .card-body .button,
  .card.progress .card-body .button,
  .card.overdue .card-body .button {
    margin-left: auto;
  }

  .card.ready img,
  .card.progress img {
    display: block;
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }

  .card.overdue .material-icons {
    margin-right: 1rem;
    color: rgb(255, 183, 105);
  }

  .stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .stats .stat {
    flex: calc(100% / 6 - 1rem);
    flex-shrink: 0;
    flex-grow: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    min-width: 6.25rem;
    padding: .75rem;
    padding-top: 1rem;
    border-radius: 6px;
    background-color: #ffffff;
    border: solid 1px #d6e0e8;
    margin-right: .5rem;
    transition: all 150ms ease-in-out;
  }

  .stats .stat:hover,
  .stats .stat.show {
    border-color: var(--xpl-color-secondary-lm);
    background-color: var(--xpl-color-secondary-bg-lm);
  }

  .stats .stat.show h6 {
    color: var(--xpl-color-secondary-lm);
  }

  .stats .stat:last-child {
    margin-right: 0;
  }

  .stats .stat h6 {
    text-align: center;
    font-size: .625rem;
    font-weight: 800;
    letter-spacing: 0.8px;
    color: #8b9bac;
    margin-bottom: .375rem;
    text-transform: uppercase;
  }

  .stats .stat .number {
    text-align: center;
    font-size: 1.25rem;
  }

  .stats .stat .number.sent {
    color: #084e8a;
  }
  .stats .stat .number.delivered {
    color: #00848e;
  }
  .stats .stat .number.opened {
    color: #6923f4;
  }
  .stats .stat .number.clicked {
    color: #5c6ac4;
  }
  .stats .stat .number.bounced {
    color: #f49342;
  }
  .stats .stat .number.unsubscribed {
    color: #ec6b6b;
  }
  .stats .stat .number.revenue {
    color: #133C8B;
  }

  .stats .stat .sub-number {
    margin-top: .375rem;
    font-size: .75rem;
    color: #212b36;
    text-align: center;
  }

  iframe.preview-rendering {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: none;
    width: 100%;
    height: auto;
    overflow: hidden;
    /*height: 45.25rem;*/
  }

  iframe.preview-rendering.mobile {
    max-width: 320px;
  }

  iframe.preview-rendering * {
    pointer-events: none;
  }
</style>
