<template>
  <el-form @submit.native.prevent>
    <div class="field" v-if="['birthday', 'referral_source', 'home_location', 'waiver_signed', 'mt_opt_in_email', 'mt_opt_in_sms', 'password', 'mt_terms_and_service'].indexOf(fieldName) === -1">
      <label data-test-placeholder v-if="!shadow">{{ placeholder }}</label>
      <el-input data-test-input v-model="field" :placeholder="placeholder" readonly />
      <div>
        <el-checkbox data-test-required-switch size="mini" v-model="req" label="Required" @change="changeRequired" />
      </div>
    </div>
    <div class="field" v-if="fieldName === 'password' && !vendorSyncSettings">
      <label data-test-placeholder v-if="!shadow">{{ placeholder }}</label>
      <el-input data-test-input v-model="field" :placeholder="placeholder" readonly />
      <div>
        <el-checkbox data-test-required-switch size="mini" v-model="req" label="Required" @change="changeRequired"  />
      </div>
    </div>
    <div class="field" v-if="fieldName === 'referral_source'">
      <label data-test-referral-placeholder v-if="!shadow">{{ placeholder }}</label>
      <div>
        <el-select data-test-referral-select v-model="field" placeholder="Referral Source" readonly style="width: 100%;">
          <el-option :data-test-referral-option="item" v-for="item in referral_types" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </div>
      <div>
        <el-checkbox data-test-referral-required-switch size="mini" v-model="req" label="Required" @change="changeRequired" />
      </div>
    </div>
    <div class="field" v-if="fieldName === 'birthday'">
      <label data-test-birthday-placeholder >{{ placeholder }}</label>
      <el-row :span="24">
        <el-col :span="7">
        <el-select data-test-day-select v-model="day" placeholder="Day">
          <el-option :data-test-day-option="day" v-for="day in days" :key="day" :label="day" :value="day"></el-option>
        </el-select>
        </el-col>
        <el-col :span="7" :offset="1">
          <el-select data-test-month-select v-model="month" placeholder="Month">
            <el-option :data-test-month-option="mo" v-for="mo in months" :key="mo" :label="mo" :value="mo"></el-option>
          </el-select>
        </el-col>
        <el-col :span="8" :offset="1">
          <el-select data-test-year-select v-model="year" placeholder="Year">
            <el-option :data-test-year-option="y" v-for="y in years" :key="y" :label="y" :value="y"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <div>
        <el-checkbox data-test-birthday-required-switch size="mini" v-model="req" label="Required" @change="changeRequired" />
      </div>
    </div>
    <div class="field" v-if="fieldName === 'home_location'">
      <el-tooltip class="item" effect="light" content="This a required field for MarianaTek account creation." placement="right" popper-class="mt-auto-account-creation-tooltip 1" data-test-field-home-location-tooltip>
       <label  data-test-field-placeholder-home-location v-if="!shadow">{{ placeholder }}</label>
      </el-tooltip>
        <el-select data-test-home-location-dropdown v-model="field" placeholder="Home Location" readonly style="width: 100%;">
          <el-option data-test-home-location-option v-for="location in homeLocations" :key="location.id" :label="location.name" :value="location.id"></el-option>
        </el-select>
    </div>
    <div class="field" v-if="fieldName === 'waiver_signed'">
      <el-tooltip data-test-field-tooltip-waiver class="item" effect="light" content="This a required field for MarianaTek account creation." placement="right" popper-class="mt-auto-account-creation-tooltip 3">
        <label data-test-field-placeholder-waiver v-if="!shadow">{{ placeholder }}</label>
      </el-tooltip>
        <el-checkbox id="waiver-checkbox" style="display: block margin-bottom: 0" data-test-waiver-check v-model="field" label="Yes" ></el-checkbox>
    </div>

     <div class="field" v-if="fieldName === 'mt_opt_in_sms' || fieldName === 'mt_opt_in_email'">
      <el-tooltip data-test-field-tooltip-opt-in class="item" effect="light" content="This a required field for MarianaTek account creation." placement="right" popper-class="mt-auto-account-creation-tooltip 4">
         <label class="el-checkbox__label label-overide" data-test-field-placeholder-opt-in >{{ placeholder }}</label>
      </el-tooltip>
        <div class="opt-in-field-content">
           <el-checkbox v-if="fieldName === 'mt_opt_in_email'" class="vendor-sync-checkbox" style="display: inline" data-test-opt-in-email v-model="field"  ></el-checkbox>
           <el-checkbox v-if="fieldName === 'mt_opt_in_sms'" class="vendor-sync-checkbox" style="display: inline" data-test-opt-in-sms v-model="field"  ></el-checkbox>
          <span>
            {{optInCommunicationsLabel}}
          </span>
        </div>
    </div>

    <div class="field" v-if="fieldName === 'mt_terms_and_service'">
      <el-tooltip data-test-field-tooltip class="item" effect="light" content="This a required field for MarianaTek account creation." placement="right" popper-class="mt-auto-account-creation-tooltip 5">
       <label class="el-checkbox__label label-overide" data-test-field-placeholder-terms-and-conditions v-if="!shadow">{{ placeholder }}</label>
      </el-tooltip>
        <div class="opt-in-field-content">
          <el-checkbox  class="vendor-sync-checkbox" style="display: inline margin-bottom: 0" data-test-terms-and-conditions v-model="field" label="" ></el-checkbox>
          <span data-test-terms-and-conditions-text >
             I agree to electronically signing the <a :href="termsAndServiceUrlsMT.terms_of_service_url">Terms of Service</a> and <a :href="termsAndServiceUrlsMT.privacy_policy_url">Privacy Policy</a>.
          </span>
        </div>
    </div>
    <div>
      <el-switch v-if="!this.vendorSyncSettings" data-test-include-switch active-text="Include" v-model="model" :disabled="disabled" @change="emitValue"></el-switch>
    </div>
  </el-form>
</template>
<script>
import * as api from '@/api';
import EventBus from '@/event-bus';
import axios from 'axios';
import { enviromentVariables } from '@/values/enviromentValues';

export default {
  props: [
    'placeholder',
    'disabled',
    'include',
    'fieldName',
    'shadow',
    'fieldIndex',
    'required',
    'vendorSyncSettings',
    'homeLocations',
  ],
  data() {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i < 100; i += 1) {
      years.push(currentYear - i);
    }
    return {
      field: null,
      referral_types: [],
      model: this.include,
      req: this.required,
      day: null,
      month: null,
      year: null,
      days: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31',
      ],
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      years,
      termsAndServiceUrlsMT: {
        terms_of_service_url: '',
        privacy_policy_url: '',
      },
    };
  },
  created() {
    if (this.fieldName === 'referral_source') {
      this.fetchReferralTypes();
    }
    if (this.fieldName === 'mt_terms_and_service') {
      this.fetchTermsandServiceUrlsMT();
    }
  },
  methods: {
    emitValue(value) {
      EventBus.$emit('forms.update.field', this.fieldIndex, value);
    },
    changeRequired(value) {
      EventBus.$emit('forms.update.required', this.fieldIndex, value);
    },
    async fetchReferralTypes() {
      try {
        const response = await api.getMindbodyReferralTypes();
        this.referral_types = response.types;
      } catch (err) {
        this.$toasted.error(err.message);
      }
    },
    async fetchTermsandServiceUrlsMT() {
      const enviroment = enviromentVariables.apiUrl === 'staging' ? 'staging-api' : 'api';
      try {
        const accountId = this.$store.state.user.current_account.id;
        const response = await axios.get(`https://${enviroment}.brandbot.cloud/vendor/marianatek/accounts/${accountId}/terms_and_service`); //eslint-disable-line
        this.termsAndServiceUrlsMT = response.data;
      } catch (err) {
        this.$toasted.error(`${err.message} + No Terms of Service and Privacy Policy found`);
      }
    },
  },
  computed: {
    optInCommunicationsLabel() {
      return `By selecting this option, you agree to receive marketing communications via ${this.fieldName === 'mt_opt_in_sms' ? 'sms' : 'email'}.`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/scss/global/_variables.scss';
.el-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.field {
  width: 70%;
}
#waiver-checkbox {
  margin-bottom: 0;
  display: block;
}
.vendor-sync-checkbox {
  color: #303133;
  //font-family: 'Muli', sans-serif;
  font-size: 14px;
  font-weight: 700;
}
.opt-in-field-content {
  color: #8b9bac;
  font-size: 0.75rem;
  .el-checkbox {
    margin-right: 10px;
  }
}
.label-overide {
  padding-left: 0;
}
</style>
