<template>
  <div class="condition">
    <form @submit.prevent>
      <div class="form-group">
        <label>The contact is assigned any of the following membership types:</label>
        <div class="multiselect-block">
          <Multiselect v-model="selected_membership_types" placeholder="Select Membership Type(s)" :options="membership_types" label="type" track-by="id" :searchable="true" :allow-empty="true" :show-labels="false" :multiple="true" :close-on-select="false" />
        </div>
        <button class="xpl-button xpl-button--primary" @click.stop="commitCondition" :disabled="!selected_membership_types.length">Save</button>
      </div>
    </form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['uuid', 'rule', 'campaignItem'],
    components: {
      Multiselect,
    },
    data() {
      return {
        membership_types: [],
        selected_membership_types: [],
      };
    },
    created() {
      this.fetchMembershipData();

      if (this.rule && this.rule.length && this.rule[0].membership_type) {
        this.selected_membership_types = this.rule[0].membership_type;
      }
    },
    methods: {
      fetchMembershipData() {
        api.getMindbodyMemberships().then((response) => {
          this.membership_types = response.membership_types;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition(event) {
        const target = event.target || event.srcElement;
        target.disabled = true;
        target.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';

        const payload = {
          condition: {
            uuid: this.uuid,
            quick: true,
          },
          membership_status: [{ status: 'active' }],
          timeframe: 'all',
          membership_type: this.selected_membership_types,
          misc: {
            filtering: false,
          },
        };
        EventBus.$emit('condition.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .form-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .multiselect-block {
    display: block;
    width: 100%;
  }

  .condition label {
    color: #1D364B;
    margin-bottom: .75rem;
    font-weight: 400;
    font-size: 1rem;
  }

  .btn-bb-primary {
    margin-top: 1.5rem;
  }

  .small.text-muted {
    margin-top: .5rem;
  }
</style>
