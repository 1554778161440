<template>
<!--
segments= in the contacts tab, lists of contacts
enrollment rules= inside campaigns (how you get into campaigns)
emails sms tasks phone call reminders tags = campaign items
goals = inside of campaigns (how a contact exits)
-->
  <div class="rule-english-hybrid">

  <RuleEnglishUIA v-if="$store.getters.isUIAVendor"
                  :rule="rule"/>
  <RuleEnglish v-else
               :rule="rule"/>
  </div>
</template>

<script>
import RuleEnglish from './RuleEnglish';
import RuleEnglishUIA from './UIA/Summaries/RuleEnglish';

export default {
  name: 'RuleEnglishHybrid',
  components: { RuleEnglish, RuleEnglishUIA },
  props: ['rule'],
};
</script>

<style lang="scss" scoped>
.rule-english-hybrid {
  position: relative;
}
</style>
