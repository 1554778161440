<template>
  <div>
    <div class="tabs">
      <a href="#" :class="{ active: sidebar_tabs.build }" @click.prevent="toggleSidebarTab('build')">Build</a>
      <a href="#" :class="{ active: sidebar_tabs.style }" @click.prevent="toggleSidebarTab('style')">Style</a>
    </div>

    <div class="build_tab" v-show="sidebar_tabs.build">
      <form @submit.prevent>
        <div class="form-group">
          <label>Content</label>
          <input type="text" v-model.trim="currentElement.content" class="form-control" @input="attributeEdited" />
        </div>

        <div v-if="currentElement.attributes['css-class'].indexOf('bb-calendar') === -1">
          <div class="form-group">
            <label>Link Type</label>
            <el-select v-model="link_type" placeholder="Link Type" style="width: 100%;">
              <el-option :key="'Web Address'" :label="'Web Address'" :value="'Web Address'"></el-option>
              <el-option :key="'Email Address'" :label="'Email Address'" :value="'Email Address'"></el-option>
              <el-option :key="'Phone Number'" :label="'Phone Number'" :value="'Phone Number'"></el-option>
              <el-option :key="'Landing Page'" :label="'Landing Page'" :value="'Landing Page'" v-if="!$store.getters.isUIAVendor"></el-option>
            </el-select>
          </div>

          <div v-if="link_type !== 'Landing Page'">
            <div class="form-group">
              <label v-if="link_type === 'Phone Number'">Phone Number</label>
              <label v-if="link_type === 'Web Address'">Web Address</label>
              <label v-if="link_type === 'Email Address'">Email Address</label>
              <input type="text" v-model="link" class="form-control" placeholder="https://brandbot.com" @input="hrefEdited" @touch="$v.link.$touch()" v-if="link_type === 'Web Address'" />
              <input type="email" v-model="link" class="form-control" placeholder="me@email.com" @input="hrefEdited" @touch="$v.link.$touch()" v-if="link_type === 'Email Address'" />
              <input type="tel" v-model="link" class="form-control" placeholder="Phone" @input="hrefEdited" @touch="$v.link.$touch()" v-if="link_type === 'Phone Number'" />
              <div class="small helper text-danger" v-if="link_type === 'Email Address' && link && $v.link.$invalid">Please make sure your email is valid.</div>
              <a href="#" class="small helper" @click.prevent="save_link = true" v-if="link && ['Web Address', 'Email Address'].indexOf(link_type) > -1">Click here to save this {{ link_type === 'Web Address' ? 'link' : 'email'  }}.</a>
            </div>

            <div class="form-group" v-if="save_link">
              <label>{{ link_type === 'Web Address' ? 'Link' : 'Email' }} Name</label>
              <input type="text" v-model="link_name" class="form-control" placeholder="Name" />
            </div>

            <div class="form-group" v-if="save_link">
              <button type="button" class="xpl-button xpl-button--primary" @click="saveLink" :disabled="!link_name">Save Link</button>
            </div>

            <div class="form-group">
              <el-button type="secondary" class="xpl-button xpl-button--secondary"  @click="show_saved_links_modal = !show_saved_links_modal">Select from Saved Links</el-button>
            </div>
          </div>
          <div v-if="link_type === 'Landing Page'">
            <el-form style="padding: 0;">
              <el-form-item>
                <label>Link</label>
                <el-input v-model="link" :disabled="true" placeholder="Landing Page URL"></el-input>
              </el-form-item>
            </el-form>
            <el-button type="secondary" class="xpl-button xpl-button--secondary"  @click="landingPageModal">Landing Pages</el-button>
          </div>
        </div>
      </form>
    </div>

    <div class="style_tab" v-show="sidebar_tabs.style">
      <div class="banner">Text Styling</div>

      <form @submit.prevent>
        <div class="form-group" v-if="!$store.state.user.current_account.brand_restriction">
          <label>Font Family</label>
          <Multiselect v-model="font_family" placeholder="Change Font Family" :options="available_fonts" :searchable="true" label="label" track-by="value" :allow-empty="false" :show-labels="false" @input="toggleFontFamily">
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <div class="option__title">{{ props.option.label }}</div>
                <span class="small text-muted">{{ props.option.safe ? 'Web Safe' : 'Custom' }}</span>
              </div>
            </template>
          </Multiselect>
        </div>

        <div class="group">
          <div class="form-group" v-if="!$store.state.user.current_account.brand_restriction">
            <label>Font Size</label>
            <Multiselect v-model="currentElement.attributes['font-size']" placeholder="Font Size" :options="['9px', '10px', '11px', '12px', '13px', '14px', '16px', '18px', '24px', '30px', '36px', '48px', '60px', '72px']" :allow-empty="false" :show-labels="false" @input="attributeEdited" :taggable="true" @tag="addFontSize" tag-placeholder="" />
          </div>
          <div class="form-group">
            <label>Font Style</label>
            <div class="styles">
              <div :class="{ style: true, selected: currentElement.attributes['font-weight'] === 'bold' }" @click="toggleFontWeight">
                <span class="material-icons">format_bold</span>
              </div>
              <div :class="{ style: true, selected: currentElement.attributes['font-style'] === 'italic' }" @click="toggleFontStyle">
                <span class="material-icons">format_italic</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" v-if="!$store.state.user.current_account.brand_restriction">
          <label>Text Transform</label>
          <Multiselect v-model="currentElement.attributes['text-transform']" placeholder="Text Transform" :options="['None', 'Capitalize', 'Uppercase', 'Lowercase']" :allow-empty="false" :show-labels="false" @input="attributeEdited" />
        </div>

        <div class="form-group" v-if="!$store.state.user.current_account.brand_restriction">
          <label>Font Color</label>
          <div class="input-group" @click="show_font_color_picker = !show_font_color_picker">
            <span class="input-group-addon">
              <span class="icon icon-square" :style="'color: ' + font_color.hex"></span>
            </span>
            <input type="text" ref="font_color" v-model="font_color.hex" class="form-control" readonly />
          </div>
          <Sketch v-model="font_color" :disableAlpha="true" @input="fontColorEdited" v-if="show_font_color_picker" />
        </div>
      </form>

      <div class="banner">Button Styling</div>

      <form @submit.prevent>
        <div class="form-group">
          <label>Alignment</label>
          <div class="alignments">
            <div :class="{ alignment: true, selected: currentElement.attributes.align === 'left' }" @click="currentElement.attributes.align = 'left'; attributeEdited();">
              <span class="material-icons">format_align_left</span>
            </div>
            <div :class="{ alignment: true, selected: currentElement.attributes.align === 'center' }" @click="currentElement.attributes.align = 'center'; attributeEdited();">
              <span class="material-icons">format_align_center</span>
            </div>
            <div :class="{ alignment: true, selected: currentElement.attributes.align === 'right' }" @click="currentElement.attributes.align = 'right'; attributeEdited();">
              <span class="material-icons">format_align_right</span>
            </div>
          </div>
        </div>

        <div class="group" v-if="!$store.state.user.current_account.brand_restriction">
          <div class="form-group">
            <label>Height</label>
            <input type="number" ref="height" min="1" class="form-control" @input="innerPaddingEdited" />
          </div>
          <div class="form-group">
            <label>Width</label>
            <input type="number" ref="width" min="1" class="form-control" @input="innerPaddingEdited" />
          </div>
        </div>

        <div class="form-group" v-if="!$store.state.user.current_account.brand_restriction">
          <label>Button Color</label>
          <div class="input-group" @click="show_button_color_picker = !show_button_color_picker">
            <span class="input-group-addon">
              <span class="icon icon-square" :style="'color: ' + button_color.hex"></span>
            </span>
            <input type="text" ref="button_color" v-model="button_color.hex" class="form-control" readonly />
          </div>
          <Sketch v-model="button_color" :disableAlpha="true" @input="buttonColorEdited" v-if="show_button_color_picker" />
        </div>

        <div class="form-group" v-if="!$store.state.user.current_account.brand_restriction">
          <label>Rounded Corner</label>
          <input type="number" ref="border_radius" class="form-control" min="0" @input="borderRadiusEdited($event.target.value)" />
        </div>

        <div class="form-group" v-if="!$store.state.user.current_account.brand_restriction">
          <label>Border Type</label>
          <Multiselect v-model="border_type" placeholder="Border Type" :options="['None', 'Solid', 'Dashed', 'Dotted']" :allow-empty="false" :show-labels="false" @input="borderEdited" />
        </div>

        <div class="form-group" v-if="border_type !== 'None' && !$store.state.user.current_account.brand_restriction">
          <label>Border Width</label>
          <input type="number" ref="border_width" class="form-control" min="1" @input="borderEdited" />
        </div>

        <div class="form-group" v-if="border_type !== 'None' && !$store.state.user.current_account.brand_restriction">
          <label>Border Color</label>
          <div class="input-group">
            <span class="input-group-addon">
              <span class="icon icon-square" :style="'color: ' + border_color.hex" @click="show_border_color_picker = !show_border_color_picker"></span>
            </span>
            <input type="text" ref="border_color" v-model="border_color.hex" class="form-control" readonly />
          </div>
          <Sketch v-model="border_color" :disableAlpha="true" @input="borderEdited" v-if="show_border_color_picker" />
        </div>
      </form>

      <div class="banner">Container Styling</div>

      <form @submit.prevent>
        <div class="group" v-if="currentElement.attributes['css-class'].indexOf('bb-calendar') === -1">
          <div class="form-group">
            <label>Spacing Top</label>
            <input type="number" ref="spacing_top" class="form-control" min="0" @input="spacingTopEdited($event.target.value)" />
          </div>

          <div class="form-group">
            <label>Spacing Bottom</label>
            <input type="number" ref="spacing_bottom" class="form-control" min="0" @input="spacingBottomEdited($event.target.value)" />
          </div>
        </div>

        <div class="form-group" v-if="!$store.state.user.current_account.brand_restriction">
          <label>Background Color</label>
          <div class="input-group" @click="show_background_color_picker = !show_background_color_picker">
            <span class="input-group-addon">
              <span class="icon icon-square" :style="'color: ' + background_color.hex"></span>
            </span>
            <input type="text" ref="background_color" v-model="background_color.hex" class="form-control" readonly />
          </div>
          <Sketch v-model="background_color" :disableAlpha="true" @input="backgroundColorEdited" v-if="show_background_color_picker" />
        </div>
      </form>
    </div>

    <SavedLinksModal v-if="show_saved_links_modal" />

    <div class="footer">
      <button type="button" class="xpl-button xpl-button--primary" @click="saveAndCloseBlock">Save and close</button>
    </div>
  </div>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import _ from 'lodash';
  import Multiselect from 'vue-multiselect';
  import { required } from 'vuelidate/lib/validators';
  import { Sketch } from 'vue-color';
  import Fonts from './fonts.json';
  import SavedLinksModal from './SavedLinksModal';

  export default {
    props: ['currentElement'],
    components: {
      Multiselect,
      Sketch,
      SavedLinksModal,
    },
    data() {
      return {
        show_saved_links_modal: false,
        show_font_color_picker: false,
        show_button_color_picker: false,
        show_border_color_picker: false,
        show_background_color_picker: false,
        save_link: false,
        link_name: '',
        link_type: 'Web Address',
        link: '',
        font_family: null,
        font_color: {
          hex: this.currentElement.attributes.color,
        },
        button_color: {
          hex: this.currentElement.attributes['background-color'],
        },
        border_color: {
          hex: null,
        },
        background_color: {
          hex: this.currentElement.attributes['container-background-color'],
        },
        border_type: null,
        sidebar_tabs: {
          build: true,
          style: false,
        },
        available_fonts: Fonts,
      };
    },
    validations() {
      if (this.link_type === 'Email Address') {
        return {
          link: {
            required,
          },
        };
      }

      return {
        link: {
          required,
        },
      };
    },
    created() {
      EventBus.$on('landing_page.link', (link) => {
        if (link.indexOf('.html') === -1) {
          this.link = `${link}.html`;
        } else {
          this.link = link;
        }
        this.hrefEdited();
      });

      EventBus.$on('saved_links_modal.close', () => {
        this.show_saved_links_modal = false;
      });

      EventBus.$on('saved_links_modal.selected', (link) => {
        this.link = link.url;
        this.show_saved_links_modal = false;
        this.hrefEdited();
      });
    },
    mounted() {
      this.link = this.currentElement.attributes.href ?
        this.currentElement.attributes.href
          .replace('mailto:', '')
          .replace('tel:', '') : '';

      this.link_type = 'Web Address';
      if (this.currentElement.attributes.href &&
          this.currentElement.attributes.href.indexOf('mailto:') > -1) {
        this.link_type = 'Email Address';
      }

      if (this.currentElement.attributes.href &&
          this.currentElement.attributes.href.indexOf('tel:') > -1) {
        this.link_type = 'Phone Number';
      }

      this.font_family = this.available_fonts.filter(f => f.value === this.currentElement.attributes['font-family'])[0];
      this.font_color.hex = this.currentElement.attributes.color;
      this.background_color.hex = this.currentElement.attributes['container-background-color'];

      this.$refs.spacing_top.value = this.currentElement.attributes['padding-top'] ? this.currentElement.attributes['padding-top'].replace('px', '') : null;
      this.$refs.spacing_bottom.value = this.currentElement.attributes['padding-bottom'] ? this.currentElement.attributes['padding-bottom'].replace('px', '') : null;
      const innerPadding = this.currentElement.attributes['inner-padding'].split(' ');
      const height = innerPadding[0] ? innerPadding[0] : 0;
      const width = innerPadding[1] ? innerPadding[1] : 0;
      this.$refs.height.value = height.replace('px', '');
      this.$refs.width.value = width.replace('px', '');

      this.$refs.border_radius.value = this.currentElement.attributes['border-radius'].replace('px', '');

      let border = this.currentElement.attributes.border;
      if (border !== 'none') {
        border = border.split(' ');
        const borderWidth = border[0];
        const borderType = border[1];
        const borderColor = border[2];

        this.$refs.border_width.value = borderWidth.replace('px', '');
        this.border_color.hex = borderColor;
        this.border_type = `${borderType.charAt(0).toUpperCase()}${borderType.slice(1)}`;
      } else {
        this.border_type = 'None';
      }
    },
    beforeDestroy() {
      EventBus.$off('saved_links_modal.close');
      EventBus.$off('saved_links_modal.selected');
      EventBus.$off('landing_page.link');
    },
    watch: {
      currentElement(newVal) {
        this.link = newVal.attributes.href ? newVal.attributes.href.replace('mailto:', '') : '';
        this.link_type = newVal.attributes.href &&
                        newVal.attributes.href.indexOf('mailto:') > -1 ?
                          'Email Address' : 'Web Address';
        this.font_family = this.available_fonts.filter(f => f.value === newVal.attributes['font-family'])[0];
        this.font_color.hex = newVal.attributes.color;
        this.background_color.hex = newVal.attributes['container-background-color'];

        this.$refs.spacing_top.value = newVal.attributes['padding-top'] ? newVal.attributes['padding-top'].replace('px', '') : null;
        this.$refs.spacing_bottom.value = newVal.attributes['padding-bottom'] ? newVal.attributes['padding-bottom'].replace('px', '') : null;
        const innerPadding = newVal.attributes['inner-padding'].split(' ');
        const height = innerPadding[0] ? innerPadding[0] : 0;
        const width = innerPadding[1] ? innerPadding[1] : 0;
        this.$refs.height.value = height.replace('px', '');
        this.$refs.width.value = width.replace('px', '');

        if (this.$refs.border_radius) {
          this.$refs.border_radius.value = newVal.attributes['border-radius'].replace('px', '');
        }

        let border = newVal.attributes.border;
        if (border !== 'none') {
          border = border.split(' ');
          const borderWidth = border[0];
          const borderType = border[1];
          const borderColor = border[2];

          if (this.$refs.border_width) {
            this.$refs.border_width.value = borderWidth.replace('px', '');
          }
          this.border_color.hex = borderColor;
          this.border_type = `${borderType.charAt(0).toUpperCase()}${borderType.slice(1)}`;
        } else {
          this.border_type = 'None';
        }
      },
    },
    methods: {
      landingPageModal() {
        EventBus.$emit('landing_page.open');
      },
      saveAndCloseBlock() {
        EventBus.$emit('editor.close');
      },
      saveLink() {
        if (!this.save_link) {
          return;
        }

        if (!this.link_name.length) {
          this.$toasted.error('Please name your link.');
        }

        const payload = {
          type: 'link',
          link: this.link,
          link_name: this.link_name,
        };

        api.saveSavedLinks(payload).then((response) => {
          this.$toasted.success(response.message);
          this.link_name = '';
          this.save_link = false;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      toggleSidebarTab(tab) {
        this.sidebar_tabs.build = false;
        this.sidebar_tabs.style = false;

        this.sidebar_tabs[tab] = true;
      },
      attributeEdited: _.debounce(() => {
        EventBus.$emit('email.edit.attributes');
      }, 300,
      ),
      toggleFontFamily(selectedOption) {
        if (selectedOption.safe) {
          this.currentElement.attributes['font-family'] = selectedOption.value;
        } else {
          this.currentElement.attributes['font-family'] = selectedOption.value;
          const payload = {
            type: 'fonts',
            name: selectedOption.label,
            url: `https://fonts.googleapis.com/css?family=${selectedOption.label.replace(' ', '+')}`,
          };
          EventBus.$emit('email.edit.fonts', payload);
        }

        this.attributeEdited();
      },
      toggleFontStyle() {
        if (this.currentElement.attributes['font-style'] === 'italic') {
          this.currentElement.attributes['font-style'] = null;
        } else {
          this.currentElement.attributes['font-style'] = 'italic';
        }

        this.attributeEdited();
      },
      toggleFontWeight() {
        if (this.currentElement.attributes['font-weight'] === 'bold') {
          this.currentElement.attributes['font-weight'] = null;
        } else {
          this.currentElement.attributes['font-weight'] = 'bold';
        }

        this.attributeEdited();
      },
      addFontSize: _.debounce(function customFunction(size) {
        if (size.replace(' ', '').indexOf('px') > -1) {
          this.currentElement.attributes['font-size'] = size;
        } else {
          if (isNaN(Number(size))) {
            return;
          }

          this.currentElement.attributes['font-size'] = `${Number(size)}px`;
        }

        this.attributeEdited();
      }, 300,
      ),
      fontColorEdited: _.debounce(function customFunction(data) {
        this.currentElement.attributes.color = data.hex;
        this.attributeEdited();
      }, 300,
      ),
      buttonColorEdited: _.debounce(function customFunction(data) {
        this.currentElement.attributes['background-color'] = data.hex;
        this.attributeEdited();
      }, 300,
      ),
      backgroundColorEdited: _.debounce(function customFunction(data) {
        this.currentElement.attributes['container-background-color'] = data.hex;
        this.attributeEdited();
      }, 300,
      ),
      spacingTopEdited: _.debounce(function customFunction(value) {
        this.currentElement.attributes['padding-top'] = `${value}px`;
        this.attributeEdited();
      }, 300,
      ),
      spacingBottomEdited: _.debounce(function customFunction(value) {
        this.currentElement.attributes['padding-bottom'] = `${value}px`;
        this.attributeEdited();
      }, 300,
      ),
      borderRadiusEdited: _.debounce(function customFunction(value) {
        this.currentElement.attributes['border-radius'] = `${value}px`;
        this.attributeEdited();
      }, 300,
      ),
      innerPaddingEdited: _.debounce(function customFunction() {
        const innerPadding = `${this.$refs.height.value || 0}px ${this.$refs.width.value || 0}px`;
        this.currentElement.attributes['inner-padding'] = innerPadding;

        this.attributeEdited();
      }, 300,
      ),
      borderEdited: _.debounce(function customFunction() {
        if (this.border_type === 'None') {
          this.currentElement.attributes.border = 'none';
        } else {
          this.currentElement.attributes.border = `${this.$refs.border_width.value || 1}px ${this.border_type.toLowerCase()} ${this.border_color.hex || 'transparent'}`;
        }

        this.attributeEdited();
      }, 300,
      ),
      hrefEdited: _.debounce(function customFunction() {
        if (this.$v.link.$invalid) {
          return;
        }

        let link = this.link;

        if (!link.length) {
          delete this.currentElement.attributes.href;
          this.attributeEdited();
          return;
        }

        if (this.link_type === 'Email Address') {
          link = `mailto:${this.link}`;
        }

        if (this.link_type === 'Web Address' && !this.link.toLowerCase().startsWith('http') && !this.link.toLowerCase().includes('_url')) {
          link = `http://${this.link}`;
        }

        if (this.link_type === 'Phone Number') {
          link = `tel:${link}`;
        }

        this.currentElement.attributes.href = link;
        this.attributeEdited();
      }, 300,
      ),
    },
  };
</script>
<style lang="scss" scoped>
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgb(205, 215, 226);
    padding-top: .75rem;
    padding-bottom: .75rem;
    padding-left: 1.5rem;
    background-color: white;
    z-index: 1001;
  }

  .tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tabs a {
    width: 50%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: rgb(29, 54, 75);
    font-size: .875rem;
    text-align: center;
    border-bottom: 2px solid rgb(217, 231, 240);
    transition: all 150ms ease-in-out;
  }

  .tabs a:hover,
  .tabs a.active {
    text-decoration: none;
    border-bottom: 2px solid var(--xpl-color-secondary-lm);
  }

  .banner {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1.5rem;
    background-color: var(--xpl-color-background-lm);
    border-top: 1px solid #d9e7f0;
    border-bottom: 1px solid #d9e7f0;
    font-size: .75rem;
    line-height: 1rem;
    color: rgb(139, 155, 172);
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  form {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  label {
    font-size: .875rem;
    line-height: 20px;
    color: rgb(29, 54, 75);
    margin-bottom: .25rem;
  }

  .group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .group .form-group {
    margin-right: .5rem;
  }

  .group .form-group:last-child {
    margin-right: 0;
  }

  .alignments,
  .styles {
    display: flex;
    align-items: center;
  }

  .alignments .alignment,
  .styles .style {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.25rem;
    height: 2.5rem;
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    border: 1px solid  rgb(175, 188, 202);
    margin-right: .25rem;
    transition: all 150ms ease-in-out;
    color: #617080;
  }

  .alignments .alignment:last-child,
  .styles .style:last-child {
    margin-right: 0;
  }

  .alignments .alignment:hover,
  .alignments .alignment.selected,
  .styles .style:hover,
  .styles .style.selected {
    border: 2px solid var(--xpl-color-secondary-lm);
    color: var(--xpl-color-secondary-lm);
  }

  .styles .style {
    font-size: .75rem;
  }

  .input-group {
    z-index: 0;
  }

  .input-group-addon {
    padding: .25rem .5rem;
    padding-right: 0;
    cursor: pointer;
  }

  .icon-square {
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 1.5rem;
    cursor: pointer;
  }

  input[readonly] {
    background-color: white;
    cursor: pointer;
  }

  .input-group .form-control {
    z-index: 1 !important;
  }

  .build_tab,
  .style_tab {
    padding-bottom: 4rem;
  }

  .build_tab {
    padding-top: 1rem;
  }
</style>
