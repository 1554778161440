<template>
  <div class="sidebar-inner" style="width: 100%;">
    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">1</span>
      </el-col>
      <el-col :span="22" class="v2__heading">Which Waiver did the contact fill out?</el-col>
    </el-row>

    <el-row :span="24" style="margin-bottom: 2rem;">
      <el-col :span="22" :offset="2">
        <el-select v-model="selected_form" filterable placeholder="Select a form" style="width: 100%;" value-key="uuid" @change="fetchFields">
          <el-option v-for="item in forms" :key="item.uuid" :label="item.name" :value="item"></el-option>
        </el-select>
      </el-col>
    </el-row>

    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">3</span>
      </el-col>
      <el-col :span="22" class="v2__heading">Did they answer a custom question?</el-col>
    </el-row>

    <el-row :span="24" style="margin-bottom: 2rem;">
      <el-col :span="22" :offset="2">
        <el-radio-group v-model="answered_custom">
          <el-radio :label="true" border>Yes</el-radio>
          <el-radio :label="false" border>No</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>

    <div v-if="answered_custom">
      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">4</span>
        </el-col>
        <el-col :span="22" class="v2__heading">What question did they answer?</el-col>
      </el-row>

      <el-row :span="24" style="margin-bottom: 2rem;">
        <el-col :span="22" :offset="2">
          <el-select v-model="selected_field" filterable placeholder="Select a question" style="width: 100%;" :disabled="!fields.length" :loading="fieldsLoading" value-key="id" @change="fetchAnswers">
            <el-option v-for="item in fields" :key="item.id" :label="item.label" :value="item">
              <span>{{ item.label }}</span>
              <span class="display-caption text-muted pull-right">
                {{ item.type }}
              </span>
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row style="margin-bottom: 1.25rem;" v-if="selected_field">
        <el-col :span="2">
          <span class="step-circle">5</span>
        </el-col>
        <el-col :span="22" class="v2__heading">What was their answer?</el-col>
      </el-row>

        <el-row :span="24">
          <el-col :span="22" :offset="2">
            <el-card :shadow="'none'" style="margin-bottom: 2rem;" v-if="selected_field" v-loading="answersLoading">
              <div class="" style="margin-bottom: 1.5rem;">Options</div>
              <div v-if="selected_field.type === 'text'">
                <el-row>
                  <el-radio v-model="option" label="is" size="small">Is</el-radio>
                  <el-col :offset="1" :span="23">
                    <el-input v-model="answer" size="small" v-if="option === 'is'" />
                  </el-col>
                </el-row>
                <el-row>
                  <el-radio v-model="option" label="is-not" size="small">Is not</el-radio>
                  <el-col :offset="1" :span="23">
                    <el-input v-model="answer" size="small" v-if="option === 'is-not'" />
                  </el-col>
                </el-row>
                <el-row>
                  <el-radio v-model="option" label="contains" size="small">Contains</el-radio>
                  <el-col :offset="1" :span="23">
                    <el-input v-model="answer" size="small" v-if="option === 'contains'" />
                  </el-col>
                </el-row>
                <el-row>
                  <el-radio v-model="option" label="contains-not" size="small">Does not contain</el-radio>
                  <el-col :offset="1" :span="23">
                    <el-input v-model="answer" size="small" v-if="option === 'contains-not'" />
                  </el-col>
                </el-row>
              </div>
              <div v-if="selected_field.type === 'dropdown'">
                <el-row>
                  <el-radio v-model="option" label="is" size="small">Is</el-radio>
                  <el-col :offset="1" :span="23">
                    <el-select v-model="answer" filterable placeholder="Answer" size="small" v-if="option === 'is'">
                      <el-option v-for="item in field_answers" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                  </el-col>
                </el-row>
                <el-row>
                  <el-radio v-model="option" label="is-not" size="small">Is not</el-radio>
                  <el-col :offset="1" :span="23">
                    <el-select v-model="answer" filterable placeholder="Answer" size="small" v-if="option === 'is-not'">
                      <el-option v-for="item in field_answers" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                  </el-col>
                </el-row>
              </div>
              <div v-if="selected_field.type === 'multi'">
                <el-row>
                  <el-radio v-model="option" label="is" size="small">Is</el-radio>
                  <el-col :offset="1" :span="23">
                    <el-select v-model="answer" filterable multiple placeholder="Answer" size="small" v-if="option === 'is'">
                      <el-option v-for="item in field_answers" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                  </el-col>
                </el-row>
                <el-row>
                  <el-radio v-model="option" label="is-not" size="small">Is not</el-radio>
                  <el-col :offset="1" :span="23">
                    <el-select v-model="answer" filterable multiple placeholder="Answer" size="small" v-if="option === 'is-not'">
                      <el-option v-for="item in field_answers" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                  </el-col>
                </el-row>
              </div>
              <div v-if="selected_field.type === 'rating'">
                <el-radio-group v-model="comparison">
                  <el-radio label="less" border>Rating less than</el-radio>
                  <el-radio label="exactly" border>Rating exactly</el-radio>
                  <el-radio label="more" border>Rating more than</el-radio>
                  <el-radio label="between" border>Rating between</el-radio>
                </el-radio-group>
                <div class="options" v-if="comparison">
                  <el-input-number v-model="answer" />
                  <span v-if="comparison === 'between'">and</span>
                  <el-input-number v-model="answer_2" v-if="comparison === 'between'" />
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>

    <div class="form-group">
      <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition" :disabled="!selected_form">Save this condition</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['condition', 'editedRule'],
    data() {
      return {
        answersLoading: false,
        fieldsLoading: false,
        answered_custom: false,
        timeframe: null,
        forms: [],
        selected_form: null,
        fields: [],
        selected_field: null,
        option: null,
        answer: null,
        answer_2: null,
        field_answers: [],
        comparison: null,
      };
    },
    created() {
      this.fetchForms();

      if (this.editedRule) {
        this.selected_form = this.editedRule.form;
        this.selected_field = this.editedRule.field;
        this.answer = this.editedRule.answer;
        this.answer_2 = this.editedRule.answer_2;
        this.comparison = this.editedRule.comparison;
        this.option = this.editedRule.option;
        this.answered_custom = this.editedRule.answered_custom;

        if (this.selected_form) {
          this.fetchFields(this.selected_form);
        }

        if (this.selected_field) {
          this.fetchAnswers(this.selected_field);
        }
      }
    },
    methods: {
      fetchForms() {
        api.getWaiversSmall().then((response) => {
          this.forms = response.waivers;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchFields(form) {
        this.fieldsLoading = true;
        if (!this.editedRule) {
          this.selected_field = null;
        }
        api.getFormFields(form.id).then((response) => {
          this.fieldsLoading = false;
          this.fields = response.fields;
        }).catch((err) => {
          this.fieldsLoading = false;
          this.$toasted.error(err.message);
        });
      },
      fetchAnswers(customField) {
        if (!this.editedRule) {
          this.answer = null;
          this.answer_2 = null;
          this.option = null;
        }
        if (customField.type === 'text' || customField.type === 'rating') {
          return;
        }

        this.answersLoading = true;
        api.fetchFormCustomAnswers(customField.id).then((response) => {
          this.answersLoading = false;
          this.field_answers = response.answers;
        }).catch((err) => {
          this.answersLoading = false;
          this.$message.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          answered_custom: this.answered_custom,
          form: this.selected_form,
          field: this.selected_field,
          answer: this.answer,
          answer_2: this.answer_2,
          comparison: this.comparison,
          option: this.option,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
 .options {
    padding: 1.25rem;
    background-color: #f5f8fb;
    margin-bottom: 1.5rem;
  }
</style>
