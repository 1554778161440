<template>
  <transition name="fade">
    <div class="modal show" style="display: block;" @click="closeModal">
      <div class="modal-dialog" @click.stop>
        <div class="modal-content">
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <label for="card-element">Credit or debit card</label>
                <div id="card-element"></div>
                <div id="card-errors" role="alert"></div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="button" class="xpl-button xpl-button--primary" @click.prevent="process">Save</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    data() {
      return {
        card: null,
        stripe: null,
      };
    },
    mounted() {
      // this.stripe = Stripe('pk_test_6qL03UkGusboyl8sBnlWFLmI');
      // eslint-disable-next-line
      this.stripe = Stripe('pk_live_4veErhAzP9Yoby3svTd1ObjH');
      const elements = this.stripe.elements();

      // Custom styling can be passed to options when creating an Element.
      const style = {
        base: {
          color: '#32325d',
          lineHeight: '18px',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      };

      // Create an instance of the card Element.
      this.card = elements.create('card', { style });

      // Add an instance of the card Element into the `card-element` <div>.
      this.card.mount('#card-element');

      this.card.addEventListener('change', ({ error }) => {
        const displayError = document.getElementById('card-errors');
        if (error) {
          displayError.textContent = error.message;
        } else {
          displayError.textContent = '';
        }
      });
    },
    methods: {
      closeModal(success = null) {
        EventBus.$emit('update_card_modal.close', success);
      },
      process(event) {
        const target = event.target || event.srcElement;
        const originalInner = target.innerHTML;
        target.disabled = true;
        target.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';

        this.stripe.createToken(this.card).then((response) => {
          if (response.error) {
            const errorElement = document.getElementById('card-errors');
            errorElement.textContent = response.error.message;
            target.disabled = false;
            target.innerHTML = originalInner;
          } else {
            api.updateAccountCard({ token: response.token.id }).then((res) => {
              this.$toasted.success(res.message);
              this.closeModal(true);
            }).catch((err) => {
              target.disabled = false;
              target.innerHTML = originalInner;
              this.$toasted.error(err.message);
            });
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  margin-top: 1rem;
  background-color: white;
  height: 2.5rem;
  padding: .625rem .75rem;
  border-radius: .25rem;
  border: 1px solid transparent;
  // box-shadow: 0 1px 3px 0 #e6ebf1;
  // transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  // box-shadow: 0 1px 3px 0 var(--xpl-color-secondary-lm);
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

#card-errors {
  margin-top: .5rem;
  color: red;
  font-size: 80%;
}
</style>
