<template>
  <div>
    <el-container>
      <el-header>
        <div class="display-small">Targeted SMS</div>
      </el-header>
      <el-container class="inner">
       <el-aside width="17rem">
          <div :class="['item', 'active']">
            All Targeted SMS
          </div>
        </el-aside>
        <el-main v-loading="loading">
          <el-row :gutter="24" class="heading">
            <el-col :span="12">
              <div class="display-medium">
                All Targeted SMS
              </div>
            </el-col>
            <el-col :span="8" :offset="4" style="display: flex; justify-content: flex-end;">
              <el-button type="primary" class="xpl-button xpl-button--primary" @click.prevent="newTargeted">Create a new Targeted SMS</el-button>
            </el-col>
          </el-row>

          <el-row :gutter="24" class="search">
            <el-col :span="24">
              <el-input prefix-icon="el-icon-search" placeholder="Search" v-model="query"></el-input>
            </el-col>
          </el-row>

          <el-card
            shadow="none"
            v-for="targeted in filteredTargeted"
            @click.native="$router.push('/apps/targeted-sms/' + targeted.uuid)"
            :key="targeted.id">
            <div class="name">{{ targeted.name }}</div>
            <div class="small text-muted">Last updated on {{ targeted.updated_at | humanDateTime }}</div>
            <div class="text-success small" v-if="targeted.sent">Sent</div>
          </el-card>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
  import {
    getTargetedSMS,
    createTargetedSMS,
  } from '@/api';

  export default {
    data() {
      return {
        loading: false,
        query: '',
        targeted_sms: {},
      };
    },
    created() {
      this.fetchTargetedSMS();
    },
    computed: {
      filteredTargeted() {
        if (!this.targeted_sms.data) {
          return [];
        }

        return this.targeted_sms.data;
      },
    },
    methods: {
      fetchTargetedSMS() {
        this.loading = true;
        getTargetedSMS().then((response) => {
          this.targeted_sms = response.targeted_sms;
          this.loading = false;
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      newTargeted() {
        this.$prompt('Name', 'New Targeted SMS', {
          confirmButtonText: 'Create',
          cancelButtonText: 'Cancel',
          inputPattern: /^.{3,}$/,
          inputErrorMessage: 'A Targeted SMS name of at least 3 characters is required.',
        }).then(({ value }) => {
          this.postTargeted(value);
        });
      },
      postTargeted(name) {
        const payload = {
          name,
        };
        createTargetedSMS(payload).then((response) => {
          this.$message.success(response.message);
          this.fetchTargetedSMS();
          this.$router.push(`/apps/targeted-sms/${response.targeted_sms.uuid}`);
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-header {
    display: block;
    width: 100%;
    position: fixed;
    z-index: 100;
    border-bottom: 1px solid var(--xpl-color-gray-400);
    padding-left: 1.25rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .el-main {
    margin-top: 3.75rem;
    height: calc(100vh - 6.75rem);
  }

  .el-aside {
    margin-top: 3.75rem;
    height: calc(100vh - 6.75rem);
    padding-top: 2.5rem;
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .el-aside .item {
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: .875rem;
    color: #2e4457;
    transition: all 150ms ease-in-out;
    border-radius: 6px;
    margin-bottom: .375rem;
  }

  .el-aside .item:hover,
  .el-aside .item.active {
    cursor: pointer;
    background-color: var(--xpl-color-secondary-bg-lm);
  }

  .el-aside .item.active {
    font-weight: 700;
  }

  .el-aside .item .count {
    margin-left: auto;
    color: #617080;
  }

  .el-aside .heading {
    padding-left: 1rem;
    margin-top: 1.875rem;
    font-size: 1rem;
    font-weight: 700;
    color: #00152a;
    margin-bottom: 1.25rem;
  }

  .el-aside a {
    display: block;
    margin-top: 1rem;
    padding-left: 1rem;
    font-size: .875rem;
    color: #6923f4;
  }

  .container .container-inner {
    height: calc(100vh - 4rem);
    display: block;
    width: 100%;
    margin-top: 4rem;
    padding-top: 1.875rem;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .el-main .heading {
    margin-bottom: 1.875rem;
  }

  .el-main .heading .display-medium {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .el-row.search {
    margin-bottom: 2.5rem;
  }

  .el-card + .el-card {
    margin-top: 1rem;
  }

  .el-card:hover,
  .el-card:focus {
    border-color: var(--xpl-color-secondary-lm);
    cursor: pointer;
  }

  .el-card .name {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #1d364b;
  }
</style>
