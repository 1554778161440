<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner">
      Has / Has Not
    </div>
    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="!purchased" @click="purchased = true"></span>
            <span class="icon icon-radio-checked" v-if="purchased" @click="purchased = true"></span>
          </div>
          <div class="col-xs-10">
            <label for="purchased_yes" @click="purchased = true">
              Has visited after a purchase
            </label>
            <div class="small text-muted">Get all contacts who have visited after a purchase with the options below</div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="purchased === null || purchased === true" @click="purchased = false"></span>
            <span class="icon icon-radio-checked" v-if="purchased === false" @click="purchased = false"></span>
          </div>
          <div class="col-xs-10">
            <label for="purchased_no" @click="purchased = false">
              Has not visited after a purchase
            </label>
            <div class="small text-muted">Get all contacts who have not visited after a purchase with the options below</div>
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Purchase Details
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="purchase_type === null || purchase_type != 'any'" @click="purchase_type = 'any'"></span>
            <span class="icon icon-radio-checked" v-if="purchase_type === 'any'" @click="purchase_type = 'any'"></span>
          </div>
          <div class="col-xs-10">
            <label for="purchase_type_any" @click="purchase_type = 'any'">
              <span v-if="!enrollment">
                Contact has<span v-if="purchased === false"> not</span> visited after any purchase
              </span>
              <span v-if="enrollment">Contact makes any purchase and visits</span>
            </label>
            <div class="small text-muted">Get contacts who visit after any purchase with the following details</div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="purchase_type === null || purchase_type != 'specific'" @click="purchase_type = 'specific'"></span>
            <span class="icon icon-radio-checked" v-if="purchase_type === 'specific'" @click="purchase_type = 'specific'"></span>
          </div>
          <div class="col-xs-10">
            <label for="purchase_type_specific" @click="purchase_type = 'specific'">
              <span v-if="!enrollment">
                Contact has<span v-if="purchased === false"> not</span> visited after a specific type of purchase
              </span>
              <span v-if="enrollment">Contact makes a <em>specific</em> purchase and visits</span>
            </label>
            <div class="small text-muted">Get contacts who visit after a specific purchase with the following details</div>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="purchase_type === 'specific'">
        <div class="form-group">
          <label for="pricing_options">Pricing Option(s)</label>
          <div class="multiselect-block">
            <Multiselect v-model="selected_pricing_options" placeholder="Select Pricing Option(s)" :options="pricing_options" label="name" track-by="id" :searchable="true" :allow-empty="true" :show-labels="false" :multiple="true" :close-on-select="false" />
          </div>
        </div>

        <div v-if="see_more">
          <div class="form-group">
            <label for="service_categories">Service Categories</label>
            <div class="multiselect-block">
              <Multiselect v-model="selected_service_categories" placeholder="Select Service Categories" :options="service_categories" label="name" track-by="id" :searchable="true" :allow-empty="true" :show-labels="false" :multiple="true" :close-on-select="false" />
            </div>
          </div>
          <div class="form-group">
            <label for="revenue_categories">Revenue Categories</label>
            <div class="multiselect-block">
              <Multiselect v-model="selected_revenue_categories" placeholder="Select Revenue Categories" :options="revenue_categories" label="name" track-by="id" :searchable="true" :allow-empty="true" :show-labels="false" :multiple="true" :close-on-select="false" />
            </div>
          </div>
        </div>

        <div class="text-muted small see-more" v-if="purchase_type === 'specific' && !see_more" @click="see_more = !see_more">Show more options (Service Categories... etc) <span class="small icon icon-arrow-down3"></span></div>
        <div class="text-muted small see-more" v-if="purchase_type === 'specific' && see_more" @click="see_more = !see_more">Hide more options <span class="small icon icon-arrow-up3"></span></div>
      </div>
    </form>

    <div class="condition-banner">
      Time Frame
    </div>

    <form @submit.prevent>
      <div class="form-group" v-if="goalList">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'entering_campaign'" @click="timeframe = 'entering_campaign'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'entering_campaign'" @click="timeframe = 'entering_campaign'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label @click="timeframe = 'entering_campaign'; comparison = null;">
              Since entering this campaign
            </label>
            <small class="form-text text-muted">Isolates the first time a client purchases this specific type of purchase.</small>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'absolute'" @click="timeframe = 'absolute'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'absolute'" @click="timeframe = 'absolute'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_absolute" @click="timeframe = 'absolute'; comparison = null;">
              Purchases around specific dates
            </label>
            <div class="small text-muted">Select before, after, or between static dates. e.g. After July 27th 2018</div>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="timeframe === 'absolute'">
        <div class="form-inline">
          <label>These purchases occured </label>
          <Multiselect v-model="comparison" placeholder="Select a comparison" :options="comparisons_date" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" />
        </div>
        <div class="dates" v-if="comparison && timeframe === 'absolute'">
          <div class="date">
            <vue-datepicker-local v-model="dates.start" :local="local" format="MM-DD-YYYY" />
          </div>
          <span v-if="comparison.value === 'between'">&nbsp;and&nbsp;</span>
          <div class="date" v-if="comparison.value === 'between'">
            <vue-datepicker-local v-model="dates.end" :local="local" format="MM-DD-YYYY" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'relative'" @click="timeframe = 'relative'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'relative'" @click="timeframe = 'relative'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_relative" @click="timeframe = 'relative'; comparison = null;">
              Purchases relative to the current date
            </label>
            <div class="small text-muted">Select at least, less than, or exactly a number of days ago. This is a moving window of time. e.g. Within the last 10 days</div>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="timeframe === 'relative'">
        <div class="form-inline">
          Every contact who made a purchase
          <Multiselect v-model="comparison" placeholder="Select a comparison" :options="comparisons_number" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" />
          <input type="number" name="relative_days" v-model.number="relative_days" class="form-control" min="0" style="display: inline-block; width: 5rem;" />
          days<span v-if="comparison && comparison.value !== 'within'"> ago</span>.
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'all'" @click="timeframe = 'all'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'all'" @click="timeframe = 'all'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_all" @click="timeframe = 'all'; comparison = null;">
              All time
            </label>
            <div class="small text-muted">See if the contact has met this condition in their entire lifetime</div>
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Location and Site ID (Optional)
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="misc_filtering === null || misc_filtering === true" @click="misc_filtering = false"></span>
            <span class="icon icon-radio-checked" v-if="misc_filtering === false" @click="misc_filtering = false"></span>
          </div>
          <div class="col-xs-10">
            <label for="misc_filtering_no" @click="misc_filtering = false">
              Select all locations and Site IDs
            </label>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="misc_filtering === null || misc_filtering === false" @click="misc_filtering = true"></span>
            <span class="icon icon-radio-checked" v-if="misc_filtering === true" @click="misc_filtering = true"></span>
          </div>
          <div class="col-xs-10">
            <label for="misc_filtering_yes" @click="misc_filtering = true">
               Select specific locations or Site IDs
            </label>
          </div>
        </div>
      </div>
      <div class="options-inner" v-if="misc_filtering">
        <div class="form-group">
          <label class="form-control-label">Location</label>
          <div class="multiselect-block">
            <Multiselect v-model="location" placeholder="All Locations" label="location_name" track-by="location_id" :options="locations" :searchable="false" :allow-empty="true" :show-labels="false" :close-on-select="false" />
          </div>
        </div>
      </div>
    </form>

    <form @submit.prevent>
      <div class="form-group">
        <button type="button" class="xpl-button xpl-button--primary" @click.stop="commitCondition">Save this condition</button>
      </div>
    </form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';
  import VueDatepickerLocal from 'vue-datepicker-local';

  export default {
    props: ['locations', 'condition', 'editedRule', 'enrollment', 'goalList'],
    data() {
      return {
        edit_state: false,
        see_more: false,
        purchased: true,
        purchase_type: 'any',
        purchased_nothing_before: false,
        purchased_this_before: false,
        timeframe: 'all',
        comparison: null,
        relative_days: 0,
        misc_filtering: false,
        location: null,
        comparisons_date: [{
          label: 'After',
          value: 'after',
        }, {
          label: 'Before',
          value: 'before',
        }, {
          label: 'Between',
          value: 'between',
        }],
        comparisons_number: [{
          label: 'At least',
          value: 'more',
        }, {
          label: 'Within the last',
          value: 'within',
        }, {
          label: 'Exactly',
          value: 'exactly',
        }],
        dates: {
          start: '',
          end: '',
        },
        pricing_options: [],
        selected_pricing_options: [],
        service_categories: [],
        selected_service_categories: [],
        revenue_categories: [],
        selected_revenue_categories: [],
        local: {
          dow: 1,
          hourTip: '',
          minuteTip: '',
          secondTip: '',
          yearSuffix: '',
          monthsHead: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
          months: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
          weeks: 'Mon_Tue_Wed_Thu_Fri_Sat_Sun'.split('_'),
        },
      };
    },
    components: {
      Multiselect,
      VueDatepickerLocal,
    },
    created() {
      this.fetchPricingOptions();
      this.fetchServiceCategories();
      this.fetchRevenueCategories();

      if (this.editedRule) {
        this.purchased = this.editedRule.purchase;
        this.timeframe = this.editedRule.timeframe;

        if (this.timeframe === 'relative') {
          this.comparison = this.comparisons_number.filter(c =>
            c.value === this.editedRule.comparison,
          )[0];
        }
        if (this.timeframe === 'absolute') {
          this.comparison = this.comparisons_date.filter(c =>
            c.value === this.editedRule.comparison,
          )[0];
        }

        this.relative_days = this.editedRule.relative_days;
        this.dates.start = this.editedRule.start_date;
        this.dates.end = this.editedRule.end_date;

        this.purchase_type = this.editedRule.purchase_type;
        this.selected_pricing_options = this.editedRule.pricing_options;
        this.selected_service_categories = this.editedRule.service_categories;
        this.selected_revenue_categories = this.editedRule.revenue_categories;

        this.misc_filtering = this.editedRule.misc.filtering;
        if (this.misc_filtering) {
          this.location = this.locations.filter(l =>
            l.location_id === this.editedRule.misc.location,
          )[0];
        }
      }
    },
    methods: {
      fetchPricingOptions() {
        api.getPricingOptions().then((response) => {
          this.pricing_options = response.pricing_options;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchServiceCategories() {
        api.getServiceCategories().then((response) => {
          this.service_categories = response.service_categories;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchRevenueCategories() {
        api.getRevenueCategories().then((response) => {
          this.revenue_categories = response.revenue_categories;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          purchase: this.purchased,
          purchase_type: this.purchase_type,
          pricing_options: this.selected_pricing_options,
          service_categories: this.selected_service_categories,
          revenue_categories: this.selected_revenue_categories,
          timeframe: this.timeframe,
          relative_days: this.relative_days,
          comparison: this.comparison ? this.comparison.value : null,
          start_date: this.dates.start,
          end_date: this.dates.end,
          misc: {
            filtering: this.misc_filtering,
            location: this.location ? this.location.location_id : null,
            site_id: null,
          },
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar-inner {
    position: relative;
  }

  .sidebar-inner .condition-banner {
    margin-top: 2rem;
    background-color: #f2f6fa;
    font-size: .75rem;
    font-weight: 700;
    line-height: 1rem;
    margin-bottom: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1.5rem;
    color: #8b9bac;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    text-transform: uppercase;
  }

  .sidebar-inner .condition-banner:first-child {
    margin-top: 0;
  }

  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .see-more {
    cursor: pointer;
  }

  .icon-radio-checked,
  .icon-radio-unchecked {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }

  .footer {
    position: fixed;
    display: flex;
    align-items: center;
    bottom: 0;
    width: 100%;
    border-top: 1px solid var(--xpl-color-gray-400);
    padding-top: .75rem;
    padding-bottom: .75rem;
    padding-left: 1.5rem;
    background-color: white;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    z-index: 1001;
  }

  .footer button {
    margin: 0;
  }
</style>
