<template>
  <el-card shadow="never">
    <h1>Style your brand</h1>
    <el-form ref="brandingForm" :model="branding" :rules="rules" status-icon @submit.native.prevent>
      <el-form-item prop="logo">
        <label>Logo</label>
        <el-upload
          :multiple="false"
          :limit="1"
          ref="logo"
          accept="image/*"
          action=""
          list-type="picture"
          :http-request="uploadLogo">
            <el-button size="small" type="primary" class="xpl-button xpl-button--primary" :loading="loading" :disabled="branding.logo">{{ branding.logo ? 'Uploaded!' : 'Upload a Logo' }}</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item prop="color">
        <label>Choose your most used color</label>
        <div>
          <el-color-picker v-model="branding.color"></el-color-picker>
        </div>
        <div class="display-caption subdued">This will be used for buttons and headers</div>
      </el-form-item>
      <el-form-item prop="font">
        <label>Choose your font</label>
        <div>
          <el-select v-model="branding.font" filterable placeholder="Select a font" style="width: 100%;">
            <el-option v-for="item in fonts" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="display-caption subdued">This will be used for headers</div>
      </el-form-item>
      <el-form-item style="text-align: center; margin-top: 1.5rem;">
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="saveBranding" :loading="loading">Finish</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
  import fonts from '@/components/FullEmailEditor/EditBlocks/fonts.json';
  import * as api from '@/api';
  import EventBus from '@/event-bus';

  export default {
    data() {
      return {
        loading: false,
        fonts,
        branding: {
          font: null,
          color: null,
          logo: false,
        },
        rules: {
          font: [{ required: true, trigger: 'change', message: 'Please select a font' }],
          logo: [{ required: true, type: 'boolean', trigger: 'change', message: 'Please upload a logo' }],
          color: [{ required: true, trigger: 'change', message: 'Please select a color' }],
        },
      };
    },
    methods: {
      saveBranding() {
        this.$refs.brandingForm.validate((valid) => {
          if (!valid) {
            return;
          }

          this.loading = true;

          const payload = {
            primary_color: this.branding.color,
            header_font_family: this.branding.font,
          };
          api.saveBrandingMetadata(payload).then(() => {
            this.loading = false;
            this.$store.dispatch('refreshUser').then(() => {
              EventBus.$emit('signup.branding.done');
            });
          }).catch((err) => {
            this.loading = false;
            if (err.errors) {
              this.$message.error(err.errors[Object.keys(err.errors)[0]][0]);
            } else {
              this.$message
               .error('We were unable to upload your logo. Please try again later.');
            }
          });
        });
      },
      uploadLogo(payload) {
        const formData = new FormData();
        formData.append('type', 'image');
        formData.append('logo_file', payload.file);

        this.loading = true;
        api.saveBrandingLogo(formData).then(() => {
          this.$message.success('Logo uploaded!');
          this.branding.logo = true;
          this.loading = false;
        }).catch((err) => {
          this.loading = false;
          if (err.errors) {
            this.$message.error(err.errors[Object.keys(err.errors)[0]][0]);
          } else {
            this.$message
             .error('We were unable to upload your logo. Please try again later.');
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-card {
    max-width: 500px;
    width: 500px;
    min-width: 500px;
    border: none;
  }

  .el-card h1 {
    font-size: 1.75rem;
    color: #00152a;
    margin-bottom: 2.5rem;
  }

  .el-form-item {
    margin-bottom: 1rem;
  }
</style>
