<template>
  <div :title="selectedCondition.uuid" style="width: 100%; min-height: 33vh">
    <Birthday v-if="selectedCondition.uuid === 'e5c8a7af-e573-4d53-82a1-0c8f99bacfec'"
              :editedRule.sync="editedRule"
              :condition="selectedCondition"
              :enrollment="enrollment"/>
    <Gender v-if="selectedCondition.uuid === 'b48aa38b-6476-485e-b4f8-f2b1bce1c679'"
            :editedRule.sync="editedRule"
            :condition="selectedCondition"/>
    <InteractedWithEmail v-if="selectedCondition.uuid === '9ebf3698-2407-4d70-97f1-afa09adddac9'"
                         :editedRule.sync="editedRule"
                         :condition="selectedCondition"
                         :enrollment="enrollment" />
    <JoinedSignupForm v-if="selectedCondition.uuid === 'ac08df3e-b14a-4760-8068-54585cb19d9e'"
                      :editedRule.sync="editedRule"
                      :condition="selectedCondition"
                      :enrollment="enrollment"
                      :goalList="goalList"/>
    <Unsubscribed v-if="selectedCondition.uuid === '2fad64a8-f2e4-47e7-9429-36b18877850a'"
                  :editedRule.sync="editedRule"
                  :condition="selectedCondition"
                  :enrollment="enrollment"
                  :goalList="goalList"/>
    <HasBrandbotTag v-if="selectedCondition.uuid === 'f511f8f8-cd8f-40af-bc86-99b1faef9a02'"
                    :editedRule.sync="editedRule"
                    :condition="selectedCondition"
                    :enrollment="enrollment"
                    :goalList="goalList"/>
    <AssignedBrandBotTag v-if="selectedCondition.uuid === '18da4f54-1615-4766-b5ee-c2e7b7334a67'"
                         :editedRule.sync="editedRule"
                         :condition="selectedCondition"
                         :enrollment="enrollment"
                         :goalList="goalList"/>
    <PreferredLocation v-if="selectedCondition.uuid === '5a03d4d7-648c-47a3-99a0-2b9b51b38886'"
                       :editedRule.sync="editedRule"
                       :condition="selectedCondition"
                       :locations="locations"/>
    <InSegment v-if="selectedCondition.uuid === 'a848f0f6-29c9-4f85-a4d0-bc188f09670b'"
               :editedRule.sync="editedRule"
               :condition="selectedCondition"
               :locations="locations"/>
    <RepliedToSMSWithKeyword v-if="selectedCondition.uuid === '3992bfd3-9d39-4811-86d2-0e85675001d6'"
                             :editedRule.sync="editedRule"
                             :condition="selectedCondition"/>
    <FunnelStage v-if="selectedCondition.uuid === '72a8d969-dc52-4131-a1a6-0feb2123a92f'"
                 :editedRule.sync="editedRule"
                 :condition="selectedCondition"/>
    <GeneralEvent v-if="selectedCondition.uuid === '989c7172-3159-4846-98a9-298393002583'"
                  :editedRule.sync="editedRule"
                  :condition="selectedCondition"
                  :enrollment="enrollment"
                  :goalList="goalList"/>
    <LandedLandingPage v-if="selectedCondition.uuid === '65f2cd79-db45-4005-bea3-eeca722d4bea'"
                       :editedRule.sync="editedRule"
                       :condition="selectedCondition"
                       :enrollment="enrollment"
                       :goalList="goalList"/>
    <FinishedLandingPage v-if="selectedCondition.uuid === '79256903-1854-41c5-8b40-f256ed2c5fb8'"
                         :editedRule.sync="editedRule"
                         :condition="selectedCondition"
                         :enrollment="enrollment"
                         :goalList="goalList"/>
    <AbandonnedLandingPage v-if="selectedCondition.uuid === 'c9187025-81e2-4475-b469-cdc054a156a6'"
                           :editedRule.sync="editedRule"
                           :condition="selectedCondition"
                           :enrollment="enrollment"
                           :goalList="goalList"/>
    <AnsweredAForm v-if="selectedCondition.uuid === '7302c598-ab99-4091-9090-69850d4dc50e'"
                   :editedRule.sync="editedRule"
                   :condition="selectedCondition"
                   :enrollment="enrollment"
                   :goalList="goalList"/>
    <AnsweredWaiver v-if="selectedCondition.uuid === '956e61a7-73be-4c41-9a7c-9b7958352b93'"
                    :editedRule.sync="editedRule"
                    :condition="selectedCondition"
                    :enrollment="enrollment"
                    :goalList="goalList"/>
    <UnsubscribedSMS v-if="selectedCondition.uuid === '15929b17-3492-4bc6-91c2-253f7022b3b5'"
                     :editedRule.sync="editedRule"
                     :condition="selectedCondition"
                     :enrollment="enrollment"
                     :goalList="goalList"/>
  </div>
</template>

<script>
import Birthday from '../../Birthday';
import Gender from '../../Gender';
import JoinedSignupForm from '../../JoinedSignupForm';
import Unsubscribed from '../../../../AllContacts/Unsubscribed';
import HasBrandbotTag from '../../HasBrandbotTag';
import AssignedBrandBotTag from '../../AssignedBrandBotTag';
import PreferredLocation from '../../PreferredLocation';
import InSegment from '../../InSegment';
import RepliedToSMSWithKeyword from '../../RepliedToSMSWithKeyword';
import FunnelStage from '../../FunnelStage';
import GeneralEvent from '../../GeneralEvent';
import LandedLandingPage from '../../LandedLandingPage';
import FinishedLandingPage from '../../FinishedLandingPage';
import AbandonnedLandingPage from '../../AbandonnedLandingPage';
import AnsweredAForm from '../../AnsweredAForm';
import AnsweredWaiver from '../../AnsweredWaiver';
import UnsubscribedSMS from '../../UnsubscribedSMS';
import EmailInteraction from '../../../../../Campaigns/Campaign/Quick/EmailInteraction';
import InteractedWithEmail from '../../InteractedWithEmail';

export default {
  name: 'BrandBotField',
  components: {
    InteractedWithEmail,
    EmailInteraction,
    UnsubscribedSMS,
    AnsweredWaiver,
    AnsweredAForm,
    AbandonnedLandingPage,
    FinishedLandingPage,
    LandedLandingPage,
    GeneralEvent,
    FunnelStage,
    RepliedToSMSWithKeyword,
    InSegment,
    PreferredLocation,
    AssignedBrandBotTag,
    HasBrandbotTag,
    Unsubscribed,
    JoinedSignupForm,
    Gender,
    Birthday,
  },
  props: ['editedRule', 'selectedCondition', 'enrollment', 'goalList'],
  data() {
    return {
      locations: [],
    };
  },
};
</script>

<style scoped>

</style>
