<template>
  <transition name="fade">
    <div class="lists-container">
      <div class="lists-sidebar">
        <div :class="['group', filter_group ? '' : 'selected']" @click.prevent="filter_group = null">All Segments</div>
        <h6>Segment Groups</h6>
        <div>
          <div :class="['group', filter_group && filter_group.id === group.id ? 'selected' : '']" @click.prevent="filter_group = group" v-for="group in list_groups">
            {{ group.name }}
            <span class="material-icons" @click.stop="deleteListGroup(group)">delete</span>
          </div>
        </div>
        <a href="#" @click.prevent="new_group_modal = !new_group_modal" style="padding-left: .5rem;">Add Segment Group</a>
      </div>
      <div class="lists-inner">
        <div class="container-header">
          <h2 v-if="!filter_group">All Segments</h2>
          <div v-if="filter_group">
            <input type="text" v-model="filter_group.name" class="form-control group-name" @focus="toggleHelp" @blur="toggleHelp" @keyup.enter="editGroupName" />
            <div class="small text-muted" v-show="show_help">Press enter to save</div>
          </div>
          <div>
            <button class="xpl-button xpl-button--primary" @click="new_contact_list = true">Create Segment</button>
            <CreateContactList v-if="new_contact_list" :filterGroup="filter_group" />
          </div>
        </div>
        <div class="datatable">
          <div class="filters">
            <form @submit.prevent>
              <div class="input-group">
                <span class="input-group-addon">
                  <span class="icon icon-search"></span>
                </span>
                <input type="search" name="query" v-model.trim="query" class="form-control" placeholder="Start typing to search..." />
              </div>
            </form>
          </div>

          <div v-if="contact_lists">
            <table class="table bb-datatable table-hover contact-lists">
              <thead>
                <tr>
                  <td @click="sortBy('name')">Segment Name
                    <i class="fa fa-caret-down" v-if="name_sort && name_sort === 'desc'"></i>
                    <i class="fa fa-caret-up" v-if="name_sort && name_sort === 'asc'"></i>
                  </td>
                  <td>Segment Type</td>
                  <td class="text-right">Subscribed Contacts</td>
                  <td class="text-right">All Contacts</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="contact_list in filteredLists" v-if="isVisible(contact_list)" @click="$router.push('/contacts/lists/' + contact_list.uuid)">
                  <td>
                    {{ contact_list.name }}
                    <el-tag
                      v-if="contact_list.real_time && $store.getters.isStaff"
                      type="info"
                      size="mini">
                      Real-time
                    </el-tag>
                    <div class="text-muted small">Created {{ contact_list.created_at | humanDate }}</div>
                  </td>
                  <td class="number">
                    <img src="https://assets.brandbot.com/public/icons/contact-lists/standard.svg" v-if="!contact_list.smart" />
                    <img src="https://assets.brandbot.com/public/icons/contact-lists/smart.svg" v-if="contact_list.smart" />
                    {{ contact_list.smart ? 'Smart Segment' : 'Standard Segment' }}
                  </td>
                  <td class="text-right number">{{ contact_list.subscribed | prettyNumber }}</td>
                  <td class="text-right number">{{ contact_list.all | prettyNumber }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center" v-else>
            <i class="fa fa-spin fa-spinner fa-fw"></i>
          </div>
          <LoadingBox v-if="isLoading" />
        </div>
      </div>
      <NewListGroupModal v-if="new_group_modal" />
    </div>
  </transition>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import { isRealTimeTestSegment } from '@/utils';
  import _ from 'lodash';
  import CreateContactList from './ContactLists/CreateContactList';
  import NewListGroupModal from './NewListGroupModal';
  import LoadingBox from '../Global/LoadingBox';

  export default {
    components: {
      LoadingBox,
      CreateContactList,
      NewListGroupModal,
    },
    data() {
      return {
        isLoading: true,
        new_contact_list: false,
        query: '',
        contact_lists: [],
        new_group_modal: false,
        list_groups: [],
        filter_group: null,
        show_help: false,
        name_sort: null,
      };
    },
    mounted() {
      // eslint-disable-next-line
      document.title = `Segments | ${this.$store.getters.brandName}`;

      EventBus.$on('contact_lists.new_contact_list.close', this.closeModal);
      EventBus.$on('list_group.close', () => {
        this.new_group_modal = false;
        this.fetchListGroups();
      });
    },
    created() {
      this.fetchContactLists();
      this.fetchListGroups();
    },
    beforeDestroy() {
      // eslint-disable-next-line
      document.title = this.$store.getters.brandName;

      EventBus.$off('contact_lists.new_contact_list.close');
      EventBus.$off('list_group.close');
    },
    watch: {
      query() {
        this.searchContactLists();
      },
    },
    computed: {
      filteredLists() {
        if (this.filter_group) {
          return this.contact_lists.filter(l => l.tag_id === this.filter_group.id);
        }

        return this.contact_lists;
      },
    },
    methods: {
      sortBy(column) {
        if (this.name_sort === 'asc') {
          this.name_sort = 'desc';
        } else {
          this.name_sort = 'asc';
        }
        this.fetchContactLists(0, `${column}-${this.name_sort}`, this.query);
      },
      isVisible(contactList) {
        if (isRealTimeTestSegment(contactList.name) && !this.$store.getters.isStaff) {
          return false;
        }
        return true;
      },
      editGroupName() {
        const payload = {
          name: this.filter_group.name,
        };

        api.updateTag(this.filter_group.id, payload)
        .then(() => {
          this.$toasted.success('Contact List Group updated');
        })
        .catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      toggleHelp() {
        this.show_help = !this.show_help;
      },
      closeModal() {
        this.new_contact_list = false;
      },
      fetchContactLists(page = 0, sorting = '', query = '') {
        this.isLoading = true;
        api.getContactLists(page, sorting, query).then((response) => {
          this.$set(this, 'contact_lists', response.contact_lists);
          this.isLoading = false;
        }).catch(() => {
          this.$toasted.error('Something wrong happened while fetching your contact lists...');
          this.isLoading = false;
        });
      },
      fetchListGroups() {
        api.getListGroups().then((response) => {
          this.list_groups = response.list_groups;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      deleteListGroup(group) {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to delete this List Group?')) {
          return;
        }

        api.deleteListGroup(group.id).then((response) => {
          this.$toasted.success(response.message);
          this.fetchListGroups();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      previousPage() {
        if (this.contact_lists.current_page === 1) {
          return;
        }

        if (!this.contact_lists.prev_page_url) {
          return;
        }

        const page = this.contact_lists.prev_page_url.split('=')[1];
        this.fetchContactLists(page, '', this.query);
      },
      nextPage() {
        if (this.contact_lists.current_page === this.contact_lists.last_page) {
          return;
        }

        if (!this.contact_lists.next_page_url) {
          return;
        }

        const page = this.contact_lists.next_page_url.split('=')[1];
        this.fetchContactLists(page, '', this.query);
      },
      searchContactLists: _.debounce(function debounce() {
        api.searchContactLists(this.query).then((response) => {
          this.contact_lists = response.contact_lists;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      }, 500),
    },
  };
</script>
<style lang="scss" scoped>
  .container-header {
    display: flex;
    justify-content: space-between;
  }

  .lists-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    padding-top: 4rem;
  }

  .lists-sidebar {
    margin-top: 1px;
    height: 100vh;
    background-color: #fff;
    width: 16rem;
    min-width: 16rem;
    position: fixed;
    padding-top: 1.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: 5rem;
    z-index: 3;
    overflow-y: scroll;
  }

  .lists-sidebar h6 {
    padding-left: .375rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-size: .625rem;
    font-weight: bold;
    line-height: 2.5;
    color: rgb(139, 155, 172);
  }

  .lists-sidebar a {
    font-size: .75rem;
  }


  .lists-inner {
    width: 100%;
    padding-top: 1.5rem;
    padding-left: calc(17rem + 1.5rem);
    padding-right: 1.5rem;
  }

  .group {
    padding: .5rem .375rem;
    font-size: .875rem;
    line-height: 1.25rem;
    color: #1d364b;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 150ms ease-in-out;
  }

  .group .material-icons {
    color: rgb(139, 155, 172);
    opacity: 0;
    pointer-events: none;
    transition: all 150ms ease-in-out;
    font-size: .75rem;
  }

  .group:hover,
  .group.selected {
    background-color: var(--xpl-color-secondary-bg-lm);
  }

  .group:hover .material-icons {
    pointer-events: all;
    opacity: 1;
  }

  .group:first-child {
    margin-top: 1rem;
  }

  .group:last-child {
    margin-bottom: 1rem;
  }

  .datatable {
    margin-top: 2rem;
  }

  .bb-datatable {
    border: none;
    border-collapse: separate;
    border-spacing: 0 .5rem;
  }

  .bb-datatable thead tr td {
    border: none;
  }

  .bb-datatable tbody {
    border: none;
  }

  .bb-datatable thead tr td:first-child,
  .bb-datatable tbody tr td:first-child {
    padding-left: 1.5rem;
  }

  .bb-datatable thead tr td:last-child,
  .bb-datatable tbody tr td:last-child {
    padding-right: 1.5rem;
  }

  .bb-datatable tbody tr td:first-child {
    border-left: 1px solid var(--xpl-color-gray-400);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    max-width: none !important;
    width: auto !important;
  }

  .bb-datatable tbody tr td:last-child {
    border-right: 1px solid var(--xpl-color-gray-400);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    max-width: none;
    width: auto;
  }

  .bb-datatable tbody tr td {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    color: #1d364b;
    // box-shadow: 0 1px 0 0 rgba(100, 164, 210, 0.13);
  }

  .bb-datatable tbody tr td img {
    width: 1rem;
    height: 1rem;
    margin-right: .25rem;
  }

  .bb-datatable tbody tr td.number {
    color: #617080;
  }

  .filters {
    width: 100%;
  }

  .filters form {
    width: 100%;
  }

  .filters form input,
  .filters form input:focus {
    background-color: rgb(255, 255, 255);
    border: solid 1px rgb(217, 231, 240);
    border-left: none;
  }

  .filters .input-group-addon {
    border: solid 1px rgb(217, 231, 240);
    background-color: rgb(255, 255, 255);
    border-right: none;
  }

  .group-name {
    padding: 0;
    outline: none;
    background: transparent;
    border: none;
    color: #414344;
    font-size: 1.5rem;
  }
</style>
