<template>
  <div class="overlay">
    <TemplatePreview v-if="show_preview && campaignTemplateId" :campaignTemplateId="campaignTemplateId" />
    <div class="header" v-if="!show_preview && !campaignTemplateId">
      Select a template
      <div class="btn-close-wrapper" @click.stop="closeTemplateSelection">
        <span class="material-icons">close</span>
      </div>
    </div>
    <div class="container" v-if="!show_preview && !campaignTemplateId">
      <div class="sidebar">
        <div v-for="template in templates">
          <div v-for="(t, purpose) in template">
            <div class="purpose">{{ purpose }}</div>
            <div :class="['suite', selectedSuite === i ? 'active' : '']" v-for="(i, suite) in t.suites" @click.stop="selectSuite(i)">{{ suite }}</div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="templates">
          <div v-for="insideSuite in selectedSuite">
            <div class="template" v-for="template in insideSuite">
              <div class="name">
                {{ template.name }}
              </div>
              <div class="actions">
                <a :href="template.url" target="_blank" class="xpl-button xpl-button--secondary">Overview <i class="fa fa-external-link" aria-hidden="true"></i></a>
                <button type="button" class="xpl-button xpl-button--primary" @click.prevent="selectTemplate(template)">Use Template</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NameCampaignModal v-if="selectedTemplate" :template="selectedTemplate" />
  </div>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import TemplatePreview from './TemplatePreview';
  import NameCampaignModal from './NameCampaignModal';

  export default {
    components: {
      TemplatePreview,
      NameCampaignModal,
    },
    data() {
      return {
        campaignTemplateId: null,
        show_preview: false,
        selectedTemplate: false,
        group: null,
        groups: [],
        templates: [],
        query: '',
        selectedSuite: null,
      };
    },
    mounted() {
      EventBus.$on('campaign_template.preview.close', () => {
        this.show_preview = false;
        this.campaignTemplateId = null;
      });

      EventBus.$on('auto_campaigns.create_modal.close', () => {
        this.selectedTemplate = null;
      });

      EventBus.$on('campaign_template.email.preview.select', (template) => {
        this.show_preview = false;
        this.campaignTemplateId = null;
        this.selectTemplate(template);
        EventBus.$emit('campaign_is_template');
      });
    },
    created() {
      api.getCampaignTemplates().then((res) => {
        this.templates = res.templates;
        this.selectedSuite = this.templates.purposes[Object.keys(this.templates.purposes)[0]].suites;
        this.selectedSuite = this.selectedSuite[Object.keys(this.selectedSuite)[0]];
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    beforeDestroy() {
      EventBus.$off('campaign_template.preview.close');
      EventBus.$off('auto_campaigns.create_modal.close');
      EventBus.$off('campaign_template.email.preview.select');
    },
    computed: {
      filteredTemplates() {
        if (this.group) {
          return this.templates.filter(t => t.campaign_template_group_name === this.group);
        }

        return this.templates;
      },
    },
    methods: {
      selectSuite(suite) {
        this.selectedSuite = suite;
      },
      closeTemplateSelection() {
        EventBus.$emit('campaign_template.selection.close');
      },
      selectTemplate(template) {
        this.selectedTemplate = template;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10000;
  }

  .header {
    height: 3rem;
    background-color: #ffffff;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #cdd7e2;
  }

  .container {
    padding: 0;
    height: 100vh;
    width: 100vw;
    max-width: none;
  }

  .container .sidebar {
    min-width: 15rem;
    max-width: 15rem;
    width: 15rem;
    background-color: #fff;
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: fixed;
    left: 0;
    height: calc(100vh - 3rem);
    border-left: 1px solid var(--xpl-color-gray-400);
    overflow-y: scroll;
  }

  .container .content {
    width: 100%;
    height: calc(100vh - 3rem);
    background-color: #f3f7f9;
    padding-top: 1.5rem;
    padding-left: calc(15rem + 2.5rem);
    padding-right: 2.5rem;
    overflow-y: scroll;
  }

  .sidebar .title {
    font-size: .875rem;
    color: #1d364b;
    margin-bottom: 1.5rem;
  }

  .sidebar .types,
  .sidebar .tabs {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sidebar .types a,
  .sidebar .tabs a {
    display: block;
    border-radius: 6px;
    font-weight: 700;
    color: #afbcca;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 6px;
    padding-bottom: 6px;
    transition: all 150ms ease-in-out;
  }

  .sidebar .types a:hover,
  .sidebar .types a.active,
  .sidebar .tabs a:hover,
  .sidebar .tabs a.active {
    text-decoration: none;
    background-color: var(--xpl-color-gray-400);
    color: #1d364b;
  }

  .alert {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    // box-shadow: 0 1px 0 0 rgba(217, 231, 240, 0.5);
    border: solid 1px rgb(217, 231, 240);
    padding: .75rem;
    font-size: 14px;
    line-height: 1.25rem;
    color: rgb(29, 54, 75);
  }

  .alert .material-icons {
    font-size: 1.75rem;
    color: rgb(180, 224, 250);
    margin-right: .625rem;
  }

  .alert .material-icons:last-child {
    color: rgb(175, 183, 199);
    cursor: pointer;
  }

  .templates {
    margin-top: 2rem;
    padding-bottom: 2rem;
  }

  .templates form {
    display: flex;
    margin-bottom: 1.5rem;
  }

  .templates .template {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    // box-shadow: 0 1px 0 0 rgba(217, 231, 240, 0.5);
    background-color: rgb(255, 255, 255);
    border: solid 1px rgb(217, 231, 240);
    margin-bottom: 1.5rem;
  }

  .templates .template.empty {
    font-size: .75rem;
    color: rgb(97, 112, 128);
  }

  .templates .template:last-child {
    margin-bottom: 0;
  }

  .templates .template .name {
    font-size: .875rem;
    font-weight: 700;
    color: rgb(29, 54, 75);
    display: flex;
    align-items: center;
  }

  .templates .template .actions {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .templates .template .actions .btn {
    margin-right: 1rem;
  }

  .templates .template .actions a:hover {
    color: rgb(29, 54, 75);
  }

  .purpose {
    margin-top: 1.5rem;
    text-transform: uppercase;
    font-size: .625rem;
    font-weight: bold;
    line-height: 2.5;
    color: rgb(139, 155, 172);
    padding-left: .5rem;
  }

  .suite {
    cursor: pointer;
    padding: .5rem;
    border-radius: 6px;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.82;
    color: rgb(46, 68, 87);
    transition: all 300ms ease-in-out;
  }

  .suite:hover,
  .suite.active {
    color: var(--xpl-color-secondary-lm);
    background-color: var(--xpl-color-background-lm);
  }
</style>
