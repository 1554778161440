<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="sidebar"></div>
            <div class="body">
              <div class="header">
                Connect to MINDBODY
                <div class="btn-close-wrapper" @click.stop="closeModal">
                  <span class="material-icons">close</span>
                </div>
              </div>
              <div class="steps">
                <div :class="['step', steps.one ? 'active' : '']">
                  <div class="step-number">1</div>
                  <div>
                    <span class="title">Make sure you are ready to connect</span>
                    <form @submit.prevent>
                      <div class="form-group">
                        <label>Enter your Site ID</label>
                        <input type="number" v-model.number="site_id" class="form-control" placeholder="Site ID" />
                        <a href="#" class="small text-muted">Where do I find this?</a>
                      </div>
                      <div class="form-group">
                        <div class="checkbox" @click="owner = !owner">
                          <span class="icon icon-checkbox-unchecked2" v-if="!owner"></span>
                          <span class="icon icon-checkbox-checked2" v-if="owner"></span>
                          <span>
                          I am currently logged into MINDBODY as a user with owner permissions. <a href="#" @click.stop>Learn more here.</a>
                          </span>
                        </div>
                      </div>
                      <div class="form-group">
                        <button type="button" class="xpl-button xpl-button--secondary" :disabled="!owner || !site_id" @click="goToStep('two')">Next</button>
                        <a href="#" class="text-muted small" @click="no_owner = true">I do not have access to my owner permissions</a>
                      </div>
                      <div class="form-group" v-if="no_owner">
                        <div>We are going to take you to {{$store.getters.brandName}}. To set up your MINDBODY integration go to “settings”.</div>
                        <div><a href="#" @click.stop>Reach out to support</a></div>
                      </div>
                    </form>
                  </div>
                </div>

                <div :class="['step', steps.two ? 'active' : '']">
                  <div class="step-number">2</div>
                  <div>
                    <span class="title">Sign into MINDBODY</span>
                    <form @submit.prevent v-if="steps.one && steps.two">
                      <div class="form-group">
                        After clicking continue below you will be taken to MINDBODY Integration Page. If you see a green check mark you are all set. You can close and come back to {{$store.getters.brandName}}.
                      </div>
                      <img src="https://assets.brandbot.com/public/onboarding/mindbody-activation.png" />
                      <div class="form-group">
                        <button type="button" class="xpl-button xpl-button--secondary" @click.stop="launchMB">Launch MINDBODY</button>
                        <a href="#" class="text-muted small" @click="no_checkmark = true">I didn't see a green checkmark</a>
                      </div>
                      <div class="form-group" v-if="no_checkmark">
                        <div><a href="#" @click.stop>Reach out to support</a></div>
                      </div>
                    </form>
                  </div>
                </div>

                <div :class="['step', steps.three ? 'active' : '']">
                  <div class="step-number">3</div>
                  <div>
                    <span class="title">Check your connection</span>
                    <div class="connection-check" v-if="steps.one && steps.two && steps.three">
                      <img src="https://assets.brandbot.com/public/icons/onboarding/party-popper.svg" v-if="connection_success" />
                      <div>
                        <div class="form-group text-success" v-if="connection_success">
                          Connection Active
                        </div>
                        <div class="form-group" v-if="!connection_success">
                          <button type="button" class="xpl-button xpl-button--secondary" @click="checkConnection">Check Connection</button>
                        </div>
                        <div class="form-group" v-if="connection_success">
                          Syncing all of your data can take up to a few hours.<br>
                          We will send you an email when it is all done. Please note that {{$store.getters.brandName}} functionality will seem limited until it is done.<br>
                          <a href="#">Learn more about how we connect to MINDBODY.</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button type="button" class="xpl-button xpl-button--primary" v-if="steps.one && steps.two && steps.three && connection_success" @click="closeModal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    data() {
      return {
        no_owner: false,
        no_checkmark: false,
        site_id: null,
        owner: false,
        steps: {
          one: true,
          two: false,
          three: false,
        },
        connection_success: false,
      };
    },
    methods: {
      closeModal() {
        EventBus.$emit('connect_mindbody.close');
      },
      goToStep(step) {
        this.steps[step] = true;
      },
      launchMB(event) {
        const target = event.target || event.srcElement;
        const originalInner = target.innerHTML;
        const payload = {
          site_id: this.site_id,
        };

        target.innerHTML = 'Launching...';
        target.disabled = true;

        const newTab = window.open('about:blank', 'newtab');
        newTab.document.body.append('Loading... Please wait.');

        api.activateMindbodySite(payload).then((response) => {
          newTab.location = response.url;
          newTab.focus();
          this.goToStep('three');
        }).catch((err) => {
          target.disabled = false;
          target.innerHTML = originalInner;
          this.$toasted.error(err.message);
        });
      },
      checkConnection(event) {
        const target = event.target || event.srcElement;
        const originalInner = target.innerHTML;

        target.innerHTML = 'Checking...';
        target.disabled = true;

        api.checkMindbodyConnection().then((response) => {
          this.$toasted.success(response.message);
          this.connection_success = true;
        }).catch((err) => {
          target.disabled = false;
          target.innerHTML = originalInner;
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal .modal-dialog {
    max-width: 50rem;
    max-height: 80vh;
  }

  .modal .modal-dialog .modal-body {
    padding: 0;
    padding-left: 16rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 80vh;
  }

  .modal .modal-dialog .modal-body .body {
    padding-top: 1.75rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-bottom: 2.5rem;
    width: 100%;
  }

  .modal .modal-dialog .modal-body .sidebar {
    height: 100%;
    max-height: 80vh;
    width: 16rem;
    min-width: 16rem;
    flex-grow: 0;
    flex-shrink: 0;
    background-color: rgb(243, 247, 249);
    position: fixed;
    top: 0;
    left: 0;
  }

  .header {
    font-size: 1.25rem;
    line-height: 1.4;
    color: rgb(46, 68, 87);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .steps .step {
    margin-top: 2.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: .875rem;
    color: rgb(139, 155, 172);
  }

  .steps .step.active {
    color: rgb(29, 54, 75);
  }

  .steps .step .title {
    font-weight: 700;
  }

  .steps .step .step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 99rem;
    background-color: rgb(217, 231, 240);
    margin-right: 1rem;
    font-size: .75rem;
    color: rgb(97, 112, 128);
  }

  .steps .step form {
    margin-top: 1rem;
  }

  .steps .step img {
    width: 8.75rem;
    margin-bottom: 1rem;
  }

  .form-group a {
    text-decoration: underline;
  }

  .form-group button + a {
    margin-left: 1rem;
  }

  .form-group .checkbox {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    line-height: 1.5;
  }

  .form-group .checkbox .icon {
    line-height: 1.5;
    margin-right: .625rem;
  }

  .steps .step .connection-check {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .steps .step .connection-check img {
    width: 2rem;
    height: 2rem;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
    margin-right: 1rem;
  }
</style>
