<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="alert">
      <div><span class="material-icons">info_outline</span></div>
      <div>
        <div class="body">Do not use on auto-emails.</div>
      </div>
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition">Save this condition</button>
      </div>
    </form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    props: ['locations', 'condition', 'editedRule', 'goalList'],
    methods: {
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          unsubscribed: true,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar-inner {
    position: relative;
  }

  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  form .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  form .form-group:first-child {
    margin-top: 0;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked,
  .icon-checkbox-checked2,
  .icon-checkbox-unchecked2 {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }

  .alert {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: .75rem;
    border-radius: 6px;
    background-color: rgb(252, 241, 205);
    // box-shadow: 0 2px 1px 0 rgba(175, 188, 202, 0.13);
  }

  .alert .material-icons {
    font-size: 1rem;
    line-height: 1rem;
    color: rgb(255, 183, 105);
    margin-right: .625rem;
  }

  .alert .body {
    font-weight: 700;
    font-size: .875rem;
    color: rgb(35, 44, 56);
  }
</style>
