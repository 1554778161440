<template>
  <div>
    <div class="nav-subheader">
      <div>Account - <small>Settings</small></div>
    </div>
    <div class="container">
      <table class="table">
        <thead class="thead-default">
          <tr>
            <th></th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th class="text-right">Status</th>
            <th class="text-right">Account Level</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="member in members">
            <td>
              <button type="button" class="btn btn-sm btn-danger" @click.stop="removeMember(member.pivot.id)" v-if="canEdit(member.id)">
                <i class="fa fa-trash"></i>
              </button>
            </td>
            <td>{{ member.first_name }}</td>
            <td>{{ member.last_name }}</td>
            <td>{{ member.email }}</td>
            <td class="text-right">
              <i class="fa fa-check-circle text-success" v-if="member.pivot.confirmed"></i>
              <i class="fa fa-times-circle text-danger" v-if="!member.pivot.confirmed"></i>
            </td>
            <td class="text-right text-capitalize">{{ member.pivot.permission }}</td>
            <td class="text-right">
              <a href="#" class="btn btn-sm btn-link" @click.stop="openMemberModal(member.pivot.id)" v-if="canEdit(member.id)">
                Change Permissions
              </a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="6">
              <a href="#invite-member" class="btn btn-primary" @click="show_invite = !show_invite" v-if="canInvite()">Invite Team Members</a>
            </td>
          </tr>
        </tfoot>
      </table>
      <transition name="fade">
        <InviteMember v-if="show_invite" />
      </transition>
    </div>
    <Member v-show="edit_member" />
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Member from './Member';
  import InviteMember from './InviteMember';

  export default {
    components: {
      Member,
      InviteMember,
    },
    data() {
      return {
        show_invite: false,
        edit_member: false,
        members: [],
      };
    },
    beforeRouteEnter(to, from, next) {
      api.getTeamMembers().then((response) => {
        next((vm) => {
          vm.members = response.members;
        });
      });
    },
    created() {
      EventBus.$on('team.permissions.modal.close', () => {
        this.edit_member = false;
      });

      EventBus.$on('team.permissions.change', () => {
        this.fetchTeamMembers();
      });

      EventBus.$on('team.invite', () => {
        this.fetchTeamMembers();
      });
    },
    methods: {
      fetchTeamMembers() {
        api.getTeamMembers().then((response) => {
          this.members = response.members;
        });
      },
      canInvite() {
        return this.$store.state.user.current_account.pivot.permission !== 'creator';
      },
      canEdit(accountUserId) {
        return this.$store.state.user.id !== accountUserId;
      },
      openMemberModal(accountUserId) {
        this.edit_member = !this.edit_member;
        EventBus.$emit('team.permissions.modal.accountUserId', accountUserId);
      },
      removeMember(accountUserId) {
        if (!confirm('Are you sure you want to remove this member from your team?')) { // eslint-disable-line no-alert
          return false;
        }

        api.removeAccountUser(accountUserId)
        .then(() => {
          this.$toasted.success('Team member removed.');
          this.fetchTeamMembers();
        }).catch(() => {
          this.$toasted.error('Something wrong happened while trying to remove your team member...');
        });

        return true;
      },
    },
  };
</script>
