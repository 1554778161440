<template>
  <div class="field-container">
    <div class="card-condition">
      <el-row>
        <el-col :span="1.5" class="step-col">
          <div class="field-step">{{ this.field.step }}</div>
        </el-col>
        <el-col :span="22">
          <span class="field-title">{{ this.field.name }}</span>
          <div class="text-align-left field-body-text" v-if="this.showTimeTypeOptions"></div>
          <!--          -->
          <div v-if="this.showTimeTypeOptions">
            <el-radio v-model="showTimeType"
                      class="radio-btn field-label-text field-label-text relative"
                      name="relative-check"
                      @change="didSelect()"
                      label="relative">Relative to today
            </el-radio>
            <el-radio v-model="showTimeType"
                      class="radio-btn field-label-text field-label-text static"
                      name="static-check"
                      @change="didSelect()"
                      label="static">Static time frame
            </el-radio>
          </div>
          <!--          -->
          <transition name="fade">
            <div class="date-form">
              <el-form :model="form" ref="timeFrameForm">
                <el-form-item prop="selectedOption">
                  <div v-if="this.showTimeTypeChecker === 'relative'" class="option-subbody">
                    <!--                    -->
                    <!--                    -->
                    <div v-if="this.showRelativeToday" class="subtitle">RELATIVE TO TODAY</div>
                    <div v-for="option in this.relativeTodayOptions">
                      <el-radio v-model="form.selectedOption"
                                class="radio-btn field-label-text field-label-text"
                                @change="didChange(option.field)"
                                :label="option.field">{{ option.label }}
                      </el-radio>
                      <transition name="input">
                        <div v-if="form.selectedOption === option.field">
                          <div class="other-form">
                            <el-input-number v-model="form.valueOne"
                                             size="mini"
                                             controls-position="right"
                                             @change="didChange()"
                                             :min="0"
                                             :max="999"/>
                            <span class="desc-label">days</span>
                          </div>
                          <div v-if="option.description" class="desc-text" v-html="option.description"></div>
                        </div>
                      </transition>
                    </div>
                    <!--                    -->
                    <!--                    -->
                    <div v-if="this.showRelativePast" class="subtitle">IN THE PAST</div>
                    <div v-for="option in this.relativePastOptions">
                      <el-radio v-model="form.selectedOption"
                                class="radio-btn field-label-text field-label-text"
                                @change="didChange(option.field)"
                                :label="option.field">{{ option.label }}
                      </el-radio>
                      <transition name="input">
                        <div v-if="form.selectedOption === option.field">
                          <div class="other-form">
                            <el-input-number v-model="form.valueOne"
                                             size="mini"
                                             controls-position="right"
                                             @change="didChange()"
                                             :min="0" :max="1000"/>
                            <span class="desc-label">days</span>
                          </div>
                          <div v-if="option.description" class="desc-text" v-html="option.description"></div>
                        </div>
                      </transition>
                    </div>
                  </div>
                  <!--                    -->
                  <!--                    -->
                  <div v-if="this.showTimeTypeChecker === 'static'" class="option-subbody">
                    <div class="subtitle">STATIC TIME FRAME</div>
                    <div v-for="option in this.staticOptions">
                      <el-radio v-model="form.selectedOption"
                                class="radio-btn field-label-text"
                                @change="didChange(option.field)"
                                :label="option.field">{{ option.label }}
                      </el-radio>
                      <transition name="input">
                        <div v-if="form.selectedOption === option.field">
                          <div class="other-form" v-if="option.showDatePicker">
                            <el-date-picker v-if="option.field !='between'"
                                            v-model="form.valueDate"
                                            @change="didChange()"
                                            value-format="yyyy-MM-dd"
                                            format="MMM dd, yyyy"
                                            type="date"
                                            :class="errorDate"
                                            placeholder="Pick a date"/>
                            <el-date-picker v-else
                                            v-model="form.valueRange"
                                            @change="didChange()"
                                            value-format="yyyy-MM-dd"
                                            format="MMM dd, yyyy"
                                            type="daterange"
                                            :class="errorDateRange"
                                            range-separator=""
                                            start-placeholder="Start date"
                                            end-placeholder="End date"/>
                          </div>
                          <div v-if="option.description" class="desc-text" v-html="option.description"></div>
                        </div>
                      </transition>
                    </div>
                  </div>
                  <!--                    -->
                  <!--                    -->
                </el-form-item>
              </el-form>
            </div>
          </transition>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>

export default {
  props: ['field'],
  data() {
    return {
      isPristine: true,
      form: {
        selectedOption: null,
        valueOne: null,
        valueTwo: null,
        valueDate: null,
        valueRange: null,
      },
      showTimeType: null,
      //
      showRelativeToday: false,
      showRelativePast: false,
      showStatic: false,
      lastRelativeOption: null,
      lastStaticOption: null,
      options: {
        //
        within_the_next: {
          relative: true,
          future: true,
          label: 'Within the next',
          description: 'Today would be within the next 0 days. (I.e. “Within the next 1 day” equals today and tomorrow)',
        }, // REL.TODAY, F
        at_least_in_future: {
          relative: true,
          future: true,
          label: 'At Least',
          description: 'Today would be at least the next 0 days. (I.e. “Within the next 1 day” equals today and tomorrow)',
        }, // REL.TODAY, F
        exactly_in_future: {
          relative: true,
          future: true,
          label: 'Exactly',
          description: 'Today would be exactly 0 days.',
        }, // REL.TODAY, F
        //
        within_the_last: {
          relative: true,
          future: false,
          label: 'Within the last',
          description: 'Today is within the last 0 days. (I.e. “Within the last 1 day” equals yesterday and today)',
        }, // PAST, F
        at_least_in_past: {
          relative: true,
          future: false,
          label: 'At least',
          description: 'Today is at least 0 days ago. (I.e. “At least 1 day ago” equals today and tomorrow)',
        }, // PAST, F
        exactly_in_past: {
          relative: true,
          future: false,
          label: 'Exactly',
          description: 'Today is exactly 0 days. (I.e. “Exactly 1 day ago” equals yesterday)',
        }, // PAST, F
        //
        //
        none: {
          relative: false,
          label: 'None',
          description: null,
          showDatePicker: false
        }, // STATIC
        before: {
          relative: false,
          label: 'Before',
          description: 'The date selected is not included (i.e. “Before Oct 10th” equals Oct 9th and before)',
          showDatePicker: true
        }, // STATIC
        after: {
          relative: false,
          label: 'After',
          description: 'This does not include the selection (i.e. “After Oct 10th” equals Oct 11th and after',
          showDatePicker: true
        }, // STATIC
        between: {
          relative: false,
          label: 'Between',
          description: 'This will include both selections (i.e. “Between Oct 1st and Oct 3rd” equals Oct 1st, 2nd and 3rd)',
          showDatePicker: true
        }, // STATIC
        specific: {
          relative: false,
          label: 'On a specific date',
          description: null,
          showDatePicker: true
        }, // STATIC
        all_time: {
          relative: false,
          label: 'All time',
          description: null,
          showDatePicker: false
        }, // STATIC

      },
      relativeTodayOptions: [],
      relativePastOptions: [],
      staticOptions: [],
    };
  },
  created() {
    // what to show
    this.showRelativeToday = false;
    this.showRelativePast = false;
    this.showStatic = false;
    this.field.show.forEach((field) => {
      const o = this.options[field];
      o.field = field;
      if (o.relative) {
        if (o.future) {
          this.showRelativeToday = true;
          this.relativeTodayOptions.push(o);
        } else {
          this.showRelativePast = true;
          this.relativePastOptions.push(o);
        }
      } else {
        this.showStatic = true;
        this.staticOptions.push(o);
      }
    });
    //
    this.relativeTodayOptions.forEach((o) => {
      if (!this.lastRelativeOption) {
        this.lastRelativeOption = o.field;
      }
    });
    this.relativePastOptions.forEach((o) => {
      if (!this.lastRelativeOption) {
        this.lastRelativeOption = o.field;
      }
    });
    this.staticOptions.forEach((o) => {
      if (!this.lastStaticOption) {
        this.lastStaticOption = o.field;
      }
    });
    //
    if (this.showStatic && !(this.showRelativeToday || this.showRelativePast)) {
      this.showTimeType = 'static';
      if (this.staticOptions.length === 1) {
        this.form.selectedOption = this.staticOptions[0].field;
      }
    }
    if (!this.showStatic && (this.showRelativeToday || this.showRelativePast)) {
      this.showTimeType = 'relative';
    }
    //
    if (this.field && this.field.default) {
      if (typeof this.field.default === 'string') {
        this.field.default = {option: this.field.default, value: []};
      }
      let values;
      if (Array.isArray(this.field.default.value)) {
        values = this.field.default.value;
      } else {
        values = [this.field.default.value, null];
      }
      const o = this.options[this.field.default.option];
      if (o && o.field === 'between') {
        this.form.valueRange = values;
      } else if (o && !o.relative) {
        this.form.valueDate = values[0];
      } else {
        this.form.valueOne = values[0];
        this.form.valueTwo = values[1];
      }
      this.form.selectedOption = o.field;
      this.lastOption(o.field);
      this.didChange();
    }
    //
    if (this.form.selectedOption) {
      const o = this.options[this.form.selectedOption];
      if (o) {
        this.showTimeType = o.relative ? 'relative' : 'static';
        this.form.selectedOption = o.field;
        this.lastOption(o.field);
        this.didChange();
      }
    }
  },
  computed: {
    errorDateRange() {
      return this.isPristine || (this.form.valueRange && this.form.valueRange[0]) ? '' : 'error';
    },
    errorDate() {
      return this.isPristine || this.form.valueDate ? '' : 'error';
    },
    showTimeTypeChecker() {
      if (this.showTimeType === 'relative') {
        return 'relative';
      } else if (this.showTimeType === 'static') {
        return 'static';
      }
      return null;
    },
    showTimeTypeOptions() {
      return this.showStatic && (this.showRelativePast || this.showRelativeToday);
    },
  },
  methods: {
    lastOption(field) {
      const o = this.options[field];
      if (o) {
        if (o.relative) {
          this.lastRelativeOption = o.field;
        } else {
          this.lastStaticOption = o.field;
        }
      }
    },
    didSelect() {
      const o = this.options[this.form.selectedOption];
      if (this.showTimeType === 'relative' && (!o || (o && !o.relative))) {
        this.form.selectedOption = this.lastRelativeOption;
      }
      if (this.showTimeType === 'static' && (!o || (o && o.relative))) {
        this.form.selectedOption = this.lastStaticOption;
      }
      this.didChange(this.form.selectedOption);
    },
    didChange(newOption) {
      this.isPristine = false;
      if (newOption) {
        // this.form.selectedOption = newOption;
        this.field.default.option = newOption;
      }
      const o = this.options[this.form.selectedOption];
      if (!o) {
        return;
      }
      this.field.default.value = [this.form.valueOne || 0];
      if (!o.relative) {
        if (o.field === 'between') {
          this.field.default.value = this.form.valueRange;
        } else {
          this.field.default.value = [this.form.valueDate];
        }
      }
      this.$log(JSON.stringify(this.field.default));
      this.lastOption(this.form.selectedOption);
      this.validate();
    },
    validate() {
      const o = this.options[this.form.selectedOption];
      if (!o) {
        return;
      }
      let isValid = true;
      if (o.relative) {
        isValid = this.form.valueOne >= 0;
      } else if (o.field === 'between') {
        const range = this.form.valueRange;
        isValid = !!range && (!!range[0] && !!range[1]);
      } else {
        isValid = !!this.form.valueDate || !o.showDatePicker;
      }
      this.field.isValid = isValid;
      this.$log('timeframeField isvalid=', this.field.isValid);
      this.$emit('didValidate');
    },
  },
};
</script>
<style lang="scss" scoped>
@import "uia_fields";

.el-range-editor.error {
  border-color: red !important;
}

.el-date-editor.error {
  /deep/ input {
    border-color: red !important;
  }
}
</style>
