<template>
  <div class="card-condition-border" @click="sendCurrentOpen">
    <div :class="['card-condition', open ? 'disable-hover-bg' : null]">
      <el-row>
        <el-col :span="12">
          <div class="flex">
            <span>{{ title }}</span>
        </div>
        </el-col>
        <el-col v-if="!open" :span="12">
          <div class="text-align-left">
            {{ radio ? yesSummary : noSummary }}
          </div>
        </el-col>
      </el-row>
      <transition name="fade">
        <div class="option-body" v-if="open" @click.stop>
          <el-radio class="radio-btn" v-model="radio" @change="$emit('update:selection', radio)" :label="true">{{ yesCopy }}</el-radio>
          <el-radio class="radio-btn" v-model="radio" @change="$emit('update:selection', radio)" :label="false">{{ noCopy }}</el-radio>
          <div v-if="showLabel" class="desc-text">
            {{ descLabel }}
          </div>
          <el-button v-if="!initiatesDropDown && notEditState" class="continue-btn" type="primary" @click="sendCurrentOpen">Continue</el-button>
      </div>
      </transition>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    props: [
      'id',
      'title',
      'descLabel',
      'yesCopy',
      'noCopy',
      'showLabel',
      'yesSummary',
      'noSummary',
      'initiatesDropDown',
      'selection',
      'notEditState',
      'currentlyOpen',
    ],
    data() {
      return {
        radio: this.selection,
      };
    },
    computed: {
      open() {
        return this.currentlyOpen === this.id;
      },
    },
    methods: {
      sendCurrentOpen() {
        EventBus.$emit('update:currentlyOpen', this.id);
      },
    },
  };
</script>
