<template>
  <div class="field-container">
    <div class="card-condition">
      <el-row>
        <el-col :span="1.5" class="step-col">
          <div class="field-step">
            {{ this.fieldStep }}
          </div>
        </el-col>
        <el-col :span="15">
          <span class="field-title">{{ this.title }}</span>
          <transition name="fade">
            <el-form class="bool-form" :model="radioForm" ref="yesNoForm">
              <el-form-item prop="radio">
                <div class="option-body">
                  <el-radio
                    class="radio-btn field-label-text"
                    name="yes-option"
                    v-model="radioForm.radio"
                    @change="$emit('update:selection', radioForm.radio)"
                    :label="true"
                  >
                    {{ this.yesText }}
                  </el-radio>
                  <el-radio
                    class="radio-btn field-label-text"
                    name="no-option"
                    v-model="radioForm.radio"
                    @change="$emit('update:selection', radioForm.radio)"
                    :label="false"
                  >
                    {{ this.noText }}
                  </el-radio>
                  <div v-if="showLabel" class="desc-text">
                    {{ this.descLabel }}
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </transition>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

  export default {
    props: [
      'fieldIndex',
      'title',
      'fieldStep',
      'yesText',
      'noText',
      'descLabel',
      'showLabel',
      'selection',
      'id',
    ],
    data() {
      return {
        radioForm: {
          radio: this.selection,
        },
      };
    },
  };
</script>
<style lang="scss" scoped>
@import "src/scss/global/_spacing.scss";
@import "src/scss/global/_new_main.scss";
.field-title {
  font-family: $primary-bb-font-family;
  font-weight: 700;
  font-size: $base-font-size;
  color: $base-color-black-v2;
  line-height: $loose-medium-line-height;
}
.field-step {
  height: 1.5rem;
  width: 1.5rem;
  background-color: $bb-color-blue-v2;
  font-family: $primary-bb-font-family;
  font-size: $base-font-size;
  color: #FFFFFF;
  border-radius: 50%;
  text-align: center;
  margin: $space-base-inline;
}
.card-condition {
  background-color: #FFFFFF;
  border: solid 1px var(--xpl-color-gray-400);
  box-shadow: none;
  border-radius: 0;
  cursor: auto;
}
.card-condition:hover {
  background-color: #FFFFFF;
  cursor: auto;
}
.field-body-text {
  font-family: $primary-bb-font-family;
  font-weight: 400;
  font-size: $base-loose-font-size;
  color: #00152A;
  line-height: $loose-line-height;
  margin-top: $space-loose
}
.option-body {
  margin-top: 0;
}
.field-label-text /deep/ .el-radio__label {
  font-family: $primary-bb-font-family !important;
  font-size: $base-loose-font-size !important;
  color: $base-color-black-v2 !important;
  line-height: $loose-line-height !important;
  padding-left: 8px;
}
.field-label-text /deep/ .el-radio__inner {
  background-color: #F2F4F6 !important;
  border: 1px solid #A6B2BF !important;
  height: 16px;
  width: 16px;
}
.field-label-text /deep/  .el-radio__input.is-checked .el-radio__inner::after {
   background-color: var(--xpl-color-secondary-lm) !important;
   width: 12px;
   height: 12px;

}
.radio-btn {
  margin-top: $space-extra-tight;
  margin-bottom: $space-extra-tight;
}
.bool-form {
  margin-top: $space-loose;
}
.option-body {
  margin-top: 0;
}
.field-container {
  margin-top: 28px;
}
</style>
