<template>
  <el-container>
    <div v-if="$store.state.user.current_account.pivot.permission !== 'admin'" class="card">
      <div class="card-header card-header-merged card-header-secondary">Reporting</div>
      <div class="card-body">
        <div style="display: flex; width: 100%; justify-content: space-between;">
          <div class="title">Tasks Reporting</div>
          <div>
            From: {{ dates.start | humanDate(false) }} - To: {{ dates.end | humanDate(false) }}
          </div>
          <button type="button" class="xpl-button xpl-button--secondary" @click="show_calendar = !show_calendar">
            Calendar
          </button>
        </div>
        <div v-show="show_calendar">
          <input type="text" id="datepicker-value" hidden/>
          <div id="datepicker"></div>
        </div>
        <div class="datatable">
          <table class="table bb-datatable">
            <thead>
            <tr>
              <td></td>
              <td>User</td>
              <td>Complete Tasks</td>
              <td>Complete Phone Call Reminders</td>
              <td>Incomplete Tasks</td>
              <td>Incomplete Phone Call Reminders</td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="stat in stats">
              <td></td>
              <td>{{ stat.first_name }} {{ stat.last_name }}</td>
              <td>{{ stat.complete_tasks | prettyNumber }}</td>
              <td>{{ stat.complete_phone | prettyNumber }}</td>
              <td>{{ stat.incomplete_tasks | prettyNumber }}</td>
              <td>{{ stat.incomplete_phone | prettyNumber }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-if="$store.state.user.current_account.pivot.permission === 'admin'" v-loading="loading">
      <div class="container">
        <el-row style="margin-bottom: 1.25rem;">
          <el-col :span="24" class="display-medium">Reporting Summary</el-col>
        </el-row>
        <el-row style="margin-bottom: 2.625rem;">
          <el-col :span="4">
            <el-select v-model="timeframe" placeholder="Timeframe" @change="fetchStatsAdmin">
              <el-option v-for="item in timeframes" :key="item.value" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-select v-model="assignee" filterable clearable placeholder="User or Group" value-key="uuid"
                       @change="fetchStatsAdmin">
              <el-option-group v-for="group in assignees" :key="group.name" :label="group.name">
                <el-option v-for="item in group.group" :key="item.uuid" :label="item.email" :value="item"></el-option>
              </el-option-group>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 1.5rem;">
          <el-col :span="24" class="display-small">Phone Call Reminders</el-col>
        </el-row>
        <el-row class="stats" v-if="statsAdmin && statsAdmin.calls">
          <el-col :span="4">
            <el-card>
              <div class="stat">{{ statsAdmin.calls.assigned | prettyNumber }}</div>
              <div class="title">Assigned</div>
            </el-card>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-card>
              <div class="stat">{{ statsAdmin.calls.overdue | prettyNumber }}</div>
              <div class="title">Overdue</div>
            </el-card>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-card>
              <div class="stat">{{ statsAdmin.calls.completed | prettyNumber }}</div>
              <div class="title">Calls Completed</div>
            </el-card>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-card>
              <div class="stat">{{ statsAdmin.calls.reached | prettyNumber }}</div>
              <div class="title">Successfully Reached</div>
            </el-card>
          </el-col>
        </el-row>
        <el-card style="margin-bottom: 3rem;">
          <div class="display-caption">Assignee Breakdown</div>
          <el-table :data="statsAdmin.calls_users">
            <el-table-column label="Name" width="250">
              <template slot-scope="scope">
                {{ scope.row.email || scope.row.group }}
              </template>
            </el-table-column>
            <el-table-column label="Assigned" width="125">
              <template slot-scope="scope">
                {{ scope.row.assigned | prettyNumber }}
              </template>
            </el-table-column>
            <el-table-column label="Overdue" width="125">
              <template slot-scope="scope">
                {{ scope.row.overdue | prettyNumber }}
              </template>
            </el-table-column>
            <el-table-column label="Completed" width="125">
              <template slot-scope="scope">
                {{ scope.row.completed | prettyNumber }}
              </template>
            </el-table-column>
            <el-table-column v-for="out in outcomes" :label="columnName(out)" width="125" :key="out">
              <template slot-scope="scope">
                {{ scope.row[out] | prettyNumber }}
              </template>
            </el-table-column>
          </el-table>
        </el-card>

        <el-row style="margin-bottom: 1.5rem;">
          <el-col :span="24" class="display-small">Tasks</el-col>
        </el-row>
        <el-row class="stats" v-if="statsAdmin && statsAdmin.tasks">
          <el-col :span="4">
            <el-card>
              <div class="stat">{{ statsAdmin.tasks.assigned | prettyNumber }}</div>
              <div class="title">Assigned</div>
            </el-card>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-card>
              <div class="stat">{{ statsAdmin.tasks.overdue | prettyNumber }}</div>
              <div class="title">Overdue</div>
            </el-card>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-card>
              <div class="stat">{{ statsAdmin.tasks.completed | prettyNumber }}</div>
              <div class="title">Completed</div>
            </el-card>
          </el-col>
        </el-row>
        <el-card>
          <div class="display-caption">Assignee Breakdown</div>
          <el-table :data="statsAdmin.tasks_users">
            <el-table-column label="Name">
              <template slot-scope="scope">
                {{ scope.row.email || scope.row.group }}
              </template>
            </el-table-column>
            <el-table-column label="Assigned">
              <template slot-scope="scope">
                {{ scope.row.assigned | prettyNumber }}
              </template>
            </el-table-column>
            <el-table-column label="Overdue">
              <template slot-scope="scope">
                {{ scope.row.overdue | prettyNumber }}
              </template>
            </el-table-column>
            <el-table-column label="Completed">
              <template slot-scope="scope">
                {{ scope.row.completed || prettyNumber }}
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </div>
  </el-container>
</template>
<style src="flatpickr/dist/flatpickr.min.css"></style>
<script>
import * as api from '@/api';
import moment from 'moment';
import flatpickr from 'flatpickr';

export default {
  data() {
    return {
      show_calendar: false,
      stats: null,
      dates: {
        start: moment()
          .format('Y-M-D'),
        end: moment()
          .add(4, 'weeks')
          .format('Y-M-D'),
      },
      outcomes: ['connected', 'no-answer', 'busy', 'left-message', 'left-voicemail', 'wrong-number'],
      loading: true,
      timeframe: 7,
      timeframes: [{
        label: '1 Day',
        value: 1,
      }, {
        label: '7 Days',
        value: 7,
      }, {
        label: '4 Weeks',
        value: 28,
      }, {
        label: '12 Weeks',
        value: 84,
      }],
      assignee: null,
      assignees: [],
      statsAdmin: {
        calls: null,
        calls_users: [],
        tasks: null,
        tasks_users: [],
      },
    };
  },
  created() {
    this.fetchStats();
    this.fetchTeamMembers();
    this.fetchStatsAdmin();
    this.dates.start = moment()
      .format('Y-M-D');
    this.dates.end = moment()
      .add(4, 'weeks')
      .format('Y-M-D');
    this.$nextTick(() => {
      const $this = this;
      flatpickr('#datepicker-value', {
        mode: 'range',
        inline: true,
        dateFormat: 'Y-m-d',
        defaultDate: [$this.dates.start, $this.dates.end],
        maxDate: '2027-01-01',
        minDate: '2014-01-01',
        onChange(selectedDates) {
          if (selectedDates.length > 0) {
            $this.dates.start = moment(selectedDates[0])
              .format('Y-M-D');
          }

          if (selectedDates.length === 2) {
            $this.dates.end = moment(selectedDates[1])
              .format('Y-M-D');
            $this.fetchStats();
          }
        },
      });
    });
  },
  methods: {
    fetchStats() {
      api.taskReporting(this.dates.start, this.dates.end)
        .then((response) => {
          this.stats = response.stats;
        })
        .catch((err) => {
          this.$toasted.error(err.message);
        });
    },
    columnName(column) {
      let name = column.replace('-', ' ');
      name = `${name.charAt(0)
        .toUpperCase()}${name.slice(1)}`;
      return name;
    },
    fetchTeamMembers() {
      api.getTeamMembers()
        .then((response) => {
          this.assignees = response.multi;
        })
        .catch((err) => {
          this.$toasted.error(err.message);
        });
    },
    fetchStatsAdmin() {
      this.loading = true;
      const payload = {
        timeframe: this.timeframe,
      };

      if (this.assignee && this.assignee.id) {
        payload.assignee_type = 'user';
        payload.assignee_uuid = this.assignee.uuid;
      }

      if (this.assignee && !this.assignee.id) {
        payload.assignee_type = 'group';
        payload.assignee_uuid = this.assignee.uuid;
      }

      api.reportingScheduledTask(payload)
        .then((response) => {
          this.statsAdmin = response.stats;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
    },
  },
};
</script>
<style lang="scss" scoped>

.container {
  padding-top: 2rem;
  max-width: 68.75rem;
  padding-bottom: 10rem;
}

.stats {
  margin-bottom: 1.375rem;
}

.stats .el-card {
  height: 7.5rem;
  text-align: center;
}

.stats .el-card .stat {
  font-size: 1.625rem;
  color: #00152a;
  margin-bottom: .625rem;
}

.stats .el-card .title {
  font-size: .875rem;
  line-height: 1.43;
  color: #617080;
}
</style>
