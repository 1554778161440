<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner">
      Event Qualifier
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="!in_segment" @click="in_segment = true"></span>
            <span class="icon icon-radio-checked" v-if="in_segment" @click="in_segment = true"></span>
          </div>
          <div class="col-xs-10">
            <label @click="in_segment = true">Contact is in a Segment</label>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="in_segment" @click="in_segment = false"></span>
            <span class="icon icon-radio-checked" v-if="in_segment === false" @click="in_segment = false"></span>
          </div>
          <div class="col-xs-10">
            <label @click="in_segment = false">Contact is not in a Segment</label>
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Segment Details
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <label>Get contacts who are in the following segment(s)</label>
        <div class="multiselect-block">
          <Multiselect v-model="selected_segments" placeholder="Select segment(s)" :options="segments.filter(isVisible)" label="name" track-by="uuid" :searchable="true" :multiple="true" :allow-empty="false" :show-labels="false" :close-on-select="false" />
        </div>
      </div>
    </form>

    <div class="form-group">
      <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition" :disabled="!selected_segments.length">Save this condition</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import { isRealTimeTestSegment } from '@/utils';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['condition', 'editedRule'],
    components: {
      Multiselect,
    },
    data() {
      return {
        in_segment: true,
        selected_segments: [],
        segments: [],
      };
    },
    created() {
      this.fetchContactLists();

      if (this.editedRule) {
        this.in_segment = this.editedRule.in_segment;
        this.selected_segments = this.editedRule.selected_segments;
      }
    },
    methods: {
      isVisible(contactList) {
        if (isRealTimeTestSegment(contactList.name) && !this.$store.getters.isStaff) {
          return false;
        }
        return true;
      },
      fetchContactLists() {
        api.getContactLists(0, '', '', true).then((response) => {
          this.segments = response.contact_lists;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          in_segment: this.in_segment,
          selected_segments: this.selected_segments,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar-inner {
    padding-bottom: 5rem;
    min-height: 70vh;
  }

  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }
</style>
