<template>
  <el-container class="enrollment-rule-header-container">
    <div class="conditions-sidebar-header">
      <div class="header-inner-right">
        <div class="header-inner-icon">
          <img
            src='https://assets.brandbot.com/public/icons/conditions/rule-icon-blue.svg'
          />
        </div>
        <div  class="header-title">
          {{ nameOfRule }}
        </div>
      </div>
      <div class="header-inner-left">
        <div class="header-items">
          <a class="cancel-btn xpl-button xpl-button--secondary" href="#" @click="closeSidebar">Close</a>
        </div>
      </div>
    </div>
  </el-container>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    props: ['ruleName'],
    data() {
      return {
        more_options_menu: false,
        nameOfRule: this.ruleName,
      };
    },
    methods: {
      closeSidebar() {
        EventBus.$emit('contact_list.conditions_sidebar.close');
      },
    },
    computed: {
    },
  };
</script>
<style lang="scss" scoped>
@import "src/scss/global/_spacing.scss";
@import "src/scss/global/_new_main.scss";

.enrollment-rule-header-container {
  display: block;
}
.conditions-sidebar-header {
  display: flex;
  justify-content: space-between;
  padding: 0;
  background-color: var(--xpl-color-action-primary-lm);
}
.header-inner-right {
  display: flex;
  align-items: center;
}
.header-inner-icon{
  margin: 1rem 1.25rem 1.25rem 2rem;
}
.header-title {
  padding: $space-base 0 $space-base 0;
  color: $base-color-white-v2;
  line-height: $extra-loose-small-line-height;
  font-size: $loose-font-size;
  font-weight: 400;
  display: inline;
}

.header-inner-left {
  padding-top: $space-base-tight;
  margin: $space-base-inline;
}
.cancel-btn {
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  line-height: 18px;
  color: #161E27;
  height: 2rem;
  border: 1px solid #A6B2BF;
}
.header-items {
  display: flex;
}
.btn-bb-secondary{
  min-height: 0;
}

.conditions-sidebar-body {
  padding: 0;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.header-title-input {
  background-color: transparent;
  outline: 0;
  border: none;
  color: $base-color-white-v2;
  line-height: $extra-loose-small-line-height;
  font-size: $loose-font-size;
}
</style>
