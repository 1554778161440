<template>
  <transition name="fade">
    <div :class="{ 'modal': true, 'show': show }" :style="show ? 'display: block;' : ''">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Change permissions</h5>
            <button type="button" class="close" aria-label="Close" @click="closeModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-if="account_user.hasOwnProperty('permission')">
          <p>Choose a permission for this member of your team</p>
          <ul class="list-group">
            <li :class="{ 'list-group-item': true, 'active': account_user.permission === 'creator' }" @click="account_user.permission = 'creator'">
              Creator
            </li>
            <li :class="{ 'list-group-item': true, 'active': account_user.permission === 'manager' }" @click="account_user.permission = 'manager'">
              Manager
            </li>
            <li :class="{ 'list-group-item': true, 'active': account_user.permission === 'admin' }" @click="account_user.permission = 'admin'">
              Admin
            </li>
          </ul>
          </div>
          <div class="modal-body" v-if="!account_user.hasOwnProperty('permission')">
            <p class="text-center">
              <i class="fa fa-spinner fa-spin fa-fw"></i>
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="changePermissions">Save changes</button>
            <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    data() {
      return {
        show: true,
        member_id: null,
        account_user: {},
      };
    },
    mounted() {
      EventBus.$on('team.permissions.modal.accountUserId', (accountUserId) => {
        this.member_id = accountUserId;
        this.fetchMember();
      });
    },
    methods: {
      closeModal() {
        EventBus.$emit('team.permissions.modal.close');
        this.account_user = {};
        this.member_id = null;
      },
      fetchMember() {
        api.getAccountUser(this.member_id).then((response) => {
          this.account_user = response.account_user;
        }).catch((err) => {
          this.$toasted.error('Something wrong happened while fetching the details of this membership...', err);
        });
      },
      changePermissions() {
        api.changeAccountUser(this.account_user).then(() => {
          this.$toasted.success('Permission changed.');
          EventBus.$emit('team.permissions.modal.close');
          EventBus.$emit('team.permissions.change');
        }).catch((err) => {
          this.$toasted.error('Something wrong happened while trying to change this member\'s permissions.', err);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .list-group-item {
    cursor: pointer;
  }
</style>
