<template>
  <div>
    <div class="card">
      <div class="card-header card-header-merged card-header-secondary">Twilio Settings</div>
      <div class="card-body">
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="activate">Activate SMS</el-button>
        <div class="title">Assigned Phone Numbers</div>
        <div class="phones">
          <div class="small" v-for="phone in phones">
            <a :href="'tel:' + phone" target="_blank">{{ phone }}</a>
          </div>
        </div>

        <div class="title" style="margin-top: 1rem;">Billed Usage</div>
        <div v-for="item in total">
          <label>{{ item.description }} {{ item.count ? '(' + item.count + ' ' + item.count_unit + ')' : '' }}</label>
          <div class="small">{{ item.price }} {{ item.price_unit }}</div>
        </div>
      </div>
    </div>
    <el-card>
      <div class="display-medium">SMS Usage per month</div>
      <el-table :data="stats.monthly" style="width: 100%" v-if="stats">
        <el-table-column prop="month" label="Date"></el-table-column>
        <el-table-column prop="count" label="Count"></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
  import * as api from '@/api';

  export default {
    data() {
      return {
        phones: [],
        total: [],
        stats: null,
      };
    },
    created() {
      this.getTwilioData();
    },
    methods: {
      getTwilioData() {
        api.getTwilioData().then((response) => {
          this.phones = response.phones;
          this.total = response.total;
          this.stats = response.stats;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      activate() {
        api.activateSMS().then((response) => {
          this.$toasted.success(response.message);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .card-body .title {
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.43;
    color: rgb(29, 54, 75);
    margin-bottom: .25rem;
  }

  .el-card {
    margin-top: 1rem;
  }
</style>
