<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Create a new Funnel
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <label>Name</label>
                <input type="text" v-model.trim="name" class="form-control" placeholder="Name" @keyup.enter="createFunnel" />
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="button" class="xpl-button xpl-button--primary" @click.prevent="createFunnel" :disabled="!name">Create</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    data() {
      return {
        name: '',
      };
    },
    methods: {
      closeModal() {
        EventBus.$emit('create_funnel.close');
      },
      createFunnel(event) {
        if (!this.name.length) {
          return;
        }

        const target = event.target;
        target.disabled = true;

        api.createFunnel({ name: this.name }).then((response) => {
          this.$toasted.success(response.message);
          this.closeModal();
          this.$router.push(`/contacts/funnels/${response.funnel.uuid}`);
        }).catch((err) => {
          target.disabled = false;
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
