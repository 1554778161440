<template>
  <div>
    <div :class="['nav-subheader', tabs.lists ? 'fixed' : '']" v-cloak>
      <div>Contacts</div>
      <div class="nav-pills">
        <a :class="[tabs.all ? 'active' : '', 'pill']" href="/contacts/">All Contacts</a>
        <a :class="[tabs.lists ? 'active' : '', 'pill']" href="/contacts/lists">Segments</a>
        <a :class="[tabs.embed ? 'active' : '', 'pill']" href="/contacts/embed">Sign Up Forms</a>
        <a :class="[tabs.funnels ? 'active' : '', 'pill']" href="/contacts/funnels" v-if="$store.state.user.current_account.funnel_enabled" style="display: flex;">Funnels<span class="tag text-uppercase">beta</span></a>
      </div>
    </div>
    <AllContacts v-if="tabs.all" />
    <ContactLists v-if="tabs.lists" />
    <EmbeddableForms v-if="tabs.embed" />
    <Funnels v-if="tabs.funnels" />
  </div>
</template>
<script>
  import AllContacts from './AllContacts';
  import ContactLists from './ContactLists';
  import EmbeddableForms from './EmbeddableForms';
  import Funnels from './Funnels';

  export default {
    components: {
      AllContacts,
      ContactLists,
      EmbeddableForms,
      Funnels,
    },
    data() {
      return {
        tabs: {
          all: true,
          lists: false,
          embed: false,
          funnels: false,
          add_contacts: false,
        },
      };
    },
    beforeRouteEnter(to, from, next) {
      if (to.path === '/contacts/lists') {
        next((vm) => {
          vm.tabs.all = false;
          vm.tabs.lists = true;
          vm.tabs.embed = false;
          vm.tabs.funnels = false;
        });
      } else if (to.path === '/contacts/embed') {
        next((vm) => {
          vm.tabs.all = false;
          vm.tabs.lists = false;
          vm.tabs.embed = true;
          vm.tabs.funnels = false;
        });
      } else if (to.path === '/contacts/funnels') {
        next((vm) => {
          vm.tabs.all = false;
          vm.tabs.lists = false;
          vm.tabs.embed = false;
          vm.tabs.funnels = true;
        });
      } else {
        next((vm) => {
          vm.tabs.all = true;
          vm.tabs.lists = false;
          vm.tabs.embed = false;
          vm.tabs.funnels = false;
        });
      }
    },
    mounted() {
      // eslint-disable-next-line
      document.title = `Contacts | ${this.$store.getters.brandName}`;
    },
    beforeDestroy() {
      // eslint-disable-next-line
      document.title = this.$store.getters.brandName;
    },
    methods: {
      toggleTab(tab) {
        this.tabs.all = false;
        this.tabs.lists = false;
        this.tabs[tab] = true;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .nav-subheader.fixed {
    position: fixed;
    z-index: 3;
  }
  .container-fluid {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 2.25rem;
  }

  .container-header {
    display: flex;
    justify-content: space-between;
  }

  a.pill:hover {
    text-decoration: none;
  }

  .tag {
    font-size: .5rem;
    line-height: 1rem;
    margin-left: .5rem;
    padding: .125rem .5rem;
    background-color: #f3f7f9;
    color: #1d364b;
    height: auto;
    border-radius: 6px;
    text-align: center;
    border: 1px solid #cdd7e2;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: auto;
  }
</style>
