<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner">
      Behavior
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <label>Choose the status of the visit</label>
        <div class="multiselect-block">
          <Multiselect v-model="status" placeholder="Select a behavior" :options="available_statuses" :searchable="false" :allow-empty="false" :show-labels="false" :multiple="true" />
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Has / Has Not
    </div>
    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="!visited" @click="visited = true"></span>
            <span class="icon icon-radio-checked" v-if="visited" @click="visited = true"></span>
          </div>
          <div class="col-xs-10">
            <label for="visited_yes" @click="visited = true">
              Has been marked as
              <span v-for="(st, index) in status">
                {{ st }} <span v-if="index !== (status.length - 1)">or</span>
              </span>
            </label>
            <div class="small text-muted">Get contacts who meet the visit behavior you set below</div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="visited === null || visited === true" @click="visited = false"></span>
            <span class="icon icon-radio-checked" v-if="visited === false" @click="visited = false"></span>
          </div>
          <div class="col-xs-10">
            <label for="visited_no" @click="visited = false">
              Has not been marked as
                <span v-for="(st, index) in status">
                  {{ st }} <span v-if="index !== (status.length - 1)">or</span>
                </span>
            </label>
            <div class="small text-muted">Get contacts who do not have the visit behavior you set below</div>
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Time Frame
    </div>
    <form @submit.prevent>
      <div class="form-group" v-if="enrollment">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'entering_campaign'" @click="timeframe = 'entering_campaign'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'entering_campaign'" @click="timeframe = 'entering_campaign'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label @click="timeframe = 'entering_campaign'; comparison = null;">
              Since entering this campaign
            </label>
            <small class="form-text text-muted">See if the contact has met this condition since entering this auto-campaign</small>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'absolute'" @click="timeframe = 'absolute'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'absolute'" @click="timeframe = 'absolute'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_absolute" @click="timeframe = 'absolute'; comparison = null;">
              Around specific dates
            </label>
            <small class="form-text text-muted">Select before, after, or between static dates. e.g. After July 27th 2018</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="timeframe === 'absolute'">
        <div class="form-inline">
          <label>These visits occured</label>&nbsp;
          <Multiselect v-model="comparison" placeholder="Select a comparison" :options="comparisons_date" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" style="max-width: 13rem; min-width: 13rem; width: 13rem;" />
        </div>

        <div class="dates" v-if="comparison && timeframe === 'absolute'">
          <div class="date">
            <vue-datepicker-local v-model="dates.start" :local="local" format="MM-DD-YYYY" />
          </div>
          <span v-if="comparison.value === 'between'">and</span>
          <div class="date" v-if="comparison.value === 'between'">
            <vue-datepicker-local v-model="dates.end" :local="local" format="MM-DD-YYYY" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'relative'" @click="timeframe = 'relative'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'relative'" @click="timeframe = 'relative'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_relative" @click="timeframe = 'relative'; comparison = null;">
              Relative to Today
            </label>
            <small class="form-text text-muted">Select at least, less than, or exactly a number of days ago. This is a moving window of time. e.g. Within the last 10 days.</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="timeframe === 'relative'">
        <div class="form-inline">
          Every contact who
          <span v-for="(st, index) in status">
            {{ st }} <span v-if="index !== (status.length - 1)">or</span>
          </span>
           in the timeframe
          <Multiselect v-model="comparison" placeholder="Select a comparison" :options="comparisons_number" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" style="max-width: 13rem; min-width: 13rem; width: 13rem;" />&nbsp;
          <input type="number" name="relative_days" v-model.number="relative_days" class="form-control" min="0" style="display: inline-block; width: 5rem;" :disabled="!comparison" />&nbsp;
          days<span v-if="comparison && comparison.value !== 'within'">&nbsp;ago</span>.
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'all'" @click="timeframe = 'all'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'all'" @click="timeframe = 'all'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label @click="timeframe = 'all'; comparison = null;">
              All Time
            </label>
            <div class="small text-muted">See if the contact has met this condition in their entire lifetime</div>
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Details
    </div>

    <form @submit.prevent>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="filtering === null || filtering !== 'all'" @click="filtering = 'all'"></span>
            <span class="icon icon-radio-checked" v-if="filtering === 'all'" @click="filtering = 'all'"></span>
          </div>
          <div class="col-xs-10">
            <label @click="filtering = 'all'">
              Contact marked as
              <span v-for="(st, index) in status">
                {{ st }} <span v-if="index !== (status.length - 1)">or</span>
              </span>
             for any class
            </label>
            <div class="small text-muted">Get contacts who have any visit</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="filtering === null || filtering !== 'specific'" @click="filtering = 'specific'"></span>
            <span class="icon icon-radio-checked" v-if="filtering === 'specific'" @click="filtering = 'specific'"></span>
          </div>
          <div class="col-xs-10">
            <label @click="filtering = 'specific'">
              Contact marked as
              <span v-for="(st, index) in status">
                {{ st }} <span v-if="index !== (status.length - 1)">or</span>
              </span>
              for a specific class type
            </label>
            <div class="small text-muted">Get contacts who have a specific type of visit with the following details</div>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="filtering === 'specific'">
        <div class="form-group">
          <label for="classes">Class Name</label>
          <div class="multiselect-block">
            <Multiselect v-model="selected_classes" placeholder="Select Classes" :options="classes" label="name" track-by="name" :searchable="true" :allow-empty="true" :show-labels="false" :multiple="true" :close-on-select="false" />
          </div>
        </div>

        <div class="form-group">
          <label>Pricing Options</label>
          <div class="multiselect-block">
            <Multiselect v-model="selected_pricing_options" placeholder="Select Pricing Options" :options="pricing_options" label="name" track-by="id" :searchable="true" :allow-empty="true" :show-labels="false" :multiple="true" :close-on-select="false" />
          </div>
        </div>

        <div class="form-group">
          <label for="staffes">Staff Name</label>
          <div class="multiselect-block">
            <Multiselect v-model="selected_staffes" placeholder="Select Staff" :options="staffes" label="staff_name" track-by="staff_name" :searchable="true" :allow-empty="true" :show-labels="false" :multiple="true" :close-on-select="false" />
          </div>
        </div>

        <div class="form-group">
          <label for="staffes">Between these times of day</label>
          <div class="dates">
            <div class="multiselect-block">
              <Multiselect v-model="selected_time_start" placeholder="Start" :options="available_times" :searchable="true" :allow-empty="true" :show-labels="false" style="max-width: 8rem; min-width: 8rem; width: 8rem;" />
            </div>
            <div class="multiselect-block">
              <Multiselect v-model="selected_time_end" placeholder="End" :options="available_times" :searchable="true" :allow-empty="true" :show-labels="false" style="max-width: 8rem; min-width: 8rem; width: 8rem;" />
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="form-group">
      <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition">Save this condition</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';
  import VueDatepickerLocal from 'vue-datepicker-local';

  export default {
    props: ['condition', 'editedRule', 'enrollment', 'goalList'],
    components: {
      Multiselect,
      VueDatepickerLocal,
    },
    data() {
      return {
        pricing_options: [],
        selected_pricing_options: [],
        filtering: 'all',
        status: null,
        available_statuses: [],
        visited: true,
        relative_days: 0,
        timeframe: 'all',
        comparison: null,
        comparisons_date: [{
          label: 'After',
          value: 'after',
        }, {
          label: 'Before',
          value: 'before',
        }, {
          label: 'Between',
          value: 'between',
        }],
        comparisons_number: [{
          label: 'At least',
          value: 'more',
        }, {
          label: 'Within the last',
          value: 'within',
        }, {
          label: 'Exactly',
          value: 'exactly',
        }],
        dates: {
          start: '',
          end: '',
        },
        first_visit_type: null,
        selected_staffes: [],
        staffes: [],
        selected_classes: [],
        classes: [],
        available_times: [],
        selected_time_start: null,
        selected_time_end: null,
        local: {
          dow: 1,
          hourTip: '',
          minuteTip: '',
          secondTip: '',
          yearSuffix: '',
          monthsHead: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
          months: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
          weeks: 'Mon_Tue_Wed_Thu_Fri_Sat_Sun'.split('_'),
        },
      };
    },
    created() {
      this.fetchPricingOptions();
      this.fetchClasses();
      this.fetchStaffes();
      this.fetchClassTimes();

      if (this.editedRule) {
        this.status = this.editedRule.status;
        this.visited = this.editedRule.visited;
        this.timeframe = this.editedRule.timeframe;

        if (this.timeframe === 'relative') {
          this.comparison = this.comparisons_number.filter(c =>
            c.value === this.editedRule.comparison,
          )[0];
        }
        if (this.timeframe === 'absolute') {
          this.comparison = this.comparisons_date.filter(c =>
            c.value === this.editedRule.comparison,
          )[0];
        }

        this.relative_days = this.editedRule.relative_days;
        this.dates.start = this.editedRule.start_date;
        this.dates.end = this.editedRule.end_date;
        this.filtering = this.editedRule.filtering;
        this.selected_classes = this.editedRule.classes;
        this.selected_staffes = this.editedRule.staffes;
        this.selected_time_start = this.editedRule.start_time;
        this.selected_time_end = this.editedRule.end_time;
        this.selected_pricing_options = this.editedRule.pricing_options;
      }
    },
    methods: {
      fetchPricingOptions() {
        api.getPricingOptions().then((response) => {
          this.pricing_options = response.pricing_options;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchClasses() {
        api.getClasses().then((response) => {
          this.classes = response.classes;
          this.available_statuses = response.statuses;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchStaffes() {
        api.getStaffNames().then((response) => {
          this.staffes = response.staff_names;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchClassTimes() {
        api.getClassTimes().then((response) => {
          this.available_times = response.class_times;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          status: this.status,
          visited: this.visited,
          timeframe: this.timeframe,
          relative_days: this.relative_days,
          comparison: this.comparison ? this.comparison.value : null,
          start_date: this.dates.start,
          end_date: this.dates.end,
          filtering: this.filtering,
          classes: this.selected_classes,
          staffes: this.selected_staffes,
          start_time: this.selected_time_start,
          end_time: this.selected_time_end,
          pricing_options: this.selected_pricing_options,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  .dates div {
    margin-right: .5rem;
  }

  .dates div:last-child {
    margin-right: 0;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }

  .see-more {
    cursor: pointer;
  }
</style>
