<template>
  <div>
    <div class="tabs">
      <a href="#" :class="{ active: sidebar_tabs.build }" @click.prevent="toggleSidebarTab('build')">Build</a>
      <a href="#" :class="{ active: sidebar_tabs.style }" @click.prevent="toggleSidebarTab('style')">Style</a>
    </div>

    <div class="build_tab" v-show="sidebar_tabs.build">
      <form @submit.prevent>
        <div class="form-group">
          <div><img :src="this.currentElement.attributes.src" class="img-fluid" id="currentImage" /></div>
          <button type="button" class="xpl-button xpl-button--secondary" @click.stop="assetBrowser">Replace</button>
        </div>
      </form>

      <div class="banner">Image Link</div>

      <form @submit.prevent>
        <div class="form-group">
          <label>Link Type</label>
          <el-select v-model="link_type" placeholder="Link Type" style="width: 100%;">
            <el-option :key="'Web Address'" :label="'Web Address'" :value="'Web Address'"></el-option>
            <el-option :key="'Email Address'" :label="'Email Address'" :value="'Email Address'"></el-option>
            <el-option :key="'Landing Page'" :label="'Landing Page'" :value="'Landing Page'" v-if="!$store.getters.isUIAVendor"></el-option>
          </el-select>
        </div>

        <div v-if="link_type !== 'Landing Page'">
          <div class="form-group">
            <label>{{ link_type === 'Web Address' ? 'Link' : 'Email' }}</label>
            <input type="text" v-model="link" class="form-control" placeholder="https://brandbot.com" @input="hrefEdited" @touch="$v.link.$touch()" v-if="link_type === 'Web Address'" />
            <input type="email" v-model="link" class="form-control" placeholder="me@email.com" @input="hrefEdited" @touch="$v.link.$touch()" v-if="link_type === 'Email Address'" />
            <div class="small helper text-danger" v-if="link_type === 'Email Address' && link && $v.link.$invalid">Please make sure your email is valid.</div>
            <a href="#" class="small helper" @click.prevent="save_link = true" v-if="link">Click here to save this {{ link_type === 'Web Address' ? 'link' : 'email'  }}.</a>
          </div>

          <div class="form-group" v-if="save_link">
            <label>{{ link_type === 'Web Address' ? 'Link' : 'Email' }} Name</label>
            <input type="text" v-model="link_name" class="form-control" placeholder="Name" />
          </div>

          <div class="form-group" v-if="save_link">
            <button type="button" class="xpl-button xpl-button--primary" @click="saveLink" :disabled="!link_name">Save Link</button>
          </div>

          <div class="form-group">
            <button type="button" class="xpl-button xpl-button--secondary" @click="show_saved_links_modal = !show_saved_links_modal">Select from Saved Links</button>
          </div>
        </div>

        <div v-if="link_type === 'Landing Page'">
          <el-form style="padding: 0;">
            <el-form-item>
              <label>Link</label>
              <el-input v-model="link" :disabled="true" placeholder="Landing Page URL"></el-input>
            </el-form-item>
          </el-form>
          <el-button type="secondary" class="xpl-button xpl-button--secondary"  @click="landingPageModal">Landing Pages</el-button>
        </div>
      </form>
    </div>

    <div class="style_tab" v-show="sidebar_tabs.style">
      <div class="banner">Image Styling</div>
        <form @submit.prevent>
          <div class="form-group">
            <label>Image Width</label>
            <input type="number" ref="image_width" class="form-control" min="1" @input="imageWidthEdited($event.target.value)" />
          </div>

          <div class="form-group">
            <label>Alignment</label>
            <div class="alignments">
              <div :class="{ alignment: true, selected: currentElement.attributes.align === 'left' }" @click="currentElement.attributes.align = 'left'; attributeEdited();">
                <span class="material-icons">format_align_left</span>
              </div>
              <div :class="{ alignment: true, selected: currentElement.attributes.align === 'center' }" @click="currentElement.attributes.align = 'center'; attributeEdited();">
                <span class="material-icons">format_align_center</span>
              </div>
              <div :class="{ alignment: true, selected: currentElement.attributes.align === 'right' }" @click="currentElement.attributes.align = 'right'; attributeEdited();">
                <span class="material-icons">format_align_right</span>
              </div>
            </div>
          </div>
        </form>
      <div class="banner">Container Styling</div>

        <form @submit.prevent>
          <div class="group">
            <div class="form-group">
              <label>Spacing Top</label>
              <input type="number" ref="spacing_top" class="form-control" min="0" @input="spacingTopEdited($event.target.value)" />
            </div>

            <div class="form-group">
              <label>Spacing Bottom</label>
              <input type="number" ref="spacing_bottom" class="form-control" min="0" @input="spacingBottomEdited($event.target.value)" />
            </div>
          </div>

          <div class="form-group">
            <label>Horizontal Spacing</label>
            <input type="number" ref="horizontal_spacing" class="form-control" min="0" @input="horizontalSpacingEdited($event.target.value)" />
          </div>

          <div class="form-group" v-if="!$store.state.user.current_account.brand_restriction">
            <label>Background Color</label>
            <div class="input-group">
              <span class="input-group-addon">
                <span class="icon icon-square" :style="'color: ' + background_color.hex" @click="show_background_color_picker = !show_background_color_picker"></span>
              </span>
              <input type="text" ref="background_color" v-model="background_color.hex" class="form-control" readonly />
            </div>
            <Sketch v-model="background_color" :disableAlpha="true" @input="backgroundColorEdited" v-if="show_background_color_picker" />
          </div>
        </form>
    </div>

    <SavedLinksModal v-if="show_saved_links_modal" />

    <div class="footer">
      <button type="button" class="xpl-button xpl-button--primary" @click="saveAndCloseBlock">Save and close</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import _ from 'lodash';
  import { Sketch } from 'vue-color';
  import Multiselect from 'vue-multiselect';
  import { email, required } from 'vuelidate/lib/validators';
  import SavedLinksModal from './SavedLinksModal';

  export default {
    props: ['currentElement'],
    components: {
      Multiselect,
      Sketch,
      SavedLinksModal,
    },
    data() {
      return {
        save_link: false,
        link_name: '',
        link: '',
        link_type: 'Web Address',
        show_saved_links_modal: false,
        show_background_color_picker: false,
        background_color: {
          hex: null,
        },
        sidebar_tabs: {
          build: true,
          style: false,
        },
        editor: null,
      };
    },
    validations() {
      if (this.link_type === 'Email Address') {
        return {
          link: {
            required,
            email,
          },
        };
      }

      return {
        link: {
          required,
        },
      };
    },
    mounted() {
      EventBus.$on('landing_page.link', (link) => {
        if (link.indexOf('.html') === -1) {
          this.link = `${link}.html`;
        } else {
          this.link = link;
        }
        this.hrefEdited();
      });

      EventBus.$on('asset_browser.select', (url) => {
        this.currentElement.attributes.src = url;
        this.attributeEdited();
      });

      EventBus.$on('saved_links_modal.close', () => {
        this.show_saved_links_modal = false;
      });

      EventBus.$on('saved_links_modal.selected', (link) => {
        this.link = link.url;
        this.hrefEdited();
        this.show_saved_links_modal = false;
      });

      this.link = this.currentElement.attributes.href ? this.currentElement.attributes.href.replace('mailto:', '') : null;
      this.link_type = (this.currentElement.attributes.href && this.currentElement.attributes.href.indexOf('mailto:') > -1) || !this.currentElement.attributes.href ? 'Email Address' : 'Web Address';
      this.background_color.hex = this.currentElement.attributes['container-background-color'];
      this.$refs.image_width.value = this.currentElement.attributes.width ? this.currentElement.attributes.width.replace('px', '') : null;
      this.$refs.spacing_top.value = this.currentElement.attributes['padding-top'] ? this.currentElement.attributes['padding-top'].replace('px', '') : null;
      this.$refs.spacing_bottom.value = this.currentElement.attributes['padding-bottom'] ? this.currentElement.attributes['padding-bottom'].replace('px', '') : null;
      this.$refs.horizontal_spacing.value = this.currentElement.attributes['padding-right'] ? this.currentElement.attributes['padding-right'].replace('px', '') : null;
    },
    beforeDestroy() {
      EventBus.$off('asset_browser.select');
      EventBus.$off('saved_links_modal.selected');
      EventBus.$off('saved_links_modal.close');
      EventBus.$off('landing_page.link');
    },
    watch: {
      currentElement(newVal) {
        this.link = newVal.attributes.href ? newVal.attributes.href.replace('mailto:', '') : null;
        this.link_type = (newVal.attributes.href && newVal.attributes.href.indexOf('mailto:') > -1) || !newVal.attributes.href ? 'Email Address' : 'Web Address';
        this.background_color.hex = newVal.attributes['container-background-color'];
        this.$refs.image_width.value = newVal.attributes.width ? newVal.attributes.width.replace('px', '') : null;
        this.$refs.spacing_top.value = newVal.attributes['padding-top'] ? newVal.attributes['padding-top'].replace('px', '') : null;
        this.$refs.spacing_bottom.value = newVal.attributes['padding-bottom'] ? newVal.attributes['padding-bottom'].replace('px', '') : null;
        this.$refs.horizontal_spacing.value = newVal.attributes['padding-right'] ? newVal.attributes['padding-right'].replace('px', '') : null;
      },
    },
    methods: {
      assetBrowser() {
        EventBus.$emit('asset_browser.open');
      },
      landingPageModal() {
        EventBus.$emit('landing_page.open');
      },
      saveAndCloseBlock() {
        EventBus.$emit('editor.close');
      },
      saveLink() {
        if (!this.save_link) {
          return;
        }

        if (!this.link_name.length) {
          this.$toasted.error('Please name your link.');
        }

        const payload = {
          type: 'link',
          link: this.link,
          link_name: this.link_name,
        };

        api.saveSavedLinks(payload).then((response) => {
          this.$toasted.success(response.message);
          this.link_name = '';
          this.save_link = false;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      toggleSidebarTab(tab) {
        this.sidebar_tabs.build = false;
        this.sidebar_tabs.style = false;

        this.sidebar_tabs[tab] = true;
      },
      editImage() {
        //
      },
      attributeEdited: _.debounce(() => {
        EventBus.$emit('email.edit.attributes');
      }, 300,
      ),
      backgroundColorEdited: _.debounce(function customFunction(data) {
        this.currentElement.attributes['container-background-color'] = data.hex;
        this.attributeEdited();
      }, 300,
      ),
      spacingTopEdited: _.debounce(function customFunction(value) {
        this.currentElement.attributes['padding-top'] = `${value}px`;
        this.attributeEdited();
      }, 300,
      ),
      spacingBottomEdited: _.debounce(function customFunction(value) {
        this.currentElement.attributes['padding-bottom'] = `${value}px`;
        this.attributeEdited();
      }, 300,
      ),
      horizontalSpacingEdited: _.debounce(function customFunction(value) {
        this.currentElement.attributes['padding-right'] = `${value}px`;
        this.currentElement.attributes['padding-left'] = `${value}px`;

        this.attributeEdited();
      }, 300,
      ),
      imageWidthEdited: _.debounce(function customFunction(value) {
        this.currentElement.attributes.width = `${value}px`;
        this.attributeEdited();
      }, 300,
      ),
      hrefEdited: _.debounce(function customFunction() {
        let link = this.link;
        if (!link.length) {
          delete this.currentElement.attributes.href;
          this.attributeEdited();
          return;
        }

        if (this.link_type === 'Email Address') {
          link = `mailto:${this.link}`;
        } else if (this.link_type === 'Web Address' && !this.link.toLowerCase().startsWith('http') && !this.link.toLowerCase().includes('_url')) {
          link = `http://${this.link}`;
        }

        this.currentElement.attributes.href = link;
        this.attributeEdited();
      }, 300,
      ),
    },
  };
</script>
<style lang="scss" scoped>
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgb(205, 215, 226);
    padding-top: .75rem;
    padding-bottom: .75rem;
    padding-left: 1.5rem;
    background-color: white;
    z-index: 1001;
  }

  .tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tabs a {
    width: 50%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: rgb(29, 54, 75);
    font-size: .875rem;
    text-align: center;
    border-bottom: 2px solid rgb(217, 231, 240);
    transition: all 150ms ease-in-out;
  }

  .tabs a:hover,
  .tabs a.active {
    text-decoration: none;
    border-bottom: 2px solid var(--xpl-color-secondary-lm);
  }

  .banner {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1.5rem;
    background-color: var(--xpl-color-background-lm);
    border-top: 1px solid #d9e7f0;
    border-bottom: 1px solid #d9e7f0;
    font-size: .75rem;
    line-height: 1rem;
    color: rgb(139, 155, 172);
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  form {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  label {
    font-size: .875rem;
    line-height: 20px;
    color: rgb(29, 54, 75);
    margin-bottom: .25rem;
  }

  .group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .group .form-group {
    margin-right: .5rem;
  }

  .group .form-group:last-child {
    margin-right: 0;
  }

  .alignments {
    display: flex;
    align-items: center;
  }

  .alignments .alignment {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.25rem;
    height: 2.5rem;
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    border: 1px solid  rgb(175, 188, 202);
    margin-right: .25rem;
    transition: all 150ms ease-in-out;
    color: #617080;
  }

  .alignments .alignment:last-child {
    margin-right: 0;
  }

  .alignments .alignment:hover,
  .alignments .alignment.selected {
    border: 2px solid var(--xpl-color-secondary-lm);
    color: var(--xpl-color-secondary-lm);
  }

  input[readonly] {
    background-color: white;
  }

  .input-group-addon {
    padding: .25rem .5rem;
    padding-right: 0;
  }

  .input-group .form-control {
    z-index: 1 !important;
  }

  .icon-square {
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 1.5rem;
    cursor: pointer;
  }

  .build_tab,
  .style_tab {
    padding-bottom: 4rem;
  }

  img {
    margin-top: 2rem;
    max-height: 9.375rem;
    margin-bottom: 2rem;
  }

  .build_tab button {
    margin-right: 1rem;
  }

  .build_tab a {
    font-size: .875rem;
    color: rgb(46, 68, 87);
    text-decoration: underline;
  }
</style>
