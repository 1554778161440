<template>
  <div class="condition">
    <form @submit.prevent>
      <div class="form-group">
        Any contact when they visit <input type="number" v-model="visit_number" class="form-control" /> times ever.
      </div>
      <button class="xpl-button xpl-button--primary" @click.stop="commitCondition">Save</button>
    </form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    props: ['uuid', 'rule'],
    data() {
      return {
        visit_number: null,
      };
    },
    created() {
      if (this.rule && this.rule.length && this.rule[0].visit_number_start) {
        this.visit_number = this.rule[0].visit_number_start;
      }
    },
    methods: {
      commitCondition(event) {
        const target = event.target || event.srcElement;
        target.disabled = true;
        target.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';

        const payload = {
          condition: {
            uuid: this.uuid,
            quick: true,
          },
          visit_number_start: this.visit_number * 1,
          visit_number_end: null,
          comparison_visit: 'exactly',
          misc: {
            filtering: false,
          },
        };
        EventBus.$emit('condition.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .form-group {
    display: flex;
    align-items: center;
  }

  .form-group input {
    width: auto;
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .btn-bb-primary {
    margin-top: 1.5rem;
  }
</style>
