<template>
  <div class="field">
    <div class="header">
      Adding a dropdown
      <span class="delete" @click.stop="removeDropdown">Delete</span>
    </div>
    <form @submit.prevent>
      <div class="form-group">
        <label>Input Label</label>
        <input type="text" v-model="label" class="form-control" placeholder="Input Label" :disabled="field" />
      </div>

      <div class="form-group">
        <label>Build your options</label>
        <div class="input" v-for="(option, index) in options">
          <input type="text" v-model="option.label" class="form-control option" placeholder="Option 1" />
          <span class="material-icons" @click.stop="removeOption(index)">delete</span>
        </div>
        <button type="button" class="xpl-button xpl-button--secondary" @click="addMore">Add another option</button>
      </div>

      <div class="form-group">
        <button type="button" class="xpl-button xpl-button--primary" @click.stop="commit">Save</button>
      </div>
    </form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    props: ['field', 'fields'],
    data() {
      return {
        label: '',
        options: [{
          label: 'Option 1',
        }],
      };
    },
    created() {
      if (this.field) {
        this.label = this.fields[this.field].label;
        this.options = this.fields[this.field].options;
      }
    },
    methods: {
      removeOption(index) {
        if (this.options.length > 1) {
          this.$delete(this.options, index);
        }
      },
      removeDropdown() {
        EventBus.$emit('dropdown.remove', this.field);
      },
      addMore() {
        const payload = {
          label: `Option ${this.options.length + 1}`,
        };
        this.options.push(payload);
      },
      commit() {
        const payload = {
          type: 'dropdown',
          label: this.label,
          options: this.options,
        };

        EventBus.$emit('dropdown.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .field {
    margin-top: .75rem;
    padding: 1rem;
    width: 23rem;
    max-width: 23rem;
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    border: solid 1px rgb(214, 224, 232);
  }

  .field .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    color: rgb(29, 54, 75);
  }

  .field .header .delete {
    font-weight: normal;
    cursor: pointer;
    color: rgb(214, 41, 41);
  }

  .field .header .delete:hover {
    text-decoration: underline;
  }

  .field form {
    margin-top: 1rem;
  }

  .field form .input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .5rem;
  }

  .field form .input .material-icons {
    font-size: .875rem;
    color: rgb(175, 188, 202);
    margin-left: 1.25rem;
  }
</style>
