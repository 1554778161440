<template>
  <div>
    <div class="display-medium" style="margin-bottom: 2rem;">Waiver Advanced Options</div>
    <el-card v-loading="syncLoading" v-if="currentVendor === 'mindbody'">
      <div class="display-small" style="margin-bottom: 1rem;">Integration with Booking Software</div>
      <el-form>
        <el-form-item>
          <label>Do you want to create or update customers in your Booking Software?</label>
          <div>
            <el-radio v-model="sync.active" :label="true" border>Yes, update or create them</el-radio>
            <el-radio v-model="sync.active" :label="false" border>No, keep all information in {{$store.getters.brandName}} only</el-radio>
          </div>
        </el-form-item>
        <el-form-item v-if="sync.active">
          <div class="options">
            <label>Which fields would you like to sync?</label>
            <div>
              <el-checkbox v-model="fields.phone" @change="syncFields" label="Phone Number"></el-checkbox>
            </div>
            <div>
              <el-checkbox v-model="fields.address" @change="syncFields" label="Address"></el-checkbox>
            </div>
            <div v-if="currentVendor === 'mindbody'">
              <el-checkbox v-model="fields.emg_contact" @change="syncFields" label="Emergency Contact"></el-checkbox>
            </div>
            <div v-if="currentVendor === 'mindbody'">
              <el-checkbox v-model="fields.referral_source" @change="syncFields" label="Referral Source"></el-checkbox>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="saveSync">Save</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card v-if="sync.active && currentVendor === 'mindbody' && $store.state.user.is_super">
      <div class="display-small" style="margin-bottom: 1rem;">Required MINDBODY Fields</div>
      <p><b>This is only visible to {{$store.getters.brandName}} Admin for now.</b></p>
      <p>These fields are required in your MINDBODY setup when creating a profile. Please make sure they are also present on your Form.</p>
      <el-table :data="requiredFields" style="width: 100%">
        <el-table-column prop="name"></el-table-column>
      </el-table>
    </el-card>

    <el-card v-if="currentVendor === 'marianatek'">
      <div class="display-small" style="margin-bottom: 1rem;">Link this waiver to a Mariana Tek Waiver</div>
      <el-form>
        <el-form-item>
          <label v-if="allowedAccessToWaiver">Do you want to create a new waiver in Mariana Tek or link an existing one?</label>
          <label v-if="!allowedAccessToWaiver">Do you want to link an existing one?</label>
          <div>
            <el-radio v-model="marianatek.waiver_type" label="new" border v-if="allowedAccessToWaiver">Create a new one</el-radio>
            <el-radio v-model="marianatek.waiver_type" label="existing" border>Link an existing one</el-radio>
          </div>
        </el-form-item>
        <div class="options" v-if="marianatek.waiver_type === 'new'">
          <el-form-item>
            <label>Name of this Waiver in Mariana Tek</label>
            <el-input v-model="marianatek.name" placeholder="Waiver Name" />
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="marianatek.is_required" label="Make this waiver required" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="xpl-button xpl-button--primary" @click="createWaiver" :loading="MTLoading" :disabled="!marianatek.name">Create and Link</el-button>
          </el-form-item>
        </div>
        <div class="options" v-if="marianatek.waiver_type === 'existing'">
          <el-form-item>
            <label>Name of this Waiver in Mariana Tek</label>
            <el-select v-model="marianatek.waiver_id" placeholder="Mariana Tek Waiver" style="width: 100%;">
              <el-option v-for="waiver in marianatek.waivers" :key="waiver.id" :label="waiver.name" :value="waiver.id">
                <span>{{ waiver.name }}</span>
                <span class="display-caption subdued pull-right" v-if="waiver.is_required">
                  Required
                </span>
                <span class="display-caption subdued pull-right" v-if="!waiver.is_required">
                  Not Required
                </span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="xpl-button xpl-button--primary" @click="linkWaiver" :loading="MTLoading" v-if="marianatek.waiver_id && !marianatek.linked" :disabled="!marianatek.waiver_id">Link</el-button>
            <el-button type="primary" class="xpl-button xpl-button--primary" @click="unlinkWaiver" :loading="MTLoading" v-if="marianatek.waiver_id && marianatek.linked" :disabled="!marianatek.waiver_id">Unlink</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-card>

    <el-card v-loading="redirectLoading">
      <div class="display-small" style="margin-bottom: 1rem;">Redirection</div>
      <el-form>
        <el-form-item>
          <label>Do you want to send a customer to another page after they complete the Waiver?</label>
          <div>
            <el-radio v-model="redirect.active" :label="true" border>Yes, redirect them</el-radio>
            <el-radio v-model="redirect.active" :label="false" border>No, do not send them to another page</el-radio>
          </div>
        </el-form-item>
        <el-form-item v-if="redirect.active">
          <div class="options">
            <label>Redirect them to</label>
            <el-select v-model="redirect.type" placeholder="Redirect To" style="width: 100%">
              <el-option :key="'url'" :label="'URL'" :value="'url'"></el-option>
              <el-option :key="'pdf'" :label="'PDF File'" :value="'pdf'"></el-option>
            </el-select>
            <div v-if="redirect.type === 'url'">
              <label>What is the URL of the webpage?</label>
              <el-input v-model="redirect.url" placeholder="URL" />
            </div>
            <div style="margin-top: 2rem;" v-if="redirect.type === 'pdf'">
              <el-row :span="24">
                <el-col :span="12">
                  <label>Upload your PDF File below</label>
                  <el-upload
                      :multiple="false"
                      :limit="1"
                      accept="application/pdf"
                      action=""
                      :http-request="uploadFile" >
                      <el-button size="small" type="primary" class="xpl-button xpl-button--primary">Upload</el-button>
                  </el-upload>
                </el-col>
                <el-col :span="12">
                  <label>Select an existing PDF</label>
                  <el-select v-model="redirect.url" placeholder="PDF File" style="width: 100%;">
                    <el-option v-for="item in saved_pdfs" :key="item.id" :label="item.name" :value="item.url"></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-form-item>
        <el-form-item v-if="!redirect.active">
          <div class="options">
            <label>What message would you like to show?</label>
            <el-input v-model="redirect.message" placeholder="You have successfully completed this form!" />
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="saveRedirect">Save</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card v-loading="notificationLoading">
      <div class="display-small" style="margin-bottom: 1rem;">Notifications</div>
      <el-form>
        <el-form-item>
          <label>Send a notification to the email(s) below every time a contact fills out this waiver</label>
          <el-input v-model="notification.email" placeholder="email1@domain.com, email2@domain.com" />
          <div class="small text-muted">Separate multiple emails using commas.</div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="saveNotification">Save</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import {
    updateForm,
    uploadAssetImage,
    getSavedPdfs,
    getFormMBORequiredFields,
    getMTWaivers,
    createMTWaiver,
    linkMTWaiver,
    unlinkMTWaiver,
  } from '@/api';

  export default {
    props: ['form'],
    data() {
      return {
        MTLoading: false,
        currentVendor: this.$store.state.user.current_account.active_vendors[0],
        saved_pdfs: [],
        notificationLoading: false,
        notification: {
          active: true,
          email: '',
        },
        redirectLoading: false,
        redirect: {
          active: false,
          message: 'You have successfully completed your form!',
          url: null,
          type: 'url',
        },
        marianatek: {
          waiver_type: null,
          name: this.form.name,
          waiver_id: null,
          is_required: false,
          waivers: [],
          linked: false,
        },
        syncLoading: false,
        sync: {
          active: true,
          fields: [],
        },
        fields: {
          first_name: false,
          last_name: false,
          phone: false,
          address: false,
          emg_contact: false,
          referral_source: false,
        },
        requiredFields: [],
      };
    },
    created() {
      this.fetchPDFs();
      this.fetchMBORequiredFields();
      this.fetchMTWaivers();

      const settings = this.form.settings;
      Object.keys(settings).forEach((k) => {
        if (k === 'sync') {
          Object.keys(settings[k]).forEach((s) => {
            this.$set(this.sync, s, settings[k][s]);
            if (s === 'fields') {
              settings[k][s].forEach((f) => {
                this.fields[f] = true;
              });
            }
          });
        }
        if (k === 'redirect') {
          Object.keys(settings[k]).forEach((s) => {
            this.$set(this.redirect, s, settings[k][s]);
          });
        }
        if (k === 'notification') {
          Object.keys(settings[k]).forEach((s) => {
            this.$set(this.notification, s, settings[k][s]);
          });
        }
      });
    },
    computed: {
      allowedAccessToWaiver() {
        if (this.$store.state.user.current_account.is_child) {
          return false;
        }

        return true;
      },
    },
    methods: {
      fetchMTWaivers() {
        getMTWaivers(this.form.uuid).then((response) => {
          this.marianatek.waivers = response.waivers;
          this.marianatek.waiver_id = response.linked_waiver;

          if (response.linked_waiver) {
            this.marianatek.waiver_type = 'existing';
            this.marianatek.linked = true;
          }
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchMBORequiredFields() {
        getFormMBORequiredFields().then((response) => {
          this.requiredFields = response.required_fields;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchPDFs() {
        getSavedPdfs().then((response) => {
          this.saved_pdfs = response.pdfs;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      linkWaiver() {
        this.MTLoading = true;
        const payload = {
          form_uuid: this.form.uuid,
          waiver_id: this.marianatek.waiver_id,
        };
        linkMTWaiver(payload).then((response) => {
          this.$message.success(response.message);
          this.MTLoading = false;
          this.marianatek.linked = true;
        }).catch((err) => {
          this.MTLoading = false;
          this.$message.error(err.message);
        });
      },
      unlinkWaiver() {
        this.MTLoading = true;
        const payload = {
          form_uuid: this.form.uuid,
          unlink: true,
        };
        unlinkMTWaiver(payload).then((response) => {
          this.$message.success(response.message);
          this.MTLoading = false;
          this.marianatek.linked = false;
        }).catch((err) => {
          this.MTLoading = false;
          this.$message.error(err.message);
        });
      },
      createWaiver() {
        this.MTLoading = true;
        const payload = {
          form_uuid: this.form.uuid,
          name: this.marianatek.name,
          is_required: this.marianatek.is_required,
          type: this.marianatek.waiver_type,
        };
        createMTWaiver(payload).then((response) => {
          this.$message.success(response.message);
          this.MTLoading = false;
        }).catch((err) => {
          this.MTLoading = false;
          this.$message.error(err.message);
        });
      },
      uploadFile(payload) {
        const formData = new FormData();
        formData.append('pdf_file', payload.file, payload.file.name);
        formData.append('type', 'pdf');

        uploadAssetImage(formData).then((response) => {
          this.$message.success(response.message);
          this.redirect.url = response.url;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      syncFields() {
        this.$set(this.sync, 'fields', Object.keys(this.fields).filter(k => this.fields[k] === true));
      },
      saveSync() {
        this.syncLoading = true;
        const payload = {
          settings: {
            sync: {
              active: this.sync.active,
              fields: this.sync.fields,
              vendor: this.$store.state.user.current_account.active_vendors[0],
            },
          },
          fields: this.form.settings.fields,
        };

        let vFields;
        if (this.currentVendor === 'mindbody') {
          vFields = ['password', 'birthday', 'emg_email', 'emg_name', 'emg_phone', 'emg_relationship', 'referral_source'];
        }

        if (this.currentVendor === 'pike13') {
          vFields = ['birthday'];
        }

        if (this.currentVendor === 'marianatek') {
          vFields = [];
        }

        payload.fields.forEach((f, k) => {
          if (vFields.indexOf(f.id) > -1) {
            payload.fields[k].include = this.sync.active;
          }
        });

        payload.fields = JSON.stringify(payload.fields);

        updateForm(this.form.id, payload).then((response) => {
          this.syncLoading = false;
          this.$message.success(response.message);
          EventBus.$emit('form:refresh');
        }).catch((err) => {
          this.syncLoading = false;
          this.$message.error(err.message);
        });
      },
      saveRedirect() {
        this.redirectLoading = true;
        const payload = {
          settings: {
            redirect: {
              active: this.redirect.active,
              url: this.redirect.url,
              message: this.redirect.message,
            },
          },
        };

        updateForm(this.form.id, payload).then((response) => {
          this.redirectLoading = false;
          this.$message.success(response.message);
        }).catch((err) => {
          this.redirectLoading = false;
          this.$message.error(err.message);
        });
      },
      saveNotification() {
        this.notificationLoading = true;
        const payload = {
          settings: {
            notification: {
              active: this.notification.active,
              email: this.notification.email,
            },
          },
        };

        updateForm(this.form.id, payload).then((response) => {
          this.notificationLoading = false;
          this.$message.success(response.message);
        }).catch((err) => {
          this.notificationLoading = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-card + .el-card {
    margin-top: 2rem;
  }

  .options {
    padding: 1.25rem;
    background-color: #f5f8fb;
    margin-top: .75rem;
  }
</style>
