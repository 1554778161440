<template>
  <div v-if="ruleList && ruleList.length"
       :class="['conditions-container', expanded || ruleList.length === 1 ? 'expanded' : '']">
    <div class="conditions" v-for="condition in ruleList">
      <div class="name"><img alt="icon"
                             :src="condition.condition.icon_url"/> {{ condition.condition.name }}
      </div>
      <div class="details uia">
        <div v-if="condition.condition.vendor === 'brandbot'">
          <BrandBotSummary :condition="condition"/>
        </div>
        <div v-for="step in condition.condition.steps">
          <RuleSummaryRow :step="step"/>
        </div>
      </div>
      <div class="and-link" v-if="ruleList.length > 1">AND</div>
    </div>
    <div class="see-more" v-if="ruleList.length > 1" @click="expanded = !expanded">
      <div class="gradient" v-if="!expanded"></div>
      <div class="see-more-inner">
        <span v-if="!expanded">Show more (total: {{ ruleList.length }})</span>
        <span v-else>Hide</span>
        <span class="material-icons" v-if="!expanded">keyboard_arrow_down</span>
        <span class="material-icons" v-else>keyboard_arrow_up</span>
      </div>
    </div>
  </div>
</template>
<script>

import RuleSummaryRow from './RuleSummaryRow';
import BrandBotSummary from './BrandBotSummary';
import { applyTemplateProperties } from '../../../../../../api';

export default {
  components: { BrandBotSummary, RuleSummaryRow },
  props: ['rule', 'enrollment'],
  data() {
    return {
      expanded: false,
      ruleList: [],
    };
  },
  mounted() {
    this.ruleList = JSON.parse(JSON.stringify(this.rule));
    this.applyUIATemplateProperties();
  },
  methods: {
    async applyUIATemplateProperties() {
      if (Array.isArray(this.ruleList)) {
        this.ruleList = await applyTemplateProperties(this.ruleList);
      }
    },
  },
  filters: {},
};
</script>
<style lang="scss" scoped>
.conditions-container {
  height: 8rem;
  overflow: hidden;

  &.expanded {
    height: auto;
    overflow: auto;
  }

  .conditions {
    .name {
      img {
        filter: grayscale(100%);
        opacity: .6;
        width: 1rem;
        height: 1rem;
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: .5rem;
        overflow: hidden;
      }

      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: .875rem;
      color: #1d364b;
      line-height: 1rem;
      margin-bottom: .5rem;
    }

    .details {
      padding-left: 1.5rem;
      text-transform: capitalize;
      color: #8b9bac;
      font-size: .75rem;
      line-height: 1rem;

      .title {
        font-weight: 600;
        color: #617080;
        min-width: 6rem;
        width: 18rem;
        margin-right: .5rem;
      }

      .disable-caps {
        text-transform: none;
      }

      .row {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        line-height: 1.25rem;
        margin: 0 0 .25rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .conditions {
    .and-link {
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-transform: uppercase;
      font-weight: 600;
      font-size: .625rem;
      color: #8B9BAC;
    }

    &:nth-last-child(2) .and-link {
      display: none;
    }
  }

  .see-more {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: .75rem;
    font-weight: 500;
    color: #3597DE;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    .material-icons {
      font-size: .75rem;
    }
  }

  &.expanded .see-more {
    padding-left: 0;
    position: relative;
  }

  .see-more-inner {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    padding-top: 2px;
    position: absolute;
    background: white;
    bottom: 0;
  }

  &.expanded {
    padding-bottom: 1rem;
  }

  &.expanded .see-more-inner {
    padding-left: 0;
    padding-bottom: 0;
    bottom: -1rem;
  }

  .gradient {
    height: 1rem;
    width: 100%;
    background: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1));
    position: absolute;
    bottom: 0;
  }
}
</style>
