<template>
  <div class="row">
    <span class="title">{{ step.name }}</span>
    <div>
      <div class="disable-caps">
        {{ selected_option | countComponent(selected_value) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CountSummary',
  props: ['step'],
  computed: {
    selected_option() {
      return this.step.default ? this.step.default.option : this.step.selected_option;
    },
    selected_value() {
      return this.step.default ? this.step.default.value : this.step.selected_value;
    },
  },
  filters: {
    countComponent(filter, boundOne, boundTwo, label) {
      if (Array.isArray(boundOne)) {
        boundTwo = boundOne[1];
        boundOne = boundOne[0];
      }
      label = label || '';
      switch (filter) {
        case 'at_least':
          return `At least ${boundOne} ${label}`;
        case 'less_than':
          return `Less than ${boundOne} ${label}`;
        case 'exactly':
          return `Exactly ${boundOne} ${label}`;
        case 'between':
          return `Between ${boundOne} and ${boundTwo} ${label}`;
        default:
          return boundTwo;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "summary_item.scss";
</style>
