<template>
  <div>
    <div class="nav-subheader">
      <div class="nav-inner">
        <div>Account Tags</div>
        <div class="nav-pills">
          <div :class="[tabs.media ? 'active' : '', 'pill']" @click="$router.push('/account/assets')">Media</div>
          <div :class="[tabs.saved_links ? 'active' : '', 'pill']" @click="$router.push('/account/saved-links')">Saved Links</div>
          <div :class="[tabs.pdf ? 'active' : '', 'pill']" @click="$router.push('/account/saved-pdfs')">Saved PDFs</div>
          <div :class="[tabs.templates ? 'active' : '', 'pill']" @click="$router.push('/account/templates')">Templates</div>
          <div :class="[tabs.tags ? 'active' : '', 'pill']" @click="$router.push('/account/tags')">Tags</div>
        </div>
      </div>
    </div>

    <div class="tags">
      <div class="heading">
        <h1>Your account tags</h1>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="new_tag_modal = !new_tag_modal">Create a New Tag</el-button>
      </div>

      <el-card v-loading="loading">
        <el-table :data="tags">
          <el-table-column label="Tag">
            <template slot-scope="scope">
              <div v-if="!scope.row.toggled">{{ scope.row.name }}</div>
              <div v-if="scope.row.toggled">
                <el-input type="text" v-model="scope.row.name" placeholder="Name" />
              </div>
            </template>
          </el-table-column>
          <el-table-column align="right" label="Actions">
            <template slot-scope="scope">
              <div v-if="!scope.row.toggled">
                <el-button type="text" size="small" @click.stop="toggleRow(scope.row)">Edit</el-button>
                <el-button size="small" @click.stop="archiveTag(scope.row)">Archive</el-button>
              </div>
              <div v-if="scope.row.toggled">
                <el-button type="primary" class="xpl-button xpl-button--primary"size="small" @click.stop="editTag(scope.row)">Save</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <NewTagModal v-if="new_tag_modal" />
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import NewTagModal from './NewTagModal';

  export default {
    components: {
      NewTagModal,
    },
    data() {
      return {
        loading: false,
        new_tag_modal: false,
        tags: [],
        tabs: {
          media: false,
          saved_links: false,
          pdf: false,
          templates: false,
          tags: true,
        },
      };
    },
    mounted() {
      EventBus.$on('new_tag_modal.close', () => {
        this.new_tag_modal = false;
        this.fetchTags();
      });
    },
    created() {
      this.fetchTags();
    },
    methods: {
      toggleRow(tag) {
        if (!tag.toggled) {
          this.$set(tag, 'toggled', true);
        } else {
          tag.toggled = !tag.toggled;
        }
      },
      editTag(tag) {
        this.loading = true;
        api.updateTag(tag.id, { name: tag.name }).then((response) => {
          this.loading = false;
          this.$toasted.success(response.message);
          this.toggleRow(tag);
        }).catch((err) => {
          this.loading = false;
          this.$toasted.error(err.message);
        });
      },
      archiveTag(tag) {
        this.$confirm('Are you sure you want to archive this Tag?').then(() => {
          this.loading = true;
          api.deleteTag(tag.id).then((response) => {
            this.$toasted.success(response.message);
            this.fetchTags();
            this.loading = false;
          }).catch((err) => {
            this.loading = false;
            this.$toasted.error(err.message);
          });
        });
      },
      fetchTags() {
        this.loading = true;
        api.getCustomTags().then((response) => {
          this.loading = false;
          this.tags = response.tags;
        }).catch((err) => {
          this.loading = false;
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .nav-inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .tags {
    padding-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 44.375rem;
  }

  .tags .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.75rem;
  }

  .tags .heading h1 {
    font-size: 1.25rem;
    color: rgb(46, 68, 87);
  }
</style>
