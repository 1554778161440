<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner">
      Is / Is Not
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row" @click="is_member = true">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="is_member === null || is_member === false"></span>
            <span class="icon icon-radio-checked" v-if="is_member"></span>
          </div>
          <div class="col-xs-10">
            <label>Is A Member</label>
            <div class="small text-muted">Get contacts who are currently a member with the options below</div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row" @click="is_member = false">
          <div class="col-xs-2">
              <span class="icon icon-radio-unchecked" v-if="is_member === null || is_member === true"></span>
              <span class="icon icon-radio-checked" v-if="is_member === false"></span>
          </div>
          <div class="col-xs-10">
            <label>Is Not A Member</label>
            <div class="small text-muted">Get contacts who are not currently a member with the options below</div>
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Tag Details
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <label>Membership Type</label>
        <div class="multiselect-block">
          <Multiselect v-model="membership_type" placeholder="Select a type" :options="membership_types" label="type" track-by="id" :searchable="true" :multiple="true" :allow-empty="false" :show-labels="false" :close-on-select="false" />
        </div>
      </div>

      <div class="form-group">
        <label>Membership Status</label>
        <div class="multiselect-block">
          <Multiselect v-model="membership_status" placeholder="Select a status" :options="membership_statuses" label="status" track-by="status" :searchable="false" :multiple="true" :allow-empty="false" :show-labels="false" />
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Location and Site ID (Optional)
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="misc_filtering === null || misc_filtering === true" @click="misc_filtering = false"></span>
            <span class="icon icon-radio-checked" v-if="misc_filtering === false" @click="misc_filtering = false"></span>
          </div>
          <div class="col-xs-10">
            <label for="misc_filtering_no" @click="misc_filtering = false">
              Select all locations and Site IDs
            </label>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="misc_filtering === null || misc_filtering === false" @click="misc_filtering = true"></span>
            <span class="icon icon-radio-checked" v-if="misc_filtering === true" @click="misc_filtering = true"></span>
          </div>
          <div class="col-xs-10">
            <label for="misc_filtering_yes" @click="misc_filtering = true">
               Select specific locations or Site IDs
            </label>
          </div>
        </div>
      </div>
      <div class="options-inner" v-if="misc_filtering">
        <div class="form-group">
          <label class="form-control-label">Location</label>
          <div class="multiselect-block">
            <Multiselect v-model="location" placeholder="All Locations" label="location_name" track-by="location_id" :options="locations" :searchable="false" :allow-empty="true" :show-labels="false" />
          </div>
        </div>
      </div>
    </form>

    <div class="form-group">
      <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition">Save this condition</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['locations', 'condition', 'editedRule'],
    components: {
      Multiselect,
    },
    data() {
      return {
        is_member: true,
        membership_type: null,
        membership_types: [],
        membership_statuses: [],
        membership_status: null,
        misc_filtering: false,
        location: null,
      };
    },
    created() {
      this.fetchMembershipData();

      if (this.editedRule) {
        this.is_member = this.editedRule.is_member;
        this.membership_type = this.editedRule.membership_type;
        this.membership_status = this.editedRule.membership_status;
        if (this.editedRule.misc) {
          this.misc_filtering = this.editedRule.misc.filtering;
          if (this.editedRule.misc.filtering) {
            this.location = this.locations.filter(l =>
              l.location_id === this.editedRule.misc.location,
            )[0];
          }
        }
      }
    },
    methods: {
      fetchMembershipData() {
        api.getMindbodyMemberships().then((response) => {
          this.membership_types = response.membership_types;
          this.membership_statuses = response.membership_statuses;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          is_member: this.is_member,
          membership_status: this.membership_status,
          membership_type: this.membership_type,
          misc: {
            filtering: this.misc_filtering,
            location: this.location ? this.location.location_id : null,
            site_id: null,
          },
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }
</style>
