<template>
  <div class="condition">
    <div class="alert">
      <img src="https://assets.brandbot.com/public/campaigns/time-only.svg" />
      {{ campaignItem.type === 'email' || campaignItem.type === 'sms' ? 'Send' : 'Create' }} after a delay from entering the auto-campaign ({{ campaign.name }})
    </div>
    <form @submit.prevent>
      <div class="form-group">
        <span v-if="campaignItem.name !== 'MT Action' && campaignItem.name !== 'Webhook'">{{ campaignItem.type === 'email' || campaignItem.type === 'sms' ? 'Send' : 'Create' }} this {{ campaignItem.type === 'phone_call' ? 'phone call reminder' : campaignItem.type }}</span>

        <span v-if="campaignItem.name === 'MT Action'">Send this {{ 'Action'}}</span>

         <span v-if="campaignItem.name == 'Webhook'">Send this {{ 'Webhook' }}</span>

        <input type="number" v-model="relative_count" class="form-control" min="1" v-if="relative !== 'Immediately'" />
        <Multiselect v-model="relative" :options="['Hours', 'Days', 'Weeks', 'Immediately']" :searchable="false" :allow-empty="false" :show-labels="false" v-if="campaignItem.type === 'email' || campaignItem.type === 'sms'" />
        <Multiselect v-model="relative" :options="['Days', 'Weeks', 'Immediately']" :searchable="false" :allow-empty="false" :show-labels="false" v-if="campaignItem.type === 'task' || campaignItem.type === 'phone_call' || campaignItem.type === 'tag'" />
        to all contacts after entering this auto-campaign.
      </div>
      <button class="xpl-button xpl-button--primary" @click.prevent="commitCondition" :disabled="!relative">Save</button>
    </form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['campaignItem', 'campaign'],
    components: {
      Multiselect,
    },
    data() {
      return {
        choice: this.campaignItem.time_rule.choice,
        relative: this.campaignItem.time_rule.relative,
        relative_count: this.campaignItem.time_rule.relative_count,
      };
    },
    methods: {
      commitCondition() {
        this.campaignItem.time_rule.choice = this.choice;
        this.campaignItem.time_rule.relative = this.relative;
        this.campaignItem.time_rule.relative_count = this.relative_count;
        EventBus.$emit('campaign_item.commit');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .alert {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 6px;
    background-color: rgba(180, 224, 250, 0.57);
    font-size: .75rem;
    font-weight: 700;
    color: rgb(46, 68, 87);
    padding-left: .75rem;
    padding-right: .75rem;
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .alert img {
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: .75rem;
  }

  form .multiselect {
    display: inline-block;
    width: auto;
    margin-right: .5rem;
    margin-left: .5rem;
  }

  form input + .multiselect {
    margin-left: 0;
  }

  .form-group {
    display: flex;
    align-items: center;
    font-weight: 700;
    color: #1d364b;
  }

  .form-group input {
    width: auto;
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .btn-bb-primary {
    margin-top: 1.5rem;
  }
</style>
