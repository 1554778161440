<template>
  <div>
    <el-card v-loading="settings.permissionsLoading">
      <div class="display-small" style="margin-bottom: 2rem;">Permissions</div>

      <div class="section">
        <div class="v2__heading">Do you only want specific contacts to be able to purchase through this Landing Page</div>
        <div>
          <el-radio v-model="settings.isPermissioned" :label="true" border>Yes, check permissions before buying</el-radio>
          <el-radio v-model="settings.isPermissioned" :label="false" border>No, allow all customers to use this Landing Page</el-radio>
        </div>
        <div class="options" v-if="settings.isPermissioned">
          <el-form>
            <label>Select the permissions you want to allow</label>
            <el-form-item>
              <el-select v-model="settings.selected_permissions" multiple placeholder="Permissions" value-key="id" style="width: 100%;">
                <el-option
                  v-for="item in settings.permissions" :key="item.id" :label="item.name" :value="item">
                </el-option>
              </el-select>
              <div class="display-caption subdued">The permissions are tags attached to contacts.</div>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <el-button native-type="submit" type="primary" @click="savePermissions">Save</el-button>
    </el-card>
    <el-card v-loading="settings.limitLoading">
      <div class="display-small" style="margin-bottom: 2rem;">Number of Purchases</div>

      <div class="section">
        <div class="v2__heading">Do you want to limit how many times can this Landing Page be used globally?</div>
        <div>
          <el-radio v-model="settings.globalLimit" :label="true" border>Yes</el-radio>
          <el-radio v-model="settings.globalLimit" :label="false" border>No</el-radio>
        </div>
        <div class="options" v-if="settings.globalLimit">
          <el-form>
            <label>How many times?</label>
            <el-form-item>
              <el-input-number v-model.number="settings.globalLimitNumber" placeholder="10" :min="1" />
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div v-if="!$store.state.user.current_account.active_vendors.includes('marianatek')" class="section">
        <div class="v2__heading">Do you want to limit how many times can this Landing Page be used per customer?</div>
        <div>
          <el-radio v-model="settings.customerLimit" :label="true" border>Yes</el-radio>
          <el-radio v-model="settings.customerLimit" :label="false" border>No</el-radio>
        </div>
        <div class="options" v-if="settings.customerLimit">
          <el-form>
            <label>How many times?</label>
            <el-form-item>
              <el-input-number v-model.number="settings.customerLimitNumber" placeholder="10" :min="1" />
            </el-form-item>
          </el-form>
        </div>
      </div>

      <el-button native-type="submit" type="primary" @click="saveLimit">Save</el-button>
    </el-card>
    <el-card v-loading="settings.timingLoading">
      <div class="display-small" style="margin-bottom: 2rem;">Timing Restrictions</div>

      <div class="section">
        <div class="v2__heading">Do you want to only offer this Landing Page during certain days?</div>
        <div>
          <el-radio v-model="settings.isTimed" :label="true" border>Yes, only allow customers to use on specific days</el-radio>
          <el-radio v-model="settings.isTimed" :label="false" border>No, customers can use this anytime</el-radio>
        </div>
        <div class="options" v-if="settings.isTimed">
          <el-form>
            <label>When should the Landing Page be available?</label>
            <el-form-item>
              <el-date-picker v-model="settings.dates" type="daterange" range-separator="-" value-format="yyyy-MM-dd" start-placeholder="Start date" end-placeholder="End date"></el-date-picker>
              <div class="display-caption subdued">These dates are inclusive.</div>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="saveTiming">Save</el-button>
    </el-card>
    <el-card v-loading="settings.followUpLoading" v-if="settings.isPermissioned">
      <div class="display-small" style="margin-bottom: 2rem;" v-if="false">Follow Up Offer</div>
      <div class="display-small" style="margin-bottom: 2rem;">No Permissions Messaging</div>

      <div class="section">
        <div class="v2__heading" v-if="false">Do you want to have a follow up offer if a customer does not have permission to buy the primary checkout funnel offer?</div>
        <div v-if="false">
          <el-radio v-model="settings.isFollowUp" :label="true" border>Yes, give a secondary offer</el-radio>
          <el-radio v-model="settings.isFollowUp" :label="false" border>No, show a “Do not have permission” explanation</el-radio>
        </div>
        <div v-if="false && settings.isFollowUp" style="margin-top: 2rem;">
          <el-row>
            <el-col :span="11">
              <div class="section">
                <div class="v2__heading">What are you selling for your follow up offer?</div>
                <div>
                  <el-radio v-model="settings.followUp.saleType" label="pricing_option" border>Pricing Option</el-radio>
                  <el-radio v-model="settings.followUp.saleType" label="contract" border>Contract</el-radio>
                </div>
                <div class="options" v-if="settings.followUp.saleType">
                  <el-form v-if="settings.followUp.saleType === 'pricing_option'">
                    <label>Select the Pricing Option</label>
                    <el-form-item>
                      <el-select v-model="settings.followUp.pricing_option" placeholder="Pricing Option" value-key="id" style="width: 100%;">
                        <el-option
                          v-for="item in settings.followUp.pricing_options" :key="item.id" :label="item.name" :value="item">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>

                  <el-form v-if="settings.followUp.saleType === 'contract'">
                    <label>Select the Contract</label>
                    <el-form-item>
                      <el-select v-model="settings.followUp.contract" placeholder="Contract" value-key="id" style="width: 100%;">
                        <el-option
                          v-for="item in settings.followUp.contracts" :key="item.id" :label="item.name" :value="item">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <div class="section" v-if="settings.followUp.saleType !== 'contract'">
                <div class="v2__heading">Do you want to add a discount to this package?</div>
                <div>
                  <el-radio v-model="settings.followUp.isDiscounted" :label="true" border>Yes, add discount</el-radio>
                  <el-radio v-model="settings.followUp.isDiscounted" :label="false" border>No, keep full price</el-radio>
                </div>
                <div class="options" v-if="settings.followUp.isDiscounted">
                  <el-form>
                    <el-form-item>
                      <label>How much of a discount?</label>
                      <el-input type="number" v-model.number="settings.followUp.discount" placeholder="10">
                        <template slot="prepend">$</template>
                      </el-input>
                    </el-form-item>
                  </el-form>
                </div>
              </div>

              <div class="v2__heading">Checkout Appearance</div>
              <el-form>
                <el-form-item>
                  <label>Custom Name</label>
                  <el-input type="text" v-model="settings.followUp.name" placeholder="Custom Name" />
                </el-form-item>
                <el-form-item>
                  <label>Custom Description (optional)</label>
                  <el-input type="text" v-model="settings.followUp.description" placeholder="Custom Description" />
                  <div class="display-caption subdued">Optional</div>
                </el-form-item>
                <el-form-item>
                  <label>Button Text</label>
                  <el-input type="text" v-model="settings.followUp.button_text" placeholder="Button Text" />
                </el-form-item>
                <el-form-item>
                  <label>Button Color</label>
                  <div>
                    <el-color-picker size="medium" v-model="settings.followUp.button_color"></el-color-picker>
                  </div>
                </el-form-item>
                <el-form-item>
                  <label>Help Phone Number</label>
                  <el-input type="text" v-model="settings.followUp.phone" placeholder="Phone Number" />
                </el-form-item>
                <el-form-item>
                  <label>Help Email Address</label>
                  <el-input type="email" v-model="settings.followUp.email" placeholder="Email Address" />
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="12" :offset="1">
              <div class="preview">
                <el-card :body-style="{'padding-bottom': 0}" v-if="settings.followUp.pricing_option || settings.followUp.contract">
                  <div class="name">{{ settings.followUp.name }}</div>
                  <div class="description">{{ settings.followUp.description }}</div>
                  <div class="pricing" v-if="!settings.followUp.discount">{{ price }}</div>
                  <div class="pricing" v-if="settings.followUp.discount"><span class="old">{{ price }}</span>{{ priceWithDiscount }}</div>
                  <el-button native-type="button" type="primary" :style="'width: 100%; background-color: ' + settings.button_color + '; border-color: ' + settings.button_color">{{ settings.followUp.button_text }}</el-button>
                  <div class="footer" v-if="!settings.followUp.phone && settings.followUp.email">
                    Need help? Email us at <a :href="'mailto:'+settings.email">{{ settings.followUp.email }}</a>
                  </div>
                  <div class="footer" v-if="settings.followUp.phone && !settings.followUp.email">
                    Need help? Call us at <a :href="'tel:'+settings.followUp.phone" target="_blank">{{ settings.followUp.phone }}</a>
                  </div>
                  <div class="footer" v-if="settings.followUp.phone && settings.followUp.email">
                    Need help? Email us at <a :href="'mailto:'+settings.followUp.email" target="_blank">{{ settings.followUp.email }}</a>
                    or call us at
                    <a :href="'tel:'+settings.followUp.phone" target="_blank">{{ settings.followUp.phone }}</a>
                  </div>
                </el-card>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="options" v-if="!settings.isFollowUp">
          <el-form>
            <label>What do you want to say to customers that don't have permission?</label>
            <el-form-item>
              <el-input placeholder="Write your message here" v-model="settings.followUpNotice" />
            </el-form-item>
          </el-form>
        </div>
      </div>

      <el-button native-type="submit" type="primary" @click="saveFollowUp">Save</el-button>
    </el-card>
  </div>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';

  export default {
    props: ['checkoutflow'],
    data() {
      return {
        settings: {
          timingLoading: false,
          permissionsLoading: false,
          isPermissioned: false,
          isTimed: false,
          isFollowUp: false,
          followUpNotice: '',
          permissions: [],
          selected_permissions: [],
          dates: null,
          limitLoading: false,
          globalLimit: false,
          globalLimitNumber: null,
          customerLimit: false,
          customerLimitNumber: null,
          followUpLoading: false,
          followUp: {
            saleType: null,
            pricing_option: null,
            pricing_options: [],
            contract: null,
            contracts: [],
            isDiscounted: false,
            discount: null,
            name: '',
            description: null,
            button_text: 'Buy now',
            button_color: 'var(--xpl-color-secondary-lm)',
            email: this.$store.state.user.email,
            phone: '',
          },
        },
      };
    },
    created() {
      this.fetchTags();
      this.fetchPricingOptions();
      this.fetchContracts();

      if (this.checkoutflow.settings) {
        const keys = Object.keys(this.checkoutflow.settings);
        // eslint-disable-next-line
        for (let i = keys.length - 1; i >= 0; i--) {
          this.$set(this.settings, keys[i], this.checkoutflow.settings[keys[i]]);
        }
      }
    },
    computed: {
      price() {
        if (!this.settings.followUp.pricing_option &&
          !this.settings.followUp.contract) {
          return null;
        }

        if (this.settings.followUp.contract) {
          return Number(this.settings.followUp.contract.price)
          .toLocaleString(undefined, {
            style: 'currency',
            currency: 'USD',
          });
        }

        return Number(this.settings.followUp.pricing_option.price)
        .toLocaleString(undefined, {
          style: 'currency',
          currency: 'USD',
        });
      },
      priceWithDiscount() {
        if (!this.settings.followUp.pricing_option) {
          return null;
        }

        return Number(this.settings.followUp.pricing_option.price - this.settings.followUp.discount)
        .toLocaleString(undefined, {
          style: 'currency',
          currency: 'USD',
        });
      },
    },
    watch: {
      // eslint-disable-next-line
      'settings.followUp.pricing_option': function () {
        this.settings.followUp.name = this.settings.followUp.pricing_option.name;
      },
      // eslint-disable-next-line
      'settings.followUp.contract': function () {
        this.settings.followUp.name = this.settings.followUp.contract.name;
      },
    },
    methods: {
      refreshFlow() {
        EventBus.$emit('checkoutflow.refresh');
      },
      fetchTags() {
        api.getCustomTags().then((response) => {
          this.settings.permissions = response.tags;
        }).catch((err) => {
          this.$error(err.message);
        });
      },
      saveLimit() {
        this.settings.limitLoading = true;

        const payload = {
          settings: {
            globalLimit: this.settings.globalLimit,
            globalLimitNumber: this.settings.globalLimitNumber,
            customerLimit: this.settings.customerLimit,
            customerLimitNumber: this.settings.customerLimitNumber,
          },
        };

        api.updateCheckoutFlow(payload, this.checkoutflow.id).then((response) => {
          this.$message.success(response.message);
          this.settings.limitLoading = false;
          EventBus.$emit('checkoutflow.refresh');
        }).catch((err) => {
          this.settings.limitLoading = false;
          this.$message({
            showClose: true,
            message: err.message,
            type: 'error',
          });
        });
      },
      savePermissions() {
        this.settings.permissionsLoading = true;
        const payload = {
          settings: {
            isPermissioned: this.settings.isPermissioned,
            selected_permissions: this.settings.selected_permissions.map(s => (
              { id: s.id, uuid: s.uuid, name: s.name }
            )),
          },
        };

        api.updateCheckoutFlow(payload, this.checkoutflow.id).then((response) => {
          this.$message.success(response.message);
          this.settings.permissionsLoading = false;
          EventBus.$emit('checkoutflow.refresh');
        }).catch((err) => {
          this.settings.permissionsLoading = false;
          this.$message.error(err.message);
        });
      },
      saveTiming() {
        this.settings.timingLoading = true;
        const payload = {
          settings: {
            isTimed: this.settings.isTimed,
            dates: this.settings.dates,
          },
        };

        api.updateCheckoutFlow(payload, this.checkoutflow.id).then((response) => {
          this.$message.success(response.message);
          this.settings.timingLoading = false;
          EventBus.$emit('checkoutflow.refresh');
        }).catch((err) => {
          this.settings.timingLoading = false;
          this.$message.error(err.message);
        });
      },
      saveFollowUp() {
        this.settings.followUpLoading = true;
        const payload = {
          settings: {
            isFollowUp: this.settings.isFollowUp,
            followUpNotice: this.settings.followUpNotice,
            followUp: {
              saleType: this.settings.followUp.saleType,
              pricing_option: this.settings.followUp.pricing_option,
              contract: this.settings.followUp.contract,
              isDiscounted: this.settings.followUp.isDiscounted,
              discount: this.settings.followUp.discount,
              name: this.settings.followUp.name,
              description: this.settings.followUp.description,
              button_text: this.settings.followUp.button_text,
              button_color: this.settings.followUp.button_color,
              email: this.settings.followUp.email,
              phone: this.settings.followUp.phone,
            },
          },
        };

        api.updateCheckoutFlow(payload, this.checkoutflow.id).then((response) => {
          this.$message.success(response.message);
          this.settings.followUpLoading = false;
          EventBus.$emit('checkoutflow.refresh');
        }).catch((err) => {
          this.$message.error(err.message);
          this.settings.followUpLoading = false;
        });
      },
      fetchPricingOptions() {
        api.getCheckoutFlowPricingOptions().then((response) => {
          this.settings.followUp.pricing_options = response.pricing_options;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchContracts() {
        api.getContracts().then((response) => {
          this.settings.followUp.contracts = response.contracts;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .section {
    margin-bottom: 2rem;
  }

  .v2__heading {
    margin-bottom: 1rem;
  }

  .options {
    padding: 1.25rem;
    background-color: #f5f8fb;
    margin-top: .75rem;
  }

  .options .el-form-item {
    margin-bottom: .625rem;
  }

  .el-card + .el-card {
    margin-top: 2rem;
  }

  .preview {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    padding-left: 3.25rem;
    padding-right: 3.25rem;
    background-color: #f3f7f9;
    margin-right: -20px;
    margin-top: -20px;
    margin-bottom: -20px;
    height: 100vh;
  }

  .preview .el-card {
    width: 100%;
  }

  .preview .el-card .el-card__body {
    padding-bottom: 0;
  }

  .preview .name {
    font-size: 1.125rem;
    font-weight: 700;
    color: rgb(0, 21, 42);
    margin-bottom: .5rem;
  }

  .preview .description {
    font-size: 1rem;
    line-height: 1.5;
    color: rgb(0, 21, 42);
    margin-bottom: 1.5rem;
  }

  .preview .pricing {
    margin-bottom: 2.5rem;
    font-size: 1.25rem;
    font-weight: 700;
    color: rgb(0, 21, 42);
  }

  .preview .pricing .old {
    text-decoration: line-through;
    color: rgb(97, 112, 128);
    margin-right: .5rem;
  }

  .preview .footer {
    margin-top: 1rem;
    padding: 1rem;
    background-color: var(--xpl-color-background-lm);
    font-size: .625rem;
    font-weight: 700;
    color: rgb(97, 112, 128);
    text-align: center;
    margin-right: -20px;
    margin-left: -20px;
  }

  .preview .footer a {
    color: #2E4457;
    text-decoration: underline;
  }
</style>
