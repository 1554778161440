<template>
  <transition name="fade">
    <div class="modal show" style="display: block;" @click="closeModal">
      <div class="modal-dialog" @click.stop>
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Switch Account
            <i class="fa fa-times" @click.stop="closeModal"></i>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <input v-if="!query_id" type="search" name="query" v-model="query" placeholder="Search..." class="form-control" autocomplete="off" ref="accountInput"/>
              </div>
               <div class="form-group">
                <input v-if="$store.getters.isStaff" type="search" name="query-id" v-model="query_id" placeholder="Search by id" class="form-control" autocomplete="off" />
              </div>
            </form>
            <div class="list-group">
              <a href="#" @click.stop="switchMembership(membership.id)" v-if="!query_id" :class="[ isActive(membership.id) ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action' ]" v-for="membership in filteredMemberships">
                {{ membership.name }}
                <div class="small text-muted" v-if="$store.getters.isStaff">(ID: {{ membership.id }})</div>
              </a>
              <a href="#" @click.stop="switchMembership(membership.id)" v-if="query_id" :class="[ isActive(membership.id) ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action' ]" v-for="membership in filteredMembershipsbyID">
                {{ membership.name }}
                <div class="small text-muted" v-if="query_id && $store.getters.isStaff">(ID: {{ membership.id }})</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import store from '@/store';
  import * as api from '@/api';

  export default {
    data() {
      return {
        query: '',
        query_id: '',
        memberships: [],
      };
    },
    mounted() {
      this.focusAccountInput();
    },
    created() {
      api.getMemberships().then((response) => {
        this.memberships = response.memberships;
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    computed: {
      filteredMemberships() {
        if (this.query) {
          return this.memberships.filter(m =>
            m.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1,
          );
        }

        return this.memberships;
      },
      filteredMembershipsbyID() {
        return this.memberships.filter(m => m.id.toString() === this.query_id);
      },
    },
    methods: {
      focusAccountInput() {
        this.$refs.accountInput.focus();
      },
      isActive(accountId) {
        return this.$store.state.user.current_account.id === accountId;
      },
      closeModal() {
        EventBus.$emit('account_switch.close');
      },
      switchMembership(accountId) {
        const payload = {
          account_id: accountId,
        };

        api.switchMembership(payload).then((response) => {
          store.dispatch('refreshUser').then(() => {
            this.$toasted.success(response.message);
            EventBus.$emit('account_switch.close');
            window.location.href = '/calendar';
          });
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal-body {
    height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
</style>
