<template>
  <el-container class="condition-container">
    <div class="sidebar-inner" style="width: 100%">
      <BooleanField
        fieldIndex="0"
        title="Customers Who Have / Do Not Have?"
        fieldStep="1"
        yesText="Has"
        noText="Does not have"
        :selection.sync="selectionTag"
        :showLabel="true"
        descLabel=""
        id="33"
      />
      <MultiSelectField
        fieldIndex="1"
        title="Which Tag?"
        fieldStep="2"
        :anyLabel="false"
        :specificLabel="false"
        specificAnyLabel="Any of the following tags"
        specificExclusionLabel="Any except the following"
        :selectedOptions.sync="selectedTags"
        specificChoice="specific"
        :exclusionChoice.sync="tagExclusionOption"
        :options="tags"
        selectKey="id"
        id="44"
      />
    </div>
    <div class="save-condition-container" v-if="showSaveBtn">
      <el-button
        class="save-condition-btn"
        type="primary"
        @click="commitCondition"
        >Save</el-button
      >
    </div>
  </el-container>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import MultiSelectField from '@/components/Contacts/ContactLists/Conditions/Fields/MultiSelectField';
  import BooleanField from '@/components/Contacts/ContactLists/Conditions/Fields/BooleanField';

  export default {
    props: ['condition', 'editedRule', 'goalList'],
    components: {
      BooleanField,
      MultiSelectField,
    },
    data() {
      return {
        selectionTag: true,
        specificTags: 'specific',
        tagExclusionOption: 'any',
        selectedTags: [],
        tags: [],
      };
    },
    computed: {
      showSaveBtn() {
        if (!this.selectedTags.length) {
          return false;
        } return true;
      },
    },
    created() {
      this.fetchTags();
      if (this.editedRule) {
        this.selectionTag = this.editedRule.have_tags;
        this.selectedTags = this.editedRule.tags;
        this.tagExclusionOption = this.editedRule.tag_exclusion;
        this.specificTags = this.editedRule.tag_option;
      }
    },
    methods: {
      async fetchTags() {
        try {
          const response = await api.MTUserTags();
          this.tags = response.user_tags;
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          have_tags: this.selectionTag,
          tags: this.selectedTags,
          tag_exclusion: this.tagExclusionOption,
          tag_option: this.specificTags,
        };
        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
@import "src/scss/global/_spacing.scss";
@import "src/scss/global/_new_main.scss";
  .sidebar-inner {
  height: 100%;
  position: relative;
  padding-top: 0;
  background-color: #F4F8FA;
  padding-left: 2.625rem;
  padding-right: 2.25rem;
  padding-bottom: 0;
  width: 100%;
}
.condition-container {
  background-color: #F4F8FA;
  height: 100%;
  width: 100%;
}
.card-condtion {
  width: 100%;
  background-color: #FFFFFF;
  border: solid 1px var(--xpl-color-gray-400);
  box-shadow: none;
  border-radius: 0;
  cursor: auto;
}
.card-condtion:hover {
  background-color: #FFFFFF;
  cursor: auto;
}
.field-container:last-of-type{
  padding-bottom: 100px;
}
.save-condition-btn {
  width: 729px;
  margin: 16px 12px 16px 12px;
}
.save-condition-container {
  // box-shadow: 0 -6px 7px 0 rgba(0, 0, 0, 0.05);
  padding: 0;
  margin: 0;
}
.condition-banner-style {
  background-color: #F4F8FA !important;
  margin-top: 13px !important;
  padding-bottom: 0;
}
</style>
