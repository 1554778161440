<template>
  <div>
    <el-card>
      <pre class="small" style="background-color: #f3f6f9;">{{ current_account }}</pre>
    </el-card>
    <el-card>
      <div class="display-medium">Pike13</div>
      <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="activateWebhook" :loading="loading">Activate Webhooks</el-button>
    </el-card>
  </div>
</template>
<script>
  import * as api from '@/api';

  export default {
    data() {
      return {
        current_account: { ...this.$store.state.user.current_account,
          vendor_data: this.$store.state.user.current_account_vendor_data },
        loading: false,
      };
    },
    created() {
      this.current_account.metadata = JSON.parse(this.current_account.metadata);
      this.current_account.vendor_data.webhooks =
        JSON.parse(this.current_account.vendor_data.webhooks);
      this.current_account.vendor_data.external_auth =
        JSON.parse(this.current_account.vendor_data.external_auth);
    },
    methods: {
      activateWebhook() {
        const payload = {
          account_id: this.current_account.id,
        };

        this.loading = true;
        api.activateWebhook(payload).then((response) => {
          this.loading = false;
          this.$message.success(response.message);
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-card + .el-card {
    margin-top: 2rem;
  }
</style>
