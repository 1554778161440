<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">1</span>
        </el-col>
        <el-col :span="22" class="v2__heading">Do you want to find all contacts who have or do not have a plan?</el-col>
      </el-row>

      <el-row style="margin-bottom: 2rem;">
        <el-col :offset="2">
          <el-radio-group v-model="has_plan">
            <el-radio :label="true" border>Contacts who do</el-radio>
            <el-radio :label="false" border>Contacts who do <b>not</b></el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">2</span>
        </el-col>
        <el-col :span="22" class="v2__heading">What plans do the contacts {{ has_plan ? '' : 'not ' }}have?</el-col>
      </el-row>

      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2">
          <el-radio-group v-model="filter_plan">
            <el-radio :label="false" border>Any Plans</el-radio>
            <el-radio :label="true" border>Specific Plan(s)</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row style="margin-bottom: 2rem;" v-if="filter_plan">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-form>
              <label>Select your Plan(s)</label>
              <el-form-item>
                <el-select v-model="selected_plans" multiple filterable placeholder="Plans" style="width: 100%;" value-key="id">
                  <el-option v-for="plan in plans" :key="plan.id" :value="plan" :id="plan.id" :label="plan.name"></el-option>
                </el-select>
                <el-switch v-model="invert_plans" active-text="Filter on everything but the selected Plans" />
              </el-form-item>

              <label>Plan Type(s)</label>
              <el-form-item>
                <el-select v-model="selected_types" multiple filterable placeholder="Plan Types" style="width: 100%;">
                  <el-option v-for="type in types" :key="type.name" :value="type.name" :id="type.name" style="text-transform: uppercase;"></el-option>
                </el-select>
                <el-switch v-model="invert_types" active-text="Filter on everything but the selected Plan Types" />
              </el-form-item>

              <label>Revenue Categorie(s)</label>
              <el-form-item>
                <el-select v-model="selected_revenue_categories" multiple filterable placeholder="Revenue Categories" style="width: 100%;">
                  <el-option v-for="category in categories" :key="category.name" :value="category.name" :id="category.name"></el-option>
                </el-select>
                <el-switch v-model="invert_categories" active-text="Filter on everything but the selected Revenue Categories" />
              </el-form-item>

              <el-form-item>
                <el-checkbox v-model="membership_only">Only include Plans that grant Memberships</el-checkbox>
              </el-form-item>

              <el-form-item>
                <el-checkbox v-model="no_end_date">Only include Plans with no End Date</el-checkbox>
              </el-form-item>

              <el-form-item>
                <el-checkbox v-model="start_date_future">Only include Plans with Start Date strictly in the future</el-checkbox>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">3</span>
        </el-col>
        <el-col :span="22" class="v2__heading">How many visits are remaining on those plans?</el-col>
      </el-row>

      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2">
          <el-radio-group v-model="filter_visits">
            <el-radio :label="false" border>Any amount</el-radio>
            <el-radio :label="true" border>Specific amount</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row style="margin-bottom: 2rem;" v-if="filter_visits">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-radio-group v-model="visit_comparison">
              <el-radio-button label="more">At least</el-radio-button>
              <el-radio-button label="less">Less than</el-radio-button>
              <el-radio-button label="exactly">Exactly</el-radio-button>
              <el-radio-button label="between">Between</el-radio-button>
            </el-radio-group>
            <div style="margin-top: 1rem;" v-if="visit_comparison">
              <div v-if="visit_comparison && visit_comparison !== 'between'">
                <el-input-number v-model="visit_number_start" :min="0" ></el-input-number>
              </div>
              <div v-if="visit_comparison && visit_comparison === 'between'">
                <el-input-number v-model="visit_number_start" :min="0" ></el-input-number>
                and
                <el-input-number v-model="visit_number_end" :min="0" ></el-input-number>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-form>
      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="commitCondition">Save this condition</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['condition', 'editedRule', 'enrollment', 'goalList'],
    data() {
      return {
        filter_plan: false,
        filter_visits: false,
        selected_plans: [],
        invert_plans: false,
        plans: [],
        selected_types: [],
        invert_types: false,
        types: [],
        selected_revenue_categories: [],
        invert_categories: false,
        categories: [],
        visit_comparison: null,
        visit_number_start: null,
        visit_number_end: null,
        membership_only: false,
        no_end_date: null,
        start_date_future: null,
        has_plan: true,
      };
    },
    created() {
      this.fetchPlanProduct();
      this.fetchTypes();
      this.fetchCategories();

      if (this.editedRule) {
        this.selected_plans = this.editedRule.plans;
        if (this.editedRule.plan_products) {
          this.selected_plans = this.editedRule.plan_products;
        }
        this.selected_types = this.editedRule.types;
        this.selected_revenue_categories = this.editedRule.categories;
        this.invert_plans = this.editedRule.invert_plans;
        this.invert_types = this.editedRule.invert_types;
        this.invert_categories = this.editedRule.invert_categories;

        this.has_plan = this.editedRule.has_plan;
        this.filter_visits = this.editedRule.filter_visits;
        this.visit_comparison = this.editedRule.visit_comparison;
        this.visit_number_start = this.editedRule.visit_number_start;
        this.visit_number_end = this.editedRule.visit_number_end;
        this.membership_only = this.editedRule.membership_only;
        this.no_end_date = this.editedRule.no_end_date;
        this.start_date_future = this.editedRule.start_date_future;

        if (this.selected_plans.length ||
          this.selected_types.length ||
          this.selected_revenue_categories.length ||
          this.has_plan) {
          this.filter_plan = true;
        }
      }
    },
    methods: {
      fetchPlanProduct() {
        api.getPikePlanProducts().then((response) => {
          this.plans = response.plans;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchPlans() {
        api.getPersonPlans().then((response) => {
          this.plans = response.plans;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchTypes() {
        api.getPlanTypes().then((response) => {
          this.types = response.types;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchCategories() {
        api.getPlanCategories().then((response) => {
          this.categories = response.categories;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          has_plan: this.has_plan,
          membership_only: this.membership_only,
          filter_visits: this.filter_visits,
          visit_comparison: this.visit_comparison,
          visit_number_start: this.visit_number_start,
          visit_number_end: this.visit_number_end,
          filter_plan: this.filter_plan,
          plan_products: this.selected_plans,
          invert_plans: this.invert_plans,
          types: this.selected_types,
          invert_types: this.invert_types,
          categories: this.selected_revenue_categories,
          invert_categories: this.invert_categories,
          no_end_date: this.no_end_date,
          start_date_future: this.start_date_future,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
 .options {
    padding: 1.25rem;
    background-color: #f5f8fb;
  }
</style>
