<template>
  <div>
    <div class="card">
      <div class="card-header card-header-merged card-header-secondary">Billing Plan</div>
      <div class="card-body" v-if="subscription && subscription.subscription_name === 'Trial'">
        <div>You are currently on trial. Your trial will end on {{ subscription.subscription_ends_at | humanDate(false) }}.</div>
        <a href="/upgrade" class="xpl-button xpl-button--primary">Upgrade</a>
      </div>
      <div class="card-body" v-if="subscription && subscription.subscription_name === 'custom'">
        <div>You are currently on a custom plan. Please contact support if you have any questions.</div>
      </div>
      <div class="card-body" v-if="subscription && subscription.none">
        <div>Your trial has expired. Please <a href="/upgrade">upgrade</a> to continue using {{$store.getters.brandName}}.</div>
      </div>
      <div class="card-body subscribed" v-if="$store.state.user.current_account.good_standing && !$store.state.user.current_account.on_trial && tier && subscription.subscription_name !== 'custom'">
        <h2>Monthly ({{ tier.english }})</h2>
        <p>A monthly subscription charge based on the highest number of <q>subscribed</q> contacts in your account during a 30-day billing period.</p>
        <div class="alert alert-secondary" v-if="false">
          {{$store.getters.brandName}} automatically sets your billing plan with your number of <q>subscribed</q> contacts. Your account currently has {{ billing_contacts | prettyNumber }} <q>subscribed</q> contacts. Your monthly subscription will be {{ tier.monthly / 100 | prettyCurrency }}.
        </div>
      </div>
    </div>
    <div class="card credit-card">
      <div class="card-header card-header-merged card-header-secondary">Credit Card</div>
      <div class="card-body" v-if="billing_metadata && billing_metadata.hasOwnProperty('card_brand')">
        <div>
          <img :src="'https://assets.brandbot.com/public/icons/billing/' + billing_metadata.card_brand.toLowerCase() + '.png'" :alt="billing_metadata.card_brand" v-if="billing_metadata.card_brand" />
        </div>
        <div>
          {{ billing_metadata.card_brand }} ending in {{ billing_metadata.card_last_four }}.
        </div>
        <button class="xpl-button xpl-button--secondary" @click="show_card_modal = !show_card_modal">Update Card</button>
      </div>
      <div class="card-body" v-else>
        <button class="xpl-button xpl-button--secondary" @click="show_card_modal = !show_card_modal" style="margin-left: 0;">Add a Credit Card</button>
      </div>
      <UpdateCardModal v-if="show_card_modal" />
    </div>
    <div class="card" v-if="invoices.length">
      <div class="card-header card-header-merged card-header-secondary">Invoices</div>
      <div class="card-body">
        <div class="invoices">
          <div class="invoice" v-for="invoice in invoices">
            <div>
              <span class="material-icons">payment</span>
            </div>
            <div>{{ invoice.date }}</div>
            <div>{{ invoice.paid ? 'Paid' : 'Unpaid' }}</div>
            <div>{{ invoice.total | prettyCurrency }}</div>
            <div>
              <a :href="invoice.download" target="_blank">View Details</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-muted" style="margin-top: 1rem;">
      To cancel your account, please contact us.
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import UpdateCardModal from './UpdateCardModal';

  export default {
    components: {
      UpdateCardModal,
    },
    data() {
      return {
        subscription: null,
        tier: null,
        billing_metadata: null,
        invoices: [],
        show_card_modal: false,
        billing_contacts: null,
      };
    },
    mounted() {
      EventBus.$on('update_card_modal.close', () => {
        this.show_card_modal = false;
        this.fetchBilling();
      });
    },
    created() {
      this.fetchBilling();
    },
    beforeDestroy() {
      EventBus.$off('update_card_modal.close');
    },
    methods: {
      fetchBilling() {
        api.getBilling().then((response) => {
          this.subscription = response.subscription;
          this.billing_metadata = response.billing_metadata;
          this.invoices = response.invoices;
          this.tier = response.tier;
          this.billing_contacts = response.billing_contacts;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .card + .card {
    margin-top: 2.5rem;
  }

  .card .card-body {
    font-size: .875rem;
    color: #363636;
  }

  .card.credit-card .card-body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 700;
    padding: 1rem;
  }

  .card.credit-card .card-body button {
    margin-left: auto;
  }

  .card.credit-card .card-body img {
    height: 1.5rem;
    margin-right: .875rem;
  }

  .invoices .invoice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: .875rem;
    line-height: 1.43;
    color: #1d364b;
    margin-bottom: 1.5rem;
  }

  .invoices .invoice:last-child {
    margin-bottom: 0;
  }

  .card-body.subscribed h2 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #1d364b;
    margin-bottom: .5rem;
  }

  .card-body.subscribed p {
    font-size: .875rem;
    line-height: 1.43;
    color: #1d364b;
  }

  .card-body.subscribed a {
    margin-top: 1.5rem;
  }

  .alert.alert-secondary {
    margin-top: .75rem;
    border: none;
    border-radius: 0;
    background-color: #f3f7f9;
    padding: .75rem;
    font-size: .75rem;
    line-height: 1.67;
    color: #1d364b;
  }
</style>
