<template>
  <div>
    <NewUserModal v-if="newUserModal" />
    <el-row>
      <el-col :span="12">
        <div class="display-small">Team Members</div>
      </el-col>
      <el-col :span="12" class="text-right">
        <el-button type="primary" class="xpl-button xpl-button--primary" @click="newUserModal = true">Add a new Team Member</el-button>
      </el-col>
    </el-row>
    <el-card v-loading="loading">
      <el-table :data="members">
        <el-table-column label="Name">
          <template slot-scope="scope">
            <div class="name">{{ scope.row.first_name }} {{ scope.row.last_name }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="email" label="Email" class="text-bold"></el-table-column>
        <el-table-column prop="permission" label="Permissions">
          <template slot-scope="scope">
            <span class="text-capitalize" v-if="!scope.row.change">{{ scope.row.pivot.permission }}</span>
            <span v-if="scope.row.change">
              <el-select v-model="scope.row.pivot.permission" placeholder="Permission" style="width: 100%;" @change="changePermission($event, scope.row)">
                <el-option :label="'Creator'" :value="'creator'"></el-option>
                <el-option :label="'Manager'" :value="'manager'"></el-option>
                <el-option :label="'Admin'" :value="'admin'"></el-option>
              </el-select>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Status">
          <template slot-scope="scope">
            <div :class="['status', scope.row.pivot.confirmed ? 'active' : '']">
              <span v-if="scope.row.pivot.confirmed">Active</span>
              <span v-if="!scope.row.pivot.confirmed">Invitation Sent</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="right">
          <template slot-scope="scope">
            <el-dropdown trigger="click" @command="handleCommand($event, scope.row)" v-if="$store.state.user.id !== scope.row.id">
              <el-button type="secondary" class="xpl-button xpl-button--secondary"  size="small">Options</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="resend" v-if="!scope.row.pivot.confirmed">Resend Invitation</el-dropdown-item>
                <el-dropdown-item command="change" v-if="$store.state.user.current_account.pivot.permission === 'admin'">Change Permissions</el-dropdown-item>
                <el-dropdown-item command="remove" class="text-danger">Remove from Team</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import NewUserModal from './NewUserModal';

  export default {
    components: {
      NewUserModal,
    },
    data() {
      return {
        loading: false,
        newUserModal: false,
        members: [],
        email: '',
        first_name: '',
        last_name: '',
        permission: null,
      };
    },
    mounted() {
      EventBus.$on('newUserModal.close', () => {
        this.newUserModal = false;
        this.fetchTeamMembers();
      });
    },
    created() {
      this.fetchTeamMembers();
    },
    beforeDestroy() {
      EventBus.$off('newUserModal.close');
    },
    methods: {
      fetchTeamMembers() {
        this.loading = true;
        api.getTeamMembers().then((response) => {
          this.members = response.members;
          this.loading = false;
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      handleCommand(command, member) {
        if (command === 'remove') {
          this.removeMember(member);
        }

        if (command === 'change') {
          this.$set(member, 'change', true);
        }

        if (command === 'resend') {
          this.sendAgain(member);
        }
      },
      changePermission(permission, member) {
        const payload = {
          id: member.pivot.id,
          permission,
        };
        api.changeAccountUser(payload).then(() => {
          this.$set(member, 'change', false);
          this.$message.success('Permission changed.');
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      removeMember(member) {
        this.$confirm('Are you sure you want to remove this user from your team?').then(() => {
          this.loading = true;
          api.removeAccountUser(member.pivot.id).then(() => {
            this.loading = false;
            this.$message.success('Team member removed.');
            this.fetchTeamMembers();
          }).catch((err) => {
            this.loading = false;
            this.$message.error(err.message);
          });
        }).catch(() => {});
      },
      sendAgain(member) {
        const payload = {
          account_user_id: member.pivot.id,
        };

        this.$confirm('Are you sure you want to re-send this invitation?').then(() => {
          this.loading = true;
          api.reinviteAccountUser(payload).then((response) => {
            this.loading = false;
            this.$message.success(response.message);
          }).catch((err) => {
            this.loading = false;
            this.$message.error(err.message);
          });
        }).catch(() => {});
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-card {
    margin-top: 1rem;
  }

  .name {
    font-weight: 700;
    color: rgb(46, 68, 87);
  }

  .status {
    font-weight: 700;
    color: rgb(118, 130, 141);
  }

  .status.active {
    font-weight: 700;
    color: rgb(116, 221, 144);
  }

  .permission {
    font-size: 1rem;
    font-weight: 700;
    color: rgb(46, 68, 87);
    width: 10rem;
    text-align: left;
  }

  .member .dropdown-menu-right {
    left: auto;
  }

  .member .right-side .buttons {
    width: 15rem;
    text-align: right;
  }
</style>
