<template>
  <div>
    <div class="display-small">Company Profile</div>
    <el-card>
      <el-form @submit.native.prevent>
        <el-form-item>
          <label for="name">Account Name</label>
          <el-input type="text" id="name" v-model="account.name" placeholder="Account Name" />
        </el-form-item>
        <el-form-item>
          <label>Account Time Zone</label>
          <el-select v-model="account.timezone" filterable placeholder="Select a Time Zone" style="width: 100%;">
            <el-option-group v-for="group in timezones" :key="group.group" :label="group.group">
              <el-option v-for="timezone in group.zones" :key="timezone.value" :label="timezone.name" :value="timezone.value"></el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-alert type="info" show-icon :closable="false">
          All of your scheduled actions and emails will use this time zone for scheduling and sending.
        </el-alert>
        <el-form-item>
          <label>Website URL</label>
          <el-input type="text" v-model="account.website_url" placeholder="https://brandbot.com" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="xpl-button xpl-button--primary" @click="save" :loading="loading">Save</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <div class="display-small" style="margin-bottom: 1rem;">Merge Tags</div>
    <el-card>
      <el-tabs v-model="tab" type="card">
        <el-tab-pane name="account" label="Account Merge Tags">
          <el-form @submit.native.prevent>

            <el-form-item v-if="!add_new" v-for="merge_tag in filteredMergeTags" :key="merge_tag.identifier">
              <el-row :span="24" style="display: flex; align-items: center;">
                <el-col :span="17">
                  <label style="cursor: default;">{{ merge_tag.label }}</label>    <i class="fa fa-pencil" v-if="!merge_tag.label_is_locked" style="cursor: pointer;" @click.stop="openMergeTagLabelDialog(merge_tag)" ></i>        
              <el-dialog
                title="Update Merge Tag Label"
                :visible.sync="merge_tag_dialog_visible"
                width="30%"
                >
                <el-input v-model="merge_tag_label_update_values.label" placeholder="Update label here" />
                <span slot="footer" class="dialog-footer">
                  <el-button @click="merge_tag_dialog_visible = false">Cancel</el-button>
                  <el-button type="primary" @click="updateMergeTagLabel()">Save</el-button>
                </span>
              </el-dialog>
                  <el-input v-model="merge_tag.value" :placeholder="merge_tag.label" />
                  <div class="small muted">Use it as such: [%{{ merge_tag.identifier }}%]</div>
                </el-col>
                <el-col class="text-right" :span="6" :offset="1">
                  <el-button type="primary" class="xpl-button xpl-button--primary"size="small" :loading="loading" @click.stop="updateMergeTag(merge_tag)">Save</el-button>
                  <el-button type="text" class="text-danger" size="small" :loading="loading" @click.stop="deleteMergeTag(merge_tag)">Delete</el-button>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item v-if="add_new">
              <label>Identifier</label>
              <el-input v-model="identifier" placeholder="Identifier" />
              <div class="small muted">The identifier needs to be unique and will be used in the merge tag itself. No spaces or special characters allowed.</div>
               <div class="small muted">The identifier needs to be appended by _url when the merge tag value is a link.</div>
            </el-form-item>
            <el-form-item v-if="add_new">
              <label>Label</label>
              <el-input v-model="label" placeholder="Label" />
            </el-form-item>
            <el-form-item v-if="add_new">
              <label>Value (optional)</label>
              <el-input v-model="value" placeholder="Value" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="newMergeTag" :loading="loading" v-if="add_new" :disabled="!identifier || !label">Add</el-button>
              <el-button type="secondary" class="xpl-button xpl-button--secondary"  @click.stop="add_new = true" v-if="!add_new">Add new Merge Tag</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane name="franchise" label="Franchise Merge Tags" v-if="account.franchise || account.family">
          <el-button v-if="account.franchise" type="warning" size="mini" @click="applySyncMergeTags" :loading="loading">Sync with Franchisees</el-button>
          <div class="display-caption" style="margin-top: .5rem;">Any changes in values made here only apply to this account.</div>
          <div class="display-caption" v-if="account.franchise">If a Merge Tag is added here, it will be created in all Franchisee Accounts.</div>
          <el-form @submit.native.prevent>
            <el-form-item v-if="!add_new" v-for="merge_tag in filteredMergeTags" :key="merge_tag.identifier">
              <el-row :span="24" style="display: flex; align-items: center;">
                <el-col :span="17">
                  <label>{{ merge_tag.label }}</label>
                  <el-input v-model="merge_tag.value" :placeholder="merge_tag.label" @change="$set(merge_tag, 'touched', true)" />
                  <div class="small muted">Use it as such: [%{{ merge_tag.identifier }}%]</div>
                </el-col>
                <el-col class="text-right" :span="6" :offset="1">
                  <el-button type="primary" class="xpl-button xpl-button--primary"size="small" :loading="loading" @click.stop="updateMergeTag(merge_tag)">Save</el-button>
                  <el-button v-if="account.franchise" type="text" class="text-danger" size="small" :loading="loading" @click.stop="deleteMergeTag(merge_tag)">Delete</el-button>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item v-if="add_new">
              <label>Identifier</label>
              <el-input v-model="identifier" placeholder="Identifier" />
              <div class="small muted">The identifier needs to be unique and will be used in the merge tag itself. No spaces or special characters allowed.</div>
              <div class="small muted">The identifier needs to be appended by _url when the merge tag value is a link.</div>
            </el-form-item>
            <el-form-item v-if="add_new">
              <label>Label</label>
              <el-input v-model="label" placeholder="Label" />
            </el-form-item>
            <el-form-item v-if="add_new">
              <label>Value (optional)</label>
              <el-input v-model="value" placeholder="Value" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="newMergeTag" :loading="loading" v-if="add_new" :disabled="!identifier || !label">Add</el-button>
              <el-button type="secondary" class="xpl-button xpl-button--secondary"  @click.stop="add_new = true" v-if="!add_new && account.franchise">Add new Merge Tag</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
  import * as api from '@/api';
  import timezones from './timezones.json';

  export default {
    props: ['account'],
    data() {
      return {
        timezones,
        loading: false,
        add_new: false,
        identifier: null,
        label: null,
        value: null,
        tab: 'account',
        merge_tag_dialog_visible: false,
        merge_tag_label_update_values: {
          label: null,
          value: null,
          id: null,
          identifier: null,
        },
      };
    },
    computed: {
      filteredMergeTags() {
        if (this.tab === 'account') {
          return this.account.merge_tags.filter(m => !m.franchise).sort((a, c) => a.id - c.id);
        }

        if (this.tab === 'franchise') {
          return this.account.merge_tags.filter(m => m.franchise).sort((a, c) => a.id - c.id);
        }

        return [];
      },
    },
    methods: {
      selectTimezone(selectedOption) {
        this.account.timezone = selectedOption.value;
      },
      applySyncMergeTags() {
        this.loading = true;
        api.syncMergeTags().then((response) => {
          this.$store.dispatch('refreshUser').then(() => {
            this.$message.success(response.message);
            this.loading = false;
          });
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      deleteMergeTag(mergeTag) {
        this.$confirm('Are you sure you want to delete this merge tag? This is not recoverable and will apply across all your franchisees.').then(() => {
          this.loading = true;
          api.deleteMergeTag(mergeTag.id).then((response) => {
            this.$store.dispatch('refreshUser').then(() => {
              this.$message.success(response.message);
              this.loading = false;
            });
          }).catch((err) => {
            this.loading = false;
            this.$message.error(err.message);
          });
        });
      },
       async updateMergeTag(mergeTag) {
          if (mergeTag.identifier.toLowerCase().includes('_url')) {
            if (!mergeTag.value.toLowerCase().startsWith('http')) {
              const http = 'http://';
              const url = mergeTag.value;
              mergeTag.value = http.concat('', url)
            }
          }
        const payload = {
          identifier: mergeTag.identifier,
          value: mergeTag.value,
          label: mergeTag.label,
        };

        this.loading = true;
        await api.updateMergeTag(mergeTag.id, payload).then((response) => {
          
          this.$store.dispatch('refreshUser').then(() => {
            this.$message.success(response.message);
            this.loading = false;
          });
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
        this.merge_tag_label_update_id = null;
        if(this.merge_tag_dialog_visible){
          this.merge_tag_dialog_visible = false;
        }
      },
      newMergeTag() {
        if (this.identifier.toLowerCase().includes('_url')) {
          if (!this.value.toLowerCase().startsWith('http')) {
            const http = 'http://';
            const url = this.value;
            this.value = http.concat('', url)
          }
        }
        const payload = {
          identifier: this.identifier,
          label: this.label,
          value: this.value,
          franchise: this.tab === 'franchise',
        };
        if (!this.identifier || !this.label) {
          this.$message.error('Please provide an identifier and a label.');
          return;
        }

        if (!this.identifier.match(/^[0-9a-zA-Z-_]+$/)) {
          this.$message.error('Only alphanumeric characters, "-" and "_" are allowed.');
          return;
        }

        this.loading = true;

        api.newMergeTag(payload).then((response) => {
          this.$store.dispatch('refreshUser').then(() => {
            this.$message.success(response.message);
            this.add_new = false;
            this.identifier = null;
            this.label = null;
            this.value = null;
            this.loading = false;
          });
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      save() {
        const payload = {
          name: this.account.name,
          timezone: this.account.timezone,
          website_url: this.account.website_url,
        };

        this.loading = true;

        api.updateAccount(this.account.id, payload).then((response) => {
          this.$store.dispatch('refreshUser').then(() => {
            this.$toasted.success(response.message);
          });
          this.loading = false;
        }).catch((err) => {
          this.loading = false;
          this.$toasted.error(err.message);
        });
      },
      openMergeTagLabelDialog(mt) {
        this.merge_tag_dialog_visible = true;
        this.merge_tag_label_update_values.label = mt.label;
        this.merge_tag_label_update_values.id = mt.id;
        this.merge_tag_label_update_values.value = mt.value;
        this.merge_tag_label_update_values.identifier = mt.identifier;
      },
      updateMergeTagLabel() {
        this.merge_tag_dialog_visible = false;
        const updatedMergeTag = {
          identifier: this.merge_tag_label_update_values.identifier,
          value: this.merge_tag_label_update_values.value,
          label: this.merge_tag_label_update_values.label,
          id: this.merge_tag_label_update_values.id,
        }
        this.updateMergeTag(updatedMergeTag);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-card {
    margin-bottom: 2rem;
  }
</style>
