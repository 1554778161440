<template>
  <div>
    <form method="post" @submit.prevent="inviteMember">
      <div class="form-group">
        <label class="form-control-label" for="email">Email Address</label>

        <input
          type="email"
          id="email"
          name="email"
          v-model.trim="email"
          placeholder="Email Address"
          class="form-control"
          @touch="$v.email.$touch()"
          required />

          <div class="invalid-feedback" v-if="!$v.email.required">An email is required to invite your team member.</div>
          <div class="invalid-feedback" v-if="!$v.email.email">A valid email is required to invite your team member.</div>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-primary" @click.prevent="inviteMember" :disabled="$v.$error || $v.$invalid">Invite</button>
      </div>
    </form>
  </div>
</template>
<script>
  import { required, email } from 'vuelidate/lib/validators';
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    data() {
      return {
        email: '',
      };
    },
    validations: {
      email: {
        required,
        email,
      },
    },
    methods: {
      inviteMember() {
        const payload = {
          email: this.email,
        };

        api.addAccountUser(payload).then(() => {
          EventBus.$emit('team.invite');
          this.email = '';
          this.$toasted.success('Team member invited!');
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
