<template>
  <div>
    <div class="nav-subheader">
      <div class="nav-inner">
        <div>Account Saved Links</div>
        <div class="nav-pills">
          <div :class="[tabs.media ? 'active' : '', 'pill']" @click="$router.push('/account/assets')">Media</div>
          <div :class="[tabs.saved_links ? 'active' : '', 'pill']" @click="$router.push('/account/saved-links')">Saved Links</div>
          <div :class="[tabs.pdf ? 'active' : '', 'pill']" @click="$router.push('/account/saved-pdfs')">Saved PDFs</div>
          <div :class="[tabs.templates ? 'active' : '', 'pill']" @click="$router.push('/account/templates')">Templates</div>
          <div :class="[tabs.tags ? 'active' : '', 'pill']" @click="$router.push('/account/tags')">Tags</div>
        </div>
      </div>
    </div>

    <div class="saved-links">
      <div class="heading">
        <h1>Your account Saved Links</h1>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="new_link_modal = !new_link_modal">Add a Saved Link</el-button>
      </div>

      <el-card v-loading="loading">
        <el-table :data="savedLinksFiltered">
          <el-table-column label="Link">
            <template slot="header" slot-scope="scope">
              <el-input v-model="search" size="mini" placeholder="Search" />
            </template>
            <template slot-scope="scope">
              <div v-if="!scope.row.toggled">
                {{ scope.row.name }}
                <div class="small text-muted">
                  <a :href="scope.row.url" target="_blank">{{ scope.row.url }}</a>
                </div>
              </div>
              <div v-if="scope.row.toggled">
                <el-input type="text" v-model="scope.row.name" placeholder="Name" />
                <el-input type="text" v-model="scope.row.url" placeholder="Url" />
              </div>
            </template>
          </el-table-column>
          <el-table-column align="right" label="Actions">
            <template slot-scope="scope">
              <div v-if="!scope.row.toggled">
                <el-button type="text" size="small" @click.stop="toggleRow(scope.row)">Edit</el-button>
                <el-button size="small" @click.stop="archiveAccountAsset(scope.row)">Archive</el-button>
              </div>
              <div v-if="scope.row.toggled">
                <el-button type="primary" class="xpl-button xpl-button--primary"size="small" @click.stop="updateAccountAsset(scope.row)">Save</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <NewSavedLinkModal v-if="new_link_modal" />
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import NewSavedLinkModal from './NewSavedLinkModal';

  export default {
    components: {
      NewSavedLinkModal,
    },
    data() {
      return {
        loading: false,
        new_link_modal: false,
        saved_links: [],
        search: '',
        tabs: {
          media: false,
          saved_links: true,
          pdf: false,
          templates: false,
          tags: false,
        },
      };
    },
    mounted() {
      EventBus.$on('new_saved_link_modal.close', () => {
        this.new_link_modal = false;
        this.fetchSavedLinks();
      });
    },
    created() {
      this.fetchSavedLinks();
    },
    computed: {
      savedLinksFiltered() {
        if (this.search.length) {
          return this.saved_links.filter(sl =>
            sl.name.toLowerCase().includes(this.search.toLowerCase()),
          );
        }

        return this.saved_links;
      },
    },
    methods: {
      archiveAccountAsset(link) {
        this.$confirm('Are you sure you want to archive this Saved Link?').then(() => {
          const payload = {
            type: 'link',
            link_id: link.id,
          };

          this.loading = true;
          api.deleteAccountAsset(payload).then((response) => {
            this.loading = false;
            this.$toasted.success(response.message);
            this.fetchSavedLinks();
          }).catch((err) => {
            this.loading = false;
            this.$toasted.error(err.message);
          });
        });
      },
      updateAccountAsset(link) {
        const payload = {
          name: link.name,
          url: link.url,
        };

        this.loading = true;
        api.updateAccountAsset(link.id, payload).then((response) => {
          this.loading = false;
          this.$toasted.success(response.message);
          this.toggleRow(link);
        }).catch((err) => {
          this.loading = false;
          this.$toasted.error(err.message);
        });
      },
      toggleRow(link) {
        if (!link.toggled) {
          this.$set(link, 'toggled', true);
        } else {
          link.toggled = !link.toggled;
        }
      },
      fetchSavedLinks() {
        this.loading = true;
        api.getSavedLinks().then((response) => {
          this.loading = false;
          this.saved_links = response.saved_links;
        }).catch((err) => {
          this.loading = false;
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .nav-inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .saved-links {
    padding-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 44.375rem;
  }

  .saved-links .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.75rem;
  }

  .saved-links .heading h1 {
    font-size: 1.25rem;
    color: rgb(46, 68, 87);
  }
</style>
