<template>
  <div>
    <div class="tabs">
      <a href="#" class="active">Style</a>
    </div>
    <div class="style_tab">
      <div class="banner">Divider Settings</div>

      <form @submit.prevent>
        <div class="form-group">
          <label>Divider Height</label>
          <input type="number" ref="border_width" class="form-control" min="1" @input="borderWidthEdited($event.target.value)" />
        </div>

        <div class="form-group" v-if="!$store.state.user.current_account.brand_restriction">
          <label>Divider Color</label>
          <div class="input-group" @click="show_color_picker = !show_color_picker">
            <span class="input-group-addon">
              <span class="icon icon-square" :style="'color: ' + color.hex"></span>
            </span>
            <input type="text" ref="color" v-model="color.hex" class="form-control" readonly />
          </div>
          <Sketch v-model="color" :disableAlpha="true" @input="borderColorEdited" v-if="show_color_picker" />
        </div>
      </form>

      <div class="banner">Container Styling</div>
      <form @submit.prevent>
          <div class="form-group">
            <label>Spacing Top</label>
            <input type="number" ref="spacing_top" :class="{ 'form-control': true, 'is-invalid': $refs.spacing_top && !$refs.spacing_top.value }" min="0" @input="spacingTopEdited($event.target.value)" @blur="$v.currentElement.attributes['padding-top'].$touch()" />
          </div>

          <div class="form-group">
            <label>Spacing Bottom</label>
            <input type="number" ref="spacing_bottom" :class="{ 'form-control': true, 'is-invalid': $refs.spacing_bottom && !$refs.spacing_bottom.value }" min="0" @input="spacingBottomEdited($event.target.value)" @blur="$v.currentElement.attributes['padding-bottom'].$touch()" />
          </div>

          <div class="form-group" v-if="!$store.state.user.current_account.brand_restriction">
            <label>Background Color</label>
            <div class="input-group" @click="show_bg_picker = !show_bg_picker">
              <span class="input-group-addon">
                <span class="icon icon-square" :style="'color: ' + background_color.hex"></span>
              </span>
              <input type="text" ref="background_color" v-model="background_color.hex" class="form-control" readonly />
            </div>
            <Sketch v-model="background_color" :disableAlpha="true" @input="backgroundColorEdited" v-if="show_bg_picker" />
          </div>

          <div class="form-group">
            <label>Width (%)</label>
            <input type="number" ref="width" :class="{ 'form-control': true, 'is-invalid': $refs.width && !$refs.width.value }" min="1" max="100" @input="widthEdited($event.target.value)" @blur="$v.currentElement.attributes['padding-left'].$touch()" />
          </div>
      </form>
    </div>

    <div class="footer">
      <button type="button" class="xpl-button xpl-button--primary" @click="saveAndCloseBlock">Save and close</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import _ from 'lodash';
  import { Sketch } from 'vue-color';
  import { required } from 'vuelidate/lib/validators';

  export default {
    props: ['currentElement'],
    components: {
      Sketch,
    },
    data() {
      return {
        show_bg_picker: false,
        background_color: {
          hex: null,
        },
        show_color_picker: false,
        color: {
          hex: null,
        },
      };
    },
    validations: {
      currentElement: {
        attributes: {
          'padding-top': {
            required,
          },
          'padding-bottom': {
            required,
          },
          'padding-left': {
            required,
          },
        },
      },
    },
    mounted() {
      this.color.hex = this.currentElement.attributes['border-color'];
      this.background_color.hex = this.currentElement.attributes['container-background-color'];
      this.$refs.border_width.value = this.currentElement.attributes['border-width'].replace('px', '');
      this.$refs.spacing_top.value = this.currentElement.attributes['padding-top'].replace('px', '');
      this.$refs.spacing_bottom.value = this.currentElement.attributes['padding-bottom'].replace('px', '');
      this.$refs.width.value = this.currentElement.attributes.width ? this.currentElement.attributes.width.replace('%', '') : null;
    },
    watch: {
      currentElement(newVal) {
        this.color.hex = newVal.attributes['border-color'];
        this.background_color.hex = newVal.attributes['container-background-color'];
        this.$refs.border_width.value = newVal.attributes['border-width'].replace('px', '');
        this.$refs.spacing_top.value = newVal.attributes['padding-top'].replace('px', '');
        this.$refs.spacing_bottom.value = newVal.attributes['padding-bottom'].replace('px', '');
        this.$refs.width.value = newVal.attributes.width ? newVal.attributes.width.replace('%', '') : null;
      },
    },
    methods: {
      saveAndCloseBlock() {
        EventBus.$emit('editor.close');
      },
      attributeEdited: _.debounce(() => {
        EventBus.$emit('email.edit.attributes');
      }, 300,
      ),
      borderColorEdited: _.debounce(function customFunction(data) {
        this.currentElement.attributes['border-color'] = data.hex;
        this.attributeEdited();
      }, 300,
      ),
      backgroundColorEdited: _.debounce(function customFunction(data) {
        this.currentElement.attributes['container-background-color'] = data.hex;
        this.attributeEdited();
      }, 300,
      ),
      borderWidthEdited: _.debounce(function customFunction(value) {
        this.currentElement.attributes['border-width'] = `${value}px`;
        this.attributeEdited();
      }, 300,
      ),
      spacingTopEdited: _.debounce(function customFunction(value) {
        this.currentElement.attributes['padding-top'] = `${value}px`;
        this.attributeEdited();
      }, 300,
      ),
      spacingBottomEdited: _.debounce(function customFunction(value) {
        this.currentElement.attributes['padding-bottom'] = `${value}px`;
        this.attributeEdited();
      }, 300,
      ),
      widthEdited: _.debounce(function customFunction(value) {
        this.currentElement.attributes.width = `${value}%`;

        this.attributeEdited();
      }, 300,
      ),
    },
  };
</script>
<style lang="scss" scoped>
  .vc-sketch {
    position: absolute;
    z-index: 100;
  }

  .footer {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgb(205, 215, 226);
    padding-top: .75rem;
    padding-bottom: .75rem;
    padding-left: 1.5rem;
    background-color: white;
    z-index: 1001;
  }

  .footer button {
    margin-right: .5rem;
  }

  .tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tabs a {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: rgb(29, 54, 75);
    font-size: .875rem;
    text-align: center;
    border-bottom: 2px solid rgb(217, 231, 240);
    transition: all 150ms ease-in-out;
  }

  .tabs a:hover,
  .tabs a.active {
    text-decoration: none;
    border-bottom: 2px solid var(--xpl-color-secondary-lm);
  }

  .banner {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1.5rem;
    background-color: var(--xpl-color-background-lm);
    border-top: 1px solid #d9e7f0;
    border-bottom: 1px solid #d9e7f0;
    font-size: .75rem;
    line-height: 1rem;
    color: rgb(139, 155, 172);
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  form {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  label {
    font-size: .875rem;
    line-height: 20px;
    color: rgb(29, 54, 75);
    margin-bottom: .25rem;
  }

  .input-group {
    cursor: pointer;
  }

  .input-group-addon {
    cursor: pointer;
    padding: .25rem .5rem;
    padding-right: 0;
  }

  .input-group input {
    cursor: pointer;
  }

  .icon-square {
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 1.5rem;
    cursor: pointer;
  }

  input[readonly] {
    background-color: white;
  }

  .style_tab {
    padding-bottom: 4rem;
  }
</style>
