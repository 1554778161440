<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Send SMS
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <textarea class="form-control" v-model="body"></textarea>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="submit" ref="submit" class="xpl-button xpl-button--primary" @click.stop="save" :disabled="!body.length">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['contactId'],
    data() {
      return {
        body: '',
      };
    },
    methods: {
      closeModal() {
        EventBus.$emit('send_sms.close');
      },
      save() {
        if (!this.body.length) {
          return;
        }

        this.$refs.submit.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';
        this.$refs.submit.disabled = true;

        const payload = {
          contact_id: this.contactId,
          body: this.body,
        };

        api.sendContactMessage(payload).then((response) => {
          this.body = '';
          this.$toasted.success(response.message);
          this.closeModal();
        }).catch((err) => {
          this.$refs.submit.disabled = false;
          if (err.errors && err.errors.body) {
            this.$toasted.error(err.errors.body);
          } else {
            this.$toasted.error(err.message);
          }
        });
      },
    },
  };
</script>
