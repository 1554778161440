<template>
  <div class="sidebar-inner" style="width: 100%;">
    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">1</span>
      </el-col>
      <el-col :span="22" class="v2__heading">Do you want to find all contacts who have or have not made a purchase?</el-col>
    </el-row>

    <el-row style="margin-bottom: 2rem;">
      <el-col :offset="2">
        <el-radio-group v-model="purchased">
          <el-radio :label="true" border>Contacts who have</el-radio>
          <el-radio :label="false" border>Contacts who have <b>not</b></el-radio>
        </el-radio-group>
      </el-col>
    </el-row>

    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">2</span>
      </el-col>
      <el-col :span="22" class="v2__heading">What did the contacts {{ !purchased ? 'not ' : ''}}purchase?</el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row>
        <el-col :offset="2">
          <el-radio-group v-model="filter_purchase">
            <el-radio :label="false" border>Any purchase</el-radio>
            <el-radio :label="true" border>Specific purchase(s)</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row v-if="filter_purchase">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-form>
              <label>Product(s)</label>
              <el-form-item>
                <el-select v-model="selected_products" filterable multiple placeholder="Products" style="width: 100%;" value-key="id">
                  <el-option v-for="item in products" :value="item" :label="item.name" :key="item.id"></el-option>
                </el-select>
                <el-switch v-model="invert_products" active-text="Filter on everything but the selected Products" />
              </el-form-item>

              <label>Product Type(s)</label>
              <el-form-item>
                <el-select v-model="selected_types" filterable multiple placeholder="Product Types" style="width: 100%;">
                  <el-option v-for="type in types" :key="type.name" :value="type.name" :id="type.name" style="text-transform: capitalize;"></el-option>
                </el-select>
                <el-switch v-model="invert_types" active-text="Filter on everything but the selected Product Types" />
              </el-form-item>

              <label>Product Revenue Categorie(s)</label>
              <el-form-item>
                <el-select v-model="selected_revenue_categories" filterable multiple placeholder="Revenue Categories" style="width: 100%;">
                  <el-option v-for="category in categories" :key="category.name" :value="category.name" :id="category.name" style="text-transform: capitalize;"></el-option>
                </el-select>
                <el-switch v-model="invert_categories" active-text="Filter on everything but the selected Revenue Categories" />
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">3</span>
      </el-col>
      <el-col :span="22" class="v2__heading">When did the contacts {{ !purchased ? 'not ' : ''}}make the purchase?</el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2" :span="22">
          <el-radio-group v-model="timeframe">
            <el-radio label="all" border>All Time</el-radio>
            <el-radio label="absolute" border>Around static date(s)</el-radio>
            <el-radio label="relative" border>A relative time frame</el-radio>
            <el-radio label="entering_campaign" border v-if="goalList">Since entering campaign</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row v-if="timeframe === 'absolute'">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-radio-group v-model="time_comparison">
              <el-radio-button label="before">Before</el-radio-button>
              <el-radio-button label="between">Between</el-radio-button>
              <el-radio-button label="after">After</el-radio-button>
            </el-radio-group>

            <div class="block" v-if="time_comparison && time_comparison !== 'between'">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="dates"
                type="date"
                placeholder="Pick a date">
              </el-date-picker>
            </div>

            <div class="block" v-if="time_comparison && time_comparison === 'between'">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="dates"
                type="daterange"
                range-separator="-"
                start-placeholder="Start"
                end-placeholder="End">
              </el-date-picker>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row v-if="timeframe === 'relative'">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-radio-group v-model="time_comparison">
              <el-radio-button label="more">At least</el-radio-button>
              <el-radio-button label="within">Within the last</el-radio-button>
              <el-radio-button label="exactly">Exactly</el-radio-button>
            </el-radio-group>
            <div class="block" style="display: flex; align-items: center;">
              <el-input-number :min="0" v-model="relative_days"></el-input-number>
              &nbsp;
              days<span v-if="time_comparison && time_comparison !== 'within'">&nbsp;ago</span>.
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-form>
      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="commitCondition">Save this condition</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['condition', 'editedRule', 'enrollment', 'goalList'],
    data() {
      return {
        purchased: true,
        filter_purchase: false,
        selected_products: [],
        invert_products: false,
        products: [],
        selected_types: [],
        invert_types: false,
        types: [],
        selected_revenue_categories: [],
        invert_categories: false,
        categories: [],
        timeframe: 'all',
        time_comparison: null,
        dates: null,
        relative_days: null,
      };
    },
    created() {
      this.fetchProducts();
      this.fetchProductTypes();
      this.fetchProductCategories();

      if (this.editedRule) {
        this.purchased = this.editedRule.purchased;
        this.timeframe = this.editedRule.timeframe;
        this.time_comparison = this.editedRule.time_comparison;
        this.relative_days = this.editedRule.relative_days;

        if (this.editedRule.end_date) {
          this.dates = [this.editedRule.start_date, this.editedRule.end_date];
        } else {
          this.dates = this.editedRule.start_date;
        }

        this.filter_purchase = this.editedRule.filter_purchase;
        this.selected_products = this.editedRule.products;
        this.selected_types = this.editedRule.types;
        this.selected_revenue_categories = this.editedRule.categories;
        this.invert_products = this.editedRule.invert_products;
        this.invert_types = this.editedRule.invert_types;
        this.invert_categories = this.editedRule.invert_categories;
      }
    },
    methods: {
      fetchProducts() {
        api.getPikeProducts().then((response) => {
          this.products = response.products;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchProductTypes() {
        api.getPikeProductTypes().then((response) => {
          this.types = response.types;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchProductCategories() {
        api.getPikeProductCategories().then((response) => {
          this.categories = response.categories;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          purchased: this.purchased,
          timeframe: this.timeframe,
          time_comparison: this.time_comparison,
          start_date: Array.isArray(this.dates) ? this.dates[0] : this.dates,
          end_date: Array.isArray(this.dates) ? this.dates[1] : null,
          relative_days: this.relative_days,
          filter_purchase: this.filter_purchase,
          products: this.selected_products,
          invert_products: this.invert_products,
          types: this.selected_types,
          invert_types: this.invert_types,
          categories: this.selected_revenue_categories,
          invert_categories: this.invert_categories,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
 .options {
    padding: 1.25rem;
    background-color: #f5f8fb;
  }
</style>
