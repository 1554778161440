<template>
  <el-container>
    <el-header>
      <div class="display-small">Sheer ID Integration</div>
    </el-header>
    <el-container>
      <el-aside width="15rem">
        <div @click.stop="switchTab('programs')" :class="['item', tabs.programs ? 'active' : '']">
          Programs
        </div>
        <div @click.stop="switchTab('settings')" :class="['item', tabs.settings ? 'active' : '']">
          Settings
        </div>
      </el-aside>
      <el-main>
        <Programs v-if="tabs.programs" />
        <Settings v-if="tabs.settings" />
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import Programs from './Programs';
import Settings from './Settings';

export default {
  components: {
    Programs,
    Settings,
  },
  data() {
    return {
      tabs: {
        programs: true,
        settings: false,
      },
    };
  },
  created() {
    if (this.$route.query.tab) {
        // eslint-disable-next-line
        for (const tab in this.tabs) {
          // eslint-disable-next-line
          if (this.tabs.hasOwnProperty(tab)) {
            this.tabs[tab] = false;
          }
        }
      this.tabs[this.$route.query.tab] = true;
    }
  },
  methods: {
    switchTab(t) {
      Object.keys(this.tabs).forEach((tab) => {
          // eslint-disable-next-line no-prototype-builtins
        if (this.tabs.hasOwnProperty(tab)) {
          this.tabs[tab] = false;
        }
      });

      this.tabs[t] = true;
      this.$router.push({ query: { tab: t } });
    },
  },
};
</script>
<style lang="scss" scoped>
  .el-header {
    display: block;
    width: 100%;
    position: fixed;
    z-index: 100;
    border-bottom: 1px solid var(--xpl-color-gray-400);
    padding-left: 1.25rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .el-main {
    margin-top: 3.75rem;
    height: calc(100vh - 6.75rem);
    padding: 1.875rem 3.75rem;
  }

  .el-aside {
    margin-top: 3.75rem;
    height: calc(100vh - 6.75rem);
    padding-top: 2.5rem;
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .el-aside .item {
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: .875rem;
    color: #2e4457;
    transition: all 150ms ease-in-out;
    border-radius: 6px;
    margin-bottom: .375rem;
  }

  .el-aside .item:hover,
  .el-aside .item.active {
    cursor: pointer;
    background-color: var(--xpl-color-secondary-bg-lm);
  }

  .el-aside .item.active {
    font-weight: 700;
  }
</style>
