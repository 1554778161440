<template>
  <div>
    <el-row :gutter="24" class="heading">
      <el-col :span="12">
        <div class="display-medium">
          Reminder Settings
        </div>
      </el-col>
    </el-row>

    <el-card v-if="$store.state.user.current_account.active_vendors.indexOf('zingfit') === -1">
      <div class="option">
        <el-checkbox v-model="settings.registration" /> Send on Registration
      </div>
      <div class="inner-option">
        <el-checkbox v-model="settings.toggle_registration_email" /> Send an Email
        <el-form v-if="settings.toggle_registration_email">
          <el-form-item>
            <div style="margin-top: 1rem;">
              <el-radio v-model="settings.registration_template_filter" label="saved" @change="settings.registration_template = null" border>Saved Templates</el-radio>
              <el-radio v-model="settings.registration_template_filter" label="brandbot" @change="settings.registration_template = null" border>{{$store.getters.brandName}} Templates</el-radio>
            </div>
            <label>Use the following template</label>
            <el-select v-model="settings.registration_template" style="width: 100%;" value-key="id">
              <el-option v-for="template in registration_templates" :key="template.id" :label="template.name" :value="template.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="inner-option" v-if="$store.state.user.current_account.active_vendors.indexOf('pike13') === -1">
        <el-checkbox v-model="settings.toggle_registration_sms" /> Send a text message
        <el-form v-if="settings.toggle_registration_sms">
          <el-form-item>
            <label>Use the following message</label>
            <el-input type="textarea" v-model="settings.registration_sms" />
          </el-form-item>
          <el-form-item>
            <ClassRemindersPersonalizations />
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-card>
      <div class="option">
        <el-checkbox v-model="settings.hour" /> Send an Hour Before Class
      </div>
      <div class="inner-option">
        <el-checkbox v-model="settings.toggle_hour_email" /> Send an Email
        <el-form v-if="settings.toggle_hour_email">
          <el-form-item>
            <div style="margin-top: 1rem;">
              <el-radio v-if="$store.state.user.current_account.active_vendors.indexOf('zingfit') === -1 || [1940, 1978, 1975, 1976, 1977].indexOf($store.state.user.current_account.id) > -1" v-model="settings.hour_template_filter" label="saved" @change="settings.hour_template = null" border>Saved Templates</el-radio>
              <el-radio v-model="settings.hour_template_filter" label="brandbot" @change="settings.hour_template = null" border>{{$store.getters.brandName}} Templates</el-radio>
            </div>
            <label>Use the following template</label>
            <el-select v-model="settings.hour_template" style="width: 100%;" value-key="id">
              <el-option v-for="template in hour_templates" :key="template.id" :label="template.name" :value="template.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="inner-option" v-if="$store.state.user.current_account.active_vendors.indexOf('zingfit') === -1 && $store.state.user.current_account.active_vendors.indexOf('pike13') === -1">
        <el-checkbox v-model="settings.toggle_hour_sms" /> Send a text message
        <el-form v-if="settings.toggle_hour_sms">
          <el-form-item>
            <label>Use the following message</label>
            <el-input type="textarea" v-model="settings.hour_sms" />
          </el-form-item>
          <el-form-item>
            <ClassRemindersPersonalizations />
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-card>
      <div class="option">
        <el-checkbox v-model="settings.fifteen_minutes" /> Send 15 Minutes Before Class
      </div>
      <div class="inner-option">
        <el-checkbox v-model="settings.toggle_fifteen_minutes_email" /> Send an Email
        <el-form v-if="settings.toggle_fifteen_minutes_email">
          <el-form-item>
            <div style="margin-top: 1rem;">
              <el-radio v-if="$store.state.user.current_account.active_vendors.indexOf('zingfit') === -1 || [1940, 1978, 1975, 1976, 1977].indexOf($store.state.user.current_account.id) > -1" v-model="settings.fifteen_minutes_template_filter" label="saved" @change="settings.fifteen_minutes_template = null" border>Saved Templates</el-radio>
              <el-radio v-model="settings.fifteen_minutes_template_filter" label="brandbot" @change="settings.fifteen_minutes_template = null" border>{{$store.getters.brandName}} Templates</el-radio>
            </div>
            <label>Use the following template</label>
            <el-select v-model="settings.fifteen_minutes_template" style="width: 100%;" value-key="id">
              <el-option v-for="template in fifteen_minutes_templates" :key="template.id" :label="template.name" :value="template.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="inner-option" v-if="$store.state.user.current_account.active_vendors.indexOf('zingfit') === -1 && $store.state.user.current_account.active_vendors.indexOf('pike13') === -1">
        <el-checkbox v-model="settings.toggle_fifteen_minutes_sms" /> Send a text message
        <el-form v-if="settings.toggle_fifteen_minutes_sms">
          <el-form-item>
            <label>Use the following message</label>
            <el-input type="textarea" v-model="settings.fifteen_minutes_sms" />
          </el-form-item>
          <el-form-item>
            <ClassRemindersPersonalizations />
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-card>
      <div class="option">
        <el-checkbox v-model="settings.five_minutes" /> Send 5 Minutes Before Class
      </div>
      <div class="inner-option">
        <el-checkbox v-model="settings.toggle_five_minutes_email" /> Send an Email
        <el-form v-if="settings.toggle_five_minutes_email">
          <el-form-item>
            <div style="margin-top: 1rem;">
              <el-radio v-if="$store.state.user.current_account.active_vendors.indexOf('zingfit') === -1 || [1940, 1978, 1975, 1976, 1977].indexOf($store.state.user.current_account.id) > -1" v-model="settings.five_minutes_filter" label="saved" @change="settings.five_minutes_template = null" border>Saved Templates</el-radio>
              <el-radio v-model="settings.five_minutes_filter" label="brandbot" @change="settings.five_minutes_template = null" border>{{$store.getters.brandName}} Templates</el-radio>
            </div>
            <label>Use the following template</label>
            <el-select v-model="settings.five_minutes_template" style="width: 100%;" value-key="id">
              <el-option v-for="template in five_minutes_templates" :key="template.id" :label="template.name" :value="template.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="inner-option" v-if="$store.state.user.current_account.active_vendors.indexOf('zingfit') === -1 && $store.state.user.current_account.active_vendors.indexOf('pike13') === -1">
        <el-checkbox v-model="settings.toggle_five_minutes_sms" /> Send a text message
        <el-form v-if="settings.toggle_five_minutes_sms">
          <el-form-item>
            <label>Use the following message</label>
            <el-input type="textarea" v-model="settings.five_minutes_sms" />
          </el-form-item>
          <el-form-item>
            <ClassRemindersPersonalizations />
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <div style="margin-top: 2rem; margin-bottom: 1rem;">
      <el-checkbox v-model="approval" label="I acknowledge that the messages we are sending are not commercial/marketing in nature." />
    </div>
    <el-button type="primary" class="xpl-button xpl-button--primary" @click="saveChanges" :disabled="!approval" :loading="loading">Save Changes</el-button>

  </div>
</template>
<script>
  import {
    getClassReminderSettings,
    updateClassRemindersSettings,
    getClassRemindersTemplates } from '@/api';
  import ClassRemindersPersonalizations from './ClassRemindersPersonalizations';

  export default {
    components: {
      ClassRemindersPersonalizations,
    },
    data() {
      return {
        registration_templates: [],
        hour_templates: [],
        fifteen_minutes_templates: [],
        five_minutes_templates: [],
        loading: false,
        loadingEnable: false,
        approval: false,

        settings: {
          enable: false,

          toggle_registration_email: false,
          toggle_registration_sms: false,
          registration: false,
          registration_template: null,
          registration_template_filter: 'brandbot',
          registration_sms: null,

          toggle_hour_email: false,
          toggle_hour_sms: false,
          hour: false,
          hour_template: null,
          hour_template_filter: 'brandbot',
          hour_sms: null,

          toggle_fifteen_minutes_email: false,
          toggle_fifteen_minutes_sms: false,
          fifteen_minutes: false,
          fifteen_minutes_template: null,
          fifteen_minutes_template_filter: 'brandbot',
          fifteen_minutes_sms: null,

          toggle_five_minutes_email: false,
          toggle_five_minutes_sms: false,
          five_minutes: false,
          five_minutes_template: null,
          five_minutes_filter: 'brandbot',
          five_minutes_sms: null,
        },
      };
    },
    created() {
      this.fetchClassReminderSettings();
    },
    watch: {
      // eslint-disable-next-line
      'settings.registration_template_filter': function (newVal, oldVal) {
        this.getTemplates('registration', newVal);
      },
      // eslint-disable-next-line
      'settings.hour_template_filter': function (newVal, oldVal) {
        this.getTemplates('hour', newVal);
      },
      // eslint-disable-next-line
      'settings.fifteen_minutes_template_filter': function (newVal, oldVal) {
        this.getTemplates('fifteen_minutes', newVal);
      },
      // eslint-disable-next-line
      'settings.five_minutes_filter': function (newVal, oldVal) {
        this.getTemplates('five_minutes', newVal);
      },
    },
    methods: {
      fetchClassReminderSettings() {
        getClassReminderSettings().then((response) => {
          this.settings = response.settings;
          this.getTemplates('registration', this.settings.registration_template_filter);
          this.getTemplates('hour', this.settings.hour_template_filter);
          this.getTemplates('fifteen_minutes', this.settings.fifteen_minutes_template_filter);
          this.getTemplates('five_minutes', this.settings.five_minutes_filter);
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      getTemplates(model, templateFilter) {
        getClassRemindersTemplates(templateFilter).then((response) => {
          this.$set(this, `${model}_templates`, response.templates);
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      saveChanges() {
        this.loading = true;
        const payload = {
          toggle_registration_email: this.settings.toggle_registration_email,
          toggle_registration_sms: this.settings.toggle_registration_sms,
          registration: this.settings.registration,
          registration_template: this.settings.registration_template,
          registration_template_filter: this.settings.registration_template_filter,
          registration_sms: this.settings.registration_sms,

          toggle_hour_email: this.settings.toggle_hour_email,
          toggle_hour_sms: this.settings.toggle_hour_sms,
          hour: this.settings.hour,
          hour_template: this.settings.hour_template,
          hour_template_filter: this.settings.hour_template_filter,
          hour_sms: this.settings.hour_sms,

          toggle_fifteen_minutes_email: this.settings.toggle_fifteen_minutes_email,
          toggle_fifteen_minutes_sms: this.settings.toggle_fifteen_minutes_sms,
          fifteen_minutes: this.settings.fifteen_minutes,
          fifteen_minutes_template: this.settings.fifteen_minutes_template,
          fifteen_minutes_template_filter: this.settings.fifteen_minutes_template_filter,
          fifteen_minutes_sms: this.settings.fifteen_minutes_sms,

          toggle_five_minutes_email: this.settings.toggle_five_minutes_email,
          toggle_five_minutes_sms: this.settings.toggle_five_minutes_sms,
          five_minutes: this.settings.five_minutes,
          five_minutes_template: this.settings.five_minutes_template,
          five_minutes_filter: this.settings.five_minutes_filter,
          five_minutes_sms: this.settings.five_minutes_sms,
        };
        updateClassRemindersSettings(payload).then((response) => {
          this.loading = false;
          this.$message.success(response.message);
          this.fetchClassReminderSettings();
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .display-medium {
    margin-bottom: 2.625rem;
  }

  .el-card + .el-card {
    margin-top: 1.5rem;
  }

  .option {
    font-weight: 700;
    line-height: 1.5;
    color: #00152a;
    margin-bottom: 1.875rem;
  }

  .option .el-checkbox,
  .inner-option .el-checkbox {
    margin-right: .625rem;
  }

  .inner-option {
    margin-left: 2.675rem;
    margin-top: 2.25rem;
  }
</style>
