<template>
  <div>
    <el-card shadow="never" v-if="!locations">
      <h1 class="text-center">Connect to your MarianaTek Account</h1>
      <el-form @submit.native.prevent>
        <el-form-item>
          <label>MarianaTek Site Name</label>
          <el-input v-model.trim="site" placeholder="MarianaTek Site Name">
            <template slot="append">.marianatek.com</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <label>MarianaTek Username</label>
          <el-input v-model="username" placeholder="MarianaTek Username" />
        </el-form-item>
        <el-form-item>
          <label>MarianaTek Password</label>
          <el-input type="password" v-model="password" placeholder="MarianaTek Password" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="loginMarianaTek" :loading="loading" :disabled="!site || !username || !password">Connect</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <MarianaTekLocations :locations="locations" v-if="locations" />
  </div>
</template>
<script>
  import axios from 'axios';
  import { saveMTToken } from '@/api';
  import MarianaTekLocations from './MarianaTekLocations';

  export default {
    props: ['accountId'],
    components: {
      MarianaTekLocations,
    },
    data() {
      return {
        loading: false,
        site: null,
        username: null,
        password: null,
        access_token: null,
        locations: null,
      };
    },
    methods: {
      loginMarianaTek() {
        this.loading = true;

        const formUrlEncoded = x =>
          Object.keys(x).reduce((p, c) => `${p}&${c}=${encodeURIComponent(x[c])}`, '');
        const headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
        };
        const url = `https://${this.site.trim().toLowerCase()}.marianatek.com/o/token/`;
        const payload = formUrlEncoded({
          grant_type: 'password',
          username: this.username,
          password: this.password,
        });

        axios.post(url, payload, headers).then((response) => {
          this.loading = false;
          this.saveToken(response.data);
        }).catch((err) => {
          this.loading = false;
          if (err.response && err.response.data && err.response.data.error_description) {
            this.$message.error(err.response.data.error_description);
          } else {
            this.$message.error('Your MarianaTek credentials are invalid.');
          }
        });
      },
      saveToken(r) {
        this.access_token = r.access_token;
        const payload = {
          site: this.site,
          access_token: r.access_token,
          refresh_token: r.refresh_token,
          scope: r.scope,
        };
        saveMTToken(payload).then((response) => {
          this.$message.success(response.message);
          this.getLocations();
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      getLocations() {
        const url = `https://${this.site.trim().toLowerCase()}.marianatek.com/api/locations?page_size=100`;
        const headers = {
          Authorization: `Bearer ${this.access_token}`,
        };

        axios.get(url, headers).then((response) => {
          this.locations = response.data;
        }).catch(() => {
          this.$message.error('We had an issue fetching your locations. Please reach out to support to continue your onboarding.');
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-card {
    max-width: 500px;
    width: 500px;
    min-width: 500px;
    border: none;
  }

  .el-card h1 {
    font-size: 1.75rem;
    color: #00152a;
    margin-bottom: 2.5rem;
  }

  .el-form-item {
    margin-bottom: 1rem;
  }
</style>
