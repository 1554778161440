<template>
  <div :class="[edit_email ? 'edit-email' : 'campaign-item-cont']" v-if="campaign_item">
    <EditEmail :campaignItem="campaign_item" :emailId="campaign_item.type_id" v-if="campaign_item.type === 'email' && edit_email" />
    <div v-if="!edit_email" class="off-white-bg sidebar">
        <div class="pos-fixed">
        <div :v-loading="!loading_items" style="height:calc(100vh-92px);width:264px">
          <CampaignItemsList  :campaign_items="this.campaign_items"  :selected_item_uuid="this.selected_item_uuid"/>
        </div>
      </div>
    </div>
    <div class="cont">
       <div class="campaign-item-container" v-if="campaign_item && campaign && !edit_email">
      <a href="" class="go-back"></a>
      <router-link :to="'/campaigns/' + campaign.uuid" class="go-back"><i class="fa fa-chevron-left"></i> Back to {{ campaign.name }}</router-link>
      <div class="header">
        <span v-if="item_name_edit === false" class="item-name">{{campaign_item.name}}<i class="fa fa-pencil" v-if="!item_name_edit" style="cursor: pointer;" @click="item_name_edit = true" ></i></span>
        <div class="name-change-text-box-save">
         <textarea v-if="item_name_edit === true" type="text" rows="5" cols="60" class="name-change-text-box" v-model="campaign_item.name" ></textarea>  <button v-if="item_name_edit === true" type="button" class="xpl-button xpl-button--secondary  name-save" @click="updateName">Save</button>
        </div>

        <div class="actions actions-spacer" v-if="!hideButtons">
          <div class="dropdown">
            <button type="button" class="xpl-button xpl-button--secondary" @click.stop="options_menu = !options_menu">Options</button>
            <div :class="['dropdown-menu', 'dropdown-menu-right', options_menu ? 'show' : '']">
              <a href="#" class="dropdown-item" @click.prevent="copyCampaignItem">Copy</a>
              <a href="#" class="dropdown-item text-danger" v-if="!campaign_item.turned_on_at" @click.prevent="deleteCampaignItem">Delete</a>
            </div>
          </div>
          <span v-if="this.campaign_item.name !== 'MT Action' && this.campaign_item.name !== 'Webhook'">
             <button type="button" class="xpl-button xpl-button--success" v-if="!campaign_item.turned_on_at && !campaign_item.paused_at" @click.prevent="toggleCampaignItemState" :disabled="(!behaviorComplete || !taskComplete) && (!behaviorComplete || !emailComplete) && (!behaviorComplete || !tagComplete) && (!behaviorComplete || !smsComplete)">Set Live</button>
          </span>

          <button
          type="button" class="xpl-button xpl-button--success" v-if="!campaign_item.turned_on_at && !campaign_item.paused_at && (this.campaign_item.name === 'MT Action' || this.campaign_item.name === 'Webhook')" @click.prevent="toggleCampaignItemState" :disabled="(!behaviorComplete || !taskComplete) && (!behaviorComplete || !emailComplete) && (!behaviorComplete || !MTtagComplete) && (!behaviorComplete || !smsComplete)">Set Live</button>
        </div>
      </div>

      <div class="campaign-item-inner">

        <CampaignItemIsPaused :type="campaign_item.type" v-if="campaign_item.paused_at && !campaign_item.turned_on_at && !hideButtons" />
        <CampaignItemIsLive :type="campaign_item.type" v-if="!campaign_item.paused_at && campaign_item.turned_on_at && !hideButtons" />

        <CampaignItemSetup :campaignItem="campaign_item" :campaign="campaign" />

        <div class="card" v-if="(campaign_item.type === 'task' || campaign_item.type === 'phone_call') && !campaign_item.turned_on_at && !campaign_item.paused_at && behaviorComplete && !taskComplete">
          <div :class="['card-header', 'card-header-merged', 'card-header-white']">
            <div class="title">
              <span class="step-number">
                <span>2</span>
              </span>
              {{ campaign_item.type === 'task' ? 'Auto Scheduled Task' : 'Auto Scheduled Phone Call Reminder' }}
            </div>
          </div>
          <div class="card-body">
            <form @submit.prevent>
              <div class="form-group">
                <label v-if="campaign_item.type === 'task'">Task Name</label>
                <label v-if="campaign_item.type === 'phone_call'">Phone Call Reminder Name</label>
                <input type="text" v-model="campaign_item.task.name" class="form-control" placeholder="Name" />
              </div>

              <div class="form-group">
                <label v-if="campaign_item.type === 'task'">Task Note (optional)</label>
                <label v-if="campaign_item.type === 'phone_call'">Phone Call Reminder Note (optional)</label>
                <quill-editor style="width: 100%;" v-model="campaign_item.task.note" ref="myQuillEditor1" :options="editorOption"></quill-editor>
              </div>

              <div class="form-group">
                <label>Assignment (optional)</label>
                <div class="assigned" v-if="!campaign_item.assigned_user" @click="task_assignment_modal = !task_assignment_modal">
                  <span class="circle empty"></span>
                  <span>No assignment</span>
                </div>
                <div class="assigned active" v-if="campaign_item.assigned_user" @click="task_assignment_modal = !task_assignment_modal">
                  <span class="user-badge" v-if="campaign_item.assigned_user.first_name && campaign_item.assigned_user.last_name">
                    {{ campaign_item.assigned_user.first_name.slice(0,1) }}{{ campaign_item.assigned_user.last_name.slice(0,1) }}
                  </span>
                  <span class="user-badge" v-else>
                    {{ campaign_item.assigned_user.email.slice(0,2) }}
                  </span>
                  <span>{{ campaign_item.assigned_user.first_name ? campaign_item.assigned_user.first_name + ' ' + campaign_item.assigned_user.last_name : campaign_item.assigned_user.email }}</span>
                  <span class="icon icon-cross3 remove-assigned" @click.stop="removeAssigned"></span>
                </div>
              </div>

              <button type="button" class="xpl-button xpl-button--primary" @click="save" :disabled="!campaign_item.task.name">Save and Continue</button>
            </form>
          </div>
        </div>

        <div class="card" v-if="campaign_item.type === 'tag' && behaviorComplete && campaign_item.name !== 'MT Action'">
          <div class="card-body">
            <div class="title">
              <span :class="['step-number', tagComplete ? 'complete' : '']">
                <span v-if="!tagComplete">2</span>
                <span class="icon icon-checkmark" v-if="tagComplete"></span>
              </span>
              Tag Details
              <button style="margin-left: auto;" class="xpl-button xpl-button--secondary" v-if="campaign_item.tags.action && campaign_item.tags.tags && campaign_item.tags.tags.length && !edit_tags && !hideButtons" @click="editTags">Edit</button>
            </div>
            <div class="tag-types" v-if="(!campaign_item.tags.action && campaign_item.tags.tags && !campaign_item.tags.tags.length) || (edit_tags && !campaign_item.tags.action)">
              <div class="tag-type" @click="selectTagAction('tag')">
                <div class="illustration tag"></div>
                <div>
                  <div class="name">Add Tags</div>
                </div>
              </div>
              <div class="tag-type" @click="selectTagAction('untag')">
                <div class="illustration untag"></div>
                <div>
                  <div class="name">Remove Tags</div>
                </div>
              </div>
            </div>

            <div class="tag-types" v-if="edit_tags && campaign_item.tags.action">
              <div class="btn-back" @click.prevent="campaign_item.tags.action = null">
                <span class="material-icons">keyboard_arrow_left</span>
                Back to options
              </div>

              <TagAction :tagAction="campaign_item.tags.action" :campaignItemId="campaign_item.id" :selectedTags="campaign_item.tags.tags" />
            </div>

            <div class="tag-types" v-if="campaign_item.tags.action && campaign_item.tags.tags && campaign_item.tags.tags.length && !edit_tags">
              <div class="stats" v-if="campaign_item.turned_on_at || campaign_item.paused_at" style="border-bottom: none;">
                <div :class="['stat', show_stat ? 'show' : '']" style="flex-grow: 0;" @click="show_stat = false">
                  <h6>{{ campaign_item.tags.action === 'tag' ? 'Tagged' : 'Untagged' }}</h6>
                  <span class="number">{{ campaign_item.created_tags | prettyNumber }}</span>
                </div>
              </div>
              <p>{{ campaign_item.tags.action === 'tag' ? 'Add' : 'Remove' }} the following tag(s)</p>
              <div class="tags">
                <span class="tag" v-for="tag in campaign_item.tags.tags">{{ tag.name }}</span>
              </div>
            </div>
          </div>
        </div>

<!-- MT ACTION -->
      <div class="card" v-if="campaign_item.type === 'tag' && behaviorComplete && campaign_item.name === 'MT Action'">
          <div class="card-body">
            <div class="title">
              <span :class="['step-number', MTtagComplete ? 'complete' : '']">
                <span v-if="!MTtagComplete">2</span>
                <span class="icon icon-checkmark" v-if="MTtagComplete"></span>
              </span>
              Mariana Tek Action Details
              <button style="margin-left: auto;" class="xpl-button xpl-button--secondary" v-if="campaign_item.tags.action === 'tag' && campaign_item.tags.tags && campaign_item.tags.tags.length && !edit_tags && !hideButtons" @click="editTags">Edit</button>

              <button style="margin-left: auto;" class="xpl-button xpl-button--secondary" v-if="campaign_item.tags.action === 'untag'" @click="editTags">Edit</button>
            </div>
            <div class="tag-types" v-if="(!campaign_item.tags.action && campaign_item.tags.tags && !campaign_item.tags.tags.length) || (edit_tags && !campaign_item.tags.action)">
              <div class="tag-type wizard" @click="selectTagAction('tag')">
                <div class="illustration tag"></div>
                <div>
                  <div class="name">Add Mariana Tek Tags</div>
                </div>
              </div>
              <div class="tag-type wizard" @click="selectTagAction('untag')">
                <div class="illustration untagy"></div>
                <div>
                  <div class="name">Give a Customer a Credit or Membership</div>
                </div>
              </div>
            </div>

            <div class="tag-types" v-if="edit_tags && campaign_item.tags.action">
              <div class="btn-back" @click.prevent="campaign_item.tags.action = null">
                <span class="material-icons">keyboard_arrow_left</span>
                Back to options
              </div>

              <TagAction :tagAction="campaign_item.tags.action" :campaignItemId="campaign_item.id" :selectedTags="campaign_item.tags.tags" :mtAction="campaign_item.name" />
            </div>

            <div class="tag-types" v-if="campaign_item.tags.action && !edit_tags">
              <div class="stats" v-if="campaign_item.turned_on_at || campaign_item.paused_at" style="border-bottom: none;">
                <div :class="['stat', show_stat ? 'show' : '']" style="flex-grow: 0;" @click="show_stat = false">
                  <h6 v-if="campaign_item.tags.action === 'tag'">Tagged</h6>
                  <h6 v-if="campaign_item.tags.action === 'untag'">Memberships & Credits</h6>
                  <span v-if="campaign_item.tags.action === 'tag'" class="number">{{ campaign_item.tags.tags.length | prettyNumber }}</span>
                  <span v-if="campaign_item.tags.action === 'untag' "class="number">{{ 1 | prettyNumber }}</span>
                </div>
              </div>
              <p v-if="campaign_item.tags.action === 'tag'" >Add the following tag(s)</p>
              <p v-if="campaign_item.tags.action === 'untag'" >Give customer the following membership(s) and credit(s)</p>
              <div class="tags">
                <span v-if="campaign_item.tags.action === 'tag'" v-for="tag in campaign_item.tags.tags" class="tag">{{ tag.name }}</span>
                <span v-if="campaign_item.tags.action === 'untag'" class="tag">{{ this.mtActionTag.name }}</span>
              </div>
            </div>
          </div>
        </div>

    <div class="card" v-if="campaign_item.type === 'sms' && behaviorComplete && campaign_item.name === 'Webhook'">
          <div class="card-body">
            <div class="title">
              <span :class="['step-number', smsComplete ? 'complete' : '']">
                <span v-if="!smsComplete">2</span>
                <span class="icon icon-checkmark" v-if="smsComplete"></span>
              </span>
              Webhook Details
              <button style="margin-left: auto;" class="xpl-button xpl-button--secondary" v-if="(campaign_item.turned_on_at || campaign_item.paused_at) && campaign_item.sms.body &&  campaign_item.sms.body.length && !edit_sms && !hideButtons" @click="editSms">Edit</button>
            </div>
            <div class="stats" v-if="campaign_item.turned_on_at || campaign_item.paused_at">
              <div :class="['stat', show_stat === 'created' ? 'show' : '']" style="flex-grow: 0;" @click="showStat('created')">
                <h6>Scheduled</h6>
                <span class="number">{{ campaign_item.scheduled_sms | prettyNumber }}</span>
              </div>
              <div :class="['stat', show_stat === 'sent' ? 'show' : '']" style="flex-grow: 0;" @click="showStat('sent')">
                <h6>Sent</h6>
                <span class="number">{{ campaign_item.sent_sms | prettyNumber }}</span>
              </div>
            </div>

            <SMSStats v-if="show_stat" :stat="show_stat" :campaignItemId="campaign_item.id" />

            <div v-if="!show_stat">
              <div class="sms-setup" v-if="(!campaign_item.turned_on_at && !campaign_item.paused_at) || edit_sms">
                <p>Send this Webhook to the contacts based on the criteria set above</p>
                <div class="row">
                  <div class="col-sm-6">
                    <form @submit.prevent>
                      <div class="form-group">
                        <label>Webhook post body:</label>
                        <textarea class="form-control" v-model="campaign_item.sms.body"></textarea>
                        <!-- <div :class="['small', campaign_item.sms.body.length <= 1000 ? 'text-muted' : 'text-danger']">Characters remaining: {{ 320 - campaign_item.sms.body.length }}</div> -->
                        <!-- <div class="small text-muted">If a Personalization Tag goes over the character limit, the SMS will break into multiple parts and count as multiple SMS sent.</div> -->
                      </div>
                       <div class="form-group">
                        <label>Webhook URL:</label>
                        <!-- <input class="form-control" v-model="hardURL"></input> -->
                        <input type="text" v-model="this.hardUrl" class="form-control" placeholder="enter your webhook url" />
                        <!-- <div :class="['small', campaign_item.sms.body.length <= 1000 ? 'text-muted' : 'text-danger']">Characters remaining: {{ 320 - campaign_item.sms.body.length }}</div> -->
                        <!-- <div class="small text-muted">If a Personalization Tag goes over the character limit, the SMS will break into multiple parts and count as multiple SMS sent.</div> -->
                      </div>
                      <div class="form-group">
                        <button type="button" class="xpl-button xpl-button--primary" :disabled="!campaign_item.sms.body || !campaign_item.sms.body.length || campaign_item.sms.body.length > 320" @click="save">Save</button>
                      </div>
                    </form>
                  </div>
                  <div class="col-sm-6">
                    <!-- <div style="margin-bottom: .5rem; font-weight: 700; font-size: .875rem; line-height: 1rem;">Available Personalizations</div>
                    <div class="personalizations-container">
                      <div class="personalizations" v-if="$store.state.user.current_account.active_vendors.indexOf('pike13') > -1">
                        <div class="personalization">
                          First Name
                          <a href="#" @click.prevent="copyPersonalization('first_name', 'there')">Copy</a>
                        </div>
                        <div class="personalization">
                          Last Name
                          <a href="#" @click.prevent="copyPersonalization('last_name', 'there')">Copy</a>
                        </div>
                        <div class="personalization">
                          Next Future Visit (e.g. Monday, June 8th)
                          <a href="#" @click.prevent="copyPersonalization('direct_future_reservation', 'soon')">Copy</a>
                        </div>
                        <div class="personalization" v-if="$store.state.user.current_account.franchise || $store.state.user.current_account.family" v-for="merge_tag in $store.state.user.current_account.merge_tags">
                          {{ merge_tag.label }}
                          <a href="#" @click.prevent="copyPersonalization(merge_tag.identifier, null)">Copy</a>
                        </div>
                      </div> -->
                      <!-- <div class="personalizations" v-if="$store.state.user.current_account.active_vendors.indexOf('pike13') === -1">
                        <div class="personalization">
                          First Name
                          <a href="#" @click.prevent="copyPersonalization('first_name', 'there')">Copy</a>
                        </div>
                        <div class="personalization">
                          Last Name
                          <a href="#" @click.prevent="copyPersonalization('last_name', 'there')">Copy</a>
                        </div>
                        <div class="personalization">
                          Last Instructor
                          <a href="#" @click.prevent="copyPersonalization('last_instructor')">Copy</a>
                        </div>
                        <div class="personalization">
                          Last visit date (e.g. May 7th)
                          <a href="#" @click.prevent="copyPersonalization('last_visit_at')">Copy</a>
                        </div>
                        <div class="personalization" v-if="$store.state.user.current_account.franchise || $store.state.user.current_account.family" v-for="merge_tag in $store.state.user.current_account.merge_tags">
                          {{ merge_tag.label }}
                          <a href="#" @click.prevent="copyPersonalization(merge_tag.identifier, null)">Copy</a>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="sms-setup" v-if="(campaign_item.turned_on_at || campaign_item.paused_at) && !edit_sms">
                <p>Send this Webhook to the contacts based on the criteria set above</p>
                <div class="alert alert-default">
                  <p class="jazz"><span class="diffy">Webhook Post Body:</span> {{ campaign_item.sms.body }}</p>

                  <p class="jazz"><span class="diffy">Webhook URL:</span> {{this.hardUrl}}</p>

                </div>
              </div>
            </div>
          </div>
        </div>
       <div class="card" v-if="campaign_item.type === 'sms' && behaviorComplete && campaign_item.name !== 'Webhook'">
          <div class="card-body">
            <div class="title">
              <span :class="['step-number', smsComplete ? 'complete' : '']">
                <span v-if="!smsComplete">2</span>
                <span class="icon icon-checkmark" v-if="smsComplete"></span>
              </span>
              SMS Details
              <button style="margin-left: auto;" class="xpl-button xpl-button--secondary" v-if="(campaign_item.turned_on_at || campaign_item.paused_at) && campaign_item.sms.body &&  campaign_item.sms.body.length && !edit_sms && !hideButtons" @click="editSms">Edit</button>
            </div>
            <div class="stats" v-if="campaign_item.turned_on_at || campaign_item.paused_at">
              <div :class="['stat', show_stat === 'created' ? 'show' : '']" style="flex-grow: 0;" @click="showStat('created')">
                <h6>Scheduled</h6>
                <span class="number">{{ campaign_item.scheduled_sms | prettyNumber }}</span>
              </div>
              <div :class="['stat', show_stat === 'sent' ? 'show' : '']" style="flex-grow: 0;" @click="showStat('sent')">
                <h6>Sent</h6>
                <span class="number">{{ campaign_item.sent_sms | prettyNumber }}</span>
              </div>
            </div>

            <SMSStats v-if="show_stat" :stat="show_stat" :campaignItemId="campaign_item.id" />

            <div v-if="!show_stat">
              <div class="sms-setup" v-if="(!campaign_item.turned_on_at && !campaign_item.paused_at) || edit_sms">
                <p>Send this SMS to the contacts based on the criteria set above</p>
                <div class="row">
                  <div class="col-sm-6">
                    <form @submit.prevent>
                      <div class="form-group">
                        <label>Message you would like to send</label>
                        <textarea class="form-control" v-model="campaign_item.sms.body"></textarea>
                        <div :class="['small', campaign_item.sms.body.length <= 320 ? 'text-muted' : 'text-danger']">Characters remaining: {{ 320 - campaign_item.sms.body.length }}</div>
                        <div class="small text-muted">If a Personalization Tag goes over the character limit, the SMS will break into multiple parts and count as multiple SMS sent.</div>
                      </div>
                      <div class="form-group">
                        <button type="button" class="xpl-button xpl-button--primary" :disabled="!campaign_item.sms.body || !campaign_item.sms.body.length || campaign_item.sms.body.length > 320" @click="save">Save</button>
                      </div>
                    </form>
                  </div>
                  <div class="col-sm-6">
                    <div style="margin-bottom: .5rem; font-weight: 700; font-size: .875rem; line-height: 1rem;">Available Personalizations</div>
                    <div class="personalizations-container">
                      <div class="personalizations" v-if="$store.state.user.current_account.active_vendors.indexOf('pike13') > -1">
                        <div class="personalization">
                          First Name
                          <a href="#" @click.prevent="copyPersonalization('first_name', 'there')">Copy</a>
                        </div>
                        <div class="personalization">
                          Last Name
                          <a href="#" @click.prevent="copyPersonalization('last_name', 'there')">Copy</a>
                        </div>
                        <div class="personalization">
                          Next Future Visit (e.g. Monday, June 8th)
                          <a href="#" @click.prevent="copyPersonalization('direct_future_reservation', 'soon')">Copy</a>
                        </div>
                        <div class="personalization" v-if="$store.state.user.current_account.franchise || $store.state.user.current_account.family" v-for="merge_tag in $store.state.user.current_account.merge_tags">
                          {{ merge_tag.label }}
                          <a href="#" @click.prevent="copyPersonalization(merge_tag.identifier, null)">Copy</a>
                        </div>
                      </div>
                      <div class="personalizations" v-if="$store.state.user.current_account.active_vendors.indexOf('pike13') === -1">
                        <div class="personalization">
                          First Name
                          <a href="#" @click.prevent="copyPersonalization('first_name', 'there')">Copy</a>
                        </div>
                        <div class="personalization">
                          Last Name
                          <a href="#" @click.prevent="copyPersonalization('last_name', 'there')">Copy</a>
                        </div>
                        <div class="personalization">
                          Last Instructor
                          <a href="#" @click.prevent="copyPersonalization('last_instructor')">Copy</a>
                        </div>
                        <div class="personalization">
                          Last visit date (e.g. May 7th)
                          <a href="#" @click.prevent="copyPersonalization('last_visit_at')">Copy</a>
                        </div>
                        <div class="personalization" v-if="$store.state.user.current_account.franchise || $store.state.user.current_account.family" v-for="merge_tag in $store.state.user.current_account.merge_tags">
                          {{ merge_tag.label }}
                          <a href="#" @click.prevent="copyPersonalization(merge_tag.identifier, null)">Copy</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sms-setup" v-if="(campaign_item.turned_on_at || campaign_item.paused_at) && !edit_sms">
                <p>Send this SMS to the contacts based on the criteria set above</p>
                <div class="alert alert-default">
                  {{ campaign_item.sms.body }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="['card', 'pick-type', !behaviorComplete ? 'disabled' : '']" v-if="!campaign_item.type_id && campaign_item.type === 'email'">
          <div class="card-body">
            <div class="title">
              <span class="step-number">2</span>
              Select the type of email you want to send
            </div>
            <div class="email-types">
              <div class="email-type" @click="selectMessageType('full')">
                <div class="illustration full-design"></div>
                <div>
                  <div class="name">Full Design with Drag and Drop</div>
                  (Fill it with brand colors, fonts, buttons and images)
                </div>
              </div>
              <div class="email-type" @click="selectMessageType('rich')">
                <div class="illustration rich-text"></div>
                <div>
                  <div class="name">Text Only</div>
                  (Something you would send a friend)
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card email-details" v-if="campaign_item.type_id && campaign_item.type === 'email'">
          <div class="card-header card-header-secondary card-header-merged">
            <div>
            Email Details
            <small class="text-muted" v-if="campaign_item.email">Last updated {{ campaign_item.email.updated_at | dateTime }}</small>
            </div>
            <div class="actions">
              <div class="dropdown" v-if="!campaign_item.turned_on_at">
                <button type="button" class="xpl-button xpl-button--secondary" @click="email_options_menu = !email_options_menu">Options</button>
                <div :class="['dropdown-menu', 'dropdown-menu-right', email_options_menu ? 'show' : '']">
                  <a href="#" class="dropdown-item" @click.prevent="show_template_modal = !show_template_modal">Save as Template</a>
                  <a href="#" class="dropdown-item" @click="startOver" v-if="!hideButtons">Start Over</a>
                </div>
              </div>
              <button type="button" class="xpl-button xpl-button--primary" @click="designEmail" v-if="!campaign_item.turned_on_at && !hideButtonContent">Edit this Email</button>
              <button type="button" class="xpl-button xpl-button--secondary" v-if="campaign_item.turned_on_at && !hideButtonContent" @click="toggleCampaignItemState">Pause and Edit</button>
            </div>
          </div>
          <div class="card-body">
            <div class="stats" v-if="campaign_item.email && campaign_item.email.stats && (campaign_item.turned_on_at || campaign_item.paused_at)">
              <div :class="{ 'stat': true, 'show': show_stat === 'scheduled' }" @click.stop="showStat('scheduled')">
                <h6>Scheduled</h6>
                <span class="number sent">{{ campaign_item.scheduled_emails | prettyNumber }}</span>
              </div>
              <div :class="{ 'stat': true, 'show': show_stat === 'sent' }" @click.stop="showStat('sent')">
                <h6>Sent</h6>
                <span class="number sent">{{ campaign_item.email.stats.sent | prettyNumber }}</span>
              </div>
              <div :class="{ 'stat': true, 'show': show_stat === 'opened' }" @click.stop="showStat('opened')">
                <h6>Opened</h6>
                <span class="number opened">{{ campaign_item.email.stats.sent ? campaign_item.email.stats.opened_unique / campaign_item.email.stats.sent : 0 | prettyPercentage }}</span>
                <span class="sub-number">{{ campaign_item.email.stats.opened_unique | prettyNumber }}</span>
              </div>
              <div :class="{ 'stat': true, 'show': show_stat === 'clicked' }" @click.stop="showStat('clicked')">
                <h6>Clicked</h6>
                <span class="number clicked">{{ campaign_item.email.stats.sent ? campaign_item.email.stats.clicked_unique / campaign_item.email.stats.sent : 0 | prettyPercentage }}</span>
                <span class="sub-number">{{ campaign_item.email.stats.clicked_unique | prettyNumber }}</span>
              </div>
              <div :class="{ 'stat': true, 'show': show_stat === 'bounced' }" @click.stop="showStat('bounced')">
                <h6>Bounces</h6>
                <span class="number bounced">{{ campaign_item.email.stats.sent ? campaign_item.email.stats.bounced / campaign_item.email.stats.sent : 0 | prettyPercentage }}</span>
                <span class="sub-number">{{ campaign_item.email.stats.bounced | prettyNumber }}</span>
              </div>
              <div :class="{ 'stat': true, 'show': show_stat === 'unsubscribed' }" @click.stop="showStat('unsubscribed')">
                <h6>Unsubscribed</h6>
                <span class="number unsubscribed">{{ campaign_item.email.stats.sent ? campaign_item.email.stats.unsubscribed / campaign_item.email.stats.sent : 0 | prettyPercentage }}</span>
                <span class="sub-number">{{ campaign_item.email.stats.unsubscribed | prettyNumber }}</span>
              </div>
            </div>

            <EmailStat :event="show_stat" :typeUuid="campaign_item.uuid" :campaignItemId="campaign_item.id" v-if="show_stat" />

            <div v-if="!show_stat && campaign_item.email">
              <div class="row">
                <div class="col-md-2 label">Subject Line</div>
                <div class="col-md-10">
                  {{ campaign_item.email.subject }}
                  <div class="text-muted">{{ campaign_item.email.pre_header }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2 label">From Info</div>
                <div class="col-md-10">
                  {{ campaign_item.email.from_name }}
                  <span class="text-muted">({{ campaign_item.email.from_address }})</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="preview" v-if="!show_stat && campaign_item.type === 'email' && campaign_item.type_id">
          <div class="preview-header">
            <span>Email Preview</span>
            <div class="preview-types">
              <span :class="{ 'icon': true, 'icon-display': true, 'active': preview_mode.desktop }" @click="togglePreviewMode('desktop')"></span>
              <span :class="{ 'icon': true, 'icon-mobile': true, 'active': preview_mode.mobile }" @click="togglePreviewMode('mobile')"></span>
            </div>
            <div>
              <button type="button" class="xpl-button xpl-button--secondary" v-if="campaign_item.type_id" @click.stop="show_quick_send_modal = !show_quick_send_modal">Quick Send</button>
              <button type="button" class="xpl-button xpl-button--secondary" v-if="campaign_item.type_id" @click="test_email = true">Test Email</button>
            </div>
            <TestEmail v-if="test_email && campaign_item.type_id" :emailId="campaign_item.type_id" />
            <QuickSendModal v-if="show_quick_send_modal" :campaignItemId="campaign_item.id" />
          </div>
          <div class="">
            <div :class="{ 'preview-rendering': true, 'mobile': preview_mode.mobile }" v-html="preview_html"></div>
            <div class="text-center" v-if="!preview_html">
              <i class="fa fa-spin fa-spinner fa-fw"></i>
            </div>
          </div>
        </div>

        <div class="card" v-if="(campaign_item.turned_on_at || campaign_item.paused_at || taskComplete) && (campaign_item.type === 'task' || campaign_item.type === 'phone_call')">
          <div :class="['card-header', 'card-header-merged', 'card-header-white', !edit_task ? 'card-header-secondary' : '']">
            <div class="title">
              <span class="step-number">2</span>
              Automated {{ campaign_item.type === 'phone_call' ? 'Phone Call Reminder' : 'Task' }}
            </div>
            <button type="button" class="xpl-button xpl-button--secondary" @click="toggleCampaignItemState" v-if="campaign_item.turned_on_at && !hideButtons">Pause and Edit</button>
            <button type="button" class="xpl-button xpl-button--secondary" v-if="(!campaign_item.paused_at && !campaign_item.turned_on_at && !edit_task) || (taskComplete && campaign_item.paused_at && !edit_task) || (hideButtonContent && !edit_task)" @click.prevent="edit_task = !edit_task">Edit</button>
            <button type="button" class="xpl-button xpl-button--primary" @click="save" v-if="edit_task" :disabled="!campaign_item.task.name">Save</button>
          </div>
          <div class="card-body">
            <div class="stats" v-if="campaign_item.turned_on_at || campaign_item.paused_at">
              <div :class="['stat', show_stat ? 'show' : '']" style="flex-grow: 0;" @click="show_stat = !show_stat">
                <h6>Created</h6>
                <span class="number">{{ campaign_item.created_tasks | prettyNumber }}</span>
              </div>
            </div>
            <div class="" v-if="show_stat">
              <a href="#" class="go-back" @click.prevent="show_stat = false"><i class="fa fa-caret-left"></i> Back to task</a>
              <div class="datatable">
                <div class="stats-header">
                  <h1>Tasks Created</h1>

                  <form @submit.prevent>
                    <div class="input-group">
                      <span class="input-group-addon">
                        <span class="icon icon-search"></span>
                      </span>
                      <input type="search" name="query" v-model="query" class="form-control" placeholder="Search" />
                    </div>
                  </form>
                </div>

                <table class="table bb-datatable">
                  <thead>
                    <tr>
                      <td></td>
                      <td>Name</td>
                      <td class="text-right">Due Date</td>
                      <td class="text-right">Created Date</td>
                      <td class="text-right">Task Status</td>
                      <td class="text-right"></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="contact in contacts.data">
                      <td style="cursor: initial;"></td>
                      <td style="cursor: initial;">
                        <router-link :to="'/contacts/' + contact.uuid" target="_blank">
                          {{ contact.first_name ? contact.first_name + ' ' + contact.last_name : contact.email }}
                        </router-link>
                      </td>
                      <td style="cursor: initial;" class="text-right">{{ contact.scheduled_at | humanDate(false) }}</td>
                      <td style="cursor: initial;" class="text-right">{{ contact.created_at | humanDateTime }}</td>
                      <td style="cursor: initial;" class="text-right">{{ contact.task_status === true ? 'Complete' : 'Incomplete' }}</td>
                      <td style="cursor: initial;" class="text-right">
                        <a href="#" @click.prevent="selectedContact = contact; show_contact_activity = true;">See Activity</a>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="pages">
                  <div class="pages-flip">
                    <div class="page-left" @click="previousPage()">
                      <i class="fa fa-arrow-left"></i>
                    </div>
                    <div class="page-right" @click="nextPage()">
                      <i class="fa fa-arrow-right"></i>
                    </div>
                  </div>
                  Page {{ contacts.current_page }} of {{ contacts.last_page }}
                </div>
              </div>
            </div>
            <form @submit.prevent v-if="(!show_stat && campaign_item.turned_on_at && !edit_task) || (!edit_task && !show_stat)">
              <div class="form-group">
                <label>Task Name</label>
                <div>{{ campaign_item.task.name }}</div>
              </div>

              <div class="form-group" v-if="campaign_item.task.note">
                <label>Task Note</label>
                <div v-html="campaign_item.task.note"></div>
              </div>

              <div class="form-group">
                <label>Task Assignment</label>
                <div class="assigned" style="cursor: initial;" v-if="!campaign_item.assigned_user">
                  <span class="circle empty"></span>
                  <span>No assignment</span>
                </div>
                <div class="assigned active" style="cursor: initial;" v-if="campaign_item.assigned_user">
                  <span class="user-badge" v-if="campaign_item.assigned_user.first_name && campaign_item.assigned_user.last_name">
                    {{ campaign_item.assigned_user.first_name.slice(0,1) }}{{ campaign_item.assigned_user.last_name.slice(0,1) }}
                  </span>
                  <span class="user-badge" v-else>
                    {{ campaign_item.assigned_user.email.slice(0,2) }}
                  </span>
                  <span>{{ campaign_item.assigned_user.first_name ? campaign_item.assigned_user.first_name + ' ' + campaign_item.assigned_user.last_name : campaign_item.assigned_user.email }}</span>
                </div>
              </div>
            </form>
            <form @submit.prevent v-if="(!show_task && edit_task)">
              <div class="form-group" v-if="!hideButtons">
                <label>Task Name</label>
                <input type="text" v-model="campaign_item.task.name" class="form-control" placeholder="Task Name" />
              </div>

              <div class="form-group" v-if="!hideButtons">
                <label>Task Note (optional)</label>
                <quill-editor style="width: 100%;" v-model="campaign_item.task.note" ref="myQuillEditor2" :options="editorOption"></quill-editor>
              </div>

              <div class="form-group">
                <label>Task Assignment (optional)</label>
                <div class="assigned" v-if="!campaign_item.assigned_user" @click="task_assignment_modal = !task_assignment_modal">
                  <span class="circle empty"></span>
                  <span>No assignment</span>
                </div>
                <div class="assigned active" v-if="campaign_item.assigned_user" @click="task_assignment_modal = !task_assignment_modal">
                  <span class="user-badge" v-if="campaign_item.assigned_user.first_name && campaign_item.assigned_user.last_name">
                    {{ campaign_item.assigned_user.first_name.slice(0,1) }}{{ campaign_item.assigned_user.last_name.slice(0,1) }}
                  </span>
                  <span class="user-badge" v-else>
                    {{ campaign_item.assigned_user.email.slice(0,2) }}
                  </span>
                  <span>{{ campaign_item.assigned_user.first_name ? campaign_item.assigned_user.first_name + ' ' + campaign_item.assigned_user.last_name : campaign_item.assigned_user.email }}</span>
                  <span class="icon icon-cross3 remove-assigned" @click.stop="removeAssigned"></span>
                </div>
              </div>
            </form>
          </div>
        </div>
    </div>
      <TemplateSelection :campaignItem="campaign_item" :type="emailType" v-if="emailType && show_template_selection_modal" />
      <TemplateModal v-if="show_template_modal && campaign_item.email" :emailId="campaign_item.email.id" :email="campaign_item.email" />
      <TaskAssignModal v-if="task_assignment_modal" :type="campaign_item.type" :assignedUser="campaign_item.assigned_user" />
      <ContactActivity v-if="show_contact_activity" :campaign="campaign" :contact="selectedContact" />

      <div v-if="!edit_email" class="card sweet" style="margin-top: 2rem;">
        <div class="card-header card-header-merged card-header-secondary ">Campaign Item Details</div>
        <div class="card-body">
          <div>
            This Campaign Item was created on {{ campaign_item.created_at | humanDateTime }}
            <span v-if="campaign_item.turned_on_at">and was started on {{ campaign_item.turned_on_at | humanDateTime }}.</span>
            <span v-if="campaign_item.paused_at">and was paused on {{ campaign_item.paused_at | humanDateTime }}.</span>
          </div>
        </div>
      </div>
    </div>
   
  </div>
</template>
<script>
  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';
  import { quillEditor } from 'vue-quill-editor';
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';
  import EditEmail from '@/components/ScheduledItems/EditEmail';
  import TestEmail from '@/components/ScheduledItems/TestEmail';
  import QuickSendModal from '@/components/ScheduledItems/QuickSendModal';
  import TemplateModal from '@/components/ScheduledItems/TemplateModal';
  import EmailStat from '@/components/ScheduledItems/EmailStats/EmailStat';
  import TemplateSelection from './TemplateSelection';
  import TaskAssignModal from './TaskAssignModal';
  import ContactActivity from './ContactActivity';
  import CampaignItemIsLive from './CampaignItemIsLive';
  import CampaignItemIsPaused from './CampaignItemIsPaused';
  import CampaignItemSetup from './CampaignItemSetup';
  import TagAction from './TagAction';
  import SMSStats from './SMSStats';
  import CampaignItemsList from './CampaignItemsList'

  export default {
    props: ['uuid', 'campaignItems', 'campaign_id'],
    components: {
      quillEditor,
      Multiselect,
      TemplateSelection,
      EditEmail,
      TestEmail,
      QuickSendModal,
      EmailStat,
      TemplateModal,
      TaskAssignModal,
      ContactActivity,
      CampaignItemIsLive,
      CampaignItemIsPaused,
      CampaignItemSetup,
      TagAction,
      SMSStats,
      CampaignItemsList,
    },
    data() {
      return {
        editorOption: {
          placeholder: 'Add a note',
          modules: {
            toolbar: ['bold', 'italic', 'underline', 'link'],
          },
        },
        show_quick_send_modal: false,
        email_options_menu: false,
        test_email: false,
        edit_email: false,
        edit_task: false,
        edit_behavior: false,
        behavior_sidebar: false,
        show_task: false,
        options_menu: false,
        campaign: null,
        campaign_item: null,
        emailType: null,
        show_template_selection_modal: false,
        show_template_modal: false,
        show_stat: false,
        preview_mode: {
          desktop: true,
          mobile: false,
        },
        preview_html: null,
        task_assignment_modal: false,
        contacts: [],
        selectedContact: null,
        show_contact_activity: false,
        query: '',
        task_complete: false,
        tag_complete: false,
        sms_complete: false,
        edit_tags: false,
        edit_sms: false,
        mtActionTag: {
          id: 66556,
          uuid: 'df35aaad-ef08-49e7-9823-c281a4caec40',
          type: 'brandbot',
          name: 'Pure Barre Irvine Monthly Unlimited',
          value: null,
          metadata: null,
          created_at: '2021-03-05 20:12:17',
          updated_at: '2021-03-05 20:12:17',
        },
        hardUrl: 'www.speedyoga.com',
        campaign_items: [],
        loading_items: true,
        selected_item_uuid: this.uuid,
        item_name_edit: false,
      };
    },
    mounted() {
      EventBus.$on('quick_send.close', () => {
        this.show_quick_send_modal = false;
      });

      EventBus.$on('sms_stat.close', () => {
        this.show_stat = null;
      });

      EventBus.$on('scheduled_email.test_email.close', () => {
        this.test_email = false;
      });

      EventBus.$on('scheduled_email.template.close', () => {
        this.show_template_modal = false;
      });

      EventBus.$on('campaign_item.template.close', () => {
        this.emailType = null;
        this.show_template_selection_modal = false;
      });

      EventBus.$on('campaign_item.template.selected', (email) => {
        this.show_template_selection_modal = false;
        this.emailType = null;
        this.campaign_item.type_id = email.id;
        this.campaign_item.email = email;
        this.edit_email = true;
      });

      EventBus.$on('scheduled_item.email.edit.close', () => {
        this.edit_email = false;
        this.fetchCampaignItem();
        this.fetchEmailPreview();
        this.emailPerformance();
      });

      EventBus.$on('task.assign_modal.close', () => {
        this.task_assignment_modal = false;
      });

      EventBus.$on('task.assign_modal.choose', (assignedUser) => {
        this.campaign_item.assigned_user = assignedUser;
      });

      EventBus.$on('contact_activity.sidebar.close', () => {
        this.show_contact_activity = false;
      });

      EventBus.$on('scheduled_email.stats.close', () => {
        this.show_stat = false;
      });

      EventBus.$on('campaign_item.refresh', (payload) => {
        if (payload) {
          this.campaign_item.time_rule.choice = payload.choice;
          this.campaign_item.time_rule.relative = payload.choice;
          this.campaign_item.time_rule.relative_count = payload.choice;
          this.campaign_item.behavior_rule.rule = payload.rule;
        } else {
          this.fetchCampaignItem();
        }
      });

      EventBus.$on('campaign_item.pause', () => {
        this.toggleCampaignItemState();
      });

      EventBus.$on('campaign_item.start', () => {
        this.toggleCampaignItemState();
      });

      EventBus.$on('campaign_item.save', () => {
        this.save();
      });

      EventBus.$on('tag_action.close', () => {
        this.fetchCampaignItem();
        this.edit_tags = false;
      });
      EventBus.$on('campaign_items_list.refresh', () =>{
        this.fetchCampaignItems(this.campaign.id);
      });
      EventBus.$on('campaign_item.toggle', (payload) =>  {
        this.edit_email = false;
        this.edit_task = false;
        this.edit_behavior = false;
        this.edit_tags = false;
        this.edit_sms = false;
        api.getCampaignItem(payload.uuid).then((response) => {
          this.campaign_item = response.campaign_item;
          this.campaign = response.campaign;
          this.selected_item_uuid = payload.uuid;
          this.item_name_edit = false;
          if (response.campaign_item.type !== 'email' &&
            response.campaign_item.task &&
            response.campaign_item.task.name) {
            this.task_complete = true;
          }
          if (response.campaign_item &&
            response.campaign_item.type === 'email' &&
            response.campaign_item.type_id) {
            this.fetchEmailPreview();
            this.emailPerformance();
          }

          if (response.campaign_item &&
            (response.campaign_item.type === 'task' || response.campaign_item.type === 'phone_call') &&
            response.campaign_item.type_id) {
            this.fetchContactsCreatedTask();
          }
        }).catch((err) => {
          this.$toasted.error(err.message);
        });

      });
    },
    created() {
      this.fetchCampaignItem();
      if(this.campaignItems?.length) {
        this.campaign_items = this.campaignItems;
      } else if (this.campaign_id) {
        this.fetchCampaignItems(this.campaign_id);
      }
      if (!this.$store.state.user.current_account.did_setup_brand) {
        this.$router.push('/quick-brand');
      }
    },
    beforeDestroy() {
      EventBus.$off('quick_send.close');
      EventBus.$off('campaign_item.save');
      EventBus.$off('scheduled_email.test_email.close');
      EventBus.$off('campaign_item.template.close');
      EventBus.$off('campaign_item.template.selected');
      EventBus.$off('scheduled_email.template.close');
      EventBus.$off('scheduled_item.email.edit.close');
      EventBus.$off('task.assign_modal.close');
      EventBus.$off('task.assign_modal.choose');
      EventBus.$off('contact_activity.sidebar.close');
      EventBus.$off('scheduled_email.stats.close');
      EventBus.$off('campaign_item.refresh');
      EventBus.$off('campaign_item.pause');
      EventBus.$off('campaign_item.start');
      EventBus.$off('tag_action.close');
      EventBus.$off('sms_stat.close');
      EventBus.$off('campaign_items_list.refresh');
      EventBus.$off('campaign_item.toggle');
    },
    computed: {
      hideButtons() {
        if (this.$store.state.user.is_hq_admin) {
          return false;
        }

        // this magical array contains some non-BB users who can sync campaigns
        if (this.$store.getters.isBrandBotAdmin || this.$store.getters.isCampaignSuperUser) {
          return false;
        }

        if (this.campaign.sync_type && this.campaign.sync_type === 'sync_lock') {
          return true;
        }

        if (this.campaign.sync_type && this.campaign.sync_type === 'sync_unlock') {
          return false;
        }

        return false;
      },
      hideButtonContent() {
        if (this.$store.state.user.is_hq_admin) {
          return false;
        }

        // this magical array contains some non-BB users who can sync campaigns
        if (this.$store.getters.isBrandBotAdmin || this.$store.getters.isCampaignSuperUser) {
          return false;
        }

        if (this.campaign.sync_type && this.campaign.sync_type === 'sync_lock') {
          return true;
        }

        if (this.campaign.sync_type && this.campaign.sync_type === 'sync_unlock') {
          return false;
        }

        return false;
      },
      behaviorComplete() {
        if (!this.campaign_item) {
          return false;
        }

        return ((this.campaign_item.time_rule.choice !== null ||
          (this.campaign_item.behavior_rule.rule && this.campaign_item.behavior_rule.rule.length)) &&
          this.campaign_item.updated_at > this.campaign_item.created_at);
      },
      emailComplete() {
        if (!this.campaign_item || !this.campaign_item.email) {
          return false;
        }

        return this.campaign_item.type === 'email' && this.campaign_item.type_id && this.campaign_item.email.subject && this.campaign_item.email.from_name && this.campaign_item.email.replyto_address;
      },
      taskComplete() {
        if (this.campaign_item &&
            (this.campaign_item.type === 'task' || this.campaign_item.type === 'phone_call')) {
          return this.campaign_item.task.name &&
            this.campaign_item.updated_at > this.campaign_item.created_at && this.task_complete;
        }

        return false;
      },
      tagComplete() {
        if (this.campaign_item &&
            (this.campaign_item.type === 'tag')) {
          return this.campaign_item.updated_at > this.campaign_item.created_at &&
        this.campaign_item.tags && this.campaign_item.tags.tags &&
        this.campaign_item.tags.tags.length && this.campaign_item.tags.action;
        }

        return false;
      },
      MTtagComplete() {
        if (this.campaign_item &&
            (this.campaign_item.type === 'tag')) {
          return this.campaign_item.updated_at > this.campaign_item.created_at &&
          this.mtActionTag && this.campaign_item.tags.action;
        }

        return false;
      },
      smsComplete() {
        if (this.campaign_item &&
            (this.campaign_item.type === 'sms')) {
          return this.campaign_item.updated_at > this.campaign_item.created_at &&
        this.campaign_item.sms.body && this.campaign_item.sms.body.length;
        }

        return false;
      },
    },
    methods: {
      copyPersonalization(personalization, fallback = 'Fallback') {
        let personalizationString = `[%${personalization} | ${fallback}%]`;
        if (!fallback || !fallback.length) {
          personalizationString = `[%${personalization}%]`;
        }
        this.$copyText(`${personalizationString}`).then(() => {
          this.$toasted.success('Copied!');
        }, () => {
          this.$toasted.error('Something wrong happened while trying to copy...');
        });
      },
      editTags() {
        if (this.campaign_item.turned_on_at) {
          this.$toasted.error('Please pause this item before making any edits.');
          return;
        }

        this.edit_tags = !this.edit_tags;
      },
      editSms() {
        if (this.campaign_item.turned_on_at) {
          this.$toasted.error('Please pause this item before making any edits.');
          return;
        }

        this.edit_sms = !this.edit_sms;
      },
      selectTagAction(action) {
        this.edit_tags = true;
        this.campaign_item.tags.action = action;
      },
      async fetchCampaignItem() {
        try {
          const response = await api.getCampaignItem(this.uuid);
          this.campaign_item = response.campaign_item;
          this.campaign = response.campaign;
          if (response.campaign_item.type !== 'email' &&
            response.campaign_item.task &&
            response.campaign_item.task.name) {
            this.task_complete = true;
          }
          if (response.campaign_item &&
            response.campaign_item.type === 'email' &&
            response.campaign_item.type_id) {
            this.fetchEmailPreview();
            this.emailPerformance();
          }

          if (response.campaign_item &&
            (response.campaign_item.type === 'task' || response.campaign_item.type === 'phone_call') &&
            response.campaign_item.type_id) {
            this.fetchContactsCreatedTask();
          }
          if(!this.campaign_id) {
            this.fetchCampaignItems(response.campaign.id);
          }
        } catch (err) {
          this.$toasted.error(err.message)
        }
      },
      startOver() {
        const payload = {
          type_id: null,
        };

        api.updateCampaignItem(this.campaign_item.id, payload).then((response) => {
          this.$toasted.success('You have started your auto-email over.');
          this.campaign_item = response.campaign_item;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      showStat(stat) {
        if (this.show_stat !== stat) {
          this.show_stat = stat;
        } else {
          this.show_stat = false;
        }
      },
      removeAssigned() {
        this.campaign_item.assigned_user = null;
      },
      fetchContactsCreatedTask(page = 0) {
        api.contactsCreatedTask(this.campaign_item.id, page).then((response) => {
          this.contacts = response.contacts;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      previousPage() {
        if (this.contacts.current_page === 1) {
          return;
        }

        if (!this.contacts.prev_page_url) {
          return;
        }

        const page = this.contacts.prev_page_url.split('=')[1];
        this.fetchContactsCreatedTask(page);
      },
      nextPage() {
        if (this.contacts.current_page === this.contacts.last_page) {
          return;
        }

        if (!this.contacts.next_page_url) {
          return;
        }

        const page = this.contacts.next_page_url.split('=')[1];
        this.fetchContactsCreatedTask(page);
      },
      copyCampaignItem() {
        api.copyCampaignItem(this.campaign_item.id).then((response) => {
          this.$toasted.success(response.message);
          this.$router.push(`/campaigns/${this.campaign.uuid}`);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      deleteCampaignItem() {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to delete this campaign item?')) {
          return;
        }

        api.deleteCampaignItem(this.campaign_item.id).then((response) => {
          this.$toasted.success(response.message);
          this.$router.push(`/campaigns/${this.campaign.uuid}`);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      toggleCampaignItemState() {
        if (!this.campaign_item) {
          return;
        }

        let payload;
        if (this.campaign_item.turned_on_at) {
          payload = {
            pause: true,
          };
        } else {
          payload = {
            turn_on: true,
          };
        }

        api.updateCampaignItem(this.campaign_item.id, payload).then((response) => {
          this.$toasted.success(response.message);
          this.campaign_item.turned_on_at = response.campaign_item.turned_on_at;
          this.campaign_item.paused_at = response.campaign_item.paused_at;
          this.campaign_item.updated_at = response.campaign_item.updated_at;

          if (payload.turn_on) {
            // eslint-disable-next-line no-undef
            analytics.track('Set an Automation Item live', {
              'Automation ID': this.campaign.id,
              'Automation Name': this.campaign.name,
              'Automation Item Name': response.campaign_item.name,
            });
          }

          if (payload.pause) {
            // eslint-disable-next-line no-undef
            analytics.track('Paused an Automation Item', {
              'Automation ID': this.campaign.id,
              'Automation Name': this.campaign.name,
              'Automation Item Name': response.campaign_item.name,
            });
          }
          EventBus.$emit('campaign_items_list.refresh');
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      selectMessageType(type) {
        this.emailType = type;
        this.show_template_selection_modal = true;
      },
      saveBehavior() {
        const payload = {
          choice: this.campaign_item.time_rule.choice,
          relative: this.campaign_item.time_rule.relative,
          relative_count: this.campaign_item.time_rule.relative_count,
        };

        api.updateCampaignItem(this.campaign_item.id, payload).then((response) => {
          this.$toasted.success(response.message);
          this.edit_task = false;
          this.edit_behavior = false;
          this.campaign_item.updated_at = response.campaign_item.updated_at;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      updateName() {
        const payload = {
          name: this.campaign_item.name,
        };

        api.updateCampaignItem(this.campaign_item.id, payload).then((response) => {
          this.$toasted.success(response.message);
          this.fetchCampaignItems(this.campaign.id);
          this.item_name_edit = false;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      save() {
        let payload;
        if (this.campaign_item.type === 'email') {
          payload = {
            choice: this.campaign_item.time_rule.choice,
            relative: this.campaign_item.time_rule.relative,
            relative_count: this.campaign_item.time_rule.relative_count,
          };
        }

        if (this.campaign_item.type === 'task' ||
          this.campaign_item.type === 'phone_call' ||
          this.campaign_item.type === 'tag') {
          const assignedUserId = this.campaign_item.assigned_user;
          if (assignedUserId && assignedUserId.uuid) {
            assignedUserId.type = 'group';
          }
          payload = {
            choice: this.campaign_item.time_rule.choice,
            relative: this.campaign_item.time_rule.relative,
            relative_count: this.campaign_item.time_rule.relative_count,
            action: `new_${this.campaign_item.type}`,
            task_name: this.campaign_item.task.name,
            task_note: this.campaign_item.task.note,
            assigned_user_id: assignedUserId,
          };
        }

        if (this.campaign_item.type === 'sms') {
          payload = {
            choice: this.campaign_item.time_rule.choice,
            relative: this.campaign_item.time_rule.relative,
            relative_count: this.campaign_item.time_rule.relative_count,
            sms_body: this.campaign_item.sms.body,
          };
        }

        api.updateCampaignItem(this.campaign_item.id, payload).then((response) => {
          this.$toasted.success(response.message);
          if (this.campaign_item.type === 'email' && response.email) {
            this.campaign_item.type_id = response.email.id;
          }

          if (this.campaign_item.type === 'task' || this.campaign_item.type === 'phone_call') {
            this.task_complete = true;
          }

          if (this.campaign_item.type === 'sms') {
            this.sms_complete = true;
            this.edit_sms = false;
          }

          this.edit_task = false;
          this.edit_behavior = false;
          this.campaign_item.updated_at = response.campaign_item.updated_at;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      togglePreviewMode(mode) {
        this.preview_mode.desktop = false;
        this.preview_mode.mobile = false;

        this.preview_mode[mode] = true;
      },
      fetchEmailPreview() {
        if (!this.campaign_item.type_id) {
          return;
        }

        this.preview_html = null;
        api.getEmailPreview(this.campaign_item.type_id).then((response) => {
          this.preview_html = response.html;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      emailPerformance() {
        if (this.campaign_item.type === 'email') {
          api.emailPerformance(this.campaign_item.uuid).then((response) => {
            this.$set(this.campaign_item.email, 'stats', response.stats);
          }).catch((err) => {
            this.$toasted.error(err.message);
          });
        }
      },
      designEmail() {
        this.edit_email = true;
      },
      editBehavior() {
        if (this.campaign_item.behavior_rule.rule &&
            this.campaign_item.behavior_rule.rule.length &&
            this.campaign_item.behavior_rule.rule[0].condition.quick) {
          this.selectedOption = this.currentEnrollmentRule.condition.uuid;
        } else {
          this.conditions_sidebar = true;
        }
      },
      async fetchCampaignItems(id) {
        try {
          const response = await api.getCampaignItems(id);
          this.campaign_items = response.campaign_items;
          this.loading_items = false;
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
    },
  };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .campaign-item-container {
    margin: auto;
    padding-top: 2rem;
    padding-left: 8rem;
    padding-right: 8rem;
    padding-bottom: 4rem;
  }

  .card.paused,
  .card.started {
    // box-shadow: 0 2px 4px 0 rgba(217, 231, 240, 0.5);
    border: none;
  }

  .card.paused .card-header {
    padding: 0;
    border: none;
    background-image: linear-gradient(to right, rgb(187, 211, 242), rgb(169, 203, 224));
    height: 0.6875rem;
  }

  .card.started .card-header {
    padding: 0;
    border: none;
    background-image: linear-gradient(to right, rgb(70, 232, 136), rgb(94, 246, 188));
    height: 0.6875rem;
  }

  .card.paused .card-body,
  .card.started .card-body {
    border: solid 1px rgb(217, 231, 240);
    border-top: none;
    padding-top: 1rem;
    padding-left: 1.25rem;
    padding-bottom: 1rem;
    padding-right: 1.25rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .card.paused .card-body h1,
  .card.started .card-body h1 {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 700;
    color: rgb(29, 54, 75);
  }

  .card.paused .card-body .btn,
  .card.started .card-body .btn {
    margin-left: auto;
  }

  .card.paused .card-body img,
  .card.started .card-body img {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 1.25rem;
  }


  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 2.375rem;
  }

  .header input {
    font-size: 1.625rem;
    color: #1d364b;
    background-color: transparent;
    outline: 0;
    border: none;
    width: 45rem;
    padding: 0;
  }

  .header .actions {
    display: flex;
  }

  .header .actions .btn:first-child {
    margin-right: .5rem;
  }

  .campaign-item-inner .step {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1rem;
    color: #60676e;
    margin-top: 2rem;
  }

  .campaign-item-inner .step:first-child {
    margin-top: 0;
  }

  .campaign-item-inner .step .step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 99rem;
    background-color: #afbcca;
    margin-right: 1rem;
    color: white;
    font-size: .75rem;
  }

  .campaign-item-inner .step.active .step-number {
    background-color: #43a2e0;
  }

  .campaign-item-inner .step.complete .step-number {
    background-color: #00dd8c;
  }

  .campaign-item-inner .card {
    margin-top: 2rem;
  }

  .card-body .choices {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .card-body .choices .choice {
    display: flex;
    margin-bottom: 1.5rem;
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.25;
    color: #1d364b;
  }

  .card-body .choices .choice .small {
    max-width: 36rem;
    margin-top: 6px;
  }

  .card-body .choices .choice .icon {
    line-height: 1.25;
    font-size: 1rem;
    margin-right: .875rem;
  }

  .card-body .choices .choice .label {
    cursor: pointer;
  }

  .options-inner {
    margin-bottom: 2rem;
  }

  .options-inner input[type="number"] {
    width: 4rem;
    display: inline-block;
  }

  .email-types,
  .tag-types {
    padding-left: 2.5rem;
    margin-top: 1rem;
  }

  .email-types .email-type,
  .tag-types .tag-type {
    padding: .75rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: .5rem;
    border-radius: 6px;
    font-size: .875rem;
    line-height: 1.43;
    background-color: rgb(255, 255, 255);
    border: solid 1px rgb(217, 231, 240);
    color: rgb(139, 155, 172);
    font-style: italic;
  }

  .email-types .email-type .name,
  .tag-types .tag-type .name {
    color: rgb(29, 54, 75);
  }

  .email-types .email-type:last-child,
  .tag-types .tag-type:last-child {
    margin-bottom: 0;
  }

  .email-types .email-type:hover,
  .email-types .email-type.active,
  .tag-types .tag-type:hover,
  .tag-types .tag-type.active {
    cursor: pointer;
    // box-shadow: 0 2px 4px 0 rgb(217, 231, 240);
    background-color: rgb(255, 255, 255);
    border: solid 1px rgb(53, 186, 255);
  }

  .email-types .email-type .illustration,
  .tag-types .tag-type .illustration {
    flex-shrink: 0;
    flex-grow: 0;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    transition: all 150ms ease-in-out;
    border-radius: 6px;
    background-color: rgb(239, 246, 253);
  }

  .email-types .email-type .illustration.full-design {
    background-image: url('https://assets.brandbot.com/public/scheduled-emails/full-design-default.svg');
  }

  .email-types .email-type:hover .illustration.full-design,
  .email-types .email-type.active .illustration.full-design {
    background-image: url('https://assets.brandbot.com/public/scheduled-emails/full-design-hover.svg');
  }

  .email-types .email-type .illustration.rich-text {
    background-image: url('https://assets.brandbot.com/public/scheduled-emails/rich-text-default.svg');
  }

  .email-types .email-type:hover .illustration.rich-text,
  .email-types .email-type.active .illustration.rich-text {
    background-image: url('https://assets.brandbot.com/public/scheduled-emails/rich-text-hover.svg');
  }

  .tag-types .tag-type .illustration.tag {
    background-size: 70%;
    background-image: url('https://assets.brandbot.com/public/icons/campaigns/tag.svg');
  }

  .tag-types .tag-type:hover .illustration.tag {
    background-size: 70%;
    background-image: url('https://assets.brandbot.com/public/icons/campaigns/tag.svg');
  }

  .tag-types .tag-type .illustration.untag {
    background-size: 70%;
    background-image: url('https://assets.brandbot.com/public/icons/campaigns/untag.svg');
  }

  .tag-types .tag-type:hover .illustration.untag {
    background-size: 70%;
    background-image: url('https://assets.brandbot.com/public/icons/campaigns/untag.svg');
  }

  .tag-types .tag-type .illustration.untagy {
    background-size: 70%;
    background-image: url('https://assets.brandbot.com/public/icons/campaign-items/condition_icons_expanded.svg');
  }

  .tag-types .tag-type:hover .illustration.untagy {
    background-size: 70%;
    background-image: url('https://assets.brandbot.com/public/icons/campaign-items/condition_icons_expanded.svg');
  }


  .card-header-secondary small {
    margin-left: 1rem;
  }

  .card .row {
    line-height: 1.25rem;
    color: rgb(29, 54, 75);
    margin-top: 1rem;
    font-size: .875rem;
  }

  .card .row:first-child {
    margin-top: 0;
  }

  .card .label {
    font-weight: 700;
  }

  .card {
    flex-shrink: 0;
    flex-grow: 0;
  }

  .card:first-child .card-body {
    padding-bottom: 2rem;
  }

  .preview {
    border: solid 1px rgb(217, 231, 240);
    border-top: none;
    padding: 1rem;
    background-color: rgb(247, 248, 249);
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .preview-header {
    margin-top: -1rem;
    margin-left: -1rem;
    width: calc(100% + 2rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: .875rem;
    font-weight: 700;
    color: rgb(29, 54, 75);
  }

  .preview-types .icon {
    cursor: pointer;
    margin-right: 1rem;
  }

  .preview-types .icon:last-child {
    margin-right: 0;
  }

  .preview-types .icon.active {
    color: rgb(0, 105, 160);
  }

  .preview-rendering {
    width: 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    transition: all 300ms ease-in-out;
    pointer-events: none;
  }

  .preview-rendering.mobile {
    width: 320px;
  }

  .card.ready {
    border-radius: 0;
    // box-shadow: 0 1px 0 0 rgba(217, 231, 240, 0.5);
    border: solid 1px var(--xpl-color-gray-400);
    margin-bottom: 2rem;
  }

  .card.ready .card-body {
    border: 0;
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }

  .card.ready .card-body h6 {
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    color: #1d364b;
  }

  .card.ready .card-body p {
    margin: 0;
    font-size: .75rem;
    line-height: 1.125rem;
  }

  .card.ready .card-body .button {
    margin-left: auto;
  }

  .card.ready .material-icons {
    margin-right: 1rem;
  }

  .stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 2rem;
    margin-left: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1rem;
    width: calc(100% + 3rem);
    border-bottom: solid 1px rgb(236, 236, 236);
  }

  .stats .stat {
    flex: calc(100% / 6 - 1rem);
    flex-shrink: 0;
    flex-grow: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    min-width: 6.25rem;
    padding: .75rem;
    padding-top: 1rem;
    border-radius: 6px;
    background-color: #ffffff;
    border: solid 1px #d6e0e8;
    margin-right: .5rem;
    transition: all 150ms ease-in-out;
  }

  .stats .stat:hover,
  .stats .stat.show {
    border-color: var(--xpl-color-secondary-lm);
    background-color: var(--xpl-color-secondary-bg-lm);
  }

  .stats .stat.show h6 {
    color: var(--xpl-color-secondary-lm);
  }

  .stats .stat:last-child {
    margin-right: 0;
  }

  .stats .stat h6 {
    text-align: center;
    font-size: .625rem;
    font-weight: 800;
    letter-spacing: 0.8px;
    color: #8b9bac;
    margin-bottom: .375rem;
    text-transform: uppercase;
  }

  .stats .stat .number {
    text-align: center;
    font-size: 1.25rem;
  }

  .stats .stat .number.sent {
    color: #084e8a;
  }
  .stats .stat .number.delivered {
    color: #00848e;
  }
  .stats .stat .number.opened {
    color: #6923f4;
  }
  .stats .stat .number.clicked {
    color: #5c6ac4;
  }
  .stats .stat .number.bounced {
    color: #f49342;
  }
  .stats .stat .number.unsubscribed {
    color: #ec6b6b;
  }

  .stats .stat .sub-number {
    margin-top: .375rem;
    font-size: .75rem;
    color: #212b36;
    text-align: center;
  }

  .card .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card .actions .dropdown {
    margin-right: .5rem;
  }

  .edit-email {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2000;
    background: whitesmoke;
  }

  .card.set-live .card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .card.set-live .card-body button {
    margin-top: .875rem;
  }

  .assigned {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .875rem;
    line-height: 1.25rem;
    color: rgb(97, 112, 128);
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 99rem;
    margin-right: .75rem;
  }

  .assigned span {
    margin-right: .75rem;
  }

  .assigned .remove-assigned {
    cursor: pointer;
    opacity: 0;
    margin-left: auto;
    transition: all 300ms ease-in-out;
  }

  .assigned:hover .remove-assigned {
    opacity: 1;
  }

  .assigned.active {
    color: #2E4457;
  }

  .empty {
    border: 1px dashed #b7bfc6;
  }

  .full {
    border: 1px solid #b7bfc6;
  }

  .rule-bg {
    margin-top: 1rem;
    position: relative;
    background-color: #f2fafe;
    padding: .75rem;
    padding-bottom: 0;
    border-radius: 6px;
    width: 28rem;
  }

  .stats-header {
    display: flex;
    justify-content: space-between;
  }

  h1 {
    font-size: 1.25rem;
    color: rgb(33, 43, 54);
  }

  .datatable {
    margin-top: 2rem;
  }

  .card {
    border: none;
    background-color: rgb(255, 255, 255);
    // box-shadow: 0 4px 4px 0 rgba(97, 112, 128, 0.05);
  }

  .card-body .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 99rem;
    background-color: var(--xpl-color-secondary-lm);
    margin-right: 1rem;
    color: white;
    font-size: .75rem;
  }

  .card.pick-type.disabled {
    pointer-events: none;
    opacity: .5;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    color: #1D364B;
    line-height: 1.5rem;
    font-size: 1rem;
    margin-bottom: .25rem;
  }

  .step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 99rem;
    background-color: var(--xpl-color-secondary-lm);
    margin-right: 1rem;
    color: white;
    font-size: .75rem;
  }

  .step-number.complete {
    background-color: white;
    border: 2px solid var(--xpl-color-secondary-lm);
    color: var(--xpl-color-secondary-lm);
  }

  .card {
    border: none;
    background-color: rgb(255, 255, 255);
    // box-shadow: 0 4px 4px 0 rgba(97, 112, 128, 0.05);
  }

  .card-header.card-header-merged {
    height: auto;
    padding-left: 1.5rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-right: 1.5rem;
  }

  .card.email-details {
    box-shadow: none;
    border-radius: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border: solid 1px rgb(217, 231, 240);
  }

  .btn-back {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: rgb(105, 139, 156);
    font-size: .75rem;
    margin-bottom: 1.5rem;
  }

  .btn-back .material-icons {
    font-size: .75rem;
    margin-right: .5rem;
    line-height: 1;
  }

  .tags {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .tags .tag {
    flex-grow: 0;
    flex-shrink: 0;
    background-color: var(--xpl-color-gray-400);
    color: #2E4457;
    border-radius: 6px;
    position: relative;
    display: inline-block;
    padding: .5rem .75rem;
    margin-right: .5rem;
    line-height: 1;
    margin-bottom: .5rem;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    font-size: .75rem;
    font-weight: 700;
    text-align: center;
    opacity: .8;
  }

  .tags .tag:last-child {
    margin-right: 0;
  }

  .sms-setup {
    padding-left: 2.5rem;
  }

  .sms-setup p {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }

  .personalization {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: .875rem;
    color: rgb(29, 54, 75);
    line-height: 1.25rem;
    margin-top: 1rem;
  }

  .personalizations .personalization:first-child {
    margin-top: 0;
  }

  .personalization a {
    color: rgb(46, 68, 87);
    text-decoration: underline;
  }

  .personalization a:hover {
    text-decoration: none;
  }

.jazz {
  color: grey;
}
.diffy {
  color: #212529;
}
.sweet {
 max-width: 1052px;
 margin: auto;
}
.wizard {
  align-items: center!important;
}

.off-white-bg {
  background-color: #F3F4F6;
}
.sidebar {
  width: 264px;
}
.cont {
  flex-grow: 1;
}

.item-name {
  font-size: 1.625rem;
    color: #1d364b;
    background-color: transparent;
    outline: 0;
    border: none;
    width: 45rem;
    padding: 0;
    flex-grow: 1;
}

.fa-pencil {
 margin-left: 5px;
}
.name-change-text-box {
  border: 1px solid #d9e7f0;
  border-radius: 6px;
}
.name-change-text-box-save {
  display: flex;
  align-items: center;
}
.name-save {
  margin-left: 10px;
}
.actions-spacer {
  margin-left: 10px;
}
.campaign-item-cont {
  display: flex;
}
</style>
