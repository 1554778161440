<template>
  <el-container class="condition-container">
    <div class="sidebar-inner" style="width: 100%;">
      <BooleanField
        fieldIndex="0"
        title="Customers Who Have / Do Not Have?"
        fieldStep="1"
        yesText="Has"
        noText="Does not have"
        :selection.sync="haveSelection"
        :showLabel="true"
        descLabel="Options include instructors, credit type, membership type, status and more"
        id="37"
      />
      <TimeFrameField
        fieldIndex="1"
        title="When Are You Looking For?"
        fieldStep="2"
        :selectedOption.sync="timeframeComparison"
        :valueOne.sync="timeframeValue"
        futureLabel="days"
        pastLabel="days ago"
        :hidePast="true"
        :hideFuture="false"
        :hideTimeType="false"
        :goalList="goalList"
        id="22"
      />
      <BooleanField
        fieldIndex="2"
        title="Which Visit(s)?"
        fieldStep="3"
        yesText="All visits"
        noText="Specific visits"
        :selection.sync="selectionVisit"
        :showLabel="true"
        descLabel="Options include class name, payment option, instructors, class time, and location"
        id="33"
      />
      <div v-if="!selectionVisit" class="condition-banner-style">
        Drill Down Options
      </div>
      <MultiSelectField
        v-if="!selectionVisit"
        fieldIndex="3"
        title="Which Class Name(s)?"
        fieldStep="4"
        anyLabel="Any class name(s)"
        specificLabel="Specific class name(s)"
        specificAnyLabel="Any of the following class name(s)"
        specificExclusionLabel="Any except the following"
        :selectedOptions.sync="selectedClassNames"
        :specificChoice.sync="classNameType"
        :exclusionChoice.sync="classNameExclusionOption"
        :options="classNames"
        selectKey="id"
        id="44"
      />
       <div class="field-container" v-if="!selectionVisit">
        <div class="card-condition">
          <el-row>
            <el-col :span="1.5" class="step-col">
              <div class="field-step">5</div>
            </el-col>
            <el-col :span="12" class="body-col">
              <span class="field-title">Which Payment Option(s)?</span>
              <div class="option-body">
                <el-radio
                  class="radio-btn field-label-text"
                  v-model="paymentOptionType"
                  label="any"
                  >With any payment option</el-radio
                >
                <el-radio
                  class="radio-btn field-label-text"
                  v-model="paymentOptionType"
                  label="specific"
                  >Only specific payment options</el-radio
                >
                <el-checkbox
                  class="membership-check checks"
                  v-if="paymentOptionType === 'specific'"
                  v-model="membershipTypeChecked"
                  >Membership Types</el-checkbox
                >
                <div
                  class="desc-container"
                  v-if="
                    membershipTypeChecked && paymentOptionType === 'specific'
                  "
                >
                  <SelectionField
                    :descLabel="'Any of the following membership types'"
                    :filterOption.sync="membershipOptionExclusionOption"
                    :choice.sync="selectedMembershipOptions"
                    :options="membershipTypes"
                    :selectKey="'id'"
                    style="margin-bottom: 16px"
                    :showAny="false"
                  />
                </div>
                <el-checkbox
                  class="credit-check checks"
                  v-if="paymentOptionType === 'specific'"
                  v-model="creditTypeChecked"
                  >Credit Types</el-checkbox
                >
                <div
                  class="desc-container"
                  v-if="creditTypeChecked && paymentOptionType === 'specific'"
                >
                  <SelectionField
                    :descLabel="'Any of the following credit types'"
                    :filterOption.sync="creditOptionExclusionOption"
                    :choice.sync="selectedCreditOptions"
                    :options="creditTypes"
                    :selectKey="'id'"
                    :showAny="false"
                  />
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <MultiSelectField
        v-if="!selectionVisit"
        fieldIndex="5"
        title="Which Class Instructor(s)?"
        fieldStep="6"
        anyLabel="Any class instructor(s)"
        specificLabel="Specific class instructor(s)"
        specificAnyLabel="Any of the following class instructors"
        specificExclusionLabel="Any except the following"
        :selectedOptions.sync="selectedClassInstructors"
        :specificChoice.sync="classInstructorType"
        :exclusionChoice.sync="classInstructorExclusionOption"
        :options="classInstructors"
        selectKey="id"
        id="41"
      />
      <MultiSelectField
        v-if="!selectionVisit"
        fieldIndex="6"
        title="Which Class Time(s)?"
        fieldStep="7"
        anyLabel="Any class time(s)"
        specificLabel="Specific class time(s)"
        specificAnyLabel="Any of the following class times"
        specificExclusionLabel="Any except the following"
        :selectedOptions.sync="selectedClassTimes"
        :specificChoice.sync="classTimeType"
        :exclusionChoice.sync="classTimeExclusionOption"
        :options="classTimes"
        selectKey="id"
        id="46"
      />
      <MultiSelectField
        v-if="!selectionVisit"
        fieldIndex="7"
        title="Which Location(s)?"
        fieldStep="8"
        anyLabel="Any location in my account"
        specificLabel="Specific location(s)  in my account"
        specificAnyLabel="Any of the following locations"
        specificExclusionLabel="Any except the following"
        :selectedOptions.sync="selectedLocations"
        :specificChoice.sync="selectedLocationOption"
        :exclusionChoice.sync="exclusionLocationOption"
        :options="locations"
        selectKey="id"
        id="52"
      />
       <BooleanField
        v-if="!selectionVisit"
        fieldIndex="8"
        title="Customers Who Have / Do Not Have?"
        fieldStep="9"
        yesText="Only include first reservation ever"
        noText="Include any future reservation"
        :selection.sync="firstVisitSelection"
        :showLabel="false"
        id="99"
      />
    </div>
    <div class="save-condition-container" v-if="showSaveButton">
      <el-button class="save-condition-btn" type="primary" @click="commitCondition">Save</el-button>
    </div>
  </el-container>
</template>
<script>
import * as api from '@/api';
import EventBus from '@/event-bus';
import BooleanField from '@/components/Contacts/ContactLists/Conditions/Fields/BooleanField';
import TimeFrameField from '@/components/Contacts/ContactLists/Conditions/Fields/TimeFrameField';
import CountField from '@/components/Contacts/ContactLists/Conditions/Fields/CountField';
import MultiSelectField from '@/components/Contacts/ContactLists/Conditions/Fields/MultiSelectField';
import SelectionField from '@/components/Contacts/ContactLists/Conditions/Fields/SelectionField';

export default {
  props: ['condition', 'editedRule', 'goalList'],
  components: {
    BooleanField,
    TimeFrameField,
    CountField,
    MultiSelectField,
    SelectionField,
  },
  data() {
    return {
      haveSelection: true,
      selection: null,
      countOne: 1,
      countTwo: 2,
      timeframeComparison: null,
      timeframeValue: 1,
      selectionVisit: true,
      firstVisitSelection: false,
      classNameType: 'any',
      classNameExclusionOption: 'any',
      selectedClassNames: [],
      classNames: [],
      classInstructorType: 'any',
      classInstructorExclusionOption: 'any',
      selectedClassInstructors: [],
      classInstructors: [],
      classTimeType: 'any',
      classTimeExclusionOption: 'any',
      selectedClassTimes: [],
      classTimes: [],
      membershipTypeChecked: false,
      creditTypeChecked: false,
      membershipTypes: [],
      creditTypes: [],
      locations: [],
      selectedLocationOption: 'any',
      selectedLocations: [],
      exclusionLocationOption: 'any',
      paymentOptionType: 'any',
      creditOptionExclusionOption: 'any',
      membershipOptionExclusionOption: 'any',
      selectedCreditOptions: [],
      selectedMembershipOptions: [],
    };
  },
  created() {
    this.fetchInstructors();
    this.fetchClasses();
    this.fetchMemberships();
    this.fetchCredits();
    this.fetchClassTimes();
    this.fetchLocations();

    if (this.editedRule) {
      if (
        Object.prototype.hasOwnProperty.call(
          this.editedRule,
          'has_future_reservation',
        )
      ) {
        this.haveSelection = this.editedRule.has_future_reservation;
      } else {
        this.haveSelection = true;
      }

      this.countOne = this.editedRule.first_bound;
      this.countTwo = this.editedRule.second_bound;
      this.selection = this.editedRule.count_comparison;

      this.timeframeComparison = this.editedRule.first_visit_comparison;
      this.timeframeValue = this.editedRule.first_visit_value;

      this.selectionVisit = this.editedRule.all_first_visits;

      this.selectedClassNames = this.editedRule.class_names;
      this.classNameExclusionOption = this.editedRule.class_name_exclusion;
      this.classNameType = this.editedRule.class_name_option;

      this.selectedClassInstructors = this.editedRule.class_instructors;
      this.classInstructorExclusionOption = this.editedRule.class_instructor_exclusion;
      this.classInstructorType = this.editedRule.class_instructor_option;

      this.selectedClassTimes = this.editedRule.class_times;
      this.classTimeExclusionOption = this.editedRule.class_time_exclusion;
      this.classTimeType = this.editedRule.class_time_option;

      this.selectedMembershipOptions = this.editedRule.membership_options;
      this.selectedCreditOptions = this.editedRule.credit_options;
      this.creditOptionExclusionOption = this.editedRule.credit_option_exclusion;
      this.membershipOptionExclusionOption = this.editedRule.membership_option_exclusion;
      this.paymentOptionType = this.editedRule.payment_option_option;
      this.creditTypeChecked = this.editedRule.credit_types_checked;
      this.membershipTypeChecked = this.editedRule.membership_types_checked;

      this.selectedLocations = this.editedRule.visit_locations;
      this.exclusionLocationOption = this.editedRule.visit_location_exclusion;
      this.selectedLocationOption = this.editedRule.visit_location_option;

      this.firstVisitSelection = this.editedRule.first_visit_option;
    }
  },
  computed: {
    showSaveButton() {
      if (!this.timeframeComparison) {
        return false;
      }
      if ((this.timeframeComparison === 'before' || this.timeframeComparison === 'after' || this.timeframeComparison === 'between' || this.timeframeComparison === 'specificDate') && Number.isInteger(this.timeframeValue)) {
        return false;
      }

      if (this.timeframeComparison === 'between' && !this.timeframeValue.length) {
        return false;
      }

      if (!this.selectionVisit) {
        if (
          this.classNameType === 'specific' &&
          !this.selectedClassNames.length
        ) {
          return false;
        }

        if (
          this.classInstructorType === 'specific' &&
          !this.selectedClassInstructors.length
        ) {
          return false;
        }

        if (
          this.classTimeType === 'specific' &&
          !this.selectedClassTimes.length
        ) {
          return false;
        }

        if (this.paymentOptionType === 'specific' && !this.creditTypeChecked && !this.membershipTypeChecked) {
          return false;
        }

        if (this.paymentOptionType === 'specific' && this.creditTypeChecked && this.creditOptionExclusionOption !== 'all') {
          if (!this.selectedCreditOptions.length) {
            return false;
          }
        }

        if (
          this.paymentOptionType === 'specific' &&
          this.membershipTypeChecked && this.membershipOptionExclusionOption !== 'all'
        ) {
          if (!this.selectedMembershipOptions.length) {
            return false;
          }
        }

        if (
          this.selectedLocationOption === 'specific' &&
          !this.selectedLocations.length
        ) {
          return false;
        }
        return true;
      }
      return true;
    },
  },
  methods: {
    async fetchLocations() {
      try {
        const response = await api.getMTLinkedLocations();
        this.locations = response.locations;
      } catch (err) {
        this.$toasted.error(err.message);
      }
    },
    async fetchClassTimes() {
      try {
        const response = await api.MTClassTimes();
        this.classTimes = response.class_times;
      } catch (err) {
        this.$toasted.error(err.message);
      }
    },
    async fetchInstructors() {
      try {
        const response = await api.MTInstructors();
        this.classInstructors = response.instructors;
      } catch (err) {
        this.$toasted.error(err.message);
      }
    },
    async fetchMemberships() {
      try {
        const response = await api.getMTMemberships();
        this.membershipTypes = response.memberships;
      } catch (err) {
        this.$toasted.error(err.message);
      }
    },
    async fetchCredits() {
      try {
        this.creditTypes = await api.MTCreditsWithoutClassPass();
      } catch (err) {
        this.$toasted.error(err.message);
      }
    },
    async fetchClasses() {
      try {
        const response = await api.MTClassTypes();
        this.classNames = response.class_types;
      } catch (err) {
        this.$toasted.error(err.message);
      }
    },
    commitCondition() {
      const payload = {
        condition: {
          uuid: this.condition.uuid,
          name: this.condition.name,
          unique: this.condition.unique,
        },
        has_future_reservation: this.haveSelection,
        count_comparison: this.selection,
        first_bound: this.countOne,
        second_bound: this.countTwo,
        first_visit_comparison: this.timeframeComparison,
        first_visit_value: this.timeframeValue,
        all_first_visits: this.selectionVisit,
        class_names: this.selectedClassNames,
        class_name_exclusion: this.classNameExclusionOption,
        class_name_option: this.classNameType,
        class_instructors: this.selectedClassInstructors,
        class_instructor_exclusion: this.classInstructorExclusionOption,
        class_instructor_option: this.classInstructorType,
        class_times: this.selectedClassTimes,
        class_time_exclusion: this.classTimeExclusionOption,
        class_time_option: this.classTimeType,
        credit_options: this.selectedCreditOptions,
        membership_options: this.selectedMembershipOptions,
        membership_option_exclusion: this.membershipOptionExclusionOption,
        credit_option_exclusion: this.creditOptionExclusionOption,
        payment_option_option: this.paymentOptionType,
        credit_types_checked: this.creditTypeChecked,
        membership_types_checked: this.membershipTypeChecked,
        visit_locations: this.selectedLocations,
        visit_location_exclusion: this.exclusionLocationOption,
        visit_location_option: this.selectedLocationOption,
        first_visit_option: this.firstVisitSelection,
      };

      EventBus.$emit('conditions_selection.commit', payload);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/scss/global/_spacing.scss";
@import "src/scss/global/_new_main.scss";
.field-container {
  margin-top: 28px;
}
.option-body {
  margin-top: 20px;
}
.card-condition {
  background-color: #FFFFFF;
  border: solid 1px var(--xpl-color-gray-400);
  box-shadow: none;
  border-radius: 0;
  cursor: auto;
}
.card-condition:hover {
  background-color: #FFFFFF;
  cursor: auto;
}
.field-step {
  height: 1.5rem;
  width: 1.5rem;
  background-color: $bb-color-blue-v2;
  font-family: $primary-bb-font-family;
  font-size: $base-font-size;
  color: #FFFFFF;
  border-radius: 50%;
  text-align: center;
  margin: $space-base-inline;
}
.field-title {
  font-family: $primary-bb-font-family;
  font-weight: 700;
  font-size: $base-font-size;
  color: $base-color-black-v2;
  line-height: $loose-medium-line-height;
}
.radio-btn {
  margin-top: $space-extra-tight;
  margin-bottom: $space-extra-tight;
}
.field-label-text  {
  display: block;
}
.field-label-text /deep/ .el-radio__label {
  font-family: $primary-bb-font-family !important;
  font-size: $base-loose-font-size !important;
  color: $base-color-black-v2 !important;
  line-height: $loose-line-height !important;
  padding-left: 8px;
}
.field-label-text /deep/ .el-radio__inner {
  background-color: #F2F4F6 !important;
  border: 1px solid #A6B2BF !important;
  height: 16px;
  width: 16px;
}
.field-label-text /deep/  .el-radio__input.is-checked .el-radio__inner::after {
  background-color: var(--xpl-color-secondary-lm) !important;
  width: 12px;
  height: 12px;
}
.condition-container {
  background-color: #F4F8FA;
  height: 100%;
  width: 100%;
}
.field-container:last-of-type{
  padding-bottom: 100px;
}
.sidebar-inner {
  height: 100%;
  position: relative;
  padding-top: 0;
  background-color: #F4F8FA;
  padding-left: 2.625rem;
  padding-right: 2.25rem;
  padding-bottom: 0;
  width: 100%;
}
.save-condition-btn {
  width: 729px;
  margin: 16px 12px 16px 12px;
}
.save-condition-container {
  // box-shadow: 0 -6px 7px 0 rgba(0, 0, 0, 0.05);
  padding: 0;
  margin: 0;
  z-index: 1;
}
.condition-banner-style {
  background-color: #F4F8FA !important;
  margin-top: 13px !important;
  padding-bottom: 0;
}
.membership-check {
  margin-top: 24px;
}
.credit-check {
  display: block;
}
.checks{
  font-family: $primary-bb-font-family;
  font-size: 12px;
  line-height: 16px;
  color: #39414A;
  font-weight: 700;
}
.checks /deep/ .el-checkbox__inner {
  height: 16px;
  width: 16px;
  border: 1px solid #DCDFE6;
  z-index: 0;
}
.checks /deep/ .el-checkbox__label {
  height: 16px;
  width: 16px;
  color: #39414A;
}
.checks /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #F3F7F9;
}
.checks /deep/ .el-checkbox__input.is-checked .el-checkbox__inner::after {
  border: 2px solid #067DFF;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 2px;
  width: 3px;
}
</style>
