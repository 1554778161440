<template>
  <div>
    <div class="display-medium" style="margin-bottom: 2rem;">Waiver Builder</div>
    <div style="display: flex; justify-content: center; margin-bottom: 2rem;" v-if="form.settings.sync.active">
      <el-alert
        style="max-width: 60%; width: 100%;"
        title="Vendor sync is enabled"
        description="Please go to Advanced Options to edit your settings."
        type="warning"
        :closable="false"></el-alert>
    </div>
    <div style="display: flex; justify-content: center; margin-bottom: 2rem;" v-if="!form.settings.sync.active && currentVendor === 'mindbody'">
      <el-alert
        style="max-width: 60%; width: 100%;"
        type="info"
        :closable="false">
          Waiver integration with your booking software is not active. Would you like to <a :href="form.uuid + '?tab=advanced'">activate it</a>?
        </el-alert>
    </div>
    <div class="container">
      <div class="display-small" style="margin-bottom: .875rem;">Standard Questions</div>
        <el-card
          class="field"
          :body-style="{ 'opacity': field.include ? 1 : 0.3 }"
          :key="field.index"
          v-for="field in standardFields"
          v-if="['address_1', 'address_2', 'state', 'city', 'zipcode', 'country'].indexOf(field.id) === -1">
          <StandardField
            :key="field.index"
            :fieldIndex="field.index"
            :placeholder="field.label"
            :include="['email', 'first_name', 'last_name'].indexOf(field.id) > -1 ? null : field.include"
            :fieldName="field.id" />
        </el-card>

        <el-card class="field">
          <label>Address</label>
          <el-card
            :style="'border: none;'"
            :body-style="{ 'opacity': field.include ? 1 : 0.3 }"
            :shadow="'none'"
            :key="field.index"
            v-for="field in standardFields"
            v-if="['address_1', 'address_2', 'state', 'city', 'zipcode', 'country'].indexOf(field.id) > -1">
            <StandardField
              :shadow="'none'"
              :key="field.index"
              :fieldIndex="field.index"
              :placeholder="field.label"
              :include="field.include"
              :fieldName="field.id" />
          </el-card>
        </el-card>

      <div style="margin-top: 1rem;" v-if="syncActive">
        <el-card
          class="field"
          :body-style="{ 'opacity': field.include ? 1 : 0.3 }"
          v-for="field in vendorFields"
          :key="field.index"
          v-if="['emg_name', 'emg_email', 'emg_phone', 'emg_relationship'].indexOf(field.id) === -1">
          <VendorField
            :key="field.index"
            :fieldIndex="field.index"
            :include="field.include"
            :placeholder="field.label"
            :fieldName="field.id"
            :required="field.required" />
        </el-card>

        <el-card class="field" v-if="currentVendor === 'mindbody'">
          <label>Emergency Contact</label>
          <el-card
            :style="'border: none;'"
            :body-style="{ 'opacity': field.include ? 1 : 0.3 }"
            :shadow="'none'"
            :key="field.index"
            v-for="field in vendorFields"
            v-if="['emg_name', 'emg_email', 'emg_phone', 'emg_relationship'].indexOf(field.id) > -1">
              <VendorField
                :shadow="'none'"
                :key="field.index"
                :fieldIndex="field.index"
                :include="field.include"
                :placeholder="field.label"
                :fieldName="field.id"
                :required="field.required" />
            </el-card>
          </el-card>
      </div>

      <div class="display-small" style="margin-bottom: .875rem; margin-top: 2rem;">Custom Questions</div>
        <div class="field" v-for="field in customFields" v-if="!addCustom && !editingField">
          <CustomField
            :key="field.index"
            :fieldIndex="field.index"
            :type="field.type"
            :placeholder="field.label"
            :options="field.options"
            :low="field.low"
            :high="field.high"
            :required="field.required" />
          </div>

      <el-form style="margin-top: 2rem;" @submit.native.prevent>
        <el-form-item v-if="!addCustom">
          <el-button type="secondary" class="xpl-button xpl-button--secondary"  @click.stop="addCustom = true">
            Add a Custom Question to this Waiver
          </el-button>
        </el-form-item>
        <el-form-item v-if="addCustom && !fieldType">
          <label>What kind of question would you like to add?</label>
          <div>
            <el-button size="small" @click.stop="pickFieldType('dropdown')">Dropdown</el-button>
            <el-button size="small" @click.stop="pickFieldType('multi')">Multi Select Checkboxes</el-button>
            <el-button size="small" @click.stop="pickFieldType('input')">Text Input</el-button>
            <el-button size="small" @click.stop="pickFieldType('rating')">Rating</el-button>
          </div>
        </el-form-item>
        <InputText v-if="fieldType === 'input'" :field="editingField" />
        <Dropdown v-if="fieldType === 'dropdown'" :field="editingField" />
        <Multiselect v-if="fieldType === 'multi'" :field="editingField" />
        <Rating v-if="fieldType === 'rating'" :field="editingField" />
      </el-form>

      <el-card style="margin-bottom: 1rem;">
        <el-form style="display: block;" @submit.native.prevent>
          <el-form-item>
            <label>Liability Waiver</label>
            <quill-editor style="width: 100%;" v-model="waiver" ref="myQuillEditor" :options="editorOption" @click.stop></quill-editor>
          </el-form-item>
          <el-form-item>
            <label>Require a checkbox or a signature?</label>
            <div>
              <el-radio-group v-model="signature">
                <el-radio :label="true" border>Signature</el-radio>
                <el-radio :label="false" border>Checkbox</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="saveWaiver">Save</el-button>
          </el-form-item>
        </el-form>
      </el-card>

      <el-card style="margin-top: 1rem;">
        <div class="display-small" style="margin-bottom: 1rem;">Call to Action Design</div>
        <el-form style="display: block;">
          <el-form-item>
            <label>Button Text</label>
            <el-input v-model="template.btn_text" placeholder="Submit" />
          </el-form-item>
          <el-form-item>
            <label>Button Color</label>
            <div>
              <el-color-picker v-model="template.btn_color"></el-color-picker>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="saveCTA">Save</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>
<script>
  import { quillEditor } from 'vue-quill-editor';
  import { updateForm } from '@/api';
  import EventBus from '@/event-bus';
  import StandardField from './Fields/StandardField';
  import VendorField from './Fields/VendorField';
  import CustomField from './Fields/CustomField';
  import InputText from './Fields/CustomFields/InputText';
  import Dropdown from './Fields/CustomFields/Dropdown';
  import Multiselect from './Fields/CustomFields/Multiselect';
  import Rating from './Fields/CustomFields/Rating';

  export default {
    props: ['form'],
    components: {
      StandardField,
      VendorField,
      CustomField,
      InputText,
      Dropdown,
      Multiselect,
      Rating,
      quillEditor,
    },
    data() {
      return {
        currentVendor: this.$store.state.user.current_account.active_vendors[0],
        waiver: '',
        signature: false,
        syncActive: false,
        addCustom: false,
        fieldType: null,
        editingField: null,
        fields: [],
        template: {
          btn_color: '',
          btn_text: 'Submit',
        },
        editorOption: {
          placeholder: 'Add your Liability Waiver',
          modules: {
            toolbar: ['bold', 'italic', 'underline', 'link'],
          },
        },
      };
    },
    mounted() {
      EventBus.$on('forms.update.field', (fieldIndex, value) => {
        this.fields[fieldIndex].include = value;
        this.saveFields();
      });

      EventBus.$on('forms.update.required', (fieldIndex, value) => {
        this.fields[fieldIndex].required = value;
        this.saveFields();
      });

      EventBus.$on('field.commit', (payload) => {
        this.addCustom = false;
        this.fieldType = null;
        this.editingField = null;
        const field = {
          order: this.fields.length + 1,
          id: `custom_${this.slugify(payload.label)}`,
          type: payload.type,
          label: payload.label,
          required: payload.required,
          include: true,
        };

        if (payload.options) {
          field.options = payload.options;
        }

        if (payload.low && payload.high) {
          field.low = payload.low;
          field.high = payload.high;
        }

        if (Object.prototype.hasOwnProperty.call(payload, 'fieldIndex')) {
          this.$set(this.fields, this.fields.splice(payload.fieldIndex, 1, field));
        } else {
          this.fields.push(field);
        }
        this.saveFields();
      });

      EventBus.$on('field.edit', (fieldIndex) => {
        this.addCustom = true;
        this.fieldType = this.fields[fieldIndex].type;
        this.editingField = this.fields[fieldIndex];
        this.editingField.fieldIndex = fieldIndex;
      });

      EventBus.$on('field.remove', (fieldIndex) => {
        this.addCustom = false;
        this.fieldType = null;
        this.editingField = null;
        this.$delete(this.fields, fieldIndex);
        this.saveFields();
      });

      EventBus.$on('field.cancel', () => {
        this.addCustom = false;
        this.fieldType = null;
        this.editingField = null;
      });
    },
    created() {
      const fields = JSON.parse(this.form.fields);
      this.fields = fields.sort((one, two) => one.order - two.order);

      this.syncActive = this.form.settings.sync.active;
      this.waiver = this.form.settings.liability_waiver;
      this.signature = this.form.settings.signature;
      this.template.btn_text = this.form.settings.template.btn_text;
      this.template.btn_color = this.form.settings.template.btn_color;
    },
    beforeDestroy() {
      EventBus.$off('forms.update.field');
      EventBus.$off('forms.update.required');
      EventBus.$off('field.commit');
      EventBus.$off('field.edit');
      EventBus.$off('field.remove');
      EventBus.$off('field.cancel');
    },
    computed: {
      standardFields() {
        const standardFields = this.fields;

        // We do this to maintain true index in fields array
        standardFields.forEach((f, k) => {
          standardFields[k].index = k;
        });

        return standardFields.filter(f => ['email', 'first_name', 'last_name', 'phone', 'address_1', 'address_2', 'city', 'state', 'zipcode', 'country'].indexOf(f.id) > -1);
      },
      customFields() {
        const customFields = this.fields;

        // We do this to maintain true index in fields array
        customFields.forEach((f, k) => {
          customFields[k].index = k;
        });

        return customFields.filter(f => f.id.indexOf('custom_') > -1);
      },
      vendorFields() {
        let vFields = ['password', 'birthday', 'emg_email', 'emg_name', 'emg_phone', 'emg_relationship', 'referral_source'];

        const vendor = this.$store.state.user.current_account.active_vendors[0];

        if (vendor === 'pike13') {
          vFields = vFields
            .filter(
              f => ['password', 'birthday'].indexOf(f) > -1,
            );
        }

        const vendorFields = this.fields;

        // We do this to maintain true index in fields array
        vendorFields.forEach((f, k) => {
          vendorFields[k].index = k;
        });

        return vendorFields.filter(f => vFields.indexOf(f.id) > -1);
      },
    },
    methods: {
      updatedOrder() {
        // eslint-disable-next-line
        const fields = this.fields.map((f, i) => {
          const field = this.fields[i];
          field.order = i;
          return field;
        });

        this.saveFields();
      },
      saveFields() {
        const payload = {
          fields: JSON.stringify(this.fields),
        };
        updateForm(this.form.id, payload).then((response) => {
          this.$message.success(response.message);
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      saveWaiver() {
        const payload = {
          waiver: this.waiver,
          signature: this.signature,
        };

        updateForm(this.form.id, payload).then((response) => {
          this.$message.success(response.message);
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      pickFieldType(type) {
        this.fieldType = type;
        this.addCustom = true;
      },
      saveCTA() {
        const payload = {
          settings: {
            template: this.form.settings.template,
          },
        };

        payload.settings.template.btn_color = this.template.btn_color;
        payload.settings.template.btn_text = this.template.btn_text;

        updateForm(this.form.id, payload).then((response) => {
          this.$message.success(response.message);
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      slugify(str) {
        str = str.replace(/^\s+|\s+$/g, '');
        str = str.toLowerCase();

        const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
        const to = 'aaaaaaeeeeiiiioooouuuunc------';

        // eslint-disable-next-line
        for (let i = 0, l = from.length; i < l; i++) {
          str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str
          .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
          .replace(/\s+/g, '-') // collapse whitespace and replace by -
          .replace(/-+/g, '-'); // collapse dashes

        return str;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .container {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  .container .field + .field {
    margin-top: 1rem;
  }

  .container .el-card .el-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .container .el-card .el-form .field {
    width: 70%;
  }
</style>
