<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div data-test-main-header class="v2__heading">Design this Form</div>
            <div>
              <a data-test-advanced-link href="#advanced" class="advanced" @click.prevent="toggleAdvanced">{{ advanced ? 'Hide' : 'Show' }} Advanced</a>
              <el-button data-test-save-exit type="primary" @click.stop="saveDetails">Save and Exit</el-button>
            </div>
          </div>
          <div class="modal-body">
            <el-container>
              <el-aside width="20%">
                <el-form ref="templateForm" :model="formFields" status-icon>
                  <div data-test-field v-for="field in fieldsComputed">
                    <hr v-if="field.type === 'divider'" />
                    <el-form-item v-if="field.type !== 'divider'" :prop="field.key">
                      <label data-test-field-label>{{ field.label }}</label>

                      <el-input data-test-field-number-input type="number" v-model.number="formFields[field.key]" :placeholder="field.placeholder" v-if="field.type === 'number'" style="width: 100%;" @change="fieldEdited" />

                      <el-input  data-test-field-text-input type="text" v-model="formFields[field.key]" :placeholder="field.placeholder" v-if="field.type === 'text'" style="width: 100%;" @change="fieldEdited" />

                      <el-input data-test-field-textarea-input type="textarea" :rows="2" v-model="formFields[field.key]" :placeholder="field.placeholder" v-if="field.type === 'long-text'" style="width: 100%;" @change="fieldEdited" />

                      <el-select data-test-field-select v-model="formFields[field.key]" :placeholder="field.placeholder" v-if="field.type === 'font-family'" style="width: 100%;" @change="fieldEdited" value-key="value">
                          <el-option v-for="item in fonts"
                              :data-test-field-select-option="item.label"
                              :key="item.value"
                              :label="item.label"
                              :value="item.label">
                          </el-option>
                      </el-select>

                      <el-upload
                      data-test-field-upload-image
                      :multiple="false"
                      :limit="1"
                      :ref="fields.key"
                      accept="image/*"
                      :data="{'key': field.key}"
                      action=""
                      :http-request="changeLogo"
                      v-if="field.type === 'image'">
                        <el-button  data-test-field-upload-image-button slot="trigger" size="small" type="secondary">Change Image</el-button>
                      </el-upload>

                      <div>
                        <el-color-picker data-test-field-color-picker size="medium" show-alpha v-model="formFields[field.key]" v-if="field.type === 'color'" @change="fieldEdited"></el-color-picker>
                      </div>
                      <div data-test-helper-text class="display-caption subdued">{{ field.helper }}</div>
                    </el-form-item>
                  </div>
                </el-form>
              </el-aside>
              <el-main>
                <div data-test-preview-heading class="heading">Preview</div>
                <div class="preview">
                  <div class="browser-container">
                    <div class="circles">
                      <div class="circle"></div>
                      <div class="circle"></div>
                      <div class="circle"></div>
                    </div>
                    <div class="input"></div>
                    <div class="circles">
                      <div class="circle"></div>
                      <div class="circle"></div>
                      <div class="circle"></div>
                    </div>
                  </div>
                  <iframe data-test-preview-iframe  id="iframe" src="" class="preview-rendering" height="100vh"></iframe>
                </div>
              </el-main>
            </el-container>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import _ from 'lodash';
  import EventBus from '@/event-bus';
  import { getTemplateFormPreview, uploadImageForm, updateForm } from '@/api';
  import Fonts from '@/components/FullEmailEditor/EditBlocks/fonts.json';

  export default {
    props: ['form', 'template'],
    data() {
      return {
        advanced: false,
        settings: {
          template: {
            id: this.template.id,
          },
        },
        fonts: Fonts,
        rules: {
          logo: [{ required: true, trigger: 'change', message: 'A logo is required' }],
          height: [{ required: true, trigger: 'change', message: 'A logo height is required' }],
          width: [{ required: true, trigger: 'change', message: 'A logo width is required' }],
          heading: [{ required: true, trigger: 'change', message: 'A heading is required' }],
          description: [{ required: true, trigger: 'change', message: 'A description is required' }],
          bg_image: [{ required: true, trigger: 'change', message: 'A background image is required' }],
        },
        formFields: {},
      };
    },
    created() {
      this.fetchPreview();

      if (this.template && this.template.fields) {
        const fields = JSON.parse(this.template.fields);
        // eslint-disable-next-line
        for (const k in fields) {
          // eslint-disable-next-line
          if (fields[k].hasOwnProperty('key') && fields[k].hasOwnProperty('default')) {
            this.$set(this.formFields, fields[k].key, fields[k].default);
          }

          // eslint-disable-next-line
          if (fields[k].hasOwnProperty('key') && !fields[k].hasOwnProperty('default')) {
            this.$set(this.formFields, fields[k].key, '');
          }
        }
      }

      const settings = this.form.settings;
      if (this.form.settings && settings.template) {
        const keys = Object.keys(settings.template);
        // eslint-disable-next-line
        for (let i = keys.length - 1; i >= 0; i--) {
          this.$set(this.formFields, keys[i], settings.template[keys[i]]);
        }
      }
    },
    computed: {
      fields() {
        if (this.template) {
          return JSON.parse(this.template.fields).sort(f => f.order);
        }

        return null;
      },
      fieldsComputed() {
        if (!this.advanced) {
          return this.fields.filter(f => !f.advanced);
        }

        return this.fields;
      },
    },
    methods: {
      toggleAdvanced() {
        this.advanced = !this.advanced;
      },
      closeModal() {
        EventBus.$emit('close_edit');
      },
      saveDetails() {
        const payload = {
          settings: {
            template: this.formFields,
          },
        };
        payload.settings.template.id = this.template.id;

        updateForm(this.form.id, payload).then(() => {
          EventBus.$emit('form:refresh');
          this.closeModal();
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchPreview() {
        const payload = {
          form_uuid: this.form.uuid,
          template_id: this.template.id,
        };
        getTemplateFormPreview(payload).then((response) => {
          this.$nextTick(() => {
            const iframe = document.getElementById('iframe');
            iframe.contentWindow.document.open();
            iframe.contentWindow.document.write(response.html);
            iframe.contentWindow.document.close();
          });
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fieldEdited: _.debounce(function customFunction() {
        this.$refs.templateForm.validate((valid) => {
          if (!valid) {
            return;
          }

          const payload = {
            settings: {
              template: this.formFields,
            },
          };
          payload.settings.template.id = this.template.id;

          updateForm(this.form.id, payload).then(() => {
            this.fetchPreview();
          }).catch((err) => {
            this.$message.error(err.message);
          });
        });
      }, 200,
      ),
      changeLogo(payload) {
        const key = payload.data.key;

        const formData = new FormData();
        formData.append('image_file', payload.file, payload.file.name);
        formData.append('key', key);
        formData.append('form_id', this.form.id);

        uploadImageForm(formData).then((response) => {
          this.formFields[key] = response.url;
          EventBus.$emit('form:refresh');
          this.fetchPreview();
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal-dialog {
    max-width: 100vw;
    margin: 0;
  }

  .modal-content {
    height: 100vh;
    border-radius: 0;
  }

  .modal-body {
    padding: 0;
    max-height: none !important;
  }

  .modal .modal-content .modal-header {
    font-size: .875rem;
    color: rgb(29, 54, 75);
  }

  .modal .modal-content .modal-header .advanced {
    color: #8b9bac;
    font-size: .75rem;
    margin-right: 1rem;
  }

  .modal-body > .el-container > .el-aside {
    height: calc(100vh - 3.75rem);
    min-height: calc(100vh - 3.75rem);
    padding: 1.5rem;
  }

  .modal-body > .el-container > .el-main {
    height: calc(100vh - 3.75rem);
    min-height: calc(100vh - 3.75rem);
  }

  .heading {
    font-size: .75rem;
    line-height: 1.33;
    color: #617080;
    margin-bottom: 2.5rem;
  }

  .preview {
    width: 100%;
  }

  .preview .el-aside {
    display: flex;
    align-items: center;
    padding: 100px 60px;
  }

  .preview .el-main {
    padding-right: 0px;
    padding-left: 0px;
    background-position: 447px 50%;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .paragraph {
    color: #777;
    font-size: 1rem;
    line-height: 150%;
  }

  .right-side {
    background-size: cover;
    background-repeat: no-repeat;
    background-repeat: fixed;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5));
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right-side > .widget {
    width: 15rem;
    height: 10rem;
    background-color: white;
  }

  .el-form-item {
    margin-bottom: .5rem;
  }

  .widget .el-card {
    width: 100%;
  }

  .widget .el-card .el-card__body {
    padding-bottom: 0;
  }

  .widget .name {
    font-size: 1.125rem;
    font-weight: 700;
    color: rgb(0, 21, 42);
    margin-bottom: .5rem;
  }

  .widget .description {
    font-size: 1rem;
    line-height: 1.5;
    color: rgb(0, 21, 42);
    margin-bottom: 1.5rem;
  }

  .widget .pricing {
    margin-bottom: 2.5rem;
    font-size: 1.25rem;
    font-weight: 700;
    color: rgb(0, 21, 42);
  }

  .widget .pricing .old {
    text-decoration: line-through;
    color: rgb(97, 112, 128);
    margin-right: .5rem;
  }

  .widget .footer {
    margin-top: 1rem;
    padding: 1rem;
    background-color: var(--xpl-color-background-lm);
    font-size: .625rem;
    font-weight: 700;
    color: rgb(97, 112, 128);
    text-align: center;
    margin-right: -20px;
    margin-left: -20px;
  }

  .widget .footer a {
    color: #2E4457;
    text-decoration: underline;
  }

  .display-caption.subdued {
    line-height: 1.25rem;
  }

  .preview-rendering * {
    font-family: initial !important;
    font-size: initial !important;
    font-weight: initial !important;
    color: initial !important;
  }

  .preview-rendering {
    width: 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    transition: all 300ms ease-in-out;
    /*pointer-events: none !important;*/
  }

  iframe.preview-rendering {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: none;
    width: 100%;
    height: auto;
    overflow: hidden;
    overflow-y: scroll;
    height: 40rem;
  }

  iframe.preview-rendering.mobile {
    max-width: 320px;
  }

  iframe.preview-rendering * {
    /*pointer-events: none;*/
  }

  .browser-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 45px;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #dee1e6;
  }

  .circles {
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .circles .circle {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border-radius: 100px;
    background-color: #fff;
  }

  .input {
    height: 100%;
    min-width: 60%;
    border-radius: 40px;
    background-color: hsla(0, 0%, 100%, .54);
  }
</style>
