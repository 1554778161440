<template>
  <div>
    <div class="header">
      <div>
        <a href="#" class="go-back" @click.stop="closePreview">
          <span class="icon icon-arrow-left3"></span>
          Go Back
        </a>
        {{ template.name }}
      </div>
      <button type="button" class="xpl-button xpl-button--primary" @click.stop="selectTemplate">Use This Template</button>
    </div>
    <div class="preview">
      <div v-html="preview_html"></div>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['template'],
    data() {
      return {
        preview_html: null,
      };
    },
    created() {
      const payload = {
        type: this.template.type,
        uuid: this.template.uuid,
        // stupid bug, please solve
        payload: JSON.stringify(JSON.parse(this.template.body)),
      };

      api.getTemplatePreview(payload).then((response) => {
        this.preview_html = response.html;
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    methods: {
      closePreview() {
        EventBus.$emit('template.preview.close');
      },
      selectTemplate() {
        EventBus.$emit('template.preview.select', this.template.id);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .header {
    height: 3rem;
    background-color: #ffffff;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #cdd7e2;
    font-weight: 700;
    line-height: 1.5rem;
    color: #1d364b;
  }

  .header .icon {
    font-size: .75rem;
    margin-right: .25rem;
  }

  .header .go-back {
    margin-right: 1rem;
    color: #586777;
  }

  .preview {
  }
</style>
