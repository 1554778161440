<template>
  <div>
    <el-card class="main">
      <div class="v2__heading">Templates</div>
      <div class="templates" v-loading="loading">
        <el-card
          v-for="(t, index) in templates"
          :key="index"
          :class="['template', settings.selectedTemplate === t.id ? 'active' : '']"
          :body-style="{padding: '0px'}">
          <div class="image" :style="'background-image: url('+t.image+')'"></div>
          <div class="bottom">
            {{ t.name }}
            <el-button size="small" @click="selectTemplate(t)" :disabled="settings.selectedTemplate === t.id">
              {{ settings.selectedTemplate === t.id ? 'Selected' : 'Pick' }}
            </el-button>
          </div>
        </el-card>
      </div>
    </el-card>
    <el-card v-if="settings.selectedTemplate" class="main" :body-style="{padding: '0px'}">
      <div class="header v2__heading">
        <div>Preview</div>
        <el-button size="small" @click.stop="editDesign">Edit Design</el-button>
      </div>
      <div class="preview">
        <iframe id="iframe-small" src="" class="preview-rendering" height="100vh"></iframe>
      </div>
    </el-card>
    <CheckoutTemplateEditDesign v-if="edit" :checkoutflow="checkoutflow" :template="currentTemplate" />
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import CheckoutTemplateEditDesign from './CheckoutTemplateEditDesign';

  export default {
    props: ['checkoutflow'],
    components: {
      CheckoutTemplateEditDesign,
    },
    data() {
      return {
        templates: [],
        settings: {
          selectedTemplate: null,
        },
        edit: false,
        loading: false,
      };
    },
    created() {
      this.fetchTemplates();
      if (this.checkoutflow.settings) {
        const keys = Object.keys(this.checkoutflow.settings);
        // eslint-disable-next-line
        for (let i = keys.length - 1; i >= 0; i--) {
          this.$set(this.settings, keys[i], this.checkoutflow.settings[keys[i]]);
        }
      }

      if (this.settings.selectedTemplate) {
        this.fetchPreview();
      }
    },
    mounted() {
      EventBus.$on('close_edit', () => {
        this.edit = false;
        this.fetchPreview();
        EventBus.$emit('checkoutflow.refresh');
      });
    },
    beforeDestroy() {
      EventBus.$off('close_edit');
    },
    computed: {
      currentTemplate() {
        if (this.settings.selectedTemplate) {
          return this.templates.filter(t => t.id === this.settings.selectedTemplate)[0];
        }

        return null;
      },
    },
    methods: {
      fetchPreview() {
        const payload = {
          checkoutflow_uuid: this.checkoutflow.uuid,
          template_id: this.settings.selectedTemplate,
        };

        api.getLandingPageTemplatePreview(payload, this.$store.getters.currentAccountSubdomain).then((response) => {
          this.$nextTick(() => {
            const iframe = document.getElementById('iframe-small');
            iframe.contentWindow.document.open();
            iframe.contentWindow.document.write(response);
            iframe.contentWindow.document.close();
          });
        });
      },
      selectTemplate(t) {
        this.loading = true;
        const payload = {
          settings: {
            selectedTemplate: t.id,
          },
        };

        api.updateCheckoutFlow(payload, this.checkoutflow.id).then(() => {
          this.loading = false;
          this.settings.selectedTemplate = t.id;
          this.fetchPreview();
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      editDesign() {
        this.edit = true;
      },
      fetchTemplates() {
        api.getLandingPageTemplates().then((response) => {
          this.templates = response.templates;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .templates {
    margin-top: 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
  }

  .templates .template {
    flex: 0 0 25%;
    // box-shadow: 0 2px 4px 0 rgba(217, 231, 240, 0.31);
    border: solid 1px #d9e7f0;
    background-color: #ffffff;
    height: 15rem;
    transition: all 150ms ease-in-out;
    margin-right: .5rem;
    margin-bottom: .5rem;
  }

  .templates .template:last-child {
    margin-right: 0;
  }

  .templates .template:hover,
  .templates .template.active {
    cursor: pointer;
    border-color: var(--xpl-color-secondary-lm);
  }

  .el-card.main + .el-card.main {
    margin-top: 1rem;
  }

  .el-card .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid rgba(217, 231, 240, 1);
  }

  .el-card .preview {
    padding: 1rem;
  }

  .el-card .bottom {
    font-size: .75rem;
    border-top: 1px solid rgba(217, 231, 240, 1);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }

  .el-card .image {
    width: 100%;
    height: 11rem;
    display: block;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .preview-rendering * {
    font-family: initial !important;
    font-size: initial !important;
    font-weight: initial !important;
    color: initial !important;
  }

  .preview-rendering {
    width: 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    transition: all 300ms ease-in-out;
    /*pointer-events: none !important;*/
  }

  iframe.preview-rendering {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: none;
    width: 100%;
    height: auto;
    overflow: hidden;
    height: 40rem;
  }

  iframe.preview-rendering.mobile {
    max-width: 320px;
  }

  iframe.preview-rendering * {
    /*pointer-events: none;*/
  }
</style>
