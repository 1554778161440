h<template>
  <div>
    <div class="alert">
      <img src="https://assets.brandbot.com/public/campaigns/behavior-only.svg" />
      <span v-if="this.mtAction !== 'MT Action'">{{ tagAction === 'tag' ? 'Add tag(s)' : 'Remove tag(s)' }}</span>

      <span v-if="this.mtAction === 'MT Action' &&  tagAction === 'tag' ">Add tag(s)</span>
      <span v-if="this.mtAction === 'MT Action' &&  tagAction === 'untag' ">Add membership(s) or credit(s)</span>
    </div>

    <form @submit.prevent>
      <div class="form-group" v-if="this.mtAction !== 'MT Action'">
        <label>{{ tagAction === 'tag' ? 'Add' : 'Remove' }} the following tag(s)</label>
        <Multiselect v-model="selectedTags" :options="tags" :multiple="true" track-by="id" label="name" :close-on-select="false" placeholder="Select Tag(s)" :searchable="true" :allow-empty="false" :show-labels="false" :taggable="true" tag-placeholder="Press enter to create this tag" @tag="createTag" />
      </div>

       <div class="form-group" v-if="this.mtAction === 'MT Action' && tagAction === 'tag'" >
        <label>Add the following tag(s)</label>
        <Multiselect v-model="selectedTags" :options="tags" :multiple="true" track-by="id" label="name" :close-on-select="false" placeholder="Select Tag(s)" :searchable="true" :allow-empty="false" :show-labels="false" :taggable="true" tag-placeholder="Press enter to create this tag" @tag="createTag" />
      </div>

      <div class="form-group" v-if="this.mtAction === 'MT Action' && tagAction === 'untag'" >
        <label>Select from the following membership(s) and credit(s)</label>
        <Multiselect v-model="theCredit" :options="credits" :multiple="true" track-by="id" label="name" :close-on-select="false" placeholder="Select membership(s) or credit(s)" :searchable="true" :allow-empty="false" :show-labels="false" :taggable="true" tag-placeholder="Press enter to create this tag" @tag="createTag" />
      </div>

      <button v-if="this.mtAction !== 'MT Action'" class="xpl-button xpl-button--primary" :disabled="!selectedTags.length" @click="saveTags">Save</button>
       <button v-if="this.mtAction === 'MT Action'" class="xpl-button xpl-button--primary" @click="saveTags">Save</button>
    </form>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['campaignItemId', 'tagAction', 'selectedTags', 'mtAction'],
    components: {
      Multiselect,
    },
    data() {
      return {
        tags: [],
        credits: [
          {
            id: 66556,
            uuid: 'df35aaad-ef08-49e7-9823-c281a4zzaec40',
            type: 'brandbot',
            name: 'Pure Barre Irvine Monthly Unlimited',
            value: null,
            metadata: null,
            created_at: '2021-03-05 20:12:17',
            updated_at: '2021-03-05 20:12:17',
          },
          {
            id: 66556,
            uuid: 'df35aaad-ef08-445e7-9823-c281a4zzaec40',
            type: 'brandbot',
            name: 'Xponential All Access Membership',
            value: null,
            metadata: null,
            created_at: '2021-03-05 20:12:17',
            updated_at: '2021-03-05 20:12:17',
          },
          {
            id: 66556,
            uuid: 'df35aaad-ef08-49e7-9823-c286a4zzaec40',
            type: 'brandbot',
            name: 'Go All Access Membership',
            value: null,
            metadata: null,
            created_at: '2021-03-05 20:12:17',
            updated_at: '2021-03-05 20:12:17',
          },
        ],
        theCredit: [],
      };
    },
    created() {
      this.fetchTags();
    },
    methods: {
      fetchTags() {
        api.getCustomTags().then((response) => {
          this.tags = response.tags;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      createTag(tag) {
        const payload = {
          type: 'brandbot',
          name: tag,
          id: Math.floor((Math.random() * 10000000)),
        };

        api.addCustomTag(payload).then((response) => {
          this.selectedTags.push(response.tag);
          this.tags.push(response.tag);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      saveTags() {
        const payload = {
          tag_action: this.tagAction,
          tags: this.selectedTags.map(t => t.id),
        };

        api.updateCampaignItem(this.campaignItemId, payload).then((response) => {
          this.$toasted.success(response.message);
          EventBus.$emit('tag_action.close');
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .alert {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 6px;
    background-color: rgba(180, 224, 250, 0.57);
    font-size: .75rem;
    font-weight: 700;
    color: rgb(46, 68, 87);
    padding-left: .75rem;
    padding-right: .75rem;
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .alert img {
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: .75rem;
  }
</style>
