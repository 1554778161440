<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="title">
          <div>
            <span :class="['step-number', enrollmentRuleExists ? 'complete' : '']">
              <span v-if="enrollmentRuleEmpty">1</span>
              <span class="icon icon-checkmark" v-if="enrollmentRuleExists"></span>
            </span>
            Who should enter this auto-campaign?
          </div>
          <div>
            <button class="xpl-button xpl-button--secondary" v-if="enrollmentRuleExists && !selectedOption && !hideButtons" @click.stop="editEnrollmentRule">Edit</button>
          </div>
        </div>

        <div class="options-container" v-if="selectedOption === null && enrollmentRuleEmpty">
          <div class="quick-options">
            <div class="title" v-if="$store.getters.isPike13">Quick Options</div>
            <div class="options" v-if="$store.getters.isPike13">
              <div class="option" @click.stop="selectQuickOption('ff56be42-b88b-4abe-82b3-c47e5d485660')">
                <div class="icon">
                  <img src="https://assets.brandbot.com/public/icons/conditions/5c48a13c-ac7f-4873-9d23-cba7b05f206e.svg" />
                </div>
                <div class="name">
                  <div class="header">Purchase</div>
                  <span>Any contact who purchases a specific product for the first time</span>
                </div>
              </div>
              <div class="option" @click.stop="selectQuickOption('36fd8c92-65ea-4395-8429-c443afad1eae')">
                <div class="icon">
                  <img src="https://assets.brandbot.com/public/icons/conditions/033c6574-fc3d-4ee8-9970-889790c432d6.svg" />
                </div>
                <div class="name">
                  <div class="header">Starts a Plan</div>
                  <span>Any contact who starts a specific plan for the first time</span>
                </div>
              </div>
              <div class="option" @click.stop="selectQuickOption('919d821d-4f0c-4993-b916-f02607fd901f')">
                <div class="icon">
                  <img src="https://assets.brandbot.com/public/icons/conditions/af62480f-6da6-4519-9d24-de4996a30d98.svg" />
                </div>
                <div class="name">
                  <div class="header">First Visit</div>
                  <span>Any contact after their first visit on any or a plan</span>
                </div>
              </div>
              <div class="option" @click.stop="selectQuickOption('1e6a8fb2-8205-41fa-87a8-a46a0c2327dd')">
                <div class="icon">
                  <img src="https://assets.brandbot.com/public/icons/conditions/8cfb3bd2-1742-4be3-9ff6-51a9a42241d8.svg" />
                </div>
                <div class="name">
                  <div class="header">All Time Visits</div>
                  <span>Any contact if they do not make a purchase or visit your business for a given time</span>
                </div>
              </div>
              <div class="option" @click.stop="selectQuickOption('10f161c2-3998-4922-a720-31bfba7744ff')">
                <div class="icon">
                  <img src="https://assets.brandbot.com/public/icons/conditions/d2b29794-384f-4e75-9ac8-5fe1b4d9c92f.svg" />
                </div>
                <div class="name">
                  <div class="header">No interaction in a while</div>
                  <span>Any contact if they do not make a purchase or visit your business for a given time</span>
                </div>
              </div>
              <div class="option" @click.stop="selectQuickOption('737adbd7-24b9-417f-a079-d245cd96a33c')">
                <div class="icon">
                  <img src="https://assets.brandbot.com/public/icons/conditions/202eac6b-5e86-491a-89f6-b306bd101800.svg" />
                </div>
                <div class="name">
                  <div class="header">Created a Profile</div>
                  <span>Any contact after they create a Pike13 profile</span>
                </div>
              </div>
            </div>

            <div class="title" v-if="!($store.getters.isPike13 || $store.getters.isMT || $store.getters.isUIAVendor)">Quick Options</div>
            <div class="options" v-if="!($store.getters.isPike13 || $store.getters.isMT || $store.getters.isUIAVendor)">
              <div class="option" @click.stop="selectQuickOption('5c48a13c-ac7f-4873-9d23-cba7b05f206e')">
                <div class="icon">
                  <img src="https://assets.brandbot.com/public/icons/conditions/5c48a13c-ac7f-4873-9d23-cba7b05f206e.svg" />
                </div>
                <div class="name">
                  <div class="header">Purchase</div>
                  <span>Any contact who purchases a specific pricing option(s) for the first time</span>
                </div>
              </div>
              <div class="option" @click.stop="selectQuickOption('033c6574-fc3d-4ee8-9970-889790c432d6')">
                <div class="icon">
                  <img src="https://assets.brandbot.com/public/icons/conditions/033c6574-fc3d-4ee8-9970-889790c432d6.svg" />
                </div>
                <div class="name">
                  <div class="header">Purchase and Visit</div>
                  <span>Any contact who purchases and visits using a specific pricing option(s) for the first time</span>
                </div>
              </div>
              <div class="option" @click.stop="selectQuickOption('27fb545f-fc3f-4cd0-9b4c-6de1983a3a8b')">
                <div class="icon">
                  <img src="https://assets.brandbot.com/public/icons/conditions/27fb545f-fc3f-4cd0-9b4c-6de1983a3a8b.svg" />
                </div>
                <div class="name">
                  <div class="header">Assigned a Membership</div>
                  <span>Any contact who is assigned a membership type for the first time</span>
                </div>
              </div>
              <div class="option" @click.stop="selectQuickOption('af62480f-6da6-4519-9d24-de4996a30d98')">
                <div class="icon">
                  <img src="https://assets.brandbot.com/public/icons/conditions/af62480f-6da6-4519-9d24-de4996a30d98.svg" />
                </div>
                <div class="name">
                  <div class="header">First Visit</div>
                  <span>Any contact after their first visit on any or a specific pricing option</span>
                </div>
              </div>
              <div class="option" @click.stop="selectQuickOption('d2b29794-384f-4e75-9ac8-5fe1b4d9c92f')">
                <div class="icon">
                  <img src="https://assets.brandbot.com/public/icons/conditions/d2b29794-384f-4e75-9ac8-5fe1b4d9c92f.svg" />
                </div>
                <div class="name">
                  <div class="header">No interaction in a while</div>
                  <span>Any contact if they do not make a purchase or visit your business for a given time</span>
                </div>
              </div>
              <div class="option" @click.stop="selectQuickOption('8cfb3bd2-1742-4be3-9ff6-51a9a42241d8')">
                <div class="icon">
                  <img src="https://assets.brandbot.com/public/icons/conditions/8cfb3bd2-1742-4be3-9ff6-51a9a42241d8.svg" />
                </div>
                <div class="name">
                  <div class="header">All Time Visits</div>
                  <span>Any contact after they visit a certain amount of times ever</span>
                </div>
              </div>
              <div class="option" @click.stop="selectQuickOption('202eac6b-5e86-491a-89f6-b306bd101800')">
                <div class="icon">
                  <img src="https://assets.brandbot.com/public/icons/conditions/202eac6b-5e86-491a-89f6-b306bd101800.svg" />
                </div>
                <div class="name">
                  <div class="header">Created a Profile</div>
                  <span>Any contact after they create a MINDBODY profile</span>
                </div>
              </div>
            </div>
          </div>

          <div class="quick-options">
            <div class="title">Advanced Options</div>
            <div class="options">
              <div class="option" @click.prevent="conditions_sidebar = true">
                <div class="icon">
                  <img src="https://assets.brandbot.com/public/icons/conditions/advanced.svg" />
                </div>
                <div class="name">
                  <div class="header">Custom Rule</div>
                  <span>Use our advanced rule builder to combine any of our 20+ conditions</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="selected-options" v-if="selectedOption">
          <div class="btn-back" @click.prevent="clearSetup">
            Select a different option
          </div>
          <div v-if="!advanced_rule">
            <Purchase v-if="selectedOption === '5c48a13c-ac7f-4873-9d23-cba7b05f206e'" :uuid="selectedOption" :rule="campaign.enrollment_rule.rule" />
            <PurchaseAndVisit v-if="selectedOption === '033c6574-fc3d-4ee8-9970-889790c432d6'" :uuid="selectedOption" :rule="campaign.enrollment_rule.rule" />
            <Membership v-if="selectedOption === '27fb545f-fc3f-4cd0-9b4c-6de1983a3a8b'" :uuid="selectedOption" :rule="campaign.enrollment_rule.rule" />
            <FirstVisit v-if="selectedOption === 'af62480f-6da6-4519-9d24-de4996a30d98'" :uuid="selectedOption" :rule="campaign.enrollment_rule.rule" />
            <LastVisit v-if="selectedOption === 'd2b29794-384f-4e75-9ac8-5fe1b4d9c92f'" :uuid="selectedOption" :rule="campaign.enrollment_rule.rule" />
            <MilestoneVisits v-if="selectedOption === '8cfb3bd2-1742-4be3-9ff6-51a9a42241d8'" :uuid="selectedOption" :rule="campaign.enrollment_rule.rule" />
            <CreatedMindbodyProfile v-if="selectedOption === '202eac6b-5e86-491a-89f6-b306bd101800'" :uuid="selectedOption" :rule="campaign.enrollment_rule.rule" />

            <PikeFirstVisit v-if="selectedOption === '919d821d-4f0c-4993-b916-f02607fd901f'" :uuid="selectedOption" :rule="campaign.enrollment_rule.rule" />
            <PikeLastVisit v-if="selectedOption === '10f161c2-3998-4922-a720-31bfba7744ff'" :uuid="selectedOption" :rule="campaign.enrollment_rule.rule" />
            <PikeProfile v-if="selectedOption === '737adbd7-24b9-417f-a079-d245cd96a33c'" :uuid="selectedOption" :rule="campaign.enrollment_rule.rule" />
            <PikePurchase v-if="selectedOption === 'ff56be42-b88b-4abe-82b3-c47e5d485660'" :uuid="selectedOption" :rule="campaign.enrollment_rule.rule" />
            <PikeHasAPlan v-if="selectedOption === '2d5f2b5c-1620-451a-96fb-0f96bab671e0'" :uuid="selectedOption" :rule="campaign.enrollment_rule.rule" />
            <PikeStartsAPlan v-if="selectedOption === '36fd8c92-65ea-4395-8429-c443afad1eae'" :uuid="selectedOption" :rule="campaign.enrollment_rule.rule" />
            <PikeAllTimeVisits v-if="selectedOption === '1e6a8fb2-8205-41fa-87a8-a46a0c2327dd'" :uuid="selectedOption" :rule="campaign.enrollment_rule.rule" />
          </div>
          <div v-if="advanced_rule">
            <p style="display: flex; justify-content: space-between;">
              The contact meets the following rule
               <button class="xpl-button xpl-button--secondary" type="button" @click="conditions_sidebar = true;">Edit</button>
            </p>
            <RuleEnglishHybrid :rule="campaign.enrollment_rule.rule" :enrollment="true" />
            <div style="margin-top: 1rem;">
              <button type="button" class="xpl-button xpl-button--primary" @click="advanced_rule = false; selectedOption = null">Save</button>
            </div>
          </div>
        </div>

        <div class="english" v-if="enrollmentRuleExists && !selectedOption">
          <EnrollmentEnglish :rule="currentEnrollmentRule" v-if="currentEnrollmentRule.condition.quick" />
          <div v-else>
            <div style="margin-bottom: 1rem;">Any contacts when they meet the following rule:</div>
            <RuleEnglishHybrid :rule="campaign.enrollment_rule.rule"
                              :enrollment="true" />
          </div>
        </div>
        <div class= "options-container">
           <div class="add-contacts">
          <div class="contact-header">Add segments or contacts manually</div>
          <span class="contact-subdued-text">Add a segment that you've already created or add individuals that will be missed by the rule above.</span>
          <el-button class="xpl-button xpl-button--secondary" @click="checkCampaignStatus">Add Contacts</el-button>
           <div style="position: relative">
                    <el-popover
                    placement="bottom"
                    trigger="manual"
                    v-model="contacts_warning_show"
                    visible-arrow=false
                    popper-class="contacts-add-pop"
                    >
                      <div  class="campaign-pop-header">
                        Set your campaign live before adding contacts
                      </div>
                      <span class="padding-pop">
                        <p class="popper-text">Set your campaign and campaign items live before manually enrolling contacts in order for them to receive messages in the campaign!</p>
                        <p class="popper-text">Do you still want to add contacts to the campaign?</p>
                      </span>
                      <div class="pop-footer">
                        <el-button class="cancel" @click="contacts_warning_show = false">Cancel</el-button>
                        <button  class="confirm-pop" type="primary" size="mini" @click="$router.push('/campaigns/' + campaign.uuid + '/enroll'), contacts_warning_show = false" >Add Contacts</button>
                      </div>
                    </el-popover>
            </div>
        </div>
        </div>

        <div class="help">
          <a href="https://docs.brandbot.com/en/articles/3854879-auto-campaigns-entry-rules" target="_blank" class="btn btn-bb-learn-more">
            <span class="material-icons">info_outline</span>
            Learn more about auto-campaign enrollment rule
          </a>
        </div>
      </div>
    </div>
    <ConditionsSidebarHybrid v-if="conditions_sidebar" :contact_list="campaign.enrollment_rule" :enrollment="true" />
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import EnrollmentEnglish from './EnrollmentEnglish';
  import Purchase from './Quick/Purchase';
  import PurchaseAndVisit from './Quick/PurchaseAndVisit';
  import Membership from './Quick/Membership';
  import FirstVisit from './Quick/FirstVisit';
  import LastVisit from './Quick/LastVisit';
  import MilestoneVisits from './Quick/MilestoneVisits';
  import CreatedMindbodyProfile from './Quick/CreatedMindbodyProfile';
  import PikeProfile from './Quick/PikeProfile';
  import PikePurchase from './Quick/PikePurchase';
  import PikeStartsAPlan from './Quick/PikeStartsAPlan';
  import PikeHasAPlan from './Quick/PikeHasAPlan';
  import PikeLastVisit from './Quick/PikeLastVisit';
  import PikeFirstVisit from './Quick/PikeFirstVisit';
  import PikeAllTimeVisits from './Quick/PikeAllTimeVisits';
  import ConditionsSidebarHybrid from '../../Contacts/ContactLists/Conditions/ConditionsSidebarHybrid';
  import RuleEnglishHybrid from '../../Contacts/ContactLists/Conditions/RuleEnglishHybrid';

  export default {
    props: ['campaign', 'allLiveCheck'],
    components: {
      RuleEnglishHybrid,
      ConditionsSidebarHybrid,
      PikeAllTimeVisits,
      PikeFirstVisit,
      PikeLastVisit,
      PikePurchase,
      PikeStartsAPlan,
      PikeHasAPlan,
      PikeProfile,
      EnrollmentEnglish,
      Purchase,
      PurchaseAndVisit,
      Membership,
      FirstVisit,
      LastVisit,
      MilestoneVisits,
      CreatedMindbodyProfile,
    },
    data() {
      return {
        selectedOption: null,
        conditions_sidebar: false,
        advanced_rule: false,
        contacts_warning_show: false,
      };
    },
    mounted() {
      EventBus.$on('contact_list.conditions_sidebar.close', () => {
        this.conditions_sidebar = false;
      });

      EventBus.$on('contact_list.refresh', () => {
        this.selectedOption = null;
        EventBus.$emit('campaign.goal.setup');
      });

      EventBus.$on('condition.commit', (payload) => {
        if (payload.type && payload.type === 'goal') {
          return;
        }

        this.campaign.enrollment_rule.rule = [payload];
        this.saveEnrollmentRule();
      });
    },
    beforeDestroy() {
      EventBus.$off('contact_list.conditions_sidebar.close');
      EventBus.$off('condition.commit');
      EventBus.$off('contact_list.refresh');
    },
    computed: {
      hideButtons() {
        if (this.$store.state.user.is_hq_admin || this.$store.getters.isStaff) {
          return false;
        }

        if (this.campaign.sync_type && this.campaign.sync_type === 'sync_lock') {
          return true;
        }

        if (this.campaign.sync_type && this.campaign.sync_type === 'sync_unlock') {
          return true;
        }

        return false;
      },
      enrollmentRuleExists() {
        return this.campaign.enrollment_rule.rule &&
          this.campaign.enrollment_rule.rule.length &&
          !this.selectedOption;
      },
      enrollmentRuleEmpty() {
        return !this.campaign.enrollment_rule.rule ||
          !this.campaign.enrollment_rule.rule.length ||
          this.selectedOption;
      },
      currentEnrollmentRule() {
        if (!this.campaign.enrollment_rule.rule || !this.campaign.enrollment_rule.rule.length) {
          return null;
        }

        return this.campaign.enrollment_rule.rule[0];
      },
    },
    methods: {
      clearSetup() {
        this.campaign.enrollment_rule.rule = [];
        this.selectedOption = null;
        this.advanced_rule = false;
      },
      saveEnrollmentRule() {
        const payload = {
          contact_list_id: this.campaign.enrollment_rule.id,
          rule: JSON.stringify(this.campaign.enrollment_rule.rule),
        };

        api.saveSmartRule(payload).then((response) => {
          this.$toasted.success(response.message);
          this.selectedOption = null;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      selectQuickOption(option) {
        this.selectedOption = option;
      },
      editEnrollmentRule() {
        if (this.currentEnrollmentRule.condition.quick) {
          this.selectedOption = this.currentEnrollmentRule.condition.uuid;
        } else {
          this.selectedOption = this.currentEnrollmentRule.condition.uuid;
          this.advanced_rule = true;
        }
      },
      checkCampaignStatus() {
        if (this.campaign.turned_on_at && this.allLiveCheck) {
          this.$router.push('/campaigns/' + this.campaign.uuid + '/enroll');
          this.contacts_warning_show = false;
        }
        this.contacts_warning_show = true;
      },
    },
  };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import "src/scss/global/_variables.scss";
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    color: #1D364B;
    line-height: 1.5rem;
    margin-bottom: .25rem;
  }

  .title div {
    display: flex;
    align-items: center;
  }

  .step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 99rem;
    background-color: var(--xpl-color-secondary-lm);
    margin-right: 1rem;
    color: white;
    font-size: .75rem;
  }

  .step-number.complete {
    background-color: white;
    border: 2px solid var(--xpl-color-secondary-lm);
    color: var(--xpl-color-secondary-lm);
  }

  .quick-options .title {
    text-transform: uppercase;
    color: #AFBCCA;
    font-size: .625rem;
  }

  .quick-options .options .option {
    width: 100%;
    display: flex;
    border: 1px solid var(--xpl-color-gray-400);
    border-radius: 6px;
    background-color: #FFFFFF;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: .675rem;
    padding-right: .675rem;
  }

  .quick-options .options .option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .875rem;
    transition: all 300ms ease-in-out;
    margin-bottom: .5rem;
  }

  .quick-options .options .option:hover {
    cursor: pointer;
    border: 1px solid var(--xpl-color-secondary-lm);
    // box-shadow: 0 2px 4px 0 var(--xpl-color-gray-400);
  }

  .quick-options .options .option .icon {
    width: 2.25rem;
    height: 2.25rem;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: .5rem;
    border-radius: 6px;
    background-color: #EFF6FD;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 300ms ease-in-out;
  }

  .quick-options .options .option:hover .icon {
    background-color: var(--xpl-color-secondary-bg-lm);
  }

  .quick-options .options .option .icon img {
    width: 1rem;
    max-width: 1rem;
  }

  .quick-options .options .option .name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: .875rem;
    color: rgba(29, 54, 75, .55);
  }

  .quick-options .options .option .name .header {
    color: rgb(29, 54, 75);
    font-weight: bold;
    margin-right: 1rem;
  }

  .selected-options {
    margin-left: 2.5rem;
    margin-top: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 6px;
    background-color: rgba(235, 244, 250, 0.51);
    // box-shadow: inset 0 3px 15px 0 rgba(139, 155, 172, 0.13);
  }

  .selected-options .btn-back {
    text-decoration: underline;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: rgb(105, 139, 156);
    font-size: .75rem;
    margin-bottom: 1.5rem;
  }

  .selected-options .btn-back .material-icons {
    font-size: .75rem;
    margin-right: .5rem;
    line-height: 1;
  }

  a.small.delete {
    margin-left: .5rem;
  }

  .english {
    padding-left: 2.5rem;
  }

  .options-container {
    margin-left: 2.5rem;
    margin-top: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 6px;
    background-color: rgba(235, 244, 250, 0.51);
    // box-shadow: inset 0 3px 15px 0 rgba(139, 155, 172, 0.13);
  }

  .card.card-body {
    padding-bottom: 1.5rem;
  }

  .help  {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contact-header {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
  }
  .contact-subdued-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #617080;
    margin: 4px 0 16px 0;
    display: block;
  }

  .confirm-pop {
  min-width: 5rem;
  background: var(--xpl-color-secondary-lm);
  border: 1px solid var(--xpl-color-secondary-lm);
  border-radius: 5rem;
  cursor: pointer;
  color: #fff;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.43;
  padding: 6px 1rem;
  outline: none;
  text-align: center;
  text-decoration: none;
  margin-left: 10px;

  &:hover {
    background: linear-gradient(45deg, #3ab8ff 0%, #00cfff 100%);
    background: -webkit-linear-gradient(45deg, #3ab8ff 0%, #00cfff 100%);
    border-color: transparent;
    color: $white-color;
    text-decoration: none;
  }
}

.cancel {
margin: left ;

}

.pop-footer {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  background-color: #f3f7f9;
  font-size: .875rem;
  border-radius: 0 0 3px 3px;
  padding: 15px;
  border-top: 1px solid #e9ecef;
}

.padding-pop  {
  display: block;
  padding: 15px;
}

.popper-text {
  margin-bottom: .5rem;
  word-break: normal;
  text-align: left;
}
</style>
