<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Send a test email
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <label>Email addresses, separated by comma</label>
                <input type="text" v-model.trim="emails" id="emails" class="form-control" placeholder="test1@test.com, test2@test.com" />
              </div>
              <p class="small">
                <strong>Please note:</strong><br>
                Only First Name and Last Name as merge fields will be replaced when sending a test email. All other merge fields will use their fallback.
              </p>
              <p class="small">
                You can see what they look like by saving as a template and using the "Quick Send" option.
              </p>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="button" class="xpl-button xpl-button--primary" @click.stop="sendEmail($event)" :disabled="!emails">Send</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';

  export default {
    props: ['emailId'],
    data() {
      return {
        emails: '',
      };
    },
    methods: {
      closeModal() {
        EventBus.$emit('scheduled_email.test_email.close');
      },
      sendEmail(event) {
        if (!this.emails) {
          return;
        }

        const target = event.srcElement || event.target;
        const originalInner = target.innerHTML;

        target.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';
        target.disabled = true;


        const payload = {
          emails: this.emails,
        };

        api.sendTestEmail(this.emailId, payload).then((response) => {
          this.$toasted.success(response.message);
          target.innerHTML = originalInner;
          this.closeModal();
        }).catch((err) => {
          this.$toasted.error(err.message);
          target.innerHTML = originalInner;
          target.disabled = false;
        });
      },
    },
  };
</script>
