<template>
  <div>
    <div class="display-medium" style="margin-bottom: 2rem;">Marketing Tracking</div>
    <el-card v-loading="isLoading">
      <el-form>
        <el-form-item>
          <label>Facebook Pixel</label>
          <el-input v-model.trim="settings.pixel.id" placeholder="Facebook Pixel ID" />
          <div class="display-caption subdued">For more help, head to the <a href="https://developers.facebook.com/docs/facebook-pixel/implementation" target="_blank">Facebook Pixel Implementation documentation</a>.</div>
        </el-form-item>
        <el-form-item>
          <label>What events would you like to track on your Hosted Waiver?</label>
          <div>
            <el-checkbox v-model="settings.pixel.pageview" label="PageView Event"></el-checkbox>
            <el-checkbox v-model="settings.pixel.lead" label="Lead Event"></el-checkbox>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="saveTracking">Save</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
  import { updateForm } from '@/api';

  export default {
    props: ['form'],
    data() {
      return {
        isLoading: false,
        settings: {
          pixel: {
            id: null,
            pageview: true,
            lead: true,
          },
        },
      };
    },
    created() {
      const settings = this.form.settings;
      Object.keys(settings).forEach((k) => {
        if (k === 'pixel') {
          Object.keys(settings[k]).forEach((s) => {
            this.$set(this.settings.pixel, s, settings[k][s]);
          });
        }
      });
    },
    methods: {
      saveTracking() {
        this.isLoading = true;
        const payload = {
          settings: {
            pixel: {
              id: this.settings.pixel.id,
              pageview: this.settings.pixel.pageview,
              lead: this.settings.pixel.lead,
            },
          },
        };

        updateForm(this.form.id, payload).then((response) => {
          this.isLoading = false;
          this.$message.success(response.message);
        }).catch((err) => {
          this.isLoading = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
