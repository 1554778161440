<template>
  <el-card v-loading="loading">
    <div class="display-medium">SheerID Settings</div>
    <el-form>
      <el-form-item>
        <label>SheerID Access Token</label>
        <el-input v-model="accessToken" placeholder="Access Token"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click="updateSettings">Save Changes</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
  import {
    updateSheerIDSettings,
    getSheerIDSettings,
  } from '@/api';

  export default {
    data() {
      return {
        loading: false,
        accessToken: null,
      };
    },
    created() {
      this.getSettings();
    },
    methods: {
      getSettings() {
        this.loading = true;

        getSheerIDSettings().then((response) => {
          this.loading = false;
          this.accessToken = response.accessToken;
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      updateSettings() {
        const payload = {
          accessToken: this.accessToken,
        };

        this.loading = true;

        updateSheerIDSettings(payload).then((response) => {
          this.loading = false;
          this.$message.success(response.message);
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .display-medium {
    margin-bottom: 2rem;
  }
</style>
