<template>
  <div>
    <div data-test-header class="display-medium" style="margin-bottom: 2rem;">Form Advanced Options</div>
    <el-card v-loading="syncLoading" v-if="this.$store.getters.currentUserActiveVendor === 'mindbody'">
      <div data-test-integration-header class="display-small" style="margin-bottom: 1rem;">Integration with Booking Software</div>
      <el-form>
        <el-form-item>
          <label data-test-integration-label>Do you want to create or update customers in your Booking Software?</label>
          <div>
            <el-radio data-test-integeration-radio-true v-model="sync.active" :label="true" border>Yes, update or create them</el-radio>
            <el-radio data-test-integration-radio-false v-model="sync.active" :label="false" border>No, keep all information in {{$store.getters.brandName}} only</el-radio>
          </div>
        </el-form-item>
        <el-form-item v-if="sync.active">
          <div class="options">
            <label data-test-sync-label >Which fields would you like to sync?</label>
            <div>
              <el-checkbox data-test-sync-phone v-model="fields.phone" @change="syncFields" label="Phone Number"></el-checkbox>
            </div>
            <div>
              <el-checkbox data-test-sync-address v-model="fields.address" @change="syncFields" label="Address"></el-checkbox>
            </div>
            <div v-if="this.$store.getters.currentUserActiveVendor === 'mindbody'">
              <el-checkbox data-test-sync-emergency v-model="fields.emg_contact" @change="syncFields" label="Emergency Contact"></el-checkbox>
            </div>
            <div v-if="this.$store.getters.currentUserActiveVendor === 'mindbody'">
              <el-checkbox data-test-sync-referral v-model="fields.referral_source" @change="syncFields" label="Referral Source"></el-checkbox>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button data-test-sync-save type="primary" @click.stop="saveSync">Save</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card v-if="sync.active && this.$store.getters.currentUserActiveVendor === 'mindbody' && $store.state.user.is_super">
      <div data-test-mindbody-header-admin class="display-small" style="margin-bottom: 1rem;">Required MINDBODY Fields</div>
      <p><b data-test-required-mindbody-admin >This is only visible to {{$store.getters.brandName}} Admin for now.</b></p>
      <p>These fields are required in your MINDBODY setup when creating a profile. Please make sure they are also present on your Form.</p>
      <el-table  data-test-mindbody-table :data="requiredFields" style="width: 100%">
        <el-table-column prop="name"></el-table-column>
      </el-table>
    </el-card>
    <el-card  v-loading="autoAccountCreationLoading" v-if="this.$store.getters.currentUserActiveVendor === 'marianatek' && showMTAdvancedOptions">
       <div data-test-auto-account-creation-header class="display-small" style="margin-bottom: 1rem;">MarianaTek Integration</div>
      <el-form>
        <el-form-item>
          <label data-test-mt-sync-label>Do you want to create an account in Mariana Tek when a customer fills out this form?</label>
          <div>
            <el-radio name="account-creation-auto" data-test-auto-account-creation-radio-true @change="toggleMTVendorSync" v-model="sync.active" :label="true" border>Yes, create an account</el-radio>
            <el-radio name="account-creation-auto" data-test-auto-account-creation-radio-false @change="toggleMTVendorSync" v-model="sync.active" :label="false" border>No, only create a contact in {{$store.getters.brandName}}</el-radio>
          </div>
        </el-form-item>
        <el-form-item v-if="sync.active">
          <label data-test-auto-account-creation-label>Do you want the customer to set their home location?</label>
          <div>
            <el-radio data-test-auto-account-creation-home-location-radio-false @change="toggleMTVendorSync" v-model="sync.home_location.default" :label="false" border>Yes, show a field for location</el-radio>
            <el-radio data-test-auto-account-creation-home-location-radio-true  @change="toggleMTVendorSync" v-model="sync.home_location.default" :label="true" border>No, choose a default location</el-radio>
          </div>

          <div v-if="sync.home_location.default && this.autoAccountCreationLocations.length >= 1" class="options">
            <label data-test-default-home-location-label>Which location would you like to default to?</label>

            <el-select v-if="sync.home_location.default && this.autoAccountCreationLocations.length >= 1" data-test-default-home-location-select v-model="sync.home_location.default_location_id" placeholder="Choose a home location" style="width: 100%">
               <el-option  :data-test-default-home-location-option="location.id" v-for="location in autoAccountCreationLocations" :key="location.id" :label="location.name" :value="location.id"></el-option>
            </el-select>
          </div>
        </el-form-item>
                  <div v-if="sync.active"
        data-test-auto-account-creation-comp-credit-header
        class="display-extra-small"
        style="margin-bottom: 1rem"
      >
        Mariana Tek Action
      </div>
       <el-form-item v-if="sync.active" style="margin-bottom: 2px">
          <label data-test-comped-credit-label
            >Do you want to automatically add a comped credit when a new account is created?</label
          >
           <div :style="{ 'margin-bottom': !settings.compedCreditActiveStatus ? '22px' :0 }" >
            <el-radio
              name="account-creation-comped-credit"
              data-test-auto-account-creation-comped-credit-radio-true
              v-model="settings.compedCreditActiveStatus"
              :label="true"
              border
              >Yes, automatically add a comped credit</el-radio
            >
            <el-radio
              name="account-creation-auto"
              data-test-auto-account-creation-comped-credit-radio-false
              v-model="settings.compedCreditActiveStatus"
              :label="false"
              border
              >No, do not add anything to their account</el-radio
            >
          </div>
            <div v-if="settings.compedCreditActiveStatus"  class="options">
            <label data-test-comped-credit-select-label
              >Which credit would you like to comp to their account?</label
            >
            <div>
              <el-select
                data-test-comped-credit-select
                v-model="settings.creditAction.creditId"
                placeholder="Choose a credit"
                style="width: 100%"
                 v-if="
                this.autoAccountCreationCredits.length >= 1
              "
              >
                <el-option
                  :data-test-comped-credit-select-option="
                    credit.id
                  "
                  v-for="credit in autoAccountCreationCredits"
                  :key="credit.id"
                  :label="credit.name"
                  :value="credit.id"
                ></el-option>
              </el-select>
            </div>
          </div>
        </el-form-item>
        <el-form-item v-if="settings.compedCreditActiveStatus && sync.active">

          <div>
            <label data-test-comped-credit-amount-label
              >How many credits?</label
            >
            <div style="line-height: 27px;">
              <el-input-number
              data-test-comped-credit-amount-input
                name="credit-input"
                size="mini"
                controls-position="right"
                v-model="settings.creditAction.quantity"
                :min="1"
                label="Quantity"
              ></el-input-number>
            </div>
          </div>
          <div>
            <label data-test-comped-credit-expiration-label
              >When do you want the credits to expire?</label
            >
            <!-- When a user mouses over the credit expiration date field -->
            <!-- That mouse event will trigger this method to decrease the minimum value to 1 -->
            <div v-on:mouseover="updateCompedCreditDaysUntilExpiration">
              <!-- Element ui's  'min' property sets the minimum value on the input and acts as the default value -->
              <!-- We want to set the minimum value to 7 on a fresh form with no comped credit settings saved -->
                <el-input-number
                :min="compedCreditDaysUntilExpiration"
                  size="mini"
                  controls-position="right"
                  name="credit-date-input"
                  v-model="settings.creditAction.daysUntilExpiration"
                  placeholder="1"
                ></el-input-number>
                 <span class="desc-label">days from the date the credit is added</span>
            </div>
            <label data-test-comped-credit-reason-label>What is the reason for the comped credit?</label>
          </div>
          <el-input
           data-test-comped-credit-reason-input
            placeholder="Please input a reason"
            v-model="settings.creditAction.reason"
          ></el-input>
          <div style="height: 8px" ></div>
        </el-form-item>
        <el-form-item>
          <el-button data-test-redirect-save type="primary"  :disabled="disableSaveMTSyncIntegration" @click.stop="saveAutoAccountCreationSync">Save</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card v-loading="redirectLoading">
      <div data-test-redirect-header class="display-small" style="margin-bottom: 1rem;">Redirection</div>
      <el-form>
        <el-form-item>
          <label data-test-redirect-label>Do you want to send a customer to another page after they complete the Form?</label>
          <div>
            <el-radio data-test-redirect-radio-true v-model="redirect.active" :label="true" border>Yes, redirect them</el-radio>
            <el-radio data-test-redirect-radio-false v-model="redirect.active" :label="false" border>No, do not send them to another page</el-radio>
          </div>
        </el-form-item>
        <el-form-item v-if="redirect.active">
          <div class="options">
            <label data-test-redirect-to-label>Redirect them to</label>
            <el-select data-test-redirect-select v-model="redirect.type" placeholder="Redirect To" style="width: 100%">
              <el-option data-test-redirect-url-option :key="'url'" :label="'URL'" :value="'url'"></el-option>
              <el-option data-test-redirect-pdf-option :key="'pdf'" :label="'PDF File'" :value="'pdf'"></el-option>
            </el-select>
            <div v-if="redirect.type === 'url'">
              <label data-test-url-label>What is the URL of the webpage?</label>
              <el-input data-test-url-input v-model="redirect.url" placeholder="URL" />
            </div>
            <div style="margin-top: 2rem;" v-if="redirect.type === 'pdf'">
              <el-row :span="24">
                <el-col :span="12">
                  <label data-test-pdf-upload-label >Upload your PDF File below</label>
                  <el-upload
                      data-test-pdf-upload
                      :multiple="false"
                      :limit="1"
                      accept="application/pdf"
                      action=""
                      :http-request="uploadFile" >
                      <el-button size="small" type="primary">Upload</el-button>
                  </el-upload>
                </el-col>
                <el-col :span="12">
                  <label data-test-pdf-select-label>Select an existing PDF</label>
                  <el-select data-test-pdf-select-input v-model="redirect.url" placeholder="PDF File" style="width: 100%;">
                    <el-option data-test-pdf-select-input v-for="item in saved_pdfs" :key="item.id" :label="item.name" :value="item.url"></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-form-item>
        <el-form-item v-if="!redirect.active">
          <div class="options">
            <label data-test-message-label >What message would you like to show?</label>
            <el-input data-test-message-input v-model="redirect.message" placeholder="You have successfully completed this form!" />
          </div>
        </el-form-item>
        <el-form-item>
          <el-button data-test-redirect-save type="primary" @click.stop="saveRedirect">Save</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card v-loading="notificationLoading">
      <div data-test-notification-header class="display-small" style="margin-bottom: 1rem;">Notifications</div>
      <el-form>
        <el-form-item>
          <label data-test-notification-label >Send a notification to the email(s) below every time a contact fills out this form</label>
          <el-input data-test-notification-email-input v-model="notification.email" placeholder="Email" />
          <div data-test-notification-email-sub-text class="small text-muted">Separate multiple emails using commas.</div>
        </el-form-item>
        <el-form-item>
          <el-button data-test-notification-save type="primary" class="xpl-button xpl-button--primary" @click.stop="saveNotification">Save</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import { enviromentVariables } from '@/values/enviromentValues';
  import { accountIds } from '@/values/accountIdValues'; // eslint-disable-line
  import axios from 'axios';

  export default {
    props: ['form'],
    data() {
      return {
        saved_pdfs: [],
        notificationLoading: false,
        notification: {
          active: true,
          email: '',
        },
        redirectLoading: false,
        redirect: {
          active: false,
          message: 'You have successfully completed your form!',
          url: null,
          type: 'url',
        },
        syncLoading: false,
        sync: {
          active: true,
          fields: [],
          home_location: {
            default: false,
            default_location_id: '',
          },
        },
        settings: {
          compedCreditActiveStatus: false,
          creditAction: {
            creditId: null,
            quantity: null,
            daysUntilExpiration: null,
            reason: null,
          },
        },
        fields: {
          first_name: false,
          last_name: false,
          phone: false,
          address: false,
          emg_contact: false,
          referral_source: false,
        },
        mariana_tek_advanced_settings_fields: null,
        requiredFields: [],
        autoAccountCreationLocations: [],
        autoAccountCreationLoading: false,
        linkedLocationsLoading: true,
        creditsLoading: true,
        autoAccountCreationCredits: [],
        fetchLinkedLocationsSuccessful: true,
        compedCreditDaysUntilExpiration: 1,
      };
    },
    created() {
      const fieldz = JSON.parse(this.form.fields);
      this.mariana_tek_advanced_settings_fields = fieldz.sort((one, two) => one.order - two.order);
      this.fetchPDFs();
      this.fetchVendorSpecificInfo();
      const settings = this.form.settings;
      Object.keys(settings).forEach((k) => {
        if (k === 'sync') {
          Object.keys(settings[k]).forEach((s) => {
            this.$set(this.sync, s, settings[k][s]);
            if (s === 'fields') {
              settings[k][s].forEach((f) => {
                this.fields[f] = true;
              });
            }
          });
        }
        if (k === 'redirect') {
          Object.keys(settings[k]).forEach((s) => {
            this.$set(this.redirect, s, settings[k][s]);
          });
        }
        if (k === 'notification') {
          Object.keys(settings[k]).forEach((s) => {
            this.$set(this.notification, s, settings[k][s]);
          });
        }
        if (k === 'creditAction') {
          Object.keys(settings[k]).forEach((s) => {
            this.$set(this.settings.creditAction, s, settings[k][s]);
            // check if settings were saved to expand the comped credit setting block
            if (settings[k][s] != null) {
              this.$set(this.settings, 'compedCreditActiveStatus', true);
            }
          });
        }
      });
      const saveCompedCreditSettings = !this.form.settings.creditAction;
      if (saveCompedCreditSettings) {
        this.compedCreditDaysUntilExpiration = 7;
      }
    },
    methods: {
      updateCompedCreditDaysUntilExpiration() {
        this.compedCreditDaysUntilExpiration = 1;
      },
      toggleMTVendorSync() {
        // Set the home location field with the right settings
        this.findAndSetAdvancedSettings('home_location', { required: this.sync.active, include: this.sync.active });
        // Set the terms and services with the right settings
        this.findAndSetAdvancedSettings('mt_terms_and_service', {
          include: this.sync.active,
          required: this.sync.active,
        });
        // Set the required standard fields to be included
        if (this.sync.active) {
          this.findAndSetAdvancedSettings('first_name', { include: this.sync.active });
          this.findAndSetAdvancedSettings('last_name', { include: this.sync.active });
          this.findAndSetAdvancedSettings('phone', { include: this.sync.active });
        }
         // Set the unrequired fields with the right settings
        this.findAndSetAdvancedSettings('birthday', { include: this.sync.active });
        this.findAndSetAdvancedSettings('mt_opt_in_email', { required: false, include: this.sync.active });
        this.findAndSetAdvancedSettings('mt_opt_in_sms', { required: false, include: this.sync.active });
        //  eslint-disable-next-line
        // Set homelocation field to not be included/required when a default location is chosen.(wont appear in form)
        if (this.sync.home_location.default && this.sync.active) {
          this.findAndSetAdvancedSettings('home_location', { required: false, include: false });
        }
        // Set the sync settings to be saved to the db
        this.sync = {
          active: this.sync.active,
          home_location: {
            default: this.sync.home_location.default,
            default_location_id: this.sync.home_location.default_location_id,
            options: this.autoAccountCreationLocations,
          },
          vendor: this.$store.getters.currentUserActiveVendor,
        };
      },
      findAndSetAdvancedSettings(fieldId, changes) {
        const settings = this.mariana_tek_advanced_settings_fields
          .find(field => field.id === fieldId);
        Object.assign(settings, changes);
        if (fieldId === 'home_location') {
          // reformat the location options
          const renameOptionLocationNameArray = this.autoAccountCreationLocations.map((item) => {//eslint-disable-line
            return { label: item.name, id: item.id };
          });
          Object.assign(settings.options, renameOptionLocationNameArray);
        }
      },
      async fetchVendorSpecificInfo() {
        if (this.$store.getters.currentUserActiveVendor === 'mindbody') {
          await this.fetchMBORequiredFields();
        }
        if (this.$store.getters.currentUserActiveVendor === 'marianatek') {
          await this.fetchLinkedLocations();
          await this.getMTCoreCredits();
        }
      },
      async fetchLinkedLocations() {
        try {
          const response = await api.getMTLinkedLocations();
          this.autoAccountCreationLocations = response.locations;
          this.linkedLocationsLoading = false;
        } catch (err) {
          this.$toasted.error('Failed to load locations.');
          this.linkedLocationsLoading = false;
          this.fetchLinkedLocationsSuccessful = false;
        }
      },
      async getMTCoreCredits() {
        const enviroment = enviromentVariables.apiUrl === 'staging' ? 'staging-api' : 'api';
        try {
          const accountId = this.$store.state.user.current_account.id;
          const response = await axios.get(`https://${enviroment}.brandbot.cloud/vendor/marianatek/accounts/${accountId}/credits/core`); // eslint-disable-lin
          this.autoAccountCreationCredits = response.data.credits;
          this.creditsLoading = false;
        } catch (err) {
          this.$toasted.error('Failed to load credits.');
          this.creditsLoading = false;
        }
      },
      async saveAutoAccountCreationSync() {
        this.autoAccountCreationLoading = true;
        const payload = { settings:
        {
          sync: this.sync,
          creditAction: {
            creditId: this.settings.creditAction.creditId,
            quantity: this.settings.creditAction.quantity,
            daysUntilExpiration: this.settings.creditAction.daysUntilExpiration,
            reason: this.settings.creditAction.reason,
          },
        },
          fields: JSON.stringify(this.mariana_tek_advanced_settings_fields),
        };
        try {
          const response = await api.updateForm(this.form.id, payload);
          this.autoAccountCreationLoading = false;
          this.$message.success(response.message);
          EventBus.$emit('form:refresh');
        } catch (err) {
          this.autoAccountCreationLoading = false;
          this.$toasted.error(err.message);
        }
      },
      async fetchMBORequiredFields() {
        try {
          const response = await api.getFormMBORequiredFields();
          this.requiredFields = response.required_fields;
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      async fetchPDFs() {
        try {
          const response = await api.getSavedPdfs();
          this.saved_pdfs = response.pdfs;
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      async uploadFile(payload) {
        const formData = new FormData();
        formData.append('pdf_file', payload.file, payload.file.name);
        formData.append('type', 'pdf');
        try {
          const response = await api.uploadAssetImage(formData);
          this.$message.success(response.message);
          this.redirect.url = response.url;
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      syncFields() {
        this.$set(this.sync, 'fields', Object.keys(this.fields).filter(k => this.fields[k] === true));
      },
      async saveSync() {
        this.syncLoading = true;
        const payload = {
          settings: {
            sync: {
              active: this.sync.active,
              fields: this.sync.fields,
              vendor: this.$store.getters.currentUserActiveVendor,
            },
          },
          fields: this.form.settings.fields,
        };
        let vFields;
        if (this.$store.getters.currentUserActiveVendor === 'mindbody') {
          vFields = ['password', 'birthday', 'emg_email', 'emg_name', 'emg_phone', 'emg_relationship', 'referral_source'];
        }
        payload.fields.forEach((f, k) => {
          if (vFields.indexOf(f.id) > -1) {
            payload.fields[k].include = this.sync.active;
          }
        });
        payload.fields = JSON.stringify(payload.fields);
        try {
          const response = await api.updateForm(this.form.id, payload);
          this.syncLoading = false;
          this.$message.success(response.message);
          EventBus.$emit('form:refresh');
        } catch (err) {
          this.syncLoading = false;
          this.$toasted.error(err.message);
        }
      },
      async saveRedirect() {
        this.redirectLoading = true;
        const payload = {
          settings: {
            redirect: {
              active: this.redirect.active,
              url: this.redirect.url,
              message: this.redirect.message,
            },
          },
        };
        try {
          const response = await api.updateForm(this.form.id, payload);
          this.redirectLoading = false;
          this.$message.success(response.message);
        } catch (err) {
          this.redirectLoading = false;
          this.$toasted.error(err.message);
        }
      },
      async saveNotification() {
        this.notificationLoading = true;
        const payload = {
          settings: {
            notification: {
              active: this.notification.active,
              email: this.notification.email,
            },
          },
        };
        try {
          const response = await api.updateForm(this.form.id, payload);
          this.notificationLoading = false;
          this.$message.success(response.message);
        } catch (err) {
          this.notificationLoading = false;
          this.$message.error(err.message);
        }
      },
    },
    computed: {
      disableSaveCompedCreditNotSet() {
        // Check the comped credit fields to validate if the settings are completely filled out
        const syncOnCompedCreditOn = this.sync.active && this.settings.compedCreditActiveStatus;
        const isCreditSelected = this.settings.creditAction.creditId === null;
        const isCreditExpirationSet = this.settings.creditAction.daysUntilExpiration === null;
        const isCreditReasonSet = this.settings.creditAction.reason === null || this.settings.creditAction.reason === '';
        return (isCreditSelected || isCreditExpirationSet || isCreditReasonSet)
        && syncOnCompedCreditOn;
      },
      showMTAdvancedOptions() {
        // MT needs to be the active vendor
        const vendor = this.$store.getters.currentUserActiveVendor;
        if (vendor !== 'marianatek') return false;
        // We check if the form is legacy from the absence of home_location field
        const isFormLegacyMT = this.form.settings.fields.find(field => field.id === 'home_location');
        // We only want to show advanced options for staging + taurfit in production for now.
        const isStaging = enviromentVariables.apiUrl === 'staging';
        const accountId = this.$store.state.user.current_account.id;
        const TAURFIT_ACCOUNT_ID_PROD = accountIds.testingAccounts.includes(accountId);
        return (isStaging || TAURFIT_ACCOUNT_ID_PROD) && isFormLegacyMT;
      },
      canSaveDefaultHomeLocationSetting() {
        // if the customer has MT contact sync turned on
        // and they have a default home location set,
        // let's make sure they are selecting a location before save.
        const isHomeLocationSet = this.sync.home_location.default &&
        !this.sync.home_location.default_location_id;
        return isHomeLocationSet && this.sync.active;
      },
      disableSaveMTSyncIntegration() {
        if(this.canSaveDefaultHomeLocationSetting || this.disableSaveCompedCreditNotSet
          || this.linkedLocationsLoading || !this.fetchLinkedLocationsSuccessful) {
          return true;
        }
        return false;
      }
    },
  };
</script>
<style lang="scss" scoped>
  .el-card + .el-card {
    margin-top: 2rem;
  }
  .options {
    padding: 1.25rem;
    background-color: #f5f8fb;
    margin-top: .75rem;
  }
</style>
