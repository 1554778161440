<template>
  <div>
    <div class="header">
      <h2>Franchisees</h2>
      <el-button type="warning" @click.stop="applyBranding" :loading="loading" v-if="$store.getters.isStaff">Apply Branding to Franchisees</el-button>
      <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="addNewFranchisee">Add new Franchisee</el-button>
      <NewFranchisee v-if="franchisee_modal" :franchise="$store.state.user.current_account" />
    </div>
     <el-row :span="24" class="top-banner" v-if="$store.state.user.current_account.active_vendors.indexOf('marianatek') > -1 && [1288, 2740, 2956].indexOf(this.$store.state.user.current_account.id) > -1">
      <el-col :span="24">
        <el-card  v-if="!turfLoading" :class="[is_timeline_turfed ? '' : 'inactive']">
          <div class="display-small turfed-flex">NEW - Turfed Contact Timelines
            <span></span>
             <el-button type="secondary"
              size="small"
              class="turfed-button xpl-button xpl-button--secondary"
              @click="updateTurfedTimeline(false)"
              v-if="is_timeline_turfed"

              >Turn Off for My Account</el-button
            >
            <el-button type="secondary"
               class="turfed-button xpl-button xpl-button--secondary"
              size="small"
              @click="updateTurfedTimeline(true)"
              v-if="!is_timeline_turfed"

              >Turn On for My Account</el-button
            >
          </div>

          <div class="inner">
            <span v-if="is_timeline_turfed"
              >Timelines Displaying Events for Connected Locations Only</span
            >
            <span v-if="!is_timeline_turfed"
              >Timelines Displaying Events for All Locations</span
            >

          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-card v-for="fr in franchisees" :key="fr.id">
      <el-row :span="24">
        <el-col :span="12">
          <div class="display-small">{{ fr.name }}</div>
          <div style="margin-top: 1rem;" class="display-caption subdued">Details</div>

          <div v-if="fr.site_id" class="display-caption">
            MINDBODY Site ID: {{ fr.site_id }}
            <div v-if="fr.confirmed" class="text-success">Active Sync</div>
            <div v-if="!fr.confirmed" class="text-danger">Not Synced</div>
          </div>
          <div class="display-caption">
            <span v-if="fr.sms" class="text-success">SMS Enabled</span>
            <span v-if="!fr.sms" class="text-danger">SMS Not Enabled</span>
          </div>

          <div class="display-caption muted" v-if="$store.getters.isStaff">
            <el-button type="secondary" class="xpl-button xpl-button--secondary"  size="mini" @click.stop="setCorpPaid(fr.id)" :loading="corpPaidLoading">Set as Corporate Paid</el-button>
            <el-button type="warning" size="mini" @click.stop="applyFooter(fr)" :loading="fr.loading">Apply Footer from HQ</el-button>
          </div>
        </el-col>
        <el-col :span="12" class="text-right">
          <el-button type="secondary" class="xpl-button xpl-button--secondary" @click="switchAccount(fr.id)" :loading="switchLoading">Go to Account</el-button>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
  import * as api from '@/api';
  import EventBus from '@/event-bus';
  import NewFranchisee from './NewFranchisee';

  export default {
    components: {
      NewFranchisee,
    },
    data() {
      return {
        turfLoading: true,
        franchisee_modal: false,
        corpPaidLoading: false,
        switchLoading: false,
        applyFooterLoading: false,
        franchisees: [],
        is_timeline_turfed: null,
      };
    },
    mounted() {
      EventBus.$on('new_franchisee.close', () => {
        this.franchisee_modal = false;
      });
    },
    created() {
      this.fetchFranchisees();
      this.fetchIsTimelineTurfed();
    },
    beforeDestroy() {
      EventBus.$off('new_franchisee.close');
    },
    methods: {
      async updateTurfedTimeline(updatedTurfedValue) {
        const payload = {
          is_timeline_turfed: updatedTurfedValue,
        };
        try {
          const response = await api.updatetIsTimelineTurfed(payload);
          this.is_timeline_turfed = response.is_timeline_turfed;
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      async fetchIsTimelineTurfed() {
        try {
          const response = await api.getIsTimelineTurfed();
          this.is_timeline_turfed = response.is_timeline_turfed;
          this.turfLoading = false;
        } catch (err) {
          this.$toasted.error(err.message);
        }
      },
      applyFooter(franchise) {
        const payload = {
          account_id: franchise.id,
        };

        this.$confirm('Are you sure you want to do this? It will override the footer in this account.')
        .then(() => {
          this.$set(franchise, 'loading', true);
          api.applyFranchiseFooter(payload).then((response) => {
            this.$set(franchise, 'loading', false);
            this.$message.success(response.message);
          }).catch((err) => {
            this.$set(franchise, 'loading', false);
            this.$message.error(err.message);
          });
        });
      },
      applyBranding() {
        this.$confirm('Are you sure you want to do this? This is irreversible and will override branding in the Franchisees').then(() => {
          this.loading = true;
          api.syncBranding().then((response) => {
            this.loading = false;
            this.$message.success(response.message);
          }).catch((err) => {
            this.loading = false;
            this.$message.error(err.message);
          });
        });
      },
      addNewFranchisee() {
        this.franchisee_modal = true;
      },
      setCorpPaid(accountId) {
        this.corpPaidLoading = true;
        const payload = {
          account_id: accountId,
        };

        api.setAsCorpPaid(payload).then((response) => {
          this.$message.success(response.message);
          this.corpPaidLoading = false;
        }).catch((err) => {
          this.corpPaidLoading = false;
          this.$message.error(err.message);
        });
      },
      fetchFranchisees() {
        api.getChildrenAccounts().then((response) => {
          this.franchisees = response.children;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      switchAccount(accountId) {
        const payload = {
          account_id: accountId,
        };

        this.switchLoading = true;

        api.switchMembership(payload).then((response) => {
          this.$store.dispatch('refreshUser').then(() => {
            this.$message.success(response.message);
            window.location.href = '/calendar';
            this.switchLoading = false;
          });
        }).catch((err) => {
          this.switchLoading = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  h2 {
    margin-bottom: 2rem;
  }

  .el-card + .el-card {
    margin-top: 1rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .top-banner {
    margin-top: 2rem;
    margin-bottom: .625rem;
  }

  .top-banner .inactive {
    background-color: #f2bebe;
  }

  .top-banner .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    color: #00152a;
  }
  .turfed-flex {
    display:  flex;
    justify-content: space-between;
  }
  .turfed-button {
    font-size: 14px;
  }
</style>
