<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Add User to {{ addToGroup.name }}
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <Multiselect v-model="users" :options="team" placeholder="Select a team member" track-by="id" label="email" :searchable="true" :show-labels="false" :multiple="true" />
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="button" class="xpl-button xpl-button--primary" :disabled="!users || !users.length" @click.stop="add">Add</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['addToGroup'],
    components: {
      Multiselect,
    },
    data() {
      return {
        users: null,
        team: [],
      };
    },
    created() {
      this.fetchTeamMembers();
    },
    methods: {
      fetchTeamMembers() {
        api.getTeamMembers().then((response) => {
          // eslint-disable-next-line
          for (const key in response.members) {
            this.team.push({
              id: response.members[key].id,
              email: response.members[key].email,
              first_name: response.members[key].first_name,
              last_name: response.members[key].last_name,
            });
          }
        });
      },
      add() {
        const payload = {
          user_ids: this.users.map(u => u.id),
          user_group_id: this.addToGroup.id,
        };

        api.addUserToGroup(payload).then((response) => {
          this.$toasted.success(response.message);
          this.closeModal();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      closeModal() {
        EventBus.$emit('add_user_group.close');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal .modal-content .modal-body {
    overflow-y: visible;
  }
</style>
