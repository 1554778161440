<template>
  <div>
    <a href="#" class="go-back" @click.prevent="closeStat"><i class="fa fa-caret-left"></i> Go back</a>
    <div class="header">
      <h1>{{ stat === 'created' ? 'Scheduled' : 'Sent' }}</h1>
      <form @submit.prevent v-if="!loading && results.length">
        <div class="input-group">
          <span class="input-group-addon">
            <span class="icon icon-search"></span>
          </span>
          <input type="search" name="query" v-model.trim="query" class="form-control" placeholder="Search" />
        </div>
      </form>
    </div>
    <div class="datatable" v-if="!loading && results.length">
      <table class="table bb-datatable">
        <thead>
          <tr>
            <td>Contact</td>
            <td class="text-right">{{ stat === 'created' ? 'Scheduled For' : 'Sent At' }}</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="result in filteredResults" v-if="!loading">
            <td>
              <router-link :to="'/contacts/' + result.uuid">{{ result.email }}</router-link>
            </td>
            <td class="text-right">{{ result.scheduled_at | humanDate(false) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="illustration" v-if="!loading && !results.length">
      <img src="https://assets.brandbot.com/public/empty-states/no-sms.svg" />
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['stat', 'campaignItemId'],
    data() {
      return {
        loading: true,
        results: [],
        query: '',
      };
    },
    created() {
      this.fetchStats();
    },
    computed: {
      filteredResults() {
        if (this.query.length) {
          return this.results.filter(r =>
            r.email.toLowerCase().indexOf(this.query.toLowerCase()) > -1,
          );
        }

        return this.results;
      },
    },
    methods: {
      closeStat() {
        EventBus.$emit('sms_stat.close');
      },
      fetchStats() {
        this.results = [];
        if (this.stat === 'created') {
          api.contactsCreatedSms(this.campaignItemId, 0).then((response) => {
            this.results = response.contacts.data;
            this.loading = false;
          }).catch((err) => {
            this.$toasted.error(err.message);
          });
        } else {
          api.contactsSentSms(this.campaignItemId, 0).then((response) => {
            this.results = response.contacts.data;
            this.loading = false;
          }).catch((err) => {
            this.$toasted.error(err.message);
          });
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .header {
    margin-top: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h1 {
    text-transform: capitalize;
    font-size: 1.25rem;
    font-weight: normal;
    color: #212B36;
    line-height: 1.5rem;
  }

  .illustration img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 10rem;
    margin-bottom: 2rem;
  }
</style>
