<template>
  <el-card shadow="never">
    <h1>Connect to your booking software</h1>
    <el-form ref="bookingForm" :model="booking" :rules="bookingRules" status-icon @submit.native.prevent>
      <el-form-item prop="software">
        <label>Choose your software</label>
        <div>
          <el-select v-model="booking.software" placeholder="Booking Softwares" style="width: 100%;">
            <el-option v-for="item in softwares" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item style="text-align: center; margin-top: 1.5rem;">
        <el-button type="primary" class="xpl-button xpl-button--primary":loading="loading" @click.stop="pickSoftware">Continue</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    data() {
      return {
        loading: false,
        softwares: [{
          label: 'MINDBODY',
          value: 'mindbody',
        }, {
          label: 'Pike13',
          value: 'pike',
        }, {
          label: 'Mariana Tek',
          value: 'marianatek',
        }, {
          label: 'Zingfit',
          value: 'zingfit',
        }],
        booking: {
          software: null,
        },
        bookingRules: {
          software: [{ required: true, trigger: 'change', message: 'Please select a software' }],
        },
      };
    },
    methods: {
      pickSoftware() {
        this.$refs.bookingForm.validate((valid) => {
          if (!valid) {
            return;
          }

          this.loading = true;
          localStorage.setItem('brandbotSelectedVendor', this.booking.software);
          EventBus.$emit('signup.software.done', this.booking.software);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-card {
    max-width: 500px;
    width: 500px;
    min-width: 500px;
    border: none;
  }

  .el-card h1 {
    font-size: 1.75rem;
    color: #00152a;
    margin-bottom: 2.5rem;
  }

  .el-form-item {
    margin-bottom: 1rem;
  }
</style>
