<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Assign Scheduled Email
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <div class="text-center" style="padding: 1rem;" v-if="loading">
              <i class="fa fa-spin fa-spinner fa-fw text-muted"></i>
            </div>
            <div class="members" v-if="team_members && !loading">
              <div :class="{ 'member': true, 'selected': assigned_user && assigned_user.id === member.id }"
                  v-for="member in team_members"
                  @click="assigned_user = member">
                <span class="user-badge" v-if="member.first_name && member.last_name">
                  {{ member.first_name.slice(0,1) }}{{ member.last_name.slice(0,1) }}
                </span>
                <span class="user-badge" v-else>
                  {{ member.email.slice(0,2) }}
                </span>
                <div>
                  <div>{{ member.first_name ? member.first_name + ' ' + member.last_name : member.email }}</div>
                  <div class="small text-muted">{{ member.email }}</div>
                </div>
              </div>
            </div>
            <div class="text-center" v-if="!team_members">
              <i class="fa fa-spin fa-spinner fa-fw"></i>
            </div>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="button" class="xpl-button xpl-button--primary" @click.stop="assign($event)">Assign</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['assignedUser', 'scheduledEmailId'],
    data() {
      return {
        loading: true,
        team_members: [],
        assigned_user: this.assignedUser,
      };
    },
    created() {
      const confirmedMembers = true;

      api.getTeamMembers(confirmedMembers).then((response) => {
        this.team_members = response.members;
        this.loading = false;
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    methods: {
      closeModal() {
        EventBus.$emit('scheduled_email.assign.close');
      },
      assign() {
        const payload = {
          assigned_user_id: this.assigned_user.id,
        };

        api.updateScheduledEmail(this.scheduledEmailId, payload).then((response) => {
          this.$toasted.success(response.message);
          this.closeModal();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal-body {
    padding: 0;
  }

  .members .member {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
    font-size: .875rem;
    line-height: .875rem;
    letter-spacing: 0.2px;
    transition: all 150ms ease-in-out;
  }

  .members .member:last-child {
    border-bottom: none;
  }

  .members .member:hover,
  .members .member.selected {
    cursor: pointer;
    background-color: var(--xpl-color-background-lm);
  }

  .members .member .user-badge {
    text-transform: uppercase;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: .75rem;
  }
</style>
