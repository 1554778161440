<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner">
      Gender Details
    </div>

    <el-form @submit.prevent>
      <el-form-item>
        <label>Select a gender</label>
        <el-select v-model="selected_genders" multiple filterable placeholder="Select a gender" value-key="gender" style="width: 100%;">
          <el-option v-for="item in available_genders" :key="item.gender" :label="item.gender" :value="item"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="commitCondition" :disabled="!selected_genders.length">Save this condition</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    props: ['condition', 'editedRule'],
    data() {
      return {
        selected_genders: [],
        available_genders: [{
          gender: 'Female',
        }, {
          gender: 'Male',
        }, {
          gender: 'Other',
        }],
      };
    },
    created() {
      if (this.editedRule) {
        this.selected_genders = this.editedRule.selected_genders;
      }
    },
    methods: {
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          selected_genders: this.selected_genders,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar-inner {
    position: relative;
    padding-top: 0;
  }
</style>
