<template>
  <div class="sidebar-inner" style="width: 100%;">
    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">1</span>
      </el-col>
      <el-col :span="22" class="v2__heading">Do you want to find all contacts who have or do not have this event?</el-col>
    </el-row>

    <el-row style="margin-bottom: 2rem;">
      <el-col :offset="2">
        <el-radio-group v-model="have">
          <el-radio :label="true" border>Contacts who have</el-radio>
          <el-radio :label="false" border>Contacts who <b>do not</b> have</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">2</span>
        </el-col>
        <el-col :span="22" class="v2__heading">When did the contacts {{ !have ? 'not ' : ''}}make this event?</el-col>
      </el-row>

      <el-row style="margin-bottom: 1.5rem;">
        <el-col :offset="2">
          <el-radio-group v-model="timeframe">
            <el-radio label="all" border>All Time</el-radio>
            <el-radio label="absolute" border>Around static date(s)</el-radio>
            <el-radio label="relative" border>A relative time frame</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row v-if="timeframe === 'absolute'">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-radio-group v-model="comparison">
              <el-radio-button label="before">Before</el-radio-button>
              <el-radio-button label="between">Between</el-radio-button>
              <el-radio-button label="after">After</el-radio-button>
            </el-radio-group>

            <div class="block" v-if="comparison && comparison !== 'between'">
              <el-date-picker
                v-model="dates"
                type="date"
                placeholder="Pick a date">
              </el-date-picker>
            </div>

            <div class="block" v-if="comparison && comparison === 'between'">
              <el-date-picker
                v-model="dates"
                type="daterange"
                range-separator="-"
                start-placeholder="Start"
                end-placeholder="End">
              </el-date-picker>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row v-if="timeframe === 'relative'">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-radio-group v-model="comparison">
              <el-radio-button label="more">At least</el-radio-button>
              <el-radio-button label="within">Within the last</el-radio-button>
              <el-radio-button label="exactly">Exactly</el-radio-button>
            </el-radio-group>
            <div class="block" style="display: flex; align-items: center;">
              <el-input-number min="0" v-model="relative_days"></el-input-number>
              &nbsp;days<span v-if="comparison && comparison !== 'within'">&nbsp;ago</span>.
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">3</span>
        </el-col>
        <el-col :span="22" class="v2__heading">Which events would you like to filter on?</el-col>
      </el-row>

      <el-row>
        <el-col :offset="2" :span="22">
          <el-select style="width: 100%;" v-model="selected_event_names" multiple filterable collapse placeholder="Event Name(s)">
              <el-option v-for="item in event_names"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">4</span>
        </el-col>
        <el-col :span="22" class="v2__heading">Were these events cancelled?</el-col>
      </el-row>

      <el-row>
        <el-col :offset="2">
          <el-radio-group v-model="cancelled">
            <el-radio :label="true" border>Yes</el-radio>
            <el-radio :label="false" border>No</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
    </div>

    <el-form>
      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click="commitCondition">Save this condition</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['condition', 'editedRule', 'enrollment', 'goalList'],
    data() {
      return {
        cancelled: false,
        have: true,
        timeframe: 'all',
        comparison: null,
        relative_days: 1,
        dates: null,
        event_names: [],
        selected_event_names: [],
      };
    },
    created() {
      this.fetchEventNames();

      if (this.editedRule) {
        this.have = this.editedRule.have;
        this.timeframe = this.editedRule.timeframe;
        this.comparison = this.editedRule.comparison;
        this.relative_days = this.editedRule.relative_days;
        this.cancelled = this.editedRule.cancelled;
        this.selected_event_names = this.editedRule.event_names;

        if (this.editedRule.end_date) {
          this.dates = [this.editedRule.start_date, this.editedRule.end_date];
        } else {
          this.dates = this.editedRule.start_date;
        }
      }
    },
    methods: {
      fetchEventNames() {
        api.getCustomEventNames().then((response) => {
          this.event_names = response.names;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          cancelled: this.cancelled,
          have: this.have,
          timeframe: this.timeframe,
          comparison: this.comparison,
          relative_days: this.relative_days,
          start_date: Array.isArray(this.dates) ? this.dates[0] : this.dates,
          end_date: Array.isArray(this.dates) ? this.dates[1] : null,
          event_names: this.selected_event_names,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .options {
    padding: 1.25rem;
    background-color: #f5f8fb;
  }
</style>
