<template>
  <div class="card">
    <div class="card-body">
      <div v-if="loading">
        <i class="fa fa-spin fa-spinner fa-fw"></i>
      </div>
      <div v-if="!contact_stages.length && !loading">
        <p>This Contact is not in any Funnel.</p>
        <button type="button" class="xpl-button xpl-button--secondary" @click.stop="add_to_funnel = true" v-if="!add_to_funnel">Add to funnel stage</button>
      </div>
      <div v-if="contact_stages.length">
        <div class="stages">
          <div class="stage" v-for="stage in contact_stages">
            <div>
              <h6>{{ stage.funnel }}</h6>
              <div>Stage: {{ stage.stage }}</div>
              <div class="small" style="margin-top: .5rem;">Added on {{ stage.created_at | humanDateTime }}</div>
            </div>
            <span class="material-icons" @click.stop="removeFromFunnel(stage)">delete</span>
          </div>
        </div>
        <button type="button" class="xpl-button xpl-button--secondary" @click.stop="add_to_funnel = true" v-if="!add_to_funnel">Add to another funnel stage</button>
      </div>

      <div v-if="add_to_funnel">
        <form @submit.prevent>
          <div class="form-group">
            <label>Funnel Stage</label>
            <Multiselect v-model="stage" :options="funnels" track-by="id" label="stage" :searchable="true" :allow-empty="false" :show-labels="false">
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <div class="option__title">{{ props.option.stage }}</div>
                  <div class="small" style="margin-top: .25rem;">Funnel: {{ props.option.funnel }}</div>
                </div>
              </template>
            </Multiselect>
          </div>

          <button type="button" class="xpl-button xpl-button--primary" @click.stop="addToStage" :disabled="!stage">Add</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['contactId'],
    data() {
      return {
        add_to_funnel: false,
        loading: true,
        contact_stages: [],
        funnels: [],
        stage: '',
      };
    },
    components: {
      Multiselect,
    },
    created() {
      this.fetchFunnels();
      this.contactStages();
    },
    methods: {
      fetchFunnels() {
        api.funnelsWithStages().then((response) => {
          this.funnels = response.funnels;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      addToStage() {
        this.add_to_funnel = false;
        const payload = {
          contact_id: this.contactId,
          stage_id: this.stage.id,
        };

        api.addContactToFunnel(payload).then((response) => {
          this.$toasted.success(response.message);
          this.contactStages();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      contactStages() {
        api.stagesForContact(this.contactId).then((response) => {
          this.loading = false;
          this.contact_stages = response.stages;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      removeFromFunnel(stage) {
        // eslint-disable-next-line
        if (!confirm('Are you sure you want to remove this Contact from this Funnel?')) {
          return;
        }

        const payload = {
          contact_id: this.contactId,
          stage_id: stage.id,
        };
        api.removeContactFromFunnel(payload).then((response) => {
          this.$toasted.success(response.message);
          this.contactStages();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
  .stages {
    margin-bottom: 2rem;
  }

  .stages .stage {
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    margin-bottom: 1.5rem;
    font-size: .875rem;
    color: rgb(29, 54, 75);
    padding-left: .75rem;
    padding-right: .75rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: .875rem;
    color: rgb(97, 112, 128);
    background-color: var(--xpl-color-background-lm);
  }

  .stages .stage h6 {
    font-weight: 700;
    font-size: 1rem;
    color: rgb(46, 68, 87);
    margin-bottom: .25rem;
  }

  .stages .stage span.material-icons {
    font-size: .75rem;
    cursor: pointer;
    color: #d62929;
  }
</style>
