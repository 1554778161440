<template>
  <div>
    <div class="nav-subheader" v-if="campaign">
      <div class="nav-inner">
        <router-link :to="'/campaigns/' + campaign.uuid" class="go-back"><i class="fa fa-chevron-left"></i> Go Back</router-link>
        <div class="name">
          {{ campaign.name }}
        </div>
        <span></span>
      </div>
    </div>

    <div class="campaign-container" v-if="campaign">
      <h1>Auto-Campaign Settings</h1>

      <el-card>
        <div class="v2__heading">Contacts Exit Settings</div>
        <el-form>
          <el-form-item>
            <el-checkbox v-model="campaign.metadata.contacts_expire">Remove if a contact is in the campaign after a certain amount of days</el-checkbox>
          </el-form-item>
          <el-form-item v-if="campaign.metadata.contacts_expire">
            <div>
              Remove after <el-input-number v-model.number="campaign.metadata.contacts_expire_days" :min="1" /> days of being in this campaign
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="xpl-button xpl-button--primary":loading="loading" @click.prevent="saveExitRule">Save</el-button>
          </el-form-item>
        </el-form>
      </el-card>

      <el-card>
        <div class="v2__heading" style="display: flex; align-items: center;">Campaign Re-Entry <span class="pill pill-secondary" style="margin-left: .5rem;">BETA</span></div>
        <el-form>
          <el-form-item>
            <el-checkbox v-model="campaign.metadata.reentry">Allow Contacts to re-enter this campaign after a certain amount of days</el-checkbox>
          </el-form-item>
          <el-form-item v-if="campaign.metadata.reentry">
            <div>
              Allow contacts to re-enter this Campaign after <el-input-number v-model.number="campaign.metadata.reentry_days" :min="1" /> days
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="xpl-button xpl-button--primary":loading="loading" @click.prevent="saveReentry">Save</el-button>
          </el-form-item>
        </el-form>
      </el-card>

      <el-card>
        <div class="v2__heading">Export Campaign Data</div>
        <el-form>
          <el-form-item>
            <label>Which segment of this Campaign do you want to export?</label>
            <div>
              <el-radio v-model="export_type" label="enrolled">In Campaign</el-radio>
            </div>
            <div>
              <el-radio v-model="export_type" label="all">All Time</el-radio>
            </div>
            <div>
              <el-radio v-model="export_type" label="goals">Goals</el-radio>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="xpl-button xpl-button--primary":loading="exportLoading" @click.stop="exportCampaign">Export</el-button>
          </el-form-item>
        </el-form>
      </el-card>

      <el-card v-if="$store.state.user.current_account.franchise || $store.getters.isStaff">
        <div class="v2__heading" style="display: flex; align-items: center;">Copy Campaigns</div>
        <el-form>
          <el-form-item>
            <label>Choose a target account to copy this campaign into</label>
            <el-select v-model="target_account_id" filterable placeholder="Select an account" style="width: 100%;" value-key="id">
              <el-option v-for="account in accounts" :key="account.id" :label="account.name" :value="account"></el-option>
            </el-select>
            <div class="small text-muted">This campaign will be copied fully (minus contacts) and will have the same name with 'Copy' appended.</div>
          </el-form-item>
          <el-button type="primary" class="xpl-button xpl-button--primary" @click.prevent="replicateCampaign" :disabled="!target_account_id" :loading="replicateLoading">Copy Campaign Over</el-button>
        </el-form>
      </el-card>

      <el-card v-if="$store.getters.isStaff">
        <div class="v2__heading">Admin</div>
        <el-form>
          <el-form-item>
            <label @click="toggleDayLookup">
              <span class="icon icon-checkbox-unchecked2" v-if="!campaign.metadata.day_lookup || campaign.metadata.day_lookup === false"></span>
              <span class="icon icon-checkbox-checked2" v-if="campaign.metadata.day_lookup === true"></span>
              Toggle Day lookup
            </label>
          </el-form-item>
        </el-form>
      </el-card>

      <el-card v-if="$store.getters.isStaff">
        <div class="v2__heading">Campaign Tools</div>
        <el-form @submit.native.prevent>
          <el-form-item>
            <label>Click below to run all goals of this campaign manually</label>
            <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="campaignRunGoals" :loading="runGoalsLoading">Run Goals</el-button>
          </el-form-item>
          <el-form-item>
            <label>Click below to run the campaign enrollment manually</label>
            <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="campaignRunEnrollment" :loading="runEnrollmentLoading">Run Enrollment</el-button>
          </el-form-item>
        </el-form>
      </el-card>

      <el-card v-if="$store.getters.isStaff">
        <div class="v2__heading" style="margin-bottom: 1rem;">Buffer List ({{ bufferList.total | prettyNumber }})</div>
        <h6 class="" style="margin-bottom: 1rem;" v-if="dev_condition_contacts > 0 && $store.getters.isBrandBotAdmin">Added By Dev Condition ({{ dev_condition_contacts | prettyNumber }})</h6>
        <div style="margin-bottom: 2rem;">
          <el-button type="danger" @click.stop="clearBuffer">Clear the buffer list</el-button>
        </div>
        <el-table :data="bufferData" style="width: 100%">
          <el-table-column label="Contact">
            <template slot-scope="scope">
              <span v-if="scope.row.dev_condition_version && $store.getters.isBrandBotAdmin" class="badge badge-danger">DEV</span> <a :href="'/contacts/' + scope.row.uuid" target="_blank">{{ scope.row.email }}</a>
            </template>
          </el-table-column>
          <el-table-column label="Added">
            <template slot-scope="scope">
              {{ scope.row.created_at | humanDateTime(false) }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" align="right">
            <template slot="header" slot-scope="scope">
              <el-input v-model="search" size="mini" placeholder="Search"/>
            </template>
            <template slot-scope="scope">
              <el-button type="danger" size="small" @click="removeFromBuffer(scope.row.id_buffer)">Delete</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 2rem;">
          <el-pagination layout="total, prev, pager, next" :total="bufferList.total" :page-size="bufferList.per_page"
                         @next-click="nextPage" @prev-click="prevPage" @current-change="currentPage(page)"
                         style="text-align: center;"></el-pagination>
        </div>
      </el-card>

    </div>
  </div>
</template>
<script>
// bbe-2617 - fixed per_page
//
  import * as api from '@/api';

  export default {
    props: ['uuid'],
    data() {
      return {
        franchisees: [],
        franchise_type: 'standard',
        loading: false,
        target_account_id: null,
        accounts: [],
        campaign: null,
        bufferList: [],
        search: '',
        page: 1,
        runEnrollmentLoading: false,
        runGoalsLoading: false,
        replicateLoading: false,
        export_type: 'enrolled',
        exportLoading: false,
        dev_condition_contacts: 0,
      };
    },
    created() {
      this.fetchCampaign();
      this.fetchMemberships();
    },
    computed: {
      bufferData() {
        if (this.search.length) {
          return this.bufferList.data
            .filter(c => c.email.indexOf(this.search.toLowerCase()) > -1);
        }

        return this.bufferList.data;
      },
    },
    methods: {
      exportCampaign() {
        const payload = {
          campaign_id: this.campaign.id,
          export_type: this.export_type,
        };
        this.exportLoading = true;
        api.exportCampaignData(payload).then((response) => {
          this.exportLoading = false;
          this.$message.success('Downloading...');
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'export.csv');
          link.click();
          window.URL.revokeObjectURL(url);
        }).catch(() => {
          this.exportLoading = false;
          this.$message.error('We are having issues exporting your data. Please try again later.');
        });
      },
      campaignRunGoals() {
        this.runGoalsLoading = true;
        api.campaignRunGoals({ campaign_id: this.campaign.id }).then((response) => {
          this.runGoalsLoading = false;
          this.$message.success(response.message);
        }).catch((err) => {
          this.runGoalsLoading = false;
          this.$message.error(err.message);
        });
      },
      campaignRunEnrollment() {
        this.runEnrollmentLoading = true;
        api.campaignRunEntry({ campaign_id: this.campaign.id }).then((response) => {
          this.runEnrollmentLoading = false;
          this.$message.success(response.message);
        }).catch((err) => {
          this.runEnrollmentLoading = false;
          this.$message.error(err.message);
        });
      },
      toggleDayLookup() {
        this.loading = true;
        if (this.campaign.metadata.day_lookup === null) {
          this.campaign.metadata.day_lookup = false;
        }
        api.updateCampaign(this.campaign.id, { day_lookup: !this.campaign.metadata.day_lookup })
        .then((response) => {
          this.loading = false;
          this.$toasted.success(response.message);
          this.$set(this.campaign.metadata, 'day_lookup', !this.campaign.metadata.day_lookup);
        }).catch((err) => {
          this.loading = false;
          this.$toasted.error(err.message);
        });
      },
      fetchMemberships() {
        if (this.$store.state.user.current_account.franchise) {
          api.getChildrenAccounts().then((response) => {
            this.accounts = response.children;
          }).catch((err) => {
            this.$message.error(err.message);
          });
        } else {
          api.getMemberships().then((response) => {
            this.accounts = response.memberships;
          });
        }
      },
      fetchDebug() {
        if (!this.campaign) {
          return;
        }
        api.campaignDebug({ campaign_id: this.campaign.id }, this.page).then((response) => {
          this.bufferList = response.buffer;
          this.dev_condition_contacts = this.bufferList.data[0].dev_condition_total;
        });
      },
      removeFromBuffer() {
        this.$confirm('This will permanently remove this contact from the buffer list and allow them to be enrolled.', 'Danger', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }).then(() => {
          this.$message.error('This feature is not yet available.');
        });
      },
      clearBuffer() {
        this.$confirm('This will permanently delete the full buffer list. Continue?', 'Danger', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }).then(() => {
          this.callClearBuffer();
        });
      },
      callClearBuffer() {
        const payload = {
          campaign_id: this.campaign.id,
        };
        api.campaignDebugClearBuffer(payload).then((response) => {
          this.$message.success(response.message);
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchCampaign() {
        api.getCampaign(this.uuid, this.page).then((response) => {
          this.campaign = response.campaign;

          if (this.$store.getters.isStaff) {
            this.fetchDebug();
          }
        });
      },
      nextPage() {
        this.page += 1;
        this.fetchDebug();
      },
      prevPage() {
        this.page = this.page - 1;
        this.fetchDebug();
      },
      currentPage(page) {
        this.page = page;
        this.fetchDebug();
      },
      saveReentry() {
        const payload = {
          reentry: this.campaign.metadata.reentry,
          reentry_days: this.campaign.metadata.reentry_days,
        };
        this.loading = true;
        api.updateCampaign(this.campaign.id, payload).then((response) => {
          this.loading = false;
          this.$message.success(response.message);
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      saveExitRule() {
        const payload = {
          contacts_expire: this.campaign.metadata.contacts_expire,
          expiration_days: this.campaign.metadata.contacts_expire_days,
        };

        this.loading = true;

        api.updateCampaign(this.campaign.id, payload).then((response) => {
          this.loading = false;
          this.$message.success(response.message);
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      replicateCampaign() {
        const payload = {
          campaign_id: this.campaign.id,
          target_account_id: this.target_account_id.id,
        };

        this.replicateLoading = true;

        api.replicateCampaign(payload).then((response) => {
          this.$message.success(response.message);
          this.target_account_id = null;
          this.replicateLoading = false;
        }).catch((err) => {
          this.replicateLoading = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .nav-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav-inner .name {
    display: flex;
    align-items: center;
  }

  .campaign-container {
    max-width: 73.75rem;
    margin: auto;
    padding-top: 2rem;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 4rem;
  }

  .campaign-container h1 {
    font-size: 1.625rem;
    line-height: 1.23;
    color: #1d364b;
    margin-bottom: 1.5rem;
  }

  .card.exit .card-body > div .icon {
    margin-right: .5rem;
  }

  .card.exit .card-body > div a {
    font-size: .75rem;
    font-style: italic;
    text-decoration: underline;
    color: rgb(139, 155, 172);
  }

  .card.exit .card-body .options-inner {
    margin-bottom: 1.5rem;
    font-size: .875rem;
    line-height: 1.25rem;
    color: rgb(29, 54, 75);
  }

  .card.exit .card-body .options-inner input {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .card.exit .card-body label {
    cursor: pointer;
  }

  .form-inline {
    align-items: flex-start;
    flex-direction: column;
  }

  .el-card + .el-card {
    margin-top: 2rem;
  }
</style>
