<template>
  <div class="card">
    <div class="card-body">
      <div class="stats">
        <div :class="['stat', show_campaign_stats === 'in-campaign' ? 'show' : '']" @click.prevent="showStat('in-campaign')">
          <h6>In Campaign</h6>
          <div class="number in-campaign" v-if="campaign.enrollment_rule.enrolled">{{ campaign.enrollment_rule.enrolled | prettyNumber }}</div>
          <div class="number in-campaign" v-else>&mdash;</div>
        </div>
        <div :class="['stat', show_campaign_stats === 'history' ? 'show' : '']" @click.prevent="showStat('history')">
          <h6>All Time</h6>
          <div class="number history" v-if="campaign.enrollment_rule.count">{{ campaign.enrollment_rule.count | prettyNumber }}</div>
          <div class="number history" v-else>&mdash;</div>
        </div>
        <div :class="['stat', show_campaign_stats === 'goal' ? 'show' : '']" @click.prevent="showStat('goal')" v-if="!campaign.no_goals">
          <h6>Met Goal</h6>
          <div class="number goal" v-if="campaign.enrollment_rule.count">{{ campaign.total_goal / campaign.enrollment_rule.count | prettyPercentage }}</div>
          <div class="number goal" v-else>&mdash;</div>
          <div class="sub-number">{{ campaign.total_goal }}</div>
        </div>
      </div>

      <CampaignStat v-if="show_campaign_stats" :stat="show_campaign_stats" :campaign="campaign" />
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import CampaignStat from './CampaignStat';

  export default {
    props: ['campaign'],
    components: {
      CampaignStat,
    },
    data() {
      return {
        show_campaign_stats: false,
      };
    },
    mounted() {
      EventBus.$on('campaign.stats.close', () => {
        this.show_campaign_stats = false;
      });
    },
    beforeDestroy() {
      EventBus.$off('campaign.stats.close');
    },
    methods: {
      showStat(stat) {
        if (this.show_campaign_stats !== stat) {
          this.show_campaign_stats = stat;
        } else {
          this.show_campaign_stats = false;
        }
        EventBus.$emit('show_campaign_stats', stat);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .card {
    margin-bottom: 1rem;
  }

  .stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .stats .stat {
    flex: calc(100% / 6 - 1rem);
    flex-shrink: 0;
    flex-grow: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    min-width: 6.25rem;
    padding: .75rem;
    padding-top: 1rem;
    border-radius: 6px;
    background-color: #ffffff;
    border: solid 1px #d6e0e8;
    margin-right: .5rem;
    transition: all 150ms ease-in-out;
  }

  .stats .stat:hover,
  .stats .stat.show {
    border-color: var(--xpl-color-secondary-lm);
    background-color: var(--xpl-color-secondary-bg-lm);
  }

  .stats .stat.show h6 {
    color: var(--xpl-color-secondary-lm);
  }

  .stats .stat:last-child {
    margin-right: 0;
  }

  .stats .stat h6 {
    text-align: center;
    font-size: .625rem;
    font-weight: 800;
    letter-spacing: 0.8px;
    color: #8b9bac;
    margin-bottom: .375rem;
    text-transform: uppercase;
  }

  .stats .stat .number {
    text-align: center;
    font-size: 1.25rem;
  }

  .stats .stat .number.in-campaign {
    color: var(--xpl-color-secondary-lm);
  }
  .stats .stat .number.history {
    color: rgb(92, 106, 196);
  }
  .stats .stat .number.goal {
    color: rgb(92, 106, 196);
  }

  .stats .stat .sub-number {
    margin-top: .375rem;
    font-size: .75rem;
    color: #212b36;
    text-align: center;
  }
</style>
