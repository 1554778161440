<template>
  <div>
    <el-row :span="24" class="heading">
      <el-col :span="12">
        <div class="display-medium">
          Re-opening Waiver
        </div>
      </el-col>
    </el-row>

    <el-card v-loading="loading">
      <div class="display-small">Email clients who do not have a Required Waiver signed for in person classes</div>
      <el-form>
        <el-form-item>
          <label>Do you want to enable this feature?</label>
          <div>
            <el-radio v-model="enable" :label="true" border>Yes, send an email</el-radio>
            <el-radio v-model="enable" :label="false" border>No, disable</el-radio>
          </div>
        </el-form-item>
        <el-form-item v-if="enable">
          <label>Use the following template</label>
          <el-select v-model="template_id" style="width: 100%;" value-key="id">
            <el-option v-for="template in templates" :key="template.id" :label="template.name" :value="template.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="xpl-button xpl-button--primary" @click="saveTemplate">Save</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
  import { getClassRemindersTemplates, getCovidTemplate, saveCovidTemplate } from '@/api';

  export default {
    data() {
      return {
        loading: false,
        template_id: null,
        enable: false,
        templates: [],
      };
    },
    created() {
      this.fetchCovidTemplate();
      this.getTemplates();
    },
    methods: {
      getTemplates() {
        getClassRemindersTemplates('saved').then((response) => {
          this.templates = response.templates;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      saveTemplate() {
        this.loading = true;
        const payload = {
          template_id: this.template_id,
        };

        if (!this.enable) {
          payload.template_id = null;
        }

        saveCovidTemplate(payload).then((response) => {
          this.loading = false;
          this.$message.success(response.message);
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      fetchCovidTemplate() {
        getCovidTemplate().then((response) => {
          this.template_id = response.template_id;
          if (this.template_id) {
            this.enable = true;
          }
        }).catch((err) => {
          this.$error.message(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .display-medium {
    margin-bottom: 2.625rem;
  }

  .display-small {
    margin-bottom: 1rem;
  }

  .el-card + .el-card {
    margin-top: 1.5rem;
  }
</style>
