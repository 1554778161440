import { render, staticRenderFns } from "./KeywordReplies.vue?vue&type=template&id=523eeb16&scoped=true&"
import script from "./KeywordReplies.vue?vue&type=script&lang=js&"
export * from "./KeywordReplies.vue?vue&type=script&lang=js&"
import style0 from "./KeywordReplies.vue?vue&type=style&index=0&id=523eeb16&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "523eeb16",
  null
  
)

export default component.exports