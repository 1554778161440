<template>
  <div class="container">
    <div class="container-inner">
    <!--  We show desktop verbiage when chrome or safari isn't used-->
      <h1 v-if="!validDesktopBrowser">At the moment, BrandBot is only supported on <a href="https://www.google.com/chrome/browser/desktop/index.html">Google Chrome</a> and <a href="https://support.apple.com/downloads/safari"> Safari</a> web browsers</h1>
      <h1 v-if="!validDesktopBrowser">Please reach out to <a href="mailto:support@brandbot.com">support@brandbot.com</a> if you need any assistance on using Google Chrome or Safari for BrandBot.</h1>

<!-- We show mobile  verbiage when mobile browsers are used-->
       <h1 v-if="isMobile">At the moment, BrandBot does not support mobile browsers</h1>
      <h1 v-if="isMobile">Please reach out to <a href="mailto:support@brandbot.com">support@brandbot.com</a> if you need any assistance on using BrandBot on your computer.</h1>
    </div>
  </div>
</template>
<script>
 export default {
    props: ['validDesktopBrowser', 'isMobile'],
  };
</script>
<style lang="scss" scoped>
  .container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .container-inner {
    max-width: 700px;
  }

  h1 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  @media only screen and (max-width: 600px) {
 .container {
   text-align:  center;
 }
}
</style>
