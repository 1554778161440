<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner">
      Milestone Visit Details
    </div>
    <form @submit.prevent class="form-inline" v-if="!enrollment">
      Any contact who has visited&nbsp;
      <Multiselect v-model="comparison_visit" placeholder="Select a comparison" :options="comparisons_visit" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" style="min-width: 13rem; width: 13rem; max-width: 13rem;" />
      <input type="number" name="visit_number" v-model.number="visit_number_start" class="form-control" min="0" style="display: inline-block; width: 5rem;" :disabled="!comparison_visit" /><span v-if="comparison_visit && comparison_visit.value === 'between'">
        &nbsp;and&nbsp;<input type="number" name="visit_number" v-model.number="visit_number_end" class="form-control" min="0" style="display: inline-block; width: 5rem;" /></span>&nbsp;times.
    </form>

    <form @submit.prevent class="form-inline" v-if="enrollment">
        Enroll any contact who has
        <Multiselect v-model="comparison_visit" placeholder="Select a comparison" :options="comparisons_visit" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" />

        <input type="number" name="visit_number" v-model.number="visit_number_start" class="form-control" min="0" style="display: inline-block; width: 5rem;" /><span v-if="comparison_visit && comparison_visit.value === 'between'"> and <input type="number" name="visit_number" v-model.number="visit_number_end" class="form-control" min="0" style="display: inline-block; width: 5rem;" /></span> visits ever.
      </form>

      <div class="condition-banner">
        Location and Site ID (Optional)
      </div>

      <form @submit.prevent>
        <div class="form-group">
          <div class="row">
            <div class="col-xs-2">
              <span class="icon icon-radio-unchecked" v-if="misc_filtering === null || misc_filtering === true" @click="misc_filtering = false"></span>
              <span class="icon icon-radio-checked" v-if="misc_filtering === false" @click="misc_filtering = false"></span>
            </div>
            <div class="col-xs-10">
              <label for="misc_filtering_no" @click="misc_filtering = false">
                Select all locations and Site IDs
              </label>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-xs-2">
              <span class="icon icon-radio-unchecked" v-if="misc_filtering === null || misc_filtering === false" @click="misc_filtering = true"></span>
              <span class="icon icon-radio-checked" v-if="misc_filtering === true" @click="misc_filtering = true"></span>
            </div>
            <div class="col-xs-10">
              <label for="misc_filtering_yes" @click="misc_filtering = true">
                 Select specific locations or Site IDs
              </label>
            </div>
          </div>
        </div>
        <div class="options-inner" v-if="misc_filtering">
          <div class="form-group">
            <label class="form-control-label">Location</label>
            <div class="multiselect-block">
              <Multiselect v-model="location" placeholder="All Locations" label="location_name" track-by="location_id" :options="locations" :searchable="false" :allow-empty="true" :show-labels="false" />
            </div>
          </div>
        </div>
      </form>

      <div class="form-group">
        <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition" :disabled="!comparison_visit || visit_number_start === null">Save this condition</button>
      </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['locations', 'condition', 'editedRule', 'enrollment'],
    components: {
      Multiselect,
    },
    data() {
      return {
        visit_number_start: null,
        visit_number_end: null,
        comparisons_visit: [{
          label: 'At least',
          value: 'more',
        }, {
          label: 'Less than',
          value: 'less',
        }, {
          label: 'Exactly',
          value: 'exactly',
        }, {
          label: 'Between',
          value: 'between',
        }],
        comparison_visit: null,
        misc_filtering: false,
        location: null,
      };
    },
    created() {
      if (this.editedRule) {
        this.visit_number_start = this.editedRule.visit_number_start;
        this.visit_number_end = this.editedRule.visit_number_end;
        this.comparison_visit = this.comparisons_visit.filter(c =>
          c.value === this.editedRule.comparison_visit,
        )[0];

        this.misc_filtering = this.editedRule.misc ? this.editedRule.misc.filtering : false;
        if (this.misc_filtering) {
          this.location = this.locations.filter(l =>
            l.location_id === this.editedRule.misc.location,
          )[0];
        }
      }
    },
    methods: {
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          visit_number_start: this.visit_number_start,
          visit_number_end: this.visit_number_end,
          comparison_visit: this.comparison_visit ? this.comparison_visit.value : null,
          misc: {
            filtering: this.misc_filtering,
            location: this.location ? this.location.location_id : null,
            site_id: null,
          },
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar-inner {
    position: relative;
    padding-bottom: 5rem;
  }

  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }
</style>
