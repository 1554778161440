<template>
  <div class="sidebar-inner" style="width: 100%;">
    <el-row style="margin-bottom: 1.25rem;">
      <el-col :span="2">
        <span class="step-circle">1</span>
      </el-col>
      <el-col :span="22" class="v2__heading">Do you want to find all contacts who have or have not made a purchase</el-col>
    </el-row>

    <el-row style="margin-bottom: 2rem;">
      <el-col :offset="2">
        <el-radio-group v-model="purchased">
          <el-radio :label="true" border>Contacts who have</el-radio>
          <el-radio :label="false" border>Contacts who have <b>not</b></el-radio>
        </el-radio-group>
      </el-col>
    </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">2</span>
        </el-col>
        <el-col :span="22" class="v2__heading">What service did the contacts {{ !purchased ? 'not ' : ''}}purchase?</el-col>
      </el-row>

      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2">
          <el-radio-group v-model="purchase_type">
            <el-radio label="any" border>Any service</el-radio>
            <el-radio label="specific" border>A specific service</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row style="margin-bottom: 2rem;" v-if="purchase_type === 'specific'">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-form :label-position="'top'" label-width="100px">
              <label>Pricing Option(s)</label>
              <el-form-item>
                <el-select v-model="selected_pricing_options" collapse-tags multiple filterable placeholder="Select Pricing Option(s)"  style="display: block;" value-key="id">
                  <el-option v-for="item in pricing_options" :value="item" :label="item.name" :key="item.id"></el-option>
                </el-select>
              </el-form-item>

              <div v-if="see_more" style="margin-top: 1.5rem;">
                <label>Contract(s)</label>
                <el-form-item>
                  <el-select v-model="selected_contracts" collapse-tags multiple filterable placeholder="Select Contract(s)"  style="display: block;" value-key="id">
                    <el-option v-for="item in contracts" :key="item.id" :label="item.name" :value="item"></el-option>
                  </el-select>
                </el-form-item>

                <label>Service Categorie(s)</label>
                <el-form-item>
                  <el-select v-model="selected_service_categories" collapse-tags multiple filterable placeholder="Select Service Categorie(s)"  style="display: block;" value-key="id">
                    <el-option v-for="item in service_categories" :key="item.id" :label="item.name" :value="item"></el-option>
                  </el-select>
                </el-form-item>

                <label>Revenue Categorie(s)</label>
                <el-form-item>
                  <el-select v-model="selected_revenue_categories" collapse-tags multiple filterable placeholder="Select Revenue Categorie(s)"  style="display: block;" value-key="id">
                    <el-option v-for="item in revenue_categories" :key="item.id" :label="item.name" :value="item"></el-option>
                  </el-select>
                </el-form-item>

                <div style="margin-top: 1.5rem; margin-bottom: 1rem;">
                  <el-switch v-model="auto_pay"></el-switch>
                  <label>Only Include Auto-Pay</label>
                </div>
              </div>

              <a href="#" @click.stop="see_more = !see_more" v-if="!see_more" class="v2__caption subdued">Show more options (contracts, service and revenue categories, auto-pay)</a>
              <a href="#" @click.stop="see_more = !see_more" v-if="see_more" class="v2__caption subdued">Show less options (contracts, service and revenue categories, auto-pay)</a>
          </el-form>
          </div>
        </el-col>
      </el-row>
    </div>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">3</span>
        </el-col>
        <el-col :span="22" class="v2__heading">When did the contacts {{ !purchased ? 'not ' : ''}}make their purchase?</el-col>
      </el-row>

      <div style="margin-bottom: 2rem;">
        <el-row style="margin-bottom: 1rem;">
          <el-col :offset="2">
            <el-radio-group v-model="timeframe">
              <el-radio label="all" border>All Time</el-radio>
              <el-radio label="absolute" border>Around static date(s)</el-radio>
              <el-radio label="relative" border>A relative time frame</el-radio>
              <el-radio label="entering_campaign" border v-if="goalList">Since entering campaign</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>

        <el-row v-if="timeframe === 'absolute'">
          <el-col :offset="2" :span="22">
            <div class="options">
              <el-radio-group v-model="comparison">
                <el-radio-button label="before">Before</el-radio-button>
                <el-radio-button label="between">Between</el-radio-button>
                <el-radio-button label="after">After</el-radio-button>
              </el-radio-group>

              <div class="block" v-if="comparison && comparison !== 'between'">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="dates"
                  type="date"
                  placeholder="Pick a date">
                </el-date-picker>
              </div>

              <div class="block" v-if="comparison && comparison === 'between'">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="dates"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="Start"
                  end-placeholder="End">
                </el-date-picker>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row v-if="timeframe === 'relative'">
          <el-col :offset="2" :span="22">
            <div class="options">
              <el-radio-group v-model="comparison">
                <el-radio-button label="more">At least</el-radio-button>
                <el-radio-button label="within">Within the last</el-radio-button>
                <el-radio-button label="exactly">Exactly</el-radio-button>
              </el-radio-group>
              <div class="block" style="display: flex; align-items: center;">
                <el-input-number :min="0" v-model="relative_days"></el-input-number>
                &nbsp;
                days<span v-if="comparison && comparison !== 'within'">&nbsp;ago</span>.
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">4</span>
        </el-col>
        <el-col :span="22" class="v2__heading">Where did the contacts {{ !purchased ? 'not ' : ''}}make their purchase?</el-col>
      </el-row>

      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2">
          <el-radio-group v-model="misc_filtering">
            <el-radio :label="false" border>Any location</el-radio>
            <el-radio :label="true" border>Specific Location(s)</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row v-if="misc_filtering">
        <el-col :offset="2" :span="22">
          <div class="options">
            <label>Location(s)</label>
            <el-select v-model="location" multiple filterable placeholder="Select Location(s)" style="display: block;" value-key="location_id">
              <el-option v-for="item in locations" :key="item.location_id" :label="item.location_name" :value="item"></el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <button type="button" class="xpl-button xpl-button--primary" @click.stop="commitCondition">Save this condition</button>
      </div>
    </form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['locations', 'condition', 'editedRule', 'enrollment', 'goalList'],
    data() {
      return {
        dates: null,
        auto_pay: false,
        see_more: false,
        purchased: true,
        purchase_type: 'any',
        timeframe: 'all',
        comparison: 'before',
        relative_days: 1,
        misc_filtering: false,
        location: null,
        pricing_options: [],
        selected_pricing_options: [],
        service_categories: [],
        selected_service_categories: [],
        revenue_categories: [],
        selected_revenue_categories: [],
        contracts: [],
        selected_contracts: [],
      };
    },
    created() {
      this.fetchPricingOptions();
      this.fetchServiceCategories();
      this.fetchRevenueCategories();
      this.fetchContracts();

      if (this.editedRule) {
        this.purchased = this.editedRule.purchase;
        this.timeframe = this.editedRule.timeframe;

        this.comparison = this.editedRule.comparison;

        this.relative_days = this.editedRule.relative_days;
        if (this.editedRule.end_date) {
          this.dates = [this.editedRule.start_date, this.editedRule.end_date];
        } else {
          this.dates = this.editedRule.start_date;
        }

        this.purchase_type = this.editedRule.purchase_type;
        this.selected_pricing_options = this.editedRule.pricing_options;
        this.selected_service_categories = this.editedRule.service_categories;
        this.selected_revenue_categories = this.editedRule.revenue_categories;
        this.selected_contracts = this.editedRule.contracts;
        this.auto_pay = this.editedRule.auto_pay;

        if (this.selected_service_categories.length ||
            this.selected_revenue_categories.length ||
            this.auto_pay) {
          this.see_more = true;
        }

        this.misc_filtering = this.editedRule.misc.filtering;
        if (this.misc_filtering) {
          this.location = this.editedRule.misc.location;
        }
      }
    },
    methods: {
      fetchPricingOptions() {
        api.getPricingOptions().then((response) => {
          this.pricing_options = response.pricing_options;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchServiceCategories() {
        api.getServiceCategories().then((response) => {
          this.service_categories = response.service_categories;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchRevenueCategories() {
        api.getRevenueCategories().then((response) => {
          this.revenue_categories = response.revenue_categories;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchContracts() {
        api.getContracts().then((response) => {
          this.contracts = response.contracts;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          auto_pay: this.auto_pay,
          purchase: this.purchased,
          purchase_type: this.purchase_type,
          pricing_options: this.selected_pricing_options,
          service_categories: this.selected_service_categories,
          revenue_categories: this.selected_revenue_categories,
          contracts: this.selected_contracts,
          timeframe: this.timeframe,
          relative_days: this.relative_days,
          comparison: this.comparison,
          start_date: Array.isArray(this.dates) ? this.dates[0] : this.dates,
          end_date: Array.isArray(this.dates) ? this.dates[1] : null,
          misc: {
            filtering: this.misc_filtering,
            location: this.location,
            site_id: null,
          },
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar-inner {
    position: relative;
  }

  .sidebar-inner .condition-banner {
    margin-top: 2rem;
    background-color: #f2f6fa;
    font-size: .75rem;
    font-weight: 700;
    line-height: 1rem;
    margin-bottom: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1.5rem;
    color: #8b9bac;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    text-transform: uppercase;
  }

  .options {
    padding: 1.25rem;
    background-color: #f5f8fb;
  }

  .sidebar-inner .condition-banner:first-child {
    margin-top: 0;
  }

  .el-radio {
    background-color: #f8f9fa;
  }

  .el-radio.is-checked {
    background-color: #f5f8fb;
  }

  .el-form-item {
    margin-bottom: .5rem !important;
  }

  .el-form-item + label {
    margin-top: 1.5rem !important;
  }
</style>
