<template>
  <div class="field-container">
    <div class="card-condition">
      <el-row>
        <el-col :span="1.5" class="step-col">
          <div class="field-step">{{ this.field.step }}</div>
        </el-col>
        <el-col :span="22" class="body-col">
          <span class="field-title">{{ this.field.name }}</span>
          <transition name="fade">
            <el-form :model="form"
                     class="field-form" ref="countingForm">
              <el-form-item prop="selectedOption">
                <div class="option-body" @click.stop>
                  <!--                  -->
                  <div v-for="v of this.field.show">
                    <el-radio
                      class="radio-btn field-label-text"
                      v-model="form.selectedOption"
                      @change="didChange"
                      :label="v">{{ EN[v] }}
                    </el-radio>
                    <transition name="input">
                      <div v-if="form.selectedOption === v" class="other-form">
                        <el-input-number v-model="form.valueOne"
                                         size="mini"
                                         controls-position="right"
                                         :class="errorBetween(v)"
                                         @change="didChange()"
                                         :max="9999"
                                         :min="1"/>
                        <div v-if="v === 'between'" class="and-text">AND</div>
                        <el-input-number v-if="v === 'between'" v-model="form.valueTwo"
                                         size="mini"
                                         :class="errorBetween(v)"
                                         controls-position="right"
                                         @change="didChange()"
                                         :max="9999"
                                         :min="1"/>
                      </div>
                    </transition>
                  </div>
                  <!--                  -->
                </div>
              </el-form-item>
            </el-form>
          </transition>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  props: ['field'],
  data() {
    return {
      isPristine: true,
      EN: {
        at_least: 'At Least',
        exactly: 'Exactly',
        less_than: 'Less Than',
        between: 'Between',
        more_than: 'More Than',
      },
      form: {
        selectedOption: null,
        valueOne: null,
        valueTwo: null,
      },
    };
  },
  created() {
    if (this.field && this.field.default) {
      this.form.selectedOption = this.field.default.option;
      this.form.valueOne = this.field.default.value[0];
      this.form.valueTwo = this.field.default.value[1];
    }
    this.didChange();
  },
  methods: {
    errorBetween(type) {
      if (type === 'between') {
        const isValid = this.isValidBetween();
        return isValid ? '' : 'error';
      }
      return '';
    },
    didChange() {
      this.isPristine = false;
      this.field.default.option = this.form.selectedOption;
      this.field.default.value = [this.form.valueOne, this.form.valueTwo];
      this.validate();
    },
    isValidBetween() {
      const isValid = !!this.form.valueOne
        && !!this.form.valueTwo
        && (this.form.valueOne <= this.form.valueTwo);
      return isValid;
    },
    validate() {
      if (this.form.selectedOption === 'between') {
        this.field.isValid = this.isValidBetween();
      } else {
        this.field.isValid = !!this.form.valueOne;
      }
      this.$log('countField isvalid=', this.field.isValid);
      this.$emit('didValidate');
    },
  },
};
</script>
<style lang="scss" scoped>
@import "uia_fields";

.el-input-number.error {
    border-color: red !important;
}
</style>
