<template>
  <div>
    <div class="stats-detail">
      <a href="#" class="go-back" @click.prevent="closeCampaignStat"><i class="fa fa-caret-left"></i> Go back to campaign</a>
    </div>
    <InStat v-if="stat === 'in-campaign'" :campaign="campaign" />
    <History v-if="stat === 'history'" :campaign="campaign" />
    <Goal v-if="stat === 'goal'" :campaign="campaign" />
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import InStat from './Stats/InStat';
  import History from './Stats/History';
  import Goal from './Stats/Goal';

  export default {
    props: ['stat', 'campaign'],
    components: {
      InStat,
      History,
      Goal,
    },
    data() {
      return {};
    },
    methods: {
      closeCampaignStat() {
        EventBus.$emit('campaign.stats.close');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .stats-detail {
    margin-top: 1.5rem;
    margin-bottom: 3rem;
  }
</style>
