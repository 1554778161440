<template>
  <div class="metrics" v-if="metrics">
    <div class="metric">
      <span class="number text-muted" v-if="!metrics.sent">&mdash;</span>
      <span class="number" v-else>{{ metrics.sent | prettyNumber }}</span>
      Sent
    </div>
    <div class="metric">
      <span class="number text-muted" v-if="!metrics.opened_unique">&mdash;</span>
      <span class="number" v-else>{{ metrics.opened_unique | prettyNumber }}</span>
      Opens
    </div>
    <div class="metric">
      <span class="number text-muted" v-if="!metrics.clicked_unique">&mdash;</span>
      <span class="number" v-else>{{ metrics.clicked_unique | prettyNumber }}</span>
      Clicks
    </div>
  </div>
</template>
<script>
  import * as api from '@/api';

  export default {
    props: ['uuid'],
    data() {
      return {
        metrics: null,
      };
    },
    created() {
      api.emailPerformance(this.uuid).then((response) => {
        this.metrics = response.stats;
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
  };
</script>
<style lang="scss">
  .metrics {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .metrics .metric {
    font-size: .75rem;
    line-height: 1.5;
    letter-spacing: 0.2px;
    color: rgb(118, 130, 141);
    margin-right: .5rem;
  }

  .metrics .metric .number {
    display: block;
    margin-bottom: .25rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.2px;
    color: rgb(46, 68, 87);
  }
</style>
