<template>
  <div class="container">
    <img :src="$store.getters.brandLogo" :alt="$store.getters.brandName" class="img-fluid" />
    <div class="alert alert-danger" v-if="error">
      {{ error }}
    </div>
    <h1 v-if="!done">Confirming your email...</h1>
    <h1 v-if="done">Redirecting you...</h1>
  </div>
</template>
<script>
  import * as api from '@/api';

  export default {
    props: ['uuid'],
    data() {
      return {
        done: false,
        error: false,
      };
    },
    created() {
      const payload = {
        uuid: this.uuid,
      };

      api.confirmEmail(payload)
      .then(() => {
        this.done = true;
        this.$toasted.success('Your email is now confirmed! Please check your email for your credentials.');
        if (this.$store.getters.isLoggedIn) {
          this.$store.dispatch('refreshUser').then(() => {
            this.$router.push('/login');
          });
        } else {
          this.$router.push('/login');
        }
      })
      .catch((err) => {
        this.error = err.message;
      });
    },
  };
</script>
<style lang="scss" scoped>
  .container {
    width: 50em;
    height: calc(100vh - 3rem);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .container h1 {
    font-size: 2.8rem;
    font-weight: 300;
  }

  .container img {
    width: 42rem;
    height: auto;
    max-height: 42px;
  }
</style>
