<template>
  <div>
    <div class="header" v-if="!show_preview">
      Select a template
      <div class="btn-close-wrapper" @click.stop="closeTemplateSelection">
        <span class="material-icons">close</span>
      </div>
    </div>
    <div class="container" v-if="!show_preview">
      <div class="sidebar">
        <div class="tabs">
          <a href="#" :class="{'active': !selectedSuite}" @click.stop="selectedSuite = null">Account Saved Templates</a>
          <a href="#" :class="{'active': selectedSuite === 'franchise'}" @click.stop="selectedSuite = 'franchise'; preview_html = null;"
             v-if="franchise_templates && franchise_templates.length">Franchise Templates</a>
        </div>
        <div v-for="template in templates">
          <div v-for="(t, purpose) in template">
            <div class="purpose">{{ purpose }}</div>
            <div :class="['suite', selectedSuite === i ? 'active' : '']" v-for="(i, suite) in t.suites" @click.stop="selectSuite(i)">{{ suite }}</div>
          </div>
        </div>
      </div>
      <div class="second-sidebar" v-if="selectedSuite && selectedSuite !== 'franchise'">
        <div v-for="emails in selectedSuite">
          <div :class="['email', selectedEmail === email ? 'active' : '']" v-for="email in emails" @click.stop="selectEmail(email)">
            {{ email.name }}
          </div>
        </div>
      </div>
      <div :class="['content', selectedSuite && selectedSuite !== 'franchise' && selectedEmail ? 'show-suite' : '']">
        <div v-if="selectedSuite && selectedSuite !== 'franchise' && selectedEmail">
          <div class="subject-banner">
            {{ (selectedEmail.subject === "") ? "Write a great subject line here" : selectedEmail.subject }}
            <button class="xpl-button xpl-button--primary" @click.stop="chooseTemplate(selectedEmail.id, 'brandbot')">Select</button>
          </div>
          <div class="preview-html" v-html="preview_html" v-if="preview_html"></div>
        </div>
        <div :class="['templates', 'saved']" v-if="!selectedSuite">
          <div class="template" v-for="template in saved_templates" v-if="saved_templates.length">
            <div class="template-inner saved">
              <div class="preview" :style="'background-image: url('+template.preview_img_desktop+');'"></div>
              <div>
                <div class="name">{{ template.name }}</div>
                <div class="small text-muted">Last updated on {{ template.updated_at | humanDate }}</div>
              </div>
              <div class="actions">
                <button type="button" class="xpl-button xpl-button--secondary" @click.stop="showPreview(template)">Preview</button>
                <button type="button" class="xpl-button xpl-button--primary" @click.stop="chooseTemplate(template.id, 'saved')">Select</button>
              </div>
            </div>
          </div>
          <div class="empty" v-if="!saved_templates.length">
            <img class="illustration" src="https://assets.brandbot.com/public/empty-states/saved-templates.svg" alt="No Saved Templates" />
            <p>You have no saved templates.</p>
          </div>
        </div>

        <div :class="['templates', 'saved']" v-if="selectedSuite === 'franchise'">
          <div class="template" v-for="template in franchise_templates">
            <div class="template-inner saved">
              <div class="preview" :style="'background-image: url('+template.preview_img_desktop+');'"></div>
              <div>
                <div class="name">{{ template.name }}</div>
                <div class="small text-muted">Last updated on {{ template.updated_at | humanDate }}</div>
              </div>
              <div class="actions">
                <button type="button" class="xpl-button xpl-button--secondary" @click.stop="showPreview(template)">Preview</button>
                <button type="button" class="xpl-button xpl-button--primary" @click.stop="chooseTemplate(template.id, 'saved')">Select</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TemplatePreview v-if="show_preview" :template="template" />
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';
  import TemplatePreview from './TemplatePreview';

  export default {
    props: ['scheduledEmail'],
    components: {
      Multiselect,
      TemplatePreview,
    },
    data() {
      return {
        show_preview: false,
        template: null,
        group: null,
        templates: [],
        saved_templates: [],
        franchise_templates: [],
        selectedSuite: null,
        selectedEmail: null,
        preview_html: null,
      };
    },
    mounted() {
      EventBus.$on('template.preview.close', () => {
        this.show_preview = false;
        this.template = null;
      });

      EventBus.$on('template.preview.select', (templateId) => {
        this.chooseTemplate(templateId, 'saved');
      });
    },
    created() {
      this.fetchEmailTemplates();
      this.fetchSavedTemplates();
    },
    beforeDestroy() {
      EventBus.$off('template.preview.close');
      EventBus.$off('template.preview.select');
    },
    methods: {
      fetchEmailTemplates() {
        api.getEmailTemplates(this.scheduledEmail.type, 'scheduled').then((response) => {
          this.templates = response.templates;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchSavedTemplates() {
        api.getEmailTemplatesSaved(this.scheduledEmail.type).then((response) => {
          this.saved_templates = response.templates;
          this.franchise_templates = response.franchise;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      selectSuite(suite) {
        this.selectedSuite = suite;
        this.selectEmail(suite.emails[0]);
      },
      selectEmail(email) {
        this.selectedEmail = email;
        this.preview_html = null;
        const payload = {
          type: this.scheduledEmail.type,
        };

        if (payload.type === 'full') {
          payload.payload = JSON.stringify(JSON.parse(email.body));
        } else {
          payload.payload = JSON.stringify(email.body);
        }
        api.getTemplatePreview(payload).then((response) => {
          this.preview_html = response.html;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      showPreview(template) {
        this.show_preview = true;
        this.template = template;
      },
      closeTemplateSelection() {
        EventBus.$emit('scheduled_email.template.close');
      },
      chooseTemplate(templateId, kind = 'brandbot') {
        const payload = {
          kind,
          template_email_id: templateId,
        };

        api.updateScheduledEmail(this.scheduledEmail.id, payload).then((response) => {
          this.$toasted.success(response.message);
          EventBus.$emit('scheduled_email.template.selected', response.email);
        })
        .catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .header {
    width: 100vw;
    height: 3rem;
    background-color: #ffffff;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #cdd7e2;
    position: fixed;
  }

  .container {
    max-width: none;
  }

  .container .sidebar {
    margin-top: 3rem;
    min-width: 15rem;
    max-width: 15rem;
    width: 15rem;
    background-color: #fff;
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: fixed;
    left: 0;
    height: calc(100vh - 3rem);
    border-left: 1px solid #d9e7f0;
    overflow-y: scroll;
    padding-bottom: 2rem;
  }

  .container .second-sidebar {
    margin-top: 3rem;
    min-width: 15rem;
    max-width: 15rem;
    width: 15rem;
    background-color: #fff;
    padding-top: 3rem;
    position: fixed;
    left: 15rem;
    height: calc(100vh - 3rem);
    border-left: 1px solid #d9e7f0;
    overflow-y: scroll;
    padding-bottom: 2rem;
  }

  .container .content {
    padding-top: 4.5rem;
    padding-left: calc(15rem + 3rem);
  }

  .container .content.show-suite {
    padding-left: calc(30rem + .5rem);
  }

  .sidebar .title {
    font-size: .875rem;
    color: #1d364b;
    margin-bottom: 1.5rem;
  }

  .sidebar .types,
  .sidebar .tabs {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sidebar .tabs a {
    display: block;
    cursor: pointer;
    padding: .5rem;
    border-radius: 6px;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.82;
    color: rgb(46, 68, 87);
    transition: all 300ms ease-in-out;
  }
  .sidebar .tabs a:hover,
  .sidebar .tabs a.active {
    text-decoration: none;
    color: var(--xpl-color-secondary-lm);
    background-color: var(--xpl-color-background-lm);
  }

  .content h1 {
    font-size: 1.625rem;
    line-height: 2rem;
    color: #1d364b;
  }

  form {
    margin-top: 1.5rem;
    display: inline-block;
  }

  .templates {
    margin-top: 2rem;
  }

  .templates.saved {
    width: 100%
  }

  .templates.provided {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .templates .template .template-inner.saved {
    padding: 1rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: #ffffff;
    // box-shadow: 0 1px 0 0 rgba(217, 231, 240, 0.5);
    border: solid 1px #d9e7f0;
    margin-bottom: .5rem;
    transition: all 150ms ease-in-out;
  }

  .templates .template .template-inner.provided {
    padding: 0;
    min-width: 18rem;
    max-width: 18rem;
    flex-shrink: 0;
    flex-grow: 0;
    border: solid 1px #d9e7f0;
    margin-bottom: 2rem;
    transition: all 150ms ease-in-out;
  }

  .templates .template .template-inner.provided .preview {
    width: 100%;
    height: 12.5rem;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .templates .template .template-inner.provided .footer {
    padding-top: 1rem;
    padding-left: .75rem;
    padding-right: .75rem;
    padding-bottom: 1rem;
    border-top: solid 1px rgb(175, 188, 202);
    background-color: white;
    font-size: .75rem;
    color: rgb(29, 54, 75);
  }

  .templates .template .template-inner.saved .preview {
    width: 4rem;
    height: 4rem;
    flex-grow: 0;
    flex-shrink: 0;
    background-color: #d8d8d8;
    border: solid 1px #979797;
    margin-right: 1rem;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .templates .template .name {
    font-size: .75rem;
    font-weight: 700;
    line-height: 1.25rem;
    color: #1d364b;
  }

  .templates .template .small.text-muted {
    font-size: .75rem;
    line-height: 1.25rem;
    color: #8b9bac;
  }

  .templates .template .actions {
    margin-left: auto;
  }

  .templates .template:last-child {
    margin-bottom: 0;
  }

  .templates .template:hover {
    cursor: pointer;
    border-color: var(--xpl-color-secondary-lm);
  }

  .templates .template .template-inner button {
    opacity: 0;
    transition: all 300ms ease-in-out;
  }

  .templates .template:hover .template-inner button {
    opacity: 1;
  }

  .templates .template .template-inner.saved button:first-child {
    margin-right: .75rem;
  }

  .templates .template .template-inner.provided button:first-child {
    margin-bottom: .75rem;
  }

  .purpose {
    margin-top: 1.5rem;
    text-transform: uppercase;
    font-size: .625rem;
    font-weight: bold;
    line-height: 2.5;
    color: rgb(139, 155, 172);
    padding-left: .5rem;
  }

  .suite {
    cursor: pointer;
    padding: .5rem;
    border-radius: 6px;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.82;
    color: rgb(46, 68, 87);
    transition: all 300ms ease-in-out;
  }

  .suite:hover,
  .suite.active,
  .email:hover,
  .email.active {
    color: var(--xpl-color-secondary-lm);
    background-color: var(--xpl-color-background-lm);
  }

  .email {
    cursor: pointer;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: .875rem;
    color: rgb(46, 68, 87);
    transition: all 300ms ease-in-out;
  }

  .subject-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    background-color: rgb(249, 250, 251);
    // box-shadow: 0 1px 3px 0 rgba(63, 63, 68, 0.12), 0 0 0 1px rgba(63, 63, 68, 0.07);
    font-size: .875rem;
    font-weight: 700;
    color: rgb(46, 68, 87);
    padding: .5rem 1rem;
  }

  .empty {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .illustration {
    display: block;
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 15rem;
  }

  .preview-html {
    display: block;
    width: 100%;
    margin-top: 2rem;
    pointer-events: none;
  }
</style>
