<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            New Saved Link
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <el-form @submit.native.prevent>
              <el-form-item>
                <label>Name</label>
                <el-input type="text" v-model.trim="name" placeholder="Name" />
              </el-form-item>
              <el-form-item>
                <label>URL</label>
                <el-input type="text" v-model.trim="url" placeholder="URL" />
              </el-form-item>
            </el-form>
          </div>
          <div class="modal-footer">
            <span></span>
            <el-button type="primary" class="xpl-button xpl-button--primary":disabled="!url || !name" @click.prevent="createSavedLink" :loading="loading">Create</el-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    data() {
      return {
        loading: false,
        name: '',
        url: '',
      };
    },
    methods: {
      closeModal() {
        EventBus.$emit('new_saved_link_modal.close');
      },
      createSavedLink() {
        const payload = {
          type: 'link',
          link_name: this.name,
          link: this.url,
        };

        this.loading = true;
        api.saveSavedLinks(payload).then((response) => {
          this.loading = false;
          this.$toasted.success(response.message);
          this.closeModal();
        }).catch((err) => {
          this.loading = false;
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
