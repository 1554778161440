<template>
  <div>
    <el-card v-loading="fieldsLoading">
      <el-row>
        <el-col :span="20">
          <div class="display-small" style="margin-bottom: 2rem;">Registration Fields</div>
        </el-col>
        <el-col :span="4" align="right">
          <el-button type="secondary" class="xpl-button xpl-button--secondary"  @click.stop="fetchRequiredFields(true)">Refresh Fields</el-button>
        </el-col>
      </el-row>

      <el-table :data="fields" style="width: 100%">
        <el-table-column prop="name" label="Field"></el-table-column>
        <el-table-column label="Status">
          <template slot-scope="scope">
            <div v-if="scope.row.required">Required</div>
            <div v-else>
              <el-select v-model="scope.row.show">
                <el-option :key="true" :label="'Show'" :value="true"></el-option>
                <el-option :key="false" :label="'Hide'" :value="false"></el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-form style="margin-top: 2rem;">
        <el-form-item>
          <el-button type="primary" class="xpl-button xpl-button--primary" @click="saveFields" :loading="fieldsSaving">Save</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['checkoutflow'],
    data() {
      return {
        fieldsSaving: false,
        fieldsLoading: false,
        fields: [],
      };
    },
    created() {
      this.fetchRequiredFields();
    },
    methods: {
      fetchRequiredFields(refresh = false) {
        this.fieldsLoading = true;
        api.getRequiredFields(refresh).then((response) => {
          this.fields = response.fields;
          this.fieldsLoading = false;

          if (this.checkoutflow.settings.fields &&
            this.checkoutflow.settings.fields.length) {
            const shownFields = this.checkoutflow.settings
                  .fields
                  .filter(f => f.show)
                  .map(f => f.name);

            this.fields.forEach((f, i) => {
              if (shownFields.indexOf(f.name) > -1) {
                this.fields[i].show = true;
              }
            });
          }
        }).catch((err) => {
          this.$message.error(err.message);
          this.fieldsLoading = false;
        });
      },
      saveFields() {
        this.fieldsSaving = true;
        const payload = {
          settings: {
            fields: this.fields.filter(f => Object.prototype.hasOwnProperty.call(f, 'show')),
          },
        };

        if (payload.settings.fields.length) {
          api.updateCheckoutFlow(payload, this.checkoutflow.id).then((response) => {
            this.$message.success(response.message);
            this.fieldsSaving = false;
            EventBus.$emit('checkoutflow.refresh');
          }).catch((err) => {
            this.$message.error(err.message);
            this.fieldsSaving = false;
          });
        } else {
          this.fieldsSaving = false;
        }
      },
    },
  };
</script>
