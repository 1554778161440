<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner">
      Index Details
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <label>Get all contacts who are assigned the following index(es)</label>
        <div class="multiselect-block">
          <Multiselect v-model="selected_indexes" placeholder="Select indexes" :options="available_indexes" label="value" track-by="id" :searchable="true" :multiple="true" :allow-empty="false" :show-labels="false" :close-on-select="false">
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <div class="option__title">{{ props.option.name }}</div>
                <span class="option__small small">{{ props.option.value }}</span>
              </div>
            </template>
          </Multiselect>
        </div>
      </div>
    </form>

    <div class="form-group">
      <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition" :disabled="!selected_indexes.length">Save this condition</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['condition', 'editedRule'],
    components: {
      Multiselect,
    },
    data() {
      return {
        selected_indexes: [],
        available_indexes: [],
      };
    },
    created() {
      this.fetchIndexes();

      if (this.editedRule) {
        this.selected_indexes = this.editedRule.selected_indexes;
      }
    },
    methods: {
      fetchIndexes() {
        api.getMindbodyIndexes().then((response) => {
          this.available_indexes = response.indexes;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          selected_indexes: this.selected_indexes,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar-inner {
    padding-bottom: 5rem;
    min-height: 70vh;
  }

  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }
</style>
