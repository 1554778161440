<template>
  <div class="card paused">
    <div class="card-header card-header-secondary card-header-merged"></div>
    <div class="card-body">
      <div>
        <img src="https://assets.brandbot.com/public/icons/scheduled-items/paused.svg" />
      </div>
      <div>
        <h1>This {{ type === 'task' || type === 'tag' ? 'auto-action' : 'auto-message' }} is paused</h1>
        <div class="small text-muted">If you need to edit it more, you will need to set it back to in progress.</div>
      </div>
      <button type="button" class="btn xpl-button xpl-button--secondary" @click.prevent="startCampaign">Start</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    props: ['type'],
    methods: {
      startCampaign() {
        EventBus.$emit('campaign_item.start');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .card.paused,
  .card.started {
    // box-shadow: 0 2px 4px 0 rgba(217, 231, 240, 0.5);
    border: none;
    margin-bottom: 2rem;
  }

  .card.paused .card-header {
    padding: 0;
    border: none;
    background-image: linear-gradient(to right, rgb(187, 211, 242), rgb(169, 203, 224));
    height: 0.6875rem;
  }

  .card.started .card-header {
    padding: 0;
    border: none;
    background-image: linear-gradient(to right, rgb(70, 232, 136), rgb(94, 246, 188));
    height: 0.6875rem;
  }

  .card.paused .card-body,
  .card.started .card-body {
    border: solid 1px rgb(217, 231, 240);
    border-top: none;
    padding-top: 1rem;
    padding-left: 1.25rem;
    padding-bottom: 1rem;
    padding-right: 1.25rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .card.paused .card-body h1,
  .card.started .card-body h1 {
    font-size: 1rem;
    line-height: 1.5;
    color: rgb(29, 54, 75);
  }

  .card.paused .card-body .btn,
  .card.started .card-body .btn {
    margin-left: auto;
  }

  .card.paused .card-body img,
  .card.started .card-body img {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 1.25rem;
  }
</style>
