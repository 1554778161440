<template>
  <div class="sms-chat">
    <div class="conversation" id="conversation">
      <div :class="['message', msg.hasOwnProperty('from') ? 'own' : 'account', specialKeywords.indexOf(msg.body.toUpperCase()) > -1 ? 'subscription' : '']" v-for="msg in conversation">
        <div class="message-inner" v-if="specialKeywords.indexOf(msg.body.toUpperCase()) === -1">
          {{ msg.body }}
        </div>
        <div class="message-footer" v-if="specialKeywords.indexOf(msg.body.toUpperCase()) === -1">
          <span v-if="msg.sent_user">
            Sent By {{ msg.sent_user.first_name ? msg.sent_user.first_name : msg.sent_user.email }}
            &vert;
          </span>
          <span v-if="msg.campaign">
            Sent By Auto-Campaign ({{ msg.campaign }})
            &vert;
          </span>
          <span v-if="msg.hasOwnProperty('from')">Received on {{ msg.created_at | humanDateTime }}</span>
          <span v-if="!msg.delivered_at && !msg.hasOwnProperty('from')">Sent on {{ msg.created_at | humanDateTime }}</span>
          <span v-if="msg.delivered_at">Delivered on {{ msg.delivered_at | humanDateTime }}</span>
        </div>
        <div class="message-subscription" v-if="stopKeywords.indexOf(msg.body.toUpperCase()) > -1">
          This contact has <strong>unsubscribed</strong> from your SMS on {{ msg.created_at | humanDateTime }}
        </div>
        <div class="message-subscription" v-if="startKeywords.indexOf(msg.body.toUpperCase()) > -1">
          This contact has <strong>subscribed</strong> back to your SMS on {{ msg.created_at | humanDateTime }}
        </div>
      </div>
    </div>
    <div class="composer">
      <form @submit.prevent>
        <div>
          <textarea class="message-input" v-model="body" autocomplete="off" placeholder="SMS Message" rows="4"></textarea>
        </div>
        <div>
          <button type="button" ref="submit" class="xpl-button xpl-button--primary" @click="sendMessage" :disabled="!body || body.length < 1">Send</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
  import * as api from '@/api';

  export default {
    props: ['contactId'],
    data() {
      return {
        body: '',
        conversation: [],
        interval: null,
        loading: false,
        scrolled: false,
        stopKeywords: ['STOP', 'END', 'QUIT', 'CANCEL', 'UNSUBSCRIBE'],
        startKeywords: ['START'],
        specialKeywords: ['STOP', 'END', 'QUIT', 'CANCEL', 'UNSUBSCRIBE', 'START'],
        show_exclude: false,
      };
    },
    mounted() {
      // this.interval = setInterval(this.fetchConversation, 4200);
    },
    created() {
      this.fetchConversation();
    },
    beforeDestroy() {
      // clearInterval(this.interval);
    },
    methods: {
      fetchConversation() {
        if (!this.loading) {
          this.loading = true;
          api.getContactMessages(this.contactId).then((response) => {
            this.conversation = response.conversation;
            this.loading = false;
            this.$nextTick(() => {
              if (!this.scrolled) {
                const objDiv = document.getElementById('conversation');
                objDiv.scrollTop = objDiv.scrollHeight;
              }
            });
          }).catch((err) => {
            this.$toasted.error(err.message);
          });
        }
      },
      sendMessage(event) {
        if (this.body.length < 1) {
          return;
        }

        const target = event.target || event.srcElement;
        target.disabled = true;
        const payload = {
          contact_id: this.contactId,
          body: this.body,
        };

        if (this.show_exclude) {
          api.excludeContact({ contact_id: this.contactId })
          .then(() => {
            api.sendContactMessage(payload).then((response) => {
              this.body = '';
              this.$toasted.success(response.message);
              target.disabled = false;
              this.scrolled = false;
              this.fetchConversation();
            }).catch((err) => {
              target.disabled = false;
              if (err.errors && err.errors.body) {
                this.$toasted.error(err.errors.body);
              } else {
                if (err.duplicate === true) {
                  this.show_exclude = true;
                }
                this.$toasted.error(err.message);
              }
            });
          });
        } else {
          api.sendContactMessage(payload).then((response) => {
            this.body = '';
            this.$toasted.success(response.message);
            target.disabled = false;
            this.scrolled = false;
            this.fetchConversation();
          }).catch((err) => {
            target.disabled = false;
            if (err.errors && err.errors.body) {
              this.$toasted.error(err.errors.body);
            } else {
              if (err.duplicate === true) {
                this.show_exclude = true;
              }
              this.$toasted.error(err.message);
            }
          });
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sms-chat {
    height: calc(100% - 3.625rem);
    max-height: calc(100% - 3.625rem);
    min-height: calc(100% - 3.625rem);
  }
    .conversation {
    height: calc(100vh - 9.5rem - 11.3125rem);
    max-height: calc(100vh - 9.5rem - 11.3125rem);
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    overflow-y: scroll;
  }

  .message {
    min-width: 29rem;
    max-width: 29rem;
    width: 29rem;
    margin-bottom: 1.5rem;
  }

  .message.subscription {
    max-width: none;
    min-width: none;
    width: 100%;
  }

  .message-inner {
    border-radius: .5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    font-size: .875rem;
  }

  .message.own .message-inner {
    background-color: rgb(255, 255, 255);
    color: rgb(46, 68, 87);
    border-bottom-left-radius: 0;
  }

  .message.account {
    margin-left: auto;
  }

  .message.account .message-footer {
    text-align: right;
  }

  .message.account .message-inner {
    background-color: #6923f4;
    color: rgb(255, 255, 255);
    border-bottom-right-radius: 0;
  }

  .message-footer {
    font-size: .75rem;
    color: rgb(139, 155, 172);
  }

   .composer {
    position: fixed;
    width: calc(100% - 32.5rem - 1rem + 7.5rem);
    bottom: 0;
    padding: 2rem;
    margin-left: 1rem;
    background-color: rgb(255, 255, 255);
    border: 1px solid var(--xpl-color-gray-400);
    margin-bottom: 0;
  }
  .message .message-subscription {
    font-size: .75rem;
    width: 100%;
    text-align: center;
    color: rgb(139, 155, 172);
  }

    textarea {
    outline: none;
    text-align: left;
    border: none;
    outline: none;
    line-height: 1.4;
    width: 100%;
    color: #00152a;
    overflow-wrap: break-word;
    resize: none;
    height: 60px;
    font-size: 14px;
  }
</style>
