const accountIds = {
  aquaTots: [874, 1004, 1089, 1091, 1092, 1093, 1094, 1095, 1096, 1097, 1098, 1099,
    1100, 1101, 1102, 1103, 1104, 1105, 1106, 1107, 1108, 1109, 1110, 1111, 1112, 1113,
    1114, 1115, 1116, 1117, 1118, 1119, 1120, 1121, 1122, 1123, 1124, 1125, 1126, 1127,
    1128, 1129, 1130, 1131, 1132, 1133, 1134, 1135, 1136, 1137, 1138, 1139, 1140, 1141,
    1142, 1143, 1146, 1147, 1148, 1149, 1150, 1151, 1152, 1153, 1154, 1155, 1156, 1157,
    1158, 1159, 1160, 1161, 1162, 1163, 1164, 1165, 1166, 1167, 1168, 1169, 1170, 1171,
    1172, 1173, 1174, 1175, 1176, 1177, 1178, 1179, 1180, 1181, 1182, 1183, 1184, 1185,
    1186, 1187, 1188, 1189, 1190, 1191, 1192, 1193, 1194, 1908, 2017, 2049, 2071, 2732,
    2716, 2392, 2241, 2240, 2211, 2164, 2106, 2096, 2089, 2968, 2953],
  baseCamp: [1495, 2559, 2581, 2740, 2741, 2742, 2743, 2744, 2745, 2746, 2747,
    2748, 2810, 2811],
  aquaTotsHQ: [1266],
  aquaTotsCartAbandonmentBeta: [1266, 874],
  testingAccounts: [2868],
};


export { accountIds }; //eslint-disable-line
