<template>
  <div>
    <div class="nav-subheader">
      <div class="nav-inner">
        <div>Account Templates</div>
        <div class="nav-pills">
          <div :class="[tabs.media ? 'active' : '', 'pill']" @click="$router.push('/account/assets')">Media</div>
          <div :class="[tabs.saved_links ? 'active' : '', 'pill']" @click="$router.push('/account/saved-links')">Saved Links</div>
          <div :class="[tabs.pdf ? 'active' : '', 'pill']" @click="$router.push('/account/saved-pdfs')">Saved PDFs</div>
          <div :class="[tabs.templates ? 'active' : '', 'pill']" @click="$router.push('/account/templates')">Templates</div>
          <div :class="[tabs.tags ? 'active' : '', 'pill']" @click="$router.push('/account/tags')">Tags</div>
        </div>
      </div>
    </div>

    <div class="templates">
      <form @submit.prevent>
        <div class="input-group">
          <span class="input-group-addon">
            <span class="icon icon-search"></span>
          </span>
          <input type="search" name="query" v-model.trim="query" class="form-control" placeholder="Search" />
        </div>
      </form>
      <div class="template" v-for="t in filteredTemplates" @click="template = t; show_preview = true;">
        <div class="preview" :style="'background-image: url('+t.preview_img_desktop+')'"></div>
        <div>
          <div class="name">{{ t.name }}</div>
          <div class="small text-muted">Last updated on {{ t.updated_at | humanDate }}</div>
          <div class="small text-success" v-if="t.shared">Shared</div>
        </div>
        <div class="actions">
          <div class="dropdown">
            <button type="button" class="xpl-button xpl-button--secondary" @click.stop="toggleDropdown(t)">Options</button>
            <div :class="{ 'dropdown-menu': true, show: t.dropdown ? t.dropdown : false }">
              <a href="#" @click.stop="toggleShareFranchisees(t)" class="dropdown-item" v-if="$store.state.user.current_account && $store.state.user.current_account.franchise">{{ t.shared ? 'Unshare' : 'Share' }} with Franchisees</a>
              <a href="#" @click.stop="renameTemplate(t)" class="dropdown-item">Rename</a>
              <a href="#" @click.stop="deleteTemplate(t)" class="dropdown-item text-danger">Delete</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Preview v-if="template && show_preview" :template="template" />
    <RenameTemplateModal v-if="show_rename_modal" :template="template" />
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Preview from './Preview';
  import RenameTemplateModal from './RenameTemplateModal';

  export default {
    components: {
      Preview,
      RenameTemplateModal,
    },
    data() {
      return {
        show_preview: false,
        show_rename_modal: false,
        toggle_dropdown: false,
        tabs: {
          media: false,
          saved_links: false,
          pdf: false,
          templates: true,
          tags: false,
          checkout: false,
        },
        templates: [],
        query: '',
        template: null,
      };
    },
    created() {
      this.fetchTemplates();

      EventBus.$on('templates.preview.close', () => {
        this.template = null;
        this.show_preview = false;
        this.fetchTemplates();
      });

      EventBus.$on('template.rename.close', () => {
        this.template = null;
        this.show_rename_modal = false;
      });
    },
    beforeDestroy() {
      EventBus.$off('templates.preview.close');
      EventBus.$off('template.rename.close');
    },
    computed: {
      filteredTemplates() {
        if (this.query.length) {
          return this.templates.filter(t =>
            t.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1,
          );
        }

        return this.templates;
      },
    },
    methods: {
      toggleShareFranchisees(template) {
        template.dropdown = !template.dropdown;

        const payload = {
          shared: !template.shared,
        };

        api.updateEmailTemplatesSaved(template.id, payload).then((response) => {
          this.$toasted.success(response.message);
          this.fetchTemplates();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      toggleDropdown(template) {
        if (!Object.prototype.hasOwnProperty.call(template, 'dropdown')) {
          this.$set(template, 'dropdown', true);
        } else {
          template.dropdown = !template.dropdown;
        }
      },
      fetchTemplates() {
        api.getEmailTemplatesSaved('all').then((response) => {
          this.templates = response.templates;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      renameTemplate(template) {
        this.template = template;
        this.show_rename_modal = true;
        template.dropdown = !template.dropdown;
      },
      deleteTemplate(template) {
        template.dropdown = !template.dropdown;

        // eslint-disable-next-line
        if (!confirm('Are you sure you want to delete this template?')) {
          return;
        }

        api.deleteEmailTemplatesSaved(template.id).then((response) => {
          this.$toasted.success(response.message);
          this.fetchTemplates();
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .nav-inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .templates {
    margin-left: auto;
    margin-right: auto;
    max-width: 44.375rem;
    padding-top: 2rem;
  }

  .templates form {
    margin-bottom: 1.25rem;
    display: flex;
  }

  .templates .template {
    padding: 1rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffff;
    // box-shadow: 0 1px 0 0 rgba(217, 231, 240, 0.5);
    border: solid 1px var(--xpl-color-gray-400);
    width: 100%;
    margin-bottom: .5rem;
    transition: all 150ms ease-in-out;
  }

  .templates .template .preview {
    width: 4rem;
    height: 4rem;
    flex-grow: 0;
    flex-shrink: 0;
    background-color: #d8d8d8;
    border: solid 1px #979797;
    margin-right: 1rem;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .templates .template .name {
    font-size: .75rem;
    font-weight: 700;
    line-height: 1.25rem;
    color: #1d364b;
  }

  .templates .template .small.text-muted {
    font-size: .75rem;
    line-height: 1.25rem;
    color: #8b9bac;
  }

  .templates .template .actions {
    margin-left: auto;
  }

  .templates .template:last-child {
    margin-bottom: 0;
  }

  .templates .template:hover {
    cursor: pointer;
    border-color: var(--xpl-color-secondary-lm);
  }

  .templates .template button {
    opacity: 0;
    transition: all 300ms ease-in-out;
  }

  .templates .template:hover button {
    opacity: 1;
  }

  .templates .template button:first-child {
    margin-right: .75rem;
  }

  .tag {
    font-size: .5rem;
    line-height: 1rem;
    margin-left: .5rem;
    padding: .125rem .5rem;
    background-color: #f3f7f9;
    color: #1d364b;
    height: auto;
    border-radius: 6px;
    text-align: center;
    border: 1px solid #cdd7e2;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: auto;
  }
</style>
