<template>
  <div v-if="targeted_sms" v-loading="loading">
    <el-container>
      <el-header>
        <input type="text" autocomplete="off" v-model.trim="targeted_sms.name" @keyup.enter="editName" />
        <div class="actions">
          <el-dropdown trigger="click" @command="handleCommand" style="margin-right: 1rem;">
            <el-button type="secondary" class="xpl-button xpl-button--secondary" >
              Options <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="text-danger" command="delete">Delete</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-container>
        <el-main>
          <el-alert style="margin-bottom: 2rem;" :title="'Previously sent'" type="warning" :closable="false" show-icon v-if="targeted_sms.sent">
            This Targeted SMS has already been sent. Please create a new one if you wish to send again.
          </el-alert>
          <div class="display-small">Audience Settings</div>
          <el-card>
            <div class="display-caption" style="margin-bottom: 1rem;">Who do you want to send to</div>
            <div class="options">
              <el-form>
                <el-form-item>
                  <label>Any customer who has a visit within the following dates:</label>
                  <el-row :span="24">
                    <el-col :span="8">
                      <label>Start Date</label>
                      <el-date-picker v-model="start_date" type="date" value-format="yyyy-MM-dd" placeholder="Start Date"></el-date-picker>
                    </el-col>
                    <el-col :span="8">
                      <label>Start Time</label>
                      <el-time-select v-model="start_time" :picker-options="timeSteps" placeholder="Select time"></el-time-select>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <label>End Date</label>
                      <el-date-picker v-model="end_date" type="date" value-format="yyyy-MM-dd" placeholder="End Date"></el-date-picker>
                    </el-col>
                    <el-col :span="8">
                      <label>End Time</label>
                      <el-time-select v-model="end_time" :picker-options="timeSteps" placeholder="Select time"></el-time-select>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="saveTimes" :loading="saveLoading" v-if="!targeted_sms.sent">Save</el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-card>

          <div class="display-small">SMS Settings</div>
          <el-card>
            <div class="display-caption" style="margin-bottom: 1rem;">SMS Content</div>
            <div class="options">
              <el-form>
                <el-row :span="24">
                  <el-col :span="12">
                    <el-form-item>
                      <label>Body of your SMS</label>
                      <el-input type="textarea" v-model="targeted_sms.body" placeholder="SMS Content" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="11" :offset="1">
                    <el-form-item>
                      <label>Copy a pre-written message</label>
                      <div class="links">
                        Aqua-Tots Incident
                        <a href="#" @click.prevent="copyText('incident')">Copy</a>
                      </div>
                      <div class="links">
                        Snow Day Closure
                        <a href="#" @click.prevent="copyText('snow')">Copy</a>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item>
                  <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="saveBody" :loading="saveLoading" v-if="!targeted_sms.sent">Save</el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-card>

          <div class="display-small" v-if="!targeted_sms.sent">Review and Send</div>
          <div class="display-small" v-if="targeted_sms.sent">Sent</div>
          <el-card>
            <div class="display-caption" style="margin-bottom: 1rem;">Estimated Audience</div>
            <el-card :shadow="'none'" class="estimated">
              <div class="number" v-if="!targeted_sms.count">&mdash;</div>
              <div class="number" v-if="targeted_sms.count">{{ targeted_sms.count | prettyNumber }}</div>
              <div class="caption">Contacts</div>
            </el-card>
            <el-button type="primary" class="xpl-button xpl-button--primary"v-if="!targeted_sms.sent" :loading="saveLoading" :disabled="!targeted_sms.body || !targeted_sms.count" @click.stop="sendSMS">Send SMS</el-button>
          </el-card>

          <div class="display-small" v-if="targeted_sms.sent">Opt Outs</div>
          <el-card v-if="targeted_sms.sent">
            <el-table :data="targeted_sms.optouts">
              <el-table-column label="Contact">
                <template slot-scope="scope">
                  <router-link target="_blank" :to="'/contacts/' + scope.row.uuid">{{ scope.row.first_name }} {{ scope.row.last_name }}</router-link>
                </template>
              </el-table-column>
              <el-table-column prop="phone" label="Phone"></el-table-column>
            </el-table>
          </el-card>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
  import { getTargetedSMSSingle,
    editTargetedSingle,
    deleteTargetedSingle,
    pushTargetedSMS } from '@/api';

  export default {
    props: ['uuid'],
    data() {
      return {
        loading: false,
        saveLoading: false,
        targeted_sms: null,
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        timeSteps: {
          start: '05:00',
          step: '00:05',
          end: '23:00',
        },
      };
    },
    created() {
      this.fetchTargeted();
    },
    methods: {
      saveBody() {
        const payload = {
          body: this.targeted_sms.body,
        };

        this.saveLoading = true;

        editTargetedSingle(this.targeted_sms.id, payload).then((response) => {
          this.saveLoading = false;
          this.$message.success(response.message);
        }).catch((err) => {
          this.saveLoading = false;
          this.$message.error(err.message);
        });
      },
      saveTimes() {
        const payload = {
          start_date: this.start_date,
          start_time: this.start_time,
          end_date: this.end_date,
          end_time: this.end_time,
        };

        this.saveLoading = true;

        editTargetedSingle(this.targeted_sms.id, payload).then((response) => {
          this.saveLoading = false;
          this.$message.success(response.message);
          this.fetchTargeted();
        }).catch((err) => {
          this.saveLoading = false;
          this.$message.error(err.message);
        });
      },
      editName() {
        const payload = {
          name: this.targeted_sms.name,
        };

        editTargetedSingle(this.targeted_sms.id, payload).then((response) => {
          this.$message.success(response.message);
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchTargeted() {
        this.loading = true;
        getTargetedSMSSingle(this.uuid).then((response) => {
          this.targeted_sms = response.targeted_sms;
          this.start_date = response.targeted_sms.settings.start_date;
          this.start_time = response.targeted_sms.settings.start_time;
          this.end_date = response.targeted_sms.settings.end_date;
          this.end_time = response.targeted_sms.settings.end_time;
          this.loading = false;
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      handleCommand(command) {
        if (command === 'delete') {
          this.$confirm('Are you sure you want to delete this?').then(() => {
            this.deleteTargeted();
          });
        }
      },
      deleteTargeted() {
        this.loading = true;
        deleteTargetedSingle(this.targeted_sms.id).then((response) => {
          this.loading = false;
          this.$message.success(response.message);
          this.$router.push('/apps/targeted-sms');
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      copyText(type) {
        if (type === 'incident') {
          this.$copyText('Aqua-Tots Incident').then(() => {
            this.$message.success('Copied!');
          });
        }

        if (type === 'snow') {
          this.$copyText('Snow Day closed!').then(() => {
            this.$message.success('Copied!');
          });
        }
      },
      sendSMS() {
        this.$confirm('Are you sure you want to send this SMS?').then(() => {
          this.sendTargetedSMS();
        });
      },
      sendTargetedSMS() {
        const payload = {
          id: this.targeted_sms.id,
        };
        this.saveLoading = true;
        pushTargetedSMS(payload).then((response) => {
          this.saveLoading = false;
          this.$message.success(response.message);
        }).catch((err) => {
          this.saveLoading = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-container {
    background-color: white;
  }

  .el-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d9e7f0;
  }

  .el-header input {
    text-align: left;
    border: none;
    outline: none;
    width: calc(100% / 3);
    font-size: 1.25rem;
    line-height: 1.4;
    color: #00152a;
  }

  .el-header .actions {
    width: calc(100% / 3);
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .el-header .actions .btn:first-child {
    margin-right: 1rem;
  }

  .el-main {
    height: 100%;
    max-width: 60%;
    padding-bottom: 5rem;
    margin-left: auto;
    margin-right: auto;
  }

  .el-card {
    margin-bottom: 2rem;
  }

  .el-card.estimated {
    width: 30%;
    text-align: center;
  }

  .el-card.estimated .number {
    font-size: 2rem;
    font-weight: bold;
  }

  .el-card.estimated .caption {
    font-size: .875rem;
    color: #617080;
  }

  .options {
    padding: 1.25rem;
    background-color: #f5f8fb;
  }

  .links {
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
  }

  .links a {
    font-size: .875rem;
    color: #00152a;
    text-decoration: underline;
  }

  .display-small {
    margin-bottom: 1rem;
  }
</style>
