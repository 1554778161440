<template>
  <transition name="fade">
    <div class="modal show" style="display: block;" @click="closeModal">
      <div class="modal-dialog" @click.stop>
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            New Verified Email
            <i class="fa fa-times" @click.stop="closeModal"></i>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <label>Email</label>
                <input type="email" v-model="email" class="form-control" placeholder="Email" />
                <div class="small text-muted">We will be sending an email to this address to verify that you own it.</div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="button" class="xpl-button xpl-button--primary" @click.stop="saveEmail" :disabled="!email">Save</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    data() {
      return {
        email: '',
      };
    },
    methods: {
      closeModal() {
        EventBus.$emit('verified_emails_modal.close');
      },
      saveEmail(event) {
        if (!this.email) {
          return;
        }

        const target = event.target || event.srcElement;

        target.disabled = true;

        const payload = {
          email: this.email,
        };

        api.createVerifiedEmails(payload).then((response) => {
          this.$toasted.success(response.message);
          target.disabled = false;
          this.closeModal();
        }).catch((err) => {
          target.disabled = false;
          if (err.errors && err.errors.email) {
            this.$toasted.error(err.errors.email);
          } else {
            this.$toasted.error(err.message);
          }
        });
      },
    },
  };
</script>
