import { authAxios, getContactList } from './index';

export function UIASmartRule(condition) {
  const rule = {
    uuid: 'UIA',
    frontend: true,
    vendor_condition_template_uuid: condition.condition.template_uuid,
    vendor_condition_template_version_uuid: condition.condition.template_version_uuid,
    steps: [],
  };
  condition.condition.steps.forEach((step) => {
    const s = {type: step.type, step: step.step, external_id: step.external_id};
    switch (step.type) {
      case 'timeframe': {
        s.selected_timeframe = step.default.option;
        s.selected_value = step.default.value.filter(x => x != null);
        break;
      }
      case 'multiselect': {
        s.selected_option = step.default.option;
        s.selected_value = step.default.value;
        break;
      }
      case 'count': {
        s.selected_option = step.default.option;
        s.selected_value = step.default.value.filter(x => x != null);
        break;
      }
      case 'radio': {
        const o = step.options.find(x => x.option_id === step.default.option_id);
        s.selected_option = {...o};
        break;
      }
      default:
        break;
    }
    rule.steps.push(s);
  });
  return rule;
}

export function getContactListsAvailableConditionTypesUIA(isDevAccount = false) {
  return new Promise((resolve, reject) => {
    authAxios.put('bridge/core-api', {
      endpoint: `/vendor-core/v1/conditions/list/${isDevAccount ? 'both' : 'active'}`,
    })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export function getMultiselectItems(step) {
  return new Promise((resolve, reject) => {
    authAxios.put('bridge/condition-options', {
      name: step.name,
      external_id: step.external_id,
      api_url: step.api_url
    })
      .then((response) => {
        resolve(response.data || []);
      })
      .catch((err) => {
        reject(err.response.data);
      })
  });
}

// TODO: better caching system
const cache = [];
//
export function getConditionTypeUIA(rule) {
  const templateUuid = rule.vendor_condition_template_uuid || rule.template_uuid;
  // eslint-disable-next-line max-len
  const versionUuid = rule.vendor_condition_template_version_uuid || rule.template_version_uuid || rule.version_uuid;
  return new Promise((resolve, reject) => {
    const url = `/vendor-core/v1/conditions/${templateUuid}/version/${versionUuid}`;
    if (cache[url]) {
      resolve(cache[url]);
      return;
    }
    authAxios.put('bridge/core-api', {
      endpoint: url,
    }).then((response) => {
      const data = response.data.data;
        // helpers
      if (data && data.condition_template_version && data.condition_template) {
          // eslint-disable-next-line max-len
        data.condition_template_version.template_version_uuid = data.condition_template_version.uuid;
        data.condition_template_version.template_uuid = data.condition_template.uuid;
          //
        data.condition_template_version.name = data.condition_template.name;
        data.condition_template_version.version_status = data.condition_template_version.status;
        data.condition_template_version.description = data.condition_template.description;
        data.condition_template_version.icon_url = data.condition_template.icon_url;
      }
      cache[url] = JSON.parse(JSON.stringify(data));
      resolve(data);
    })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function applyTemplateProperties(ruleList) {
  const templates = [];
  // try to get templates 1 by 1
  if (ruleList && ruleList.length) {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of ruleList) {
      const rule = item.condition || item;
      // eslint-disable-next-line max-len
      if (rule && rule.vendor_condition_template_uuid
        && rule.vendor_condition_template_version_uuid
        && !rule.name) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const template = await getConditionTypeUIA(rule);
          templates.push(template.condition_template_version);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      }
    }
  }

  //
  try {
    // inject template's contents
    if (ruleList && ruleList.length) {
      ruleList.forEach((rule) => {
        if (!rule.condition.vendor_condition_template_uuid) {
          return;
        }
        // eslint-disable-next-line max-len
        const template = templates.find(x => x.template_uuid === rule.condition.vendor_condition_template_uuid
          && x.template_version_uuid === rule.condition.vendor_condition_template_version_uuid);

        if (!template) { return; }
        // smart-rule saved values
        const values = rule.condition.steps;

        // pristine template
        rule.condition = template;

        // apply actual values
        values.forEach((val, idx) => {
          const condition = rule.condition.steps[idx];
          switch (condition.type) {
            case 'radio': {
              condition.default = { option_id: val.selected_option.option_id };
              break;
            }
            case 'include_exclude_multiselect':
            case 'multiselect': {
              condition.default = { option: 'any' };
              if (val.selected_option) {
                condition.default.option = val.selected_option;
              }
              if (val.selected_value) {
                condition.default.value = val.selected_value;
              }
              break;
            }
            case 'timeframe': {
              condition.default = {};
              condition.default.option = val.selected_timeframe;
              condition.default.value = val.selected_value;
              break;
            }
            case 'count': {
              condition.default = {};
              condition.default.option = val.selected_option;
              condition.default.value = val.selected_value;
              break;
            }
            default:
              break;
          }
        });
      });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
  return ruleList;
}

export async function getContactListUIA(uuid) {
  const r = await getContactList(uuid);
  const contactList = r.contact_list;
  if (!contactList) {
    return null;
  }
  if (contactList.rule) {
    // contactList.rule = await applyTemplateProperties(contactList.rule);
  }
  return { contact_list: contactList };
}
