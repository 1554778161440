<template>
  <el-container v-if="campaign">
    <el-header>
      <router-link :to="'/campaigns/' + uuid" class="go-back"><i class="fa fa-chevron-left"></i> Go Back</router-link>
      <div class="name">{{ campaign.name }}</div>
      <div></div>
    </el-header>
    <el-main>
      <div class="main-container">
        <el-card>
          <div class="v2__heading">Push to Franchisees</div>
          <el-form>
            <el-form-item>
              <label>Franchisees</label>
              <el-select style="width: 100%;" v-model="selected_franchisees" filterable multiple placeholder="Franchisee Accounts" value-key="id">
                <el-option v-for="item in franchisees" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <div>
                <el-radio v-model="push_type" label="standard">Create a standard copy</el-radio>
              </div>
              <div>
                <el-radio v-model="push_type" label="sync_unlock">Create a synced copy where the Franchisees can only edit the content inside of the auto-items</el-radio>
              </div>
              <div>
                <el-radio v-model="push_type" label="sync_lock">Create a synced copy that is not editable by the Franchisees</el-radio>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="pushCampaign" :loading="loading" :disabled="!selected_franchisees.length || !push_type">Push to Franchisees</el-button>
            </el-form-item>
          </el-form>
        </el-card>

        <div class="display-small" style="margin-bottom: 1rem;">
          Currently Associated
          <el-button size="small" type="primary" @click.stop="syncChanges('rules')" style="float: right; margin-left: 0.5rem;" :loading="syncLoading">Sync Changes to Rules</el-button>
          <el-button size="small" type="warning" @click.stop="syncChanges('items')" style="float: right;" :loading="syncLoading">Sync Changes to Content</el-button>
        </div>
        <el-card shadow="none" v-for="campaign in synced_campaigns" :key="campaign.id">
          <el-row :span="24" style="display: flex; align-items: center;">
            <el-col :span="2" class="text-center">
              <span class="pill pill-success" v-if="campaign.turned_on_at">Live</span>
              <span class="pill pill-danger" v-if="campaign.paused_at">Paused</span>
              <span class="pill pill-warning" v-if="!campaign.paused_at && !campaign.turned_on_at">Draft</span>
            </el-col>
            <el-col :span="2" class="text-center">
              <span class="pill pill-default" v-if="campaign.push_type === 'sync_lock'">Sync Locked</span>
              <span class="pill pill-default" v-if="campaign.push_type === 'sync_unlock'">Sync Unlocked</span>
              <span class="pill pill-default" v-if="campaign.push_type === 'standard'">Standard</span>
            </el-col>
            <el-col :span="8" class="text-center">
              {{ campaign.account_name }}
            </el-col>
            <el-col :span="8">
              <div class="text-small muted" v-if="campaign.turned_on_at">Last set live {{ campaign.turned_on_at | humanDate }}.</div>
              <div class="text-small muted" v-if="campaign.paused_at">Last paused {{ campaign.paused_at | humanDate }}.</div>
              <div class="text-small muted" v-if="!campaign.paused_at && !campaign.turned_on_at">Last on draft mode {{ campaign.updated_at | humanDate }}.</div>
            </el-col>
            <el-col :span="4" class="text-right">
              <el-dropdown trigger="click" @command="handleActions">
                <el-button type="secondary" class="xpl-button xpl-button--secondary"  @click.stop="">Actions</el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="campaign">Go to Campaign</el-dropdown-item>
                  <el-dropdown-item command="'start'" v-if="!campaign.turned_on_at || (!campaign.turned_on_at && !campaign.paused_at)">Start Campaign</el-dropdown-item>
                  <el-dropdown-item command="'pause'" v-if="!campaign.paused_at && campaign.turned_on_at">Pause Campaign</el-dropdown-item>
                  <el-dropdown-item :command="{'sync': true, campaign: campaign}">Sync Items</el-dropdown-item>
                  <el-dropdown-item :command="{'rules': true, campaign: campaign}" v-if="[2, 7, 9304, 8235, 6082, 7145, 9519, 9748,10131, 10132, 10587].indexOf($store.state.user.id) > -1">Sync Rule</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </el-main>
  </el-container>
</template>
<script>
  import {
    switchMembership,
    getChildrenAccounts,
    getCampaign,
    pushToFranchisees,
    syncedFranchiseCampaigns,
    syncChangesCampaigns } from '@/api';

  export default {
    props: ['uuid'],
    data() {
      return {
        campaign: null,
        selected_franchisees: [],
        push_type: null,
        franchisees: [],
        loading: false,
        synced_campaigns: [],
        syncLoading: false,
      };
    },
    created() {
      this.fetchCampaign();
      this.fetchFranchisees();
    },
    methods: {
      switchAccount(accountId, redirectCampaign) {
        const payload = {
          account_id: accountId,
          redirect_url: `/campaigns/${redirectCampaign}`,
        };

        this.switchLoading = true;

        switchMembership(payload).then((response) => {
          this.$store.dispatch('refreshUser').then(() => {
            this.$message.success(response.message);
            if (response.redirect_url) {
              window.location.href = response.redirect_url;
            } else {
              window.location.href = '/calendar';
            }
            this.switchLoading = false;
          });
        }).catch((err) => {
          this.switchLoading = false;
          this.$message.error(err.message);
        });
      },
      handleActions(command) {
        if (command.uuid) {
          this.switchAccount(command.account_id, command.uuid);
        }

        if (command === 'start') {
          this.$message.warning('Not built yet.');
        }

        if (command === 'pause') {
          this.$message.warning('Not built yet.');
        }

        if (command.sync && command.campaign) {
          this.syncChanges('items', command.campaign.id);
        }

        if (command.rules && command.campaign) {
          this.syncChanges('rules', command.campaign.id);
        }

        return true;
      },
      syncChanges(option, campaignId = null) {
        this.syncLoading = true;
        const syncTypeUnlock = this.synced_campaigns.filter(c => c.push_type === 'sync_unlock');

        if (syncTypeUnlock.length) {
          this.$confirm('Some of your campaign are Synced but Editable by your Franchisees. Any changes made by them will be overriten if you choose to apply your changes. Proceed?').then(() => {
            this.postSyncChanges(option, campaignId);
          }).catch(() => {
            this.syncLoading = false;
          });
        } else {
          this.postSyncChanges(option, campaignId);
        }
      },
      postSyncChanges(option, campaignId = null) {
        const payload = {
          sync_option: option,
          franchise_campaign_id: this.campaign.id,
        };

        if (campaignId) {
          payload.franchisee_campaign_ids = [campaignId];
        } else {
          payload.franchisee_campaign_ids = this.synced_campaigns.filter(c => c.push_type !== 'standard').map(c => c.id);
        }
        syncChangesCampaigns(payload).then((response) => {
          this.$message.success(response.message);
          this.syncLoading = false;
        }).catch((err) => {
          this.syncLoading = false;
          this.$message.error(err.message);
        });
      },
      fetchFranchisees() {
        getChildrenAccounts().then((response) => {
          this.franchisees = response.children;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchCampaign() {
        getCampaign(this.uuid).then((response) => {
          this.campaign = response.campaign;
          this.fetchSyncedCampaigns();
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      pushCampaign() {
        const payload = {
          campaign_id: this.campaign.id,
          franchisees: this.selected_franchisees,
          push_type: this.push_type,
        };

        this.loading = true;

        pushToFranchisees(payload).then((response) => {
          this.$message.success(response.message);
          this.loading = false;
          this.fetchSyncedCampaigns();
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      fetchSyncedCampaigns() {
        const payload = {
          franchise_campaign_id: this.campaign.id,
        };
        this.loading = true;
        syncedFranchiseCampaigns(payload).then((response) => {
          this.synced_campaigns = response.campaigns;
          this.loading = false;
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .el-header .name {
    font-size: 1.625rem;
  }

  .el-main {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 4rem;
  }

  .main-container {
    max-width: 73.75rem;
    margin-right: auto;
    margin-left: auto;
  }

  .display-small {
    margin-top: 2rem;
  }
</style>
