<template>
  <el-container class="condition-container">
  <div class="sidebar-inner" style="width: 100%;">
      <BooleanField
          fieldIndex="0"
          title="Customers Who Purchased / Did Not Purchase?"
          fieldStep="1"
          yesText="Has Purchased"
          noText="Has Not Purchased"
          :selection.sync="selection"
          :showLabel="false"
          descLabel="Options include location, credit type, membership type, status and more"
          id="33"
        />
      <TimeFrameField
          fieldIndex="1"
          title="When Was The Purchase Made?"
          fieldStep="2"
          :selectedOption.sync="timeframeComparison"
          :valueOne.sync="timeframeValue"
          pastLabel="days ago"
          :hidePast="false"
          :hideFuture="true"
          :hideTimeType="false"
          :goalList="goalList"
          id="22"
        />
      <BooleanField
          fieldIndex="2"
          title="Which Purchase(s)?"
          fieldStep="3"
          yesText="Any purchase"
          noText="A specific purchase type"
          :selection.sync="selectionPurchase"
          :showLabel="true"
          descLabel="Options include purchase type, purchase category, and location"
          id="23"
        />
      <div v-if="!selectionPurchase" class="condition-banner-style">
        Drill Down Options
      </div>
      <MultiSelectField
          v-if="!selectionPurchase"
          fieldIndex="3"
          title="Which Type of Purchase?"
          fieldStep="4"
          anyLabel="Any purchase"
          specificLabel="Specific purchases"
          specificAnyLabel="Any of the following purchases"
          specificExclusionLabel="Any except the following"
          :selectedOptions.sync="selectedPurchases"
          :specificChoice.sync="purchaseType"
          :exclusionChoice.sync="purchaseExclusionOption"
          :options="purchases"
          :type-to-search="true"
          selectKey="id"
          id="452"
        />
         <MultiSelectField
          v-if="!selectionPurchase"
          fieldIndex="4"
          title="Which Purchase Category?"
          fieldStep="5"
          anyLabel="Any purchase category"
          specificLabel="Specific purchase category"
          specificAnyLabel="Any of the following categories"
          specificExclusionLabel="Any except the following"
          :selectedOptions.sync="selectedCategories"
          :specificChoice.sync="purchaseCategoryType"
          :exclusionChoice.sync="categoryExclusionOption"
          :options="purchaseCategories"
          selectKey="id"
          id="879"
        />
       <MultiSelectField
          v-if="!selectionPurchase"
          fieldIndex="5"
          title="Which Purchase Location(s)?"
          fieldStep="6"
          anyLabel="Any location in my acccount"
          specificLabel="Specific location(s) in my account"
          specificAnyLabel="Any of the following locations"
          specificExclusionLabel="Any except the following"
          :selectedOptions.sync="selectedLocations"
          :specificChoice.sync="selectedLocationOption"
          :exclusionChoice.sync="exclusionLocationOption"
          :options="locations"
          selectKey="id"
          id="8129"
        />
    </div>
    <div class="save-condition-container" v-if="showSaveButton">
      <el-button class="save-condition-btn" type="primary" @click="commitCondition">Save</el-button>
    </div>
  </el-container>
</template>
<script>
import * as api from '@/api';
import EventBus from '@/event-bus';
import TimeFrameField from '@/components/Contacts/ContactLists/Conditions/Fields/TimeFrameField';
import MultiSelectField from '@/components/Contacts/ContactLists/Conditions/Fields/MultiSelectField';
import BooleanField from '@/components/Contacts/ContactLists/Conditions/Fields/BooleanField';

export default {
  props: ['condition', 'editedRule', 'goalList'],
  components: {
    TimeFrameField,
    MultiSelectField,
    BooleanField,
  },
  data() {
    return {
      selection: true,
      timeframeComparison: null,
      timeframeValue: null,
      selectionPurchase: true,
      purchaseType: 'any',
      purchaseExclusionOption: 'any',
      selectedPurchases: [],
      purchases: [],
      purchaseCategoryType: 'any',
      categoryExclusionOption: 'any',
      selectedCategories: [],
      purchaseCategories: [],
      locations: [],
      selectedLocationOption: 'any',
      selectedLocations: [],
      exclusionLocationOption: 'any',
    };
  },
  created() {
    this.fetchPurchaseCategories();
    this.fetchLocations();
    this.fetchPurchases();
    if (this.editedRule) {
      this.selection = this.editedRule.all_made_purchase;
      this.timeframeComparison = this.editedRule.purchase_comparison;
      this.timeframeValue = this.editedRule.purchase_value;

      this.selectionPurchase = this.editedRule.all_purchase_types;

      this.selectedPurchases = this.editedRule.purchases;
      this.purchaseExclusionOption = this.editedRule.purchase_exclusion;
      this.purchaseType = this.editedRule.purchase_option;

      this.selectedCategories = this.editedRule.purchase_categories;
      this.categoryExclusionOption = this.editedRule.purchase_category_exclusion;
      this.purchaseCategoryType = this.editedRule.purchase_category_option;

      this.selectedLocations = this.editedRule.purchase_locations;
      this.exclusionLocationOption = this.editedRule.purchase_location_exclusion;
      this.selectedLocationOption = this.editedRule.purchase_location_option;
    }
  },
  computed: {
    showSaveButton() {
      if (!this.timeframeComparison) {
        return false;
      }
      if ((this.timeframeComparison === 'before' || this.timeframeComparison === 'after' || this.timeframeComparison === 'between' || this.timeframeComparison === 'specificDate') && Number.isInteger(this.timeframeValue)) {
        return false;
      }

      if (this.timeframeComparison === 'between' && !this.timeframeValue.length) {
        return false;
      }
      if (!this.selectionPurchase) {
        if (
          this.purchaseType === 'specific' &&
          !this.selectedPurchases.length
        ) {
          return false;
        }
        if (
          this.purchaseCategoryType === 'specific' &&
          !this.selectedCategories.length
        ) {
          return false;
        }
        if (
          this.selectedLocationOption === 'specific' &&
          !this.selectedLocations.length
        ) {
          return false;
        }
        return true;
      }
      return true;
    },
  },
  methods: {
    async fetchPurchases() {
      try {
        const response = await api.MTProducts();
        this.purchases = response.products;
      } catch (err) {
        this.$toasted.error(err.message);
      }
    },
    async fetchPurchaseCategories() {
      try {
        const response = await api.MTProductClasses();
        this.purchaseCategories = response.product_classes;
      } catch (err) {
        this.$toasted.error(err.message);
      }
    },
    async fetchLocations() {
      try {
        const response = await api.getMTLinkedLocations();
        this.locations = response.locations;
      } catch (err) {
        this.$toasted.error(err.message);
      }
    },
    commitCondition() {
      const payload = {
        condition: {
          uuid: this.condition.uuid,
          name: this.condition.name,
          unique: this.condition.unique,
        },
        all_made_purchase: this.selection,
        purchase_comparison: this.timeframeComparison,
        purchase_value: this.timeframeValue,
        all_purchase_types: this.selectionPurchase,
        purchases: this.selectedPurchases,
        purchase_exclusion: this.purchaseExclusionOption,
        purchase_option: this.purchaseType,
        purchase_categories: this.selectedCategories,
        purchase_category_exclusion: this.categoryExclusionOption,
        purchase_category_option: this.purchaseCategoryType,
        purchase_locations: this.selectedLocations,
        purchase_location_exclusion: this.exclusionLocationOption,
        purchase_location_option: this.selectedLocationOption,
      };

      EventBus.$emit('conditions_selection.commit', payload);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/scss/global/_spacing.scss";
@import "src/scss/global/_new_main.scss";
.field-container {
  margin-top: 28px;
}
.option-body {
  margin-top: 20px;
}
.card-condition {
  background-color: #ffffff;
  border: solid 1px var(--xpl-color-gray-400);
  box-shadow: none;
  border-radius: 0;
  cursor: auto;
}
.card-condition:hover {
  background-color: #ffffff;
  cursor: auto;
}
.field-step {
  height: 1.5rem;
  width: 1.5rem;
  background-color: $bb-color-blue-v2;
  font-family: $primary-bb-font-family;
  font-size: $base-font-size;
  color: #ffffff;
  border-radius: 50%;
  text-align: center;
  margin: $space-base-inline;
}
.field-title {
  font-family: $primary-bb-font-family;
  font-weight: 700;
  font-size: $base-font-size;
  color: $base-color-black-v2;
  line-height: $loose-medium-line-height;
}
.radio-btn {
  margin-top: $space-extra-tight;
  margin-bottom: $space-extra-tight;
}
.field-label-text {
  display: block;
}
.field-label-text /deep/ .el-radio__label {
  font-family: $primary-bb-font-family !important;
  font-size: $base-loose-font-size !important;
  color: $base-color-black-v2 !important;
  line-height: $loose-line-height !important;
  padding-left: 8px;
}
.field-label-text /deep/ .el-radio__inner {
  background-color: #f2f4f6 !important;
  border: 1px solid #a6b2bf !important;
  height: 16px;
  width: 16px;
}
.field-label-text /deep/ .el-radio__input.is-checked .el-radio__inner::after {
  background-color: var(--xpl-color-secondary-lm) !important;
  width: 12px;
  height: 12px;
}
.condition-container {
  background-color: #f4f8fa;
  height: 100%;
  width: 100%;
}
.field-container:last-of-type {
  padding-bottom: 100px;
}
.sidebar-inner {
  height: 100%;
  position: relative;
  padding-top: 0;
  background-color: #f4f8fa;
  padding-left: 2.625rem;
  padding-right: 2.25rem;
  padding-bottom: 0;
  width: 100%;
}
.save-condition-btn {
  width: 729px;
  margin: 16px 12px 16px 12px;
}
.save-condition-container {
  // box-shadow: 0 -6px 7px 0 rgba(0, 0, 0, 0.05);
  padding: 0;
  margin: 0;
  z-index: 1;
}
.condition-banner-style {
  background-color: #f4f8fa !important;
  margin-top: 13px !important;
  padding-bottom: 0;
}
</style>

