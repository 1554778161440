<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Name this Campaign Goal
            <div class="btn-close-wrapper" @click.stop="closeModal(null)">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <label>Name</label>
                <input type="text" v-model="name" class="form-control" />
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="submit" class="xpl-button xpl-button--primary" :disabled="!name" @click="createGoal">Create</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['campaignId'],
    data() {
      return {
        name: '',
      };
    },
    methods: {
      closeModal(payload = null) {
        EventBus.$emit('campaign_goal_name.modal.close', payload);
      },
      createGoal() {
        const payload = {
          name: this.name,
          action: 'new_goal',
        };

        api.addNewGoalCampaign(this.campaignId, payload).then((response) => {
          this.closeModal(response);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
