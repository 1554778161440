<template>
  <div>
    <div class="header">
      <h1>Scheduled</h1>
      <form @submit.prevent v-if="!loading && results.length">
        <div class="input-group">
          <span class="input-group-addon">
            <span class="icon icon-search"></span>
          </span>
          <input type="search" name="query" v-model.trim="query" class="form-control" placeholder="Search" />
        </div>
      </form>
    </div>
    <div class="datatable" v-if="!loading && results.length">
      <table class="table bb-datatable">
        <thead>
          <tr>
            <td>Contact</td>
            <td class="text-right">Scheduled For</td>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading">
            <td class="text-center" colspan="2"><i class="fa fa-spin fa-spinner fa-fw"></i></td>
          </tr>
          <tr v-for="result in filteredResults" v-if="!loading">
            <td>
              <router-link :to="'/contacts/' + result.uuid">{{ result.email }}</router-link>
            </td>
            <td class="text-right">{{ result.latest_timestamp | humanDate(false) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="illustration" v-if="!loading && !results.length">
      <img src="https://assets.brandbot.com/public/empty-states/analytics-scheduled.svg" />
    </div>
  </div>
</template>
<script>
  import * as api from '@/api';

  export default {
    props: ['campaignItemId'],
    data() {
      return {
        loading: true,
        results: [],
        query: '',
      };
    },
    created() {
      // This component does not behave like its siblings because
      // the Scheduled items are Campaign specific and not
      // pulled from Sendgrid Events.

      api.contactsScheduledEmail(this.campaignItemId).then((response) => {
        this.results = response.contacts;
        this.loading = false;
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    computed: {
      filteredResults() {
        if (this.query.length) {
          return this.results.data.filter(r =>
            r.email.toLowerCase().indexOf(this.query.toLowerCase()) > -1,
          );
        }

        return this.results;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .header {
    margin-top: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h1 {
    text-transform: capitalize;
    font-size: 1.25rem;
    font-weight: normal;
    color: #212B36;
    line-height: 1.5rem;
  }

  .illustration img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 10rem;
    margin-bottom: 2rem;
  }
</style>
