<template>
  <div>
    <div class="nav-subheader">
      <div class="nav-inner">
        <div>Apps</div>
      </div>
    </div>
    <div class="apps-container">
      <div class="apps">
        <div class="app" @click.stop="$router.push('/\apps/landing-pages')" v-if="!$store.getters.isPike13 && !$store.getters.isUIAVendor">
          <img src="https://assets.brandbot.com/public/icons/apps/landing-page.svg" alt="Landing Pages" />
          <div class="title">Checkout Landing Pages</div>
          <div class="description">Create landing pages that allow your customers to make purchases.</div>
        </div>
        <div class="app" @click.stop="$router.push('/apps/forms')">
          <img src="https://assets.brandbot.com/public/icons/apps/forms.svg" alt="Forms"/>
          <div class="title">Forms</div>
          <div class="description">Create hosted and embeddable forms to capture leads.</div>
        </div>
        <div class="app" @click.stop="$router.push('/apps/waivers')">
          <img src="https://assets.brandbot.com/public/icons/apps/waivers.svg" alt="Waivers"/>
          <div class="title">Waivers</div>
          <div class="description">Create hosted waivers.</div>
        </div>
        <div class="app" @click.stop="$router.push('/apps/class-reminders')">
          <span class="pill pill-secondary pull-right" v-if="$store.getters.isMT || $store.getters.isPike13">BETA</span>
          <img src="https://assets.brandbot.com/public/icons/apps/class-reminders.svg" alt="Class Reminders" />
          <div class="title">
            {{$store.getters.brandName}} LiveStream Manager
          </div>
          <div class="description">Send class reminders to your clients with custom messaging.</div>
        </div>
        <div class="app" @click.stop="$router.push('/apps/targeted-sms')"
             v-if="$store.state.user.current_account.sms_enabled && $store.state.user.current_account.franchises && $store.state.user.current_account.franchises.indexOf('aqua-tots') > -1">
          <img src="https://assets.brandbot.com/public/icons/apps/waivers.svg" alt="Targeted SMS"/>
          <div class="title">Targeted SMS</div>
          <div class="description">Send targeted SMS communication to your studios.</div>
        </div>
        <div class="app" @click.stop="$router.push('/apps/sheer-id')"
             v-if="[1458, 1939].indexOf($store.state.user.current_account.id) > -1">
          <img src="https://assets.brandbot.com/public/icons/apps/sheerid.png" alt="Sheer ID" style="width: auto;"/>
          <div class="title">SheerID Integration</div>
          <div class="description">SheerID Integration</div>
        </div>
        <!-- <div class="app inactive" v-if="$store.state.user.current_account.active_vendors.indexOf('pike13') === -1">
          <span class="pill pill-warning pull-right">Coming Soon</span>
          <img src="https://assets.brandbot.com/public/icons/apps/nps.svg" alt="Net Promoter Score" />
          <div class="title">Net Promoter Score &amp; Feedback Requests</div>
          <div class="description">Gather real feedback from your customers by sending Net Promoter Score emails or freeform surveys.</div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.apps-container {
  display: block;
  padding-top: 5rem;
  max-width: 68rem;
  margin-left: auto;
  margin-right: auto;
}

.apps {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
}

.app {
  flex: 0 1 25%;
  // box-shadow: 0 2px 4px 0 rgba(217, 231, 240, 0.31);
  border: solid 1px var(--xpl-color-gray-400);
  border-radius: 6px;
  background-color: #ffffff;
  height: 16rem;
  padding: 1.5rem;
  transition: all 150ms ease-in-out;
}

.app {
  margin-right: 1.75rem;
  margin-bottom: 1rem;
}

.apps .app:last-child {
  margin-right: 0;
}

.apps .app:hover {
  cursor: pointer;
  border-color: var(--xpl-color-secondary-lm);
}

.apps .app.inactive:hover {
  cursor: initial;
}

.apps .app .title {
  font-size: 1rem;
  font-weight: 700;
  color: #00152a;
  margin-bottom: .5rem;
}

.apps .app .description {
  font-size: .875rem;
  line-height: 1.43;
  color: #617080;
}

.apps .app img {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  margin-bottom: 1.25rem;
}
</style>
