<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Add to Campaign
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <el-form>
              <label>Campaign</label>
              <el-form-item>
                <el-select v-model="campaignId" placeholder="Select a campaign" style="width: 100%;">
                    <el-option v-for="item in campaigns"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="modal-footer">
            <span></span>
            <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="addToCampaign" :loading="loading" :disabled="!campaignId">Add</el-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['contacts', 'allContacts', 'contactListId'],
    data() {
      return {
        loading: false,
        campaignId: null,
        campaigns: [],
      };
    },
    created() {
      this.fetchCampaigns();
    },
    methods: {
      fetchCampaigns() {
        api.getCampaignsSmall().then((response) => {
          this.campaigns = response.campaigns;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      addToCampaign() {
        const payload = {
          campaign_id: this.campaignId,
          contact_ids: this.contacts.map(c => c.id),
          all_contacts: this.allContacts,
          contact_list_id: this.contactListId,
        };

        this.loading = true;

        api.massAddToCampaign(payload).then((response) => {
          this.$toasted.success(response.message);
          this.closeModal();
          this.loading = false;
        }).catch((err) => {
          this.loading = false;
          this.$toasted.error(err.message);
        });
      },
      closeModal() {
        EventBus.$emit('add_to_campaign.close');
      },
    },
  };
</script>
