<template>
  <el-main>
    <router-link to="/apps/class-reminders" class="go-back"><i class="fa fa-chevron-left"></i> Go Back</router-link>
    <el-row :span="24" class="class-name">
      <el-col :span="24" v-if="class_detail">{{ class_detail.class_name }}</el-col>
      <el-dropdown type="secondary" class="pull-right" @command="handleCommand">
        <el-button type="secondary" class="xpl-button xpl-button--secondary" >
          Options<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="email-registration" v-if="$store.state.user.current_account.active_vendors.indexOf('zingfit') === -1">Send a Test Email for Registration</el-dropdown-item>
          <el-dropdown-item command="email-hour">Send a Test Email for the Hour Reminder</el-dropdown-item>
          <el-dropdown-item command="email-fifteen_minutes">Send a Test Email for the 15 minutes Reminder</el-dropdown-item>
          <el-dropdown-item command="email-five_minutes">Send a Test Email for the 5 minutes Reminder</el-dropdown-item>
          <hr>
          <el-dropdown-item command="sms-registration" v-if="$store.state.user.current_account.active_vendors.indexOf('zingfit') === -1 && $store.state.user.current_account.active_vendors.indexOf('pike13') === -1">Send a Test SMS for Registration</el-dropdown-item>
          <el-dropdown-item command="sms-hour" v-if="$store.state.user.current_account.active_vendors.indexOf('zingfit') === -1 && $store.state.user.current_account.active_vendors.indexOf('pike13') === -1">Send a Test SMS for the Hour Reminder</el-dropdown-item>
          <el-dropdown-item command="sms-fifteen_minutes" v-if="$store.state.user.current_account.active_vendors.indexOf('zingfit') === -1 && $store.state.user.current_account.active_vendors.indexOf('pike13') === -1">Send a Test SMS for the 15 minutes Reminder</el-dropdown-item>
          <el-dropdown-item command="sms-five_minutes" v-if="$store.state.user.current_account.active_vendors.indexOf('zingfit') === -1 && $store.state.user.current_account.active_vendors.indexOf('pike13') === -1">Send a Test SMS for the 5 minutes Reminder</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-row>

    <el-row :span="24" class="top-banner" v-if="class_detail">
      <el-col :span="24">
        <el-card :class="[class_detail.active ? '' : 'inactive']">
          <div class="inner">
            <span v-if="class_detail.active">This class will receive automated reminders</span>
            <span v-if="!class_detail.active">This class IS NOT set to receive automated reminders</span>
            <el-button type="secondary" class="xpl-button xpl-button--secondary"  size="small" @click="changeStatusClass" :loading="loadingClassDetail" v-if="class_detail.active">Turn Off for This Class</el-button>
            <el-button type="secondary" class="xpl-button xpl-button--secondary"  size="small" @click="changeStatusClass" :loading="loadingClassDetail" v-if="!class_detail.active">Turn On for This Class</el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row :span="24" class="class-description" v-if="class_detail">
      <el-col :span="24">
        <el-card>
          <div class="instructor">
            <span class="material-icons">account_box</span>
            {{ class_detail.instructor_first_name }} {{ class_detail.instructor_last_name }}
          </div>
          <div class="start-time">
            <span class="material-icons">query_builder</span>
            {{ class_detail.start_time | humanTime }}
          </div>
          <div class="location" v-if="class_detail.location_name">
            <span class="material-icons">place</span>
            {{ class_detail.location_name }}
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row :span="24" class="class-links" v-if="class_detail">
      <el-col :span="24">
        <el-card>
          <el-form>
            <el-form-item>
              <label>Virtual Link Here</label>
              <el-input v-model="class_detail.virtual_link" placeholder="Virtual Link Here" />
            </el-form-item>
            <el-form-item>
              <label>Playlist Link Here (Optional)</label>
              <el-input v-model="class_detail.playlist_link" placeholder="Playlist Link Here (Optional)" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="xpl-button xpl-button--primary":loading="loadingLinks" @click="updateLinks">Save Changes</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

    <el-row :span="24" class="class-roster">
      <el-col :span="24">
        <el-card>
          <el-row :span="24">
            <el-col :span="12">
              All Time Visits Shoutouts
            </el-col>
            <el-col :span="12" class="roster-heading">
              <el-button type="secondary" size="small" class="pull-right xpl-button xpl-button--secondary" @click="fetchRoster" :loading="loadingRoster">Show Shoutouts</el-button>
            </el-col>
          </el-row>
          <el-table :data="roster" style="width: 100%;">
            <el-table-column prop="name" label="Name"></el-table-column>
            <el-table-column prop="all_visits" label="All Time Visits" align="right"></el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
  </el-main>
</template>
<script>
  import moment from 'moment';
  import {
    getClassDetail,
    getClassRoster,
    updateClassDetail,
    classRemindersSendTestSMS,
    classRemindersSendTestEmail,
  } from '@/api';

  export default {
    props: ['uuid'],
    data() {
      return {
        class_detail: null,
        roster: [],
        loadingClassDetail: false,
        loadingRoster: false,
        loadingLinks: false,
      };
    },
    created() {
      this.fetchClassDetail();
    },
    filters: {
      humanTime(datetime) {
        return moment(datetime).format('h:mma');
      },
    },
    methods: {
      handleCommand(command) {
        const payload = {
          type: command.split('-')[1],
          class_id: this.class_detail.class_id,
        };
        if (command.indexOf('email') > -1) {
          // This is an email test
          this.$prompt('Please input your e-mail', {
            confirmButtonText: 'Send',
            cancelButtonText: 'Cancel',
            inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
            inputErrorMessage: 'Invalid Email',
          }).then(({ value }) => {
            payload.email = value;
            this.sendTestEmail(payload);
          });
        }

        if (command.indexOf('sms') > -1) {
          // This is an sms test
          this.$prompt('Please input your mobile number', {
            confirmButtonText: 'Send',
            cancelButtonText: 'Cancel',
          }).then(({ value }) => {
            payload.phone = value;
            this.sendTestSMS(payload);
          });
        }
      },
      sendTestSMS(payload) {
        classRemindersSendTestSMS(payload).then((response) => {
          this.$message.success(response.message);
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      sendTestEmail(payload) {
        classRemindersSendTestEmail(payload).then((response) => {
          this.$message.success(response.message);
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      fetchClassDetail() {
        getClassDetail(this.uuid).then((response) => {
          this.class_detail = response.class_detail;
        });
      },
      changeStatusClass() {
        this.loadingClassDetail = true;
        const payload = {
          active: !this.class_detail.active,
        };
        updateClassDetail(this.uuid, payload).then((response) => {
          this.loadingClassDetail = false;
          this.$message.success(response);
          this.fetchClassDetail();
        }).catch((err) => {
          this.loadingClassDetail = false;
          this.$message.error(err.message);
        });
      },
      updateLinks() {
        this.loadingLinks = true;
        const payload = {
          virtual_link: this.class_detail.virtual_link,
          playlist_link: this.class_detail.playlist_link,
        };
        updateClassDetail(this.uuid, payload).then((response) => {
          this.loadingLinks = false;
          this.$message.success(response);
          this.fetchClassDetail();
        }).catch((err) => {
          this.loadingLinks = false;
          this.$message.error(err.message);
        });
      },
      fetchRoster() {
        this.loadingRoster = true;

        const payload = {
          uuid: this.uuid,
        };

        getClassRoster(payload).then((response) => {
          this.loadingRoster = false;
          this.roster = response.roster;
        }).catch((err) => {
          this.loadingRoster = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-main {
    margin-top: 3rem;
    width: 63.25rem;
    margin-left: auto;
    margin-right: auto;
  }

  .class-name {
    margin-top: 1.875rem;
    font-size: 1.625rem;
    letter-spacing: 0.15px;
    color: #00152a;
    display: flex;
    justify-content: space-between;
  }

  .top-banner {
    margin-top: 1rem;
    margin-bottom: .625rem;
  }

  .top-banner .inactive {
    background-color: #f2bebe;
  }

  .top-banner .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    color: #00152a;
  }

  .class-description {
    margin-bottom: 1.5rem;
  }

  .class-links {
    margin-bottom: 1.875rem;
  }

  .instructor {
    font-size: .875rem;
    font-weight: 700;
    color: #17202c;
    display: flex;
    align-items: center;
  }

  .instructor .material-icons {
    font-size: .875rem;
    margin-right: .625rem;
  }

  .start-time {
    font-size: .875rem;
    font-weight: 700;
    color: #17202c;
    display: flex;
    align-items: center;
  }

  .start-time .material-icons {
    font-size: .875rem;
    margin-right: .625rem;
  }

  .location {
    font-size: .875rem;
    font-weight: 700;
    color: #17202c;
    display: flex;
    align-items: center;
  }

  .location .material-icons {
    font-size: .875rem;
    margin-right: .625rem;
  }

  .roster-heading {
    font-size: 1rem;
    font-weight: 700;
    color: #00152a;
  }
</style>
