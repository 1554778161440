<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner">
      Event Qualifier
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="!has_tag" @click="has_tag = true"></span>
            <span class="icon icon-radio-checked" v-if="has_tag" @click="has_tag = true"></span>
          </div>
          <div class="col-xs-10">
            <label @click="has_tag = true">Has specific tag(s)</label>
            <div class="small text-muted">Get contacts who are assigned specific tag(s).</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="has_tag" @click="has_tag = false"></span>
            <span class="icon icon-radio-checked" v-if="has_tag === false" @click="has_tag = false"></span>
          </div>
          <div class="col-xs-10">
            <label @click="has_tag = false">Does not have specific tag(s)</label>
            <div class="small text-muted">Get contacts who are not assigned specific tag(s).</div>
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Tag Details
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <label>Get all contacts who are tagged with the following tag(s)</label>
        <div class="multiselect-block">
          <Multiselect v-model="selected_tags" placeholder="Select tags" :options="tags" label="name" track-by="id" :searchable="true" :multiple="true" :allow-empty="false" :show-labels="false" :close-on-select="false" />
        </div>
      </div>
    </form>

    <div class="form-group">
      <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition" :disabled="!selected_tags.length">Save this condition</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['condition', 'editedRule'],
    components: {
      Multiselect,
    },
    data() {
      return {
        has_tag: true,
        selected_tags: [],
        tags: [],
      };
    },
    created() {
      this.fetchTags();

      if (this.editedRule) {
        this.has_tag = this.editedRule.has_tag;
        this.selected_tags = this.editedRule.selected_tags;
      }
    },
    methods: {
      fetchTags() {
        api.getCustomTags().then((response) => {
          this.tags = response.tags;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          has_tag: this.has_tag,
          selected_tags: this.selected_tags,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar-inner {
    padding-bottom: 5rem;
    min-height: 70vh;
  }

  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }
</style>
