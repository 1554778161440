<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            <span v-if="type === 'email'">Name this Auto Email</span>
            <span v-if="type === 'task'">Name this Auto Task</span>
            <span v-if="type === 'phone_call'">Name this Auto Phone Call Reminder</span>
            <span v-if="type === 'tag'">Name this Auto Tag</span>
            <span v-if="type === 'sms'">Name this Auto SMS</span>
            <span v-if="type === 'mtAction'">Name this Mariana Tek Action</span>
            <span v-if="type === 'webHook'">Name this Webhook</span>
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <label>Name</label>
                <input type="text" v-model="name" class="form-control" @keyup.enter="createCampaignItem" />
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="submit" ref="createBtn" class="xpl-button xpl-button--primary" :disabled="!name" @click="createCampaignItem">Create</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['type', 'campaignId', 'campaignItems'],
    data() {
      return {
        name: '',
      };
    },
    methods: {
      closeModal() {
        EventBus.$emit('campaign_item_name.modal.close');
      },
      createCampaignItem() {
        if (this.type === 'mtAction') {
          const payload = {
            type: 'tag',
            name: this.name,
            campaign_id: this.campaignId,
          };
          this.$refs.createBtn.disabled = true;
          this.$refs.createBtn.innerHTML = '<i class="fa fa-fw fa-spin fa-spinner"></i>';
          api.createCampaignItem(payload).then((response) => {
            this.$router.push({name: 'campaigns items', params:{uuid: response.campaign_item.uuid, campaignItems: [], campaign_id: this.campaignId}})
            this.closeModal();
          }).catch((err) => {
            this.$refs.createBtn.disabled = false;
            this.$refs.createBtn.innerHTML = 'Create';
            this.$toasted.error(err.message);
          });
        } else if (this.type === 'webHook') {
          const payload = {
            type: 'sms',
            name: this.name,
            campaign_id: this.campaignId,
          };
          this.$refs.createBtn.disabled = true;
          this.$refs.createBtn.innerHTML = '<i class="fa fa-fw fa-spin fa-spinner"></i>';

          api.createCampaignItem(payload).then((response) => {
            this.$router.push({name: 'campaigns items', params:{uuid: response.campaign_item.uuid, campaignItems: [], campaign_id: this.campaignId}})
            this.closeModal();
          }).catch((err) => {
            this.$refs.createBtn.disabled = false;
            this.$refs.createBtn.innerHTML = 'Create';
            this.$toasted.error(err.message);
          });
        } else {
          const payload = {
            type: this.type,
            name: this.name,
            campaign_id: this.campaignId,
          };
          this.$refs.createBtn.disabled = true;
          this.$refs.createBtn.innerHTML = '<i class="fa fa-fw fa-spin fa-spinner"></i>';

          api.createCampaignItem(payload).then((response) => {
            this.$router.push({name: 'campaigns items', params:{uuid: response.campaign_item.uuid, campaignItems: [], campaign_id: this.campaignId}})
            this.closeModal();
          }).catch((err) => {
            this.$refs.createBtn.disabled = false;
            this.$refs.createBtn.innerHTML = 'Create';
            this.$toasted.error(err.message);
          });
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
