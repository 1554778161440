<template>
  <div>
    <div class="header">
      <div class="btn-close-wrapper" @click.stop="goBack">
        <span class="material-icons">arrow_back</span>
      </div>
      Select a layout to drag into your email
    </div>
    <div class="blocks">
      <div class="block" v-for="block in blocks">
        <img :src="'https://assets.brandbot.com/public/emails/blocks/' + block.name.replace(/ /g, '-').toLowerCase() + '.png'" draggable="true" @drag="onDrag($event)" @dragend="onDragEnd($event)" :data-body="block.body" />
      </div>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['currentSection', 'editingFooter'],
    data() {
      return {
        dragged: null,
        blocks: [],
      };
    },
    created() {
      api.getBlocksForSection(this.currentSection.uuid).then((response) => {
        this.blocks = response.blocks;
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    mounted() {
      document.addEventListener('drop', this.onDrop, false);
    },
    beforeDestroy() {
      document.removeEventListener('drop', this.onDrop, false);
    },
    methods: {
      goBack() {
        EventBus.$emit('selection_block.close');
      },
      onDrop(event) {
        event.preventDefault();
        let nextSiblingUuid;
        let previousSiblingUuid;
        let position;
        if (event.target.nextSibling.className && event.target.nextSibling.className.indexOf('mj-wrapper')) {
          position = 'before';
          nextSiblingUuid = Array.from(event.target.nextSibling.classList).filter(c => c.indexOf('bb-uuid') > -1)[0];
        } else {
          position = 'after';
          previousSiblingUuid = Array.from(event.target.previousSibling.classList).filter(c => c.indexOf('bb-uuid') > -1)[0];
        }

        EventBus.$emit('editor.drop.element', position, nextSiblingUuid || previousSiblingUuid, JSON.stringify(this.dragged));
      },
      onDrag(draggedElement) {
        if (!this.dragged) {
          this.$set(this, 'dragged', JSON.parse(draggedElement.target.dataset.body));
        }

        const mjWrappersWithoutFooter = document.querySelectorAll('div.mj-wrapper:not(.bb-footer)');
        const areaBefore = document.createElement('div');
        areaBefore.innerHTML = 'Drop here';
        areaBefore.classList.add('drop-area-before');
        areaBefore.addEventListener('dragenter', (event) => {
          event.target.classList.add('active');
        }, false);
        areaBefore.addEventListener('dragleave', (event) => {
          event.target.classList.remove('active');
        }, false);
        areaBefore.addEventListener('dragover', (event) => {
          event.preventDefault();
        }, false);

        const areaAfter = document.createElement('div');
        areaAfter.innerHTML = 'Drop here';
        areaAfter.classList.add('drop-area-after');
        areaAfter.addEventListener('dragenter', (event) => {
          event.target.classList.add('active');
        }, false);
        areaAfter.addEventListener('dragleave', (event) => {
          event.target.classList.remove('active');
        }, false);
        areaAfter.addEventListener('dragover', (event) => {
          event.preventDefault();
        }, false);

        if (!this.editingFooter) {
          Array.from(mjWrappersWithoutFooter)
              .forEach((mw, index) => {
                if (!mw.previousSibling.className || !mw.previousSibling.className.indexOf('drop-area-before') === -1) {
                  const parentNode = mw.parentNode;
                  parentNode.insertBefore(areaBefore, mw);
                }

                // Last element before footer, we insert drop area after
                if (index === mjWrappersWithoutFooter.length - 1) {
                  if (!mw.nextSibling.className || !mw.nextSibling.className.indexOf('drop-area-before') === -1) {
                    const parentNode = mw.parentNode;
                    parentNode.insertBefore(areaAfter, mw.nextSibling);
                  }
                }
              });
        } else {
          const footerMjWrappers = document.querySelectorAll('div.mj-wrapper.bb-footer');
          Array.from(footerMjWrappers)
              .forEach((mw, index) => {
                if (!mw.previousSibling.className || !mw.previousSibling.className.indexOf('drop-area-before') === -1) {
                  const parentNode = mw.parentNode;
                  parentNode.insertBefore(areaBefore, mw);
                }

                // Last element before footer, we insert drop area after
                if (index === footerMjWrappers.length - 1) {
                  if (!mw.nextSibling.className || !mw.nextSibling.className.indexOf('drop-area-before') === -1) {
                    const parentNode = mw.parentNode;
                    parentNode.insertBefore(areaAfter, mw.nextSibling);
                  }
                }
              });
        }
      },
      onDragEnd() {
        this.dragged = null;
        Array.from(document.getElementsByClassName('drop-area-before')).forEach(el => el.remove());
        Array.from(document.getElementsByClassName('drop-area-after')).forEach(el => el.remove());
      },
    },
  };
</script>
<style lang="scss" scoped>
  .header {
    background-color: white;
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: .875rem;
    line-height: 1.25rem;
    color: rgb(29, 54, 75);
  }

  .header .btn-close-wrapper {
    font-size: 2rem;
    color: rgb(214, 224, 232);
    cursor: pointer;
    margin-right: 1rem;
  }

  .blocks {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: rgb(205, 215, 226);
    height: 100%;
  }

  .blocks .block {
    width: 100%;
    background-color: white;
    border-radius: 6px;
    padding: 1rem;
    cursor: grab;
    margin-bottom: 1rem;
    transition: all 300ms ease-in-out;
  }

  .blocks .block:hover {
    // box-shadow: 0 0 15px 0 rgba(0,0,0,0.2);
  }

  .blocks .block img {
    /*display: block;*/
    max-width: 100%;
  }
</style>
