<template>
  <div class="field-container">
    <div class="card-condition">
      <el-row>
        <el-col :span="1.5" class="step-col">
          <div class="field-step">
            {{ this.fieldStep }}
          </div>
        </el-col>
        <el-col :span="12" class="body-col">
          <span class="field-title">{{ this.title }}</span>
          <transition name="fade">
            <el-form class="field-form" :model="countForm" ref="countingForm">
              <el-form-item prop="selectedTimeFrame">
                <div class="option-body" @click.stop>
                  <el-radio
                    class="radio-btn field-label-text"
                    name="atleast-option"
                    v-model="countForm.selectedTimeFrame"
                    @change="
                      $emit('update:selection', countForm.selectedTimeFrame)
                    "
                    label="atLeast"
                    >At Least</el-radio
                  >
                  <transition name="input">
                    <div
                      v-if="countForm.selectedTimeFrame === 'atLeast'"
                      class="other-form"
                    >
                      <el-input-number
                        name="atleast-input"
                        size="mini"
                        controls-position="right"
                        v-model="countForm.moreThanCount"
                        @change="
                          $emit('update:countOne', countForm.moreThanCount)
                        "
                        :min="1"
                      ></el-input-number>
                      <span class="desc-label">{{ this.countType }}</span>
                    </div>
                  </transition>
                  <transition name="input">
                    <div
                      v-if="countForm.selectedTimeFrame === 'atLeast'"
                      class="desc-text"
                    >
                      {{ moreThanText }}
                    </div>
                  </transition>
                  <el-radio
                    class="radio-btn field-label-text"
                    name="lessthan-option"
                    v-model="countForm.selectedTimeFrame"
                    @change="
                      $emit('update:selection', countForm.selectedTimeFrame)
                    "
                    label="lessThan"
                    >Less Than</el-radio
                  >
                  <transition name="input">
                    <div
                      v-if="countForm.selectedTimeFrame === 'lessThan'"
                      class="other-form"
                    >
                      <el-input-number
                        name="lessthan-input"
                        size="mini"
                        controls-position="right"
                        v-model="countForm.lessThanCount"
                        @change="
                          $emit('update:countOne', countForm.lessThanCount)
                        "
                        :min="1"
                      ></el-input-number>
                      <span class="desc-label">{{ this.countType }}</span>
                    </div>
                  </transition>
                  <transition name="input">
                    <div
                      v-if="countForm.selectedTimeFrame === 'lessThan'"
                      class="desc-text"
                    >
                      {{ lessThanText }}
                    </div>
                  </transition>

                  <el-radio
                    class="radio-btn field-label-text"
                    name="exactly-option"
                    v-model="countForm.selectedTimeFrame"
                    @change="
                      $emit('update:selection', countForm.selectedTimeFrame)
                    "
                    label="exactly"
                    >Exactly</el-radio
                  >
                  <transition name="input">
                    <div
                      v-if="countForm.selectedTimeFrame === 'exactly'"
                      class="other-form"
                    >

                      <el-input-number
                        v-if="Number.isInteger(this.exactlyMinDefaultVal)"
                        name="exactly-input"
                        size="mini"
                        controls-position="right"
                        v-model="countForm.exactlyCountVal"
                        @change="
                          $emit('update:countOne', countForm.exactlyCountVal)
                        "
                        :min="exactlyMinDefaultVal"
                      ></el-input-number>

                       <el-input-number
                       v-if="!Number.isInteger(this.exactlyMinDefaultVal)"
                        name="exactly-input"
                        size="mini"
                        controls-position="right"
                        v-model="countForm.exactlyCount"
                        @change="
                          $emit('update:countOne', countForm.exactlyCount)
                        "
                        :min="1"
                      ></el-input-number>
                      <span class="desc-label">{{ this.countType }}</span>
                    </div>
                  </transition>

                  <el-radio
                    class="radio-btn field-label-text"
                    name="between-option"
                    v-model="countForm.selectedTimeFrame"
                    @change="
                      $emit('update:selection', countForm.selectedTimeFrame)
                    "
                    label="between"
                    >Between</el-radio
                  >
                  <transition name="input">
                    <div
                      v-if="countForm.selectedTimeFrame === 'between'"
                      class="other-form"
                    >
                      <el-input-number
                        name="between-input-one"
                        size="mini"
                        controls-position="right"
                        v-model="countForm.minVisitsNumber"
                        @change="
                          $emit('update:countOne', countForm.minVisitsNumber)
                        "
                        :min="1"
                      ></el-input-number>
                      <div class="and-text">AND</div>
                      <el-input-number
                        name="between-input-two"
                        size="mini"
                        controls-position="right"
                        v-model="countForm.maxVisitsNumber"
                        @change="
                          $emit('update:countTwo', countForm.maxVisitsNumber)
                        "
                        :min="1"
                      ></el-input-number>
                      <span class="desc-label">{{ this.countType }}</span>
                    </div>
                  </transition>
                  <transition name="input">
                    <div
                      v-if="countForm.selectedTimeFrame === 'between'"
                      class="desc-text"
                    >
                      {{ betweenText }}
                    </div>
                  </transition>
                </div>
              </el-form-item>
            </el-form>
          </transition>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  export default {
    props: [
      'fieldIndex',
      'title',
      'fieldStep',
      'selection',
      'countOne',
      'countTwo',
      'countType',
      'moreThanText',
      'lessThanText',
      'betweenText',
      'id',
      'exactlyMinDefaultVal',
    ],
    data() {
      return {
        countForm: {
          selectedTimeFrame: this.selection,
          minVisitsNumber: null,
          maxVisitsNumber: null,
          moreThanCount: null,
          lessThanCount: null,
          exactlyCount: null,
        },
      };
    },
    created() {
      if (this.selection === 'atLeast') {
        this.countForm.moreThanCount = this.countOne;
      } else if (this.selection === 'lessThan') {
        this.countForm.lessThanCount = this.countOne;
      } else if (this.selection === 'exactly') {
        this.countForm.exactlyCount = this.countOne;
      } else if (this.selection === 'between') {
        this.countForm.minVisitsNumber = this.countOne;
        this.countForm.maxVisitsNumber = this.countTwo;
      }

      if (this.countOne === this.exactlyMinDefaultVal) {
        this.$set(this.countForm, 'exactlyCountVal', this.exactlyMinDefaultVal);
      } else {
        this.$set(this.countForm, 'exactlyCountVal', this.countOne);
      }
    },
    watch: {
      selection(val) {
        if (Number.isInteger(this.exactlyMinDefaultVal) && val === 'exactly') {
          this.$emit('update:countOne', this.exactlyMinDefaultVal);
          this.$set(this.countForm, 'exactlyCountVal', this.exactlyMinDefaultVal);
        } else {
          this.$emit('update:countOne', 1);
        }
        this.countForm.minVisitsNumber = null;
        this.countForm.maxVisitsNumber = null;
        this.countForm.moreThanCount = null;
        this.countForm.lessThanCount = null;
        this.countForm.exactlyCount = null;
      },
    },
  };
</script>
<style lang="scss" scoped>
@import "src/scss/global/_spacing.scss";
@import "src/scss/global/_new_main.scss";
.field-title {
  font-family: $primary-bb-font-family;
  font-weight: 700;
  font-size: $base-font-size;
  color: $base-color-black-v2;
  line-height: $loose-medium-line-height;
}
.field-body-text {
  font-family: $primary-bb-font-family;
  font-weight: 400;
  font-size: $base-loose-font-size;
  color: #00152A;
  line-height: $loose-line-height;
  margin-top: $space-loose
}
.field-step {
  height: 1.5rem;
  width: 1.5rem;
  background-color: $bb-color-blue-v2;
  font-family: $primary-bb-font-family;
  font-size: $base-font-size;
  color: #FFFFFF;
  border-radius: 50%;
  text-align: center;
  margin: $space-base-inline;
}
.el-radio.is-bordered+.el-radio.is-bordered {
  margin-left: 0;
}
.card-condition {
  background-color: #FFFFFF;
  border: solid 1px var(--xpl-color-gray-400);
  box-shadow: none;
  border-radius: 0;
  cursor: auto;
}
.card-condition:hover {
  background-color: #FFFFFF;
  cursor: auto;
}
.body-col-2 {
  margin-right: $space-base;
}
.option-body {
  margin-top: 0;
}
.field-form {
  margin-top: $space-loose;
}
.field-label-text /deep/ .el-radio__label {
  font-family: $primary-bb-font-family !important;
  font-size: $base-loose-font-size !important;
  color: $base-color-black-v2 !important;
  line-height: $loose-line-height !important;
  padding-left: 8px;
}
.field-label-text /deep/ .el-radio__inner {
  background-color: #F2F4F6 !important;
  border: 1px solid #A6B2BF !important;
  height: 16px;
  width: 16px;
}
.field-label-text /deep/  .el-radio__input.is-checked .el-radio__inner::after {
   background-color: var(--xpl-color-secondary-lm) !important;
   width: 12px;
   height: 12px;
}
.radio-btn {
  margin-top: $space-extra-tight;
  margin-bottom: $space-extra-tight;
}
.other-form /deep/ .el-input-number--mini {
  border: 1px solid #A6B2BF;
  width: 98px;
  border-radius: 2px;
}
.other-form /deep/ .el-input-number--mini input {
  padding-left: 32px;
  padding-right: 0px;
}
.other-form /deep/ .el-input__inner {
  border: none;
  border-radius: 2px;
}
.other-form /deep/ .el-input-number__increase {

  width: 18px;
}
.other-form /deep/ .el-input-number__decrease {
  width: 18px;
}
.desc-label {
    margin-left: 8px;
    font-size: 12px !important;
    color: #161e27;
    font-weight: 400;
    line-height: 20px;
}
.desc-text {
  margin-bottom: 4px;
}
.el-form-item {
  margin-bottom: 0;
}
.field-container {
  margin-top: 28px;
}
</style>
