<template>
  <transition name="fade">
    <div class="campaign" v-if="campaign">
      <div class="list-nav">
        <router-link :to="'/campaigns/' + campaign.uuid" class="go-back"><i class="fa fa-chevron-left"></i> Go Back</router-link>
        {{ campaign.name }}
        <div class="actions"></div>
      </div>

      <div class="container-fluid">
        <h2>Manually add and import contacts into this campaign ({{ campaign.name }})</h2>
        <p>Have contacts that were missed by the enrollment rule? You are able to add contacts here manually. Please note that contacts will get marked as subscribed for emails and texts when entered manually.</p>
        <el-row :gutter="24">
              <el-col :span="24">
                <div class="alert-warning">
                  <div class="alert-title">Set your campaign live before adding contacts</div>
                  Set your campaign live before manually enrolling contacts in order for them to receive messages in the campaign!
                </div>
              </el-col>
            </el-row>
        <div class="inner">
          <div class="section">
            <h3>Upload from .CSV</h3>
            <div class="card">
              <div class="card-body">
                <form @submit.prevent>
                  <label>Select CSV</label>
                  <div class="form-group">
                    <input type="file" accept="text/csv,text/plain" id="csv_file" name="csv_file" class="input-file" @change="fileUpload($event)" />
                    <label for="csv_file">
                      <span ref="csv_file_name"></span>
                      Browse Files
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label" for="check">
                      <input type="checkbox" name="check" id="check" v-model="check" class="form-check-input" />
                      I understand that importing contacts may affect my billing plan
                    </label>
                  </div>
                </form>
                <button type="button" class="xpl-button xpl-button--primary" :disabled="!check || !file" @click="upload">Upload CSV</button>
              </div>
            </div>
          </div>
          <div class="section">
            <h3>Add a single contact</h3>
            <div class="card">
              <div class="card-body flex">
                <form @submit.prevent>
                  <div class="form-group">
                    <label for="email">Email Address</label>
                    <input type="text"
                      id="email"
                      name="email"
                      v-model="one_off.email"
                      class="form-control"
                      placeholder="Email" />
                  </div>
                  <div class="form-group">
                    <label for="first_name">First Name</label>
                    <input type="text"
                      id="first_name"
                      name="first_name"
                      v-model="one_off.first_name"
                      class="form-control"
                      placeholder="First Name" />
                  </div>
                  <div class="form-group">
                    <label for="last_name">Last Name</label>
                    <input type="text"
                      id="last_name"
                      name="last_name"
                      v-model="one_off.last_name"
                      class="form-control"
                      placeholder="Last Name" />
                  </div>
                </form>
                <form @submit.prevent>
                  <div class="form-group" @click.prevent="one_off.set_joined_at = true">
                    <a href="#" class="small text-muted" v-if="!one_off.set_joined_at">Set a custom campaign entry date</a>
                    <div v-if="one_off.set_joined_at">
                      <label for="joined_at">Campaign Entry Date</label>
                      <div>
                        <vue-datepicker-local v-model="one_off.joined_at" :local="local" format="MM-DD-YYYY" placeholder="Entry Date" />
                      </div>
                    </div>
                  </div>
                </form>
                <button type="button" ref=" addContact" class="xpl-button xpl-button--primary" @click="addContact" :disabled="!one_off.email">Add Contact</button>
              </div>
            </div>
          </div>
           <div class="section">
            <h3>Add a Segment</h3>
            <div class="card">
              <div class="card-body flex">
                <form @submit.prevent>
                  <div class="form-group">
                   <label>Select Segment</label>
                    <Multiselect v-model="campaign_contact_list" placeholder="Type to search" :options="contact_lists.filter(isVisible)" track-by="uuid" :allow-empty="true" :multiple="false" :show-labels="false"  label="name">
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                          (<span class="option__small" v-if="props.option.subscribed">{{ props.option.subscribed | prettyNumber }}</span>)
                        </div>
                      </template>
                  </Multiselect>
                  </div>
                </form>
                <el-button type="button" ref="addContact" class="xpl-button" @click="addSegment" :loading="loading" :disabled="!campaign_contact_list" >Add Segment</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import * as api from '@/api';
  import VueDatepickerLocal from 'vue-datepicker-local';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['uuid'],
    components: {
      VueDatepickerLocal,
      Multiselect,
    },
    data() {
      return {
        campaign: null,
        check: false,
        file: null,
        loading: false,
        one_off: {
          email: '',
          first_name: '',
          last_name: '',
          set_joined_at: false,
          joined_at: '',
        },
        local: {
          dow: 1,
          hourTip: '',
          minuteTip: '',
          secondTip: '',
          yearSuffix: '',
          monthsHead: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
          months: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
          weeks: 'Mon_Tue_Wed_Thu_Fri_Sat_Sun'.split('_'),
        },
        campaign_contact_list: null,
        contact_lists: [],
      };
    },
    beforeRouteEnter(to, from, next) {
      api.getCampaign(to.params.uuid).then((response) => {
        next((vm) => {
          vm.campaign = response.campaign;
        });
      }).catch(() => {
        next((vm) => {
          vm.$toasted.error('Something wrong happened while trying to fetch this campaign...');
          return false;
        });
      });
    },
    mounted() {
      api.getContactLists().then((response) => {
        this.contact_lists = response.contact_lists;
      }).catch(() => {
        this.$toasted.error('Something wrong happened while fetching your segments...');
      });
    },
    methods: {
      addContact() {
        if (!this.one_off.email) {
          this.$toasted.error('Please make sure the email field is filled out properly.');
          return;
        }

        const payload = {
          campaign_id: this.campaign.id,
          email: this.one_off.email,
          first_name: this.one_off.first_name,
          last_name: this.one_off.last_name,
          joined_at: this.one_off.joined_at,
        };

        this.$refs.addContact.disabled = true;
        this.$refs.addContact.innerHTML = '<i class="fa fa-fw fa-spin fa-spinner"></i>';

        api.addContactCampaign(payload).then((response) => {
          this.$toasted.success(response.message);
          this.one_off.email = '';
          this.one_off.first_name = '';
          this.one_off.last_name = '';
          this.one_off.joined_at = '';
          this.$refs.addContact.disabled = false;
          this.$refs.addContact.innerHTML = 'Add Contact';
        }).catch((err) => {
          this.$refs.addContact.disabled = false;
          this.$refs.addContact.innerHTML = 'Add Contact';
          this.$toasted.error(err.message);
        });
      },
      fileUpload(event) {
        const target = event.target || event.srcElement;
        if (!target.files.length) {
          return;
        }

        const file = target.files[0];

        this.$refs.csv_file_name.innerHTML = file.name;
        this.file = file;
      },
      upload() {
        const formData = new FormData();
        formData.append('csv_file', this.file, this.file.name);
        formData.append('campaign_id', this.campaign.id);

        api.uploadContactsCampaign(formData).then((response) => {
          this.$toasted.success(response.message);
          this.file = null;
          this.check = false;
          this.$router.push(`/campaigns/${this.campaign.uuid}`);
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      isVisible(contactList) {
        if (contactList.name.startsWith('cait-realtime-test') && !this.$store.getters.isStaff) {
          return false;
        }
        return true;
      },
      async addSegment() {
        const payload = {
          campaign_id: this.campaign.id,
          all_contacts: true,
          contact_list_id: this.campaign_contact_list.id,
        };
        this.loading = true;
        try {
          const response = await api.massAddToCampaign(payload);
          this.$toasted.success(response.message);
          this.loading = false;
        } catch (err) {
          this.$toasted.error(err.message);
          this.loading = false;
        }
      },
    },
  };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>

<style lang="scss" scoped>
@import "src/scss/global/_new_main.scss";

  h2, p {
    margin-top: .5rem;
  }

  p {
    max-width: 34rem;
  }

  p.tiny-text {
    font-size: .625rem;
    margin-bottom: 2rem;
  }

  .inner {
    margin-top: 2rem;
  }

  .section {
    margin-bottom: 2rem;
  }

  .card-body.flex form {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .form-group {
    flex: 1;
    margin-right: 1rem;
  }

  .form-group:last-child {
    margin-right: 0;
  }

  .alert-warning {
    background-color: #fdf6ec;
    color: #e6a23c;
    width: 100%;
    padding: 8px 24px;
    margin: 0;
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;
    opacity: 1;
    font-size: 12px;
    line-height: 1.5em;
    margin-bottom: 1rem;
  }

  .alert-warning .alert-title {
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 700;
  }

</style>
