<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <el-form ref="accountSignup" :model="account" :rules="accountRules" status-icon @submit.native.prevent>
              <h1>Franchisee Details</h1>
              <el-form-item prop="name">
                <label for="name">Franchisee Name</label>
                <el-input id="name" v-model="account.name" placeholder="Franchisee Name" />
              </el-form-item>
              <el-form-item prop="website_url">
                <label for="website_url">Business URL</label>
                <el-input id="website_url" v-model.trim="account.website_url" placeholder="Business URL" />
              </el-form-item>
              <el-form-item prop="timezone">
                <label for="type">Timezone</label>
                <div>
                  <el-select v-model="account.timezone" filterable placeholder="Timezone" style="width: 100%;">
                    <el-option-group v-for="group in timezones" :key="group.group" :label="group.group">
                      <el-option v-for="timezone in group.zones" :key="timezone.value" :label="timezone.name" :value="timezone.value"></el-option>
                    </el-option-group>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item prop="type">
                <label for="type">Company Type</label>
                <div>
                  <el-select v-model="account.type" filterable placeholder="Company Type" style="width: 100%;">
                      <el-option v-for="type in types" :key="type" :label="type" :value="type"></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item style="text-align: center; margin-top: 1.5rem;">
                <el-button type="primary" class="xpl-button xpl-button--primary":loading="loading" @click.stop="confirmCreate">Continue</el-button>
                <el-button type="secondary" class="xpl-button xpl-button--secondary"  @click.stop="cancelCreate">Cancel</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import { newFranchiseeAccount } from '@/api';
  import timezones from './timezones.json';

  export default {
    props: ['franchise'],
    data() {
      const checkUrl = (rule, value, callback) => {
        if (value && value.length &&
          !value.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm)) {
          return callback(new Error('We need a valid url for your website URL.'));
        }

        return callback();
      };
      return {
        timezones,
        loading: false,
        types: ['CrossFit', 'Dance', 'Fitness', 'Health Club', 'Massage', 'Martial Arts', 'Personal Training', 'Physical Therapy', 'Pilates', 'Barre', 'Salon', 'Spa', 'Sports Training', 'Wellness/Health Center', 'Yoga', 'Other'],
        account: {
          name: '',
          website_url: '',
          type: '',
          timezone: null,
        },
        accountRules: {
          name: [{ required: true, trigger: 'change', message: 'A company name is required' }],
          website_url: [{ required: true, validator: checkUrl, trigger: 'change' }],
          type: [{ required: true, trigger: 'change', message: 'Please select a company type' }],
          timezone: [{ required: true, trigger: 'change', message: 'Please pick a timezone' }],
        },
      };
    },
    methods: {
      cancelCreate() {
        EventBus.$emit('new_franchisee.close');
      },
      confirmCreate() {
        this.$confirm('Are you sure you want to create this Franchisee account?').then(() => {
          this.createAccount();
        }).catch(() => {
          this.cancelCreate();
        });
      },
      createAccount() {
        this.loading = true;
        newFranchiseeAccount(this.account).then((response) => {
          this.$store.dispatch('refreshUser').then(() => {
            this.$message.success(response.message);
            window.location.href = '/signup';
          });
        }).catch(() => {
          this.loading = false;
          this.$message.error('Something wrong happened. Please try again later.');
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal-content {
    display: block !important;
    height: 100% !important;
  }

  .modal-dialog {
    max-width: none;
    margin: 0;
    height: 100%;
  }

  .modal .modal-content .modal-body {
    max-height: none;
  }

  h1 {
    font-size: 1.75rem;
    color: #00152a;
    margin-bottom: 2.5rem;
  }

  .el-form {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
  }

  .el-form-item {
    margin-bottom: 1rem;
  }
</style>
