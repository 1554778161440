<template>
  <div>
    <div class="signup">
      <div class="header">
        <img :src="$store.getters.brandLogo" :alt="$store.getters.brandName" />
      </div>
      <div class="main">
        <User v-if="steps.one" :pikeToken="pikeToken" />
        <Account v-if="steps.two" :pikeToken="pikeToken" />
        <Software v-if="steps.three" />
        <Mindbody v-if="steps.mindbody" />
        <Pike13 v-if="steps.pike" :accountId="accountId" />
        <MarianaTek v-if="steps.marianatek" :accountId="accountId" />
        <Zingfit v-if="steps.zingfit" :accountId="accountId" />
        <Address v-if="steps.four" />
        <Branding v-if="steps.five" />
      </div>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import User from './Signup/User';
  import Account from './Signup/Account';
  import Software from './Signup/Software';
  import Mindbody from './Signup/Mindbody';
  import Pike13 from './Signup/Pike13';
  import MarianaTek from './Signup/MarianaTek';
  import Zingfit from './Signup/Zingfit';
  import Address from './Signup/Address';
  import Branding from './Signup/Branding';

  export default {
    components: {
      User,
      Account,
      Software,
      Address,
      Mindbody,
      Pike13,
      MarianaTek,
      Zingfit,
      Branding,
    },
    data() {
      return {
        steps: {
          one: false,
          two: false,
          three: false,
          four: false,
          mindbody: false,
          pike: false,
          marianatek: false,
          zingfit: false,
          five: false,
        },
        userId: null,
        accountId: null,
        pikeToken: null,
      };
    },
    mounted() {
      EventBus.$on('signup.user.done', (user, tokens) => {
        if (tokens) {
          localStorage.setItem('access_token', tokens.access_token);
          localStorage.setItem('refresh_token', tokens.refresh_token);
        }

        if (user) {
          localStorage.setItem('user', JSON.stringify(user));
          this.userId = user.id;
        }
        this.steps.one = false;
        this.steps.two = true;
      });

      EventBus.$on('signup.account.done', (account) => {
        if (account) {
          this.accountId = account.id;
        }
        this.steps.two = false;
        this.steps.three = false;
        this.steps.four = false;
        this.steps.five = false;
        this.steps.mindbody = false;
        this.steps.pike = false;
        this.steps.marianatek = false;
        this.steps.zingfit = false;
        if (this.pikeToken) {
          this.steps.four = true;
        } else {
          this.steps.three = true;
        }
      });

      EventBus.$on('signup.software.done', (software) => {
        this.steps.three = false;
        this.steps[software] = true;
      });

      EventBus.$on('signup.vendor.done', (software) => {
        this.steps[software] = false;
        this.steps.four = true;
      });

      EventBus.$on('signup.address.done', () => {
        this.steps.four = false;
        this.steps.five = true;
      });

      EventBus.$on('signup.branding.done', () => {
        this.$store.dispatch('refreshUser').then(() => {
          this.$nextTick(() => {
            window.location.href = '/calendar';
          });
        });
      });
    },
    created() {
      let user = localStorage.getItem('user');
      const vendor = localStorage.getItem('brandbotSelectedVendor');
      this.pikeToken = localStorage.getItem('pikeToken');
      if (user && JSON.parse(user)) {
        user = JSON.parse(user);
        this.userId = user.id;
        this.accountId = user.current_account ? user.current_account.id : null;

        if (this.pikeToken) {
          this.steps.one = true;
          return;
        }

        if (this.userId && !this.accountId) {
          this.steps.two = true;
        }

        if (this.accountId && this.userId && !vendor) {
          this.steps.three = true;
        }

        if (this.accountId && this.userId && vendor) {
          this.steps[vendor] = true;
        }
      } else {
        this.steps.one = true;
      }
    },
    beforeDestroy() {
      EventBus.$off('signup.user.done');
      EventBus.$off('signup.account.done');
      EventBus.$off('signup.software.done');
      EventBus.$off('signup.vendor.done');
      EventBus.$off('signup.address.done');
      EventBus.$off('signup.branding.done');
    },
  };
</script>
<style lang="scss" scoped>
  .signup {
    background-color: #fff;
    min-width: 100vw;
    width: 100%;
    height: 100vh;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .signup .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 75px;
  }

  .signup .header img {
    display: block;
    min-width: 42px;
    height: auto;
    max-height: 42px;
  }

  .signup .main {
    display: flex;
    height: calc(100vh - 75px);
    align-items: center;
    justify-content: center;
  }
</style>
