<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner">
      Has / Has Not
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="!is_prospect" @click="is_prospect = true"></span>
            <span class="icon icon-radio-checked" v-if="is_prospect" @click="is_prospect = true"></span>
          </div>
          <div class="col-xs-10">
            <label @click="is_prospect = true">
              Is a Prospect
            </label>
            <small class="form-text text-muted">
              Isolates the first time a client purchases this specific type of purchase
            </small>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="is_prospect === null || is_prospect === true"  @click="is_prospect = false"></span>
            <span class="icon icon-radio-checked" v-if="is_prospect === false"  @click="is_prospect = false"></span>
          </div>
          <div class="col-xs-10">
            <label @click="is_prospect = false">
              Is not a Prospect
            </label>
            <small class="form-text text-muted">Isolates the first time a client purchases this specific type of purchase</small>
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Location and Site ID (Optional)
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="misc_filtering === null || misc_filtering === true" @click="misc_filtering = false"></span>
            <span class="icon icon-radio-checked" v-if="misc_filtering === false" @click="misc_filtering = false"></span>
          </div>
          <div class="col-xs-10">
            <label for="misc_filtering_no" @click="misc_filtering = false">
              Select all locations and Site IDs
            </label>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="misc_filtering === null || misc_filtering === false" @click="misc_filtering = true"></span>
            <span class="icon icon-radio-checked" v-if="misc_filtering === true" @click="misc_filtering = true"></span>
          </div>
          <div class="col-xs-10">
            <label for="misc_filtering_yes" @click="misc_filtering = true">
               Select specific locations or Site IDs
            </label>
          </div>
        </div>
      </div>
      <div class="options-inner" v-if="misc_filtering">
        <div class="form-group">
          <label class="form-control-label">Location</label>
          <div class="multiselect-block">
            <Multiselect v-model="location" placeholder="All Locations" label="location_name" track-by="location_id" :options="locations" :searchable="false" :allow-empty="true" :show-labels="false" />
          </div>
        </div>
      </div>
    </form>

    <div class="form-group">
      <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition" :disabled="is_prospect === null">Save this condition</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['locations', 'condition', 'editedRule'],
    components: {
      Multiselect,
    },
    data() {
      return {
        is_prospect: true,
        misc_filtering: false,
        location: null,
      };
    },
    created() {
      if (this.editedRule) {
        this.is_prospect = this.editedRule.is_prospect;
        this.misc_filtering = this.editedRule.misc.filtering;
        if (this.misc_filtering) {
          this.location = this.locations.filter(l =>
            l.location_id === this.editedRule.misc.location,
          )[0];
        }
      }
    },
    methods: {
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          is_prospect: this.is_prospect,
          misc: {
            filtering: this.misc_filtering,
            location: this.location ? this.location.location_id : null,
            site_id: null,
          },
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }
</style>
