<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Saved Links
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <input type="search" ref="query" v-model="query" class="form-control" placeholder="Search" />
              </div>
            </form>
            <div class="links">
              <div class="link" v-for="link in savedLinksFiltered" @click="selectLink(link)">
                <div class="name">{{ link.name }}</div>
                <a :href="link.url" target="_blank" @click.prevent>{{ link.url }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    data() {
      return {
        query: '',
        saved_links: [],
      };
    },
    created() {
      document.body.classList.add('stop-scroll');

      api.getSavedLinks().then((response) => {
        this.saved_links = response.saved_links;
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    beforeDestroy() {
      document.body.classList.remove('stop-scroll');
    },
    computed: {
      savedLinksFiltered() {
        if (this.query.length) {
          return this.saved_links.filter(sl =>
            sl.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1 ||
            sl.url.toLowerCase().indexOf(this.query.toLowerCase()) > -1,
          );
        }

        return this.saved_links;
      },
    },
    methods: {
      closeModal() {
        EventBus.$emit('saved_links_modal.close');
      },
      selectLink(link) {
        EventBus.$emit('saved_links_modal.selected', link);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .links {
    width: 100%;
    overflow-y: scroll;
    word-wrap: break-word;
    height: 20rem;
  }

  .links .link {
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    // box-shadow: 0 1px 0 0 rgba(217, 231, 240, 0.5);
    border: solid 1px rgb(217, 231, 240);
    padding: .875rem;
    margin-bottom: .5rem;
    transition: all 150ms ease-in-out;
  }

  .links .link:hover {
    border-color: var(--xpl-color-secondary-lm);
  }

  .links .link .name {
    font-size: .75rem;
    font-weight: 700;
    line-height: 1.25rem;
    color: rgb(29, 54, 75);
  }

  .links .link a {
    color: rgb(139, 155, 172);
    font-size: .75rem;
    line-height: 1.25rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-decoration: none;
  }
</style>
