<template>
  <div class="field-container">
    <div class="card-condition">
      <el-row>
        <el-col :span="1.5" class="step-col">
          <div class="field-step">{{ this.field.step }}</div>
        </el-col>
        <el-col :span="22">
          <span class="field-title">{{ this.field.name }}</span>
          <transition name="fade">
            <el-form class="bool-form" :model="form" ref="yesNoForm">
              <el-form-item prop="radio">
                <div class="option-body">
                  <el-radio-group v-model="form.valueOne" @change="didChange()">
                    <div v-for="option in this.field.options">
                      <el-radio class="radio-btn field-label-text"
                                @change="didChange"
                                :label="option.option_id">{{ option.display_name }}
                      </el-radio>
                    </div>
                  </el-radio-group>
                </div>
              </el-form-item>
            </el-form>
          </transition>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

export default {
  props: ['field'],
  data() {
    let d = this.field.default;
    if (typeof d !== 'object') {
      d = { option_id: d };
    }
    return {
      form: {
        valueOne: d.option_id,
      },
    };
  },
  created() {
    this.didChange();
  },
  methods: {
    didChange() {
      this.field.default = { option_id: this.form.valueOne };
      this.validate();
    },
    validate() {
      this.field.isValid = true;
      // eslint-disable-next-line no-console
      this.$log('radioField isvalid=', this.field.isValid);
      this.$emit('didValidate');
    },
  },
};
</script>
<style lang="scss" scoped>
@import "uia_fields";
</style>
