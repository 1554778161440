<template>
  <div class="condition">
    <form @submit.prevent>
      <label>Any contact who purchases any of the selected pricing options for the first time ever:</label>
      <div class="multiselect-block">
        <Multiselect v-model="selected_pricing_options" placeholder="Select Pricing Option(s)" :options="pricing_options" label="name" track-by="id" :searchable="true" :allow-empty="true" :show-labels="false" :multiple="true" :close-on-select="false" />
      </div>
      <div class="small text-muted">Select as many pricing options as you want</div>
      <button class="xpl-button xpl-button--primary" @click.stop="commitCondition" :disabled="!selected_pricing_options.length">Save</button>
    </form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['uuid', 'rule', 'goal'],
    components: {
      Multiselect,
    },
    data() {
      return {
        pricing_options: [],
        selected_pricing_options: [],
      };
    },
    created() {
      this.fetchPricingOptions();

      if (this.rule && this.rule.length && this.rule[0].pricing_options) {
        this.selected_pricing_options = this.rule[0].pricing_options;
      }
    },
    methods: {
      fetchPricingOptions() {
        api.getPricingOptions().then((response) => {
          this.pricing_options = response.pricing_options;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition(event) {
        const target = event.target || event.srcElement;
        target.disabled = true;
        target.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';

        const payload = {
          condition: {
            uuid: this.uuid,
            quick: true,
          },
          comparison: null,
          purchase: true,
          purchase_type: 'specific',
          pricing_options: this.selected_pricing_options,
          timeframe: this.goal ? 'entering_campaign' : 'all',
          service_categories: [],
          revenue_categories: [],
          relative_days: null,
          start_date: null,
          end_date: null,
          misc: {
            filtering: false,
          },
        };

        if (this.goal) {
          payload.type = 'goal';
        }

        EventBus.$emit('condition.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .condition label {
    color: #1D364B;
    margin-bottom: .75rem;
    font-weight: 400;
    font-size: 1rem;
  }

  .btn-bb-primary {
    margin-top: 1.5rem;
  }

  .small.text-muted {
    margin-top: .5rem;
  }
</style>
