<template>
  <div>
    <div class="nav-subheader">
      <div class="nav-inner">
        <div>Account Assets</div>
        <div class="nav-pills">
          <div :class="[tabs.media ? 'active' : '', 'pill']" @click="$router.push('/account/assets')">Media</div>
          <div :class="[tabs.saved_links ? 'active' : '', 'pill']" @click="$router.push('/account/saved-links')">Saved Links</div>
          <div :class="[tabs.pdf ? 'active' : '', 'pill']" @click="$router.push('/account/saved-pdfs')">Saved PDFs</div>
          <div :class="[tabs.templates ? 'active' : '', 'pill']" @click="$router.push('/account/templates')">Templates</div>
          <div :class="[tabs.tags ? 'active' : '', 'pill']" @click="$router.push('/account/tags')">Tags</div>
        </div>
      </div>
    </div>

    <AssetBrowser />

  </div>
</template>
<script>
  import NewFolderModal from './NewFolderModal';
  import AssetBrowser from './AssetBrowser';

  export default {
    components: {
      NewFolderModal,
      AssetBrowser,
    },
    data() {
      return {
        tabs: {
          media: true,
          saved_links: false,
          pdf: false,
          templates: false,
          tags: false,
          checkout: false,
        },
      };
    },
  };
</script>
<style lang="scss" scoped>
  .nav-subheader {
    justify-content: space-between;
    position: fixed;
    z-index: 100;
  }

  .nav-inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .tag {
    font-size: .5rem;
    line-height: 1rem;
    margin-left: .5rem;
    padding: .125rem .5rem;
    background-color: #f3f7f9;
    color: #1d364b;
    height: auto;
    border-radius: 6px;
    text-align: center;
    border: 1px solid #cdd7e2;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: auto;
  }
</style>
