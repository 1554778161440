<template>
  <div class="forgot-password">
    <div class="card">
      <div class="card-body">
        <h1>Set your new password</h1>
        <form @submit.prevent="resetPassword">
          <div class="form-group">
            <input type="password" id="password" name="password" v-model="password" placeholder="New Password" class="form-control" required />
          </div>
          <div class="form-group">
            <input type="password" id="confirm_password" name="confirm_password" v-model="confirm_password" placeholder="Confirm Password" class="form-control" required />
          </div>

          <div class="form-group">
            <label @click.stop="agree = !agree">
              <span class="icon icon-checkbox-unchecked2" v-if="agree === false"></span>
              <span class="icon icon-checkbox-checked2" v-if="agree"></span>
              I agree to the <a href="https://brndbot.webflow.io/legal/terms-of-service" target="_blank">Terms and Services</a> and <a href="https://brndbot.webflow.io/legal/acceptable-use-policy" target="_blank">Acceptable Use Policy.</a>
            </label>
          </div>

          <div class="form-group">
            <button type="button" ref="submit" class="xpl-button xpl-button--primary" @click.prevent="resetPassword" :disabled="!password || !confirm_password || !agree">Change Password</button>
          </div>
          <div class="text-center">
            <router-link to="/login" class="small">Back to login</router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import * as api from '@/api';

  export default {
    props: ['token'],
    data() {
      return {
        password: null,
        confirm_password: null,
        agree: false,
      };
    },
    methods: {
      resetPassword() {
        if (this.password !== this.confirm_password) {
          this.$toasted.error('Please make sure your passwords match.');
          return;
        }

        if (this.password.length < 6) {
          this.$toasted.error('Please make sure your password is at least 6 characters long.');
          return;
        }

        if (!this.agree) {
          this.$toasted.error('You have to agree to our new Terms and Services before continuing.');
          return;
        }

        const payload = {
          token: this.token,
          password: this.password,
        };

        api.resetPassword(payload).then((response) => {
          this.$toasted.success(response.message);
          this.$router.push('/login');
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .forgot-password {
    background-color: var(--xpl-color-background-lm);
    //background-image: url('https://assets.brandbot.com/public/onboarding/login-bg.png');
    background-size: 100% 80%;
    background-repeat: no-repeat;
    background-position: bottom center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    padding-bottom: 4rem;
  }

  .forgot-password img {
    width: 4rem;
    margin-bottom: 2rem;
  }

  .forgot-password .card {
    max-width: 21.875rem;
    // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid  rgb(217, 231, 240);
    flex-shrink: 0;
    flex-grow: 0;
  }

  .forgot-password .card .card-body {
    padding: 2rem;
  }

  h1 {
    font-size: 1.25rem;
    line-height: 1.4;
    color: rgb(46, 68, 87);
    margin-bottom: 1rem;
  }

  .small {
    color: rgb(46, 68, 87);
  }

  .form-group a {
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.43;
    color: rgb(29, 54, 75);
  }
</style>
