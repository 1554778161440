const enviromentVariables = {
  apiUrl: '',
};
if (process.env.VUE_APP_API_URL === 'https://api.brandbot.com/api/') {
  enviromentVariables.apiUrl = 'production';
} else {
  enviromentVariables.apiUrl = 'staging';
}

export { enviromentVariables }; //eslint-disable-line
