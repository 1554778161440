<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Connect to Perkville
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <el-form>
              <el-form-item>
                <label>Perkville Business ID</label>
                <el-input v-model="business_id" placeholder="Business ID" />
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="xpl-button xpl-button--primary":disabled="!business_id" @click.stop="connectPerkville">Connect</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';

  export default {
    props: ['accountId'],
    data() {
      return {
        business_id: null,
      };
    },
    methods: {
      closeModal() {
        EventBus.$emit('connect_perkville.close');
      },
      connectPerkville() {
        this.closeModal();
      },
    },
  };
</script>
