<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner">
      Visit Details
    </div>
    <form class="form-inline" @submit.prevent>
      <div class="form-group">
        Any contact who has visited&nbsp;
        <el-select v-model="comparison_visit" filterable placeholder="Pick">
          <el-option v-for="item in comparisons_visit" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        &nbsp;
        <el-input type="number" name="visit_number" v-model.number="visit_number_start" :min="comparison_visit === 'between' ? 1 : 0" style="display: inline-block; width: 5rem;" :disabled="!comparison_visit" /><span v-if="comparison_visit === 'between'"> and <el-input type="number" name="visit_number" v-model.number="visit_number_end" min="0" style="display: inline-block; width: 5rem;" /></span>&nbsp;times in the timeframe below.
      </div>
    </form>

    <div class="condition-banner">
      Time Frame
    </div>
    <form @submit.prevent>
      <div class="form-group" v-if="goalList">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'entering_campaign'" @click="timeframe = 'entering_campaign'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'entering_campaign'" @click="timeframe = 'entering_campaign'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label @click="timeframe = 'entering_campaign'; comparison = null;">
              Since entering this campaign
            </label>
            <small class="form-text text-muted">Isolates the first time a client purchases this specific type of purchase.</small>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'absolute'" @click="timeframe = 'absolute'; comparison_date = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'absolute'" @click="timeframe = 'absolute'; comparison_date = null; relative_days = 0;"></span>
          </div>
          <div class="col-xs-10">
            <label @click="timeframe = 'absolute'; comparison_date = null; relative_days = 0;">
              Visits around specific dates
            </label>
            <small class="form-text text-muted">Select before, after, or between static dates. e.g. After July 27th 2018</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="timeframe === 'absolute'">
        <div class="form-group" style="display: flex; align-items: center;">
          These visits occured
          &nbsp;
          <el-select v-model="comparison_date" filterable placeholder="Pick">
            <el-option v-for="item in comparisons_date" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>

        <div class="dates" v-if="comparison_date && timeframe === 'absolute'">
          <div class="date">
            <vue-datepicker-local v-model="dates.start" :local="local" format="MM-DD-YYYY" />
          </div>
          <span v-if="comparison_date === 'between'">&nbsp;and&nbsp;</span>
          <div class="date" v-if="comparison_date === 'between'">
            <vue-datepicker-local v-model="dates.end" :local="local" format="MM-DD-YYYY" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'relative'" @click="timeframe = 'relative'; comparison_date = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'relative'" @click="timeframe = 'relative'; comparison_date = null;"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_relative" @click="timeframe = 'relative'; comparison_date = null;">
              Visits relative to Today
            </label>
            <small class="form-text text-muted">Select at least, less than, or exactly a number of days ago. This is a moving window of time. e.g. Within the last 10 days.</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="timeframe === 'relative'">
        <div class="form-group">
          Every contact who visited
          &nbsp;
          <el-select v-model="comparison_date" filterable placeholder="Pick">
            <el-option v-for="item in comparisons_number" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-input type="number" v-model.number="relative_days" min="0" />
          days<span v-if="comparison_date !== 'within'"> ago</span>.
        </div>
      </div>

      <div class="alert">
        <div><span class="material-icons">info_outline</span></div>
        <div>
          <div class="header">For all time (milestone) visits use the Milestone Conditon</div>
          <div class="body">To make it easier to understand this condition, we have decided to make all time visits, or “milestone” visits in a separate condition. </div>
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Qualifiers
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-checkbox-unchecked2" v-if="visit_type.indexOf('pricing_options') === -1" @click="toggleVisitType('pricing_options')"></span>
            <span class="icon icon-checkbox-checked2" v-if="visit_type.indexOf('pricing_options') > -1" @click="toggleVisitType('pricing_options')"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_relative" @click="toggleVisitType('pricing_options')">
              Only include visits with specific Pricing Option(s)
            </label>
            <small class="form-text text-muted">This will only include visits that use any of the pricing options below.</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="visit_type.indexOf('pricing_options') > -1">
        <div class="form-group">
          <label for="pricing_options">Only include visits with these Pricing Options</label>
          <div class="multiselect-block">
            <el-select v-model="selected_pricing_options" collapse-tags multiple filterable placeholder="Select Pricing Option(s)"  style="display: block;" value-key="id">
              <el-option v-for="item in pricing_options" :value="item" :label="item.name" :key="item.id"></el-option>
            </el-select>
            <el-switch v-model="invert_pricing_options" active-text="Filter on everything but the selected Pricing Options" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-checkbox-unchecked2" v-if="visit_type.indexOf('classes') === -1" @click="toggleVisitType('classes')"></span>
            <span class="icon icon-checkbox-checked2" v-if="visit_type.indexOf('classes') > -1" @click="toggleVisitType('classes')"></span>
          </div>
          <div class="col-xs-10">
            <label @click="toggleVisitType('classes')">
              Only include visits with specific Classes
            </label>
            <small class="form-text text-muted">This will only include visits that use any of the classes or appointment types below.</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="visit_type.indexOf('classes') > -1">
        <div class="form-group">
          <label for="pricing_options">Only include visits with specific Classes</label>
          <div class="multiselect-block">
            <Multiselect v-model="selected_classes" placeholder="Select Classes" :options="classes" label="name" track-by="name" :searchable="true" :allow-empty="true" :show-labels="false" :multiple="true" :close-on-select="false" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-checkbox-unchecked2" v-if="visit_type.indexOf('staffes') === -1" @click="toggleVisitType('staffes')"></span>
            <span class="icon icon-checkbox-checked2" v-if="visit_type.indexOf('staffes') > -1" @click="toggleVisitType('staffes')"></span>
          </div>
          <div class="col-xs-10">
            <label @click="toggleVisitType('staffes')">
              Only include visits with specific Staff
            </label>
            <small class="form-text text-muted">This will only include visits that are with any of the staff below.</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="visit_type.indexOf('staffes') > -1">
        <div class="form-group">
          <label for="pricing_options">Only include visits with specific Staff</label>
          <div class="multiselect-block">
            <Multiselect v-model="selected_staffes" placeholder="Select Staff" :options="staffes" label="staff_name" track-by="staff_name" :searchable="true" :allow-empty="true" :show-labels="false" :multiple="true" :close-on-select="false" />
          </div>
        </div>
      </div>
    </form>

      <div class="condition-banner">
        Location and Site ID (Optional)
      </div>

      <form @submit.prevent>
        <div class="form-group">
          <div class="row">
            <div class="col-xs-2">
              <span class="icon icon-radio-unchecked" v-if="misc_filtering === null || misc_filtering === true" @click="misc_filtering = false"></span>
              <span class="icon icon-radio-checked" v-if="misc_filtering === false" @click="misc_filtering = false"></span>
            </div>
            <div class="col-xs-10">
              <label for="misc_filtering_no" @click="misc_filtering = false">
                Select all locations and Site IDs
              </label>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-xs-2">
              <span class="icon icon-radio-unchecked" v-if="misc_filtering === null || misc_filtering === false" @click="misc_filtering = true"></span>
              <span class="icon icon-radio-checked" v-if="misc_filtering === true" @click="misc_filtering = true"></span>
            </div>
            <div class="col-xs-10">
              <label for="misc_filtering_yes" @click="misc_filtering = true">
                 Select specific locations or Site IDs
              </label>
            </div>
          </div>
        </div>
        <div class="options-inner" v-if="misc_filtering">
          <div class="form-group">
            <label class="form-control-label">Location</label>
            <div class="multiselect-block">
              <Multiselect v-model="location" placeholder="All Locations" label="location_name" track-by="location_id" :options="locations" :searchable="false" :allow-empty="true" :show-labels="false" />
            </div>
          </div>
        </div>
      </form>

      <form @submit.prevent>
        <div class="form-group">
          <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition" :disabled="!comparison_visit || !timeframe">Save this condition</button>
        </div>
      </form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';
  import VueDatepickerLocal from 'vue-datepicker-local';

  export default {
    props: ['locations', 'condition', 'editedRule', 'goalList'],
    components: {
      Multiselect,
      VueDatepickerLocal,
    },
    data() {
      return {
        misc_filtering: false,
        location: null,
        relative_days: 0,
        visit_number_start: 1,
        visit_number_end: 2,
        timeframe: null,
        comparison_visit: null,
        comparison_date: null,
        comparisons_date: [{
          label: 'After',
          value: 'after',
        }, {
          label: 'Before',
          value: 'before',
        }, {
          label: 'Between',
          value: 'between',
        }],
        comparisons_visit: [{
          label: 'At least',
          value: 'more',
        }, {
          label: 'Less than',
          value: 'less',
        }, {
          label: 'Exactly',
          value: 'exactly',
        }, {
          label: 'Between',
          value: 'between',
        }],
        comparisons_number: [{
          label: 'At least',
          value: 'more',
        }, {
          label: 'Within the last',
          value: 'within',
        }, {
          label: 'Exactly',
          value: 'exactly',
        }],
        dates: {
          start: '',
          end: '',
        },
        local: {
          dow: 1,
          hourTip: '',
          minuteTip: '',
          secondTip: '',
          yearSuffix: '',
          monthsHead: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
          months: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
          weeks: 'Mon_Tue_Wed_Thu_Fri_Sat_Sun'.split('_'),
        },
        visit_type: [],
        selected_pricing_options: [],
        invert_pricing_options: false,
        pricing_options: [],
        classes: [],
        selected_classes: [],
        staffes: [],
        selected_staffes: [],
      };
    },
    created() {
      this.fetchPricingOptions();
      this.fetchClasses();
      this.fetchStaffes();

      if (this.editedRule) {
        this.timeframe = this.editedRule.timeframe;
        this.visit_number_start = this.editedRule.visit_number_start;
        this.visit_number_end = this.editedRule.visit_number_end;
        this.relative_days = this.editedRule.relative_days;
        this.comparison_date = this.editedRule.comparison_date;
        this.comparison_visit = this.editedRule.comparison_visit;

        this.dates.start = this.editedRule.start_date;
        this.dates.end = this.editedRule.end_date;
        this.visit_type = this.editedRule.visit_type ? this.editedRule.visit_type : [];

        this.selected_pricing_options = this.editedRule.pricing_options;
        this.invert_pricing_options = this.editedRule.invert_pricing_options;
        this.selected_classes = this.editedRule.classes;
        this.selected_staffes = this.editedRule.staffes;

        this.misc_filtering = this.editedRule.misc.filtering;
        if (this.misc_filtering) {
          this.location = this.locations.filter(l =>
            l.location_id === this.editedRule.misc.location,
          )[0];
        }
      }
    },
    watch: {
      locations(newVal) {
        if (this.misc_filtering) {
          this.location = newVal.filter(l =>
            l.location_id === this.editedRule.misc.location,
          )[0];
        }
      },
    },
    methods: {
      fetchPricingOptions() {
        api.getPricingOptions().then((response) => {
          this.pricing_options = response.pricing_options;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchClasses() {
        api.getClasses().then((response) => {
          this.classes = response.classes;
          this.available_statuses = response.statuses;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchStaffes() {
        api.getStaffNames().then((response) => {
          this.staffes = response.staff_names;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      toggleVisitType(type) {
        if (!(this.visit_type instanceof Array)) {
          if (this.visit_type === 'pricing_options') {
            this.visit_type = [this.visit_type];
          } else {
            this.visit_type = [];
          }
        }

        if (this.visit_type.indexOf(type) === -1) {
          this.visit_type.push(type);
        } else {
          this.visit_type.splice(this.visit_type.indexOf(type), 1);
        }
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          timeframe: this.timeframe,
          visit_number_start: this.visit_number_start,
          visit_number_end: this.visit_number_end,
          relative_days: this.relative_days,
          comparison_date: this.comparison_date,
          comparison_visit: this.comparison_visit,
          start_date: this.dates.start,
          end_date: this.dates.end,
          invert_pricing_options: this.invert_pricing_options,
          visit_type: this.visit_type,
          pricing_options: this.selected_pricing_options,
          classes: this.selected_classes,
          staffes: this.selected_staffes,
          misc: {
            filtering: this.misc_filtering,
            location: this.location ? this.location.location_id : null,
            site_id: null,
          },
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar-inner {
    position: relative;
  }

  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  form .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  form .form-group:first-child {
    margin-top: 0;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked,
  .icon-checkbox-checked2,
  .icon-checkbox-unchecked2 {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }

  .alert {
    margin-top: 1.875rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: .75rem;
    background-color: #f3f7f9;
    border-radius: 6px;
  }

  .alert .material-icons {
    font-size: 1rem;
    color: #afbcca;
    margin-right: .625rem;
  }

  .alert .header {
    font-size: .75rem;
    font-weight: 700;
    line-height: 1rem;
    color: #1d364b;
  }

  .alert .body {
    margin-top: .3125rem;
    font-size: .75rem;
    line-height: 1rem;
    color: #1d364b;
  }
</style>
