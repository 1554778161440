<template>
  <div v-if="form">
     <div class="header-container">
    <div id="back-container">
      <router-link data-test-back-link :to="'/apps/forms'" class="go-back"> <span class="icon icon-arrow-left3"></span>
      Go Back
      </router-link>
    </div>
    <el-header>
      <input data-test-form-name-input type="text" autocomplete="off" v-model.trim="form.name" @keyup.enter="editName" />
      <div class="actions">
        <el-button data-test-view-hosted-form-button style="margin-right: 1rem;" type="secondary" @click.stop="openHostedForm" v-if="form.published">View Hosted Form</el-button>
        <el-dropdown data-test-options-drop-down trigger="click" @command="handleCommand" style="margin-right: 1rem;">
          <el-button data-test-options-button type="secondary"  class="xpl-button xpl-button--secondary">
            Options <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item data-test-unpublish-form-dropdown-item  command="unpublish" v-if="form.published">Unpublish Form</el-dropdown-item>
            <el-dropdown-item data-test-delete-form-dropdown-item class="text-danger" command="delete">Delete</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button type="primary" class="xpl-button xpl-button--primary" data-test-publish-button @click.stop="askPublishForm" v-if="!form.published">Publish</el-button>
        <el-button type="primary" class="xpl-button xpl-button--primary" data-test-republish-button @click.stop="askPublishForm" v-if="form.published">Re-publish</el-button>
      </div>
    </el-header>
     </div>
    <el-container class='main-container' v-loading="loading">
      <el-aside width="20%">
        <div class="sidebar">
          <div class="links">
            <div data-test-create-tab :class="['link', tabs.created ? 'active' : '']" @click="toggleTab('created')">Create</div>
            <div data-test-share class="link disabled">Share</div>
            <div data-test-page-design-tab :class="['right', 'link', tabs.design ? 'active' : '']" @click="toggleTab('design')">Page Design</div>
            <div data-test-embed-tab :class="['right', 'link', tabs.embed ? 'active' : '']" @click="toggleTab('embed')">Embed</div>
            <div data-test-insights-tab :class="['link', tabs.insights ? 'active' : '']" @click="toggleTab('insights')">Insights</div>
            <div data-test-marketing-tab :class="['link', tabs.marketing ? 'active' : '']" @click="toggleTab('marketing')">Marketing Tracking</div>
            <div data-test-advanced-tab :class="['link', tabs.advanced ? 'active' : '']" @click="toggleTab('advanced')">Advanced Options</div>
          </div>
        </div>
      </el-aside>
      <el-main id="el-main">
        <FormBuilder data-test-form-builder v-if="tabs.created" :form="form" />
        <FormDesign data-test-form-design v-if="tabs.design" :form="form" />
        <FormEmbed data-test-form-embeded v-if="tabs.embed" :form="form" />
        <FormInsights data-test-form-insights v-if="tabs.insights" :form="form" />
        <FormMarketing data-test-form-marketing v-if="tabs.marketing" :form="form" />
        <FormAdvanced data-test-form-advanced v-if="tabs.advanced" :form="form" />
      </el-main>
    </el-container>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import {
    getForm,
    deleteForm,
    updateForm,
    publishForm,
    unpublishForm } from '@/api';
  import FormBuilder from './Form/FormBuilder';
  import FormDesign from './Form/FormDesign';
  import FormEmbed from './Form/FormEmbed';
  import FormInsights from './Form/FormInsights';
  import FormMarketing from './Form/FormMarketing';
  import FormAdvanced from './Form/FormAdvanced';

  export default {
    props: ['uuid'],
    components: {
      FormBuilder,
      FormDesign,
      FormEmbed,
      FormInsights,
      FormMarketing,
      FormAdvanced,
    },
    data() {
      return {
        loading: false,
        form: null,
        tabs: {
          created: true,
          design: false,
          embed: false,
          insights: false,
          marketing: false,
          advanced: false,
        },
      };
    },
    mounted() {
      EventBus.$on('form:refresh', () => {
        this.fetchForm();
      });
    },
    created() {
      this.fetchForm();

      if (this.$route.query.tab) {
        // eslint-disable-next-line
        for (const tab in this.tabs) {
          // eslint-disable-next-line
          if (this.tabs.hasOwnProperty(tab)) {
            this.tabs[tab] = false;
          }
        }
        this.tabs[this.$route.query.tab] = true;
      }
    },
    beforeDestroy() {
      EventBus.$off('form:refresh');
    },
    methods: {
      openHostedForm() {
        const forms_subdomain = process.env.NODE_ENV === 'production' ? 'forms' : 'forms-staging'; //eslint-disable-line
        const link = `https://${this.form.subdomain}.brandbot.io/${forms_subdomain}/${this.form.settings.vanity}.html`; //eslint-disable-line
        return window.open(link);
      },
      toggleTab(t) {
        // eslint-disable-next-line
        for (const tab in this.tabs) {
          // eslint-disable-next-line
          if (this.tabs.hasOwnProperty(tab)) {
            this.tabs[tab] = false;
          }
        }

        this.tabs[t] = true;
        // Scroll to the top of the page
        this.$router.push({ query: { tab: t } });
        document.getElementById('el-main').scrollTop = 0;
      },
      async fetchForm() {
        this.loading = true;
        await window.sleep(1000);
        getForm(this.uuid).then((response) => {
          this.loading = false;
          this.form = response.form;
          this.form.settings = JSON.parse(this.form.settings);
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      handleCommand(command) {
        if (command === 'delete') {
          this.$confirm('This will permanently delete this Form. Continue?', 'Warning', {
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }).then(() => {
            this.delForm();
          });
        }

        if (command === 'unpublish') {
          this.$confirm('Are you sure you want to unpublish this Form? Unpublishing a form does *not* stop your Embed Code from working.', 'Warning', {
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }).then(() => {
            this.unpublish();
          });
        }
      },
      editName() {
        const payload = {
          name: this.form.name,
        };

        updateForm(this.form.id, payload).then((response) => {
          this.$message.success(response.message);
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      delForm() {
        this.loading = true;
        deleteForm(this.form.id).then((response) => {
          this.$message.success(response.message);
          this.$router.push('/apps/forms');
          this.loading = false;
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      askPublishForm() {
        this.$confirm('Are you sure you want to publish this Form?', 'Publish?').then(() => {
          this.callPublishForm();
        });
      },
      callPublishForm() {
        const payload = {
          uuid: this.form.uuid,
        };

        this.loading = true;

        publishForm(payload).then((response) => {
          this.$message.success(response.message);
          this.loading = false;
          this.fetchForm();
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      unpublish() {
        const payload = {
          uuid: this.form.uuid,
        };

        this.loading = true;

        unpublishForm(payload).then((response) => {
          this.$message.success(response.message);
          this.loading = false;
          this.fetchForm();
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
@import "src/scss/global/_spacing.scss";
 #back-container {
   padding: $space-tight $space-loose 0 $space-loose;
   background-color: white;
 }
  .el-container {
    height: calc(100vh - 90px);
    background-color: white;
  }
  .el-header {
    position: fixed;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--xpl-color-gray-400);
  }
  .el-aside {
    padding: 1.25rem;
  }
  .el-header input {
    text-align: left;
    border: none;
    outline: none;
    width: calc(100% / 3);
    font-size: 1.25rem;
    line-height: 1.4;
    color: #00152a;
  }
  .el-header .actions {
    width: calc(100% / 3);
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .el-header .actions .btn:first-child {
    margin-right: 1rem;
  }
  .sidebar .links .link {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #00152a;
    margin-bottom: 1rem;
    padding: 4px 16px;
    cursor: pointer;
    transition: all 150ms ease-in-out;
    border-right: 3px solid transparent;
  }
  .sidebar .links .link.right {
    padding-left: 1.6rem;
  }
  .sidebar .links .link.disabled {
    cursor: initial;
  }
  .sidebar .links .link:hover:not(.disabled),
  .sidebar .links .link.active {
    background-color: var(--xpl-color-secondary-bg-lm);
  }
  .el-main {
    padding-bottom: 5rem;
  }
  .header-container{
    position: fixed;
    z-index: 1029;
    width: 100%;
  }
  .main-container {
    padding-top: 90px;
  }
  .xpl-button {
    text-wrap: none;
  }
</style>
