<template>
  <el-card shadow="never">
    <h1>Connect to MINDBODY</h1>
    <el-form ref="mboForm" :model="mindbody" :rules="rules" status-icon @submit.native.prevent>
      <el-form-item prop="siteId">
        <label for="site_id">MINDBODY Site ID</label>
        <el-input id="site_id" v-model.number="mindbody.siteId" placeholder="Site ID" />
        <el-checkbox v-model="mindbody.owner" label="I am currently signed in on another tab with owner permissions"></el-checkbox>
      </el-form-item>
      <el-form-item>
        <p style="line-height: 1.5;">After clicking the button below you will be taken to the MINDBODY Integration Page. If you see a <strong style="color: green;">green check mark</strong>, then you are all set. You can close the tab and come back to {{$store.getters.brandName}}.</p>
        <el-button type="secondary" class="xpl-button xpl-button--secondary"  @click.stop="launchMB" v-if="!connected" :disabled="!mindbody.owner" :loading="loading">Launch MINDBODY</el-button>
      </el-form-item>
      <el-form-item style="text-align: center; margin-top: 1.5rem;" v-if="connected">
        <el-button type="primary" class="xpl-button xpl-button--primary":loading="loading" :disabled="!connected" @click.stop="checkConnection">Continue</el-button>
      </el-form-item>
      <el-form-item>
        <el-row>
          <el-col :span="12">
            <a href="#" class="text-muted text-small" @click.prevent="goBack">Go Back</a>
          </el-col>
          <el-col :span="12" class="text-right">
            <a href="#" class="text-muted text-small" @click.prevent="skip">Skip</a>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    data() {
      return {
        loading: false,
        connected: false,
        mindbody: {
          siteId: null,
          owner: false,
        },
        rules: {
          siteId: [{ required: true, type: 'number', trigger: 'change', message: 'Your Site ID is required' }],
        },
      };
    },
    methods: {
      goBack() {
        localStorage.removeItem('brandbotSelectedVendor');
        EventBus.$emit('signup.account.done');
      },
      skip() {
        EventBus.$emit('signup.vendor.done', 'mindbody');
      },
      checkConnection() {
        this.loading = true;
        api.checkMindbodyConnection().then(() => {
          this.loading = false;
          this.$nextTick(() => {
            EventBus.$emit('signup.vendor.done', 'mindbody');
          });
        }).catch((err) => {
          this.loading = false;
          if (err.errors) {
            this.$message.error(err.errors[Object.keys(err.errors)[0]][0]);
          } else {
            this.$message
             .error('We were unable to create your account. Please try again later.');
          }
        });
      },
      launchMB() {
        this.loading = true;

        const payload = {
          site_id: this.mindbody.siteId,
        };
        api.activateMindbodySite(payload).then((response) => {
          const newTab = window.open('about:blank', 'newtab');
          newTab.document.body.append('Loading... Please wait...');
          newTab.location = response.url;
          newTab.focus();
          this.connected = true;
          this.loading = false;
        }).catch((err) => {
          this.loading = false;
          if (err.errors) {
            this.$message.error(err.errors[Object.keys(err.errors)[0]][0]);
          } else {
            this.$message
             .error('We were unable to create your account. Please try again later.');
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-card {
    max-width: 500px;
    width: 500px;
    min-width: 500px;
    border: none;
  }

  .el-card h1 {
    font-size: 1.75rem;
    color: #00152a;
    margin-bottom: 2.5rem;
  }

  .el-form-item {
    margin-bottom: 1rem;
  }
</style>
