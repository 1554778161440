import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/components/Auth/Login';
import Browser from '@/components/Browser';
import Signup from '@/components/Auth/Signup';
import Pike13Landing from '@/components/Auth/Pike13Landing';
import ForgotPassword from '@/components/Auth/ForgotPassword';
import ResetPassword from '@/components/Auth/ResetPassword';
import ConfirmNeeded from '@/components/Auth/ConfirmNeeded';
import ConfirmEmail from '@/components/Auth/ConfirmEmail';
import ConfirmInvitation from '@/components/Auth/ConfirmInvitation';
import Calendar from '@/components/Calendar/Calendar';
import Plans from '@/components/Plans/Plans';
import Plan from '@/components/Plans/Plan/Plan';
import CalendarList from '@/components/Calendar/CalendarList';
import ScheduledEmail from '@/components/ScheduledItems/ScheduledEmail';
import Campaigns from '@/components/Campaigns/Campaigns';
import Campaign from '@/components/Campaigns/Campaign/Campaign';
import CampaignSettings from '@/components/Campaigns/Campaign/CampaignSettings';
import CampaignSynced from '@/components/Campaigns/Campaign/CampaignSynced';
import CampaignItem from '@/components/Campaigns/Campaign/CampaignItem';
import ManualEnroll from '@/components/Campaigns/Campaign/ManualEnroll';
import Contacts from '@/components/Contacts/Contacts';
import ContactDetail from '@/components/Contacts/ContactDetail';
import ContactList from '@/components/Contacts/ContactLists/ContactList';
import Form from '@/components/Contacts/EmbeddableForms/Form';
import Funnel from '@/components/Contacts/Funnels/Funnel';
import ImportContactsContactList from '@/components/Contacts/ContactLists/ImportContactsContactList';
import Assets from '@/components/Account/Assets/Assets';
import Team from '@/components/Account/Team';
import Templates from '@/components/Account/Templates/Templates';
import Tags from '@/components/Account/Tags/Tags';
import SavedLinks from '@/components/Account/SavedLinks/SavedLinks';
import SavedPdfs from '@/components/Account/SavedPdfs/SavedPdfs';
import Settings from '@/components/Settings/Settings';
import Invoice from '@/components/Settings/Invoice';
import VerifiedEmailConfirm from '@/components/Settings/VerifiedEmailConfirm';
import NotFound from '@/components/Errors/NotFound';
import Upgrade from '@/components/Upgrade';
import Inbox from '@/components/Inbox/Inbox';
import CheckoutFlows from '@/components/CheckoutFlows/CheckoutFlows';
import CheckoutFlow from '@/components/CheckoutFlows/CheckoutFlow';
import QuickBrandContainer from '@/components/Account/Branding/QuickBrandContainer';
import Apps from '@/components/Apps/Apps';
import Forms from '@/components/Forms/Forms';
import Waivers from '@/components/Waivers/Waivers';
import Waiver from '@/components/Waivers/Waiver';
import FormsForm from '@/components/Forms/Form';
import TargetedSMS from '@/components/TargetedSMS/TargetedSMS';
import TargetedSMSSingle from '@/components/TargetedSMS/TargetedSMSSingle';
import ClassReminders from '@/components/ClassReminders/ClassReminders';
import SheerID from '@/components/SheerID/SheerID';
import Program from '@/components/SheerID/Program';
import ClassDetail from '@/components/ClassReminders/ClassDetail';
import store from '../store';

Vue.use(Router);

const isMobile = (function (agent) {  switch (true) {

  case agent.indexOf("android") > -1: return "android";
  case agent.indexOf("ios") > -1: return "ios";
  case agent.indexOf("mobile") > -1: return "mobile";
  case agent.indexOf("iphone") > -1: return "iphone";
  default: return false;
}
})(window.navigator.userAgent.toLowerCase());

const browserName = (function (agent) {  switch (true) {

  case agent.indexOf("chrome") > -1 && !!window.chrome: return "chrome";
  case agent.indexOf("safari") > -1: return "safari";
  default: return false;
}
})(window.navigator.userAgent.toLowerCase());

const validDesktopBrowser = browserName === 'chrome' || browserName === 'safari';



function checkAuth(to, from, next) {

 
  if (!validDesktopBrowser || isMobile) {
    // Route to the browser component if either is true
    next({ path: '/browser' });
  }

  if (!store.getters.isLoggedIn) {
    next({path: '/login'});

    return;
  }

  // canonical domain
  if (store.getters.toCanonicalUrl) {
    window.toCanonicalHost(store.getters.brandHost);
    //window.location.replace(store.getters.toCanonicalUrl);
  }

  store.dispatch('refreshUser').then(() => {
    if (store.getters.isLoggedIn &&
      typeof store.getters.isConfirmed !== 'undefined' &&
      !store.getters.isConfirmed &&
      to.path !== '/confirm-needed') {
      next({path: '/confirm-needed'});

      return;
    }

    if (!store.state.user.current_account.good_standing &&
      to.path !== '/upgrade' &&
      to.path.indexOf('/settings') === -1) {
      next({path: '/upgrade'});
    } else {
      next();
    }

    if (store.state.user.current_account.active_vendors &&
      store.state.user.current_account.active_vendors.length &&
      store.state.user.current_account.active_vendors.indexOf('zingfit') > -1 &&
      to.path.indexOf('/settings') === -1 &&
      to.path.indexOf('/upgrade') === -1 &&
      to.path.indexOf('/quick-brand') === -1 &&
      to.path.indexOf('/apps/class-reminders') === -1) {
      next({path: '/apps/class-reminders'});
    } else {
      next();
    }
  }).catch((err) => {
    if (err.error && err.error === 'Unauthenticated.') {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('user');
      window.location.href = '/login';
    }
  });
}

function kickIfAuth(to, from, next) {
  if (store.getters.isLoggedIn) {
    next({path: '/calendar'});
  } else {
    next();
  }
}

function checkAuthNonUIA(to, from, next) {
  if (store.getters.isLoggedIn && store.getters.isUIAVendor) {
    next({ path: '/calendar' });
  } else {
    checkAuth(to, from, next);
  }
}

function checkUIASettings(to, from, next) {
  if (store.getters.isUIAVendor && to.params.tab === 'franchise_mapping') {
    next({ path: '/*' });
  } else {
    checkAuth(to, from, next);
  }
}

function checkUIACampaignSyncing(to, from, next) {
  if (store.getters.isUIAVendor && !store.getters.isHQ) {
    next({ path: '/*' });
  } else {
    checkAuth(to, from, next);
  }
}


function asAnonymous(to,from,next) {
  store.state.anonymous = true;
  next();
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'root',
      redirect: '/login',
      beforeEnter: checkAuth,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      beforeEnter: kickIfAuth,
    },
    {
      path: '/browser',
      name: 'browser',
      component: Browser,
      props: { validDesktopBrowser: validDesktopBrowser, isMobile: isMobile },
    },
    {
      path: '/upgrade',
      name: 'upgrade',
      component: Upgrade,
      beforeEnter: checkAuth,
    },
    {
      path: '/signup/pike13',
      name: 'signup pike13',
      component: Pike13Landing,
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
    },
    {
      path: '/forgot-password',
      name: 'forgot password',
      component: ForgotPassword,
      beforeEnter: kickIfAuth,
    },
    {
      path: '/reset-password/:token',
      name: 'reset password',
      component: ResetPassword,
      beforeEnter: asAnonymous,
      props: true,
    },
    {
      path: '/confirm-needed',
      name: 'confirm needed',
      component: ConfirmNeeded,
      // beforeEnter: checkAuth,
    },
    {
      path: '/confirm/email/:uuid',
      name: 'confirm email',
      component: ConfirmEmail,
      props: true,
    },
    {
      path: '/confirm/invitation/:uuid',
      name: 'confirm invitation',
      component: ConfirmInvitation,
      props: true,
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: Calendar,
      beforeEnter: checkAuth,
    },
    {
      path: '/tasks/:id',
      name: 'tasks',
      component: Calendar,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '/call-reminders/:id',
      name: 'call reminders',
      component: Calendar,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '/plans',
      name: 'plans',
      component: Plans,
      beforeEnter: checkAuth,
    },
    {
      path: '/plans/:uuid',
      name: 'plan',
      component: Plan,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '/calendar/list',
      name: 'calendar list',
      component: CalendarList,
      beforeEnter: checkAuth,
    },
    {
      path: '/scheduled-emails/:uuid',
      name: 'scheduled email',
      component: ScheduledEmail,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '/campaigns',
      name: 'campaigns',
      component: Campaigns,
      beforeEnter: checkAuth,
    },
    {
      path: '/campaigns/items/:uuid',
      name: 'campaigns items',
      component: CampaignItem,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '/campaigns/:uuid',
      name: 'campaign',
      component: Campaign,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '/campaigns/:uuid/settings',
      name: 'campaign settings',
      component: CampaignSettings,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '/campaigns/:uuid/synced',
      name: 'campaign synced',
      component: CampaignSynced,
      beforeEnter: checkUIACampaignSyncing,
      props: true,
    },
    {
      path: '/campaigns/:uuid/enroll',
      name: 'campaign manual enroll',
      component: ManualEnroll,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: Contacts,
      beforeEnter: checkAuth,
    },
    {
      path: '/contacts/funnels',
      name: 'contacts funnels',
      component: Contacts,
      beforeEnter: checkAuth,
    },
    {
      path: '/contacts/funnels/:uuid',
      name: 'contacts funnel',
      component: Funnel,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '/contacts/lists',
      name: 'contact lists',
      component: Contacts,
      beforeEnter: checkAuth,
    },
    {
      path: '/contacts/embed',
      name: 'contact embed',
      component: Contacts,
      beforeEnter: checkAuth,
    },
    {
      path: '/contacts/embed/:uuid',
      name: 'contact embed form',
      component: Form,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '/contacts/lists/:uuid',
      name: 'contact list',
      component: ContactList,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '/contacts/lists/:uuid/import',
      name: 'contact list import',
      component: ImportContactsContactList,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '/contacts/:uuid',
      name: 'contact detail',
      component: ContactDetail,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '/account/team',
      name: 'account team',
      component: Team,
      beforeEnter: checkAuth,
    },
    {
      path: '/account/assets',
      name: 'account assets',
      component: Assets,
      beforeEnter: checkAuth,
    },
    {
      path: '/account/templates',
      name: 'account templates',
      component: Templates,
      beforeEnter: checkAuth,
    },
    {
      path: '/account/tags',
      name: 'account tags',
      component: Tags,
      beforeEnter: checkAuth,
    },
    {
      path: '/account/saved-links',
      name: 'account saved-links',
      component: SavedLinks,
      beforeEnter: checkAuth,
    },
    {
      path: '/account/saved-pdfs',
      name: 'account saved-pdfs',
      component: SavedPdfs,
      beforeEnter: checkAuth,
    },
    {
      path: '/settings/:tab',
      name: 'settings',
      component: Settings,
      beforeEnter: checkUIASettings,
      props: true,
    },
    {
      path: '/settings/billing/invoice/:invoiceId',
      name: 'settings billing invoice',
      component: Invoice,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '/verified-emails/confirm/:uuid',
      name: 'verified emails confirm',
      component: VerifiedEmailConfirm,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '/inbox',
      name: 'inbox',
      component: Inbox,
      beforeEnter: checkAuth,
    },
    {
      path: '/apps/class-reminders',
      name: 'class reminders',
      component: ClassReminders,
      beforeEnter: checkAuth,
    },
    {
      path: '/apps/class-reminders/:uuid',
      name: 'class detail',
      component: ClassDetail,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '/apps/landing-pages',
      name: 'checkout flows',
      component: CheckoutFlows,
      beforeEnter: checkAuthNonUIA,
    },
    {
      path: '/apps/landing-pages/:uuid',
      name: 'checkout flow',
      component: CheckoutFlow,
      beforeEnter: checkAuthNonUIA,
      props: true,
    },
    {
      path: '/quick-brand',
      name: 'quick brand',
      component: QuickBrandContainer,
      beforeEnter: checkAuth,
    },
    {
      path: '/apps',
      name: 'apps',
      component: Apps,
      beforeEnter: checkAuth,
    },
    {
      path: '/apps/forms',
      name: 'forms',
      component: Forms,
      beforeEnter: checkAuth,
    },
    {
      path: '/apps/forms/:uuid',
      name: 'form',
      component: FormsForm,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '/apps/waivers',
      name: 'waivers',
      component: Waivers,
      beforeEnter: checkAuth,
    },
    {
      path: '/apps/waivers/:uuid',
      name: 'waiver',
      component: Waiver,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '/apps/targeted-sms',
      name: 'targeted sms',
      component: TargetedSMS,
      beforeEnter: checkAuth,
    },
    {
      path: '/apps/targeted-sms/:uuid',
      name: 'targeted sms single',
      component: TargetedSMSSingle,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '/apps/sheer-id',
      name: 'sheer id',
      component: SheerID,
      beforeEnter: checkAuth,
    },
    {
      path: '/apps/sheer-id/:uuid',
      name: 'sheer id program',
      component: Program,
      beforeEnter: checkAuth,
      props: true,
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFound,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return {x: 0, y: 0};
  },
});

router.beforeEach((to, from, next) => {
  console.log({ anon: store.state.anonymous})
  store.state.anonymous = false;
  next();
})


export default router;
