<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">1</span>
        </el-col>
        <el-col :span="22" class="v2__heading">Do you want contacts who have or do not have a reservation in the future?</el-col>
      </el-row>

      <el-row style="margin-bottom: 2rem;">
        <el-col :offset="2">
          <el-radio-group v-model="has_reservation">
            <el-radio :label="true" border>Contacts who have</el-radio>
            <el-radio :label="false" border>Contacts who have <b>not</b></el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">2</span>
        </el-col>
        <el-col :span="22" class="v2__heading">How far in the future?</el-col>
      </el-row>

      <el-row style="margin-bottom: 2rem;">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-form>
              <el-form-item>
                <el-select v-model="timeframe" placeholder="Choose a timeframe">
                  <el-option :key="'more'" :label="'Within the next'" :value="'more'"></el-option>
                  <el-option :key="'exactly'" :label="'Exactly'" :value="'exactly'"></el-option>
                </el-select>
                <el-input-number v-model="relative_days" :min="0" :step="1" />
                days <span v-if="timeframe === 'exactly'">from now</span>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>

      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">3</span>
        </el-col>
        <el-col :span="22" class="v2__heading">How many reservations?</el-col>
      </el-row>

    <div style="margin-bottom: 2rem;">
      <el-row style="margin-bottom: 1rem;">
        <el-col :offset="2" :span="22">
          <div class="options">
            <el-radio-group v-model="visit_comparison">
              <el-radio-button label="more">At least</el-radio-button>
              <el-radio-button label="less">Less than</el-radio-button>
              <el-radio-button label="exactly">Exactly</el-radio-button>
              <el-radio-button label="between">Between</el-radio-button>
            </el-radio-group>
            <div style="margin-top: 1rem;" v-if="visit_comparison">
              <div v-if="visit_comparison && visit_comparison !== 'between'">
                <el-input-number v-model="visit_number_start" :min="0" ></el-input-number>
              </div>
              <div v-if="visit_comparison && visit_comparison === 'between'">
                <el-input-number v-model="visit_number_start" :min="0" ></el-input-number>
                and
                <el-input-number v-model="visit_number_end" :min="0" ></el-input-number>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">4</span>
        </el-col>
        <el-col :span="22" class="v2__heading">Do you want to filter on visits with specific Staff Members?</el-col>
      </el-row>

      <div style="margin-bottom: 2rem;">
        <el-row style="margin-bottom: 1rem;">
          <el-col :offset="2">
            <el-radio-group v-model="filter_staff">
              <el-radio :label="true" border>Yes</el-radio>
              <el-radio :label="false" border @click.native="selected_staff = []">No</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>

        <el-row v-if="filter_staff">
          <el-col :offset="2" :span="22">
            <div class="options">
              <el-form>
                <label>Staff Members</label>
                <el-form-item>
                  <el-select v-model="selected_staff" multiple filterable style="width: 100%;" multiple placeholder="Staff Members">
                    <el-option v-for="staff in staffes" :key="staff.id" :label="staff.name" :value="staff.name"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </div>

      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">5</span>
        </el-col>
        <el-col :span="22" class="v2__heading">Do you want to filter on visits with specific Payments Options?</el-col>
      </el-row>

      <div style="margin-bottom: 2rem;">
        <el-row style="margin-bottom: 1rem;">
          <el-col :offset="2">
            <el-radio-group v-model="filter_payment_options">
              <el-radio :label="true" border>Yes</el-radio>
              <el-radio :label="false" border @click.native="selected_payments = []">No</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>

        <el-row v-if="filter_payment_options">
          <el-col :offset="2" :span="22">
            <div class="options">
              <el-form>
                <label>Payment Options</label>
                <el-form-item>
                  <el-select v-model="selected_payments" multiple filterable style="width: 100%;" placeholder="Payment Options">
                    <el-option v-for="payment in payment_options" :key="payment.name" :label="payment.name" :value="payment.name"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </div>

      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">6</span>
        </el-col>
        <el-col :span="22" class="v2__heading">Do you want to filter on visits with specific Event Names?</el-col>
      </el-row>

      <div style="margin-bottom: 2rem;">
        <el-row style="margin-bottom: 1rem;">
          <el-col :offset="2">
            <el-radio-group v-model="filter_event_names">
              <el-radio :label="true" border>Yes</el-radio>
              <el-radio :label="false" border @click.native="selected_events = []">No</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>

        <el-row v-if="filter_event_names">
          <el-col :offset="2" :span="22">
            <div class="options">
              <el-form>
                <label>Event Names</label>
                <el-form-item>
                  <el-select v-model="selected_events" multiple filterable style="width: 100%;" placeholder="Event Names">
                    <el-option v-for="event in events" :key="event.name" :label="event.name" :value="event.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </div>

      <el-row style="margin-bottom: 1.25rem;">
        <el-col :span="2">
          <span class="step-circle">7</span>
        </el-col>
        <el-col :span="22" class="v2__heading">Do you want to filter on visits with specific Service Names?</el-col>
      </el-row>

      <div style="margin-bottom: 2rem;">
        <el-row style="margin-bottom: 1rem;">
          <el-col :offset="2">
            <el-radio-group v-model="filter_service_names">
              <el-radio :label="true" border>Yes</el-radio>
              <el-radio :label="false" border @click.native="selected_service_names = []">No</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>

        <el-row v-if="filter_service_names">
          <el-col :offset="2" :span="22">
            <div class="options">
              <el-form>
                <label>Service Names</label>
                <el-form-item>
                  <el-select v-model="selected_service_names" multiple filterable style="width: 100%;" placeholder="Service Names">
                    <el-option v-for="service in service_names" :key="service.name" :label="service.name" :value="service.name"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <el-form>
      <el-form-item>
        <el-button type="primary" class="xpl-button xpl-button--primary" @click.stop="commitCondition">Save this condition</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['condition', 'editedRule'],
    data() {
      return {
        has_reservation: true,
        timeframe: 'more',
        relative_days: 1,
        filter_staff: false,
        filter_payment_options: false,
        filter_service_names: false,
        filter_event_names: false,
        staffes: [],
        selected_staff: [],
        payment_options: [],
        selected_payments: [],
        service_names: [],
        selected_service_names: [],
        events: [],
        selected_events: [],
        visit_comparison: null,
        visit_number_start: 1,
        visit_number_end: 2,
      };
    },
    created() {
      this.fetchStaffMembers();
      this.fetchPaymentOptions();
      this.fetchServiceNames();
      this.fetchEvents();

      if (this.editedRule) {
        this.has_reservation = this.editedRule.has_reservation;
        this.timeframe = this.editedRule.timeframe;
        this.relative_days = this.editedRule.relative_days;

        if (this.editedRule.staffes.length) {
          this.filter_staff = true;
        }

        if (this.editedRule.payment_options.length) {
          this.filter_payment_options = true;
        }

        if (this.editedRule.service_names.length) {
          this.filter_service_names = true;
        }

        if (this.editedRule.events.length) {
          this.filter_event_names = true;
        }

        this.selected_staff = this.editedRule.staffes;
        this.selected_payments = this.editedRule.payment_options;
        this.selected_service_names = this.editedRule.service_names;
        this.selected_events = this.editedRule.events;

        this.visit_comparison = this.editedRule.visit_comparison;
        this.visit_number_start = this.editedRule.visit_number_start;
        this.visit_number_end = this.editedRule.visit_number_end;
      }
    },
    methods: {
      fetchStaffMembers() {
        api.getStaffMembers().then((response) => {
          this.staffes = response.staffes;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchPaymentOptions() {
        api.getPaymentOptions().then((response) => {
          this.payment_options = response.payment_options;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchServiceNames() {
        api.getServiceNames().then((response) => {
          this.service_names = response.service_names;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchEvents() {
        api.getPikeEvents().then((response) => {
          this.events = response.events;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          has_reservation: this.has_reservation,
          timeframe: this.timeframe,
          relative_days: this.relative_days,
          staffes: this.selected_staff,
          payment_options: this.selected_payments,
          service_names: this.selected_service_names,
          events: this.selected_events,
          visit_comparison: this.visit_comparison,
          visit_number_start: this.visit_number_start,
          visit_number_end: this.visit_number_end,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
 .options {
    padding: 1.25rem;
    padding-bottom: .5rem;
    background-color: #f5f8fb;
    margin-bottom: 1.5rem;
  }
</style>
