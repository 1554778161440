<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Edit Template
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <el-form>
              <el-form-item>
                <label>Name</label>
                <el-input type="text" v-model="template.name" />
              </el-form-item>
              <el-form-item>
                <label>Subject</label>
                <el-input type="text" v-model="template.subject" />
              </el-form-item>
              <el-form-item>
                <label>Pre-Header Text</label>
                <el-input type="text" v-model="template.pre_header_text" />
              </el-form-item>
              <el-form-item>
                <label>Body</label>
                <el-input type="textarea" v-model="mjml" rows="5" />
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="xpl-button xpl-button--primary" @click="saveTemplate" :loading="loading">Save</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import axios from 'axios';
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    props: ['template'],
    data() {
      return {
        loading: false,
        mjml: null,
      };
    },
    created() {
      this.getMjmlVersion();
    },
    methods: {
      getMjmlVersion() {
        if (this.template.type !== 'full') {
          this.mjml = this.template.body;
          return;
        }

        const payload = {
          payload: JSON.parse(this.template.body),
        };

        axios.post('https://livepreview.brndbot.net/template/json2mjml', payload).then((response) => {
          this.mjml = response.data;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
      closeModal() {
        EventBus.$emit('edit_template.close');
      },
      saveTemplate() {
        this.loading = true;

        const template = this.template;
        delete template.body;

        const payload = {
          brandbot: true,
          template,
          mjml: this.mjml,
        };
        api.updateEmailTemplatesSaved(this.template.id, payload).then((response) => {
          this.loading = false;
          this.$message.success(response.message);
          this.closeModal();
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal .modal-content .modal-body {
    max-height: none;
  }
</style>
