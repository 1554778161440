import { render, staticRenderFns } from "./CampaignSettings.vue?vue&type=template&id=191a5dae&scoped=true&"
import script from "./CampaignSettings.vue?vue&type=script&lang=js&"
export * from "./CampaignSettings.vue?vue&type=script&lang=js&"
import style0 from "./CampaignSettings.vue?vue&type=style&index=0&id=191a5dae&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "191a5dae",
  null
  
)

export default component.exports