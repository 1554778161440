<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Create a Template
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="row">
                <div class="col-md-1">
                  <span class="icon icon-radio-unchecked" v-if="!create_new" @click="create_new = true; existing = false;"></span>
                  <span class="icon icon-radio-checked" v-if="create_new" @click="create_new = true; existing = false;"></span>
                </div>
                <div class="col-md-11">
                  <div class="label" @click="create_new = true; existing = false;">Create a new template</div>
                  <div class="small text-muted">Create a brand new template to use for future emails</div>
                  <div class="form-group" v-if="create_new">
                    <label for="new_name">Name this Template</label>
                    <input type="text" id="new_name" v-model.trim="name" class="form-control" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-1">
                  <span class="icon icon-radio-unchecked" v-if="!existing" @click="existing = true; create_new = false;"></span>
                  <span class="icon icon-radio-checked" v-if="existing" @click="existing = true; create_new = false;"></span>
                </div>
                <div class="col-md-11">
                  <div class="label" @click="existing = true; create_new = false;">Save over an existing template</div>
                  <div class="small text-muted">Replace an old template with a new updated version</div>
                  <div class="form-group" v-if="existing">
                    <label>Existing Template to Replace</label>
                    <Multiselect v-model="existing_template" placeholder="Select an existing template" :options="templates" :searchable="true" label="name" track-by="id" :allow-empty="false" :show-labels="false" />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="button" class="xpl-button xpl-button--primary" @click="saveTemplate">{{ create_new ? 'Create' : 'Replace' }}</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['emailId', 'email'],
    components: {
      Multiselect,
    },
    data() {
      return {
        create_new: true,
        existing: false,
        name: '',
        existing_template: '',
        templates: [],
      };
    },
    created() {
      api.getEmailTemplatesSaved(this.email.type).then((response) => {
        this.templates = response.templates;
      }).catch((err) => {
        this.$toasted.error(err.message);
      });
    },
    methods: {
      saveTemplate() {
        let payload;
        if (this.create_new) {
          payload = {
            action: 'template',
            email_id: this.emailId,
            name: this.name,
          };

          api.newEmailTemplate(payload).then((response) => {
            this.$toasted.success(response.message);
            this.name = '';
            this.existing_template = '';
            this.closeModal();
          }).catch((err) => {
            this.$toasted.error(err.message);
          });
        }

        if (this.existing) {
          payload = {
            email_id: this.emailId,
          };

          api.updateEmailTemplatesSaved(this.existing_template.id, payload).then((response) => {
            this.$toasted.success(response.message);
            this.name = '';
            this.existing_template = '';
            this.closeModal();
          }).catch((err) => {
            this.$toasted.error(err.message);
          });
        }
      },
      closeModal() {
        EventBus.$emit('scheduled_email.template.close');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .form-group {
    padding: 1rem;
    background-color: #f3f7f9;
  }

  .form-group label {
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.25rem;
    color: #1d364b;
    margin-bottom: .25rem;
  }

  .label {
    cursor: pointer;
  }

  .small.text-muted {
    margin-bottom: 1.5rem;
  }
</style>
