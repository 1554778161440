<template>
  <div v-loading="loading">
    <div class="text-right">
      <el-dropdown @command="newIntegration">
        <el-button type="primary" class="xpl-button xpl-button--primary">Add a new Integration <i class="el-icon-arrow-down el-icon--right"></i></el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="int in availableIntegrations" :command="int" :key="int">
            {{ integration_names[int] }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-card v-for="integration in integrations" v-loading="loading" :key="integration.identifier">
      <div slot="header" class="clearfix">
        <span>{{integration.displayName}} Integration</span>
        <span style="float: right; padding: 3px 0" v-if="integration.vendor === 'mindbody'">
          Site ID: {{ integration.identifier }}
        </span>
        <span style="float: right; padding: 3px 0" v-if="integration.vendor === 'pike13'">
          Subdomain: {{ integration.identifier }}
        </span>
        <span style="float: right; padding: 3px 0" v-if="integration.vendor === 'marianatek'">
          Site: {{ integration.identifier }}
        </span>
        <span style="float: right; padding: 3px 0" v-if="integration.vendor === 'vimeo'">
          API Key: {{ integration.api_key }}
        </span>
        <span style="float: right; padding: 3px 0" v-if="integration.vendor === 'zingfit'">
          Region ID: {{ integration.identifier }}
        </span>
      </div>
      <div v-if="integration.vendor === 'marianatek'" class="location-spacing">
        <div :class="integration.confirmed ? 'text-success' : 'text-danger'">{{ integration.confirmed ? 'Active' : 'Not Active' }}</div>
        <div class="location-text">
          <div>Connected locations:
            <div  v-if="linked_locations === null && !fetchLocationsFailed">loading...</div>
            <div  v-if="linked_locations && linked_locations.length">
              <div  v-for="location in linked_locations" :key="location.id"><b>{{location.name}}</b></div>
            </div>
          </div>
          <div v-if="linked_locations && linked_locations.length === 0 && !fetchLocationsFailed">No connected locations</div>
          <span class="text-danger" v-if="fetchLocationsFailed">Failed to load locations</span>
          <a v-if="fetchLocationsFailed" @click="fetchLinkedLocationsRefresh">Refresh</a>
        </div>
      </div>

      <div class="pulls text-muted small" v-if="integration.vendor === 'mindbody'">
        <div>Contacts data and newly created contacts refresh roughly every hour.</div>
        <div>Visits and sales data refresh roughly every 30 minutes.</div>
        <div>Account data such as new pricing options or new classes refresh roughly every hour.</div>
      </div>
      <div style="margin-top: 1rem;" v-if="integration.vendor === 'mindbody'">
        <el-button type="secondary" class="xpl-button xpl-button--secondary"  size="small" @click.stop="checkMBOIntegration" :loading="loading">Check MINDBODY Connection</el-button>
      </div>
    </el-card>
    <ConnectMindbody v-if="show_mindbody_modal" />
    <ConnectPerkville v-if="show_perkville_modal" />
    <ConnectVimeo v-if="show_vimeo_modal" />
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import { getActiveIntegrations, checkMindbodyConnection, getMTLinkedLocations } from '@/api';
  import ConnectMindbody from './ConnectMindbody';
  import ConnectPerkville from './ConnectPerkville';
  import ConnectVimeo from './ConnectVimeo';

  export default {
    components: {
      ConnectMindbody,
      ConnectPerkville,
      ConnectVimeo,
    },
    data() {
      return {
        loading: true,
        show_mindbody_modal: false,
        show_perkville_modal: false,
        show_pike_modal: false,
        show_marianatek_modal: false,
        show_vimeo_modal: false,
        integrations: [],
        integration_names: {
          mindbody: 'MINDBODY',
          perkville: 'Perkville',
          vimeo: 'Vimeo OTT',
          marianatek: 'MarianaTek',
          pike13: 'Pike13',
        },
        linked_locations: null,
        fetchLocationsFailed: false,
      };
    },
    mounted() {
      EventBus.$on('connect_mindbody.close', () => {
        this.show_mindbody_modal = false;
        this.refreshIntegrations();
      });

      EventBus.$on('connect_perkville.close', () => {
        this.show_perkville_modal = false;
        this.refreshIntegrations();
      });

      EventBus.$on('connect_pike.close', () => {
        this.show_pike_modal = false;
        this.refreshIntegrations();
      });

      EventBus.$on('connect_vimeo.close', () => {
        this.show_vimeo_modal = false;
        this.refreshIntegrations();
      });
    },
    created() {
      if(this.$store.getters.activeVendor === 'marianatek') {
        this.fetchLinkedLocations();
      }
      this.refreshIntegrations();
    },
    beforeDestroy() {
      EventBus.$off('connect_mindbody.close');
      EventBus.$off('connect_perkville.close');
      EventBus.$off('connect_pike.close');
      EventBus.$off('connect_vimeo.close');
    },
    computed: {
      availableIntegrations() {
        // const allowedIntegrations = ['mindbody', 'pike13', 'perkville', 'MarianaTek', 'vimeo OTT'];
        const allowedIntegrations = ['mindbody', 'pike13', 'marianatek', 'vimeo'];

        const currentIntegrations = this.integrations.map(i => i.vendor);

        const difference = allowedIntegrations
        .filter(x => !currentIntegrations.includes(x))
        .concat(currentIntegrations.filter(x => !allowedIntegrations.includes(x)));

        return difference;
      },
    },
    methods: {
      checkMBOIntegration() {
        this.loading = true;
        checkMindbodyConnection().then((response) => {
          this.loading = false;
          this.$message.success(response.message);
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      refreshIntegrations() {
        getActiveIntegrations().then((response) => {
          this.integrations = response.integrations;
          this.loading = false;
          const existingVendors = {
            mindbody: {
              displayName: 'MINDBODY',
            },
            pike13: {
              displayName: 'Pike13',
            },
            marianatek: {
              displayName: 'MarianaTek',
            },
            vimeo: {
              displayName: 'Vimeo',
            },
          };

          this.integrations.forEach((integration) => {
            // check for existing non uia vendors & inject display name
            if (existingVendors[integration.vendor]) {
              integration.displayName = existingVendors[integration.vendor].displayName;
            }
          // check uia vendors & inject display name
            if (!existingVendors[integration.vendor]) {
              integration.confirmed = false;
              integration.displayName = integration.vendor;
              integration.identifier = integration.vendor;
              if (this.$store.getters.isUIAVendor &&
                  this.$store.getters.activeVendor === integration.vendor) {
                integration.confirmed = true;
                integration.displayName = this.$store.getters.activeVendorDisplayName;
              }
            }
          });
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
      },
      newIntegration(command) {
        command = command.toLowerCase();
        const currentIntegrations = this.integrations.map(i => i.vendor);
        if (command === 'mindbody') {
          if (currentIntegrations.some(r => ['pike13', 'marianatek'].includes(r))) {
            this.$message.warning('Please reach out to your Account Manager if you wish to switch booking platforms.');
            return;
          }
          this.show_mindbody_modal = true;
        }

        if (command === 'perkville') {
          this.show_perkville_modal = true;
        }

        if (command === 'pike13') {
          if (currentIntegrations.some(r => ['mindbody', 'marianatek'].includes(r))) {
            this.$message.warning('Please reach out to your Account Manager if you wish to switch booking platforms.');
            return;
          }
          this.show_pike_modal = true;
        }

        if (command === 'marianatek') {
          if (currentIntegrations.some(r => ['mindbody', 'pike13'].includes(r))) {
            this.$message.warning('Please reach out to your Account Manager if you wish to switch booking platforms.');
            return;
          }
          this.show_marianatek_modal = true;
        }

        if (command === 'vimeo') {
          this.show_vimeo_modal = true;
        }
      },
      async fetchLinkedLocations() {
        try {
          const response = await getMTLinkedLocations();
          this.linked_locations = response.locations;
          if (this.fetchLocationsFailed) {
            this.fetchLocationsFailed = false;
          }
        } catch (err) {
          this.fetchLocationsFailed = true;
          this.$toasted.error('Failed to load locations.');
        }
      },
      fetchLinkedLocationsRefresh() {
        this.linked_locations = null;
        this.fetchLinkedLocations();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .el-card,
  .pulls {
    margin-top: 1rem;
  }

  .display-small {
    margin-bottom: .5rem;
  }
  .location-spacing {
    display: flex;
    justify-content: space-between;
  }
  .location-text {
    font-size: 14px;
    text-align: right;
    display: flex;
    flex-direction: column;
    a {
      text-decoration: underline;
      color: var(--xpl-color-secondary-lm);
    }
  }

</style>
