<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner">
      Behavior
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <label>Choose the type of behavior</label>
        <div class="multiselect-block">
          <Multiselect v-model="status" placeholder="Select a behavior" :options="available_statuses" :searchable="false" :allow-empty="false" :show-labels="false" />
        </div>
        <div class="small text-muted">Lorem</div>
      </div>
    </form>

    <div class="condition-banner">
      Has / Has Not
    </div>
    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="!visited" @click="visited = true"></span>
            <span class="icon icon-radio-checked" v-if="visited" @click="visited = true"></span>
          </div>
          <div class="col-xs-10">
            <label for="visited_yes" @click="visited = true">
              Has {{ status }}
            </label>
            <small class="form-text text-muted">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
            </small>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="visited === null || visited === true"></span>
            <span class="icon icon-radio-checked" v-if="visited === false"></span>
          </div>
          <div class="col-xs-10">
            <label for="visited_no" @click="visited = false">
              Has Not {{ status }}
            </label>
            <small class="form-text text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</small>
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner" v-if="!enrollment">
      Time Frame
    </div>
    <form @submit.prevent v-if="!enrollment">
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'absolute'" @click="timeframe = 'absolute'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'absolute'" @click="timeframe = 'absolute'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_absolute" @click="timeframe = 'absolute'; comparison = null;">
              Around specific dates
            </label>
            <small class="form-text text-muted">Select before, after, or between static dates. e.g. After July 27th 2018</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="timeframe === 'absolute'">
        <div class="form-group">
          <label>These visits occured</label>
          <Multiselect v-model="comparison" placeholder="Select a comparison" :options="comparisons_date" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" />
        </div>

        <div class="dates" v-if="comparison && timeframe === 'absolute'">
          <div class="date">
            <vue-datepicker-local v-model="dates.start" :local="local" format="MM-DD-YYYY" />
          </div>
          <span v-if="comparison.value === 'between'">&nbsp;and&nbsp;</span>
          <div class="date" v-if="comparison.value === 'between'">
            <vue-datepicker-local v-model="dates.end" :local="local" format="MM-DD-YYYY" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'relative'" @click="timeframe = 'relative'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'relative'" @click="timeframe = 'relative'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label for="timeframe_relative" @click="timeframe = 'relative'; comparison = null;">
              Relative to Today
            </label>
            <small class="form-text text-muted">Select at least, less than, or exactly a number of days ago. This is a moving window of time. e.g. Within the last 10 days.</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="timeframe === 'relative'">
        <div class="form-group">
          Every contact who {{ status }} for first time
          <Multiselect v-model="comparison" placeholder="Select a comparison" :options="comparisons_number" label="label" track-by="value" :searchable="false" :allow-empty="false" :show-labels="false" />
          <input type="number" name="relative_days" v-model.number="relative_days" class="form-control" min="0" style="display: inline-block; width: 5rem;" />
          days<span v-if="comparison && comparison.value !== 'within'"> ago</span>.
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="timeframe === null || timeframe !== 'all'" @click="timeframe = 'all'; comparison = null;"></span>
            <span class="icon icon-radio-checked" v-if="timeframe === 'all'" @click="timeframe = 'all'; comparison = null;"></span>
          </div>
          <div class="col-xs-10">
            <label @click="timeframe = 'all'; comparison = null;">
              All Time
            </label>
            <small class="form-text text-muted">Select at least, less than, or exactly a number of days ago. This is a moving window of time. e.g. Within the last 10 days.</small>
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Details
    </div>

    <form @submit.prevent>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="filtering === null || filtering !== 'all'" @click="filtering = 'all'"></span>
            <span class="icon icon-radio-checked" v-if="filtering === 'all'" @click="filtering = 'all'"></span>
          </div>
          <div class="col-xs-10">
            <label @click="filtering = 'all'">
              Contact is marked as {{ status }} for any enrollment
            </label>
            <small class="form-text text-muted">Add a contact to this list when they make any purchase in the time frame for this condition.</small>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="filtering === null || filtering !== 'specific'" @click="filtering = 'specific'"></span>
            <span class="icon icon-radio-checked" v-if="filtering === 'specific'" @click="filtering = 'specific'"></span>
          </div>
          <div class="col-xs-10">
            <label @click="filtering = 'specific'">
              Contact is marked as {{ status }} for a specific enrollment
            </label>
            <small class="form-text text-muted">Add a contact to this list when they make a purchase with the following details in the time frame for this condition.</small>
          </div>
        </div>
      </div>

      <div class="options-inner" v-if="filtering === 'specific'">
        <div class="form-group">
          <label>Enrollment Name</label>
          <div class="multiselect-block">
            <Multiselect v-model="selected_classes" placeholder="Select Enrollments" :options="enrollments" label="name" track-by="name" :searchable="true" :allow-empty="true" :show-labels="false" :multiple="true" :close-on-select="false" />
          </div>
          <small class="form-text text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmods</small>
        </div>
      </div>
    </form>

    <div class="form-group">
      <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition">Save this condition</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import Multiselect from 'vue-multiselect';
  import VueDatepickerLocal from 'vue-datepicker-local';

  export default {
    props: ['condition', 'editedRule', 'enrollment'],
    components: {
      Multiselect,
      VueDatepickerLocal,
    },
    data() {
      return {
        filtering: null,
        status: null,
        available_statuses: [],
        see_more: false,
        visited: true,
        relative_days: 0,
        timeframe: 'all',
        comparison: null,
        comparisons_date: [{
          label: 'After',
          value: 'after',
        }, {
          label: 'Before',
          value: 'before',
        }, {
          label: 'Between',
          value: 'between',
        }],
        comparisons_number: [{
          label: 'At least',
          value: 'more',
        }, {
          label: 'Within the last',
          value: 'within',
        }, {
          label: 'Exactly',
          value: 'exactly',
        }],
        dates: {
          start: '',
          end: '',
        },
        first_visit_type: null,
        selected_staffes: [],
        staffes: [],
        selected_enrollments: [],
        enrollments: [],
        pricing_options: [],
        selected_pricing_options: [],
        available_times: [],
        selected_times: [],
      };
    },
    created() {
      this.fetchEnrollments();
      this.fetchClassTimes();

      if (this.editedRule) {
        this.timeframe = this.editedRule.timeframe;
        this.relative_days = this.editedRule.relative_days;

        if (this.timeframe === 'relative') {
          this.comparison = this.comparisons_number.filter(c =>
            c.value === this.editedRule.comparison,
          )[0];
        }
        if (this.timeframe === 'absolute') {
          this.comparison = this.comparisons_date.filter(c =>
            c.value === this.editedRule.comparison,
          )[0];
        }

        this.dates.start = this.editedRule.start_date;
        this.dates.end = this.editedRule.end_date;
        this.first_visit_type = this.editedRule.first_visit_type;
        this.selected_pricing_options = this.editedRule.pricing_options;
        this.selected_enrollments = this.editedRule.enrollments;
      }
    },
    methods: {
      fetchEnrollments() {
        api.getEnrollments().then((response) => {
          this.enrollments = response.enrollments;
          this.available_statuses = response.statuses;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      fetchClassTimes() {
        api.getClassTimes().then((response) => {
          this.available_times = response.class_times;
        }).catch((err) => {
          this.$toasted.error(err.message);
        });
      },
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          timeframe: this.timeframe,
          relative_days: this.relative_days,
          comparison: this.comparison ? this.comparison.value : null,
          start_date: this.dates.start,
          end_date: this.dates.end,
          first_visit_type: this.first_visit_type,
          pricing_options: this.selected_pricing_options,
          enrollments: this.selected_enrollments,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }

  .see-more {
    cursor: pointer;
  }
</style>

