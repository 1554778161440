<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            New Folder
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <input type="text" v-model="name" placeholder="Folder Name" :class="['form-control', $v.name.$error ? 'is-invalid' : '']" @input="$v.name.$touch()" />
                <div class="small invalid-feedback" v-if="$v.name.$error">Your folder name can only contain letters, numbers, "-" and "_" as characters. Spaces or other special characters are not allowed.</div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="button" class="xpl-button xpl-button--primary" @click.stop="createFolder($event)" :disabled="$v.$error || $v.$invalid || !name">Save</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  // import { alphaNum } from 'vuelidate/lib/validators';
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    data() {
      return {
        name: null,
      };
    },
    validations: {
      name: {
        alphaNum(value) {
          // eslint-disable-next-line
          const regexp = /^[a-zA-Z0-9-_]+$/;

          return regexp.test(value);
        },
      },
    },
    methods: {
      closeModal() {
        EventBus.$emit('new_folder_modal.close');
      },
      createFolder(event) {
        const target = event.srcElement || event.target;
        const originalInner = target.innerHTML;

        target.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';
        target.disabled = true;

        const payload = {
          type: 'folder',
          folder_name: this.name,
        };

        api.createNewFolder(payload).then((response) => {
          this.$toasted.success(response.message);
          target.innerHTML = originalInner;
          EventBus.$emit('assets.refresh');
          this.closeModal();
        }).catch((err) => {
          target.innerHTML = originalInner;
          target.disabled = false;
          this.$toasted.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal-dialog {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
</style>
