<template>
  <div id="app">
    <Navbar v-if="showNavbar"/>
    <div :style="showNavbar && !onTrial ? 'padding-top: 52px;' : showNavbar && onTrial ? 'padding-top: 6.1rem;' : ''">
      <transition name="fade">
        <router-view></router-view>
      </transition>
    </div>
    <GitBranch/>
  </div>
</template>
<script>
import Vuex from 'vuex';
import Navbar from './components/Navbar';
import GitBranch from '@/components/GitBranch';

export default {
  components: {
    GitBranch,
    Navbar,
  },
  mounted() {
    if (this.$store.state.user.current_account?.franchises?.indexOf('aqua-tots') > -1) {
      return;
    }
    this.mounted2();
  },
  methods: {
    mounted2() {
      const appId = this.$store.getters.brandIntercomId || process.env.VUE_APP_INTERCOM_APP_ID;

      if (process.env.NODE_ENV !== 'production' || this.$store.getters.isStaff) {
        return;
      }

      if (!this.isLoggedIn) {
        window.startIntercomMessenger(appId, {app_id: appId});
        return;
      }
      try {
        window.startIntercomMessenger(appId, {
          app_id: appId,
          user_id: this.$store.state.user.uuid,
          name: this.$store.state.user.fullname,
          email: this.$store.state.user.email,
          user_hash: this.$store.state.user.user_hash,
          company: {
            id: this.$store.state.user.current_account.uuid,
            name: this.$store.state.user.current_account.name,
            Timezone: this.$store.state.user.current_account.timezone,
            'Total Campaigns': this.$store.state.user.current_account.total_campaigns ? this.$store.state.user.current_account.total_campaigns : 0,
            'Total Started Campaigns': this.$store.state.user.current_account.total_started_campaigns ? this.$store.state.user.current_account.total_started_campaigns : 0,
            'Total Scheduled Emails Sent': this.$store.state.user.current_account.total_scheduled_emails_sent ? this.$store.state.user.current_account.total_scheduled_emails_sent : 0,
            'Total Scheduled Emails Sent MTD': this.$store.state.user.current_account.total_scheduled_emails_sent_mtd ? this.$store.state.user.current_account.total_scheduled_emails_sent_mtd : 0,
            'On Trial': this.$store.state.user.current_account.on_trial,
            'On Paid Plan': this.$store.state.user.current_account.subscribed,
            'Days Left on Trial': this.$store.state.user.current_account.remaining_trial_days ? this.$store.state.user.current_account.remaining_trial_days : 0,
          },
        });
      } catch (e) {
        console.error(e);
      }

      //
      try {
        // eslint-disable-next-line no-undef
        analytics.identify(this.$store.state.user.uuid, {
          firstName: this.$store.state.user.first_name,
          lastName: this.$store.state.user.last_name,
          name: this.$store.state.user.fullname,
          email: this.$store.state.user.email,
          createdAt: this.$store.state.user.created_at,
        });
      } catch (e) {
        console.error(e);
      }

      //
      try {
        // eslint-disable-next-line no-undef
        analytics.group(this.$store.state.user.current_account.uuid, {
          name: this.$store.state.user.current_account.name,
          address: {
            city: this.$store.state.user.current_account.city,
            country: this.$store.state.user.current_account.country,
          },
        });
      } catch (e) {
        console.error(e);
      }

      //
      try {
        // eslint-disable-next-line no-undef
        window.hj('identify', this.$store.state.user.uuid, {
          email: this.$store.state.user.email,
          'Signed up': this.$store.state.user.created_at,
          'Current Account': this.$store.state.user.current_account.name,
        });
      } catch (e) {
        // eslint-disable-next-line
        console.error(e);
      }
    },
  },
  computed: {
    ...Vuex.mapGetters(['isLoggedIn']),
    onTrial() {
      return this.$store.state.user.current_account?.on_trial === true;
    },
    showNavbar() {
      if (!this.isLoggedIn) {
        return false;
      }

      if (this.$route.path === '/login') {
        return false;
      }

      if (this.$route.path === '/signup') {
        return false;
      }

      if (this.$route.path === '/onboarding') {
        return false;
      }

      if (this.$route.path.indexOf('scheduled-email') > -1) {
        return false;
      }

      if (this.$route.path.indexOf('browser') > -1) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
@import "scss/styles";

#app {
  width: 100%;
}
</style>
