<template>
  <div class="sidebar-inner" style="width: 100%;">
    <div class="condition-banner">
      Event Qualifier
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="!area === null || area !== 'inside'" @click="area = 'inside'"></span>
            <span class="icon icon-radio-checked" v-if="area === 'inside'" @click="area = 'inside'"></span>
          </div>
          <div class="col-xs-10">
            <label @click="area = 'inside'">Address inside of the area below</label>
            <div class="small text-muted">
              Isolates the first time a client purchases this specific type of purchase
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-xs-2">
            <span class="icon icon-radio-unchecked" v-if="!area === null || area !== 'outside'" @click="area = 'outside'"></span>
            <span class="icon icon-radio-checked" v-if="area === 'outside'" @click="area = 'outside'"></span>
          </div>
          <div class="col-xs-10">
            <label @click="area = 'outside'">Address outside of the area below</label>
            <div class="small text-muted">
              Isolates the first time a client purchases this specific type of purchase
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="condition-banner">
      Address Details
    </div>

    <form @submit.prevent>
      <div class="form-group">
        <label>Zip Code</label>
        <input type="text" v-model="zipcode" class="form-control" />
        <div class="small text-muted">Field-specific help message goes here</div>
      </div>

      <div class="form-group">
        <label>Radius</label>
        <input type="number" v-model.number="radius" class="form-control" />
        <div class="small text-muted">Field-specific help message goes here</div>
      </div>
    </form>

    <div class="form-group">
      <button type="button" class="xpl-button xpl-button--primary" @click="commitCondition">Save this condition</button>
    </div>
  </div>
</template>
<script>
  import EventBus from '@/event-bus';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['condition', 'editedRule'],
    components: {
      Multiselect,
    },
    data() {
      return {
        area: null,
        radius: null,
        zipcode: null,
      };
    },
    created() {

    },
    methods: {
      commitCondition() {
        const payload = {
          condition: {
            uuid: this.condition.uuid,
            name: this.condition.name,
            unique: this.condition.unique,
          },
          area: this.area,
          zipcode: this.zipcode,
          radius: this.radius,
        };

        EventBus.$emit('conditions_selection.commit', payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .multiselect {
    width: auto !important;
    display: inline-block !important;
  }

  .multiselect-block .multiselect {
    width: 100% !important;
    display: block !important;
  }

  .form-group {
    margin-top: 1.5rem;
    width: 100%;
  }

  .dates {
    display: flex;
    align-items: center;
  }

  .dates span {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  button {
    margin-top: 1rem;
  }

  .icon-radio-checked,
  .icon-radio-unchecked {
    margin-right: 1rem;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }
</style>
