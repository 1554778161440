<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Add a new Tag
            <i class="icon icon-cancel-square" @click.stop="closeModal"></i>
          </div>
          <div class="modal-body">
            <div class="selected-tags" v-if="selectedTag">
              <span class="pill pill-default">{{ selectedTag.name }} <i class="fa fa-times" @click="removeTag()"></i></span>
            </div>
            <input type="search" name="search" v-model="query" class="form-control" placeholder="Search tags..." />
            <div class="tags" v-if="loaded">
              <div :class="[ tag.selected ? 'selected tag' : 'tag' ]" v-for="tag in tagsFiltered" @click="addTag(tag)">{{ tag.name }}</div>
            </div>
            <div class="tags" v-else>
              <i class="fa fa-spin fa-spinner fa-fw"></i>
            </div>
          </div>
          <div class="modal-footer">
            <a href="#">Manage Tags</a>
            <button type="submit" class="xpl-button xpl-button--primary" @click.stop="save">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';

  export default {
    data() {
      return {
        loaded: false,
        query: '',
        tags: [],
        selectedTag: null,
      };
    },
    mounted() {
      api.getCustomTags().then((response) => {
        this.tags = response.tags;
        this.loaded = true;
      });
    },
    computed: {
      tagsFiltered() {
        if (this.query.length) {
          const query = this.query.toLowerCase();
          return this.tags.filter(tag => tag.name.toLowerCase().indexOf(query) > -1);
        }

        return this.tags;
      },
    },
    methods: {
      addTag(tag) {
        // const tagExists = this.selectedTag.filter(t => tag.uuid === t.uuid).length > 0;
        // We only allow one Tag at a time, for now.
        if (!this.selectedTag) {
          this.selectedTag = tag;
        }
      },
      removeTag() {
        // this.selectedTag = this.selectedTag.filter(t => t.uuid !== tag.uuid);
        this.selectedTag = null;
      },
      closeModal() {
        EventBus.$emit('contact_details.tags.close');
      },
      save() {
        EventBus.$emit('contact_details.tags.close', this.selectedTag);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .selected-tags {
    margin-bottom: 1rem;
  }

  .selected-tags .fa-times {
    cursor: pointer;
  }

  .tags {
    margin-top: 1rem;
  }

  .tags .tag {
    padding: .5rem;
    font-size: .875rem;
    background-color: white;
    margin-top: .25rem;
    margin-bottom: .25rem;
    border: 1px solid #cdd7e2;
  }

  .tags .tag:hover,
  .tags .tag.selected {
    cursor: pointer;
    background-color: var(--xpl-color-gray-400);
  }
</style>
