<template>
  <div class="login">
    <!--    <img src="/static/xplor-logo.svg"/>-->
    <div class="card">
      <div class="card-body">
        <h1>Sign In</h1>
        <form @submit.prevent="login">
          <div class="form-group xpl-input">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email Address"
              v-model.trim="credentials.email"
              class="form-control"
              autocomplete="username"
              required/>
          </div>
          <div class="form-group xpl-input">
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              v-model="credentials.password"
              class="form-control"
              autocomplete="current-password"
              required/>
          </div>
          <div class="form-group">
            <button type="submit" ref="submit" class="xpl-button xpl-button--primary"
                    :disabled="!credentials.email || !credentials.password">Sign In
            </button>
          </div>
          <router-link to="/forgot-password" class="small float-left">Forgot Password</router-link>
          <router-link to="/signup" class="small float-right" v-if="false">Create Account</router-link>
        </form>
        <i class="fa fa-spinner fa-spin fa-fw watermark"></i>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from '@/api';

export default {
  data() {
    return {
      credentials: {
        email: '',
        password: '',
      },
      error: '',
    };
  },
  methods: {
    async login() {
      this.$refs.submit.disabled = true;
      this.$refs.submit.innerHTML = '<i class="fa fa-spinner fa-spin fa-fw"></i>';
      try {
        const response = await api.loginUser(this.credentials);
        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('refresh_token', response.refresh_token);
        this.$toasted.success('Welcome back!');
        await this.$store.dispatch('refreshUser');

        // canonical domain
        if (this.$store.getters.toCanonicalUrl) {
          window.toCanonicalHost(this.$store.getters.brandHost);
          return;
        }

        this.$nextTick(() => {
          window.location.href = '/calendar';
        });
      } catch (err) {
        this.$toasted.error('We were unable to sign you in. Please try again');
        this.error = err.message;
        this.$refs.submit.disabled = false;
        this.$refs.submit.innerHTML = 'Sign In';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
form {
  display: block
}

.login {
  background: var(--xpl-color-background-lm);
  background-size: 100% 80%;
  background-repeat: no-repeat;
  background-position: bottom center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  padding-bottom: 4rem;
}

.login img {
  width: 8rem;
  margin-bottom: 1rem;
}

.login .card {
  max-width: 21.875rem;
  border: solid 1px rgb(217, 231, 240);
  flex-shrink: 0;
  flex-grow: 0;
}

.login .card .card-body {
  padding: 1.4rem 1.75rem;
}

h1 {
  font-size: 1.25rem;
  font-width: 700;
  line-height: 1.4;
  color: rgb(46, 68, 87);
  margin-bottom: 1rem;
}

.small {
  color: rgb(46, 68, 87);
}

.watermark {
  color: white;
  font-size: 1px;
}
</style>
