<template>
  <div>
    <div class="nav-subheader">
      <div class="nav-inner">
        <div>Your Calendar</div>
        <div class="nav-pills">
          <div class="pill" @click="$router.push('/calendar/list?type=emails')">Emails</div>
          <div class="pill" @click="$router.push('/calendar/list?type=tasks')">To-Do</div>
        </div>
      </div>
      <div class="dropdown">
        <button type="button" class="xpl-button xpl-button--primary" @click="create_dropdown = !create_dropdown"
                v-on-clickaway="away">Create New
        </button>
        <div :class="{'dropdown-menu': true, 'dropdown-menu-right': true, 'show': create_dropdown}">
          <a class="dropdown-item" href="#" @click.stop="scheduled.email = true; create_dropdown = false;">
            <span class="material-icons">email</span> Scheduled Email
          </a>
          <a class="dropdown-item" href="#" v-if="false">
            <span class="material-icons">forum</span> Facebook Post
          </a>
          <a class="dropdown-item" href="#"
             @click.stop="show_tasks_sidebar = true; scheduledTaskId = null; create_dropdown = false;">
            <span class="material-icons">assignment</span> Task
          </a>
          <a class="dropdown-item" href="#"
             @click.stop="show_phone_sidebar = true; scheduledTaskId = null; create_dropdown = false;">
            <span class="material-icons">call</span> Phone Call Reminder
          </a>
          <a v-if="$store.state.user.current_account && $store.state.user.current_account.franchise"
             class="dropdown-item" href="#"
             @click.stop="scheduled.email = true; corporate = true; create_dropdown = false;">
            <span class="material-icons">exit_to_app</span> Pushed Corporate Email
          </a>
        </div>
      </div>
    </div>
    <div class="calendar-container">
      <div class="calendar-picker">
        <input type="text" id="datepicker-value" hidden>
        <div id="datepicker"></div>
      </div>
      <div class="calendar-inner">
        <div class="calendar-item" v-for="calendar_item in calendar">
          <div class="day">{{ calendar_item.date }}</div>
          <div class="card empty" v-if="!calendar_item.items.length">
            Nothing scheduled for today
          </div>
          <div class="card hoverable" v-if="calendar_item.items.length" v-for="item in calendar_item.items"
               @click.stop="showCalendarItem(item)">
            <div :class="['card-body', item.type === 'scheduled_email' ? 'email' : 'task']">
              <div class="status" v-if="item.type === 'scheduled_email'">
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/in-progress.svg"
                     v-if="!item.scheduled && !item.overdue"/>
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/complete.svg"
                     v-if="item.scheduled && item.sent"/>
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/scheduled.svg"
                     v-if="item.scheduled && !item.sent"/>
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/alert.svg"
                     v-if="item.incomplete && !item.scheduled && !item.sent && item.overdue"/>
              </div>
              <div class="status" v-if="item.type === 'scheduled_task'">
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/empty.svg"
                     v-if="!item.complete && !item.overdue"/>
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/complete.svg" v-if="item.complete"/>
                <img src="https://assets.brandbot.com/public/icons/scheduled-items/alert.svg"
                     v-if="!item.complete && item.overdue"/>
              </div>
              <div class="details-container">
                <div class="name">
                  <span class="material-icons" v-if="item.type === 'scheduled_email'">email</span>
                  <span class="material-icons"
                        v-if="item.type === 'scheduled_task' && item.phone_call === 0">assignment</span>
                  <span class="material-icons"
                        v-if="item.type === 'scheduled_task' && item.phone_call === 1">phone</span>
                  <h6>{{ item.name }}</h6>
                </div>
                <div class="details" v-if="item.type === 'scheduled_email'">
                  <span class="text-muted" v-if="item.sent">Sent on {{ item.scheduled_at | humanDateTime }}</span>
                  <span class="text-muted"
                        v-if="item.scheduled && !item.sent">Scheduled for {{ item.scheduled_at | humanDateTime }}</span>
                  <span class="text-muted" v-if="!item.scheduled && item.email_id && !item.overdue">In Progress &middot; {{
                      item.scheduled_at | humanDateTime
                    }}</span>
                  <span class="text-muted" v-if="!item.scheduled && item.email_id && item.overdue">Overdue &middot; {{
                      item.scheduled_at | humanDateTime
                    }}</span>
                  <span class="text-muted"
                        v-if="!item.email_id">Not Started &middot; {{ item.scheduled_at | humanDateTime }}</span>
                </div>
              </div>
              <ScheduledEmailMetrics
                v-if="item.type === 'scheduled_email' && item.scheduled && item.sent && item.email_uuid"
                :typeUuid="item.uuid"/>
              <div class="associated" v-if="item.type === 'scheduled_task'">
                <span v-if="item.associated_contact_first_name">{{
                    item.associated_contact_first_name
                  }} {{ item.associated_contact_last_name }}</span>
                <span v-if="!item.associated_contact_first_name">{{ item.associated_contact_email }}</span>
              </div>
              <div class="assignee" v-if="item.assigned_type === 'user'">
                <avatar
                  :username="item.assigned_user_first_name ? ' ' + item.assigned_user_first_name + ' ' + item.assigned_user_last_name : item.assigned_user_email"
                  color="#fff" :rounded="true" :size="24" v-if="item.assigned_user_id"></avatar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ScheduledEmail v-if="scheduled.email" :corporate="corporate"/>
    <TasksSidebar v-if="show_tasks_sidebar" :scheduledTaskId="scheduledTaskId"/>
    <PhoneCallsSidebar v-if="show_phone_sidebar" :scheduledTaskId="scheduledTaskId"/>
  </div>
</template>
<script>
import store from '@/store';
import EventBus from '@/event-bus';
import * as api from '@/api';
import { directive as onClickaway } from 'vue-clickaway';
import moment from 'moment';
import flatpickr from 'flatpickr';
import Avatar from 'vue-avatar';
import ScheduledEmail from './ScheduledEmail';
import ScheduledEmailMetrics from './ScheduledEmailMetrics';
import TasksSidebar from '../Tasks/TasksSidebar';
import PhoneCallsSidebar from '../Tasks/PhoneCallsSidebar';

export default {
  directives: {
    onClickaway,
  },
  components: {
    ScheduledEmail,
    ScheduledEmailMetrics,
    TasksSidebar,
    PhoneCallsSidebar,
    Avatar,
  },
  data() {
    return {
      show_tasks_sidebar: false,
      show_phone_sidebar: false,
      scheduledTaskId: null,
      create_dropdown: false,
      corporate: false,
      scheduled: {
        email: false,
      },
      calendar: [],
      tabs: {
        calendar: true,
        list: false,
      },
      dates: {
        start: null,
        end: null,
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    if (Object.keys(store.state.user).length === 0) {
      store.dispatch('refreshUser')
        .then(() => {
          next();
        });
    } else {
      next();
    }
  },
  mounted() {
    // eslint-disable-next-line
    document.title = `Calendar | ${this.$store.getters.brandName}`;

    EventBus.$on('scheduled_modal.close', () => {
      this.scheduled.email = false;
    });

    EventBus.$on('calendar.refresh', () => {
      this.fetchCalendarItems();
    });

    EventBus.$on('calendar.task_sidebar.close', (refresh) => {
      this.show_tasks_sidebar = false;
      this.show_phone_sidebar = false;
      if (refresh) {
        this.fetchCalendarItems();
      }
    });
  },
  created() {
    this.dates.start = moment()
      .format('YYYY-MM-DD');
    this.dates.end = moment()
      .add(4, 'weeks')
      .format('YYYY-MM-DD');
    this.fetchCalendarItems();

    if (this.$route.path.indexOf('/tasks') > -1) {
      this.show_tasks_sidebar = true;
      this.scheduledTaskId = this.$route.params.id;
    }

    if (this.$route.path.indexOf('/call-reminders') > -1) {
      this.show_phone_sidebar = true;
      this.scheduledTaskId = this.$route.params.id;
    }

    this.$nextTick(() => {
      flatpickr('#datepicker-value', {
        mode: 'range',
        inline: true,
        dateFormat: 'Y-m-d',
        defaultDate: [this.dates.start, this.dates.end],
        maxDate: '2027-01-01',
        minDate: '2017-01-01',
        onChange: (selectedDates) => {
          if (selectedDates.length > 0) {
            this.$set(this.dates, 'start', moment(selectedDates[0])
              .format('YYYY-MM-DD'));
          }

          if (selectedDates.length === 2) {
            this.$set(this.dates, 'end', moment(selectedDates[1])
              .format('YYYY-MM-DD'));
            this.fetchCalendarItems();
          }
        },
      });
    });
  },
  beforeDestroy() {
    // eslint-disable-next-line
    document.title = this.$store.getters.brandName;
    EventBus.$off('scheduled_modal.close');
    EventBus.$off('calendar.refresh');
    EventBus.$off('calendar.task_sidebar.close');
  },
  methods: {
    away() {
      this.create_dropdown = false;
    },
    showCalendarItem(item) {
      if (item.type === 'scheduled_email') {
        this.$router.push(`/scheduled-emails/${item.uuid}`);
      }

      if (item.type === 'scheduled_task') {
        if (item.phone_call) {
          this.show_phone_sidebar = true;
        } else {
          this.show_tasks_sidebar = true;
        }
        this.scheduledTaskId = item.id;
      }
    },
    fetchCalendarItems(startDate = null, endDate = null) {
      api.getCalendarItems(this.dates.start || startDate, this.dates.end || endDate)
        .then((response) => {
          this.calendar = response.calendar;
        })
        .catch((err) => {
          this.$toasted.error(err.message);
        });
    },
  },
};
</script>
<style src="flatpickr/dist/flatpickr.min.css"></style>
<style lang="scss">
.flatpickr-calendar.inline {
  display: block;
  max-width: 100% !important;
  top: 0;
  border-radius: 0;
  border: 1px solid #f3f7f9;
  border-top: none;
  box-shadow: none;
  font-size: .675rem;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  content: none;
}

.dayContainer {
  width: 16rem !important;
  min-width: 16rem !important;
  max-width: 100% !important;
}

/*.flatpickr-days {*/
/*  width: 16rem !important;*/
/*  min-width: auto !important;*/
/*  max-width: 100% !important;*/
/*}*/

/*.flatpickr-day {*/
/*  color: #1d364b !important;*/
/*  border: none !important;*/
/*  margin: 0 !important;*/
/*}*/

/*.flatpickr-day.startRange,*/
/*.flatpickr-day.endRange {*/
/*  border-radius: 4rem !important;*/
/*  background-color: var(--xpl-color-secondary-lm) !important;*/
/*  color: white !important;*/
/*  border: none !important;*/
/*}*/

/*.flatpickr-day.inRange {*/
/*  box-shadow: none !important;*/
/*  background-color: var(--xpl-color-secondary-bg-lm) !important;*/
/*  !*color: white !important;*!*/
/*}*/

/*.flatpickr-weekdays {*/
/*  color: #757575 !important;*/
/*  border-bottom: 1px solid var(--xpl-color-gray-400) !important;*/
/*}*/

/*.flatpickr-month {*/
/*  margin-bottom: .5rem;*/
/*}*/

/*.flatpickr-current-month span.cur-month {*/
/*  font-size: 100% !important;*/
/*}*/
</style>
<style lang="scss" scoped>
.nav-subheader {
  justify-content: space-between;
  position: fixed;
  z-index: 100;
}

.nav-inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.calendar-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  padding-top: 4rem;
}

.calendar-picker {
  margin-top: 1px;
  height: 100vh;
  background-color: #fff;
  border-right: 1px solid rgb(229, 231, 235);
  width: 17rem;
  min-width: 17rem;
  position: fixed;
  z-index: 1000;
}

.calendar-inner {
  width: 100%;
  padding-top: 1.5rem;
  padding-left: calc(17rem + 1.5rem);
  padding-right: 1.5rem;
}

.calendar-inner .calendar-item .day {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  color: #697c86;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.calendar-inner .calendar-item:first-child .day {
  margin-top: 0;
}

.dropdown-item {
  display: flex;
  align-items: center;
  transition: none;
}

/*XPLOR*/
/*.dropdown-item:hover {*/
/*  background-color: white;*/
/*  color: white;*/
/*  background: linear-gradient(45deg, #3ab8ff 0%, #00cfff 100%);*/
/*}*/

.dropdown-item .material-icons {
  color: #cdd7e2;
  font-size: 1.5rem;
  margin-right: 1rem;
}

/*XPLOR*/
/*.dropdown-item:hover .material-icons {*/
/*  color: white;*/
/*}*/

.calendar-item .card {
  cursor: pointer;
  margin-bottom: .35rem;
}

.calendar-item .card.empty {
  cursor: initial;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #edf2f4;
  border: solid 1px var(--xpl-color-gray-400);
  box-shadow: none;
  font-size: .75rem;
  line-height: 1.25rem;
  color: #617080;
}

.calendar-item .card .card-body {
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.calendar-item .card .card-body.task {
  padding-top: .5rem;
  padding-bottom: .5rem;
  align-items: center;
}

.calendar-item .card .card-body h6 {
  margin-bottom: 0;
}

.calendar-item .card .card-body .status,
.calendar-item .card .card-body .status img {
  width: 1.5rem;
  height: 1.5rem;
  display: block;
}

.calendar-item .card .card-body .associated {
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 10rem;
  width: 10rem;
  text-align: left;
  margin-left: 5rem;
  font-size: .875rem;
  color: rgb(98, 98, 98);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.calendar-item .card .card-body .assignee {
  width: 1.5rem;
  margin-left: 5rem;
}

.calendar-item .card .card-body .associated + .assignee {
  margin-left: 5rem;
}

.calendar-item .card .card-body .details-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex-basis: 25rem;
  flex-grow: 1;
}

.calendar-item .card .card-body .details-container .name {
  display: flex;
}

.calendar-item .card .card-body .details-container .name .material-icons,
.calendar-item .card .card-body .details-container .name h6 {
  line-height: 1rem;
}

.calendar-item .card .card-body .details-container .name .material-icons {
  margin-left: .75rem;
  margin-right: .5rem;
  font-size: 1.25rem;
  color: var(--xpl-color-gray-400);
}

.calendar-item .card .card-body .details-container .name h6 {
  color: rgb(76, 79, 82);
}

.calendar-item .card .card-body .details-container .details {
  margin-top: .5rem;
  margin-left: .875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgb(132, 143, 153);
  font-size: .75rem;
}

.calendar-item .card .card-body .details .material-icons {
  margin-right: .5rem;
  font-size: .75rem;
}

.metrics {
  margin-left: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: .75rem;
  line-height: 1.25;
  color: #868e96;
  text-align: right;
}

.metrics .number {
  text-align: right;
  font-size: 1rem;
  line-height: 1;
  color: rgb(76, 79, 82);
  display: block;
  margin-bottom: .5rem;
}

.metrics .sent {
  margin-right: 1.25rem;
}

.metrics .opens {
  margin-right: 1.25rem;
}
</style>
