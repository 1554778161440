<template>
  <transition name="fade">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span></span>
            Create a New Segment
            <div class="btn-close-wrapper" @click.stop="closeModal">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="form-group">
                <label class="form-control-label" for="name">Segment Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  v-model="name"
                  placeholder="Segment Name"
                  :class="{ 'form-control': true, 'is-invalid': $v.name.$error }"
                  @input="$v.name.$touch()"
                  @keyup.enter="save" />
                  <div class="invalid-feedback" v-if="!$v.name.required">You are required to name your segment.</div>
                  <div class="invalid-feedback" v-if="!$v.name.minLength">The name of your segment should be at least 2 characters long.</div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span></span>
            <button type="button" ref="saveButton" class="xpl-button xpl-button--primary" @click.stop="save" :disabled="!name || $v.$invalid">Create Segment</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import EventBus from '@/event-bus';
  import * as api from '@/api';
  import { required, minLength } from 'vuelidate/lib/validators';

  export default {
    props: ['filterGroup'],
    data() {
      return {
        name: '',
      };
    },
    methods: {
      closeModal() {
        EventBus.$emit('contact_lists.new_contact_list.close');
      },
      save() {
        this.$refs.saveButton.disabled = true;
        this.$refs.saveButton.innerHTML = '<i class="fa fa-spin fa-spinner fa-fw"></i>';

        const payload = {
          name: this.name,
          group: this.filterGroup ? this.filterGroup.id : null,
        };

        api.addContactList(payload).then((response) => {
          this.$toasted.success(response.message);

          // eslint-disable-next-line no-undef
          analytics.track('Created a Segment', {
            name: payload.name,
          });

          EventBus.$emit('contact_lists.new_contact_list.close');
          this.$router.push(`/contacts/lists/${response.contact_list.uuid}`);
        }).catch((err) => {
          this.$toasted.error(err.message);
          this.$refs.saveButton.disabled = false;
          this.$refs.saveButton.innerHTML = 'Create';
        });
      },
    },
    validations: {
      name: {
        required,
        minLength: minLength(2),
      },
    },
  };
</script>
<style lang="scss" scoped>
  .icon {
    cursor: pointer;
  }
</style>
