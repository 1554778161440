<template>
  <div>
    <div class="display-medium" style="margin-bottom: 2rem;">Waiver Insights</div>
    <el-row :span="24">
      <el-col :span="24">
        <el-card>
          <div class="text-center display-medium">{{ answers.length | prettyNumber }}</div>
          <div class="text-center display-caption subdued">All Time</div>
        </el-card>
      </el-col>
    </el-row>
    <el-card style="margin-top: 2rem;">
      <el-table v-if="answers.length" :data="answers">
        <el-table-column prop="created_at" :key="created_at" :label="'Date'" :width="200" sortable :formatter="humanDate"></el-table-column>
        <el-table-column prop="contact" :key="contact" :label="'Contact'" :width="200">
          <template slot-scope="scope">
            <a :href="'/contacts/' + scope.row.contact.uuid" target="_blank">{{ scope.row.contact.fullname || scope.row.contact.email }}</a>
          </template>
        </el-table-column>
        <el-table-column v-for="col in columns" :prop="col" :key="col" :label="col" :width="200"></el-table-column>
      </el-table>
      <div v-if="!answers.length" class="text-center">
        No data has been collected yet
      </div>
    </el-card>
  </div>
</template>
<script>
  import { getFormInsights } from '@/api';

  export default {
    props: ['form'],
    data() {
      return {
        loading: false,
        all_time: {},
        answers: [],
      };
    },
    created() {
      this.fetchInsights();
    },
    computed: {
      columns() {
        if (!Object.keys(this.answers).length) {
          return [];
        }

        return Object.keys(this.answers[Object.keys(this.answers)[0]])
        .filter(k => ['created_at', 'email', 'contact'].indexOf(k) === -1);
      },
    },
    methods: {
      humanDate(row) {
        return this.$options.filters.humanDate(row.created_at);
      },
      fetchInsights() {
        getFormInsights(this.form.id).then((response) => {
          this.all_time = response.all_time;
          this.answers = response.answers;
        }).catch((err) => {
          this.$message.error(err.message);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .display-medium {
    margin-bottom: 1rem;
  }
</style>
